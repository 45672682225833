import { Component, Host, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {IPaymentDetail} from "../../shared/entities/paymentDetail";
import {PagerService} from "../../shared/pager.service";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {PaymentsService} from "../payments.service";
import {Router} from "@angular/router";
import {ISite} from "../../shared/entities/site";
import {PortalService} from "../../shared/portal.service";
import { BankAccountPipe } from "../../shared/pipe/bank-account.pipe"
import { IAccountBalanceRequest, IPaymentMethod } from "../payments";
import {IUserEmail, IUserAccount} from "../../shared/entities/profile"
import {FakerService} from "../../shared/faker.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import { PaymentsComponent } from '../payments.component';
@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss'],
  providers: [OrderByPipe, BankAccountPipe]
})
export class PaymentConfirmationComponent implements OnInit {

  paymentTotal: number;
  pager: any;
  sortBy: string = 'siteId';
  ascending: boolean = true;
  selectedBalances: any[];
  paymentDate: Date;
  paymentMethod: IPaymentMethod;
  emailError: boolean = false;
  customerName: string;
  maskMode: boolean;
  myAccount: IUserAccount;
  printing: boolean;
  fakeAccountName: string;
  helpStep: IHelpStep;
  payByAccount = false;

  maskModeSubscription: Subscription;
  accountSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private dataService: DataService,
              private pagerService: PagerService,
              private orderByPipe: OrderByPipe,
              private paymentsService: PaymentsService,
              private router: Router,
              private portalService: PortalService,
              private bankAccountPipe: BankAccountPipe,
              @Host() private parent: PaymentsComponent) {

  }

  ngOnInit() {
    this.paymentsService.setSubmitted(true);
    this.dataService.setSelectedNavItem('billing');
    this.dataService.setTitleSource("Make a Payment");
    this.selectedBalances = this.paymentsService.selectedBalances;
    this.selectedBalances.forEach((balance) => {
      balance.paymentAmount = Number(balance.paymentAmount).toFixed(2);
    });
    this.paymentTotal = this.paymentsService.paymentTotal;
    this.paymentDate = this.paymentsService.paymentSchedule.paymentDate;
    if(this.paymentsService.paymentSchedule){
      this.paymentDate = this.paymentsService.paymentSchedule.paymentDate;
      this.paymentMethod = this.paymentsService.paymentSchedule.paymentMethod;
    }

    this.myAccount = this.dataService.getAccountSource();
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
    });
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.fakeAccountName = this.dataService.getFakeAccountName();
    });
    this.paginate(1);
    this.paymentsService.setStepReached(4);
    this.createEmailConfirmation();
    this.sendInitalConfirmationEmail();
    let account = this.dataService.getAccountSource();
    let balanceRequest: IAccountBalanceRequest = {
      accountId: account.id,
      startDate: moment().subtract(60, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    };
    
    this.paymentsService.getBalanceDueDates(balanceRequest).subscribe(
      (resp) => {
        // console.log("Getting balance due dates: ", resp);
        let balanceDue = this.dataService.getBalanceDue();
        if(balanceDue) {
          balanceDue.paymentDate = resp.paymentDate;
          balanceDue.paymentAmount = resp.paymentAmount;
          balanceDue.nextPaymentDue = resp.nextPaymentDue;
          this.dataService.setBalanceDue(balanceDue);
        } else {
          this.dataService.setBalanceDue(resp);
        }
      }, (err) => {
        if(err.status == '404'){
          let currBalance = this.dataService.getBalanceDue();
          if(currBalance){
            currBalance.paymentDate = null;
            currBalance.paymentAmount = 0.00;
            currBalance.nextPaymentDue = null;
            this.dataService.setBalanceDue(currBalance);
          } else {
            this.dataService.setBalanceDue({
              paymentDate: null,
              paymentAmount: 0.00,
              nextPaymentDue: null
            } as any);
          }
        } else {
          this.dataService.setBalanceDue(null);
        }
      }
    );

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    this.payByAccount = this.paymentsService.payByAccount;
  }

  ngOnDestroy(){
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  printWindow() {
    this.printing = true;
    let pageItems = this.pager.items;
    this.pager.items = this.selectedBalances;
    this.dataService.setPrinting(this.printing);
    setTimeout(() => {
      window.print();
      this.printing = false;
      this.pager.items = pageItems;
      this.dataService.setPrinting(this.printing);
    }, 50);

  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  goToSelectBalances() {
    this.paymentsService.setBalances(null);
    this.paymentsService.setSubmitted(false);
    this.paymentsService.payByAccount = false;
    if (this.parent) {
      this.parent.toggleAccountView(false);
    }
    this.router.navigate(['/payments/select-balances']);
  }

  paginate(page: number) {
    if((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager && this.selectedBalances){
        this.selectedBalances = this.orderByPipe.transform(this.selectedBalances, this.sortBy, this.ascending);
        this.pager = this.pagerService.getPage(this.selectedBalances, page);
    }
  }

  createEmailConfirmation(){
    this.dataService.setSelectedBalances(this.selectedBalances);
    this.dataService.setPaymentTotal(this.paymentTotal.toFixed(2));
    this.dataService.setPaymentDate(this.paymentDate);
  }

  sendInitalConfirmationEmail(){
    let account = this.dataService.getAccountSource();
    let customerName = account.name;
    let accountId = account.id;
    let myProfile = this.dataService.getProfile();
    let emails:IUserEmail[] = [{temp:myProfile.email}];
    let paymentConfirmation = this.selectedBalances.map(function(site:any){
      return {
        "siteId": site.collective ? 'Multiple' : site.siteId,
        "contractAccountNum": site.accountNumber,
        "invoice": site.invoiceNo,
        "balanceDue": site.paymentAmount
      };
    }
  );
  var  paymentMethod = "";
  if(this.paymentMethod.maskedAccountNumber)
     paymentMethod = this.bankAccountPipe.transform(this.paymentMethod.maskedAccountNumber.toString());
  else
     paymentMethod = this.bankAccountPipe.transform(this.paymentMethod.maskedCardNumber.toString());
  let confirmationNumber = this.selectedBalances[0].confirmationNumber;
  let paymentDate = moment(this.paymentDate).format('YYYY-MM-DD');
  this.portalService.sendPaymentConfirmationEmails(emails, this.paymentTotal.toString(), paymentDate, paymentConfirmation, customerName, paymentMethod, confirmationNumber, accountId).subscribe(
      (resp) => {
        // console.log("Inital email sent Successfully!");
      },
      (err) => {
        // console.log("Error sending inital email!");
        this.emailError = true;
      }
    );
  }
  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }
  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}

