import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { IRenewableSite } from '../../renewable-site';
import { IGenerationPoint, IRenewableSiteGeneration, ISiteGenerationRequest } from '../../renewable-site-generation';
import { RenewablesService } from '../../renewables.service';
import { solarTitle, gradient9, updateSeriesVisibility, gradient10, buildChartConfig, sharedSeries, setSolarExtremes, apiDateFormat } from '../../renewables-utility';
import * as moment from 'moment';
import { DateRangeSelectorComponent } from '../../../shared/components/date-range-selector/date-range-selector.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import {IUserAccount} from "../../../shared/entities/profile";
import {DataService} from "../../../shared/data.service";


declare var Highcharts: any;

@Component({
  selector: 'app-history-graph',
  templateUrl: './history-graph.component.html',
  styleUrls: ['./history-graph.component.scss']
})
export class HistoryGraphComponent implements OnInit, AfterViewInit, OnDestroy {
  myUserAccount: IUserAccount;
  sites: IRenewableSite[];
  siteGeneration: IRenewableSiteGeneration;
  minHistoryDate: Date;
  maxHistoryDate = moment().endOf('day').subtract(0, 'day').toDate();
  dateRangeStartOverride: Date;
  dateRangeEndOverride: Date;
  showForecast = true;
  showSolar = true;
  hourlyGenerationAverage: number;
  generationTotal: any;
  // generationTotals: string;
  hourlyGenerationPeak: number;
  hourlyGenerationPeakTime: Date;
  private graphChart: any;
  private maxSolar: number;
  private readonly interval = 15;
  private readonly unsubscribe$ = new Subject();
  


  availStartDate: string;
  availEndDate: string;
  selStartDate: string;
  selEndDate: string;
  private readonly dateFormat = 'MM/DD/YY';

  @ViewChild(DateRangeSelectorComponent, {static : false}) dateRangeSelector: DateRangeSelectorComponent;
  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(private renewablesService: RenewablesService,
    private dataService: DataService
    ) { }

  ngOnInit() {
    this.initializeGraph();
    this.myUserAccount = this.dataService.getAccountSource();
  }

  ngAfterViewInit() {
    // Wait until after all children have initialized
    this.spinner.show('Loading Site Generation...');
    this.renewablesService.sites
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.sites = sites;
        this.minHistoryDate = new Date(this.sites
          .filter(site => site.minRangeDate)
          .map(site => site.minRangeDate)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]);

          this.maxHistoryDate =  new Date(this.sites
            .filter(site => site.maxRangeDate)
            .map(site => site.maxRangeDate)
            .sort((a,b) => new Date(b).getTime() - new Date(a).getTime())[0]);

            this.availStartDate = moment( this.minHistoryDate).add(1,'d').format(this.dateFormat);
            this.availEndDate = moment(this.maxHistoryDate).add(2,'d').format(this.dateFormat);
     
            this.minHistoryDate = moment( this.minHistoryDate).add(1,'d').toDate();
            this.maxHistoryDate = moment( this.maxHistoryDate).add(2,'d').toDate();    
     

        this.fetchSiteGeneration();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchSiteGeneration() {
    this.spinner.show('Loading Site Generation...');
    this.graphChart.zoomOut();
    this.dateRangeSelector.selectedStartDate;
    this.dateRangeSelector.selectedEndDate;

    this.selStartDate = moment(this.dateRangeSelector.selectedStartDate).format(apiDateFormat);
    this.selEndDate = moment(this.dateRangeSelector.selectedEndDate).format(apiDateFormat);
    const request = {
      accountId: this.myUserAccount.id,
      siteIds: this.sites.filter(site => site.selected).map(site => site.id),
      startDate: moment(this.dateRangeSelector.selectedStartDate).format(apiDateFormat),
      endDate: moment(this.dateRangeSelector.selectedEndDate).format(apiDateFormat),
      interval: this.interval
    } as ISiteGenerationRequest;
    this.renewablesService.getSiteGeneration(request)
      .takeUntil(this.unsubscribe$)
      .subscribe(siteGeneration => {
        this.siteGeneration = siteGeneration;
        this.updateGraphData();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error('Failed to load site generation', error);
      });
  }

  toggleSeriesVisibility() {
    setSolarExtremes(this.graphChart, this.maxSolar);
    this.graphChart.yAxis[1].setTitle({ text: this.showSolar && this.siteGeneration.solarAvailable ? solarTitle : null });
    this.graphChart.yAxis[1].update({ visible: this.showSolar && this.siteGeneration.solarAvailable });

    updateSeriesVisibility(this.graphChart, 0, this.siteGeneration.generationAvailable);
    updateSeriesVisibility(this.graphChart, 1, this.siteGeneration.shadowAvailable);
    updateSeriesVisibility(this.graphChart, 2, this.showForecast && this.siteGeneration.forecastAvailable);
    updateSeriesVisibility(this.graphChart, 3, this.showSolar && this.siteGeneration.solarAvailable);
    this.graphChart.legend.render();
  }

  private initializeGraph() {
    const chartConfig = buildChartConfig(this.interval, {
      defs: {
        gradient9,
        gradient10
      },
      chart: {
        events: {
          selection: event => this.handleGraphZoom(event)
        },
        zoomType: 'x'
      },
      series: [
        {
          name: 'Generation (KW)',
          type: 'area',
          yAxis: 0,
          turboThreshold: 500000
        },
        ...sharedSeries()
      ]
    }, () => {
      setSolarExtremes(this.graphChart, this.maxSolar);
    });
    this.graphChart = Highcharts.chart('renewables-history-graph-container', chartConfig);
  }

  private handleGraphZoom(event: any) {
    if (event.xAxis) {
      const points = this.siteGeneration.points;
      // Determine the start and end date overrides in the date range selector
      // Set indicies to factor in rounding to the hour
      const minIndex = Math.floor(event.xAxis[0].min);
      const maxIndex = Math.ceil(event.xAxis[0].max);
      const firstIntervalIndex = Math.max(minIndex - (minIndex % 4), 0);
      const lastIntervalIndex = Math.min(maxIndex + 4 - (maxIndex % 4), points.length - 1);
      this.dateRangeStartOverride = moment(this.siteGeneration.points[firstIntervalIndex].date, apiDateFormat).toDate();
      this.dateRangeEndOverride = moment(points[lastIntervalIndex].date, apiDateFormat).toDate();

      this.calculateStatistics(this.siteGeneration.points.slice(minIndex, maxIndex));
    } else {
      this.dateRangeStartOverride = null;
      this.dateRangeEndOverride = null;
    }
  }

  private updateGraphData() {
    const points = this.siteGeneration.points;

    this.graphChart.xAxis[0].setCategories(points.map(point =>
      moment(point.date, apiDateFormat).add((point.intervalId - 1) * this.interval, 'minutes').toDate()
    ));

    this.graphChart.series[0].setData(points.map(point =>
      ({ y: point.generation, countLabel: 'Generation Site Count: ', count: point.generationCount })));
    this.graphChart.series[1].setData(points.map(point =>
      ({ y: point.shadow, countLabel: 'Shadow Site Count: ', count: point.shadowCount })));
    this.graphChart.series[2].setData(points.map(point =>
      ({ y: point.forecast, countLabel: 'Forecast Site Count: ', count: point.forecastCount })));
    this.graphChart.series[3].setData(points.map(point => point.solar));

    this.maxSolar = points.map(point => point.solar).sort((a, b) => b - a)[0];

    this.calculateStatistics(points);
    this.toggleSeriesVisibility();
  }

  private calculateStatistics(summedPoints: IGenerationPoint[]) {
    const hourlyAverages = new Array<IGenerationPoint>();
    let hourValues = [];
    summedPoints.forEach((point, index) => {
      hourValues.push(point.generation);

      if (index % 4 === 0 && index > 0) {
        hourlyAverages.push({
          date: point.date,
          intervalId: point.intervalId,
          generation: this.averageValues(hourValues)
        } as IGenerationPoint);
        hourValues = [];
      }
    });

    this.hourlyGenerationAverage = this.averageValues(hourlyAverages.map(point => point.generation)) / (60 / this.interval);
    this.generationTotal = this.sumValues(summedPoints.map(point => point.generation + point.shadow)) / (60 / this.interval);
    this.generationTotal = this.generationTotal.toFixed();
    const peakHourlyPoint = hourlyAverages.filter(point => point.generation > 0).sort((a, b) => b.generation - a.generation)[0];
    this.hourlyGenerationPeak = peakHourlyPoint ? peakHourlyPoint.generation : 0;
    this.hourlyGenerationPeakTime = peakHourlyPoint
      ? moment(peakHourlyPoint.date, apiDateFormat).add((peakHourlyPoint.intervalId - 1) * this.interval, 'minutes').toDate()
      : null;
  }

  private sumValues(values: number[]): number {
    return values.reduce((sum, val) => sum + val, 0);
  }

  private averageValues(values: number[]): number {
    if (!values.length) {
      return 0;
    }
    return this.sumValues(values) / values.length;
  }
}
