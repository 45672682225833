import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { EditorialContentService } from '../../editorial-content/editorial-content.service';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
import { DataService } from '../../shared/data.service';
import { ContentResponse } from '../../shared/entities/contentResponse';
import 'rxjs/add/operator/takeUntil';

@Component({
  selector: 'app-renewables-certificates',
  templateUrl: './renewables-certificates.component.html',
  styleUrls: ['./renewables-certificates.component.scss']
})
export class RenewablesCertificatesComponent implements OnInit, OnDestroy {
  insightsContent: ContentResponse;
  showInsights = true;
  private readonly unsubscribe$ = new Subject();
  //private readonly contentRoute = '/content/nrg/portal/sustainability-insights.nrgcontent.json?nc=1';
  private readonly contentRoute = '/portal/SustainabilityInsights.nrgcontent.json';

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(
    private dataService: DataService,
    private editorialContentService: EditorialContentService
  ) { }

  ngAfterViewInit(){
    this.spinner.show('Loading Insights...');
  }
  ngOnInit() {
    this.dataService.showGenerationDetailExport.emit(false);
    this.dataService.setContextualHelp(false);

    this.editorialContentService.getContentPage(this.contentRoute, true)
      .takeUntil(this.unsubscribe$)
      .subscribe(content => {
        this.insightsContent = content as ContentResponse;
        this.showInsights = true;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.showInsights = false;
        console.error('Failed to retrieve AEM content', error);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get insights(): any[] {
    if (!this.insightsContent) {
      return [];
    }
    return this.insightsContent.primaryComponents.concat(this.insightsContent.secondaryComponents);
  }
}
