import { Injectable } from '@angular/core';
import {PortalService} from "../shared/portal.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Http} from "@angular/http";
import {environment} from "../../environments/environment";
import {httpFactory} from "@angular/http/src/http_module";
import {Observable} from "rxjs";
import {ISuperUser} from "../shared/entities/superUser";
import { ICommissionDatePayload } from '../shared/entities/commissionsDatePayload';

@Injectable()
export class ManageSuperUsersService {

  constructor(private oldHttp: Http,
              private http: HttpClient,
              private portalService: PortalService) { }


  getAllSuperUsers(){
    let url = environment.getAllSuperUsersUrl;

    return this.http
      .get(url)
      .map((response: HttpResponse<boolean>) => response)
      .catch(this.handleError);
  }

  editSuperUser(superUser: ISuperUser){
    let url = environment.editSuperUserUrl;

    return this.http
      .post(url, superUser)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  deleteSuperUser(userId: number){
    // console.log("STARTING Delete");
    let url = environment.deleteSuperUserUrl
      .replace('{userId}', userId + '');

    return this.http
      .post(url,"")
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  checkForExistingUser(email: string){
    let url = environment.checkForExistingUserUrl
      .replace('{login}', email);

    return this.http
      .get(url)
      .map((response: HttpResponse<ISuperUser>) => response)
      .catch(this.handleError);
  }

  export(){
    let url = environment.exportSuperUsersUrl;

    this.portalService.exportToExcel(url, 'SuperUsers.xlsx');
  }

  resendActivationEmail(superUser: ISuperUser){
    let url = environment.resendSuperUserActivationUrl;

    return this.http
      .post(url, superUser)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  refreshUserAccountCache(){
    let url = environment.refreshUserAccountCache;

    return this.http.get(url).catch(this.handleError);
  }

  getGeneratedStatementsEmailList(startDate:Date, endDate:Date) {
    let url = environment.getGeneratedStatementsEmailListUrl;
    let datePayload : ICommissionDatePayload = {startDate: null, endDate: null};
    datePayload.startDate = startDate;
    datePayload.endDate = endDate;
    return this.http
    .post(url,datePayload)
    .map((response: HttpResponse<any>) => response)
    .catch(this.handleError);
  }

  sendGeneratedStatementsEmail(startDate:Date, endDate:Date){
    let datePayload : ICommissionDatePayload = {startDate: null, endDate: null};
    datePayload.startDate = startDate;
    datePayload.endDate = endDate;
    let url = environment.manuallySendStatements;
    return this.http
    .post(url,datePayload)
    .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}
