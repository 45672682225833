import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Widget, SummaryPeriods, IntervalUsagePeriods, DateTypes, DataOptions } from "../shared/entities/widgets/widget";
import { WidgetType } from "../shared/const/widgetType";
import { BillingSummaryWidget } from "../shared/entities/widgets/billingSummaryWidget";
import { IntervalUsageWidget } from "../shared/entities/widgets/intervalUsageWidget";
import { HelpTopicsWidget } from "../shared/entities/widgets/helpTopicsWidget";
import { UsageSummaryWidget } from "../shared/entities/widgets/usageSummaryWidget";
import { RecentInvoicesWidget } from "../shared/entities/widgets/recentInvoicesWidget";
import { SitesControl } from "../shared/entities/controls";
import {ISiteGroup, ISite} from "../shared/entities/site";
import { IComparisonReport, IComparisonChartResponse } from "../shared/entities/comparison";
import {SiteGroupService} from "../site-groups/site-groups.service";
import {FadeAnimation} from "../animations/fadeAnimation";
import {FadeInAnimation} from "../animations/fadeInAnimation";
import { DashboardService } from "../dashboard/dashboard.service";
import { IUserAccount } from "../shared/entities/profile";
import {Subscription} from "rxjs";
import {DataService} from "../shared/data.service";
import {IDashboard} from "../shared/entities/dashboard";
declare var $: any;
import * as moment from 'moment';
import {PortalService} from "../shared/portal.service";
import {IDownloadInvoiceRequest, IInvoice} from "../shared/entities/bill-reports";
import {BillingSummaryService} from "../billing-summary/billing-summary.service";
import {FakerService} from "../shared/faker.service";
import {IHelpStep} from "../shared/entities/contextualHelp";
import {IAccount} from "../shared/entities/account";

@Component({
  selector: 'widget-component',
  templateUrl: './widget.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['widget.component.scss']
})
export class WidgetComponent implements OnInit {

  isMobile: boolean = false;
  openedWidget: Widget;
  widgetData: any;
  draggable: boolean = true;
  periodTypes: any[];
  intervalTypes: any[];
  dateTypes: any[];
  dataOptions: any[];
  settingsColumn: string;
  dateDisplay: string;
  tempWidget: any;
  showSites: boolean = false;
  sitesControl: SitesControl;
  systemSiteGroups: ISiteGroup[];
  selectedReport: IComparisonReport;
  userSiteGroups: ISiteGroup[];
  filteredSites: ISite[] = [];
  siteIndex: number = -1;
  maxWidgets: number = 12;
  getSiteGroupsSubscription: Subscription;
  accountSubscription: Subscription;
  sitesForAccountSubscription: Subscription;
  contractsSubscription: Subscription;
  maskModeSubscription: Subscription;
  sitesForAccount: ISite[] = [];
  selectedCustomer: IUserAccount;
  widgetSubtitle: string = '';
  showPlaceholder: boolean = false;
  loading: boolean = false;
  noDataError: boolean = false;
  maskMode: boolean = false;
  helpStep: IHelpStep;

  marketsGroup = {
    groupName: 'MARKETS',
    display: 'Markets'
  };

  @Input()
  widget: Widget;

  @Input()
  i: any;

  @Input()
  myAccount: IUserAccount;

  @Input()
  accountWithContractData: IAccount;

  @Input()
  dashboard: IDashboard;

  @Output()
  remove: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  dropped: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  resized: EventEmitter<any> = new EventEmitter<any>();

  helpStepSubscription: Subscription;

  constructor(private siteGroupsService: SiteGroupService,
              private service: DashboardService,
              private dataService: DataService,
              private portalService: PortalService,
              private billingService: BillingSummaryService,
              private fakerService: FakerService) { }

  ngOnInit() {
    this.isMobile = this.dataService.getIsMobile();
    this.maskMode = this.dataService.getMaskMode();
    this.constructWidget(this.widget);
    this.selectedCustomer = this.dataService.getAccountSource();
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.selectedCustomer = account;
      }
    );

    this.sitesControl = new SitesControl();
    if(this.widget.widgetName != 'INTERVALUSAGE'){
      this.sitesForAccount = this.dataService.getSitesForAccountSource();
      if(this.sitesForAccount){
        this.sitesForAccount.forEach((site) => {
          this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
        });
      }
      this.sitesForAccountSubscription = this.dataService.sitesForAccountSourceUpdated.subscribe(
        (sites) => {
          this.sitesForAccount = sites;
          sites.forEach((site) => {
            this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
          });
        }
      );
    } else {
      this.sitesForAccount = this.dataService.getSitesForAccountSource();
      this.sitesForAccountSubscription = this.dataService.sitesForAccountSourceUpdated.subscribe((sites) => {
        this.sitesForAccount = sites;
      })
    }

    this.setWidgetSettingDisplays(this.widget);
    this.intervalTypes = IntervalUsagePeriods;
    this.dateTypes = DateTypes;
    this.dataOptions = DataOptions;
    this.getSettingsColumn(this.widget);

    let siteGroups = this.dataService.getSiteGroupsSource();
    if(siteGroups){
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.setDisplayDate();
      if(this.widget.widgetId) {
        if(!this.loading){
          this.loading = true;
          this.portalService.spinBabySpin('spinner-' + this.widget.widgetId);
          this.getWidgetData();
        }
      }
    }
    this.getSiteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.systemSiteGroups = siteGroups.systemSiteGroups;
        this.userSiteGroups = siteGroups.userSiteGroups;
        this.setDisplayDate();
        if(this.widget.widgetId) {
          if(!this.loading){
            this.loading = true;
            this.portalService.spinBabySpin('spinner-' + this.widget.widgetId);
            this.getWidgetData();
          }
        }
      }
    );

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.getWidgetSubtitle();
    });

    this.contractsSubscription = this.dataService.contractsForAccountSourceUpdated.subscribe(() => {
      if(this.widget.systemGroupName == 'CONTRACTS') {
        if(this.widget.groupCategory && this.widget.groupCategory.value){
          this.widget.groupCategory = {
            value: this.widget.groupCategory.value,
            display: this.portalService.getContractNickname(this.widget.groupCategory.value)
          }
        } else {
          this.widget.groupCategory = {
            value: this.widget.groupCategory,
            display: this.portalService.getContractNickname(this.widget.groupCategory)
          }
        }
      } else if (this.widget.groupCategory){
        if(this.widget.groupCategory.value){
          this.widget.groupCategory = {
            value: this.widget.groupCategory.value,
            display: this.widget.groupCategory.display
          }
        } else {
          this.widget.groupCategory = {
            value: this.widget.groupCategory,
            display: this.widget.groupCategory
          }
        }
      }
      if(this.widgetData){
        this.getWidgetSubtitle();
      }
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
      this.helpStepUpdated();
    })
  }

  ngOnDestroy() {
    if (this.getSiteGroupsSubscription) this.getSiteGroupsSubscription.unsubscribe();
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.sitesForAccountSubscription) this.sitesForAccountSubscription.unsubscribe();
    if (this.contractsSubscription) this.contractsSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  getSiteIdDisplay(siteId: string) {
    return this.portalService.getSiteIdDisplay(siteId);
  }

  helpStepUpdated() {
    let helpWidgetId;
    for(let i = 0; i < this.dashboard.widgets.length; i++){
      if(this.dashboard.widgets[i].widgetName == 'BILLINGSUMMARY'){
        helpWidgetId = this.dashboard.widgets[i].widgetId;
        break;
      }
    }
    if(this.widget.widgetId != helpWidgetId){
      this.helpStep = null;
    }
    if(this.helpStep){
      if (this.helpStep.stepNum == 3){
        this.openWidget(this.widget);
        this.showSites = true;
        this.sitesControl.siteRequest = 'GROUP_CATEGORY';
      } else {
        this.showSites = false;
        this.closeWidget(this.widget);
      }
    }
  }

  constructWidget(widget) {
    if(widget.widgetName == WidgetType.BillingSummary){
      this.widget = new BillingSummaryWidget(widget);
      this.tempWidget = new BillingSummaryWidget(widget);
      this.periodTypes = SummaryPeriods;
    } else if (widget.widgetName == WidgetType.IntervalUsage){
      this.widget = new IntervalUsageWidget(widget);
      this.tempWidget = new IntervalUsageWidget(widget);
      this.periodTypes = IntervalUsagePeriods;
    }
    // else if (widget.widgetName == WidgetType.HelpTopics){
    //   this.widget = new HelpTopicsWidget(widget);
    //   this.tempWidget = new HelpTopicsWidget(widget);
    // }
    else if (widget.widgetName == WidgetType.RecentInvoices){
      this.widget = new RecentInvoicesWidget(widget);
      this.tempWidget = new RecentInvoicesWidget(widget);
    } else if (widget.widgetName == WidgetType.UsageSummary){
      this.widget = new UsageSummaryWidget(widget);
      this.tempWidget = new UsageSummaryWidget(widget);
      this.periodTypes = SummaryPeriods;
    }
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeAddress(site: ISite){
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getSettingsColumn(widget: Widget) {
    if(widget.width === 2) {
      this.settingsColumn = 'col-sm-3';
    } else {
      this.settingsColumn = 'col-sm-6'
    }
  }

  getColumnClass(widget: Widget) {
    if(widget.width === 1) {
      return 'col-sm-4';
    } else {
      return 'col-sm-8';
    }
  }

  setWidgetSettingDisplays(widget: Widget) {

    SummaryPeriods.forEach((period) => {
      if(period.value == widget.periodType) widget.periodType = period;
    });

    DateTypes.forEach((dateType) => {
      if(dateType.value == widget.dateType) widget.dateType = dateType;
    });

    DataOptions.forEach((dataOption) => {
      if(dataOption.value == widget.billingDataType) widget.billingDataType = dataOption;
    });

    IntervalUsagePeriods.forEach((intervalType) => {
      if(intervalType.value == widget.periodType) widget.periodType = intervalType;
    });

  }

  setDisplayDate() {
    this.dateDisplay = this.widget.getDisplayDate(this.systemSiteGroups, this.userSiteGroups, this.maskMode);
  }

  openWidget(widget: Widget) : void {
  	this.openedWidget = widget;
    this.tempWidget = Object.assign({}, widget);
    this.getSiteControlDisplay();
  }

  closeWidget(widget: Widget) : void {
  	this.openedWidget = null;
    this.tempWidget = Object.assign({}, widget);
  }

  saveWidget(resized) : void {
    this.portalService.spinBabySpin('spinner-' + this.widget.widgetId);
    if(!resized) {
      this.widgetData = null;
      this.loading = true;
      this.widget = Object.assign({}, this.tempWidget);
    }
    let serverWidget = this.prepareServerWidget(this.widget);
    let request = serverWidget;
    request.widgetIds = this.dashboard.widgetIds;

    this.service.addOrUpdateWidget(request).subscribe(
      (widget : any) => {
        this.openedWidget = null;
        this.setWidgetSettingDisplays(widget);
        this.constructWidget(widget);
        this.setDisplayDate();
        if(resized) {
          this.resized.emit();
        } else {
          this.getWidgetData(true);
        }
      }
    );
  }

  // There is therefore now no condemnation for those who are in Christ Jesus. - Romans 8:1

  getWidgetData( changeMade? : boolean) {
    this.noDataError = false;
    const serverWidget = this.prepareServerWidget(this.widget);
    const request = serverWidget;
    if (this.dataService.getAccountSource().id === this.selectedCustomer.id && this.selectedCustomer.id === request.accountId) {
      request.widgetIds = this.dashboard.widgetIds;
      if (this.myAccount.accessType !== 'ThirdParty') {
        this.service.getWidgetData(request, changeMade).subscribe(
          (widgetData) => {
            this.loading = false;
            this.widgetData = widgetData;
            if (this.dataService.getContractsForAccount()) {
              if (this.widget.systemGroupName === 'CONTRACTS') {
                if (this.widget.groupCategory.value) {
                  this.widget.groupCategory = {
                    value: this.widget.groupCategory.value,
                    display: this.portalService.getContractNickname(this.widget.groupCategory.value)
                  };
                } else {
                  this.widget.groupCategory = {
                    value: this.widget.groupCategory,
                    display: this.portalService.getContractNickname(this.widget.groupCategory)
                  };
                }

              } else if (this.widget.groupCategory) {
                if (this.widget.groupCategory.value) {
                  this.widget.groupCategory = {
                    value: this.widget.groupCategory.value,
                    display: this.widget.groupCategory.display
                  };
                } else {
                  this.widget.groupCategory = {
                    value: this.widget.groupCategory,
                    display: this.widget.groupCategory
                  };
                }
              }
              this.getWidgetSubtitle();
            }
            this.noDataError = !this.widget.buildWidget(widgetData);
          }, err => {
            if (err.status === 404) {
              this.widgetData = null;
              this.noDataError = true;
            }
            this.loading = false;
          }
        );
      }
    }
  }

  getDateDisplay(dateString: string) {
    return moment(dateString).format('MM/DD/YY');
  }

  numberWithCommas(x: number) { return this.portalService.numberWithCommas(x.toFixed(2)); }

  downloadInvoice(invoice: IInvoice) {
    this.dataService.setLoading(true);
    let request: IDownloadInvoiceRequest = {
      accountId: this.myAccount.id,
      contractAccountNumber: invoice.contractAccountNumber,
      collectiveAccountId: this.maskMode ? null : invoice.collectiveAccountId,
      collectiveInvoiceNo: this.maskMode ? null : invoice.invoiceNo.toString(),
      siteId: invoice.siteId,
      invoiceNo: this.maskMode ? '9999999999990' : invoice.invoiceNo,
      billingPeriod: invoice.billingDate.substr(0,7).replace("-",""),
      repOwnerId: invoice.repOwnerId
    };
    if(this.dataService.getIsMobile()){
      this.billingService.openMobileInvoice(request);
    } else {
      this.billingService.downloadInvoice(request);
    }
  }

  removeWidget(widget: Widget) : void {
    this.remove.emit(widget);
  }

  changeSetting(value: any, setting: string) {
    this.tempWidget[setting] = value;
  }

  resize($event) {
    let vm = this;
    let x = $event.clientX;
    let y = $event.clientY;
    let elem = $event.target;
    let col = $(elem).closest('[class*="col-sm-"]');
    let chart = $(elem).closest('[id*="chart-"]');
    let percentWidth = vm.getWidth(col);
    let closestWidth = vm.getClosestWidth(percentWidth, col);;

    col.removeAttr('draggable', 'false');

    $(window).mousemove(function(e){
      percentWidth = vm.getWidth(col);
      let width = col.width();
      closestWidth = vm.getClosestWidth(percentWidth, col);

      if(e.clientX < x) {
          col.width(width - (x - e.clientX));
      }
      if(e.clientX > x) {

          col.width(width + (e.clientX - x));
      }
      x = e.clientX;
    });
    $(window).mouseup(() =>{
    // $(col).mouseleave(() =>{
      col.css('width',  '');

      if(closestWidth === 33.33333333) {
        this.widget.width = 1;
      } else {
        this.widget.width = 2;
      }

      setInterval(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);

      vm.setDisplayDate();
      vm.getSettingsColumn(this.widget);

      $(window).unbind('mousemove');

      vm.saveWidget(true);
      $(window).unbind('mouseup');
      col.attr('draggable', 'true');
    });
  }


  getClosestWidth(width, col) {
    let values = [33.33333333, 66.66666667];
    let minDiff = Math.abs(width - values[0]);
    let closest = 0; //index of the current closest number

    for (let i = 1; i < values.length; i++) {
      let currentDiff = Math.abs(width - values[i]);
      if (currentDiff < minDiff) {
        minDiff = currentDiff;
        closest = i;
      }
    }
    return values[closest];
  }

  getWidth(col) {
    let width = col.width();
    let parentWidth = col.closest('.container-fluid').width();
    let percent = 100*width/parentWidth;
    return percent;
  }

  onDropSuccess() {
    this.dropped.emit();
  }

  toggleSites() {
    this.showSites = !this.showSites;
    if(this.showSites) {
      this.populateSitesControl();
    } else {
      if(this.sitesControl.selectedSite){
        if(!this.maskMode) {
          this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
        } else {
          this.sitesControl.siteSearch = this.getFakeSiteId(this.sitesControl.selectedSite) + ' ' + this.fakerService.getCompanyName();
        }
      } else {
        this.sitesControl.siteSearch = '';
      }
    }
  }

  populateSitesControl() {
    // Load data from selectedReport into customerControl
    this.sitesControl.siteRequest = this.tempWidget.siteRequest;
    if(this.sitesControl.siteRequest == 'GROUP_CATEGORY'){
      this.systemSiteGroups.forEach((systemGroup) => {
        if(systemGroup.groupName == this.tempWidget.systemGroupName){
          this.selectGroup(systemGroup);
        }
      });
      this.userSiteGroups.forEach((userGroup) => {
        if(userGroup.id == this.tempWidget.groupId){
          this.selectGroup(userGroup);
        }
      });
      this.sitesControl.selectedCategory = this.tempWidget.groupCategory;
    } else if(this.sitesControl.siteRequest == 'SPECIFIC_SITE'){
      this.filteredSites.forEach((site, index) => {
        if(site.siteId == this.tempWidget.siteId){
          this.selectSite(site, index);
        }
      })
    }
  }

  selectGroup(group: ISiteGroup){
    this.sitesControl.selectedGrouping = group;
    if(this.sitesControl.selectedGrouping.id) {
      if(this.sitesControl.selectedGrouping.groupName.length > 15){
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName.substring(0,15) + '...'
      } else {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName;
      }
      this.siteGroupsService.getCategoriesForUserGroup(this.sitesControl.selectedGrouping.id).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = [];
          let categoryForGroup;
          categories.forEach((category) => {
            categoryForGroup = {
              value: category,
              display: category
            };
            this.sitesControl.categoriesForGroup.push(categoryForGroup);
          });
        }
      )
    } else{
      let startDate, endDate;
      if(this.sitesControl.selectedGrouping.groupName == 'CONTRACTS') {
        startDate = moment().subtract(13, 'months').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
      }
      this.siteGroupsService.getCategoriesForSystemGroup(this.sitesControl.selectedGrouping.groupName, startDate, endDate).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = [];
          let categoryForGroup = {};
          if(this.sitesControl.selectedGrouping.display == 'Contracts'){
            categories.forEach((category) => {
              categoryForGroup = {
                value: category,
                display: this.portalService.getContractNickname(category),
              };
              this.sitesControl.categoriesForGroup.push(categoryForGroup);
            });
          } else {
            categories.forEach((category) => {
              categoryForGroup = {
                value: category,
                display: category
              };
              this.sitesControl.categoriesForGroup.push(categoryForGroup);
            });
          }
        }
      )
    }
    this.sitesControl.selectedCategory = null;
  }

  selectCategory(category: any){
    this.sitesControl.selectedCategory = category;
  }

  selectSite(site: ISite, index?: number){
    if(this.filteredSites.length > 0){
      if(this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if(index) {
        this.sitesControl.selectedSite = this.filteredSites[index];
      } else {
        this.sitesControl.selectedSite = this.filteredSites[this.siteIndex];
      }
      if(!this.maskMode) {
        this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
      } else {
        this.sitesControl.siteSearch = this.getFakeSiteId(site) + ' ' + this.fakerService.getCompanyName();
      }
      this.resetTypeahead();
    }
  }

  searchSites() {
    this.filteredSites = [];
    this.sitesForAccount.forEach((site) => {
      if(site.siteId.match(this.sitesControl.siteSearch) ||
        (site.serviceAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
        this.sitesControl.siteSearch == ''){
        this.filteredSites.push(site);
      }
    });
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  applySites() {
    // Apply changes in customerControl to selectedReport
    this.sitesControl.categoryError = false;
    this.tempWidget.siteRequest = this.sitesControl.siteRequest;
    if(this.tempWidget.siteRequest == 'ALL_SITES'){
      this.tempWidget.siteId = this.tempWidget.systemGroupName = this.tempWidget.groupId = this.tempWidget.groupCategory = null
    } else if(this.tempWidget.siteRequest == 'GROUP_CATEGORY'){
      if(!this.sitesControl.selectedCategory){
        this.sitesControl.categoryError = true;
      }
      this.tempWidget.groupCategory = this.sitesControl.selectedCategory;
      if(this.sitesControl.selectedGrouping.id){
        this.tempWidget.groupId = this.sitesControl.selectedGrouping.id;
        this.tempWidget.systemGroupName = null;
      } else {
        this.tempWidget.systemGroupName = this.sitesControl.selectedGrouping.groupName;
        this.tempWidget.groupId = null;
      }
    } else if(this.tempWidget.siteRequest == 'SPECIFIC_SITE'){
      this.tempWidget.siteId = this.sitesControl.selectedSite.siteId;
    }
    if(!this.sitesControl.categoryError){
      this.toggleSites();
      this.getSiteControlDisplay();
    }
  }

  getSiteControlDisplay() {
    if(this.tempWidget.siteRequest == 'ALL_SITES') {
      this.sitesControl.display = 'All Sites';
    } else if(this.tempWidget.siteRequest == 'GROUP_CATEGORY'){
      this.sitesControl.display = '';
      if(this.tempWidget.systemGroupName) {
        this.systemSiteGroups.forEach((systemGroup) => {
          if(systemGroup.groupName == this.tempWidget.systemGroupName) {
            this.sitesControl.display += systemGroup.display;
          }
        });
      } else {
        this.userSiteGroups.forEach((siteGroup) => {
          if(siteGroup.id == this.tempWidget.groupId) {
            this.sitesControl.display += siteGroup.groupName;
          }
        });
      }
      if(this.sitesControl.selectedGrouping.groupName == 'COLLECTIVE_BILLING' && this.maskMode){
        this.sitesControl.display += ': 80012345';
      } else {
        this.sitesControl.display += ': ' + this.tempWidget.groupCategory.display;
      }
    } else if(this.tempWidget.siteRequest == 'SPECIFIC_SITE'){
      if(!this.maskMode){
        this.sitesControl.display = '...' + this.tempWidget.siteId.substr(5);
      } else {
        this.sitesControl.display = '...' + this.fakerService.getSiteId().substr(5);
      }
    }
  }

  getWidgetSubtitle() {
    if(this.widget.siteRequest == 'ALL_SITES') {
        this.widgetSubtitle = 'All Sites (' + this.widgetData.siteCount + ')';
    } else if(this.widget.siteRequest == 'GROUP_CATEGORY'){
      this.widgetSubtitle = '';
      if(this.widget.systemGroupName) {
        this.systemSiteGroups.forEach((systemGroup) => {
          if(systemGroup.groupName == this.widget.systemGroupName) {
            this.widgetSubtitle += systemGroup.display;
          }
        });
      } else {
        this.userSiteGroups.forEach((siteGroup) => {
          if(siteGroup.id == this.widget.groupId) {
            this.widgetSubtitle += siteGroup.groupName;
          }
        });
      }

      if (this.maskMode && this.widgetSubtitle.toLowerCase().match('contracts')) {
        this.widgetSubtitle += ': ' + this.fakerService.getContractNum() + ' (' + this.widgetData.siteCount + ')';
      } else if(this.maskMode && this.widgetSubtitle.toLowerCase().match('collective')){
        this.widgetSubtitle += ': 80012345 (' + this.widgetData.siteCount + ')';
      } else {
        this.widgetSubtitle += ': ' + this.widget.groupCategory.display + ' (' + this.widgetData.siteCount + ')';
      }
    } else if(this.widget.siteRequest == 'SPECIFIC_SITE') {
      this.sitesForAccount.forEach((site) => {
        if(this.widget.siteId == site.siteId) {
          if(!this.maskMode){
            this.widgetSubtitle = 'Site: ' + site.serviceAddress.streetAddress + ' (...' + site.siteId.substr(10) + ')';
          } else {
            this.widgetSubtitle = 'Site: ' + this.getFakeAddress(site) + ' (...' + this.getFakeSiteId(site).substr(10) + ')';
          }
        }
      });
    }
  }

  prepareServerWidget(widget: any) {
    let serverWidget: any = {};

    serverWidget.widgetId = widget.widgetId ? widget.widgetId : null;
    serverWidget.widgetName = widget.widgetName;
    serverWidget.width = widget.width;
    serverWidget.height = widget.height;
    serverWidget.accountId = widget.accountId;
    serverWidget.periodType = widget.periodType ? widget.periodType.value : null;
    serverWidget.dateType = widget.dateType ? widget.dateType.value : null;
    serverWidget.billingDataType = widget.billingDataType? widget.billingDataType.value : null;
    serverWidget.siteRequest = widget.siteRequest ? widget.siteRequest : null;
    serverWidget.systemGroupName = widget.systemGroupName ? widget.systemGroupName : null;
    serverWidget.groupCategory = widget.groupCategory ? (widget.groupCategory.value ? widget.groupCategory.value : widget.groupCategory) : null;
    serverWidget.siteId = widget.siteId ? widget.siteId : null;
    serverWidget.groupId = widget.groupId ? widget.groupId : null;

    return serverWidget;
  }

  radioChanged(siteRequest: string){
    this.sitesControl.siteRequest = siteRequest;
    if(siteRequest != 'GROUP_CATEGORY'){
      this.sitesControl.selectedCategory = null;
      this.sitesControl.selectedGrouping = null;
    }
    if(siteRequest != 'SPECIFIC_SITE') {
      this.sitesControl.siteSearch = '';
    }
  }

}
