import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FadeAnimation } from "../../animations/fadeAnimation";
import { DataService } from '../../shared/data.service';
import { MarketsService } from '../markets.service';
import { HttpHeaders } from '@angular/common/http';
import { PortalService } from '../../shared/portal.service';
import { IContract } from '../../shared/entities/contract';
import { Subscription } from 'rxjs';
import { ForwardPriceForm } from '../forward-price-form/forward-price.form';
import {IProduct, IZone} from '../../shared/entities/product';

import * as moment from 'moment-timezone';
import { IReportSettings } from '../../shared/entities/markets';

@Component({
  selector: 'app-renewal-analysis',
  templateUrl: './forward-pricing-renewal.component.html',
  animations: [ FadeAnimation ],
  styleUrls: [
    '../../shared/styles/reports.scss',
    './forward-pricing-renewal.component.scss'
  ]
})
export class ForwardPricingRenewalComponent implements OnInit {

  @Output() viewReport: EventEmitter<ForwardPriceForm> = new EventEmitter();

  contractSubscription: Subscription;
  maskModeSubscription: Subscription;

  // Form Data
  public productsRadio: string;
  forwardPriceForm: ForwardPriceForm =  new ForwardPriceForm(this.portalService, this.marketsService, this.dataService);

  // Service Data
  priceData: any[];
  dateModified: string = "";
  maskMode: boolean;
  contracts: IContract[];

  headers: HttpHeaders;

  contractFilter = (contract: IContract) => {
    return contract.commodity==true && contract.contractType !== 'Transition' && moment(contract.deliveryEndDate).isAfter(moment().subtract(6, 'months')) && contract.products.length > 0;
  }

  contractSorter = function mySorter(val1, val2)  {
    if(val1.deliveryEndDate == val2.deliveryEndDate){
      return (val1.deliveryStartDate < val2.deliveryStartDate) ? 1 : -1;
    }else{
      return (val1.deliveryEndDate < val2.deliveryEndDate) ? 1 : -1;
    }
  };

  mapRecentProducts = (contract: IContract) => {
    const newContract = {...contract} as IContract;
    newContract.products = contract.products.filter(product => moment(product.deliveryEndDate).isAfter(moment().subtract(6, 'months')));
    return newContract;
  }

  constructor(private dataService: DataService,
    private marketsService: MarketsService,
    private portalService: PortalService) { }

  ngOnInit() {
    this.productsRadio = '';
    this.forwardPriceForm.ngOnInit();
    this.forwardPriceForm.accountId = this.dataService.getAccountSource().id;
    this.forwardPriceForm.userId = this.dataService.getUserSource().email;
    this.forwardPriceForm.custom = false;
    this.dataService.setOutputPage(false);

    if (this.dataService.getContractsForAccount()) {
      this.contracts = this.dataService.getContractsForAccount()
        .sort(this.contractSorter)
        .map(this.mapRecentProducts)
        .filter(this.contractFilter);
      if (this.contracts && this.contracts.length) {
        this.selectContract(this.contracts[0]);
      }
    }
    this.contractSubscription = this.dataService.contractsForAccountSourceUpdated.subscribe(contracts => {
      this.contracts = contracts.sort(this.contractSorter).map(this.mapRecentProducts).filter(this.contractFilter);
      if (this.contracts && this.contracts.length) {
        this.selectContract(this.contracts[0]);
      }
    });

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
  }

  ngOnDestroy() {
    if (this.contractSubscription) this.contractSubscription.unsubscribe();
  }

  public selectProduct(product: IProduct, chartContainer: any){
    if(this.getZoneDisplay(product.congestionZones) === '--') {
      return null;
    }
    const date = moment(product.deliveryEndDate).add(1, 'months').startOf('month');
    this.productsRadio = product.id;
    this.forwardPriceForm.selectedProduct = product;
    this.forwardPriceForm.selectedStartDate = date.toDate();
    this.forwardPriceForm.selectedStartDateDisplay = date.format('MM/DD/YYYY');
    
    this.forwardPriceForm.selectZone({name: this.getZoneDisplay(product.congestionZones), value: 'MULTIPLE'});
    this.forwardPriceForm.startDateSelected(moment( this.forwardPriceForm.selectedStartDate).toDate());
    this.forwardPriceForm.refreshChart(chartContainer);

  }

  public selectContract(contract: IContract) {
    if (contract.products.length && contract.products[0].siteCount === undefined) {
      this.portalService.populateProductDetails(contract);
    }
    this.forwardPriceForm.initForm();
    this.forwardPriceForm.selectedContract = contract;
    this.forwardPriceForm.selectedProduct = null;
    this.forwardPriceForm.updateSelectedStartDate(moment(contract.deliveryEndDate).add(1, 'day').toDate());
    this.forwardPriceForm.selectMarket({value: contract.market});
  }


  createReport() {
    this.viewReport.emit(this.forwardPriceForm);
  }

  getZoneDisplay(congestionZones: IZone[]) {
    return ForwardPriceForm.getZoneDisplay(this.forwardPriceForm.selectedMarket.value, congestionZones);
  }
}
