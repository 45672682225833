import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { FadeAnimation } from '../animations/fadeAnimation';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import { DateType } from '../shared/const/dateType';
import { IUserAccount } from '../shared/entities/profile';
import { ISiteGroup, ISite } from '../shared/entities/site';
import { BillingComparisonService } from './billing-comparison.service';
import { IComparisonReport, IComparisonChartResponse } from '../shared/entities/comparison';
import { IBillingComparisonChartData } from '../shared/entities/billingComparison';
import { SitesControl } from '../shared/entities/controls';
import { SiteGroupService } from '../site-groups/site-groups.service';
import { PortalService } from '../shared/portal.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
declare var Highcharts: any;
import { ISiteCounts } from '../shared/entities/usageGraph';
import { FakerService } from '../shared/faker.service';
import { IHelpStep } from '../shared/entities/contextualHelp';
declare var $: any;

@Component({
  selector: 'app-billing-comparison',
  templateUrl: './billing-comparison.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['billing-comparison.component.scss', '../shared/styles/reports.scss']
})
export class BillingComparisonComponent implements OnInit, OnDestroy {

  reports: IComparisonReport[];
  publicReports: IComparisonReport[];
  privateReports: IComparisonReport[];
  selectedReport: IComparisonReport;
  selectedCustomer: IUserAccount;
  userSiteGroups: ISiteGroup[];
  systemSiteGroups: ISiteGroup[];
  sitesForAccount: ISite[] = [];
  filteredSites: ISite[] = [];
  siteCounts: ISiteCounts;

  sitesControl: SitesControl;

  showDateType = false;
  showYears = false;
  showSites = false;
  enableSave = false;
  makeDefault = true;
  makePublic = false;
  makeNewPublic = false;
  makeNewDefault = true;
  noDataError = false;
  isMobile = false;
  maskMode = false;
  showSave = false;
  showReports = false;
  showSettings = false;

  selectedSiteDescription: string;
  selectedDateType: any;
  selectedGrouping: ISiteGroup;
  siteIndex: number = -1;

  helpStep: IHelpStep;

  DateType;

  newReportName: string;
  tempYear1: number;
  tempYear2: number;
  years: number[];

  chartData: IBillingComparisonChartData = {
    totalChargesYear1: [],
    totalChargesYear2: [],
    totalChargesDelta: [],
    usageYear1: [],
    usageYear2: [],
    usageDelta: [],
    siteCountYear1: [],
    siteCountYear2: [],
    siteCountDelta: []
  };

  tableColumns = ['Year', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  accountSourceSubscription: Subscription;
  getSiteGroupsSubscription: Subscription;
  siteCountsSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;



  markets: string[];
  msgDisplay = false;

  constructor(private dataService: DataService,
    private service: BillingComparisonService,
    private siteGroupsService: SiteGroupService,
    private portalService: PortalService,
    private fakerService: FakerService) { }

  ngOnInit() {

    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        numericSymbols: null
      }
    });
    this.sitesControl = new SitesControl();
    this.setCurrentActivePage('billing')
    this.dataService.setTitleSource('Billing Comparison Report');
    this.selectedCustomer = this.dataService.getAccountSource();
    this.maskMode = this.dataService.getMaskMode();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.dataService.setLoading(true);
        this.selectedCustomer = account;
        this.markets = this.dataService.getMarketArrayForAccountSource();
        // console.log("Inside this.market: ", this.markets);
      }
    );


    this.markets = this.dataService.getMarketArrayForAccountSource();
    // console.log("Outside this.market: ", this.markets);

    this.markets && this.markets.forEach(
      (market) => {
        if (market == 'NEISO' || market == 'NYISO') {
          this.msgDisplay = true;
        }
      }
    );

    const siteGroups = this.dataService.getSiteGroupsSource();
    if (siteGroups) {
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.loadSiteOptions();
      this.loadReports();
    }
    this.getSiteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.systemSiteGroups = siteGroups.systemSiteGroups;
        this.userSiteGroups = siteGroups.userSiteGroups;
        this.loadSiteOptions();
        this.loadReports();
      }
    );

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.siteCounts = this.dataService.getSiteCountsSource();
    this.siteCountsSubscription = this.dataService.siteCountSourceUpdated.subscribe(
      (siteCounts) => {
        this.siteCounts = siteCounts;
      }
    );
    this.DateType = DateType;

    this.isMobile = this.dataService.getIsMobile();

    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#sitesSection').length == 0) {
        this.showSites = false;
      }
      if ($($event.target).parents('#yearsSection').length == 0) {
        this.showYears = false;
      }
      if ($($event.target).parents('#optionsSection').length == 0) {
        this.showSettings = false;
      }
      if ($($event.target).parents('#reportsSection').length == 0) {
        this.showReports = false;
      }
      if ($($event.target).parents('#saveSection').length == 0) {
        this.showSave = false;
      }
      if ($($event.target).parents('#dateTypeSection').length == 0) {
        this.showDateType = false;
      }
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.getSiteGroupsSubscription) this.getSiteGroupsSubscription.unsubscribe();
    if (this.siteCountsSubscription) this.siteCountsSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  loadReports(keepSelected?: boolean) {
    if (!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.years = [moment().year(), moment().year() - 1, moment().year() - 2, moment().year() - 3];

    this.service.getReports(this.selectedCustomer.id).subscribe(
      (reports) => {
        this.reports = reports;
        let defaultReport = null;
        if (!keepSelected) {
          if (this.reports.length > 0) {
            this.reports.forEach((report) => {
              if (report.defaultReport) {
                defaultReport = report;
              }
            });
            if (!defaultReport) {
              this.selectReport(this.reports[0]);
            } else {
              this.selectReport(defaultReport);
            }
          } else {
            defaultReport = this.getDefaultReport();
            this.selectReport(defaultReport);
            this.enableSave = true;
            this.saveReport();
          }
        }
        this.publicReports = [];
        this.privateReports = [];
        this.reports.forEach((report) => {
          if (report.publicFlag) {
            this.publicReports.push(report);
          } else {
            this.privateReports.push(report);
          }
        });
      }
    );
  }

  toggleSave() {
    this.showSave = !this.showSave;
  }

  toggleReports() {
    this.showReports = !this.showReports;
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  renameReport() {
    this.selectedReport.reportName = this.newReportName;
    this.enableSave = true;
    this.saveReport();
    $('#renameModal').modal('hide');
  }

  createNewReport() {
    const newReport = this.getDefaultReport();
    newReport.reportName = this.newReportName;
    this.makePublic = this.makeNewPublic;
    this.makeDefault = this.makeNewDefault;
    this.enableSave = true;
    this.selectedReport = newReport;
    this.saveReport();
    $('#createReportModal').modal('hide');
  }

  openCreateNewReport() {
    this.makeNewDefault = false;
    this.makeNewPublic = false;
    this.newReportName = '';
  }

  goPublic() {
    this.toggleSettings();
    this.makePublic = true;
    this.enableSave = true;
    this.saveReport();
  }

  duplicateAndSave() {
    this.makePublic = false;
    this.makeDefault = false;
    this.newReportName = '';
    $('#renameModal').modal('show');
  }

  getDefaultReport() {
    const defaultReport: IComparisonReport = {
      accountId: this.selectedCustomer.id,
      reportName: 'Comparison Report 01',
      dateType: this.DateType.MeterReadEndDate.value,
      year1: this.years[0],
      year2: this.years[1],
      siteRequest: 'ALL_SITES',
      defaultReport: 0,
      publicFlag: 0,
      createdUserId: this.dataService.getUserSource().login
    };
    return defaultReport;
  }

  saveNewReport() {
    this.selectedReport.reportId = null;
    $('#renameModal').modal('show');
    this.toggleSave();
  }

  setAsDefault() {
    this.toggleSettings();
    this.makeDefault = true;
    this.enableSave = true;
    this.saveReport();
  }

  loadSiteOptions() {
    /*this.siteGroupsService.getSitesForSystemGroup(this.selectedCustomer.id, 'ALL_SITES').subscribe(
      (sites) => {
        this.sitesForAccount = sites;
        sites.forEach((site) => {
          this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
        });
      }
    );*/
    this.sitesForAccount = this.dataService.getSitesForAccountSource();
    this.sitesForAccount.forEach((site) => {
      this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
    });
  }

  selectReport(report: IComparisonReport) {
    if (!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.selectedReport = report;
    if (this.selectedReport.dateType == this.DateType.MeterReadEndDate.value) {
      this.selectedDateType = this.DateType.MeterReadEndDate;
    } else {
      this.selectedDateType = this.DateType.BillingDate;
    }
    this.makeDefault = this.selectedReport.defaultReport == 1;
    this.makePublic = this.selectedReport.publicFlag == 1;
    this.getSiteControlDisplay();
    this.createCharts();
  }

  getSiteControlDisplay() {
    if (this.selectedReport.siteRequest == 'ALL_SITES') {
      this.sitesControl.display = 'All Sites';
    } else if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
      if (this.selectedReport.systemGroupName == 'CONTRACTS') {
        this.sitesControl.display = this.portalService.getContractNickname(this.selectedReport.siteGroupCategory);
      } else if (this.selectedReport.systemGroupName == 'COLLECTIVE_BILLING') {
        this.sitesControl.display = '80012345';
      } else {
        this.sitesControl.display = this.selectedReport.siteGroupCategory;
      }
    } else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
      if (!this.maskMode) {
        this.sitesControl.display = '...' + this.selectedReport.siteId.substr(5);
      } else {
        this.sitesControl.display = '...' + this.fakerService.getSiteId().substr(5);
      }
    }
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  populateSitesControl() {
    // Load data from selectedReport into customerControl
    this.sitesControl.siteRequest = this.selectedReport.siteRequest;
    if (this.sitesControl.siteRequest == 'GROUP_CATEGORY') {
      this.systemSiteGroups.forEach((systemGroup) => {
        if (systemGroup.groupName == this.selectedReport.systemGroupName) {
          this.selectGroup(systemGroup);
        }
      });
      this.userSiteGroups.forEach((userGroup) => {
        if (userGroup.id == this.selectedReport.groupId) {
          this.selectGroup(userGroup);
        }
      });
      this.sitesControl.selectedCategory = this.selectedReport.siteGroupCategory;
    } else if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      this.filteredSites.forEach((site, index) => {
        if (site.siteId == this.selectedReport.siteId) {
          this.selectSite(site, index);
        }
      });
    }
  }

  saveReport() {
    if (this.enableSave) {
      this.dataService.setLoading(true);
      this.selectedReport.dateType = this.selectedDateType.value;
      this.selectedReport.defaultReport = this.makeDefault ? 1 : 0;
      this.selectedReport.publicFlag = this.makePublic ? 1 : 0;
      this.service.saveReport(this.selectedReport).subscribe((savedReport) => {
        this.selectedReport = savedReport;
        // console.log('publicFlag is ' + this.selectedReport.publicFlag);
        this.enableSave = false;
        this.loadReports(true);
        this.makeNewPublic = this.makeNewDefault = false;
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 1000);
      }, err => {
        if (err.status == 409) {
          this.selectedReport.publicFlag = 0;
          this.dataService.setLoading(false);
          $('#duplicateModal').modal('show');
        }
      });
    }
  }

  deleteReport() {
    this.dataService.setLoading(true);
    this.selectedReport.dateType = this.selectedReport.dateType.value;
    this.service.deleteReport(this.selectedReport).subscribe(() => {
      this.loadReports();
      setTimeout(() => {
        this.dataService.setLoading(false);
      }, 1000);
    });
  }

  selectGroup(group: ISiteGroup) {
    this.sitesControl.selectedGrouping = group;
    if (this.sitesControl.selectedGrouping.id) {
      if (this.sitesControl.selectedGrouping.groupName.length > 15) {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName.substring(0, 15) + '...';
      } else {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName;
      }
      this.siteGroupsService.getCategoriesForUserGroup(this.sitesControl.selectedGrouping.id).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      );
    } else {
      this.siteGroupsService.getCategoriesForSystemGroup(this.sitesControl.selectedGrouping.groupName).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      );
    }
    this.sitesControl.selectedCategory = null;
  }

  getContractNickname(contractNum: string) {
    return this.portalService.getContractNickname(contractNum);
  }

  selectCategory(category: string) {
    this.sitesControl.selectedCategory = category;
  }

  getCategoryDisplay() {
    if (this.sitesControl.selectedCategory) {
      if (this.sitesControl.selectedGrouping.groupName == 'COLLECTIVE_BILLING' && this.maskMode) {
        return '80012345';
      }
      if (this.sitesControl.selectedGrouping.display == 'Contracts') {
        return this.getContractNickname(this.sitesControl.selectedCategory);
      } else {
        return this.sitesControl.selectedCategory;
      }
    } else {
      return 'Select a category';
    }
  }

  defaultChanged() {
    if (this.makeDefault && this.selectedReport.defaultReport || !this.makeDefault && !this.selectedReport.defaultReport) {
      this.enableSave = false;
    } else {
      this.enableSave = true;
    }
  }

  publicChanged() {
    if (this.makePublic && this.selectedReport.publicFlag || !this.makePublic && !this.selectedReport.publicFlag) {
      this.enableSave = false;
    } else {
      this.enableSave = true;
    }
  }

  createCharts() {
    this.dataService.setLoading(true);
    this.service.getCharts(this.selectedReport).subscribe(
      (chartResponse) => {
        if (chartResponse && chartResponse.year1Values.length == 0 && chartResponse.year2Values.length == 0) {
          //empty response
          this.noDataError = true;
        } else {
          this.extractChartData(chartResponse);
          this.noDataError = false;
          this.createChargesChart();
          this.createUsageChart();
          if (this.selectedReport.siteRequest != 'SPECIFIC_SITE') {
            this.createSitesChart();
          }
        }
        this.dataService.setLoading(false);
      }, err => {
        if (err.status == 404 || err.status == 500) {
          // console.log('no data billing comparison');
          this.chartData = null;
          this.noDataError = true;
        }
      }
    );
  }

  extractChartData(chartResponse: IComparisonChartResponse) {
    let currentMonth = 1;
    const emptyVal = 0;
    this.chartData.totalChargesYear1 = [];
    this.chartData.totalChargesYear1Partial = false;
    this.chartData.siteCountYear1 = [];
    this.chartData.usageYear1 = [];
    this.chartData.usageYear1Partial = false;

    if (chartResponse.year1Values) {
      chartResponse.year1Values.sort((val1, val2) => {
        return val1.month < val2.month ? -1 : 1;
      });
      chartResponse.year1Values.forEach((item) => {
        if (currentMonth < item.month) {
          while (currentMonth < item.month) {
            this.chartData.totalChargesYear1.push(null);
            this.chartData.siteCountYear1.push(null);
            this.chartData.usageYear1.push(null);
            currentMonth++;
          }
        }
        currentMonth++;
        this.chartData.totalChargesYear1.push(item.totalCharges);
        this.chartData.siteCountYear1.push(item.siteCount);
        this.chartData.usageYear1.push(item.usage);
      });
    }

    while (this.chartData.totalChargesYear1.length < 12) {
      this.chartData.totalChargesYear1Partial = true;
      this.chartData.totalChargesYear1.push(null);
    }
    while (this.chartData.siteCountYear1.length < 12) {
      this.chartData.siteCountYear1.push(null);
    }
    while (this.chartData.usageYear1.length < 12) {
      this.chartData.usageYear1Partial = true;
      this.chartData.usageYear1.push(null);
    }

    this.chartData.totalChargesYear2 = [];
    this.chartData.totalChargesYear2Partial = false;
    this.chartData.siteCountYear2 = [];
    this.chartData.usageYear2 = [];
    this.chartData.usageYear2Partial = false;

    if (chartResponse.year2Values) {
      chartResponse.year2Values.sort((val1, val2) => {
        return val1.month < val2.month ? -1 : 1;
      });
      currentMonth = 1;
      chartResponse.year2Values.forEach((item) => {
        if (currentMonth < item.month) {
          while (currentMonth < item.month) {
            this.chartData.totalChargesYear2.push(null);
            this.chartData.siteCountYear2.push(null);
            this.chartData.usageYear2.push(null);
            currentMonth++;
          }
        }
        currentMonth++;
        this.chartData.totalChargesYear2.push(item.totalCharges);
        this.chartData.siteCountYear2.push(item.siteCount);
        this.chartData.usageYear2.push(item.usage);
      });
    }

    while (this.chartData.totalChargesYear2.length < 12) {
      this.chartData.totalChargesYear2Partial = true;
      this.chartData.totalChargesYear2.push(null);
    }
    while (this.chartData.siteCountYear2.length < 12) {
      this.chartData.siteCountYear2.push(null);
    }
    while (this.chartData.usageYear2.length < 12) {
      this.chartData.usageYear2Partial = true;
      this.chartData.usageYear2.push(null);
    }

    this.chartData.totalChargesDelta = [];
    this.chartData.usageDelta = [];
    this.chartData.siteCountDelta = [];
    let delta = 0;

    for (const i in this.chartData.totalChargesYear1) {
      if (this.chartData.totalChargesYear1[i] == null || this.chartData.totalChargesYear2 == null) {
        this.chartData.totalChargesDelta.push(null);
      } else {
        delta = this.chartData.totalChargesYear1[i] - this.chartData.totalChargesYear2[i];
        if (delta) {
          this.chartData.totalChargesDelta.push(delta);
        } else {
          this.chartData.totalChargesDelta.push(null);
        }
      }
    }

    for (const i in this.chartData.usageYear1) {
      if (this.chartData.usageYear1[i] == null || this.chartData.usageYear2 == null) {
        this.chartData.usageDelta.push(null);
      } else {
        delta = this.chartData.usageYear1[i] - this.chartData.usageYear2[i];
        if (delta) {
          this.chartData.usageDelta.push(delta);
        } else {
          this.chartData.usageDelta.push(null);
        }
      }
    }

    for (let i = 0; i < this.chartData.siteCountYear1.length; i++) {
      if (this.chartData.siteCountYear1[i] == null || this.chartData.siteCountYear2 == null) {
        this.chartData.siteCountDelta.push(null);
      } else {
        delta = this.chartData.siteCountYear1[i] - this.chartData.siteCountYear2[i];
        if (delta) {
          this.chartData.siteCountDelta.push(delta);
        } else {
          this.chartData.siteCountDelta.push(null);
        }
      }
    }

    // Get Totals - Thanks Moses...
    this.chartData.totalChargesYear1Total = 0;
    this.chartData.totalChargesYear1.forEach((charge) => { this.chartData.totalChargesYear1Total += charge; });
    this.chartData.totalChargesYear2Total = 0;
    this.chartData.totalChargesYear2.forEach((charge) => { this.chartData.totalChargesYear2Total += charge; });
    this.chartData.totalChargesDeltaTotal = 0;
    this.chartData.totalChargesDelta.forEach((charge) => { this.chartData.totalChargesDeltaTotal += charge; });
    this.chartData.usageYear1Total = 0;
    this.chartData.usageYear1.forEach((usage) => { this.chartData.usageYear1Total += usage; });
    this.chartData.usageYear2Total = 0;
    this.chartData.usageYear2.forEach((usage) => { this.chartData.usageYear2Total += usage; });
    this.chartData.usageDeltaTotal = 0;
    this.chartData.usageDelta.forEach((usage) => { this.chartData.usageDeltaTotal += usage; });
  }

  toggleDateType() {
    this.showDateType = !this.showDateType;
    this.showYears = this.showSites = false;
  }

  toggleYears() {
    this.showYears = !this.showYears;
    if (this.showYears) {
      this.tempYear1 = this.selectedReport.year1;
      this.tempYear2 = this.selectedReport.year2;
    }
    this.showDateType = this.showSites = false;
  }

  toggleSites() {
    this.showSites = !this.showSites;
    if (this.showSites) {
      this.populateSitesControl();
    } else {
      if (this.sitesControl.selectedSite) {
        if (!this.maskMode) {
          this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
        } else {
          this.sitesControl.siteSearch = this.getFakeSiteId(this.sitesControl.selectedSite) + ' ' + this.fakerService.getCompanyName();
        }
      } else {
        this.sitesControl.siteSearch = '';
      }
    }
    this.showDateType = this.showYears = false;
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType;
    this.selectedReport.dateType = dateType.value;
    this.toggleDateType();
    this.enableSave = true;
    this.createCharts();
  }

  selectYear1(year: number) {
    this.tempYear1 = year;
  }

  selectYear2(year: number) {
    this.tempYear2 = year;
  }

  applyYears() {
    this.selectedReport.year1 = this.tempYear1;
    this.selectedReport.year2 = this.tempYear2;
    this.enableSave = true;
    this.toggleYears();
    this.createCharts();
  }

  searchSites() {
    this.filteredSites = [];
    this.sitesForAccount.forEach((site) => {
      if (site.siteId.match(this.sitesControl.siteSearch) ||
        (site.name && site.name.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
        this.sitesControl.siteSearch == '') {
        this.filteredSites.push(site);
      }
    });
  }

  navigateTypeahead(down: boolean) {
    if (down && this.siteIndex < this.filteredSites.length - 1) {
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  selectSite(site: ISite, index?: number) {
    if (this.filteredSites.length > 0) {
      if (this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if (index) {
        this.sitesControl.selectedSite = this.filteredSites[index];
      } else {
        this.sitesControl.selectedSite = this.filteredSites[this.siteIndex];
      }
      if (!this.maskMode) {
        this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
      } else {
        this.sitesControl.siteSearch = this.getFakeSiteId(site) + ' ' + this.fakerService.getCompanyName();
      }
      this.resetTypeahead();
    }
  }

  resetSiteSelection() {
    this.sitesControl.siteSearch = null;
  }

  applySites() {
    // Apply changes in customerControl to selectedReport
    this.toggleSites();
    this.selectedReport.siteRequest = this.sitesControl.siteRequest;
    if (this.selectedReport.siteRequest == 'ALL_SITES') {
      this.selectedReport.siteId = this.selectedReport.systemGroupName = this.selectedReport.groupId = this.selectedReport.siteGroupCategory = null;
    } else if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
      this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
      if (this.sitesControl.selectedGrouping.id) {
        this.selectedReport.groupId = this.sitesControl.selectedGrouping.id;
      } else {
        this.selectedReport.systemGroupName = this.sitesControl.selectedGrouping.groupName;
      }
      this.selectedReport.siteId = null;
    } else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
      this.selectedReport.siteId = this.sitesControl.selectedSite.siteId;
    }
    this.enableSave = true;
    this.getSiteControlDisplay();
    this.createCharts();
    this.resetSiteSelection();
  }

  formatDelta(delta: number, dollar: boolean) {
    if (!delta && delta != 0) {
      return '--';
    }
    if (delta > 0) {
      return this.numberWithCommas(delta, dollar);
    } else {
      return dollar ? '-$' + this.numberWithCommas(Math.abs(delta), false, true) : this.numberWithCommas(delta, false, false);
    }
  }

  numberWithCommas(x, dollar?: boolean, twoDecimals?: boolean) {
    if (x == null) {
      return '--';
    }
    let response = this.portalService.numberWithCommas(x);
    if (dollar || twoDecimals) {
      response = this.portalService.numberWithCommas(x.toFixed(2));
    }
    if (dollar) {
      response = '$' + response;
    }
    return response;
  }

  createChargesChart() {
    Highcharts.chart('chargesChart', {
      chart: {
        height: this.isMobile ? 250 : 350,
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: {
        title: { text: 'CURRENT CHARGES' },
        labels: {
          formatter: function () {
            return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }
      },
      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>${point.y: ,0.2f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.totalChargesYear1
      },
      {
        name: this.selectedReport.year2,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.totalChargesYear2
      }
      ]
    });
  }

  createUsageChart() {
    Highcharts.chart('usageChart', {
      chart: {
        height: this.isMobile ? 250 : 350,
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: { title: { text: 'USAGE (kWh)' } },
      credits: {
        enabled: false
      },

      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y: ,0.2f} kWh</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.usageYear1
      }, {
        name: this.selectedReport.year2,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.usageYear2
      }]
    });
  }

  createSitesChart() {
    Highcharts.chart('sitesChart', {
      chart: {
        type: 'column',
        height: this.isMobile ? 250 : 350
      },
      exporting: { enabled: false },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'NUMBER OF SITES'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y: 0.0f} site(s)</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        data: this.chartData.siteCountYear1
      }, {
        name: this.selectedReport.year2,
        showInLegend: false,
        data: this.chartData.siteCountYear2

      }]
    });
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}
