import { Component, Host, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaymentsService } from '../payments.service';
import { ISiteGroup, ISite } from '../../shared/entities/site';
import { IBalancesRequest, IBalances } from '../payments';
import { PortalService } from '../../shared/portal.service';
import { IUserAccount } from '../../shared/entities/profile';
import { DataService } from '../../shared/data.service';
import { Subscription } from 'rxjs';
import { IDownloadInvoiceRequest, ICategory, IInvoice } from '../../shared/entities/bill-reports';
import { BillingSummaryService } from '../../billing-summary/billing-summary.service';
import { BalancesPipe } from '../../shared/pipe/balances.pipe';
import { environment } from '../../../environments/environment';
import { IHelpStep } from '../../shared/entities/contextualHelp';
import * as moment from 'moment';
import { GenericPipe } from '../../shared/pipe/generic.pipe';
import { OrderByPipe } from '../../shared/pipe/orderBy.pipe';
import { IAccount } from '../../shared/entities/account';
import { PaymentsComponent } from '../payments.component';
import { AccountBalancesComponent } from './account-balances/account-balances.component';
declare var $: any;

@Component({
  selector: 'app-select-balances',
  templateUrl: './select-balances.component.html',
  styleUrls: ['select-balances.component.scss'],
  providers: [BalancesPipe, GenericPipe, OrderByPipe]
})
export class SelectBalancesComponent implements OnInit, OnDestroy {
  @ViewChild(AccountBalancesComponent, {static : false}) accountBalanceComponent : AccountBalancesComponent;
  selectedGroup: ISiteGroup;
  myAccount: IUserAccount;
  accountWithContractData: IAccount;
  showSearchBar = false;
  anyChecked = false;
  searchTerm: string;
  balances: IBalances;
  invoices: IInvoice[]; // for Invoice View
  numOfRowsToShow = 10000;
  filteredInvoices: IInvoice[];
  filteredBalances: IBalances;
  userSiteGroups: ISiteGroup[];
  systemSiteGroups: ISiteGroup[];
  accountChanged = false;
  showNoBalancesMessage = false;
  ncaFlag : boolean = false;
  showCCFilter = false;
  maskMode: boolean;
  helpStep: IHelpStep;
  summaryView = true;
  selectCCAccountschecked = false;
  invoicesSelected : IInvoice[] = [];
  showCreditCard: boolean = false;
  nccaStatus : boolean = true;
  maskedCategoryAddress: string;
  maskedCategoryCity: string;
  maskedCategoryZip: string;

  
  paymentOptionAction = () => { };
  filterOnlyCreditcards = () => { };

  // Subscriptions
  accountSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  siteGroupsSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;
  
  constructor(private paymentsService: PaymentsService,
    private portalService: PortalService,
    private dataService: DataService,
    private billingService: BillingSummaryService,
    private balancesPipe: BalancesPipe,
    private orderByPipe: OrderByPipe,
    private genericPipe: GenericPipe,
    @Host() private parent: PaymentsComponent) { }

  ngOnInit() {
    this.showCreditCard = this.dataService.getShowCreditCard();
    this.nccaStatus = this.dataService.getNCCAFlag();
    this.paymentsService.setStepReached(1);
    this.myAccount = this.dataService.getAccountSource();
    if (this.myAccount) {
      if (this.myAccount.largeCustomer) {
        this.numOfRowsToShow = 25;
        this.loadScroller();
      } else {
        this.numOfRowsToShow = 10000;
      }
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.dataService.setLoading(true);
        this.myAccount = account;
        this.accountChanged = true;
        if (this.myAccount.largeCustomer) {
          this.numOfRowsToShow = 25;
          this.loadScroller();
        } else {
          this.numOfRowsToShow = 10000;
        }
      }
    );
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.getBalances();
    });
    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.accountWithContractData = account;
    });
    const siteGroups = this.dataService.getSiteGroupsSource();
    if (siteGroups) {
      this.loadGroups(siteGroups);
    }
    this.siteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.loadGroups(siteGroups);
      }
    );
    if (this.paymentsService.selectedBalances && this.paymentsService.selectedBalances.length == 0) {
      this.clearAll();
    }
    if (!environment.production) {
      this.getBalances();
    }
    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  loadScroller() {
    const component = this;
    $(window).scroll(function () {
      let categoryExpanded = false;
      if (component.filteredBalances) {
        component.filteredBalances.categories.forEach((category) => {
          if (category.expanded) {
            categoryExpanded = true;
          }
        });
      }
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        if (categoryExpanded || !component.summaryView) {
          component.numOfRowsToShow = component.numOfRowsToShow + 25;
        }
      }
    });
  }

  toggleSummaryView() {
    this.searchTerm = '';
    if (this.summaryView) {
      this.filterBalances();
    } else {
      this.filterInvoices();
    }
    if (this.myAccount.largeCustomer) {
      this.dataService.setLoading(true);
      setTimeout(() => {
        this.dataService.setLoading(false);
      }, 3000);
    }
    this.checkViewSwitch(false, () => {
      this.summaryView = true;
      this.parent.toggleAccountView(false);

      this.getDisabledInvoices();
      if (this.myAccount.largeCustomer) {
        this.numOfRowsToShow = 25;
      }
    });
  }

  toggleInvoiceView() {
    this.searchTerm = '';
    if (this.summaryView) {
      this.filterBalances();
    } else {
      this.filterInvoices();
    }
    if (this.myAccount.largeCustomer) {
      this.dataService.setLoading(true);
      setTimeout(() => {
        this.dataService.setLoading(false);
      }, 3000);
    }
    this.checkViewSwitch(false, () => {
      this.summaryView = false;
      this.parent.toggleAccountView(false);
      this.filteredInvoices.forEach((invoice2) => {
        this.filteredBalances.categories.forEach((category) => {
          category.sites.forEach((site) => {
            site.invoices.forEach((invoice) => {
              if (invoice2.invoiceNo == invoice.invoiceNo) {
                invoice2.checked = invoice.checked;
              }
            });
          });
        });
      });
      if (this.myAccount.largeCustomer) {
        this.numOfRowsToShow = 25;
      }
      this.getDisabledInvoices();
    });
  }

  toggleAccountView() {
    this.searchTerm = '';
    if (this.summaryView) {
      this.filterBalances();
    } else {
      this.filterInvoices();
    }
    this.checkViewSwitch(true, () => {
      this.dataService.setLoading(true);
      this.summaryView = false;
      this.parent.toggleAccountView(true);
      this.accountBalanceComponent.searchTerm = '';
      this.accountBalanceComponent.filterBalances();
      setTimeout(() => {
        this.dataService.setLoading(false);
      }, 400);
    });
  }

  filterCC() {
    //this.showCCFilter=!this.showCCFilter;
    this.selectCCAccountschecked=!this.selectCCAccountschecked;
    if(this.accountView)
      this.accountBalanceComponent.filterCC(this.selectCCAccountschecked);
    this.ccAccountsChecked();
  }

  checkViewSwitch(showAccount: boolean, completed: () => any) {
    this.paymentOptionAction = () => { };
    const selectedInvoices = this.balances.invoices.filter(invoice => invoice.checked);
    const selectedCategories = this.balances.categories.filter(category => category.checked);
    // Selected sites used by pay by account view
    const selectedSites = this.balances.categories.filter(category =>
      category.sites.filter(site => site.checked || site.otherAmount).length
    );

    // Switch view if no invoices, category, or sites are checked
    if (selectedInvoices.length === 0 && selectedCategories.length === 0 && selectedSites.length === 0) {
      completed();
      return;
    }

    // If switching from invoice to account, or from account to invoice views
    if ((showAccount && !this.accountView) || (!showAccount && this.accountView)) {
      $('#paymentOptionModal').modal('show');
      this.paymentOptionAction = () => {
        $('#paymentOptionModal').modal('hide');
        this.clearAll();
        this.clearAllInvoices();
        setTimeout(() => completed(), 500);
      };
    } else {
      completed();
    }
  }

  private filterCCEligibleSites() {
    if(this.summaryView)
      this.filteredBalances.categories = this.filteredBalances.categories.map((i)=>({
        ...i,
        sites: i.sites.filter(x => x.nccaStatus !== "X")
      })
      );  
    else
      this.filteredInvoices = this.invoices.filter(x => x.nccaStatus !== "X");
  }

  ccAccountsChecked() {
    if (this.selectCCAccountschecked) {

      //Loop and check if any of the non CC eligible record is selected, then show popup
      if((!this.summaryView && this.invoices.filter(x => x.nccaStatus == "X").filter(y => y.checked).length > 0) || 
      (this.summaryView && this.filteredBalances.categories.map(x => x.sites.filter(x => x.nccaStatus == "X").filter(y => y.checked)).filter(x=>x.length>0).length > 0))
         $('#paymentTypeFilterModal').modal('show');
         
      else
          this.filterCCEligibleSites();

      // On clicking Proceed in the popup, clear checkboxes for non CC eligible records
      this.filterOnlyCreditcards = () => {
        this.invoices.filter(x => x.nccaStatus == "X").forEach(y => y.checked = false);
        this.filteredBalances.categories.forEach(x => x.sites.forEach(element => {
          if(element.nccaStatus == "X"){
            element.checked = false;
            element.invoices.forEach(y => y.checked = false);
          }
        }));
        this.filterCCEligibleSites();
        $('#paymentTypeFilterModal').modal('hide');
      };
    }
     else {
      this.filteredInvoices = this.invoices;
      this.filteredBalances.categories = [];
      this.filteredBalances.categories = [...this.balances.categories];
    }

  }

  ngOnDestroy() {
    let currBalances = this.paymentsService.selectedBalances;
    let size = currBalances.length;
    if(this.balances && this.balances.categories){
      this.balances.categories.forEach((category) => {
        category.sites.forEach((site) => {
          site.invoices.forEach((invoice) => {
            if (invoice.checked && currBalances.indexOf(invoice) == -1) {
              currBalances.push(invoice)
            }
          });
        });
      });
   }
    
    if(currBalances.length != size){
      this.paymentsService.setSelectedBalances(currBalances);
    }
    
    this.dataService.setLoading(false);
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.siteGroupsSubscription) this.siteGroupsSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  getBalances() {
    let request: IBalancesRequest;
    if (this.myAccount && this.selectedGroup) {
      request = {
        accountId: this.myAccount.id,
        groupId: this.selectedGroup.id,
        system: this.selectedGroup.id ? false : true,
        groupName: this.selectedGroup.groupName,
      };
    } else {
      request = {
        accountId: '9000',
        groupId: 1,
        system: true,
        groupName: 'HOWDY',
      };
    }
    this.dataService.setLoading(true);
    this.paymentsService.getBalances(request).subscribe(
      (balances) => {
        
        if(balances.categories) {
          balances.categories.forEach((category)=>{
            if(category.sites){
              category.sites.forEach((site)=>{
                    if(site.invoices)
                        site.invoices = this.orderByPipe.transform(site.invoices, 'dueDate', true);
                })
              }
              })
            }
          

        this.balances = balances;
        this.invoices = this.balances.invoices;
        this.filteredInvoices = this.invoices;
        this.checkIfAllCCEligible();
        this.filterInvoices();
        this.filterBalances();
        this.getDisabledInvoices();
        this.getCollectiveInvoices();
        this.showNoBalancesMessage = false;
        this.dataService.setLoading(false);
      }, () => {
        // Empty table
        this.filteredBalances = {
          balanceDue: 0,
          siteCount: 0,
          invoiceCount: 0,
          categories: []
        };
        this.showNoBalancesMessage = true;
        this.dataService.setLoading(false);
      }
    );
  }

  doSomething() {

  }

  hasChildNcaa(site: ISite) {
   return (site.invoices.filter(invoice => invoice.nccaStatus == 'X').length > 0);
  }

  // Checks entire page of select-balances if are all Credit Card Eligible
  allInvoicesCCEligible(filteredBalances: IBalances) {
    let allEligible = true;
    if(filteredBalances.categories) {
      filteredBalances.categories.forEach((category)=>{
        if(category.sites){
          let showCCForCategory = this.allInvoicesOfCategoryCCEligible(category.sites); 
          if(!showCCForCategory){
            return allEligible = false;
          }
        } else {
          return allEligible = false;
        }
      })
    }
    if(filteredBalances && filteredBalances.invoices){
      filteredBalances.invoices.forEach((invoice) => {
        let ncaa = invoice.nccaStatus;
        let date = invoice.paymentDate;
        let disabled = invoice.disabled;
        let autoPaymentFlag = invoice.autoPaymentFlag;
        let showCCForThisInvoice = this.showCCForInvoice(invoice);
        if(!showCCForThisInvoice) {
          allEligible = false;
        } 
      });
    }
    
    return allEligible;
  }

  // Same method as above but for the subcategories of balances. If all balances are CC eligible then the parent category checkbox is eligible
  allInvoicesOfCategoryCCEligible(sites: ISite[]){
    let allEligible = true;
    sites.forEach((site) => {
      if(site.nccaStatus || site.autoPaymentFlag) {
        return allEligible = false;
      }
      let showCCForThisSite = this.showCCForSite(site);
      if(!showCCForThisSite){
        return allEligible = false;
      }
     
    });
    return allEligible;
  }

  showCCForInvoice(invoice:IInvoice){
    let ncca = invoice.nccaStatus;
    let date = invoice.paymentDate;
    let disabled = invoice.disabled;
    let autoPaymentFlag = invoice.autoPaymentFlag;
    if(!invoice.nccaStatus && !invoice.autoPaymentFlag){
      return true;
    } else {
      return false
    }
  }

  showCCForSite(site:ISite){
    let allEligible = true;
    site.invoices.forEach((invoice) => {
      let ncca = invoice.nccaStatus;
      let date = invoice.paymentDate;
      let disabled = invoice.disabled;
      let autoPaymentFlag = invoice.autoPaymentFlag;
      let showCCForThisInvoice = this.showCCForInvoice(invoice);
      if(!showCCForThisInvoice) {
        return allEligible = false;
      } 
    });
    return allEligible;
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeCompanyName(site: any) {
    return this.portalService.getFakeCompanyName(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }

  loadGroups(siteGroups: any) {
    const hasCollective = siteGroups.systemSiteGroups.filter(group => group.groupName == 'COLLECTIVE_BILLING').length > 0;
    const hasSummary = siteGroups.systemSiteGroups.filter(group => group.groupName == 'SUMMARY_BILLING').length > 0;
    const billingGroup = { groupName: 'BILLING_GROUP', display: 'Billing Groups', description: 'Collective or Summary Billing' };
    const allSites = { groupName: 'ALL_SITES', display: 'All Sites', description: 'All Sites' };
    const marketsGroup = { groupName: 'MARKETS', display: 'Markets', description: 'Markets' };

    if (hasCollective) {
      this.systemSiteGroups = [billingGroup];
      this.userSiteGroups = [];
    } else if (hasSummary) {
      this.systemSiteGroups = [billingGroup];
      this.userSiteGroups = siteGroups.userSiteGroups;
    } else {
      this.systemSiteGroups = [allSites];
      this.userSiteGroups = siteGroups.userSiteGroups;
    }
    if (this.accountWithContractData.markets && this.accountWithContractData.markets.length > 1) {
      this.systemSiteGroups.push(marketsGroup);
    }

    this.selectGroup(this.systemSiteGroups[0]);
  }

  findSummary(group) {
    return group.groupName == 'SUMMARY_BILLING';
  }

  toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    if (this.summaryView) {
      this.filterBalances();
    } else {
      this.filterInvoices();
    }
  }

  downloadInvoice(invoice: any, site?: any) {
    this.dataService.setLoading(true);
    if (!site) site = invoice;
    const request: IDownloadInvoiceRequest = {
      accountId: this.myAccount.id,
      contractAccountNumber: invoice.contractAccountNum,
      collectiveAccountId: this.maskMode ? null : site.contractAccountNum.substr(0, 3) == '800' ? site.contractAccountNum : '',
      collectiveInvoiceNo: this.maskMode ? null : invoice.collectiveInvoiceNo,
      siteId: site.siteId,
      invoiceNo: this.maskMode ? '9999999999990' : invoice.invoiceNo,
      billingPeriod: invoice.dueDate.substr(0, 7).replace('-', ''),
      repOwnerId: invoice.repOwnerId
    };
    if (this.dataService.getIsMobile()) {
      this.billingService.openMobileInvoice(request);
    } else {
      this.billingService.downloadInvoice(request);
    }
  }

  numbersWithCommas(x) { return this.portalService.numberWithCommas(x); }

  filterBalances() {
    if (this.searchTerm) {
      // Apply "checked" attribute to regular balances.
      this.balances.categories.forEach((category) => {
        category.sites.forEach((site) => {
          this.filteredBalances.categories.forEach((filteredCategory) => {
            filteredCategory.sites.forEach((filteredSite) => {
              if (site.siteId == filteredSite.siteId) {
                site.checked = filteredSite.checked;
              }
            });
          });
        });
      });
      this.filteredBalances = this.balancesPipe.transform(this.balances, this.searchTerm);
    } else {
      this.filteredBalances = {...this.balances};
      //this.filteredBalances = this.balances;
    }
  }

  getDateDisplay(dateString: string) {
    if (dateString && !dateString.toLowerCase().match('multiple') && !dateString.toLowerCase().match('past due') && !dateString.toLowerCase().match('yes') && !dateString.toLowerCase().match('no')) {
      return moment(dateString).format('MM/DD/YY');
    } else {
      return dateString;
    }
  }

  expand(row) {
    row.expanded = !row.expanded;
  }

  selectGroup(group: ISiteGroup, reload?: boolean) {
    this.selectedGroup = group;
    if (group.id) {
      this.selectedGroup.display = group.groupName;
    } else {
      this.selectedGroup.display = group.display;
    }
    if (this.paymentsService.balances && !this.accountChanged && !reload) {
      this.balances = this.paymentsService.balances;
      this.paymentsService.setBalances(this.balances); // don't remove this line, sets off subscription in payments.component.ts
      this.filterBalances();
      this.checkForAnyChecks();

      this.invoices = this.balances.invoices;
      this.filteredInvoices = this.invoices;
      this.filterInvoices();
    } else {
      this.clearAll();
      this.getBalances();
    }
  }

  // Checkbox logic
  rootChecked() {    
    if(this.filteredBalances.checked) {
      this.filteredBalances.categories.forEach(x => x.sites.forEach(element => {
          element.checked = true;
          element.invoices.forEach(y => y.checked = true);
      }));
    }else{
      this.filteredBalances.categories.forEach(x => x.sites.forEach(element => {
        element.checked = false;
        element.invoices.forEach(y => y.checked = false);
    }));
    }

    
    if (this.filteredBalances.partialCheck) {
      this.filteredBalances.partialCheck = false;
      this.filteredBalances.checked = true;
      const rootCheck = document.getElementById('rootCheck');
      setTimeout(() => {
        rootCheck.click();
      }, 10);
    }
    this.filteredBalances.categories.forEach((category) => {
      category.partialCheck = false;
      if (!category.autoPaymentFlag && !category.paymentDate && !category.paymentScheduled) {
        category.checked = this.filteredBalances.checked;
      }
      category.sites.forEach((site) => {
        let invCount = 0;
        if (!site.autoPaymentFlag && !site.paymentDate && !site.paymentScheduled) {
          site.checked = this.filteredBalances.checked;
        } else {
          site.checked = false;
        }
        site.invoices.forEach((invoice) => {
          if (!invoice.autoPaymentFlag && !invoice.paymentDate && !invoice.scheduledPayment) {
            invoice.checked = this.filteredBalances.checked;
          } else {
            invoice.checked = false;
          }

          if(invoice.checked){
            invCount ++;
          }
        });

        if(invCount == site.invoiceCount){
          site.checked = true;
          site.partialCheck = false;
        }
        else if(invCount > 0 && invCount != site.invoiceCount){
          site.checked = true;
          site.partialCheck = false;
        }
        else if(invCount == 0){
          site.checked = false;
          site.partialCheck = false;
        }
        
      });
    });
    this.checkForAnyChecks();
    this.getDisabledInvoices();
    this.paymentsService.setBalances(this.filteredBalances);
  }

  categoryChecked(category: ICategory) {
    if (category.partialCheck) {
      category.partialCheck = false;
      category.checked = true;
      const categoryCheck = document.getElementById(category.name + '-check');
      setTimeout(() => {
        categoryCheck.click();
      }, 10);
    }
    category.sites.forEach((site) => {
      if (!site.autoPaymentFlag && !site.paymentDate && !site.paymentScheduled) {
        site.checked = category.checked;
      }

      let checkedInvoiceCount = 0;
      site.invoices.forEach((invoice) => {
        if (!invoice.autoPaymentFlag && !invoice.paymentDate && !invoice.scheduledPayment) {
          invoice.checked = site.checked;
          if(invoice.checked){
            checkedInvoiceCount ++;
          }
        }
      });

      if(checkedInvoiceCount == site.invoiceCount){
        site.checked = true;
        site.partialCheck = false;
      }

      
    });
    let allCategoriesChecked = true;
    let partialCheck = false;
    this.balances.categories.forEach((category) => {
      if ((!category.checked || category.partialCheck) && !category.autoPaymentFlag) {
        allCategoriesChecked = false;
      } else {
        partialCheck = true;
      }
    });
    this.balances.checked = allCategoriesChecked;
    this.balances.partialCheck = false;
    if (!this.balances.checked && partialCheck) {
      this.balances.checked = true;
      if(!allCategoriesChecked){
        this.balances.partialCheck = true;
      }
      
    }

    let allFilteredCategoriesChecked = true;
    partialCheck = false;
    if(this.filteredBalances){
      this.filteredBalances.categories.forEach((category) => {
        category.sites.forEach((site) =>{
          if ((!site.checked || site.partialCheck)) {
            allFilteredCategoriesChecked = false;
          } else {
            partialCheck = true;
          }
        });
        })
        this.filteredBalances.checked = allFilteredCategoriesChecked;
        this.filteredBalances.partialCheck = false;
        if (!this.filteredBalances.checked && partialCheck) {
          this.filteredBalances.checked = true;
          if(!allFilteredCategoriesChecked){
            this.filteredBalances.partialCheck = true;
          }
        }
       
    }

    this.checkForAnyChecks();
    this.getDisabledInvoices();
    this.paymentsService.setBalances(this.balances);
  }

  siteChecked(category: ICategory, site: ISite) {
    site.partialCheck = false;
    let allSitesChecked = true;
    let partialCheck = false;

    category.sites.forEach((siteInCategory) => {
      if (!siteInCategory.checked && (!siteInCategory.autoPaymentFlag || (!siteInCategory.paymentDate && !siteInCategory.paymentScheduled))) {
        allSitesChecked = false;
      } 
      if(siteInCategory.checked || siteInCategory.partialCheck) {
        partialCheck = true;
      }
    });
    category.checked = allSitesChecked;
    category.partialCheck = false;
    if (!category.checked && partialCheck) {
      category.checked = true;
      category.partialCheck = true;
    }
    site.invoices.forEach((invoice) => {
      if (!invoice.autoPaymentFlag && (!invoice.paymentDate && !invoice.scheduledPayment)) {
        invoice.checked = site.checked;
      }
    });

    let allCategoriesChecked = true;
    partialCheck = false;
    this.balances.categories.forEach((category) => {
      if ((!category.checked || category.partialCheck) && !category.autoPaymentFlag) {
        allCategoriesChecked = false;
      } 
      if(category.checked || category.partialCheck) {
        partialCheck = true;
      }
    });
    this.balances.checked = allCategoriesChecked;
    this.balances.partialCheck = false;
    if (!this.balances.checked && partialCheck) {
      this.balances.checked = true;
      this.balances.partialCheck = true;
    }

    //loop thru all sites and see if >0 and not max site length are checked, then set partial of filtered balances

    let checkedSiteCount = 0;
    if(this.filteredBalances){
      category.sites.forEach(site=>{
        if(site.checked || site.partialCheck){
          checkedSiteCount += 1;
        }
      })
      if(checkedSiteCount != category.siteCount && checkedSiteCount > 0){
        this.filteredBalances.checked = true;
        this.filteredBalances.partialCheck = true;
      }
      else if(checkedSiteCount == 0){
        this.filteredBalances.checked = false;
        this.filteredBalances.partialCheck = false;
      }
      else{
        //must be all checked
        this.filteredBalances.checked = true;
        this.filteredBalances.partialCheck = false;
      }
      
    }

    
    this.checkForAnyChecks();
    this.getDisabledInvoices();
    this.paymentsService.setBalances(this.balances);
  }

  invoiceChecked(category: any, site: any, invoice: any) {
    this.getDisabledInvoices();
    let allInvoicesChecked = true;
    let partialCheck = false;
    site.invoices.forEach((invoice) => {
      if (!invoice.checked && (!invoice.scheduledPayment || !invoice.autoPaymentFlag) || invoice.disabled) {
        allInvoicesChecked = false;
      } 
      if(invoice.checked) {
        partialCheck = true;
      }
    });
    site.checked = allInvoicesChecked;
    site.partialCheck = false;
    if (!site.checked && partialCheck) {
      site.checked = true;
      site.partialCheck = true;
    }

    let allSitesChecked = true;
    partialCheck = false;
    category.sites.forEach((site) => {
      if ((!site.checked || site.partialCheck) && !site.autoPaymentFlag) {
        allSitesChecked = false;
      } 
      if(site.checked || site.partialCheck) {
        partialCheck = true;
      }
    });
    category.checked = allSitesChecked;
    category.partialCheck = false;
    if (!category.checked && partialCheck) {
      category.checked = true;
      category.partialCheck = true;
    }

    let allCategoriesChecked = true;
    partialCheck = false;
    this.balances.categories.forEach((category) => {
      if ((!category.checked || category.partialCheck) && !category.autoPaymentFlag) {
        allCategoriesChecked = false;
      } else {
        partialCheck = true;
      }
    });
    this.balances.checked = allCategoriesChecked;
    this.balances.partialCheck = false;
    if (!this.balances.checked && partialCheck) {
      this.balances.checked = true;
      this.balances.partialCheck = true;
    }

    let checkedSiteCount = 0;
    if(this.filteredBalances){
      category.sites.forEach(site=>{
        if(site.checked || site.partialCheck){
          checkedSiteCount += 1;
        }
      })
      if(checkedSiteCount != category.siteCount && checkedSiteCount > 0){
        this.filteredBalances.checked = true;
        this.filteredBalances.partialCheck = true;
      }
      else if(checkedSiteCount == 0){
        this.filteredBalances.checked = false;
        this.filteredBalances.partialCheck = false;
      }
      else{
        this.filteredBalances.checked = true;
        this.filteredBalances.partialCheck = false;
        //all checked
      }
    }
    

    this.checkForAnyChecks();
   
    this.paymentsService.setBalances(this.balances);
  }

  checkForAnyChecks() {
    // Enable/Disable "Clear Selections" button
    this.anyChecked = false;
    this.balances.categories.forEach((category) => {
      category.sites.forEach((site) => {
        site.invoices.forEach((invoice) => {
          if (invoice.checked) {
            this.anyChecked = true;
          }
        });
      });
    });
  }

  clearAll() {
    this.anyChecked = false;
    if (this.balances) {
      this.balances.checked = false;
      this.balances.categories.forEach((category) => {
        category.checked = false;
        category.sites.forEach((site) => {
          site.checked = false;
          site.editing = true;
          site.otherAmount = null;
          site.tempOtherAmount = null;
          site.invoices.forEach(invoice => {
            invoice.checked = false;
          });
        });
      });
    }
    if (this.balances) {
      this.getDisabledInvoices();
    }
    if (this.filteredBalances) {
      this.filteredBalances.checked = false;
      this.filteredBalances.partialCheck = false;
      this.filteredBalances.categories.forEach((category) => {
        category.checked = false;
        category.partialCheck = false;
        category.sites.forEach((site) => {
          site.checked = false;
          site.partialCheck = false;
          site.editing = true;
          site.otherAmount = null;
          site.tempOtherAmount = null;
          site.invoices.forEach(invoice => {
            invoice.checked = false;
          });
        });
      });
    }
    this.paymentsService.setBalances(this.balances);
  }


  // INVOICE VIEW

  filterInvoices() {
    if (this.searchTerm) {
      this.filteredInvoices = this.genericPipe.transform(this.invoices, this.searchTerm);
    } else {
      this.filteredInvoices = this.invoices;
    }
    this.filteredInvoices = this.orderByPipe.transform(this.filteredInvoices, 'dueDate', true);
  }

enableCategoryCheckbox(category : ICategory) : boolean {

  let enabled = true;
  for(let i = 0; i < category.sites.length; i ++)
  {
    let currSite = category.sites[i];
    currSite.invoices.forEach((invoice)=>{
      if((invoice.nccaStatus == 'X' && invoice.ncaStatus == 'X') || invoice.autoPaymentFlag == 'X' || !(currSite.paymentDateStr == '' || !currSite.paymentScheduled)){
        enabled = false;
      }
      if(enabled && (currSite.autoPaymentFlag != 'X' || (currSite.paymentDateStr == '' || !currSite.paymentScheduled))){
        enabled = true;
      }
    })
  
  }
  return enabled;
}

enableTopLevelCheckbox(invoices : IInvoice[]) : boolean {
  let enabled = true;
  if(invoices){
  for(let i = 0; i < invoices.length; i ++){
    let currInvoice = invoices[i];
    if((currInvoice.ncaStatus == 'X' && currInvoice.nccaStatus == 'X') || currInvoice.autoPaymentFlag == 'X'){
      enabled = false;
    }
    if(enabled && (currInvoice.autoPaymentFlag != 'X' || (currInvoice.paymentDate == '' || !currInvoice.scheduledPayment))){
      enabled = true;
    }
  }
}
return enabled;
}

enableSiteCheckBox(site : ISite){
  let invoices = site.invoices;
  let enabled = true;
  if(invoices){
  for(let i = 0; i < invoices.length; i ++){
    let currInvoice = invoices[i];
    if((currInvoice.ncaStatus == 'X' && currInvoice.nccaStatus == 'X') || currInvoice.autoPaymentFlag == 'X'){
      enabled = false;
    }
    if(enabled && (currInvoice.autoPaymentFlag != 'X' || (currInvoice.paymentDate == '' || !currInvoice.scheduledPayment))){
      enabled = true;
    }
  }
  }
  if(site.paymentDateStr || site.paymentScheduled)
    enabled = false;
  return enabled;
}

enableToolTipCheckBox(invoice : IInvoice) : boolean {
  if((!invoice.paymentDate && !invoice.scheduledPayment)&& invoice.disabled && invoice.autoPaymentFlag != 'X'){
    return true;
  }
  return false;
}

  invoiceChecked2(invoice: IInvoice) {
    this.anyChecked = false;
    this.invoices.forEach((invoice2) => {
      // if (moment(invoice2.dueDate).isAfter(invoice.dueDate) && !invoice.checked) {
      //   invoice2.checked = false;
      // }
      if (invoice2.checked) {
        this.anyChecked = true;
      }
    });
    this.filteredBalances.categories.forEach((category) => {
      category.sites.forEach((site) => {
        site.invoices.forEach((invoice2) => {
          if (invoice.invoiceNo == invoice2.invoiceNo) {
            invoice2.checked = invoice.checked;
            this.invoiceChecked(category, site, invoice2);
          }
          if(invoice.contractAccountNum == invoice2.contractAccountNum && invoice.invoiceNo != invoice2.invoiceNo)
          {
            if(!invoice.checked || invoice2.disabled){
              invoice2.checked = false;
            }
          }
        });
      });
    });
    if(this.filteredBalances != this.balances){
      this.balances.categories.forEach((category) =>{
        category.sites.forEach((site)=>{
          site.invoices.forEach((i)=>{
            if(invoice.invoiceNo == i.invoiceNo){
              i.checked = invoice.checked;
              this.invoiceChecked(category, site, i);
            }
            if(invoice.contractAccountNum == i.contractAccountNum && invoice != i)
            {
            if(!invoice.checked){
              i.checked = false;
            }
            }
          })
        })
      })
    }
    this.getDisabledInvoices();
    this.paymentsService.setBalances(this.balances);
  }

  clearAllInvoices() {
    this.anyChecked = false;
    this.invoices.forEach((invoice) => {
      invoice.checked = false;
    });
    this.balances.invoices.forEach(invoice => {
      invoice.checked = false;
    });
    if (this.balances) {
      this.getDisabledInvoices();
    }
    this.paymentsService.setBalances(this.balances);
  }

  getDisabledInvoices() {
    this.paymentsService.setNca(false);
    if (!this.summaryView) {
      this.invoices.forEach((invoice) => {
        invoice.disabled = false;
        this.invoices.forEach((invoice2) => {
          if (invoice.contractAccountNum == invoice2.contractAccountNum && !invoice2.checked && moment(invoice2.dueDate).isBefore(invoice.dueDate)) {
            invoice.disabled = true;
            invoice.checked = false;
          }
          if(invoice.ncaStatus == 'X' && invoice.nccaStatus == 'X'){
            invoice.disabled = true;
            invoice.checked = false;
            this.ncaFlag = true;
            this.paymentsService.setNca(this.ncaFlag);
          }
          if(invoice.ncaStatus == 'X' && (invoice.nccaStatus == "" || invoice.nccaStatus == null)){
            this.ncaFlag = true;
            this.paymentsService.setNca(this.ncaFlag);
          }
        });
      });
    } else {
      this.balances.categories.forEach((category) => {
        category.sites.forEach((site) => {
          site.invoices.forEach((invoice) => {
            invoice.disabled = false;
            site.invoices.forEach((invoice2) => {
              if (!invoice.checked && moment(invoice2.dueDate).isAfter(invoice.dueDate)) {
                invoice2.checked = false;
              }
              if (invoice.contractAccountNum == invoice2.contractAccountNum && !invoice2.checked && moment(invoice2.dueDate).isBefore(invoice.dueDate)) {
                invoice.disabled = true;
              }
              if(invoice.ncaStatus == 'X' && invoice.nccaStatus == 'X'){
                invoice.disabled = true;
                invoice.checked = false;
                this.ncaFlag = true;
                this.paymentsService.setNca(this.ncaFlag);
              }
              if(invoice.ncaStatus == 'X' && (invoice.nccaStatus == "" || invoice.nccaStatus == null)){
                this.ncaFlag = true;
                this.paymentsService.setNca(this.ncaFlag);
              }
            });
          });
        });
      });
    }

    
  }

  getCollectiveInvoices() {
    const collectiveInvoiceIds = [];
    this.balances.categories.filter(category => category.name.includes('C:')).forEach(category => {
      category.sites.forEach((site) => {
        site.invoices.map(invoice => invoice.invoiceNo).forEach(id => collectiveInvoiceIds.push(id));
      });
    });

    this.invoices.forEach(invoice => {
      invoice.collective = collectiveInvoiceIds.includes(invoice.invoiceNo);
    });
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  get accountView(): boolean {
    return this.parent && this.parent.state.viewAccountToggled;
  }



  checkIfAllCCEligible(){
    let count: number = 0;
    this.invoices.forEach(invoice => {
      if(invoice.nccaStatus == "X") {
        count=count+1;
      }
    });

    if(count < this.invoices.length && count > 0)
    {
      this.showCCFilter = true;
    }
  }

  checkIfAll
}
