import { Pipe, PipeTransform } from '@angular/core';

export interface HelpTopicCategory {
    categoryName: string;
    displayName: string;
    allowedRoles : userType[];
    displayCategory: string;
    displayOrder: number;
    isJumpPage: boolean;
}
export interface userType {
  userType: string;
}
export interface ContactContainer {
    contactHeaderText : string;
    hyperlinkText : string;
}
export interface SearchContainer {

}
export interface MainPage {
    title : string;
    path : string;
    category : string;
    viewPriority: number;
}

export interface ActiveHelpPage {
  title : string;
  fullText : string;
  htmlText : string;
}
// Singular Help Page Objects
export class HelpTopicPageResponse {
  data: {
    helpTopicPageByPath: {
      item: ContentPage;
      _references: Reference[];
    };
  };
  dynamicUrl: string;
}

export interface JumpLinkParent {
  title: string;
  order: number;
  category: string;
  contentList: JumpLinkChild[];
}

export interface JumpLinkChild {
  label: string;
  context: string;
  dataType: string;
  hyperlink: string;
  referencePath: string;
}

export interface ContentPage {
    title : string;
    body: ContentBody;
}

export class ContentBody {
  html: string;
  markdown: string;
  plaintext: string;
  json: ContentNodes[];
}

export class ContentNodes {
  nodeType: string;
  content: Node[];
}

export interface Node {
  nodeType: string;
  content?: Node[];
  data?: {
    href?: string;
    target?: string;
    type?: string;
  };
  value?: string;
}

export class Reference {
  _path: string;
  image: {
      _path: string;
  };
  imageSize: string;
}


export interface NodeMap {
  [key: string]: (node: Node, children?: string, style?:string) => string;
}

@Pipe({ name: 'aemSearchFilter' })
export class SearchFilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter(it => {
      it = it.title.toLowerCase();
      return it.includes(searchText);
    });
  }
}
