import { Component, Host, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {IPaymentDetail} from "../../shared/entities/paymentDetail";
import {PagerService} from "../../shared/pager.service";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {AutoPaymentsService} from "../autopayments.service";
import {Router} from "@angular/router";
import {ISite} from "../../shared/entities/site";
import {PortalService} from "../../shared/portal.service";
import { BankAccountPipe } from "../../shared/pipe/bank-account.pipe"
import { IPaymentMethod } from "../../payments/payments";
import {IUserEmail, IUserAccount} from "../../shared/entities/profile"
import {FakerService} from "../../shared/faker.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import { AutoPaymentsComponent } from '../autopayments.component';
import { IAutoPaymentState, IAutoPaySites, IPaymentSchedule } from '../autopayments';
@Component({
  selector: 'app-autopay-confirmation',
  templateUrl: './autopay-confirmation.component.html',
  styleUrls: ['./autopay-confirmation.component.scss'],
  providers: [OrderByPipe, BankAccountPipe]
})
export class AutoPaymentConfirmationComponent implements OnInit {

  paymentTotal: number;
  pager: any;
  sortBy: string = 'siteId';
  ascending: boolean = true;
  selectedAutopay: any;
  paymentDate: string;
  paymentMethod: IPaymentMethod;
  emailError: boolean = false;
  customerName: string;
  maskMode: boolean;
  myAccount: IUserAccount;
  printing: boolean;
  fakeAccountName: string;
  helpStep: IHelpStep;
  payByAccount = false;
  state: IAutoPaymentState;
  action: string;
  paymentSch: IPaymentSchedule;

  maskModeSubscription: Subscription;
  accountSubscription: Subscription;
  helpStepSubscription: Subscription;

  
  constructor(private dataService: DataService,
              private pagerService: PagerService,
              private orderByPipe: OrderByPipe,
              private paymentsService: AutoPaymentsService,
              private router: Router,
              private portalService: PortalService,
              private bankAccountPipe: BankAccountPipe,
              @Host() private parent: AutoPaymentsComponent) {

  }

  ngOnInit() {
    this.paymentsService.setSubmitted(true);
    this.dataService.setSelectedNavItem('billing');
    this.dataService.setTitleSource("AutoPay");
    this.selectedAutopay = this.paymentsService.autopay;
    this.state = this.parent.state;
    this.action = this.parent.autopaySel;
    // this.selectedBalances.forEach((balance) => {
    //   balance.paymentAmount = Number(balance.paymentAmount).toFixed(2);
    // });
    // selectedAutopay
    // this.paymentTotal = this.paymentsService.paymentTotal;
    // this.paymentDate = this.paymentsService.paymentSchedule.paymentDate;
    // if(this.paymentsService.paymentSchedule){
    //   this.paymentDate = this.paymentsService.paymentSchedule.paymentDate;
    //   this.paymentMethod = this.paymentsService.paymentSchedule.paymentMethod;
    // }
    
     this.paymentDate = moment(new Date()).format('YYYY-MM-DD');

    this.myAccount = this.dataService.getAccountSource();
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
    });
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.fakeAccountName = this.dataService.getFakeAccountName();
    });
    this.paginate(1);
    this.paymentsService.setStepReached("confirm");
    this.sendInitalConfirmationEmail();

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    this.payByAccount = this.paymentsService.payByAccount;
    this.paymentSch = this.paymentsService.getPaymentSchedule();
  }

  paginate(page: number) {
    if((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager && this.selectedAutopay){
        this.selectedAutopay = this.paymentsService.autopay;
        this.pager = this.pagerService.getSingleItemPage(this.selectedAutopay, page);
    }
  }

  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(accountNum) {
      if(paymentType === 'CARD'){
        if(accountNum.length >= 4){
          return '*'+accountNum.substring(accountNum.length-4);
        }
      }
      else{
        // if(accountNum.indexOf('-') != -1){
        //   return accountNum.split('-')[1];
        // }
        if(accountNum.length >= 4){
          return '*'+accountNum.substring(accountNum.length-3);
        }        
      }
      return 'XXXXXXXXXX';
    }
  }

  isExpired(method: IAutoPaySites) {
    const dateSel = new Date(method.expMonth + '/' + '01' + '/' +  method.expYear);
    const dateCurrent = new Date();
    if(dateSel > dateCurrent) {
      return false;
    }
    return true;
  }
  
  isExpiring(method: IAutoPaySites) {
    const dateSel = new Date(method.expMonth + '/' + '01' + '/' +  method.expYear);
    const current = new Date();
    const today = new Date();
    current.setMonth(current.getMonth() + 4);
    const dateCurrent = new Date( current.getMonth() + "/" + '01' + '/' + current.getFullYear());
    if(dateSel>today && dateSel < dateCurrent) {
      return true;
    }
    return false;
  }
  
  ngOnDestroy(){
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  printWindow() {
    this.printing = true;
    let pageItems = this.pager.items;
    this.pager.items = this.selectedAutopay;
    this.dataService.setPrinting(this.printing);
    setTimeout(() => {
      window.print();
      this.printing = false;
      this.pager.items = pageItems;
      this.dataService.setPrinting(this.printing);
    }, 50);
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeStreetAddress(site: ISite): string {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: ISite): string {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: ISite): string {
    return this.portalService.getFakeZipCode(site);
  }


  goToAutoPay() {
    this.paymentsService.setSubmitted(false);
    this.paymentsService.payByAccount = false;
    this.router.navigate(['/autopayments/autopay-select-balances']);
  }

  sendInitalConfirmationEmail(){
          const action = this.paymentsService.getActionSel();
          const profile = this.dataService.getProfile();
          const brand = this.dataService.getAccountSource().accountBranding;
          const userId = this.dataService.getUserSource().login;
          const siteId = this.paymentsService.autopay.siteId;
          const fullAdd = this.paymentsService.autopay.serviceAddress;
          const address = fullAdd.streetAddress + "," + fullAdd.city + ", " + fullAdd.state + ", " +fullAdd.zipCode;
          let enrollmentDate = moment(new Date()).format('YYYY-MM-DD');
          let myProfile = this.dataService.getProfile();
          let emails:IUserEmail[] = [{temp:myProfile.email}];
          let account = this.dataService.getAccountSource();
          let customerName = account.name;
          let accountId = account.id;
          let caNumber = this.paymentsService.autopay.caNumber;
          let paymentSel;
          let paymentInfo;
          let paymentType;

          if(this.paymentsService.getPaymentSchedule())
          {     
            paymentSel = this.paymentsService.getPaymentSchedule().paymentMethod;
            if(paymentSel.paymentType==='BANKACCOUNT'){
              paymentInfo = this.hideAccountNumber("BANK",paymentSel.userPaymentSettingsKey.paymentToken);
              paymentType = "BANK";
            }else if(paymentSel.paymentType==='CARD') {
              paymentInfo = this.hideAccountNumber("CARD",paymentSel.userPaymentSettingsKey.paymentToken);
              paymentType = "CARD";
            }
          }                   
          this.portalService.sendAutopayConfirmationEmails(
              emails, userId, brand, customerName, accountId, siteId, address, enrollmentDate, paymentInfo,paymentType,caNumber, this.paymentsService.actionSel).subscribe(
              (resp) => {
              },
              (err) => {
               }
            );
  }
  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }
  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}

