// Code add by Ramalingam
import { Injectable } from '@angular/core';
import {Http, Response, Headers} from '@angular/http';

import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";
import {DataService} from "../shared/data.service";
import {Dev} from "../shared/const/properties";

@Injectable()
export class HelpTopicsService {

  headers: HttpHeaders;

  constructor(private oldHttp: Http, private http: HttpClient, private dataService: DataService) { 
    this.headers = new HttpHeaders({'Access-Control-Allow-Origin': 'https://stg-www.nrg.com'});
  }

  // Get contact information by account ID
  getHelpTopics(): Observable<any> {
    let url = environment.production ? environment.helpTopicsUrl : environment.helpTopicsUrl;
    return this.oldHttp
      .get(url)
      .map((response: Response) => response.json())
      .catch(this.handleError);
  }

  getContextualHelp(page: string) {
    let location = window.location.href;
    let url = '';
    let properties = this.dataService.getAppProperties();
    let contextualHelp;
    if(properties){
      contextualHelp = properties.contextualHelp;
    } else {
      // Local
      contextualHelp = Dev.contextualHelp;
    }

    if (location.match('site-groups/view-edit')) {
      url = contextualHelp.helpViewEditSiteGroupsUrl;
    } else if (location.match('site-groups/manage')) {
      url = contextualHelp.helpManageSiteGroupsUrl;
    } else if (location.match('dashboard')) {
      url = contextualHelp.helpDashboardUrl;
    } else if (location.match('billing-reports')) {
      url = contextualHelp.helpBillingSummaryUrl;
    } else if (location.match('billing-comparison')) {
      url = contextualHelp.helpBillingComparisonUrl;
    } else if (location.match('billing-usage')) {
      url = contextualHelp.helpBillingUsageUrl;
    } else if (location.match('payments/select-balances')) {
      url = contextualHelp.helpSelectBalancesUrl;
    } else if (location.match('payments/payment-info')) {
      url = contextualHelp.helpPaymentInfoUrl;
    } else if (location.match('payments/review-payment')) {
      url = contextualHelp.helpReviewPaymentUrl;
    } else if (location.match('payments/payment-confirmation')) {
      url = contextualHelp.helpPaymentConfirmationUrl;
    } else if (location.match('payment-history')) {
      url = contextualHelp.helpPaymentHistoryUrl;
    } else if (location.match('manage-payment-methods')) {
      url = contextualHelp.helpManagePaymentMethodsUrl;
    } else if (location.match('usage-reports')) {
      url = contextualHelp.helpUsageSummaryUrl;
    } else if (location.match('usage-comparison')) {
      url = contextualHelp.helpUsageComparisonUrl;
    } else if (location.match('interval-usage-report/select-sites')) {
      url = contextualHelp.helpSelectSitesUrl;
    } else if (location.match('interval-usage-report/choose-settings')) {
      url = contextualHelp.helpSelectReportSettingsUrl;
    } else if (location.match('usage-graph')) {
      url = contextualHelp.helpUsageIntervalGraphUrl;
    } else if (location.match('contracts')) {
      url = contextualHelp.helpContractsUrl;
    } else if (location.match('profile')) {
      url = contextualHelp.helpProfileUrl;
    } else if (location.match('amendments/home')) {
      url = contextualHelp.helpAddRemoveSitesUrl;
    } else if (location.match('amendments/history')) {
      url = contextualHelp.helpAddDeleteHistoryUrl;
    } else if (location.match('fourcp/overview')) {
      url = contextualHelp.helpFourcpOverviewUrl;
    } else if (location.match('fourcp/alert-history')) {
      url = contextualHelp.helpFourcpAlertHistoryUrl;
    } else if (location.match('renewables/overview')) {
      url = contextualHelp.helpSustainabilityOverviewUrl;
    } else if (location.match('shadow-metering/distributed-generation')) {
      url = contextualHelp.helpDistributedGenerationUrl;
    } else if (location.match('shadow-metering/onsite-generation')) {
      url = contextualHelp.helpOnsiteGenerationUrl;
    } else if (location.match('shadow-metering/realtime-usage')) {
      url = contextualHelp.helpRealtimeUsageUrl;
    } else if (location.match('pricing')) {
      if (page === 'pricing-quote-list') {
        url = contextualHelp.helpPricingQuoteListUrl;
      } else if (page === 'pricing-quote-contact') {
        url = contextualHelp.helpPricingQuoteContactUrl;
      } else if (page === 'pricing-quote-contracts') {
        url = contextualHelp.helpPricingQuoteContractsUrl;
      } else if (page === 'pricing-quote-customers') {
        url = contextualHelp.helpPricingQuoteCustomersUrl;
      } else if (page === 'pricing-quote-pricing') {
        url = contextualHelp.helpPricingQuotePricingUrl;
      } else if (page === 'pricing-quote-sites') {
        url = contextualHelp.helpPricingQuoteSitesUrl;
      }
    }
    return this.http
        .get(url)
        .map((response: HttpResponse<any>) => response)
        .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
