import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from '../shared/data.service';
import { PortalService } from '../shared/portal.service';
import { Authority } from '../shared/const/authority';
import { GenerationDetailExportModalComponent } from './generation-detail-export-modal/generation-detail-export-modal.component';
import { MaintenanceModalComponent } from './maintenance-modal/maintenance-modal.component';
import { RenewablesService } from './renewables.service';
import { IUserAccount } from '../shared/entities/profile';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';

@Component({
  selector: 'app-renewables',
  templateUrl: './renewables.component.html',
  styleUrls: ['./renewables.component.scss']
})
export class RenewablesComponent implements OnInit, OnDestroy {
  readonly epaGreenhouseGasCalculatorUrl = 'https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator';
  @ViewChild(MaintenanceModalComponent, {static : false}) maintenanceModal: MaintenanceModalComponent;
  @ViewChild(GenerationDetailExportModalComponent, {static : false}) generationDetailExportModal: GenerationDetailExportModalComponent;
  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  private readonly renewablesThemeClass = 'renewables-theme';
  private readonly unsubscribe$ = new Subject();

  isSuperUser: boolean;
  isSuperAdmin: boolean;
  showRenewables: boolean;
  selectedAccount: IUserAccount;

  constructor(
    private dataService: DataService,
    @Inject(DOCUMENT) private document: Document,
    private renewablesService: RenewablesService,
    private portalService: PortalService) { }


  ngOnInit() {
    this.dataService.setTitleSource('Renewables & Sustainability');
    this.dataService.setSelectedNavItem('tools');
    this.setCurrentActivePage('tools')
    if (!this.document.body.classList.contains(this.renewablesThemeClass)) {
      this.document.body.classList.add(this.renewablesThemeClass);
    }
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);

    this.dataService.loadingUpdated.subscribe(loading => this.onSubmit(loading));

    this.selectedAccount = this.dataService.getAccountSource();
    if (this.selectedAccount) {
      this.showRenewables = this.portalService.getRenewablesFlag(this.selectedAccount);
    } 
    else{
      this.showRenewables = false;
    }

    this.renewablesService.getSites()
      .takeUntil(this.unsubscribe$)
      .subscribe();

    this.renewablesService.getEquivalencies()
      .takeUntil(this.unsubscribe$)
      .subscribe();

    this.dataService.generationDetailExportTriggered
      .takeUntil(this.unsubscribe$)
      .subscribe(() => {
        this.generationDetailExportModal.open();
      });
  }


  ngOnDestroy() {
    if (this.document.body.classList.contains(this.renewablesThemeClass)) {
      this.document.body.classList.remove(this.renewablesThemeClass);
    }
    // When we unload the renewables pages, be sure to clear out the site list
    this.renewablesService.resetData();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dataService.setContextualHelp(true);
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  onSubmit(show: boolean) {
    if(show) {
      this.spinner.show('Exporting ...');
    } else {
      this.spinner.hide();
    }
  }
}
