import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../shared/data.service";
import { OrderByPipe } from "../shared/pipe/orderBy.pipe";
import { PagerDenoms } from "../shared/const/pagerDenoms";
import { IPagerDenom } from "../shared/const/pagerDenoms";
import { Pager, PagerService } from "../shared/pager.service";
import {
  BrokerDrop,
  DropReason,
  IBrokerDropRequest,
  TSDP,
} from "../shared/entities/broker";
import * as moment from "moment";
import { DateRangeSelectorComponent } from "../shared/components/date-range-selector/date-range-selector.component";
import { IUserAccount } from "../shared/entities/profile";
import { Subscription } from "rxjs";
import { BrokerService } from "../tp-payout-report/broker.service";
import { FadeAnimation } from "../animations/fadeAnimation";
import { FakerService } from "../shared/faker.service";
import { FilterType, ISubscribeReportTemplate } from "../shared/entities/subscribeReportTemplate";
import { IDropReportSetting, IGenericReportSetting } from "../shared/entities/reportSettings";
import { ReportDataUtility } from "../subscribe-to-report/report-data.utility";
import { ReportSettingsService } from "../subscribe-to-report/report-settings.service";
declare var $: any;

// TODO: when typescript >= 3.5.3
// override the Pager interface property type of "any" set for "items"
// to use specific type like BrokerDropsPager as defined below for better error checking.
// refer: https://stackoverflow.com/a/51507473
// export interface BrokerDropsPager extends Omit<Pager, "items"> {
//   items: BrokerDrop[];
// }

@Component({
  selector: "app-broker-drop",
  templateUrl: "./broker-drop.component.html",
  styleUrls: ["./broker-drop.component.scss"],
  providers: [OrderByPipe],
  animations: [FadeAnimation],
})
export class BrokerDropComponent implements OnInit {
  @ViewChild(DateRangeSelectorComponent, { static: false })
  dateRangeSelector: DateRangeSelectorComponent;
  maskMode: boolean;
  myAccount: IUserAccount;
  showSearchBar = false;
  searchTerm: string = "";
  ascending: boolean = true;
  sortBy: string = "confirmEffectiveDt";
  brokerDrops: BrokerDrop[] = [];
  filteredBrokerDrops: BrokerDrop[] = [];
  pagerDenoms: IPagerDenom[];
  pager: Pager = null;
  selectedDenom: IPagerDenom;
  showTdspEdc: boolean = false;
  maxDays: number = 30;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  initialStartDate: Date = new Date();
  startDate: Date = new Date();
  endDate: Date = new Date();
  readonly All = "All";
  tdspOptions: string[] = [this.All];
  selectedTdsp: string = this.tdspOptions[0]; // used for backend api
  displaySelectedTdsp: string = ""; // used for UI display
  noDataError: boolean = false;
  displayLimitError: boolean = false;

  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  exportSubscription: Subscription;
  brokerSubscription: Subscription;
  exportSubs: Subscription;

 /**************************************************************
  Variables For Subscribe to Report Template - START
  **************************************************************/ 
  reportSettings: IGenericReportSetting[]; // List of All Report Settings for Subscribe To Report Template
  privateReports: IGenericReportSetting[]; // List of Private Report Settings for Subscribe To Report Template
  publicReports: IGenericReportSetting[];  // List of Private Report Settings for Subscribe To Report Template
  reportsLoaded: boolean = false;          // Boolean that tells us if Reports have loaded, used to Render Subscribe to Report Template
  newReportName = '';                      // Report Name used for saving a report
  makeDefault: boolean = true;             // Boolean Flag used for saving default report
  makePublic: boolean = false;             // Boolean Flag used for saving public or private reports
  selectedReport: IGenericReportSetting;   // Report that is used to generate data for the table and for saving/editing/deleting reports
  reportSubscriptionRangesSourceSubscription: Subscription; // Used to get the valid date ranges for Reports
  datePeriods: any[];                      // Helper for displaying different date periods for the filter (3 Months, 6 Months, etc.)
  reportDateRanges: any[];                 // Stores valid date ranges for reports
  endDates: any[];                         // Stores end date ranges for reports
  dateRangeControl: any = {                // Used to track date range selections from user inputs
    periodSelected: true,
    toggled: false,
    show: false,
    startDate: {},
    endDate: {},
    datePeriod: 'Last3Months',
    datePeriodDisplay: 'Last 3 Months',
    dateRangeDisplay: '',
  };
  reportTemplate: ISubscribeReportTemplate = { // Holds the information about the specific report and builds child SubscribeToReportTemplate with correct filters
    title: "Broker Drop Report",
    defaultReportName: "Drop Report 01",
    reportType: 'DROP_REPORT',
    description: "This is a sample report description.",
    filters: [
      { // This is the first filter
        name: "Drop Date Range",
        filterType: FilterType.DatePicker,
        options: [
          { label: "Choice1", value: "choice1" }, // These are unused DatePicker is a special filter type
          { label: "Choice2", value: "choice2" }, // Never the less this is the pattern you would use to load a filter
          { label: "Choice3", value: "choice3" },
          { label: "Choice4", value: "choice4" },
          { label: "Choice5", value: "choice5" },
        ],
        selectedOptionLabel:"Last 3 Months", // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        selectedOptionValue: "Last3Months",  // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        defaultOptionLabel: "Last 3 Months", // Default Option
        defaultOptionValue: "Last3Months",   // Default Option
        showfilter: false
      },
    ],
  };
   /**************************************************************
   Variables For Subscribe to Report Template - END
   **************************************************************/ 

  constructor(
    private orderByPipe: OrderByPipe,
    private dataService: DataService,
    private pagerService: PagerService,
    private brokerService: BrokerService,
    private reportSettingsService: ReportSettingsService,
    private fakerService: FakerService,
    private utility: ReportDataUtility,

  ) {}

  ngOnInit(): void {
    this.displaySelectedTdsp = this.tdspOptions[0].length < 30 ? this.tdspOptions[0] : this.tdspOptions[0].substr(0, 30) + '...';
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );
    this.dataService.setTitleSource("Drop Report");
    this.dataService.setCurrentActivePage("tools");
    this.dataService.setSelectedNavItem("tools");
    this.myAccount = this.dataService.getAccountSource();
    this.initDateRangeValues();
    this.tdspOptions = this.tdspOptions.concat(
      Object.keys(TSDP)
        .map((key) => {
          return TSDP[key];
        })
        .sort()
    );
    this.reportTemplate.filters.forEach(filter =>{
      if(filter.filterType === FilterType.DatePicker) {
        filter.selectedOptionLabel = this.dateRangeControl.datePeriodDisplay;
        this.reportSubscriptionRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
          (dateRanges) => {
            this.reportDateRanges = dateRanges;
            this.endDates = dateRanges;
          });
      }
    });
    this.utility.calculateDateRanges();
    if (this.myAccount) {
      this.getReportSettings(true);
    }
    this.accountSourceSubscription =
      this.dataService.accountSourceUpdated.subscribe((account) => {
        this.myAccount = account;
        this.getBrokerDrops();
      });
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if (denom.count == 10) {
        this.selectDenom(denom);
      }
    });
    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.exportDropReport();
    });

    document.addEventListener("click", ($event) => {
      if ($($event.target).parents("#tdspDropdown").length == 0) {
        this.showTdspEdc = false;
      }
    });

  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) {
      this.accountSourceSubscription.unsubscribe();
    }
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }
    if (this.exportSubscription) {
      this.exportSubscription.unsubscribe();
    }
    if (this.brokerSubscription) {
      this.brokerSubscription.unsubscribe();
    }
    if (this.exportSubs) {
      this.exportSubs.unsubscribe();
    }
    if (this.reportSubscriptionRangesSourceSubscription){
      this.reportSubscriptionRangesSourceSubscription.unsubscribe();
    }
  }

  initDateRangeValues() {
    this.maxDays = moment(new Date()).diff(
      moment(new Date()).subtract(3, "years"),
      "days"
    );
    this.minDate = moment(new Date()).subtract(3, "years").toDate();
    this.maxDate = moment(new Date()).toDate();
    this.initialStartDate = moment(this.maxDate)
      .subtract(30, "day")
      .endOf("day")
      .toDate();
    this.startDate = this.initialStartDate;
    this.endDate = this.maxDate;
  }

  exportDropReport() {
    this.dataService.setLoading(true);
    // Get start date, end date, account Id,
    let startDateString = moment(this.startDate).format("YYYY-MM-DD");
    let endDateString = moment(this.endDate).format("YYYY-MM-DD");
    if (startDateString && endDateString) {
      let request: IBrokerDropRequest = {
        accountId: this.myAccount.id,
        accountName: this.dataService.getAccountSource().name,
        startDate: startDateString,
        endDate: endDateString,
        maskingMode: this.dataService.getMaskMode(),
        brand: window.localStorage.getItem("brand"),
        tdsp:
          this.selectedTdsp === this.All
            ? this.All.toUpperCase()
            : this.selectedTdsp,
      };
      this.exportSubs = this.brokerService.exportBrokerDrop(request);
    }
  }

  getBrokerDrops() {
    this.dataService.setLoading(true);
    let startDateString = moment(this.startDate).format("YYYY-MM-DD");
    let endDateString = moment(this.endDate).format("YYYY-MM-DD");
    if (startDateString && endDateString) {
      const request: IBrokerDropRequest = {
        accountId: this.myAccount.id,
        accountName: this.dataService.getAccountSource().name,
        startDate: startDateString,
        endDate: endDateString,
        brand: window.localStorage.getItem("brand"),
        tdsp: this.All.toUpperCase(),
      };
      this.brokerSubscription = this.brokerService
        .getBrokerDrops(request)
        .subscribe(
          (res) => {
            // convert dates into mm/dd/yyyy format for easier search
            res = res.map((item) => {
              for (const key in item) {
                if (item.hasOwnProperty(key) && key.match("Date")) {
                  item[key] = moment(item[key], "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  );
                }
              }
              return item;
            });
            this.brokerDrops = res;
            this.noDataError = this.brokerDrops.length == 0;
            this.displayLimitError = false;
            // fake records for masked mode
            this.brokerDrops = this.brokerDrops.map((dropItem) => {
              dropItem.fake = {};
              dropItem.fake.accountName = this.fakerService.getCompanyName();
              dropItem.fake.siteId = this.fakerService.getSiteId();
              dropItem.fake.contractId =
                this.fakerService.getContractAccountNum();
              dropItem.fake.street = this.fakerService.getStreetAddress();
              dropItem.fake.city = this.fakerService.getCity();
              dropItem.fake.state = this.fakerService.getState();
              dropItem.fake.zip = this.fakerService.getZipCode();
              return dropItem;
            });
            this.filterDropsReport();
            this.sortBrokerDropsByDropDate();
            this.dataService.setLoading(false);
          },
          (error) => {
            if (error.status == 404) {
              this.noDataError = true;
              this.displayLimitError = true;
            }
            if (error.status == 500) {
              this.displayLimitError = true;
              this.noDataError = false;
            }
            console.error(error);
            this.brokerDrops = [];
            this.filteredBrokerDrops = [];
            this.paginate(1);
            this.dataService.setLoading(false);
          }
        );
    }
  }

  filterDropsReport() {
    let filteredItems: BrokerDrop[] = this.brokerDrops;
    // filter by tdsp
    filteredItems =
      this.selectedTdsp.toUpperCase().trim() === this.All.toUpperCase()
        ? filteredItems
        : filteredItems.filter((item) => {
            return (
              item.tdsp.toUpperCase().trim() ===
              this.selectedTdsp.toUpperCase().trim()
            );
          });
    this.filteredBrokerDrops = JSON.parse(JSON.stringify(filteredItems));
    this.sortBrokerDropsByDropDate();
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
        this.searchTerm = "";
      }

  sortBrokerDrops(sortBy: string) {
    if (sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }

  sortBrokerDropsByCustomer() {
    // sort the customer column in ascending order
    this.ascending = true;
    this.filteredBrokerDrops = this.orderByPipe.transform(
      this.filteredBrokerDrops,
      "accountName",
      this.ascending
    );
    this.paginate(1);
  }

  sortBrokerDropsByDropDate() {
    // sort the drop date column in descending order
    this.ascending = false;
    this.filteredBrokerDrops = this.orderByPipe.transform(
      this.filteredBrokerDrops,
      "dropDate",
      this.ascending
    );
    this.paginate(1);
  }

  paginate(page: number) {
    if (this.filteredBrokerDrops) {
      this.filteredBrokerDrops = this.orderByPipe.transform(
        this.filteredBrokerDrops,
        this.sortBy,
        this.ascending
      );
      this.pager = this.pagerService.getPage(
        this.filteredBrokerDrops,
        page,
        this.selectedDenom.count
      );
    }
  }

  searchDropReport() {
    if (this.filteredBrokerDrops) {
      if (this.searchTerm) {
        this.filteredBrokerDrops = this.filterDropsBySearch();
      } else {
        this.filteredBrokerDrops = this.brokerDrops; //JSON.parse(JSON.stringify(this.filteredBrokerDrops));
      }
    }
    this.paginate(1);
  }

  filterDropsBySearch(): BrokerDrop[] {
    const term = this.searchTerm
      .toString()
      .split("")
      .filter((s) => s !== "/" && s !== "-" && s !== ".")
      .join("")
      .toLowerCase()
      .trim();
    const termMoment = moment(this.searchTerm);
    const filteredDrops: BrokerDrop[] = [];
    this.filteredBrokerDrops.forEach((drop) => {
      for (let key in drop) {
        if (drop.hasOwnProperty(key) && drop[key]!=null) {
          if (
            drop[key]
              .toString()
              .split("")
              .filter((s) => s !== "/" && s !== "-" && s !== ".")
              .join("")
              .toLowerCase()
              .trim()
              .match(term)
          ) {
            filteredDrops.push(drop);
            break;
          } else if (
            !drop[key].toString().match(/[a-z]/i) &&
            key.match("Date") &&
            moment(drop[key]).format("YYYYMMDD").match(term)
          ) {
            filteredDrops.push(drop);
            break;
          }
        }
      }
    });
    return filteredDrops;
  }

  toggleTdspEdc() {
    this.showTdspEdc = !this.showTdspEdc;
  }

  selectTdsp(tdsp: string) {
    // format: CNP - CENTERPOINT ENERGY. Extract the abbreviation.
    this.displaySelectedTdsp = tdsp.length < 30 ? tdsp : tdsp.substr(0, 30) + '...'
    this.selectedTdsp = tdsp.split("-")[0].trim();
    this.toggleTdspEdc();
    this.filterDropsReport();
  }

  /**************************************************************
  Methods For Subscribe to Report Template - Start
  **************************************************************/   
  getReportSettings(selectDefault?: boolean){
    this.utility.getReportSettings(this.myAccount.id, this.reportTemplate, this.dateRangeControl, this.datePeriods, this.reportDateRanges, this.maskMode, selectDefault, this.selectedReport, null, null)
    .subscribe({
      next: ({ reportSettings, reportTemplate, selectedReport, publicReports, privateReports, startDate, endDate }) => {
        this.reportSettings = reportSettings;
        this.selectedReport = selectedReport;
        this.publicReports = publicReports;
        this.privateReports = privateReports;
        this.startDate = startDate;
        this.endDate = endDate;
        this.reportsLoaded = true;
        reportTemplate.filters.forEach((filter) => {
          switch (filter.filterType) {
            case 'DatePicker':
              this.dateRangeControl = filter.savedFilterControl;
              break;
            case 'SitePicker':
              // Uncomment if using SitePicker
              // this.sitesControl = filter.savedFilterControl;
              break;
            default:
              // Action for other filter types
              break;
          }
        });
        this.getBrokerDrops();
      },
      error: (error) => {
        // Handle errors
      }
    });  
  }

  reportSavedHandler($event) {
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    this.makePublic = this.selectedReport.publicFlag ? this.selectedReport.publicFlag == 1 : false;
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          // this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    let isNewReport = this.selectedReport.reportId == null;

    const saveReportRequest: any = {
      reportType: this.reportTemplate.reportType,
      reportId: this.selectedReport.reportId,
      accountId: this.myAccount.id,
      reportName: this.newReportName ? this.newReportName : this.selectedReport.reportName ? this.selectedReport.reportName : 'Generic Report 01',
      defaultReport: this.selectedReport.defaultReport ? 1 : 0,
      publicFlag: this.selectedReport.publicFlag ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.endDate,
    };
    this.utility.saveReport(saveReportRequest, isNewReport)
    .then((reportId) => {
        this.selectedReport.reportId = reportId;
        this.getReportSettings();
    })
    .catch((error) => {
        // it will load default report
        this.getReportSettings(true);

    });
  }

  reportSelectedHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    this.startDate = loadSelectedReport.startDate;
    this.endDate = loadSelectedReport.endDate;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          // this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    this.getBrokerDrops();
  }

  exportHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the BillingReport Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          // this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });
    this.dataService.setLoading(true);
    this.exportDropReport();
  }

  reportDeletedHandler($event){
    this.reportSettingsService.deleteReportSetting(this.myAccount.id, this.selectedReport.reportId, this.dataService.getUserSource().login,this.reportTemplate.reportType, this.selectedReport.publicFlag).subscribe(
      () => {
        this.getReportSettings(true);
      }
    );
  }
  /**************************************************************
  Methods For Subscribe to Report Template - End
  **************************************************************/      
}
