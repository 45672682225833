import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { PreAuthDataService } from '../pre-auth-data.service';
import { nrgNav } from '../../../assets/api/nrg-nav/nrg-nav';


@Component({
  selector: 'app-pre-auth-footer',
  templateUrl: './pre-auth-footer.component.html',
  styleUrls: ['./pre-auth-footer.component.scss']
})
export class PreAuthFooterComponent implements OnInit {
  layoutType:string;
  footerContent: any | null = null;
  isMobile: boolean;
  currentDate: Date;

  constructor(
    private preAuthDataService: PreAuthDataService,
    private dataService: DataService
    ) { }

  ngOnInit() {
    if (window.screen.width <= 360) { // 768px portrait
      this.isMobile = true;
    }
    this.dataService.setLoading(true);
    this.currentDate = new Date();
    this.getNavigationData();
    console.log(this.footerContent);
  }

  getNavigationData() {
    let response = this.preAuthDataService.getFooterNavData();


    if(response != null) {
      response.subscribe((footerResponse : any) => {
        this.layoutType = footerResponse.layoutType;
        if (footerResponse.content !== undefined) {
          this.footerContent = footerResponse.content.payload;
          this.footerContent.copyrightText = this.currentDate.getFullYear().toString() + " " + this.footerContent.copyrightText;
        }
        this.dataService.setLoading(false);
      },(error)=>{
        let footerResponse : any = this.preAuthDataService.mapToType(nrgNav,"Footer");
        this.layoutType = footerResponse.layoutType;
        if (footerResponse.content !== undefined) {
          this.footerContent = footerResponse.content.payload;
          this.footerContent.copyrightText = this.currentDate.getFullYear().toString() + " " + this.footerContent.copyrightText;

        }
        this.dataService.setLoading(false);
      });
    } else {
      this.dataService.setLoading(false);
    }
  }
  navigateTo(dest: string) {
    window.location.href= dest;
  }
}
