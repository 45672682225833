import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MarketsService } from '../markets.service';
import {
  IMarketData,
  ErcotMarketSources,
  IOffset,
  IMarketComparison,
  ErcotMarketSection,
  MarketGraphComparison,
  ComparisonOptionItem,
  ErcotGenerationMixProperties,
  Market,
  PJMMarketSection,
  ERCOTSnapshotComparisonOptions,
  ERCOTInfoComparisonOptions,
  PJMInfoComparisonOptions,
  PJMSnapshotComparisonOptions,
  IMarketProperties,
  PjmGenerationMixProperties,
  PjmMarketSources
} from '../../shared/entities/markets';
import { DataService } from '../../shared/data.service';
import { IUserAccount } from '../../shared/entities/profile';
declare var Highcharts: any;
declare var $: any;
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { Subscription, forkJoin } from 'rxjs';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { PortalService } from '../../shared/portal.service';
import { IMarketSetting } from '../../shared/entities/marketSetting';
import { UtilsService } from '../../shared/utils.service';
import { catchError, map } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FadeAnimation } from '../../animations/fadeAnimation';

@Component({
  selector: 'app-markets-new-section',
  templateUrl: './markets-new-section.component.html',
  styleUrls: ['./markets-new-section.component.scss'],
  animations: [FadeAnimation]
})
export class MarketsNewSectionComponent implements OnInit, OnChanges {
  @Input() properties: any; // for some reason, IMarketProperties throws an annoying Warning
  marketSnapshotChart;
  marketHistoryChart;
  marketGenerationMixChart;
  chartName: string;
  marketData: IMarketData[];
  myAccount: IUserAccount;
  selectedOffset: IOffset;
  selectedFutureOffset: IOffset;
  selectedComparisons: IMarketComparison[] = [];
  defaultComparisons: IMarketComparison[] = [];
  legendSelection: IMarketComparison[] = [];
  generationMixLegendSelection: IMarketComparison[] = [];
  pricesLegendSelection: IMarketComparison[] = [];
  generationMixProperties: IMarketProperties = null;
  marketSources: any = null;
  marketDataTicker = 0;
  marketDates: any[];
  forecastStartDate: any;
  actualLoadEndDate: any;
  marketYAxis: any[];
  marketXAxis: any[] = [];
  datasets: any[];
  generationMixDatasets: any[];
  loading: boolean = false;
  settings: IMarketSetting[];
  isMobile: boolean;
  minInterval: number = 0;
  ctr: number = 0;
  maxSelect: boolean = false;
  expandedGroup: string = '';
  minStartDate: Date = new Date();
  maxStartDate: Date = new Date();
  minEndDate: Date = new Date();
  maxEndDate: Date = new Date();
  startDateOverride: Date = new Date();
  endDateOverride: Date = new Date();
  editStartDate: Date = new Date();
  editEndDate: Date = new Date();

  showComparisons: boolean;
  showIndicators: boolean;
  showLayerComparision = false;
  origMarketGraphComparisonOptions: MarketGraphComparison[] = [];
  marketGraphComparisonOptions: MarketGraphComparison[] = [];
  marketGraphComparisonItems: ComparisonOptionItem[] = [];
  showHorizontalLegend = false;
  marketSection: any;
  marketSettings: MarketGraphComparison[] = [];
  timezone: string;
  readonly generationMixParent = 'GENERATION MIX';
  readonly pricesParent = 'PRICES';
  Market = Market;

  accountSubscription: Subscription;
  loadingSubscription: Subscription;
  refreshSubscription: Subscription;
  maskMode: boolean;
  maskModeSubscription: Subscription;
  marketChartLegendToggleSubs: Subscription;
  settingsSubs: Subscription;
  marketDataSubs: Subscription;
  updateSettingsSubs: Subscription;
  updateNewSettingsSubs: Subscription;

  constructor(
    private marketsService: MarketsService,
    private dataService: DataService,
    private portalService: PortalService,
    private utilsService: UtilsService,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnChanges() {
    this.setMarketProperties();
    this.initDateRangeValues();
    this.chartName = this.properties.name + '-chart';
    this.selectedOffset = this.properties.defaultOffset;
    this.selectedFutureOffset = this.properties.defaultFutureOffset;
  }

  ngOnInit() {
    this.dataService.setCurrentActivePage('tools');
    this.maskMode = this.dataService.getMaskMode();
    this.myAccount = this.dataService.getAccountSource();
    this.isMobile = this.dataService.getIsMobile();
    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });
    this.dataService.selectedMarketViewChanged.subscribe(() => {
      this.getMarketDashboardViewSettings();
    });
    const interval =
      this.properties.name == this.marketSection.Info ? 900000 : 150000;
    this.refreshSubscription = IntervalObservable.create(interval).subscribe(
      () => {
        this.getMarketData();
      }
    );
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe(
      () => {
        this.getMarketData();
      }
    );
    this.marketChartLegendToggleSubs =
      this.dataService.marketChartLegendToggle.subscribe(() => {
        if (this.chartName !== this.marketSection.Info + '-chart') {
          this.reRenderMarketChart();
        }
      });
    // Click outside event for date range and groupings sections
    document.addEventListener('click', ($event) => {
      if (
        $($event.target).parents(
          `#graph-comparison-section${this.properties.name}`
        ).length == 0
      ) {
        this.showLayerComparision = false;
      }
    });
    this.loadingSubscription = this.dataService.localLoaderUpdated.subscribe(
      (loader) => {
        if ('MARKETS-' + this.properties.name == loader.id) {
          this.loading = loader.loading;
        }
      }
    );
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(max-width: 768px)']) {
          this.showHorizontalLegend = true;
        } else {
          this.showHorizontalLegend = false;
        }
      });
    let resizeTimer;
    const component = this;
    $(window).resize(function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        component.reRenderMarketChart();
      }, 300);
    });
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }
    if (this.marketChartLegendToggleSubs) {
      this.marketChartLegendToggleSubs.unsubscribe();
    }
    if (this.settingsSubs) {
      this.settingsSubs.unsubscribe();
    }
    if (this.marketDataSubs) {
      this.marketDataSubs.unsubscribe();
    }
    if (this.updateSettingsSubs) {
      this.updateSettingsSubs.unsubscribe();
    }
    if (this.updateNewSettingsSubs) {
      this.updateNewSettingsSubs.unsubscribe();
    }
  }

  setMarketProperties() {
    if (this.properties.market === Market.ERCOT) {
      this.marketSection = ErcotMarketSection;
      this.timezone = 'CT';
      this.dataService.setMarketInfoSettings(ERCOTInfoComparisonOptions);
      this.dataService.setMarketSnapShotGenMixSettings(
        ERCOTSnapshotComparisonOptions
      );
      this.generationMixProperties = ErcotGenerationMixProperties;
      this.marketSources = ErcotMarketSources;
    } else if (this.properties.market === Market.PJM) {
      this.marketSection = PJMMarketSection;
      this.timezone = 'ET';
      this.dataService.setMarketInfoSettings(PJMInfoComparisonOptions);
      this.dataService.setMarketSnapShotGenMixSettings(
        PJMSnapshotComparisonOptions
      );
      this.generationMixProperties = PjmGenerationMixProperties;
      this.marketSources = PjmMarketSources;
    }
  }

  getMarketDashboardViewSettings() {
    this.dataService.setLoading(true);
    this.settingsSubs = this.marketsService
      .getMarketViewSettings(
        this.dataService.selectedMarketViewName,
        this.properties.market + '_' + this.properties.name
      )
      .subscribe(
        (res) => {
          if (this.properties.name === this.marketSection.Info) {
            this.dataService.dbInfoMarketGraphSettings = res;
          } else {
            this.dataService.dbSnapshotMarketGraphSettings = res;
          }
          this.setMarketGraphComparisonOptions();
          if (this.myAccount) {
            this.getMarketData();
          }
          this.dataService.setLoading(false);
        },
        (error) => {
          console.log('Error fetching market settings: ', error);
          this.dataService.dbInfoMarketGraphSettings = [];
          this.dataService.dbSnapshotMarketGraphSettings = [];
          if (this.myAccount) {
            this.getMarketData();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  initDateRangeValues() {
    this.minStartDate.setDate(
      this.properties.name === this.marketSection.Info
        ? new Date().getDate() - 1095
        : new Date().getDate() - 3
    );
    this.maxStartDate.setDate(new Date().getDate());
    this.minEndDate.setDate(
      this.properties.name === this.marketSection.Info
        ? new Date().getDate() - 1095
        : new Date().getDate() + 1
    );
    this.maxEndDate.setDate(
      this.properties.name === this.marketSection.Info
        ? new Date().getDate()
        : new Date().getDate() + 7
    );
    this.startDateOverride = new Date();
    this.endDateOverride = new Date();
    if (this.properties.name !== this.marketSection.Info) {
      this.startDateOverride.setDate(new Date().getDate() - 1);
      this.endDateOverride.setDate(new Date().getDate() + 1);
    } else {
      this.startDateOverride.setDate(new Date().getDate() - 30);
      this.endDateOverride.setDate(new Date().getDate());
      if (
        this.properties.name === this.marketSection.Info &&
        this.properties.market === Market.PJM
      ) {
        this.minStartDate = moment().subtract(18, 'months').toDate();
        this.minEndDate = moment().subtract(18, 'months').toDate();
      }
    }
    // setting these dates to trigger onchanges in date range component
    this.editStartDate = null;
    this.editEndDate = null;
  }

  setMarketGraphComparisonOptions() {
    let settings: IMarketSetting[] = [];
    if (this.properties.name === this.marketSection.Info) {
      this.origMarketGraphComparisonOptions = this.utilsService.clone(
        this.dataService.marketInfoSettings
      );
      if (this.dataService.dbInfoMarketGraphSettings.length) {
        this.origMarketGraphComparisonOptions =
          this.origMarketGraphComparisonOptions.map((item) => {
            item.items = item.items.map((subItem) => {
              subItem.selected = false;
              return subItem;
            });
            item.count = item.items.filter((si) => si.selected).length;
            return item;
          });
      }
      if (!this.dataService.dbInfoMarketGraphSettings.length) {
        this.dataService.dbInfoMarketGraphSettings =
          this.processMarketGraphComparisonOptions();
      }
      settings = this.dataService.dbInfoMarketGraphSettings;
      this.dataService.newDbInfoMarketGraphSettings = this.utilsService.clone(
        this.dataService.dbInfoMarketGraphSettings
      );
    } else {
      this.origMarketGraphComparisonOptions = this.utilsService.clone(
        this.dataService.marketSnapShotGenMixSettings
      );
      if (this.dataService.dbSnapshotMarketGraphSettings.length) {
        this.origMarketGraphComparisonOptions =
          this.origMarketGraphComparisonOptions.map((item) => {
            item.items = item.items.map((subItem) => {
              subItem.selected = false;
              return subItem;
            });
            item.count = item.items.filter((si) => si.selected).length;
            return item;
          });
      }
      if (!this.dataService.dbSnapshotMarketGraphSettings.length) {
        this.dataService.dbSnapshotMarketGraphSettings =
          this.processMarketGraphComparisonOptions();
      }
      settings = this.dataService.dbSnapshotMarketGraphSettings;
      this.dataService.newDbSnapshotMarketGraphSettings =
        this.utilsService.clone(this.dataService.dbSnapshotMarketGraphSettings);
    }
    // TODO if we are saving only selected settings in the db and if we find saved settings in the db,
    // we need to reset all the default settings to false (unselected) before iterating thru the db list.
    // This way, if the default settings have any true (selected) values they won't interfere with the db settings.
    settings.forEach((setting) => {
      if (setting && setting.fieldName) {
        this.origMarketGraphComparisonOptions.forEach((option) => {
          const selecteditem = option.items.find(
            (item) => item.source === setting.fieldName
          );
          if (selecteditem) {
            selecteditem.selected = setting.fieldValue === 'true';
            // flatten the items inside comparison options in an array
            option.count = option.items.filter((item) => item.selected).length;
          }
        });
      }
    });
    this.marketGraphComparisonOptions = this.utilsService.clone(
      this.origMarketGraphComparisonOptions
    );
  }

  getMarketData() {
    this.dataService.setLoading(true);
    let marketDataObs: Observable<IMarketData>[] = [];
    let subsCount = 0;
    this.portalService.spinBabySpin('spinner-' + this.properties.name);
    this.dataService.setLocalLoader('MARKETS-' + this.properties.name, true);
    // flatten the items inside comparison options in an array
    this.marketGraphComparisonItems = [];
    this.origMarketGraphComparisonOptions.forEach((option) => {
      this.marketGraphComparisonItems = this.marketGraphComparisonItems.concat(
        option.items
      );
    });
    this.marketData = [];
    this.marketDataTicker = 0;
    this.legendSelection = this.getLegendSelection();
    this.generationMixLegendSelection = this.getGenerationMixLegendSelection();
    this.pricesLegendSelection = this.getPriceLegendSelection();
    this.origMarketGraphComparisonOptions.forEach((option) => {
      option.items.forEach((item) => {
        if (item.selected) {
          subsCount++;
          marketDataObs.push(
            this.marketsService.getMarketData(
              item.source,
              item.source.match('FORECAST') ||
                this.properties.name === this.marketSection.Info
                ? this.selectedFutureOffset
                : this.selectedOffset,
              option.text === this.generationMixParent
                ? this.generationMixProperties.dataUrl
                : this.properties.dataUrl,
              this.properties.name,
              item.source.match('FORECAST') ||
                this.properties.name === this.marketSection.Info
                ? this.selectedOffset
                : null
            )
          );
          if (
            item.parent === this.generationMixParent &&
            (item.source === `${this.properties.market}_SOLAR` ||
              item.source === `${this.properties.market}_WIND`)
          ) {
            marketDataObs.push(
              this.marketsService.getMarketData(
                item.source + '_FORECAST',
                this.selectedFutureOffset,
                this.generationMixProperties.dataUrl,
                null,
                this.selectedOffset
              )
            );
          }
        }
      });
    });
    // do error handling before hand so that forkjoin subscription does not fail
    const marketDataReqs = marketDataObs.map((data) =>
      data
        .pipe(map((result) => ({ success: true, result })))
        .pipe(catchError((result) => of({ success: false, result })))
    );
    this.marketDataSubs = forkJoin(marketDataReqs).subscribe(
      (marketData: any) => {
        // filter the successful results
        this.marketData = marketData
          .filter((r) => r.success)
          .map((r) => r.result);
        this.marketDataTick();
        this.dataService.setLoading(false);
      },
      (error) => {
        console.error(error);
        this.dataService.setLoading(false);
      }
    );
  }

  getLegendSelection(): any[] {
    const snapshotLegend = this.utilsService.clone(
      this.marketGraphComparisonItems.filter(
        (item) => item.selected && item.parent !== this.pricesParent
      )
    );
    const solar = snapshotLegend.find(
      (item) => item.source === this.properties.market + '_SOLAR'
    );
    const wind = snapshotLegend.find(
      (item) => item.source === this.properties.market + '_WIND'
    );
    if (solar) {
      const solarForecast = {
        parent: this.generationMixParent,
        text: 'Solar Forecast',
        selected: true,
        source: `${this.properties.market}_SOLAR_FORECAST`,
        hexColor: '#F3934F'
      };
      snapshotLegend.push(solarForecast);
    }
    if (wind) {
      const windForecast = {
        parent: this.generationMixParent,
        text: 'Wind Forecast',
        selected: true,
        source: `${this.properties.market}_WIND_FORECAST`,
        hexColor: '#7FCFFF'
      };
      snapshotLegend.push(windForecast);
    }
    return this.properties.name === this.marketSection.Snapshot
      ? snapshotLegend
      : this.marketGraphComparisonItems.filter((item) => item.selected);
  }

  getGenerationMixLegendSelection(): any[] {
    return this.marketGraphComparisonItems.filter(
      (item) => item.selected && item.parent === this.generationMixParent
    );
  }

  getPriceLegendSelection(): any[] {
    return this.marketGraphComparisonItems.filter(
      (item) => item.selected && item.parent === this.pricesParent
    );
  }

  marketDataTick() {
    this.dataService.setLocalLoader('MARKETS-' + this.properties.name, false);
    if (this.marketData) {
      this.getDatesAndData();
      this.createChart();
      this.dataService.showMarketDBlegend = true;
    }
  }

  toggleComparisons() {
    this.showComparisons = !this.showComparisons;
  }

  toggleIndicators() {
    this.showIndicators = !this.showIndicators;
  }

  getFormattedDate(date: string) {
    return moment(date).format('MM/DD/YY HH:mm');
  }

  getMarketDates() {
    // Get Smallest Interval with Min/Max Dates
    let dates, maxDate, minDate;
    for (let dataSource of this.marketData) {
      if (!dataSource || !dataSource.sources || !dataSource.sources.length) {
        continue;
      }
      dates = dataSource.sources[0].dates;
      if (dates.length) {
        if (
          !this.minInterval ||
          moment(dates[1]).diff(dates[0], 'minutes') < this.minInterval
        ) {
          this.minInterval = moment(dates[1]).diff(dates[0], 'minutes');
        }
        if (moment(dates[0]).isBefore(moment(minDate)) || !minDate) {
          minDate = dates[0];
        }
        if (
          moment(dates[dates.length - 1]).isAfter(moment(maxDate)) ||
          !maxDate
        ) {
          maxDate = dates[dates.length - 1];
        }
      }
    }

    // Generate Dates
    this.marketDates = [];
    let currDate = moment(minDate);
    while (currDate.isBefore(moment(maxDate))) {
      this.marketDates.push(currDate.toDate().getTime());
      currDate = currDate.add(this.minInterval, 'minutes');
    }
    // push the last maxDate to marketDates
    if (currDate.isSame(moment(maxDate))) {
      this.marketDates.push(currDate.toDate().getTime());
    }
  }

  adjustMarketData() {
    let actualLoadEndDate;
    for (let dataSource of this.marketData) {
      if (!dataSource || !dataSource.sources || !dataSource.sources.length) {
        continue;
      }
      let sourceDates = dataSource.sources[0].dates;
      if (!sourceDates.length) {
        continue;
      }
      let oldVals = Object.assign([], dataSource.sources[0].values);
      let interval = moment(sourceDates[1]).diff(
        moment(sourceDates[0]),
        'minutes'
      );
      interval = interval / this.minInterval;
      if (interval == 1) {
        interval = 0;
      } else {
        interval--;
      }
      if (
        dataSource.sources[0].source &&
        !dataSource.sources[0].source.match('FORECAST') &&
        !dataSource.sources[0].source.match('DA_')
      ) {
        actualLoadEndDate = moment(sourceDates[sourceDates.length - 1]);
        if (
          !this.actualLoadEndDate ||
          this.actualLoadEndDate.isBefore(actualLoadEndDate)
        ) {
          this.actualLoadEndDate = actualLoadEndDate;
        }
      }
      if (sourceDates.length < this.marketDates.length) {
        let j = 0;
        let k = 0; // number of points filled for each loop

        let newVal;
        for (let i = 0; i < sourceDates.length - 1; i++) {
          if (i != 0 && i != sourceDates.length - 1) {
            newVal = oldVals[i];
          } else {
            newVal = null;
          }
          k = 0;
          while (k < interval) {
            dataSource.sources[0].values.splice(j, 0, newVal);
            k++;
            j++;
          }
          k = 0;
          j++;
        }
      }
    }
  }

  loadChartData() {
    let name, data;
    let first = true;
    this.marketGraphComparisonItems.forEach((item) => {
      name = item.source;
      data = [];
      for (let dataSource of this.marketData) {
        if (!dataSource || !dataSource.sources || !dataSource.sources.length) {
          continue;
        }
        if (item.source == dataSource.sources[0].source) {
          if (dataSource.sources[0]) {
            name = dataSource.sources[0].source;
            data = dataSource.sources[0].values;
          }
        }
      }
      let xyData = [],
        xyObj = {},
        x;
      if (item.selected) {
        for (let i = 0; i < this.marketDates.length; i++) {
          xyObj = {
            y: data[i],
            x: this.marketDates[i]
          };
          xyData.push(xyObj);
        }
      }
      this.datasets.push({
        componentUsed: item.parent || this.properties.name,
        id: item.source,
        name: name,
        display: item.text,
        type:
          name.match(`${this.properties.market}_ACTUAL_LOAD`) &&
          this.properties.name === this.marketSection.Info
            ? 'area'
            : 'line',
        data: xyData,
        yAxis:
          name.match('LZ') || name.match('HB') || name.match('INF') ? 1 : 0,
        tooltip: {
          valueDecimals: 3
        },
        turboThreshold: 1000000,
        first: item.selected ? first : false
      });
      if (item.selected) {
        first = false;
      }
    });
    const generationMixForecast = this.marketGraphComparisonItems.filter(
      (item) =>
        item.parent === this.generationMixParent &&
        (item.source === `${this.properties.market}_SOLAR` ||
          item.source === `${this.properties.market}_WIND`)
    );
    console.log('gen mix forecast items: ', generationMixForecast);
    if (generationMixForecast && generationMixForecast.length) {
      generationMixForecast.forEach((item) => {
        name = item.source + '_FORECAST';
        data = [];
        for (let dataSource of this.marketData) {
          if (
            !dataSource ||
            !dataSource.sources ||
            !dataSource.sources.length
          ) {
            continue;
          }
          if (item.source + '_FORECAST' == dataSource.sources[0].source) {
            if (dataSource.sources[0]) {
              name = dataSource.sources[0].source;
              data = dataSource.sources[0].values;
            }
          }
        }
        let xyData = [],
          xyObj = {},
          x;
        if (item.selected) {
          for (let i = 0; i < this.marketDates.length; i++) {
            xyObj = {
              y: data[i],
              x: this.marketDates[i]
            };
            xyData.push(xyObj);
          }
        }
        this.datasets.push({
          componentUsed: item.parent || this.properties.name,
          id: item.source + '_FORECAST',
          name: name,
          display: item.text,
          type:
            name.match(`${this.properties.market}_ACTUAL_LOAD`) ||
            name.match(`${this.properties.market}_FORECAST_LOAD`)
              ? 'area'
              : 'line',
          data: xyData,
          yAxis: 0,
          tooltip: {
            valueDecimals: 3
          },
          turboThreshold: 1000000,
          first: item.selected ? first : false
        });
        if (item.selected) {
          first = false;
        }
      });
    }
    if (this.properties.name === this.marketSection.Snapshot) {
      this.generationMixDatasets = this.utilsService.clone(
        this.datasets
          .filter((set) => {
            return set.componentUsed === this.generationMixParent;
          })
          .map((item) => {
            item.type = 'line';
            return item;
          })
      );
    }
    console.log('data sets: ', this.utilsService.clone(this.datasets));
  }

  getXAxis() {
    this.marketXAxis = [
      {
        tickColor: 'transparent',
        minorTickLength: 0,
        tickLength: 30,
        plotBands: [
          {
            color: '#fcfcfc',
            from:
              this.marketDates && this.marketDates.length
                ? this.marketDates[0]
                : null,
            to: this.actualLoadEndDate
          }
        ],
        plotLines: [
          {
            width: 2,
            value: this.actualLoadEndDate,
            color: '#505053'
          }
        ]
      }
    ];
  }

  getYAxis() {
    if (this.properties.name === this.marketSection.Info) {
      this.marketYAxis = [
        {
          // Primary yAxis
          labels: {
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);

              // Use thousands separator for four-digit numbers too
              if (/^[0-9]{4}$/.test(label)) {
                return this.value / 1000 + 'K';
              }
              return label;
            }
          },
          title: {
            text: 'Demand in MW',
            align: 'high',
            offset: -50,
            rotation: 0,
            y: -20
          },
          opposite: false,
          plotLines: []
        },
        {
          // Secondary yAxis
          title: {
            className: '',
            text: 'Price',
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -20
          },
          labels: {
            format: '${value}'
          },
          lineWidth: 1,
          plotLines: []
        }
      ];
    } else {
      this.marketYAxis = [
        {
          // yAxis (load + gen mix)
          labels: {
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);

              // Use thousands separator for four-digit numbers too
              if (/^[0-9]{4}$/.test(label)) {
                return this.value / 1000 + 'K';
              }
              return label;
            }
          },
          title: {
            text: 'Demand in MW',
            align: 'high',
            offset: -50,
            rotation: 0,
            y: -20
          },
          opposite: false,
          plotLines: [],
          height: '50%',
          offset: 0
        },
        {
          // yAxis (Price)
          top: '50%',
          title: {
            className: '',
            text: 'Price',
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -12
          },
          labels: {
            format: '${value}'
          },
          opposite: false,
          lineWidth: 1,
          plotLines: [],
          height: '50%',
          offset: 0
        },
        {
          // opposite yAxis (load + gen mix)
          title: {
            className: '',
            text: '',
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -20
          },
          labels: {
            format: '${value}'
          },
          lineWidth: 1,
          plotLines: [],
          height: '50%',
          offset: 0
        },
        {
          // opposite yAxis (price)
          top: '50%',
          title: {
            className: '',
            text: '',
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -20
          },
          labels: {
            format: '${value}'
          },
          lineWidth: 1,
          plotLines: [],
          height: '50%',
          offset: 0
        }
      ];
    }
  }

  getDatesAndData() {
    this.datasets = [];
    this.marketDates = [];
    this.getMarketDates();
    this.adjustMarketData();
    this.loadChartData();
    this.getYAxis();
    this.getXAxis();
  }

  getGradients() {
    return {
      gradient0: {
        tagName: 'linearGradient',
        id: 'gradient-0',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [
          {
            tagName: 'stop',
            offset: 0
          },
          {
            tagName: 'stop',
            offset: 1
          }
        ]
      },
      gradient1: {
        tagName: 'linearGradient',
        id: 'gradient-1',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [
          {
            tagName: 'stop',
            offset: 0
          },
          {
            tagName: 'stop',
            offset: 1
          }
        ]
      }
    };
  }

  createChart() {
    if (this.chartName === this.marketSection.Snapshot + '-chart') {
      this.marketSnapshotChart = null;
      this.marketSnapshotChart = this.getChartInstance();
    } else if (this.chartName === this.marketSection.Info + '-chart') {
      this.marketHistoryChart = null;
      this.marketHistoryChart = this.getChartInstance();
    }
  }

  getChartInstance() {
    let component = this;
    let defs = this.getGradients();
    return Highcharts.stockChart(this.chartName, {
      chart: {
        zoomType: 'x',
        colorCount: 25,
        spacingBottom:
          this.chartName === this.marketSection.Snapshot + '-chart' ? 0 : 15,
        spacingTop:
          this.chartName === this.marketSection.Info + '-chart' ? 10 : 0,
        alignTicks: true,
        events: {
          render() {},
          redraw() {},
          load: (c) => {
            setTimeout(() => {
              if (this.properties.name === this.marketSection.Snapshot) {
                c.target.setSize($('#SNAPSHOT-chart-wrapper').width());
              }
            }, 1);
          }
        },
        resetZoomButton: {
          position: {
            x: -80,
            y:
              this.chartName === this.marketSection.Snapshot + '-chart'
                ? 40
                : 10
          },
          relativeTo: 'chart'
        }
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      title: {
        text: '' // need this property set to allow y axis title to align properly. Without title the chart area cuts off at the top where the y axis should appear. Hack: add title and set the fill to transparent.
      },
      plotOptions: {
        series: {
          // general options for all series
          marker: {
            symbol: 'circle'
          },
          states: {
            hover: {
              enabled: true,
              halo: {
                fill: 'none'
              }
            }
          }
        },
        line: {
          dataGrouping: {
            enabled: false
          }
        },
        area: {
          dataGrouping: {
            enabled: false
          }
        }
      },
      defs: defs,
      tooltip: {
        formatter: function () {
          var s = '<b>' + moment(this.x).format('ddd, MMM D HH:mm') + '</b>';
          $.each(this.points, function (i, point) {
            let myColor = '',
              name = '';
            const solar = component.marketGraphComparisonItems.find(
              (item) => item.source === component.properties.market + '_SOLAR'
            );
            const wind = component.marketGraphComparisonItems.find(
              (item) => item.source === component.properties.market + '_WIND'
            );
            component.marketGraphComparisonItems.forEach((item) => {
              if (point.series.name == item.source) {
                myColor = item.hexColor;
                name = item.text;
              }
            });
            // outlier solar forecast
            if (
              point.series.name ===
              component.properties.market + '_SOLAR_FORECAST'
            ) {
              myColor = solar.hexColor;
              name = solar.text + ' Forecast';
            }
            // outlier wind forecast
            if (
              point.series.name ===
              component.properties.market + '_WIND_FORECAST'
            ) {
              myColor = wind.hexColor;
              name = wind.text + ' Forecast';
            }
            s +=
              '<br/><span style="color:' +
              myColor +
              '">\u25CF</span> ' +
              name +
              ': ';
            if (
              name.toUpperCase().match('HUB') ||
              name.toUpperCase().match('ZONE')
            ) {
              s += '$';
            }
            s += point.y.toFixed(2);
            if (
              !name.toUpperCase().match('HUB') &&
              !name.toUpperCase().match('ZONE')
            ) {
              s += ' MW';
            }
          });
          return s;
        }
      },
      credits: {
        enabled: false
      },
      yAxis: this.marketYAxis,
      xAxis:
        this.properties.name === this.marketSection.Snapshot
          ? this.marketXAxis
          : undefined,
      series: this.datasets
    });
  }

  getComparisonsString() {
    let comparisons = '';
    this.properties.comparisons.forEach((comparison) => {
      if (comparison.selected) {
        comparisons += comparison.value + ',';
      }
    });
    comparisons = comparisons.slice(0, -1);
    return comparisons;
  }

  exportToExcel() {
    const sources = this.marketGraphComparisonItems
      .filter((option) => {
        return option.selected;
      })
      .map((item) => item.source)
      .join();
    this.marketsService.exportToExcel(
      this.properties.name,
      this.selectedFutureOffset.value,
      sources,
      this.maskMode,
      this.selectedOffset.value
    );
  }

  toggleLayerComparisionOptions() {
    this.showLayerComparision = !this.showLayerComparision;
  }

  toggleLayerComparisionSuboptions(option: any) {
    const selectedOption = this.marketGraphComparisonOptions.find(
      (opt) => opt.text === option.text
    );
    selectedOption.expanded = !selectedOption.expanded;
  }

  selectlayerComparisonOption(
    option: MarketGraphComparison,
    subOption: ComparisonOptionItem
  ) {
    const selectedOption = this.marketGraphComparisonOptions.find(
      (opt) => opt.text === option.text
    );
    const selectedSuboption = selectedOption.items.find(
      (opt) => opt.source === subOption.source
    );
    // do not allow the last option to be unselected. This is to display atleast one series each graph.
    if (selectedOption.parent === this.pricesParent) {
      if (
        // sum of price selected options
        this.marketGraphComparisonOptions
          .filter((item) => {
            return item.parent === this.pricesParent;
          })
          .reduce((a, b) => {
            return a + b.count;
          }, 0) === 1
      ) {
        selectedSuboption.selected = true;
      } else {
        selectedSuboption.selected = !selectedSuboption.selected;
      }
    } else {
      if (
        // sum of non gen mix selected options
        this.marketGraphComparisonOptions
          .filter((item) => {
            return item.parent !== this.pricesParent;
          })
          .reduce((a, b) => {
            return a + b.count;
          }, 0) === 1
      ) {
        selectedSuboption.selected = true;
      } else {
        selectedSuboption.selected = !selectedSuboption.selected;
      }
    }
    selectedOption.count = selectedOption.items.filter(
      (item) => item.selected
    ).length;
  }

  toggleChartLegend() {
    this.dataService.showMarketDBlegend = !this.dataService.showMarketDBlegend;
  }

  reRenderMarketChart() {
    setTimeout(() => {
      this.createChart();
    }, 300);
  }

  get selectedGraphDataComparisonsCount(): number {
    let selectCount = 0;
    this.marketGraphComparisonOptions.forEach((option) => {
      selectCount += option.items.filter((item) => item.selected).length;
    });
    return selectCount;
  }

  filterGraphComparisonOptions() {
    this.showLayerComparision = false;
    this.resetComparisonDisplay();
    this.origMarketGraphComparisonOptions = this.utilsService.clone(
      this.marketGraphComparisonOptions
    );
    if (this.properties.name === this.marketSection.Info) {
      this.dataService.newDbInfoMarketGraphSettings =
        this.processMarketGraphComparisonOptions();
      this.marketsService.setSettingsIdData(
        this.dataService.dbInfoMarketGraphSettings,
        this.dataService.newDbInfoMarketGraphSettings
      );
    } else {
      this.dataService.newDbSnapshotMarketGraphSettings =
        this.processMarketGraphComparisonOptions();
      this.marketsService.setSettingsIdData(
        this.dataService.dbSnapshotMarketGraphSettings,
        this.dataService.newDbSnapshotMarketGraphSettings
      );
    }
    this.getMarketData();
  }

  resetSettings() {
    this.showLayerComparision = false;
    this.resetComparisonDisplay();
    //reset comparisons
    this.setMarketGraphComparisonOptions();
    //reset offsets
    this.selectedOffset = this.properties.defaultOffset;
    this.selectedFutureOffset = this.properties.defaultFutureOffset;
    this.initDateRangeValues();
    this.getMarketData();
  }

  revertSettings() {
    this.marketGraphComparisonOptions = this.utilsService.clone(
      this.origMarketGraphComparisonOptions
    );
    this.showLayerComparision = false;
    this.resetComparisonDisplay();
  }

  processMarketGraphComparisonOptions(): IMarketSetting[] {
    const iMarketSettings: IMarketSetting[] = [];
    this.origMarketGraphComparisonOptions.forEach((option) => {
      option.items.forEach((item) => {
        iMarketSettings.push({
          fieldName: item.source,
          fieldValue: item.selected.toString(),
          componentUsed: this.properties.market + '_' + this.properties.name,
          viewName: this.dataService.selectedMarketViewName
        });
      });
    });
    return iMarketSettings;
  }

  resetComparisonDisplay() {
    this.marketGraphComparisonOptions = this.marketGraphComparisonOptions.map(
      (option) => {
        option.expanded = false;
        return option;
      }
    );
  }

  applyDateOffset(event: { startDate: Date; endDate: Date }) {
    this.editStartDate = event.startDate;
    this.editEndDate = event.endDate;
    console.log('date range event: ', event);
    const aDay = 1000 * 60 * 60 * 24;
    let offset;
    let futureOffset;
    if (this.properties.name === this.marketSection.Info) {
      // range: pastOffset - offset. The selectedOffset is left limit and the selectFutureOffset is right limit.
      offset = `-${Math.floor(
        (new Date().getTime() - event.startDate.getTime()) / aDay
      )}`;
      this.selectedOffset = { display: offset, value: offset };
      offset = `-${Math.floor(
        (new Date().getTime() - event.endDate.getTime()) / aDay
      )}`;
      this.selectedFutureOffset = { display: offset, value: offset };
    } else {
      offset = Math.floor(
        (new Date().getTime() - event.startDate.getTime()) / aDay
      );
      offset = offset < 0 ? `+${offset * -1}` : `-${offset}`;
      this.selectedOffset = { display: offset, value: offset };
      futureOffset = `${Math.ceil(
        (event.endDate.getTime() - new Date().getTime()) / aDay
      )}`;
      futureOffset = futureOffset < 0 ? `${futureOffset}` : `+${futureOffset}`;
      this.selectedFutureOffset = {
        display: futureOffset,
        value: futureOffset
      };
    }
    this.getMarketData();
  }

  getChartHeaderTime() {
    return `${momentTz
      .tz(
        new Date(),
        this.properties.market === Market.PJM
          ? 'America/New_York'
          : 'America/Chicago'
      )
      .format('hh:mm A ')} ${this.timezone}`;
  }
}
