import { ResponseContentType ,Http, Headers, Response} from "@angular/http";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import {Observable, throwError} from 'rxjs';
import { IProfileReport } from "./profile-report";
import { DataService } from "../../shared/data.service";
import { catchError, map } from "rxjs/operators";


@Injectable()
export class ProfileReportsService {

    headers: HttpHeaders;

    constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService) {
      this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    }

    getSubscriptionsForUser(userId) : any
    {
        let url = environment.production? environment.getAllReportSubscriptions.replace('{userId}',userId) : environment.getAllReportSubscriptions;
        return this.http.get(url).map((response : any) => response)
        .catch(this.handleError)
    }

    downloadReport(report: IProfileReport): Observable<void> {
      let url = environment.production ? environment.downloadAwsReport : environment.downloadAwsReport;
      url = url.replace('{reportHash}', report.oamReportHash);

      return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
        map((response: HttpResponse<Blob>) => {
          console.log(response);
          this.downloadFile(response.body, report.reportName);
        }),
        catchError(error => {
          console.error('Error occurred:', error);
          return throwError(error);
        })
      );
    }

    private downloadFile(data: Blob, filename: string): void {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }

    private handleError(error: HttpResponse<any>) {
        return Observable.throw(error || 'Server error.');
      }

}
