import {IFourcpSite,ISiteGroup, ISite} from "./site";
import {IAccount} from "./account";
import {IUser} from "./user";
import {IUserAccount} from "./profile";
export class SitesControl {
  siteOptions: string[];
  siteSearch: string;
  siteRequest: string;
  selectedGrouping: ISiteGroup;
  selectedCategory: any;
  selectedSite: ISite;
  categoriesForGroup: any[];
  siteId: string;
  display: string;
  selectedTypeDisplay: string;
  catDisplay: string;
  categoryError: boolean;

  constructor() {
    this.siteOptions = [],
      this.siteSearch = '',
      this.siteRequest = '',
      this.selectedGrouping = null,
      this.selectedCategory = null,
      this.selectedSite = null,
      this.categoriesForGroup = [],
      this.siteId = null,
      this.display = '',
      this.catDisplay = '',
      this.categoryError = false;
  }
}

export class CustomerControl {
  customerOptions: string[];
  customerSearch: string;
  customerRequest: string;
  selectedGrouping: ISiteGroup;
  selectedCategory: any;
  selectedCustomer: any;
  categoriesForGroup: any[];
  customerId: string;
  display: string;
  categoryError: boolean;

  constructor() {
    this.customerOptions = [],
      this.customerSearch = '',
      this.customerRequest = 'ALL_CUSTOMERS',
      this.selectedGrouping = null,
      this.selectedCategory = null,
      this.selectedCustomer = null,
      this.categoriesForGroup = [],
      this.customerId = null,
      this.display = 'All Customers',
      this.categoryError = false;
  }
}
