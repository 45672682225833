import {PipeTransform, Pipe} from '@angular/core';
import {IContract} from "../entities/contract";

@Pipe({
  name: 'contractPipe'
})
export class ContractPipe implements PipeTransform {

  transform(contracts: IContract[], contractStatus: string){
    let filteredContracts = [];
    contracts.forEach(function(c) {
      if(c.status.toString().toLowerCase() == contractStatus.toLowerCase()){
        filteredContracts.push(c);
      }
    });
    return filteredContracts;
  }
}
