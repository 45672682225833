import { Component, OnInit } from '@angular/core';
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import { IAccount } from '../../shared/entities/account';
import {PortalService} from "../../shared/portal.service";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {Subscription} from "rxjs";
declare var $ :any;
import * as moment from 'moment';
import {Router} from "@angular/router";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {IPagerDenom, PagerDenoms} from "../../shared/const/pagerDenoms";
import {PagerService} from "../../shared/pager.service";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {I4cpContact} from "../../shared/entities/fourcp";
import {FourcpService} from "../../fourcp/fourcp.service";
import {IProfile, IUserAccount} from "../../shared/entities/profile";
import {DataService} from "../../shared/data.service";
import {ProfileService} from "../profile.service";


@Component({
  selector: 'app-profile-alerts',
  templateUrl: './profile-alerts.component.html',
  styleUrls: ['./profile-alerts.component.scss'],
  providers: [OrderByPipe],
  animations: [FadeInAnimation, FadeAnimation]
})
export class ProfileAlertsComponent implements OnInit {

    myAccount: IUserAccount;
    maskMode: boolean;
    pagerDenoms: IPagerDenom[];
    selectedDenom: IPagerDenom;
    pager: any;
    helpStep: IHelpStep;
    selectedTab: string = 'ACCOUNTS';
    editingTextPhone: boolean;

    profileAlerts: I4cpContact[];
    tempContact: I4cpContact;
    profile: IProfile;
    alertToDelete: I4cpContact;

    //Permissions
    allowedToManageUsers: boolean = false;

    accountSourceSubscription: Subscription;
    profileSubscription: Subscription;

  constructor(private fourcpService: FourcpService,
              private dataService: DataService,
              private profileService: ProfileService,
              private router: Router) { }

  ngOnInit() {
    this.profile = this.dataService.getProfile();
    this.getProfileAlerts();

    this.myAccount = this.dataService.getAccountSource();

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
    });

    this.profileSubscription = this.dataService.profileUpdated.subscribe(() => {
      this.updateSyncedAlerts();
    });
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.profileSubscription) this.profileSubscription.unsubscribe();
  }

  updateSyncedAlerts() {
    if(this.profileAlerts){
      this.profileAlerts.forEach((alert) => {
        if(alert.textPhoneSelection == 'MOBILE'){
          alert.textAlertPhone = this.profile.mobilePhone;
        } else if (alert.textPhoneSelection == 'WORK'){
          alert.textAlertPhone = this.profile.phone;
        }
        if(alert.voicePhoneSelection == 'MOBILE'){
          alert.voiceAlertPhone = this.profile.mobilePhone;
        } else if (alert.voicePhoneSelection == 'WORK'){
          alert.voiceAlertPhone = this.profile.phone;
          alert.voiceAlertExt = this.profile.extension;
        }
        if (alert.voiceAlertPhone && alert.voiceAlertPhone.length > 10) {
          alert.voiceAlertPhone = alert.voiceAlertPhone.substr(1, 3) + alert.voiceAlertPhone.substr(6, 3) + alert.voiceAlertPhone.substr(10, 4);
        }
        if (alert.textAlertPhone && alert.textAlertPhone.length > 10) {
          alert.textAlertPhone = alert.textAlertPhone.substr(1, 3) + alert.textAlertPhone.substr(6, 3) + alert.textAlertPhone.substr(10, 4);
        }
      });
    }
  }

  getProfileAlerts() {
    this.dataService.setLoading(true);
    this.profileService.getProfileAlerts().subscribe((alerts : any) => {
      this.dataService.setLoading(false);
      this.profileAlerts = alerts;
    });
  }

  getDateDisplay(date: string) {
    return moment(date).format('MM/DD/YY');
  }

  openPhoneModal(alert: I4cpContact, text: boolean){
    this.tempContact = Object.assign({}, alert);
    this.editingTextPhone = text;
    if(text) {
      this.tempContact.phoneSelection = alert.textPhoneSelection;
      this.tempContact.alertPhone = alert.textAlertPhone;
    } else {
      this.tempContact.phoneSelection = alert.voicePhoneSelection;
      this.tempContact.alertPhone = alert.voiceAlertPhone;
    }
    if(!this.tempContact.phoneSelection){
      this.tempContact.phoneSelection = 'NONE';
      this.tempContact.alertPhone = '';
      this.tempContact.voiceAlertExt = '';
    }
  }


  updatePhoneSelection() {
    if (this.tempContact.phoneSelection == 'MOBILE') {
      this.tempContact.alertPhone = this.profile.mobilePhone;
    } else if (this.tempContact.phoneSelection == 'WORK') {
      this.tempContact.alertPhone = this.profile.phone;
      this.tempContact.voiceAlertExt = this.profile.extension;
    } else {
      this.tempContact.alertPhone = null;
    }
  }

  goTo4CP() {
    this.router.navigate(['/fourcp']);
  }

  applyPhoneContact() {
    this.profileAlerts.forEach((alert) => {
      if (alert.alertEmail == this.tempContact.alertEmail && alert.accountId == this.tempContact.accountId) {
        if (this.tempContact.alertPhone.length > 10) {
          this.tempContact.alertPhone = this.tempContact.alertPhone.substr(1, 3) + this.tempContact.alertPhone.substr(6, 3) + this.tempContact.alertPhone.substr(10, 4);
        }
        if(this.tempContact.phoneSelection = 'NONE') {
          this.tempContact.alertPhone = '';
          this.tempContact.voiceAlertExt = '';
        }
        if (this.editingTextPhone) {
          alert.textPhoneSelection = this.tempContact.phoneSelection;
          alert.textAlertPhone = this.tempContact.alertPhone;
        } else {
          alert.voicePhoneSelection = this.tempContact.phoneSelection;
          alert.voiceAlertPhone = this.tempContact.alertPhone;
          alert.voiceAlertExt = this.tempContact.voiceAlertExt;
        }
        this.saveAlert(alert);
      }
    });
  }


  saveAlert(alert: I4cpContact) {
    let req;
    this.fourcpService.get4cpContacts(alert.accountId).subscribe((resp : any) => {
      resp.contacts.forEach((contact) => {
        if(contact.alertEmail == this.profile.email){
          contact.textPhoneSelection = alert.textPhoneSelection;
          contact.textAlertPhone = alert.textAlertPhone;
          contact.voicePhoneSelection = alert.voicePhoneSelection;
          contact.voiceAlertPhone = alert.voiceAlertPhone;
          contact.voiceAlertExt = alert.voiceAlertExt;
          let req = {
            accountId: alert.accountId,
            contacts: [contact]
          };
          this.fourcpService.saveContact(req).subscribe((resp) => {
            this.getProfileAlerts();
          });
        }
      });
    });
  }

  toggleDelete(alert: I4cpContact){
    this.alertToDelete = alert;
  }

  deleteContact(alert: I4cpContact){
    this.fourcpService.get4cpContacts(alert.accountId).subscribe((resp : any) => {
      resp.contacts.forEach((contact) => {
        if(contact.alertEmail == this.profile.email){
          contact.activeFlag = "F";
          let req = {
            accountId: alert.accountId,
            contacts: [contact]
          };
          this.fourcpService.saveContact(req).subscribe((resp) => {
            this.getProfileAlerts();
          });
        }
      })
    });
  }

  getPhoneNumberFormatted(phoneNumStr: string){
    if(phoneNumStr && phoneNumStr.length == 10) {
      phoneNumStr = '(' + phoneNumStr.substr(0, 3) + ') ' + phoneNumStr.substr(3, 3) + '-' + phoneNumStr.substr(6, 4);
    }
    return phoneNumStr;
  }



}
