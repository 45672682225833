import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { DataService } from '../shared/data.service';
import { ContactService } from '../contact/contact.service';
import { Http, ResponseContentType } from '@angular/http';
import { DOCUMENT } from '@angular/common';
import { zip } from 'rxjs';
import {Permissions} from '../shared/const/permissions';
import { Subscription } from 'rxjs';
import { IUserAccount } from '../shared/entities/profile';
import {Router} from "@angular/router";
import { PortalService } from '../shared/portal.service';
import { Authority } from '../shared/const/authority';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, OnDestroy {
  brokerPortalFailed = false;
  brokerPortalLoaded = false;
  brokerPortalSubscription: Subscription;
  selectedAccount: IUserAccount;
  @ViewChild('embeddedAppContainer', { read : 'true', static : true}) embeddedAppContainer : HTMLElement

  constructor(
    private router: Router,
    private dataService: DataService,
    private contactService: ContactService,
    private portalService: PortalService,
    private httpClient: Http,
    @Inject(DOCUMENT) private document: Document,
    ) { }

  ngOnInit() {
    // reset page title on init
    this.dataService.setTitleSource('');
    this.brokerPortalFailed = false;
    this.brokerPortalLoaded = false;
    this.dataService.pricingPageTriggered.emit(true);
    this.dataService.setCurrentActivePage('pricing');
    this.selectedAccount = this.dataService.getAccountSource();
    const appProperties = this.dataService.getAppProperties();
    const profile = this.dataService.getProfile();

    if (!profile) {
      return;
    }

    if (!this.dataService.getPricingTcEnabled()) {
      return this.router.navigate(['/pricing-tc']);
    }

    let showMBP = this.dataService.getShowMBP();
    const profileName = profile.firstName + ' ' + profile.lastName;
    const accessLevel = this.getEffectiveAccessLevel();
    let mbpEnabled = showMBP && this.hasMatrixPricingEnabled(profile.userAccounts);
    window.bpConfig = {
      apiPrefix: (appProperties && appProperties.brokerPrefix ? appProperties.brokerPrefix : '/myaccount/private'),
      brokerUserId: profile.userId,
      brokerCompanyId: this.selectedAccount.id,
      brokerCompanyName: this.selectedAccount.name,
      brokerUserName: profileName,
      brokerUserEmail: profile.email,
      brokerUserAccess: accessLevel,
      accountRep: '',
      hasMatrixBrokerPricingEnabled : mbpEnabled
    };
    this.contactService.getContactsForAccount(this.selectedAccount.id, true).subscribe(
      (contactInfo) => {
        if (contactInfo && contactInfo.specialist && contactInfo.specialist.length > 0) {
          window.bpConfig.accountRep = contactInfo.specialist[0].email;
        } else if (contactInfo && contactInfo.accountExec && contactInfo.accountExec.length > 0) {
          window.bpConfig.accountRep = contactInfo.accountExec[0].email;
        }
      }, err => {
        console.error(err);
      }
    );
    this.loadBrokerPricingApp();
  }

  private getEffectiveAccessLevel() {
    // tslint:disable-next-line:no-bitwise
    if ((Permissions.PricingAll & this.selectedAccount.permissions) === Permissions.PricingAll ||
        // tslint:disable-next-line:no-bitwise
        (Permissions.Admin & this.selectedAccount.permissions) === Permissions.Admin) {
      return 'ADMIN';
    }
    return 'USER';
  }

  private loadBrokerPricingApp() {
    this.brokerPortalSubscription = zip(
      this.httpClient.get(this.brokerPricingJsUrl, { responseType: ResponseContentType.Text }),
      this.httpClient.get(this.brokerPricingCssUrl, { responseType: ResponseContentType.Text }),
      this.httpClient.get(this.brokerPricingShadowDOMScript, { responseType: ResponseContentType.Text })
    ).subscribe(
      brokerPricingFiles => {
        this.brokerPortalLoaded = true;

        const component = this.document.getElementsByTagName('app-pricing')[0];

        // add style tag
        const style = this.document.createElement('style');
        style.innerHTML = brokerPricingFiles[1].text();
        component.appendChild(style);

        // add script tag
        const script = this.document.createElement('script');
        script.innerHTML = brokerPricingFiles[0].text();
        component.appendChild(script);

        // add shadowDOM script tag
        const scriptShadowDOM = this.document.createElement('script');
        scriptShadowDOM.innerHTML = brokerPricingFiles[2].text();
        component.appendChild(scriptShadowDOM);

        // add app
        const frontend = this.document.createElement('app-broker-pricing');
        const content = this.document.getElementById('embedded-app-container');
        content.appendChild(frontend);
      },
      err => {
        console.error('Failed to load Broker Portal', err);
        this.dataService.setTitleSource('Pricing');
        this.brokerPortalFailed = true;
      }
    );
  }

  ngOnDestroy() {
    this.dataService.pricingPageTriggered.emit(false);
    if (this.brokerPortalSubscription) {
      this.brokerPortalSubscription.unsubscribe();
    }
  }

  get brokerPricingJsUrl(): string {

    if(this.document.URL.includes('localhost:8880')){
      return 'http://localhost:4200/assets/app/main.js';
    }

    return this.document.URL.includes('localhost:4200') ?
      'http://localhost:4200/assets/app/main.js' :
      window.bpConfig.apiPrefix + '/broker-pricing/js/broker-pricing.js';
  }

  get brokerPricingCssUrl(): string {

    if(this.document.URL.includes('localhost:8880')){
      return 'http://localhost:4200/assets/app/styles.css';
    }

    return this.document.URL.includes('localhost:4200') ?
      'http://localhost:4200/assets/app/styles.css' :
      window.bpConfig.apiPrefix + '/broker-pricing/js/broker-pricing.css';
  }

  get brokerPricingShadowDOMScript(): string {
    return 'https://cdnjs.cloudflare.com/ajax/libs/shadydom/1.8.0/shadydom.min.js';
  }

  hasMatrixPricingEnabled(accounts: IUserAccount[]) {
    //not super user and has 3rd party account access, then can view mbp
    let isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    let hasThirdPartyPermissions = false;

    if (!isSuperUser) {
      let accts = accounts;


      //call service to get account flags for all accounts, find account with the matrix flag
      if (accts) {
        console.log('profile: found ' + accts.length + ' accounts for user source');
        for (let i = 0; i < accts.length; i++) {
          let acct = accts[i];
          console.log(acct.name + ' matrix pricing flag: ' + acct.matrixPricingFlagEnabled)
          if (acct.matrixPricingFlagEnabled && this.hasPricingEnabled(acct)) {
            console.log(acct.name + ' has matrix and pricing access');
            return true;
          }
        }

      } else {
        console.log('profile: no accounts for user source, or super-user');
      }
    }
    return false;
  }
  hasPricingEnabled(account: IUserAccount) {
    if (account.pricingFlagEnabled) {
      if (this.hasPermission(Permissions.PricingAll, account) || this.hasPermission(Permissions.PricingUser, account)) {
        return true;
      }
      if (!this.portalService.userHasAuthority(Authority.SuperUser)) {
        if (account.accessLevel.toUpperCase() === 'ADMIN') {
          return true;
        }
      }
    }
    return false;
  }
  hasPermission(mask: number, account: IUserAccount) {
    const permission = account.permissions;
  // tslint:disable-next-line:no-bitwise
    return (permission & mask) === mask;
  }
}
