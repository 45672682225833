import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';

@Component({
  selector: 'app-renewables-history',
  templateUrl: './renewables-history.component.html',
  styleUrls: ['./renewables-history.component.scss']
})
export class RenewablesHistoryComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit() {
     //this.dataService.showGenerationDetailExport.emit(true);
     this.dataService.setContextualHelp(false);
  }
}
