import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as moment from 'moment';
import { Observable, Subscription } from "rxjs";
import { of } from "rxjs";
import { Subject } from "rxjs";
import { DataService } from "../../../shared/data.service";
import { IUserAccount } from "../../../shared/entities/profile";
import { PortalService } from "../../../shared/portal.service";
import { IGenerationSite } from "../../generation-site";
import { IGeneratorStatus } from "../../generator-status";
import { ShadowMeteringService } from "../../shadow-metering.service";
import { dgenColumnDefaults, dgenInitialColumns, IColumn, IColumnRequest } from "../column-selector/column-data";
import { ColumnSelectorComponent } from "../column-selector/column-selector.component";
import { ColumnSelectorService } from "../column-selector/column-selector.service";
import {OrderByPipe} from "../../../shared/pipe/orderBy.pipe";
import { Router } from '@angular/router';

@Component({
  selector: "app-generators-table",
  providers: [OrderByPipe],
  templateUrl: "./generators-table.component.html",
  styleUrls: ["./generators-table.component.scss"],
})
export class GeneratorsTableComponent implements OnInit, OnDestroy {
  selectedColumns: string[];
  myAccount: IUserAccount;
  @Input() sites: IGenerationSite[];
  actualColumns = dgenColumnDefaults();
  generatorStatuses$: Observable<IGeneratorStatus[]>;
  generatorStatuses:IGeneratorStatus[];
  private readonly unsubscribe$ = new Subject();
  @ViewChild(ColumnSelectorComponent, {static : false}) columnSelector: ColumnSelectorComponent;
  sortBy: string = 'siteAssetName';
  ascending: boolean = true;
  @Input() showFuelLevel: boolean;

  maskMode = false;
  maskModeSubscription: Subscription;

  constructor(
    private router: Router,
    private shadowMeteringService: ShadowMeteringService,
    private columnSelectorService: ColumnSelectorService,
    private orderByPipe: OrderByPipe,
    private portalService: PortalService,
    private dataService: DataService
  ) {
      this.generatorStatuses$ = shadowMeteringService.generatorStatuses;
      shadowMeteringService.generatorStatuses.subscribe(generatorStatuses => 
      {
          this.generatorStatuses=generatorStatuses;   

      });
    }

  ngOnInit() {
    this.myAccount = this.dataService.getAccountSource();

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.defaultColumnRequest$
      .takeUntil(this.unsubscribe$)
      .subscribe(
        res => this.onColumnsSelected(res && res.selectedSettings ? res.selectedSettings : dgenInitialColumns()) ,
        _err => this.onColumnsSelected(dgenInitialColumns())
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }
  }

  hasGenFuel(genStatus: IGeneratorStatus): boolean {
    let site = this.getSite(genStatus.siteId);
    let hasFuelData = false;
    if(site.generators) {       
      site.generators.forEach(gens => {
      if(genStatus.id===gens.id && gens.fuelType==="Diesel") {
         hasFuelData = true;
      }
    });
    }
    return hasFuelData;
  }

  get defaultColumnRequest$(): Observable<IColumnRequest> {
    if (this.sites) {
      return this.columnSelectorService.getColumnSettingsForAllEntity('dgen', this.myAccount.id);
    } else if (this.shadowMeteringService.selectedSite) {
      return this.columnSelectorService.getColumnSettingsForSingleEntity('dgen', this.myAccount.id, this.shadowMeteringService.selectedSite.id);
    } else {
      return of({selectedSettings: dgenInitialColumns(), id: this.myAccount.id});
    }
  }

  navigateTo(dest: string, siteId: string) {
    if (this.sites)
    {
      this.sites.forEach(siteSel => {
      if(siteSel.id===siteId) {
        // this.loading = true;
        this.shadowMeteringService.setSelectedSite(siteSel);
        this.router.navigate(['/' + dest]); 
      // combineLatest(
      //   updateGenerationStatuses$,
      //   updateGenerationUsageHistory$,
      //   (_status, _usage) => {}
      // ).subscribe(
      //   _pair => {
      //     this.loading = false;
      //   },
      //   err => {
      //     console.error('Error occurred fetching new site data for selected site.', err);
      //     this.loading = false;
      //   });
      }
      })      
      this.sites = null;
    } 
  }

  sortGen(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }    

    if( this.sortBy === 'siteAssetName') {
      this.generatorStatuses.forEach(generatorStatus => {
        generatorStatus.siteName = (this.getSite(generatorStatus.siteId)).name;
      });
    }

    this.generatorStatuses = this.orderByPipe.transform(this.generatorStatuses, this.sortBy, this.ascending);
    
    
    //this.paginate(1);
  }

  // paginate(page: number) {
  //   if((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager){
  //       this.filteredSites = this.orderByPipe.transform(this.filteredSites, this.sortBy, this.ascending);
  //       this.pager = this.pagerService.getPage(this.filteredSites, page);
  //   }
  // }

  openColumnSelector() {
    this.columnSelector.show();
  }

  showColumn(column: string): boolean {
    if (!this.selectedColumns) {
      return false;
    }
    return this.selectedColumns.includes(column);
  }

  onColumnsSelected(newColumns: string[]) {
    this.selectedColumns = newColumns;
    this.updateActualColumns();
  }

  fuelNeededFor90(generatorStatus: IGeneratorStatus): number {
    if (!generatorStatus) {
      return 0;
    }
    return Math.round(
      generatorStatus.fuelLevelMax * 0.9 - generatorStatus.fuelLevel
    );
  }

  updateActualColumns() {
    for (const section of this.actualColumns) {
      if(section.key==='FUEL_LEVEL') {
        if(this.showFuelLevel) {
          section.selected = this.selectedColumns.includes(section.key);
          if (!section.data) {
            continue;
          }
          for (const column of section.data) {
            column.selected = this.selectedColumns.includes(column.key);
          }
        } else {
          section.selected = false;
          section.disabled = true;
          if (!section.data) {
            continue;
          }
          for (const column of section.data) {
            column.selected = false;
            column.disabled = true;
          }
        }
      }
      else {
        section.selected = this.selectedColumns.includes(section.key);
        if (!section.data) {
          continue;
        }
        for (const column of section.data) {
          column.selected = this.selectedColumns.includes(column.key);
        }
      }
    }
    for (const section of this.actualColumns) {
      if(section.key==='FUEL_LEVEL') {
        if(this.showFuelLevel) {
          section.partialSelected = this.getPartialSelected(section);
        } else {
          section.partialSelected = false;
        }
      } else {
        section.partialSelected = this.getPartialSelected(section);
      }
    }
  }

  getPartialSelected(column: IColumn) {
    if (!column || !column.data) {
      return false;
    }

    const totalSub = column.data.length;
    const totalChecked = column.data.reduce((sum, section) => sum + (section.selected ? 1 : 0), 0);
    return totalChecked !== totalSub;
  }

  get statusTimestamp(): moment.Moment {
    return this.shadowMeteringService.statusTimestamp;
  }

  get lookbackEnabled(): boolean {
    return this.shadowMeteringService.lookbackEnabled;
  }

  get entityId(): string {
    return this.sites || !this.shadowMeteringService.selectedSite ? undefined : this.shadowMeteringService.selectedSite.id;
  }

  getSite(siteId: string): IGenerationSite {
    return this.sites ? this.sites.find(site => site.id === siteId) : this.shadowMeteringService.selectedSite;
  }

  getFakeSiteId(site: IGenerationSite) {
    return this.portalService.getFakeSiteId(site);
  }
  getFakeSiteName(site: IGenerationSite) {
    return this.portalService.getFakeSiteName(site);
  }
  getFakeSiteAddress(site: IGenerationSite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  formatDisplayAddress(genSite: IGenerationSite) {
    let retAddress = '';
    if (genSite && genSite.serviceAddress) {
      const address = genSite.serviceAddress;

      if (address.streetAddress && address.streetAddress.length > 0) {
        retAddress = this.maskMode ? this.getFakeSiteAddress(genSite) : address.streetAddress;
      }
      if (address.city && address.city.length > 0) {
        retAddress = retAddress + ', ' + address.city;
      }
      if (address.state && address.state.length > 0) {
        retAddress = retAddress + ', ' + address.state;
      }
      if (address.zipCode && address.zipCode.length > 0) {
        retAddress = retAddress + ', ' + address.zipCode;
      }
      if (retAddress && retAddress.length > 0) {
        return retAddress;
      }
    }
    return '...';
  }
}
