// For common API calls and helper methods shared between components
import { Injectable } from '@angular/core';
import * as faker from 'faker';

@Injectable()
export class FakerService {

  constructor() {}
  /*
    Charm is deceptive, and beauty is fleeting;
    but a woman who fears the Lord is to be praised.
    Proverbs 31:30
  */
  getName() {
    return faker.name.findName();
  }

  generateFakeReportName(reportName?: string): string {

    if (!reportName) {
      reportName = faker.company.companyName();
    }

    // Generate a random arbitrary number between 1 and 100
    const randomNumber = Math.floor(Math.random() * 100) + 1;

    // Pad the random number with leading zeros if necessary
    const paddedNumber = randomNumber < 10 ? `0${randomNumber}` : `${randomNumber}`;

    // Append the fake name with the padded random number
    const fakeReportName = `${reportName} ${paddedNumber}`;

    return fakeReportName;
  }

  getCompanyName() {
    return faker.company.companyName();
  }

  getSiteId() {
    let fakeSiteId = '';
    for(let i = 0; i < 17; i++){
      fakeSiteId += Math.floor((Math.random() * 9)).toString();      // generate random, 22 digit number
    }
    return fakeSiteId;
  }

  getContractNum() {
    let str = '';
    for(let i = 0; i < 6; i++){
      str += faker.random.alphaNumeric().toString().toUpperCase();
    }
    return '1-' + str;
  }

  getContractAccountNum() {
    return Math.floor((Math.random() * 99999999)).toString() + '-' + Math.floor((Math.random() * 9)).toString();
  }

  getStreetAddress() {
    return faker.address.streetAddress();
  }

  getCity() {
    return faker.address.city();
  }

  getState() {
    return faker.address.stateAbbr();
  }

  getZipCode() {
    return faker.address.zipCode();
  }

  getConfirmationNumber() {
    let str = '';
    for(let i = 0; i < 8; i++){
      str += faker.random.alphaNumeric().toString().toUpperCase();
    }
    return str;
  }

  getFirstName() {
    return faker.name.firstName();
  }

  getLastName() {
    return faker.name.lastName();
  }
}
