import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { SitesControl } from '../../shared/entities/controls';

@Component({
  selector: 'app-renewables-overview',
  templateUrl: './renewables-overview.component.html',
  styleUrls: ['./renewables-overview.component.scss']
})
export class RenewablesOverviewComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.showGenerationDetailExport.emit(true);
    this.dataService.setContextualHelp(true);
  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(false);
  }
}
