import { Response } from '@angular/http';
import {
  Component, OnInit, ElementRef,
} from '@angular/core';
import {AccountsService} from "./accounts.service";
import {IAccount} from "../shared/entities/account";
import {DataService} from "../shared/data.service";
import {ContractExportRequest, IContract, IContractMetadata} from "../shared/entities/contract";
import {IProduct, IProductMetadata} from "../shared/entities/product";
import {ContractStatus} from "../shared/const/contractStatus";
import {ContractPipe} from "../shared/pipe/contract.pipe";
import {PortalService} from "../shared/portal.service";
import {ISite} from "../shared/entities/site";
import {SitePipe} from "../shared/pipe/site.pipe";
import {PagerService} from "../shared/pager.service";
import {OrderByPipe} from "../shared/pipe/orderBy.pipe";
import {FadeAnimation} from "../animations/fadeAnimation";
import {IUserAccount} from "../shared/entities/profile";
import {Subscription} from "rxjs";
import {FakerService} from "../shared/faker.service";
import {IHelpStep} from "../shared/entities/contextualHelp";
import {Authority} from "../shared/const/authority";
import { AccountBrandingConstant } from '../shared/const/accountBranding';
import { IPagerDenom, PagerDenoms } from '../shared/const/pagerDenoms';


@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  providers: [ContractPipe, SitePipe, OrderByPipe],
  animations: [FadeAnimation],
  styleUrls: ['./accounts.component.scss']
})

export class AccountsComponent implements OnInit {

  isSuperUser: boolean = false;
  myUserAccount: IUserAccount;
  myAccount: IAccount;
  selectedContract: IContract;
  selectedContractStatus: string = 'All'; // for filtering
  selectedProduct: IProduct;
  filteredContracts: IContract[];
  sites: ISite[];
  filteredSites: ISite[]; // filtered subset of all sites
  pager: any;
  selectedDenom: IPagerDenom;
  pagerDenoms: IPagerDenom[];
  editingNickName: boolean = false;
  editingProductDescription: boolean = false;
  showContractFilter: boolean = false;
  showSearchRow: boolean = false;
  maskMode: boolean;
  searchTerm: string;
  contractStatuses: any[]; // string values of ContractStatus enumeration
  sortBy: string = 'siteId';
  ascending: boolean = true;
  nickName: string;
  productDescription: string;
  contractFilterToggled: boolean = false;
  helpStep: IHelpStep;
  getSitesForContractSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private accountsService: AccountsService,
              private dataService: DataService,
              private contractPipe: ContractPipe,
              private sitePipe: SitePipe,
              private elRef: ElementRef,
              private portalService: PortalService,
              private pagerService: PagerService,
              private orderByPipe: OrderByPipe,
              private fakerService: FakerService) { }

  ngOnInit() {
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.dataService.setSelectedNavItem('accounts'); // highlight nav item on page refresh
    this.setCurrentActivePage('accounts');
    this.dataService.setTitleSource('My Account');
    this.myUserAccount = this.dataService.getAccountSource();
    if(this.myUserAccount){
      this.accountUpdated(this.myUserAccount.accessType);
    }
    this.getSitesForContractSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myUserAccount = account;
        if(this.myUserAccount.accessType != 'ThirdParty') {
          this.accountUpdated(this.myUserAccount.accessType);
        }
      }
    );

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      // this.getSites();
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    })

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if (denom.count == 10) {
        this.selectDenom(denom);
      }
    });
  }

  ngOnDestroy() {
    if (this.getSitesForContractSubscription) this.getSitesForContractSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getTooltip(status: string) {
    switch(status) { 
      case 'Added': { 
         return 'Site was added via an amendment.'; 
      } 
      case 'Assigned Away': { 
         return 'Site was reassigned to another customer without a change in price.';
      } 
      case 'Assigned To': { 
        return 'Site was reassigned from another customer without a change in price.';
     } 
     case 'Deleted': { 
      return 'Site was deleted via an amendment.';
     } 
     case 'Rescinded': { 
      return 'Contract was never valid.';
     } 
     case 'Restructured': { 
      return 'Contract was restructured; new contract will contain \'Under Contract\' site.';
     } 
     case 'Terminated': { 
      return 'Contract is no longer valid.';
     } 
     case 'Under Contract': { 
      return 'Active site that was included in contract at the time of closing the contract.';
     } 
     case ' Pending Contract': { 
      return 'Contract has not closed.';
     } 
   } 
  }

  accountUpdated(accessType? : string) {
    this.dataService.setLoading(true);
    var selectedEditContract = this.selectedContract;
    /*this.accountsService.getContractsForAccount(this.myUserAccount.id, this.myUserAccount.customerAgentId).subscribe(
      (account) => 
        this.dataService.setAccountWithContractDataSource(this.myAccount);{*/
        this.myAccount = this.dataService.getAccountWithContractDataSource();
        if(this.myAccount.contracts){
          this.myAccount.contracts.forEach((contract) => {
            if(contract.contractType == 'Transition' && contract.nickName != 'On Transition'){
              contract.nickName = 'On Transition';
              let contractMetadata: IContractMetadata = {
                contractId: contract.id,
                accountId: this.myAccount.id,
                nickName: 'On Transition'
              };
              this.accountsService.setContractMetadata(contractMetadata).subscribe();
            }
            if(selectedEditContract && selectedEditContract.contractNum===contract.contractNum)
            {
                selectedEditContract = contract;
            }

          });
          this.dataService.setContractsForAccount(this.myAccount.contracts);
          if(this.myAccount.contracts.length > 0){
            this.filterContracts("All");

            if(selectedEditContract)
            {
              this.selectContract(selectedEditContract);
            }
            else
               this.selectContract(this.myAccount.contracts[0]);
            this.getSites();

            // Assign filtered contracts to be entire set of contracts
            this.filteredContracts = this.myAccount.contracts;
            // Populate contractStatuses with string values from ContractStatus enumeration
            this.contractStatuses = ['All'];
            for (let status in ContractStatus) {
              if(!parseInt(status) && status != '0') {
                this.contractStatuses.push(status.replace(/([A-Z])/g, ' $1').trim());
              }

            }
          }
        }
        this.dataService.setLoading(false);
      /*}, err => {
        this.dataService.setLoading(false);
      }
    );*/
  }

  // Show input field
  editNickName() {
    this.editingNickName = true;
  }

  exitNickName(update?: boolean) {
    this.editingNickName = false;
    if(update){
      this.updateNickName();
    }
  }

  // Show input field
  editProductDescription(product?: IProduct) {
     this.selectedProduct = product;
     this.productDescription = product.description;
     this.selectedProduct.editingProductDescription = true;
  }

  exitProductDescription(update?: boolean) {
    this.selectedProduct.editingProductDescription = false;
    if(update){
      this.updateProductDescription();
    }
  }
  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getSiteIdDisplay(siteId: string) {
    return this.portalService.getSiteIdDisplay(siteId);
  }
  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }


  // Create contract metadata and call PUT endpoint
  updateNickName() {
    this.editingNickName = false;
    let contractMetadata: IContractMetadata = {
      contractId: this.selectedContract.id,
      accountId: this.myAccount.id,
      nickName: this.nickName
    };
    let i = this.myAccount.contracts.findIndex(function(c) {
      return c.id == contractMetadata.contractId;
    });
    this.myAccount.contracts[i].nickName = contractMetadata.nickName;
    this.accountsService.setContractMetadata(contractMetadata).subscribe(
      (newContractMetadata) => {
        // this.dataService.setAccountSource(this.myAccount);
      }, (error) => {
        console.error(error);
      }
    );
    this.accountUpdated(this.myUserAccount.accessType);
  }

  // Create product metadata and call PUT endpoint
  updateProductDescription() {
    this.editingProductDescription = false;
    let productMetadata: IProductMetadata = {
      productId: this.selectedProduct.id,
      accountId: this.myAccount.id,
      description: this.productDescription
    };

    this.accountsService.setProductMetadata(productMetadata).subscribe(
      (newProductMetadata) => {
        // this.dataService.setAccountSource(this.myAccount);
      }, (error) => {
        console.error(error);
      }
    );
    this.productDescription = "";
    this.accountUpdated(this.myUserAccount.accessType);
  }

  selectContract(contract: IContract){
    this.selectedContract = contract;
    this.nickName = this.selectedContract.nickName;
    //this.getSites();
  }

  selectContractClicked(contract: IContract){
    this.selectContract(contract);
    this.getSites();
  }

  filterContractsStatus(status: string) {
    this.filterContracts(status);
    if(this.filteredContracts.length > 0) { 
      this.getSites();
    }
  }

  // Filter contracts by contract status
  filterContracts(status: string) {
    this.showContractFilter = false;
    this.selectedContractStatus = status;
    if(status == 'All'){
      this.sortContracts();
      this.filteredContracts = this.myAccount.contracts;
    } else {
      this.filteredContracts = this.contractPipe.transform(this.myAccount.contracts, this.selectedContractStatus);
      this.sitePipe.transform(this.sites, '');
    }
    if(this.filteredContracts.length > 0) {
      this.selectContract(this.filteredContracts[0]);
    }
  }

  // Sort Contracts to display 'Active' contracts first by default
  sortContracts() {
    let sortedContracts = [];
    let active =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.Active);
    let assigned =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.Assigned);
    let futureStart =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.FutureStart);
    let restructured =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.Restructured);
    let onTransition =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.OnTransition);
    let terminated =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.Terminated);
    let expired =  this.myAccount.contracts.filter(contract => contract.status == ContractStatus.Expired);
    sortedContracts.push(active);
    // TODO: Confirm order of 'assigned' in the contract list
    sortedContracts.push(assigned);
    sortedContracts.push(futureStart);
    sortedContracts.push(restructured);
    sortedContracts.push(onTransition);
    sortedContracts.push(terminated);
    sortedContracts.push(expired);
    this.myAccount.contracts = [].concat.apply([],sortedContracts);
  }

  // Retrieve sites for contract
  getSites() {
    this.accountsService.getSitesForContract(this.myAccount.id, this.selectedContract.contractNum).subscribe(
      (sites) => {
        this.sites = sites;
        if(this.maskMode) {
          this.sites.forEach((site) => {
            site.serviceAddress.streetAddress = this.fakerService.getStreetAddress();
            site.serviceAddress.city = this.fakerService.getCity();
            site.billingAddress.streetAddress = this.fakerService.getStreetAddress();
            site.billingAddress.city = this.fakerService.getCity();
          })
        }
        this.filteredSites = this.sites;
        this.paginate(1);
        this.searchTerm = '';
      }, err => {
        this.sites = [];
        this.filteredSites = [];
        this.paginate(0);
        console.error(err);
      }
    );
  }

  // Filter sites by search term using sitePipe
  filterSites() {
    if(!this.searchTerm){
      this.filteredSites = this.sites;
    } else {
      this.filteredSites = this.sitePipe.transform(this.sites, this.searchTerm);
    }
    this.paginate(1);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow
    this.searchTerm = '';
    this.filterSites();
  }

  // Adjust arrow up/down, call out to paginate & sort
  sortSites(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }

  // "2017-01-01T00:00:00" -> "01/01/2017"
  getDateDisplay(dateString: string) {
     let dateStr = this.portalService.getDateDisplay(dateString);
    //  console.log("date string: ", dateStr);
     if(dateStr && dateStr.includes("9999")) {
       dateStr = "";
     }
     return dateStr;
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  // Apply ordering and pagination to sites table
  paginate(page: number) {
    if((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager){
        this.filteredSites = this.orderByPipe.transform(this.filteredSites, this.sortBy, this.ascending);
        this.pager = this.pagerService.getPage(this.filteredSites, page, this.selectedDenom.count);
    }
  }

  getStatusCount(status: string){
    if(status == 'All'){
      return this.myAccount.contracts.length;
    } else {
      return this.contractPipe.transform(this.myAccount.contracts, status).length;
    }
  }

  export() {
    let exportRequest : ContractExportRequest = {
      accountId : this.myAccount.id,
      contractId : this.selectedContract.id.toString(),
      maskingMode : this.dataService.getMaskMode(),
      brand : window.localStorage.getItem("brand")
    }
    this.accountsService.exportContractSites(exportRequest);
  }

  toggleContractFilter() {
    this.showContractFilter = !this.showContractFilter;
    this.contractFilterToggled = true;
    setTimeout(() => {
      this.contractFilterToggled = false;
    }, 100);
  }

  clickOutside(event) {
    if(this.showContractFilter && !this.contractFilterToggled) {
      this.showContractFilter = false;
    }
  }
}
