import {PipeTransform, Pipe} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'orderByPipe', pure: false})
export class OrderByPipe implements PipeTransform {

  transform(items: any[], sortBy: string, ascending: boolean): any[] {

    if(sortBy.indexOf('.') != -1){
      let sortByArr = sortBy.split('.');
      let aProp, bProp;
      items.sort((a: any, b: any) => {
        aProp = a[sortByArr[0]][sortByArr[1]];
        bProp = b[sortByArr[0]][sortByArr[1]];
        if (aProp < bProp || !aProp && bProp) {
          return ascending ? -1 : 1;
        } else if (aProp > bProp || aProp && !bProp) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });
    } 
    else if(sortBy == 'siteAssetName'){
      items.sort((a: any, b: any) => {
        if (a.siteName+''+a.name < b.siteName+''+b.name) {
            return ascending ? -1 : 1;
        } else if (a.siteName+''+a.name > b.siteName+''+b.name) {
            return ascending ? 1 : -1;
        } else {
            return 0;
        }        
      });
    } 
    else if(sortBy == 'contractStatus'){
      items.sort((a: any, b: any) => {
        if (a.contractStatus < b.contractStatus) {
          return ascending ? -1 : 1;
        } else if (a.contractStatus > b.contractStatus) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    else if(sortBy == 'fuelLevel'){
      items.sort((a: any, b: any) => {
        if ((a.fuelLevel/a.fuelLevelMax) < (b.fuelLevel/b.fuelLevelMax)) {
            return ascending ? -1 : 1;
        } else if ((a.fuelLevel/a.fuelLevelMax) > (b.fuelLevel/b.fuelLevelMax)) {
            return ascending ? 1 : -1;
        } else {
            return 0;
        }        
      });
    } 
    else if(sortBy == 'productName'){
      items.sort((a: any, b: any) => {
        if (a.productInfo.name < b.productInfo.name) {
          return ascending ? -1 : 1;
        } else if (a.productInfo.name > b.productInfo.name) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if(sortBy.match('Address') && !sortBy.match('displayAddress')){
      let addressProps = sortBy.split('.');
      items.sort((a: any, b: any) => {
        if(a[addressProps[0]][addressProps[1]] < b[addressProps[0]][addressProps[1]]){
          return ascending ? -1 : 1;
        } else if (a[addressProps[0]][addressProps[1]] > b[addressProps[0]][addressProps[1]]) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (sortBy.toLowerCase().match('date')){
      items.sort((a: any, b: any) => {
        if (moment(a[sortBy]).isBefore(moment(b[sortBy]))) {
          return ascending ? -1 : 1;
        } else if (moment(a[sortBy]).isAfter(moment(b[sortBy]))) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (sortBy.toLowerCase().match('confirmeffectivedt') || sortBy.toLowerCase().match('confirmstartdt')
    || sortBy.toLowerCase().match('confirmenddt')){
      items.sort((a: any, b: any) => {
         if (moment(a[sortBy],'MM/dd/yyyy').isBefore(moment(b[sortBy],'MM/dd/yyyy'))) {
          return ascending ? -1 : 1;
        } else if (moment(a[sortBy],'MM/dd/yyyy').isAfter(moment(b[sortBy],'MM/dd/yyyy'))) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });        
    }
    else {
      items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy] || !a[sortBy] && b[sortBy]) {
          return ascending ? -1 : 1;
        } else if (a[sortBy] > b[sortBy] || a[sortBy] && !b[sortBy]) {
          return ascending ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
    return items;
  }
}
