import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../shared/data.service";
import {Subscription} from "rxjs";
import {FadeAnimation} from "../animations/fadeAnimation";
import { IUserAccount } from '../shared/entities/profile';
import { MetricsService } from '../metrics/metrics.service';
import { PortalService } from '../shared/portal.service';
import { Permissions } from '../shared/const/permissions';

@Component({
  selector: 'app-pricing-tc',
  templateUrl: './pricing-tc.component.html',
  animations: [FadeAnimation],
  styleUrls: ['./pricing-tc.component.scss']
})
export class PricingTcComponent implements OnInit {

  accepted: boolean = false;

  selectedAccount: IUserAccount;

  accountSubscription: Subscription;

  constructor( private router: Router,
              private dataService: DataService,
              private metricsService: MetricsService,
              private portalService: PortalService
              ) { }

  ngOnInit() {
    this.dataService.setTitleSource('Pricing Platform Access');
    this.dataService.setSelectedNavItem('');
    this.dataService.setContextualHelp(false);
    
    this.selectedAccount = this.dataService.getAccountSource();

    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goHome();
    });
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
  }

  continue(){

    if (this.accepted) {
      this.metricsService.submitUserPricingTcStatus().subscribe();
      this.dataService.setPricingTcEnabled(true);
      this.router.navigate(['/pricing']);
    }

  }

  isGoHomeActive() {
    return this.portalService.hasPermission(Permissions.PostSale);
  }

  goHome() {
    this.router.navigate(['/third-party/dashboard']);
  }
}
