import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {
  IUserMetrics, IUserMetricsDetail, ITransactionMetrics, IEnrollmentHistory, IEnrollmentMonthly,
  IEnrollmentDetail
} from "./metrics";
import {Observable} from "rxjs";
import * as moment from 'moment';
import {PortalService} from "../shared/portal.service";


@Injectable()
export class MetricsService {

  constructor(private oldHttp: Http,
              private http: HttpClient,
              private portalService: PortalService) { }

  getUserMetricData(startDate: string, endDate: string, organization: string){
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');
    // console.log("FROM DATE: ", startDate);
    // console.log("END DATE: ", endDate);
    // console.log("organization: ", organization);
    let url = environment.getUserMetricDataUrl
      .replace('{fromDateParam}', startDate)
      .replace('{toDateParam}', endDate)
      .replace('{orgParam}', organization);

    return this.http
      .get(url)
      .map((response: HttpResponse<IUserMetrics[]>) => response)
      .catch(this.handleError);
  }

  getUserMetricDataUX(startDate: string, organization: string,eventType: string){
    startDate = moment(startDate).format('YYYY-MM-DD');
    // endDate = moment(endDate).format('YYYY-MM-DD');
    // console.log("FROM DATE: ", startDate);    
    // console.log("organization: ", organization);
    let url = environment.getUserMetricDataUxUrl
      .replace('{fromDateParam}', startDate)      
      .replace('{orgParam}', organization)
      .replace('{eventTypeParm}', eventType);

    return this.http
      .get(url)
      .map((response: HttpResponse<IUserMetrics[]>) => response)
      .catch(this.handleError);
  }


  
  getUserMetricDataTX(startDate: string, organization: string,eventType: string){
    startDate = moment(startDate).format('YYYY-MM-DD');
    // endDate = moment(endDate).format('YYYY-MM-DD');
    // console.log("FROM DATE: ", startDate);    
    // console.log("organization: ", organization);
    let url = environment.getUserMetricDataTxUrl
      .replace('{fromDateParam}', startDate)      
      .replace('{orgParam}', organization)
      .replace('{eventTypeParm}', eventType);

    return this.http
      .get(url)
      .map((response: HttpResponse<IUserMetrics[]>) => response)
      .catch(this.handleError);
  }





  // getDetail(date: string, type: string, organization: string){
  //   let url = environment.getUserMetricDetailDataUrl;
  //
  //   return this.http
  //     .get(url)
  //     .map((response: HttpResponse<IUserMetricsDetail[]>) => response)
  //     .catch(this.handleError);
  // }

  
  getTransactionMetricsDetails(forDate: string, colName: string, organization: string, userType: string, salesGroup: string){
    forDate = moment(forDate).format('YYYY-MM-DD');
    // toDate = moment(toDate).format('YYYY-MM-DD');
    colName = encodeURI(colName);
    organization = encodeURI(organization);
    userType = encodeURI(userType);
    salesGroup = encodeURI(salesGroup);


    // // console.log("ORGANIZATION PARAM: ", organization);
    // // console.log("USER TYPE PARAM: ", userType);

    let url = environment.getUserTransactionMetricDetailUrl
      .replace('{forDateParam}', forDate)       
      .replace('{eventParam}', colName)
      .replace('{orgParam}', organization)
      .replace('{userTypeParam}', userType)
      .replace('{salesGroupParam}', salesGroup);

    return this.http
      .get(url)
      .map((response: HttpResponse<ITransactionMetrics[]>) => response)
      .catch(this.handleError);
  }



  getTransactionMetrics(fromDate: string, toDate: string, organization: string, userType: string, salesGroup: string){
    fromDate = moment(fromDate).format('YYYY-MM-DD');
    toDate = moment(toDate).format('YYYY-MM-DD');
    organization = encodeURI(organization);
    userType = encodeURI(userType);
    salesGroup = encodeURI(salesGroup);

    // // console.log("ORGANIZATION PARAM: ", organization);
    // // console.log("USER TYPE PARAM: ", userType);

    let url = environment.getUserTransactionMetricUrl
      .replace('{fromDateParam}', fromDate)
      .replace('{toDateParam}', toDate)
      .replace('{orgParam}', organization)
      .replace('{userTypeParam}', userType)
      .replace('{salesGroupParam}', salesGroup);

    return this.http
      .get(url)
      .map((response: HttpResponse<ITransactionMetrics[]>) => response)
      .catch(this.handleError);
  }

  getHistoricalEnrollmentMetrics(grouping: string, startDate: string, endDate: string){

    let url = environment.getHistoricalEnrollmentMetricUrl
      .replace('{groupingParam}', grouping)
      .replace('{startParam}', startDate)
      .replace('{endParam}', endDate);

    return this.http
      .get(url)
      .map((response: HttpResponse<IEnrollmentHistory[]>) => response)
      .catch(this.handleError);
  }

  getCurrentEnrollmentMetrics(grouping: string){
    let url = environment.getCurrentEnrollmentMetricUrl
      .replace('{groupingParam}', grouping);

    return this.http
      .get(url)
      .map((response: HttpResponse<IEnrollmentMonthly>) => response)
      .catch(this.handleError);
  }

  getUnassignedCustomers(grouping: string){
    let url = environment.getUnassignedCustomersUrl
      .replace('{groupingParam}', grouping);

    return this.http
      .get(url)
      .map((response: HttpResponse<IEnrollmentDetail[]>) => response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

  exportTransactions(fromDate: string, toDate: string, organization: string, orgName: string, userType: string, salesGroup: string){
    fromDate = moment(fromDate).format('YYYY-MM-DD');
    toDate = moment(toDate).format('YYYY-MM-DD');
    organization = encodeURI(organization);
    userType = encodeURI(userType);
    salesGroup = encodeURI(salesGroup);

    let url = environment.exportFromCustomerUrl
      .replace('{fromDateParam}', fromDate)
      .replace('{toDateParam}', toDate)
      .replace('{orgParam}', organization)
      .replace('{orgNameParam}', orgName)
      .replace('{userTypeParam}', userType)
      .replace('{salesGroupParam}', salesGroup);

    this.portalService.exportToExcel(url, 'AdoptionFromCustomerMetrics.xlsx');
  }

  exportUserMetrics(fromDate: string, toDate: string, organization: string, orgName: string){
    fromDate = moment(fromDate).format('YYYY-MM-DD');
    toDate = moment(toDate).format('YYYY-MM-DD');
    organization = encodeURI(organization);

    let url = environment.exportByCustomerUrl
      .replace('{fromDateParam}', fromDate)
      .replace('{toDateParam}', toDate)
      .replace('{orgParam}', organization)
      .replace('{orgNameParam}', orgName);

    this.portalService.exportToExcel(url, 'AdoptionByCustomerMetrics.xlsx');
  }

  exportEnrollmentMetrics(grouping: string, startDate: string, endDate: string, period: string){

    let url = environment.exportEnrollmentUrl
      .replace('{groupingParam}', grouping)
      .replace('{startParam}', startDate)
      .replace('{endParam}', endDate)
      .replace('{periodParam}', period);

    this.portalService.exportToExcel(url, 'EnrollmentMetrics.xlsx');
  }

  submitUserPricingTcStatus(){
    let url = environment.acknowledgeUrl;
    url = url + '?acktype=PRICING_TERMS_AND_CONDITIONS_ACKNOWLEDGE';

    return this.http
      .get(url, {'responseType' : 'text'})
      .map((response: any) => response)
      .catch(this.handleError);
  }

  getUserPricingTcStatus() {
    let url = environment.getUserPricingTcStatus;

    return this.http
    .get(url)
    .map((response: HttpResponse<boolean>) => response)
    .catch(this.handleError);
  }

}
