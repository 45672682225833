import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../shared/data.service';
import { FadeAnimation } from '../animations/fadeAnimation';
import { CookieService } from 'ngx-cookie-service';
import { PortalService } from '../shared/portal.service';
import { IUserAccount, IUserAccountFlag } from '../shared/entities/profile';
import { Subscription } from 'rxjs';
import { Authority } from '../shared/const/authority';
import { Permissions } from '../shared/const/permissions';
import { IAccount } from '../shared/entities/account';
import { IProduct } from '../shared/entities/product';
import { IContract } from '../shared/entities/contract';
import { MetricsService } from '../metrics/metrics.service';
import { Header, HeaderSuperUser } from './header';
import * as moment from 'moment';
import { SelectProductContext } from '../shared/const/selectProductContext';
import { AccountBrandingConstant } from '../shared/const/accountBranding';
import { AccountsService } from '../accounts/accounts.service';
import { ProfileService } from '../profile/profile.service';
import { ISite } from '../shared/entities/site';
declare var $: any;


@Component({
  selector: 'app-header',
  animations: [
    FadeAnimation
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [CookieService]
})
export class HeaderComponent implements OnInit {
  //lhSurvey: boolean;
  selectedNavItem = 'dashboard'; // Default to highlighting "Dashboard" text
  readyToCloseSubNav: boolean;
  accountsForUser: IUserAccount[];
  accountWithContracts: IAccount;
  allSites: ISite[];
  thirdPartyAccounts: IUserAccount[] = [];
  filteredAccounts: IUserAccount[] = [];
  recentAccounts: IUserAccount[] = [];
  selectedAccount: IUserAccount;
  accountsToggled = false;
  accountLoaded = false;
  loggedIn: boolean;
  isSuperUser: boolean;
  isSuperAdmin: boolean;
  accountSearch = '';
  subNavPos: number;
  showSubNav: boolean;
  username: string;
  avatar: any;
  subNav: any[];
  subNavGroups: any[][] = [];
  header: any;
  typeaheadIndex: number = -1;
  maskMode: boolean = false;
  maskModeMessage = 'Turn On Masking';
  accountSourceSubscription: Subscription;
  selectedNavItemSourceSubscription: Subscription;
  accountsForUserSourceSubscription: Subscription;
  thirdPartyAccountsSubscription: Subscription;
  profileSubscription: Subscription;
  profileImageSubscription: Subscription;
  userSubscription: Subscription;
  loggedInSubscription: Subscription;

  pricingTcSubscription: Subscription;
  mobileAccountsToggled: boolean = false;

  mobileAccountSearch: string;
  mobileTypeaheadIndex: number = -1;
  shadow: boolean;
  isErcot = false;
  isNeiso = false;

  autopay: boolean;
  viewPortal: boolean;
  fourCPandDgenOnlyCustomer: boolean;
  fourCPOnlyCustomer: boolean;
  dgenOnlyCustomer: boolean; //customer access if not one of these bools
  scsCustomer: boolean = false;
  // nonStandardBillingCustomer: boolean;

  pricingFlagEnabled: boolean;
  isPostSaleEnabled: boolean;
  isPricingEnabled: boolean;
  canViewCommissions: boolean;
  canViewBrokerConfirm: boolean;
  canViewBrokerDropEnrollment: boolean; //used for BOTH enroll and drop reports
  showAEMHelp: boolean = false;
  showBillingManagement: boolean;
  showFourCP: boolean;
  showRenewables: boolean;
  showRealTimeUsage: boolean;
  showDistributedGeneration: boolean;
  showOnsiteGeneration: boolean;

  isIE = false;
  menuActivatedForAccountId = '';

  currentInteriorNav: string = '';
  visitedInteriorNav: boolean = false;
  activeNavItemIndex: string;
  lastSearchedTerm = '';


  currentActivePage: string;


  constructor(private router: Router,
    public dataService: DataService,
    private cookieService: CookieService,
    private profileService: ProfileService,
    private portalService: PortalService,
    private metricsService: MetricsService,
    private accountService: AccountsService) { }


  ngOnInit() {

    this.activeNavItemIndex = '';
    this.readyToCloseSubNav = false;
    this.loggedIn = this.dataService.getLoggedIn();
    this.loggedInSubscription = this.dataService.loggedInUpdated.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
    this.selectedNavItemSourceSubscription = this.dataService.selectedNavItemSourceUpdated.subscribe(
      (navItem) => {
        this.selectedNavItem = navItem;
      }, err => {
        console.error(err);
      }
    );


    this.dataService.currentActivePage.subscribe(currentActivePage => this.currentActivePage = currentActivePage)
    //this.activeNavItemIndex = this.currentActivePage;
    this.thirdPartyAccountsSubscription = this.dataService.thirdPartyAccountsSourceUpdated.subscribe((tpas) => {
      this.thirdPartyAccounts = tpas;
    });

    this.accountsForUserSourceSubscription = this.dataService.accountsForUserSourceUpdated.subscribe((accounts) => {
      this.accountsForUser = accounts;
      this.filteredAccounts = accounts;
      this.loadDefaultAccount();
    });



    this.selectedAccount = this.dataService.getAccountSource();

    if (this.selectedAccount && this.selectedAccount.accessType === 'ThirdParty') {
      setTimeout(() => {
        this.accountLoaded = true;
      }, 500);
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.showSubNav = false;
      this.accountLoaded = false;

      this.pricingFlagEnabled = account.pricingFlagEnabled;
      this.isPostSaleEnabled = true;
      this.isPricingEnabled = false;
      this.dataService.setShowCreditCard(false);
      if (account.accessType === 'ThirdParty') {
        this.isPricingEnabled = this.hasPricingEnabled(account);
        this.isPostSaleEnabled = this.hasPostSaleAccess(account);
      }
      this.selectedAccount = account;
      this.canViewCommissions = this.hasCommissionsAccess(account);
      this.canViewBrokerConfirm = this.hasBrokerConfirm(account);
      this.canViewBrokerDropEnrollment = this.hasBrokerDropEnrollment(account);
      this.portalService.getAccountFlags(account).subscribe((response) => {
        if (response.availableFeatures.includes("AEM_HELP")) {
          this.dataService.setShowAEMHelp(true);
          this.showAEMHelp = true;
        } else {
          this.dataService.setShowAEMHelp(false);
          this.showAEMHelp = false;
        }
        if (response.availableFeatures.includes("BILL_MGMT")) {
          this.dataService.setShowBillingManagement(true);
          this.showBillingManagement = true;
        } else {
          this.dataService.setShowBillingManagement(false);
          this.showBillingManagement = false;
        }
        if (response.availableFeatures.includes("MBP")) {
          this.dataService.setShowMBP(true);
        }
        else {
          this.dataService.setShowMBP(false);
        }
        this.dataService.showMarketDashboard = response.availableFeatures.includes("MARKET_DASHBOARD");
        if (account.accessType !== 'ThirdParty') {
          if (response.comvergeAccess) {
            this.dataService.setComverge(true);
            this.selectedAccount.comverge = 1;
          } else {
            this.dataService.setComverge(false);
            this.selectedAccount.comverge = 0;
          }

          if (response.renewables) {
            this.showRenewables = true;
          } else {
            this.showRenewables = false;
          }

          if (response.shadowMeters) {
            this.shadow = true;
            this.showRealTimeUsage = true;
          } else {
            this.showRealTimeUsage = false;
          }

          // PBI 84567: Restrict dgen subpage visibility to super admin, super user, or those with dgen account flag
          if (response.dgen) {
            this.portalService.loadDgenSites(account);
            this.shadow = true;
            this.showDistributedGeneration = true;
            this.showOnsiteGeneration = true;
          } else {
            this.showDistributedGeneration = false;
            this.showOnsiteGeneration = false;
          }

          if (!response.shadowMeters && !response.dgen) {
            this.shadow = false;
          }
          this.dataService.setIsShadow(this.shadow);

          if (response.availableFeatures.includes("CREDIT_CARD")) {
            this.dataService.setShowCreditCard(true);
          } else {
            this.dataService.setShowCreditCard(false);
          }


          if (response.availableFeatures.includes("AUTOPAY")) {
            this.autopay = true;
            this.dataService.setAutopay(true);
          } else {
            this.autopay = false;
            this.dataService.setAutopay(false);
          }

          if (response.availableFeatures.includes("MBP")) {
            this.dataService.setShowMBP(true);
          }
          else {
            this.dataService.setShowMBP(false);
          }

          this.portalService.getScsFlagForAccount(this.dataService.getAccountSource().id).subscribe((resp => {
            // console.log(resp);
            if (resp.length > 0) {
              if (resp[0].scsFlagCustomer != 'Y') {
                //set header access values to f, unsure if there are any y's at all in dal dev
                this.scsCustomer = false;
                this.dataService.setScsCustomer(false);
              }
              else {
                this.scsCustomer = true;
                this.dataService.setScsCustomer(true);
              }
            }
            else {
              console.log('no scs info found, hiding scs pages');
              this.scsCustomer = false;
              this.dataService.setScsCustomer(false);
            }
          }));


          this.dataService.setIsShadow(this.shadow);
          this.accountWithContracts = this.dataService.getAccountWithContractDataSource();
          this.allSites = this.dataService.getSitesForAccountSource();
          this.getHeader();
          this.changeAccount(account);
          this.accountLoaded = true;

          if (response.availableFeatures.includes("MBP")) {
            this.dataService.setShowMBP(true);
          }
          else {
            this.dataService.setShowMBP(false);
          }

          this.accountWithContracts = this.dataService.getAccountWithContractDataSource();
          this.allSites = this.dataService.getSitesForAccountSource();
          this.changeAccount(account);
          this.getHeader();
          this.accountLoaded = true;
        }
        else {

          this.accountWithContracts = this.dataService.getAccountWithContractDataSource();
          this.allSites = this.dataService.getSitesForAccountSource();
          this.changeAccount(account);
          this.getHeader();
          this.accountLoaded = true;
        }
      });

      if (this.accountWithContracts) {
        setTimeout(() => {
          // this.accountLoaded = true;
        }, 500);
      }

      // if (this.selectedAccount.accessType == 'ThirdParty') {
      //   setTimeout(() => {
      //     this.accountLoaded = true;
      //   }, 500);
      // }
      this.walkMeRole();
    });



    // this.lhSurvey = this.dataService.gethSurvey();
    // if (this.lhSurvey) {

    //   $(document).ready(function () {
    //     // setTimeout(function() {console.log("Firing script for loading Maritz Survey xyz");},20000);
    //     // console.log("Firing script for loading Maritz Survey xyz");
    //     let node1 = document.createElement('script');
    //     node1.type = 'text/JavaScript';
    //     node1.src = 'https://nrg.allegiancetech.com/surveys/JC7293/Scripts/clientExtensibility.js';
    //     // node1.src = 'https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js';
    //     // document.body.removeChild(node1);
    //     setTimeout(function () { document.body.appendChild(node1); }, 2000);

    //     // var node2 = document.createElement('script');
    //     // node2.type = 'text/JavaScript';
    //     // node2.src = 'https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js';
    //     // document.body.removeChild(node2);
    //     // document.body.appendChild(node2);
    //   });

    //   this.dataService.sethSurvey(false);


    // }

    this.profileSubscription = this.dataService.profileUpdated.subscribe((profile) => {
      this.username = profile.firstName;
    });

    this.profileImageSubscription = this.dataService.profileImageUpdated.subscribe((profileImage) => {
      this.avatar = profileImage;
    });

    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    if (this.isSuperUser) {
      this.getRecentAccounts();
    }
    this.userSubscription = this.dataService.userSourceUpdated.subscribe(() => {
      if (this.isSuperUser) {
        this.getRecentAccounts();
      }
      this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
      this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    });
    // this.accountWithContracts = this.dataService.getAccountWithContractDataSource();
    // if (this.accountWithContracts) {
    //   this.getHeader();
    //   setTimeout(() => {
    //     this.accountLoaded = true;
    //   }, 500);
    // }

    // this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
    //   this.accountWithContracts = account;
    //   setTimeout(() => {
    //     this.accountLoaded = true;
    //   }, 500);
    // });
    if (this.isSuperUser || this.isSuperAdmin) {
      this.header = HeaderSuperUser;
    }
    else {
      this.header = Header;
    }
    this.isIEBrowser();

    if (this.isIE) {
      console.log('This is an IE Browser');
    }

    this.pricingTcSubscription = this.metricsService.getUserPricingTcStatus().subscribe(
      (res) => {
        this.dataService.setPricingTcEnabled(Boolean(res));
      }
    );

  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.selectedNavItemSourceSubscription) this.selectedNavItemSourceSubscription.unsubscribe();
    if (this.accountsForUserSourceSubscription) this.accountsForUserSourceSubscription.unsubscribe();
    if (this.profileSubscription) this.profileSubscription.unsubscribe();
    if (this.profileImageSubscription) this.profileImageSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.pricingTcSubscription) this.pricingTcSubscription.unsubscribe();
  }

  getHeader() {
    console.log('- GET HEADER CALLED -');
    const viewPortal = this.portalService.hasPermission(Permissions.ViewPortal);
    this.viewPortal = viewPortal;
    this.hasReliabilityProducts();
    this.hasFourCPProducts();
    this.fourCPandDgenOnlyCustomer = this.isFourCPandDgenOnlyCustomer();
    this.fourCPOnlyCustomer = this.isFourCPOnlyCustomer();
    this.dgenOnlyCustomer = this.isdgenOnlyCustomer();
    if (this.selectedAccount.accessType === 'ThirdParty') {
      if (this.isPostSaleEnabled) {
        this.router.navigate(['/third-party']);
      } else if (this.showPricing()) {
        this.setSelectedNavItem('pricing');
        this.navigateTo('pricing');
      } else {
        this.navigateTo('access-denied');
      }
    } else if (this.fourCPandDgenOnlyCustomer || this.fourCPOnlyCustomer) {
      // if 4cp only or 4CP + dgen only, then direct to 4CP dashboard
      this.router.navigate(['/fourcp/overview']);
    } else if (this.dgenOnlyCustomer && this.showDistributedGeneration) {
      // if dgen only, then go to dgen page
      this.router.navigate(['/shadow-metering/distributed-generation']);
    } else {
      // otherwise, go to dashboard
      if (this.portalService.checkValidContracts() && !this.dgenOnlyCustomer && !this.fourCPOnlyCustomer) {
        this.router.navigate(['/dashboard']);
      } else if (!this.dgenOnlyCustomer && !this.fourCPOnlyCustomer) {
        this.router.navigate(['/welcome']);
      } else {
        this.navigateTo('access-denied');
      }
    }
    this.validateSelectedNavItem();
  }

  getRecentAccounts() {
    let recentAccounts = this.cookieService.get('recent-accounts');
    if (!recentAccounts) recentAccounts = '';
    const recentAccountIds = recentAccounts.split(',');
    this.recentAccounts = [];
    let added;
    recentAccountIds.forEach((id) => {
      added = false;
      this.accountsForUser.forEach((account) => {
        if (account.id == id && !added) {
          this.recentAccounts.push(account);
          added = true;
        }
      });
      if (this.thirdPartyAccounts && !added) {
        this.thirdPartyAccounts.forEach((tpa) => {
          if (tpa.id == id) this.recentAccounts.push(tpa);
        });
      }
    });
  }

  navigateTo(dest: string, slug = '') {
    this.router.navigate(['/' + dest]);
    this.showSubNav = false;
    this.closeSideNav('mobileNav');
    this.setSelectedNavItem('');
    this.currentInteriorNav = '';
    this.activeNavItemIndex = '';
    if (this.header && this.header.enabled) {
      if (dest === 'markets/public-markets') {
        this.setTab(slug);
      }
    }

    this.currentInteriorNav = '';

    this.portalService.callAEM();
  }

  // function to set the tab for Markets --DRR 08222019
  setTab(tab: string) {
    this.dataService.setSelectedTabForMarketsReports(tab);
  }
  setInteriorNav(navString: string) {
    if (this.currentInteriorNav == navString) {
      this.currentInteriorNav = '';
    }
    else {
      this.currentInteriorNav = navString;
    }
  }
  // --DRR 08222019

  setSelectedNavItem(navItem: string) {
    this.dataService.setSelectedNavItem(navItem);
    this.activeNavItemIndex = navItem;
  }

  showNav(navItem: string) {
    if (navItem == '' && !this.readyToCloseSubNav) {
      this.readyToCloseSubNav = true;
    }
    else {
      if (!this.showSubNav) {
        this.activeNavItemIndex = navItem; //better way to leave underline?
        this.currentInteriorNav = '';
        this.setSelectedNavItem(navItem);
        this.showSubNav = this.hasSubNav(navItem);
      } else {
        this.activeNavItemIndex = '';
        this.showSubNav = false;
      }
      this.readyToCloseSubNav = false;
    }
  }

  showMobileNav(navItem: string) {
    if (this.selectedNavItem == navItem) {
      this.selectedNavItem = '';
    }
    else {
      this.selectedNavItem = navItem;
    }
  }

  navigate(dest: string, navItem?: string, subNavPos?: number) {
    this.subNavPos = subNavPos;
    if (this.header && (this.header.subnavs && !this.header.subnavs[dest])) {
      // If there is no subnav for the selected nav item, navigate there.
      this.router.navigate(['/' + dest]);

      if (navItem) {
        // Navigating from within the subnav
        this.dataService.setSelectedNavItem(navItem);
      } else {
        // Immediate Navigation (e.g. Contact Us)
        this.dataService.setSelectedNavItem(dest);
      }
      this.showSubNav = false;
    } else {
      // There is a subnav for this nav item, open it.
      if (this.selectedNavItem === dest) {
        this.showSubNav = !this.showSubNav;
      } else {
        this.showSubNav = true;
        this.dataService.setSelectedNavItem(dest);
      }
      this.subNav = this.header.subnavs[this.selectedNavItem];
      this.subNavGroups = this.getSubnavGroups();
    }
  }

  toggleMaskMode() {
    this.maskMode = !this.maskMode;
    if (this.maskMode) {
      this.maskModeMessage = 'Turn Off Masking';
    } else {
      this.maskModeMessage = 'Turn On Masking';
    }
    this.dataService.setMaskMode(this.maskMode);
  }

  getFakeCompanyName(account: IUserAccount) {
    return this.portalService.getFakeCompanyName(account);
  }

  viewProfile() {
    this.router.navigate(['/profile']);
  }

  logOut() {
    this.portalService.logout().subscribe();
  }

  // For mobile
  openSideNav(id: string) {
    document.getElementById(id).style.width = '100%';
  }

  // For mobile
  closeSideNav(sideNavId: string, cascade?: boolean, dest?: string, navItem?: string) {
    this.selectedNavItem = '';
    this.mobileAccountSearch = '';
    this.mobileAccountsToggled = false;
    // console.log("Closing nav!");
    document.getElementById(sideNavId).style.width = '0';
    if (cascade) {
      document.getElementById('mobileNav').style.width = '0';
    }
    if (dest) {
      if (navItem) {
        this.navigate(dest, navItem);
      } else {
        this.navigate(dest);
      }
    }
  }

  showAccounts() {
    // console.log("Show accounts");
    this.accountsToggled = true;
  }

  showMobileAccounts() {
    // console.log("Show mobile accounts");
    this.mobileAccountsToggled = true;
  }
  hideMobileAccounts() {
    this.mobileAccountsToggled = false;
  }

  hideAccounts() {
    this.accountsToggled = false;
  }


  hasPricingEnabled(account: IUserAccount) {
    if (this.pricingFlagEnabled) {
      if (this.isSuperAdmin || this.isSuperUser) {
        if (this.portalService.userHasAuthority(Authority.PricingAll)) {
          return true;
        }
      } else {
        if (account.accessLevel.toUpperCase() === 'ADMIN') {
          return true;
        }
        if (this.portalService.hasPermission(Permissions.PricingAll) ||
          this.portalService.hasPermission(Permissions.PricingUser)) {
          return true;
        }
      }
    }
    return false;
  }

  hasPostSaleAccess(account: IUserAccount) {
    if (account.accessLevel.toUpperCase() === 'ADMIN' || this.isSuperAdmin || this.isSuperUser) {
      return true;
    }
    return this.portalService.hasPermission(Permissions.PostSale);
  }

  hasCommissionsAccess(account: IUserAccount) {
    if (account != null) {
      if (account.accessType != null && account.accessType.toUpperCase() === 'THIRDPARTY') {
        if ((account.accessLevel != null && account.accessLevel.toUpperCase() === 'ADMIN') || this.isSuperAdmin || this.isSuperUser) {
          return true;
        }
        return this.portalService.hasPermission(Permissions.Commissions);
      }
    }
    return false;
  }

  hasBrokerConfirm(account: IUserAccount) {
    if(account != null) {
      if (account.accessType != null && account.accessType.toUpperCase() === 'THIRDPARTY') {
          if ((account.accessLevel != null && account.accessLevel.toUpperCase() ==='ADMIN') || this.isSuperAdmin || this.isSuperUser) {
            return true;
          }
          return this.portalService.hasPermission(Permissions.BrokerConfirms);
        }
    }
    return false;
  }

  hasBrokerDropEnrollment(account: IUserAccount) {
    if(account != null) {
      if (account.accessType != null && account.accessType.toUpperCase() === 'THIRDPARTY') {
          if ((account.accessLevel != null && account.accessLevel.toUpperCase() ==='ADMIN') || this.isSuperAdmin || this.isSuperUser) {
            return true;
          }
          return this.portalService.hasPermission(Permissions.BrokerDropsEnrollments);
        }
    }
    return false;
  }


  changeAccount(account: IUserAccount) {
    this.cookieService.delete('default-account');
    this.cookieService.set('default-account', String(account.id));
    let recentAccounts = this.cookieService.get('recent-accounts');
    if (!recentAccounts) recentAccounts = '';
    const recentAccountIds = recentAccounts.split(',');
    let hasAccountId = false;
    recentAccountIds.forEach((accountId) => {
      if (accountId == account.id) hasAccountId = true;
    });
    if (!hasAccountId) {
      recentAccountIds.unshift(account.id);
      let newRecentAccounts = '';
      for (let i = 0; i < 5; i++) {
        if (recentAccountIds[i]) newRecentAccounts += (recentAccountIds[i] + ',');
      }
      this.cookieService.set('recent-accounts', newRecentAccounts);
    }
    this.getRecentAccounts();
    this.walkMeRole();
  }

  loadDefaultAccount() {
    const defaultAccount = this.cookieService.get('default-account');
    if (!this.isSuperUser && !this.isSuperAdmin) {
      if (defaultAccount != null) {
        let found = false;
        if (this.thirdPartyAccounts && this.thirdPartyAccounts.length > 0) {
          this.thirdPartyAccounts.forEach((tpa) => {
            if (tpa.id === defaultAccount) {
              this.selectAccount(tpa);
              found = true;
            }
          });
          if (!found) {
            this.selectAccount(this.thirdPartyAccounts[0]);
            found = true;
          }
        }
        if (!found) {
          this.accountsForUser.forEach((account) => {
            if (account.id === defaultAccount && !found) {
              this.selectAccount(account);
            }
          });
        }
      } else {
        if (this.thirdPartyAccounts && this.thirdPartyAccounts.length > 0) {
          this.selectAccount(this.thirdPartyAccounts[0]);
        } else if (this.accountsForUser && this.accountsForUser.length > 0) {
          this.selectAccount(this.accountsForUser[0]);
        }
      }
    } else {
      let found = false;
      //load nrg account from back-end
      let nrgAccount;
      this.accountService.getAccount('1-E5237').subscribe(account => {
        nrgAccount = account;
        nrgAccount.name = account.header.name;
        let found = false;
        if (!nrgAccount) {
          this.selectAccount(this.accountsForUser[0]);
        }
        else {
          if (this.thirdPartyAccounts && this.thirdPartyAccounts.length > 0) {
            this.thirdPartyAccounts.forEach((tpa) => {
              if (tpa.id === nrgAccount.id) {
                this.selectAccount(tpa);
                found = true;
              }
            });
          }
          if (!found) {
            this.accountsForUser.forEach((account) => {
              if (account.id === nrgAccount.id && !found) {
                this.selectAccount(account);
                found = true;
              }
            });
          }
          if (!found)
            this.selectAccount(nrgAccount);
        }
      });

    }


  }


  walkMeRole() {
    (window as any).wmUserRole = this.selectedAccount.accessType ? this.selectedAccount.accessType.toUpperCase() : null;
    (window as any).wmIsTexasCustomer = this.selectedAccount.accountBranding ? this.selectedAccount.accountBranding.toUpperCase() == 'RELIANT' : null;
    (window as any).wmUserEmail = this.dataService.getProfile().email;


    let companyCodeList = [];
    if(this.accountWithContracts && this.accountWithContracts.contracts){
      this.accountWithContracts.contracts.forEach((contract=>{
        if(contract.companyCode){
          companyCodeList.push(contract.companyCode);
        }
      }))
    }
    (window as any).wmCompanyCode = companyCodeList;
    let marketList = [];
    if(this.accountWithContracts && this.accountWithContracts.contracts){
      this.accountWithContracts.contracts.forEach((contract=>{
        if(contract.market){
          marketList.push(contract.market);
        }
      }))
    }
    (window as any).wmCustomerMarket = marketList;

    let productType = [];
    if(this.accountWithContracts && this.accountWithContracts.contracts){
      productType = this.accountWithContracts.contracts.map(x => x.products.map(y=>y.name));
      (window as any).wmProductType = productType;
    }


      if(!this.allSites){ // All SITES call takes time sometimes to return
        setTimeout(() => {
          this.allSites = this.dataService.getSitesForAccountSource();
          let zipCodes = [];
          if(this.allSites) {
            zipCodes = this.allSites.map(x => x.serviceAddress.zipCode);
            (window as any).wmZipCodes = zipCodes;
          }

          let tdsps = [];
          if(this.allSites){
            tdsps = this.allSites.map(x => x.utility);
            (window as any).wmTdsps = tdsps;
          }
        }, 5000);

      }
      else {
        let zipCodes = [];
        if(this.allSites) {
          zipCodes = this.allSites.map(x => x.serviceAddress.zipCode);
          (window as any).wmZipCodes = zipCodes;
        }

        let tdsps = [];
        if(this.allSites){
          tdsps = this.allSites.map(x => x.utility);
          (window as any).wmTdsps = tdsps;
        }
      }



  }

  navigateTypeahead(down: boolean) {
    if (down && this.typeaheadIndex < this.filteredAccounts.length - 1) {
      this.typeaheadIndex++;
    } else if (!down && this.typeaheadIndex > -1) {
      this.typeaheadIndex--;
    }
  }

  selectAccount(account?: IUserAccount) {

    if (this.filteredAccounts.length > 0) {
      if (this.typeaheadIndex === -1) {
        this.typeaheadIndex = 0;
      }
      if (account) {
        this.portalService.loadAccountData(account);
        // this.changeAccount(account);
        if (this.maskMode) {
          this.dataService.setFakeAccountName(this.getFakeCompanyName(account));
        }
      } else {
        this.portalService.loadAccountData(this.filteredAccounts[this.typeaheadIndex]);
        // this.changeAccount(this.filteredAccounts[this.typeaheadIndex]);
      }
      this.accountSearch = '';
      this.resetTypeahead();
    } else if (account) {
      this.portalService.loadAccountData(account);
      // this.changeAccount(account);
      if (this.maskMode) {
        this.dataService.setFakeAccountName(this.getFakeCompanyName(account));
      }
    }
    this.accountsToggled = false;

  }

  getSelectedAccountName() {
    if (this.selectedAccount) {
      if (!this.maskMode) {
        return this.selectedAccount.name.length < 20 ? this.selectedAccount.name : this.selectedAccount.name.substr(0, 20) + '...';
      } else {
        return this.getFakeCompanyName(this.selectedAccount);
      }
    }
  }

  getHeaderAccountName() {
    if (this.selectedAccount) {
      if (!this.maskMode) {
        return this.selectedAccount.name.length < 23 ? this.selectedAccount.name : this.selectedAccount.name.substr(0, 23) + '...';
      } else {
        return this.getFakeCompanyName(this.selectedAccount);
      }
    }
  }

  searchAccounts(searchTerm: string) {
    if (this.isSuperUser) {
      this.searchAccountsForSuperUser(searchTerm);
    } else {

      this.filteredAccounts = [];
      let duplicate;
      if (searchTerm.length >= 3) {
        const escapedAccountSearch = this.portalService.escapeRegExp(searchTerm);
        this.accountsForUser.forEach((account) => {
          if (account.id && account.id.match(escapedAccountSearch) ||
            (account.name && account.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) ||
            searchTerm === '') {
            duplicate = false;
            this.filteredAccounts.forEach((acc, i) => {
              if (acc.id === account.id) {
                duplicate = true;
                if (acc.customerAgentId) {
                  this.filteredAccounts[i] = account;
                }
              }
            });
            if (!duplicate) {
              this.filteredAccounts.push(account);
            }
          }
        });
        this.thirdPartyAccounts.forEach((tpa) => {
          if (tpa.id.match(escapedAccountSearch) ||
            (tpa.name && tpa.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) || searchTerm === '') {
            if (!tpa.accessType) tpa.accessType = 'ThirdParty'; // for super users
            duplicate = false;
            this.filteredAccounts.forEach((acc) => {
              if (acc.id === tpa.id) {
                duplicate = true;
              }
            });
            if (!duplicate) {
              this.filteredAccounts.push(tpa);
            }
          }
        });
      }
    }
  }

  searchAccountsForSuperUser(searchTerm: string) {
    this.filteredAccounts = [];
    this.lastSearchedTerm = '';
    let duplicate;
    if (searchTerm.length >= 3) {
      if (searchTerm != this.lastSearchedTerm) {
        this.lastSearchedTerm = searchTerm;
        const escapedAccountSearch = this.portalService.escapeRegExp(searchTerm);
        // Call the new service that looks up data in Elastic search
        this.profileService.getUserAccounts(searchTerm).subscribe(
          (accountsForUser: any) => {
            accountsForUser.forEach((account) => {
              if (account.id.toLowerCase().match(escapedAccountSearch.toLowerCase()) ||
                (account.name && account.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) ||
                searchTerm === '') {
                duplicate = false;
                this.filteredAccounts.forEach((acc, i) => {
                  if (acc.id === account.id) {
                    duplicate = true;
                    if (acc.customerAgentId) {
                      this.filteredAccounts[i] = account;
                    }
                  }
                });
                if (!duplicate) {
                  this.filteredAccounts.push(account);
                }
              }
            });
          }, err => {
            console.error(err);
          }
        );
      }
    }
  }

  showBillingUsage() {
    this.isErcot = false;
    let isErcotAndCommodity: boolean = false;
    if (this.accountWithContracts && this.accountWithContracts.markets.length > 0) {
      this.accountWithContracts.markets.forEach((sMarket) => {
        if (sMarket === 'ERCOT') {
          this.isErcot = true;
        }
        if (sMarket === 'NEISO') {
          this.isNeiso = true;
        }
      });

      isErcotAndCommodity = this.accountWithContracts.contracts.filter(c => c.market === 'ERCOT' && c.commodity).length > 0;

    }

    return isErcotAndCommodity;
  }

  showUsage() {
    return this.isPostSaleEnabled && this.selectedAccount && this.accountWithContracts && this.selectedAccount.accessType !== 'ThirdParty'
      && (this.accountWithContracts.billTypes && this.accountWithContracts.billTypes[0] === 'Consolidated'
        && this.accountWithContracts.billTypes.length === 1)
      && (this.selectedAccount.accessType !== 'ThirdParty');
  }

  showBillingAndPayments() {
    return this.selectedAccount && ((this.selectedAccount.accessType === 'ThirdParty' && this.isPostSaleEnabled));
  }

  showBillingPaymentsAndUsage() {
    return this.isPostSaleEnabled && this.selectedAccount && this.accountWithContracts && (this.selectedAccount.accessType != 'ThirdParty'
      && (this.accountWithContracts.billTypes && this.accountWithContracts.billTypes[0] !== 'Consolidated'
        || this.accountWithContracts.billTypes.length !== 1)
    );
  }

  showMarkets() {
    return !this.dgenOnlyCustomer && this.isPostSaleEnabled && this.selectedAccount
      && this.accountWithContracts && this.selectedAccount.accessType !== 'ThirdParty'
      && (this.isSuperUser
        || (this.accountWithContracts.billTypes && this.accountWithContracts.billTypes[0] !== 'Consolidated'
          || this.accountWithContracts.billTypes.length !== 1));
  }

  showPricing(): boolean {
    return this.isPricingEnabled && this.pricingFlagEnabled && !this.dgenOnlyCustomer && !this.fourCPOnlyCustomer;
  }

  resetTypeahead() {
    this.typeaheadIndex = -1;
    this.accountsToggled = false;
  }

  manageSuperUsers() {
    this.router.navigate(['manage-super-users']);
  }

  getLeftOffset() {
    if (!(this.isPostSaleEnabled && !this.dgenOnlyCustomer && !this.fourCPOnlyCustomer && (this.showBillingAndPayments() || this.showBillingPaymentsAndUsage()))) {
      if (!this.showBillingPaymentsAndUsage() || this.fourCPOnlyCustomer) {
        if (!this.showBillingAndPayments() && !this.dgenOnlyCustomer && !this.fourCPOnlyCustomer) {
          return '294px';
        }
        else {
          return '124px';
        }

      }
      return '276px';
    }
    else if (this.dgenOnlyCustomer || this.fourCPOnlyCustomer) {
      return '461px';
    }
    else {
      if (this.selectedAccount.accessType != 'ThirdParty') {
        return '490px';
      }
      else {
        return '412px';
      }
    }
  }
  getBillingOffset() {
    if (this.selectedAccount.accessType == 'ThirdParty') {
      return '151px';
    }
    else {
      return '202px';
    }
  }
  getToolsOffset() {
    if (this.dgenOnlyCustomer || this.fourCPOnlyCustomer) {

      return '230px';

    }
    else if (!this.shadow && !this.showDistributedGeneration && !this.isErcot) {
      return '371px'
    }
    //future start customers, refactor all this
    else if (this.getUsageOffset() == '202px' && this.getLeftOffset() == '294px') {
      return '412px';
    }
    else {
      return '596px';
    }
  }
  getUsageOffset() {
    if (!(this.isPostSaleEnabled && !this.dgenOnlyCustomer && !this.fourCPOnlyCustomer && (this.showBillingAndPayments() || this.showBillingPaymentsAndUsage()))) {
      return '202px';
    }
    else
      return '408px';
  }
  getPaymentOffset() {
    if (this.selectedAccount.accessType == 'ThirdParty') {
      return '230px';
    }
    else {
      return '230px';
    }

  }

  private getSubnavGroups(): any[][] {
    if (!this.subNav) {
      return [];
    }
    const reduced = this.subNav.reduce((map, val) => {
      if (!map.has(val.group)) {
        map.set(val.group, []);
      }
      map.get(val.group).push(val);
      return map;
    }, new Map<number, any[]>());

    return Array.from(reduced.keys()).map(groupNumber => {
      return reduced.get(groupNumber);
    });
  }

  private validateSelectedNavItem() {
    // If there is no selected header, or the new header config is not enabled
    if (!this.header || !this.header.enabled) {
      return;
    }

    // If the current navigation item is accessible via the header config
    if (this.header.navItems.find(item => item.slug === this.selectedNavItem)) {
      return;
    }

    // Navigate to the first available item in the header
    const firstItem = this.header.navItems[0];
    if (!firstItem) {
      console.warn('Nothing to navigate to!');
      return;
    }
    this.router.navigate([firstItem.navOverride || firstItem.slug]);
  }

  private hasSubNav(navItem: any): boolean {
    if (!this.header || !this.header.enabled) {
      return true;
    }
    return this.header.subnavs[navItem] !== undefined;
  }

  private hasFourCPProducts() {
    if (!this.accountWithContracts) {
      return false;
    }
    const fourCPContracts = this.accountWithContracts.contracts.filter(
      (contract: IContract) =>
        contract.products.filter(
          (product: IProduct) => product.name === '4CP Product'
        ).length > 0
    );
    if (fourCPContracts.length > 0) {
      this.showFourCP = true;
    } else {
      this.showFourCP = false;
    }
  }

  private hasReliabilityProducts() {
    // PBI 84567: Restrict shadow meter subpage visibility to account contracts
    // that contain 'Reliability Service' or 'Reliability Fuel Service' products
    if (!this.accountWithContracts) {
      this.showDistributedGeneration = false;
      this.showOnsiteGeneration = false;
    }
    if (this.showDistributedGeneration && this.showOnsiteGeneration) {
      const reliabilityServiceContracts = this.accountWithContracts.contracts
        .filter((contract: IContract) => contract.products
          .filter((product: IProduct) => product.detailName && product.detailName.startsWith('Reliability Service')).length > 0);
      const reliabilityFuelServiceContracts = this.accountWithContracts.contracts
        .filter((contract: IContract) => contract.products
          .filter((product: IProduct) => product.detailName && product.detailName.startsWith('Reliability Fuel Service')).length > 0);
      // Disable either dgen and/or onsite gen options based on products present
      if (reliabilityServiceContracts.length === 0 && reliabilityFuelServiceContracts.length > 0) {
        this.showDistributedGeneration = false;
      } else if (reliabilityServiceContracts.length === 0 && reliabilityFuelServiceContracts.length === 0) {
        this.showDistributedGeneration = false;
        this.showOnsiteGeneration = false;
      }
    }
  }

  private isFourCPandDgenOnlyCustomer() {
    if (!this.accountWithContracts) {
      return false;
    }
    const nonCommodity4CPContracts = this.accountWithContracts.contracts.filter(
      (contract: IContract) =>
        !contract.commodity &&
        contract.products.filter(
          (product: IProduct) => product.name === '4CP Product'
        ).length > 0
    );
    const nonCommodityReliabilityContracts = this.accountWithContracts.contracts.filter(
      (contract: IContract) =>
        !contract.commodity &&
        contract.products.filter(
          (product: IProduct) => product.name === 'Reliability Product'
        ).length > 0
    );
    return (
      nonCommodity4CPContracts.length > 0 &&
      nonCommodityReliabilityContracts.length > 0 &&
      nonCommodity4CPContracts.length +
      nonCommodityReliabilityContracts.length ===
      this.accountWithContracts.contracts.length
    );
  }

  private isFourCPOnlyCustomer() {
    if (!this.accountWithContracts) {
      return false;
    }
    const nonCommodity4CPContracts = this.accountWithContracts.contracts.filter(
      (contract: IContract) =>
        !contract.commodity &&
        contract.products.filter(
          (product: IProduct) => product.name === '4CP Product'
        ).length > 0
    );
    return (
      nonCommodity4CPContracts.length > 0 &&
      nonCommodity4CPContracts.length ===
      this.accountWithContracts.contracts.length
    );
  }

  private isdgenOnlyCustomer() {
    if (!this.accountWithContracts) {
      return false;
    }
    const nonCommodityReliabilityContracts = this.accountWithContracts.contracts.filter(
      (contract: IContract) =>
        !contract.commodity && contract.products.filter(
          (product: IProduct) => product.name === 'Reliability Product').length > 0
    );
    return (nonCommodityReliabilityContracts.length > 0
      && nonCommodityReliabilityContracts.length === this.accountWithContracts.contracts.length
    );
  }

  contractFilter = (contract: IContract) => {
    return contract.commodity == true
      && (contract.contractType !== 'Transition' || contract.status !== 'Terminated')
      && moment(contract.deliveryEndDate).isSameOrAfter(moment().subtract(6, 'months'))
      && contract.products.length > 0
      && contract.market == 'ERCOT';
  }

  mapRecentProducts = (contract: IContract) => {
    const newContract = { ...contract } as IContract;
    newContract.products = contract.products.filter(
      product => moment(product.deliveryEndDate).isAfter(moment().subtract(6, 'months'))
    );

    newContract.products = contract.products.filter(
      product => product.name === SelectProductContext.PCHBE
    );

    return newContract;
  }

  accountHasContractsForPCHBE() {
    const contracts = this.dataService.getContractsForAccount() || [];
    return contracts.find(
      (contract) =>
        contract.status.toLowerCase() == 'active' &&
        contract.market.toLowerCase() == 'ercot'
    );
  }

  closeInteriorNav() {
    if (this.currentInteriorNav && this.visitedInteriorNav) {
      this.currentInteriorNav = '';
      this.visitedInteriorNav = false;
    }
  }

  private isIEBrowser() {
    console.log('What Browser is this? ');
    console.log(navigator.userAgent);
    if (navigator.userAgent.toLowerCase().indexOf('msie') > -1 || navigator.userAgent.toLowerCase().indexOf('trident') > -1) {
      this.isIE = true;
    } else {
      this.isIE = false;
    }
    console.log('isIE:', this.isIE);
  }

}


