export const Authority = {
  SuperUser: 'SUPER_USER',
  User: 'ROLE_USER',
  SuperUserAdmin: 'SUPER_USER_ADMIN',
  PricingUser: 'BROKER_PRICING_USER',
  PricingAll: 'BROKER_PRICING_ALL',
  PostSale: 'POST_SALE',
  VhospAdmin: 'VHOSP_ADMIN',
  VhospTrader: 'VHOSP_TRADER',

  oamAccessList: ['Admin','Post Sale','Pricing','No Access'],

  oamAccessToPermMap:[['Admin',[63, 512, 1024, 2048]],
                    // ['Pricing',[128, 158, 256]],
                    ['Pricing',[128, 256]],
                    ['Post Sale',[62, 190, 318, 512, 1024, 2048]],
                    ['No Access',[0]]],

  oamPermToAccessMap: new Map([[63, 'Admin'],
                    [0, 'No Access'],
                    [128, 'Pricing'],
           //         [158, 'Pricing'],
                    [256, 'Pricing'],
                    [62, 'Post Sale'],
                    [190, 'Post Sale'],
                    [318, 'Post Sale'],
                    [512, 'Commissions'],
                    [1024, 'Broker Confirm'],
                    [2048, 'Broker Drop'],
                  ]),


  accessMap: new Map([[63, 'Admin'],
                      [0, 'No Access'],
                      [128, 'Pricing (User Quotes)'],
                   //   [158, 'Pricing (No Quotes)'],
                      [256, 'Pricing (All Quotes)'],
                      [62, 'Post Sale'],
                      [190, 'Post Sale and Pricing (User Quotes)'],
                      [318, 'Post Sale and Pricing (All Quotes)'],
                      [512, 'Commissions'],
                      [1024, 'Broker Confirm'],
                      [2048, 'Broker Drop']]),

  activityMap: new Map([['Broker Confirm', 1024],
                        ['Broker Drop', 2048],
                        ['COMMISSIONS', 512],
                        ['POST_SALE_AND_PRICING_ALL_QUOTES', 318],
                        ['POST_SALE_AND_PRICING_USER_QUOTES', 190],
                        ['PRICING_ALL_QUOTES', 256],
                        ['PRICING_USER_QUOTES', 128],
                        ['POST_SALE', 62],
                        ['FULL_ACCESS', 62],
                        ['VIEW_ONLY', 32],
                        ['NO_ACCESS', 0],
                        ['ADMIN', 63]]),

 
  accessToActivityMap: new Map([['Pricing (User Quotes)', 'PRICING_USER_QUOTES'],
                      ['Pricing (All Quotes)', 'PRICING_ALL_QUOTES'],
                      ['Broker Pricing User', 'PRICING_USER_QUOTES'],
                      ['Broker Pricing All', 'PRICING_ALL_QUOTES'],
                      ['No Access', 'NO_ACCESS'],
                      ['Admin', 'ADMIN'],
                      ['Post Sale', 'POST_SALE'],
                      ['Full Access', 'FULL_ACCESS'],
                      ['View Only', 'VIEW_ONLY'],
                      ['Post Sale and Pricing (User Quotes)', 'POST_SALE_AND_PRICING_USER_QUOTES'],
                      ['Post Sale and Pricing (All Quotes)', 'POST_SALE_AND_PRICING_ALL_QUOTES'],
                      ['Commissions','COMMISSIONS'],
                      ['Broker Confirm','BROKER_CONFIRM'],
                      ['Broker Drop','BROKER_DROP']]),

  activityToAccessMap: new Map([['PRICING_USER_QUOTES', 'Pricing (User Quotes)'],
                      ['PRICING_ALL_QUOTES', 'Pricing (All Quotes)'],
                      ['ADMIN', 'Admin'],
                      ['NO_ACCESS', 'No Access'],
                      ['POST_SALE', 'Post Sale'],
                      ['POST_SALE_AND_PRICING_USER_QUOTES', 'Post Sale and Pricing (User Quotes)'],
                      ['POST_SALE_AND_PRICING_ALL_QUOTES', 'Post Sale and Pricing (All Quotes)'],
                      ['COMMISSIONS','Commissions'],
                      ['BROKER_CONFIRM','Broker Confirm'],
                      ['BROKER_DROP','Broker Drop']])

};
