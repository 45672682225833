import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { IMaintenanceNote } from '../../maintenance-note';
import { IRenewableSite } from '../../renewable-site';
import { IGenerationPoint, IRenewableSiteGeneration, ISiteGenerationRequest } from '../../renewable-site-generation';
import { RenewablesComponent } from '../../renewables.component';
import { RenewablesService } from '../../renewables.service';
import { IGenerationHistoryMonth } from '../generation-history-month';
import { DataService } from '../../../shared/data.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { IEquivalency } from '../../equvalency';
import { DecimalPipe } from '@angular/common';
import { GenericPipe } from '../../../shared/pipe/generic.pipe';
import { FadeAnimation } from '../../../animations/fadeAnimation';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { apiDateFormat } from '../../renewables-utility';
import { PortalService } from '../../../shared/portal.service';
import { Authority } from '../../../shared/const/authority';
import {IUserAccount} from "../../../shared/entities/profile";


@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss'],
  providers: [GenericPipe],
  animations: [FadeAnimation]
})
export class HistoryTableComponent implements OnInit, OnDestroy {
  myUserAccount: IUserAccount;
  sites: IRenewableSite[];
  private allHistoryMonths: IGenerationHistoryMonth[];
  filteredHistoryMonths: IGenerationHistoryMonth[];
  equivalencies = new Array<IEquivalency>();
  selectedEquivalencyType = 'vehicles';
  showSearchRow = false;
  searchTerm = '';
  showYearSelector: boolean;
  selectedYear: number;
  currentYear: string;
  yearOptions: number[] = [];
  components: any;
  showComponentSelector: boolean;
  showInternal: Boolean;
  private readonly flatMap = (f, xs) => xs.reduce((acc, x) => acc.concat(f(x)), []);
  private readonly dateFormat = 'YYYY/MM/DD';
  private readonly unsubscribe$ = new Subject();
  maskMode = false;
  maskModeSubscription: Subscription;

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(
    private renewablesService: RenewablesService,
    private decimalPipe: DecimalPipe,
    private genericPipe: GenericPipe,
    private dataService: DataService,
    private portalService: PortalService,
    @Inject(RenewablesComponent) private parent: RenewablesComponent
  ) { }

  ngAfterViewInit(){
    this.spinner.show('Loading Site Generation...');
  }
  ngOnInit() {
    if(this.parent.isSuperAdmin){
      this.showInternal = true;
    } else {
      this.showInternal = false;
    }
    this.maskMode = this.dataService.getMaskMode();
    this.myUserAccount = this.dataService.getAccountSource();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.showInternal = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    //this.selectedYear = 2021;
    this.selectedYear = moment().year(); 
    this.renewablesService.sites
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.sites = sites;
        this.fetchSiteGeneration();
      });

    this.renewablesService.equivalencies
      .takeUntil(this.unsubscribe$)
      .subscribe(equivalencies => {
        this.equivalencies = equivalencies;
      });


      this.components = this.equivalencies.filter(equal => equal.type != 'tons');





      this.renewablesService.getSiteYears().subscribe(
        (response) => {
          this.yearOptions = response.map(Number);
        }
       );



  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterTable();
  }

  filterTable() {
    if (this.searchTerm) {
      this.filteredHistoryMonths = this.genericPipe.transform(this.allHistoryMonths, this.searchTerm);
    } else {
      this.filteredHistoryMonths = this.allHistoryMonths;
    }

    if (this.filteredHistoryMonths.length) {
      this.filteredHistoryMonths = [this.historyTotal].concat(this.filteredHistoryMonths);
    }
  }

  openMaintenanceModal(historyMonth: IGenerationHistoryMonth) {
    this.parent.maintenanceModal.openHistoryMonth(historyMonth, this.showInternal.valueOf(), this.maskMode);
  }

  get selectedEquivalency(): IEquivalency {
    const equivalency = this.equivalencies.find(e => e.type === this.selectedEquivalencyType);
    if (!equivalency) {
      return null;
    }
    return equivalency;
  }

  formatEquivalency(type: string, value: number, unitDetail = true): string {
    const equivalency = this.equivalencies.find(e => e.type === type);
    if (!equivalency) {
      console.warn('equivalency not found for type', type);
      return '';
    }

    const calculatedValue = value / equivalency.divisor;
    const outputValue = equivalency.showDecimal
      ? this.decimalPipe.transform(calculatedValue, '1.2-2')
      : this.decimalPipe.transform(calculatedValue, '1.0-0');
    return outputValue + ' ' + equivalency.unit + (unitDetail ? ' ' + equivalency.unitDetail : '');
  }

  private fetchSiteGeneration() {
    this.showSearchRow = false;
    this.searchTerm = '';
    this.spinner.show('Loading Site Generation...');
    const yearDate = moment().set('year', this.selectedYear);

    const siteRequest = {
      accountId: this.myUserAccount.id,
      siteIds: this.sites.filter(site => site.selected).map(site => site.id),
      startDate: yearDate.startOf('year').format(apiDateFormat),
      endDate: yearDate.endOf('year').format(apiDateFormat),
      interval: 15
    } as ISiteGenerationRequest;

    this.renewablesService.getSiteGeneration(siteRequest)
      .takeUntil(this.unsubscribe$)
      .subscribe(siteGeneration => {
        this.allHistoryMonths = this.mapGenerationToMonths(siteGeneration, yearDate);
        this.filterTable();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error('Failed to get site generation', error);
      });
  }

  private mapGenerationToMonths(siteGeneration: IRenewableSiteGeneration, yearDate: Moment): IGenerationHistoryMonth[] {
    const pointsByMonth = new Map<string, IGenerationPoint[]>();
    let monthDate = yearDate.startOf('year');

    // Set up all months for the year
    for (let month = 0; month < 12; month++) {
      pointsByMonth.set(monthDate.startOf('month').format(this.dateFormat), []);
      monthDate = monthDate.clone().add(1, 'month');
    }

    // Aggregate all of the points by month
    siteGeneration.points.forEach((point: IGenerationPoint) => {
      const monthStart = moment(point.date, apiDateFormat).startOf('month').format(this.dateFormat);
      if (pointsByMonth.has(monthStart)) {
        pointsByMonth.get(monthStart).push(point);
      }
     });
     let dataAvailable = false;
    // Sum all of the month point values
    const historyMonths = new Array<IGenerationHistoryMonth>();
    pointsByMonth.forEach((points, date) => {
      const historyMonth = {
        date: moment(date, this.dateFormat).toDate(),
        maintenanceNotes: this.getMaintenanceNotesByMonth(moment(date, this.dateFormat)),
        expanded: false,
        hover: false
      } as IGenerationHistoryMonth;
      if (points != null && points.length) {
        dataAvailable = true;
        const actualGeneration = points.reduce((sum, val) => sum + val.generation, 0);
        const shadowGeneration = points.reduce((sum, val) => sum + val.shadow, 0);
        historyMonth.amount = actualGeneration + shadowGeneration;
        historyMonth.amount = historyMonth.amount/4;
        historyMonth.actualAmount = actualGeneration/4;
        historyMonth.shadowMeterAmount = shadowGeneration/4;
      }

            // Only add history month if data has already been made available
            if (dataAvailable) {
              historyMonths.push(historyMonth);
            }
      // historyMonths.push(historyMonth);
    });
    return historyMonths;
  }


  toggleYearSelector() {
    this.showYearSelector = !this.showYearSelector;
  }

  toggleComponentSelector() {
    this.showComponentSelector = !this.showComponentSelector;
  }

  selectYear(year: number) {
    this.selectedYear = year;
    this.fetchSiteGeneration();


    this.toggleYearSelector();

  }

  selectComponent(equivalency: IEquivalency) {
    this.selectedEquivalencyType = equivalency.type;
    this.toggleComponentSelector();
  }

  private getMaintenanceNotesByMonth(monthDate: Moment): IMaintenanceNote[] {
    const monthStart = monthDate.startOf('month').format(this.dateFormat);
    const monthEnd = monthDate.endOf('month').format(this.dateFormat);
    if (!this.sites.length) {
      return [];
    }
    return (this.flatMap(site => site.maintenanceNotes, this.sites.filter(site => site.selected)) as IMaintenanceNote[])
      .filter(note => note ? moment(note.date).isBetween(monthStart, monthEnd) : null) ;



  }

  private get historyTotal(): IGenerationHistoryMonth {
    if (!this.filteredHistoryMonths.length) {
      return null;
    }

    return {
      amount: this.filteredHistoryMonths.reduce((acc, historyMonth) => acc + (historyMonth.amount || 0), 0),
      // amount: this.filteredHistoryMonths.map(historyMonth => historyMonth.amount).reduce((acc, val) => acc + val, 0),
      maintenanceNotes: this.flatMap(historyMonth => historyMonth.maintenanceNotes, this.filteredHistoryMonths) as IMaintenanceNote[]
    } as IGenerationHistoryMonth;
  }
}
