import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from "../../animations/fadeAnimation";
import { DataService } from "../../shared/data.service";

@Component({
  selector: 'app-fourcp-overview',
  templateUrl: './fourcp-overview.component.html',
  styleUrls: ['./fourcp-overview.component.scss'],
  animations: [FadeAnimation]
})
export class FourcpOverviewComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit() {
  }

}
