import { Component, OnInit } from '@angular/core';
import {IntervalUsageReportService} from "../interval-usage-report.service";
import {IIntervalSite, ISite} from "../../shared/entities/site";
import {Router} from "@angular/router";
import {PortalService} from "../../shared/portal.service";
import {Subscription} from "rxjs";
import { DataService } from '../../shared/data.service';


@Component({
  selector: 'app-export-confirmation',
  templateUrl: './export-confirmation.component.html',
  styleUrls: ['export-confirmation.component.scss']
})

export class ExportConfirmationComponent implements OnInit {

  selectedSites: IIntervalSite[];
  maskMode: boolean;
  maskModeSubscription: Subscription;

  constructor(private service: IntervalUsageReportService,
              private dataService: DataService,
              private router: Router,
              private portalService: PortalService) { }

  ngOnInit() {
    this.service.setStepReached(3);
    this.selectedSites = this.service.selectedSites;
    if(this.selectedSites.length > 0){

    } else {
      this.router.navigate(['/interval-usage-report/select-sites']);
    }

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
  }

  goToSelectSites() {
    this.service.setSelectedSites([]);
    this.service.setStartDate('');
    this.service.setEndDate('');
    this.service.setDataInterval('');
    this.router.navigate(['/interval-usage-report/select-sites']);
  }

  getDateDisplay(dateString: string) {
    return this.portalService.getDateDisplay(dateString);
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

}
