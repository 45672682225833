export const TpBillingSummaryColumns = [
  {
    name: 'General Information',
    columns: [
      {display: 'Total Current Charges', value: 'totalCharge'}
    ]
  },
  {
    name: 'Invoice Charge Details',
    columns: [
      {display: 'Usage (kWh)', value: 'totalEnergyQuantity'},
      {display: 'Energy Charge', value: 'energyCharge'},
      {display: 'Transmission Charges', value: 'transmissionCharge'},
      {display: 'Current Pass Through', value: 'currentPeriodPassThrough'},
      {display: 'Prior Pass Through', value: 'priorPeriodPassThrough'},
      {display: 'Aggregation Fee', value: 'aggregationFee'},
      {display: 'Nodal Charge', value: 'nodalAncillaryCharge'},
      // {display: 'NIT Charge', value: 'nitCharge'},
      // {display: 'CAPO Charge', value: 'capoCharge'},
      // {display: 'TEC', value: 'tec'},
      {display: 'Other Charges', value: 'otherCharge'},
      {display: 'Taxes & Assessments', value: 'taxAmount'},
      {display: 'Total Current Charges', value: 'totalCurrentCharges'},
      
    ]
  }
];
