import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {AmendmentsService} from "../amendments.service";
import {IAmendmentRequest} from "../../shared/entities/amendments";
import * as moment from 'moment';
import {FadeAnimation} from "../../animations/fadeAnimation";


@Component({
  selector: 'app-amendments-terms',
  templateUrl: './amendments-terms.component.html',
  animations: [FadeAnimation],
  styleUrls: ['./amendments-terms.component.scss', '../amendments.component.scss']
})
export class AmendmentsTermsComponent implements OnInit {

  accepted: boolean = false;
  selectedAmendmentRequest: IAmendmentRequest;

  accountSubscription: Subscription;

  constructor( private router: Router,
              private dataService: DataService,
              private amendmentsService: AmendmentsService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Submit Request ');
    this.dataService.setSelectedNavItem('accounts');

    this.selectedAmendmentRequest = this.dataService.getSelectedAmendmentRequest();
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goHome();
    });
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
  }

  continue(){
    // record (hit API) that user accepted Ts & Cs
     if(this.accepted){
       if(this.selectedAmendmentRequest){
         this.selectedAmendmentRequest.conditionsAcknowledgedDate = moment().toDate();
       } else {
         this.selectedAmendmentRequest = {} as any; // Local:4200
       }

       this.amendmentsService.submitAmendmentstc(this.selectedAmendmentRequest).subscribe();
       this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
       this.router.navigate(['/amendments/start']);
    }
  }




  goHome() {
    this.router.navigate(['/amendments/home']);
  }
}
