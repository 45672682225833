import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from "../shared/data.service";
import { SitesControl } from "../shared/entities/controls";
import { FadeAnimation } from "../animations/fadeAnimation";
import { FadeInAnimation } from "../animations/fadeInAnimation";
import { Subscription } from "rxjs";
import { ISiteGroup, ISite } from "../shared/entities/site";
import { IUserAccount } from "../shared/entities/profile";
import { SiteGroupService } from "../site-groups/site-groups.service";
import { IUsageGraphRequest, IUsageGraph, IUsageGraphPoint, ISiteCounts } from "../shared/entities/usageGraph";
import * as moment from "moment";
import { BillingUsageService } from "./billing-usage.service";
import { PortalService } from "../shared/portal.service";
import { FakerService } from "../shared/faker.service";
import { IHelpStep } from "../shared/entities/contextualHelp";
declare var Highcharts: any;
import { Router } from "@angular/router";
import { IAccount } from "../shared/entities/account";
import { IUsage } from '../shared/entities/usage-reports';
import { Authority } from '../shared/const/authority';
import { Observable } from 'rxjs-compat';
import { concatAll } from 'rxjs-compat/operator/concatAll';

@Component({
  selector: 'app-billing-usage',
  templateUrl: './billing-usage.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['./billing-usage.component.scss']
})
export class BillingUsageComponent implements OnInit, OnDestroy {
  myAccount: IUserAccount;
  isSuperUser: boolean = false;
  isSuperAdmin: boolean = false;
  accountWithContractData: IAccount;
  component = this;
  request: IUsageGraphRequest = {};
  siteCounts: ISiteCounts;
  totalUsage: number;
  totalHedge: number;
  totalExposure: number;
  percentExposure: number;
  peakHourlyDemand: number;
  peakHourlyDemands: number;
  averageHourlyUsage: number;
  peakDemandHr: string;
  allSites: ISite[];
  filteredSites: ISite[];
  userSiteGroups: ISiteGroup[] = [];
  systemSiteGroups: ISiteGroup[] = [];
  showSites = false;
  showMeterType = false;
  showDateRange = false;
  showDateRangeError = false;
  noDataError: boolean = true;
  daylightSavings: boolean = false;
  maskMode: boolean;
  amsCount: number = 0;
  idrCount: number = 0;
  nidrCount: number = 0;
  siteIndex: number = -1;
  enableSave: boolean = false;
  sitesControl: SitesControl;
  accountSourceSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  getSiteGroupsSubscription: Subscription;
  siteCountsSubscription: Subscription;
  realTimeReportSubscription: Subscription;
  exportTriggeredSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;
  minDate: Date;
  maxDate: Date;
  minDateShow: Date;
  maxDateShow: Date;
  selectedStartDate: Date;
  selectedEndDate: Date;
  dateRangeDisplay: string;
  helpStep: IHelpStep;
  capeProductFlg: string;
  errorMessage: string;

  contractsGroup = {
    groupName: 'CONTRACTS',
    display: 'Contracts'
  };

  exportSubscription: Subscription;
  usageGraphSubscription: Subscription;

  constructor(private service: BillingUsageService,
    private dataService: DataService,
    private portalService: PortalService,
    private siteGroupsService: SiteGroupService,
    private fakerService: FakerService,
    private router: Router) { }

  ngOnInit() {
    this.dataService.setTitleSource('Billing Usage Report');
    this.dataService.setSelectedNavItem('billing');
    this.setCurrentActivePage('billing')
    this.sitesControl = new SitesControl();
    this.myAccount = this.dataService.getAccountSource();
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    this.dataService.setBillingUsageReportDate(new Date());

    //Only Super Users/Admin has permission to view this page
    // if (this.isSuperUser) {
    if (this.dataService.getAccountWithContractDataSource()) {
      this.capeProductFlg = this.dataService.getAccountWithContractDataSource().capeProductFlg
    }
    console.log('capeProductflg', this.capeProductFlg);
    //  this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {this.exportToExcel()});
    if (this.myAccount) {
      this.loadDefaultGraph()
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      // this.dataService.setLoading(true);
      this.myAccount = account;
      this.loadDefaultGraph();
    });
    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.myAccount = account;
    });

    this.maskMode = this.dataService.getMaskMode();
    let siteGroups = this.dataService.getSiteGroupsSource();
    if (siteGroups) {
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.loadSiteOptions();
    }
    this.getSiteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe((siteGroups) => {
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.loadSiteOptions();
    });

    this.siteCounts = this.dataService.getSiteCountsSource();
    this.siteCountsSubscription = this.dataService.siteCountSourceUpdated.subscribe((siteCounts) => {
      this.siteCounts = siteCounts;
    });

    this.realTimeReportSubscription = this.dataService.realTimeDataReportTriggered.subscribe(() => { this.openRealTimeDataReport() })
    this.exportTriggeredSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.dataService.setLoading(true);
      this.exportGraph()
    });

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    })
    // }
  }

  ngOnDestroy() {
    // if (this.isSuperUser) {
    if (this.exportSubscription) {
      this.exportSubscription.unsubscribe();
    }
    if (this.siteCountsSubscription) this.siteCountsSubscription.unsubscribe();
    if (this.realTimeReportSubscription) this.realTimeReportSubscription.unsubscribe();
    if (this.getSiteGroupsSubscription) this.getSiteGroupsSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.exportTriggeredSubscription) this.exportTriggeredSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.usageGraphSubscription) this.usageGraphSubscription.unsubscribe();
    // }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getCategoryDisplay() {
    if (this.sitesControl.selectedCategory) {
      if (this.sitesControl.selectedGrouping.groupName == 'COLLECTIVE_BILLING' && this.maskMode) {
        return '80012345';
      }
      if (this.sitesControl.selectedGrouping.display == 'Contracts') {
        return this.getContractNickname(this.sitesControl.selectedCategory);
      } else {
        return this.sitesControl.selectedCategory;
      }
    } else {
      return 'Select a category';
    }
  }


  getContractNickname(contractNum: string) {
    return this.portalService.getContractNickname(contractNum);
  }

  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  loadDefaultGraph() {
    this.request.accountId = this.myAccount.id;
    this.request.accountName = this.myAccount.name;
    this.request = {
      accountId: this.myAccount.id,
      accountName: this.myAccount.name,
      siteRequest: 'ALL_SITES',
      startDate: moment(moment().subtract(2, 'months').format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(moment().subtract(2, 'months').format('YYYY-MM-DD')).endOf('month').format('YYYY-MM-DD'),
      frequencyType: 'ALL',
      capeProductFlg: this.capeProductFlg
    };
    this.sitesControl.siteRequest = "ALL_SITES";
    this.minDate = moment().subtract(2, 'years').toDate();
    this.minDateShow = moment(this.minDate).add(1, 'days').toDate();
    this.maxDateShow = moment().toDate();
    this.maxDate = moment().subtract(2, 'months').endOf('month').toDate();
    this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + '-' + moment(this.request.endDate).format('MM/DD/YY');
    this.applyChanges();
  }

  exportGraph() {
    this.request.maskingMode = this.dataService.getMaskMode();
    this.request.brand = window.localStorage.getItem("brand");
    this.service.exportUsageGraph(this.request);
  }

  toggleSites() {
    this.showSites = !this.showSites;
    this.showMeterType = this.showDateRange = false;
    if (this.showSites) {
      this.sitesControl.siteRequest = this.request.siteRequest;
      if (this.request.siteRequest == 'GROUP_CATEGORY') {
        this.systemSiteGroups.forEach((systemGroup) => {
          if (systemGroup.groupName == this.request.systemGroupName) {
            this.selectGroup(systemGroup);
          }
        });
        this.userSiteGroups.forEach((userGroup) => {
          if (userGroup.id == this.request.groupId) {
            this.selectGroup(userGroup);
          }
        });
        this.sitesControl.selectedCategory = this.request.groupCategory;
      } else if (this.request.siteRequest == 'SPECIFIC_SITE') {
        this.filteredSites.forEach((site, index) => {
          if (site.siteId == this.request.siteId) {
            this.selectSite(site, index);
          }
        });
      }
    } else {
      if (this.sitesControl.selectedSite) {
        if (!this.maskMode) {
          this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
        } else {
          this.sitesControl.siteSearch = this.getFakeSiteId(this.sitesControl.selectedSite) + ' ' + this.fakerService.getCompanyName();
        }
      } else {
        this.sitesControl.siteSearch = '';
      }
    }
  }
  toggleMeterType() {
    this.showMeterType = !this.showMeterType;
    this.showDateRange = this.showSites = false;
  }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
    this.showDateRangeError = this.showMeterType = this.showSites = false;
    this.selectedStartDate = moment(this.request.startDate).toDate();
    this.selectedEndDate = moment(this.request.endDate).toDate();
  }

  loadSiteOptions() {
    /*this.siteGroupsService.getSitesForSystemGroup(this.myAccount.id, 'ALL_SITES').subscribe(
      (sites) => {
        this.allSites = sites;
        sites.forEach((site) => {
          //console.log(site.market, "/",site.salesgroup);
          if (site.market == 'ERCOT' && site.salesgroup == 'C&I') {
            //console.log("Added**************************");
            this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
          }
        });
      }
    );*/
    this.allSites = this.dataService.getSitesForAccountSource();
    this.allSites .forEach((site) => {
      //console.log(site.market, "/",site.salesgroup);
      if (site.market == 'ERCOT' && site.salesgroup == 'C&I') {
        //console.log("Added**************************");
        this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
      }
    });
  }

  selectGroup(group: ISiteGroup) {
    this.sitesControl.selectedGrouping = group;
    if (this.sitesControl.selectedGrouping.id) {
      if (this.sitesControl.selectedGrouping.groupName.length > 15) {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName.substring(0, 15) + '...'
      } else {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName;
      }
      this.siteGroupsService.getCategoriesForUserGroup(this.sitesControl.selectedGrouping.id).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      )
    } else {
      this.siteGroupsService.getCategoriesForSystemGroup(this.sitesControl.selectedGrouping.groupName).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      )
    }
    this.sitesControl.selectedCategory = null;
  }

  selectCategory(category: string) {
    this.sitesControl.selectedCategory = category;
  }

  selectMeterType(meterType: string) {
    this.request.frequencyType = meterType;
    this.toggleMeterType();
    this.applyChanges();
  }

  searchSites() {
    this.filteredSites = [];
    this.allSites.forEach((site) => {
      if (site.siteId.match(this.sitesControl.siteSearch) ||
        (site.serviceAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
        this.sitesControl.siteSearch == '') {
        this.filteredSites.push(site);
      }
    });
  }

  // searchSites() {
  //   this.filteredSites = [];
  //   this.allSites.forEach((site) => {
  //     //console.log(site.market, "/",site.salesgroup);
  //     // if ( (site.market == 'ERCOT' && site.salesgroup == 'C&I') && (site.siteId.match(this.sitesControl.siteSearch) ||
  //     if((site.siteId.match(this.sitesControl.siteSearch) ||
  //     (site.serviceAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
  //       this.sitesControl.siteSearch == '')) {
  //         //console.log("Added zFiltered**************************");
  //         this.filteredSites.push(site);
  //     }
  //   });
  // }

  navigateTypeahead(down: boolean) {
    if (down && this.siteIndex < this.filteredSites.length - 1) {
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  selectStartDate() {
    this.selectedEndDate = moment(this.selectedStartDate).add(30, 'days').toDate();
  }

  selectSite(site: ISite, index?: number) {
    if (this.filteredSites.length > 0) {
      if (this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if (index) {
        this.sitesControl.selectedSite = this.filteredSites[index];
      } else {
        this.sitesControl.selectedSite = this.filteredSites[this.siteIndex];
      }
      if (!this.maskMode) {
        this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
      } else {
        this.sitesControl.siteSearch = this.getFakeSiteId(site) + ' ' + this.fakerService.getCompanyName();
      }
      this.resetTypeahead();
    }
  }

  resetSiteSelection() {
    this.sitesControl.siteSearch = null;
  }

  applySites() {
    // Apply changes in customerControl to selectedReport
    this.toggleSites();
    this.enableSave = true;
    this.request.siteRequest = this.sitesControl.siteRequest;
    if (this.request.siteRequest == 'ALL_SITES') {
      this.request.groupCategory = null;
      this.request.systemGroupName = null;
      this.request.groupId = null;
      this.request.siteId = null;
    } else if (this.request.siteRequest == 'GROUP_CATEGORY') {
      this.request.groupCategory = this.sitesControl.selectedCategory;
      if (this.sitesControl.selectedGrouping.id) {
        this.request.groupId = this.sitesControl.selectedGrouping.id;
        this.request.systemGroupName = null;
      } else {
        this.request.systemGroupName = this.sitesControl.selectedGrouping.groupName;
        this.request.groupId = null;
      }
      this.request.siteId = null;
    } else if (this.request.siteRequest == 'SPECIFIC_SITE') {
      this.request.siteId = this.sitesControl.selectedSite.siteId;
      this.request.groupCategory = null;
      this.request.systemGroupName = null;
      this.request.groupId = null;
    }
    this.applyChanges();
    this.resetSiteSelection();
  }

  applyChanges() {
    // Get graph data
    if (!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.usageGraphSubscription = this.service.getUsageGraph(this.request).subscribe(
      (graphResponse) => {
        this.noDataError = graphResponse.points.length == 0;
        const graphData = graphResponse as IUsageGraph;

        Observable.from<IUsageGraphPoint>(graphData.points)
          .groupBy(point => point.date, point => point)
          .mergeMap(group => group.toArray())
          .filter(points => points.length != 96 && points.length != 24)
          .map(points => {
            this.daylightSavings = true;
            return points
          })
          .filter(points => points.length == 100 || points.length == 25) // [[{}, {}, ...], [...]] => length 100 || 25
          .flatMap(point => point) // [{}, {}, {}, ...]
          .filter(point => {
            if (this.capeProductFlg == 'N') {
              return point.intervalId >= 9;
            } else {
              return point.intervalId >= 3;
            }
          })
          .subscribe(point => {
            if (this.capeProductFlg == 'N') {
              point.isLongDay = point.intervalId <= 12;
              point.intervalId -= 4;
            } else {
              point.isLongDay = point.intervalId == 3;
              point.intervalId -= 1;
            }
          });

        graphData.points.forEach(point => {
          point.dateDisplay = this.getMomentFromPoint(point).format('MM/DD/YYYY HH:mm');
        })

        this.getSiteControlDisplay();
        if (!this.noDataError) {
          this.populateSummaryData(graphData.points);
          setTimeout(() => {
            // Ensure div is rendered for HighCharts
            this.createGraph(this.capeProductFlg, graphData);
          }, 50);
        }
        this.dataService.setLoading(false);
      }, err => {
        if (err.status == 404) {
          this.noDataError = true;
        }
        this.dataService.setLoading(false);
      }
    );
  }

  applyDateRange() {
    this.showDateRangeError = false;
    this.minDate = moment().subtract(2, 'years').toDate();
    this.maxDate = moment().subtract(2, 'months').endOf('month').toDate();

    if (moment(this.selectedEndDate).isBefore(this.selectedStartDate)) {
      this.errorMessage = 'End Date is Greater than the Start Date';
      this.showDateRangeError = true;
    }

    if (moment(this.selectedStartDate).isBefore(this.minDate)) {
      this.errorMessage = ' Date selected is prior to the minimum start date allowed. Please enter a date greater than ' + moment(this.minDate).format('LL')
      this.showDateRangeError = true;
    }

    // if (moment(this.selectedEndDate).isAfter(this.maxDate)) {
    //   this.errorMessage = '  Date selected exceeds the maximum start date allowed. Please enter an end date prior to ' + moment(this.maxDate).format('LL')
    //   this.showDateRangeError = true;
    // }

    // if ((Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days')) > 45)) {
    //   this.errorMessage = 'Range between start and end date exceeds maximum range of 45 days'
    //   this.showDateRangeError = true;
    // }
    if (this.showDateRangeError) {
    }
    else {
      if (!(Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days')) > 730)) {
        this.request.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
        this.request.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
        this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + ' - ' + moment(this.request.endDate).format('MM/DD/YY');
        this.toggleDateRange();
        this.applyChanges();
        this.showDateRangeError = false;
      } else {
        this.showDateRangeError = true;
      }
    }
  }
  openRealTimeDataReport() {
    this.service.getRealTimeDataReportUrl(this.myAccount.id).subscribe(
      (url) => {
        window.open(url, '_blank');
      }
    );
  }

  getSiteControlDisplay() {
    if (this.sitesControl.siteRequest == 'ALL_SITES') {
      this.sitesControl.display = 'All Sites';
    } else if (this.sitesControl.siteRequest == 'GROUP_CATEGORY') {
      this.sitesControl.display = this.sitesControl.selectedCategory;
    } else if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      this.sitesControl.display = '...' + this.sitesControl.selectedSite.siteId.substr(5);
    }
  }

  populateSummaryData(points: IUsageGraphPoint[]) {
    this.totalUsage = 0;
    this.peakHourlyDemand = 0;
    this.peakHourlyDemands = 0;
    this.totalHedge = 0;
    this.totalExposure = 0;
    this.totalUsage = 0;
    this.percentExposure = 0;
    let peakDemandPoint = { demand: 0 } as IUsageGraphPoint;
    let hourlyUsage = 0;
    let hourlyUsages = [];
    let hourlyDemand = 0;
    let hourlyDemands = 0;
    let count = 0;
    let totalHourlyUsages = 0;
    let lastHour = moment(points[0].date).hour(points[0].intervalId);
    const totalTestUsage = points.reduce((acc, point) => acc + point.usage, 0);
    // console.log('points',points);
    points.forEach((point, i) => {
      // Calculate Total Usage
      this.totalUsage += point.usage;
      // Calculate Total Hedge
      this.totalHedge += point.blockedUsage;
      // Calcuations for peakHourlyDemand and averageHourlyUsage
      hourlyUsage += point.usage;
      hourlyDemand += point.demand;
      // hourlyDemands = point.usage;


      // console.log('hourlyDeamnds count' + count +'   ' + hourlyDemands);
      // console.log('outside PeakhourlyDeamnds',this.peakHourlyDemands);

      //console.log('cape value',this.capeProductFlg);
      if (this.capeProductFlg == 'N') {
        //console.log('not a cape product',this.capeProductFlg);

        if (count == 4) {
          if (hourlyDemands > this.peakHourlyDemand) {
            this.peakHourlyDemand = hourlyDemands;

            // console.log('inside PeakhourlyDeamnds ch',this.peakHourlyDemands);
          }
          count = 0
          hourlyDemands = 0
        }

        hourlyDemands += point.usage;
        count++
      }





      // If data is in 15 min
      if (point.blocked == 'Y') {
        // console.log('date',point.date);
        // console.log('intervalid',point.intervalId);

        if (moment(point.date).hour(point.intervalId) != lastHour) {
          // Populate hourlyUsages array to calcuate Average Hourly Usage
          hourlyUsages.push(hourlyUsage);
          hourlyUsage = 0;
          // Calculate Peak Hourly Demand
          if (this.capeProductFlg == 'Y') {
            if (hourlyDemand > this.peakHourlyDemand) {
              this.peakHourlyDemand = hourlyDemand;
            }
            hourlyDemand = 0;
          }
          lastHour = moment(point.date).hour(point.intervalId);
        }
      } else {
        // console.log('in else',point.date);
        // Data in hour intervals
        hourlyUsages.push(hourlyUsage);
        hourlyUsage = 0;
        if (this.capeProductFlg == 'Y') {
          if (hourlyDemand > this.peakHourlyDemand) {
            this.peakHourlyDemand = hourlyDemand;
          }
          hourlyDemand = 0;
        }
      }
      // Calculate Peak Hour
      if (point.demand > peakDemandPoint.demand) {
        peakDemandPoint = point;
      }
    });


    hourlyUsages.forEach((usage) => {
      totalHourlyUsages += usage;
    });
    // Calcuate Average Hourly Usage
    if (hourlyUsages.length > 0) {
      this.averageHourlyUsage = totalHourlyUsages / hourlyUsages.length;
      if (this.capeProductFlg == 'N') {
        this.averageHourlyUsage = this.averageHourlyUsage * 4;
      }
    } else {
      this.averageHourlyUsage = 0;
    }
    // Format Peak Demand Hour
    this.peakDemandHr = this.getMomentFromPoint(peakDemandPoint).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
    // Calculate Total Exposure
    this.totalExposure = Math.abs(this.totalUsage - this.totalHedge);
    // Calculate Percent Exposure
    if (this.totalUsage === 0) {
      this.percentExposure = 0;
    } else {
      this.percentExposure = ((this.totalExposure / this.totalUsage));
    }
  }

  goToSiteGroups() {
    this.router.navigate(['/site-groups']);
  }

  getMomentFromPoint(point: IUsageGraphPoint) {
    if (this.capeProductFlg == 'Y')
      return moment(moment(point.date).hour(point.intervalId - 1).format('MM/DD/YY HH:mm:SS'));
    if (this.capeProductFlg == 'N')
      return moment(moment(point.date).minute((point.intervalId - 1) * 15).format('MM/DD/YY HH:mm:SS'));
  }

  createGraph(capeflag: string, graphData: IUsageGraph) {
    Highcharts.chart('usageGraph', {
      title: {
        text: ''
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      xAxis: {
        categories: this.getCategories(graphData),
        events: {
          afterSetExtremes: (event) => {
            if (event.max) {
              const minIndex = Math.ceil(event.min);
              const maxIndex = Math.floor(event.max);
              const newStart = moment(graphData.points[minIndex].date);
              const newEnd = moment(graphData.points[maxIndex].date);
              this.dateRangeDisplay = newStart.format('MM/DD/YY') + ' - ' + newEnd.format('MM/DD/YY');
              this.populateSummaryData(graphData.points.slice(minIndex, maxIndex + 1));
            }
          }
        }
      },
      chart: {
        zoomType: 'x',
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
      },
      yAxis: {
        title: {
          text: 'DEMAND (KW)'
        },
        labels: {
          formatter: function () {
            return this.axis.defaultLabelFormatter.call(this);
          }
        }
      },
      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },

      tooltip: {
        crosshairs: {
          width: 2,
          color: 'black'
        },
        formatter: function () {
          let start, end;
          if (capeflag == 'Y') {
            start = moment(this.point.dateDisplay);
            end = start.clone().add(1, 'hours');
          } else {
            start = moment(this.point.dateDisplay);
            end = start.clone().add(15, 'minutes');
          }

          return start.format('ddd, MMM Do') + '<br />' +
            start.format('HH:mm') + ' - ' + end.format('HH:mm') + ' CST <br/>'
            + 'Demand: ' + this.point.demand.toFixed(3) + ' KW <br /> Hedge: ' + this.point.blockedDemand.toFixed(3)
            + ' KW <br /> Index: ' + this.point.mcpeDemand.toFixed(3) + ' KW <br />'
            + 'AMS Count: ' + this.point.amsCount + ' <br /> IDR Count: ' + this.point.idrCount
            + ' <br /> NIDR Count: ' + this.point.nidrCount + ' <br />';
        },
      },
      series: [{
        name: 'Demand (KW)',
        showInLegend: true,
        type: 'area',
        data: this.getDemandData(graphData),
        step: 'left',
        turboThreshold: 1000000,
      }, {
        name: 'Hedge (KW)',
        showInLegend: true,
        type: 'area',
        data: this.getBlockData(graphData),
        turboThreshold: 1000000,
        step: 'left',
        legendColor: 'red',
      }
      ]
    });
  }


  exportToExcel() {
    this.dataService.setLoading(true)
    let req: IUsageGraphRequest = {
      accountId: this.myAccount.id,
      accountName: this.myAccount.name,
      siteRequest: 'ALL_SITES',
      startDate: moment(moment().subtract(2, 'months').format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(moment().subtract(2, 'months').format('YYYY-MM-DD')).endOf('month').format('YYYY-MM-DD'),
      frequencyType: 'ALL',
      capeProductFlg: this.capeProductFlg,
      brand: window.localStorage.getItem("brand")
    };
    this.service.exportUsageGraph(req);
  }




  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  private getCategories(graphData: IUsageGraph): string[] {
    return graphData.points.map(point => (point.isLongDay ? '*' : '') + moment(point.dateDisplay).format('MM/DD HH:mm'));
  }

  private getDemandData(graphData: IUsageGraph): IUsageGraphPoint[] {
    return graphData.points.map(pt => {
      const point = { ...pt };
      point.y = point.demand;
      return point;
    });
  }

  private getBlockData(graphData: IUsageGraph): IUsageGraphPoint[] {
    return graphData.points.map(pt => {
      const point = { ...pt };
      point.y = point.blockedDemand;
      return point;
    });
  }
}
