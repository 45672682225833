import { Component, OnInit, Injectable } from '@angular/core';
import {DataService} from "../shared/data.service";
import {IUserAccount} from "../shared/entities/profile";
import {PortalService} from '../shared/portal.service';
import * as moment from 'moment';
import {GenericPipe} from '../shared/pipe/generic.pipe'
import {OrderByPipe} from '../shared/pipe/orderBy.pipe'
import {PagerDenoms} from '../shared/const/pagerDenoms'
import {IPagerDenom} from '../shared/const/pagerDenoms'
import {PagerService} from '../shared/pager.service'
import {Subscription} from "rxjs";
import {FakerService} from "../shared/faker.service";
import {IHelpStep} from "../shared/entities/contextualHelp";
import {Authority} from "../shared/const/authority";
import {IBrokerEnrollments, IBrokerEnrollmentsRequest, TSDP} from '../shared/entities/brokerEnrollments';
import {BrokerEnrollmentsService } from './broker-enrollments.service';
import {BrokerEnrollmentsPipe} from '../shared/pipe/brokerenrollments.pipe';
import { ISite } from '../shared/entities/site';
import { FilterType, ISubscribeReportTemplate } from '../shared/entities/subscribeReportTemplate';
import { IEnrollmentReportSetting, IGenericReportSetting } from '../shared/entities/reportSettings';
import { ReportSettingsService } from '../subscribe-to-report/report-settings.service';
import { ReportDataUtility } from '../subscribe-to-report/report-data.utility';

declare var $: any;

@Component({
  selector: 'app-broker-enrollments',
  templateUrl: './broker-enrollments.component.html',
  styleUrls: ['./broker-enrollments.component.scss'],
  providers: [BrokerEnrollmentsPipe, GenericPipe, OrderByPipe]
})

@Injectable()
export class BrokerEnrollmentsComponent implements OnInit {

  myAccount: IUserAccount;

  minDate: Date;
  maxDate: Date;
  maxDays: number = 30;
  fromDate: Date;
  toDate: Date;
  showSearchBar = false;
  initialStartDate: Date = new Date();
  startDate: Date = new Date();
  endDate: Date = new Date();
  selectAll = false;
  readonly All = "All";

  tdspOptions: string[] = [this.All];
  selectedTdsp: string = this.tdspOptions[0]; // used for backend api
  displaySelectedTdsp: string = ""; // used for UI display
  showTdspEdc: boolean = false;
  showEnrollment: boolean = false;


  searchTerm: string;

  maskMode: boolean;
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  ascending: boolean;
  noDataError: boolean = false;
  displayLimitError: boolean = false;

  allEnrollmentsReport: IBrokerEnrollments[];
  filteredEnrollmentsReport: IBrokerEnrollments[];

  isSuperUser: boolean;

  helpStep: IHelpStep;

  sortBy: string = 'enollmentSubmissionDt';

  // Subscriptions
  exportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

 /**************************************************************
  Variables For Subscribe to Report Template - START
  **************************************************************/
  reportSettings: IGenericReportSetting[]; // List of All Report Settings for Subscribe To Report Template
  privateReports: IGenericReportSetting[]; // List of Private Report Settings for Subscribe To Report Template
  publicReports: IGenericReportSetting[];  // List of Private Report Settings for Subscribe To Report Template
  reportsLoaded: boolean = false;          // Boolean that tells us if Reports have loaded, used to Render Subscribe to Report Template
  newReportName = '';                      // Report Name used for saving a report
  makeDefault: boolean = true;             // Boolean Flag used for saving default report
  makePublic: boolean = false;             // Boolean Flag used for saving public or private reports
  selectedReport: IGenericReportSetting;   // Report that is used to generate data for the table and for saving/editing/deleting reports
  reportSubscriptionRangesSourceSubscription: Subscription; // Used to get the valid date ranges for Reports
  datePeriods: any[];                      // Helper for displaying different date periods for the filter (3 Months, 6 Months, etc.)
  reportDateRanges: any[];                 // Stores valid date ranges for reports
  endDates: any[];                         // Stores end date ranges for reports
  dateRangeControl: any = {                // Used to track date range selections from user inputs
    periodSelected: true,
    toggled: false,
    show: false,
    startDate: {},
    endDate: {},
    datePeriod: 'Last3Months',
    datePeriodDisplay: 'Last 3 Months',
    dateRangeDisplay: '',
    includeSitesWithoutMoveIn: true
  };
  reportTemplate: ISubscribeReportTemplate = { // Holds the information about the specific report and builds child SubscribeToReportTemplate with correct filters
    title: "Broker Enrollment Report",
    defaultReportName: "Enrollment Report 01",
    reportType: 'BROKER_ENROLL',
    description: "This is a sample report description.",
    filters: [
      { // This is the first filter
        name: "Move-In Date Range",
        filterType: FilterType.DatePicker,
        options: [
          { label: "Choice1", value: "choice1" }, // These are unused DatePicker is a special filter type
          { label: "Choice2", value: "choice2" }, // Never the less this is the pattern you would use to load a filter
          { label: "Choice3", value: "choice3" },
          { label: "Choice4", value: "choice4" },
          { label: "Choice5", value: "choice5" },
        ],
        selectedOptionLabel:"Last 3 Months", // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        selectedOptionValue: "Last3Months",  // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        defaultOptionLabel: "Last 3 Months", // Default Option
        defaultOptionValue: "Last3Months",   // Default Option
        showfilter: false
      },
    ],
  };
   /**************************************************************
   Variables For Subscribe to Report Template - END
   **************************************************************/


  constructor(private dataService: DataService,
    private enrollmentsReportService: BrokerEnrollmentsService,
    private pipe: GenericPipe,
    private portalService: PortalService,
    private enrollmentsReportPipe: BrokerEnrollmentsPipe,
    private reportSettingsService: ReportSettingsService,
    private utility: ReportDataUtility,
    private orderByPipe: OrderByPipe,
    private pagerService: PagerService,
    private fakerService: FakerService) { }

  ngOnInit(): void {
    this.displaySelectedTdsp = this.tdspOptions[0].length < 30 ? this.tdspOptions[0] : this.tdspOptions[0].substr(0, 30) + '...';
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    // console.log("IS SUPER USER: ", this.isSuperUser);
   this.dataService.setTitleSource('Enrollments Report');
    this.setCurrentActivePage('tools');
    this.dataService.setSelectedNavItem('tools');
    this.myAccount = this.dataService.getAccountSource();
    // this.minDate = moment(new Date()).subtract(3,'years').toDate();
    // this.maxDate = moment(new Date()).toDate();
    // this.startDate = moment(new Date()).subtract(30,'days').toDate();
    // this.endDate =  moment(new Date()).toDate();
    this.initDateRangeValues();
    this.tdspOptions = this.tdspOptions.concat(
      Object.keys(TSDP)
        .map((key) => {
          return TSDP[key];
        })
        .sort()
    );
    this.reportTemplate.filters.forEach(filter =>{
      if(filter.filterType === FilterType.DatePicker) {
        filter.selectedOptionLabel = this.dateRangeControl.datePeriodDisplay;
        this.reportSubscriptionRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
          (dateRanges) => {
            this.reportDateRanges = dateRanges;
            this.endDates = dateRanges;
          });
      }
    });
    this.utility.calculateDateRanges();
    if (this.myAccount) {
      this.getReportSettings(true);
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getEnrollmentsReport();
      }
    );
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.count==10) {
        this.selectDenom(denom);
      }
    });
    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {this.export();});


    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    document.addEventListener("click", ($event) => {
      if ($($event.target).parents("#enrollmentDropdown").length == 0) {
        this.showEnrollment = false;
      }
    });

    document.addEventListener("click", ($event) => {
      if ($($event.target).parents("#tdspDropdown").length == 0) {
        this.showTdspEdc = false;
      }
    });
  }

   toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    this.filterEnrollmentsReport();
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }


  getFakeCustName(){
    return this.portalService.getFakeCustName();
  }

  paginate(page: number){
    if(this.filteredEnrollmentsReport){
      if(this.sortBy !== 'contractStartDate' && this.sortBy!=='contractEndDate' &&  this.sortBy!=='moveInDate'){
        this.filteredEnrollmentsReport = this.orderByPipe.transform(this.filteredEnrollmentsReport, this.sortBy, this.ascending);
      } else {
        this.filteredEnrollmentsReport = this.filteredEnrollmentsReport.sort((a, b) => {
          if(this.sortBy === 'contractStartDate'){
            let dateA = new Date(a.contractStartDate);
            let dateB = new Date(b.contractStartDate);
            if (this.ascending) {
              return dateA.getTime() - dateB.getTime();
            } else {
              return dateB.getTime() - dateA.getTime();
            }
          } else if(this.sortBy === 'contractEndDate'){
            let dateA = new Date(a.contractEndDate);
            let dateB = new Date(b.contractEndDate);
            if (this.ascending) {
              return dateA.getTime() - dateB.getTime();
            } else {
              return dateB.getTime() - dateA.getTime();
            }
          } else if(this.sortBy === 'moveInDate'){
            let dateA = new Date(a.moveInDate);
            let dateB = new Date(b.moveInDate);
            if (this.ascending) {
              return dateA.getTime() - dateB.getTime();
            } else {
              return dateB.getTime() - dateA.getTime();
            }
          }
        });
      }
      this.pager = this.pagerService.getPage(this.filteredEnrollmentsReport, page, this.selectedDenom.count)
    }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  numberWithCommas(x, dollar?: boolean, twoDecimals?: boolean) {
    if(x == null){
      return '--'
    }
    let val = +x;
    let response = this.portalService.numberWithCommas(val);
    if(dollar || twoDecimals){
      response = this.portalService.numberWithCommas(val.toFixed(2));
    }
    if(dollar){
      response = '$' + response;
    }
    return response;
  }

  initDateRangeValues() {
    this.maxDays = moment(new Date()).diff(
      moment(new Date()).subtract(3, "years"),
      "days"
    );
    this.minDate = moment(new Date()).subtract(3, "years").toDate();
    this.maxDate = moment(new Date()).toDate();
    this.initialStartDate = moment(this.maxDate)
      .subtract(30, "day")
      .endOf("day")
      .toDate();
    this.startDate = this.initialStartDate;
    this.endDate = this.maxDate;
  }

  export() {
    // Get start date, end date, account Id,
    let startDateString = moment(this.startDate).format('YYYY-MM-DD');
    let endDateString = moment(this.endDate).format('YYYY-MM-DD');
    this.dataService.setLoading(true);

    if(startDateString && endDateString){
        let request: IBrokerEnrollmentsRequest = {
         accountId: this.myAccount.id,
         accountName: this.dataService.getAccountSource().name,
         startDate: startDateString,
         endDate: endDateString,
         includeNullMoveInDates: this.selectAll,
         maskingMode : this.dataService.getMaskMode(),
         brand: window.localStorage.getItem("brand"),
         tdsp:
         this.selectedTdsp === this.All
           ? this.All.toUpperCase()
           : this.selectedTdsp
      };
      this.enrollmentsReportService.exportEnrollmentsReport(request);
     }
   }



  filterEnrollmentsReport() {
    let filteredItems: IBrokerEnrollments[] = this.allEnrollmentsReport;
    // filter by tdsp
    filteredItems =
      this.selectedTdsp.toUpperCase().trim() === this.All.toUpperCase()
        ? filteredItems
        : filteredItems.filter((item) => {
            return (
              item.tdsp.toUpperCase().trim() ===
              this.selectedTdsp.toUpperCase().trim()
            );
          });
    this.filteredEnrollmentsReport = JSON.parse(JSON.stringify(filteredItems));
    this.sortEnrollmentsReportByMoveInDate();
  }

  sortEnrollmentsReportByMoveInDate() {
    // sort the drop date column in descending order
    this.ascending = false;
    this.filteredEnrollmentsReport = this.orderByPipe.transform(
      this.filteredEnrollmentsReport,
      "dropDate",
      this.ascending
    );
    this.paginate(1);
  }


  sortEnrollmentsReport(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }

  searchEnrollmentsReport() {
    if (this.filteredEnrollmentsReport) {
      if (this.searchTerm) {
        this.filteredEnrollmentsReport = this.filterDropsBySearch();
      } else {
        this.filteredEnrollmentsReport = this.allEnrollmentsReport; //JSON.parse(JSON.stringify(this.filteredEnrollmentsReport));
      }
    }
    this.paginate(1);
  }

  filterDropsBySearch(): IBrokerEnrollments[] {
    const term = this.searchTerm
      .toString()
      .split("")
      .filter((s) => s !== "/" && s !== "-" && s !== ".")
      .join("")
      .toLowerCase()
      .trim();
    const termMoment = moment(this.searchTerm);
    const filteredEnrolls: IBrokerEnrollments[] = [];
    this.filteredEnrollmentsReport.forEach((enroll) => {
      for (let key in enroll) {
        if (enroll.hasOwnProperty(key) && enroll[key]) {
          if (
            enroll[key]
              .toString()
              .split("")
              .filter((s) => s !== "/" && s !== "-" && s !== ".")
              .join("")
              .toLowerCase()
              .trim()
              .match(term)
          ) {
            filteredEnrolls.push(enroll);
            break;
          } else if (
            !enroll[key].toString().match(/[a-z]/i) &&
            key.match("Date") &&
            moment(enroll[key]).format("YYYYMMDD").match(term)
          ) {
            filteredEnrolls.push(enroll);
            break;
          }
        }
      }
    });
    return filteredEnrolls;
  }

   getEnrollmentsReport(){
      this.dataService.setLoading(true);
      let startDateString = moment(this.startDate).format('YYYY-MM-DD');
      let endDateString = moment(this.endDate).format('YYYY-MM-DD');
      if(startDateString && endDateString){
        let request: IBrokerEnrollmentsRequest = {
          accountId: this.myAccount.id,
          accountName: this.dataService.getAccountSource().name,
          startDate: startDateString,
          endDate: endDateString,
          includeNullMoveInDates: this.selectAll,
          brand: window.localStorage.getItem("brand"),
          tdsp: this.All.toUpperCase()
        };

        this.enrollmentsReportService.getEnrollmentsReport(request).subscribe(
          (enrollmentsReportResult) => {
            this.allEnrollmentsReport = enrollmentsReportResult;
            this.noDataError = this.allEnrollmentsReport.length == 0;
            this.displayLimitError = false;
            this.ascending = true;
            this.filteredEnrollmentsReport = this.orderByPipe.transform(this.allEnrollmentsReport, 'enollmentSubmissionDt', this.ascending);
            this.sortEnrollmentsReport('enollmentSubmissionDt');
            this.paginate(1);
            this.dataService.setLoading(false);
          }, err => {
            if(err.status == 404) {
              this.noDataError = true;
              this.displayLimitError = true;
            }
            if(err.status == 500) {
              this.displayLimitError = true;
              this.noDataError = false;
            }
            this.filteredEnrollmentsReport = [];
            this.paginate(1);
            console.error(err);
            this.dataService.setLoading(false);
          }
        );
      }
   }


   ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.reportSubscriptionRangesSourceSubscription) this.reportSubscriptionRangesSourceSubscription.unsubscribe()
  }


  toggleEnrollment() {
    this.showEnrollment = !this.showEnrollment;
  }

  toggleTdspEdc() {
    this.showTdspEdc = !this.showTdspEdc;
  }

  selectTdsp(tdsp: string) {
    // format: CNP - CENTERPOINT ENERGY. Extract the abbreviation.\
    this.displaySelectedTdsp = tdsp.length < 30 ? tdsp : tdsp.substr(0, 30) + '...'
    this.selectedTdsp = tdsp.split("-")[0].trim();
    this.toggleTdspEdc();
    this.filterEnrollmentsReport();
  }

  updateDateRange(event: { startDate: Date; endDate: Date, selectAll: boolean }) {
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.selectAll = event.selectAll;
    this.getEnrollmentsReport();
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  /**************************************************************
  Methods For Subscribe to Report Template - Start
  **************************************************************/
  getReportSettings(selectDefault?: boolean){
    this.utility.getReportSettings(this.myAccount.id, this.reportTemplate, this.dateRangeControl, this.datePeriods, this.reportDateRanges, this.maskMode, selectDefault, this.selectedReport, null, null)
    .subscribe({
      next: ({ reportSettings, reportTemplate, selectedReport, publicReports, privateReports, startDate, endDate }) => {
        this.reportSettings = reportSettings;
        this.selectedReport = selectedReport;
        this.publicReports = publicReports;
        this.privateReports = privateReports;
        this.startDate = startDate;
        this.endDate = endDate;
        this.reportsLoaded = true;
        reportTemplate.filters.forEach((filter) => {
          switch (filter.filterType) {
            case 'DatePicker':
              this.dateRangeControl = filter.savedFilterControl;
              this.dateRangeControl.includeSitesWithoutMoveIn ? this.selectAll = this.dateRangeControl.includeSitesWithoutMoveIn : null;
              break;
            case 'SitePicker':
              // Uncomment if using SitePicker
              // this.sitesControl = filter.savedFilterControl;
              break;
            default:
              // Action for other filter types
              break;
          }
        });
        this.getEnrollmentsReport();
      },
      error: (error) => {
        // Handle errors
      }
    });
  }

  reportSavedHandler($event) {
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    this.makePublic = this.selectedReport.publicFlag ? this.selectedReport.publicFlag == 1 : false;
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          this.dateRangeControl.includeSitesWithoutMoveIn ? this.selectAll = this.dateRangeControl.includeSitesWithoutMoveIn : null;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          // this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
  });

    let isNewReport = this.selectedReport.reportId == null;

    const saveReportRequest: any = {
      reportType: this.reportTemplate.reportType,
      reportId: this.selectedReport.reportId,
      accountId: this.myAccount.id,
      reportName: this.newReportName ? this.newReportName : this.selectedReport.reportName ? this.selectedReport.reportName : 'Generic Report 01',
      defaultReport: this.selectedReport.defaultReport ? 1 : 0,
      publicFlag: this.selectedReport.publicFlag ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.endDate,
      reportSpecificBoolean: this.dateRangeControl.includeSitesWithoutMoveIn ? this.dateRangeControl.includeSitesWithoutMoveIn : null
    };
    this.utility.saveReport(saveReportRequest, isNewReport)
    .then((reportId) => {
        this.selectedReport.reportId = reportId;
        this.getReportSettings();
    })
    .catch((error) => {
        // it will load default report
        this.getReportSettings(true);
    });
  }

  reportSelectedHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    this.selectAll = this.selectedReport.reportSpecificBoolean; // Broker Enroll Specific
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    this.startDate = loadSelectedReport.startDate;
    this.endDate = loadSelectedReport.endDate;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          this.dateRangeControl.includeSitesWithoutMoveIn ? this.selectAll = this.dateRangeControl.includeSitesWithoutMoveIn : null;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          // this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    this.getEnrollmentsReport();
  }

  exportHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the BillingReport Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          // this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });
    this.dataService.setLoading(true);
    this.export();
  }

  reportDeletedHandler($event){
    this.reportSettingsService.deleteReportSetting(this.myAccount.id, this.selectedReport.reportId, this.dataService.getUserSource().login,this.reportTemplate.reportType, this.selectedReport.publicFlag).subscribe(
      () => {
        this.getReportSettings(true);
      }
    );
  }
  /**************************************************************
  Methods For Subscribe to Report Template - End
  **************************************************************/
}


