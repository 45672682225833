import {PipeTransform, Pipe} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'marketsPipe'
})
export class MarketsPipe implements PipeTransform {
  transform(datasets: any[], term: string): any[]{
    term = term.toLowerCase().trim();
    let termMoment = moment(term);
    if(term.length >= 3){
      let filteredDatasets = Object.assign([], datasets);
      let filteredData;

      filteredDatasets.forEach((dataset) => {
        filteredData = [];
        dataset.data.forEach((point) => {
          if(moment(point.x).format('MM/DD') == termMoment.format('MM/DD')){
            filteredData.push(point);
          }
        });
        dataset.data = filteredData;
      });
      return filteredDatasets;
    } else {
      return datasets;
    }

  }
}
