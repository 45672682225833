import { Injectable } from "@angular/core";
import { Headers, Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend } from "@angular/http";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DataService } from "./data.service";
import { PortalService } from "./portal.service";

@Injectable()
export class OamHttpOldInterceptor extends Http {
  constructor(backend: XHRBackend, defaultOptions: RequestOptions,
      private portalService: PortalService) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {

    const nrgEndpoints = ["./private", "./api"];
    if (url instanceof Request && !nrgEndpoints.some(endpoint => url.url.startsWith(endpoint))) return super.request(url, options);
    if (typeof url === 'string' && !nrgEndpoints.some(endpoint => url.startsWith(endpoint))) return super.request(url, options);

    let accessToken = window.localStorage.getItem('access_token');
    let refreshToken = window.localStorage.getItem('refresh_token');

    if (typeof url === 'string') {
      if (!options) {
        options = { headers: new Headers() };
      }
      options.headers.set('Authorization', 'Bearer ' + accessToken);
      options.headers.set('Refresh-Token', refreshToken);
    } else {
      url.headers.set('Authorization', 'Bearer ' + accessToken);
      url.headers.set('Refresh-Token', refreshToken);
    }

    return super.request(url, options).pipe(
      tap((event: Response) => {
        let newAuthToken = event.headers.get('Authorization-Updated');
          let newRefreshToken = event.headers.get('Refresh-Updated');
          if (newAuthToken && newRefreshToken) {
            localStorage.setItem('access_token', newAuthToken);
            localStorage.setItem('refresh_token', newRefreshToken);
          }
      }),
      catchError((error: any) => {
        if (error.status === 403) {
          console.error('Unauthorized access - possibly invalid token.');
          this.portalService.logout().subscribe();
        }
        // Let other errors pass through
        return throwError(error);
      })
    );
  }
}
