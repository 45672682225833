import {Injectable, EventEmitter} from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {PortalService} from "../shared/portal.service";


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";

import { ContentResponse } from "../shared/entities/contentResponse";

@Injectable()
export class EditorialContentService {

  headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private oldHttp: Http, private portalService: PortalService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getContentPage(route: string, useRelatedData = false) {
    if(route == null) {
      return null;
    }

    let url = environment.production ? environment.contentPage : environment.contentPage;
    let updatedURL = url + route;
    //let url = "http://localhost:8880/api/content?path=" + route

    let personalizationProfile = this.portalService.getPersonalizationProfile(true);

    return this.httpClient
      .post(updatedURL, personalizationProfile, {headers: this.headers})
      .map((response: HttpResponse<any>) => {
        let contentResponse = this.mapToContentResponse(response, useRelatedData);
        this.portalService.updatePersonalizationProfileAndFireAnalyticsEvent(contentResponse.personalizationTags, route);
        return contentResponse
      })
      .catch(this.handleError);
  }

  mapToContentResponse(response: any, useRelatedData: boolean) {
    let contentResponse: ContentResponse = {} as any;
    contentResponse.personalizationTags = response.contentPayload.personalizationTags;

    let primaryChild = response.contentPayload.children[0];

    contentResponse.layoutType = primaryChild.type;
    if (useRelatedData) {
      contentResponse.primaryComponents = primaryChild.payload.relatedContent.items;
      contentResponse.secondaryComponents = [primaryChild.payload.relatedNews.event];
    } else {
      contentResponse.primaryComponents = primaryChild.payload.primary;
      contentResponse.secondaryComponents = primaryChild.payload.secondary;
      if (primaryChild.payload.header && primaryChild.payload.header.length) {
        contentResponse.headerComponents = primaryChild.payload.header;
      }
    }

    return contentResponse;
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
