// Created by Ramalingam - May 2020

import { Injectable } from '@angular/core';
import { Response, ResponseContentType, Headers, Http } from '@angular/http';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
import { DataService } from '../../shared/data.service';
import { ISite } from '../../shared/entities/site';
import { IUsageGraph, IUsageGraphRequest } from '../../shared/entities/usageGraph';
import { IRealTimeGraph } from '../../shared/entities/realtimeUsage';
import { IMarketData } from '../../shared/entities/markets';


@Injectable()
export class RealtimeUsageService {
  headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private http: HttpClient, private oldHttp: Http, private dataService: DataService) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }


  //   getSitesForSystemGroup(accountId: string, groupName: string, frequencyType?: string): Observable<ISite[]> {
  //     let url = environment.getSitesForSystemGroupUrl;
  //     if(environment.production){
  //       url = url.replace('{accountId}', accountId.toString()).replace('{groupName}', groupName.toString());
  //       if(frequencyType){
  //         url += frequencyType;
  //       }
  //     }
  //     return this.http
  //       .get(url)
  //       .map((response: HttpResponse<ISite[]>) => response)
  //       .catch(this.handleError);
  //   }

  getShadowSites(accountId: string): Observable<ISite[]> {
    let url = environment.getShadowSites.replace('{accountId}', accountId.toString());
    // console.log('4 shadow Meter',accountId.toString());
    return this.http
      .get(url).pipe(
        map((data: ISite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getShadowCategory(accountId: string, groupId: string): Observable<any[]> {
    let url = environment.getShadowCategory.replace('{accountId}', accountId.toString()).replace('{groupId}', groupId.toString());
    // console.log('5 shadow Groups Category',accountId.toString());
    return this.http
      .get(url).pipe(
        map((data: any[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getShadowGroups(accountId: string): Observable<any[]> {
    let url = environment.getShadowGroups.replace('{accountId}', accountId.toString());
    // console.log('5 shadow Groups accountId',accountId.toString());
    return this.http
      .get(url).pipe(
        map((data: any[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getShadowContracts(accountId: string): Observable<any[]> {
    let url = environment.getShadowContracts.replace('{accountId}', accountId.toString());
    console.log('5 shadow Contracts accountId', accountId.toString());
    return this.http
    .get(url).pipe(
      map((data: any[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getDefault(accountId: string): Observable<IUsageGraphRequest> {
    let url = environment.setRUDefaultUrl.replace('{accountId}', accountId.toString());
    return this.http
      .get(url).pipe(
        map((data: IUsageGraphRequest)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  setDefault(request: IUsageGraphRequest) {
    let url = environment.setRUDefaultUrl.replace('{accountId}', request.accountId.toString());
    return this.http
    .post(url, request, { headers: this.headers })
    .map((response: HttpResponse<IRealTimeGraph>) => response)
    .catch(this.handleError);
  }

  getDemand(request: IUsageGraphRequest): Observable<IRealTimeGraph> {
    let url = environment.getShadowDemandUrl.replace('{accountId}', request.accountId.toString());;

    return this.http
    .post(url,request, {headers : this.headers}).pipe(
      map((data: IRealTimeGraph)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );

    // if(environment.production){
    //   url = environment.getUsageGraphUrl.replace('{accountId}', request.accountId);
    //   return this.http
    //     .post(url, request, {headers: this.headers})
    //     .map((response: HttpResponse<any[]>) => response)
    //     .catch(this.handleError);
    // } else {
    //   return this.http
    //     .get(url)
    //     .map((response: HttpResponse<any[]>) => response)
    //     .catch(this.handleError);
    // }
  }


  getActualAndForecast(request: any): Observable<any> {
    let url = environment.getActualAndForecastUrl.replace('{accountId}', request.accountId);
    return this.httpClient
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getBlockTransactions(request: any): Observable<any> {
    let url = environment.getBlockTransactionsUrl;
    return this.httpClient
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  exportRealtimeGraph(request: IUsageGraphRequest): any {
    let url = environment.production ? environment.exportShadowMeterUrl.replace('{accountId}', request.accountId.toString()) : environment.exportShadowMeterUrl;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.oldHttp
      .post(url, request, { headers: headers, responseType: ResponseContentType.Blob })
      .subscribe(
        (res) => {
          let filename = 'Shadow Usage Detail_' + moment().format('MMDDYYYY') + '.xlsx';
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }



  getUsageGraph(request: IUsageGraphRequest): Observable<IUsageGraph> {
    let url = environment.getActualAndShadowDemandUrl;
    if (environment.production) {
      url = environment.getActualAndShadowDemandUrl.replace('{accountId}', request.accountId);
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IUsageGraph)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
        .post(url,request, {headers : this.headers}).pipe(
          map((data: IUsageGraph)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
    }
  }


  rgetMarketData(source: string, request: IUsageGraphRequest) {
    let url = environment.getMarketUsageUrl;

    if (environment.production) {
      url = url.replace('{source}', source);
      url = url.replace('{startDate}', request.startDate);
      url = url.replace('{endDate}', request.endDate);
    }


    return this.http
      .get(url, { headers: this.headers })
      .map((response: HttpResponse<IMarketData>) => response)
      .catch(this.handleError);
  }



  getMarketData(source: string, offset: number) {
    let url = environment.getMarketDashboardDataUrl;

    if (environment.production) {
      url = url.replace('{source}', source);
      url = url.replace('{offset}', '-' + offset.toString());
    }


    // if(offset){
    //   url = url.replace('{offset}', offset.value.toString());
    // } else {
    //   url = url.replace('{offset}', '-7');
    // }
    return this.http
      .get(url, { headers: this.headers })
      .map((response: HttpResponse<IMarketData>) => response)
      .catch(this.handleError);
  }



  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
