import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {AmendmentsService} from "../amendments.service";
import {Router} from "@angular/router";
import {IUserAccount} from "../../shared/entities/profile";
import {Subscription} from "rxjs";
import * as moment from 'moment';
import {IAmendmentProduct, IAmendmentRequest, IAmendmentSite} from "../../shared/entities/amendments";
import {PortalService} from "../../shared/portal.service";
declare var $: any;

@Component({
  selector: 'app-amendments-confirmation',
  templateUrl: './amendments-confirmation.component.html',
  styleUrls: ['./amendments-confirmation.component.scss', '../amendments.component.scss']
})
export class AmendmentsConfirmationComponent implements OnInit {

  myAccount: IUserAccount;
  accountSubscription: Subscription;
  selectedAmendmentRequest: IAmendmentRequest;
  selectedAmendmentSubscription: Subscription;
  printing: boolean;
  hasAddSites: boolean;
  hasDeleteSites: boolean;
  loadAPI: Promise<any>;
  maskMode: boolean;
  maskModeSubscription: Subscription;
  fakeAccountName: string;

  constructor(private dataService: DataService,
              private amendmentsService: AmendmentsService,
              private router: Router,
              private portalService: PortalService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Add/Delete Sites - Confirmation');
    this.dataService.setSelectedNavItem('accounts');
    this.selectedAmendmentRequest = this.dataService.getSelectedAmendmentRequest();

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.fakeAccountName = this.dataService.getFakeAccountName();
    });

    if(!this.selectedAmendmentRequest || !this.selectedAmendmentRequest.id){
      this.goBack();
    }

    this.myAccount = this.dataService.getAccountSource();
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
    });


  // $.getScript("https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js")
  //               .done(() => {  });

  //       setTimeout(function(){
  //         $.getScript("https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js")
  //               .done(() => { });
  //           }, 2000);

//  $.getScript("https://nrg.allegiancetech.com/surveys/WG79UA/Preview/Scripts/clientExtensibility.js?")

        // setTimeout(function(){
        //   $.getScript("https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js")
        //         .done(() => { });
        //     }, 3000);

      // this.loadAPI = new Promise((resolve) => {
      //         this.loadScript();
      //         resolve(true);
      //     });

              // $(document).ready(function() {
              //       //  alert("Hello! I am an  alert box inside amendment function!!");

              //       console.log("Firing script for loading Maritz Survey xyz");
              //       var node1 = document.createElement('script');
              //       node1.type = 'text/JavaScript';
              //       node1.src = 'https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js';
              //       // document.body.removeChild(node1);
              //       document.body.appendChild(node1);

              //        var node2 = document.createElement('script');
              //       node2.type = 'text/JavaScript';
              //       node2.src = 'https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js';
              //       // document.body.removeChild(node2);
              //       document.body.appendChild(node2);
              //    });

    this.formatAmendmentRequest();

    this.selectedAmendmentSubscription = this.dataService.selectedAmendmentRequestUpdated.subscribe((selectedAmendmentRequest) => {
      this.selectedAmendmentRequest = selectedAmendmentRequest;
      this.formatAmendmentRequest();
    });

  }


 loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
            isFound = true;
        }
    }
// if (!isFound) {
//   //var dynamicScripts = ["https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js","https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js"];
//   var dynamicScripts = ["https://nrg.allegiancetech.com/surveys/JC7293/Scripts/clientExtensibility.js"];
//   for (var i = 0; i < dynamicScripts.length; i++) {
//             let node = document.createElement('script');
//             node.src = dynamicScripts [i];
//             node.type = 'text/javascript';
//             node.async = true;
//             node.charset = 'utf-8';
//             document.getElementsByTagName('head')[0].appendChild(node);
//         }
// }
}


  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.selectedAmendmentSubscription) this.selectedAmendmentSubscription.unsubscribe();
  }

  goBack(){
    this.router.navigate(['/amendments/home']);
  }

  formatAmendmentRequest(){
    this.hasAddSites = false;
    this.hasDeleteSites = false;
    if(this.selectedAmendmentRequest && this.selectedAmendmentRequest.amendmentSites){
      this.selectedAmendmentRequest.amendmentSites.forEach((amendmentSite) => {
        amendmentSite.displayAddress = this.formatDisplayAddress(amendmentSite);
        amendmentSite.requestDateFormatted = this.formatRequestDate(amendmentSite);
        if(amendmentSite.amendmentType.toLowerCase() == 'add'){
          this.hasAddSites = true;
        }
        if(amendmentSite.amendmentType.toLowerCase() == 'delete'){
          this.hasDeleteSites = true;
        }
      });
    }
  }

  getSiteIdDisplay(siteId: string) {
    return this.portalService.getSiteIdDisplay(siteId);
  }

  formatDisplayAddress(amendmentSite: IAmendmentSite){
    if(amendmentSite.siteAddress && amendmentSite.siteAddress.length > 0 &&
      amendmentSite.siteCity && amendmentSite.siteCity.length > 0 &&
      amendmentSite.siteState && amendmentSite.siteState.length > 0 &&
      amendmentSite.siteZip && amendmentSite.siteZip.length > 0){
      return amendmentSite.siteAddress + ", " + amendmentSite.siteCity +
        " " + amendmentSite.siteState.toUpperCase() + " " + amendmentSite.siteZip;
    }
    return "-------";
  }

  formatRequestDate(amendmentSite: IAmendmentSite){
    if(!amendmentSite.completeByMeterReadDate){
      if(amendmentSite.completionDate){
        return moment(amendmentSite.completionDate).format('M/D/YYYY');
      }
      else{
        return "-------";
      }
    }
    else{
      return "Next Meter Read";
    }
  }

  printWindow() {
    this.printing = true;
    this.dataService.setPrinting(this.printing);
    setTimeout(() => {
      window.print();
      this.printing = false;
      this.dataService.setPrinting(this.printing);
    }, 50);

  }
}
