import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {Router} from "@angular/router";
import {FadeAnimation} from "../../animations/fadeAnimation";
import * as moment from 'moment';
import {IUserAccount} from "../../shared/entities/profile";
import {IAmendmentSite, IAmendmentProduct, IAmendmentRequest} from "../../shared/entities/amendments";
import {Subscription} from "rxjs";
import {ISite} from "../../shared/entities/site";
import {SitePipe} from "../../shared/pipe/site.pipe";
import {PagerService} from "../../shared/pager.service";
import {IContract} from "../../shared/entities/contract";
import {SiteGroupService} from "../../site-groups/site-groups.service";
import {PortalService} from "../../shared/portal.service";
import {IProduct} from "../../shared/entities/product";
import {AmendmentsService} from "../amendments.service";
import {IAccount} from "../../shared/entities/account";
import {Authority} from "../../shared/const/authority";
import {States} from "../../shared/const/utility";
declare var $ :any;


@Component({
  selector: 'app-amendments-delete',
  templateUrl: './amendments-delete.component.html',
  animations: [FadeAnimation],
  providers: [SitePipe],
  styleUrls: ['../amendments.component.scss', 'amendments-delete.component.scss']
})
export class AmendmentsDeleteComponent implements OnInit {

  myAccount: IUserAccount;
  isSuperUser: boolean = false;
  accountWithContractData: IAccount;
  form: IAmendmentSite = {} as any;
  sitesForAccount: ISite[] = [];
  contractSitesForAccount: ISite[] = [];
  contractsForAccount: IContract[];
  selectedRequest: IAmendmentRequest;
  step = 1;
  completionError: string;
  States: string[];
  sitesLoading: boolean;

  // Request Date
  minDate: Date;
  maxDate: Date;
  completionDateChanged: boolean;
  completionDateDate: Date;
  invalidCompletionDate: boolean;

  // Site selection
  searchTerm: string;
  filteredSites: ISite[] = [];
  showSearchRow: boolean;
  pager: any;
  selectedSite: ISite;
  selectedSiteId: string;

  // Contract/Product Selection
  contractsForSite: IContract[];
  anyChecks: boolean;

  // Reason for Move Out
  raisins = ['Demolition', 'Abandonment', 'Other'];
  showRaisins: boolean;

  accountSubscription: Subscription;
  contractsSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;

  noSitesAvailableError: boolean;

  constructor(private dataService: DataService,
              private router: Router,
              private sitePipe: SitePipe,
              private pagerService: PagerService,
              private siteGroupService: SiteGroupService,
              private portalService: PortalService,
              private amendmentsService: AmendmentsService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Submit Request ');
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    let existingForm = this.dataService.getSelectedAmendmentSite();
    if(existingForm){
      this.form = existingForm;
      if(this.form.completionDate){
        this.completionDateDate = moment(this.form.completionDate).toDate();
      }
    }

    this.States = this.dataService.getMarketStates().filter(st => st.market == 'ALL').map(st => st.state);
    
    if(moment().hour() >= 17){
      this.minDate = moment().add(1, 'days').toDate();
    } else {
      this.minDate = moment().toDate();
    }
    this.maxDate = moment().add(10, 'years').toDate();

    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
      this.form.accountId = this.myAccount.id;
      this.form.market = 'ERCOT';
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goHome();
    });

    this.contractsForAccount = this.dataService.getContractsForAccount();
    if(this.contractsForAccount) {
      this.loadContractSites();
      if(this.form.id){
        this.getStep();
      }
    }
    this.contractsSubscription = this.dataService.contractsForAccountSourceUpdated.subscribe((contracts) => {
      this.contractsForAccount = contracts;
      this.loadContractSites();
      if(this.form.id){
        this.getStep();
      }
    });

    this.selectedRequest = this.dataService.getSelectedAmendmentRequest();
    if(this.selectedRequest){
      this.form.requestId = this.selectedRequest.id;
      this.form.amendmentType = 'DELETE';
    } else {
      this.goHome();
    }

    document.addEventListener("click", ($event) => {
      if ($($event.target).parents('#raisinsSection').length == 0) {
        this.showRaisins = false;
      }
    });

    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.accountWithContractData = account;
    })
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.contractsSubscription) this.contractsSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    this.dataService.setShowAmendmentsHeader(false);
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  getActiveSites() {
    this.sitesLoading = true;
    this.portalService.spinBabySpin('sitesSpinner');
    this.amendmentsService.getActiveSites(this.form.completionDate).subscribe((sites : any) => {
      this.sitesForAccount = sites;
      this.sitesLoading = false;
      this.noSitesAvailableError = false;

      if(this.sitesForAccount.length==0) {
        this.noSitesAvailableError = true;
        this.continue(1); //rename function to goToStep()
        this.selectedSite = null;
      } else {
        this.sitesForAccount.forEach((site) => {
          if(site.siteId == this.form.siteId){
            this.selectedSite = site;
          }
        });
        this.filteredSites = this.sitesForAccount;
        if(this.form.siteId){
          this.selectedSiteId = this.form.siteId;
        }
        this.paginate(1);
      }
    });
  }

  goHome(){
    this.dataService.setSelectedAmendmentSite(undefined);
    this.router.navigate(['/amendments/home']);
  }

  goToContracts(){
    this.dataService.setSelectedAmendmentSite(undefined);
    this.router.navigate(['/contracts']);
    // let url = window.location.href;
    // let urlArr = url.split('#');
    // let newUrl = urlArr[0] + '#/contracts';
    // let win = window.open(newUrl, '_blank');
    // win.focus();
  }

  save() {
    if(!this.dataService.getShowAmendmentsHeader()){
      this.dataService.setShowAmendmentsHeader(true);
    }
    this.amendmentsService.saveAmendmentSite(this.form).subscribe((resp : any) => {
      if(!this.form.id){
        this.form.id = resp.id;
      }
      if(resp.products){
        this.form.products = resp.products;
      } else if(this.contractsForSite){
        // Thanks, Moses...
        let newProducts = [];
        this.contractsForSite.forEach((contract) => {
          contract.products.forEach((product) => {
            if(product.checked){
              newProducts.push(product);
            }
          });
        });
        this.form.products = newProducts;
      }
      // console.log("RESPONSE: ", resp);
      if(this.form.tdspName != resp.tdspName){
        this.form.tdspName = resp.tdspName;
        // console.log("Changed form tdspName to :", this.form.tdspName);
      }
      //replace old site with updated response
      let newSiteList = [];
      if(!this.selectedRequest.amendmentSites){
        this.selectedRequest.amendmentSites = [];
      } else {
        this.selectedRequest.amendmentSites.forEach((amendmentSite) => {
          if(amendmentSite.id != this.form.id){
            newSiteList.push(amendmentSite);
          }
        });
      }
      newSiteList.push(this.form);
      this.selectedRequest.amendmentSites = newSiteList;
      this.dataService.setSelectedAmendmentRequest(this.selectedRequest);
      this.dataService.setSelectedAmendmentSite(this.form);
      if(this.completionDateChanged){
        this.getActiveSites();
        this.completionDateChanged = false;
      }
    });
  }

  loadContractSites() {
    this.paginate(1);
    this.siteGroupService.getSitesForSystemGroupLessReliability(this.myAccount.id, 'CONTRACTS').subscribe(
      (sites) => {
        this.contractSitesForAccount = sites;
      }
    );
  }

  getStep() {
    this.step = 1;

    this.selectedSiteId = this.form.siteId;
    this.getContractsForSite();

    if(this.form.physicallyRemove != undefined){
      this.step = 7;
    } else if((this.form.reasonForMoveout != 'Other' && this.form.reasonForMoveout) || this.form.otherReason) {
      this.step = 5;
    } else if(this.anyChecks){
      this.step = 4;
    } else if(this.form.siteId){
      this.step = 3;
    } else if(this.form.completionDate) {
      this.step = 2
    }

    if(this.step > 1){
      this.dataService.setSelectedAmendmentSite(this.form);
      this.dataService.setShowAmendmentsHeader(true);
      this.getActiveSites();
      this.scrollToStep(2);
    }
  }

  scrollToStep(secs?: number){
    setTimeout(() => {
      if(secs){
        $("html, body").animate({ scrollTop: $('#step' + this.step).offset().top }, secs*1000);
      } else {
        $("html, body").animate({ scrollTop: $('#step' + this.step).offset().top }, 1000);
      }
    }, 50)
  }

  continue(step?: number){
    if((this.selectedSite && this.selectedSite.market != 'ERCOT') && this.step == 3) {
      this.step = 7;
    } else {
      if(step){
        this.step = step;
      } else if (this.step < 7){
        this.step++;
      }
    }
    this.scrollToStep();
  }

  changeCompletionDate() {
    this.invalidCompletionDate = false;
    if(moment(this.completionDateDate).isBefore(moment(this.minDate).startOf('day')) || moment(this.completionDateDate).isAfter(moment(this.maxDate))) {
      this.form.completionDate = null;
      this.invalidCompletionDate = true;
    } else {
      this.completionDateChanged = true;
      this.form.completionDate = moment(this.completionDateDate).format('YYYY-MM-DD');
    }
    this.save();
  }

  // His body bound and drenched in tears
  // They laid him down in Joseph's tomb
  // The entrance sealed with heavy stone
  // Messiah still, and all alone

  // Site Selection /////////////////////////////////////////////////////////////////////////////////////////////

  siteRadioChanged(){
    this.sitesForAccount.forEach((site) => {
      if(site.siteId == this.selectedSiteId){
        this.selectedSite = site;
      }
    });
    if(this.selectedSite.serviceAddress){
      this.form.siteAddress = this.selectedSite.serviceAddress.streetAddress;
      this.form.siteCity = this.selectedSite.serviceAddress.city;
      this.form.siteState = this.selectedSite.serviceAddress.state;
      this.form.siteZip = this.selectedSite.serviceAddress.zipCode;
    }
    this.form.siteId = this.selectedSite.siteId;
    this.form.market = this.selectedSite.market;
    this.form.tdsp = this.selectedSite.utilityCode;
    this.form.tdspName = this.selectedSite.utility;
    if(this.selectedSite.market == 'ERCOT') {
      this.step = 2;
    }
    this.save();
    this.getContractsForSite(true);
  }

  filterSites() {
    if(!this.searchTerm){
      this.filteredSites = this.sitesForAccount;
    } else {
      this.filteredSites = this.sitePipe.transform(this.sitesForAccount, this.searchTerm);
    }
    this.paginate(1);
  }

  paginate(page: number) {
    if((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager){
      this.pager = this.pagerService.getPage(this.filteredSites, page);
    }
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow
    this.searchTerm = '';
    this.filterSites();
  }

  // And on the third at break of dawn
  // The Son of Heaven rose again
  // O trampled death where is your sting?
  // The angels roar for Christ the King!

  // Contract / Product Selection ////////////////////////////////////////////////////////////////////////////////

  getContractsForSite(checkAll?: boolean) {
    this.amendmentsService.getContractsForSite(this.selectedSiteId, this.form.completionDate).subscribe((contracts : any) => {
      this.contractsForSite = contracts;
      if(this.contractsForSite && this.contractsForSite.length > 0){
        this.contractsForSite.forEach((contract) => {
          this.contractsForAccount.forEach((accountContract) => {
            if(contract.contractNum == accountContract.contractNum){
              contract.deliveryStartDate = accountContract.deliveryStartDate;
              contract.deliveryEndDate = accountContract.deliveryEndDate;
            }
          });
        });
        this.getContractDisplayDates(this.contractsForSite);
        this.contractsForSite.forEach((contract) => {
          contract.checked = true;
          contract.toggled = true;
          this.contractChecked(contract);
        });
        if(this.form.products && !checkAll){
          this.loadChecks();
          this.checkForAnyChecks();
        } else {
          this.contractsForSite.forEach((contract) => {
            contract.products.forEach((product) => {
              product.checked = true;
              let amendmentProduct = this.getAmendmentProductFromProduct(product, contract);
              this.saveProduct(amendmentProduct);
            });
          });
        }
      }
    });
  }

  getContractNickname(contractNum: string){
    return this.portalService.getContractNickname(contractNum);
  }

  getContractDisplayDates(contracts: IContract[]){
    contracts.forEach((contract) => {
      contract.deliveryStartDateDisplay = moment(contract.deliveryStartDate).format('MM/DD/YYYY');
      contract.deliveryEndDateDisplay = moment(contract.deliveryEndDate).format('MM/DD/YYYY');
      contract.products.forEach((product) => {
        product.deliveryStartDateDisplay = moment(product.deliveryStartDate).format('MM/DD/YYYY');
        product.deliveryEndDateDisplay = moment(product.deliveryEndDate).format('MM/DD/YYYY');
      });
    });
  }

  contractChecked(contract: IContract, save?: boolean) {
    setTimeout(() => {
      //Wait 0.01s for model to update before triggering other checkboxes #HACK
      let amendmentProduct;
      this.contractsForSite.forEach((contract) => {
        if (contract.contractNum == contract.contractNum) {
          contract.partialCheck = false;
          contract.products.forEach((product) => {
            amendmentProduct = this.getAmendmentProductFromProduct(product, contract);
            if(!product.checked && contract.checked && this.form.id && save) {
              this.saveProduct(amendmentProduct);
            } else if (product.checked && !contract.checked && this.form.id && save){
              this.deleteProduct(amendmentProduct);
            }
            product.checked = contract.checked;
          });
        }
      });
      this.checkForAnyChecks();
    }, 10);
  }

  checkForAnyChecks(){
    this.anyChecks = false;
    this.contractsForSite.forEach((contract) => {
      if(contract.checked){
        this.anyChecks = true;
      }
      contract.products.forEach((product) => {
        if(product.checked){
          this.anyChecks = true;
        }
      })
    })
  }

  productChecked(product, contract) {
    let amendmentProduct = this.getAmendmentProductFromProduct(product, contract);
    setTimeout(() => {
      if(product.checked) {
        this.saveProduct(amendmentProduct);
      } else {
        this.deleteProduct(amendmentProduct);
      }
      this.getPartialChecks();
    }, 10);
  }

  saveProduct(product: IAmendmentProduct){
    this.amendmentsService.saveProduct(this.form, product).subscribe((resp) => {
      this.checkForCompletedForm();
      this.save();
    });
  }

  deleteProduct(product: IAmendmentProduct){
    this.amendmentsService.deleteProduct(this.form, product).subscribe((resp) => {
      this.checkForCompletedForm();
      this.save();
    });
  }

  checkForCompletedForm(){
    this.validateForm();
    if(!this.completionError) {
      this.form.complete = true;
    } else {
      this.form.complete = false;
    }
  }

  getAmendmentProductFromProduct(product: IProduct, contract: IContract){
    let amendmentProduct = product as any;
    amendmentProduct.amendmentSiteId = this.form.id;
    amendmentProduct.contractId = contract.contractNum;
    amendmentProduct.productId = product.id;
    amendmentProduct.productName = product.name;
    amendmentProduct.deliveryStartDate = product.deliveryStartDate;
    amendmentProduct.deliveryEndDate = product.deliveryEndDate;
    return amendmentProduct;
  }

  loadChecks() {
    // Get checkboxes
    this.contractsForSite.forEach((contract) => {
      contract.checked = false;
      contract.products.forEach((product) => {
        product.checked = false;
        this.form.products.forEach((amendmentProduct) => {
          if(product.id == amendmentProduct.productId) {
            product.checked = true;
            this.productChecked(product, contract);
          }
        });
      })
    });
    this.getPartialChecks();
  }

  getPartialChecks() {
    this.contractsForSite.forEach((contract)=>{
      contract.partialCheck = false;
      let checkedCount = 0;
      contract.products.forEach((product)=>{
        if(product.checked) {
          contract.partialCheck = true;
          contract.checked = true;
          checkedCount++;
        }
        if(checkedCount === contract.products.length) {
          contract.partialCheck = false;
        } else if(checkedCount == 0){
          contract.checked = false;
        }
      });
    });
    this.checkForAnyChecks();
  }

  // Reason for Move Out
  toggleRaisins() {
    this.showRaisins = !this.showRaisins;
  }

  selectRaisin(raisin: string){
    this.form.reasonForMoveout = raisin;
    if(raisin != 'Other'){
      this.form.otherReason = '';
      this.continue();
    }
    this.validateForm();
    this.save();
    this.toggleRaisins();
  }


  continueToCart(){
    this.validateForm();
    if(!this.completionError){
      this.form.complete = true;
      this.save();
      this.router.navigate(['/amendments/cart']);
    }
  }

  validateForm() {
    this.completionError = null;
    if(this.form.completionDate) {
      // Step 1 validated
      if(this.form.siteId) {
        // Step 2 validated
        if(this.anyChecks) {
          // Step 3 validated
          if((this.form.reasonForMoveout || this.form.otherReason) || this.selectedSite.market != 'ERCOT') {
            // Step 4 validated
            if(this.form.physicallyRemove != undefined || this.selectedSite.market != 'ERCOT') {
              // Step 5 validated;
              this.completionError = '';
            } else {
              this.completionError = 'Please ensure you\'ve entered all required contact information.';
            }
          } else {
            this.completionError = 'Please select a reason for moveout.';
          }
        } else {
          this.completionError = 'Please select a product to delete';
        }
      } else {
        this.completionError = 'Please select a site ID.';
      }
    } else {
      this.completionError = 'Please select a request date.';
    }
  }

  selectState(state: string) {
    this.form.contactState = state;
    this.save();
  }
}
