import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {DataService} from "./../../shared/data.service";
import {Stage, Dev} from "./../../shared/const/properties";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-email-password',
  templateUrl: './email-password.component.html',
  styleUrls: ['./email-password.component.scss']
})
export class EmailPasswordComponent implements OnInit {

  appProperties: any;
  routeSubscription: Subscription;
  brand: string;

  constructor(private router: Router,
              private dataService: DataService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.brand = params['brand'];
      // console.log("this.brand: ", this.brand);
    });
  }

  getOriginUrl() : string{
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com/myaccount";
    const stg1 = "https://stg1-manage.nrg.com/myaccount";
    const test1 = "https://test1-manage.nrg.com/myaccount";
    const pd = "https://manage.nrg.com/myaccount";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
      return dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return pd;
    }
    else {
      return local;
    }
  }

  loginScreen(){
    this.appProperties = this.dataService.getAppProperties();
    this.dataService.setEmailPassword(false);

    window.location.replace(this.getOriginUrl() + "/login.html");
    }

}
