import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IProductSelectDefaultSettings } from '../shared/entities/productSelectDefaultSettings';

@Injectable()
export class SelectProductService {
  headers: HttpHeaders;
  constructor(private http: HttpClient) { }

  getDefault(accountId: string): Observable<IProductSelectDefaultSettings> {
    let url = environment.productSelectDefaultUrl.replace('{accountId}', accountId.toString());
    return this.http
    .get(url).pipe(
      map((data: IProductSelectDefaultSettings)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  setDefault(request: IProductSelectDefaultSettings) {
    let url = environment.productSelectDefaultUrl.replace('{accountId}', request.accountId.toString());
    return this.http
    .post(url, request, { headers: this.headers })
    .map((response: HttpResponse<IProductSelectDefaultSettings>) => response)
    .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}


// hedge volume price service