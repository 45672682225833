import { Component, OnInit, Input } from '@angular/core';
import { FourcpService } from '../../fourcp.service';
import * as moment from 'moment';
import { I4cpPreferences, I4cpAlertHistory } from '../../../shared/entities/fourcp';
import { DataService } from '../../../shared/data.service';
import { IHelpStep } from '../../../shared/entities/contextualHelp';
import { Subscription } from 'rxjs';
declare var Highcharts: any;

@Component({
  selector: 'app-fourcp-summary',
  templateUrl: './fourcp-summary.component.html',
  styleUrls: ['../../fourcp.component.scss', 'fourcp-summary.component.scss']
})
export class FourcpSummaryComponent implements OnInit {

  preferences: I4cpPreferences;
  alertHistory: I4cpAlertHistory | I4cpAlertHistory;
  startDate: string;
  year: number;
  month: number;
  chartData: any;
  pastMonth: boolean;
  inSeason = false;
  helpStep: IHelpStep;

  helpStepSubscription: Subscription;

  @Input()
  alertHistoryInput: I4cpAlertHistory | I4cpAlertHistory;

  @Input()
  historicalView: boolean;

  ngOnChanges(changes) {
    if (changes['alertHistoryInput'] && this.alertHistoryInput) {
      this.alertHistory = this.alertHistoryInput;
      this.extractChartData();
      this.createChart();
    }
  }

  preferencesSubscription: Subscription;

  constructor(private fourcpService: FourcpService,
    private dataService: DataService) { }

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    if ((moment().month() >= 5 && moment().month() <= 8) || (moment().month() == 4 && (+moment().format('DD')) >= 26)) {
      this.inSeason = true;
    }
    this.startDate = this.getStartDate();
    this.preferences = this.dataService.getFourcpPreferences();
    if (this.preferences) {
      this.getAlertHistory();
    }
    this.preferencesSubscription = this.dataService.fourcpPreferencesUpdated.subscribe((preferences) => {
      this.preferences = preferences;
      this.getAlertHistory();
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.preferencesSubscription) this.preferencesSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  getAlertHistory() {
    if (!this.historicalView) {
      this.fourcpService.get4cpAlertHistory(this.startDate, this.preferences.subscriptionType).subscribe((alertHistory) => {
        this.alertHistory = Object.assign({}, alertHistory);
        this.alertHistory.peaks = [];
        alertHistory.peaks.forEach((peak) => {
          // if(moment(peak.date).month() <= moment().month()) {
          this.alertHistory.peaks.push(peak);
          // }
        });
        this.extractChartData();
        this.createChart();
      });
    }
  }

  getStartDate() {
    let start = moment();

    // if(start.format('D') == '1' || (start.format('D') == '2' && parseInt(start.format('H')) < 7)) {
    // Before the 2nd of the month @ 7am
    //  start = start.subtract(1, 'month');
    // }
    start = start.startOf('month');
    const startDate = start.format('YYYY-MM-DD');
    return startDate;
  }

  getStartDateWithYearMonth(year: number, month: number) {
    this.startDate = year + '-' + month + '-01';
  }

  extractChartData() {
    this.chartData = [];
    let point = {} as any;
    const lastPoint = this.alertHistory.peaks[this.alertHistory.peaks.length - 1];
    this.pastMonth = moment(lastPoint.date).isBefore(moment());
    this.alertHistory.peaks.forEach((peak) => {
      point = {
        x: moment(peak.date).toDate().getTime(),
        y: peak.actualMW
      };
      if (peak.mtdMaxLoad) {
        point.className = 'blue-bar';
      }
      if (this.preferences.subscriptionType == 'PREMIUM') {
        if (moment(peak.date).month() == 4 && !point.y && peak.forecastMW) {
          point.y = peak.forecastMW;
          point.className = 'darkgray-bar';
        }
        else {
          if (!point.y && peak.forecastMW) {
            point.y = peak.forecastMW;
            if (peak.forecastAlertType == 'HIGH') {
              point.className = 'red-bar';
            } else if (peak.forecastAlertType == 'MEDIUM') {
              point.className = 'yellow-bar';
            } else if (peak.forecastAlertType == 'LOW') {
              point.className = 'green-bar';
            }
          }
        }
      }
      else {
        if (!point.y && peak.forecastMW) {
          point.y = peak.forecastMW;
          point.className = 'darkgray-bar';
        }
      }
      if (!this.inSeason && moment(peak.date).isAfter(moment())) {
        // Remove forecasted "nulls" if out of season
        peak = null;
      }
      if (peak) {
        this.chartData.push(point);
      }
    });
  }

  createChart() {
    let chart = {} as any;
    chart = 
    Highcharts.chart('summaryChart', {
      chart: {
        type: 'column',
      },
      exporting: { enabled: false },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'DEMAND (MW)'
        }
      },
      xAxis: {
        tickInterval: 86400000,
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b %e'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y: ,0.0f} MW</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        name: 'Demand',
        showInLegend: false,
        data: this.chartData
      }]
    });
    
    let mwValues = this.alertHistory.peaks.filter(f => f.actualMW !=0 || f.forecastMW !=0)
                                    .map(f => f.actualMW === null? f.forecastMW : f.actualMW)
                                    .sort();    
    let minY = Math.floor(mwValues[0]/1000)*1000;
    let maxY = Math.ceil(mwValues[mwValues.length-1]/1000)*1000;

    chart.yAxis[0].setExtremes(minY,maxY);
  }

}
