import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {IUserAccount} from "../../shared/entities/profile";
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {ContactService} from "../../contact/contact.service";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {IAmendmentRequest} from "../../shared/entities/amendments";
import {AmendmentsService} from "../amendments.service";
import {IAccount} from "../../shared/entities/account";


@Component({
  selector: 'app-amendments-start',
  templateUrl: './amendments-start.component.html',
  animations: [FadeInAnimation],
  styleUrls: ['amendments-start.component.scss', '../amendments.component.scss']
})
export class AmendmentsStartComponent implements OnInit {

  step = 1;
  myAccount: IUserAccount;
  myContractsAccount: IAccount;

  accountRep: string;
  selectedAmendmentRequest: IAmendmentRequest;
  formPath: string;

  startBoolean: boolean = false;
  thirdParty: boolean = false;
  markets: string[] = [];
  northEastOrNational: boolean;

  accountSubscription: Subscription;

  constructor(private router: Router,
              private dataService: DataService,
              private contactService: ContactService,
              private amendmentService: AmendmentsService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Submit Request ');
    this.dataService.setSelectedNavItem('accounts');

    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
      this.getAccountRep();
      this.thirdParty = this.myAccount.accessType == 'ThirdParty';
      // this.accountUpdated();
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goHome();
    });

    this.selectedAmendmentRequest = this.dataService.getSelectedAmendmentRequest();
    if(!this.selectedAmendmentRequest){
      this.router.navigate(['/amendments/home']);
    }

    this.markets = this.dataService.getAccountWithContractDataSource().markets;
    this.northEastOrNational = this.markets.length > 1 || this.markets[0] != 'ERCOT';
  }

  ngOnDestroy(){
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
  }

// accountUpdated() {
//     // console.log('inside finding status');
//     this.dataService.setLoading(true);
//     this.amendmentService.getContractsForAccount(this.myAccount.id).subscribe(
//       (mycontractsaccount) => {
//         this.myContractsAccount = mycontractsaccount;
//         this.dataService.setAccountWithContractDataSource(mycontractsaccount);
//         if(this.myContractsAccount.contracts){
//           this.myContractsAccount.contracts.forEach((contract) => {
//             if(contract.status == 'Active' || contract.status == 'Future Start'){
//               // console.log('status' + contract.status);
//                this.startBoolean = true;
//             }
//           });
//         }
//       }
//     );
// }


  getAccountRep(){
    this.contactService.getContactsForAccount(this.myAccount.id,this.thirdParty).subscribe((contacts) => {
      this.accountRep = contacts.specialist[0].name;
    });
  }

  goBack(){
    if(this.step == 1){
      this.goHome();
    } else {
      this.step--;
    }
  }

  goHome() {
    this.router.navigate(['/amendments/home']);
  }

  navigateToAddForm(){
    if(this.selectedAmendmentRequest){ // to not break local
      this.selectedAmendmentRequest.requestType = 'ADD';
      this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    }
    this.dataService.setSelectedAmendmentSite(undefined);
    this.formPath = '/amendments/add';
    this.navigateToForm();
  }

  navigateToForm(){
    this.amendmentService.saveNewAmendmentRequest(this.selectedAmendmentRequest).subscribe((response : any) => {
      this.selectedAmendmentRequest.id = response.id;
      this.dataService.setSelectedAmendmentRequest(response);
      this.router.navigate([this.formPath]);
    });
    // console.log('app start');
    // console.log(this.selectedAmendmentRequest.id);

    // if(!this.dataService.getAppProperties()){
    //   // Local
    //   this.selectedAmendmentRequest.id = 0;
    //   this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    //   this.router.navigate([this.formPath]);
    // }
  }

  goToAdd(){
    if(this.selectedAmendmentRequest){ // to not break local
      this.selectedAmendmentRequest.requestType = 'ADD';
      this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    }
    this.dataService.setSelectedAmendmentSite(undefined);
    this.formPath = '/amendments/add';
    //this.step = 2;
  }

  goToDelete(){
    if(this.selectedAmendmentRequest){ // to not break local
      this.selectedAmendmentRequest.requestType = 'DELETE';
      this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    }
    this.dataService.setSelectedAmendmentSite(undefined);
    this.formPath = '/amendments/delete';
    this.navigateToForm();
  }

  goToBoth(){
    this.selectedAmendmentRequest.requestType = 'ADD_DELETE';
    this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    //TODO: update formPath to ADD_DELETE Page? Nope. This button has *exact same behavior* as add
    this.formPath = '/amendments/add';
    this.step = 2;
  }

    submitRequest(){
    this.amendmentService.submitRequest(this.selectedAmendmentRequest);
    //TODO: update selectAmendmentRequest
  }


}
