import { Component, OnInit } from "@angular/core";
import { GenericPipe } from '../shared/pipe/generic.pipe';
import { DataService } from "../shared/data.service";
import { Observable } from "rxjs";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { IContract } from "../shared/entities/contract";
import {IProduct, IZone} from "../shared/entities/product";
import { PortalService } from "../shared/portal.service";
import { PositionService } from "./position.service";
import { IUserAccount } from "../shared/entities/profile";
import { FadeAnimation } from "../animations/fadeAnimation";
import { IMonthData, ILayersList } from "./month-breakdown";
import { IRiskLegAttribute, IRampInOut } from "../shared/entities/position";
import {ForwardPriceForm} from "../markets/forward-price-form/forward-price.form";
import { AccordionAnimation } from "../animations/accordionAnimation";
import { Authority } from "../shared/const/authority";
declare var Highcharts: any;
declare var $: any;

@Component({
  selector: "app-position",
  templateUrl: "./position.component.html",
  animations: [ FadeAnimation, AccordionAnimation ],
  styleUrls: ["./position.component.scss"],
  providers: [GenericPipe]

})
export class PositionComponent implements OnInit {

  CHART: any;
  objectKeys = Object.keys;
  searchTerm: string = '';
  showSearchRow: boolean = false;
  // filteredTable: 

  //Graph Data
  isSuperUser: boolean = false;
  actualData: any[] = [];
  forecastData: any[] = [];
  blockData: any[] = [];
  indexData: any[] = [];
  BMQData: any[] = [];
  mockData: any[] = [];
  series: any[] = [];
  verticalLine: object;
  intervalDetailsData: object = {};
  currentIntervalDetails: IRiskLegAttribute[];
  showIntervalDetails: boolean = false;
  colors: string[] = [ "#18AAFD", "#0A38D9", "#EC008C", "#000000"];
  noContractError: boolean = false;
  noDataError: boolean = false;
  isDayLightSavings: boolean = false;

  //Dates
  account: IUserAccount;
  selectedStartDate: Date;
  selectedEndDate: Date;
  showDateRange: boolean = false;
  showDateRangeError: boolean = false;
  dateRangeDisplay: string;

  minDate: Date;
  maxDate: Date;
  errorMessage: string;

  showLayers: boolean = false;
  expanded: boolean = true;

  tradeRateData: any;
  buttonLabel: string = "Play";
  buttonTitle: string = "play";
  maxRange = 36;
  rangeInput = 0;
  maskModeSubscription: Subscription;
  contractSubscription: Subscription;
  actualForecastBlockIndexSubscription: Subscription;
  exportTriggeredSubscription: Subscription;
  bmqSubscription: Subscription;
  maskMode: boolean;
  contractsHidden: boolean = false;
  contracts: IContract[];
  selectedContract: IContract = null;
  selectedProduct: IProduct = null;
  numberOfLayers: number = 0;
  totalLayers: number = 0;
  currentIntervalDateDisplay: string;
  currentIntervalTimeDisplay: string;
  layers: ILayersList = defaultLayers;
  summaryTableData: IMonthData[] = [];
  filteredTableData: IMonthData[] = [];
  rampInOut: IRampInOut[] = [];
  
  mockIntervalDetails: IRiskLegAttribute[] = [
    {
      meterLoad: "8000",
      risklegType: "Fixed",
      fixedPrice: "35.00"
    },
    {
      meterLoad: "8000",
      risklegType: "Heat Rate",
      fixedPrice: "36.12"
    },
    {
      meterLoad: "8000",
      risklegType: "Fixed",
      fixedPrice: "33.85"
    },
    {
      meterLoad: "8000",
      risklegType: "Fixed",
      fixedPrice: "34.90"
    }
  ]

  contractSorter = function mySorter(val1, val2) {
    if (val1.deliveryEndDate == val2.deliveryEndDate) {
      return val1.deliveryStartDate < val2.deliveryStartDate ? 1 : -1;
    } else {
      return val1.deliveryEndDate < val2.deliveryEndDate ? 1 : -1;
    }
  };

  contractFilter = (contract: IContract) => {
    return contract.commodity == true 
      && (contract.contractType !== 'Transition' || contract.status !== 'Terminated')
      && moment(contract.deliveryEndDate).isSameOrAfter(moment().subtract(6, 'months'))
      && contract.products.length > 0
      && contract.market == 'ERCOT';
  }

  mapRecentProducts = (contract: IContract) => {
    const newContract = {...contract} as IContract;
    newContract.products = contract.products.filter(product => moment(product.deliveryEndDate).isAfter(moment().subtract(6, 'months')));
    return newContract;
  }

  constructor(
    private dataService: DataService,
    private portalService: PortalService,
    private positionService: PositionService,
    private genericPipe: GenericPipe
  ) {}

  ngOnInit() {
    this.dataService.setTitleSource("Position Report");
    this.dataService.setSelectedNavItem("tools");
    this.setCurrentActivePage('accounts');
    this.account = this.dataService.getAccountSource();
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.dataService.setPositionReportDate(new Date());
    if (this.isSuperUser) {
      if (this.dataService.getContractsForAccount()) {
        this.contracts = this.dataService
          .getContractsForAccount()
          .sort(this.contractSorter)
          .map(this.mapRecentProducts)
          .filter(this.contractFilter);
        if (this.contracts.length > 0) {
          this.selectContract(this.contracts[0]);
          this.noContractError = false;
        } else {
          this.dateRangeDisplay = '--';
          this.noContractError = true;
        }
      }
  
      this.exportTriggeredSubscription = this.dataService.exportTriggered.subscribe(() => {
        this.dataService.setLoading(true);
        this.exportGraph()
      });
  
      this.contractSubscription = this.dataService.contractsForAccountSourceUpdated.subscribe(
        contracts => {
          this.contracts = contracts
            .sort(this.contractSorter)
            .map(this.mapRecentProducts)
            .filter(this.contractFilter);
          if (this.contracts.length > 0) {
            this.selectContract(this.contracts[0]);
            this.noDataError = false;
          } else {
            this.dateRangeDisplay = '--';
            this.noDataError = true;
          }
        }
      );
      this.maskMode = this.dataService.getMaskMode();
      this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
        maskMode => {
          this.maskMode = maskMode;
        }
      );
  
      // Listening when the user clicks outside of the dropdown
      document.addEventListener("click", ($event) => {
        if($($event.target).parents('#layerSelection').length == 0){
          this.showLayers = false;
        }
      });
    }
  }


  ngOnDestroy() {
    this.dataService.setContextualHelp(true);
    if (this.exportTriggeredSubscription) this.exportTriggeredSubscription.unsubscribe();
    if (this.actualForecastBlockIndexSubscription) this.actualForecastBlockIndexSubscription.unsubscribe();
    if (this.bmqSubscription) this.bmqSubscription.unsubscribe();
    if (this.contractSubscription) this.contractSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
  }

  applyDateRange() {

    this.showDateRangeError = false;    
    this.minDate = moment().subtract(2, 'years').toDate();
    this.maxDate =  moment(this.selectedProduct.deliveryEndDate).add(1, 'M').toDate();

   

    if(moment(this.selectedEndDate).isBefore(this.selectedStartDate))
      {
        this.errorMessage = 'End Date is Greater than the Start Date' ;
        this.showDateRangeError = true;
      }


    if(moment(this.selectedStartDate).isBefore(this.selectedProduct.deliveryStartDate))
      {
        this.minDate =moment(this.selectedProduct.deliveryStartDate).toDate();
      }

    if(moment(this.selectedStartDate).isBefore(this.minDate))
      {        
        this.errorMessage = ' Date selected is prior to the minimum start date allowed. Please enter a date greater than ' + moment(this.minDate).format('LL')
        this.showDateRangeError = true;
        console.log('date test max',this.errorMessage);
      }

   if(moment(this.selectedEndDate).isAfter(this.maxDate))
      {
       this.errorMessage = '  Date selected exceeds the maximum start date allowed. Please enter an end date prior to ' + moment(this.maxDate).format('LL')
       this.showDateRangeError = true;
      }

      if((Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days')) > 190)){
        this.errorMessage = 'Range between start and end date exceeds maximum range of 6 Months'
        this.showDateRangeError = true;
    }

    if(this.showDateRangeError) {
    }
    else{
    this.toggleDateRange();
    this.dateRangeDisplay = `${moment(this.selectedStartDate).format("MM/DD/YYYY")} - ${moment(this.selectedEndDate).format("MM/DD/YYYY")}`;
    this.populateGraphData();
    }
  }

  toggleLayers() {
    this.showLayers = !this.showLayers;
    this.showDateRange = false;
  }

  applyLayers() {
    // TODO: Redraw graph
    this.toggleLayers();
    this.numberOfLayers = Object.keys(this.layers).reduce(this.reduceLayers, 0);
    this.getSeries();
  }

  getMomentFromPoint(point) {
    return moment(
      moment(point.date)
        .minute(point.intervalId * 15 - 15)
        .format("MM/DD/YY HH:mm:SS")
    );
  }

  reduceLayers = (accum, current) => {
    if (this.layers[current].selected && this.layers[current].hasData) return accum + 1
    else return accum
  }

  selectLayer(layer: string) {
    this.layers[layer].selected = !this.layers[layer].selected;
  }

  /**
   * Starts the API chain when the user selects a new Contract/Product selection or date range update.
   * 
   * Link to learn more about Promise.all: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all
   */
  populateGraphData() {
    this.dataService.setLoading(true);
    let startDate = moment(this.selectedStartDate).format("YYYY-MM-DD");
    let endDate = moment(this.selectedEndDate).format("YYYY-MM-DD");
    let capeProductFlg = this.selectedProduct.name === 'Block & Index - IDR Only' ? 'Y' : 'N';
    let request = {
      contractId: this.selectedContract.contractNum,
      productId: this.selectedProduct.id,
      productName: this.selectedProduct.name,
      accountId: this.account.id,
      startDate: startDate,      
      endDate: endDate,
      capeProductFlg: capeProductFlg
    };
    
    // Reset data. Future TODO: Add logic to not call backend api again if the new range is within the old date range
    this.verticalLine = {};
    this.layers = defaultLayers;
    this.actualData = this.forecastData = this.blockData = this.BMQData = this.indexData = [];
    this.isDayLightSavings = false, this.noDataError = false;

    Promise.all([
      this.getActualForecastBlockData(request),
      this.getBMQContractData(request)
    ]).then(value => {
      this.checkDataExist();
      this.dataService.setLoading(false);
    }).catch(err => {
      this.checkDataExist();
      this.dataService.setLoading(false);
    });
  }

  checkDataExist() {
    if (this.layers.actual.hasData || this.layers.forecast.hasData || this.layers.bmq.hasData || this.layers.block.hasData) {
      this.noDataError = false;
      this.getSeries();
    } else {
      this.noDataError = true;
    }
  }

  /**
   * Maps the API response for Actual data. Long/short day is calculated here by counting the length based off of months.
   * @param data 
   */
  mapActualData(data: any[]) {
    let previousDate = '', counter = 0;
    this.actualData = data.map(ele => {
      if (ele.date === previousDate && !this.isDayLightSavings) {
        counter += 1;
      } else if (ele.date !== previousDate && !this.isDayLightSavings) {
        if (counter == 25 || counter == 23) {
          this.isDayLightSavings = true;
        }
        previousDate = ele.date;
        counter = 0;
      }
      return [moment(ele.date).add(ele.loadhour - 1, 'hours').valueOf(), ele.demand];
    });
  }

  /**
   * Maps the API response for Forecast data. Long/short day is calculated here by counting the length based off of months.
   * @param data 
   */
  mapForecastData(data: any[]) {
    let previousDate = '', counter = 0;
    this.forecastData = data.map(ele => {
      if (ele.date === previousDate && !this.isDayLightSavings) {
        counter += 1;
      } else if (ele.date !== previousDate && !this.isDayLightSavings) {
        if (counter == 25 || counter == 23) {
          this.isDayLightSavings = true;
        }
        previousDate = ele.date;
        counter = 0;
      }
      return [moment(ele.date).add(ele.loadhour - 1, 'hours').valueOf(), ele.demand];
    });
  }

  
  exportGraph() {
    let startDate = moment(this.selectedStartDate).format("YYYY-MM-DD");
    let endDate = moment(this.selectedEndDate).format("YYYY-MM-DD");
    let capeProductFlg = this.selectedProduct.name === 'Block & Index - IDR Only' ? 'Y' : 'N';
    let congestionZones = this.getZoneDisplay(this.selectedContract.market, this.selectedProduct.congestionZones);
    let productTerm = this.getDateDisplay(this.selectedProduct.deliveryStartDate) + '-' + this.getDateDisplay(this.selectedProduct.deliveryEndDate);
    let request = {
      contractId: this.selectedContract.contractNum,
      productId: this.selectedProduct.id,
      accountId: this.account.id,
      accountName: this.account.name,
      startDate: startDate,
      endDate: endDate,
      capeProductFlg: capeProductFlg,
      market: this.selectedContract.market,
      contractNickname: this.selectedContract.nickName,
      congestionZones: congestionZones,
      productName: this.selectedProduct.name,
      productTerm: productTerm, 
      siteCount: this.selectedProduct.siteCount
    };
     this.positionService.exportPositionGraph(request);
  }

  /**
   * Decided to group the Actual, Forecast, and Block due to all need to work together on the backend to determine additional
   * information. layers...hasData is for the graph to know the set of data is available to allow the user to toggle display.
   * 
   * @param request let request = {
      contractId: this.selectedContract.contractNum,
      productId: this.selectedProduct.id,
      accountId: this.account.id,
      startDate: startDate,      
      endDate: endDate,
      capeProductFlg: capeProductFlg
    };
   */
  getActualForecastBlockData(request) {
    return new Promise((resolve, reject) => {
      this.actualForecastBlockIndexSubscription = this.positionService.getActualAndForecast(request).subscribe(
        response => {
          // Store data to actual and forecast
          this.mapActualData(response.actual);
          this.mapForecastData(response.forecast);
          // console.log(this.selectedProduct.name);
          if (this.selectedProduct.name == "Monthly Gas Index") {
            //console.log('Hello monthly');
          }
          else
         {
            this.mapBlockTransactionsData(response.block.forecast);
         }
          this.indexData = response.yearMonthIndex;
          this.layers.actual.hasData = this.actualData.length > 0;
          this.layers.forecast.hasData = this.forecastData.length > 0;
          this.layers.block.hasData = this.blockData.length > 0;
          this.populateTableData(response.actual, response.forecast, response.block.forecast);

          resolve(null);
        },
        err => {
          resolve(err);
        }
      );
    });
  }

  /**
   * Similar to other map functions. Determines if there is long/short day involved as the application is uncertain if all, one or any
   * returns with data. 
   * 
   * this.intervalDetailsData[date.valueOf()] is set by reading the risklegAttributes for the interval detail section that appears on the
   * right if the user toggles.
   * @param data 
   */
  mapBlockTransactionsData(data: any[]) {
    let result = [];
    let previousDate = '';
    let counter = 0;
    data.forEach(ele => {
      if (ele.date === previousDate && !this.isDayLightSavings) {
        counter += 1;
      } else if (ele.date !== previousDate && !this.isDayLightSavings) {
        if (counter == 25 || counter == 23) {
          this.isDayLightSavings = true;
        }
        previousDate = ele.date;
        counter = 0;
      }
      let date = moment(ele.date).add(ele.loadHour - 1, 'hours');

      result.push([date.valueOf(), ele.forecastOrActualBlock]);
      this.intervalDetailsData[date.valueOf()] = ele.risklegAttributes.filter(riskleg => riskleg.meterLoad != 0 && riskleg.fixedPrice != 0) || [];
    });

    this.blockData = result;
  }

  /**
   * Makes a API call for BMQ. Must iterate through the response to determine long/short day.
   * Promise resolves when the map has finished. The failed, throw the error back to Promise.all
   * @param request let request = {
      contractId: this.selectedContract.contractNum,
      productId: this.selectedProduct.id,
      accountId: this.account.id,
      startDate: startDate,      
      endDate: endDate,
      capeProductFlg: capeProductFlg
    };
   */
  getBMQContractData(request) {
    return new Promise((resolve, reject) => {
      this.bmqSubscription = this.positionService.getBMQContract(request).subscribe(
        response => {
          let previousDate = '';
          let counter = 0;

          this.BMQData = response.map(ele => {
            if (ele.bmqDate === previousDate && !this.isDayLightSavings) {
              counter += 1;
            } else if (ele.bmqDate !== previousDate && !this.isDayLightSavings) {
              if (counter == 25 || counter == 23) {
                this.isDayLightSavings = true;
              }
              previousDate = ele.bmqDate;
              counter = 0;
            }
            return [
              moment(ele.bmqDate).add(ele.interval - 1, 'hours').valueOf(),
              ele.bmqVolume
            ]
          }).sort((a, b) => a[0] - b[0]); // Sort ascending
          
          this.layers.bmq.hasData = this.BMQData.length > 0;
          resolve(null);
        },
        err => {
          resolve(err);
        }
      );
    });
  }

  /**
   *  Logic for getting the various series needed for the graph. This sets the defaults for what will be rendered
   *  on the graph.
   * 
    return [{
      name: 'Demand (KW)',
      showInLegend: true,
      type: 'line',
      data: this.graphData.points,
      turboThreshold: 3000
    },{
      name: 'Hedge',
      showInLegend: true,
      type: 'line',
      data: this.graphDataBlock.points,
      turboThreshold: 3000,
      step: 'left',
    }]

  
    if (Array.isArray(this.mockData) && this.mockData.length > 0) {
      tempSeries = [...tempSeries, {
        name: 'Mock',
        showInLegend: true,
        type: 'line',
        data: this.mockData,
        turboThreshold: 3000
      }]
    } 
  */
  
  getSeries() {
    this.rampInOut = this.setRampInRampOut();
  
    let tempSeries = [
      {
        name: "Actual Demand (KW)",
        showInLegend: this.actualData.length > 0 && this.layers.actual.selected,
        color: "#18AAFD",        
        type: "area",
        step: "left",
        data: this.layers.actual.selected ? this.actualData : [],
        turboThreshold: 0
      },
      {
        name: "Forecast Demand (KW)",
        showInLegend: this.forecastData.length > 0 && this.layers.forecast.selected,
        type: "area",
        step: "left",
        color: "#0A38D9",
        data: this.layers.forecast.selected ? this.forecastData : [],
        turboThreshold: 0
      },        
      {
        name: "Hedge (KW)",
        showInLegend: this.blockData.length > 0 && this.layers.block.selected,
        type: "area",
        color: "#EC008C",
        step: "left",
        data: this.layers.block.selected ? this.blockData : [],
        turboThreshold: 0
      },
      {
        name: "Initial Load Forecast (KW)",
        showInLegend: false,
        type: "line",
        color: "#000000",
        data: this.layers.bmq.selected ? this.BMQData : [],
        turboThreshold: 0
      },
      {
        name: "Initial Load Forecast (KW)",
        showInLegend: this.BMQData.length > 0 && this.layers.bmq.selected,
        type: "area",
        linkedTo: ':previous',
        color: "#000000",
        data: []
      },
      {
        name: "Ramp In/Out",
        showInLegend: this.rampInOut.length > 0,
        type: "area",
        color: "#424242",
        data: [],
        turboThreshold: 0
      }
    ];

    if (Array.isArray(this.actualData) && this.actualData.length > 0) {
      this.verticalLine = {
        value: this.actualData[this.actualData.length - 1][0]
      };
    } else if (
      Array.isArray(this.forecastData) &&
      this.forecastData.length > 0
    ) {
      this.verticalLine = {
        value: this.forecastData[0][0]
      };
    }

    this.series = tempSeries;
    this.numberOfLayers = Object.keys(this.layers).reduce(this.reduceLayers, 0);
    this.totalLayers = Object.keys(this.layers).reduce((acc, cur) => {
      if (this.layers[cur].hasData) return acc + 1;
      else return acc;
    }, 0);
    this.createGraph();
  }

  /**
   * Determines if the product start and end day is the first or last month of the term. If so, the graph should 
   * show a gray block on the graph.
   */
  setRampInRampOut(): IRampInOut[] {
    let rampArray: IRampInOut[] = [];
    if (moment(this.selectedProduct.deliveryStartDate).isSameOrAfter(this.selectedStartDate, 'month')) {
      rampArray.push({
        from: moment(this.selectedProduct.deliveryStartDate).valueOf(),
        to: moment(this.selectedProduct.deliveryStartDate).endOf('month').valueOf()
      });
    }

    if (moment(this.selectedProduct.deliveryEndDate).isSameOrBefore(this.selectedEndDate, 'month')) {
      let month = moment(this.selectedProduct.deliveryEndDate).add(1, 'M');
      rampArray.push({
        from: month.startOf('month').valueOf(),
        to: month.endOf('month').valueOf()
      });
    }

    return rampArray;
  }

  displayIntervalDetails(event) {
    this.showIntervalDetails = event.target.checked;

    // The chart redraws faster than the DOM is updated with showIntervalDetails
    setTimeout(() => this.CHART.reflow(), 100);
  }

  setBlockIntervalDetail(dateValueOf: number) {
    let current = moment(dateValueOf)
    this.currentIntervalDateDisplay = current.format('MM/DD/YYYY');
    this.currentIntervalTimeDisplay = `${current.format('HH:mm')} - ${current.add(1, 'h').format('HH:mm')}`;
    this.currentIntervalDetails = this.intervalDetailsData[dateValueOf] || [];
  }

  /**
   * Highcharts configurations is set here. Need to read the highcharts x-axis value to determine what to show on the interval details.
   * Need to understand how JS Closure works for blackHasData and mouseOver().
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures
   */
  createGraph() {
    const blockHasData = this.layers.block.hasData;
    const setBlockDetail = (value) => this.setBlockIntervalDetail(value);
    this.CHART = Highcharts.chart("positionGraph", {
      title: {
        text: ""
      },
      plotOptions: {
        series: {
          marker: {
            symbol: 'square',
          },
          point: {
            events: {
              mouseOver: function () {
                if(blockHasData) {
                  setBlockDetail(this.x);
                }
              }
            }
          }
        }
      },
      xAxis: {
        type: "datetime",
        labels: {
          rotation: -45,
          formatter: function() {
            return moment(this.value).format('MM/DD/YYYY HH:mm')
          }
        },
        dateTimeLabelFormats: {
          second: "%Y-%m-%d<br/>%H:%M:%S",
          minute: "%Y-%m-%d<br/>%H:%M",
          hour: "%Y-%m-%d<br/>%H:%M",
          day: "%Y<br/>%m-%d",
          week: "%Y<br/>%m-%d",
          month: "%Y-%m",
          year: "%Y"
        },
        plotLines: Object.keys(this.verticalLine).length > 0 ? [this.verticalLine] : undefined,
        plotBands: this.rampInOut
      },
      chart: {
        zoomType: "x",
      },
      colors: this.colors,
      legend: {
        align: "right",
        verticalAlign: "top",
        layout: "horizontal"
      },
      yAxis: {
        title: {
          text: "Demand (KW)"
        },
        labels: {
          formatter: function() {
            return this.axis.defaultLabelFormatter.call(this);
          }
        }
      },
      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient2: {
          tagName: 'linearGradient',
          id: 'gradient-2',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient3: {
          tagName: 'linearGradient',
          id: 'gradient-3',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      tooltip: {
        shared: true,
        crosshairs: {
          width: 2,
          color: 'black',
        },
        formatter: function() {
          let date = moment(this.x);
          let nextDate =  moment(this.x).add(1, 'hours');
          let str =
            "<b>" + date.format("ddd, MMM Do") + "</b>" + "<br/>"
            + date.format("HH:mm") + ' - ' + nextDate.format("HH:mm") + " CST" 
            + (nextDate.isSame(date, 'hour') ? '*' : '') + "<br/>";
          $.each(this.points, function(i, point) {
            str +=
              '<span style="color:' +
              point.series.chart.options.colors[point.series.colorIndex] +
              '">\u25CF</span>' + point.series.name + ': ';
            if (point.series.name.includes("Initial Load")) {
              str += Math.round(point.y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              str += point.y.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            str += " KW<br/>";
          });
          return str;
        }
      },
      series: this.series
    });
  }

  public getDateDisplay(dateString: string) {
    let dateStr = this.portalService.getDateDisplay(dateString);
    if (dateStr && dateStr.includes("9999")) {
      dateStr = "";
    }
    return dateStr;
  }

  public setDates() {
    let todayMinusStart = moment().subtract(4, 'M');
    let todayPlusEnd = moment().add(2, 'M');
    let productStart = moment(this.selectedProduct.deliveryStartDate);
    let productEnd = moment(this.selectedProduct.deliveryEndDate).add(1, 'M');

    this.selectedStartDate = todayMinusStart.isBefore(productStart) ? productStart.toDate() : todayMinusStart.toDate();
    this.selectedEndDate = todayPlusEnd.isBefore(productEnd) ? todayPlusEnd.toDate() : productEnd.toDate();

    if (moment(this.selectedStartDate).isSame(productStart)) {
      let dateCalc = moment(productStart).add(6, 'M');
      this.selectedEndDate = dateCalc.isBefore(productEnd) ? dateCalc.toDate() : productEnd.toDate();
    }

    this.dateRangeDisplay = `${moment(this.selectedStartDate).format("MM/DD/YYYY")} - ${moment(this.selectedEndDate).format("MM/DD/YYYY")}`;
  }

  public selectStartDate(event) {
    this.selectedStartDate = event;
  }

  public selectEndDate(event) {
    this.selectedEndDate = event;
  }

  public selectContract(contract: IContract) {
    this.selectedContract = contract;
    if (contract.products.length && contract.products[0].siteCount === undefined) {
      this.portalService.populateProductDetails(contract);
    }
    // this.selectedProduct = contract.products[0];
    this.selectProduct(contract.products[0]);
  }

  selectProduct(product: IProduct) {
    // Unsubscribe to kill previous instances prior to calling a new API call
    if (this.actualForecastBlockIndexSubscription) {
      this.actualForecastBlockIndexSubscription.unsubscribe();
    }
    if (this.bmqSubscription) {
      this.bmqSubscription.unsubscribe();
    }
    this.selectedProduct = product;
    this.setDates();
    this.showIntervalDetails = false;
    this.populateGraphData();
  }

  getDetailsValueString(details: IRiskLegAttribute) {
    if (details.risklegType === 'Fixed') {
      return `$${Number(details.fixedPrice).toFixed(2)}/MWh`;
    } else if (details.risklegType === 'Heat Rate') {
      return `${Number(details.fixedPrice).toFixed(2)} HR`;
    } else if (details.risklegType === 'Percent Fixed') {
      return `$${Number(details.fixedPrice).toFixed(2)}`;
    } else {
      return `$${Number(details.fixedPrice).toFixed(2)}`;
    }
  }
  
  getLoadDetailsValueString(details: IRiskLegAttribute) {
    if (this.selectedProduct.name === 'Percent Fixed & Index') {
       return `${Number(details.meterLoad).toFixed(0)}%`;
    } else if (this.selectedProduct.name === 'Block & Index' || this.selectedProduct.name === 'Block & Index - IDR Only') {
      return `${(Number(details.meterLoad)/1000).toFixed(2)} MW`;
    } else {
      return `$${Number(details.meterLoad).toFixed(2)}`;
    }
  }

  /**
   * Similar to Observables in java, data is being manipulated to set the data in the right format for the calculations of demand, hedge, etc.
   * @param actual response from api
   * @param forecast response from api
   * @param block response from api
   */
  populateTableData(actual, forecast, block) {
    this.summaryTableData = [];
    const monthYearFormat = "MMMM 'YY"
    const startDate = moment(this.selectedProduct.deliveryStartDate).format(monthYearFormat);
    const endDate = moment(this.selectedProduct.deliveryEndDate).add(1, 'M').format(monthYearFormat);
    actual.forEach(actualDataPoint => {
      actualDataPoint.period = "Actual";
    })
    forecast.forEach(actualDataPoint => {
      actualDataPoint.period = "Forecast";
    })
    Observable.from<any>(actual.concat(forecast))
      .map(dataPoint => {
        dataPoint["dateLabel"] = moment(dataPoint["date"]).format(monthYearFormat);
        return dataPoint;
      })
      .groupBy(dataPoint => dataPoint["dateLabel"], dataPoint => dataPoint)
      .mergeMap(group => group.toArray())
      .subscribe(dataPoint => {
        const monthData = {
          totalDemand: {},
          totalHedge: {},
          totalExposure: {},
          percentIndex: {},
        } as IMonthData;
        monthData.month = dataPoint[0].dateLabel;
        monthData.period = dataPoint[0].period;
        monthData.expanded = false;
        if (monthData.month == startDate) {
          monthData.ramp = "RAMP IN";
        } else if (monthData.month == endDate) {
          monthData.ramp = "RAMP OUT";
        }

        this.setTotalDemand(monthData, dataPoint);
        this.setTotalHedge(monthData, dataPoint);
        this.setTotalExposure(monthData, dataPoint);
        this.setPercentIndex(monthData);
        this.summaryTableData.push(monthData);
      });
    this.filterTable();
  }

  private setTotalDemand(monthData: IMonthData, monthlyDataPoints: any[]) {
    monthData.totalDemand.offPeak = Math.round(monthlyDataPoints
      .filter(offTotal => offTotal.peakType == "Off Peak")
      .reduce((acc, d) => acc + parseFloat(d.demand), 0));
    monthData.totalDemand.onPeak = Math.round(monthlyDataPoints
      .filter(onTotal => onTotal.peakType == "On Peak")
      .reduce((acc, d) => acc + parseFloat(d.demand), 0));
    monthData.totalDemand.total = Math.round(monthlyDataPoints.reduce((acc, d) => acc + parseFloat(d.demand), 0));
  }

  private setTotalHedge(monthData: IMonthData, monthlyDataPoints: any[]) {
    monthData.totalHedge.offPeak = Math.round(monthlyDataPoints
      .filter(offTotal => offTotal.peakType == "Off Peak")
      .reduce((acc, d) => acc + parseFloat(d.block), 0));
    monthData.totalHedge.onPeak =Math.round(monthlyDataPoints
      .filter(onTotal => onTotal.peakType == "On Peak")
      .reduce((acc, d) => acc + parseFloat(d.block), 0));
    monthData.totalHedge.total = Math.round(monthlyDataPoints.reduce((acc, d) => acc + parseFloat(d.block), 0));
  }

  private setTotalExposure(monthData: IMonthData, monthlyDataPoints: any[]) {
    monthData.totalExposure.offPeak = Math.round(Math.abs(monthlyDataPoints
      .filter(offTotal => offTotal.peakType == "Off Peak")
      .reduce((acc, d) => acc + parseFloat(d.index), 0)));
    monthData.totalExposure.onPeak = Math.round(Math.abs(monthlyDataPoints
      .filter(onTotal => onTotal.peakType == "On Peak")
      .reduce((acc, d) => acc + parseFloat(d.index), 0)));
    monthData.totalExposure.total = Math.round(Math.abs(monthlyDataPoints.reduce((acc, d) => acc + parseFloat(d.index), 0)));
  }

  private setPercentIndex(monthData: IMonthData) {
    monthData.percentIndex.offPeak = (monthData.totalDemand.offPeak > 0 ? monthData.totalExposure.offPeak / monthData.totalDemand.offPeak : 0);
    monthData.percentIndex.onPeak = (monthData.totalDemand.onPeak > 0 ? monthData.totalExposure.onPeak / monthData.totalDemand.onPeak : 0);
    monthData.percentIndex.total = (monthData.totalDemand.total > 0 ? monthData.totalExposure.total / monthData.totalDemand.total : 0);
  }

  expand(record: IMonthData) {
    record.expanded = !record.expanded;
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterTable();
  }

  filterTable() {
    if(!this.searchTerm){
      this.filteredTableData = this.summaryTableData;
    } else {
      this.filteredTableData = this.genericPipe.transform(this.summaryTableData, this.searchTerm);
    }
  }

  getZoneDisplay(market, congestionZones: IZone[]) {
    if (!congestionZones || congestionZones.length === 0 || !market) {
      return '--';
    }
    if (congestionZones.length === 1) {
      return ForwardPriceForm.getHumanZoneName(market, congestionZones[0].congestionZone);
    }
    return congestionZones.map(zone => ForwardPriceForm.getHumanZoneName(market, zone.congestionZone) + ' ' + zone.percentage + '%').join(', ');
  }
}

const defaultLayers: ILayersList = {
  actual: { name: "Actual Demand (KW)", hasData: false, selected: true, color: "#18AAFD" },
  forecast: { name: "Forecast Demand (KW)", hasData: false, selected: true, color: "#0A38D9" },
  block: { name: "Hedge (KW)", hasData: false, selected: true, color: "#EC008C" },
  bmq: { name: "Initial Load Forecast (KW)", hasData: false, selected: false, color: "#000000" }
};
