import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-position-review-queue',
  templateUrl: './position-review-queue.component.html',
  styleUrls: ['./position-review-queue.component.scss']
})
export class PositionReviewQueueComponent implements OnInit {

  constructor(
    private dataService : DataService,
  ) { 
  }

  ngOnInit() {
    this.dataService.setTitleSource('Position Review Queue');
  }

}
