import {Component, Injectable, EventEmitter} from '@angular/core';
import {environment} from '../../environments/environment';
import {DataService} from "../shared/data.service";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Http} from "@angular/http";
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {
  I4cpRecentAlerts,
  I4cpPreferences,
  AlertType,
  I4cpAlert,
  I4cpROC,
  I4cpMTDData,
  I4cpAlertHistory,
  IDemandData,
  I4cpContactResponse, I4cpContact, I4cpSitePerformance, I4cpSummaryPerformance,
} from "../shared/entities/fourcp";
import {IMarketData} from "../shared/entities/markets";
import * as moment from 'moment';
import {IFourcpSite} from "../shared/entities/site";
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class FourcpService {

  headers: HttpHeaders;
  alert: I4cpAlert;
  alertHistory: I4cpAlertHistory

  alertUpdated = new EventEmitter<I4cpAlert>();

  constructor(private http: HttpClient,
              private oldHttp: Http,
              private dataService: DataService,
              private cookieService: CookieService) {

    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  setAlert(alert: I4cpAlert) {
    this.alert = alert;
    this.alertUpdated.emit(alert);
  }

  setAlertHistory(alertHistory: I4cpAlertHistory){
    this.alertHistory = alertHistory;
  }


  get4cpPreferences(accountId: string){
    let url = environment.get4cpPreferencesUrl.replace("{accountId}", accountId);

    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpPreferences>) => response)
      .catch(this.handleError);
  }



  get4cpAlert(date?: string){
    let url;
    if(date){
      url = environment.get4cpAlertByDateUrl.replace('{date}', date);
    } else {
      url = environment.get4cpAlertUrl;
    }
    let alertTypeParam = '';
    let preferences = this.dataService.getFourcpPreferences();
    if(preferences.alertType && preferences.alertType == AlertType.HighlySelective){
      alertTypeParam = 'highlySelective'
    } else {
      alertTypeParam = preferences.alertType ? preferences.alertType.toLowerCase() : preferences.alertType;
    }
    url = url.replace('{alertType}', alertTypeParam);

    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpAlert>) => response)
      .catch(this.handleError);
  }

  get4cpMarketData(source: string, start: string, end: string) {
    let url = environment.get4cpMarketDataUrl;
    url = url.replace('{source}', source);
    url = url.replace('{start}', start);
    url = url.replace('{end}', end);

    return this.http
      .get(url)
      .map((response: HttpResponse<IMarketData>) => response)
      .catch(this.handleError);
  }

  get4cpAlertHistory(startDate: string, subscriptionType: string) {
    let url = environment.get4cpAlertHistoryUrl;
    url = url.replace('{accountId}', this.dataService.getAccountSource().id);
    let newMonth = moment(startDate).month() + 1;
    url = url.replace('{month}', newMonth.toString());
    url = url.replace('{year}', moment(startDate).year().toString());

    return this.http
      .get(url)
      .map((response: any) => {return {peaks: response.monthlyRun};})
      .catch(this.handleError);
  }

  // Get contact information by account ID
  getRecentAlerts(accountId: string, count?: number): Observable<I4cpRecentAlerts> {

     let url = environment.production ? environment.getRecentAlertsUrl.replace('{accountId}', accountId.toString()) : environment.getRecentAlertsUrl;
     if(count){
       url = url + '?count=' + count;
     }
   // let url = './assets/api/fourcp/recentAlerts.json';
   return this.http
      .get(url).pipe(
        map((data: I4cpRecentAlerts)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }


    get4cpMTDData(){
    let url = environment.get4cpMTDUrl;
    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpMTDData>) => response)
      .catch(this.handleError);
  }

  get4cpROC(){
    let url = environment.get4cpRocUrl;
    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpROC>) => response)
      .catch(this.handleError);
  }

  get4cpContacts(accountId: string) {
    let url = environment.get4cpContactsUrl;
    url = url.replace('{accountId}', accountId);

    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpContactResponse>) => response)
      .catch(this.handleError);
  }



getFourcpSites(accountId: string, year: number): Observable<IFourcpSite[]> {
   // let url = environment.production ? environment.getSystemSiteGroupsUrl.replace('{accountId}', accountId.toString()) : environment.getSystemSiteGroupsUrl;
    let url = environment.getFourcpSiteUrl;
    url = url.replace('{accountId}', accountId)
    url = url.replace('{year}', year +'');

    return this.http
      .get(url).pipe(
        map((data: IFourcpSite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }



  saveContact(contacts: I4cpContactResponse){
    let url = environment.get4cpContactsUrl;
    url = url.replace('{accountId}', this.dataService.getAccountSource().id);

    return this.http
      .post(url, contacts, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }


  loadDemandGraph(site: IFourcpSite, year: number) {
    let url = environment.get4cpSummaryDemandUrl;

    // url = url.replace('{siteId}', site.esiId)
    //   .replace('{year}', year + '');

    return this.http
      .get(url)
      .map((response: HttpResponse<IDemandData>) => response)
      .catch(this.handleError);
  }

  loadSitePerformance(accountId: string, site: IFourcpSite, year: number) {
    let url = environment.get4cpSitePerformanceUrl;
    url = url.replace('{accountId}', accountId);
    url = url.replace('{siteId}', site.siteId)
    url = url.replace('{year}', year + '');

    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpSitePerformance>) => response)
      .catch(this.handleError);
  }

  loadSummaryPerformance(accountId: string, year: number){
    let url = environment.get4cpSummaryPerformanceUrl;
    url = url.replace('{accountId}', accountId)
    url = url.replace('{year}', year+'');

    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpSummaryPerformance>) => response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    if(error.status == 500) {
       console.log("returned 500 error, ignore");
       return;
    }
    return Observable.throw(error || 'Server error.');
  }
}
