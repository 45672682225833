import { Component, OnInit } from '@angular/core';
import { FadeInAnimation } from "../../animations/fadeInAnimation";
import { IAccount } from '../../shared/entities/account';
import { PortalService } from "../../shared/portal.service";
import { OrderByPipe } from "../../shared/pipe/orderBy.pipe";
import { Subscription } from "rxjs";
declare var $: any;
import * as moment from 'moment';
import { FadeAnimation } from "../../animations/fadeAnimation";
import { IPagerDenom, PagerDenoms } from "../../shared/const/pagerDenoms";
import { Pager, PagerService } from "../../shared/pager.service";
import { IHelpStep } from "../../shared/entities/contextualHelp";
import { IProfile, IUserAccount, IUserPermission } from "../../shared/entities/profile";
import { DataService } from "../../shared/data.service";
import { WeeklyDatePeriods } from "../../shared/const/datePeriods";
import { ISubscription } from "../../shared/entities/widgets/subscriptions";
import { SubscriptionsService } from "../../subscriptions/subscriptions.service";
import { GenericPipe } from "../../shared/pipe/generic.pipe";
import { Router } from "@angular/router";
import { ProgressStep } from '../../shared/entities/progressBarInput';
import { CookieService } from 'ngx-cookie-service';
import { Authority } from '../../shared/const/authority';
import { ProfileService } from '../profile.service';
import { ProfileSubscriptionsUtility, ReportTypeOption } from './profile-subscriptions.utility';
import { FakerService } from '../../shared/faker.service';


@Component({
  selector: 'app-profile-subscriptions',
  templateUrl: './profile-subscriptions.component.html',
  styleUrls: ['./profile-subscriptions.component.scss'],
  providers: [OrderByPipe, GenericPipe],
  animations: [FadeInAnimation, FadeAnimation]
})
export class ProfileSubscriptionsComponent implements OnInit {

  subscriptionOptions: any = {
    frequencies: [{ 'display': 'Weekly', 'option': 'WEEKLY' }, { 'display': 'Monthly', 'option': 'MONTHLY' }],
    deliveries: WeeklyDatePeriods,
    description: '',
    valid: false
  };
  refreshSubscriptionDeliveryList: boolean = true;

  myProfile: IProfile;
  accountDropdownList: String[] = [];
  reportTypeDropdownList: String[] = [];
  subscriptionList: ISubscription[];
  customerSubscriptionList: ISubscription[];
  brokerSubscriptionList: ISubscription[];
  filteredAccountList: String[] = [];
  filteredSubscriptions: ISubscription[];
  subscriptionToEdit: ISubscription;
  subscriptionToDelete: ISubscription;
  filterForThirdPartyFlag : boolean = false;
  filterForAccount : string = 'All';
  filterForReportType : string = 'All';
  filterAccountSearch : string = '';
  showSubscribeUserModal : boolean = false;
  recentAccounts: IUserAccount[] = [];
  thirdPartyAccounts: IUserAccount[] = [];
  editSubEmail: string;

  selectedReportTypeFilterText: string = 'All';
  selectedAccountFilterText: string = 'All';

  // For Progress Bar in New Subscription Modal
  progressSteps: ProgressStep[] = [
    { label: 'Step 1', index: 1 },
    { label: 'Step 2', index: 2 },
    { label: 'Step 3', index: 3 }
  ];
  currentStep: number = 1;
  nextButtonDisabled: boolean = true;

  // Account List for New Subscription Modal
  accountsForUserSourceSubscription: Subscription;
  userSubscription: Subscription;
  thirdPartyAccountsSubscription: Subscription;
  accountsForUser: IUserAccount[];
  filteredAccounts: IUserAccount[] = [];
  accountsSelectSettings: Array<{display: string, value: IUserAccount}> = [];
  selectedAccount: IUserAccount;
  isSuperUser: boolean;
  isSuperAdmin: boolean;
  isBrokerAdmin: boolean;
  isCustomerAdmin: boolean;
  typeaheadIndex: number = -1;
  lastSearchedTerm = '';
  accountSearch = '';
  showSearchResults: boolean = false;
  selectUserDisabled: boolean = true;
  usersForSelectedAccount: IUserPermission[];
  usersDropDownSettings: Array<{display: string, value: IUserPermission}> = [{display: "Default User", value: null}];
  selectedUser: IUserPermission;
  brokerFeatureDisabled: boolean = false;
  showAccountDropdown: boolean = false;
  searchResultText: string = 'Search Results';

  // Report Type Settings For New Subscription Modal
  reportTypeSettings: ReportTypeOption[];
  selectedReportType: ReportTypeOption;

  // List of Reports for New Subscription Modal
  selectReportDisabled: boolean = true;
  listOfReportsForAccount: Array<{display: string, value: string}> = [];
  selectedReport: {display:string, value: string} = {display: '',value: ''};

  // Subscription Variables for New SubscriptionModal
  newSubscription: ISubscription;
  subscriptionOptionsHeaderText:string;
  subscribedReports:any;
  showSubscribeOptions: boolean;
  activeReportSubscription:boolean;
  newSubscriptionOptions: any = {
    frequencies: [
      { 'display': 'Daily', 'option': 'DAILY', 'selected':false },
      { 'display': 'Weekly', 'option': 'WEEKLY','selected':false },
      { 'display': 'Monthly', 'option': 'MONTHLY','selected':false }],
    deliveries: WeeklyDatePeriods,
    description: '',
    valid: false
  };
  editSubscriptionOptions: any = {
    frequencies: [
      { 'display': 'Daily', 'option': 'DAILY', 'selected':false },
      { 'display': 'Weekly', 'option': 'WEEKLY','selected':false },
      { 'display': 'Monthly', 'option': 'MONTHLY','selected':false }],
    deliveries: WeeklyDatePeriods,
    description: '',
    valid: false
  };
  activeSubscriptionId: number;
  descriptionText: string = "";

  searchTerm: string = "";
  showSearchRow: boolean = false;

  pager: Pager = null;
  selectedDenom: IPagerDenom;
  pagerDenoms: IPagerDenom[];

  maskMode: boolean;
  maskModeSubscription: Subscription;

  constructor(private dataService: DataService,
    private portalService: PortalService,
    private profileService: ProfileService,
    private utility: ProfileSubscriptionsUtility,
    private subscriptionService: SubscriptionsService,
    private cookieService: CookieService,
    private pagerService: PagerService,
    private genericPipe: GenericPipe,
    private fakerService: FakerService,
    private router: Router) { }

  ngOnInit() {
    this.dataService.setLoading(true);
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );
    // Initializing subscription for new Subscription Modal
    this.newSubscription = {
      description: '',
      frequencyType: 'DAILY',
      weeklyFrequencySelector: {'option':''},
      frequencyTypeSelector: {'option': 'DAILY'}
    };

    this.reportTypeSettings = this.utility.reportTypeOptions;

    this.myProfile = this.dataService.getProfile();
    this.loadSubscriptions();

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if (denom.count == 10) {
        this.selectDenom(denom);
      }
    });

    const brokerAdmin = this.myProfile.userAccounts.find(acc => acc.accessType === 'ThirdParty' && acc.accessLevel === 'Admin');
    this.isBrokerAdmin = brokerAdmin ? true : false;
    const customerAdmin = this.myProfile.userAccounts.find(acc => acc.accessType === 'Direct' && acc.accessLevel === 'Admin');
    this.isCustomerAdmin = customerAdmin ? true : false;

    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);

    if (this.isBrokerAdmin) {
      this.thirdPartyAccounts = this.dataService.getThirdPartyAccountsSource();
      this.accountsForUser = this.dataService.getAccountsForUserSource();
    } else {
      this.accountsForUser = this.myProfile.userAccounts;
    }

    if (this.isSuperUser) {
      this.getRecentAccounts();
    }
    this.userSubscription = this.dataService.userSourceUpdated.subscribe(() => {
      if (this.isSuperUser) {
        this.getRecentAccounts();
      }
      this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
      this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    });

    this.accountsForUserSourceSubscription = this.dataService.accountsForUserSourceUpdated.subscribe((accounts) => {
      this.accountsForUser = accounts;
      this.filteredAccounts = accounts;
    });

    if(!this.isSuperUser && !this.isBrokerAdmin){
      this.loadAccountSelect();
    }
  }

  ngOnDestroy() {
    if (this.accountsForUserSourceSubscription) this.accountsForUserSourceSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  loadSubscriptions() {
    this.subscriptionService.getSubscriptionsForUser(this.myProfile.userId).subscribe(
      (subscriptions: any) => {
        this.subscriptionList = subscriptions;
        this.subscriptionList.forEach(sub => {
          sub.fakeCustomerName = this.fakerService.getCompanyName();
          sub.fakeFirstName = this.fakerService.getFirstName();
          sub.fakeLastName = this.fakerService.getLastName();
          sub.fakeEmail = sub.fakeFirstName + sub.fakeLastName + "@email.com";
          sub.fakeReportName = this.fakerService.generateFakeReportName(sub.fakeCustomerName);
          sub.fakeDescription = this.toTitleCase(sub.reportType.replace('_',' ')) + " Description " + Math.floor(Math.random() * 100) + 1;
        })
        this.customerSubscriptionList = this.filteredSubscriptions = this.subscriptionList.filter(x => !x.thirdPartyFlag);
        this.brokerSubscriptionList = this.subscriptionList.filter(x => x.thirdPartyFlag);
        this.brokerSubscriptionList.forEach(subscription => {
          if (subscription.reportType === 'BROKER_ENROLL') {
              subscription.reportType = 'Enrollment Report';
          }
        });
        this.filterSubscriptions();
        this.formatSubscriptionList();
        this.filterForDirect();
        this.setDropdowns();
        this.dataService.setLoading(false);
        this.paginate(1);
      },
      (err) => {
        this.dataService.setLoading(false);
        this.paginate(1);
      }
    )
  }

  toggleSearchRow() {
    this.searchTerm = "";
    this.showSearchRow = !this.showSearchRow;
    this.filterSubscriptions();
  }

  setSubscriptionSetting(value, type) {
    console.log("value: ", value);
    console.log("type: ", type);
    if (type == 'frequencyType') {
      this.subscriptionToEdit.frequencyTypeSelector = value;
      this.subscriptionToEdit.frequencyType = value.option;
      if (this.subscriptionToEdit.frequencyType == 'WEEKLY') {
        this.editSubscriptionOptions.deliveries = WeeklyDatePeriods;
        this.subscriptionToEdit.monthlyFrequency = null;
        this.subscriptionToEdit.monthlyFrequencySelector = null;
        this.subscriptionToEdit.weeklyFrequencySelector = null;
      }
      else {
        this.editSubscriptionOptions.deliveries = this.setMonthDays();
        this.subscriptionToEdit.weeklyFrequency = null;
        this.subscriptionToEdit.monthlyFrequencySelector = null;
        this.subscriptionToEdit.weeklyFrequencySelector = null;
      }
    }
    else if (type == 'monthlyFrequency') {
      this.subscriptionToEdit[type] = value.option;
      this.subscriptionToEdit.monthlyFrequencySelector = value;
    }
    else if (type == 'weeklyFrequency') {
      this.subscriptionToEdit[type] = value.option;
      this.subscriptionToEdit.weeklyFrequencySelector = value;
    }
    else {
      this.subscriptionToEdit[type] = value;
    }
  }

  formatSubscriptionList() {
    if (this.subscriptionList != null) {
      this.subscriptionList.forEach((subscription) => {
        console.log("SUBSCRIPTION: ", subscription);
        let displayFrequencyType = this.toCamelcase(subscription.frequencyType);
        let displayFrequency = this.formatFrequency(subscription);
        subscription.displayFrequencyType = displayFrequencyType;
        subscription.displayFrequency = displayFrequency;
        subscription.formattedDateCreated = moment(subscription.dateCreated).format("MM/DD/YY");
        subscription.formattedMostRecent = subscription.mostRecent ? moment(subscription.mostRecent).format("MM/DD/YY") : "------";
      });
    }
  }

  filterSubscriptions() {
    let filteringSubs = this.filterForThirdPartyFlag ? this.brokerSubscriptionList : this.customerSubscriptionList;
    if (this.filterForAccount != 'All') {
      filteringSubs = this.genericPipe.transform(filteringSubs, this.filterForAccount);
    }
    if (this.filterForReportType != 'All') {
      filteringSubs = this.genericPipe.transform(filteringSubs, this.filterForReportType)
    }
    if (this.searchTerm) {
      filteringSubs = this.genericPipe.transform(filteringSubs, this.searchTerm);
    }
    this.filteredSubscriptions = filteringSubs.sort((a,b) => (a.customerName < b.customerName ? -1 : 1));
    this.paginate(1);
  }

  setDropdowns() {
    this.accountDropdownList = [];
    this.reportTypeDropdownList = [];
    const filteredSubs = this.filterForThirdPartyFlag ? this.subscriptionList.filter(x => x.thirdPartyFlag) : this.subscriptionList.filter(x => !x.thirdPartyFlag);
    filteredSubs.forEach((sub=>{
      if(!this.accountDropdownList.includes(sub.customerName)){
        this.accountDropdownList.push(sub.customerName);
      }
      if(!this.reportTypeDropdownList.includes(sub.reportType)){
        this.reportTypeDropdownList.push(sub.reportType);
      }
    }));
    this.accountDropdownList = this.filteredAccountList = this.accountDropdownList.sort((a,b) => (a < b ? -1 : 1));
    this.reportTypeDropdownList = this.reportTypeDropdownList.sort((a,b) => (a < b ? -1 : 1));
    this.accountDropdownList.unshift('All');
    this.reportTypeDropdownList.unshift('All');
    this.selectedReportTypeFilterText = this.selectedAccountFilterText = 'All';
    this.filterForAccount = this.filterForReportType = 'All';
    this.searchTerm = this.filterAccountSearch = '';
    this.showSearchRow = false;
  }

  selectAccountForFilter(account: string){
    this.filterForAccount = account;
    this.selectedAccountFilterText = account;
    if (this.selectedAccountFilterText.length > 22) {
      this.selectedAccountFilterText = this.selectedAccountFilterText.substring(0,22) + "...";
    }
    this.filterAccountSearch = account;
    if (account != 'All') {
      this.filteredAccountList = [account];
    } else {
      this.filteredAccountList = this.accountDropdownList;
    }
    this.filterSubscriptions();
  }

  selectReportTypeForFilter(type: string){
    this.filterForReportType = type;
    this.selectedReportTypeFilterText = this.toTitleCase(type.replace('_',' '));
    this.filterSubscriptions();
  }

  filterForThirdParty(){
    this.filterForThirdPartyFlag = true;
    this.setDropdowns();
    this.filteredSubscriptions = this.brokerSubscriptionList;
    this.paginate(1);
  }

  filterForDirect(){
    this.filterForThirdPartyFlag = false;
    this.setDropdowns();
    this.filteredSubscriptions = this.customerSubscriptionList;
    this.paginate(1);
  }

  searchSubscriptionAccounts(accountName: string) {
    if (!accountName) {
      this.filteredAccountList = this.accountDropdownList;
      return;
    }
    this.filteredAccountList = this.accountDropdownList.filter(x => x.toUpperCase().includes(accountName.toUpperCase()));
  }

  toCamelcase(str: string) {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  }

  formatFrequency(subscription: ISubscription) {
    console.log("Subscription monthly: ", subscription);
    if (subscription.frequencyType == 'WEEKLY') {
      return this.toCamelcase(subscription.weeklyFrequency);
    }
    if (subscription.frequencyType == 'MONTHLY') {
      if (subscription.monthlyFrequency == 99) {
        return 'On the last day of the month';
      }
      else {
        return 'On the ' + moment(subscription.monthlyFrequency + '-01-18', 'D-MM-YY').format('Do');
      }
    }
    return 'When information available';
  }

  setMonthDays() {
    let days = 28;
    let deliveries = [];

    for (var i = 0; i < days; i++) {
      if (i != (days - 1)) {
        let dayOfMonth = moment((i + 1) + '-01-18', 'D-MM-YY').format('Do');
        deliveries.push({ option: (i + 1), display: dayOfMonth, selected: false })
      } else {
        deliveries.push({ option: 99, display: 'Last Day of the Month ', selected: false })
      }
    }

    return deliveries;
  }

  getMonthDays() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return new Date(year, month, 0).getDate();
  }

  toggleEditModal(subscription: ISubscription) {
    console.log("SUBSCRIPTION TO EDIT BEFORE: ", this.subscriptionToEdit);
    console.log('subscriptionOptions: ', this.subscriptionOptions.frequencies);
    this.subscriptionToEdit = Object.assign({}, subscription);
    this.descriptionText = subscription.description;
    this.editSubEmail = subscription.email;
    this.findFrequencyType();
    this.findWeeklyFrequency();
    this.findMonthlyFrequency();
    // this.setSubscriptionSetting(this.subscriptionToEdit.frequencyType, 'frequencyType');
    // this.setSubscriptionSetting(this.subscriptionToEdit.monthlyFrequency, 'monthlyFrequency');
    // this.setSubscriptionSetting(this.subscriptionToEdit.weeklyFrequency, 'weeklyFrequency');
    console.log("SUBSCRIPTION TO EDIT AFTER: ", this.subscriptionToEdit);
  }

  toggleDeleteModal(subscription: ISubscription) {
    this.subscriptionToDelete = subscription;
  }

  findFrequencyType() {
    this.subscriptionOptions.frequencies.forEach((frequency) => {
      if (this.subscriptionToEdit.frequencyType == frequency.option) {
        this.subscriptionToEdit.frequencyTypeSelector = frequency;
        this.setSubscriptionSetting(frequency, 'frequencyType');
      }
    });
  }

  findMonthlyFrequency() {
    if (this.subscriptionToEdit.frequencyType == 'MONTHLY') {
      this.editSubscriptionOptions.deliveries.forEach(
        (delivery) => {
          console.log("Delivery: ", delivery);
          if (this.subscriptionToEdit.monthlyFrequency == delivery.option) {
            this.subscriptionToEdit.monthlyFrequencySelector = delivery;
          }
        });
    }
  }

  findWeeklyFrequency() {
    if (this.subscriptionToEdit.frequencyType == 'WEEKLY') {
      this.subscriptionOptions.deliveries.forEach(
        (delivery) => {
          if (this.subscriptionToEdit.weeklyFrequency == delivery.option) {
            this.subscriptionToEdit.weeklyFrequencySelector = delivery;
          }
        });
    }
  }

  saveSubscription() {
    console.log("Sub before: ", this.subscriptionToEdit);
    this.subscriptionToEdit.frequencyType = this.subscriptionToEdit.frequencyTypeSelector.option;
    if (this.subscriptionToEdit.monthlyFrequencySelector) {
      this.subscriptionToEdit.monthlyFrequency = this.subscriptionToEdit.monthlyFrequencySelector.option;
    }
    if (this.subscriptionToEdit.weeklyFrequencySelector) {
      this.subscriptionToEdit.weeklyFrequency = this.subscriptionToEdit.weeklyFrequencySelector.option;
    }
    if (this.subscriptionToEdit.frequencyType == 'DAILY') {
      this.subscriptionToEdit.weeklyFrequency = 'MONDAY';
      this.subscriptionToEdit.monthlyFrequency = 0;

    }
    if (this.subscriptionToEdit.frequencyType == 'WEEKLY') {
      this.subscriptionToEdit.monthlyFrequency = 0;
    }
    if (this.subscriptionToEdit.frequencyType == 'MONTHLY') {
      this.subscriptionToEdit.weeklyFrequency = ' ';
    }
    this.subscriptionToEdit.description = this.descriptionText;
    console.log("Sub after: ", this.subscriptionToEdit);
    this.subscriptionService.editSubscription(this.subscriptionToEdit).subscribe(
      (response: any) => {
        console.log("EDITING RESPONSE: ", response);
        this.subscriptionList.forEach((subscription) => {
          if (subscription.id == response.id) {
            subscription.monthlyFrequency = response.monthlyFrequency;
            subscription.weeklyFrequency = response.weeklyFrequency;
            subscription.frequencyType = response.frequencyType;
            subscription.description = response.description;
          }
        });
        this.formatSubscriptionList()
        this.filterSubscriptions();
      }
    );
  }

  deleteSubscription() {
    this.subscriptionService.deleteSubscription(this.subscriptionToDelete).subscribe(
      (response) => {
        console.log("DELETE RESPONSE: ", response);
        let temp = [];
        this.subscriptionList.forEach((subscription) => {
          if (subscription.id != this.subscriptionToDelete.id) {
            temp.push(subscription);
          }
        });
        this.subscriptionList = temp;
        this.customerSubscriptionList = this.subscriptionList.filter(x => !x.thirdPartyFlag);
        this.brokerSubscriptionList = this.subscriptionList.filter(x => x.thirdPartyFlag);
        this.formatSubscriptionList();
        this.filterSubscriptions();
        this.paginate(1);
      }
    );
  }

  redirectToBillingSummaryReport() {
    this.router.navigate(['/billing-reports']);
  }

  toTitleCase(str: string){
    if (!str) {
      return "";
    }
    return str.split(' ')
   .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
   .join(' ');
  }

  subscribeUser() {
    if(!this.showSubscribeUserModal) {
      this.showSubscribeUserModal = true;
      this.reportTypeSettings = this.utility.reportTypeOptions;
      this.brokerFeatureDisabled = false;
      this.accountSearch = '';
      this.searchResultText = 'Search Results';
      this.listOfReportsForAccount = [];
      this.selectedAccount = null;
      this.selectedUser = null;
      this.selectedReportType = {display: '',value: '', accountType: ''};
      this.selectedReport = {display: '',value: ''};
      this.selectUserDisabled = true;
      this.selectReportDisabled = true;
      this.currentStep = 1;
      this.newSubscription = {
        description: '',
        frequencyType: 'DAILY',
        weeklyFrequencySelector: {'option':''},
        frequencyTypeSelector: {'option': 'DAILY'}
      };
      this.descriptionText = "";
      $('#subscribeUserModal').modal('show');
    }
  }

  closeSubscribeUser() {
    if(this.showSubscribeUserModal){
      this.showSubscribeUserModal = false;
      $('#subscribeUserModal').modal('hide');
    }
  }

  continueNewSubscription() {
    this.currentStep += 1;
    this.nextButtonDisabled = true;
  }

  loadAccountSelect(){
    this.accountsForUser.forEach((account)=>{
      this.accountsSelectSettings.push({display: account.name, value: account})
    })
  }

  async selectNewAccount($event){
    this.selectedAccount = $event.value;
    this.showSearchResults = false;
    this.setReportTypeOptions();
    this.usersDropDownSettings = [{display:'default user',value:null}];
    await this.getUsersFromBrokerAndCustomer();
  }

  setReportTypeOptions() {
    this.reportTypeSettings = this.utility.reportTypeOptions;
    if (this.selectedAccount.accessType === 'ThirdParty') {
      this.reportTypeSettings = this.reportTypeSettings.filter(x => x.accountType === "BROKER" || x.accountType === "BOTH");
    } else {
      this.reportTypeSettings = this.reportTypeSettings.filter(x => x.accountType === "CUSTOMER" || x.accountType === "BOTH");
    }
    this.reportTypeSettings = this.reportTypeSettings.sort((a,b) => (a.display < b.display ? -1 : 1));
  }

  selectNewUser($event){
    this.selectedUser = $event.value;
    this.nextButtonDisabled = false;
  }

  navigateTypeahead(down: boolean) {
    if (down && this.typeaheadIndex < this.filteredAccounts.length - 1) {
      this.typeaheadIndex++;
    } else if (!down && this.typeaheadIndex > -1) {
      this.typeaheadIndex--;
    }
  }

  resetTypeahead() {
    this.typeaheadIndex = -1;
  }

  getRecentAccounts() {
    let recentAccounts = this.cookieService.get('recent-accounts');
    if (!recentAccounts) recentAccounts = '';
    const recentAccountIds = recentAccounts.split(',');
    this.recentAccounts = [];
    let added;
    recentAccountIds.forEach((id) => {
      added = false;
      this.accountsForUser.forEach((account) => {
        if (account.id == id && !added) {
          this.recentAccounts.push(account);
          added = true;
        }
      });
      if (this.thirdPartyAccounts && !added) {
        this.thirdPartyAccounts.forEach((tpa) => {
          if (tpa.id == id) this.recentAccounts.push(tpa);
        });
      }
    });
  }

  searchAccounts(searchTerm: string) {
    this.searchResultText = 'Search Results';
    if (this.isSuperUser) {
      this.searchAccountsForSuperUser(searchTerm);
    } else {

      this.filteredAccounts = [];
      let duplicate;
      if (searchTerm.length >= 3) {
        const escapedAccountSearch = this.portalService.escapeRegExp(searchTerm);
        this.accountsForUser.forEach((account) => {
          if (account.id && account.id.match(escapedAccountSearch) ||
            (account.name && account.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) ||
            searchTerm === '') {
            duplicate = false;
            this.filteredAccounts.forEach((acc, i) => {
              if (acc.id === account.id) {
                duplicate = true;
                if (acc.customerAgentId) {
                  this.filteredAccounts[i] = account;
                }
              }
            });
            if (!duplicate) {
              this.filteredAccounts.push(account);
            }
          }
        });
        this.thirdPartyAccounts.forEach((tpa) => {
          if (tpa.id.match(escapedAccountSearch) ||
            (tpa.name && tpa.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) || searchTerm === '') {
            if (!tpa.accessType) tpa.accessType = 'ThirdParty'; // for super users
            duplicate = false;
            this.filteredAccounts.forEach((acc) => {
              if (acc.id === tpa.id) {
                duplicate = true;
              }
            });
            if (!duplicate) {
              this.filteredAccounts.push(tpa);
            }
          }
        });
        if (this.filteredAccounts.length === 0) {
          this.searchResultText = 'No Results Found';
        }
        this.showSearchResults = true;
      } else {
        this.showSearchResults = false;
      }
    }
  }

  searchAccountsForSuperUser(searchTerm: string) {
    this.showSearchResults = true;
    this.filteredAccounts = [];
    this.lastSearchedTerm = '';
    let duplicate;
    if (searchTerm.length >= 3) {
      if (searchTerm != this.lastSearchedTerm) {
        this.lastSearchedTerm = searchTerm;
        const escapedAccountSearch = this.portalService.escapeRegExp(searchTerm);
        // Call the new service that looks up data in Elastic search
        this.profileService.getUserAccounts(searchTerm).subscribe(
          (accountsForUser: any) => {
            accountsForUser.forEach((account) => {
              if (account.id.toLowerCase().match(escapedAccountSearch.toLowerCase()) ||
                (account.name && account.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) ||
                searchTerm === '') {
                duplicate = false;
                this.filteredAccounts.forEach((acc, i) => {
                  if (acc.id === account.id) {
                    duplicate = true;
                    if (acc.customerAgentId) {
                      this.filteredAccounts[i] = account;
                    }
                  }
                });
                if (!duplicate) {
                  this.filteredAccounts.push(account);
                }
              }
            });
            if (this.filteredAccounts.length === 0) {
              this.searchResultText = 'No Results Found';
            }
          }, err => {
            console.error(err);
          }
        );
      }
    } else {
      this.showSearchResults = false;
    }
  }

  async selectAccount(account?: IUserAccount) {
    if (this.filteredAccounts.length > 0 || account) {
      if (this.typeaheadIndex === -1) {
        this.typeaheadIndex = 0;
      }
      if (account) {
        this.accountSearch = account.name;
        this.selectedAccount = account;
        this.showSearchResults = false;
        this.setReportTypeOptions();
        this.usersDropDownSettings = [{display:'default user',value:null}];
        await this.getUsersFromBrokerAndCustomer();
      }
      this.resetTypeahead();
    }
  }

  async getUsersFromBrokerAndCustomer() {
    if (this.selectedAccount.customerAgentId && (this.isBrokerAdmin || this.isSuperAdmin || this.isSuperUser)) {
      await this.getAllUsersForAccount(this.selectedAccount.customerAgentId);
    }
    if (this.isCustomerAdmin || this.isSuperAdmin || this.isSuperUser || this.isBrokerAdmin) {
      await this.getAllUsersForAccount(this.selectedAccount.id);
    }
  }

  async getAllUsersForAccount(accountId?: string) {
    this.profileService.getUserPermissions(accountId).subscribe(
      (userPermissions : any) => {
        this.usersForSelectedAccount = userPermissions;
        this.usersForSelectedAccount.forEach(user => {
          const isUserInDropdown = this.usersDropDownSettings.findIndex(u => u.value != null && u.value.userProfile.id === user.userProfile.id);
          if (isUserInDropdown === -1) {
            this.usersDropDownSettings.push({display: user.userProfile.firstName + " " + user.userProfile.lastName + " - "+ user.userProfile.email, value: user})
          }
        });
        // Find the index of the item with value === null
        const indexToRemove = this.usersDropDownSettings.findIndex(item => item.value === null);

        // If indexToRemove is found, remove the item using splice
        if (indexToRemove !== -1) {
          this.usersDropDownSettings.splice(indexToRemove, 1);
        }

        this.usersDropDownSettings.sort((a,b) => (a.value.userProfile.firstName < b.value.userProfile.firstName ? -1 : 1));
        this.selectUserDisabled = false;

      }, err => {
        console.error(err);
        this.profileService.getUserPermissions(this.selectedAccount.id).subscribe(
          (userPermissions : any) => {
            this.usersForSelectedAccount = userPermissions;
            this.usersDropDownSettings = [];
            this.usersForSelectedAccount.forEach(user => {
              this.usersDropDownSettings.push({display: user.userProfile.firstName + " " + user.userProfile.lastName + " - "+ user.userProfile.email, value: user})
            });
            // Find the index of the item with value === null
            const indexToRemove = this.usersDropDownSettings.findIndex(item => item.value === null);

            // If indexToRemove is found, remove the item using splice
            if (indexToRemove !== -1) {
              this.usersDropDownSettings.splice(indexToRemove, 1);
            }
            this.selectUserDisabled = false;

          }, err => {
            console.error(err);
          }
        );
      }
    );
  }

  // Setting Report Type in Create New Subscription Modal
  async selectSubscriptionReportType($event){
    this.selectedReportType = $event;
    await this.getAllReportsByType(this.selectedReportType.value);
    this.selectReportDisabled = false;
  }

  getAllReportsByType(reportType: string) {
    this.listOfReportsForAccount = [];
    let request = {reportType: reportType, accountId: this.selectedAccount.id}
    this.subscriptionService.getReportsForType(request).subscribe(
      (reports : any) => {
        reports.forEach((report) => {
          let displayReport = {display: report.reportName, value: report.reportId}
          this.listOfReportsForAccount.push(displayReport);
        })
      }
    )
  }

  selectReportForSubscription($event){
    this.selectedReport = $event;
    this.nextButtonDisabled = false;
  }

  setNewSubscriptionSetting(value, type) {
    if (type == 'frequencyType') {
      this.newSubscription.frequencyTypeSelector = {'option': value.option};
      this.newSubscription.frequencyType = value.option;
      if (this.newSubscription.frequencyType == 'WEEKLY') {
        this.newSubscriptionOptions.deliveries = WeeklyDatePeriods;
        this.newSubscription.monthlyFrequency = null;
        this.newSubscription.monthlyFrequencySelector = null;
        this.newSubscription.weeklyFrequencySelector = this.newSubscription.weeklyFrequencySelector ? this.newSubscription.weeklyFrequencySelector : 'MONDAY';
      }
      if (this.newSubscription.frequencyType == 'MONTHLY') {
        this.newSubscriptionOptions.deliveries = this.setMonthDays();
        this.newSubscription.weeklyFrequency = null;
        this.newSubscription.monthlyFrequencySelector = null;
        this.newSubscription.weeklyFrequencySelector = null;
      }
    }
    if (type == 'dailyFrequency'){
      this.newSubscription[type] = value
    }
    else if (type == 'monthlyFrequency') {
      this.newSubscription[type] = value.option;
      this.newSubscription.monthlyFrequencySelector = {'option': value};
    }
    else if (type == 'weeklyFrequency') {
      this.newSubscription[type] = value;
      this.newSubscription.weeklyFrequencySelector = {'option': value};
    }
  }

  setEditSubscriptionSetting(value, type) {
    if (type == 'frequencyType') {
      this.subscriptionToEdit.frequencyTypeSelector = {'option': value.option};
      this.subscriptionToEdit.frequencyType = value.option;
      if (this.subscriptionToEdit.frequencyType == 'WEEKLY') {
        this.editSubscriptionOptions.deliveries = WeeklyDatePeriods;
        this.subscriptionToEdit.monthlyFrequency = null;
        this.subscriptionToEdit.monthlyFrequencySelector = null;
        this.subscriptionToEdit.weeklyFrequencySelector = this.subscriptionToEdit.weeklyFrequencySelector ? this.subscriptionToEdit.weeklyFrequencySelector : 'MONDAY';
      }
      if (this.subscriptionToEdit.frequencyType == 'MONTHLY') {
        this.editSubscriptionOptions.deliveries = this.setMonthDays();
        this.subscriptionToEdit.weeklyFrequency = null;
        this.subscriptionToEdit.monthlyFrequencySelector = null;
        this.subscriptionToEdit.weeklyFrequencySelector = null;
      }
    }
    if (type == 'dailyFrequency'){
      this.subscriptionToEdit[type] = value
    }
    else if (type == 'monthlyFrequency') {
      this.subscriptionToEdit[type] = value.option;
      this.subscriptionToEdit.monthlyFrequencySelector = {'option': value};
    }
    else if (type == 'weeklyFrequency') {
      this.subscriptionToEdit[type] = value;
      this.subscriptionToEdit.weeklyFrequencySelector = {'option': value};
    }
  }


  saveNewSubscription(){
    this.newSubscription.reportType = this.selectedReportType.value;
    this.newSubscription.reportId = parseInt(this.selectedReport.value);
    this.newSubscription.userId = this.selectedUser.userProfile.id;
    // add new columns
    this.newSubscription.accountId = this.selectedAccount.id;
    this.newSubscription.customerName = this.selectedAccount.name;
    this.newSubscription.thirdPartyFlag = this.selectedAccount.accessType == 'ThirdParty';
    this.newSubscription.email = this.selectedUser.userProfile.email;
    this.newSubscription.firstName = this.selectedUser.userProfile.firstName;
    this.newSubscription.lastName = this.selectedUser.userProfile.lastName;
    this.newSubscription.deleted = false;
    this.newSubscription.description = this.descriptionText;
    this.newSubscription.frequencyType = this.newSubscription.frequencyTypeSelector.option;
    if (this.newSubscription.monthlyFrequencySelector) {
      const monthlySubscribtionOption = this.newSubscription.monthlyFrequencySelector.option;
      this.newSubscription.monthlyFrequency = monthlySubscribtionOption.option;
    }
    else if (this.newSubscription.weeklyFrequencySelector) {
      this.newSubscription.weeklyFrequencySelector.option === '' ? this.newSubscription.weeklyFrequency = "MONDAY" : this.newSubscription.weeklyFrequency = this.newSubscription.weeklyFrequencySelector.option;
    } else {
      this.newSubscription.weeklyFrequency = "MONDAY"; // Daily
      this.newSubscription.monthlyFrequency = 0; // Daily
    }
    this.newSubscription.reportId = parseInt(this.selectedReport.value);
    this.newSubscription.userId = this.selectedUser.userProfile.id;
    this.newSubscription.frequencyType = this.newSubscription.frequencyTypeSelector.option;
    this.subscriptionService.saveNewSubscription(this.newSubscription).subscribe((response : any) => {
      // console.log("response: ", response);
      if (response && response.id) {
        response.reportName = this.selectedReport.display;
        this.subscriptionList.push(response);
        this.customerSubscriptionList = this.subscriptionList.filter(x => !x.thirdPartyFlag);
        this.brokerSubscriptionList = this.subscriptionList.filter(x => x.thirdPartyFlag);
        this.formatSubscriptionList();
        this.filterSubscriptions();
        this.paginate(1);
        // this.subscribedReports = true;
        this.descriptionText = "";
        this.newSubscription = {
          description: '',
          frequencyType: 'DAILY',
          weeklyFrequencySelector: {'option':'0'},
          frequencyTypeSelector: {'option': 'DAILY'}
        };
      }
    });
    this.showSubscribeUserModal = false;
    $('#subscribeUserModal').modal('hide');
    this.currentStep = 1;
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number) {
    if (this.filteredSubscriptions) {
      this.pager = this.pagerService.getPage(
        this.filteredSubscriptions,
        page,
        this.selectedDenom.count
      );
    }
  }
}
