export const BillingSummaryColumns = [
  {
    name: 'General Information',
    columns: [
      {display: 'Meter Read Start Date', value: 'meterReadStartDate'},
      {display: 'Meter Read End Date', value: 'meterReadEndDate'},
      // {display: 'Total Current Charges', value: 'totalCurrentCharges'},
      {display: 'Billing Date', value: 'billingDate'},
      {display: 'Due Date', value: 'dueDate'},
      // {display: 'Payment Date', value: 'paymentDate'},
      // {display: 'Balance Due', value: 'balanceDue'},
      {display: 'Site Name', value: 'siteName'},
      {display: 'Account #', value: 'contractAccountNumber'},
      {display: 'Service Address', value: 'serviceAddress'},
      {display: 'Billing Address', value: 'billingAddress'},
    ]
  },
  {
    name: 'Invoice Charge Details',
    columns: [
      {display: 'Usage (kWh)', value: 'energy'},
      {display: 'Demand', value: 'demand'},
      {display: 'Energy Charge', value: 'energyCharge'},
      {display: 'Transmission Charges', value: 'transmissionCharge'},
      {display: 'Current Pass Through', value: 'currentPeriodPassThrough'},
      {display: 'Prior Pass Through', value: 'priorPeriodPassThrough'},
      {display: 'Aggregation Fee', value: 'aggregationFee'},
      {display: 'Nodal Charge', value: 'nodalAncillaryCharge', ercot: true},
      // {display: 'TEC', value: 'tec'},
      {display: 'Other Charges', value: 'otherCharge'},
      {display: 'Taxes & Assessments', value: 'taxesAndAssessments'},
      {display: 'Total Current Charges', value: 'totalCurrentCharges'},
      // {display: 'NIT Charge', value: 'nitCharge', northeast: true},
      // {display: 'CAPO Charge', value: 'capoCharge', northeast: true},
    ]
  },
  // {
  //   name: 'Additional Invoice Details',
  //   columns: [
  //     {display: 'Payment Status', value: 'paymentStatus'},
  //     {display: 'Date Cleared', value: 'dateCleared'},
  //     {display: 'Forward Balance', value: 'forwardBalance'},
  //   ]
  // },
  {
    name: 'Site Contract Details',
    columns: [
      {display: 'Contract', value: 'contractId'},
      {display: 'Contract Nickname', value: 'contractNickName'},
      {display: 'Contract Start Date', value: 'contractStartDate'},
      {display: 'Contract End Date', value: 'contractEndDate'},
      {display: 'Site Status', value: 'siteStatus'}
    ]
  },
  {
    name: 'Site Enrollment Details',
    columns: [
      {display: 'Move In Date', value: 'moveInDate'},
      {display: 'Move Out Date', value: 'moveOutDate'},
      {display: 'Site Type', value: 'frequencyType'},
      {display: 'Market', value: 'market'},
      {display: 'TDSP/EDC', value: 'utility'},
      {display: 'Zone', value: 'siteCongestionZone'},
      {display: 'Current Meter #s', value: 'meterDeviceNumber'}

    ]
  }
];
