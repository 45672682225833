import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {DataService} from "../shared/data.service";
import {environment} from "../../environments/environment";
import {Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportSettingsService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient,
              private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getReportSettings(accountId: string,reportType: string): Observable<any>{
    let url = environment.getReportSettingsUrl.replace('{accountId}', accountId.toString()).replace('{reportType}',reportType.toString());
    return this.http
    .get(url).pipe(
      map((data: any[]) => {
        return this.mapItems<any>(data);
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  private mapItems<T>(data: any[]): T[] {
    return data.map(item => {
      const mappedItem: T = {} as T;
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          mappedItem[key] = item[key];
        }
      }
      return mappedItem;
    });
  }

  createReportSetting(setting: any): Observable<any> {
    let url = environment.createOrUpdateReportSettingsUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', '');
    return this.http
        .post(url,setting, {headers : this.headers}).pipe(
          map((data: any)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  updateReportSetting(setting: any): Observable<any> {
    let url = environment.createOrUpdateReportSettingsUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', setting.reportId.toString());
    return this.http
        .post(url,setting, {headers : this.headers}).pipe(
          map((data: any)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  deleteReportSetting(accountId: string, reportId: number, createdUserId: string, reportType: string, publicFlag: number): Observable<any>{
    let url = environment.deleteReportSettingsUrl
      .replace('{accountId}', accountId.toString())
      .replace('{reportId}', reportId.toString())
      .replace('{reportType}', reportType.toString())
      .replace('{publicFlag}', publicFlag.toString());
    return this.http
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }


  private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }
}
