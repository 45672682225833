import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
//import {IBillDelivery, IBillDeliveryRequest } from '../shared/entities/billDelivery';
import {DataService} from '../../shared/data.service';
import {PortalService} from "../../shared/portal.service";

import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import {Response, Http, Headers, ResponseContentType} from "@angular/http";

import { IBillDeliveryEdit, IBillDeliveryEditEmail, IBillDeliveryEditPostRequest, IBillDeliveryEditPostResponse } from '../../shared/entities/billDeliveryEdit';


@Injectable()
export class BillDeliveryEditService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp : Http, private portalService: PortalService, private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
   
   }


  getBillDelivery() {
    // if(environment.production){
      let url = environment.billingDeliveryUrl.replace('{accountId}', this.dataService.getAccountSource().id);

      return this.http
        .get(url)
        .map((response: HttpResponse<any>) => response)
        .catch(this.handleError);
  }

  applyChanges(request: IBillDeliveryEditPostRequest): Observable<HttpResponse<IBillDeliveryEditPostResponse>> {
    let url = environment.billingDeliveryUpdateUrl.replace('{accountId}',   this.dataService.getAccountSource().id);
    //for Delete the End date will be set to yesterday
    if(environment.production){
      return this.http
        .post(url, request, {headers: this.headers})
        .map((response: HttpResponse<IBillDeliveryEditPostResponse>) => response)
        .catch(this.handleError);
    } else {
      return this.http
        .get(url, {headers: this.headers})
        .map((response: HttpResponse<IBillDeliveryEditPostResponse>) => response)
        .catch(this.handleError);
    }
  }

    exportBillDelivery(request : any): any {
      let url = environment.production ? environment.exportBillingDeliveryUrl.replace('{accountId}', this.dataService.getAccountSource().id): environment.exportBillingDeliveryUrl;

      let headers = new Headers({'Content-Type': 'application/json'});
      return this.oldHttp
        .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
        .subscribe(
          (res) => {
            let contentDisposition = res.headers.get('content-disposition');
            let filename = contentDisposition.match(/filename=(.+)/)[1];
            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
              window.navigator.msSaveBlob(res.blob(), filename);
            } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
              window.open(window.URL.createObjectURL(blob));
            } else {
              let link=document.createElement('a');
              link.href=window.URL.createObjectURL(res.blob());
              link.download = filename;
              link.click();
            }
            this.dataService.setLoading(false);
          }
        );
    }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}


