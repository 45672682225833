import { Component, OnDestroy,ViewChild, OnChanges,SimpleChanges, Output, EventEmitter  } from '@angular/core';
import { Subject } from 'rxjs';
import { IRenewableSite } from '../renewable-site';
import { RenewablesService } from '../renewables.service';
import { IGenerationDetailExport } from './generation-detail-export';
import { DateRangeSelectorComponent } from '../../shared/components/date-range-selector/date-range-selector.component';
import {SiteSelectorComponent } from '../renewables-overview/site-selector/site-selector.component';
import { DataService } from '../../shared/data.service';
import { PortalService } from '../../shared/portal.service';
import { IUserAccount } from '../../shared/entities/profile';
import * as moment from 'moment';
import { FadeAnimation } from '../../animations/fadeAnimation';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-generation-detail-export-modal',
  templateUrl: './generation-detail-export-modal.component.html',
  styleUrls: ['./generation-detail-export-modal.component.scss'],
  animations: [FadeAnimation]
})


export class GenerationDetailExportModalComponent implements OnDestroy {
  sites: IRenewableSite[];
  dateTypeRadio: string;
  activeStep = 1;
  intervalType: number;
  availStartDate: string;
  availEndDate: string;
  selStartDate: string;
  selEndDate: string;
  maskMode: boolean;
  maskModeSubscription: Subscription;



  minHistoryDate: Date;
  maxHistoryDate = moment().endOf('day').subtract(0, 'day').toDate();
  @Output() onSubmit = new EventEmitter();

  private readonly dateFormat = 'MM/DD/YY';

  private readonly unsubscribe$ = new Subject();
  myAccount: IUserAccount;
  @ViewChild(DateRangeSelectorComponent, {static : false}) dateRangeSelector: DateRangeSelectorComponent;
  @ViewChild(SiteSelectorComponent, {static : false}) siteSelector: SiteSelectorComponent;
  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(private renewablesService: RenewablesService,
    private portalService: PortalService,
    private dataService: DataService) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  open() {
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    
    this.siteSelector.green = true;

    this.myAccount = this.dataService.getAccountSource();
    this.sites = [];
    this.dateTypeRadio = '15MIN';
    this.activeStep = 1;
    // this.renewablesService.sites
    //   .takeUntil(this.unsubscribe$)
    //   .subscribe(sites => {
    //     this.sites = sites;
    //   });

      this.renewablesService.sites
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.sites = sites;
        this.minHistoryDate = new Date(this.sites
          .filter(site => site.minRangeDate)
          .map(site => site.minRangeDate)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]);

          this.maxHistoryDate =  new Date(this.sites
            .filter(site => site.maxRangeDate)
            .map(site => site.maxRangeDate)
            .sort((a,b) => new Date(b).getTime() - new Date(a).getTime())[0]);

      });


  // }


       this.availStartDate = moment( this.minHistoryDate).add(1,'d').format(this.dateFormat);
       this.availEndDate = moment(this.maxHistoryDate).add(1,'d').format(this.dateFormat);

       this.minHistoryDate = moment( this.minHistoryDate).add(1,'d').toDate();
       this.maxHistoryDate = moment( this.maxHistoryDate).add(1,'d').toDate();

       this.selStartDate = moment(this.dateRangeSelector.selectedStartDate).format(this.dateFormat);
       this.selEndDate = moment(this.dateRangeSelector.selectedEndDate).format(this.dateFormat);

      // this.availStartDate = moment(this.sites[1].minRangeDate).format(this.dateFormat);
      // this.availEndDate = moment(this.sites[1].maxRangeDate).format(this.dateFormat);

      // const availEndDate = this.sites[1].minRangeDate;

    $('#generationDetailExportModal').modal('show');
  }

  close() {
    $('#generationDetailExportModal').modal('hide');
  }

  expanded = true;
  displayedSites: IRenewableSite[] = [];


  submit() {
    $('#generationDetailExportModal').modal('hide');
    this.onSubmit.emit(true);
    const generationDetailExport = {} as IGenerationDetailExport;

    // console.log('selectedSiteIds',this.siteSelector.selectedSiteIds);



    if (this.dateTypeRadio === '15MIN') {
      this.intervalType =  15;
    } else if (this.dateTypeRadio === 'HOUR') {
      this.intervalType = 60;
    }
    moment(this.dateRangeSelector.selectedStartDate).format('YYYY-MM-DD');
    generationDetailExport.interval = this.intervalType;
    generationDetailExport.siteIds = this.siteSelector.selectedSiteIds;
    generationDetailExport.startDate = moment(this.dateRangeSelector.selectedStartDate).format('YYYY-MM-DD');
    generationDetailExport.endDate = moment(this.dateRangeSelector.selectedEndDate).format('YYYY-MM-DD');
    generationDetailExport.accountId = this.myAccount.id;
    generationDetailExport.accountName = this.myAccount.name;
    generationDetailExport.maskingMode = this.dataService.getMaskMode();


   this.renewablesService.createGenerationDetailExport(generationDetailExport)

   // this.spinner.hide();

    // TODO: Show spinner
    // this.renewablesService.createGenerationDetailExport(generationDetailExport)
    //   .takeUntil(this.unsubscribe$)
    //   .subscribe(excelExport => {
    //     // TODO: Download file
    //     this.close();
    //   });
  }
}
