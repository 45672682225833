import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MarketsService } from '../markets.service';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import {
  ErcotMarketStatus,
  FuelMixData,
  ERCOTGenerationMixPieColors,
  IMarketsDashboardProperty,
  IMarketSource,
  ErcotMarketSources,
  ErcotRTMarketDemandTitles,
  ERCOTHbLzList,
  ErcotGridTitles,
  ErcotSnapshotProperties,
  ErcotInfoProperties,
  MarketDemandComparison,
  ERCOTGenerationMixPieSources,
  DefaultViewName,
  ERCOTGenerationMixSources,
  ERCOTDemandComparisonOptions,
  IMarketProperties,
  PjmSnapshotProperties,
  PjmInfoProperties,
  Market,
  PJMGenerationMixPieColors,
  PJMGenerationMixSources,
  PjmMarketSources,
  PjmMarketStatus,
  PJMDemandComparisonOptions,
  PjmRTMarketDemandTitles,
  PjmGridTitles,
  ComparisonOptionItem,
  PJMGenerationMixPieSources,
  PJMMarketPriceSources,
  ErcotMarketSection,
  PJMMarketSection,
  PJMPricesTypes,
  ERCOTGenerationMixSourceMap,
  PJMGenerationMixSourceMap
} from '../../shared/entities/markets';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Subscription, forkJoin } from 'rxjs';
import { IUserAccount } from '../../shared/entities/profile';
import { DataService } from '../../shared/data.service';
import { UtilsService } from '../../shared/utils.service';
import { IMarketSetting } from '../../shared/entities/marketSetting';
import { map, switchMap } from 'rxjs/operators';
declare var Highcharts: any;
declare var $: any;

@Component({
  selector: 'app-market-dashboard',
  templateUrl: './market-dashboard.component.html',
  styleUrls: ['./market-dashboard.component.scss']
})
export class MarketDashboardComponent implements OnChanges, OnInit {
  @Input() market: string;
  load: IMarketsDashboardProperty = {} as any;
  forceLoad: boolean = true;
  snapshotProperties: IMarketProperties;
  infoProperties: IMarketProperties;
  generationMixPieColors: Map<string, string>;
  generationMixPieSourceMap: Map<string, string>;
  generationMixSources: string[] = [];
  generationMixPieSources: ComparisonOptionItem[] = [];
  marketAlertsLink: string;
  selectedAccount: IUserAccount;
  fuelMixData: FuelMixData[] = [];
  // offset value used to paginate RT market demand tiles
  marketDemandsOffset: number = 0;
  marketDemandsPageSize: number = 6;
  // the group that is displayed on the RT market Demand tile at a time.
  rtMarketDemandDisplay: IMarketSource[] = [];
  rtMarketDemandDisplayMobile: IMarketSource[][] = [];
  // the rt demand values that are selected for display
  filteredRTDemands: IMarketSource[] = [];
  // rt demand values from all sources (all inclusive)
  rtMarketDemands: IMarketSource[] = [];
  rtPrices: IMarketSource[] = [];
  pjmYourListRes: IMarketSource[] = [];
  pjmDaYourListRes: IMarketSource[] = [];
  gridConditions: IMarketSource[] = [];
  sourceMixData: { source: string; value: Number; percentage: Number }[] = [];
  sourceMixData1: { source: string; value: Number; percentage: Number }[] = [];
  sourceMixData2: { source: string; value: Number; percentage: Number }[] = [];
  viewMoreSources = false;
  isFullScreen: boolean;
  dbMarketDemandsSettingsFound: boolean = false;
  showRtMarketsDemandComparision = false;
  marketConditionStatus: { text: string; background: string } = null;
  newMarketViewName: string;
  saveAsDefaultDB: boolean = true;
  generationMixChart;
  isRenamed: boolean = false;
  pjmHbsFormatted: IMarketSource[][] = [];
  pjmLzsFormatted: IMarketSource[][] = [];
  pjmInfsFormatted: IMarketSource[][] = [];
  pjmYourLists: IMarketSource[] = [];
  pjmYourListsFormatted: IMarketSource[][] = [];
  pjmDaHbsFormatted: IMarketSource[][] = [];
  pjmDaLzsFormatted: IMarketSource[][] = [];
  pjmDaInfsFormatted: IMarketSource[][] = [];
  pjmDaYourLists: IMarketSource[] = [];
  pjmDaYourListsFormatted: IMarketSource[][] = [];
  gridConditionTiles: any;
  showYourListMenu = false;
  showDaYourListMenu = false;
  pjmLzs: IMarketSource[] = [];
  pjmHbs: IMarketSource[] = [];
  pjmInfs: IMarketSource[] = [];
  pjmDaLzs: IMarketSource[] = [];
  pjmDaHbs: IMarketSource[] = [];
  pjmDaInfs: IMarketSource[] = [];
  origDemandComparisonOptions: MarketDemandComparison[] = [];
  demandComparisonOptions: MarketDemandComparison[] = [];
  marketDemandComparisonOptions: MarketDemandComparison[] = [];
  isZonesExpanded = false;
  isHubsExpanded = false;
  isInfsExpanded = false;
  yourListZonesCount = 0;
  yourListHubsCount = 0;
  yourListInfsCount = 0;
  yourListSelectionCount = 0;
  yourListSelection: IMarketSource[] = [];
  isDaZonesExpanded = false;
  isDaHubsExpanded = false;
  isDaInfsExpanded = false;
  daYourListZonesCount = 0;
  daYourListHubsCount = 0;
  daYourListInfsCount = 0;
  daYourListSelectionCount = 0;
  daYourListSelection: IMarketSource[] = [];
  timezone: string;
  ercotHbLzList = ERCOTHbLzList;
  marketSources: any = ErcotMarketSources;
  rtMarketDemandTitles: Map<string, string>;
  marketStatus: any;
  marketSection: any;
  PJMPricesTypes = PJMPricesTypes;
  Market = Market;

  // Subscriptions
  dashboardRefresher: Subscription;
  dbSettingsSubs: Subscription;
  gridConditionsSubs: Subscription;
  marketPricesSubs: Subscription;
  marketDemandSettingsSubs: Subscription;
  marketDemandSourcesSubs: Subscription;
  fuelMixSubs: Subscription;
  saveDashboardSubs: Subscription;
  deleteDashboardSubs: Subscription;
  marketViewChangeSubs: Subscription;
  breakpointSubs: Subscription;
  marketViewSubs: Subscription;
  dashboardViewsSubs: Subscription;
  saveAllMarketViewsSubs: Subscription;
  saveAllMarketDashboardSettingsSubs: Subscription;
  deleteDBSettingsSubs: Subscription;

  constructor(
    public dataService: DataService,
    public marketsService: MarketsService,
    private utilsService: UtilsService,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnChanges() {
    this.resetDashboard();
    this.getMarketProperties();
    this.getDashboardViews();
  }

  ngOnInit() {
    const currentDate = moment();
    this.load.date = currentDate.toDate();
    this.load.formattedDate = currentDate.format('hh:mm A ') + 'CPT';
    this.load.value = currentDate.toDate().getTime();
    this.dataService.isMarketDBFullscreen = false;
    this.selectedAccount = this.dataService.getAccountSource();
    this.marketViewChangeSubs =
      this.dataService.selectedMarketViewChanged.subscribe(() => {
        this.forceLoad = true;
        this.loadDashboard();
      });
    this.dashboardRefresher = IntervalObservable.create(150000).subscribe(() => {
      this.forceLoad = false;
      this.loadDashboard();
    });
    this.saveDashboardSubs =
      this.dataService.saveDashBoardModalTriggered.subscribe(
        (result: { openModal: boolean; name: string; isRenamed: boolean }) => {
          this.savemarketdb(result.openModal, result.name, result.isRenamed);
        }
      );
    this.deleteDashboardSubs =
      this.dataService.deleteDashBoardModalTriggered.subscribe(() => {
        this.deleteMarketDb();
      });
    this.dbSettingsSubs =
      this.dataService.emitOpenDBSettingsSaveOptions.subscribe(() => {
        this.openDBSettingsSaveOptions();
      });
    // Click outside event for date range and groupings sections
    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#demands-comparison-section').length == 0) {
        this.showRtMarketsDemandComparision = false;
      }
      if (
        $($event.target).parents('#fullscreen-settings-menu-section').length ==
        0
      ) {
        this.dataService.openMDBViewOptions = false;
      }
      if ($($event.target).parents('#fullscreen-save-section').length == 0) {
        this.dataService.openMDBSaveOptions = false;
      }
      if (
        $($event.target).parents('#your-list-menu').length == 0 &&
        $($event.target).parents('#add-to-your-list').length == 0 &&
        $($event.target).parents('#edit-your-list').length == 0 &&
        $($event.target).parents('#icon-expanded-zone').length == 0 &&
        $($event.target).parents('#icon-collapsed-zone').length == 0 &&
        $($event.target).parents('#icon-expanded-hub').length == 0 &&
        $($event.target).parents('#icon-collapsed-hub').length == 0 &&
        $($event.target).parents('#icon-expanded-inf').length == 0 &&
        $($event.target).parents('#icon-collapsed-inf').length == 0
      ) {
        this.showYourListMenu = false;
      }
    });
    this.breakpointSubs = this.breakpointObserver
      .observe([
        '(max-width: 1024px)',
        '(min-width: 769px)',
        '(max-width: 768px)',
        '(min-width: 376px)',
        '(max-width: 375px)'
      ])
      .subscribe((state: BreakpointState) => {
        if (
          state.breakpoints['(max-width: 1024px)'] &&
          state.breakpoints['(min-width: 769px)']
        ) {
          // set pagesize 3
          this.marketDemandsPageSize = 3;
          this.setRTMarketDemandDisplay(0);
        } else if (
          state.breakpoints['(max-width: 768px)'] &&
          state.breakpoints['(min-width: 376px)']
        ) {
          // set pagesize 4
          this.marketDemandsPageSize = 4;
          this.setRTMarketDemandDisplay(0);
        } else if (state.breakpoints['(max-width: 375px)']) {
          // set pagesize 0
          this.marketDemandsPageSize = 0;
          this.setRTMarketDemandDisplayMobile();
        } else {
          // set page size 6
          this.marketDemandsPageSize = 6;
          this.setRTMarketDemandDisplay(0);
        }
        this.changeFullscreen();
      });
  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(false);
    if (this.saveDashboardSubs) {
      this.saveDashboardSubs.unsubscribe();
    }
    if (this.deleteDashboardSubs) {
      this.deleteDashboardSubs.unsubscribe();
    }
    if (this.fuelMixSubs) {
      this.fuelMixSubs.unsubscribe();
    }
    if (this.marketDemandSourcesSubs) {
      this.marketDemandSourcesSubs.unsubscribe();
    }
    if (this.marketDemandSettingsSubs) {
      this.marketDemandSettingsSubs.unsubscribe();
    }
    if (this.marketPricesSubs) {
      this.marketPricesSubs.unsubscribe();
    }
    if (this.gridConditionsSubs) {
      this.gridConditionsSubs.unsubscribe();
    }
    if (this.dbSettingsSubs) {
      this.dbSettingsSubs.unsubscribe();
    }
    if (this.dashboardRefresher) {
      this.dashboardRefresher.unsubscribe();
    }

    if (this.marketViewChangeSubs) {
      this.marketViewChangeSubs.unsubscribe();
    }
    if (this.breakpointSubs) {
      this.breakpointSubs.unsubscribe();
    }
    if (this.marketViewSubs) {
      this.marketViewSubs.unsubscribe();
    }
    if (this.dashboardViewsSubs) {
      this.dashboardViewsSubs.unsubscribe();
    }
    if (this.saveAllMarketViewsSubs) {
      this.saveAllMarketViewsSubs.unsubscribe();
    }
    if (this.saveAllMarketDashboardSettingsSubs) {
      this.saveAllMarketDashboardSettingsSubs.unsubscribe();
    }
    if (this.deleteDBSettingsSubs) {
      this.deleteDBSettingsSubs.unsubscribe();
    }
  }

  getMarketProperties() {
    if (this.market === Market.ERCOT) {
      this.marketSection = ErcotMarketSection;
      this.rtMarketDemandTitles = ErcotRTMarketDemandTitles;
      this.marketStatus = ErcotMarketStatus;
      this.marketSources = ErcotMarketSources;
      this.snapshotProperties = ErcotSnapshotProperties;
      this.infoProperties = ErcotInfoProperties;
      this.generationMixPieColors = ERCOTGenerationMixPieColors;
      this.generationMixPieSourceMap = ERCOTGenerationMixSourceMap;
      this.generationMixSources = ERCOTGenerationMixSources;
      this.generationMixPieSources = ERCOTGenerationMixPieSources;
      this.marketAlertsLink =
        'https://www.ercot.com/services/comm/mkt_notices/opsmessages';
      this.gridConditionTiles = ErcotGridTitles;
      this.marketDemandComparisonOptions = ERCOTDemandComparisonOptions;
      this.timezone = 'CT';
    } else if (this.market === Market.PJM) {
      this.marketSection = PJMMarketSection;
      this.rtMarketDemandTitles = PjmRTMarketDemandTitles;
      this.marketStatus = PjmMarketStatus;
      this.marketSources = PjmMarketSources;
      this.snapshotProperties = PjmSnapshotProperties;
      this.infoProperties = PjmInfoProperties;
      this.generationMixPieColors = PJMGenerationMixPieColors;
      this.generationMixPieSourceMap = PJMGenerationMixSourceMap;
      this.generationMixSources = PJMGenerationMixSources;
      this.generationMixPieSources = PJMGenerationMixPieSources;
      this.marketAlertsLink =
        'https://emergencyprocedures.pjm.com/ep/pages/dashboard.jsf';
      this.gridConditionTiles = PjmGridTitles;
      this.marketDemandComparisonOptions = PJMDemandComparisonOptions;
      this.isZonesExpanded = false;
      this.isHubsExpanded = false;
      this.isInfsExpanded = false;
      this.yourListZonesCount = 0;
      this.yourListHubsCount = 0;
      this.yourListInfsCount = 0;
      this.timezone = 'ET';
    } else if (this.market === 'ISO-NE') {
      // TODO
    }
  }

  resetDashboard() {
    this.fuelMixData = [];
    this.marketDemandsOffset = 0;
    this.marketDemandsPageSize = 6;
    this.rtMarketDemandDisplay = [];
    this.rtMarketDemandDisplayMobile = [];
    this.filteredRTDemands = [];
    this.rtMarketDemands = [];
    this.rtPrices = [];
    this.gridConditions = [];
    this.pjmLzs = [];
    this.pjmHbs = [];
    this.pjmInfs = [];
    this.pjmYourLists = [];
    this.pjmHbsFormatted = [];
    this.pjmLzsFormatted = [];
    this.pjmInfsFormatted = [];
    this.pjmYourListsFormatted = [];
    this.pjmDaLzs = [];
    this.pjmDaHbs = [];
    this.pjmDaInfs = [];
    this.pjmDaYourLists = [];
    this.pjmDaHbsFormatted = [];
    this.pjmDaLzsFormatted = [];
    this.pjmDaInfsFormatted = [];
    this.pjmDaYourListsFormatted = [];
    this.sourceMixData = [];
    this.sourceMixData1 = [];
    this.sourceMixData2 = [];
    this.viewMoreSources = false;
    this.showRtMarketsDemandComparision = false;
    this.marketConditionStatus = null;
    this.newMarketViewName = null;
    this.saveAsDefaultDB = true;
    this.generationMixChart;
    this.origDemandComparisonOptions = [];
    this.demandComparisonOptions = [];
    this.dbMarketDemandsSettingsFound = false;
    this.dataService.marketViews = [];
    this.dataService.defaultMarketViewName = null;
    this.dataService.setMarketDemandSettings([]);
    this.dataService.dbMarketDemandsSettings = [];
    this.dataService.newDbMarketDemandsSettings = [];
    this.dataService.isMarketDBFullscreen = false;
    this.dataService.newDbInfoMarketGraphSettings = [];
    this.dataService.dbInfoMarketGraphSettings = [];
    this.dataService.newDbSnapshotMarketGraphSettings = [];
    this.dataService.dbSnapshotMarketGraphSettings = [];
    this.dataService.dbPjmYourListSettings = [];
    this.dataService.newDbPjmYourListSettings = [];
    this.dataService.dbPjmDaYourListSettings = [];
    this.dataService.newDbPjmDaYourListSettings = [];
    this.dataService.openMDBSaveOptions = false;
    this.dataService.openMDBViewOptions = false;
    this.dataService.setMarketInfoSettings([]);
    this.dataService.setMarketSnapShotGenMixSettings([]);
  }

  getDashboardViews() {
    this.dashboardViewsSubs = this.marketsService
      .getDashboardViews(this.market)
      .subscribe((res) => {
        const myProfile = this.dataService.getProfile();
        // first time case when no saved views are available
        if (!res.length || (res.length === 1 && !res[0].market)) {
          res = [
            {
              market: this.market,
              mdUser: myProfile.email,
              viewName: DefaultViewName,
              viewValue: 'true'
            }
          ];
        }
        this.dataService.marketViews = res;
        const defaultView = res.find((item) => item.viewValue === 'true');
        this.dataService.defaultMarketViewName = defaultView
          ? defaultView.viewName
          : this.dataService.marketViews[0].viewName;
        // triggers loadDashboard() function
        this.dataService.selectedMarketViewName =
          this.dataService.defaultMarketViewName;
      });
  }

  loadDashboard() {
    //Continue to refresh if dashboard data date is 5 minutes older than current time until new data is received
    const currentDate = moment();
    let timeToRefresh = moment(this.load.date)
      .add(1, 'minutes')
      .isBefore(currentDate);
    if (this.forceLoad || timeToRefresh) {
      this.load.date = currentDate.toDate();
      this.load.formattedDate = currentDate.format('hh:mm A ') + 'CPT';
      this.load.value = currentDate.toDate().getTime();
      this.getGenerationMixData();
      this.getGridConditions();
      this.getMarketDemandSettings();
      if (this.market === Market.ERCOT) {
        this.getRtMarketPrices();
      } else if (this.market === Market.PJM) {
        this.getRtMarketPrices();
      }
    }
  }

  getGridConditions() {
    this.gridConditionsSubs = this.marketsService
      .getGridConditions(this.market)
      .subscribe((res) => {
        this.gridConditions = res;
        this.setMarketConditionStatus();
      });
  }

  getRtMarketPrices() {
    if (this.market === Market.ERCOT) {
      this.marketPricesSubs = this.marketsService
        .getRTMarketPrices(this.market)
        .subscribe((res: IMarketSource[]) => {
          this.mapERCOTRtMarketPrices(res);
        });
    } else {
      this.marketPricesSubs = forkJoin([
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.Zones
        ),
        this.marketsService.getRTMarketPrices(this.market, PJMPricesTypes.Hubs),
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.Interfaces
        ),
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.YourList,
          this.dataService.selectedMarketViewName
        ),
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.ZonesDA
        ),
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.HubsDA
        ),
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.InterfacesDA
        ),
        this.marketsService.getRTMarketPrices(
          this.market,
          PJMPricesTypes.YourListDA,
          this.dataService.selectedMarketViewName
        )
      ])
        .pipe(
          map(
            ([
              zones,
              hubs,
              infs,
              yourList,
              zonesDa,
              hubsDa,
              infsDa,
              yourListDa
            ]) => {
              return {
                zones: zones,
                hubs: hubs,
                infs: infs,
                yourList: yourList,
                zonesDa: zonesDa,
                hubsDa: hubsDa,
                infsDa: infsDa,
                yourListDa: yourListDa
              };
            }
          )
        )
        .subscribe(
          ({
            zones,
            hubs,
            infs,
            yourList,
            zonesDa,
            hubsDa,
            infsDa,
            yourListDa
          }) => {
            let {
              pjmLzs,
              pjmHbs,
              pjmInfs,
              pjmLzsFormatted,
              pjmHbsFormatted,
              pjmInfsFormatted
            } = this.mapPJMMarketPrices(zones.concat(hubs).concat(infs));
            this.pjmLzs = pjmLzs;
            this.pjmHbs = pjmHbs;
            this.pjmInfs = pjmInfs;
            this.pjmLzsFormatted = pjmLzsFormatted;
            this.pjmHbsFormatted = pjmHbsFormatted;
            this.pjmInfsFormatted = pjmInfsFormatted;
            let {
              pjmLzs: pjmDaLzs,
              pjmHbs: pjmDaHbs,
              pjmInfs: pjmDaInfs,
              pjmLzsFormatted: pjmDaLzsFormatted,
              pjmHbsFormatted: pjmDaHbsFormatted,
              pjmInfsFormatted: pjmDaInfsFormatted
            } = this.mapPJMMarketPrices(zonesDa.concat(hubsDa).concat(infsDa));
            this.pjmDaLzs = pjmDaLzs;
            this.pjmDaHbs = pjmDaHbs;
            this.pjmDaInfs = pjmDaInfs;
            this.pjmDaLzsFormatted = pjmDaLzsFormatted;
            this.pjmDaHbsFormatted = pjmDaHbsFormatted;
            this.pjmDaInfsFormatted = pjmDaInfsFormatted;
            this.mapPJMRtMarketYourListPrices(yourList);
            this.mapPJMDaMarketYourListPrices(yourListDa);
          }
        );
    }
  }

  mapERCOTRtMarketPrices(res: IMarketSource[]) {
    this.ercotHbLzList = this.utilsService.clone(ERCOTHbLzList);
    this.rtPrices = this.ercotHbLzList.map((item) => {
      const rtPrice: IMarketSource = {
        source: null,
        title: null,
        dates: [],
        values: []
      };
      rtPrice.source = item;
      rtPrice.dates = res.length ? res[0].dates : [new Date().toString()];
      rtPrice.title = item;
      // accumulate hb and lz in values array respectively.
      rtPrice.values = res
        .filter((source) => {
          return item.toLowerCase() === 'panhandle'
            ? source.source === ErcotMarketSources.PanHandleHub
            : item.toLowerCase() === 'hub average'
            ? source.source === ErcotMarketSources.HubAverage
            : source.source === `HB_${item.toUpperCase()}` ||
              source.source === `LZ_${item.toUpperCase()}`;
        })
        .map((x) => x.values[0]);
      return rtPrice;
    });
  }

  mapPJMMarketPrices(rtPrices: IMarketSource[]) {
    rtPrices = rtPrices.map((item) => {
      item.title = PJMMarketPriceSources.get(item.source);
      item.dates = rtPrices.length
        ? rtPrices[0].dates
        : [new Date().toString()];
      return item;
    });
    if (rtPrices.length) {
      this.rtPrices = rtPrices;
    }
    const pjmLzs = rtPrices.filter((item) => item.source.indexOf('LZ_') > -1);
    const pjmLzsFormatted = this.getPjmZoneData(pjmLzs);
    const pjmHbs = rtPrices.filter((item) => item.source.indexOf('HB_') > -1);
    const pjmHbsFormatted = this.getPjmHubData(pjmHbs);
    const pjmInfs = rtPrices.filter((item) => item.source.indexOf('INF_') > -1);
    const pjmInfsFormatted = this.getPjmInfData(pjmInfs);
    return {
      pjmLzs,
      pjmHbs,
      pjmInfs,
      pjmLzsFormatted,
      pjmHbsFormatted,
      pjmInfsFormatted
    };
  }

  mapPJMRtMarketYourListPrices(yourList: IMarketSource[]) {
    this.dataService.dbPjmYourListSettings = this.processPjmYourListSettings(
      yourList,
      PJMPricesTypes.YourList
    );
    if (this.forceLoad) {
      this.dataService.newDbPjmYourListSettings = this.utilsService.clone(
        this.dataService.dbPjmYourListSettings
      );
    }
    this.setDbPjmYourListSettings();
    this.getPjmYourListData();
  }

  mapPJMDaMarketYourListPrices(yourList: IMarketSource[]) {
    this.dataService.dbPjmDaYourListSettings = this.processPjmYourListSettings(
      yourList,
      PJMPricesTypes.YourListDA
    );
    if (this.forceLoad) {
      this.dataService.newDbPjmDaYourListSettings = this.utilsService.clone(
        this.dataService.dbPjmDaYourListSettings
      );
    }
    this.setDbPjmDaYourListSettings();
    this.getPjmDaYourListData();
  }

  getMarketDemandSettings() {
    this.dataService.setLoading(true);
    this.marketDemandsOffset = 0;
    // default settings
    this.dataService.setMarketDemandSettings(
      this.marketDemandComparisonOptions
    );
    const componentName = this.marketSection.RTMarketDemand;
    this.origDemandComparisonOptions = this.utilsService.clone(
      this.dataService.marketDemandSettings
    );
    if (this.forceLoad) {
      this.demandComparisonOptions = this.utilsService.clone(
        this.origDemandComparisonOptions
      );
    }
    this.dataService.dbMarketDemandsSettings = this.processDemandSettings();
    // override with db settings (if any)
    this.marketDemandSettingsSubs = this.marketsService
      .getMarketViewSettings(
        this.dataService.selectedMarketViewName,
        componentName
      )
      .subscribe(
        (res) => {
          this.dbMarketDemandsSettingsFound = false;
          // populate saved market demand settings into demand comparison menu
          if (res.length) {
            this.dbMarketDemandsSettingsFound = true;
            this.dataService.dbMarketDemandsSettings = res;
          }
          if (this.forceLoad) {
            this.dataService.newDbMarketDemandsSettings =
              this.utilsService.clone(this.dataService.dbMarketDemandsSettings);
          }
          if (res.length) {
            this.setDbMarketDemandSettings();
          }
          this.getMarketDemands();
          this.dataService.setLoading(false);
        },
        (error) => {
          console.error(error);
          this.dbMarketDemandsSettingsFound = false;
          this.dataService.newDbMarketDemandsSettings = this.utilsService.clone(
            this.dataService.dbMarketDemandsSettings
          );
          this.getMarketDemands();
          this.dataService.setLoading(false);
        }
      );
  }

  /**
   * set demandComparisonOptions based on the rt market demand settings
   */
  setDbMarketDemandSettings() {
    // TODO if we are saving only selected settings in the db and if we find saved settings in the db,
    // we need to reset all the default settings to false (unselected) before iterating thru the db list.
    // This way, if the default settings have any true (selected) values they won't interfere with the db settings.
    if (this.dbMarketDemandsSettingsFound) {
      this.origDemandComparisonOptions = this.origDemandComparisonOptions.map(
        (item) => {
          if (item.checked) {
            item.checked = false;
          }
          if (item.items.length) {
            item.items = item.items.map((subItem) => {
              subItem.checked = false;
              return subItem;
            });
          }
          return item;
        }
      );
    }
    this.dataService.newDbMarketDemandsSettings.forEach((setting) => {
      if (setting && setting.fieldValue) {
        const selectedIndex = this.origDemandComparisonOptions.findIndex(
          (opt) => opt.source === setting.fieldName
        );
        // search through main list then search through sub list
        if (selectedIndex < 0) {
          this.origDemandComparisonOptions.forEach((opt, index) => {
            const selectedSubIndex = opt.items.findIndex(
              (subOpt) => subOpt.source === setting.fieldName
            );
            if (selectedSubIndex > -1) {
              this.origDemandComparisonOptions[index].items[
                selectedSubIndex
              ].checked = setting.fieldValue === 'true';
              this.origDemandComparisonOptions[index].count =
                this.origDemandComparisonOptions[index].items.filter(
                  (item) => item.checked
                ).length;
            }
          });
        } else {
          this.origDemandComparisonOptions[selectedIndex].checked =
            setting.fieldValue === 'true';
        }
      }
    });
    if (this.forceLoad) {
      this.demandComparisonOptions = this.utilsService.clone(
        this.origDemandComparisonOptions
      );
    }
  }

  setDbPjmYourListSettings() {
    // use the new db your list settings to find the respective items from zones/hubs/inf list and accumulate them into your list object.
    this.pjmYourLists = [];
    this.dataService.newDbPjmYourListSettings.forEach((setting) => {
      let item: IMarketSource;
      if (setting.fieldName.indexOf('LZ_') > -1) {
        item = this.pjmLzs.find((zone) => zone.source === setting.fieldName);
      } else if (setting.fieldName.indexOf('HB_') > -1) {
        item = this.pjmHbs.find((hub) => hub.source === setting.fieldName);
      } else if (setting.fieldName.indexOf('INF_') > -1) {
        item = this.pjmInfs.find((inf) => inf.source === setting.fieldName);
      } else {
        item = null;
      }
      if (item && item.source) {
        this.pjmYourLists.push(item);
      }
    });
  }

  setDbPjmDaYourListSettings() {
    // use the new db your list settings to find the respective items from zones/hubs/inf list and accumulate them into your list object.
    this.pjmDaYourLists = [];
    this.dataService.newDbPjmDaYourListSettings.forEach((setting) => {
      let item: IMarketSource;
      if (setting.fieldName.indexOf('LZ_') > -1) {
        item = this.pjmDaLzs.find((zone) => zone.source === setting.fieldName);
      } else if (setting.fieldName.indexOf('HB_') > -1) {
        item = this.pjmDaHbs.find((hub) => hub.source === setting.fieldName);
      } else if (setting.fieldName.indexOf('INF_') > -1) {
        item = this.pjmDaInfs.find((inf) => inf.source === setting.fieldName);
      } else {
        item = null;
      }
      if (item && item.source) {
        this.pjmDaYourLists.push(item);
      }
    });
  }

  getMarketDemands() {
    this.dataService.setLoading(true);
    this.marketDemandSourcesSubs = this.marketsService
      .getMarketDemandSources(this.market)
      .subscribe(
        (res) => {
          this.rtMarketDemands = res;
          this.setRTMarketDemands();
          this.setRTMarketDemandDisplay(this.marketDemandsOffset);
          this.dataService.setLoading(false);
        },
        (error) => {
          console.error(error);
          this.dataService.setLoading(false);
        }
      );
  }

  setRTMarketDemands() {
    this.filteredRTDemands = [];
    this.origDemandComparisonOptions.forEach((option) => {
      if (!option.items.length && option.checked) {
        const selectedDemandValue = this.rtMarketDemands.find(
          (item) => item.source === option.source
        );
        if (selectedDemandValue) {
          this.filteredRTDemands.push({
            title: this.rtMarketDemandTitles.get(option.source),
            values: this.utilsService.clone(selectedDemandValue.values),
            dates: this.utilsService.clone(selectedDemandValue.dates),
            source: option.source
          });
        }
      } else {
        const checkedOptions = option.items.filter((item) => item.checked);
        checkedOptions.forEach((subOption) => {
          const selectedDemandValue = this.rtMarketDemands.find(
            (item) => item.source === subOption.source
          );
          if (selectedDemandValue) {
            this.filteredRTDemands.push({
              title: this.rtMarketDemandTitles.get(subOption.source),
              values: this.utilsService.clone(selectedDemandValue.values),
              dates: this.utilsService.clone(selectedDemandValue.dates),
              source: subOption.source
            });
          }
        });
      }
    });
  }

  setRTMarketDemandDisplay(offset: number) {
    if (this.marketDemandsPageSize) {
      this.rtMarketDemandDisplay = this.filteredRTDemands.slice(
        offset,
        offset + this.marketDemandsPageSize
      );
    } else {
      this.setRTMarketDemandDisplayMobile();
    }
  }

  /**
   * arrange the display in two columns fpr mobile view
   */
  setRTMarketDemandDisplayMobile() {
    this.rtMarketDemandDisplayMobile = [];
    let i = 1;
    while (i <= this.filteredRTDemands.length) {
      this.rtMarketDemandDisplayMobile.push([
        this.filteredRTDemands[i - 1],
        i < this.filteredRTDemands.length ? this.filteredRTDemands[i] : null
      ]);
      i += 2;
    }
  }

  getGenerationMixData() {
    this.fuelMixSubs = this.marketsService
      .getGenerationMixData(this.market)
      .subscribe((res) => {
        this.fuelMixData = res;
        // if (this.market === Market.PJM) {
        //   this.fuelMixData = this.fuelMixData.filter((item) => {
        //     return item.type !== PjmMarketSources.PJMStorage;
        //   });
        // }
        // sort from highest to lowest percentage values
        this.fuelMixData.sort((a, b) => b.percentage - a.percentage);
        this.formatSourceData();
        this.createChart();
      });
  }

  getPieColor(data: string) {
    return this.generationMixPieColors.get(
      data
        .toLowerCase()
        .split('')
        .filter((e) => e.trim().length)
        .join('') // lowercase, remove spaces
    );
  }

  getPieText(data: string) {
    return this.generationMixPieSourceMap.get(
      data
        .toLowerCase()
        .split('')
        .filter((e) => e.trim().length)
        .join('') // lowercase, remove spaces
    );
  }

  changeFullscreen() {
    this.dataService.isMarketDBFullscreen = false;
  }

  toggleMoreSources() {
    this.viewMoreSources = !this.viewMoreSources;
  }

  createChart() {
    const component = this;
    const chartName = `energy-source-donut-chart-${this.market}`;
    this.generationMixChart = Highcharts.chart(chartName, {
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      title: {
        text: ''
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      subtitle: {
        useHTML: true,
        text: this.getSubtitle(),
        floating: true,
        verticalAlign: 'middle',
        y: -20
      },
      plotOptions: {
        pie: {
          size: '100%',
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              halo: false,
              enabled: true
            },
            inactive: {
              enabled: true,
              opacity: 0.2
            }
          }
        },
        series: {
          states: {
            inactive: {
              enabled: true
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' + this.point.name + '</b>: ' + (+this.y).toFixed(2) + ' %'
          );
        }
      },
      series: [
        {
          name: 'GenMix',
          data: this.getData(),
          size: '100%',
          innerSize: '80%',
          showInLegend: true,
          type: 'pie',
          dataLabels: {
            enabled: false
          },
          point: {
            events: {
              click: function () {
                component.updateSubtitle(this);
              }
            }
          }
        }
      ]
    });
  }

  getData() {
    // sort the data according to the predefined source sequence.
    // This way the correct color is rendered from the CSS to its corresponding sources.
    const chartData: FuelMixData[] = [];
    this.generationMixPieSources.forEach((item) => {
      const found = this.fuelMixData.find(
        (data) =>
          this.getPieText(data.type).toLowerCase().trim() ===
          item.text.toLowerCase().trim()
      );
      if (found) {
        chartData.push(found);
      } else {
        const fuelData = new FuelMixData();
        fuelData.percentage = 0;
        fuelData.type = item.text;
        fuelData.gen = 0;
        chartData.push(fuelData);
      }
    });
    const output = chartData.map((mix) => {
      return {
        x: mix.gen,
        y: Number(mix.percentage),
        name: this.getPieText(mix.type)
      };
    });
    return output;
  }

  updateSubtitle(chartRef) {
    const source = this.sourceMixData.find(
      (item) => item.source === this.generationMixSources[chartRef.index]
    );
    const icon = `./assets/img/icon-${source.source
      .toLowerCase()
      .split(' ')
      .join('-')}.svg`;
    const value = +source.value.toFixed(2);
    const percentage = +source.percentage.toFixed(2);
    this.generationMixChart.setTitle(null, {
      useHTML: true,
      text:
        `
        <div style="
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #1B1B1B;
        text-align: center">
          <div>${source.source}</div>
          <div>
            <b>${value}</b> MW ${percentage}%
          </div>` +
        (source.source.toLowerCase() === 'other'
          ? ``
          : `<div>
            <img style="width: 48px;" src='${icon}'>
          </div>`) +
        `</div>`
    });
  }

  getSubtitle() {
    const source = this.sourceMixData.length ? this.sourceMixData[0] : null;
    if (source) {
      const icon = `./assets/img/icon-${source.source
        .toLowerCase()
        .split(' ')
        .join('-')}.svg`;
      const value = +source.value.toFixed(2);
      const percentage = +source.percentage.toFixed(2);
      return (
        `
    <div style="
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1B1B1B;
    text-align: center">
      <div>${source.source}</div>
      <div>
        <b>${value}</b> MW ${percentage}%
      </div>` +
        (source.source.toLowerCase() === 'other'
          ? ``
          : `<div>
        <img style="width: 48px;" src='${icon}'>
      </div>`) +
        `</div>`
      );
    }
  }

  formatSourceData() {
    this.sourceMixData = this.fuelMixData.map((data) => {
      return {
        source: this.getPieText(data.type),
        value: data.gen,
        percentage: data.percentage,
        color: this.getPieColor(data.type)
      };
    });
    this.sliceSourceMixData();
  }

  sliceSourceMixData() {
    this.sourceMixData1 = this.sourceMixData.slice(0, 3);
    this.sourceMixData2 = this.sourceMixData.slice(3);
  }

  toggleDemandComparisionOptions() {
    this.showRtMarketsDemandComparision = !this.showRtMarketsDemandComparision;
  }

  setMarketConditionStatus() {
    // TODO: check what are the statuses for PJM
    const reserves =
      this.market === Market.ERCOT
        ? ErcotMarketSources.ERCOTReserves
        : PjmMarketSources.PjmReserves;
    const status: IMarketSource = this.gridConditions.find(
      (condition) => condition.source.toUpperCase() === reserves.toUpperCase()
    );
    this.marketConditionStatus =
      status && status.title
        ? this.marketStatus[status.title.toLowerCase()]
        : this.marketStatus.normal;
  }

  toggleDemandComparisionSuboptions(option: MarketDemandComparison) {
    const selectedOption = this.demandComparisonOptions.find(
      (opt) => opt.text === option.text
    );
    selectedOption.expanded = !selectedOption.expanded;
  }

  selectDemandSubOption(option: MarketDemandComparison) {
    const selectedOption = this.demandComparisonOptions.find(
      (opt) => opt.text === option.text
    );
    if (selectedOption.items.length) {
      selectedOption.count = selectedOption.items.filter(
        (item) => item.checked
      ).length;
    }
  }

  cancelDBSettings() {
    // reset input text
    this.newMarketViewName = '';
  }

  saveDBSettings(viewName: string = null, isDefault: boolean = false) {
    // close modal
    $('#saveDBModal').modal('hide');
    this.dataService.setLoading(true);
    // add new view to the view list
    if (viewName) {
      const myProfile = this.dataService.getProfile();
      if (this.isRenamed) {
        this.dataService.marketViews = this.dataService.marketViews.map(
          (view) => {
            if (
              view.viewName.toUpperCase() ===
              this.dataService.selectedMarketViewName.toUpperCase()
            ) {
              view.viewName = viewName;
            }
            return view;
          }
        );
      } else {
        this.dataService.marketViews.push({
          mdUser: myProfile.email,
          viewName: viewName,
          viewValue: 'false',
          market: this.market
        });
      }
    }
    // if save as default is selected set the default view
    if (isDefault) {
      this.dataService.marketViews = this.dataService.marketViews.map(
        (view) => {
          view.viewValue = 'false';
          return view;
        }
      );
      const defaultView = this.dataService.marketViews.find(
        (view) =>
          view.viewName.toUpperCase().trim() === viewName.toUpperCase().trim()
      );
      defaultView.viewValue = 'true';
    } else if (viewName) {
      const currentView = this.dataService.marketViews.find(
        (view) =>
          view.viewName.toUpperCase().trim() === viewName.toUpperCase().trim()
      );
      currentView.viewValue = 'false';
    }
    this.saveAllMarketViewsSubs = this.marketsService
      .saveAllMarketViews(this.dataService.marketViews)
      .subscribe(
        (res) => {
          this.dataService.marketViews = res;
          this.dataService.setLoading(false);
        },
        (error) => {
          console.error(error);
          this.dataService.setLoading(false);
        }
      );
    // make a call to the database to save the page settings
    // assign the results to the data service db market variables
    // just save the items with 'true' fieldValues
    let settings: IMarketSetting[] = [];
    settings = settings.concat(
      this.dataService.newDbInfoMarketGraphSettings.filter(
        (item) => item.fieldValue === 'true'
      )
    );
    settings = settings.concat(
      this.dataService.newDbSnapshotMarketGraphSettings.filter(
        (item) => item.fieldValue === 'true'
      )
    );
    settings = settings.concat(
      this.dataService.newDbMarketDemandsSettings.filter(
        (item) => item.fieldValue === 'true'
      )
    );
    if (this.market === Market.PJM) {
      settings = settings.concat(
        this.dataService.newDbPjmYourListSettings.filter(
          (item) => item.fieldValue === 'true'
        )
      );
      settings = settings.concat(
        this.dataService.newDbPjmDaYourListSettings.filter(
          (item) => item.fieldValue === 'true'
        )
      );
    }
    settings = settings.map((setting) => {
      setting.viewName = viewName || this.dataService.selectedMarketViewName;
      if (viewName && !this.isRenamed) {
        setting = {
          fieldName: setting.fieldName,
          fieldValue: setting.fieldValue,
          componentUsed: setting.componentUsed,
          viewName: setting.viewName
        };
      }
      return setting;
    });
    this.dataService.setLoading(true);
    this.saveAllMarketDashboardSettingsSubs = this.marketsService
      .saveAllMarketDashboardSettings(settings, this.market)
      .subscribe(
        (res) => {
          // segregate them base on componentUsed property
          this.dataService.dbInfoMarketGraphSettings = res.filter((item) => {
            return item.componentUsed === this.marketSection.Info;
          });
          this.dataService.newDbInfoMarketGraphSettings =
            this.utilsService.clone(this.dataService.dbInfoMarketGraphSettings);
          this.dataService.dbSnapshotMarketGraphSettings = res.filter(
            (item) => {
              return item.componentUsed === this.marketSection.Snapshot;
            }
          );
          this.dataService.newDbSnapshotMarketGraphSettings =
            this.utilsService.clone(
              this.dataService.dbSnapshotMarketGraphSettings
            );
          this.dataService.dbMarketDemandsSettings = res.filter((item) => {
            return item.componentUsed === this.marketSection.RTMarketDemand;
          });
          this.dataService.newDbMarketDemandsSettings = this.utilsService.clone(
            this.dataService.dbMarketDemandsSettings
          );
          if (this.market === Market.PJM) {
            this.dataService.dbPjmYourListSettings = res.filter((item) => {
              return item.componentUsed === PJMPricesTypes.YourList;
            });
            this.dataService.newDbPjmYourListSettings = this.utilsService.clone(
              this.dataService.dbPjmYourListSettings
            );
            this.dataService.dbPjmDaYourListSettings = res.filter((item) => {
              return item.componentUsed === PJMPricesTypes.YourListDA;
            });
            this.dataService.newDbPjmDaYourListSettings =
              this.utilsService.clone(this.dataService.dbPjmDaYourListSettings);
          }
          // TODO: if needed add a new set for tracking changes to your list in PJM prices tile.
          // updating the default view
          const defaultView = this.dataService.marketViews.find(
            (view) => view.viewValue === 'true'
          );
          this.dataService.defaultMarketViewName = defaultView
            ? defaultView.viewName
            : this.dataService.marketViews[0].viewName;
          // updating the selected view. This will trigger the dashboard reload.
          if (viewName) {
            this.dataService.selectedMarketViewName = viewName;
          }
          this.dataService.setLoading(false);
        },
        (error) => {
          console.error(error);
          this.dataService.setLoading(false);
        }
      );
  }

  savemarketdb(
    openModal: boolean = false,
    defaultMarketViewName: string = null,
    isRenamed: boolean = false
  ) {
    if (!isRenamed && this.marketsService.settingsNotChanged) {
      return;
    }
    if (
      openModal ||
      this.dataService.defaultMarketViewName.toUpperCase() ===
        DefaultViewName.toUpperCase()
    ) {
      if (defaultMarketViewName) {
        this.newMarketViewName = defaultMarketViewName;
      }
      this.isRenamed = isRenamed;
      // works as "save as". open modal.
      $('#saveDBModal').modal('show');
    } else {
      this.isRenamed = isRenamed;
      // save the current selection
      this.saveDBSettings();
    }
  }

  deleteMarketDb() {
    $('#deleteDBModal').modal('show');
  }

  deleteDBSettings() {
    // close modal
    $('#deleteDBModal').modal('hide');
    // return;
    this.dataService.setLoading(true);
    // delete the market view
    // delete the corresponding settings
    this.deleteDBSettingsSubs = this.marketsService
      .deleteMarketView(this.dataService.selectedMarketViewName, this.market)
      .pipe(
        switchMap(() =>
          this.marketsService.deleteMarketSettings(
            this.dataService.selectedMarketViewName,
            this.market
          )
        )
      )
      .subscribe(
        (res) => {
          this.getDashboardViews();
          this.dataService.setLoading(false);
        },
        (error) => {
          this.dataService.setLoading(false);
          console.error('Error while deleting dashboard settings: ', error);
        }
      );
  }

  get viewNameChanged() {
    return (
      this.newMarketViewName &&
      this.newMarketViewName.trim().length &&
      !!!this.dataService.marketViews.find(
        (setting) =>
          setting.viewName.toUpperCase().trim() ===
          this.newMarketViewName.toUpperCase().trim()
      )
    );
  }

  openDBSettingsSaveOptions() {
    this.dataService.openMDBSaveOptions = !this.dataService.openMDBSaveOptions;
  }

  toggleMDBViewOptions() {
    this.dataService.openMDBViewOptions = !this.dataService.openMDBViewOptions;
  }

  increaseMarketDemandsOffset() {
    if (
      this.marketDemandsOffset >= 0 &&
      this.marketDemandsOffset + this.marketDemandsPageSize <
        this.filteredRTDemands.length
    ) {
      this.setRTMarketDemandDisplay(++this.marketDemandsOffset);
    }
  }

  decreaseMarketDemandsOffset() {
    if (
      this.marketDemandsOffset > 0 &&
      this.marketDemandsOffset + this.marketDemandsPageSize <=
        this.filteredRTDemands.length
    ) {
      this.setRTMarketDemandDisplay(--this.marketDemandsOffset);
    }
  }

  get selectedDemandComparisonsCount(): number {
    let selectCount = 0;
    this.demandComparisonOptions.forEach((option) => {
      if (option.items.length) {
        selectCount += option.items.filter((item) => item.checked).length;
      } else if (option.checked) {
        selectCount++;
      }
    });
    return selectCount;
  }

  filterDemandComparisonOptions() {
    this.toggleDemandComparisionOptions();
    this.resetComparisonDisplay();
    this.origDemandComparisonOptions = this.utilsService.clone(
      this.demandComparisonOptions
    );
    this.dataService.newDbMarketDemandsSettings = this.processDemandSettings();
    this.marketsService.setSettingsIdData(
      this.dataService.dbMarketDemandsSettings,
      this.dataService.newDbMarketDemandsSettings
    );
    this.setRTMarketDemands();
    this.marketDemandsOffset = 0;
    this.setRTMarketDemandDisplay(this.marketDemandsOffset);
    this.dataService.setMarketDemandSettings(
      this.utilsService.clone(this.origDemandComparisonOptions)
    );
  }

  resetDemandComparisonOptions() {
    this.toggleDemandComparisionOptions();
    this.resetComparisonDisplay();
    this.dataService.newDbMarketDemandsSettings = this.utilsService.clone(
      this.dataService.dbMarketDemandsSettings
    );
    this.setDbMarketDemandSettings();
    this.demandComparisonOptions = this.utilsService.clone(
      this.origDemandComparisonOptions
    );
    this.setRTMarketDemands();
    this.marketDemandsOffset = 0;
    this.setRTMarketDemandDisplay(this.marketDemandsOffset);
  }

  revertDemandComparisonOptions() {
    this.toggleDemandComparisionOptions();
    this.demandComparisonOptions = this.utilsService.clone(
      this.origDemandComparisonOptions
    );
    this.resetComparisonDisplay();
  }

  resetComparisonDisplay() {
    this.demandComparisonOptions = this.demandComparisonOptions.map(
      (option) => {
        if (option.items.length) {
          option.expanded = false;
        }
        return option;
      }
    );
  }

  processDemandSettings(): IMarketSetting[] {
    let iMarketSettings: IMarketSetting[] = [];
    this.origDemandComparisonOptions.forEach((option) => {
      if (option.items.length) {
        option.items.forEach((item) => {
          iMarketSettings.push({
            fieldName: item.source,
            fieldValue: item.checked.toString(),
            componentUsed: this.marketSection.RTMarketDemand,
            viewName: this.dataService.selectedMarketViewName
          });
        });
      } else {
        iMarketSettings.push({
          fieldName: option.source,
          fieldValue: option.checked.toString(),
          componentUsed: this.marketSection.RTMarketDemand,
          viewName: this.dataService.selectedMarketViewName
        });
      }
    });
    return iMarketSettings;
  }

  processPjmYourListSettings(rtPrices: IMarketSource[], type: string) {
    let iMarketSettings: IMarketSetting[] = [];
    rtPrices.forEach((item) => {
      iMarketSettings.push({
        fieldName: item.source,
        fieldValue: 'true',
        componentUsed: type,
        viewName: this.dataService.selectedMarketViewName
      });
    });
    return iMarketSettings;
  }

  getFullScreenDate() {
    return `${momentTz
      .tz(
        new Date(),
        this.market === Market.PJM ? 'America/New_York' : 'America/Chicago'
      )
      .format('llll')} ${this.timezone}`;
  }

  getFullScreenTime() {
    return `${momentTz
      .tz(
        new Date(),
        this.market === Market.PJM ? 'America/New_York' : 'America/Chicago'
      )
      .format('hh:mm A ')} ${this.timezone}`;
  }

  getFormattedTime(option: IMarketSource) {
    return `${momentTz
      .tz(
        option.dates[0],
        this.market === Market.PJM ? 'America/New_York' : 'America/Chicago'
      )
      .format('hh:mm A ')} ${this.timezone}`;
  }

  getGenerationMixFormattedTime(option: FuelMixData) {
    return `${momentTz
      .tz(
        option.lastUpdated,
        this.market === Market.PJM ? 'America/New_York' : 'America/Chicago'
      )
      .format('hh:mm A ')} ${this.timezone}`;
  }

  getFormattedMonthDay(option: IMarketSource) {
    return `${momentTz
      .tz(
        option.dates[0],
        this.market === Market.PJM ? 'America/New_York' : 'America/Chicago'
      )
      .format('MM/DD')}`;
  }

  getPjmZoneData(pjmLzs) {
    const pjmLzsFormatted = [];
    const rtPrice: IMarketSource = {
      source: null,
      title: null,
      dates: [],
      values: []
    };
    const pjmLzs1 = pjmLzs.slice(0, Math.ceil(pjmLzs.length / 2));
    const pjmLzs2 = pjmLzs.slice(Math.ceil(pjmLzs.length / 2));
    if (pjmLzs2.length < pjmLzs1.length) {
      pjmLzs2.push(rtPrice);
    }
    pjmLzs1.forEach((item, i) => {
      pjmLzsFormatted.push([pjmLzs1[i], pjmLzs2[i]]);
    });
    return pjmLzsFormatted;
  }

  getPjmHubData(pjmHbs) {
    const pjmHbsFormatted = [];
    const rtPrice: IMarketSource = {
      source: null,
      title: null,
      dates: [],
      values: []
    };
    const pjmHbs1 = pjmHbs.slice(0, Math.ceil(pjmHbs.length / 2));
    const pjmHbs2 = pjmHbs.slice(Math.ceil(pjmHbs.length / 2));
    if (pjmHbs2.length < pjmHbs1.length) {
      pjmHbs2.push(rtPrice);
    }
    pjmHbs1.forEach((item, i) => {
      pjmHbsFormatted.push([pjmHbs1[i], pjmHbs2[i]]);
    });
    return pjmHbsFormatted;
  }

  getPjmInfData(pjmInfs) {
    const pjmInfsFormatted = [];
    const rtPrice: IMarketSource = {
      source: null,
      title: null,
      dates: [],
      values: []
    };
    const pjmInfs1 = pjmInfs.slice(0, Math.ceil(pjmInfs.length / 2));
    const pjmInfs2 = pjmInfs.slice(Math.ceil(pjmInfs.length / 2));
    if (pjmInfs2.length < pjmInfs1.length) {
      pjmInfs2.push(rtPrice);
    }
    pjmInfs1.forEach((item, i) => {
      pjmInfsFormatted.push([pjmInfs1[i], pjmInfs2[i]]);
    });
    return pjmInfsFormatted;
  }

  getPjmYourListData() {
    // caching your list values fetched from the db to avoid multiple db calls
    this.pjmYourListRes = this.utilsService.clone(this.pjmYourLists);
    this.pjmYourListsFormatted = [];
    const rtPrice: IMarketSource = {
      source: null,
      title: null,
      dates: [],
      values: []
    };
    const pjmYourLists1 = this.pjmYourLists.slice(
      0,
      Math.ceil(this.pjmYourLists.length / 2)
    );
    const pjmYourLists2 = this.pjmYourLists.slice(
      Math.ceil(this.pjmYourLists.length / 2)
    );
    if (pjmYourLists2.length < pjmYourLists1.length) {
      pjmYourLists2.push(rtPrice);
    }
    pjmYourLists1.forEach((item, i) => {
      this.pjmYourListsFormatted.push([pjmYourLists1[i], pjmYourLists2[i]]);
    });
  }

  getPjmDaYourListData() {
    // caching your list values fetched from the db to avoid multiple db calls
    this.pjmDaYourListRes = this.utilsService.clone(this.pjmDaYourLists);
    this.pjmDaYourListsFormatted = [];
    const rtPrice: IMarketSource = {
      source: null,
      title: null,
      dates: [],
      values: []
    };
    const pjmDaYourLists1 = this.pjmDaYourLists.slice(
      0,
      Math.ceil(this.pjmDaYourLists.length / 2)
    );
    const pjmDaYourLists2 = this.pjmDaYourLists.slice(
      Math.ceil(this.pjmDaYourLists.length / 2)
    );
    if (pjmDaYourLists2.length < pjmDaYourLists1.length) {
      pjmDaYourLists2.push(rtPrice);
    }
    pjmDaYourLists1.forEach((item, i) => {
      this.pjmDaYourListsFormatted.push([
        pjmDaYourLists1[i],
        pjmDaYourLists2[i]
      ]);
    });
  }

  toggleYourListMenu() {
    this.showYourListMenu = !this.showYourListMenu;
    this.isHubsExpanded = false;
    this.isInfsExpanded = false;
    this.isZonesExpanded = false;
    this.yourListZonesCount = this.pjmYourLists.filter(
      (item) => item.source.indexOf('LZ_') > -1
    ).length;
    this.yourListHubsCount = this.pjmYourLists.filter(
      (item) => item.source.indexOf('HB_') > -1
    ).length;
    this.yourListInfsCount = this.pjmYourLists.filter(
      (item) => item.source.indexOf('INF_') > -1
    ).length;
    this.yourListSelectionCount =
      this.yourListZonesCount + this.yourListHubsCount + this.yourListInfsCount;
    this.yourListSelection = this.utilsService.clone(this.pjmYourLists);
  }

  toggleYourListSelection(value: IMarketSource) {
    const findIndex = this.yourListSelection.findIndex(
      (item) => item.source.toLowerCase() === value.source.toLowerCase()
    );
    if (findIndex >= 0) {
      if (value.source.indexOf('LZ_') > -1) {
        this.yourListZonesCount--;
      } else if (value.source.indexOf('HB_') > -1) {
        this.yourListHubsCount--;
      } else if (value.source.indexOf('INF_') > -1) {
        this.yourListInfsCount--;
      }
      this.yourListSelection.splice(findIndex, 1);
      this.yourListSelectionCount--;
    } else {
      if (value.source.indexOf('LZ_') > -1) {
        this.yourListZonesCount++;
      } else if (value.source.indexOf('HB_') > -1) {
        this.yourListHubsCount++;
      } else if (value.source.indexOf('INF_') > -1) {
        this.yourListInfsCount++;
      }
      this.yourListSelection.push(value);
      this.yourListSelectionCount++;
    }
  }

  filterYourListSelection() {
    this.pjmYourLists = this.utilsService.clone(this.yourListSelection);
    this.toggleYourListMenu();
    this.getPjmYourListData();
    this.dataService.newDbPjmYourListSettings = this.processPjmYourListSettings(
      this.pjmYourLists,
      PJMPricesTypes.YourList
    );
    this.marketsService.setSettingsIdData(
      this.dataService.dbPjmYourListSettings,
      this.dataService.newDbPjmYourListSettings
    );
  }

  resetYourListSelection() {
    this.dataService.newDbPjmYourListSettings = this.utilsService.clone(
      this.dataService.dbPjmYourListSettings
    );
    this.mapPJMRtMarketYourListPrices(this.pjmYourLists);
    this.toggleYourListMenu();
  }

  isYourListItemSelected(value: IMarketSource) {
    return !!this.yourListSelection.find(
      (item) => item.source.toLowerCase() === value.source.toLowerCase()
    );
  }

  toggleZonesMenu() {
    this.isZonesExpanded = !this.isZonesExpanded;
  }

  toggleHubsMenu() {
    this.isHubsExpanded = !this.isHubsExpanded;
  }

  toggleInfsMenu() {
    this.isInfsExpanded = !this.isInfsExpanded;
  }

  switchTab(type: string) {}

  toggleDaYourListMenu() {
    this.showDaYourListMenu = !this.showDaYourListMenu;
    this.isDaHubsExpanded = false;
    this.isDaInfsExpanded = false;
    this.isDaZonesExpanded = false;
    this.daYourListZonesCount = this.pjmDaYourLists.filter(
      (item) => item.source.indexOf('LZ_') > -1
    ).length;
    this.daYourListHubsCount = this.pjmDaYourLists.filter(
      (item) => item.source.indexOf('HB_') > -1
    ).length;
    this.daYourListInfsCount = this.pjmDaYourLists.filter(
      (item) => item.source.indexOf('INF_') > -1
    ).length;
    this.daYourListSelectionCount =
      this.daYourListZonesCount +
      this.daYourListHubsCount +
      this.daYourListInfsCount;
    this.daYourListSelection = this.utilsService.clone(this.pjmDaYourLists);
  }

  toggleDaYourListSelection(value: IMarketSource) {
    const findIndex = this.daYourListSelection.findIndex(
      (item) => item.source.toLowerCase() === value.source.toLowerCase()
    );
    if (findIndex >= 0) {
      if (value.source.indexOf('LZ_') > -1) {
        this.daYourListZonesCount--;
      } else if (value.source.indexOf('HB_') > -1) {
        this.daYourListHubsCount--;
      } else if (value.source.indexOf('INF_') > -1) {
        this.daYourListInfsCount--;
      }
      this.daYourListSelection.splice(findIndex, 1);
      this.daYourListSelectionCount--;
    } else {
      if (value.source.indexOf('LZ_') > -1) {
        this.daYourListZonesCount++;
      } else if (value.source.indexOf('HB_') > -1) {
        this.daYourListHubsCount++;
      } else if (value.source.indexOf('INF_') > -1) {
        this.daYourListInfsCount++;
      }
      this.daYourListSelection.push(value);
      this.daYourListSelectionCount++;
    }
  }

  filterDaYourListSelection() {
    this.pjmDaYourLists = this.utilsService.clone(this.daYourListSelection);
    this.toggleDaYourListMenu();
    this.getPjmDaYourListData();
    this.dataService.newDbPjmDaYourListSettings =
      this.processPjmYourListSettings(
        this.pjmDaYourLists,
        PJMPricesTypes.YourListDA
      );
    this.marketsService.setSettingsIdData(
      this.dataService.dbPjmDaYourListSettings,
      this.dataService.newDbPjmDaYourListSettings
    );
  }

  resetDaYourListSelection() {
    this.dataService.newDbPjmDaYourListSettings = this.utilsService.clone(
      this.dataService.dbPjmDaYourListSettings
    );
    this.mapPJMDaMarketYourListPrices(this.pjmDaYourLists);
    this.toggleYourListMenu();
  }

  isDaYourListItemSelected(value: IMarketSource) {
    return !!this.daYourListSelection.find(
      (item) => item.source.toLowerCase() === value.source.toLowerCase()
    );
  }

  toggleDaZonesMenu() {
    this.isDaZonesExpanded = !this.isDaZonesExpanded;
  }

  toggleDaHubsMenu() {
    this.isDaHubsExpanded = !this.isDaHubsExpanded;
  }

  toggleDaInfsMenu() {
    this.isDaInfsExpanded = !this.isDaInfsExpanded;
  }

  isForecastedPeakLoad(option) {
    return option.source.match('_FORECASTED_PEAK_LOAD');
  }

  isOutages(option) {
    return option.source.match('_OUTAGES');
  }
}
