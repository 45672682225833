// Created by Ramalingam - May 2020
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalService } from '../../shared/portal.service';
import { DataService } from '../../shared/data.service';
import { AccordionAnimation } from '../../animations/accordionAnimation';
//import { SitesControl } from '../../shared/entities/controls';
import { FadeAnimation } from '../../animations/fadeAnimation';
import { FadeInAnimation } from '../../animations/fadeInAnimation';
import { Subscription } from 'rxjs';
import { ISiteGroup, ISite } from '../../shared/entities/site';
import { IUserAccount } from '../../shared/entities/profile';
import { SiteGroupService } from '../../site-groups/site-groups.service';
import { RealtimeUsageService } from './realtime-usage.service';
import { IUsageGraphRequest, IUsageGraph, IUsageGraphPoint } from '../../shared/entities/usageGraph';
import { IRealTimeGraph, IRealTimePoint } from '../../shared/entities/realtimeUsage';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import {
  IMarketData, IUMarketComparison, ErcotSRnapshotProperties, IRMarketProperties, IRMarketComparison, IUMarketProperties, ErcotIUnfoProperties, IUMarkets
} from '../../shared/entities/markets';
import { IRiskLegAttribute } from '../../shared/entities/position';
import { ILayersList } from '../../position/month-breakdown';
import { ForwardPriceForm } from '../../markets/forward-price-form/forward-price.form';
import { IZone } from '../../shared/entities/product';
declare var Highcharts: any;
declare var $: any;
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import { Router } from '@angular/router';

@Component({
  selector: 'app-realtime-usage',
  templateUrl: './realtime-usage.component.html',
  animations: [FadeAnimation, FadeInAnimation, AccordionAnimation],
  styleUrls: ['./realtime-usage.component.scss']
})
export class RealtimeUsageComponent implements OnInit, OnDestroy {

  setAsDefault = false;
  firstTime = true;
  isValidGroupSelect = true;
  isValidContractSelect = true;
  value = 1; selectedOption: string;
  tempSelectedOption: string;
  tempSelCat: string;
  tempSelectedSite: string;
  tempSelectedContract: string;
  tempSelectedContrac: string;

  // modify: boolean = false; apply: boolean = true;
  modify = false; apply = false; cancel = false;
  myAccount: IUserAccount; //sitesControl: SitesControl;
  request: IUsageGraphRequest = {}; minDate: Date;
  defaultResponse:IUsageGraphRequest = {};
  selectedGroupObj: any;
  selectedStartDate: Date; selectedEndDate: Date; showDateRange = false;
  showDateRangeError = false; dateRangeDisplay: string;
  userSiteGroups: ISiteGroup[] = [];
  selectedGroup: string; selectedGroupId: number; selectedContract: string;
  tempSelectedGroup: string;
  selectedContrac: any;
  systemSiteGroups: ISiteGroup[] = []; allSites: ISite[];
  totalSites: ISite[]; shadowSites: ISite[]; shadowCategory: any;
  shadowDemand: IRealTimeGraph; selectedCategory: any; selectedProduct: any;
  selectedSite: any; shadowGroups: any; shadowContracts: any;
  shadowProducts: any;
  // selectedSite: boolean = true;
  groupSelected = false; contractSelected = false;
  marketYAxis: any[]; totalSitesValue = 0; shadowSitesValue = 0;
  shadowGroupsValue = 0; shadowCategoryValue = 0;
  shadowContractsValue = 0; shadowProductsValue = 0;
  daylightSavings = false; noDataError = true;
  datasets: any[]; selectedOffset = 0; properties: IRMarketProperties;
  showComparisons = false; selectedComparisons: IRMarketComparison[] = [];
  rshowComparisons = false; rselectedComparisons: IUMarketComparison[] = [];
  lvalue = 0;
  lsitecount = 0;
  // defaultComparisons: IUMarketComparison[] = [];
  //  legendSelection : IRMarketComparison[] = [];


  ctrColor = 0; ctr = 0; maxSelect = false;
  showChartLegend = true; bIndex = false;
  newdatasets: any[]; mCtr = 0; bshowGvalue = true;
  sRequest: string; expandedMarket = ''; expandedGroup = '';
  dispLastVal: string; shContract = false;

  marketData: IMarketData[];
  marketDataTicker = 0; marketsLoading = false; shadowData = true;
  isDayLightSavings = false;

  blockData: any[] = [];
  ublockData: any[] = [];
  hblockData: any[] = [];
  intervalDetailsData: object = {};
  currentIntervalDetails: IRiskLegAttribute[];
  showIntervalDetails = true; currentIntervalDateDisplay: string;
  bshowIntervalDetails = false;
  showBlockDetails = false; rshowBlockDetails = false;
  currentIntervalTimeDisplay: string; isZoom = false;

  rintervalDetailsData: object = {};
  rcurrentIntervalDetails: IRiskLegAttribute[];
  rshowIntervalDetails = true; rcurrentIntervalDateDisplay: string;
  rcurrentIntervalTimeDisplay: string;

  rproperties: IUMarketProperties; target: IUsageGraph; hrequest: IUsageGraphRequest = {};
  isMarketData = false; graphData: IUsageGraph; allPoints: IUsageGraphPoint[];
  rdatasets: any[]; totalUsage: number; peakHourlyDemand: number; averageHourlyUsage: number;
  peakDemandHr: string; rmarketYAxis: any[]; rnoDataError = true;
  rmarketsLoading = false; rmCtr = 0;
  rmarketData: IMarketData[]; rmarketDataTicker = 0;
  // graphValues: IRealTimelvalue[];
  realChart: any; realCharth: any;
  graphValues: any; lTime: any;
  allTPoints: IUsageGraphPoint[]; demLegend = false; snapSitesValue = 0;


  accountSourceSubscription: Subscription;
  getSiteGroupsSubscription: Subscription;
  siteCountsSubscription: Subscription;
  refreshSubscription: Subscription;
  actualForecastBlockIndexSubscription: Subscription;
  exportTriggeredSubscription: Subscription;
  maskModeSubscription: Subscription;
  maskMode = false;

  layers: ILayersList = defaultLayers;

  tempHrequest: IUsageGraphRequest = null;

  constructor(private realtimeUsageService: RealtimeUsageService,
    private dataService: DataService,
    private portalService: PortalService,
    private siteGroupsService: SiteGroupService,
    // private fakerService: FakerService,
    private router: Router
  ) { }

  ngOnInit() {
      // this.sitesControl = new SitesControl();
      // this.selectedOption = 'ALL_SITES';
      this.firstTime = true;
      this.myAccount = this.dataService.getAccountSource();
      
      this.totalSites = this.dataService.getSitesForAccountSource();
      this.properties = ErcotSRnapshotProperties;
      this.maskMode = this.dataService.getMaskMode();
      this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
        this.maskMode = maskMode;
      });
      this.dataService.showExportUsageDetail.emit(true);
      this.properties.comparisons.forEach((comparison, index) => {
        // this.defaultComparisons.push(defaultComparison);
        this.properties.comparisons[index].selected = false;
        this.properties.comparisons[index].expandedValue = false;
      });

      this.rproperties = ErcotIUnfoProperties;
      this.rproperties.market.forEach((market, index) => {
        this.rproperties.market[index].comparisons.forEach((comparison, index2) => {
        // this.defaultComparisons.push(defaultComparison);
        this.rproperties.market[index].comparisons[index2].selected = false;
        this.rproperties.market[index].comparisons[index2].expandedValue = false;
        this.rproperties.market[index].comparisons[index2].expandColor = false;
        });
      });
    
      if (this.myAccount) {    
            this.portalService.spinBabySpin('defaultLoader');
      
            this.getDefault();       
            this.getShadowSites();
            this.getShadowGroups();
            this.getShadowContracts();
            
            
            
         //   if (this.selectedOption==='ALL_SITES' || this.defaultResponse.defaultReport==="0") {
         //    console.log(this.defaultResponse);
            //  if (this.defaultResponse===null  || !this.defaultResponse || !this.defaultResponse.siteRequest) {
            //   console.log("IN IF");
            //   this.applyChanges();
            //   this.rloadDefaultGraph();
            // }
           // setTimeout(() => {
                // this.getDefault(); //=> {
                // this.applyChanges();
                // this.rloadDefaultGraph();
            //   });
            // }, 5000);            
      }
      this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
      });

      const siteGroups = this.dataService.getSiteGroupsSource();
      if (siteGroups) {
        this.systemSiteGroups = siteGroups.systemSiteGroups;
        this.userSiteGroups = siteGroups.userSiteGroups;
       // this.loadSiteOptions();
      }

      this.getSiteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe((siteGroups) => {
        this.systemSiteGroups = siteGroups.systemSiteGroups;
        this.userSiteGroups = siteGroups.userSiteGroups;
      //  this.loadSiteOptions();
      });

      if (this.userSiteGroups.length == 0)
        this.isValidGroupSelect = false;


      this.exportTriggeredSubscription = this.dataService.exportTriggered.subscribe(() => {
        // this.dataService.setLoading(true);
        this.exportGraph();
      });

      if (this.totalSites) {
        this.totalSitesValue = this.totalSites.length;
      }

      if (this.refreshSubscription) {
        this.refreshSubscription.unsubscribe();
      }
      this.refreshSubscription = IntervalObservable.create(60000).subscribe(() => {
        if (!this.noDataError) {
          this.rFresh();
        }
      });
      
 
    //  if (this.selectedOption == 'CONTRACT'){
    //    if(this.selectedProduct.name.includes('Block & Index')){
    //      this.bIndex = true;
    //     //  this.populateGraphData();
    //    }
    //  }

  }


  ngOnDestroy() {
    // this.rproperties.comparisons.forEach((comp) => {
    //   comp.selected = false;
    //   comp.expandColor = false;
    // });
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }

    if (this.siteCountsSubscription) this.siteCountsSubscription.unsubscribe();
    if (this.getSiteGroupsSubscription) this.getSiteGroupsSubscription.unsubscribe();
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.actualForecastBlockIndexSubscription) this.actualForecastBlockIndexSubscription.unsubscribe();
    if (this.exportTriggeredSubscription) this.exportTriggeredSubscription.unsubscribe();
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }
  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }
  getFakeSiteAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  toggleComparisons() {
    this.showComparisons = !this.showComparisons;
  }

  goToSiteGroups() {
    this.router.navigate(['/site-groups']);
  }

  getShadowGroups() {
    this.realtimeUsageService.getShadowGroups(this.myAccount.id).subscribe(
      (response) => {
        this.shadowGroups = response;        
          if (this.shadowGroups) {
            this.shadowGroupsValue = this.shadowGroups.length;
            if (this.shadowGroups.length > 0) {
              this.selectedGroupObj = this.shadowGroups[0];
              this.selectGroup(this.shadowGroups[0]);
            }            
          }          
      }
    );
  }


  getShadowContracts() {
    this.realtimeUsageService.getShadowContracts(this.myAccount.id).subscribe(
      (response) => {
        this.shadowContracts = response;
        if (this.shadowContracts) {
          this.shadowContractsValue = this.shadowContracts.length;
        }

        if(this.defaultResponse.productId){
          if (this.shadowContracts && this.shadowContracts.length > 0) {
            this.shadowContracts.forEach( (element) => {
             if(element.contractId && this.defaultResponse.contractId && element.contractId===this.defaultResponse.contractId)
              {
                this.selectContract(element);
              }
            });
          }
        }

        if (this.shadowContractsValue > 0) {
          this.shContract = true;
          this.selectContract(this.shadowContracts[0]);
        }
           
        if (this.defaultResponse.siteRequest == 'PRODUCT_ID') {
          if(this.firstTime) {
            this.executeDefault(); //=> {
            this.applyChanges();
            this.rloadDefaultGraph();
            this.firstTime = false;
          }
        }
      }
    );

  }



  selectGroup(group: any) {
    this.selectedGroupObj = group;
    this.selectedGroup = group.groupName;

    this.selectedGroupId = group.groupId;
    if (this.selectedGroup.length > 0) {
      this.groupSelected = true;
      this.isValidGroupSelect = true;
    }
    else {
      this.isValidGroupSelect = false;
    }

    this.realtimeUsageService.getShadowCategory(this.myAccount.id, group.id).subscribe(
      (response) => {
        this.shadowCategory = response;
        if(this.defaultResponse.groupCategory){
          if (this.shadowCategory && this.shadowCategory.length > 0) {
            this.shadowCategory.forEach( (element) => {
              
              if(element.groupId && this.defaultResponse.groupId && element.groupId===this.defaultResponse.groupId)
              {
                this.selectedCategory = element;
                this.shadowCategoryValue = this.shadowCategory.length;
              }
            });
          }
        }
        else if (this.shadowCategory) {
          this.selectedCategory = this.shadowCategory[0];
          this.shadowCategoryValue = this.shadowCategory.length;
        }
        if (this.defaultResponse.siteRequest == 'GROUP_CATEGORY') 
        {
          if(this.firstTime) {
            this.executeDefault(); //=> {
            this.applyChanges();
            this.rloadDefaultGraph();
            this.firstTime = false;
          }
        }
      },
        err => {
          if (err.status == 404) {
            this.shadowDemand = null;
            this.noDataError = true;
          }
        }
    );
 
  }

  selectContract(contract: any) {
    this.selectedContract = contract.id;
    this.selectedContrac = contract;

    if (this.selectedContract.length > 0) {
      this.contractSelected = true;
      this.isValidContractSelect = true;
    }
    else
      this.isValidContractSelect = false;

    this.shadowContracts.forEach((sContract) => {
      if (sContract.id == contract.id) {
        this.shadowProducts = sContract.products;
      }
    });

    if (this.shadowProducts) {
      this.selectedProduct = this.shadowProducts[0];
      this.shadowProductsValue = this.shadowProducts.length;
    }
  }

  public getDateDisplay(dateString: string) {
    let dateStr = this.portalService.getDateDisplay(dateString);
    if (dateStr && dateStr.includes('9999')) {
      dateStr = '';
    }
    return dateStr;
  }

  selectSite(site: any) {
    this.selectedSite = site;
  }

  selectCategory(category: any) {
    this.selectedCategory = category;
  }

  getDefault() {
    this.realtimeUsageService.getDefault(this.myAccount.id).subscribe(
     (response) => {            
          this.defaultResponse = response;
          if (this.defaultResponse  &&  !this.defaultResponse.siteRequest) {
            this.selectedOption = 'ALL_SITES';
            this.applyChanges();
            this.rloadDefaultGraph();
            this.firstTime = false;
          } 
        }, 
        err => {
          if (err.status == 404) {
            this.shadowDemand = null;
            this.noDataError = true;
          }
        }        
        );
    }

  executeDefault() {
          if( Object.keys(this.defaultResponse).length>0) {
            //this.setAsDefault = true;
            this.selectedOption = this.defaultResponse.siteRequest;
            if (this.defaultResponse.siteRequest == 'ALL_SITES') {
              this.selectedOption = 'ALL_SITES';
            }
            if (this.defaultResponse.siteRequest == 'SPECIFIC_SITE') {
                if(this.shadowSites) {
                     if (this.shadowSites.length > 0) {
                        this.shadowSites.forEach( (element) => {
                        if(element.siteId && this.defaultResponse.siteId && element.siteId===this.defaultResponse.siteId)
                          {
                            this.selectSite(element);                            
                          }
                        });
                      }
                }
                this.selectedOption = 'SP_SITE';
            }
            if (this.defaultResponse.siteRequest == 'GROUP_CATEGORY') {
                 this.selectedOption = 'GROUP_CATEGORY';
                 if (this.shadowGroups) {
                      this.shadowGroupsValue = this.shadowGroups.length;
                      if (this.shadowGroups.length > 0) {
                        this.shadowGroups.forEach( (element) => {
                        if(element.id && this.defaultResponse.groupId && element.id===this.defaultResponse.groupId)
                          {
                            this.selectedGroupObj = element;
                            //this.selectGroup(element);                            
                          }
                        });
                      }
                    }
            }
            if (this.defaultResponse.siteRequest == 'PRODUCT_ID') {
                 this.selectedOption = 'CONTRACT';
                 if(this.shadowContracts) {
                  if (this.shadowContracts.length > 0) {
                    this.shadowContracts.forEach( (element) => {
                    if(element.id && this.defaultResponse.contractId && element.id===this.defaultResponse.contractId)
                      {
                        this.selectContract(element);                            
                      }
                    });
                  }
                 }                
            }
          }
  }

  getShadowSites() {
    this.realtimeUsageService.getShadowSites(this.myAccount.id).subscribe(
      (response) => {
        console.log("getShadowSites >> ", response);
        this.shadowSites = response;
         if (this.shadowSites) {
          this.shadowSitesValue = this.shadowSites.length;
          if (this.shadowSitesValue == 1)
            this.selectedOption = 'SP_SITE';
          else
            this.selectedOption = 'ALL_SITES';

          this.selectedSite = this.shadowSites[0];
          if (this.selectedOption == 'SP_SITE') {
            this.snapSitesValue = 1;
          }
          else {
            this.snapSitesValue = this.shadowSitesValue;
          }
          if (this.defaultResponse.siteRequest == 'SPECIFIC_SITE' || this.defaultResponse.siteRequest =='ALL_SITES') 
          {
            if(this.firstTime) {
              this.executeDefault();
              this.applyChanges();
              this.rloadDefaultGraph();
              this.firstTime = false;
            }
          }
        }
      }
    );
  }

  getZoneDisplay(market, congestionZones: IZone[]) {
    if (!congestionZones || congestionZones.length === 0 || !market) {
      return '--';
    }
    if (congestionZones.length === 1) {
      return ForwardPriceForm.getHumanZoneName(market, congestionZones[0].congestionZone);
    }
    return congestionZones.map(zone => ForwardPriceForm.getHumanZoneName(market, zone.congestionZone) + ' ' + zone.percentage + '%').join(', ');
  }

  // loadSiteOptions() {
  //   this.siteGroupsService.getSitesForSystemGroup(this.myAccount.id, 'ALL_SITES').subscribe(
  //     (sites) => {
  //       this.allSites = sites;
  //       sites.forEach((site) => {
  //         this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
  //       });
  //     }
  //   );
  // }

  populateGraphData(flag: string) {
    // this.dataService.setLoading(true);

    const capeProductFlg = this.selectedProduct.name === 'Block & Index - IDR Only' ? 'Y' : 'N';
    let request;
    if (flag == 'usage') {
      request = {
        contractId: this.selectedContract,
        productId: this.selectedProduct.id,
        productName: this.selectedProduct.name,
        accountId: this.myAccount.id,
        startDate: moment().subtract(this.selectedOffset, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(0, 'days').format('YYYY-MM-DD'),
        capeProductFlg: capeProductFlg
      };
    }

    // this.hrequest.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
    //     this.hrequest.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');

    if (flag == 'history') {
      request = {
        contractId: this.selectedContract,
        productId: this.selectedProduct.id,
        productName: this.selectedProduct.name,
        accountId: this.myAccount.id,
        startDate: this.hrequest.startDate,
        endDate: this.hrequest.endDate,
        capeProductFlg: capeProductFlg
      };

    }


    // Reset data. Future TODO: Add logic to not call backend api again if the new range is within the old date range
    // this.verticalLine = {};
    // this.layers = defaultLayers;
    // this.actualData = this.forecastData = this.blockData = this.BMQData = this.indexData = [];
    this.isDayLightSavings = false;
    //  this.noDataError = false;
    this.blockData = [];
    //  this.ublockData = []
    //  this.hblockData = []
    Promise.all([
      this.getActualForecastBlockData(request, flag),
      // this.getBMQContractData(request)
    ]).then(() => {
      // this.checkDataExist();
      // this.dataService.setLoading(false);
    }).catch(() => {
      // this.checkDataExist();
      // this.dataService.setLoading(false);
    });
  }

  getContractNickname(nickName: string) {
    if (nickName.length > 22) {
      nickName = nickName.substring(0, 22) + '...';
    }
    return nickName;
  }


  getActualForecastBlockData(request, flag) {
    return new Promise((resolve) => {
      this.actualForecastBlockIndexSubscription = this.realtimeUsageService.getActualAndForecast(request).subscribe(
        response => {
          // Store data to actual and forecast
          // this.mapActualData(response.actual);
          // this.mapForecastData(response.forecast);
          this.mapBlockTransactionsData(response.block.forecast, flag);

          // this.indexData = response.yearMonthIndex;
          // this.layers.actual.hasData = this.actualData.length > 0;
          // this.layers.forecast.hasData = this.forecastData.length > 0;
          this.layers.block.hasData = this.blockData.length > 0;
          // this.populateTableData(response.actual, response.forecast, response.block.forecast);

          resolve(null);
        },
        err => {
          resolve(err);
        }
      );
    });
  }

  /**
   * Similar to other map functions. Determines if there is long/short day involved as the application is uncertain if all, one or any
   * returns with data.
   *
   * this.intervalDetailsData[date.valueOf()] is set by reading the risklegAttributes for the interval detail section that appears on the
   * right if the user toggles.
   * @param data
   */


  mapBlockTransactionsData(data: any[], flag) {
    const result = [];
    let previousDate = '';
    let counter = 0;

    data.forEach(ele => {
      if (ele.date === previousDate && !this.isDayLightSavings) {
        counter += 1;
      } else if (ele.date !== previousDate && !this.isDayLightSavings) {
        if (counter == 25 || counter == 23) {
          this.isDayLightSavings = true;
        }
        previousDate = ele.date;
        counter = 0;
      }

      const cdate = moment(ele.date).add(ele.loadHour, 'hours').format('M/DD HH:mm');

      const date = moment(ele.date).add(ele.loadHour - 1, 'hours');
      if (flag == 'usage') {
        for (let i = 1; i <= 12; i++) {
          // let datec = moment(moment(date).minute((i * 5) - 5).format('MM/DD/YY HH:mm:SS'));
          result.push([date, ele.forecastOrActualBlock, date.valueOf()]);
        }
        this.intervalDetailsData[cdate] = ele.risklegAttributes.filter(riskleg => riskleg.meterLoad != 0 && riskleg.fixedPrice != 0) || [];
      }


      if (flag == 'history') {
        for (let i = 1; i <= 4; i++) {
          // let datec = moment(moment(date).minute((i * 5) - 5).format('MM/DD/YY HH:mm:SS'));
          result.push([date, ele.forecastOrActualBlock]);
        }
        this.rintervalDetailsData[cdate] = ele.risklegAttributes.filter(riskleg => riskleg.meterLoad != 0 && riskleg.fixedPrice != 0) || [];
      }

      // let ddate = moment(ele.date).add(ele.loadHour,'h').format('M/DD HH:00');


      // let cdate = momen(ele)
      // this.intervalDetailsData[cdate] = ele.risklegAttributes.filter(riskleg => riskleg.meterLoad != 0 && riskleg.fixedPrice != 0) || [];

    });

    if (flag == 'usage') {
      this.blockData = result;
      this.ublockData = [];
      for (let i = 0; i < this.shadowDemand.time.length; i++) {
        this.ublockData.push(this.blockData[i]);
      }

      setTimeout(() => {
        // if(this.selectedComparisons.length == 0 ){
        this.createGraph();
        // }
      }, 200);

    }


    if (flag == 'history') {
      this.blockData = result;
      this.hblockData = [];
      for (let i = 0; i < this.graphData.time.length; i++) {
        this.hblockData.push(this.blockData[i]);
      }

      setTimeout(() => {
        // if(this.selectedComparisons.length == 0 ){

        this.rcreateGraph();
        // }
      }, 200);

    }



  }

  rsetBlockIntervalDetail(dateValueOf: any) {


    const rDate = moment(dateValueOf).format('M/DD HH:00');
    const current = moment(rDate);
    this.rcurrentIntervalDateDisplay = current.format('MM/DD/2020') + '    ';
    this.rcurrentIntervalTimeDisplay = `${current.format('HH:mm')} - ${current.add(1, 'h').format('HH:mm')}`;
    this.rcurrentIntervalDetails = this.rintervalDetailsData[rDate] || [];
  }



  setBlockIntervalDetail(dateValueOf: any) {
    const rDate = moment(dateValueOf).format('M/DD HH:00');
    const current = moment(rDate);
    this.currentIntervalDateDisplay = current.format('MM/DD/2020') + '    ';
    this.currentIntervalTimeDisplay = `${current.format('HH:mm')} - ${current.add(1, 'h').format('HH:mm')}`;
    this.currentIntervalDetails = this.intervalDetailsData[rDate] || [];
  }

  getDetailsValueString(details: IRiskLegAttribute) {
    if (details.risklegType === 'Fixed') {
      return `$${Number(details.fixedPrice).toFixed(2)}/MWh`;
    } else if (details.risklegType === 'Heat Rate') {
      return `${Number(details.fixedPrice).toFixed(2)} HR`;
    } else if (details.risklegType === 'Percent Fixed') {
      return `$${Number(details.fixedPrice).toFixed(2)}`;
    } else {
      return `$${Number(details.fixedPrice).toFixed(2)}`;
    }
  }


  getLoadDetailsValueString(details: IRiskLegAttribute) {
    return `${(Number(details.meterLoad) / 1000).toFixed(2)} MW`;
  }



  rdisplayBlockDetails(event) {
    this.rshowBlockDetails = event.target.checked;

    // The chart redraws faster than the DOM is updated with showIntervalDetails
    setTimeout(() => this.realCharth.reflow(), 100);
  }

  displayBlockDetails(event) {
    this.showBlockDetails = event.target.checked;

    // The chart redraws faster than the DOM is updated with showIntervalDetails
    setTimeout(() => this.realChart.reflow(), 100);
  }

  displayBlockLine(event) {

    this.bshowIntervalDetails = event.target.checked;

    if (this.bshowIntervalDetails) {
      this.populateGraphData('usage');
      // setTimeout(() => {
      //   this.createGraph();
      // }, 400);
    }

    if (!this.bshowIntervalDetails) {
      this.ublockData = [];
      this.createGraph();
    }
  }

  modifySelected() {
    this.apply = true;
    this.modify = false;
    this.setAsDefault = false;
    this.tempHrequest = this.hrequest;
    this.tempSelectedOption = this.selectedOption;
    this.tempSelectedGroup = this.selectedGroup;
    this.tempSelectedSite = this.selectedSite;
    this.tempSelectedContract = this.selectedContract;
    this.tempSelectedContrac = this.selectedContrac;
  }

  applyModify() {
    this.applyReset();
    this.rapplyReset();
    this.bshowIntervalDetails = false;
    this.showBlockDetails = false;
    this.rshowBlockDetails = false;
    this.datasets = [];
    this.rdatasets = [];
    this.ublockData = [];
    this.hblockData = [];
    this.bIndex = false;

    this.tempHrequest = null;
    this.selectedOffset = 0;
  }
  applyCancel() {
    this.apply = false;
    this.modify = true;

    this.selectedOption = this.tempSelectedOption;
    this.selectedGroup = this.tempSelectedGroup;
    this.selectedSite = this.tempSelectedSite;
    this.selectedContract = this.selectedContract;
    this.selectedContrac = this.selectedContrac;

    if (this.selectedOption == 'SP_SITE') {
      this.snapSitesValue = 1;
    }
    else {
      this.snapSitesValue = this.shadowSitesValue;
    }

    this.loadDefaultGraph();

    this.realtimeUsageService.getDemand(this.request).subscribe(
      (response) => {
        this.shadowDemand = response;

        if (this.shadowDemand && this.shadowDemand.points && this.shadowDemand.points.length > 0) {
          this.extractGraphData();
        }
        else {
          this.noDataError = true;
        }



        if (!this.noDataError) {
          setTimeout(() => {
            if (this.selectedOption == 'CONTRACT') {
              if (this.selectedProduct.name.includes('Block & Index')) {
                this.bIndex = true;
                this.populateGraphData('usage');
              }
            }
          }, 50);
        }


        if (!this.noDataError) {

          setTimeout(() => {
            if (this.selectedComparisons.length == 0 && !this.bshowIntervalDetails && !this.bIndex) {

              this.createGraph();
              // this.dataService.setLoading(false);
            }
          }, 50);
        }
      }, err => {
        if (err.status == 404) {
          this.shadowDemand = null;
          this.noDataError = true;
        }
      }
    );

    if (this.selectedComparisons.length > 0) {
      this.applyMChanges();
    }

    this.rloadDefaultGraph();
    if (this.rselectedComparisons.length > 0) {
      this.rapplyChanges(() => {
        this.rapplyMChanges();
      }
      );
    }
  }

  saveDefault() {
    this.request = {};
    if (this.selectedOption == 'CONTRACT') {
      this.sRequest = 'PRODUCT_ID';
      // this.selectedCategory = null;
      // this.selectedSite = null;
    }
    else if (this.selectedOption == 'GROUP_CATEGORY') {
      this.sRequest = 'GROUP_CATEGORY';
      // this.selectedSite = null;
      // this.selectedProduct = null;
    }
    else if (this.selectedOption == 'SP_SITE') {
      this.sRequest = 'SPECIFIC_SITE';
      // this.selectedCategory = null;
      // this.selectedProduct = null;
    }
    else if (this.selectedOption == 'ALL_SITES') {
      this.sRequest = 'ALL_SITES';
      // this.selectedCategory = null;
      // this.selectedSite = null;
      // this.selectedProduct = null;
    }
    else {
      this.sRequest = this.selectedOption;
    }
    // let defaultrep = "0";
    // if(this.setAsDefault) {
    //   defaultrep = "1";
    // } 

    
    this.request.accountId = this.myAccount.id;
    this.request.accountName = this.myAccount.name;
    if (this.selectedOption == 'CONTRACT') {
       this.request.siteRequest = 'PRODUCT_ID';
       this.request.productId = (this.selectedProduct ? this.selectedProduct.id : '');
       this.request.contractId = (this.selectedProduct ? this.selectedProduct.contractId : '');
      
      // this.selectedCategory = null;
      // this.selectedSite = null;
    }
    else if (this.selectedOption == 'GROUP_CATEGORY') {
      this.request.siteRequest = 'GROUP_CATEGORY';
      this.request.groupId = (this.selectedCategory ? this.selectedCategory.groupId : '');
      this.request.groupCategory = (this.selectedCategory ? this.selectedCategory.category : '');
      // this.selectedSite = null;
      // this.selectedProduct = null;
    }
    else if (this.selectedOption == 'SP_SITE') {
      this.request.siteRequest = 'SPECIFIC_SITE';
      this.request.siteId = (this.selectedSite ? this.selectedSite.siteId : '');
      // this.selectedCategory = null;
      // this.selectedProduct = null;
    }
    else if (this.selectedOption == 'ALL_SITES') {
      this.request.siteRequest = 'ALL_SITES';
      // this.selectedCategory = null;
      // this.selectedSite = null;
      // this.selectedProduct = null;
    }
    else {
      this.sRequest = this.selectedOption;
    }
    // this.request.defaultReport= defaultrep;
    //   // frequencyType: 'ALL'
    // };

  }

  applyDefault() {
    if(this.setAsDefault) { this.saveDefault();
     this.realtimeUsageService.setDefault(this.request).subscribe(
      (response) => { }, 
      err => {
        if (err.status == 404) {
          this.shadowDemand = null;
          this.noDataError = true;
        }
      }
    );
    }
  }

  applyChanges() {
    this.apply = false;
    this.modify = true;
    //  this.noDataError = false;
    //  this.bIndex = false;

    //  if (this.selectedOption == 'CONTRACT'){
    //    if(this.selectedProduct.name.includes('Block & Index')){
    //      this.bIndex = true;
    //     //  this.populateGraphData();
    //    }
    //  }
    
    if (this.tempHrequest != null) {
      this.applyModify();
    }

    if (this.selectedOption == 'SP_SITE') {
      this.snapSitesValue = 1;
    }
    else {
      this.snapSitesValue = this.shadowSitesValue;
    }

    this.loadDefaultGraph();


    this.realtimeUsageService.getDemand(this.request).subscribe(
      (response) => {
        this.shadowDemand = response;

        if (this.shadowDemand && this.shadowDemand.points && this.shadowDemand.points.length > 0) {
          this.extractGraphData();
        }
        else {
          this.noDataError = true;
        }

        // this.shadowSitesValue = this.shadowSites.length;
        // this.selectedSite = this.shadowSites[0];


        if (!this.noDataError) {
          setTimeout(() => {
            // Ensure div is rendered for HighCharts
            if (this.selectedOption == 'CONTRACT') {
              if (this.selectedProduct.name.includes('Block & Index')) {
                this.bIndex = true;
                this.populateGraphData('usage');
              }
            }
          }, 50);
        }


        if (!this.noDataError) {
          setTimeout(() => {
            // Ensure div is rendered for HighCharts

            if (this.selectedComparisons.length == 0 && !this.bshowIntervalDetails && !this.bIndex) {

              this.createGraph();
              // this.dataService.setLoading(false);
            }
          }, 50);
        }
        //   }
        // this.dataService.setLoading(false);
      }, err => {
        if (err.status == 404) {
          this.shadowDemand = null;
          this.noDataError = true;
        }
      }
    );
  }

  extractGraphData() {
    let sCount = 0;
    let tCount = 0
    this.shadowDemand.points.forEach((point, i) => {
      if (point.kw > 0) {
        this.noDataError = false;
        point.y = point.kw;
        this.lvalue = point.kw;
        if (point.amsCount !== undefined)
          this.lsitecount = point.amsCount + point.idrCount;
        sCount = sCount + 1;
      }
    });


    this.shadowDemand.time = [];
    let moment, isLongHour = false, longHourPassed = false, longHourIndexes = [];
    this.shadowDemand.points.forEach((point, i) => {

      moment = this.getMomentFromPoint(point);

      if (moment.format('YYYY-MM-DD HH:mm') == moment.add(1, 'hour').format('YYYY-MM-DD HH:mm')) {
        longHourIndexes.push(i + 4);

      }
      isLongHour = false;
      longHourIndexes.forEach((index) => {
        if (index == i) {
          isLongHour = true;
        }
      });
      moment = this.getMomentFromPoint(point);
      tCount = tCount + 1;

      if (tCount == sCount) {
        this.lTime = moment;
      }
      if (isLongHour) {
        this.shadowDemand.time.push('*' + moment.format('M/DD HH:mm'));
        longHourPassed = true;
        this.daylightSavings = true;
      } else if (longHourPassed) {
        this.shadowDemand.time.push(moment.subtract(1, 'hour').format('M/DD HH:mm'));
      } else {
        this.shadowDemand.time.push(moment.format('M/DD HH:mm'));
      }
    });
  }


  getMomentFromPoint(point: IRealTimePoint) {
    return moment(moment(point.date).minute((point.intervalId * 5) - 5).format('MM/DD/YY HH:mm:SS'));

  }


  getMarketData() {
    this.datasets = [];
    this.mCtr = 0;
    // loop through selected comparisons
    if (this.selectedComparisons.length > 0) {
      // this.portalService.spinBabySpin('spinner-' + this.properties.name);


      // this.dataService.setLocalLoader('MARKETS-' + this.properties.name, true);
      this.marketData = [];
      this.marketDataTicker = 0;


      this.selectedComparisons.forEach((comparison) => {
        this.marketData = [];
        this.realtimeUsageService.getMarketData(comparison.value, this.selectedOffset).subscribe((marketData: any) => {
          this.marketData.push(marketData);
          this.loadChartData(comparison.display);
          this.marketDataTick();

        }, () => {

        });
      });


    }

  }

  marketDataTick() {
    this.marketDataTicker++;
    if (this.marketDataTicker == this.selectedComparisons.length) {
      this.marketsLoading = false;
      // this.dataService.setLocalLoader('MARKETS-' + this.properties.name, false);
      setTimeout(() => {
        this.createGraph();
        this.dataService.setLoading(false);
      }, 100);

    }
  }


  loadChartData(displayName: string) {
    let name, dates, data;
    let first = '';
    // this.properties.comparisons.forEach((comparison) => {
    name = '';
    data = [];

    // this.datasets = [];
    this.marketData.forEach((dataSource) => {
      // if(comparison.value == dataSource.sources[0].source) {
      if (dataSource.sources[0]) {
        // displayName = display;
        name = dataSource.sources[0].source;
        data = dataSource.sources[0].values;
        dates = dataSource.sources[0].dates;
      }
      // }
    });



    let gData, smarketTimes;
    gData = moment(this.shadowDemand.time[0]).format('M/DD HH:mm');
    smarketTimes = moment(dates[0]).format('M/DD HH:mm');

    for (let i = 0; i < this.shadowDemand.time.length; i++) {
      gData = moment(this.shadowDemand.time[i]).format('M/DD HH:mm');
      // if(moment(gData).isBefore(moment(smarketTimes).format('M/DD HH:mm'))){
      if (moment(gData).isBefore(moment(smarketTimes))) {
        dates.unshift(gData);
        data.unshift(null);

      }

    }

    let marketTimes, sTime;
    sTime = moment(this.shadowDemand.time[0]).format('M/DD HH:mm');
    marketTimes = moment(this.shadowDemand.time[this.shadowDemand.time.length - 1]).format('M/DD HH:mm');
    let xyData = [], xyObj = {};

    for (let i = 0; i < dates.length; i++) {
      if (moment(marketTimes).isSameOrAfter(moment(dates[i]).format('M/DD HH:mm')) && moment(sTime).isSameOrBefore(moment(dates[i]).format('M/DD HH:mm'))) {
        xyObj = {
          y: (data[i] == null ? null : data[i] / 1000),
          d: dates[i]
        };
        xyData.push(xyObj);
      }
    }
    if (first == '') first = 'FIRST';

    if (xyData === undefined || xyData.length == 0) {

    }
    else {
      if (xyData.length > this.mCtr) {
        this.mCtr = xyData.length;
      }


      this.datasets.push({
        // id: comparison.value,
        name: name,
        display: displayName,
        type: (name.match('LZ') || name.match('HB')) ? 'line' : 'area',
        data: xyData,
        //   yAxis: (name.match('LZ') || name.match('HB'))? 1 : 0,
        // tooltip: {
        //   valueDecimals: 3
        // },
        //   turboThreshold: 1000000,
        //   first: first == 'FIRST'
      });
    }


  }




  applyReset() {
    this.properties.comparisons.forEach((comparison, index) => {
      this.properties.comparisons[index].selected = false;
      this.properties.comparisons[index].expandColor = false;
    });
    this.selectedComparisons = [];
  }

  applyMChanges() {
    this.showChartLegend = true;
    this.selectedComparisons = [];
    this.properties.comparisons.forEach((comp) => {

      //  this.updateSettings('comparison-' + comp.value, comp.selected.toString(), true);
      if (comp.selected) {
        this.selectedComparisons.push(comp);
      }

    });


    // this.toggleComparisons();
    // this.marketsLoading = true;
    this.portalService.spinBabySpin('marketsLoader');
    this.getMarketData();

    if (this.selectedComparisons.length == 0) {
      // this.marketsLoading = false;
      this.createGraph();

    }
    this.showComparisons = false;
  }



  createGraph() {
    if (!this.bIndex) {
      this.ublockData = [];
    }
    this.dataService.setLoading(false);
    const blockHasData = this.layers.block.hasData;
    const setBlockDetail = (value) => this.setBlockIntervalDetail(value);
    this.getYAxis();

    this.realChart = Highcharts.chart('realtimeGraph', {
      title: {
        text: ''
      },
      // plotOptions: { area: {stacking: 'normal', marker: { enabled: false}}},
      // plotOptions: { area: {marker: { enabled: false}}},
      // plotOptions: {
      //   series: {
      //     // general options for all series
      //     marker: {
      //       enabled: false,
      //       symbol: 'circle',
      //     },
      //   },
      //   area: {
      //     // shared options for all area series
      //     symbol: 'circle',
      //     // tooltip: {

      //     //   shared: true
      //     // }
      //   }
      // },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            symbol: 'circle',
          },
          area: {
            symbol: 'circle',
          },
          point: {
            events: {
              mouseOver: function () {
                if (blockHasData) {
                  setBlockDetail(this.category);
                }
              }
            }
          }
        }
      },
      xAxis: {
        categories: this.shadowDemand.time,
        // events: {
        //             afterSetExtremes: (event) => {

        //               if(event.max){

        //                 this.shadowDemand.points = [];
        //                 // this.target.points = [];
        //                 let pointMoment;
        //                 let newStart = moment(moment(this.request.startDate).minute(Math.round(event.min * 15)).format('MM/DD/YY HH:mm:SS'));
        //                 let newEnd = moment(moment(this.request.startDate).minute(Math.round(event.max * 15)).format('MM/DD/YY HH:mm:SS'));
        //                 this.dateRangeDisplay = newStart.format('MM/DD/YY') + ' - ' + newEnd.format('MM/DD/YY');
        //                 this.allPoints.forEach((point) => {
        //                   pointMoment = this.getMomentFromPoint(point);
        //                   if(pointMoment.isSameOrAfter(newStart) && pointMoment.isSameOrBefore(newEnd)) {
        //                     this.graphData.points.push(point);
        //                   }
        //                 });

        //                 let tpointMoment;
        //                 this.allTPoints.forEach((tpoint) => {
        //                   tpointMoment = this.getMomentFromPoint(tpoint);
        //                   if(tpointMoment.isSameOrAfter(newStart) && tpointMoment.isSameOrBefore(newEnd)) {
        //                     this.target.points.push(tpoint);
        //                   }
        //                 });

        //                 this.extractGraphData();
        //               }
        //             }
        //           }
      },


      chart: {
        zoomType: 'x',
        // resetZoomButton: {
        //   theme: {
        //     display: 'none'
        //   }
        // },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        symbol: 'circle'
      },
      yAxis: this.marketYAxis,

      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient2: {
          tagName: 'linearGradient',
          id: 'gradient-2',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }


      },


      exporting: { enabled: false },

      tooltip: {
        shared: true,
        formatter: function () {

          let s = '';
          let flag = 'A';

          $.each(this.points, function (i, point) {
            // s += '<br/>'+ point.series.name +': '+
            // '$'+ (this.point.y).toFixed(5) + '/kWh)';
            if (this.point.series.name == 'Demand (KW)' || this.point.series.name == 'Shadow Meter Demand (KW)') {
              if (flag == 'A') {
                let dateString = this.point.date + ' ' + this.x.substring(10 - 5);
                s = moment(dateString, 'YYYY-MM-DD HH:mm').format('ddd, MMM Do') + '<br />';
                s += moment(dateString, 'YYYY-MM-DD HH:mm').format('HH:mm') + ' - ' + moment(dateString, 'YYYY-MM-DD HH:mm').add(5, 'minutes').format('HH:mm') + ' CST' + '<br />';
                flag = 'N';
              }
            }

            if (flag == 'A') {
              if (this.point.series.name == 'Hedge (KW)') {
                let dateString = this.x.substring(0, 5) + ' ' + this.x.substring(10 - 5);
                s = moment(dateString, 'MM-DD HH:mm').format('ddd, MMM Do') + '<br />';
                s += moment(dateString, 'MM-DD HH:mm').format('HH:mm') + ' - ' + moment(dateString, 'MM-DD HH:mm').add(5, 'minutes').format('HH:mm') + ' CST' + '<br />';
              }
              else {
                let dateString = this.point.d + ' ' + this.x.substring(10 - 5);
                s = moment(dateString, 'YYYY-MM-DD HH:mm').format('ddd, MMM Do') + '<br />';
                s += moment(dateString, 'YYYY-MM-DD HH:mm').format('HH:mm') + ' - ' + moment(dateString, 'YYYY-MM-DD HH:mm').add(5, 'minutes').format('HH:mm') + ' CST' + '<br />';
              }

              flag = 'N';
            }


            // if (flag == 'A'){
            //   var dateString = this.point.d + " " + this.x.substring(10-5);
            //      s = moment(dateString, "YYYY-MM-DD HH:mm").format('ddd, MMM Do') + '<br />'
            //      s += moment(dateString, "YYYY-MM-DD HH:mm").format('HH:mm') + ' - ' + moment(dateString, "YYYY-MM-DD HH:mm").add(5, 'minutes').format('HH:mm') + ' CST' + '<br />'
            //      flag = 'N';
            //     }

            if (this.point.series.name == 'Shadow Meter Demand (KW)') {
              s += 'Shadow Meter Demand (KW): ' + this.point.kw.toFixed(3) + ' KW <br/>';
              s = s + 'Shadow AMS Count: ' + this.point.amsCount + '<br/>';
              s = s + 'Shadow IDR Count: ' + this.point.idrCount + '<br/>';
            }
            if (this.point.series.name == 'Hedge (KW)') {
              s += 'Hedge: ' + this.point.y.toFixed(3) + ' KW <br/>';
              //  s += '<br />' +'Shadow AMS Count: ' + this.point.amsCount;
              //  s += '<br />' + 'Shadow IDR Count: ' + this.point.idrCount + '<br/>';
            }
            if (this.point.series.name !== 'Demand (KW)' && this.point.series.name !== 'Hedge (KW)' && this.point.series.name !== 'Shadow Meter Demand (KW)') {
              s += '<br/>' + point.series.name + ': ' +
                '$' + (this.point.y).toFixed(5) + '/kWh';
            }
          });
          return s;
        },
      },

      series: [
        {
          name: 'Shadow Meter Demand (KW)',
          showInLegend: true,
          type: 'area',
          color: '#707376',
          data: this.shadowDemand.points,
          turboThreshold: 3000,
          linearGradient: 300,
        },
        {
          name: 'Shadow Meter (KW)',
          showInLegend: false,
          type: 'area',
          data: [],
          turboThreshold: 3000,
          linearGradient: 300,
        },

        {
          name: 'Hedge (KW)',
          showInLegend: this.bIndex,
          type: 'area',
          color: '#EC008C',
          step: 'left',
          data: this.layers.block.selected ? this.ublockData : [],
          turboThreshold: 0
        },
        this.buildComparativeIndexSeries(this.datasets, 0, '#0A38D9'),
        this.buildComparativeIndexSeries(this.datasets, 1, '#471E97'),
        this.buildComparativeIndexSeries(this.datasets, 2, '#9D114D')
      ]
    });

    // let valChk = this.realChart.series[0].dataMax?true:false;
    // var data = this.realChart.yAxis[0].series[0].processedYData;
    // this.dispLastVal = data[data.length-1];

    this.setLastValue();
  }


  setLastValue() {
    this.graphValues = [];
    let xyObj = {}
    let j = 0;
    var gname, glvalue, sitecountz

    for (let i = 0; i < this.realChart.series.length; i++) {
      var timz = moment(this.realChart.series[0].xAxis.categories[this.realChart.series[1].xAxis.categories.length - 1]).format('HH:mm A') + ' CPT'
      // var htim = moment(this.realChart.series[0].xAxis.categories[this.realChart.series[1].xAxis.categories.length-1]).format('HH:00')
      // var htim_end = moment(this.realChart.series[0].xAxis.categories[this.realChart.series[1].xAxis.categories.length-1]).add(1,'h').format('HH:00')

      timz = this.lTime.format('HH:mm A') + ' CPT'
      var htim = moment(this.lTime).format('HH:00')
      var htim_end = moment(this.lTime).add(1, 'h').format('HH:00')



      if (this.realChart.series[i].dataMax) {
        gname = this.realChart.series[i].userOptions.name;
        if ((gname == 'Shadow Meter Demand (KW)')) {
          glvalue = this.lvalue.toFixed(3) + ' KW';
          // glvalue = '' + this.realChart.series[i].userOptions.data[this.realChart.series[i].userOptions.data.length-1].y.toFixed(3) + ' KW';
          gname = 'DEMAND             '
          timz = moment(this.lTime).add(5, 'm').format('HH:mm A') + ' CPT'
        }
        else if (gname == 'Hedge (KW)') {
          // var data = this.realChart.yAxis[0].series[0].processedYData;
          // glvalue = data[data.length-1];
          glvalue = this.realChart.series[i].userOptions.data[this.realChart.series[i].userOptions.data.length - 1][1].toFixed(3) + ' KW';
          gname = 'HEDGE'
          timz = htim + ' - ' + htim_end
        }
        else {
          glvalue = '$' + this.realChart.series[i].userOptions.data[this.realChart.series[i].userOptions.data.length - 1].y.toFixed(5) + '/kWh';
          gname = this.realChart.series[i].userOptions.name
          timz = moment(this.lTime).add(15, 'm').format('HH:mm A') + ' CPT'
        }

        sitecountz = this.lsitecount;

        // var ydata = this.realChart.yAxis[i].series[i].processedYData;
        // var lval = ydata[ydata.length-1];
        // if (this.point.series.name == 'Demand (KW)'){
        //   s += 'Demand: ' + this.point.kw.toFixed(3) + ' KW <br/>';

        xyObj = {
          name: gname,
          color: this.realChart.series[i].userOptions.color,
          lvalue: glvalue,
          time: timz,
          sitecount: sitecountz,
        };
        //  if (gname != 'Hedge (KW)') {
        this.graphValues.push(xyObj);
        //  }
      }
    }



  }


  getYAxis() {
    // if(this.properties.name == MarketSection.Info || this.properties.name == MarketSection.Snapshot){

    if (this.datasets && this.datasets.length) {
      this.marketYAxis = [{ // Primary yAxis
        labels: {
          format: '{value}'
        },
        title: {
          text: 'DEMAND (KW)'
        },
        opposite: false,
        // plotLines: this.ercotPlotLines,
      }, { // Secondary yAxis
        title: {
          text: 'PRICE ($/kWh)'
        },
        labels: {
          formatter: function () {
            if (this.axis.defaultLabelFormatter != undefined) {
              return '$' + Highcharts.numberFormat(this.axis.defaultLabelFormatter.call(this), 5);
            }
            return ""
          }
        },
        // labels: {
        //   format: '${value}'

        // },
        opposite: true,
        // tickInterval: 10,
        // plotLines: this.hubPlotLines,
      }];
    } else {

      this.marketYAxis = [{ // Primary yAxis
        title: {
          text: 'DEMAND (KW)'
        },
        labels: {
          format: '{value}'
        },
      }];
    }

  }


  selectOffset(bvalue: number) {

    this.showComparisons = false;
    this.selectedOffset = bvalue;
    this.rFresh();
  }

  //2nd graph
  rloadDefaultGraph() {


    if (this.selectedOption == 'CONTRACT') {
      this.sRequest = 'PRODUCT_ID';
    }
    else if (this.selectedOption == 'GROUP_CATEGORY') {
      this.sRequest = 'GROUP_CATEGORY';
    }
    else if (this.selectedOption == 'SP_SITE') {
      this.sRequest = 'SPECIFIC_SITE';
    }
    else {
      this.sRequest = this.selectedOption;
    }


    if (this.tempHrequest != null) {
      this.hrequest = this.tempHrequest;
      this.selectedStartDate = moment(this.hrequest.startDate).toDate();
      this.selectedEndDate = moment(this.hrequest.endDate).toDate();
      this.dateRangeDisplay = moment(this.hrequest.startDate).format('MM/DD/YY') + '-' + moment(this.hrequest.endDate).format('MM/DD/YY');

      // this.sitesControl.siteRequest = 'ALL_SITES';
      this.minDate = moment().subtract(3, 'years').toDate();

      this.tempHrequest = null;
    }
    else {
      this.hrequest.accountId = this.myAccount.id;
      this.hrequest.accountName = this.myAccount.name;
      this.hrequest = {
        accountId: this.myAccount.id,
        accountName: this.myAccount.name,
        siteRequest: this.sRequest,
        startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        groupId: (this.selectedCategory ? this.selectedCategory.groupId : ''),
        groupCategory: (this.selectedCategory ? this.selectedCategory.category : ''),
        siteId: (this.selectedSite ? this.selectedSite.siteId : ''),
        productId: (this.selectedProduct ? this.selectedProduct.id : ''),
        contractId: this.selectedContract,
        frequencyType: 'ALL'
      };

      // this.sitesControl.siteRequest = 'ALL_SITES';
      this.minDate = moment().subtract(3, 'years').toDate();
      this.dateRangeDisplay = moment(this.hrequest.startDate).format('MM/DD/YY') + '-' + moment(this.hrequest.endDate).format('MM/DD/YY');

      this.selectedStartDate = moment(this.hrequest.startDate).toDate();
      this.selectedEndDate = moment(this.hrequest.endDate).toDate();
    }

    // if (this.selectedOption == 'CONTRACT'){
    //   if(this.selectedProduct.name.includes('Block & Index')){
    //      this.populateGraphData('usage');
    //   }
    // }


    this.rapplyChanges();
  }

  rapplyChanges(callback = () => { }) {
    // Get graph data
    this.isMarketData = false;
    this.isZoom = false;
    if (!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.realtimeUsageService.getUsageGraph(this.hrequest).subscribe(
      (graphResponse) => {
        if (graphResponse.points.length > 0) {
          // || graphResponse.shadowPoints.length > 0
          this.rnoDataError = false;
        } else {
          this.rnoDataError = true;
          this.dataService.setLoading(false);
        }

        this.graphData = this.realTimeHourlyInterval ? this.trimAndAverageUsageGraph(graphResponse) : graphResponse;
        this.allPoints = this.graphData.points;

        this.target = cloneDeep(this.graphData);
        if (this.realTimeHourlyInterval) {
          this.target.points = this.averageUsageGraphPoints(this.target.points);
        }
        this.allTPoints = this.target.points;

        this.rselectedComparisons = [];
        
        if (this.rproperties.market != null) {      
          this.rproperties.market.forEach((market) => {
              market.comparisons.forEach((comp) => {
              if (comp.selected) {
                this.rselectedComparisons.push(comp);
                this.isMarketData = true;
              }
            });
          });
        }
        else {
          this.rproperties = ErcotIUnfoProperties;
          this.rproperties.market.forEach((market, index) => {
            market.comparisons.forEach((comparison, index1) => {
              let defaultComparison = Object.assign({}, comparison);
              // this.defaultComparisons.push(defaultComparison);
              this.rproperties.market[index].comparisons[index1].selected = false;
              this.rproperties.market[index].comparisons[index1].expandedValue = false;
              this.rproperties.market[index].comparisons[index1].expandColor = false;
            });
          });
          this.rproperties.market.forEach((market) => {
            market.comparisons.forEach((comp) => {
              if (comp.selected) {
                this.rselectedComparisons.push(comp);
                this.isMarketData = true;
              }
            });
          });
        }

        if (this.isMarketData) {
          this.rdatasets = []
        }

        this.rextractGraphData();


        if (!this.rnoDataError) {

          setTimeout(() => {
            // Ensure div is rendered for HighCharts
            if (this.selectedOption == 'CONTRACT') {
              if (this.selectedProduct.name.includes('Block & Index')) {
                this.bIndex = true;
                this.populateGraphData('history');
              }
            }
          }, 50);
        }


        // if (this.isMarketData) {
        //   setTimeout(() => {
        //     // Ensure div is rendered for HighCharts

        //       this.getMarketData();
        //     }, 50);

        //   // this.datasets = []
        // }

        // if (!this.isMarketData) {
        if (!this.rnoDataError) {
          setTimeout(() => {
            // if(this.rselectedComparisons.length == 0 && !this.bIndex) {
            if (!this.bIndex) {
              this.rcreateGraph();
            }
          }, 50);
        }
        //   }
        // this.dataService.setLoading(false);
        callback();
      }, err => {
        if (err.status == 404) {
          this.graphData = null;
          this.rnoDataError = true;
        }
      }
    );
  }

  // rapplyMChanges(comparison: IUMarketComparison){
  rapplyMChanges() {
    this.dataService.setLoading(true);
    // this.dataService.setLocalLoader('MARKETS-' + 'RealTime', true);
    this.showChartLegend = true;
    this.rselectedComparisons = [];
    
    this.rproperties.market.forEach((market) => {
      market.comparisons.forEach((comp) => {
        if (comp.selected) {
          this.rselectedComparisons.push(comp);
        }
        });
    });


    if (this.isZoom) {
      this.graphData.points = [];
      this.target.points = [];

      this.hrequest.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      this.hrequest.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
      this.dateRangeDisplay = moment(this.hrequest.startDate).format('MM/DD/YY') + ' - ' + moment(this.hrequest.endDate).format('MM/DD/YY');

      this.graphData.points = this.allPoints;
      this.target.points = this.allTPoints;
      this.rextractGraphData();
    }




    // this.rtoggleComparisons();
    this.rmarketsLoading = true;
    this.portalService.spinBabySpin('marketsLoader');

    if (this.isZoom) {
      setTimeout(() => {
        this.rgetMarketData();
      }, 100);
    }
    else {
      this.rgetMarketData();
    }
    if (this.rselectedComparisons.length == 0) {
      this.rmarketsLoading = false;
      this.rcreateGraph()
    }
    this.rshowComparisons = false;
    this.isZoom = false;
  }

  rgetMarketData() {
    this.rdatasets = [];
    this.rmCtr = 0;
    // loop through selected comparisons
    if (this.rselectedComparisons.length > 0) {
      this.rmarketData = [];
      this.rmarketDataTicker = 0;
      this.hrequest.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      this.hrequest.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');

      this.rselectedComparisons.forEach((comparison) => {
        this.rmarketData = [];

        this.realtimeUsageService.rgetMarketData(comparison.value, this.hrequest).subscribe((rmarketData: any) => {
          this.rmarketData.push(rmarketData);
          this.rloadChartData(comparison.display);
          this.rmarketDataTick();
        }, () => {
          // this.marketDataTick();
        });
      });


    }

  }


  rapplyReset() {
    // this.rproperties.market.forEach((market, index) => {
    //   market[index].comparisons.forEach((comparison, index1) => {
    //     market[index].comparisons[index1].selected = false;
    //     market[index].comparisons[index1].expandColor = false;
    //   });
    // });
    this.rproperties = ErcotIUnfoProperties;
    this.rproperties.market.forEach((market, index) => {
      this.rproperties.market[index].comparisons.forEach((comparison, index2) => {
      // this.defaultComparisons.push(defaultComparison);
      this.rproperties.market[index].comparisons[index2].selected = false;
      this.rproperties.market[index].comparisons[index2].expandedValue = false;
      this.rproperties.market[index].comparisons[index2].expandColor = false;
      });
    });
  }

  rloadChartData(displayName: string) {
    let name, dates = [], data;
    let first = '';

    name = '';
    data = [];


    // this.datasets = [];
    this.rmarketData.forEach((dataSource) => {
      // if(comparison.value == dataSource.sources[0].source) {
      if (dataSource.sources[0]) {
        // displayName = display;
        name = dataSource.sources[0].source;
        data = dataSource.sources[0].values;
        dates = dataSource.sources[0].dates;
      }
      // }
    });
    //j

    let gData, smarketTimes;
    gData = moment(this.graphData.time[0]).format('M/DD HH:mm');
    smarketTimes = moment(dates[0]).format('M/DD HH:mm');

    for (let i = 0; i < this.graphData.time.length; i++) {
      gData = moment(this.graphData.time[i]).format('M/DD HH:mm');
      // if(moment(gData).isBefore(moment(smarketTimes).format('M/DD HH:mm'))){
      if (moment(gData).isBefore(moment(smarketTimes))) {
        dates.unshift(gData);
        data.unshift(null);

      }

    }

    const marketTimes = moment(this.graphData.time[this.graphData.time.length - 1]).format('M/DD HH:mm');
    const xyData = new Array<{ y: number, d: string }>();

    let currentDate = dates[0];
    let values = [];
    // if(comparison.selected){
    for (let i = 0; i < dates.length; i++) {
      const value = data[i] == null ? null : data[i] / 1000;
      if (moment(marketTimes).isSameOrAfter(moment(dates[i]).format('M/DD HH:mm'))) {
        if (this.realTimeHourlyInterval) {
          // Average into hourly intervals
          if (i % 4 === 0 && i > 0) {
            xyData.push({
              y: this.average(values),
              d: currentDate
            });
            currentDate = dates[i];
            values = [];
          }
          values.push(value);
        } else {
          xyData.push({
            y: value,
            d: dates[i]
          });
        }
      }
    }
    if (first == '') first = 'FIRST';

    if (xyData === undefined || xyData.length == 0) {

    }
    else {
      if (xyData.length > this.mCtr) {
        this.rmCtr = xyData.length;
      }


      this.rdatasets.push({
        // id: comparison.value,
        name: name,
        display: displayName,
        type: (name.match('LZ') || name.match('HB')) ? 'line' : 'area',
        data: xyData,
        //   yAxis: (name.match('LZ') || name.match('HB'))? 1 : 0,
        // tooltip: {
        //   valueDecimals: 3
        // },
        //   turboThreshold: 1000000,
        //   first: first == 'FIRST'
      });
    }
    // }
    // if(first == 'FIRST') first = null;
    // });
    // this.filteredDatasets = this.datasets;

  }



  rmarketDataTick() {
    this.rmarketDataTicker++;
    if (this.rmarketDataTicker == this.rselectedComparisons.length) {
      this.rmarketsLoading = false;
      // this.dataService.setLocalLoader('MARKETS-' + this.properties.name, false);
      setTimeout(() => {

        this.rcreateGraph();
      }, 400);

    }
  }

  rextractGraphData() {
    this.totalUsage = 0;
    this.peakHourlyDemand = 0;
    let peakDemand = 0;
    let hourlyUsage = 0;
    let hourlyUsages = [];
    let peakDemandPoint;
    this.demLegend = false

    this.graphData.points.forEach((point, i) => {
      this.totalUsage += point.usage;
      hourlyUsage += point.usage;
      if (this.showIntervalDetails) {
        this.totalUsage += point.shdUsage;
        hourlyUsage += point.shdUsage;
      }
      if ((i + 1) % 4 == 0) {
        hourlyUsages.push(hourlyUsage);
        hourlyUsage = 0;
      }

      if (!this.showIntervalDetails) {
        if (point.demand > peakDemand) {
          peakDemand = point.demand;
          peakDemandPoint = point;
          this.peakDemandHr = this.rgetMomentFromPoint(point).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
        }
      }

      if (this.showIntervalDetails) {
        if ((point.demand + point.shdDemand) > peakDemand) {
          peakDemand = point.demand + point.shdDemand;
          peakDemandPoint = point;
          this.peakDemandHr = this.rgetMomentFromPoint(point).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
        }
      }


      if (point.demand > 0) {
        // this.rnoDataError = false;
        this.demLegend = true;
        point.y = point.demand;
      }

      if (point.demand == 0) {
        if (point.amsCount == 0 && point.idrCount == 0) {
          point.y = null;
        }
        else {
          point.y = point.demand;
        }
      }
      if (point.demand == null) {
        point.y = null;
      }

    });



    if (this.showIntervalDetails) {
      this.target.points.forEach((point, i) => {

        if (point.shdDemand > 0 || point.shdDemand == 0) {
          point.y = point.shdDemand;
        }
        if (point.shdDemand == null) {
          point.y = null;
        }
      });
    }


    this.totalUsage = this.portalService.numberWithCommas(this.totalUsage.toFixed(3));
    let count = 0;
    let hourlyDemand = 0;
    this.graphData.points.forEach((point) => {
      if (count == 4) {
        if (hourlyDemand > this.peakHourlyDemand) {
          this.peakHourlyDemand = hourlyDemand;
        }
        count = 0;
        hourlyDemand = 0;
      }
      hourlyDemand += point.usage;
      if (this.showIntervalDetails) {
        hourlyDemand += point.shdUsage
      }
      count++;
    });
    this.peakHourlyDemand = this.portalService.numberWithCommas(this.peakHourlyDemand.toFixed(3));

    let totalHourlyUsages = 0;
    hourlyUsages.forEach((usage) => {
      totalHourlyUsages += usage;
    });

    if (hourlyUsages.length > 0) {
      this.averageHourlyUsage = totalHourlyUsages / hourlyUsages.length;
      this.averageHourlyUsage = this.portalService.numberWithCommas(this.averageHourlyUsage.toFixed(3));
    } else {
      this.averageHourlyUsage = 0;
    }

    this.graphData.time = [];
    let moment, isLongHour = false, longHourPassed = false, longHourIndexes = [];
    this.graphData.points.forEach((point, i) => {

      moment = this.rgetMomentFromPoint(point);

      if (moment.format('YYYY-MM-DD HH:mm') == moment.add(1, 'hour').format('YYYY-MM-DD HH:mm')) {
        longHourIndexes.push(i + 4);

      }
      isLongHour = false;
      longHourIndexes.forEach((index) => {
        if (index == i) {
          isLongHour = true;
        }
      });
      moment = this.rgetMomentFromPoint(point);




      if (isLongHour) {
        this.graphData.time.push('*' + moment.format('M/DD HH:mm'));
        longHourPassed = true;
        this.daylightSavings = true;
      } else if (longHourPassed) {
        this.graphData.time.push(moment.subtract(1, 'hour').format('M/DD HH:mm'));
      } else {
        this.graphData.time.push(moment.format('M/DD HH:mm'));
      }
    });

  }



  rgetMomentFromPoint(point: IUsageGraphPoint, interval = 15) {
    return moment(moment(point.date).minute((point.intervalId * interval) - interval).format('MM/DD/YY HH:mm:SS'));
  }


  rgetYAxis() {
    // if(this.properties.name == MarketSection.Info || this.properties.name == MarketSection.Snapshot){

    if (this.rdatasets && this.rdatasets.length) {
      this.rmarketYAxis = [{ // Primary yAxis
        labels: {
          format: '{value}'
        },
        title: {
          text: 'DEMAND (KW)'
        },
        opposite: false,
        // plotLines: this.ercotPlotLines,
      }, { // Secondary yAxis
        title: {
          text: 'PRICE ($/kWh)'
        },
        labels: {
          formatter: function () {
            if (this.axis.defaultLabelFormatter != undefined) {
              return '$' + Highcharts.numberFormat(this.axis.defaultLabelFormatter.call(this), 5);
            }
            return ""
          }
        },
        // labels: {
        //   format: '${value}'

        // },
        opposite: true,
        // tickInterval: 10,
        // plotLines: this.hubPlotLines,
      }];
    } else {

      this.rmarketYAxis = [{ // Primary yAxis
        title: {
          text: 'DEMAND (KW)'
        },
        labels: {
          format: '{value}'
        },
        // tickInterval: 10,
        // plotLines: this.ercotPlotLines,
      }];
    }

  }


  rcreateGraph() {
    if (!this.bIndex) {
      this.hblockData = [];
    }
    this.dataService.setLoading(false);
    const blockHasData = this.layers.block.hasData;
    const rsetBlockDetail = (value) => this.rsetBlockIntervalDetail(value);
    this.rgetYAxis();
    this.dataService.setLoading(false);
    const component = this;
    const realTimeHourlyInterval = this.realTimeHourlyInterval;

    this.realCharth = Highcharts.chart('histusageGraph', {
      title: {
        text: ''
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            symbol: 'circle',
          },
          point: {
            events: {
              mouseOver: function () {
                if (blockHasData) {
                  rsetBlockDetail(this.category);
                }
              }
            }
          }
        },
        area: {
          stacking: 'normal',
          symbol: 'circle'
        }
      },

      xAxis: {
        categories: this.graphData.time,
        events: {
          afterSetExtremes: (event) => {
            if (event.max) {
              component.isZoom = true;
              this.graphData.points = [];
              this.target.points = [];
              let pointMoment;
              const interval = realTimeHourlyInterval ? 60 : 15;
              const newStart = moment(moment(this.hrequest.startDate).minute(Math.round(event.min * interval)).format('MM/DD/YY HH:mm:SS'));
              const newEnd = moment(moment(this.hrequest.startDate).minute(Math.round(event.max * interval)).format('MM/DD/YY HH:mm:SS'));
              this.dateRangeDisplay = newStart.format('MM/DD/YY') + ' - ' + newEnd.format('MM/DD/YY');
              this.allPoints.forEach((point) => {
                pointMoment = this.rgetMomentFromPoint(point, interval);
                if (pointMoment.isSameOrAfter(newStart) && pointMoment.isSameOrBefore(newEnd)) {
                  this.graphData.points.push(point);
                }
              });

              let tpointMoment;
              this.allTPoints.forEach((tpoint) => {
                tpointMoment = this.rgetMomentFromPoint(tpoint, interval);
                if (tpointMoment.isSameOrAfter(newStart) && tpointMoment.isSameOrBefore(newEnd)) {
                  this.target.points.push(tpoint);
                }
              });

              this.rextractGraphData();
            }
          }
        }
      },
      chart: {
        zoomType: 'x'
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        symbol: 'circle'
      },
      yAxis: this.rmarketYAxis,

      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient2: {
          tagName: 'linearGradient',
          id: 'gradient-2',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },

      exporting: { enabled: false },

      tooltip: {
        shared: true,
        formatter: function () {
          let tooltip = '';
          let flag = true;

          $.each(this.points, function (i, point) {
            const demandSeries = this.point.series.name === 'Demand (KW)';
            const shadowDemandSeries = this.point.series.name === 'Shadow Meter Demand (KW)';
            const hedgeSeries = this.point.series.name === 'Hedge (KW)';

            if (demandSeries || shadowDemandSeries) {
              if (flag) {
                const dateString = this.point.date + ' ' + this.x.substring(10 - 5);
                const date = moment(dateString, 'YYYY-MM-DD HH:mm');
                tooltip = date.format('ddd, MMM Do') + '<br />';
                tooltip += date.format('HH:mm') + ' - ';
                if (realTimeHourlyInterval) {
                  date.add(1, 'hour');
                } else {
                  date.add(15, 'minutes');
                }
                tooltip += date.format('HH:mm') + ' CST' + '<br />';
                flag = false;
              }
            }

            if (flag) {
              let date: moment.Moment;
              if (hedgeSeries) {
                const dateString = this.x.substring(0, 5) + ' ' + this.x.substring(10 - 5);
                date = moment(dateString, 'MM-DD HH:mm');
              } else {
                const dateString = this.point.d + ' ' + this.x.substring(10 - 5);
                date = moment(dateString, 'YYYY-MM-DD HH:mm');
              }
              tooltip = date.format('ddd, MMM Do') + '<br />';
              tooltip += date.format('HH:mm') + ' - ';
              if (realTimeHourlyInterval) {
                date.add(1, 'hour');
              } else {
                date.add(15, 'minutes');
              }
              tooltip += date.format('HH:mm') + ' CST' + '<br />';

              flag = false;
            }

            if (demandSeries) {
              tooltip += 'Demand: ' + this.point.demand.toFixed(3) + ' KW <br/>';
            } else if (shadowDemandSeries) {
              tooltip += '<br />' + 'Shadow Meter Demand: ' + this.point.shdDemand.toFixed(3) + ' KW';
              tooltip += '<br />' + 'Shadow AMS Count: ' + this.point.shdAmsCount;
              tooltip += '<br />' + 'Shadow IDR Count: ' + this.point.shdIdrCount + '<br/>';
            } else if (hedgeSeries) {
              tooltip += '<br />' + 'Hedge: ' + this.point.y.toFixed(3) + ' KW <br/>';
            } else {
              tooltip += '<br/>' + point.series.name + ': ' + '$' + (this.point.y).toFixed(5) + '/kWh';
            }
          });

          return tooltip;
        },
      },
      series: [
        {
          name: 'Shadow Meter Demand (KW)',
          showInLegend: this.showIntervalDetails,
          type: 'area',
          data: this.target.points,
          turboThreshold: 3000,
          linearGradient: 300,
        },
        {
          name: 'Demand (KW)',
          showInLegend: this.demLegend,
          type: 'area',
          data: this.graphData.points,
          turboThreshold: 3000,
          linearGradient: 300,
        },
        {
          name: 'Hedge (KW)',
          showInLegend: this.bIndex,
          type: 'area',
          color: '#EC008C',
          step: 'left',
          data: this.hblockData,
          turboThreshold: 0,
          stacking: undefined
        },
        this.buildComparativeIndexSeries(this.rdatasets, 0),
        this.buildComparativeIndexSeries(this.rdatasets, 1),
        this.buildComparativeIndexSeries(this.rdatasets, 2)
      ]
    });
  }

  private buildComparativeIndexSeries(datasets: any[], index: number, color?: string): any {
    const hasDataSets = datasets !== undefined && datasets.length;
    const hasComparativeIndexData = hasDataSets && datasets.length > index
      && datasets[index].data && datasets[index].data.length;

    return {
      name: hasComparativeIndexData ? datasets[index].display : '',
      showInLegend: hasComparativeIndexData,
      type: 'line',
      color: color,
      data: hasComparativeIndexData ? datasets[index].data : [],
      yAxis: hasDataSets ? 1 : 0,
      opposite: true,
      step: true,
      turboThreshold: 3000,
      linearGradient: 300,
    };
  }


  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
    this.rshowComparisons = false;
    this.showDateRangeError = false;
    this.selectedStartDate = moment(this.hrequest.startDate).toDate();
    this.selectedEndDate = moment(this.hrequest.endDate).toDate();
  }

  selectStartDate() {
    this.selectedEndDate = moment(this.selectedStartDate).add(7, 'days').toDate();
  }


  applyDateRange() {
    if (!(Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days')) > 30)) {
      this.hrequest.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      this.hrequest.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
      this.dateRangeDisplay = moment(this.hrequest.startDate).format('MM/DD/YY') + ' - ' + moment(this.hrequest.endDate).format('MM/DD/YY');

      this.toggleDateRange();
      this.rapplyChanges(() => {
        if (this.rselectedComparisons.length > 0) {
          this.rapplyMChanges();
        }
      });
      // if (this.rselectedComparisons.length > 0){
      //   setTimeout(() => {
      //       this.rapplyMChanges();
      //       // this.dataService.setLoading(false);
      //     }, 350);
      // }
      this.showDateRangeError = false;
    } else {
      this.showDateRangeError = true;
    }
  }

  rselectComparison(marketSel: IUMarkets, comparison: IUMarketComparison) {
    this.showChartLegend = true;
    this.ctr = 0;
    this.ctrColor = 0;
    
    this.rproperties.market.forEach((market) => {
       market.comparisons.forEach((comp) => {
          if (comp.selected) {
            this.ctr++;
            if (this.ctr < 3) {
              this.maxSelect = false;
            }
            else {
              this.maxSelect = true;
            }
          }
        });
    });
    if (this.ctr == 0) {
      this.maxSelect = false;
    }

    if (this.maxSelect) {
      if (comparison.selected) {
        comparison.selected = !comparison.selected;
        this.maxSelect = false;
      }
    }
    else {
      comparison.selected = !comparison.selected;
      // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    }

    this.rproperties.market.forEach((market) => {
      if(market.marketName===marketSel.marketName) {
        market.comparisons.forEach((comp) => {
          if (comp.groupName == comparison.groupName && comp.selected == true) {
            this.ctrColor++;
          }
        });
      }
   });

    this.rproperties.market.forEach((market) => {
      if(market.marketName===marketSel.marketName) {
        market.comparisons.forEach((comp) => {
          if (comp.groupName == comparison.groupName && comp.groupStart == true) {
            if (this.ctrColor > 0) {
              comp.expandColor = true;
              comp.selectedCount = this.ctrColor;
            }

            if (this.ctrColor == 0) {
              comp.expandColor = false;
              comp.selectedCount = this.ctrColor;
            }
          }
        });
     }
    });
    
    this.rproperties.market.forEach((market) => {
      this.ctr = 0;
      if(market.marketName===marketSel.marketName) {
        market.comparisons.forEach((comp) => {
          if (comp.selected) {
            this.ctr++;
          }
        });
        market.counter = this.ctr;
        if(market.counter>0)
        {
          market.expandedColor = true;
        }
        else {
          market.expandedColor = false;
        }
      }
    });
  }



  expandStatus(comparison: IUMarketComparison) {
    comparison.expandedValue = !comparison.expandedValue;
  }

  expandMarket(marketSel: IUMarkets) {
      marketSel.expanded = !marketSel.expanded;
     // marketSel.expandedColor = !marketSel.expandedColor;    
  }

  expandGroup(marketSel:IUMarkets, group: string, comparison: IUMarketComparison) {
    if (this.expandedMarket===marketSel.marketName  && this.expandedGroup == group) {
      this.expandedGroup = '';
      comparison.expandedValue = !comparison.expandedValue;
    } else {
      this.expandedGroup = group;
      this.expandedMarket = marketSel.marketName;
      this.rproperties.market.forEach((market) => {
        if(market.marketName === marketSel.marketName) {
            market.comparisons.forEach((comp) => {
            if (this.expandedMarket===marketSel.marketName && this.expandedGroup == comp.groupName) {
              comp.expandedValue = true;
            }
            else {
              comp.expandedValue = false;
            }
          });
        }
        else {
            market.comparisons.forEach((comp) => {
              comp.expandedValue = false;
            });
        }
      });
    }
  }


  rtoggleComparisons() {
    this.rshowComparisons = !this.rshowComparisons;
  }

  rFresh() {

    if (this.modify) {
      this.dataService.setLoading(true)
      this.applyChanges();

      if (this.bshowIntervalDetails) {
        setTimeout(() => {
          this.populateGraphData('usage')
        }, 250);
      }


      if (this.selectedComparisons.length > 0) {
        setTimeout(() => {
          this.applyMChanges();
          // this.dataService.setLoading(false);
        }, 350);
      }
      else{
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 350);
      }
      
    
    }
  }

  selectComparison(comparison: IRMarketComparison) {

    this.showChartLegend = true;
    // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    //this.selectedComparisons = [];
    this.ctr = 0;
    this.ctrColor = 0;



    this.properties.comparisons.forEach((comp) => {
      if (comp.selected) {
        this.ctr++;
        if (this.ctr < 3) {
          this.maxSelect = false;
        }
        else {
          this.maxSelect = true;
        }
      }
    });

    if (this.ctr == 0) {
      this.maxSelect = false;
    }

    if (this.maxSelect) {
      if (comparison.selected) {
        comparison.selected = !comparison.selected;
        this.maxSelect = false;
      }
    }
    else {

      comparison.selected = !comparison.selected;
      // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    }

    this.properties.comparisons.forEach((comp) => {
      if (comp.groupName == comparison.groupName && comp.selected == true) {
        this.ctrColor++;
      }
    });

    this.properties.comparisons.forEach((comp) => {
      if (comp.groupName == comparison.groupName && comp.groupStart == true) {
        if (this.ctrColor > 0) {
          comp.expandColor = true;
        }

        if (this.ctrColor == 0) {
          comp.expandColor = false;
        }
      }
    });

  }



  exportGraph() {

    $('#realTimeExportModal').modal('show');

  }


  loadDefaultGraph() {
    if (this.selectedOption == 'CONTRACT') {
      this.sRequest = 'PRODUCT_ID';
    }
    else if (this.selectedOption == 'GROUP_CATEGORY') {
      this.sRequest = 'GROUP_CATEGORY';
    }
    else if (this.selectedOption == 'SP_SITE') {
      this.sRequest = 'SPECIFIC_SITE';
    }
    else {
      this.sRequest = this.selectedOption;
    }



    this.request.accountId = this.myAccount.id;
    this.request.accountName = this.myAccount.name;
    this.request = {
      accountId: this.myAccount.id,
      accountName: this.myAccount.name,
      // siteRequest: this.selectedOption,
      siteRequest: this.sRequest,
      startDate: moment().subtract(this.selectedOffset, 'days').format('YYYY-MM-DD'),
      endDate: moment().subtract(0, 'days').format('YYYY-MM-DD'),
      groupId: (this.selectedCategory ? this.selectedCategory.groupId : ''),
      groupCategory: (this.selectedCategory ? this.selectedCategory.category : ''),
      siteId: (this.selectedSite ? this.selectedSite.siteId : ''),
      contractId: this.selectedContract,
      productId: (this.selectedProduct ? this.selectedProduct.id : ''),
      intervalType: 5
      // frequencyType: 'ALL'
    };
  }

  private get realTimeHourlyInterval(): boolean {
    // If there's a selected contract and the user has selected contract as a filter option
    return this.selectedContrac !== undefined && this.selectedOption === 'CONTRACT' && this.selectedContrac.products
      && this.selectedContrac.products.length && this.selectedContrac.products[0].name === 'Block & Index - IDR Only';
  }

  private trimAndAverageUsageGraph(usageGraph: IUsageGraph): IUsageGraph {
    if (!usageGraph) {
      return null;
    }

    if (usageGraph.time) {
      usageGraph.time = usageGraph.time.filter((val, index) => index % 4 === 0);
    }
    usageGraph.points = this.averageUsageGraphPoints(usageGraph.points);
    return usageGraph;
  }
  private averageUsageGraphPoints(graphPoints: IUsageGraphPoint[]): IUsageGraphPoint[] {
    if (!graphPoints) {
      return graphPoints;
    }

    let currentPoint: IUsageGraphPoint;
    const hourlyPointsGroups = graphPoints.reduce((map, point) => {
      if ((point.intervalId - 1) % 4 === 0) {
        map.set(point, []);
        currentPoint = point;
      }
      if (currentPoint) {
        map.get(currentPoint).push(point);
      }
      return map;
    }, new Map<IUsageGraphPoint, IUsageGraphPoint[]>());

    return Array.from(hourlyPointsGroups.keys()).map(point => {
      const values = hourlyPointsGroups.get(point);
      point.usage = this.average(values.map(p => p.usage));
      point.demand = this.average(values.map(p => p.demand));
      point.shdUsage = this.average(values.map(p => p.shdUsage));
      point.shdDemand = this.average(values.map(p => p.shdDemand));
      point.blockedUsage = this.average(values.map(p => p.blockedUsage));
      point.blockedDemand = this.average(values.map(p => p.blockedDemand));
      point.mcpeUsage = this.average(values.map(p => p.mcpeUsage));
      point.mcpeDemand = this.average(values.map(p => p.mcpeDemand));
      return point;
    });
  }

  private average(values: number[]): number {
    if (!values) {
      return null;
    }

    const filteredValues = values.filter(val => val);
    if (!filteredValues.length) {
      return null;
    }
    return filteredValues.reduce((sum, val) => val + sum, 0) / values.length;
  }
}

const defaultLayers: ILayersList = {
  actual: { name: 'Actual Demand (KW)', hasData: false, selected: true, color: '#18AAFD' },
  forecast: { name: 'Forecast Demand (KW)', hasData: false, selected: true, color: '#0A38D9' },
  block: { name: 'Hedge (KW)', hasData: false, selected: true, color: '#EC008C' },
  bmq: { name: 'Initial Load Forecast (KW)', hasData: false, selected: false, color: '#000000' }
};
