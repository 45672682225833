import { Component, OnInit } from '@angular/core';
import { IntervalUsageReportService } from '../interval-usage-report.service';
import { DataService } from '../../shared/data.service';
import { ISiteGroup, IIntervalSite, IIntervalSiteRequest, ISite } from '../../shared/entities/site';
import { FadeAnimation } from '../../animations/fadeAnimation';
import { GenericPipe } from '../../shared/pipe/generic.pipe';
import { IUserAccount } from '../../shared/entities/profile';
import { PortalService } from '../../shared/portal.service';
import { environment } from '../../../environments/environment';
import { PagerService } from '../../shared/pager.service';
import { Subscription } from 'rxjs';
import { OrderByPipe } from '../../shared/pipe/orderBy.pipe';
import { IHelpStep } from '../../shared/entities/contextualHelp';
import { IAccount } from '../../shared/entities/account';
import { MAX_SITE_SELECTION } from '../interval-usage-report.component';

declare var $: any;

@Component({
  selector: 'app-select-sites',
  templateUrl: './select-sites.component.html',
  animations: [FadeAnimation],
  providers: [GenericPipe, OrderByPipe],
  styleUrls: ['./select-sites.component.scss'],
})
export class SelectSitesComponent implements OnInit {

  userSiteGroups: ISiteGroup[];
  accountWithContractData: IAccount;

  // Groupings
  allSitesGrouping = {
    groupName: 'ALL_SITES',
    display: 'All Sites'
  };

  marketsGrouping = {
    groupName: 'MARKETS',
    display: 'Markets'
  };

  groupingsControl: any = {
    show: false,
    selectedGrouping: this.allSitesGrouping
  };

  showMeterType = false;
  selectedMeterType: string;
  anyChecked = false;
  siteMaxReached = false;
  noSitesError = false;
  maskMode: boolean;

  allSites: IIntervalSite[];
  selectedSites: IIntervalSite[] = [];
  filteredSites: IIntervalSite[] = [];
  myAccount: IUserAccount;

  showSearchRow = false;
  searchTerm = '';

  amsCount = 0;
  idrCount = 0;
  nidrCount = 0;
  pager: any;
  helpStep: IHelpStep;

  accountSourceSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  siteGroupsSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(
    private service: IntervalUsageReportService,
    private dataService: DataService,
    private pipe: GenericPipe,
    private portalService: PortalService,
    private pagerService: PagerService,
    private orderByPipe: OrderByPipe
  ) { }

  ngOnInit() {
    this.service.setStepReached(1);
    this.myAccount = this.dataService.getAccountSource();
    if (this.service.selectedSites) {
      this.selectedSites = this.service.selectedSites;
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.clearAll();
        this.myAccount = account;
      }
    );
    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.accountWithContractData = account;
    });
    this.selectedMeterType = 'ALL';
    const siteGroups = this.dataService.getSiteGroupsSource();
    if (siteGroups) {
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.selectGrouping(this.allSitesGrouping);
    }
    this.siteGroupsSourceSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.userSiteGroups = siteGroups.userSiteGroups;
        this.selectGrouping(this.allSitesGrouping);
      }
    );
    this.service.setStepReached(1);
    if (!environment.production) {
      this.loadSites(true);
    }

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.siteGroupsSourceSubscription) this.siteGroupsSourceSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  // Groupings Dialog
  toggleGroupings() {
    this.groupingsControl.show = !this.groupingsControl.show;
    this.showMeterType = false;
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  toggleMeterType() {
    this.showMeterType = !this.showMeterType;
    this.groupingsControl.show = false;
  }

  selectMeterType(meterType: string) {
    this.selectedMeterType = meterType;
    this.toggleMeterType();
    this.loadSites(false);
  }

  loadSites(recount?: boolean) {
    let groupId;
    if (environment.production) {
      if (this.groupingsControl.selectedGrouping.groupName == 'ALL_SITES') {
        groupId = 'ALL_SITES';
      } else if (this.groupingsControl.selectedGrouping.groupName == 'MARKETS') {
        groupId = 'MARKETS';
      } else {
        groupId = this.groupingsControl.selectedGrouping.id;
      }
    }
    let request: IIntervalSiteRequest;
    if (environment.production) {
      request = {
        accountId: this.myAccount.id,
        groupId: groupId,
        meterType: this.selectedMeterType
      };
    } else {
      request = {
        accountId: '1100',
        groupId: null,
        meterType: null
      };
    }
    this.dataService.setLoading(true);
    this.service.getIntervalSites(request).subscribe(
      (sites) => {
        this.allSites = sites;
        if (this.service.selectedSites.length != 0) {
          this.service.selectedSites.forEach((selectedSite) => {
            this.allSites.forEach((site) => {
              if (site.siteId == selectedSite.siteId) {
                site.checked = true;
              }
            });
          });
        }
        if (recount) {
          this.getMeterTypeCounts();
        }
        const idrOrAmsSites = [];
        this.nidrCount = 0;
        this.allSites.forEach((site) => {
          if (site.meterType != 'NIDR') {
            idrOrAmsSites.push(site);
          } else {
            this.nidrCount++;
          }
        });
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 250);
        this.allSites = idrOrAmsSites;
        if (this.allSites.length == 0) {
          this.noSitesError = true;
        } else {
          this.noSitesError = false;
        }
        this.filterSites();
      }, err => {
        if (err.status == 404) {
          this.allSites = [];
          this.noSitesError = true;
        }
        this.dataService.setLoading(false);
      }
    );

  }

  filterSites() {
    if (!this.searchTerm) {
      this.filteredSites = this.allSites;
    } else {
      this.filteredSites = this.pipe.transform(this.allSites, this.searchTerm);
    }
    this.paginate(1);
  }


  checkForAnyChecks() {
    // Enable/Disable "Clear Selections" button
    this.anyChecked = false;
    this.filteredSites.forEach((site) => {
      if (site.checked) {
        this.anyChecked = true;
      }
    });
  }

  paginate(page: number) {
    if (this.filteredSites) {
      this.filteredSites = this.orderByPipe.transform(this.filteredSites, 'siteId', true);
      this.pager = this.pagerService.getPage(this.filteredSites, page, 25);
    }
  }

  siteChecked() {
    this.checkForAnyChecks();
    this.selectedSites = this.getSelectedSites();
    this.disableCheckboxes();
    this.service.setSelectedSites(this.selectedSites);
  }

  disableCheckboxes() {
    this.siteMaxReached = this.selectedSites.length >= MAX_SITE_SELECTION;
    this.filteredSites.forEach((site) => {
      if (this.siteMaxReached) {
        if (!site.checked) {
          $('#' + site.siteId + '-checkbox').attr('disabled', 'disabled');
        } else {
          $('#' + site.siteId + '-checkbox').removeAttr('disabled');
        }
      } else {
        $('#' + site.siteId + '-checkbox').removeAttr('disabled');
      }
    });
  }

  getSelectedSites() {
    this.selectedSites = [];
    this.filteredSites.forEach((site) => {
      if (site.checked) {
        this.selectedSites.push(site);
      }
    });
    const oldSelectedSites = this.service.selectedSites;
    oldSelectedSites.forEach((oldSelected) => {
      let included = false;
      this.filteredSites.forEach((site) => {
        if (site.siteId == oldSelected.siteId) {
          included = true;
        }
      });
      if (!included && oldSelected.checked) {
        this.selectedSites.push(oldSelected);
      }
    });
    return this.selectedSites;
  }

  clearAll() {
    this.anyChecked = false;
    if (this.filteredSites) {
      this.filteredSites.forEach((site) => {
        site.checked = false;
      });
    }
    this.selectedSites = this.getSelectedSites();
    this.disableCheckboxes();
    this.service.setSelectedSites(this.selectedSites);
  }

  selectAll() {
    this.anyChecked = true;
    let selectedSiteCount = this.selectedSites.length;
    if (this.filteredSites) {
      this.filteredSites.forEach((site) => {
        const siteOnPage = !this.pager ||
          (this.pager.items.find(s => site.siteId === s.siteId) !== undefined || this.pager.totalPages === 1);
        if (selectedSiteCount < MAX_SITE_SELECTION && siteOnPage) {
          site.checked = true;
          selectedSiteCount++;
        }
      });
    }
    this.selectedSites = this.getSelectedSites();
    this.disableCheckboxes();
    this.service.setSelectedSites(this.selectedSites);
  }

  // "2017-01-01T00:00:00" -> "01/01/2017"
  getDateDisplay(dateString: string) {
    return this.portalService.getDateDisplay(dateString);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterSites();
  }


  selectGrouping(grouping: any) {
    this.groupingsControl.show = false;
    if (!grouping.display) {
      if (grouping.groupName > 18) {
        grouping.display = grouping.groupName.substring(0, 18) + '...';
      } else {
        grouping.display = grouping.groupName;
      }
    }
    this.groupingsControl.selectedGrouping = grouping;
    this.loadSites(true);
  }

  getMeterTypeCounts() {
    this.amsCount = 0;
    this.idrCount = 0;
    this.allSites.forEach((site) => {
      if (site.meterType == 'AMS') {
        this.amsCount++;
      } else if (site.meterType == 'IDR') {
        this.idrCount++;
      }
    });
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  get maxSiteSelection(): number {
    return MAX_SITE_SELECTION;
  }
}
