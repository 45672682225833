import { Component, OnInit, Injectable } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {IUserAccount} from "../../shared/entities/profile";
import {PortalService} from '../../shared/portal.service';
import * as moment from 'moment';
import {GenericPipe} from '../../shared/pipe/generic.pipe'
import {OrderByPipe} from '../../shared/pipe/orderBy.pipe'
import {PagerDenoms} from '../../shared/const/pagerDenoms'
import {IPagerDenom} from '../../shared/const/pagerDenoms'
import {PagerService} from '../../shared/pager.service'
import {Observable, Subscription} from "rxjs";
import {FakerService} from "../../shared/faker.service";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {Authority} from "../../shared/const/authority";
import {Permissions} from "../../shared/const/permissions";
import {MatDatepickerModule} from '@angular/material/datepicker';
// import { IBillDelivery, IBillDeliveryRequest } from '../shared/entities/billDelivery';
import { BillDeliveryEditService } from './bill-delivery-edit.service';
import { BillDeliveryEditPipe } from '../../shared/pipe/billdeliveryedit.pipe';
import { Router } from '@angular/router';
import { IBillDeliveryEdit, IBillDeliveryEditEmail, IBillDeliveryEditPostRequest } from '../../shared/entities/billDeliveryEdit';
import { ISite } from '../../shared/entities/site';
import { IAddress } from '../../shared/entities/address';

declare var $: any;

@Component({
  selector: 'app-bill-delivery-edit',
  templateUrl: './bill-delivery-edit.component.html',
  styleUrls: ['./bill-delivery-edit.component.scss'],
  providers: [BillDeliveryEditPipe, GenericPipe, OrderByPipe]
})

@Injectable()
export class BillDeliveryEditComponent implements OnInit {

  myAccount: IUserAccount;
  minDate: Date;
  maxDate: Date;
  fromDate: Date;
  toDate: Date;
  selectedEndDate: Date;
  minStartDate: Date;
  showSearchBar = false;
  selectedElectronicOption = 'ALL';
  selectedPaperOption = "ALL";
 // showElectronicOpt = false;
  isAddEmailMode=false;
  updateRec: boolean = false;
  endDateChange=false;
  hasNewChanges = false;
  isPaperOptChange = false;
  isEmailOptChange = false;

  // Columns
  tableColumns: any[]; // Columns iterated over the table, taken from columns model in applyColumnChanges()
  columns: any[]; // Model for sidenav, see billDeliveryColumns.ts

  showEmailError= false;


  selectedPaperDel = false;
  selectedElectronicDel = false;
  searchTerm: string;

  maskMode: boolean;
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  ascending: boolean;
  noDataError: boolean = false;
  displayLimitError: boolean = false;

  enddatesel: Date;

  isSuperUser: boolean;

  helpStep: IHelpStep;

  sortBy: string = 'caNumber';

  hideApply: boolean = false;

  // Subscriptions
  // exportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;
  exportTriggeredSubscription: Subscription;


  billDeliverySel: IBillDeliveryEdit;
  allBillingDelivery: IBillDeliveryEdit[];
  filteredBillingDelivery: IBillDeliveryEdit[];



  origEmails: IBillDeliveryEditEmail[];
  emailAddUpdate : IBillDeliveryEditEmail[];
  updateEmail : IBillDeliveryEditEmail;
  newEmailAdd : IBillDeliveryEditEmail;

  hasSGrow: boolean = false;

  constructor(private dataService: DataService,
    private billDeliveryEditService: BillDeliveryEditService,
    private pipe: GenericPipe,
    private router: Router,
    private portalService: PortalService,
    private billDeliveryEditPipe: BillDeliveryEditPipe,
    private orderByPipe: OrderByPipe,
    private pagerService: PagerService,
    private fakerService: FakerService) { }

  ngOnInit(): void {
    this.dataService.setTitleSource('Bill Delivery Management');
    this.dataService.setSelectedNavItem('payments');
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    // console.log("IS SUPER USER: ", this.isSuperUser);
    this.minStartDate = new Date();
    this.minStartDate.setDate(this.minStartDate.getDate() + 1);
    this.dataService.setTitleSource('Bill Delivery Management');
    this.setCurrentActivePage('payments');
    this.dataService.setSelectedNavItem('payments');
    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
     this.getBillDelivery();
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getBillDelivery();
      }
    );
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.count==10) {
        this.selectDenom(denom);
      }
    });
    //this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {this.export();});

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    this.exportTriggeredSubscription = this.dataService.exportTriggered.subscribe(() => {
      if(window.location.href.indexOf("history") == -1){
        this.dataService.setLoading(true);
        this.exportBillDelivery()
      }
    });
  }


  formattedDate(datePassed: string){
      if(datePassed !=null && datePassed!='') {
        var stringToken = datePassed.split("-");
        return (stringToken[1]+'/'+stringToken[2]+'/'+stringToken[0]);
      }
      else{
        return '';
      }
  }

  expand(row) {
    row.expanded = !row.expanded;
  }

   toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    this.filterBillDelivery();
  }


  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
      if(this.filteredBillingDelivery){
        this.filteredBillingDelivery = this.orderByPipe.transform(this.filteredBillingDelivery, this.sortBy, this.ascending);
        this.pager = {};
        this.pager = this.pagerService.getPage(this.filteredBillingDelivery, page, this.selectedDenom.count)
      }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  applyChanges()
  {
      this.validateEmail();
      if(!this.showEmailError){
          this.dataService.setLoading(true);
          this.pager = null;
          this.hasNewChanges = false;
          var eBillFlag = ' ';
          var paperBillFlag = ' ';
          var emailChangeList : IBillDeliveryEditEmail[] =[];
          for(var emailSel of this.emailAddUpdate) {
            if(emailSel.flag!=null)
            {
              emailChangeList.push(emailSel);
            }
          }

          if(this.selectedElectronicDel)
            eBillFlag = 'X';
          if(this.selectedPaperDel)
            paperBillFlag = 'X';
          var ebillRequest: IBillDeliveryEditPostRequest;
          ebillRequest= {
                'siteId': this.billDeliverySel.sites[0].siteId,
                'caNumber': this.billDeliverySel.caNumber,
                'customerName': this.dataService.getAccountSource().name,
                'serviceAddress': this.getFullAddress(this.billDeliverySel.sites[0].serviceAddress),
                'billingAddress': this.getFullAddress(this.billDeliverySel.sites[0].billingAddress),
                'eBillFlag': eBillFlag,
                'paperBillFlag': paperBillFlag,
                'origEBillFlag': this.billDeliverySel.origEBillFlag,
                'origPaperBillFlag': this.billDeliverySel.origPaperBillFlag,
                'emails': emailChangeList
          };
          this.billDeliveryEditService.applyChanges(ebillRequest).subscribe(
            (resp: any) => {
              if(resp.statusCode == '00') { //success
                this.dataService.setLoading(false);
                this.getBillDelivery();
              }
              else if(resp.statusCode == '99') { //FAILED
                  this.dataService.setLoadingText('');
                  this.dataService.setLoading(false);
              }
              else {
                this.dataService.setLoading(false);
              }
              this.close();
            });
        }
  }

  //called when add new email is clicked. It will initialize object
  addNewEmail()
  {
    this.isAddEmailMode = true;
    this.newEmailAdd = {};
    let today = new Date();
    today.setDate(today.getDate());

    let mon = (today.getMonth() + 1) + '';
    let dt = today.getDate()  + '';
    if(mon.length==1)
      mon='0'+mon;
    if(dt.length==1)
      dt='0'+dt;

    let dateFormattedToday: string =  today.getFullYear() + "-" + mon + "-" + dt;
    this.newEmailAdd.fromDate = dateFormattedToday;
    this.newEmailAdd.toDate = '9999-12-31';
    this.newEmailAdd.flag="A";
    //this.showEmailError = false;
    this.hideApply = true;
  }

  isValidElectronicSel(){
      if(!this.selectedElectronicDel)
        return true;
      else {
          if(this.emailAddUpdate && this.emailAddUpdate.length>0){
            let today = new Date();
            for(var emailSel of this.emailAddUpdate) {
                let tempDt = new Date();
                var spltDt = emailSel.toDate.split("-");
                tempDt.setDate(parseInt(spltDt[2]));
                tempDt.setMonth(parseInt(spltDt[1])-1);
                tempDt.setFullYear(parseInt(spltDt[0]));
                if(tempDt>today){
                  return true;
                }
            }
          }

      }
  }


  // when cancel is called : discard changes from Add/Update
  cancelAdd(){
    this.isAddEmailMode=false;
    this.updateRec=false;
    this.enddatesel = null;
    this.showEmailError = false;
    this.hideApply = false;
  }

  //when Save button is clicked : Temporary Save
  saveAdd(){
    this.isAddEmailMode=false;
    this.updateRec=false;
    this.emailAddUpdate.push(this.newEmailAdd);
    this.enddatesel = null;
    this.hasNewChanges = true;
    this.hideApply = false;
  }

  //when Update is clicked : only end date is Updated for existing email
  saveUpdate(){
    this.toggleEndDateMode();
    this.updateRec=false;
    this.enddatesel = null;
    this.hideApply = false;
  }

  deleteEmail(email){
    this.updateRec = false;
    let yday = new Date();
    //yday.setDate(yday.getDate() - 1);
    let mon = (yday.getMonth() + 1) + '';
    let dt = yday.getDate()  + '';
    if(mon.length==1)
      mon='0'+mon;
    if(dt.length==1)
      dt='0'+dt;
    let dateFormatted: string =  yday.getFullYear() + "-" +  mon + "-" + dt;
    email.toDate = dateFormatted;
    email.flag = "D";
    this.hasNewChanges = true;
  }



  // action when a  date is selected for New Email / Updating email
  editedEndDate(){

    let mon = (this.enddatesel.getMonth() + 1) + '';
    let dt = this.enddatesel.getDate()  + '';
    if(mon.length==1)
      mon='0'+mon;
    if(dt.length==1)
      dt='0'+dt;

    let dateFormatted: string =  this.enddatesel.getFullYear() + "-" +  mon + "-" + dt;

    if(this.isAddEmailMode){
        this.newEmailAdd.toDate = dateFormatted;
    }
    else if(this.updateRec)
    {
         this.updateEmail.toDate = dateFormatted;
         this.updateEmail.flag="U";
    }
    this.hasNewChanges = true;
    this.hideApply = true;
  }

  toggleEndDateMode(){
    this.endDateChange = !this.endDateChange;
  }


  editEmailMode(email){
    this.updateRec = true;
    this.updateEmail = email;
    this.enddatesel = email.toDate;
  }

  saveEmail(){
    //save code here
    this.updateRec = false;
    this.emailAddUpdate = [];
  }

  validateEmail(){
    this.showEmailError = false;
    for(var emailSel of this.emailAddUpdate) {
        if(this.isAddEmailMode && this.newEmailAdd.emailId===emailSel.emailId)
          this.showEmailError = true;
    }
  }

  changePaperDelOpt(selPaperDel){
    this.selectedPaperDel = selPaperDel;

    if((this.selectedPaperDel &&  this.billDeliverySel.origPaperBillFlag=='X') || (!this.selectedPaperDel &&  this.billDeliverySel.origPaperBillFlag==' '))
           this.isPaperOptChange = false;
    else
          {
             this.isPaperOptChange = true;
          }

  }


  changeElectronicOpt(selElectronicDel){
    this.selectedElectronicDel = selElectronicDel;
    if((this.selectedElectronicDel &&  this.billDeliverySel.origEBillFlag=='X') || (!this.selectedElectronicDel &&  this.billDeliverySel.origEBillFlag==' '))
           this.isEmailOptChange = false;
    else
       {
            this.isEmailOptChange = true;
      }
  }

  numberWithCommas(x, dollar?: boolean, twoDecimals?: boolean) {
    if(x == null){
      return '--'
    }
    let val = +x;
    let response = this.portalService.numberWithCommas(val);
    if(dollar || twoDecimals){
      response = this.portalService.numberWithCommas(val.toFixed(2));
    }
    if(dollar){
      response = '$' + response;
    }
    return response;
  }

  // export() {
  //   // Get start date, end date, account Id,
  //   let electronicOpt = this.selectedElectronicOption;
  //   let paperOpt = this.selectedPaperOption;
  //   this.dataService.setLoading(true);

  //   if(startDateString && endDateString){
  //       let request: I = {
  //        accountId: this.myAccount.id,
  //        accountName: this.dataService.getAccountSource().name,
  //        startDate: startDateString,
  //        endDate: endDateString,
  //        maskingMode : this.dataService.getMaskMode(),
  //        brand: window.localStorage.getItem("brand")
  //     };
  //     this.billDeliveryEditService.exportBillDelivery(request);
  //    }
  //  }

  filterBillDelivery() {
    if(this.filteredBillingDelivery){
      if (this.searchTerm) {
        this.filteredBillingDelivery = this.billDeliveryEditPipe.transform(this.allBillingDelivery, this.searchTerm, this.selectedElectronicOption,  this.selectedPaperOption );
      } else {
          this.filteredBillingDelivery = [];
          this.allBillingDelivery.forEach((billDelRow) => {
          if((this.selectedElectronicOption==='ALL' || (this.selectedElectronicOption==='ENROLLED' && billDelRow.eBillFlag==='X') || (this.selectedElectronicOption==='NOT ENROLLED' && billDelRow.eBillFlag===' '))
              && (this.selectedPaperOption==='ALL' || (this.selectedPaperOption==='PAPER DELIVERY' && billDelRow.paperBillFlag==='X') || (this.selectedPaperOption==='NO PAPER DELIVERY' && billDelRow.paperBillFlag===' '))
              ){
                this.filteredBillingDelivery.push(billDelRow);
              }
          });
      }
      this.paginate(1);
     }
  }

  sortBillingDelivery(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }

  selectElectronicOption(selectedElectronicOption){
    this.selectedElectronicOption = selectedElectronicOption;
    this.filterBillDelivery();
  }
  selectPaperOption(selectedPaperOption){
       this.selectedPaperOption = selectedPaperOption;
       this.filterBillDelivery();
  }

  getTooltip(siteaddress: IAddress) {
      return siteaddress.streetAddress + ' \n' + siteaddress.city + ', ' + siteaddress.state + ' ' + siteaddress.zipCode;
     }

  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getAddress(siteaddress: IAddress) {
    return siteaddress.streetAddress ? siteaddress.streetAddress + '...' : '';
  }

getFullAddress(siteaddress: IAddress) {
    return (siteaddress.streetAddress ? siteaddress.streetAddress : '') + " " +(siteaddress.city ? siteaddress.city : '') + " " + (siteaddress.state ? siteaddress.state: '') + " " + (siteaddress.zipCode ? siteaddress.zipCode : '');
  }

  formatSiteId(siteId: string){
    if(siteId.indexOf("-") != -1){
      return siteId.substring(siteId.indexOf("-")+1);
    } else{
      return siteId;
    }
  }

getBillDelivery(){
       this.dataService.setLoading(true);
       this.billDeliveryEditService.getBillDelivery().subscribe(
          (billingDelivery : any) => {
            this.allBillingDelivery = billingDelivery;
            let tempBillDelArray : IBillDeliveryEdit[] = [];
            if(this.allBillingDelivery) {
              this.allBillingDelivery.forEach( billingDelItem => {
                if(billingDelItem.flag!=='SG')
                  tempBillDelArray.push(billingDelItem);
                else
                  this.hasSGrow=true;
                });
            }
            this.allBillingDelivery = tempBillDelArray;
            this.noDataError = this.allBillingDelivery.length == 0;
            this.displayLimitError = false;
            this.ascending = false;

            this.filteredBillingDelivery = this.orderByPipe.transform(this.allBillingDelivery, 'siteid', this.ascending);

            this.paginate(1);
            this.dataService.setLoading(false);
          }, err => {
            if(err.status == 404) {
             // this.noDataError = true;
              this.displayLimitError = true;
            }
            if(err.status == 500) {
              this.displayLimitError = true;
            //  this.noDataError = false;
            }
            this.filteredBillingDelivery = [];
            this.paginate(1);
            console.error(err);
            this.dataService.setLoading(false);
          }
        );
   }

   isDeleted(emailSel){
    let today = new Date();
    let dtPassed = new Date(emailSel.toDate);

    if(dtPassed<today){
      return true;
    }
    else{
      return false;
    }
   }

  ngOnDestroy() {
    if (this.exportTriggeredSubscription) this.exportTriggeredSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  editBillDelivery(billDelivery)
  {
    //this.hideApply = true;
    this.isAddEmailMode = false;
    this.updateRec = false;
    this.billDeliverySel = billDelivery;
    this.billDeliverySel.origEBillFlag = this.billDeliverySel.eBillFlag;
    this.billDeliverySel.origPaperBillFlag = this.billDeliverySel.paperBillFlag;
    this.origEmails = []; //Array.from(this.billDeliverySel.emails);
    if(this.billDeliverySel.emails)
      this.billDeliverySel.emails.map(val => this.origEmails.push(Object.assign({}, val)));

    this.emailAddUpdate = [] //Array.from(this.billDeliverySel.emails);
    if(this.billDeliverySel.emails)
      this.billDeliverySel.emails.map(val => this.emailAddUpdate.push(Object.assign({}, val)));


    this.selectedElectronicDel = (this.billDeliverySel.eBillFlag!=' ');
    this.selectedPaperDel = (this.billDeliverySel.paperBillFlag!=' ');

    $('#billEditModal').modal({
      show: true,
      backdrop: 'static'
    });
  }

  close() {
    if(this.hasNewChanges) {
      $('#deleteModal').modal('show');
    }
    else {
      this.billDeliverySel.emails = this.origEmails; //discard all
      this.showEmailError = false;
      $('#billEditModal').modal('hide');
    }
  }

  discardChanges(){
      this.billDeliverySel.emails = this.origEmails; //discard all
      this.showEmailError = false;
      $('#billEditModal').modal('hide');
      $('#deleteModal').modal('hide');
  }

  exportBillDelivery(){
    let currentDate = moment().format("YYYY-MM-DD");
    let billDeliveryList = this.filteredBillingDelivery;
    let maskingMode = this.dataService.getMaskMode();
    let accountName = this.dataService.getAccountSource().name;
    let brand = this.dataService.getAccountSource().accountBranding;

    let request = {
      date : currentDate,
      accountName : accountName,
      eBillList : billDeliveryList,
      maskingMode: maskingMode,
      brand: brand,
      paperOption: this.selectedPaperOption,
      electronicOption: this.selectedElectronicOption
    };

    this.billDeliveryEditService.exportBillDelivery(request);
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }
}

