import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataService } from '../shared/data.service';
import { IUserAccount } from '../shared/entities/profile';
import { IStatement, IStatementRequest } from '../shared/entities/statement';
import { GenericPipe } from '../shared/pipe/generic.pipe';
import { OrderByPipe } from '../shared/pipe/orderBy.pipe';
import { PortalService } from '../shared/portal.service';
import { StatementsService } from './statements.service';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  providers: [GenericPipe, OrderByPipe],
  styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {

  myAccount: IUserAccount;
  selectedStartDate: Date;
  selectedEndDate: Date;
  minDate: Date;
  maxDate: Date;
  maskMode: boolean;
  maskModeSubscription: Subscription;
  invalidDateError: string;
  statements : IStatement[] = [];
  searchTerm: string;
  filteredStatements : IStatement[] = [];
  sortBy: string = 'statementDate';
  ascending: boolean;
  showSearchRow: boolean;
  testStatement: IStatement;
  thirdPartyId: string;
  isLoaded: boolean;



  constructor(
    private dataService: DataService,
    private statementsService: StatementsService,
    private portalService: PortalService,
    private genericPipe: GenericPipe,
    private orderByPipe: OrderByPipe,
  ) { 
  }

  ngOnInit() {
    this.dataService.setTitleSource('Statements');
    this.dataService.setCurrentActivePage('payments');
    this.dataService.setContextualHelp(false);
    this.minDate = moment('2016-01-01').toDate();
    this.maxDate = moment(new Date()).toDate();
    // this.testStatement = {date : moment('2018-01-01').toDate(), url: 'test'};

    this.selectedStartDate = moment(new Date()).subtract(3,'months').toDate();
    this.selectedEndDate = moment(new Date()).toDate();

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    this.myAccount = this.dataService.getAccountSource();
    this.thirdPartyId = this.myAccount.id;
    this.dataService.setLoading(true);
    let request : IStatementRequest = {thirdPartyId : this.myAccount.id, statementId : ''};
    this.statementsService.getAllStatements(request).subscribe(
      (statements) => {
        this.dataService.setLoading(true);
        this.statements = statements;
        this.isLoaded = true;
        this.filterStatements();
        this.dateFilter();
        this.dataService.setLoading(false);
      }
    );
    this.dataService.setLoading(false);
  }

  getDateDisplay(date: string) { return moment(date).format('MM/DD/YYYY') }

  getStatements(){
    if(this.validateDates()){
      let request : IStatementRequest = {thirdPartyId : this.myAccount.id, statementId : ''};
      this.statementsService.getAllStatements(request).subscribe(
        (statements) => {
          console.log(statements)
          this.statements = statements;
          this.isLoaded = true;
        }
      );
      this.filterStatements();
    } 
  }

  dateFilter() {
    if(this.statements.length > 0){
      let displayStatements = [];
      this.statements.forEach((statement) =>{
        if(moment(statement.createDate).isSameOrAfter(this.selectedStartDate) && moment(statement.createDate).isSameOrBefore(this.selectedEndDate)) {
          displayStatements.push(statement);
        }
      });
      this.filteredStatements = displayStatements;
    }
  }

  filterStatements() {
    if(this.searchTerm){
      this.filteredStatements = this.genericPipe.transform(this.statements, this.searchTerm);
    } else {
      this.filteredStatements = this.statements;
    }
    this.filteredStatements = this.orderByPipe.transform(this.filteredStatements, this.sortBy, this.ascending);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterStatements();
  }

  sortStatement(sortBy: string){
    if(sortBy == this.sortBy){
      this.ascending = !this.ascending;
    }
    this.sortBy = sortBy;
    this.filteredStatements = this.orderByPipe.transform(this.filteredStatements, this.sortBy, this.ascending);
  }

  validateDates() {
    this.invalidDateError = '';
    if(moment(this.selectedStartDate).isAfter(moment(this.selectedEndDate))){
      this.invalidDateError = 'Please select a valid start and end date.';
      return false;
    } else if(moment(this.selectedStartDate).isBefore(moment(this.minDate))) {
      this.invalidDateError = 'Please select a start date on or after ' + moment(this.minDate).format('M/D/YYYY') + '.';
      return false;
    } else if (moment(this.selectedEndDate).isAfter(moment(this.maxDate).add(1, 'day'))) {
      this.invalidDateError = 'Please select an end date on or before ' + moment(this.maxDate).format('M/D/YYYY') + '.';
      return false;
    } else {
      return true;
    }
  }

  
  maskAccountName(account: IUserAccount) {
    return this.portalService.getFakeCompanyName(account);
  }


  downloadStatement(statementId: string, documentRefId: string){
    this.dataService.setLoading(true);
    let request : IStatementRequest = { thirdPartyId : this.thirdPartyId , statementId : statementId};
    this.statementsService.dowloadBrokerStatement(request, documentRefId);
    this.dataService.setLoading(false);

  }
}
