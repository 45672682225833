import { environment } from '../../../environments/environment';
import { IZone } from './product';
export const ErcotMarketSources = {
  ActualLoad: 'ACTUAL_LOAD',
  ForecastLoad: 'FORECAST_LOAD',
  HubAverage: 'HB_HUBAVG',
  HoustonHub: 'HB_HOUSTON',
  NorthHub: 'HB_NORTH',
  PanHandleHub: 'HB_PAN',
  SouthHub: 'HB_SOUTH',
  WestHub: 'HB_WEST',
  HoustonZone: 'LZ_HOUSTON',
  NorthZone: 'LZ_NORTH',
  SouthZone: 'LZ_SOUTH',
  WestZone: 'LZ_WEST',
  DAHubAverage: 'DA_HB_HUBAVG',
  DAHoustonHub: 'DA_HB_HOUSTON',
  DANorthHub: 'DA_HB_NORTH',
  DAPanHandleHub: 'DA_HB_PAN',
  DASouthHub: 'DA_HB_SOUTH',
  DAWestHub: 'DA_HB_WEST',
  DAHoustonZone: 'DA_LZ_HOUSTON',
  DANorthZone: 'DA_LZ_NORTH',
  DASouthZone: 'DA_LZ_SOUTH',
  DAWestZone: 'DA_LZ_WEST',
  BusAvg: 'HB_BUSAVG',
  HenryHubMonthly: 'HENRY_HUB_MO',
  HenryHubDaily: 'HENRY_HUB_FDT_COM',
  HSCMonthly: 'HOUSTON_SHIP_CHANNEL_MO',
  HSCDaily: 'HOUSTON_SHIP_CHANNEL_FDT_COM',
  Reserves: 'RESERVES',
  ERCOTActualLoad: 'ERCOT_ACTUAL_LOAD',
  ERCOTNetLoad: 'ERCOT_NET_LOAD',
  ERCOTForecastLoad: 'ERCOT_FORECAST_LOAD',
  ERCOTForecastedLoad: 'ERCOT_FORECASTED_LOAD',
  ERCOTForecastedPeakLoad: 'ERCOT_FORECASTED_PEAK_LOAD',
  ERCOTOutagesOverall: 'ERCOT_OUTAGES_OVERALL',
  ERCOTOutagesPlanned: 'ERCOT_OUTAGES_PLANNED',
  ERCOTOutagesUnplanned: 'ERCOT_OUTAGES_UNPLANNED',
  ERCOTCombinedRenewables: 'ERCOT_COMBINED_RENEWABLES',
  ERCOTWindGen: 'ERCOT_WIND_GEN',
  ERCOTSolarGen: 'ERCOT_SOLAR_GEN',
  ERCOTHydroGen: 'ERCOT_HYDRO_GEN',
  ERCOTPowerStorageGen: 'ERCOT_POWER_STORAGE_GEN',
  ERCOTOtherGen: 'ERCOT_OTHER_GEN',
  ERCOTCoalLigniteGen: 'ERCOT_COAL_LIGNITE_GEN',
  ERCOTNuclearGen: 'ERCOT_NUCLEAR_GEN',
  ERCOTNaturalGasGen: 'ERCOT_NATURAL_GAS_GEN',
  ERCOTPriceAdders: 'ERCOT_PRICE_ADDERS',
  ERCOTOnlineReserveAdder: 'ERCOT_ONLINE_RESERVE_ADDER',
  ERCOTOnlineReliabilityAdder: 'ERCOT_ONLINE_RELIABILITY_ADDER',
  ERCOTASOverall: 'ERCOT_AS_OVERALL',
  ERCOTNonSpin: 'ERCOT_NON_SPIN',
  ERCOTPRC: 'ERCOT_PRC',
  ERCOTSCED: 'ERCOT_SCED',
  ERCOTRegUp: 'ERCOT_REG_UP',
  ERCOTRegDown: 'ERCOT_REG_DOWN',
  ERCOTECRS: 'ERCOT_ECRS',
  ERCOTRRS: 'ERCOT_RRS',
  ERCOTNetImportExport: 'ERCOT_NET_IMPORT_EXPORT',
  ERCOTImport: 'ERCOT_IMPORT',
  ERCOTExport: 'ERCOT_EXPORT',
  ERCOTRTLoad: 'ERCOT_RT_LOAD',
  ERCOTReserves: 'ERCOT_RESERVES',
  ERCOTRTSystemCapacity: 'ERCOT_RT_SYSTEM_CAPACITY',
  ERCOTForecastedSystemCapacity: 'ERCOT_FORECASTED_SYSTEM_CAPACITY',
  ERCOTHubAverage: 'ERCOT_HB_HUBAVG',
  ERCOTRTWestHub: 'ERCOT_RT_WEST_HUB',
  ERCOTAenZone: 'ERCOT_LZ_AEN',
  ERCOTCpsZone: 'ERCOT_LZ_CPS',
  ERCOTLcraZone: 'ERCOT_LZ_LCRA',
  ERCOTRaybnZone: 'ERCOT_LZ_RAYBN',
  ERCOTDaAenZone: 'ERCOT_DA_LZ_AEN',
  ERCOTDaCpsZone: 'ERCOT_DA_LZ_CPS',
  ERCOTDaLcraZone: 'ERCOT_DA_LZ_LCRA',
  ERCOTDaRaybnZone: 'ERCOT_DA_LZ_RAYBN',
  ERCOTHoustonHub: 'ERCOT_HB_HOUSTON',
  ERCOTNorthHub: 'ERCOT_HB_NORTH',
  ERCOTPanHandleHub: 'ERCOT_HB_PAN',
  ERCOTSouthHub: 'ERCOT_HB_SOUTH',
  ERCOTWestHub: 'ERCOT_HB_WEST',
  ERCOTHoustonZone: 'ERCOT_LZ_HOUSTON',
  ERCOTNorthZone: 'ERCOT_LZ_NORTH',
  ERCOTSouthZone: 'ERCOT_LZ_SOUTH',
  ERCOTWestZone: 'ERCOT_LZ_WEST',
  ERCOTDAHubAverage: 'ERCOT_DA_HB_HUBAVG',
  ERCOTDAHoustonHub: 'ERCOT_DA_HB_HOUSTON',
  ERCOTDANorthHub: 'ERCOT_DA_HB_NORTH',
  ERCOTDAPanHandleHub: 'ERCOT_DA_HB_PAN',
  ERCOTDASouthHub: 'ERCOT_DA_HB_SOUTH',
  ERCOTDAWestHub: 'ERCOT_DA_HB_WEST',
  ERCOTDAHoustonZone: 'ERCOT_DA_LZ_HOUSTON',
  ERCOTDANorthZone: 'ERCOT_DA_LZ_NORTH',
  ERCOTDASouthZone: 'ERCOT_DA_LZ_SOUTH',
  ERCOTDAWestZone: 'ERCOT_DA_LZ_WEST',
  ERCOTSolar: 'ERCOT_SOLAR',
  ERCOTWind: 'ERCOT_WIND',
  ERCOTSolarForecast: 'ERCOT_SOLAR_FORECAST',
  ERCOTWindForecast: 'ERCOT_WIND_FORECAST',
  ERCOTNuclear: 'ERCOT_NUCLEAR',
  ERCOTOther: 'ERCOT_OTHER',
  ERCOTHydro: 'ERCOT_HYDRO',
  ERCOTNaturalGas: 'ERCOT_NATURAL_GAS',
  ERCOTPowerStorage: 'ERCOT_POWER_STORAGE',
  ERCOTCoalAndLignite: 'ERCOT_COAL_AND_LIGNITE',
  ERCOTBusAverageLMP: 'ERCOT_HB_BUSAVG_LMP',
  ERCOTHoustonHubLMP: 'ERCOT_HB_HOUSTON_LMP',
  ERCOTHubAverageLMP: 'ERCOT_HB_HUBAVG_LMP',
  ERCOTNorthHubLMP: 'ERCOT_HB_NORTH_LMP',
  ERCOTPanHandleHubLMP: 'ERCOT_HB_PAN_LMP',
  ERCOTSouthHubLMP: 'ERCOT_HB_SOUTH_LMP',
  ERCOTWestHubLMP: 'ERCOT_HB_WEST_LMP',
  ERCOTHoustonZoneLMP: 'ERCOT_LZ_HOUSTON_LMP',
  ERCOTNorthZoneLMP: 'ERCOT_LZ_NORTH_LMP',
  ERCOTSouthZoneLMP: 'ERCOT_LZ_SOUTH_LMP',
  ERCOTWestZoneLMP: 'ERCOT_LZ_WEST_LMP',
  ERCOTAenZoneLMP: 'ERCOT_LZ_AEN_LMP',
  ERCOTCpsZoneLMP: 'ERCOT_LZ_CPS_LMP',
  ERCOTLcraZoneLMP: 'ERCOT_LZ_LCRA_LMP',
  ERCOTRaybnZoneLMP: 'ERCOT_LZ_RAYBN_LMP'
};

export const ErcotRTMarketDemandTitles = new Map([
  [ErcotMarketSources.ERCOTNetLoad, 'NET LOAD'],
  [ErcotMarketSources.ERCOTForecastLoad, 'FORECASTED LOAD'],
  [ErcotMarketSources.ERCOTForecastedPeakLoad, 'FORECASTED PEAK LOAD'],
  [ErcotMarketSources.ERCOTOutagesOverall, 'OUTAGES - OVERALL'],
  [ErcotMarketSources.ERCOTOutagesPlanned, 'OUTAGES - PLANNED'],
  [ErcotMarketSources.ERCOTOutagesUnplanned, 'OUTAGES - UNPLANNED'],
  [ErcotMarketSources.ERCOTCombinedRenewables, 'COMBINED RENEWABLES'],
  [ErcotMarketSources.ERCOTWindGen, 'WIND GENERATION'],
  [ErcotMarketSources.ERCOTSolarGen, 'SOLAR GENERATION'],
  [ErcotMarketSources.ERCOTHydroGen, 'HYDRO GENERATION'],
  [ErcotMarketSources.ERCOTOtherGen, 'OTHER GENERATION'],
  [ErcotMarketSources.ERCOTPriceAdders, 'PRICE ADDERS - OVERALL'],
  [ErcotMarketSources.ERCOTOnlineReserveAdder, 'ONLINE RESERVE PROCE ADDER'],
  [
    ErcotMarketSources.ERCOTOnlineReliabilityAdder,
    'ONLINE RELIABILITY DEPLOYMENT PRICE ADDER'
  ],
  [ErcotMarketSources.ERCOTASOverall, 'A/S - OVERALL'],
  [ErcotMarketSources.ERCOTECRS, 'ECRS'],
  [ErcotMarketSources.ERCOTRRS, 'RRS'],
  [ErcotMarketSources.ERCOTNonSpin, 'NON SPIN'],
  [ErcotMarketSources.ERCOTRegUp, 'REGULATION UP'],
  [ErcotMarketSources.ERCOTRegDown, 'REGULATION DOWN'],
  [ErcotMarketSources.ERCOTECRS, 'ECRS'],
  [ErcotMarketSources.ERCOTNetImportExport, 'NET IMPORTS/EXPORTS'],
  [ErcotMarketSources.ERCOTImport, 'IMPORTS'],
  [ErcotMarketSources.ERCOTExport, 'EXPORTS']
]);

export interface IMarketData {
  sources: IMarketSource[];
}

export interface IMarketProperties {
  market: string;
  name: string;
  chartTitle: string;
  offsets: IOffset[];
  futureOffsets: IOffset[];
  comparisons: IMarketComparison[];
  dataUrl: string;
  defaultOffset: IOffset;
  defaultFutureOffset?: IOffset;
}

export interface IUMarketProperties {
  name: string;
  chartTitle: string;
  offsets: IOffset[];
  futureOffsets: IOffset[];
  market: IUMarkets[];
  //comparisons: IUMarketComparison[];
  dataUrl: string;
  defaultOffset: IOffset;
  defaultFutureOffset?: IOffset;
}

export interface IRMarketProperties {
  name: string;
  chartTitle: string;
  offsets: IOffset[];
  futureOffsets: IOffset[];
  comparisons: IRMarketComparison[];
  dataUrl: string;
  defaultOffset: IOffset;
  defaultFutureOffset?: IOffset;
}

export interface IMarketSource {
  id?: string;
  title?: string;
  source: string;
  dates: string[];
  values: number[];
}

export interface IOffset {
  display: string; // "+7 Days"
  value: string; // "+7"
  unit?: string;
}

export interface IRMarketComparison {
  zIndex: number;
  display: string; // "ERCOT Actual Load"
  value: string; // "ACTUAL_LOAD"
  hexColor: string; // "#439539"
  selected: boolean;
  groupStart?: boolean;
  groupName?: string;
  expandedValue?: boolean;
  expandColor?: boolean;
}

export interface IUMarkets {
  marketName: string;
  comparisons: IUMarketComparison[];
  counter: number;
  expanded: boolean;
  expandedColor: boolean;
}

export interface IUMarketComparison {
  zIndex: number;
  display: string; // "ERCOT Actual Load"
  value: string; // "ACTUAL_LOAD"
  hexColor: string; // "#439539"
  selected: boolean;
  groupStart?: boolean;
  groupName?: string;
  expandedValue?: boolean;
  expandColor?: boolean;
  selectedCount?: number;
}

export interface IMarketComparison {
  zIndex: number;
  display: string; // "ERCOT Actual Load"
  value: string; // "ACTUAL_LOAD"
  hexColor: string; // "#439539"
  selected: boolean;
  groupStart?: boolean;
  groupName?: string;
  expandedValue?: boolean;
  expandColor?: boolean;
}

export interface IMarketIndicator {
  display: string;
  value: string;
}

export interface IMarketsDashboardProperty {
  value: number;
  date: Date;
  formattedDate: string;
}

export interface IForwardTradeRates {
  termStartDate: Date;
  termLength: number;
  rates: IFlowRate[];
}

export interface IFlowRate {
  flowDate: Date;
  flowPrice: number;

  minFlowPrice: number;
  maxFlowPrice: number;

  minFlowPriceTradeDate: Date;
  maxFlowPriceTradeDate: Date;
}

export interface IDateCurveRates {
  tradeDate: Date;
  rates: IFlowRate[];
}

export interface ITradeDateRate {
  year: string;
  rate: number;
}

export interface IYearCurveRate {
  tradeDate: Date;
  rates: number[];
}

export interface IReportSettings {
  reportId?: number;
  accountId: string;
  createdUserId: string;
  reportName?: string;
  publicFlag?: number;
  globalFlag?: number;
  priceType: string;
  market: string;
  peakType: string;
  settlementType: string;
  settlementZone?: string;
  tradeStartDate: any;
  tradeEndDate: any;
  updatedBy: string;
  updatedDate?: Date;
  updatedDateDisplay?: string;
  termLengths: IReportTerms[];
  accessType: string;
  reportType: string;
  congestionZones: IZone[];
  productId?: string;
  productName?: string;
  contractId?: string;
  contractName?: string;
  siteCount?: number;
  periodType?: string;
}

export interface IReportTerms {
  termStartDate: Date;
  termLength: number;
  termType?: string;
}

export interface MarketDemandComparison {
  text: string;
  source?: string;
  checked?: boolean;
  count?: number;
  expanded: boolean;
  items?: {
    text: string;
    source: string;
    checked: boolean;
  }[];
}

export interface MarketGraphComparison {
  text: string;
  count: number;
  expanded: boolean;
  items: ComparisonOptionItem[];
  parent?: string;
}

export interface ComparisonOptionItem {
  text: string;
  selected: boolean;
  source: string;
  hexColor: string;
  parent?: string;
}

export class FuelMixData {
  lastUpdated: string;
  day: string;
  interval: string;
  type: string;
  gen: number;
  hsl: number;
  maxCapacity: number;
  seasonalCapacity: number;
  percentage: number;
}

export interface MarketDashboardView {
  mdUser: string;
  viewName: string;
  viewValue: string;
  market: string;
}

export const ErcotMarketSection = {
  Snapshot: 'SNAPSHOT',
  Info: 'INFO',
  HenryHub: 'HENRY_HUB',
  HoustonShipChannel: 'HOUSTON_SHIP_CHANNEL',
  GenerationMix: 'GENERATION_MIX',
  RTMarketDemand: 'ERCOT_RT_MARKET_DEMAND',
  RTMarketPrice: 'ERCOT_RT_MARKET_PRICE'
};

export const ErcotNewMarketSection = {
  Snapshot: 'ERCOT_SNAPSHOT',
  Info: 'ERCOT_INFO',
  HenryHub: 'ERCOT_HENRY_HUB',
  HoustonShipChannel: 'ERCOT_HOUSTON_SHIP_CHANNEL',
  GenerationMix: 'ERCOT_GENERATION_MIX',
  RTMarketDemand: 'ERCOT_RT_MARKET_DEMAND',
  RTMarketPrice: 'ERCOT_RT_MARKET_PRICE'
};

export const SnapshotOffsets: IOffset[] = [
  { display: 'Today', value: '-0' },
  { display: '-1 Day', value: '-1' },
  { display: '-2 Days', value: '-2' },
  { display: '-3 Days', value: '-3' }
];

export const SnapshotFutureOffsets: IOffset[] = [
  { display: '+7 Days', value: '+7' },
  { display: '+5 Days', value: '+5' },
  { display: '+3 Days', value: '+3' },
  { display: '+2 Days', value: '+2' },
  { display: '+1 Day', value: '+1' }
];

export const ErcotSnapshotOldComparisons: IMarketComparison[] = [
  {
    display: 'ERCOT Actual Load',
    value: ErcotMarketSources.ActualLoad,
    hexColor: '#00AEEF',
    selected: true,
    zIndex: 11
  },
  {
    display: 'ERCOT Future Load',
    value: ErcotMarketSources.ForecastLoad,
    hexColor: '#424242',
    selected: true,
    zIndex: 12
  },
  {
    display: 'Hub Average LMP',
    value: ErcotMarketSources.HubAverage,
    hexColor: '#439539',
    selected: true,
    zIndex: 13
  },
  {
    display: 'Houston Hub LMP',
    value: ErcotMarketSources.HoustonHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14
  },
  {
    display: 'North Hub LMP',
    value: ErcotMarketSources.NorthHub,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 15
  },
  {
    display: 'Panhandle Hub LMP',
    value: ErcotMarketSources.PanHandleHub,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 18
  },
  {
    display: 'South Hub LMP',
    value: ErcotMarketSources.SouthHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16
  },
  {
    display: 'West Hub LMP',
    value: ErcotMarketSources.WestHub,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 17
  }
];

export const ErcotSnapshotComparisons: IMarketComparison[] = [
  {
    display: 'ERCOT Actual Load',
    value: ErcotMarketSources.ERCOTActualLoad,
    hexColor: '#00AEEF',
    selected: true,
    zIndex: 11
  },
  {
    display: 'ERCOT Future Load',
    value: ErcotMarketSources.ERCOTForecastLoad,
    hexColor: '#424242',
    selected: true,
    zIndex: 12
  },
  {
    display: 'Hub Average LMP',
    value: ErcotMarketSources.ERCOTHubAverage,
    hexColor: '#439539',
    selected: true,
    zIndex: 13
  },
  {
    display: 'Houston Hub LMP',
    value: ErcotMarketSources.ERCOTHoustonHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14
  },
  {
    display: 'North Hub LMP',
    value: ErcotMarketSources.ERCOTNorthHub,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 15
  },
  {
    display: 'Panhandle Hub LMP',
    value: ErcotMarketSources.ERCOTPanHandleHub,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 18
  },
  {
    display: 'South Hub LMP',
    value: ErcotMarketSources.ERCOTSouthHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16
  },
  {
    display: 'West Hub LMP',
    value: ErcotMarketSources.ERCOTWestHub,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 17
  }
];

export const ErcotSRnapshotComparisons: IRMarketComparison[] = [
  // {display: 'ERCOT Actual Load', value: ErcotMarketSources.ActualLoad, hexColor: '#00AEEF', selected: true, zIndex: 11},
  // {display: 'ERCOT Future Load', value: ErcotMarketSources.ForecastLoad, hexColor: '#424242', selected: true,  zIndex: 12},
  {
    display: 'Hub Average LMP',
    value: ErcotMarketSources.ERCOTHubAverageLMP,
    hexColor: '#439539',
    selected: true,
    zIndex: 13
  },
  {
    display: 'Houston Hub LMP',
    value: ErcotMarketSources.ERCOTHoustonHubLMP,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14
  },
  {
    display: 'North Hub LMP',
    value: ErcotMarketSources.ERCOTNorthHubLMP,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 15
  },
  {
    display: 'Panhandle Hub LMP',
    value: ErcotMarketSources.ERCOTPanHandleHubLMP,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 18
  },
  {
    display: 'South Hub LMP',
    value: ErcotMarketSources.ERCOTSouthHubLMP,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16
  },
  {
    display: 'West Hub LMP',
    value: ErcotMarketSources.ERCOTWestHubLMP,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 17
  }
];

export const ErcotSnapshotProperties: IMarketProperties = {
  market: 'ERCOT',
  name: ErcotMarketSection.Snapshot,
  chartTitle: 'Market Snapshot',
  offsets: SnapshotOffsets,
  futureOffsets: SnapshotFutureOffsets,
  comparisons: ErcotSnapshotComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl, // getMarketDataUrl, //getMarketDashboardDataUrl
  defaultOffset: SnapshotOffsets[1],
  defaultFutureOffset: SnapshotFutureOffsets[4]
};

export const ErcotSRnapshotProperties: IRMarketProperties = {
  name: ErcotMarketSection.Snapshot,
  chartTitle: 'Market Snapshot',
  offsets: SnapshotOffsets,
  futureOffsets: SnapshotFutureOffsets,
  comparisons: ErcotSRnapshotComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl,
  defaultOffset: SnapshotOffsets[1],
  defaultFutureOffset: SnapshotFutureOffsets[4]
};
export const InfoOffsets: IOffset[] = [
  { display: '1 MO', value: '-30' },
  { display: '3 MO', value: '-90' },
  { display: '6 MO', value: '-180' },
  { display: '1 Y', value: '-365' },
  { display: '2 Y', value: '-730' },
  { display: '3 Y', value: '-1095' }
];

export const InfoFutureOffsets: IOffset[] = [];
export const ErcotInfoFutureOffsets: IOffset[] = [
  { display: '+0 Day', value: '+0' }
];

// export const ErcotInfoComparisons: IMarketComparison[] = [
//   {display: 'ERCOT Actual Load', value: ErcotMarketSources.ActualLoad, hexColor: '#00AEEF', selected: true, zIndex: 11, groupName: 'ERCOT Load', groupStart: true},
//   {display: 'Hub Average RTSPP', value: ErcotMarketSources.HubAverage, hexColor: '#439539', selected: true, zIndex: 12, groupName: 'Hub Real-Time SPP', groupStart: true},
//   {display: 'Houston Hub RTSPP', value: ErcotMarketSources.HoustonHub, hexColor: '#424242', selected: false, zIndex: 13, groupName: 'Hub Real-Time SPP'},
//   {display: 'North Hub RTSPP', value: ErcotMarketSources.NorthHub, hexColor: '#333092', selected: false, zIndex: 14, groupName: 'Hub Real-Time SPP'},
//   {display: 'Panhandle Hub RTSPP', value: ErcotMarketSources.PanHandleHub, hexColor: '#ffeb3b', selected: false, zIndex: 18, groupName: 'Hub Real-Time SPP'},
//   {display: 'South Hub RTSPP', value: ErcotMarketSources.SouthHub, hexColor: '#EF3E42', selected: false, zIndex: 15, groupName: 'Hub Real-Time SPP'},
//   {display: 'West Hub RTSPP', value: ErcotMarketSources.WestHub, hexColor: '#FBB034', selected: false, zIndex: 16, groupName: 'Hub Real-Time SPP'},
//   {display: 'Houston Zone RTSPP', value: ErcotMarketSources.HoustonZone, hexColor: '#8a6d3b', selected: false, zIndex: 19, groupName: 'Zone Real-Time SPP', groupStart: true},
//   {display: 'North Zone RTSPP', value: ErcotMarketSources.NorthZone, hexColor: '#424242b8', selected: false, zIndex: 20, groupName: 'Zone Real-Time SPP'},
//   {display: 'South Zone RTSPP', value: ErcotMarketSources.SouthZone, hexColor: '#3336b7c9', selected: false, zIndex: 21, groupName: 'Zone Real-Time SPP'},
//   {display: 'West Zone RTSPP', value: ErcotMarketSources.WestZone, hexColor: '#00AEEF', selected: false, zIndex: 22, groupName: 'Zone Real-Time SPP'},
//   {display: 'Hub Average DASPP', value: ErcotMarketSources.DAHubAverage, hexColor: '#439539', selected: true, zIndex: 23, groupName: 'Hub Day-Ahead SPP', groupStart: true},
//   {display: 'Houston Hub DASPP', value: ErcotMarketSources.DAHoustonHub, hexColor: '#424242', selected: false, zIndex: 24, groupName: 'Hub Day-Ahead SPP'},
//   {display: 'North Hub DASPP', value: ErcotMarketSources.DANorthHub, hexColor: '#333092', selected: false, zIndex: 25, groupName: 'Hub Day-Ahead SPP'},
//   {display: 'Panhandle Hub DASPP', value: ErcotMarketSources.DAPanHandleHub, hexColor: '#ffeb3b', selected: false, zIndex: 26, groupName: 'Hub Day-Ahead SPP'},
//   {display: 'South Hub DASPP', value: ErcotMarketSources.DASouthHub, hexColor: '#EF3E42', selected: false, zIndex: 27, groupName: 'Hub Day-Ahead SPP'},
//   {display: 'West Hub DASPP', value: ErcotMarketSources.DAWestHub, hexColor: '#FBB034', selected: false, zIndex: 28, groupName: 'Hub Day-Ahead SPP'},
//   {display: 'Houston Zone DASPP', value: ErcotMarketSources.DAHoustonZone, hexColor: '#8a6d3b', selected: false, zIndex: 29, groupName: 'Zone Day-Ahead SPP', groupStart: true},
//   {display: 'North Zone DASPP', value: ErcotMarketSources.DANorthZone, hexColor: '#424242b8', selected: false, zIndex: 30, groupName: 'Zone Day-Ahead SPP'},
//   {display: 'South Zone DASPP', value: ErcotMarketSources.DASouthZone, hexColor: '#3336b7c9', selected: false, zIndex: 31, groupName: 'Zone Day-Ahead SPP'},
//   {display: 'West Zone DASPP', value: ErcotMarketSources.DAWestZone, hexColor: '#00AEEF', selected: false, zIndex: 32, groupName: 'Zone Day-Ahead SPP'},
//   // {display: 'dummy Zone DASPP', value: ErcotMarketSources.DAWestZone, hexColor: '#00AEEF', selected: false, zIndex: 32, groupName: 'Zne Day-Ahead SPP'},
// ];

export const ErcotInfoOldComparisons: IMarketComparison[] = [
  {
    display: 'ERCOT Actual Load',
    value: ErcotMarketSources.ActualLoad,
    hexColor: '#00AEEF',
    selected: true,
    zIndex: 11,
    groupName: 'ERCOT Load',
    groupStart: true
  },
  {
    display: 'Hub Average RTSPP',
    value: ErcotMarketSources.HubAverage,
    hexColor: '#424242',
    selected: true,
    zIndex: 12,
    groupName: 'Hub Real-Time SPP',
    groupStart: true
  },
  {
    display: 'Houston Hub RTSPP',
    value: ErcotMarketSources.HoustonHub,
    hexColor: '#439539',
    selected: false,
    zIndex: 13,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'North Hub RTSPP',
    value: ErcotMarketSources.NorthHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Panhandle Hub RTSPP',
    value: ErcotMarketSources.PanHandleHub,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 18,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'South Hub RTSPP',
    value: ErcotMarketSources.SouthHub,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 15,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'West Hub RTSPP',
    value: ErcotMarketSources.WestHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Houston Zone RTSPP',
    value: ErcotMarketSources.HoustonZone,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 19,
    groupName: 'Zone Real-Time SPP',
    groupStart: true
  },
  {
    display: 'North Zone RTSPP',
    value: ErcotMarketSources.NorthZone,
    hexColor: '#f45b5b',
    selected: false,
    zIndex: 20,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'South Zone RTSPP',
    value: ErcotMarketSources.SouthZone,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 21,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'West Zone RTSPP',
    value: ErcotMarketSources.WestZone,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 22,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'Hub Average DASPP',
    value: ErcotMarketSources.DAHubAverage,
    hexColor: '#f45b5b',
    selected: true,
    zIndex: 23,
    groupName: 'Hub Day-Ahead SPP',
    groupStart: true
  },
  // {display: 'Hub Average DASPP', value: ErcotMarketSources.DAHubAverage, hexColor: '#424242', selected: true, zIndex: 23, groupName: 'Hub Day-Ahead SPP', groupStart: true},
  {
    display: 'Houston Hub DASPP',
    value: ErcotMarketSources.DAHoustonHub,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 24,
    groupName: 'Hub Day-Ahead SPP'
  },
  // {display: 'Houston Hub DASPP', value: ErcotMarketSources.DAHoustonHub, hexColor: '#439539', selected: false, zIndex: 24, groupName: 'Hub Day-Ahead SPP'},
  {
    display: 'North Hub DASPP',
    value: ErcotMarketSources.DANorthHub,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 25,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Panhandle Hub DASPP',
    value: ErcotMarketSources.DAPanHandleHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 26,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'South Hub DASPP',
    value: ErcotMarketSources.DASouthHub,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 27,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'West Hub DASPP',
    value: ErcotMarketSources.DAWestHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 28,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Houston Zone DASPP',
    value: ErcotMarketSources.DAHoustonZone,
    hexColor: '#5cf775',
    selected: false,
    zIndex: 29,
    groupName: 'Zone Day-Ahead SPP',
    groupStart: true
  },
  {
    display: 'North Zone DASPP',
    value: ErcotMarketSources.DANorthZone,
    hexColor: '#f35cf7',
    selected: false,
    zIndex: 30,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'South Zone DASPP',
    value: ErcotMarketSources.DASouthZone,
    hexColor: '#8a608b',
    selected: false,
    zIndex: 31,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'West Zone DASPP',
    value: ErcotMarketSources.DAWestZone,
    hexColor: '#5b61f4',
    selected: false,
    zIndex: 32,
    groupName: 'Zone Day-Ahead SPP'
  }
  // {display: 'dummy Zone DASPP', value: ErcotMarketSources.DAWestZone, hexColor: '#00AEEF', selected: false, zIndex: 32, groupName: 'Zne Day-Ahead SPP'},
];

export const ErcotInfoComparisons: IMarketComparison[] = [
  {
    display: 'ERCOT Actual Load',
    value: ErcotMarketSources.ERCOTActualLoad,
    hexColor: '#00AEEF',
    selected: true,
    zIndex: 11,
    groupName: 'ERCOT Load',
    groupStart: true
  },
  {
    display: 'Hub Average RTSPP',
    value: ErcotMarketSources.ERCOTHubAverage,
    hexColor: '#424242',
    selected: true,
    zIndex: 12,
    groupName: 'Hub Real-Time SPP',
    groupStart: true
  },
  {
    display: 'Houston Hub RTSPP',
    value: ErcotMarketSources.ERCOTHoustonHub,
    hexColor: '#439539',
    selected: false,
    zIndex: 13,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'North Hub RTSPP',
    value: ErcotMarketSources.ERCOTNorthHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Panhandle Hub RTSPP',
    value: ErcotMarketSources.ERCOTPanHandleHub,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 18,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'South Hub RTSPP',
    value: ErcotMarketSources.ERCOTSouthHub,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 15,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'West Hub RTSPP',
    value: ErcotMarketSources.ERCOTWestHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Houston Zone RTSPP',
    value: ErcotMarketSources.ERCOTHoustonZone,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 19,
    groupName: 'Zone Real-Time SPP',
    groupStart: true
  },
  {
    display: 'North Zone RTSPP',
    value: ErcotMarketSources.ERCOTNorthZone,
    hexColor: '#f45b5b',
    selected: false,
    zIndex: 20,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'South Zone RTSPP',
    value: ErcotMarketSources.ERCOTSouthZone,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 21,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'West Zone RTSPP',
    value: ErcotMarketSources.ERCOTWestZone,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 22,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'Hub Average DASPP',
    value: ErcotMarketSources.ERCOTDAHubAverage,
    hexColor: '#f45b5b',
    selected: true,
    zIndex: 23,
    groupName: 'Hub Day-Ahead SPP',
    groupStart: true
  },
  // {display: 'Hub Average DASPP', value: ErcotMarketSources.DAHubAverage, hexColor: '#424242', selected: true, zIndex: 23, groupName: 'Hub Day-Ahead SPP', groupStart: true},
  {
    display: 'Houston Hub DASPP',
    value: ErcotMarketSources.ERCOTDAHoustonHub,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 24,
    groupName: 'Hub Day-Ahead SPP'
  },
  // {display: 'Houston Hub DASPP', value: ErcotMarketSources.DAHoustonHub, hexColor: '#439539', selected: false, zIndex: 24, groupName: 'Hub Day-Ahead SPP'},
  {
    display: 'North Hub DASPP',
    value: ErcotMarketSources.ERCOTDANorthHub,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 25,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Panhandle Hub DASPP',
    value: ErcotMarketSources.ERCOTDAPanHandleHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 26,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'South Hub DASPP',
    value: ErcotMarketSources.ERCOTDASouthHub,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 27,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'West Hub DASPP',
    value: ErcotMarketSources.ERCOTDAWestHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 28,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Houston Zone DASPP',
    value: ErcotMarketSources.ERCOTDAHoustonZone,
    hexColor: '#5cf775',
    selected: false,
    zIndex: 29,
    groupName: 'Zone Day-Ahead SPP',
    groupStart: true
  },
  {
    display: 'North Zone DASPP',
    value: ErcotMarketSources.ERCOTDANorthZone,
    hexColor: '#f35cf7',
    selected: false,
    zIndex: 30,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'South Zone DASPP',
    value: ErcotMarketSources.ERCOTDASouthZone,
    hexColor: '#8a608b',
    selected: false,
    zIndex: 31,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'West Zone DASPP',
    value: ErcotMarketSources.ERCOTDAWestZone,
    hexColor: '#5b61f4',
    selected: false,
    zIndex: 32,
    groupName: 'Zone Day-Ahead SPP'
  }
  // {display: 'dummy Zone DASPP', value: ErcotMarketSources.DAWestZone, hexColor: '#00AEEF', selected: false, zIndex: 32, groupName: 'Zne Day-Ahead SPP'},
];

export const IUnfoErcotComparisons: IUMarketComparison[] = [
  // {display: 'ERCOT Actual Load', value: ErcotMarketSources.ActualLoad, hexColor: '#00AEEF', selected: true, zIndex: 11, groupName: 'ERCOT Load', groupStart: true},
  {
    display: 'Average Hub RTSPP',
    value: ErcotMarketSources.ERCOTHubAverage,
    hexColor: '#424242',
    selected: true,
    zIndex: 12,
    groupName: 'Hub Real-Time SPP',
    groupStart: true,
    selectedCount: 0
  },
  {
    display: 'Houston Hub RTSPP',
    value: ErcotMarketSources.ERCOTHoustonHub,
    hexColor: '#439539',
    selected: false,
    zIndex: 13,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'North Hub RTSPP',
    value: ErcotMarketSources.ERCOTNorthHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Panhandle Hub RTSPP',
    value: ErcotMarketSources.ERCOTPanHandleHub,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 18,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'South Hub RTSPP',
    value: ErcotMarketSources.ERCOTSouthHub,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 15,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'West Hub RTSPP',
    value: ErcotMarketSources.ERCOTWestHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Houston Zone RTSPP',
    value: ErcotMarketSources.ERCOTHoustonZone,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 19,
    groupName: 'Zone Real-Time SPP',
    groupStart: true,
    selectedCount: 0
  },
  {
    display: 'North Zone RTSPP',
    value: ErcotMarketSources.ERCOTNorthZone,
    hexColor: '#f45b5b',
    selected: false,
    zIndex: 20,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'South Zone RTSPP',
    value: ErcotMarketSources.ERCOTSouthZone,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 21,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'West Zone RTSPP',
    value: ErcotMarketSources.ERCOTWestZone,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 22,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'Average Hub DASPP',
    value: ErcotMarketSources.ERCOTDAHubAverage,
    hexColor: '#f45b5b',
    selected: true,
    zIndex: 23,
    groupName: 'Hub Day-Ahead SPP',
    groupStart: true,
    selectedCount: 0
  },
  // {display: 'Hub Average DASPP', value: ErcotMarketSources.DAHubAverage, hexColor: '#424242', selected: true, zIndex: 23, groupName: 'Hub Day-Ahead SPP', groupStart: true},
  {
    display: 'Houston Hub DASPP',
    value: ErcotMarketSources.ERCOTDAHoustonHub,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 24,
    groupName: 'Hub Day-Ahead SPP'
  },
  // {display: 'Houston Hub DASPP', value: ErcotMarketSources.DAHoustonHub, hexColor: '#439539', selected: false, zIndex: 24, groupName: 'Hub Day-Ahead SPP'},
  {
    display: 'North Hub DASPP',
    value: ErcotMarketSources.ERCOTDANorthHub,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 25,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Panhandle Hub DASPP',
    value: ErcotMarketSources.ERCOTDAPanHandleHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 26,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'South Hub DASPP',
    value: ErcotMarketSources.ERCOTDASouthHub,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 27,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'West Hub DASPP',
    value: ErcotMarketSources.ERCOTDAWestHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 28,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Houston Zone DASPP',
    value: ErcotMarketSources.ERCOTDAHoustonZone,
    hexColor: '#5cf775',
    selected: false,
    zIndex: 29,
    groupName: 'Zone Day-Ahead SPP',
    groupStart: true,
    selectedCount: 0
  },
  {
    display: 'North Zone DASPP',
    value: ErcotMarketSources.ERCOTDANorthZone,
    hexColor: '#f35cf7',
    selected: false,
    zIndex: 30,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'South Zone DASPP',
    value: ErcotMarketSources.ERCOTDASouthZone,
    hexColor: '#8a608b',
    selected: false,
    zIndex: 31,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'West Zone DASPP',
    value: ErcotMarketSources.ERCOTDAWestZone,
    hexColor: '#5b61f4',
    selected: false,
    zIndex: 32,
    groupName: 'Zone Day-Ahead SPP'
  }
  // {display: 'dummy Zone DASPP', value: ErcotMarketSources.DAWestZone, hexColor: '#00AEEF', selected: false, zIndex: 32, groupName: 'Zne Day-Ahead SPP'},
];

export const IUnfoPjmComparisons: IUMarketComparison[] = [
  // {display: 'ERCOT Actual Load', value: ErcotMarketSources.ActualLoad, hexColor: '#00AEEF', selected: true, zIndex: 11, groupName: 'ERCOT Load', groupStart: true},
  {
    display: 'Average Hub RTSPP',
    value: ErcotMarketSources.HubAverage,
    hexColor: '#424242',
    selected: false,
    zIndex: 12,
    groupName: 'Hub Real-Time SPP',
    groupStart: true,
    selectedCount: 0
  },
  {
    display: 'Houston Hub RTSPP',
    value: ErcotMarketSources.HoustonHub,
    hexColor: '#439539',
    selected: false,
    zIndex: 13,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'North Hub RTSPP',
    value: ErcotMarketSources.NorthHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 14,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Panhandle Hub RTSPP',
    value: ErcotMarketSources.PanHandleHub,
    hexColor: '#8085e9',
    selected: false,
    zIndex: 18,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'South Hub RTSPP',
    value: ErcotMarketSources.SouthHub,
    hexColor: '#f15c80',
    selected: false,
    zIndex: 15,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'West Hub RTSPP',
    value: ErcotMarketSources.WestHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 16,
    groupName: 'Hub Real-Time SPP'
  },
  {
    display: 'Houston Zone RTSPP',
    value: ErcotMarketSources.HoustonZone,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 19,
    groupName: 'Zone Real-Time SPP',
    groupStart: true,
    selectedCount: 0
  },
  {
    display: 'North Zone RTSPP',
    value: ErcotMarketSources.NorthZone,
    hexColor: '#f45b5b',
    selected: false,
    zIndex: 20,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'South Zone RTSPP',
    value: ErcotMarketSources.SouthZone,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 21,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'West Zone RTSPP',
    value: ErcotMarketSources.WestZone,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 22,
    groupName: 'Zone Real-Time SPP'
  },
  {
    display: 'Average Hub DASPP',
    value: ErcotMarketSources.DAHubAverage,
    hexColor: '#f45b5b',
    selected: true,
    zIndex: 23,
    groupName: 'Hub Day-Ahead SPP',
    groupStart: true,
    selectedCount: 0
  },
  // {display: 'Hub Average DASPP', value: ErcotMarketSources.DAHubAverage, hexColor: '#424242', selected: true, zIndex: 23, groupName: 'Hub Day-Ahead SPP', groupStart: true},
  {
    display: 'Houston Hub DASPP',
    value: ErcotMarketSources.DAHoustonHub,
    hexColor: '#00AEEF',
    selected: false,
    zIndex: 24,
    groupName: 'Hub Day-Ahead SPP'
  },
  // {display: 'Houston Hub DASPP', value: ErcotMarketSources.DAHoustonHub, hexColor: '#439539', selected: false, zIndex: 24, groupName: 'Hub Day-Ahead SPP'},
  {
    display: 'North Hub DASPP',
    value: ErcotMarketSources.DANorthHub,
    hexColor: '#91e8e1',
    selected: false,
    zIndex: 25,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Panhandle Hub DASPP',
    value: ErcotMarketSources.DAPanHandleHub,
    hexColor: '#e4d354',
    selected: false,
    zIndex: 26,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'South Hub DASPP',
    value: ErcotMarketSources.DASouthHub,
    hexColor: '#2b908f',
    selected: false,
    zIndex: 27,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'West Hub DASPP',
    value: ErcotMarketSources.DAWestHub,
    hexColor: '#f7a35c',
    selected: false,
    zIndex: 28,
    groupName: 'Hub Day-Ahead SPP'
  },
  {
    display: 'Houston Zone DASPP',
    value: ErcotMarketSources.DAHoustonZone,
    hexColor: '#5cf775',
    selected: false,
    zIndex: 29,
    groupName: 'Zone Day-Ahead SPP',
    groupStart: true,
    selectedCount: 0
  },
  {
    display: 'North Zone DASPP',
    value: ErcotMarketSources.DANorthZone,
    hexColor: '#f35cf7',
    selected: false,
    zIndex: 30,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'South Zone DASPP',
    value: ErcotMarketSources.DASouthZone,
    hexColor: '#8a608b',
    selected: false,
    zIndex: 31,
    groupName: 'Zone Day-Ahead SPP'
  },
  {
    display: 'West Zone DASPP',
    value: ErcotMarketSources.DAWestZone,
    hexColor: '#5b61f4',
    selected: false,
    zIndex: 32,
    groupName: 'Zone Day-Ahead SPP'
  }
  // {display: 'dummy Zone DASPP', value: ErcotMarketSources.DAWestZone, hexColor: '#00AEEF', selected: false, zIndex: 32, groupName: 'Zne Day-Ahead SPP'},
];

export const IUnfoMarkets: IUMarkets[] = [
  {
    marketName: 'ERCOT',
    counter: 0,
    comparisons: IUnfoErcotComparisons,
    expanded: true,
    expandedColor: false
  }
];

// #aeb733

export const ErcotInfoProperties: IMarketProperties = {
  market: 'ERCOT',
  name: ErcotMarketSection.Info,
  chartTitle: 'Market Information',
  offsets: InfoOffsets,
  futureOffsets: ErcotInfoFutureOffsets,
  comparisons: ErcotInfoComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl, // getSPPMarketDataUrl,
  defaultOffset: InfoOffsets[0],
  defaultFutureOffset: ErcotInfoFutureOffsets[0]
};

export const ErcotIUnfoProperties: IUMarketProperties = {
  name: ErcotMarketSection.Info,
  chartTitle: 'Market Information',
  offsets: InfoOffsets,
  futureOffsets: InfoFutureOffsets,
  market: IUnfoMarkets,
  //comparisons: IUnfoComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl,
  defaultOffset: InfoOffsets[0]
};

export const HenryHubOffsets: IOffset[] = [
  { display: '1 MO', value: '-30' },
  { display: '3 MO', value: '-90' },
  { display: '6 MO', value: '-180' },
  { display: '1 Y', value: '-365' },
  { display: '2 Y', value: '-730' },
  { display: '3 Y', value: '-1095' }
];

export const HenryHubFutureOffsets: IOffset[] = [];

export const HenryHubComparisons: IMarketComparison[] = [
  {
    display: '',
    value: ErcotMarketSources.HenryHubMonthly,
    hexColor: '#439539',
    selected: true,
    zIndex: 12
  },
  {
    display: 'Henry Hub (NYMEX)',
    value: ErcotMarketSources.HenryHubDaily,
    hexColor: '#439539',
    selected: true,
    zIndex: 12
  }
];

export const HenryHubProperties: IMarketProperties = {
  market: '',
  name: ErcotNewMarketSection.HenryHub,
  chartTitle: 'Henry Hub (NYMEX)',
  offsets: HenryHubOffsets,
  futureOffsets: HenryHubFutureOffsets,
  comparisons: HenryHubComparisons,
  dataUrl: environment.getHenryHubDataUrl,
  defaultOffset: HenryHubOffsets[4]
};

export const HoustonShipChannelOffsets: IOffset[] = [
  { display: '1 MO', value: '-30' },
  { display: '3 MO', value: '-90' },
  { display: '6 MO', value: '-180' },
  { display: '1 Y', value: '-365' },
  { display: '2 Y', value: '-730' },
  { display: '3 Y', value: '-1095' }
];

export const HoustonShipChannelFutureOffsets: IOffset[] = [];

export const HoustonShipChannelComparisons: IMarketComparison[] = [
  {
    display: '',
    value: ErcotMarketSources.HSCMonthly,
    hexColor: '#439539',
    selected: true,
    zIndex: 11
  },
  {
    display: 'Houston Ship Channel',
    value: ErcotMarketSources.HSCDaily,
    hexColor: '#439539',
    selected: true,
    zIndex: 11
  }
];

export const HoustonShipChannelProperties: IMarketProperties = {
  market: '',
  name: ErcotNewMarketSection.HoustonShipChannel,
  chartTitle: 'Houston Ship Channel',
  offsets: HoustonShipChannelOffsets,
  futureOffsets: HoustonShipChannelFutureOffsets,
  comparisons: HoustonShipChannelComparisons,
  dataUrl: environment.getHoustonShipChannelDataUrl,
  defaultOffset: HoustonShipChannelOffsets[4]
};

export const MarketIndicators: IMarketIndicator[] = [
  { display: 'Peak', value: 'PEAK' },
  { display: 'Moving Average', value: 'EMA' },
  { display: 'Moving Average Convergence Divergence (MACD)', value: 'MACD' }
];

export const ErcotMarketStatus = {
  normal: {
    text: 'normal conditions',
    background: '#299C00'
  },
  conservation: {
    text: 'conservation requested',
    background: '#F1C40F'
  },
  eea1: {
    text: 'level 1 emergency',
    background: '#E67E23'
  },
  eea2: {
    text: 'level 2 emergency',
    background: '#E74C3C'
  },
  eea3: {
    text: 'level 3 emergency',
    background: '#000000'
  }
};

export const PjmMarketStatus = {
  normal: {
    text: 'normal conditions',
    background: '#299C00'
  },
  action: {
    text: 'action',
    background: '#993366'
  },
  advisory: {
    text: 'advisory',
    background: '#666666'
  },
  alert: {
    text: 'alert',
    background: '#3399CC'
  },
  warning: {
    text: 'warning',
    background: '#ECEC01'
  }
};

export const ERCOTDemandComparisonOptions: MarketDemandComparison[] = [
  {
    text: 'net load',
    source: ErcotMarketSources.ERCOTNetLoad,
    checked: true,
    expanded: false,
    items: []
  },
  {
    text: 'forecasted rt load',
    source: ErcotMarketSources.ERCOTForecastLoad,
    checked: true,
    expanded: false,
    items: []
  },
  {
    text: 'forecasted peak load',
    source: ErcotMarketSources.ERCOTForecastedPeakLoad,
    checked: true,
    expanded: false,
    items: []
  },
  {
    text: 'outages',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'outages - overall',
        source: ErcotMarketSources.ERCOTOutagesOverall,
        checked: true
      }
    ]
  },
  {
    text: 'renewable generation',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'combined renewable generation',
        source: ErcotMarketSources.ERCOTCombinedRenewables,
        checked: true
      },
      {
        text: 'wind generation',
        source: ErcotMarketSources.ERCOTWindGen,
        checked: false
      },
      {
        text: 'solar generation',
        source: ErcotMarketSources.ERCOTSolarGen,
        checked: false
      },
      {
        text: 'hydro generation',
        source: ErcotMarketSources.ERCOTHydroGen,
        checked: false
      }
    ]
  },
  {
    text: 'price adders',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'price adders - overall',
        source: ErcotMarketSources.ERCOTPriceAdders,
        checked: true
      },
      {
        text: 'online reserve price adder',
        source: ErcotMarketSources.ERCOTOnlineReserveAdder,
        checked: false
      },
      {
        text: 'online reliability deployment price adder',
        source: ErcotMarketSources.ERCOTOnlineReliabilityAdder,
        checked: false
      }
    ]
  },
  {
    text: 'a/s commitments - overall',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'a/s commitments - overall',
        source: ErcotMarketSources.ERCOTASOverall,
        checked: true
      },
      {
        text: 'non-spin',
        source: ErcotMarketSources.ERCOTNonSpin,
        checked: false
      },
      {
        text: 'ecrs',
        source: ErcotMarketSources.ERCOTECRS,
        checked: false
      },
      {
        text: 'regulation down',
        source: ErcotMarketSources.ERCOTRegDown,
        checked: false
      },
      {
        text: 'regulation up',
        source: ErcotMarketSources.ERCOTRegUp,
        checked: false
      },
      {
        text: 'rrs',
        source: ErcotMarketSources.ERCOTRRS,
        checked: false
      }
    ]
  },
  {
    text: 'net imports/exports',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'net imports/exports',
        source: ErcotMarketSources.ERCOTNetImportExport,
        checked: true
      },
      {
        text: 'imports',
        source: ErcotMarketSources.ERCOTImport,
        checked: false
      },
      {
        text: 'exports',
        source: ErcotMarketSources.ERCOTExport,
        checked: false
      }
    ]
  }
];

export const ERCOTSnapshotComparisonOptions: MarketGraphComparison[] = [
  {
    text: 'REAL TIME LAYERS',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'RT Load',
        selected: true,
        source: ErcotMarketSources.ERCOTActualLoad,
        hexColor: '#00AEEF'
      },
      {
        text: 'System Capacity',
        selected: false,
        source: ErcotMarketSources.ERCOTRTSystemCapacity,
        hexColor: '#6cd2de'
      }
    ]
  },
  {
    text: 'FORECASTED LAYERS',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'Load Forecast',
        selected: true,
        source: ErcotMarketSources.ERCOTForecastLoad,
        hexColor: '#424242'
      },
      {
        text: 'System Capacity Forecast',
        selected: false,
        source: ErcotMarketSources.ERCOTForecastedSystemCapacity,
        hexColor: '#001e93'
      }
    ]
  },
  {
    parent: 'PRICES',
    text: 'RT HUB & ZONE PRICES',
    count: 1,
    expanded: false,
    items: [
      {
        parent: 'PRICES',
        text: 'RT BUS AVG HUB',
        selected: true,
        source: ErcotMarketSources.ERCOTHubAverageLMP,
        hexColor: '#439539'
      },
      {
        parent: 'PRICES',
        text: 'RT HOUSTON HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTHoustonHubLMP,
        hexColor: '#f7a35c'
      },
      {
        parent: 'PRICES',
        text: 'RT NORTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTNorthHubLMP,
        hexColor: '#8085e9'
      },
      {
        parent: 'PRICES',
        text: 'RT PAN HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTPanHandleHubLMP,
        hexColor: '#f15c80'
      },
      {
        parent: 'PRICES',
        text: 'RT SOUTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTSouthHubLMP,
        hexColor: '#e4d354'
      },
      {
        parent: 'PRICES',
        text: 'RT WEST HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTWestHubLMP,
        hexColor: '#2b908f'
      },
      {
        parent: 'PRICES',
        text: 'RT AEN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTAenZoneLMP,
        hexColor: '#556dcb'
      },
      {
        parent: 'PRICES',
        text: 'RT CPS ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTCpsZoneLMP,
        hexColor: '#d0559c'
      },
      {
        parent: 'PRICES',
        text: 'RT HOUSTON ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTHoustonZoneLMP,
        hexColor: '#5cf775'
      },
      {
        parent: 'PRICES',
        text: 'RT LCRA ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTLcraZoneLMP,
        hexColor: '#008860'
      },
      {
        parent: 'PRICES',
        text: 'RT NORTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTNorthZoneLMP,
        hexColor: '#f45b5b'
      },
      {
        parent: 'PRICES',
        text: 'RT RAYBN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTRaybnZoneLMP,
        hexColor: '#55b095'
      },
      {
        parent: 'PRICES',
        text: 'RT SOUTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTSouthZoneLMP,
        hexColor: '#91e8e1'
      },
      {
        parent: 'PRICES',
        text: 'RT WEST ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTWestZoneLMP,
        hexColor: '#5b61f4'
      }
    ]
  },
  {
    parent: 'PRICES',
    text: 'DA HUB & ZONE PRICES',
    count: 0,
    expanded: false,
    items: [
      {
        parent: 'PRICES',
        text: 'DA BUS AVG HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAHubAverage,
        hexColor: '#439539'
      },
      {
        parent: 'PRICES',
        text: 'DA HOUSTON HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAHoustonHub,
        hexColor: '#f7a35c'
      },
      {
        parent: 'PRICES',
        text: 'DA NORTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDANorthHub,
        hexColor: '#8085e9'
      },
      {
        parent: 'PRICES',
        text: 'DA PAN HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAPanHandleHub,
        hexColor: '#f15c80'
      },
      {
        parent: 'PRICES',
        text: 'DA SOUTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDASouthHub,
        hexColor: '#e4d354'
      },
      {
        parent: 'PRICES',
        text: 'DA WEST HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAWestHub,
        hexColor: '#2b908f'
      },
      {
        parent: 'PRICES',
        text: 'DA AEN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaAenZone,
        hexColor: '#556dcb'
      },
      {
        parent: 'PRICES',
        text: 'DA CPS ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaCpsZone,
        hexColor: '#d0559c'
      },
      {
        parent: 'PRICES',
        text: 'DA HOUSTON ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDAHoustonZone,
        hexColor: '#5cf775'
      },
      {
        parent: 'PRICES',
        text: 'DA LCRA ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaLcraZone,
        hexColor: '#008860'
      },
      {
        parent: 'PRICES',
        text: 'DA NORTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDANorthZone,
        hexColor: '#f45b5b'
      },
      {
        parent: 'PRICES',
        text: 'DA RAYBN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaRaybnZone,
        hexColor: '#55b095'
      },
      {
        parent: 'PRICES',
        text: 'DA SOUTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDASouthZone,
        hexColor: '#91e8e1'
      },
      {
        parent: 'PRICES',
        text: 'DA WEST ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDAWestZone,
        hexColor: '#5b61f4'
      }
    ]
  },
  {
    text: 'GENERATION MIX',
    count: 3,
    expanded: false,
    items: [
      {
        parent: 'GENERATION MIX',
        text: 'Solar',
        selected: true,
        source: ErcotMarketSources.ERCOTSolar,
        hexColor: '#F3934F'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Wind',
        selected: true,
        source: ErcotMarketSources.ERCOTWind,
        hexColor: '#7FCFFF'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Hydro',
        selected: false,
        source: ErcotMarketSources.ERCOTHydro,
        hexColor: '#22BCCE'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Power Storage',
        selected: false,
        source: ErcotMarketSources.ERCOTPowerStorage,
        hexColor: '#B8006B'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Other',
        selected: false,
        source: ErcotMarketSources.ERCOTOther,
        hexColor: '#E1E2E5'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Natural Gas',
        selected: false,
        source: ErcotMarketSources.ERCOTNaturalGas,
        hexColor: '#6835AD'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Coal and Lignite',
        selected: false,
        source: ErcotMarketSources.ERCOTCoalAndLignite,
        hexColor: '#75777A'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Nuclear',
        selected: false,
        source: ErcotMarketSources.ERCOTNuclear,
        hexColor: '#EDC200'
      }
    ]
  }
];

export const ERCOTInfoComparisonOptions: MarketGraphComparison[] = [
  {
    text: 'REAL TIME LAYERS',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'RT Load',
        selected: true,
        source: ErcotMarketSources.ERCOTActualLoad,
        hexColor: '#00AEEF'
      }
      // ,
      // { // removed because of past data unavailability
      //   text: 'System Capacity',
      //   selected: false,
      //   source: ErcotMarketSources.ERCOTRTSystemCapacity,
      //   hexColor: '#6cd2de'
      // }
    ]
  },
  {
    text: 'RT HUB & ZONE PRICES',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'RT BUS AVG HUB',
        selected: true,
        source: ErcotMarketSources.ERCOTHubAverage,
        hexColor: '#439539'
      },
      {
        text: 'RT HOUSTON HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTHoustonHub,
        hexColor: '#f7a35c'
      },
      {
        text: 'RT NORTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTNorthHub,
        hexColor: '#8085e9'
      },
      {
        text: 'RT PAN HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTPanHandleHub,
        hexColor: '#f15c80'
      },
      {
        text: 'RT SOUTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTSouthHub,
        hexColor: '#e4d354'
      },
      {
        text: 'RT WEST HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTWestHub,
        hexColor: '#2b908f'
      },
      {
        text: 'RT AEN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTAenZone,
        hexColor: '#556dcb'
      },
      {
        text: 'RT CPS ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTCpsZone,
        hexColor: '#d0559c'
      },
      {
        text: 'RT HOUSTON ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTHoustonZone,
        hexColor: '#5cf775'
      },
      {
        text: 'RT LCRA ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTLcraZone,
        hexColor: '#008860'
      },
      {
        text: 'RT NORTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTNorthZone,
        hexColor: '#f45b5b'
      },
      {
        text: 'RT RAYBN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTRaybnZone,
        hexColor: '#55b095'
      },
      {
        text: 'RT SOUTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTSouthZone,
        hexColor: '#91e8e1'
      },
      {
        text: 'RT WEST ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTWestZone,
        hexColor: '#5b61f4'
      }
    ]
  },
  {
    text: 'DA HUB & ZONE PRICES',
    count: 0,
    expanded: false,
    items: [
      {
        text: 'DA BUS AVG HUB',
        selected: true,
        source: ErcotMarketSources.ERCOTDAHubAverage,
        hexColor: '#439539'
      },
      {
        text: 'DA HOUSTON HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAHoustonHub,
        hexColor: '#f7a35c'
      },
      {
        text: 'DA NORTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDANorthHub,
        hexColor: '#8085e9'
      },
      {
        text: 'DA PAN HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAPanHandleHub,
        hexColor: '#f15c80'
      },
      {
        text: 'DA SOUTH HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDASouthHub,
        hexColor: '#e4d354'
      },
      {
        text: 'DA WEST HUB',
        selected: false,
        source: ErcotMarketSources.ERCOTDAWestHub,
        hexColor: '#2b908f'
      },
      {
        text: 'DA AEN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaAenZone,
        hexColor: '#556dcb'
      },
      {
        text: 'DA CPS ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaCpsZone,
        hexColor: '#d0559c'
      },
      {
        text: 'DA HOUSTON ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDAHoustonZone,
        hexColor: '#5cf775'
      },
      {
        text: 'DA LCRA ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaLcraZone,
        hexColor: '#008860'
      },
      {
        text: 'DA NORTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDANorthZone,
        hexColor: '#f45b5b'
      },
      {
        text: 'DA RAYBN ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDaRaybnZone,
        hexColor: '#55b095'
      },
      {
        text: 'DA SOUTH ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDASouthZone,
        hexColor: '#91e8e1'
      },
      {
        text: 'DA WEST ZONE',
        selected: false,
        source: ErcotMarketSources.ERCOTDAWestZone,
        hexColor: '#5b61f4'
      }
    ]
  }
];

export const ERCOTGenerationMixPieSources: ComparisonOptionItem[] = [
  {
    text: 'Solar',
    selected: true,
    source: ErcotMarketSources.ERCOTSolar,
    hexColor: '#F3934F'
  },
  {
    text: 'Wind',
    selected: true,
    source: ErcotMarketSources.ERCOTWind,
    hexColor: '#7FCFFF'
  },
  {
    text: 'Hydro',
    selected: false,
    source: ErcotMarketSources.ERCOTHydro,
    hexColor: '#22BCCE'
  },
  {
    text: 'Power Storage',
    selected: false,
    source: ErcotMarketSources.ERCOTPowerStorage,
    hexColor: '#B8006B'
  },
  {
    text: 'Other',
    selected: false,
    source: ErcotMarketSources.ERCOTOther,
    hexColor: '#E1E2E5'
  },
  {
    text: 'Natural Gas',
    selected: false,
    source: ErcotMarketSources.ERCOTNaturalGas,
    hexColor: '#6835AD'
  },
  {
    text: 'Coal and Lignite',
    selected: false,
    source: ErcotMarketSources.ERCOTCoalAndLignite,
    hexColor: '#75777A'
  },
  {
    text: 'Nuclear',
    selected: false,
    source: ErcotMarketSources.ERCOTNuclear,
    hexColor: '#EDC200'
  }
];

export const ErcotGenerationMixProperties: IMarketProperties = {
  market: 'ERCOT',
  name: ErcotMarketSection.GenerationMix,
  chartTitle: 'Generation Mix',
  offsets: SnapshotOffsets,
  futureOffsets: SnapshotFutureOffsets,
  comparisons: ErcotSnapshotComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl, // getGenerationMixDataUrl, //getMarketDashboardDataUrl
  defaultOffset: SnapshotOffsets[1],
  defaultFutureOffset: SnapshotFutureOffsets[4]
};

export const ERCOTGenerationMixSources = [
  'Solar',
  'Wind',
  'Hydro',
  'Power Storage',
  'Other',
  'Natural Gas',
  'Coal and Lignite',
  'Nuclear'
];

export const ERCOTGenerationMixSourceMap = new Map([
  ['solar', 'Solar'],
  ['wind', 'Wind'],
  ['hydro', 'Hydro'],
  ['powerstorage', 'Power Storage'],
  ['other', 'Other'],
  ['naturalgas', 'Natural Gas'],
  ['coalandlignite', 'Coal and Lignite'],
  ['nuclear', 'Nuclear']
]);

export const ERCOTGenerationMixPieColors = new Map([
  ['solar', '#F3934F'],
  ['wind', '#7FCFFF'],
  ['hydro', '#22BCCE'],
  ['powerstorage', '#B8006B'],
  ['other', '#E1E2E5'],
  ['naturalgas', '#6835AD'],
  ['coalandlignite', '#75777A'],
  ['nuclear', '#EDC200']
]);

export const ErcotRTMarketPricesSettings = [
  ErcotMarketSources.ERCOTHubAverage,
  ErcotMarketSources.ERCOTHoustonHub,
  ErcotMarketSources.ERCOTHoustonZone,
  ErcotMarketSources.ERCOTNorthHub,
  ErcotMarketSources.ERCOTNorthZone,
  ErcotMarketSources.ERCOTPanHandleHub,
  ErcotMarketSources.ERCOTSouthHub,
  ErcotMarketSources.ERCOTSouthZone,
  ErcotMarketSources.ERCOTWestHub,
  ErcotMarketSources.ERCOTWestZone
];

export const ERCOTHbLzList = [
  'Hub Average',
  'Houston',
  'North',
  'Panhandle',
  'South',
  'West'
];

export const ErcotGridTitles = new Map([
  [ErcotMarketSources.ERCOTActualLoad, 'RT Load'],
  [ErcotMarketSources.ERCOTReserves, 'Reserves'],
  [ErcotMarketSources.ActualLoad, 'RT Load']
  // [ErcotMarketSources.Reserves, 'Reserves'],
]);

export const DefaultViewName = 'Default View';

export const PJMMarketSection = {
  Snapshot: 'SNAPSHOT',
  Info: 'INFO',
  HenryHub: 'HENRY_HUB',
  HoustonShipChannel: 'HOUSTON_SHIP_CHANNEL',
  GenerationMix: 'GENERATION_MIX',
  RTMarketDemand: 'PJM_RT_MARKET_DEMAND',
  RTMarketPrice: 'PJM_RT_MARKET_PRICE'
};

export const PjmSnapshotProperties: IMarketProperties = {
  market: 'PJM',
  name: PJMMarketSection.Snapshot,
  chartTitle: 'Market Snapshot',
  offsets: SnapshotOffsets,
  futureOffsets: SnapshotFutureOffsets,
  comparisons: ErcotSnapshotComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl, // getMarketDataUrl, //getMarketDashboardDataUrl
  defaultOffset: SnapshotOffsets[1],
  defaultFutureOffset: SnapshotFutureOffsets[4]
};

export const PjmInfoProperties: IMarketProperties = {
  market: 'PJM',
  name: PJMMarketSection.Info,
  chartTitle: 'Market Information',
  offsets: InfoOffsets,
  futureOffsets: ErcotInfoFutureOffsets,
  comparisons: ErcotInfoComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl, // getSPPMarketDataUrl,
  defaultOffset: InfoOffsets[0],
  defaultFutureOffset: ErcotInfoFutureOffsets[0]
};

export const PjmGenerationMixProperties: IMarketProperties = {
  market: 'PJM',
  name: PJMMarketSection.GenerationMix,
  chartTitle: 'Generation Mix',
  offsets: SnapshotOffsets,
  futureOffsets: SnapshotFutureOffsets,
  comparisons: ErcotSnapshotComparisons,
  dataUrl: environment.getSPPMarketDashboardDataUrl, // getGenerationMixDataUrl, //getMarketDashboardDataUrl
  defaultOffset: SnapshotOffsets[1],
  defaultFutureOffset: SnapshotFutureOffsets[4]
};

export const Market = {
  ERCOT: 'ERCOT',
  PJM: 'PJM'
};

export const PjmMarketSources = {
  ActualLoad: 'ACTUAL_LOAD',
  PjmActualLoad: 'PJM_ACTUAL_LOAD',
  PjmActualLoadHrly: 'PJM_ACTUAL_LOAD_HRLY',
  PjmRTSystemCapacity: 'PJM_RT_SYSTEM_CAPACITY',
  PjmForecastLoad: 'PJM_FORECASTED_LOAD',
  PjmForecastedSystemCapacity: 'PJM_FORECASTED_SYSTEM_CAPACITY',
  PjmReserves: 'PJM_RESERVES',
  PJMNetLoad: 'PJM_NET_LOAD',
  PJMForecastedLoad: 'PJM_FORECASTED_LOAD',
  PJMForecastedPeakLoad: 'PJM_FORECASTED_PEAK_LOAD',
  PJMOutagesOverall: 'PJM_OUTAGES_OVERALL',
  PJMPlannedOutages: 'PJM_PLANNED_OUTAGES',
  PJMOutagesUnplanned: 'PJM_OUTAGES_UNPLANNED',
  PJMMntncOutages: 'PJM_MNTNC_OUTAGES',
  PJMForcedOutages: 'PJM_FORCED_OUTAGES',
  PJMTotalOutages: 'PJM_TOTAL_OUTAGES',
  PJMCombinedRenewables: 'PJM_COMBINED_RENEWABLES',
  PJMWindGen: 'PJM_WIND_GEN',
  PJMSolarGen: 'PJM_SOLAR_GEN',
  PJMHydroGen: 'PJM_HYDRO_GEN',
  PJMOtherRenewablesGen: 'PJM_OTHER_RENEWABLES_GEN',
  PJMPriceAdders: 'PJM_PRICE_ADDERS',
  PJMOnlineReserveAdder: 'PJM_ONLINE_RESERVE_ADDER',
  PJMOnlineReliabilityAdder: 'PJM_ONLINE_RELIABILITY_ADDER',
  PJMASOverall: 'PJM_AS_OVERALL',
  PJMECRS: 'PJM_ECRS',
  PJMRRS: 'PJM_RRS',
  PJMNonSpin: 'PJM_NON_SPIN',
  PJMRegUp: 'PJM_REG_UP',
  PJMRegDown: 'PJM_REG_DOWN',
  PJMNetImportExport: 'PJM_NET_IMPORT_EXPORT',
  PJMImport: 'PJM_IMPORT',
  PJMExport: 'PJM_EXPORT',
  PJMSolar: 'PJM_SOLAR',
  PJMWind: 'PJM_WIND',
  PJMNuclear: 'PJM_NUCLEAR',
  PJMOtherRenewables: 'PJM_OTHER_RENEWABLES',
  PJMHydro: 'PJM_HYDRO',
  PJMGas: 'PJM_GAS',
  PJMStorage: 'PJM_STORAGE',
  PJMCoal: 'PJM_COAL',
  PJMOil: 'PJM_OIL',
  PJMMultipleFuels: 'PJM_MULTIPLE_FUELS',
  PJMAECOZone: 'PJM_LZ_AECO',
  PJMAEPZone: 'PJM_LZ_AEP',
  PJMAPSZone: 'PJM_LZ_APS',
  PJMATSIZone: 'PJM_LZ_ATSI',
  PJMBGEZone: 'PJM_LZ_BGE',
  PJMCOMEDZone: 'PJM_LZ_COMED',
  PJMDAYZone: 'PJM_LZ_DAY',
  PJMDEOKZone: 'PJM_LZ_DEOK',
  PJMDOMZone: 'PJM_LZ_DOM',
  PJMDPLZone: 'PJM_LZ_DPL',
  PJMDUQZone: 'PJM_LZ_DUQ',
  PJMEKPCZone: 'PJM_LZ_EKPC',
  PJMJCPLZone: 'PJM_LZ_JCPL',
  PJMMETEDZone: 'PJM_LZ_METED',
  PJMOVECZone: 'PJM_LZ_OVEC',
  PJMPENELECZone: 'PJM_LZ_PENELEC',
  PJMPEPCOZone: 'PJM_LZ_PEPCO',
  PJMPJMRTOZone: 'PJM_LZ_PJM_RTO',
  PJMPLZone: 'PJM_LZ_PL',
  PJMPSEGZone: 'PJM_LZ_PSEG',
  PJMRECOZone: 'PJM_LZ_RECO',
  PJMPEZone: 'PJM_LZ_PE',
  PJMHUDSONTPInterface: 'PJM_INF_HUDSONTP',
  PJMIMOInterface: 'PJM_INF_IMO',
  PJMLINDENVFTInterface: 'PJM_INF_LINDENVFT',
  PJMMISOInterface: 'PJM_INF_MISO',
  PJMNeptuneInterface: 'PJM_INF_NEPTUNE',
  PJMNYISInterface: 'PJM_INF_NYIS',
  PJMSouthInterface: 'PJM_INF_SOUTH',
  PJMAEPGenHub: 'PJM_HB_AEP_GEN_HUB',
  PJMAEPDaytonHub: 'PJM_HB_AEP_DAYTON_HUB',
  PJMATSIGenHub: 'PJM_HB_ATSI_GEN_HUB',
  PJMChicagoGenHub: 'PJM_HB_CHICAGO_GEN_HUB',
  PJMChicagoHub: 'PJM_HB_CHICAGO_HUB',
  PJMDominionHub: 'PJM_HB_DOMINION_HUB',
  PJMEasternHub: 'PJM_HB_EASTERN_HUB',
  PJMNIllinoisHub: 'PJM_HB_N_ILLINOIS_HUB',
  PJMNewJerseyHub: 'PJM_HB_NEW_JERSEY_HUB',
  PJMOhioHub: 'PJM_HB_OHIO_HUB',
  PJMWestINTHub: 'PJM_HB_WEST_INT_HUB',
  PJMWesternHub: 'PJM_HB_WESTERN_HUB',
  PJMDAAECOZone: 'PJM_LZ_DA_AECO',
  PJMDAAEPZone: 'PJM_LZ_DA_AEP',
  PJMDAAPSZone: 'PJM_LZ_DA_APS',
  PJMDAATSIZone: 'PJM_LZ_DA_ATSI',
  PJMDABGEZone: 'PJM_LZ_DA_BGE',
  PJMDACOMEDZone: 'PJM_LZ_DA_COMED',
  PJMDADAYZone: 'PJM_LZ_DA_DAY',
  PJMDADEOKZone: 'PJM_LZ_DA_DEOK',
  PJMDADOMZone: 'PJM_LZ_DA_DOM',
  PJMDADPLZone: 'PJM_LZ_DA_DPL',
  PJMDADUQZone: 'PJM_LZ_DA_DUQ',
  PJMDAEKPCZone: 'PJM_LZ_DA_EKPC',
  PJMDAJCPLZone: 'PJM_LZ_DA_JCPL',
  PJMDAMETEDZone: 'PJM_LZ_DA_METED',
  PJMDAOVECZone: 'PJM_LZ_DA_OVEC',
  PJMDAPPLZone: 'PJM_LZ_DA_PPL',
  PJMDAPECOZone: 'PJM_LZ_DA_PECO',
  PJMDAPENELECZone: 'PJM_LZ_DA_PENELEC',
  PJMDAPEPCOZone: 'PJM_LZ_DA_PEPCO',
  PJMDAPJMRTOZone: 'PJM_LZ_DA_PJM_RTO',
  PJMDAPLZone: 'PJM_LZ_DA_PL',
  PJMDAPSEGZone: 'PJM_LZ_DA_PSEG',
  PJMDARECOZone: 'PJM_LZ_DA_RECO',
  PJMDAPEZone: 'PJM_LZ_DA_PE',
  PJMDAHUDSONTPInterface: 'PJM_INF_DA_HUDSONTP',
  PJMDAIMOInterface: 'PJM_INF_DA_IMO',
  PJMDALINDENVFTInterface: 'PJM_INF_DA_LINDENVFT',
  PJMDAMISOInterface: 'PJM_INF_DA_MISO',
  PJMDANeptuneInterface: 'PJM_INF_DA_NEPTUNE',
  PJMDANYISInterface: 'PJM_INF_DA_NYIS',
  PJMDASouthInterface: 'PJM_INF_DA_SOUTH',
  PJMDAAEPGenHub: 'PJM_HB_DA_AEP_GEN_HUB',
  PJMDAAEPDaytonHub: 'PJM_HB_DA_AEP_DAYTON_HUB',
  PJMDAATSIGenHub: 'PJM_HB_DA_ATSI_GEN_HUB',
  PJMDAChicagoGenHub: 'PJM_HB_DA_CHICAGO_GEN_HUB',
  PJMDAChicagoHub: 'PJM_HB_DA_CHICAGO_HUB',
  PJMDADominionHub: 'PJM_HB_DA_DOMINION_HUB',
  PJMDAEasternHub: 'PJM_HB_DA_EASTERN_HUB',
  PJMDANIllinoisHub: 'PJM_HB_DA_N_ILLINOIS_HUB',
  PJMDANewJerseyHub: 'PJM_HB_DA_NEW_JERSEY_HUB',
  PJMDAOhioHub: 'PJM_HB_DA_OHIO_HUB',
  PJMDAWestINTHub: 'PJM_HB_DA_WEST_INT_HUB',
  PJMDAWesternHub: 'PJM_HB_DA_WESTERN_HUB',
  PJMAEPGenHubLMP: 'PJM_HB_AEP_GEN_HUB_LMP',
  PJMAEPDaytonHubLMP: 'PJM_HB_AEP_DAYTON_HUB_LMP',
  PJMATSIGenHubLMP: 'PJM_HB_ATSI_GEN_HUB_LMP',
  PJMChicagoGenHubLMP: 'PJM_HB_CHICAGO_GEN_HUB_LMP',
  PJMChicagoHubLMP: 'PJM_HB_CHICAGO_HUB_LMP',
  PJMDominionHubLMP: 'PJM_HB_DOMINION_HUB_LMP',
  PJMEasternHubLMP: 'PJM_HB_EASTERN_HUB_LMP',
  PJMNIllinoisHubLMP: 'PJM_HB_N_ILLINOIS_HUB_LMP',
  PJMNewJerseyHubLMP: 'PJM_HB_NEW_JERSEY_HUB_LMP',
  PJMOhioHubLMP: 'PJM_HB_OHIO_HUB_LMP',
  PJMWestINTHubLMP: 'PJM_HB_WEST_INT_HUB_LMP',
  PJMWesternHubLMP: 'PJM_HB_WESTERN_HUB_LMP',
  PJMHUDSONTPInterfaceLMP: 'PJM_INF_HUDSONTP_LMP',
  PJMIMOInterfaceLMP: 'PJM_INF_IMO_LMP',
  PJMLINDENVFTInterfaceLMP: 'PJM_INF_LINDENVFT_LMP',
  PJMMISOInterfaceLMP: 'PJM_INF_MISO_LMP',
  PJMNeptuneInterfaceLMP: 'PJM_INF_NEPTUNE_LMP',
  PJMNYISInterfaceLMP: 'PJM_INF_NYIS_LMP',
  PJMSouthInterfaceLMP: 'PJM_INF_SOUTH_LMP',
  PJMAECOZoneLMP: 'PJM_LZ_AECO_LMP',
  PJMAEPZoneLMP: 'PJM_LZ_AEP_LMP',
  PJMAPSZoneLMP: 'PJM_LZ_APS_LMP',
  PJMATSIZoneLMP: 'PJM_LZ_ATSI_LMP',
  PJMBGEZoneLMP: 'PJM_LZ_BGE_LMP',
  PJMCOMEDZoneLMP: 'PJM_LZ_COMED_LMP',
  PJMDAYZoneLMP: 'PJM_LZ_DAY_LMP',
  PJMDEOKZoneLMP: 'PJM_LZ_DEOK_LMP',
  PJMDOMZoneLMP: 'PJM_LZ_DOM_LMP',
  PJMDPLZoneLMP: 'PJM_LZ_DPL_LMP',
  PJMDUQZoneLMP: 'PJM_LZ_DUQ_LMP',
  PJMEKPCZoneLMP: 'PJM_LZ_EKPC_LMP',
  PJMJCPLZoneLMP: 'PJM_LZ_JCPL_LMP',
  PJMMETEDZoneLMP: 'PJM_LZ_METED_LMP',
  PJMOVECZoneLMP: 'PJM_LZ_OVEC_LMP',
  PJMPENELECZoneLMP: 'PJM_LZ_PENELEC_LMP',
  PJMPEPCOZoneLMP: 'PJM_LZ_PEPCO_LMP',
  PJMPJMRTOZoneLMP: 'PJM_LZ_PJM_RTO_LMP',
  PJMPLZoneLMP: 'PJM_LZ_PL_LMP',
  PJMPSEGZoneLMP: 'PJM_LZ_PSEG_LMP',
  PJMRECOZoneLMP: 'PJM_LZ_RECO_LMP',
  PJMPEZoneLMP: 'PJM_LZ_PE_LMP'
};

export const PjmGridTitles = new Map([
  [PjmMarketSources.PjmActualLoad, 'RT Load'],
  [PjmMarketSources.PjmReserves, 'Reserves'],
  [PjmMarketSources.ActualLoad, 'RT Load']
]);

export const PjmNewMarketSection = {
  Snapshot: 'PJM_SNAPSHOT',
  Info: 'PJM_INFO',
  HenryHub: 'PJM_HENRY_HUB',
  HoustonShipChannel: 'PJM_HOUSTON_SHIP_CHANNEL',
  GenerationMix: 'PJM_GENERATION_MIX',
  RTMarketDemand: 'PJM_RT_MARKET_DEMAND'
};

export const PjmRTMarketDemandTitles = new Map([
  [PjmMarketSources.PJMNetLoad, 'NET LOAD'],
  [PjmMarketSources.PJMForecastedLoad, 'FORECASTED LOAD'],
  [PjmMarketSources.PJMForecastedPeakLoad, 'FORECASTED PEAK LOAD'],
  [PjmMarketSources.PJMOutagesOverall, 'OUTAGES - OVERALL'],
  [PjmMarketSources.PJMOutagesUnplanned, 'OUTAGES - UNPLANNED'],
  [PjmMarketSources.PJMPlannedOutages, 'OUTAGES - PLANNED'],
  [PjmMarketSources.PJMMntncOutages, 'OUTAGES - MNTNC'],
  [PjmMarketSources.PJMTotalOutages, 'OUTAGES - TOTAL'],
  [PjmMarketSources.PJMForcedOutages, 'OUTAGES - FORCED'],
  [PjmMarketSources.PJMCombinedRenewables, 'COMBINED RENEWABLES'],
  [PjmMarketSources.PJMWindGen, 'WIND GENERATION'],
  [PjmMarketSources.PJMSolarGen, 'SOLAR GENERATION'],
  [PjmMarketSources.PJMHydroGen, 'HYDRO GENERATION'],
  [PjmMarketSources.PJMOtherRenewablesGen, 'OTHER RENEWABLES GENERATION'],
  [PjmMarketSources.PJMPriceAdders, 'PRICE ADDERS - OVERALL'],
  [PjmMarketSources.PJMOnlineReserveAdder, 'ONLINE RESERVE PROCE ADDER'],
  [
    PjmMarketSources.PJMOnlineReliabilityAdder,
    'ONLINE RELIABILITY DEPLOYMENT PRICE ADDER'
  ],
  [PjmMarketSources.PJMASOverall, 'A/S - OVERALL'],
  [PjmMarketSources.PJMECRS, 'ECRS'],
  [PjmMarketSources.PJMRRS, 'RRS'],
  [PjmMarketSources.PJMNonSpin, 'NON SPIN'],
  [PjmMarketSources.PJMRegUp, 'REGULATION UP'],
  [PjmMarketSources.PJMRegDown, 'REGULATION DOWN'],
  [PjmMarketSources.PJMNetImportExport, 'NET IMPORTS/EXPORTS'],
  [PjmMarketSources.PJMImport, 'IMPORTS'],
  [PjmMarketSources.PJMExport, 'EXPORTS']
]);

export const PJMDemandComparisonOptions: MarketDemandComparison[] = [
  {
    text: 'net load',
    source: PjmMarketSources.PJMNetLoad,
    checked: true,
    expanded: false,
    items: []
  },
  {
    text: 'forecasted rt load',
    source: PjmMarketSources.PJMForecastedLoad,
    checked: true,
    expanded: false,
    items: []
  },
  {
    text: 'forecasted peak load',
    source: PjmMarketSources.PJMForecastedPeakLoad,
    checked: true,
    expanded: false,
    items: []
  },
  {
    text: 'outages',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'outages - total',
        source: PjmMarketSources.PJMTotalOutages,
        checked: true
      },
      {
        text: 'outages - planned',
        source: PjmMarketSources.PJMPlannedOutages,
        checked: false
      },
      {
        text: 'outages - MNTNC',
        source: PjmMarketSources.PJMMntncOutages,
        checked: false
      },
      {
        text: 'outages - forced',
        source: PjmMarketSources.PJMForcedOutages,
        checked: false
      }
    ]
  },
  {
    text: 'renewable generation',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'combined renewable generation',
        source: PjmMarketSources.PJMCombinedRenewables,
        checked: true
      },
      {
        text: 'wind generation',
        source: PjmMarketSources.PJMWindGen,
        checked: false
      },
      {
        text: 'solar generation',
        source: PjmMarketSources.PJMSolarGen,
        checked: false
      },
      {
        text: 'hydro generation',
        source: PjmMarketSources.PJMHydroGen,
        checked: false
      },
      {
        text: 'other renewables generation',
        source: PjmMarketSources.PJMOtherRenewablesGen,
        checked: false
      }
    ]
  },
  {
    text: 'net imports/exports',
    expanded: false,
    count: 1,
    items: [
      {
        text: 'net imports/exports',
        source: PjmMarketSources.PJMNetImportExport,
        checked: true
      },
      {
        text: 'imports',
        source: PjmMarketSources.PJMImport,
        checked: false
      },
      {
        text: 'exports',
        source: PjmMarketSources.PJMExport,
        checked: false
      }
    ]
  }
];

export const PJMMarketPriceSources = new Map([
  [PjmMarketSources.PJMAECOZoneLMP, 'AECO'],
  [PjmMarketSources.PJMAEPZoneLMP, 'AEP'],
  [PjmMarketSources.PJMAPSZoneLMP, 'APS'],
  [PjmMarketSources.PJMATSIZoneLMP, 'ATSI'],
  [PjmMarketSources.PJMBGEZoneLMP, 'BGE'],
  [PjmMarketSources.PJMCOMEDZoneLMP, 'COMED'],
  [PjmMarketSources.PJMDAYZoneLMP, 'DAY'],
  [PjmMarketSources.PJMDEOKZoneLMP, 'DEOK'],
  [PjmMarketSources.PJMDOMZoneLMP, 'DOM'],
  [PjmMarketSources.PJMDPLZoneLMP, 'DPL'],
  [PjmMarketSources.PJMDUQZoneLMP, 'DUQ'],
  [PjmMarketSources.PJMEKPCZoneLMP, 'EKPC'],
  [PjmMarketSources.PJMJCPLZoneLMP, 'JCPL'],
  [PjmMarketSources.PJMMETEDZoneLMP, 'METED'],
  [PjmMarketSources.PJMOVECZoneLMP, 'OVEC'],
  [PjmMarketSources.PJMPENELECZoneLMP, 'PENELEC'],
  [PjmMarketSources.PJMPEPCOZoneLMP, 'PEPCO'],
  [PjmMarketSources.PJMPJMRTOZoneLMP, 'PJM RTO'],
  [PjmMarketSources.PJMPLZoneLMP, 'PPL'],
  [PjmMarketSources.PJMPSEGZoneLMP, 'PSEG'],
  [PjmMarketSources.PJMRECOZoneLMP, 'RECO'],
  [PjmMarketSources.PJMPEZoneLMP, 'PECO'],
  [PjmMarketSources.PJMHUDSONTPInterfaceLMP, 'HUDSONTP'],
  [PjmMarketSources.PJMIMOInterfaceLMP, 'IMO'],
  [PjmMarketSources.PJMLINDENVFTInterfaceLMP, 'LINDENVFT'],
  [PjmMarketSources.PJMMISOInterfaceLMP, 'MISO'],
  [PjmMarketSources.PJMNeptuneInterfaceLMP, 'NEPTUNE'],
  [PjmMarketSources.PJMNYISInterfaceLMP, 'NYIS'],
  [PjmMarketSources.PJMSouthInterfaceLMP, 'SOUTH'],
  [PjmMarketSources.PJMAEPGenHubLMP, 'AEP GEN HUB'],
  [PjmMarketSources.PJMAEPDaytonHubLMP, 'AEP DAYTON HUB'],
  [PjmMarketSources.PJMATSIGenHubLMP, 'ATSI GEN HUB'],
  [PjmMarketSources.PJMChicagoGenHubLMP, 'CHICAGO GEN HUB'],
  [PjmMarketSources.PJMChicagoHubLMP, 'CHICAGO HUB'],
  [PjmMarketSources.PJMDominionHubLMP, 'DOMINION HUB'],
  [PjmMarketSources.PJMEasternHubLMP, 'EASTERN HUB'],
  [PjmMarketSources.PJMNIllinoisHubLMP, 'N ILLINOIS HUB'],
  [PjmMarketSources.PJMNewJerseyHubLMP, 'NEW JERSEY HUB'],
  [PjmMarketSources.PJMOhioHubLMP, 'OHIO HUB'],
  [PjmMarketSources.PJMWestINTHubLMP, 'WEST INT HUB'],
  [PjmMarketSources.PJMWesternHubLMP, 'WESTERN HUB'],
  [PjmMarketSources.PJMDAAECOZone, 'AECO'],
  [PjmMarketSources.PJMDAAEPZone, 'AEP'],
  [PjmMarketSources.PJMDAAPSZone, 'APS'],
  [PjmMarketSources.PJMDAATSIZone, 'ATSI'],
  [PjmMarketSources.PJMDABGEZone, 'BGE'],
  [PjmMarketSources.PJMDACOMEDZone, 'COMED'],
  [PjmMarketSources.PJMDADAYZone, 'DAY'],
  [PjmMarketSources.PJMDADEOKZone, 'DEOK'],
  [PjmMarketSources.PJMDADOMZone, 'DOM'],
  [PjmMarketSources.PJMDADPLZone, 'DPL'],
  [PjmMarketSources.PJMDADUQZone, 'DUQ'],
  [PjmMarketSources.PJMDAEKPCZone, 'EKPC'],
  [PjmMarketSources.PJMDAJCPLZone, 'JCPL'],
  [PjmMarketSources.PJMDAMETEDZone, 'METED'],
  [PjmMarketSources.PJMDAOVECZone, 'OVEC'],
  [PjmMarketSources.PJMDAPECOZone, 'PECO'],
  [PjmMarketSources.PJMDAPENELECZone, 'PENELEC'],
  [PjmMarketSources.PJMDAPEPCOZone, 'PEPCO'],
  [PjmMarketSources.PJMDAPJMRTOZone, 'PJM RTO'],
  [PjmMarketSources.PJMDAPPLZone, 'PPL'],
  [PjmMarketSources.PJMDAPLZone, 'PPL'],
  [PjmMarketSources.PJMDAPSEGZone, 'PSEG'],
  [PjmMarketSources.PJMDARECOZone, 'RECO'],
  [PjmMarketSources.PJMDAPEZone, 'PECO'],
  [PjmMarketSources.PJMDAHUDSONTPInterface, 'HUDSONTP'],
  [PjmMarketSources.PJMDAIMOInterface, 'IMO'],
  [PjmMarketSources.PJMDALINDENVFTInterface, 'LINDENVFT'],
  [PjmMarketSources.PJMDAMISOInterface, 'MISO'],
  [PjmMarketSources.PJMDANeptuneInterface, 'NEPTUNE'],
  [PjmMarketSources.PJMDANYISInterface, 'NYIS'],
  [PjmMarketSources.PJMDASouthInterface, 'SOUTH'],
  [PjmMarketSources.PJMDAAEPGenHub, 'AEP GEN HUB'],
  [PjmMarketSources.PJMDAAEPDaytonHub, 'AEP DAYTON HUB'],
  [PjmMarketSources.PJMDAATSIGenHub, 'ATSI GEN HUB'],
  [PjmMarketSources.PJMDAChicagoGenHub, 'CHICAGO GEN HUB'],
  [PjmMarketSources.PJMDAChicagoHub, 'CHICAGO HUB'],
  [PjmMarketSources.PJMDADominionHub, 'DOMINION HUB'],
  [PjmMarketSources.PJMDAEasternHub, 'EASTERN HUB'],
  [PjmMarketSources.PJMDANIllinoisHub, 'N ILLINOIS HUB'],
  [PjmMarketSources.PJMDANewJerseyHub, 'NEW JERSEY HUB'],
  [PjmMarketSources.PJMDAOhioHub, 'OHIO HUB'],
  [PjmMarketSources.PJMDAWestINTHub, 'WEST INT HUB'],
  [PjmMarketSources.PJMDAWesternHub, 'WESTERN HUB']
]);

export const PJMGenerationMixSources = [
  'Solar',
  'Wind',
  'Hydro',
  'Storage',
  'Other Renewables',
  'Gas',
  'Coal',
  'Nuclear',
  'Oil',
  'Multiple Fuels'
];

export const PJMGenerationMixSourceMap = new Map([
  ['pjm_solar', 'Solar'],
  ['pjm_wind', 'Wind'],
  ['pjm_hydro', 'Hydro'],
  ['pjm_storage', 'Storage'],
  ['pjm_other_renewables', 'Other Renewables'],
  ['pjm_gas', 'Gas'],
  ['pjm_coal', 'Coal'],
  ['pjm_nuclear', 'Nuclear'],
  ['pjm_oil', 'Oil'],
  ['pjm_multiple_fuels', 'Multiple Fuels']
]);

export const PJMGenerationMixPieColors = new Map([
  ['pjm_solar', '#F3934F'],
  ['pjm_wind', '#7FCFFF'],
  ['pjm_hydro', '#22BCCE'],
  ['pjm_storage', '#B8006B'],
  ['pjm_other_renewables', '#E1E2E5'],
  ['pjm_gas', '#6835AD'],
  ['pjm_coal', '#75777A'],
  ['pjm_nuclear', '#EDC200'],
  ['pjm_oil', '#FEFE00'],
  ['pjm_multiple_fuels', '#0E3468']
]);

export const PJMGenerationMixPieSources: ComparisonOptionItem[] = [
  {
    text: 'Solar',
    selected: true,
    source: PjmMarketSources.PJMSolar,
    hexColor: '#F3934F'
  },
  {
    text: 'Wind',
    selected: true,
    source: PjmMarketSources.PJMWind,
    hexColor: '#7FCFFF'
  },
  {
    text: 'Hydro',
    selected: false,
    source: PjmMarketSources.PJMHydro,
    hexColor: '#22BCCE'
  },
  {
    text: 'Storage',
    selected: false,
    source: PjmMarketSources.PJMStorage,
    hexColor: '#B8006B'
  },
  {
    text: 'Other Renewables',
    selected: false,
    source: PjmMarketSources.PJMOtherRenewables,
    hexColor: '#E1E2E5'
  },
  {
    text: 'Gas',
    selected: false,
    source: PjmMarketSources.PJMGas,
    hexColor: '#6835AD'
  },
  {
    text: 'Coal',
    selected: false,
    source: PjmMarketSources.PJMCoal,
    hexColor: '#75777A'
  },
  {
    text: 'Nuclear',
    selected: false,
    source: PjmMarketSources.PJMNuclear,
    hexColor: '#EDC200'
  },
  {
    text: 'Oil',
    selected: false,
    source: PjmMarketSources.PJMOil,
    hexColor: '#EDC200'
  },
  {
    text: 'Multiple Fuels',
    selected: false,
    source: PjmMarketSources.PJMMultipleFuels,
    hexColor: '#EDC200'
  }
];

export const PJMSnapshotComparisonOptions: MarketGraphComparison[] = [
  {
    text: 'REAL TIME LAYERS',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'RT Load',
        selected: true,
        source: PjmMarketSources.PjmActualLoad,
        hexColor: '#00AEEF'
      } /* ,
      {
        text: 'System Capacity',
        selected: false,
        source: PjmMarketSources.PjmRTSystemCapacity,
        hexColor: '#6cd2de'
      } */
    ]
  },
  {
    text: 'FORECASTED LAYERS',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'Load Forecast',
        selected: true,
        source: PjmMarketSources.PJMForecastedLoad,
        hexColor: '#424242'
      } /* ,
      {
        text: 'System Capacity Forecast',
        selected: false,
        source: PjmMarketSources.PjmForecastedSystemCapacity,
        hexColor: '#001e93'
      } */
    ]
  },
  {
    parent: 'PRICES',
    text: 'RT HUB & ZONE PRICES',
    count: 1,
    expanded: false,
    items: [
      {
        parent: 'PRICES',
        text: 'RT AEP GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMAEPGenHubLMP,
        hexColor: '#439539'
      },
      {
        parent: 'PRICES',
        text: 'RT AEP DAYTON HUB',
        selected: false,
        source: PjmMarketSources.PJMAEPDaytonHubLMP,
        hexColor: '#f7a35c'
      },
      {
        parent: 'PRICES',
        text: 'RT ATSI GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMATSIGenHubLMP,
        hexColor: '#8085e9'
      },
      {
        parent: 'PRICES',
        text: 'RT CHICAGO GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMChicagoGenHubLMP,
        hexColor: '#f15c80'
      },
      {
        parent: 'PRICES',
        text: 'RT CHICAGO HUB',
        selected: false,
        source: PjmMarketSources.PJMChicagoHubLMP,
        hexColor: '#e4d354'
      },
      {
        parent: 'PRICES',
        text: 'RT DOMINION HUB',
        selected: false,
        source: PjmMarketSources.PJMDominionHubLMP,
        hexColor: '#2b908f'
      },
      {
        parent: 'PRICES',
        text: 'RT EASTERN HUB',
        selected: false,
        source: PjmMarketSources.PJMEasternHubLMP,
        hexColor: '#556dcb'
      },
      {
        parent: 'PRICES',
        text: 'RT N ILLINOIS HUB',
        selected: false,
        source: PjmMarketSources.PJMNIllinoisHubLMP,
        hexColor: '#d0559c'
      },
      {
        parent: 'PRICES',
        text: 'RT NEW JERSEY HUB',
        selected: false,
        source: PjmMarketSources.PJMNewJerseyHubLMP,
        hexColor: '#006780'
      },
      {
        parent: 'PRICES',
        text: 'RT OHIO HUB',
        selected: false,
        source: PjmMarketSources.PJMOhioHubLMP,
        hexColor: '#008860'
      },
      {
        parent: 'PRICES',
        text: 'RT WEST INT HUB',
        selected: false,
        source: PjmMarketSources.PJMWestINTHubLMP,
        hexColor: '#f45b5b'
      },
      {
        parent: 'PRICES',
        text: 'RT WESTERN HUB',
        selected: true,
        source: PjmMarketSources.PJMWesternHubLMP,
        hexColor: '#55b095'
      },
      {
        parent: 'PRICES',
        text: 'RT AECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMAECOZoneLMP,
        hexColor: '#91e8e1'
      },
      {
        parent: 'PRICES',
        text: 'RT AEP ZONE',
        selected: false,
        source: PjmMarketSources.PJMAEPZoneLMP,
        hexColor: '#5b61f4'
      },
      {
        parent: 'PRICES',
        text: 'RT APS ZONE',
        selected: false,
        source: PjmMarketSources.PJMAPSZoneLMP,
        hexColor: '#E57373'
      },
      {
        parent: 'PRICES',
        text: 'RT ATSI ZONE',
        selected: false,
        source: PjmMarketSources.PJMATSIZoneLMP,
        hexColor: '#CC8899'
      },
      {
        parent: 'PRICES',
        text: 'RT BGE ZONE',
        selected: false,
        source: PjmMarketSources.PJMBGEZoneLMP,
        hexColor: '#BA68C8'
      },
      {
        parent: 'PRICES',
        text: 'RT COMED ZONE',
        selected: false,
        source: PjmMarketSources.PJMCOMEDZoneLMP,
        hexColor: '#9575CD'
      },
      {
        parent: 'PRICES',
        text: 'RT DAY ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAYZoneLMP,
        hexColor: '#7986CB'
      },
      {
        parent: 'PRICES',
        text: 'RT DEOK ZONE',
        selected: false,
        source: PjmMarketSources.PJMDEOKZoneLMP,
        hexColor: '#006064'
      },
      {
        parent: 'PRICES',
        text: 'RT DOM ZONE',
        selected: false,
        source: PjmMarketSources.PJMDOMZoneLMP,
        hexColor: '#827717'
      },
      {
        parent: 'PRICES',
        text: 'RT DPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDPLZoneLMP,
        hexColor: '#4DD0E1'
      },
      {
        parent: 'PRICES',
        text: 'RT DUQ ZONE',
        selected: false,
        source: PjmMarketSources.PJMDUQZoneLMP,
        hexColor: '#4DB6AC'
      },
      {
        parent: 'PRICES',
        text: 'RT EKPC ZONE',
        selected: false,
        source: PjmMarketSources.PJMEKPCZoneLMP,
        hexColor: '#81C784'
      },
      {
        parent: 'PRICES',
        text: 'RT JCPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMJCPLZoneLMP,
        hexColor: '#AED581'
      },
      {
        parent: 'PRICES',
        text: 'RT METED ZONE',
        selected: false,
        source: PjmMarketSources.PJMMETEDZoneLMP,
        hexColor: '#DCE775'
      },
      {
        parent: 'PRICES',
        text: 'RT OVEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMOVECZoneLMP,
        hexColor: '#FFD54F'
      },
      {
        parent: 'PRICES',
        text: 'RT PENELEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMPENELECZoneLMP,
        hexColor: '#FF8A65'
      },
      {
        parent: 'PRICES',
        text: 'RT PEPCO ZONE',
        selected: false,
        source: PjmMarketSources.PJMPEPCOZoneLMP,
        hexColor: '#A1887F'
      },
      {
        parent: 'PRICES',
        text: 'RT PJM RTO ZONE',
        selected: false,
        source: PjmMarketSources.PJMPJMRTOZoneLMP,
        hexColor: '#BDBDBD'
      },
      {
        parent: 'PRICES',
        text: 'RT PL ZONE',
        selected: false,
        source: PjmMarketSources.PJMPLZoneLMP,
        hexColor: '#90A4AE'
      },
      {
        parent: 'PRICES',
        text: 'RT PSEG ZONE',
        selected: false,
        source: PjmMarketSources.PJMPSEGZoneLMP,
        hexColor: '#607D8B'
      },
      {
        parent: 'PRICES',
        text: 'RT RECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMRECOZoneLMP,
        hexColor: '#795548'
      },
      {
        parent: 'PRICES',
        text: 'RT PE ZONE',
        selected: false,
        source: PjmMarketSources.PJMPEZoneLMP,
        hexColor: '#5cf775'
      }
    ]
  },
  {
    parent: 'PRICES',
    text: 'DA HUB & ZONE PRICES',
    count: 0,
    expanded: false,
    items: [
      {
        parent: 'PRICES',
        text: 'DA AEP GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAAEPGenHub,
        hexColor: '#439539'
      },
      {
        parent: 'PRICES',
        text: 'DA AEP DAYTON HUB',
        selected: false,
        source: PjmMarketSources.PJMDAAEPDaytonHub,
        hexColor: '#f7a35c'
      },
      {
        parent: 'PRICES',
        text: 'DA ATSI GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAATSIGenHub,
        hexColor: '#8085e9'
      },
      {
        parent: 'PRICES',
        text: 'DA CHICAGO GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAChicagoGenHub,
        hexColor: '#f15c80'
      },
      {
        parent: 'PRICES',
        text: 'DA CHICAGO HUB',
        selected: false,
        source: PjmMarketSources.PJMDAChicagoHub,
        hexColor: '#e4d354'
      },
      {
        parent: 'PRICES',
        text: 'DA DOMINION HUB',
        selected: false,
        source: PjmMarketSources.PJMDADominionHub,
        hexColor: '#2b908f'
      },
      {
        parent: 'PRICES',
        text: 'DA EASTERN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAEasternHub,
        hexColor: '#556dcb'
      },
      {
        parent: 'PRICES',
        text: 'DA N ILLINOIS HUB',
        selected: false,
        source: PjmMarketSources.PJMDANIllinoisHub,
        hexColor: '#d0559c'
      },
      {
        parent: 'PRICES',
        text: 'DA NEW JERSEY HUB',
        selected: false,
        source: PjmMarketSources.PJMDANewJerseyHub,
        hexColor: '#006780'
      },
      {
        parent: 'PRICES',
        text: 'DA OHIO HUB',
        selected: false,
        source: PjmMarketSources.PJMDAOhioHub,
        hexColor: '#008860'
      },
      {
        parent: 'PRICES',
        text: 'DA WEST INT HUB',
        selected: false,
        source: PjmMarketSources.PJMDAWestINTHub,
        hexColor: '#f45b5b'
      },
      {
        parent: 'PRICES',
        text: 'DA WESTERN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAWesternHub,
        hexColor: '#55b095'
      },
      {
        parent: 'PRICES',
        text: 'DA AECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAAECOZone,
        hexColor: '#91e8e1'
      },
      {
        parent: 'PRICES',
        text: 'DA AEP ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAAEPZone,
        hexColor: '#5b61f4'
      },

      {
        parent: 'PRICES',
        text: 'DA APS ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAAPSZone,
        hexColor: '#E57373'
      },
      {
        parent: 'PRICES',
        text: 'DA ATSI ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAATSIZone,
        hexColor: '#CC8899'
      },
      {
        parent: 'PRICES',
        text: 'DA BGE ZONE',
        selected: false,
        source: PjmMarketSources.PJMDABGEZone,
        hexColor: '#BA68C8'
      },
      {
        parent: 'PRICES',
        text: 'DA COMED ZONE',
        selected: false,
        source: PjmMarketSources.PJMDACOMEDZone,
        hexColor: '#9575CD'
      },
      {
        parent: 'PRICES',
        text: 'DA DAY ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADAYZone,
        hexColor: '#7986CB'
      },
      {
        parent: 'PRICES',
        text: 'DA DEOK ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADEOKZone,
        hexColor: '#006064'
      },
      {
        parent: 'PRICES',
        text: 'DA DOM ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADOMZone,
        hexColor: '#827717'
      },
      {
        parent: 'PRICES',
        text: 'DA DPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADPLZone,
        hexColor: '#4DD0E1'
      },
      {
        parent: 'PRICES',
        text: 'DA DUQ ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADUQZone,
        hexColor: '#4DB6AC'
      },
      {
        parent: 'PRICES',
        text: 'DA EKPC ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAEKPCZone,
        hexColor: '#81C784'
      },
      {
        parent: 'PRICES',
        text: 'DA JCPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAJCPLZone,
        hexColor: '#AED581'
      },
      {
        parent: 'PRICES',
        text: 'DA METED ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAMETEDZone,
        hexColor: '#DCE775'
      },
      {
        parent: 'PRICES',
        text: 'DA OVEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAOVECZone,
        hexColor: '#FFD54F'
      },
      {
        parent: 'PRICES',
        text: 'DA PENELEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPENELECZone,
        hexColor: '#FF8A65'
      },
      {
        parent: 'PRICES',
        text: 'DA PEPCO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPEPCOZone,
        hexColor: '#A1887F'
      },
      {
        parent: 'PRICES',
        text: 'DA PJM RTO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPJMRTOZone,
        hexColor: '#BDBDBD'
      },
      {
        parent: 'PRICES',
        text: 'DA PPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPPLZone,
        hexColor: '#90A4AE'
      },
      {
        parent: 'PRICES',
        text: 'DA PSEG ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPSEGZone,
        hexColor: '#607D8B'
      },
      {
        parent: 'PRICES',
        text: 'DA RECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDARECOZone,
        hexColor: '#795548'
      },
      {
        parent: 'PRICES',
        text: 'DA PECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPECOZone,
        hexColor: '#5cf775'
      }
    ]
  },
  {
    parent: 'PRICES',
    text: 'INTERFACE PRICES',
    count: 0,
    expanded: false,
    items: [
      {
        parent: 'PRICES',
        text: 'HUDSONTP',
        selected: false,
        source: PjmMarketSources.PJMHUDSONTPInterfaceLMP,
        hexColor: '#37474F'
      },
      {
        parent: 'PRICES',
        text: 'IMO',
        selected: false,
        source: PjmMarketSources.PJMIMOInterfaceLMP,
        hexColor: '#616161'
      },
      {
        parent: 'PRICES',
        text: 'LINDENVFT',
        selected: false,
        source: PjmMarketSources.PJMLINDENVFTInterfaceLMP,
        hexColor: '#4E342E'
      },
      {
        parent: 'PRICES',
        text: 'MISO',
        selected: false,
        source: PjmMarketSources.PJMMISOInterfaceLMP,
        hexColor: '#FF6E40'
      },
      {
        parent: 'PRICES',
        text: 'NEPTUNE',
        selected: false,
        source: PjmMarketSources.PJMNeptuneInterfaceLMP,
        hexColor: '#AEEA00'
      },
      {
        parent: 'PRICES',
        text: 'NYIS',
        selected: false,
        source: PjmMarketSources.PJMNYISInterfaceLMP,
        hexColor: '#69F0AE'
      },
      {
        parent: 'PRICES',
        text: 'SOUTH',
        selected: false,
        source: PjmMarketSources.PJMSouthInterfaceLMP,
        hexColor: '#00B0FF'
      }
    ]
  },
  {
    text: 'GENERATION MIX',
    count: 2,
    expanded: false,
    items: [
      {
        parent: 'GENERATION MIX',
        text: 'Solar',
        selected: true,
        source: PjmMarketSources.PJMSolar,
        hexColor: '#F3934F'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Wind',
        selected: true,
        source: PjmMarketSources.PJMWind,
        hexColor: '#7FCFFF'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Hydro',
        selected: false,
        source: PjmMarketSources.PJMHydro,
        hexColor: '#22BCCE'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Storage',
        selected: false,
        source: PjmMarketSources.PJMStorage,
        hexColor: '#B8006B'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Other Renewables',
        selected: false,
        source: PjmMarketSources.PJMOtherRenewables,
        hexColor: '#E1E2E5'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Gas',
        selected: false,
        source: PjmMarketSources.PJMGas,
        hexColor: '#6835AD'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Coal',
        selected: false,
        source: PjmMarketSources.PJMCoal,
        hexColor: '#75777A'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Nuclear',
        selected: false,
        source: PjmMarketSources.PJMNuclear,
        hexColor: '#EDC200'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Oil',
        selected: false,
        source: PjmMarketSources.PJMOil,
        hexColor: '#FEFE00'
      },
      {
        parent: 'GENERATION MIX',
        text: 'Multiple Fuels',
        selected: false,
        source: PjmMarketSources.PJMMultipleFuels,
        hexColor: '#0E3468'
      }
    ]
  }
];

export const PJMInfoComparisonOptions: MarketGraphComparison[] = [
  {
    text: 'REAL TIME LAYERS',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'RT Load',
        selected: true,
        source: PjmMarketSources.PjmActualLoadHrly,
        hexColor: '#00AEEF'
      }
    ]
  },
  {
    text: 'RT HUB & ZONE PRICES',
    count: 1,
    expanded: false,
    items: [
      {
        text: 'RT AEP GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMAEPGenHub,
        hexColor: '#439539'
      },
      {
        text: 'RT AEP DAYTON HUB',
        selected: false,
        source: PjmMarketSources.PJMAEPDaytonHub,
        hexColor: '#f7a35c'
      },
      {
        text: 'RT ATSI GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMATSIGenHub,
        hexColor: '#8085e9'
      },
      {
        text: 'RT CHICAGO GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMChicagoGenHub,
        hexColor: '#f15c80'
      },
      {
        text: 'RT CHICAGO HUB',
        selected: false,
        source: PjmMarketSources.PJMChicagoHub,
        hexColor: '#e4d354'
      },
      {
        text: 'RT DOMINION HUB',
        selected: false,
        source: PjmMarketSources.PJMDominionHub,
        hexColor: '#2b908f'
      },
      {
        text: 'RT EASTERN HUB',
        selected: false,
        source: PjmMarketSources.PJMEasternHub,
        hexColor: '#556dcb'
      },
      {
        text: 'RT N ILLINOIS HUB',
        selected: false,
        source: PjmMarketSources.PJMNIllinoisHub,
        hexColor: '#d0559c'
      },
      {
        parent: 'PRICES',
        text: 'RT NEW JERSEY HUB',
        selected: false,
        source: PjmMarketSources.PJMNewJerseyHub,
        hexColor: '#006780'
      },
      {
        text: 'RT OHIO HUB',
        selected: false,
        source: PjmMarketSources.PJMOhioHub,
        hexColor: '#008860'
      },
      {
        text: 'RT WEST INT HUB',
        selected: false,
        source: PjmMarketSources.PJMWestINTHub,
        hexColor: '#f45b5b'
      },
      {
        text: 'RT WESTERN HUB',
        selected: true,
        source: PjmMarketSources.PJMWesternHub,
        hexColor: '#55b095'
      },
      {
        text: 'RT AECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMAECOZone,
        hexColor: '#91e8e1'
      },
      {
        text: 'RT AEP ZONE',
        selected: false,
        source: PjmMarketSources.PJMAEPZone,
        hexColor: '#5b61f4'
      },
      {
        text: 'RT APS ZONE',
        selected: false,
        source: PjmMarketSources.PJMAPSZone,
        hexColor: '#e57373'
      },
      {
        text: 'RT ATSI ZONE',
        selected: false,
        source: PjmMarketSources.PJMATSIZone,
        hexColor: '#CC8899'
      },
      {
        text: 'RT BGE ZONE',
        selected: false,
        source: PjmMarketSources.PJMBGEZone,
        hexColor: '#ba68c8'
      },
      {
        text: 'RT COMED ZONE',
        selected: false,
        source: PjmMarketSources.PJMCOMEDZone,
        hexColor: '#9575cd'
      },
      {
        text: 'RT DAY ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAYZone,
        hexColor: '#7986cb'
      },
      {
        text: 'RT DEOK ZONE',
        selected: false,
        source: PjmMarketSources.PJMDEOKZone,
        hexColor: '#006064'
      },
      {
        text: 'RT DOM ZONE',
        selected: false,
        source: PjmMarketSources.PJMDOMZone,
        hexColor: '#827717'
      },
      {
        text: 'RT DPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDPLZone,
        hexColor: '#4dd0e1'
      },
      {
        text: 'RT DUQ ZONE',
        selected: false,
        source: PjmMarketSources.PJMDUQZone,
        hexColor: '#4db6ac'
      },
      {
        text: 'RT EKPC ZONE',
        selected: false,
        source: PjmMarketSources.PJMEKPCZone,
        hexColor: '#81c784'
      },
      {
        text: 'RT JCPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMJCPLZone,
        hexColor: '#aed581'
      },
      {
        text: 'RT METED ZONE',
        selected: false,
        source: PjmMarketSources.PJMMETEDZone,
        hexColor: '#dce775'
      },
      {
        text: 'RT OVEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMOVECZone,
        hexColor: '#ffd54f'
      },
      {
        text: 'RT PENELEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMPENELECZone,
        hexColor: '#ff8a65'
      },
      {
        text: 'RT PEPCO ZONE',
        selected: false,
        source: PjmMarketSources.PJMPEPCOZone,
        hexColor: '#a1887f'
      },
      {
        text: 'RT PJM RTO ZONE',
        selected: false,
        source: PjmMarketSources.PJMPJMRTOZone,
        hexColor: '#bdbdbd'
      },
      {
        text: 'RT PL ZONE',
        selected: false,
        source: PjmMarketSources.PJMPLZone,
        hexColor: '#90a4ae'
      },
      {
        text: 'RT PSEG ZONE',
        selected: false,
        source: PjmMarketSources.PJMPSEGZone,
        hexColor: '#607d8b'
      },
      {
        text: 'RT RECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMRECOZone,
        hexColor: '#795548'
      },
      {
        text: 'RT PE ZONE',
        selected: false,
        source: PjmMarketSources.PJMPEZone,
        hexColor: '#5cf775'
      }
    ]
  },
  {
    text: 'DA HUB & ZONE PRICES',
    count: 0,
    expanded: false,
    items: [
      {
        text: 'DA AEP GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAAEPGenHub,
        hexColor: '#439539'
      },
      {
        text: 'DA AEP DAYTON HUB',
        selected: false,
        source: PjmMarketSources.PJMDAAEPDaytonHub,
        hexColor: '#f7a35c'
      },
      {
        text: 'DA ATSI GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAATSIGenHub,
        hexColor: '#8085e9'
      },
      {
        text: 'DA CHICAGO GEN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAChicagoGenHub,
        hexColor: '#f15c80'
      },
      {
        text: 'DA CHICAGO HUB',
        selected: false,
        source: PjmMarketSources.PJMDAChicagoHub,
        hexColor: '#e4d354'
      },
      {
        text: 'DA DOMINION HUB',
        selected: false,
        source: PjmMarketSources.PJMDADominionHub,
        hexColor: '#2b908f'
      },
      {
        text: 'DA EASTERN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAEasternHub,
        hexColor: '#556dcb'
      },
      {
        text: 'DA N ILLINOIS HUB',
        selected: false,
        source: PjmMarketSources.PJMDANIllinoisHub,
        hexColor: '#d0559c'
      },
      {
        parent: 'PRICES',
        text: 'DA NEW JERSEY HUB',
        selected: false,
        source: PjmMarketSources.PJMDANewJerseyHub,
        hexColor: '#006780'
      },
      {
        text: 'DA OHIO HUB',
        selected: false,
        source: PjmMarketSources.PJMDAOhioHub,
        hexColor: '#008860'
      },
      {
        text: 'DA WEST INT HUB',
        selected: false,
        source: PjmMarketSources.PJMDAWestINTHub,
        hexColor: '#f45b5b'
      },
      {
        text: 'DA WESTERN HUB',
        selected: false,
        source: PjmMarketSources.PJMDAWesternHub,
        hexColor: '#55b095'
      },
      {
        text: 'DA AECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAAECOZone,
        hexColor: '#91e8e1'
      },
      {
        text: 'DA AEP ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAAEPZone,
        hexColor: '#5b61f4'
      },
      {
        text: 'DA APS ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAAPSZone,
        hexColor: '#e57373'
      },
      {
        text: 'DA ATSI ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAATSIZone,
        hexColor: '#CC8899'
      },
      {
        text: 'DA BGE ZONE',
        selected: false,
        source: PjmMarketSources.PJMDABGEZone,
        hexColor: '#ba68c8'
      },
      {
        text: 'DA COMED ZONE',
        selected: false,
        source: PjmMarketSources.PJMDACOMEDZone,
        hexColor: '#9575cd'
      },
      {
        text: 'DA DAY ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADAYZone,
        hexColor: '#7986cb'
      },
      {
        text: 'DA DEOK ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADEOKZone,
        hexColor: '#006064'
      },
      {
        text: 'DA DOM ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADOMZone,
        hexColor: '#827717'
      },
      {
        text: 'DA DPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADPLZone,
        hexColor: '#4dd0e1'
      },
      {
        text: 'DA DUQ ZONE',
        selected: false,
        source: PjmMarketSources.PJMDADUQZone,
        hexColor: '#4db6ac'
      },
      {
        text: 'DA EKPC ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAEKPCZone,
        hexColor: '#81c784'
      },
      {
        text: 'DA JCPL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAJCPLZone,
        hexColor: '#aed581'
      },
      {
        text: 'DA METED ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAMETEDZone,
        hexColor: '#dce775'
      },
      {
        text: 'DA OVEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAOVECZone,
        hexColor: '#ffd54f'
      },
      {
        text: 'DA PENELEC ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPENELECZone,
        hexColor: '#ff8a65'
      },
      {
        text: 'DA PEPCO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPEPCOZone,
        hexColor: '#a1887f'
      },
      {
        text: 'DA PJM RTO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPJMRTOZone,
        hexColor: '#bdbdbd'
      },
      {
        text: 'DA PL ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPLZone,
        hexColor: '#90a4ae'
      },
      {
        text: 'DA PSEG ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPSEGZone,
        hexColor: '#607d8b'
      },
      {
        text: 'DA RECO ZONE',
        selected: false,
        source: PjmMarketSources.PJMDARECOZone,
        hexColor: '#795548'
      },
      {
        text: 'DA PE ZONE',
        selected: false,
        source: PjmMarketSources.PJMDAPEZone,
        hexColor: '#5cf775'
      }
    ]
  },
  {
    text: 'INTERFACE PRICES',
    count: 0,
    expanded: false,
    items: [
      {
        text: 'HUDSONTP',
        selected: false,
        source: PjmMarketSources.PJMHUDSONTPInterface,
        hexColor: '#37474f'
      },
      {
        text: 'IMO',
        selected: false,
        source: PjmMarketSources.PJMIMOInterface,
        hexColor: '#616161'
      },
      {
        text: 'LINDENVFT',
        selected: false,
        source: PjmMarketSources.PJMLINDENVFTInterface,
        hexColor: '#4e342e'
      },
      {
        text: 'MISO',
        selected: false,
        source: PjmMarketSources.PJMMISOInterface,
        hexColor: '#ff6e40'
      },
      {
        text: 'NEPTUNE',
        selected: false,
        source: PjmMarketSources.PJMNeptuneInterface,
        hexColor: '#aeea00'
      },
      {
        text: 'NYIS',
        selected: false,
        source: PjmMarketSources.PJMNYISInterface,
        hexColor: '#69f0ae'
      },
      {
        text: 'SOUTH',
        selected: false,
        source: PjmMarketSources.PJMSouthInterface,
        hexColor: '#00b0ff'
      }
    ]
  }
];

export const PJMPricesTypes = {
  Zones: 'ZONES',
  Hubs: 'HUBS',
  Interfaces: 'INTERFACES',
  YourList: 'PJM_YOUR_LIST',
  ZonesDA: 'ZONES_DA',
  HubsDA: 'HUBS_DA',
  InterfacesDA: 'INTERFACES_DA',
  YourListDA: 'PJM_YOUR_LIST_DA'
};
