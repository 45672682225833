import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {DataService} from "../../shared/data.service";
import {PaymentsService} from "../payments.service";
import { IBalances } from '../payments';
import { PortalService } from "../../shared/portal.service";
import {IPaymentSchedule, IPaymentMethod, IPaymentSettingsKey} from "../payments";
import { RoutingPipe } from "../../shared/pipe/routing.pipe"
import { BankAccountPipe } from "../../shared/pipe/bank-account.pipe"
import { AccountEndingPipe } from "../../shared/pipe/account-ending.pipe"
import * as moment from 'moment';
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {Subscription} from "rxjs";
import {Permissions} from "../../shared/const/permissions";
import { ISiteGroup, ISite } from '../../shared/entities/site';
import { IInvoice } from '../../shared/entities/bill-reports';
import {Router} from "@angular/router";
import { Authority } from '../../shared/const/authority';
declare var $ :any;

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  providers: [BankAccountPipe, RoutingPipe, AccountEndingPipe, CookieService],
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent implements OnInit {
  totalSites: ISite[];
  paymentMethods: IPaymentMethod[];
  savingRadio: boolean = true;


  allowedToEditPaymentMethods : boolean = true;
  makeDefault: boolean = true;
  paymentSchedule: IPaymentSchedule;
  methodNickname: string;
  selectedPayDate: Date;
  selectedPaymentMethod: string;
  minDate: Date;
  maxDate: Date;
  editing: boolean = false;
  editName: IPaymentMethod;
  updatedName: string;
  newRouting: number;
  newToken: string;
  editingNew: boolean;
  helpStep: IHelpStep;
  properties: any;
  showDateError: boolean;
  newPaymentAdded: boolean;
  onManagePaymentMethods: boolean = false;
  showCreditCard: boolean = false;
  showBankAccount: boolean = false;
  nonNullContractNumberForAccount: String = "";
  selectedBalances: IInvoice[] = [];
  canPayWithCC: boolean = false;
  canPayWithACH : boolean = true;
  showAutopayErrorModal: boolean = false;

  helpStepSubscription: Subscription;


  constructor(private router: Router, private dataService: DataService, private paymentsService: PaymentsService, private _cookieService:CookieService, private portalService: PortalService) { }

  ngOnInit() {
    this.paymentsService.setStepReached(2);
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 15);
    this.newPaymentAdded = false;
    let key: IPaymentSettingsKey = {
      paymentToken: null,
      userId: null,
      accountId: null
    };


    if(window.location.href.indexOf('manage-payment-methods') != -1){
      this.onManagePaymentMethods = true;
    }
    this.showCreditCard = this.dataService.getShowCreditCard() && !this.dataService.getCCNotice();
    this.updateAddPermissions();
    this.updateEditPermissions();
    this.paymentMethods = [];
    let existing = this.paymentsService.getPaymentSchedule();
    if(existing && this.dataService.getAccountSource().id!=existing.paymentMethod.userPaymentSettingsKey.accountId || (existing && existing.paymentMethod.rememberPayment == 'false')) {
      this.paymentsService.setPaymentSchedule(null);
      existing = null;
   }
    if(existing != null && (existing.paymentMethod.routingNumber != "-1" || existing.paymentMethod.maskedCardNumber!=null)) {
          this.paymentsService.getPaymentMethods(this.dataService.getAccountSource().id).subscribe(
            (methods) => {
              this.paymentMethods = methods;
              let temp = [];
              let i = 0;
              let found = false;
              this.paymentMethods.forEach((method)=> {
                  if(method.paymentType=='BANKACCOUNT' || (!this.paymentsService.getNCCA() && method.paymentType=='CARD')) {
                    temp[i] = method;
                    if(this.paymentSchedule && method.userPaymentSettingsKey.paymentToken===this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken)
                    {  found = true; }
                    i++;
                  }
                  if(method.defaultPayment===1) {
                    this.selectedPaymentMethod  = method.userPaymentSettingsKey.paymentToken.toString() + method.rememberPayment;
                  }
              });
              if(!found && this.paymentSchedule) //new payment type that was not saved in DB by user
              {
                temp[i] = this.paymentSchedule.paymentMethod;
                this.selectedPaymentMethod  = this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken.toString() + this.paymentSchedule.paymentMethod.rememberPayment;
              }
              this.paymentMethods = temp;
            }
          );
          this.paymentSchedule = existing;
          this.paymentsService.setPaymentSchedule(this.paymentSchedule);
          this.methodNickname = this.paymentSchedule.paymentMethod.nickName;
          this.savingRadio = this.paymentSchedule.savePaymentMethod;
          if(this.paymentSchedule.paymentMethod.defaultPayment === 1) {
            this.makeDefault = true;
          } else {
            this.makeDefault = false;
          }
    } else {
      let method: IPaymentMethod = {
        defaultPayment: 1,
        nickName: "",
        oldPaymentToken: "",
        routingNumber: "-1",
        methodType: "existing",
        maskedAccountNumber: null,
        userPaymentSettingsKey: key,
        paymentType: "",
        cardBrand: "",
        maskedCardNumber: null,
        cardExpiryMonth: null,
        cardExpiryYear: null,
        cvv: null,
        cardZipCode: null,
        rememberPayment: 'true'
      };
      let schedule: IPaymentSchedule = {
        paymentDate: new Date(),
        savePaymentMethod: true,
        paymentMethod: method
      };
      this.paymentSchedule = schedule;
      this.paymentsService.setPaymentSchedule(this.paymentSchedule);
      this.paymentsService.getPaymentMethods(this.dataService.getAccountSource().id).subscribe(
        (methods) => {
          if(methods.length == 0) {
           this.clickedNew();
          } else {
            let temp = [];
            let i = 0;
            let found = false;
            methods.forEach((method)=> {
                if(method.paymentType=='BANKACCOUNT' || (!this.paymentsService.getNCCA() && method.paymentType=='CARD')) {
                  method.cvv=null;
                  temp[i] = method;
                  i++;
                }
            });

            this.paymentMethods = temp;
            this.selectDefault();
          }
        }
      );

      let ncaStatus = this.paymentsService.getNca();
      if(ncaStatus){
        this.canPayWithACH = false;
      }
      else{
        this.canPayWithACH = true;
      }
    }

    this.selectedPayDate = moment().toDate();
    if(moment().hour() > 17){
      this.selectedPayDate = moment().add(1, 'day').toDate();
    }
    this.paymentSchedule.paymentDate = this.selectedPayDate;

    this.paymentsService.paymentScheduleUpdated.subscribe(
      (paymentSchedule) => {
        this.paymentSchedule = paymentSchedule;
      }
    );
    this.paymentsService.pciChangeCalledBack.subscribe(
      (info) => {
        this.paymentMethods = info.paymentMethods;
        this.paymentMethods.forEach((method)=> {
          // TODO: match on accountID as well
          if(method.userPaymentSettingsKey.paymentToken == info.paymentToken){
            this.selectMethodFromManage(method);
          }
        });
        this.editing = false;
      }
    );
    this.paymentsService.pciEditCancelCalledBack.subscribe(
      (info) => {
        this.paymentMethods = info.paymentMethods;
        this.paymentMethods.forEach((method) => {
          if(method.userPaymentSettingsKey.paymentToken == info.paymentToken) {
            this.selectMethodFromManage(method);
          }
        });
       if(info.paymentType=='BANKACCOUNT'){
         document.getElementById('achClose').click();
         this.achRefresh();
        }
        if(info.paymentType=='CARD'){
         document.getElementById('ccClose').click();
         this.ccRefresh();
        }
      }
    );
    this.paymentsService.pciCreateCalledBack.subscribe(
      (info) => {
       //console.log('>>>>> this.paymentsService.pciCreateCalledBack.subscribe :::: ', info);
       let key: IPaymentSettingsKey = {
          paymentToken: null,
          userId: null,
          accountId: this.dataService.getAccountSource().id
        };
       let method: IPaymentMethod = {
        defaultPayment: 1,
        nickName: "",
        oldPaymentToken: "",
        routingNumber: "-1",
        methodType: "existing",
        maskedAccountNumber: null,
        userPaymentSettingsKey: key,
        paymentType: "",
        cardBrand: "",
        maskedCardNumber: null,
        cardExpiryMonth: null,
        cardExpiryYear: null,
        cvv: null,
        cardZipCode: null,
        rememberPayment: info.rememberPayment
      };
      let schedule: IPaymentSchedule = {
        paymentDate: new Date(),
        savePaymentMethod: true,
        paymentMethod: method
      };
      this.paymentSchedule = schedule;
      this.paymentSchedule.paymentMethod.rememberPayment = info.rememberPayment;
        if(info.paymentType=='BANKACCOUNT'){
            this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken = info.paymentToken;
            this.paymentSchedule.paymentMethod.routingNumber = info.routingNumber;
            this.paymentSchedule.paymentMethod.maskedAccountNumber = info.maskedAccountNumber;
            this.paymentSchedule.paymentMethod.nickName = info.nickname;
            this.paymentSchedule.paymentMethod.paymentType = info.paymentType;

            this.editingNew = false;
            if(this.onManagePaymentMethods)
              this.paymentsService.setPaymentSchedule(null);
            else
              this.paymentsService.setPaymentSchedule(this.paymentSchedule);

            document.getElementById('achClose').click();

            this.achRefresh();
         }
        else if(info.paymentType=='CARD')
        {
            this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken = info.paymentToken;
            this.paymentSchedule.paymentMethod.maskedCardNumber = info.paymentToken.slice(-4);
            this.paymentSchedule.paymentMethod.cardExpiryMonth = info.cardExpMonth;
            this.paymentSchedule.paymentMethod.cardExpiryYear = info.cardExpYear;
            this.paymentSchedule.paymentMethod.cvv = info.cvv;
            this.paymentSchedule.paymentMethod.cardZipCode = info.cardZipCode;
            this.paymentSchedule.paymentMethod.nickName = info.nickname;
            this.paymentSchedule.paymentMethod.cardBrand = info.cardBrand;
            this.paymentSchedule.paymentMethod.paymentType = info.paymentType;
            this.paymentSchedule.paymentMethod.routingNumber = '';

            this.editingNew = false;
            if(this.onManagePaymentMethods)
            {
              this.paymentsService.setPaymentSchedule(null);
            }
            else
              this.paymentsService.setPaymentSchedule(this.paymentSchedule);

            document.getElementById('ccClose').click();

            this.ccRefresh();
         }
         if(this.onManagePaymentMethods)
         {
           this.getPaymentMethods();
         }
         else {
              let temp = [];
              let i = 0;
              this.paymentMethods.forEach((method)=> {
                    //if(method.paymentType=='BANKACCOUNT' || (!this.paymentsService.getNCCA() && method.paymentType=='CARD')) {
                        method.defaultPayment = 0;
                        temp[i] = method;
                        i++;
                    //}
                });
                if(this.paymentSchedule) {
                  this.paymentSchedule.paymentMethod.defaultPayment = 1;
                  temp[i] = this.paymentSchedule.paymentMethod;
                }
                this.paymentMethods = temp;
           }
           this.newPaymentAdded = true;
           this.selectDefault();
      }
    );

    // this.dataService.accountSourceUpdated.subscribe(() => {
    //     this.getPaymentMethods();
    // });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    this.properties = this.dataService.getAppProperties();



    //load balances from select balances
    if(this.onManagePaymentMethods){
      this.canPayWithCC = this.showCreditCard;
    }
    else{
      this.selectedBalances = this.paymentsService.getSelectedBalances();
      let retBool = true;
      for(let i = 0; i < this.selectedBalances.length; i ++){
        let currInv = this.selectedBalances[i];
        if(currInv.nccaStatus == 'X'){
          retBool = false;
          break;
      }
    }
    if(!this.paymentsService.payByAccount){
      this.canPayWithCC = retBool && !this.paymentsService.getNCCA();
    }
    else{
      this.canPayWithCC = !this.paymentsService.getNCCA();
    }
  }
}

  ngOnDestroy() {
    if (this.helpStepSubscription)
          this.helpStepSubscription.unsubscribe();
    // if(this.dataService.accountSourceUpdated)
    //       this.dataService.accountSourceUpdated.unsubscribe();
  }

  ccRefresh()
  {
    var myframe = document.querySelector('iframe[id="pciIFrameCC"]');
    (<HTMLIFrameElement> myframe).src += '';
  }

  achRefresh()
  {
       var myframe = document.querySelector('iframe[id="pciIFrame"]');
       (<HTMLIFrameElement> myframe).src += '';
    }

  focusOutACH(event: any)
  {
    var clicked = event.target;
    var currentID = clicked.id
    if(currentID=='addACHModal')
       this.achRefresh();
  }

  focusOutCC(event: any)
  {
    var clicked = event.target;
    var currentID = clicked.id
    if(currentID=='addCCModal')
       this.ccRefresh();
  }

  getPCICCForm(newOrEdit: string) {
    let element = $("#pciIFrameCC").contents().find("#emptyForm");
    if(element) {
      if( window.location.hostname.indexOf("localhost")!=-1) {
        if('8880' === window.location.port) {
          // Ensure we use HTTPS for non-local environments
            element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
            element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateCC";
            element[0].sourceHostname.value = window.location.protocol + "//" + window.location.hostname + ":8880";
            // console.log('(1) element[0] is', element[0]);
          }
      }  else {
        element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
        element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateCC";
        element[0].sourceHostname.value = window.location.protocol + "//" + window.location.hostname;
        // console.log('(2) element[0] is', element[0]);
      }

      element[0].accountId.value = this.dataService.getAccountSource().id;
      element[0].caNumber.value = (this.nonNullContractNumberForAccount);

      if(newOrEdit == "new") {

        if(this.onManagePaymentMethods){
          element[0].componentOrigin.value = "newPaymentMethod";
        }
        else{
          element[0].componentOrigin.value = "makePayment";
        }
        element[0].paymentType.value = "CARD";
      }


      if('8880' === window.location.port) {
        element[0].loginField.value = "admin";  //only for localhost when we cannot retrieve
        $(element).submit();
      }
      else {
          this.portalService.authenticate().subscribe((auth) => {
            element[0].loginField.value = auth;
            $(element).submit();
          }, err => {
            console.error(err);
            $(element).submit();
            console.error("Could not retrieve username from portal service");
          });
        }

    }
  }

  getPCIForm(newOrEdit: string) {
    let element = $("#pciIFrame").contents().find("#emptyForm");
    if(element) {
      if('8880' === window.location.port) {
      // Ensure we use HTTPS for non-local environments
        element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
        element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateACH";
        element[0].hostnameField.value = window.location.protocol + "//" + window.location.hostname + ":8880";
        // console.log('(1) element[0] is', element[0]);
      } else {
        element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
        element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateACH";
        element[0].hostnameField.value = window.location.protocol + "//" + window.location.hostname;
        // console.log('(2) element[0] is', element[0]);
      }

      element[0].idField.value = this.dataService.getAccountSource().id;
      element[0].caNumber.value = (this.nonNullContractNumberForAccount);

      //element[0].cancelField.value = false;

      if(newOrEdit == "new") {
        if(this.onManagePaymentMethods){
          element[0].originField.value = "newPaymentMethod";
        }
        else{
        element[0].originField.value = "makePayment";
      }
      element[0].paymentType.value = "BANKACCOUNT";
    }


      if('8880' === window.location.port) {
        element[0].loginField.value = "admin";  //only for localhost when we cannot retrieve
        $(element).submit();
      }
      else {
          this.portalService.authenticate().subscribe((auth) => {
            element[0].loginField.value = auth;
            $(element).submit();
          }, err => {
            $(element).submit();
            console.error(err);
            console.error("Could not retrieve username from portal service");
          });
        }
    }
  }

  saveOptionChanged() {
    this.paymentSchedule.savePaymentMethod = this.savingRadio;
    this.paymentsService.setPaymentSchedule(this.paymentSchedule);
  }

  nicknameChanged() {
    this.paymentSchedule.paymentMethod.nickName = this.methodNickname;
    this.paymentsService.setPaymentSchedule(this.paymentSchedule);
  }

  setDefault() {
    if(this.paymentSchedule.paymentMethod.defaultPayment == 1) {
      this.paymentSchedule.paymentMethod.defaultPayment = 0;
    } else {
      this.paymentSchedule.paymentMethod.defaultPayment = 1;
    }
    this.paymentsService.setPaymentSchedule(this.paymentSchedule);
  }

  setPayDate() {
    this.showDateError = false;
    if(moment(moment(this.selectedPayDate).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))){
      this.showDateError = true;
    }
    this.paymentSchedule.paymentDate = this.selectedPayDate;
    this.paymentsService.setPaymentSchedule(this.paymentSchedule);
  }

  onDateChange() {
    // Unselecting radio
    this.selectedPaymentMethod = null;
  }

  selectMethod( method: IPaymentMethod) {
    this.methodNickname =  method.nickName;

    if(this.onManagePaymentMethods){
      if(method.userPaymentSettingsKey.paymentToken)
            this.selectedPaymentMethod  = method.userPaymentSettingsKey.paymentToken.toString() + method.rememberPayment;
      else
            this.selectedPaymentMethod = "";
    }
    else {
      if(!this.paymentSchedule) {
        let schedule: IPaymentSchedule = {
          paymentDate: new Date(),
          savePaymentMethod: true,
          paymentMethod: method
        };
        this.paymentSchedule = schedule;
      }
      this.paymentSchedule.paymentMethod = method;
      this.paymentSchedule.paymentMethod.methodType = "existing";
      this.paymentSchedule.paymentMethod.paymentType = method.paymentType;
      this.paymentSchedule.paymentMethod.nickName = method.nickName;
      this.paymentsService.setPaymentSchedule(this.paymentSchedule);

      if(method.userPaymentSettingsKey.paymentToken)
            this.selectedPaymentMethod  = method.userPaymentSettingsKey.paymentToken.toString() + method.rememberPayment;
      else
            this.selectedPaymentMethod = "";
       }
    this.editingNew = false;
  }

  selectMethodFromManage( method: IPaymentMethod) {
    if(!this.onManagePaymentMethods){
      this.paymentSchedule.paymentMethod = method;
      this.paymentSchedule.paymentMethod.methodType = "manage";
      this.paymentsService.setPaymentSchedule(this.paymentSchedule);
      this.editing = false;
    }
  }

  isNCCA() {
    return this.paymentsService.getNCCA();
  }

  isBankAccount(name) {
    if(name.paymentType=== 'BANKACCOUNT')
        return true;
  }

  isCreditCard(name) {
    if(name.paymentType=== 'CARD')
        return true;
  }

  isScheduledPayment() {
    // Get Tommorrow's Date
    const currentDate: Date = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    if(this.selectedPayDate >= currentDate) {
      return true;
    }
  }


  isScheduledPaymentCC(name) {
    if(name.paymentType=== 'CARD') {
      // Get Tommorrow's Date
      const currentDate: Date = new Date();
      currentDate.setDate(currentDate.getDate() + 1);

      if(this.selectedPayDate >= currentDate) {
        return true;
      }
    }
  }

  updateAddPermissions(){
    if(this.onManagePaymentMethods == false){
      this.showBankAccount = true;

      let invoices = this.paymentsService.getSelectedBalances();
      invoices.forEach((invoice) =>{
        if(invoice.ncaStatus == 'X' || invoice.ncaStatus != null){
          this.showBankAccount = false;
        }
      })
    }
    this.totalSites = this.dataService.getSitesForAccountSource();

    //console.log(this.totalSites.length);

      if(this.totalSites){
        //loop thru, find first non null ca
          if(this.onManagePaymentMethods)
             this.paymentsService.setNCCA(true);

          for(let i = 0; i < this.totalSites.length; i ++)
          {
            let currSite = this.totalSites[i];
            if(currSite.contractAccountNum != '' || currSite.contractAccountNum){
              if(this.onManagePaymentMethods){
                this.showBankAccount = true;
              }
              this.nonNullContractNumberForAccount = currSite.contractAccountNum.split('-')[0];
              if(this.onManagePaymentMethods && currSite.nccaStatus !== 'X'){
                this.paymentsService.setNCCA(false);
                this.canPayWithCC = true;
                break;
              }
            }
          }
          if(this.onManagePaymentMethods && this.showCreditCard && this.paymentsService.getNCCA()) {
              this.canPayWithCC = false;
          }
      }

  }

  updateEditPermissions(){
    this.allowedToEditPaymentMethods = this.portalService.hasPermission(Permissions.EditPaymentMethods) ||
    this.portalService.hasPermission(Permissions.Admin) ||
    this.portalService.userHasAuthority(Authority.SuperUser);
  }

  clickedNew() {
      let key: IPaymentSettingsKey = {
        paymentToken: null,
        userId: null,
        accountId: null
      };
      let method: IPaymentMethod = {
        defaultPayment: 1,
        nickName: "",
        oldPaymentToken: "",
        routingNumber: "-1",
        maskedAccountNumber: null,
        paymentType: null,
        methodType: "new",
        userPaymentSettingsKey: key,
        maskedCardNumber: null,
        cardExpiryMonth: null,
        cardExpiryYear: null,
        cvv: null,
        cardZipCode: null,
        rememberPayment: 'true',
      };

      if(!this.paymentSchedule) {
        let schedule: IPaymentSchedule = {
          paymentDate: new Date(),
          savePaymentMethod: true,
          paymentMethod: method
        };
        this.paymentSchedule = schedule;
      }
      else {
          this.paymentSchedule.paymentMethod = method;
          this.paymentSchedule.paymentMethod.methodType = "new";
          this.editingNew = true;
          this.paymentsService.setPaymentSchedule(this.paymentSchedule);
      }
    }

  validPayment() {
    if(this.editingNew)
       return true;
    else
      return false;
  }

  isExpired(method: IPaymentMethod) {
    const dateSel = new Date(method.cardExpiryMonth + '/' + '01' + '/' +  method.cardExpiryYear);
    const dateCurrent = new Date();
    if(dateSel > dateCurrent) {
      return false;
    }
    return true;
  }

  isExpiring(method: IPaymentMethod) {
    const dateSel = new Date(method.cardExpiryMonth + '/' + '01' + '/' +  method.cardExpiryYear);
    const current = new Date();
    const today = new Date();
    current.setMonth(current.getMonth() + 4);
    const dateCurrent = new Date( current.getMonth() + "/" + '01' + '/' + current.getFullYear());
    if(dateSel>today && dateSel < dateCurrent) {
      return true;
    }
    return false;
  }

  enterNew(paymentTypeSel) {
      if(paymentTypeSel==='ACH')
      {
       this.getPCIForm('new');
        setTimeout(
          () => {
            $('#addACHModal').modal({backdrop: 'static', keyboard: false},'show')
          }, 400
        )
      }
      else  if(paymentTypeSel==='CC')
      {
        this.getPCICCForm('new');
        setTimeout(
          () => {
            $('#addCCModal').modal({backdrop: 'static', keyboard: false},'show')
          }, 400
        )

      }
      let element = $("#pciIFrameCC").contents().find("#emptyForm");
  }


  selectDefault() {
    this.paymentMethods.forEach((method)=> {
      if(method.defaultPayment){
        this.selectMethod(method);
      }
    });
  }

  isRememberPayment(name : IPaymentMethod) : boolean {
    // console.log(name.rememberPayment);
    return (name.rememberPayment != 'false');
  }


  save(isEdit) {
    if(isEdit)
      this.editing = true;
    else
      this.editing = false;
    //console.log("Save >>> ", this.paymentSchedule.paymentMethod.nickName)
    this.paymentsService.savePaymentNickname(this.paymentSchedule.paymentMethod);
  }


  editClicked(name, updatedName) {
    this.editing = true;
    this.editName = name;
    this.updatedName = updatedName;
    this.selectedPaymentMethod  = name.userPaymentSettingsKey.paymentToken.toString() + name.rememberPayment;
    $('#editNameModal').modal('show');
  }


  isSelectedPayment(paymentTypeSel)
  {
    if(this.selectedPaymentMethod === null || this.selectedPaymentMethod === ""){
      return false;
    }
     if(!this.paymentSchedule && (paymentTypeSel && paymentTypeSel.userPaymentSettingsKey.paymentToken)
      && this.selectedPaymentMethod)
     {
       return (paymentTypeSel.userPaymentSettingsKey.paymentToken.toString() + paymentTypeSel.rememberPayment)===this.selectedPaymentMethod;
     }
     else if(this.paymentSchedule && this.paymentSchedule.paymentMethod
       && this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken)
     {
        if( paymentTypeSel
          && this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken==paymentTypeSel.userPaymentSettingsKey.paymentToken)
        {
              this.selectedPaymentMethod = this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken.toString() + this.paymentSchedule.paymentMethod.rememberPayment;
        }
        return (this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken + this.paymentSchedule.paymentMethod.rememberPayment)===(paymentTypeSel.userPaymentSettingsKey.paymentToken.toString() + paymentTypeSel.rememberPayment);
     }
     else {
        return false;
      }

  }


  editNickName() {
    if(this.isEditNameValid())
     {
       this.editName.nickName = this.updatedName;
       //this.paymentSchedule.paymentMethod.nickName = this.editName.nickName;
       this.paymentsService.savePaymentNickname(this.editName).subscribe(
        () => { }
       );
      }
     else
     {
        console.log("Invalid nickname");
     }

  }

  cancelNickName() {
    this.editing=false;
    this.editName = null;
  }

  isEditNameValid()
  {
    var regularExpression= new RegExp('^.*[-!$%^&*()_+|~=`{}[\\]:";\'<>?,.\/]+.*$');
  //  console.log("isEditNameValid: ",regularExpression.test(this.updatedName));
  //  if(this.updatedName &&  (this.updatedName.replace(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"), '') == '')){

    if(this.updatedName=='' || (this.updatedName && (!regularExpression.test(this.updatedName)))) {
      return true;
    }
    else {
       return false;
    }
  }

  deleteAutopay(){
    $('#failedCancelModal').modal('show');
   }

  deletePaymentMethod(method: IPaymentMethod) {
    this.showAutopayErrorModal = false;
    if(method.rememberPayment != 'false'){
      this.paymentsService.deletePaymentMethod(method).subscribe(
         (success) => {
           if(success) {
              if(this.selectedPaymentMethod == method.userPaymentSettingsKey.paymentToken.toString() + method.rememberPayment){
                this.paymentsService.setStepReached(2);
                this.selectedPaymentMethod = null;
                this.newPaymentAdded = false;
                this.paymentSchedule = null;
                this.paymentsService.setPaymentSchedule(this.paymentSchedule);
              }
              this.paymentSchedule = null;
              let newPaymentMethodList : IPaymentMethod[] = [];
              for (let i = 0; i < this.paymentMethods.length; i++) {
                let currMethod = this.paymentMethods[i];
                if (currMethod != method) {
                  newPaymentMethodList.push(currMethod);
                }
              }
              this.paymentMethods = newPaymentMethodList;
              if(this.paymentMethods.length == 0 || (this.paymentMethods.length == 1 && this.paymentMethods.indexOf(method) != -1)){
                this.paymentsService.setStepReached(2);
                this.selectedPaymentMethod = null;
                this.newPaymentAdded = false;
                this.paymentSchedule = null;
                this.paymentsService.setPaymentSchedule(this.paymentSchedule);
              }
           }
           else //show msg that the payment method is used in AutoPay
           {
              this.showAutopayErrorModal = true;
              $('#failedCancelModal').modal('show');
           }
        }, err => {
          console.error(err);
        }
      )
  }
  else {
    this.newPaymentAdded = false;
    let newPaymentMethodList : IPaymentMethod[] = [];
    for(let i = 0; i < this.paymentMethods.length; i ++){
      let currMethod = this.paymentMethods[i];
      if(currMethod != method){
        newPaymentMethodList.push(currMethod);
      }
    }
    this.paymentSchedule = null;
    this.paymentsService.setPaymentSchedule(this.paymentSchedule);
    this.paymentMethods = newPaymentMethodList;
  }

  if(this.paymentMethods.length == 0){
    this.paymentsService.setStepReached(2);
    this.selectedPaymentMethod = null;
    this.newPaymentAdded = false;
    this.paymentSchedule = null;
    this.paymentsService.setPaymentSchedule(this.paymentSchedule);
  }

  }

  cancel() {
    this.editing = false;
  }

  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(paymentType == 'CARD'){
      if(accountNum.length >= 4){
        return accountNum.substring(accountNum.length-4);
      }
    }
    else{
      if(accountNum.indexOf('-') != -1){
        return accountNum.split('-')[1];
      }

    }

    return 'XXXXXXXXXX';


  }

  getPaymentMethods() {
    this.paymentsService.getPaymentMethods(this.dataService.getAccountSource().id).subscribe(
      (methods) => {
        this.paymentMethods = methods;
        if(!this.onManagePaymentMethods) {
          let found = false;
          let temp = [];
          let i = 0;
          this.paymentMethods.forEach((method)=> {
              if(method.paymentType=='BANKACCOUNT' || (!this.paymentsService.getNCCA() && method.paymentType=='CARD')) {
                 temp[i] = method;
                 if(this.paymentSchedule && method.userPaymentSettingsKey.paymentToken===this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken)
                 {  found = true; }
                 i++;

              }
          });
          if(!found && this.paymentSchedule) //new payment type that was not saved in DB by user
            {
              temp[i] = this.paymentSchedule.paymentMethod;
            }
          this.paymentMethods = temp;
        }
        this.selectDefault();
        if(!this.paymentMethods || this.paymentMethods.length===0) {
            this.newPaymentAdded = false;
          }
      }
    );
  }

  closeFailedCancel() {

  }

  gotoAutopay() {
    this.router.navigate(['/autopayments/autopay-select-balances']);
  }
}
