export const ContextualHelpDev = {
  // helpManageSiteGroupsUrl: '/private/api/help/contextualHelp/manage-site-groups.nrgcontent/',
  // helpViewEditSiteGroupsUrl: './private/api/help/contextualHelp/view-edit-site-groups.nrgcontent/',
  // helpDashboardUrl: './private/api/help/contextualHelp/dashboard.nrgcontent/',
  // helpBillingSummaryUrl: './private/api/help/contextualHelp/billing-summary.nrgcontent/',
  // helpBillingComparisonUrl: './private/api/contextualHelp/help/billing-comparison.nrgcontent/',
  // helpBillingUsageUrl: './private/api/help/contextualHelp/billing-usage-report.nrgcontent/',
  // helpSelectBalancesUrl: './private/api/help/contextualHelp/select-balances.nrgcontent/',
  // helpPaymentInfoUrl: './private/api/help/contextualHelp/payment-info.nrgcontent/',
  // helpReviewPaymentUrl: './private/api/help/contextualHelp/review-payment.nrgcontent/',
  // helpPaymentConfirmationUrl: './private/api/help/contextualHelp/payment-confirmation.nrgcontent/',
  // helpPaymentHistoryUrl: './private/api/help/contextualHelp/payment-history.nrgcontent/',
  // helpManagePaymentMethodsUrl: './private/api/help/contextualHelp/manage-payment-methods.nrgcontent/',
  // helpUsageSummaryUrl: './private/api/help/contextualHelp/usage-summary.nrgcontent/',
  // helpUsageComparisonUrl: './private/api/help/contextualHelp/usage-comparison.nrgcontent/',
  // helpSelectSitesUrl: './private/api/help/contextualHelp/select-sites.nrgcontent/',
  // helpSelectReportSettingsUrl: './private/api/help/contextualHelp/select-report-settings.nrgcontent/',
  // helpUsageIntervalGraphUrl: './private/api/help/contextualHelp/usage-interval-graph.nrgcontent/',
  // helpContractsUrl: './private/api/help/contextualHelp/contracts.nrgcontent/',
  // helpProfileUrl: './private/api/help/contextualHelp/profile.nrgcontent/',
  // helpAddRemoveSitesUrl: './private/api/help/contextualHelp/add-remove-sites.nrgcontent/',
  // helpAddDeleteHistoryUrl: './private/api/help/contextualHelp/add-delete-history.nrgcontent/',
  // helpFourcpOverviewUrl: './private/api/help/contextualHelp/fourcp-overview.nrgcontent/',
  // helpFourcpAlertHistoryUrl: './private/api/help/contextualHelp/fourcp-alert-history.nrgcontent/',
  // helpPricingQuoteListUrl: './private/api/help/contextualHelp/pricing-quote-list.nrgcontent/',
  // helpPricingQuoteContactUrl: './private/api/help/contextualHelp/pricing-quote-contact.nrgcontent/',
  // helpPricingQuoteContractsUrl: './private/api/help/contextualHelp/pricing-quote-contracts.nrgcontent/',
  // helpPricingQuoteCustomersUrl: './private/api/help/contextualHelp/pricing-quote-customers.nrgcontent/',
  // helpPricingQuotePricingUrl: './private/api/help/contextualHelp/pricing-quote-pricing.nrgcontent/',
  // helpPricingQuoteSitesUrl: './private/api/help/contextualHelp/pricing-quote-sites.nrgcontent/',
  // helpSustainabilityOverviewUrl: './private/api/help/contextualHelp/sustainability-page.nrgcontent/',
  // helpDistributedGenerationUrl: './private/api/help/contextualHelp/distributed-generation-sites.nrgcontent/',
  // helpOnsiteGenerationUrl: './private/api/help/contextualHelp/onsite-generation.nrgcontent/',
  // helpRealtimeUsageUrl: './private/api/help/contextualHelp/realtime-usage.nrgcontent/'
  helpManageSiteGroupsUrl: './private/api/help/contextualHelp/site-groups.nrgcontent/',
  helpViewEditSiteGroupsUrl: './private/api/help/contextualHelp/add-edit-site-groups.nrgcontent/',
  helpDashboardUrl: './private/api/help/contextualHelp/dashboard.nrgcontent/',
//  helpDashboardUrl: 'https://stg-www.nrg.com/portal/help-topics/dashboard.nrgcontent/',
  helpBillingSummaryUrl: './private/api/help/contextualHelp/billing-summary-report.nrgcontent/',
  helpBillingComparisonUrl: './private/api/help/contextualHelp/billing-comparison-report.nrgcontent/',
  helpBillingUsageUrl: './private/api/help/contextualHelp/billing-usage-report.nrgcontent/',
  helpSelectBalancesUrl: './private/api/help/contextualHelp/select-balances.nrgcontent/',
  helpPaymentInfoUrl: './private/api/help/contextualHelp/select-payment-information.nrgcontent/',
  helpReviewPaymentUrl: './private/api/help/contextualHelp/review-payment.nrgcontent/',
  helpPaymentConfirmationUrl: './private/api/help/contextualHelp/payment-confirmation.nrgcontent/',
  helpPaymentHistoryUrl: './private/api/help/contextualHelp/payment-history.nrgcontent/',
  helpManagePaymentMethodsUrl: './private/api/help/contextualHelp/manage-payment-methods.nrgcontent/',
  helpUsageSummaryUrl: './private/api/help/contextualHelp/usage-summary-report.nrgcontent/',
  helpUsageComparisonUrl: './private/api/help/contextualHelp/usage-comparison-report.nrgcontent/',
  helpSelectSitesUrl: './private/api/help/contextualHelp/select-sites.nrgcontent/',
  helpSelectReportSettingsUrl: './private/api/help/contextualHelp/select-report-settings.nrgcontent/',
  helpUsageIntervalGraphUrl: './private/api/help/contextualHelp/usage-interval-graph.nrgcontent/',
  helpContractsUrl: './private/api/help/contextualHelp/contracts.nrgcontent/',
  helpProfileUrl: './private/api/help/contextualHelp/profile.nrgcontent/',
  helpAddRemoveSitesUrl: './private/api/help/contextualHelp/add-remove-sites.nrgcontent/',
  helpAddDeleteHistoryUrl: './private/api/help/contextualHelp/add-delete-history-.nrgcontent/',
  helpFourcpOverviewUrl: './private/api/help/contextualHelp/4cp-dashboard.nrgcontent/ ',
  helpFourcpAlertHistoryUrl: './private/api/help/contextualHelp/4cp-dashboard-alert-history.nrgcontent/',
  helpPricingQuoteListUrl: './private/api/help/contextualHelp/pricing-quote-list.nrgcontent/',
  helpPricingQuoteContactUrl: './private/api/help/contextualHelp/pricing-quote-contact.nrgcontent/',
  helpPricingQuoteContractsUrl: './private/api/help/contextualHelp/pricing-quote-contracts.nrgcontent/',
  helpPricingQuoteCustomersUrl: './private/api/help/contextualHelp/pricing-quote-customers.nrgcontent/',
  helpPricingQuotePricingUrl: './private/api/help/contextualHelp/pricing-quote-pricing.nrgcontent/',
  helpPricingQuoteSitesUrl: './private/api/help/contextualHelp/pricing-quote-sites.nrgcontent/',
  helpSustainabilityOverviewUrl: './private/api/help/contextualHelp/sustainability-page.nrgcontent/',
  helpDistributedGenerationUrl: './private/api/help/contextualHelp/distributed-generation-sites.nrgcontent/',
  helpOnsiteGenerationUrl: './private/api/help/contextualHelp/onsite-generation.nrgcontent/',
  helpRealtimeUsageUrl: './private/api/help/contextualHelp/realtime-usage.nrgcontent/'

};

export const ContextualHelpStage = {
  helpManageSiteGroupsUrl: './private/api/help/contextualHelp/site-groups.nrgcontent/',
  helpViewEditSiteGroupsUrl: './private/api/help/contextualHelp/add-edit-site-groups.nrgcontent/',
  helpDashboardUrl: './private/api/help/contextualHelp/dashboard.nrgcontent/',
//  helpDashboardUrl: 'https://stg-www.nrg.com/portal/help-topics/dashboard.nrgcontent/',
  helpBillingSummaryUrl: './private/api/help/contextualHelp/billing-summary-report.nrgcontent/',
  helpBillingComparisonUrl: './private/api/help/contextualHelp/billing-comparison-report.nrgcontent/',
  helpBillingUsageUrl: './private/api/help/contextualHelp/billing-usage-report.nrgcontent/',
  helpSelectBalancesUrl: './private/api/help/contextualHelp/select-balances.nrgcontent/',
  helpPaymentInfoUrl: './private/api/help/contextualHelp/select-payment-information.nrgcontent/',
  helpReviewPaymentUrl: './private/api/help/contextualHelp/review-payment.nrgcontent/',
  helpPaymentConfirmationUrl: './private/api/help/contextualHelp/payment-confirmation.nrgcontent/',
  helpPaymentHistoryUrl: './private/api/help/contextualHelp/payment-history.nrgcontent/',
  helpManagePaymentMethodsUrl: './private/api/help/contextualHelp/manage-payment-methods.nrgcontent/',
  helpUsageSummaryUrl: './private/api/help/contextualHelp/usage-summary-report.nrgcontent/',
  helpUsageComparisonUrl: './private/api/help/contextualHelp/usage-comparison-report.nrgcontent/',
  helpSelectSitesUrl: './private/api/help/contextualHelp/select-sites.nrgcontent/',
  helpSelectReportSettingsUrl: './private/api/help/contextualHelp/select-report-settings.nrgcontent/',
  helpUsageIntervalGraphUrl: './private/api/help/contextualHelp/usage-interval-graph.nrgcontent/',
  helpContractsUrl: './private/api/help/contextualHelp/contracts.nrgcontent/',
  helpProfileUrl: './private/api/help/contextualHelp/profile.nrgcontent/',
  helpAddRemoveSitesUrl: './private/api/help/contextualHelp/add-remove-sites.nrgcontent/',
  helpAddDeleteHistoryUrl: './private/api/help/contextualHelp/add-delete-history-.nrgcontent/',
  helpFourcpOverviewUrl: './private/api/help/contextualHelp/4cp-dashboard.nrgcontent/ ',
  helpFourcpAlertHistoryUrl: './private/api/help/contextualHelp/4cp-dashboard-alert-history.nrgcontent/',
  helpPricingQuoteListUrl: './private/api/help/contextualHelp/pricing-quote-list.nrgcontent/',
  helpPricingQuoteContactUrl: './private/api/help/contextualHelp/pricing-quote-contact.nrgcontent/',
  helpPricingQuoteContractsUrl: './private/api/help/contextualHelp/pricing-quote-contracts.nrgcontent/',
  helpPricingQuoteCustomersUrl: './private/api/help/contextualHelp/pricing-quote-customers.nrgcontent/',
  helpPricingQuotePricingUrl: './private/api/help/contextualHelp/pricing-quote-pricing.nrgcontent/',
  helpPricingQuoteSitesUrl: './private/api/help/contextualHelp/pricing-quote-sites.nrgcontent/',
  helpSustainabilityOverviewUrl: './private/api/help/contextualHelp/sustainability-page.nrgcontent/',
  helpDistributedGenerationUrl: './private/api/help/contextualHelp/distributed-generation-sites.nrgcontent/',
  helpOnsiteGenerationUrl: './private/api/help/contextualHelp/onsite-generation.nrgcontent/',
  helpRealtimeUsageUrl: './private/api/help/contextualHelp/realtime-usage.nrgcontent/'
};

export const ContextualHelpProd = {
  helpManageSiteGroupsUrl: './private/api/help/contextualHelp/site-groups.nrgcontent/',
  helpViewEditSiteGroupsUrl: './private/api/help/contextualHelp/add-edit-site-groups.nrgcontent/',
  helpDashboardUrl: './private/api/help/contextualHelp/dashboard.nrgcontent/',
  helpBillingSummaryUrl: './private/api/help/contextualHelp/billing-summary-report.nrgcontent/',
  helpBillingComparisonUrl: './private/api/help/contextualHelp/billing-comparison-report.nrgcontent/',
  helpBillingUsageUrl: './private/api/help/contextualHelp/billing-usage-report.nrgcontent/',
  helpSelectBalancesUrl: './private/api/help/contextualHelp/select-balances.nrgcontent/',
  helpPaymentInfoUrl: './private/api/help/contextualHelp/select-payment-information.nrgcontent/',
  helpReviewPaymentUrl: './private/api/help/contextualHelp/review-payment.nrgcontent/',
  helpPaymentConfirmationUrl: './private/api/help/contextualHelp/payment-confirmation.nrgcontent/',
  helpPaymentHistoryUrl: './private/api/help/contextualHelp/payment-history.nrgcontent/',
  helpManagePaymentMethodsUrl: './private/api/help/contextualHelp/manage-payment-methods.nrgcontent/',
  helpUsageSummaryUrl: './private/api/help/contextualHelp/usage-summary-report.nrgcontent/',
  helpUsageComparisonUrl: './private/api/help/contextualHelp/usage-comparison-report.nrgcontent/',
  helpSelectSitesUrl: './private/api/help/contextualHelp/select-sites.nrgcontent/',
  helpSelectReportSettingsUrl: './private/api/help/contextualHelp/select-report-settings.nrgcontent/',
  helpUsageIntervalGraphUrl: './private/api/help/contextualHelp/usage-interval-graph.nrgcontent/',
  helpContractsUrl: './private/api/help/contextualHelp/contracts.nrgcontent/',
  helpProfileUrl: './private/api/help/contextualHelp/profile.nrgcontent/',
  helpAddRemoveSitesUrl: './private/api/help/contextualHelp/add-remove-sites.nrgcontent/',
  helpAddDeleteHistoryUrl: './private/api/help/contextualHelp/add-delete-history-.nrgcontent/',
  helpFourcpOverviewUrl: './private/api/help/contextualHelp/4cp-dashboard.nrgcontent/ ',
  helpFourcpAlertHistoryUrl: './private/api/help/contextualHelp/4cp-dashboard-alert-history.nrgcontent/',
  helpPricingQuoteListUrl: './private/api/help/contextualHelp/pricing-quote-list.nrgcontent/',
  helpPricingQuoteContactUrl: './private/api/help/contextualHelp/pricing-quote-contact.nrgcontent/',
  helpPricingQuoteContractsUrl: './private/api/help/contextualHelp/pricing-quote-contracts.nrgcontent/',
  helpPricingQuoteCustomersUrl: './private/api/help/contextualHelp/pricing-quote-customers.nrgcontent/',
  helpPricingQuotePricingUrl: './private/api/help/contextualHelp/pricing-quote-pricing.nrgcontent/',
  helpSustainabilityOverviewUrl: './private/api/help/contextualHelp/sustainability-page.nrgcontent/',
  helpDistributedGenerationUrl: './private/api/help/contextualHelp/distributed-generation-sites.nrgcontent/',
  helpOnsiteGenerationUrl: './private/api/help/contextualHelp/onsite-generation.nrgcontent/',
  helpRealtimeUsageUrl: './private/api/help/contextualHelp/realtime-usage.nrgcontent/'
};
