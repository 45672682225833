import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { DataService } from '../../../shared/data.service';
import { PortalService } from '../../../shared/portal.service';
import { IRenewableSite } from '../../renewable-site';
import { RenewablesService } from '../../renewables.service';
import { SiteSelectorComponent } from '../site-selector/site-selector.component';
import { texasMap } from './texas-map';

declare var Highcharts: any;

@Component({
  selector: 'app-state-sites-map',
  templateUrl: './state-sites-map.component.html',
  styleUrls: ['./state-sites-map.component.scss']
})
export class StateSitesMapComponent implements OnInit, OnDestroy {
  sites: IRenewableSite[];
  flipSettings = false;
  siteSelectionError: string;
  private mapChart: any;
  private readonly unsubscribe$ = new Subject();
  maskModeSubscription: Subscription;
  maskMode = false;

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;
  @ViewChild(SiteSelectorComponent, {static : false}) siteSelector: SiteSelectorComponent;

  constructor(private renewablesService: RenewablesService,
    private dataService: DataService,
    private portalService: PortalService) { }

  ngAfterViewInit(){
    this.spinner.show('Loading Sites...');
  }
  ngOnInit() {
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    this.initializeMap();

    this.renewablesService.sites
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.sites = sites;
        this.updateMapSites();
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  flip() {
    this.flipSettings = !this.flipSettings;
    this.siteSelectionError = null;
    this.siteSelector.refreshDisplayedSites(this.maskMode, this.portalService);
  }

  apply() {
    if (this.siteSelector.selectedSiteIds.length === 0) {
      this.siteSelectionError = 'Please select at least one site';
      return;
    }

    let sitesUpdated = false;
    this.sites.forEach(site => {
      const selected = this.siteSelector.selectedSiteIds.includes(site.id);
      if (site.selected !== selected) {
        sitesUpdated = true;
      }
      site.selected = selected;
    });

    // Only update sites if a change was made
    if (sitesUpdated) {
      this.renewablesService.updateSites(this.sites);
    }
    this.flip();
  }

  private initializeMap() {
    const parent = this;
    this.mapChart = Highcharts.mapChart('map-container', {
      title: undefined,
      credits: {
        enabled: false
      },
      mapNavigation: {
        enabled: false
      },
      tooltip: {
        distance: 1,
        formatter: function () {
          const site = this.point as IRenewableSite;
          let output = '<b>';
          if (parent.maskMode) {
            output += parent.portalService.getFakeSiteName(site)  + ' (' + site.capacity + ' MW)</b><br>';
            output += parent.portalService.getFakeStreetAddress(site);
          } else {
            output += site.name + ' (' + site.capacity + ' MW)</b><br>';
            output += site.serviceAddress;
          }
          return output;
        }
      },
      series: [{
        name: 'Basemap',
        mapData: texasMap,
        showInLegend: false
      }, {
        type: 'mappoint',
        dataLabels: {
          enabled: true,
          formatter: () => {
            if (!this.sites || this.sites.filter(site => site.selected).length !== 1) {
              return '';
            }
            return this.sites.filter(site => site.selected)[0].name;
          },
          allowOverlap: true,
          useHTML: true
        },
        marker: {
          radius: 6
        },
        showInLegend: false,
        data: []
      }]
    });
  }

  private updateMapSites() {
    const selectedSites = this.sites.filter(site => site.selected).map(site => {
      if (this.maskMode) {
        this.portalService.getFakeStreetAddress(site);
        this.portalService.getFakeSiteName(site);
        this.portalService.getFakeSiteId(site);
      }
      return site;
    });

    this.mapChart.series[1].setData(selectedSites);
  }

  get selectedSitesCount(): number {
    if (this.sites) {
      return this.sites.filter(site => site.selected).length;
    }
    return 0;
  }
}
