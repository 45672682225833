import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class UtilsService {
  constructor() {}
  /**
   * deep clones an object using the lodash library
   */
  clone(object: any) {
    return _.cloneDeep(object);
  }

  getTitleCaseWord(str: string) {
    const first = str.slice(0, 1).toUpperCase();
    const remaining = str.slice(1).toLowerCase();
    return first.concat(remaining);
  }
  // returns standard timezone offset
  getStdTimezoneOffset() {
    const jan = new Date(new Date().getFullYear(), 0, 1);
    const jul = new Date(new Date().getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  // daylight timezone offset is 1 hour less than standard timezone offset
  isDstObserved() {
    return new Date().getTimezoneOffset() < this.getStdTimezoneOffset();
  }
}
