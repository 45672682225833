import { Component, Input, OnInit } from '@angular/core';
import { I4cpSummaryPerformance } from '../../../shared/entities/fourcp';
import * as moment from 'moment';

@Component({
  selector: 'app-fourcp-summary-performance-peak-cards',
  templateUrl: './fourcp-summary-performance-peak-cards.component.html',
  styleUrls: ['./fourcp-summary-performance-peak-cards.component.scss', '../fourcp-summary-performance.component.scss']
})
export class FourcpSummaryPerformancePeakCardsComponent implements OnInit {

  @Input()
  selectedYear: number;

  @Input()
  summaryPerformance: I4cpSummaryPerformance | I4cpSummaryPerformance;

  constructor() { }

  ngOnInit() {
  }

  getMonthDisplay(intervalPeriod: string) {
    return moment(intervalPeriod, 'MM/DD/YYYY HH:mm').format('MMMM');
  }

  getIntervalDisplay(intervalPeriod: string) {
    return moment(intervalPeriod, 'MM/DD/YYYY HH:mm').format('ddd, M/DD/YY HH:mm') + ' CST';
  }

}
