import { Component, OnInit } from '@angular/core';
import {ISite, ISiteGroup} from "../../shared/entities/site";
import {DataService} from "../../shared/data.service";
import {SiteGroupService} from "../site-groups.service";
import {SitePipe} from "../../shared/pipe/site.pipe";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {Router} from "@angular/router";
import {PortalService} from "../../shared/portal.service";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {Subscription} from "rxjs";
import {Permissions} from "../../shared/const/permissions";
import {FakerService} from "../../shared/faker.service";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {IUserAccount} from "../../shared/entities/profile";

declare var $: any;

@Component({
  selector: 'app-view-edit-site-group',
  templateUrl: './view-edit-site-group.component.html',
  styleUrls: ['view-edit-site-group.component.scss', '../site-groups.component.scss'],
  animations: [FadeInAnimation],
  providers: [SitePipe, OrderByPipe]
})
export class ViewEditSiteGroupComponent implements OnInit {

  myAccount: IUserAccount;
  selectedGroup: ISiteGroup;
  isSystem: boolean;
  sites: ISite[];
  filteredSites: ISite[];
  numOfRowsToShow = 10000;
  editingName: boolean = false;
  editingDescription: boolean = false;
  allowedToEditGroups: boolean = false;
  maskMode: boolean;
  sortBy: string = 'category';
  ascending: boolean = false;
  saving: boolean = false;
  groupNameError: boolean = false;
  groupNameRow: string = '';
  showSearchRow: boolean = false;
  searchTerm: string = '';
  categoryOptions: string[] = [];
  typeaheadOptions: any[] = [];
  typeaheadId: number;
  typeaheadIndex: number = -1;
  helpStep: IHelpStep;

  accountSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private dataService: DataService,
              private portalService: PortalService,
              private siteGroupService: SiteGroupService,
              private sitePipe: SitePipe,
              private orderByPipe: OrderByPipe,
              private fakerService: FakerService,
              private router: Router) { }

  ngOnInit() {
    this.selectedGroup = this.siteGroupService.getSelectedGroup();
    if(this.selectedGroup) {
      this.dataService.setLoading(true);
      this.isSystem = !this.selectedGroup.id;
      this.getSitesForGroup(this.isSystem);
    } else {
      this.goBack();
    }
    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount) {
      if(this.myAccount.largeCustomer) {
        this.numOfRowsToShow = 25;
        this.loadScroller();
      }
    }

    this.allowedToEditGroups = this.portalService.hasPermission(Permissions.EditGroups);

    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.allowedToEditGroups = this.portalService.hasPermission(Permissions.EditGroups);
      this.goBack();
    });

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((step) => {
      this.helpStep = step;
    });

  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  loadScroller() {
    let component = this;
    $(window).scroll(function() {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        component.numOfRowsToShow = component.numOfRowsToShow + 25;
      }
    });
  }

  getSitesForGroup(isSystem: boolean){
    if(isSystem) {
      this.siteGroupService.getSitesForSystemGroup(this.dataService.getAccountSource().id, this.selectedGroup.groupName).subscribe(
        sites => {
          this.dataService.setLoading(false);
          this.sitesLoaded(sites);
          if(this.selectedGroup.groupName == 'CONTRACTS'){
            sites.forEach((site) => {
              let nickname = this.getContractNickname(site.contractNum);
              if(nickname && nickname != site.contractNum){
                site.category = nickname;
              }
            })
          }
        }, err => {
          this.dataService.setLoading(false);
          this.sitesLoaded([]);
        }
      )
    } else {
      this.siteGroupService.getSitesForUserGroup(this.dataService.getAccountSource().id, this.selectedGroup.id).subscribe(
        (sites) => {
          this.dataService.setLoading(false);
          this.sitesLoaded(sites);
        }, err => {
          this.dataService.setLoading(false);
          this.sitesLoaded([]);
        }
      );
    }
  }

  getFakeSiteId(site: ISite){
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite){
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeSiteName(site: ISite){
    return this.portalService.getFakeSiteName(site);
  }

  getFakeAddress(site: ISite){
    return this.portalService.getFakeStreetAddress(site);
  }

  exitDescription(save?: boolean) {
    this.editingDescription = false;
    if(save) {
      this.updateGroup();
    }
  }

  exitName(save?: boolean){
    this.editingName = false;
    if(save){
      this.updateGroup();
    }
  }

  sitesLoaded(sites: ISite[]){
    this.sites = this.filteredSites = sites;
    this.sortSites(this.sortBy, true);
    this.loadCategoryOptions();
  }

  filterSites(){
    if(!this.searchTerm){
      this.filteredSites = this.sites;
    } else {
      this.filteredSites = this.sitePipe.transform(this.sites, this.searchTerm);
    }
  }

  sortSites(sortBy: string, forceAsc?: boolean){
    if(sortBy == this.sortBy && !forceAsc) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    if(!this.myAccount.largeCustomer){
      this.filteredSites = this.orderByPipe.transform(this.filteredSites, this.sortBy, this.ascending);
    }
  }

  goToContracts() {
    this.router.navigate(['/contracts'])
  }

  loadCategoryOptions() {
    // Load values from sites for type-ahead
    this.categoryOptions = [];
    this.sites.forEach((site) => {
      if(site.category){
        let unique = true;
        this.categoryOptions.forEach((category) => {
          if(category == site.category){
            unique = false;
          }
        });
        if(unique) {
          this.categoryOptions.push(site.category);
        }
      }
    });
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterSites();
  }

  updateGroup(){
    this.siteGroupService.createOrUpdateSiteGroup(this.selectedGroup).subscribe(
      (group) => {
        this.selectedGroup = group;
        // GET after PUT to ensure update
        this.siteGroupService.getSiteGroups(this.dataService.getAccountSource().id).subscribe(
          (siteGroups) => {
            this.dataService.setSiteGroupsSource(siteGroups);
          }, err => {
            console.error(err);
          }
        )
      }, err => {
        console.error(err);
      }
    );
  }

  deleteGroup() {
    this.siteGroupService.deleteSiteGroup(this.selectedGroup).subscribe(
      resp => {
        this.goBack();
        // GET after DELETE to ensure deletion
        this.siteGroupService.getSiteGroups(this.dataService.getAccountSource().id).subscribe(
          (siteGroups) => {
            this.dataService.setSiteGroupsSource(siteGroups);
          }, err => {
            console.error(err);
          }
        )
      }, err => {
        console.error(err);
        this.goBack();
      }
    )
  }

  typeahead(site: ISite) {
    if(site.category){
      this.typeaheadOptions = [];
      this.typeaheadId = site.id;
      this.categoryOptions.forEach((categoryOption) => {
        for(var i = 0; i < categoryOption.length; i++){
          if(site.category.toLowerCase() == categoryOption.substring(0, i+1).toLowerCase()) {
            let option = {
              'match': categoryOption.substring(0,i+1),
              'remainder': categoryOption.substring(i+1),
              'full': categoryOption
            };
            this.typeaheadOptions.push(option);
            break;
          }
        }
      });
    }
  }

  navigateTypeahead(down: boolean) {
    if(down && this.typeaheadIndex < this.typeaheadOptions.length-1){
      this.typeaheadIndex++;
    } else if (!down && this.typeaheadIndex > -1) {
      this.typeaheadIndex--;
    }
  }

  selectTypeahead(site: ISite, index?: number){
    if(this.typeaheadOptions.length > 0){
      if(this.typeaheadIndex == -1) {
        this.typeaheadIndex = 0;
      }
      if(index) {
        site.category = this.typeaheadOptions[index].full;
      } else {
        site.category = this.typeaheadOptions[this.typeaheadIndex].full;
      }
      this.resetTypeahead();
    }
  }

  resetTypeahead() {
    this.typeaheadIndex = -1;
    this.typeaheadOptions = [];
  }

  updateSiteCategory(site: ISite) 
  {
    this.saving = true;
    this.groupNameError = false;
    this.groupNameRow = '';
    let body = {
      siteGroupKey: {
        siteId: site.siteId.valueOf(),
        groupId: this.selectedGroup.id
      },
      category: site.category
    };
    this.resetTypeahead();
    if(site.category != null)
    {
        if(site.category.toUpperCase().indexOf("C:")===0 || site.category.toUpperCase().indexOf("S:")===0 
        	|| site.category.toUpperCase().startsWith("OTHER SITES")  || site.category.toUpperCase().startsWith("UNCATEGORIZED") )
		{
			    site.category = '';
			    this.groupNameError = true;
			    this.groupNameRow = site.siteId.valueOf();
		}
       else
       {
        this.siteGroupService.updateSiteCategory(body, this.selectedGroup.id, this.dataService.getAccountSource().id).subscribe(
        updatedSite => {
          // Update site without disrupting the filter
          this.filteredSites.forEach((site, index) => {
            if(site.id == updatedSite.id){
              this.filteredSites[index] = updatedSite;
            }
          });
          // Update full dataset
          this.sites.forEach((site, index) => {
            if(site.id == updatedSite.id){
              this.sites[index] = updatedSite;
            }
          });
          this.loadCategoryOptions();
          setTimeout(() => {
            this.saving = false;
            this.groupNameError = false;
          }, 1000);
        }, err => {
          setTimeout(() => {
            this.saving = false;
            this.groupNameError = false;
          }, 1000);
          console.error(err);
        }
      )
      }
    } else {
      this.saving = false;
      this.groupNameError = false;
    }
  }

  getContractNickname(contractNum: string){ return this.portalService.getContractNickname(contractNum)}

  goBack() {
    this.router.navigate(['/site-groups/manage']);
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}
