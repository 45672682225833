import { Component, OnInit } from '@angular/core';
import { IAccount } from "../../shared/entities/account";
import { IProfile, IUserAccount, IUserDetails } from '../../shared/entities/profile';
import { DataService } from "../../shared/data.service";
import { ProfileService } from "../../profile/profile.service";
import { UserAccountPipe } from "../../shared/pipe/useraccounts.pipe";
import {Subscription} from "rxjs";
import { PortalService } from '../../shared/portal.service';
import { AccountRenewalService } from "./account-renewal.service";
import {ITealiumRequest} from "../../shared/entities/tealium";
import { CookieService } from 'ngx-cookie-service';

declare let $: any;

@Component({
  selector: 'app-account-renewal',
  templateUrl: './account-renewal.component.html',
  styleUrls: ['./account-renewal.component.scss'],
  providers: [CookieService]
})
export class AccountRenewalComponent implements OnInit {

  tealiumDataSubscription: Subscription;
  tealiumData: any;
  tealiumConfirmed: boolean = false;


  constructor(private dataService: DataService,
              private service: AccountRenewalService,
			  private cookieService: CookieService) { }

  ngOnInit() {
    this.tealiumData = this.dataService.getTealiumData();

	this.tealiumDataSubscription = this.dataService.tealiumDataUpdated.subscribe(
      (tealiumData) => {
        this.tealiumData = tealiumData;

		//analytics script for primaryContract
		if (this.tealiumData && this.tealiumData.eligibleForRenewal)
	    {
			if (typeof (window as any).renewalModal === "function") {
				try {
					(window as any).renewalModal(this.tealiumData.primaryContact, "display");
				}catch (e) {
					// ignore errors for now
				}
			}
		}
      }
    );
  }

  ngOnDestroy() {
    if (this.tealiumDataSubscription) this.tealiumDataSubscription.unsubscribe();
  }

  startRenewal(){

	this.confirmTealium();

	if (typeof (window as any).renewalModal === "function") {
		try {
			(window as any).renewalModal(this.tealiumData.primaryContact, "confirm");
		}catch (e) {
			// ignore errors for now
		}
	}

	var div = document.getElementById('renewalPanel');
	div.style.visibility="hidden";
	div.style.display="none";

	div = document.getElementById('thankYouPanel');
	div.style.visibility="visible";
	div.style.display="block";
  }

  secondaryContactRenewal(){
	var div = document.getElementById('renewalPanel');
	div.style.visibility="hidden";
	div.style.display="none";

	div = document.getElementById('secondaryContactPanel');
	div.style.visibility="visible";
	div.style.display="block";
  }

  reset(){

	$('#accountRenewalModal').modal('hide');

	var div = document.getElementById('renewalPanel');
	if (div != null)
	{
		div.style.visibility="visible";
		div.style.display="block";
	}

	div = document.getElementById('thankYouPanel');
	if (div != null)
	{
		div.style.visibility="hidden";
		div.style.display="none";
	}
  }

  confirmTealium(){

	  this.cookieService.set('TealiumByPass' + this.tealiumData.accountId, 'true');

	  let tealiumRequest: ITealiumRequest = {
			sbl_cta_contracts: this.tealiumData.contractNumbers,
			oam_user_email: this.tealiumData.email,
			user_unique_id: this.tealiumData.accountId,
			tealium_visitor_id: this.tealiumData.tealId
          };

	  this.service.confirmTealium(tealiumRequest).subscribe(
		  (tealiumResponse) => {
			  console.log("Tealium Confirmed");
		  }
		);

	  this.tealiumConfirmed = true;
  }

  dismissTealium(){

	  this.cookieService.set('TealiumByPass' + this.tealiumData.accountId, 'true');

	  this.reset();

	  if (this.tealiumConfirmed ==  false)
	  {

		  if (typeof (window as any).renewalModal === "function") {
			   try {
					(window as any).renewalModal(this.tealiumData.primaryContact, "decline");
				}catch (e) {
					// ignore errors for now
				}
		  }

		  let tealiumRequest: ITealiumRequest = {
				sbl_cta_contracts: this.tealiumData.contractNumbers,
				oam_user_email: this.tealiumData.email,
				user_unique_id: this.tealiumData.accountId,
				tealium_visitor_id: this.tealiumData.tealId
			  };

		  this.service.dismissTealium(tealiumRequest).subscribe(
			  (tealiumResponse) => {
				  console.log("Tealium Dissmissed");
			  }
			);
	  }

	  this.tealiumConfirmed = false;

  }

}
