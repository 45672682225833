import {Component, OnInit, Input} from '@angular/core';
import {IUserMetricsDetail} from "../../metrics/metrics";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {FadeAnimation} from "../../animations/fadeAnimation";

declare var $ :any;

@Component({
  selector: 'app-user-metrics-detail',
  templateUrl: './user-metrics-detail.component.html',
  styleUrls: ['./user-metrics-detail.component.scss', '../../metrics/metrics.component.scss'],
  animations: [FadeAnimation],
  providers: [GenericPipe]
})
export class UserMetricsDetailComponent implements OnInit {

  @Input() title: string;
  @Input() date: string;

  showSearchRow: boolean = false;
  searchTerm: string;

  userMetricsDetails: IUserMetricsDetail[];
  filteredUserMetricsDetails: IUserMetricsDetail[];

  constructor(private genericPipe: GenericPipe) { }

  ngOnInit() {
    // console.log("MODAL NG INIT");
    // console.log("title: ", this.title);
    // console.log("title: ", this.date);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterUserMetricsDetails();
  }

  filterUserMetricsDetails() {
    if(!this.searchTerm){
      this.filteredUserMetricsDetails = this.userMetricsDetails;
    } else {
      this.filteredUserMetricsDetails = this.genericPipe.transform(this.userMetricsDetails, this.searchTerm);
    }
  }

  close(){
    $('#userMetricsDetailModal').modal('hide');
  }

}
