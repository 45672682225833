import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IAccount } from '../shared/entities/account';
import { AccountsService } from '../accounts/accounts.service';
import { DataService } from '../shared/data.service';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import {FadeAnimation} from "../animations/fadeAnimation";
import {IUserAccount} from "../shared/entities/profile";
import {Subscription} from "rxjs";
import { FormsModule } from '@angular/forms';
import { HelpTopicsService } from './help-topics.service';
import {HelpTopicsPipe} from "../shared/pipe/help-topics.pipe";


declare var $: any;

@Component({
  selector: 'app-help-topics',
  templateUrl: './help-topics.component.html',
  animations: [
    FadeInAnimation,
    FadeAnimation
  ],
  styleUrls: ['./help-topics.component.scss'],
  providers: [HelpTopicsPipe]
})
export class HelpTopicsComponent implements OnInit {
   myAccount: IUserAccount;
   accountSourceSubscription: Subscription;
   isOpen = false;
   search: string;
   helpTopics: any[];
   searchResults: any[];

  constructor(private dataService: DataService,
              private service: HelpTopicsService,
              private pipe: HelpTopicsPipe
            ) { }

  ngOnInit() {
    this.dataService.setTitleSource('Help Topics'); // update title
    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
      this.getHelpTopics();
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getHelpTopics();
      }
    );

    $('.form-control').focus(()=>{
      $('.input-group-addon').addClass('active');
    });
    $('.form-control').blur(()=>{
      $('.input-group-addon').removeClass('active');
    });
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
  }

  getHelpTopics() {
    this.service.getHelpTopics().subscribe(
      (topics) => {
        this.helpTopics = topics;
      }
    );
  }

  searchTopics() {
    this.searchResults = this.pipe.transform(this.helpTopics, this.search);
  }

}

