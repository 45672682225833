import { Component, OnInit } from '@angular/core';
// import  *  as  data  from  './generation-status.json';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { DataService } from '../../../shared/data.service';
import { IGenerationSite } from '../../generation-site';
import { IGeneratorStatus } from '../../generator-status';
import { ILookbackDateRange } from '../../lookbackDate';
import { ShadowMeteringService } from '../../shadow-metering.service';
import { IBreaker, ISwitchGearStatus } from '../../switch-gear-status';

@Component({
  selector: 'app-generation-status',
  templateUrl: './generation-status.component.html',
  styleUrls: ['./generation-status.component.scss']
})

export class GenerationStatusComponent implements OnInit {
  utilityDemand = true;
  utilityBreakerExpanded = true;
  intertieBreakerExpanded = true;
  generatorStatuses$: Observable<IGeneratorStatus[]>;
  utilityFrequencyExpanded = false;
  averageVoltageExpanded = false;
  switchGearStatuses$: Observable<ISwitchGearStatus[]>
  numberFormat = new Intl.NumberFormat('en-US', {minimumFractionDigits: 3, maximumFractionDigits: 3});

  constructor(
    private shadowMeteringService: ShadowMeteringService
  ) {
    this.switchGearStatuses$ = shadowMeteringService.switchGearStatuses;
    this.generatorStatuses$ = shadowMeteringService.generatorStatuses;
  }

  ngOnInit() {}

  getUtilityDemand(switchGears: ISwitchGearStatus[]): string {
    let utilityDemand: number;

    // Utility demand defined at generator level if lookback mode is on
    if (this.shadowMeteringService.lookbackEnabled) {
      if (this.shadowMeteringService.lookbackUtilityDemand) {
        utilityDemand = this.shadowMeteringService.lookbackUtilityDemand;
      }
    } else {
      for (const switchGear of switchGears) {
        if (switchGear.kw) {
          utilityDemand = utilityDemand === undefined ? switchGear.kw
            : utilityDemand + switchGear.kw;
        }
      }
    }

    return utilityDemand !== undefined && utilityDemand !== null ? this.roundToThree(utilityDemand) + ' KW' : '---';
  }

  getAverageUtilityFrequency(switchGears: ISwitchGearStatus[]): string {
    let utilityfrequency = 0;
    let count = 0;

    for (const switchGear of switchGears) {
      if (switchGear.frequency) {
        utilityfrequency += switchGear.frequency;
        count += 1;
      }
    }
    return count > 0 ? this.roundToThree(utilityfrequency / count) + ' HZ' : '---';
  }

  getUtilityFrequency(switchGear: ISwitchGearStatus): string {
    return switchGear.frequency !== undefined && switchGear.frequency !== null ? this.roundToThree(switchGear.frequency) + ' HZ' : '---';
  }

  getVoltage(switchGears: ISwitchGearStatus[], voltageKey: string): string {
    const voltage = switchGears[0][voltageKey];
    return voltage !== undefined && voltage !== null ? this.roundToThree(voltage) + ' KV' : '---';
  }

  calculateAverageVoltageForSingleSwitchGear(switchGear: ISwitchGearStatus): number {
    let averageVoltage = 0;
    let count = 0;
    if (switchGear.voltageAB !== undefined && switchGear.voltageAB !== null) {
      averageVoltage += switchGear.voltageAB;
      ++count;
    }

    if (switchGear.voltageBC !== undefined && switchGear.voltageBC !== null) {
      averageVoltage += switchGear.voltageBC;
      ++count;
    }

    if (switchGear.voltageCA !== undefined && switchGear.voltageCA !== null) {
      averageVoltage += switchGear.voltageCA;
      ++count;
    }

    return count > 0 ? averageVoltage / count : undefined;
  }

  showBreakerExpand(switchGearStatuses: ISwitchGearStatus[], breakersKey: string): boolean {
    const activeTotalBreaker = this.computeActiveAndTotalBreaker(switchGearStatuses, breakersKey);
    return activeTotalBreaker.active !== 0 && activeTotalBreaker.active !== activeTotalBreaker.total && activeTotalBreaker.total !== 1;
  }

  getAverageVoltageForSingleSwitchGear(switchGear: ISwitchGearStatus): string {
    const averageVoltage = this.calculateAverageVoltageForSingleSwitchGear(switchGear);
    return averageVoltage !== undefined ? this.roundToThree(averageVoltage) + ' KV' : '---';
  }

  getActiveClass(switchGearStatuses: ISwitchGearStatus[], breakersKey: string): boolean {
    const activeTotalBreaker = this.computeActiveAndTotalBreaker(switchGearStatuses, breakersKey);
    return activeTotalBreaker.active !== 0;
  }

  getBreakerDisplay(breaker: IBreaker, switchGearLength: number, switchGearIndex: number): string {
    return `${switchGearLength > 1 ? 'ATS ' + (switchGearIndex + 1) + ' ' : ''}${breaker.name}`;
  }

  getAverageVoltage(switchGears: ISwitchGearStatus[]): string {
    let averageVoltageTotal = 0;
    let count = 0;
    for (const switchGear of switchGears) {
      const averageVoltage = this.calculateAverageVoltageForSingleSwitchGear(switchGear);
      if (averageVoltage !== undefined) {
        averageVoltageTotal += averageVoltage;
        ++count;
      }
    }
    return count > 0 ? this.roundToThree(averageVoltageTotal / count) + ' KV' : '---'
  }

  private roundToThree(num: number): string {
    return this.numberFormat.format(num);
  }

  getDispatchModes(switchGearStatuses: ISwitchGearStatus[]): string[] {
    const dispatchModes: string[] = [];

    for (let i = 0; i < switchGearStatuses.length; ++i) {
      for (const dispatchMode of switchGearStatuses[i].dispatchModes) {
        if (dispatchMode.dispatched) {
            dispatchModes
              .push(`${switchGearStatuses.length > 1 ? 'ATS ' + (i + 1) + ' ' : ''}${dispatchMode.name} ON`);
        }
      }
    }

    return dispatchModes;
  }

  getDispatchedUnits(generatorStatuses: IGeneratorStatus[]): string {
    const activeCount = generatorStatuses
      .filter(generatorStatus => generatorStatus.emergencyAvailable).length;
    const totalCount = generatorStatuses.length;
    return totalCount ? `${activeCount} of ${totalCount} units` : '---';
  }

  computeActiveAndTotalBreaker(switchGearStatuses: ISwitchGearStatus[], breakersKey: string): {active: number, total: number} {
    let total = 0;
    let active = 0;

    switchGearStatuses.forEach(switchGearStatus => {
       total += switchGearStatus[breakersKey]
        ? (switchGearStatus[breakersKey] as IBreaker[]).length
        : 0;
       active += switchGearStatus[breakersKey]
        ? (switchGearStatus[breakersKey] as IBreaker[]).filter((breaker: IBreaker) => breaker.closed).length
        : 0;
    });

    return {active, total};
  }

  
  showIntertieBreaker(switchGearStatuses: ISwitchGearStatus[], breakersKey: string): boolean {
    if (switchGearStatuses && (!switchGearStatuses.length || !switchGearStatuses[0][breakersKey].length)) {
      return false;
    }
    return true;
  }

  getClosedBreakersDisplay(switchGearStatuses: ISwitchGearStatus[], breakersKey: string): string {
    if (switchGearStatuses && (!switchGearStatuses.length || !switchGearStatuses[0][breakersKey].length)) {
      return '---';
    }

    const activeTotalBreaker = this.computeActiveAndTotalBreaker(switchGearStatuses, breakersKey);

    if (activeTotalBreaker.active === 0) {
      return 'OPEN';
    } else if (activeTotalBreaker.active === activeTotalBreaker.total) {
      return 'CLOSED';
    }

    if (activeTotalBreaker.total === 1) {
      return activeTotalBreaker.active === 1 ? 'CLOSED' : 'OPEN';
    }

    return `${activeTotalBreaker.active} OF ${activeTotalBreaker.total} CLOSED`;
  }

  getDispatchedCapacity(generatorStatuses: IGeneratorStatus[]): string {
    const capacity = generatorStatuses
      .reduce((sum, generatorStatus) => sum + generatorStatus.capacity, 0);
    return generatorStatuses.length ? `${Number(capacity.toFixed()).toLocaleString()} KW Capacity` : '---';
  }

  getCurrentGeneration(generatorStatuses: IGeneratorStatus[]): string {
    const generation = generatorStatuses
      .reduce((sum, generatorStatus) => sum + generatorStatus.generation, 0);
    return generatorStatuses.length ? `${this.roundToThree(generation)} KW` : '---';
  }

  get statusTimestamp(): moment.Moment {
    return this.shadowMeteringService.statusTimestamp;
  }

  get selectedSite(): IGenerationSite {
    return this.shadowMeteringService.selectedSite;
  }

  get lookbackEnabled(): boolean {
    return this.shadowMeteringService.lookbackEnabled;
  }

  get lookbackDateRange(): ILookbackDateRange {
    return this.shadowMeteringService.lookbackDateRange;
  }

  get showUtilityDemand(): boolean {
    return this.shadowMeteringService.utilityDemandAvailable;
  }
}
