import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-bill-delivery',
  templateUrl: './bill-delivery.component.html',
})
export class BillDeliveryComponent implements OnInit {

 
  accountSourceSubscription: Subscription;

  constructor(private dataService: DataService,
              private router: Router) {}

  ngOnInit() {
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.router.navigate(['/terms']);
    });
  }

  ngOnDestroy() {
   if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
  }

}
