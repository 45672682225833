import { Component, OnInit, ElementRef } from '@angular/core';
import { SubscribeToReportComponent } from '../subscribe-to-report/subscribe-to-report.component';

import { DataService } from '../shared/data.service';
import { FadeAnimation } from '../animations/fadeAnimation';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import { BillingSummaryService } from './billing-summary.service';
import {IInvoice, IDownloadInvoiceRequest, IBillingReportResponse, ICategory} from '../shared/entities/bill-reports';
import { Router } from '@angular/router';
import { DatePeriodsArr, WeeklyDatePeriods } from '../shared/const/datePeriods';
import { BillingSummaryColumns } from '../shared/const/billingSummaryColumns';
declare var $: any;
import * as moment from 'moment';
import { PortalService } from '../shared/portal.service';
import { BillingReportPipe } from '../shared/pipe/billingReport.pipe';
import { ReportDataUtility } from '../subscribe-to-report/report-data.utility';
import { Subscription } from 'rxjs';
import { IUserAccount, IProfile } from '../shared/entities/profile';
import { FakerService } from '../shared/faker.service';
import { ISite } from '../shared/entities/site';
import { IHelpStep } from '../shared/entities/contextualHelp';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { ISubscription } from '../shared/entities/widgets/subscriptions';
import { SitesControl } from '../shared/entities/controls';
import { ISiteGroup } from '../shared/entities/site';
import { SiteGroupService } from '../site-groups/site-groups.service';
import { IAccount } from '../shared/entities/account';
import { FilterType, ISubscribeReportTemplate, ReportFilter } from '../shared/entities/subscribeReportTemplate';
import { ReportSettingsService } from '../subscribe-to-report/report-settings.service';
import { IBillingReport, IBillingReportRequest, IGenericReportSetting } from '../shared/entities/reportSettings';

@Component({
  selector: 'app-bill-reports',
  templateUrl: 'billing-summary.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['billing-summary.component.scss', '../shared/styles/reports.scss'],
  providers: [BillingReportPipe],
  host: {
    '(document:click)': 'handleClick($event)',
  }
})

// In the beginning was the Word, and the Word was with God, and the Word was God.
// He was in the beginning with God.
// All things were made through him, and without him was not any thing made that was made.
// In him was life, and the life was the light of men.
// The light shines in the darkness, and the darkness has not overcome it.
// John 1:1-5

export class BillingSummaryComponent implements OnInit {

  tempPrevRequest: string;
  allSites: ISite[];
  accountWithContractData: IAccount;
  elementRef;
  myAccount: IUserAccount;
  sitesControl: SitesControl;
  showSites = false;
  filteredSites: ISite[];
  siteIndex: number = -1;
  billingReports: IBillingReport[];
  reportData: IBillingReportResponse;
  filteredReportData: IBillingReportResponse;
  reportRequest: IBillingReportRequest = {};
  summaryCategories: ICategory[];
  userSiteGroups: ISiteGroup[] = [];
  systemSiteGroups: ISiteGroup[] = [];
  noDataError = false;
  showSearchBar = false;
  searchTerm = '';
  prevSearch = '';
  showScrollbar = false;
  showReports = false;
  showSettings = false;
  showSave = false;
  maskMode: boolean;
  helpStep: IHelpStep;
  startDate: string;
  endDate: string;
  showDetailView: boolean;
  pager = {
    totalPages: 0,
    firstItem: 1,
    lastItem: 50,
    totalItems: 0,
    pageArray: [],
  };
  accountSourceSubscription: Subscription;
  billingDateRangesSourceSubscription: Subscription;
  siteGroupsSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  activateSave = false;
  creatingReport = false;
  editingReportName = false;
  makeNewDefault = false;
  makeNewPublic = false;
  dateTypeSelected = true;
  billingDateRanges: any[];

  // Subscribe
  subscribedReports: ISubscription[];
  subscription: ISubscription;
  customerName: string;
  markets: string[];
  market: string;
  msgDisplay = false;


  // Columns
  tableColumns: any[]; // Columns iterated over the table, taken from columns model in applyColumnChanges()
  columns: any[]; // Model for sidenav, see billingSummaryColumns.ts


  contractsGroup = {
    groupName: 'CONTRACTS',
    display: 'Contracts'
  };

  /**************************************************************
  Variables For Subscribe to Report Template - START
  **************************************************************/
  reportSettings: IGenericReportSetting[]; // List of All Report Settings for Subscribe To Report Template
  privateReports: IGenericReportSetting[]; // List of Private Report Settings for Subscribe To Report Template
  publicReports: IGenericReportSetting[];  // List of Private Report Settings for Subscribe To Report Template
  reportsLoaded: boolean = false;          // Boolean that tells us if Reports have loaded, used to Render Subscribe to Report Template
  newReportName = '';                      // Report Name used for saving a report
  makeDefault: boolean = true;             // Boolean Flag used for saving default report
  makePublic: boolean = false;             // Boolean Flag used for saving public or private reports
  selectedReport: IGenericReportSetting;   // Report that is used to generate data for the table and for saving/editing/deleting reports
  reportSubscriptionRangesSourceSubscription: Subscription; // Used to get the valid date ranges for Reports
  datePeriods: any[];                      // Helper for displaying different date periods for the filter (3 Months, 6 Months, etc.)
  reportDateRanges: any[];                 // Stores valid date ranges for reports
  endDates: any[];                         // Stores end date ranges for reports
  dateRangeControl: any = {                // Used to track date range selections from user inputs
    periodSelected: true,
    toggled: false,
    show: false,
    startDate: {},
    endDate: {},
    datePeriod: 'Last3Months',
    datePeriodDisplay: 'Last 3 Months',
    dateRangeDisplay: '',
  };
  reportTemplate:ISubscribeReportTemplate = { // Holds the information about the specific report and builds child SubscribeToReportTemplate with correct filters
    title: "Billing Summary",
    defaultReportName: "Billing Report 01",
    reportType: "BILLING_SUMMARY",
    description: "This is a sample report description.",
    filters: [
        { // This is the first filter
        name: "Select Sites",
        filterType: FilterType.SitePicker,
        options: [
          { label: "Option1", value: "option1" }, // These are unused SitePicker is a special filter type
          { label: "Option2", value: "option2" },
          { label: "Option3", value: "option3" },
          { label: "Option4", value: "option4" },
          { label: "Option5", value: "option5" },
        ],
        selectedOptionLabel:"ALL SITES",
        selectedOptionValue: "ALL_SITES",
        defaultOptionLabel: "ALL SITES",
        defaultOptionValue: "ALL_SITES",
        showfilter: false
        },
        {
        name: "Date Range",
        filterType: FilterType.DatePicker,
        options: [
          { label: "Choice1", value: "choice1" }, // These are unused DatePicker is a special filter type
          { label: "Choice2", value: "choice2" },
          { label: "Choice3", value: "choice3" },
          { label: "Choice4", value: "choice4" },
          { label: "Choice5", value: "choice5" },
        ],
        selectedOptionLabel:"Last 3 Months", // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        selectedOptionValue: "Last3Months",
        defaultOptionLabel: "Last 3 Months",
        defaultOptionValue: "Last3Months",
        showfilter: false
        },
      ],
    };
  /**************************************************************
  Variables For Subscribe to Report Template - END
  **************************************************************/


  constructor(private dataService: DataService,
    private billingService: BillingSummaryService,
    private billingReportPipe: BillingReportPipe,
    private router: Router,
    private portalService: PortalService,
    myElement: ElementRef,
    private utility: ReportDataUtility,
    private fakerService: FakerService,
    private reportSettingsService: ReportSettingsService,
    private siteGroupsService: SiteGroupService,
    private subscriptionsService: SubscriptionsService) {
    this.elementRef = myElement;
  }

  ngOnInit() {
    this.myAccount = this.dataService.getAccountSource();
    this.maskMode = this.dataService.getMaskMode();
    this.sitesControl = new SitesControl();
    this.sitesControl.selectedTypeDisplay = 'GROUP';
    this.dataService.setSelectedNavItem('billing');
    this.setCurrentActivePage('billing');
    this.dataService.setTitleSource('Billing Summary Report');
    this.dataService.setLoading(true);
    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.subscription = { description: '', };
    if (this.myAccount) { this.loadColumns(); }

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.customerName = account.name;
        this.loadColumns();
        this.markets = this.dataService.getMarketArrayForAccountSource();
      }
    );
    this.reportTemplate.filters.forEach(filter =>{
      if(filter.filterType === FilterType.DatePicker) {
        filter.selectedOptionLabel = this.dateRangeControl.datePeriodDisplay;
        this.reportSubscriptionRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
          (dateRanges) => {
            this.reportDateRanges = dateRanges;
            this.endDates = dateRanges;
          });
      }
    });
    this.utility.calculateDateRanges();
    if (this.myAccount) {
      this.getReportSettings(true);
    }
    this.datePeriods = DatePeriodsArr;
    this.activateSave = false;
    this.detectScrollbar();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.generateBillingReportData();
      }
    );
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.generateBillingReportData();
    });

    // Click outside event for date range and groupings sections
    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#dateRangeSection').length == 0) {
        this.dateRangeControl.show = false;
      }
      if ($($event.target).parents('#groupingsSection').length == 0) {
        this.showSites = false;

      }
      if ($($event.target).parents('#optionsSection').length == 0) {
        this.showSettings = false;
      }
      if ($($event.target).parents('#reportsSection').length == 0) {
        this.showReports = false;
      }
      if ($($event.target).parents('#saveSection').length == 0) {
        this.showSave = false;
      }
    });

  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.billingDateRangesSourceSubscription) this.billingDateRangesSourceSubscription.unsubscribe();
    if (this.siteGroupsSourceSubscription) this.siteGroupsSourceSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  handleClick(event) {
    let clickedComponent = event.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
  }

  generateDatesFromPeriod(control: any) {
    this.utility.generateDatesFromPeriod(control, this.billingDateRanges);
  }
  // And this is love: not that we loved God but that he loved us and sent his Son to be the propitiation for our sins. - 1 John 4:10
  // And the shepherds returned, glorifying and praising God for all the things that they had heard and seen, as it was told unto them.
  // Luke 2:20
  generateRequest() {
    this.reportRequest =
    {
      accountId: this.myAccount.id,
      groupId: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null : null,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null,
      startDate: this.startDate,
      endDate: this.endDate,
      dateType: this.dateTypeSelected ? 'BILL POST DATE' : 'METER READ END DATE',
      siteRequest: this.sitesControl.siteRequest,
      siteGroupCategory: this.sitesControl.selectedCategory ? this.sitesControl.selectedCategory : ''
    };

    this.reportRequest.accountId = this.myAccount.id;
    this.reportRequest.groupId = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null : null;
    this.reportRequest.systemGroupName = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null;
    if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      this.reportRequest.systemGroupName = 'SPECIFIC_SITE';
      this.reportRequest.siteRequest = 'SPECIFIC_SITE';
      this.reportRequest.system = true;
      this.sitesControl.selectedGrouping = null;
      this.reportRequest.groupId = null;
      this.reportRequest.siteId = this.sitesControl.selectedSite ? this.sitesControl.selectedSite.siteId : this.sitesControl.siteId;
      this.reportRequest.siteGroupCategory = this.sitesControl.selectedSite ? this.sitesControl.selectedSite.siteId : this.sitesControl.siteId;


      if (this.sitesControl.siteSearch != null && this.sitesControl.siteSearch != '') {
        if ((this.sitesControl.siteSearch).indexOf(' ') > 0)
          this.reportRequest.siteGroupCategory = this.sitesControl.siteSearch.substring(0, (this.sitesControl.siteSearch).indexOf(' '));
        else
          this.reportRequest.siteGroupCategory = this.sitesControl.siteSearch;
      }
      else if (this.sitesControl.selectedSite.siteId != null && this.sitesControl.selectedSite.siteId != '')
        this.reportRequest.siteGroupCategory = this.sitesControl.selectedSite.siteId;
      else
        this.reportRequest.siteGroupCategory = this.sitesControl.selectedCategory;
    }
    else if (this.sitesControl.selectedGrouping.groupName == 'All Sites') {
      this.reportRequest.systemGroupName = 'ALL_SITES';
      this.sitesControl.siteRequest = 'GROUP';
      this.reportRequest.siteGroupCategory = '';
    }
  }

  loadSiteOptions() {
    this.allSites = this.dataService.getSitesForAccountSource();
    this.allSites.forEach((site) => {
       this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
    });
  }

  generateBillingReportData() {

    this.dataService.setLoading(true);
    this.generateDatesFromPeriod(this.dateRangeControl);
    this.generateRequest();
    this.showDetailView = false;
    this.reportRequest.searchTerm = this.searchTerm.trim();

    this.billingService.generateBillingReportData(this.reportRequest).subscribe(
      (reportData) => {
        this.reportData = reportData;
        this.noDataError = false;
        if (this.reportData.categories) {
          this.summaryCategories = this.reportData.categories;
          if (this.sitesControl.selectedGrouping != null && this.sitesControl.selectedGrouping.groupName == 'CONTRACTS') {
            this.reportData.categories.forEach((category) => {
              let nickName = this.portalService.getContractNickname(category.name);
              if (nickName != category.name) {
                if (nickName.length > 25) {
                  category.tooltip = nickName;
                  nickName = nickName.substr(0, 22) + '...';
                }
                category.contractId = category.name;
                category.name = nickName;
              }
            });
          }
          else if (this.sitesControl.siteRequest == 'SPECIFIC_SITE' && this.reportData.categories.length > 0) {
            if (this.sitesControl.selectedSite != null) {
              this.reportData.categories[0].name = '...' + this.sitesControl.selectedSite.siteId.substr(5);
            }
            else {
              this.reportData.categories[0].name = '...' + this.selectedReport.siteGroupCategory.substr(5);
            }
          }
          else if (this.sitesControl.selectedGrouping != null && this.sitesControl.selectedGrouping.groupName == 'All Sites' && this.reportData.categories.length > 0) {
            this.reportData.categories[0].name = 'All Sites';
          }
        } else {
          this.reportData.categories = [reportData as any];
        }

        if (this.reportData.categories.length == 0) {
          this.noDataError = true;
        }

        this.filteredReportData = this.reportData;
        setTimeout(() => {
          const element = document.getElementById('tableWrapper');
          $(element).scroll(function () {
            if ($(this).scrollTop() != 0) {
              $('.scroll-header').css('top', $(this).scrollTop() - 5 + 'px');
            } else {
              $('.scroll-header').css('top', $(this).scrollTop() + 'px');
            }
          });
          $('.scroll').scroll(function () {
            $('.scrollbar').scrollLeft($('.scroll').scrollLeft());
          });

          $('.scrollbar').scroll(function () {
            $('.scroll').scrollLeft($('.scrollbar').scrollLeft());
          });
          this.dataService.setLoading(false);
        }, 1500);
      }, err => {
        if (err.status == 404) {
          this.reportData = null;
          this.noDataError = true;
        }
        console.error(err);
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 1500);
      });
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  expand(row) {
    row.expanded = !row.expanded;
    this.detectScrollbar();
    this.updateScrollbarLength();
  }

  toggleDetailView(categoryRow: ICategory) {
    if (this.myAccount.largeCustomer) {
      if (categoryRow.expanded) {
        this.showDetailView = true;
        if (categoryRow.name != 'Search Results') {
          this.generateRequest();
          this.reportRequest.pageNo = 1;
          this.reportRequest.pageSize = 50;
          if (categoryRow.contractId) {
            this.reportRequest.categoryName = categoryRow.contractId;
          } else {
            this.reportRequest.categoryName = categoryRow.name;
          }
          if (this.reportRequest.categoryName == 'All Sites') {
            this.reportRequest.categoryName = 'ALL_SITES';
          }
          if (this.reportRequest.siteRequest == 'SPECIFIC_SITE') {
            this.reportRequest.categoryName = this.sitesControl.selectedSite.siteId;
          }
          this.dataService.setLoading(true);
          this.billingService.getBillingReportDetailView(this.reportRequest).subscribe((sites) => {
            categoryRow.sites = sites;
            this.reportData.categories = [categoryRow];
            this.buildPager(categoryRow);
          });
        } else {
          this.reportRequest.pageNo = 1;
          this.reportRequest.pageSize = 50;
          this.buildPager(categoryRow);
        }
      } else {
        this.showDetailView = false;
        this.reportData.categories = this.summaryCategories;
      }
    }
  }

  buildPager(categoryRow: ICategory) {
    this.pager.totalPages = Math.ceil(categoryRow.siteCount / 50);
    this.pager.totalItems = categoryRow.siteCount;
    this.pager.pageArray = [];
    for (let i = 1; i <= this.pager.totalPages; i++) {
      this.pager.pageArray.push(i);
    }
    this.updatePager();
    this.dataService.setLoading(false);
  }

  updatePager() {
    this.pager.firstItem = this.reportRequest.pageNo * 50 - 49;
    this.pager.lastItem = this.reportRequest.pageNo * 50;
    if (this.pager.lastItem > this.pager.totalItems) {
      this.pager.lastItem = this.pager.totalItems;
    }
  }

  getDetailPage(pageNo: number) {
    if (pageNo > 0 && pageNo <= this.pager.totalPages) {
      this.reportRequest.pageNo = pageNo;
      this.updatePager();
      this.dataService.setLoading(true);
      this.billingService.getBillingReportDetailView(this.reportRequest).subscribe((sites) => {
        this.reportData.categories[0].sites = sites;
        this.dataService.setLoading(false);
      });
    }
  }

  detectScrollbar() {
    const element = document.getElementById('tableWrapper');
    this.showScrollbar = element.offsetHeight != element.scrollHeight;
  }

  updateScrollbarLength() {
    // Welcome to the most hated block of code in this app.
    let id, scrollWidth = 0;
    setTimeout(() => {
      this.tableColumns.forEach((tableCol) => {
        if (tableCol.checked) {
          id = '#' + tableCol.value + 'Col';
          scrollWidth += $(id).outerWidth();
        }
      });
      $('#scrollbar').css({
        'width': (scrollWidth + 'px')
      });
    }, 50);
  }

  downloadInvoice(invoice: IInvoice) {
    this.dataService.setLoading(true);
    const request: IDownloadInvoiceRequest = {
      accountId: this.myAccount.id,
      contractAccountNumber: invoice.contractAccountNumber,
      collectiveAccountId: this.maskMode ? null : invoice.collectiveAccountId,
      collectiveInvoiceNo: this.maskMode ? null : invoice.collectiveInvoiceNo,
      siteId: invoice.siteId,
      invoiceNo: this.maskMode ? '9999999999990' : invoice.invoiceNo,
      billingPeriod: invoice.billingDate.substr(0, 7).replace('-', ''),
      repOwnerId: invoice.repOwnerId
    };
    if (this.dataService.getIsMobile()) {
      this.billingService.openMobileInvoice(request);
    } else {
      this.billingService.downloadInvoice(request);
    }
  }

  formatCell(data: any, col: string, maskedAddress?: string) {
    return this.utility.formatCellBilling(data, col, this.maskMode, maskedAddress);
  }

  getTooltip(site: any, col: string) {
    if (col == 'serviceAddress') {
      return site['serviceAddress1'] + ' \n' + site['serviceCity'] + ', ' + site['serviceState'] + ' ' + site['serviceZip'];
    } else if (col == 'billingAddress') {
      return site['billingAddress1'] + ' \n' + site['billingCity'] + ', ' + site['billingState'] + ' ' + site['billingZip'];
    } else if (col == 'siteStatus') {
        switch(site['siteStatus']) {
            case 'Added': {
              return 'Site was added via an amendment.';
            }
            case 'Assigned Away': {
              return 'Site was reassigned to another customer without a change in price.';
            }
            case 'Assigned To': {
              return 'Site was reassigned from another customer without a change in price.';
          }
          case 'Deleted': {
            return 'Site was deleted via an amendment.';
          }
          case 'Rescinded': {
            return 'Contract was never valid.';
          }
          case 'Restructured': {
            return 'Contract was restructured; new contract will contain \'Under Contract\' site.';
          }
          case 'Terminated': {
            return 'Contract is no longer valid.';
          }
          case 'Under Contract': {
            return 'Active site that was included in contract at the time of closing the contract.';
          }
          case ' Pending Contract': {
            return 'Contract has not closed.';
           }
        }
    }
  }

  exportToExcel() {
    const req = {
      accountId: this.myAccount.id,
      reportName: this.selectedReport.reportName,
      columnNames: this.getColumnNamesString(),
      defaultReport: this.makeDefault ? 1 : 0,
      publicFlag: this.makePublic ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      reportId: this.selectedReport.reportId,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null,
      groupId: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id.toString() : null : null,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.endDate,
      dateType: this.dateTypeSelected ? 'BILL POST DATE' : 'METER READ END DATE',
      siteGroupCategory: this.sitesControl.selectedCategory ? this.sitesControl.selectedCategory.toString() : null,
      siteRequest: this.sitesControl.siteRequest ? this.sitesControl.siteRequest : null,
      maskingMode : this.dataService.getMaskMode(),
      brand: window.localStorage.getItem("brand"),
    };
    if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      req.systemGroupName = this.sitesControl.siteRequest;

      if (this.sitesControl.selectedSite == null)
        req.siteGroupCategory = this.sitesControl.siteSearch;
      else
        req.siteGroupCategory = this.sitesControl.selectedSite.siteId;
    }

    if (this.sitesControl.siteRequest == 'GROUP') {
      req.siteGroupCategory = '';
    }

    this.billingService.exportBillingReport(req);
  }

  toggleSearchRow() {
    const tableWrapper = document.getElementById('tableWrapper');
    tableWrapper.scrollTop = 0;
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    if (!this.showSearchBar) {
      this.filterReport(true);
    }
  }

  checkSearch(searchTerm: string) {
    searchTerm = searchTerm.trim();
    //Checks if ##/##/#### and converts it to ####-##-##
    if (/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2,4}/g.test(searchTerm)) {
      return moment(searchTerm).format('YYYY-MM-DD');
    }
    //Checks if number in between a /
    if (/(\d){1,2}\/(\d){1,2}/g.test(searchTerm)) {
      return moment(searchTerm).format('MM-DD');
    }
    return searchTerm;
  }

  filterReport(refreshLarge: boolean) {
    if (!this.myAccount.largeCustomer) {
      if (!this.searchTerm) {
        this.filteredReportData = this.reportData;
      } else {
        this.filteredReportData = this.billingReportPipe.transform(this.reportData, this.checkSearch(this.searchTerm));
      }
      this.calculateTotals();
    } else if (refreshLarge && this.myAccount.largeCustomer && this.prevSearch != this.searchTerm) {
      this.prevSearch = this.checkSearch(this.searchTerm);
      this.generateBillingReportData();
    }
  }

  //
  // Methods for Handling Custom Columns
  //
  applyColumnChanges(init?: boolean) {
    this.tableColumns = [];
    this.columns && this.columns.forEach((section) => {
      section.columns.forEach((col) => {
        const tableCol = Object.assign({}, col);
        this.tableColumns.push(tableCol);
      });
    });
    this.updateScrollbarLength();
  }

  setDefaultColumns() {
    this.columns.forEach((section) => {
      section.checked = false;
      section.toggled = section.name == 'General Information';
      section.columns.forEach((col) => {
        if(col.display == 'Meter Read Start Date' || col.display == 'Meter Read End Date' || col.display == 'Total Current Charges'
          || col.display == 'Billing Date'|| col.display == 'Due Date' || col.display == 'Account #'
          || col.display == 'Usage (kWh)' || col.display == 'Service Address'){
          col.checked = true;
          section.checked = true;
        } else {
          col.checked = false;
        }
      });
    });
    this.applyColumnChanges();
  }

  loadColumns() {
    this.columns = JSON.parse(JSON.stringify(BillingSummaryColumns));
    const colsToDelete = [];
    this.columns.forEach((section) => {
      section.checked = false;
      section.toggled = section.name == 'General Information';
      section.columns.forEach((col, i) => {
        if (col.display == 'Meter Read Start Date' || col.display == 'Meter Read End Date' || col.display == 'Total Current Charges'
          || col.display == 'Due Date' || col.display == 'Payment Date'
          || col.display == 'Balance Due' || col.display == 'Account #') {
          col.checked = true;
        } else {
          col.checked = false;
        }
        if ((col.northeast && this.myAccount.market == 'ERCOT') || (col.ercot && this.myAccount.market == 'NORTHEAST')) {
          colsToDelete.push(i);
        }
      });
      const newColumns = [];
      section.columns.forEach((col, i) => {
        if (colsToDelete.indexOf(i) == -1) {
          newColumns.push(col);
        }
      });
      section.columns = newColumns;
    });
  }

  getColumnNamesString() { return this.utility.getColumnNamesString(this.columns); }

  getColumnHeadersString() { return this.utility.getColumnHeadersString(this.columns); }

  calculateTotals() { this.filteredReportData = this.utility.calculateTotals(this.filteredReportData); }

  numbersWithCommas(x) { return this.utility.numbersWithCommas(x); }

  setCurrentActivePage(tabName: string): void { this.dataService.setCurrentActivePage(tabName);}

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  getContractNickname(contractNum: string) { return this.portalService.getContractNickname(contractNum);}

  /**************************************************************
  Methods For Subscribe to Report Template - Start
  **************************************************************/
  getReportSettings(selectDefault?: boolean){
    this.utility.getReportSettings(this.myAccount.id, this.reportTemplate, this.dateRangeControl, this.datePeriods, this.reportDateRanges, this.maskMode, selectDefault, this.selectedReport, this.columns, this.sitesControl)
    .subscribe({
      next: ({ reportSettings, reportTemplate, selectedReport, publicReports, privateReports, startDate, endDate, columns }) => {
        this.reportSettings = reportSettings;
        this.selectedReport = selectedReport;
        this.publicReports = publicReports;
        this.privateReports = privateReports;
        this.startDate = startDate;
        this.endDate = endDate;
        this.reportsLoaded = true;
        this.columns = columns;
        reportTemplate.filters.forEach((filter) => {
          switch (filter.filterType) {
            case 'DatePicker':
              this.dateRangeControl = filter.savedFilterControl;
              break;
            case 'SitePicker':
              this.sitesControl = filter.savedFilterControl;
              break;
            default:
              // Action for other filter types
          }
        });

        // If we have the column editor on this report
        if(this.selectedReport && this.selectedReport.columnNames){
          const settingsColumns = this.selectedReport.columnNames.split(',');
          this.columns.forEach((section) => {
            section.columns.forEach((col) => {
              col.checked = false;
              settingsColumns.forEach((settingsCol) => {
                if (settingsCol == col.value) {
                  col.checked = true;
                }
              });
            });
          });
          this.applyColumnChanges(true);
        }

        // Billing Summary Specific Loading
        if(this.reportTemplate.reportType === 'BILLING_SUMMARY') {
          this.dateRangeControl.dateType = this.selectedReport.dateType;
          if (this.dateRangeControl.dateType == 'BILL POST DATE') {
            this.dateTypeSelected = true;
          } else {
            this.dateTypeSelected = false;
          }
        }
        this.generateBillingReportData();
      },
      error: (error) => {
        // Handle errors
      }
    });
  }
  reportSavedHandler($event) {
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    this.makePublic = this.selectedReport.publicFlag ? this.selectedReport.publicFlag == 1 : false;
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
      }
    });

    // If we have the column editor on this report
    if(this.selectedReport.columnNames){
      const settingsColumns = this.selectedReport.columnNames.split(',');
      this.columns.forEach((section) => {
        section.columns.forEach((col) => {
          col.checked = false;
          settingsColumns.forEach((settingsCol) => {
            if (settingsCol == col.value) {
              col.checked = true;
            }
          });
        });
      });
      this.applyColumnChanges(true);
    }

    // Billing Summary Specific Loading
    if(this.reportTemplate.reportType === 'BILLING_SUMMARY') {
      this.dateRangeControl.dateType = this.selectedReport.dateType;
      if (this.dateRangeControl.dateType == 'BILL POST DATE') {
        this.dateTypeSelected = true;
      } else {
        this.dateTypeSelected = false;
      }
    }

    let isNewReport = this.selectedReport.reportId == null;

    // Billing Summary Specific Variables
    let temp = '';
    let cat = '';
    if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      temp = 'SPECIFIC_SITE';
      cat = this.sitesControl.selectedSite.siteId;
    }
    else {
      temp = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null;
      cat = this.sitesControl.selectedCategory ? this.sitesControl.selectedCategory.toString() : null;
      if(temp==='All Sites')
        temp = 'ALL_SITES'
      if (this.sitesControl.siteRequest == 'GROUP') {
        cat = '';
      }
    }

    const saveReportRequest: IBillingReport = {
      reportType: this.reportTemplate.reportType,
      reportId: this.selectedReport.reportId,
      accountId: this.myAccount.id,
      reportName: this.newReportName ? this.newReportName : this.selectedReport.reportName ? this.selectedReport.reportName : 'Billing Report 01',
      columnNames: this.getColumnNamesString(),
      defaultReport: this.makeDefault ? 1 : 0,
      publicFlag: this.makePublic ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      groupId: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id.toString() : null : null,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.endDate,
      dateType: this.dateTypeSelected ? 'BILL POST DATE' : 'METER READ END DATE',
      siteRequest: this.sitesControl.siteRequest ? this.sitesControl.siteRequest : null,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: temp,
      siteGroupCategory: cat
    };
    this.utility.saveReport(saveReportRequest, isNewReport)
    .then((reportId) => {
        this.selectedReport.reportId = reportId;
        this.getReportSettings();
    })
    .catch((error) => {
        // it will load default report
        this.getReportSettings(true);
    });
  }

  reportSelectedHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    this.startDate = loadSelectedReport.startDate;
    this.endDate = loadSelectedReport.endDate;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
      }
    });

    // If we have the column editor on this report
    if(this.selectedReport.columnNames){
      const settingsColumns = this.selectedReport.columnNames.split(',');
      this.columns.forEach((section) => {
        section.columns.forEach((col) => {
          col.checked = false;
          settingsColumns.forEach((settingsCol) => {
            if (settingsCol == col.value) {
              col.checked = true;
            }
          });
        });
      });
      this.applyColumnChanges(true);
    }

    // Billing Summary Specific Loading
    if(this.reportTemplate.reportType === 'BILLING_SUMMARY') {
      this.dateRangeControl.dateType = this.selectedReport.dateType;
      if (this.dateRangeControl.dateType == 'BILL POST DATE') {
        this.dateTypeSelected = true;
      } else {
        this.dateTypeSelected = false;
      }
    }
    this.generateBillingReportData();
  }

  // Getting columns from Subscribe to Report Template
  columnChangeHandler($event){
    this.tableColumns = [];
    this.columns = $event;
    this.columns && this.columns.forEach((section) => {
      section.columns.forEach((col) => {
        const tableCol = Object.assign({}, col);
        this.tableColumns.push(tableCol);
      });
    });
    this.updateScrollbarLength();
  }

  exportHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the BillingReport Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
      }
    });

    // If we have the column editor on this report
    if(this.selectedReport.columnNames){
      const settingsColumns = this.selectedReport.columnNames.split(',');
      this.columns.forEach((section) => {
        section.columns.forEach((col) => {
          col.checked = false;
          settingsColumns.forEach((settingsCol) => {
            if (settingsCol == col.value) {
              col.checked = true;
            }
          });
        });
      });
      this.applyColumnChanges(true);
    }

    // Billing Summary Specific Loading
    if(this.reportTemplate.reportType === 'BILLING_SUMMARY') {
      this.dateRangeControl.dateType = this.selectedReport.dateType;
      if (this.dateRangeControl.dateType == 'BILL POST DATE') {
        this.dateTypeSelected = true;
      } else {
        this.dateTypeSelected = false;
      }
    }
    this.dataService.setLoading(true);
    this.exportToExcel();
  }

  reportDeletedHandler($event){
    this.reportSettingsService.deleteReportSetting(this.myAccount.id, this.selectedReport.reportId, this.dataService.getUserSource().login,this.reportTemplate.reportType, this.selectedReport.publicFlag).subscribe(
      () => {
        this.getReportSettings(true);
      }
    );
  }
  /**************************************************************
  Methods For Subscribe to Report Template - End
  **************************************************************/

}
