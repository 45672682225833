      import {Injectable, EventEmitter} from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {PortalService} from "../shared/portal.service";


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from "@angular/common/http";

import { ContentResponse } from "../shared/entities/contentResponse";
import { NavResponse } from '../shared/entities/navResponse';
import { nrgNav } from '../../assets/api/nrg-nav/nrg-nav';

@Injectable()
export class PreAuthDataService {

  headers: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getHeaderNavData() {
    let url = environment.getNavDataUrl;
    return this.httpClient
      .get(url)
      .map((response: HttpResponse<any>) => {
        let contentResponse = this.mapToType(response, "Navigation");
        return contentResponse
      })
      .catch(this.handleError);
  }

  getFooterNavData() {
    let url = environment.getNavDataUrl;
    return this.httpClient
      .get(url)
      .map((response: HttpResponse<any>) => {
        let contentResponse = this.mapToType(response, "Footer");
        return contentResponse
      })
      .catch(this.handleError);
  }


  mapToType(response: any, type:string) {
    let navResponse: NavResponse = {} as any;
    navResponse.personalizationTags = response.contentPayload.personalizationTags;
    let primaryChild = response.contentPayload.children[0];
    navResponse.layoutType = primaryChild.type;
    primaryChild.payload.primary.forEach((content) => {
        if(content.type === type) {
            navResponse.content = content;
        }
    })
    return navResponse;
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
