import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IProfile } from '../shared/entities/profile';
import { PortalService } from '../shared/portal.service';
import { IUserAccount } from '../shared/entities/profile';
import { Authority } from '../shared/const/authority';
import { Permissions } from '../shared/const/permissions';

@Component({
  selector: 'app-tp-dashboard',
  templateUrl: './tp-dashboard.component.html',
  styleUrls: ['./tp-dashboard.component.scss']
})
export class TpDashboardComponent implements OnInit, OnDestroy {
  profile: IProfile;
  private profileSubscription: Subscription;
  private accountSourceSubscription: Subscription;
  userAccount: IUserAccount;

  constructor(private dataService: DataService,
    private portalService: PortalService,
    private router: Router) { }

  ngOnInit() {
    this.dataService.setTitleSource('Third Party Dashboard');
    this.dataService.setSelectedNavItem('dashboard');
    this.setCurrentActivePage('dashboard');
    this.profile = this.dataService.getProfile();
    this.profileSubscription = this.dataService.profileUpdated.subscribe((profile) => {
      this.profile = profile;
    });
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(account => {
      this.userAccount = account;
      if (!this.hasPostSaleAccess(this.userAccount)) {
        if (this.hasPricingEnabled(this.userAccount)) {
          this.router.navigate(['/pricing']);
        } else {
          this.dataService.setAccessDenied(true);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.accountSourceSubscription) {
      this.accountSourceSubscription.unsubscribe();
    }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  hasPricingEnabled(account: IUserAccount) {
    if (account.pricingFlagEnabled) {
      if (this.portalService.userHasAuthority(Authority.PricingAll) || this.portalService.userHasAuthority(Authority.PricingUser)) {
        return true;
      } else if (!this.portalService.userHasAuthority(Authority.SuperUser)) {
        if (account.accessLevel.toUpperCase() === 'ADMIN') {
          return true;
        }
      }
    }
    return false;
  }

  hasPostSaleAccess(account: IUserAccount) {
    if (account.accessLevel.toUpperCase() === 'ADMIN'
      || this.portalService.userHasAuthority(Authority.SuperUserAdmin)
      || this.portalService.userHasAuthority(Authority.SuperUser)) {
      return true;
    }
    return  this.portalService.hasPermission(Permissions.PostSale);
  }


  goToBillingSummary() {
    this.router.navigate(['/third-party/billing-summary']);
  }

  goToBillingComparison() {
    this.router.navigate(['/third-party/billing-comparison']);
  }

  goToPayoutReport() {
    this.router.navigate(['/third-party/payout-report']);
  }

  goToCustomerlist() {
    this.router.navigate(['/third-party/customer-list']);
  }
}
