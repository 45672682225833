import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';

import {DataService} from "../shared/data.service";
import * as moment from 'moment';
import {PortalService} from "../shared/portal.service";
import {IAccount} from "../shared/entities/account";


@Injectable()
export class BillDeliveryService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService, private portalService: PortalService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  
}
