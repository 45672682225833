import { Component, OnInit, Input } from '@angular/core';
import { ProgressStep } from '../shared/entities/progressBarInput';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() currentStep: number;
  @Input() newStyling: boolean;
  @Input() progressSteps : ProgressStep[]; 

  constructor() { }

  ngOnInit() {
  }

}
