import { Component, OnInit, ViewChild } from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import { PreAuthDataService } from '../pre-auth-data.service';
import { DataService } from '../../shared/data.service';
import { FadeAnimation } from '../../animations/fadeAnimation';
import { nrgNav } from '../../../assets/api/nrg-nav/nrg-nav';




@Component({
  selector: 'app-pre-auth-header',
  animations: [
    FadeAnimation
  ],
  templateUrl: './pre-auth-header.component.html',
  styleUrls: ['./pre-auth-header.component.scss']
})
export class PreAuthHeaderComponent implements OnInit {
  navDataExists:boolean;
  layoutType:string;
  headerContent: any | null = null;
  showSubNav: boolean;
  showSideSubNavItems: boolean;
  showSideNav: boolean;
  subNavPosition: number;
  selectedNavItem: any;
  selectedIndex: number;
  showSearchBar: boolean = false;
  searchQuery: string;
  contactUsLabel: string;
  contactUsPath: string;
  investorsLabel: string;
  investorsPath: string;
  activeNavItemIndex: number;
  readyToCloseSubNav: boolean ;
  accessDenied: boolean;

  @ViewChild('searchInput', {static : false}) searchInput: any;

  constructor(
    private dataService: DataService,
    private preAuthDataService: PreAuthDataService
    ) { }

  ngOnInit() {
    this.dataService.setLoading(true);
    this.getNavigationData();
    console.log(this.headerContent);
    this.activeNavItemIndex = -1;
    this.readyToCloseSubNav = false;
    this.showSubNav = false;
    this.dataService.accessDeniedUpdated.subscribe((accessDenied) => {
      this.accessDenied = accessDenied;
    });

    if (window.location.origin.indexOf("quick") == -1) {
      this.accessDenied = true;
    }
    if(window.location.origin.indexOf("self") == -1){
      this.accessDenied = true;
    }



  }

  showSearch(){
    this.showSearchBar = !this.showSearchBar;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchInput.nativeElement.focus();
    },0);
  }

  closeSubNav(){
    this.showSubNav = false;
    this.activeNavItemIndex = -1;
  }

  showNav(navItem: any, index : number) {
    if(navItem == '' && !this.readyToCloseSubNav){
      this.readyToCloseSubNav = true;
    }
    else{
      if(index == -1 && this.readyToCloseSubNav){
        this.closeSubNav();
        this.readyToCloseSubNav = false;
        this.selectedNavItem = null;
        this.showSubNav = false;
      }else{
        this.showSubNav = false;
        this.selectedNavItem = navItem;
        this.selectedIndex = index
        this.showSubNav = this.hasSubNav(navItem);
      }

    }

  }

  handleActiveBorder(index : number){
    this.activeNavItemIndex = index;
  }

  renderSubNav(){
    return this.showSubNav;
  }

  // Hardcoding these values for now
  setSubNavPosition(index: number){
    let pixelsToMove;
    switch(index) {
      case 0: {
         //statements;
         pixelsToMove = 96;
         break;
      }
      case 1: {
         //statements;
         pixelsToMove = 187;
         break;
      }
      case 2: {
        //statements;
        pixelsToMove = 327;
        break;
     }
     case 3: {
      //statements;
      pixelsToMove = 445;
      break;
      }
      case 4: {
        //statements;
        pixelsToMove = 554;
        break;
        }
      default: {
         //statements;
         break;
      }
   }
    let styles = {
      'left': (pixelsToMove) + 'px'
    };
    return styles;
  }

  private hasSubNav(navItem: any): boolean {
    return navItem.payload.primary.payload.items !== undefined;
  }

  navigateTo(dest: string, slug = '') {
    this.showSubNav = false;
    window.location.href= "https://www.nrg.com" +dest;
  }

  backToLogin() {
    const local = "http://localhost:8880/login.html"
    const dev1 = "https://dev1-manage.nrg.com/myaccount/login.html";
    const stg1 = "https://stg1-manage.nrg.com/myaccount/login.html";
    const test1 = "https://test1-manage.nrg.com/myaccount/login.html";
    const pd = "https://manage.nrg.com/myaccount/login.html";
    let loginUrl;
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
       loginUrl = dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      loginUrl = stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      loginUrl = test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      loginUrl = pd;
    }
    else {
      loginUrl = local;
    }
    window.location.href = loginUrl;
  }

  getNavigationData() {
    let response = this.preAuthDataService.getHeaderNavData();

    if(response != null) {
      response.subscribe((headerResponse : any) => {
        this.layoutType = headerResponse.layoutType;
        if (headerResponse.content !== undefined) {
          this.contactUsLabel = headerResponse.content.cta.label;
          this.contactUsPath = headerResponse.content.cta.route;
          this.headerContent = headerResponse.content.children;
        }
        this.headerContent.forEach((element) => {
          if(element.payload.primary.payload.items){
            element.payload.primary.payload.items.forEach((item) => {
              if(item.label == "Investors") {
                this.investorsLabel = item.label;
                this.investorsPath = item.route;
              }
            })
          }
        });
        this.dataService.setLoading(false);
      },(error)=>{
        let headerResponse : any = this.preAuthDataService.mapToType(nrgNav,"Navigation");
        this.layoutType = headerResponse.layoutType;
        if (headerResponse.content !== undefined) {
          this.contactUsLabel = headerResponse.content.cta.label;
          this.contactUsPath = headerResponse.content.cta.route;
          this.headerContent = headerResponse.content.children;
        }
        this.headerContent.forEach((element) => {
          if(element.payload.primary.payload.items){
            element.payload.primary.payload.items.forEach((item) => {
              if(item.label == "Investors") {
                this.investorsLabel = item.label;
                this.investorsPath = item.route;
              }
            })
          }
        });
        this.dataService.setLoading(false);
      });
    } else {
      this.dataService.setLoading(false);
    }
  }

  onSearchEnter(){
    window.location.href= "https://www.nrg.com/search.html?keyword=" + this.searchQuery;
  }

  navigateToInvestors(){
    window.location.href= this.investorsPath;
  }

  navigateToContactUs(){
    window.location.href= "https://www.nrg.com" + this.contactUsPath;

  }

  onFocusOutEvent(){
    this.showSearchBar = false;
  }

  toggleSideNav() {
    this.showSideNav = !this.showSideNav;
  }


  showSideSubNav(navItem: any, index : number) {
    if(this.selectedIndex != null && this.showSideSubNavItems && this.selectedIndex != index){
      this.selectedNavItem = navItem;
      this.selectedIndex = index;
      this.showSideSubNavItems = this.hasSubNav(navItem);
    } else {
      this.selectedIndex = null;
      if (!this.showSideSubNavItems) {
        this.selectedNavItem = navItem;
        this.selectedIndex = index
        this.showSideSubNavItems = this.hasSubNav(navItem);
      } else {
        this.selectedNavItem = null;
        this.showSideSubNavItems = false;
      }
    }
  }
}
