export interface IBrokerEnrollments {
    accountName: string;
    siteId: string;
    contractId: string;
    tdsp: string; // TODO replace with enum
    street: string;
    city: string;
    state: string;
    zip: string;
    contractStartDate: string;
    contractEndDate: string;
    moveInDate: string;
    contractStatus: string; // TODO replace with enum
  }
  

  export interface IBrokerEnrollmentsRequest{
    accountId: string;
    accountName: string;
    startDate: string; // "2017-01-01T00:00:00"
    endDate: string;
    includeNullMoveInDates?: boolean;
    maskingMode?: boolean;
    brand: string;
    tdsp: string;
  }


  export const TSDP = {
    CNP: "CNP - CenterPoint Energy",
    CPL: "CPL - AEP Texas Central Company (CPL)",
    LPL: "LPL - Lubbock Power & Light",
    ONC: "ONC - Oncor Electric Delivery",
    SHL: "SHL - Sharyland McAllen",
    SHY: "SHY - Sharyland Utilities",
    TNP: "TNP - Texas-New Mexico Power Company",
    WTU: "WTU - AEP Texas Central Company (WTU)",
  };