import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FadeAnimation } from '../../../animations/fadeAnimation';
import { DataService } from '../../../shared/data.service';
import { IUserAccount } from '../../../shared/entities/profile';
import { IColumn, IColumnData, IColumnRequest } from './column-data';
import { ColumnSelectorService } from './column-selector.service';

@Component({
  selector: 'app-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss'],
  animations: [FadeAnimation]
})
export class ColumnSelectorComponent implements OnInit, OnDestroy {
  @Input() columns: IColumn[] = [];
  @Input() myAccount: IUserAccount;
  @Input() entity: string;
  @Input() entityId: string; // null if accessing columns for all entities
  @Output() columnsSelected = new EventEmitter<string[]>();
  private unsubscribe$ = new Subject();
  visible = false;
  setAsDefault = false;
  tableColumns: string[];

  constructor(
    private dataService: DataService,
    private columnSelectorService: ColumnSelectorService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  show() {
    this.dataService.setOverlay(true);
    this.visible = true;
    this.setAsDefault = false;
  }

  hide() {
    this.dataService.setOverlay(false);
    this.visible = false;
  }

  applyColumnChanges() {
    this.tableColumns = [];
    this.columns && this.columns.forEach((section) => {
      if (section.selected) {
        this.tableColumns.push(section.key);
        section.data && section.data.forEach((col) => {
          if (col.selected) {
            this.tableColumns.push(col.key);
          }
        });
      }
    });
    this.columnsSelected.emit(this.tableColumns);

    if (this.setAsDefault) {
      this.getSaveColumnRequest$(this.tableColumns)
        .subscribe(
          _res => {},
          err => console.error('Error saving default columns.', err)
        );
    }
    this.hide();
  }

  updateSubSections(column: IColumn, $event) {
    let anyDisabled = false;
    if (column && column.data) {
      for (const section of column.data) {
        if (section.disabled) {
          anyDisabled = true;
          $event.preventDefault();
          break;
        }
      }
    }

    let newSelected = anyDisabled ? column.partialSelected : !column.partialSelected;

    if (column && column.data) {
      for (const section of column.data) {
        if (!section.disabled) {
          section.selected = newSelected;
        }
      }
    }

    this.updatePartialSelected(column);
    if (anyDisabled) {
      column.selected = true;
    }
  }

  updatePartialSelected(column: IColumn) {
    if (!column || !column.data) {
      return;
    }

    const totalSub = column.data.length;
    const totalChecked = column.data.reduce((sum, section) => sum + (section.selected ? 1 : 0), 0);
    column.partialSelected = totalChecked !== totalSub;
  }

  getSaveColumnRequest$(selectedSettings: string[]): Observable<IColumnRequest> {
    return this.entityId ? this.columnSelectorService.saveColumnSettingsForSingleEntity(this.entity, this.myAccount.id, this.entityId, selectedSettings)
      : this.columnSelectorService.saveColumnSettingsForAllEntity(this.entity, this.myAccount.id, selectedSettings);
  }
}
