import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Subject } from 'rxjs';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
import { DataService } from '../../shared/data.service';
import { IUserAccount } from '../../shared/entities/profile';
import { IGenerationSite } from '../generation-site';
import { ShadowMeteringService } from '../shadow-metering.service';

@Component({
  selector: 'app-onsite-generation',
  templateUrl: './onsite-generation.component.html',
  styleUrls: ['./onsite-generation.component.scss']
})
export class OnsiteGenerationComponent implements OnInit, OnDestroy {
  sites: IGenerationSite[];
  private readonly unsubscribe$ = new Subject();
  myAccount: IUserAccount;
  dgenSitesSubscription: Subscription;
  showFuelLevel: boolean;

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(
    private shadowMeteringService: ShadowMeteringService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.setContextualHelp(true);

    this.myAccount = this.dataService.getAccountSource();
    this.dataService.showExportUsageDetail.emit(false);

    if (this.dataService.getDgenSites()) {
      this.sites = this.dataService.getDgenSites();
      this.updateGenerationStatuses();
      IntervalObservable.create(300000)
      .takeUntil(this.unsubscribe$)
      .subscribe(() => {
        const siteIds: string[] = this.sites ? this.sites.map(site => site.id) : [];
        this.shadowMeteringService.getGenerationStatus(this.myAccount.id, siteIds)
          .takeUntil(this.unsubscribe$)
          .subscribe(_res => {});
      });

      this.showFuelLevel = false;
      if(this.sites) {
        this.sites.forEach(siteSel => {
          if(siteSel.generators){
            siteSel.generators.forEach(gens => {
              if(gens.fuelType==="Diesel") {
                this.showFuelLevel = true;
              }
            })
          }
        });
      }
    }


    this.dgenSitesSubscription = this.dataService.dgenSitesUpdated.subscribe(
      sites => {
      this.sites = sites;
      this.updateGenerationStatuses();

      IntervalObservable.create(300000)
      .takeUntil(this.unsubscribe$)
      .subscribe(() => {
        const siteIds: string[] = this.sites ? this.sites.map(site => site.id) : [];
        this.shadowMeteringService.getGenerationStatus(this.myAccount.id, siteIds)
          .takeUntil(this.unsubscribe$)
          .subscribe(_res => {});
      });
    });

  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.dgenSitesSubscription) this.dgenSitesSubscription.unsubscribe();
  }

  updateGenerationStatuses() {
    const siteIds: string[] = this.sites ? this.sites.map(site => site.id) : [];

    if (siteIds.length) {
      // Update generation statuses based on all site ids
      this.shadowMeteringService.getGenerationStatus(this.myAccount.id, siteIds)
        .takeUntil(this.unsubscribe$)
        .subscribe(_res => {});
    }
  }

  get generatorCount(): number {
    if (this.sites) {
      return this.sites.reduce((sum, site) => sum + site.generators.length, 0);
    }
    return 0;
  }

  get generationCapacity(): number {
    // TODO: Use Statuses
    if (this.sites) {
      return this.sites.reduce((sum, site) => sum + site.generators.length, 0);
    }
    return 0;
  }
}
