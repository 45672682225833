import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { YoutubePlayerService } from './youtube-player-service';
import { DomSanitizer } from '@angular/platform-browser';

declare const YT: any;

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.css'],
})
export class YoutubePlayerComponent implements OnInit {
  @ViewChild('youtubePlayer', { static: true }) playerRef: ElementRef;

  player: any;
  videoId: string;
  safeURL: any;

  constructor(private youtubePlayerService: YoutubePlayerService,private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.youtubePlayerService.videoId$.subscribe((videoId) => {
      if (videoId) {
        this.videoId = videoId;
        let videoURL = 'https://youtube.com/embed/' + videoId;
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL);
      }
    });
  }
}