import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {FadeAnimation} from "../animations/fadeAnimation";
import {FadeInAnimation} from "../animations/fadeInAnimation";
import {DateType} from "../shared/const/dateType";
import {IUserAccount} from "../shared/entities/profile";
import {ISiteGroup, ISite} from "../shared/entities/site";
import { IComparisonReport, ITPComparisonChartResponse } from "../shared/entities/comparison";
import {ITPBillingComparisonChartData} from "../shared/entities/billingComparison";
import {CustomerControl} from "../shared/entities/controls";
import {SiteGroupService} from "../site-groups/site-groups.service";
import {PortalService} from "../shared/portal.service";
import {Subscription} from "rxjs";
import * as moment from 'moment';

declare var Highcharts: any;
import {IHelpStep} from "../shared/entities/contextualHelp";
import {BillingComparisonService} from "../billing-comparison/billing-comparison.service";
import {ITPCustomer, ITPGroup} from "../shared/entities/thirdParty";
import {TpCustomerListService} from "../tp-customer-list/tp-customer-list.service";
import {Authority} from "../shared/const/authority";
declare var $: any;

@Component({
  selector: 'app-tp-billing-comparison',
  templateUrl: './tp-billing-comparison.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['tp-billing-comparison.component.scss', '../shared/styles/reports.scss']
})
export class TpBillingComparisonComponent implements OnInit {

  reports: IComparisonReport[];
  publicReports: IComparisonReport[];
  privateReports: IComparisonReport[];
  selectedReport: IComparisonReport;
  myAccount: IUserAccount;
  customerGroups: ITPGroup[] = [];
  customersForAccount: ITPCustomer[] = [];
  filteredCustomers: ITPCustomer[] = [];

  isSuperUser: boolean;
  customerControl: CustomerControl;

  showDateType: boolean = false;
  showYears: boolean = false;
  showSites: boolean = false;
  enableSave: boolean = false;
  makeDefault: boolean = true;
  makePublic: boolean = false;
  makeNewPublic: boolean = false;
  makeNewDefault: boolean = true;
  noDataError: boolean = false;
  isMobile: boolean = false;
  maskMode: boolean = false;
  showSave: boolean = false;
  showReports: boolean = false;
  showSettings: boolean = false;

  selectedCustomerDescription: string;
  selectedDateType: any;
  selectedGrouping: ISiteGroup;
  siteIndex: number = -1;

  helpStep: IHelpStep;

  DateType;

  newReportName: string;
  tempYear1: number;
  tempYear2: number;
  years: number[];

  chartData: ITPBillingComparisonChartData = {
    totalChargesYear1: [],
    totalChargesYear2: [],
    totalChargesDelta: [],
    usageYear1: [],
    usageYear2: [],
    usageDelta: [],
    customerCountYear1: [],
    customerCountYear2: [],
    customerCountDelta: []
  };

  tableColumns = ['Year', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  accountSourceSubscription: Subscription;
  customerGroupsSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;
  exportSubscription: Subscription;

  constructor(private dataService: DataService,
              private service: BillingComparisonService,
              private siteGroupsService: SiteGroupService,
              private portalService: PortalService,
              private tpCustomerListService: TpCustomerListService) {}

  ngOnInit() {

    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        numericSymbols: null
      }
    });
    this.customerControl = new CustomerControl();
    this.dataService.setTitleSource('Billing Comparison Report');
    this.setCurrentActivePage('billing');
    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount) {
      this.getCustomersForAccount();
    }
    this.maskMode = this.dataService.getMaskMode();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.dataService.setLoading(true);
        this.myAccount = account;
        this.getCustomersForAccount();
      }
    );
    this.customerGroups = this.dataService.getTpGroupSource();
    if(this.customerGroups) {
      this.loadReports();
    }
    this.customerGroupsSubscription = this.dataService.tpGroupSourceUpdated.subscribe((groups) => {
      this.customerGroups = groups;
      this.loadReports();
    });

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.DateType = DateType;

    this.isMobile = this.dataService.getIsMobile();

    document.addEventListener("click", ($event) => {
      if($($event.target).parents('#sitesSection').length == 0){
        this.showSites = false;
      }
      if($($event.target).parents('#yearsSection').length == 0){
        this.showYears = false;
      }
      if($($event.target).parents('#optionsSection').length == 0){
        this.showSettings = false;
      }
      if($($event.target).parents('#reportsSection').length == 0){
        this.showReports = false;
      }
      if($($event.target).parents('#saveSection').length == 0){
        this.showSave = false;
      }
      if($($event.target).parents('#dateTypeSection').length == 0){
        this.showDateType = false;
      }
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (res) => {
        if(!this.noDataError){
          this.dataService.setLoading(true);
          this.exportToExcel();
        }
      }
    );

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.customerGroupsSubscription) this.customerGroupsSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getCustomersForAccount() {
    this.tpCustomerListService.getTPCustomers(this.myAccount.id).subscribe((customers : any) => {
      this.customersForAccount = customers;
    })
  }

  loadReports(keepSelected?: boolean) {
        if (!this.dataService.getLoading()) {
          this.dataService.setLoading(true);
        }
      
        this.years = [moment().year(), moment().year() - 1, moment().year() - 2, moment().year() - 3];

        this.service.getTpReports(this.myAccount.id).subscribe(
          (reports) => {
            this.reports = reports;
            let defaultReport = null;
            if (!keepSelected) {
              if (this.reports.length > 0) {
                this.reports.forEach((report) => {
                  if (report.defaultReport) {
                    defaultReport = report;
                  }
                });
                if (!defaultReport) {
                  this.selectReport(this.reports[0]);
                } else {
                  this.selectReport(defaultReport);
                }
              } else {
                defaultReport = this.getDefaultReport();
                this.selectReport(defaultReport);
                this.enableSave = true;
                this.saveReport();
              }
            }
            this.publicReports = [];
            this.privateReports = [];
            this.reports.forEach((report) => {
              if(report.publicFlag){
                this.publicReports.push(report);
              } else {
                this.privateReports.push(report);
              }
            })
          }
        );
      
    
  }

  exportToExcel() {
    this.selectedReport.accountName = this.dataService.getAccountSource().name;
    this.selectedReport.maskingMode = this.maskMode;
    this.service.exportBillingCompReport(this.selectedReport);
  }

  toggleSave(){
    this.showSave = !this.showSave;
  }

  toggleReports(){
    this.showReports = !this.showReports;
  }

  toggleSettings(){
    this.showSettings = !this.showSettings;
  }

  renameReport() {
    this.selectedReport.reportName = this.newReportName;
    this.enableSave = true;
    this.saveReport();
    $('#renameModal').modal('hide');
  }

  createNewReport(){
    let newReport = this.getDefaultReport();
    newReport.reportName = this.newReportName;
    this.makePublic = this.makeNewPublic;
    this.makeDefault = this.makeNewDefault;
    this.enableSave = true;
    this.selectedReport = newReport;
    this.saveReport();
    $('#createReportModal').modal('hide');
  }

  openCreateNewReport(){
    this.makeNewDefault = false;
    this.makeNewPublic = false;
    this.newReportName = '';
  }

  goPublic(){
    this.toggleSettings();
    this.makePublic = true;
    this.enableSave = true;
    this.saveReport();
  }

  duplicateAndSave(){
    this.makePublic = false;
    this.makeDefault = false;
    this.newReportName = '';
    $('#renameModal').modal('show');
  }

  getDefaultReport(){
    let defaultReport: IComparisonReport = {
      accountId: this.myAccount.id,
      reportName: 'Comparison Report 01',
      dateType: this.DateType.MeterReadEndDate.value,
      year1: this.years[0],
      year2: this.years[1],
      customerRequest: 'ALL_CUSTOMERS',
      defaultReport: 0,
      publicFlag: 0,
      createdUserId: this.dataService.getUserSource().login
    };
    return defaultReport;
  }

  saveNewReport(){
    this.selectedReport.reportId = null;
    $('#renameModal').modal('show');
    this.toggleSave();
  }

  setAsDefault(){
    this.toggleSettings();
    this.makeDefault = true;
    this.enableSave = true;
    this.saveReport();
  }

  selectReport(report: IComparisonReport){
    if(!this.dataService.getLoading()){
      this.dataService.setLoading(true);
    }
    this.selectedReport = report;
    if(this.selectedReport.dateType == this.DateType.MeterReadEndDate.value){
      this.selectedDateType = this.DateType.MeterReadEndDate;
    } else {
      this.selectedDateType = this.DateType.BillingDate;
    }
    this.makeDefault = this.selectedReport.defaultReport == 1;
    this.makePublic = this.selectedReport.publicFlag == 1;
    this.getCustomerControlDisplay();
    this.createCharts();
  }

  getCustomerControlDisplay() {
    if(this.selectedReport.customerRequest == 'ALL_CUSTOMERS') {
      this.customerControl.display = 'All Customers';
    } else if(this.selectedReport.customerRequest == 'GROUP_CATEGORY'){
      this.customerControl.display = this.selectedReport.groupCategory;
    } else if(this.selectedReport.customerRequest == 'SPECIFIC_CUSTOMER'){
      this.customerControl.display = (this.isSuperUser ? this.customerControl.selectedCustomer.accountId : '') + ' - ' + this.customerControl.selectedCustomer.name;
    }
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  populateCustomerControl() {
    // Load data from selectedReport into customerControl
    this.customerControl.customerRequest = this.selectedReport.customerRequest;
    if(this.customerControl.customerRequest == 'GROUP_CATEGORY'){
      this.customerGroups.forEach((group) => {
        if(group.id == this.selectedReport.groupId){
          this.selectGroup(group);
        }
      });
      this.customerControl.selectedCategory = this.selectedReport.groupCategory;
    } else if(this.customerControl.customerRequest == 'SPECIFIC_CUSTOMER'){
      this.filteredCustomers.forEach((customer, index) => {
        if(customer.accountId == this.selectedReport.customerAccountId){
          this.selectCustomer(customer, index);
        }
      })
    }
  }

  saveReport() {
    if(this.enableSave){
      this.dataService.setLoading(true);
      this.selectedReport.dateType = this.selectedDateType.value;
      this.selectedReport.defaultReport = this.makeDefault ? 1 : 0;
      this.selectedReport.publicFlag = this.makePublic ? 1 : 0;
      this.service.saveTpReport(this.selectedReport).subscribe((savedReport) => {
        this.selectedReport = savedReport;
        // console.log('publicFlag is ' + this.selectedReport.publicFlag);
        this.enableSave = false;
        this.loadReports(true);
        this.makeNewPublic = this.makeNewDefault = false;
        setTimeout(()=> {
          this.dataService.setLoading(false);
        }, 1000);
      }, err => {
        if(err.status == 409){
          this.selectedReport.publicFlag = 0;
          this.dataService.setLoading(false);
          $('#duplicateModal').modal('show');
        }
      })
    }
  }

  deleteReport() {
    this.dataService.setLoading(true);
    this.selectedReport.dateType = this.selectedReport.dateType.value;
    this.service.deleteTpReport(this.selectedReport).subscribe(() => {
      this.loadReports();
      setTimeout(()=> {
        this.dataService.setLoading(false);
      }, 1000);
    })
  }

  selectGroup(group: ISiteGroup){
    this.customerControl.selectedGrouping = group;
    if(this.customerControl.selectedGrouping.id) {
      if(this.customerControl.selectedGrouping.groupName.length > 15){
        this.customerControl.selectedGrouping.display = this.customerControl.selectedGrouping.groupName.substring(0,15) + '...'
      } else {
        this.customerControl.selectedGrouping.display = this.customerControl.selectedGrouping.groupName;
      }
      this.tpCustomerListService.getTPGroupCategories(this.myAccount.id, this.customerControl.selectedGrouping.id.toString()).subscribe((categories : any) => {
        this.customerControl.categoriesForGroup = categories;
      })
    }
    this.customerControl.selectedCategory = null;
  }

  getContractNickname(contractNum: string){
    return this.portalService.getContractNickname(contractNum);
  }

  selectCategory(category: string){
    this.customerControl.selectedCategory = category;
  }

  getCategoryDisplay(){
    if(this.customerControl.selectedCategory) {
      if(this.customerControl.selectedGrouping.groupName == 'COLLECTIVE_BILLING' && this.maskMode){
        return '80012345';
      }
      if(this.customerControl.selectedGrouping.display == 'Contracts') {
        return this.getContractNickname(this.customerControl.selectedCategory);
      } else {
        return this.customerControl.selectedCategory;
      }
    } else {
      return 'Select a category';
    }
  }

  defaultChanged() {
    if(this.makeDefault && this.selectedReport.defaultReport || !this.makeDefault && !this.selectedReport.defaultReport) {
      this.enableSave = false;
    } else {
      this.enableSave = true;
    }
  }

  publicChanged() {
    if(this.makePublic && this.selectedReport.publicFlag || !this.makePublic && !this.selectedReport.publicFlag) {
      this.enableSave = false;
    } else {
      this.enableSave = true;
    }
  }

  createCharts() {
    this.dataService.setLoading(true);
    this.service.getTpCharts(this.selectedReport).subscribe(
      (chartResponse) => {
        this.extractChartData(chartResponse);
        this.noDataError = false;
        this.createChargesChart();
        this.createUsageChart();
        if(this.customerControl.customerRequest != 'SPECIFIC_CUSTOMER') {
          this.createSitesChart();
        }
        this.dataService.setLoading(false);
      }, err => {
        if(err.status == 404){
          this.chartData = null;
          this.noDataError = true;
        }
      }
    );
  }

  convertkWhToMWh(number: number) {
    return number/1000;
  }

  extractChartData(chartResponse: ITPComparisonChartResponse) {
    let currentMonth = 1;
    let emptyVal = 0;
    this.chartData.totalChargesYear1 = [];
    this.chartData.totalChargesYear1Partial = false;
    this.chartData.customerCountYear1 = [];
    this.chartData.usageYear1 = [];
    this.chartData.usageYear1Partial = false;

    if(chartResponse.year1Values){
      chartResponse.year1Values.sort((val1,val2) => {
        return val1.month < val2.month ? -1 : 1;
      });
      chartResponse.year1Values.forEach((item) => {
        if(currentMonth < item.month){
          while(currentMonth < item.month) {
            this.chartData.totalChargesYear1.push(null);
            this.chartData.customerCountYear1.push(null);
            this.chartData.usageYear1.push(null);
            currentMonth++;
          }
        }
        currentMonth++;
        this.chartData.totalChargesYear1.push(item.totalCharges);
        this.chartData.customerCountYear1.push(item.customerCount);
        this.chartData.usageYear1.push(this.convertkWhToMWh(item.usage));
      });
    }

    while(this.chartData.totalChargesYear1.length < 12){
      this.chartData.totalChargesYear1Partial = true;
      this.chartData.totalChargesYear1.push(null);
    }
    while(this.chartData.customerCountYear1.length < 12){
      this.chartData.customerCountYear1.push(null);
    }
    while(this.chartData.usageYear1.length < 12){
      this.chartData.usageYear1Partial = true;
      this.chartData.usageYear1.push(null);
    }

    this.chartData.totalChargesYear2 = [];
    this.chartData.totalChargesYear2Partial = false;
    this.chartData.customerCountYear2 = [];
    this.chartData.usageYear2 = [];
    this.chartData.usageYear2Partial = false;

    if(chartResponse.year2Values){
      chartResponse.year2Values.sort((val1,val2) => {
        return val1.month < val2.month ? -1 : 1;
      });
      currentMonth = 1;
      chartResponse.year2Values.forEach((item) => {
        if(currentMonth < item.month){
          while(currentMonth < item.month) {
            this.chartData.totalChargesYear2.push(null);
            this.chartData.customerCountYear2.push(null);
            this.chartData.usageYear2.push(null);
            currentMonth++;
          }
        }
        currentMonth++;
        this.chartData.totalChargesYear2.push(item.totalCharges);
        this.chartData.customerCountYear2.push(item.customerCount);
        this.chartData.usageYear2.push(this.convertkWhToMWh(item.usage));
      });
    }

    while(this.chartData.totalChargesYear2.length < 12){
      this.chartData.totalChargesYear2Partial = true;
      this.chartData.totalChargesYear2.push(null);
    }
    while(this.chartData.customerCountYear2.length < 12){
      this.chartData.customerCountYear2.push(null);
    }
    while(this.chartData.usageYear2.length < 12){
      this.chartData.usageYear2Partial = true;
      this.chartData.usageYear2.push(null);
    }

    this.chartData.totalChargesDelta = [];
    this.chartData.usageDelta = [];
    this.chartData.customerCountDelta = [];
    let delta = 0;

    for(let i in this.chartData.totalChargesYear1){
      if(this.chartData.totalChargesYear1[i] == null || this.chartData.totalChargesYear2 == null){
        this.chartData.totalChargesDelta.push(null);
      } else {
        delta = this.chartData.totalChargesYear1[i] - this.chartData.totalChargesYear2[i];
        if(delta){
          this.chartData.totalChargesDelta.push(delta);
        } else {
          this.chartData.totalChargesDelta.push(null);
        }
      }
    }

    for(let i in this.chartData.usageYear1){
      if(this.chartData.usageYear1[i] == null || this.chartData.usageYear2 == null){
        this.chartData.usageDelta.push(null);
      } else {
        delta = this.chartData.usageYear1[i] - this.chartData.usageYear2[i];
        if(delta){
          this.chartData.usageDelta.push(delta);
        } else {
          this.chartData.usageDelta.push(null);
        }
      }
    }

    for(let i = 0; i < this.chartData.customerCountYear1.length; i++){
      if(this.chartData.customerCountYear1[i] == null || this.chartData.customerCountYear2[i] == null){
        this.chartData.customerCountDelta.push(null);
      } else {
        delta = this.chartData.customerCountYear1[i] - this.chartData.customerCountYear2[i];
        if(delta){
          this.chartData.customerCountDelta.push(delta);
        } else {
          this.chartData.customerCountDelta.push(null);
        }
      }
    }

    // Get Totals - Thanks Moses...
    this.chartData.totalChargesYear1Total = 0;
    this.chartData.totalChargesYear1.forEach((charge) => { this.chartData.totalChargesYear1Total += charge });
    this.chartData.totalChargesYear2Total = 0;
    this.chartData.totalChargesYear2.forEach((charge) => { this.chartData.totalChargesYear2Total += charge });
    this.chartData.totalChargesDeltaTotal = 0;
    this.chartData.totalChargesDelta.forEach((charge) => { this.chartData.totalChargesDeltaTotal += charge });
    this.chartData.usageYear1Total = 0;
    this.chartData.usageYear1.forEach((usage) => { this.chartData.usageYear1Total += usage });
    this.chartData.usageYear2Total = 0;
    this.chartData.usageYear2.forEach((usage) => { this.chartData.usageYear2Total += usage });
    this.chartData.usageDeltaTotal = 0;
    this.chartData.usageDelta.forEach((usage) => { this.chartData.usageDeltaTotal += usage });
  }

  toggleDateType() {
    this.showDateType = !this.showDateType;
    this.showYears = this.showSites = false;
  }

  toggleYears() {
    this.showYears = !this.showYears;
    if(this.showYears){
      this.tempYear1 = this.selectedReport.year1;
      this.tempYear2 = this.selectedReport.year2;
    }
    this.showDateType = this.showSites = false;
  }

  toggleCustomers() {
    this.showSites = !this.showSites;
    if(this.showSites) {
      this.populateCustomerControl();
    } else {
      this.customerControl.customerSearch = '';
    }
    this.showDateType = this.showYears = false;
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType;
    this.selectedReport.dateType = dateType.value;
    this.toggleDateType();
    this.enableSave = true;
    this.createCharts();
  }

  selectYear1(year: number) {
    this.tempYear1 = year;
  }

  selectYear2(year: number) {
    this.tempYear2 = year;
  }

  applyYears() {
    this.selectedReport.year1 = this.tempYear1;
    this.selectedReport.year2 = this.tempYear2;
    this.enableSave = true;
    this.toggleYears();
    this.createCharts();
  }

  searchCustomers() {
    this.filteredCustomers = [];
    this.customersForAccount.forEach((customer) => {
      if(customer.accountId.match(this.customerControl.customerSearch) ||
        (customer.name && customer.name.toLowerCase().includes(this.customerControl.customerSearch.toLowerCase())) ||
        this.customerControl.customerSearch == ''){
        this.filteredCustomers.push(customer);
      }
    });
  }

  navigateTypeahead(down: boolean) {
    if(down && this.siteIndex < this.filteredCustomers.length-1){
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredCustomers = [];
  }

  selectCustomer(customer: ITPCustomer, index?: number){
    if(this.filteredCustomers.length > 0){
      if(this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if(index) {
        this.customerControl.selectedCustomer = this.filteredCustomers[index];
      } else {
        this.customerControl.selectedCustomer = this.filteredCustomers[this.siteIndex];
      }
      this.customerControl.customerSearch = (this.isSuperUser ? this.customerControl.selectedCustomer.accountId + ' ' : '') + this.customerControl.selectedCustomer.name;
      this.resetTypeahead();
    }
  }

  applyCustomers() {
    // Apply changes in customerControl to selectedReport
    this.toggleCustomers();
    this.selectedReport.customerRequest = this.customerControl.customerRequest;
    if(this.selectedReport.customerRequest == 'ALL_CUSTOMERS'){
      this.selectedReport.customerAccountId = this.selectedReport.systemGroupName = this.selectedReport.groupId = this.selectedReport.groupCategory = null;
    } else if(this.selectedReport.customerRequest == 'GROUP_CATEGORY'){
      this.selectedReport.groupCategory = this.customerControl.selectedCategory;
      if(this.customerControl.selectedGrouping.id){
        this.selectedReport.groupId = this.customerControl.selectedGrouping.id;
      } else {
        this.selectedReport.systemGroupName = this.customerControl.selectedGrouping.groupName;
      }
      this.selectedReport.customerAccountId = null;
    } else if(this.selectedReport.customerRequest == 'SPECIFIC_CUSTOMER'){
      this.selectedReport.groupCategory = null;
      this.selectedReport.customerAccountId = this.customerControl.selectedCustomer.accountId;
    }
    this.enableSave = true;
    this.getCustomerControlDisplay();
    this.createCharts();
  }

  formatDelta(delta: number, dollar?: boolean, twoDecimals?: boolean) {
    if(!delta && delta != 0){
      return '--'
    }
    if(delta >= 0) {
      return this.numberWithCommas(delta, dollar, twoDecimals);
    } else {
      return dollar ? '-' + this.numberWithCommas(Math.abs(delta), dollar, twoDecimals) : this.numberWithCommas(delta, dollar, twoDecimals);
    }
  }

  numberWithCommas(x, dollar?: boolean, twoDecimals?: boolean) {
    if(x == null){
      return '--'
    }
    let response = this.portalService.numberWithCommas(x);
    if(dollar || twoDecimals){
      response = this.portalService.numberWithCommas(x.toFixed(2));
    }
    if(dollar){
      response = '$' + response;
    }
    return response;
  }

  createChargesChart() {
    Highcharts.chart('chargesChart', {
      chart: {
        height: this.isMobile ? 250 : 350,
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis : {
        title: {text: 'CURRENT CHARGES'},
        labels: {
          formatter: function () {
            return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }
      },
      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>${point.y: ,0.2f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.totalChargesYear1
      },
        {
          name: this.selectedReport.year2,
          showInLegend: false,
          type: 'area',
          keys: ['y', 'selected'],
          data: this.chartData.totalChargesYear2
        }
      ]
    });
  }

  createUsageChart() {
    Highcharts.chart('usageChart', {
      chart: {
        height: this.isMobile ? 250 : 350,
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis : { title: { text: 'USAGE (MWh)'}},
      credits: {
        enabled: false
      },

      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y: ,0.2f} MWh</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.usageYear1
      },{
        name: this.selectedReport.year2,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.usageYear2
      }]
    });
  }

  createSitesChart() {
    Highcharts.chart('sitesChart', {
      chart: {
        type: 'column',
        height: this.isMobile ? 250 : 350
      },
      exporting: { enabled: false },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'NUMBER OF CUSTOMERS'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y: 0.0f} customer(s)</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        data: this.chartData.customerCountYear1
      }, {
        name: this.selectedReport.year2,
        showInLegend: false,
        data: this.chartData.customerCountYear2

      }]
    });
  }
}
