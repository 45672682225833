import { Injectable } from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { IBrokerEnrollments, IBrokerEnrollmentsRequest } from '../shared/entities/brokerEnrollments';
import {DataService} from '../shared/data.service';
import {PortalService} from "../shared/portal.service";



import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'


@Injectable()
export class BrokerEnrollmentsService {

  private headers: Headers;

  constructor(private http: Http, private portalService: PortalService, private dataService: DataService) {
     this.headers = new Headers({'Content-Type': 'application/json'});
   }


  getEnrollmentsReport(request: IBrokerEnrollmentsRequest): Observable<IBrokerEnrollments[]> {
    // if(environment.production){
      let url = environment.getEnrollmentsReportUrl.replace('{accountId}', request.accountId.toString());
      return this.http
      .post(url, request, {headers: this.headers})
      .map((response: Response) => <IBrokerEnrollments[]>response.json())
      .catch(this.handleError);
  }

  exportEnrollmentsReport(request: IBrokerEnrollmentsRequest): any {
    let url = environment.production ? environment.exportEnrollmentsReportUrl.replace('{accountId}', request.accountId.toString()) : environment.exportEnrollmentsReportUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.http
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}


