import { Component, Input } from '@angular/core';

@Component({
  selector: 'magnifying-glass',
  templateUrl: './magnifying-glass.component.html',
  styleUrls: ['magnifying-glass.component.scss']
})
export class MagnifyingGlassComponent {

  constructor() { }

  // Text for the step description/title
  @Input()
  text: any[];

  // Will be left or right
  @Input()
  side: any[];

}
