import {ContextualHelpDev, ContextualHelpProd, ContextualHelpStage} from "./contextualHelp";

export const Prod = {
  environment: 'PROD',
  brokerPrefix: '/myaccount/private',
  loginUrl: 'https://www.reliant.com/public/altLogon.htm',
  loginUrlNrg: 'https://manage.nrg.com/myaccount/login.html',
  logoutUrl: 'https://www.reliant.com/public/logout.htm',
  logoutUrlNrg: 'https://manage.nrg.com/myaccount/login.html?signout=true',
  contextualHelp: ContextualHelpProd,
  nrgContentUrl: 'https://www.nrg.com',
  adobeLaunchURL: '//assets.adobedtm.com/bdc402f2525d/7666b8a9756e/launch-01b704bfe3fc.min.js'
};

export const Test = {
  environment: 'TEST',
  brokerPrefix: '/myaccount/private',
  loginUrl: 'https://stg1-www.reliant.com/public/altLogon.htm',
  loginUrlNrg: 'https://test1-manage.nrg.com/myaccount/login.html',
  logoutUrl: 'https://stg1-www.reliant.com/public/logout.htm',
  logoutUrlNrg: 'https://test1-manage.nrg.com/myaccount/login.html?signout=true',
  contextualHelp: ContextualHelpStage,
  nrgContentUrl: 'https://stg-www.nrg.com',
  adobeLaunchURL: '//assets.adobedtm.com/bdc402f2525d/7666b8a9756e/launch-675ac3355c69-staging.min.js'
};

export const Stage = {
  environment: 'STAGE',
  brokerPrefix: '/myaccount/private',
  loginUrl: 'https://stg1-www.reliant.com/public/altLogon.htm',
  loginUrlNrg: 'https://stg1-manage.nrg.com/myaccount/login.html',
  logoutUrl: 'https://stg1-www.reliant.com/public/logout.htm',
  logoutUrlNrg: 'https://stg1-manage.nrg.com/myaccount/login.html?signout=true',
  contextualHelp: ContextualHelpStage,
  nrgContentUrl: 'https://stg-www.nrg.com',
  adobeLaunchURL: '//assets.adobedtm.com/bdc402f2525d/7666b8a9756e/launch-675ac3355c69-staging.min.js'
};

export const Dev = {
  environment: 'DEV',
  brokerPrefix: '/myaccount/private',
  loginUrl: 'https://dev1-www.reliant.com/public/altLogon.htm',
  loginUrlNrg: 'https://dev1-manage.nrg.com/myaccount/login.html',
  logoutUrl: 'https://dev1-www.reliant.com/public/logout.htm',
  logoutUrlNrg: 'https://dev1-manage.nrg.com/myaccount/login.html?signout=true',
  contextualHelp: ContextualHelpDev,
  adobeLaunchURL: '//assets.adobedtm.com/bdc402f2525d/7666b8a9756e/launch-54707de0910b-development.min.js'
};
