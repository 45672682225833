import {OnInit} from "@angular/core";
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import {MarketsService} from "../markets.service";
import {PortalService} from "../../shared/portal.service";
import {IFlowRate, IReportSettings, IReportTerms} from "../../shared/entities/markets";
import {IProduct, IZone} from "../../shared/entities/product";
import {IContract} from "../../shared/entities/contract";
import { DataService } from "../../shared/data.service";
import { ITermLength, ICustomTerm } from "./forwardPrice";


declare var Highcharts: any;

const staticPriceTypes = [
  {name: 'Energy Price', value: 'ENERGY_PRICE'},
  {name: 'Heat Rate', value: 'HEAT_RATE'},
  {name: 'Natural Gas', value: 'NYMEX'}
];

const staticPeakTypes = [
  {name: 'RTC', value: 'RTC'},
  {name: 'On Peak', value: 'ON_PEAK'},
  {name: 'Off Peak', value: 'OFF_PEAK'},
  {name: 'Nights', value: 'NIGHTS'},
  {name: 'Weekend', value: 'WEEKEND'},
  {name: 'RTC Heat', value: 'RTC_HEAT_RATE'}
];

const staticTermLengths = [
  { value: 6, selected: false, disabled: false, exceededLength: false },
  { value: 12, selected: true, disabled: false, exceededLength: false },
  { value: 18, selected: false, disabled: false, exceededLength: false },
  { value: 24, selected: true, disabled: false, exceededLength: false },
  { value: 30, selected: false, disabled: false, exceededLength: false },
  { value: 36, selected: true, disabled: false, exceededLength: false },
  { value: 48, selected: true, disabled: false, exceededLength: false },
  { value: 60, selected: true, disabled: false, exceededLength: false },
  { value: 120, selected: true, disabled: false, exceededLength: false }
];

const staticMarkets = [
  {name: 'ERCOT', value: 'ERCOT'},
  {name: 'PJM', value: 'PJM'},
  {name: 'NYISO', value: 'NYISO'},
  {name: 'NEISO', value: 'NEISO'},
  {name: 'N/A - Natural Gas (NYMEX)', value: 'NYMEX'}
];

const staticSettlementTypes = [
  {name: 'Hub', value: 'HUB'},
  {name: 'Load Zone', value: 'LOAD_ZONE'}
];

const staticSettlementZone = {
  ERCOT: [
    {name: 'Houston', value: 'HOUSTON'},
    {name: 'North', value: 'NORTH'},
    {name: 'Panhandle', value: 'PANHANDLE'},
    {name: 'South', value: 'SOUTH'},
    {name: 'West', value: 'WEST'}
  ],
  NEISO: [
    {name: 'Connecticut', value: 'ZCT'},
    {name: 'Maine', value: 'ZME'},
    {name: 'Northeast Massachusetts', value: 'ZNE MA'},
    {name: 'New Hampshire', value: 'ZNH'},
    {name: 'Rhode Island', value: 'ZRI'},
    {name: 'Southeast Massachusetts', value: 'ZSE MA'},
    {name: 'Western/Center Massachusetts', value: 'ZSWCT'},
  ],
  NYISO: [
    {name: 'Zone A - West', value: 'A'},
    {name: 'Zone B - Genesee', value: 'B'},
    {name: 'Zone C - Central', value: 'C'},
    {name: 'Zone D - North', value: 'D'},
    {name: 'Zone E - Mohawk Valley', value: 'E'},
    {name: 'Zone F - Capital', value: 'F'},
    {name: 'Zone G - Hudson Valley', value: 'G'},
    {name: 'Zone H - Milwood', value: 'H'},
    {name: 'Zone I - Dunwoodie', value: 'I'},
    {name: 'Zone J - New York City', value: 'J'}
  ],
  PJM: [
    {name: 'Atlantic City Electric Co', value: 'AECO'},
    {name: 'AEP', value: 'AEP'},
    {name: 'APS', value: 'APS'},
    {name: 'Baltimore Gas & Electric Co', value: 'BGE'},
    {name: 'ComEd (aka Commonwealth Edison Co)', value: 'COMED'},
    {name: 'Dayton Power & Light (Dayton)', value: 'DAYTON'},
    {name: 'Delmarva Power & Light Company', value: 'DPL'},
    {name: 'Duke Energy Ohio', value: 'DEOK'},
    {name: 'Duquesne Light', value: 'DQE'},
    {name: 'First Energy Ohio', value: 'FEOH'},
    {name: 'JCP&L (Jersey Central Power & Lt Co)', value: 'JCPL'},
    {name: 'Met-Ed (Metropolitan Edison Co)', value: 'METED'},
    {name: 'PECO (Philadelphia Electric Co)', value: 'PECO'},
    {name: 'Penelec (Pennsylvania Electric Co)', value: 'PENELC'},
    {name: 'Pennsylvania Power (ATSI Zone)', value: 'PAPWR'},
    {name: 'PEPCO - DC (Potomac Electric Power Co - DC)', value: 'PEPCODC'},
    {name: 'PEPCO - MD (Potomac Electric Power Co - MD)', value: 'PEPCOMD'},
    {name: 'PPL (Pennsylvania Power & Light)', value: 'PPL'},
    {name: 'PSEG (Public Service Electric & Gas Co)', value: 'PSEG'},
  ],
  NYMEX: [{name: 'Houston', value: 'HOUSTON'}], // NOTE: Any zone can be used here
};

export class ForwardPriceForm implements OnInit {
  public reportName: string;
  public selectedMarket: any;
  public selectedPriceType: any;
  public selectedPeakType: any;
  public selectedSettlementType: any;
  public selectedSettlementZone: any;
  public selectedStartDate: Date;
  public selectedProduct: IProduct;
  public selectedContract: IContract;
  public selectedTradeStartDate: Date;
  public selectedTradeEndDate: Date;

  public minDate: Date;
  public dateModified: Date;
  public selectedStartDateDisplay: string;
  public termCount: number = 6;
  public maxTermCount: number = 12;
  public defaultCustomTermLength: number = 75;
  public addCustomTermDisabled = false;
  public priceTypes: any[];
  public peakTypes: any[];
  public termLengths: ITermLength[] = [];
  public customTerms: ICustomTerm[] = [];
  public settlementTypes: any[] = [];
  public markets: any[] = [];
  public accountId: any;
  public userId: string;
  public custom: boolean = false;
  public updatedBy: string = '';
  public settlementZones: any = {};
  public periodType: string;
  public globalFlag: number;
  public publicFlag: number;
  public reportId: number;
  public reportTerms: IReportTerms[];
  public minActualTermStartDateDisplay: string;
  public maxActualTermEndDateDisplay: string;
  public minimumTermStart: moment.Moment = moment().subtract(2, 'y').add(1, 'M').startOf('month');
  public maximumTermStart: moment.Moment = moment().add(12, 'y').startOf('year');
  public maximumTermEnd: moment.Moment = moment().add(12, 'y').endOf('year');

  private priceData: any[] = [];
  private barChart: any;

  constructor(private portalService: PortalService,
              private marketsService: MarketsService,
              private dataService: DataService){
    this.updateSelectedStartDate(moment().add(1, 'month').startOf('month').toDate());
  }

  ngOnInit() {
    this.initForm();
  }

  public initForm() {
    this.updateSelectedStartDate(moment().add(1, 'month').startOf('month').toDate());
    this.termCount = 6;
    this.priceTypes = staticPriceTypes;
    this.peakTypes = staticPeakTypes;
    this.termLengths = JSON.parse(JSON.stringify(staticTermLengths));
    this.markets = staticMarkets;
    this.settlementTypes = staticSettlementTypes;
    this.settlementZones = staticSettlementZone.ERCOT;
    this.customTerms = [];
    this.selectedPriceType = this.priceTypes[0];
    this.selectedPeakType = this.peakTypes[0];
    this.selectedSettlementType = this.settlementTypes[0];
    // console.log('initform',this.settlementZones[0]);
    this.selectedSettlementZone = this.settlementZones[0];
    this.selectedTradeEndDate = this.getPreviousTradeDate().toDate();
    this.selectedTradeStartDate = this.getPreviousTradeDate().subtract(12, "months").toDate();
  }

  public onlyFirstDayOfMonthFunc = (date: Date) => {
    return moment(date).isSameOrAfter(this.minimumTermStart) && moment(date).isSameOrBefore(this.maximumTermStart) && date.getDate() === 1;
  }

  public updateDateModified() {
    this.dateModified = momentTz().tz(momentTz.tz.guess()).format("MM/DD/YYYY HH:mm:ss");
  }

  // "2017-01-01T00:00:00" -> "01/01/2017"
  public getDateDisplay(dateString: string) {
    return moment(dateString).format('MM/DD/YYYY');
  }

  public getEndDateDisplay(dateString: string) {
    return this.getDateDisplay(moment(dateString).subtract(1, 'months').endOf("month").format());
  }

  public refreshButtonEnable(button) {
    if (button === undefined) {
      return;
    }
    button.disabled = !this.hasAllData();
  }

  private hasAllData() {
    return this.selectedPeakType !== undefined &&
      this.selectedPriceType !== undefined &&
      this.selectedSettlementType !== undefined &&
      this.selectedSettlementZone !== undefined &&
      this.selectedStartDate !== undefined;
  }

  public showChart(chartContainerId: any) {
    this.renderIndicativeChart(chartContainerId, this.priceData);
  }

  public refreshChart(chartContainerId: any) {
    if(!this.hasAllData()) return;
    let tempSettings = Object.assign({}, this.getReportSettings());
    let termStart = new Date(new Date().getFullYear() + 1, 0, 1);

    let reportTerms = [];
    reportTerms.push({termStartDate: termStart, termLength: 120});
    tempSettings.termLengths = reportTerms;

    let tradeStart = new Date();
    tradeStart.setDate(tradeStart.getDate() -5);
    let tradeEnd = new Date();
    tempSettings.tradeStartDate = tradeStart.toISOString();
    tempSettings.tradeEndDate = tradeEnd.toISOString();

    this.marketsService.getIndicativePriceData(tempSettings).subscribe( (results : any) => {
      let flowRow = [];
      let flowDateFormat = 'YYYY';
      this.priceData = [];
      if (results.length > 0) {
        results[results.length - 1].rates.forEach((fr: IFlowRate) => {
          this.priceData.push([moment(fr.flowDate).format(flowDateFormat), fr.flowPrice]);
        });
        this.renderIndicativeChart(chartContainerId, this.priceData);
        this.updateDateModified();
      }
     });
  }

  public numbersWithCommas(num){
    if(num === 0)
      return "--"
    else
      return this.portalService.numberWithCommas(num);
  }

  public calculateMaxTermEndDate(startDate: Date) {
    const years = ['NEISO', 'NYISO'].includes(this.selectedMarket.value) ? 7 : 12;
    if (moment(startDate).isBefore(moment(), 'year')) {
      // '2019' - '2018' = 1
      let differences = Number(moment().format('YYYY')) - Number(moment(startDate).format('YYYY'));
      return moment().add(years - differences, 'years').endOf('year');
    }
    return moment().add(years, 'years').endOf('year');
  }

  public updateCustomStartDate(event, i) {
    let update = this.customTerms[i];
    update.termStartDate = event.value;
    update.maxLength = this.updateMaxTermLimit(update.termStartDate, this.calculateMaxTermEndDate(update.termStartDate));
    if (update.value > update.maxLength) {
      update.value = update.maxLength;
    }
    update.termStartDateDisplay = moment(event.value).format('MM/DD/YYYY');
    update.termEndDate = this.calculateEndDate(event.value, update.value);

    this.customTerms[i] = update;
  }

  public calculateCustomTermEndDate(term) {
    term.termEndDate = this.calculateEndDate(term.termStartDate, term.value);
  }

  /**
   * When the user adds a new custom term, it pushes to an array of custom terms. The reasoning why each one has their own max length, start
   * and end date is because custom needs to be independant.
   */
  public addCustomTerm() {
    const maxLength = this.updateMaxTermLimit(this.selectedStartDate, this.calculateMaxTermEndDate(this.selectedStartDate));
    const value = this.defaultCustomTermLength > maxLength ? maxLength : this.defaultCustomTermLength;
    this.customTerms.push({
      value: value,
      termStartDate: this.selectedStartDate,
      termStartDateDisplay: moment(this.selectedStartDate).format('MM/DD/YYYY'),
      termEndDate: this.calculateEndDate(this.selectedStartDate, value),
      maxLength: maxLength
    });
    this.countSelectedTerms();
  }

  /**
   * This prevents the user from going under 0 or have nothing. Also sets the max length limit instead of the value user inputs
   * @param event the value passed in from the input
   * @param term the object that is bind directly with the input
   * @param refreshButton detecting if something has changed
   */
  onInputMonthLength(event, term, refreshButton) {
    let value = Number(event.target.value);
    if (value == 0 || value == undefined) {
      term.value = event.target.value = 1;
    } else if (value > term.maxLength) {
      term.value = event.target.value = term.maxLength;
    }
    term.termEndDate = this.calculateEndDate(term.termStartDate, event.target.value);
    this.refreshButtonEnable(refreshButton);
  }

  /**
   * if the page is not custom, update all the term start date to reflect the new date.
   * @param event value passed in from the master term start date
   */
  public startDateSelected(event) {
    
    this.updateSelectedStartDate(event.value);

    if (!this.custom) {
      this.customTerms.forEach(term => {
        term.termStartDate = event.value;
        term.termStartDateDisplay = moment(event.value).format('MM/DD/YYYY');
        term.maxLength = this.updateMaxTermLimit(term.termStartDate, this.calculateMaxTermEndDate(term.termStartDate));
        if (term.value > term.maxLength) {
          term.value = term.maxLength;
        }
        this.calculateTermEndDate(term);
      }); 
    }
  }

  public updateSelectedStartDate(date: Date) {
    this.selectedStartDate = date;
    this.selectedStartDateDisplay = moment(date).format('MM/DD/YYYY');
    
    this.updateTermLengthAvailable(this.updateMaxTermLimit(this.selectedStartDate, this.maximumTermEnd));
  }

  /**
   * Calculate the differences of the end date - start date + 1. The reasoning for + 1 month is because the application start date
   * starts at MM/01/YYYY and end date is MM/31/YYYY
   * @param start start date
   * @param end end date
   */
  updateMaxTermLimit(start, end): number {
    return moment(end).diff(start, 'months') + 1;
  }

  /**
   * disables the term length default selections
   * @param limit 
   */
  updateTermLengthAvailable(limit) {    
    this.termLengths.forEach(term => {
      if (limit <= term.value) {
        term.selected = false;
        term.disabled = true;
        term.exceededLength = true;
      } else {
        term.exceededLength = false;
      }
    });
    this.countSelectedTerms();
  }

  public calculateTermEndDate(term) {
    term.termEndDate = this.calculateEndDate(term.termStartDate, term.value);
  }

  private calculateEndDate(startDate: Date, tLength: number): string {
    return moment(startDate).add(tLength, 'month').toISOString();
  }

  public removeCustomTerm(term) {
    let index: number = this.customTerms.indexOf(term);
    if (index !== -1){
      this.customTerms.splice(index, 1);
      this.countSelectedTerms();
    }
  }

  /**
   * Reducer on how many terms are applicable
   */
  public countSelectedTerms() {
    this.termCount = this.termLengths.reduce((acc, cur) => cur.selected === true ? ++acc : acc, 0) + this.customTerms.length;
    this.disableMoreTermLength();
  }

  disableMoreTermLength() {
    const maxTermsSelected = this.termCount >= this.maxTermCount;
    this.addCustomTermDisabled = maxTermsSelected;
    // Only disable non-selected terms
    if (maxTermsSelected) {
      this.termLengths.filter(term => !term.selected).forEach(term => term.disabled = true);
    } else {
      this.termLengths.filter(term => !term.exceededLength).forEach(term => term.disabled = false);
    }
  }

  public disableCreateReport() {
    return !this.hasAllData() || this.termLengths.filter((term) => term.selected).length + this.customTerms.length === 0;
  }

  public startDateFilter = function(date): number {
    return this.selectedStartDate.getMonth() % 2;
  };

  public showDeselectAll(): boolean {
    if (this.termLengths.length > 0 || this.customTerms.length > 0) {
      return true;
    }
    return false;
  }

  public deselectAllTerms() {
    this.termLengths = this.termLengths.map(ele => { ele.selected = false; return ele; } );
    this.customTerms = [];
    this.countSelectedTerms();
  }

  private resetTerms() {
    this.termLengths = JSON.parse(JSON.stringify(staticTermLengths));
    this.customTerms = [];
    this.countSelectedTerms();
  }

  selectMarket(market: any) {
    this.updateSelectedStartDate(moment().add(1, 'month').startOf('month').toDate());
    this.determinePriceTypes(market);
    this.determinePeakType(market);
    this.determineTermStartEnd(market);
    this.determineSettlementType(market);
    this.determineSettlementZone(market);
    if (!this.custom) {
      this.resetTerms();
    } else {
      this.deselectAllTerms();
    }
    this.selectedMarket = this.markets.find(m => m.value === market.value);
    this.updateSelectedStartDate(this.selectedStartDate);
  }

  private determinePriceTypes(market: any) {
    const northEast = ['PJM', 'NEISO', 'NYISO'];
    if (northEast.includes(market.value)) {
      this.priceTypes = staticPriceTypes.filter(priceType => priceType.value === 'ENERGY_PRICE');
    } else if (market.value === 'NYMEX') {
      this.priceTypes = staticPriceTypes.filter(priceType => priceType.value === 'NYMEX');
    } else {
      this.priceTypes = staticPriceTypes.filter(priceType => priceType.value !== 'NYMEX');
    }
    this.selectedPriceType = this.priceTypes[0];
  }

  private determinePeakType(market: any) {
    if (market.value === 'NYMEX') {
      this.peakTypes = staticPeakTypes.filter(peakType => peakType.value === 'RTC_HEAT_RATE');
    } else {
      this.peakTypes = staticPeakTypes.filter(peakType => peakType.value !== 'RTC_HEAT_RATE');
    }
    this.selectedPeakType = this.peakTypes[0];
  }

  private determineSettlementType(market: any) {
    const northEast = ['PJM', 'NEISO', 'NYISO'];
    if (northEast.includes(market.value)) {
      this.settlementTypes = staticSettlementTypes.filter(ele => ele.value === 'LOAD_ZONE');
    } else {
      this.settlementTypes = staticSettlementTypes;
    }
    this.selectedSettlementType = this.settlementTypes[0];
  }

  private determineSettlementZone(market: any) {
    this.settlementZones = staticSettlementZone[market.value.toUpperCase()];
    this.selectedSettlementZone = this.settlementZones[0];
  }

  private determineTermStartEnd(market: any) {
    const years = ['NEISO', 'NYISO'].includes(market.value) ? 7 : 12;
    this.maximumTermStart = moment().add(years, 'y').startOf('year');
    this.maximumTermEnd = moment().add(years, 'y').endOf('year');
  }

  selectPriceType(price: any) {
    if (price.value === 'HEAT_RATE') {
      // Set peak type to RTC + Heat only
      this.peakTypes = staticPeakTypes.filter(peakType => peakType.value === 'RTC');
      this.selectedPeakType = this.peakTypes[0];

      // Set settlement type = HUB only
      this.settlementTypes = staticSettlementTypes.filter(ele => ele.value === 'HUB');
      this.selectedSettlementType = this.settlementTypes[0];
    } else {
      this.determinePeakType(this.selectedMarket);
      this.determineSettlementType(this.selectedMarket);
    }
  }

  public selectZone(zone: any) {
    // console.log('zone',zone);
    this.selectedSettlementZone = zone;
  }

  public startDateEntered() {
    let newDateDisplay = this.selectedStartDateDisplay;
    let values = this.selectedStartDateDisplay.split("/");
    if(values.length == 2 &&
      values[0] != "" &&
      values[1] != "" &&
      !isNaN(+values[0]) &&
      !isNaN(+values[1])){
      newDateDisplay = values[0]+"/1/"+values[1];
      values = newDateDisplay.split("/");
    }
    if(values.length == 3 &&
      values[0] != "" &&
      values[1] != "" &&
      values[2].length == 4 &&
      !isNaN(+values[0]) &&
      !isNaN(+values[1]) &&
      !isNaN(+values[2])) {
      try {
        let newDate = moment(new Date(+values[2], +values[0]-1)).toDate();
        this.updateSelectedStartDate(newDate);
      }catch (e) {
        // ignore errors for now
      }
    }
  }

  setReportTerms() {
    this.reportTerms = [];
    let map = Object.create(null);

    this.termLengths.filter(t => {
      return (t.selected);
    }).forEach(tl => {
      this.reportTerms.push({termStartDate: this.selectedStartDate, termLength: tl.value, termType: 'O'});
    });
    this.customTerms.forEach(ct => {
      this.reportTerms.push({termStartDate: ct.termStartDate, termLength: ct.value, termType: 'C'});
    });

    // console.log('report terms',this.reportTerms);

    // Need to filter the terms to be ALL UNIQUE
    this.reportTerms = this.reportTerms.filter(term => {
      var key = term.termStartDate + '|' + term.termLength;
      if (!map[key]) {
        map[key] = true;
        return true;
      }
    });

    this.calculateTradeStartEnd();
  }

  getReportSettings(): IReportSettings {
    this.setReportTerms();

    if (this.custom) {
      return {
        accountId: this.accountId,
        createdUserId: this.userId,
        reportType: (this.custom) ? 'Custom' : 'Renewal Analysis',
        reportId: this.reportId,
        market: this.selectedMarket.value,
        peakType: this.selectedPeakType.value,
        priceType: this.selectedPriceType.value,
        settlementType: this.selectedSettlementType.value,     
        tradeStartDate: this.selectedTradeStartDate,
        tradeEndDate: this.selectedTradeEndDate,
        congestionZones:  [{ congestionZone: this.selectedSettlementZone.value, percentage: 100, fullName: this.selectedSettlementZone.name }],
        // congestionZones: this.selectedProduct !== undefined && this.selectedProduct.congestionZones !== undefined
        //   ? this.addCongestionFullName(this.selectedMarket.value, this.selectedProduct.congestionZones)
        //   : [{ congestionZone: this.selectedSettlementZone.value, percentage: 100, fullName: this.selectedSettlementZone.name }],
        updatedBy: this.updatedBy,
        updatedDateDisplay: moment().format(),
        accessType: this.globalFlag ? 'Global' : this.publicFlag ? 'Public' : 'Private',
        productName: (this.selectedProduct) ? this.selectedProduct.name : undefined,
        productId: (this.selectedProduct) ? this.selectedProduct.id : undefined,
        termLengths: this.reportTerms,
        contractId: (this.selectedContract) ? this.selectedContract.contractNum : undefined,
        contractName: (this.selectedContract) ? this.selectedContract.nickName : undefined,
        siteCount: (this.selectedProduct) ? this.selectedProduct.siteCount : undefined,
        periodType: this.periodType ? this.periodType : undefined,
        publicFlag: this.publicFlag !== undefined ? this.publicFlag : undefined,
        globalFlag: this.globalFlag !== undefined ? this.globalFlag : undefined
      } as IReportSettings;
    }

    if (!this.custom) {
    return {
      accountId: this.accountId,
      createdUserId: this.userId,
      reportType: (this.custom) ? 'Custom' : 'Renewal Analysis',
      reportId: this.reportId,
      market: this.selectedMarket.value,
      peakType: this.selectedPeakType.value,
      priceType: this.selectedPriceType.value,
      settlementType: this.selectedSettlementType.value,     
      tradeStartDate: this.selectedTradeStartDate,
      tradeEndDate: this.selectedTradeEndDate,
      congestionZones: this.selectedProduct !== undefined && this.selectedProduct.congestionZones !== undefined
        ? this.addCongestionFullName(this.selectedMarket.value, this.selectedProduct.congestionZones)
        : [{ congestionZone: this.selectedSettlementZone.value, percentage: 100, fullName: this.selectedSettlementZone.name }],
      updatedBy: this.updatedBy,
      updatedDateDisplay: moment().format(),
      accessType: this.globalFlag ? 'Global' : this.publicFlag ? 'Public' : 'Private',
      productName: (this.selectedProduct) ? this.selectedProduct.name : undefined,
      productId: (this.selectedProduct) ? this.selectedProduct.id : undefined,
      termLengths: this.reportTerms,
      contractId: (this.selectedContract) ? this.selectedContract.contractNum : undefined,
      contractName: (this.selectedContract) ? this.selectedContract.nickName : undefined,
      siteCount: (this.selectedProduct) ? this.selectedProduct.siteCount : undefined,
      periodType: this.periodType ? this.periodType : undefined,
      publicFlag: this.publicFlag !== undefined ? this.publicFlag : undefined,
      globalFlag: this.globalFlag !== undefined ? this.globalFlag : undefined
    } as IReportSettings;
  }
  }

  private addCongestionFullName(market: string, zones: IZone[]): IZone[] {
    return zones.map(zone => {
      zone.fullName = ForwardPriceForm.getHumanZoneName(market, zone.congestionZone)
      return zone;
    });
  }

  /**
   * Iterates through the array to find the min and max values and returns a [] that has two values
   * @param data All the terms the user has inputed
   */
  getMinAndMaxTermDate(data: IReportTerms[]): [number, number] {
    let min, max, maxActualEndDate;
    min = max = moment(data[0].termStartDate).valueOf();
    maxActualEndDate = moment(data[0].termStartDate).add(data[0].termLength, 'months').valueOf();

    // Optimized for performanced
    for (let i = 1; i < data.length; i++) {
      let current = moment(data[i].termStartDate);
      let value = current.valueOf();
      min = value < min ? value : min;
      max = value > max ? value : max;

      let num = current.add(data[i].termLength, 'months').valueOf();
      if (num > maxActualEndDate) {
        maxActualEndDate = num;
      }
    }

    // Side effect: Calculate the min Start Date and max End Date for chart output page => CUSTOM ONLY
    if (this.custom) {
      this.minActualTermStartDateDisplay = moment(min).format('MM/DD/YYYY');
      this.maxActualTermEndDateDisplay = moment(maxActualEndDate).subtract(1, 'days').format('MM/DD/YYYY');
    }

    return [min, max];
  }

  /**
   * Min term start date > system year => Today - 1 year
   * Min term start date = system year => Today - 2 years
   * Min term start date < system year => Today - 3 year
   */
  calculateTradeStartEnd() {
    const momentToday = moment();
    if (this.reportTerms.length > 0) {
      let [min, max] = this.getMinAndMaxTermDate(this.reportTerms); // [min, max]
      let momentTodayValueOf = momentToday.valueOf();
      let momentTodayYear = momentToday.format('YYYY');
      let minYear = moment(min).format('YYYY');

      this.selectedTradeStartDate = minYear > momentTodayYear ? 
        momentToday.subtract(1, 'y').toDate() 
        : minYear == momentTodayYear ? momentToday.subtract(2, 'y').toDate() 
        : momentToday.subtract(3, 'y').toDate();
      // Takes the minimum of max start date minus 1 day OR today
      this.selectedTradeEndDate = moment(Math.min(moment(max).subtract(1, 'days').valueOf(), momentTodayValueOf)).toDate();
    } else {
      this.selectedTradeStartDate = momentToday.subtract(1, 'y').toDate();
      this.selectedTradeEndDate = momentToday.toDate();
    }
  }

  setFormFromSettings(report: IReportSettings) {
    this.initForm();     
    report.settlementZone = report.congestionZones[0].congestionZone;
    console.log('after setting report',report);
    this.custom = report.reportType == "Custom";
    this.accountId = report.accountId;
    this.userId = report.createdUserId;
    this.reportName = report.reportName;
    this.reportId = report.reportId || undefined;
    if (report.market) this.selectMarket(staticMarkets.find(m => { return m.value == report.market}));
    if (report.priceType) this.selectedPriceType = this.priceTypes.find(pt => { return pt.value == report.priceType });
    if (report.peakType) this.selectedPeakType = this.peakTypes.find(p => { return p.value == report.peakType; });    
    console.log('Settlementzone',report.settlementZone);    
    //  this.selectedSettlementZone = report.congestionZones[0].congestionZone;
    // this.selectedSettlementZone = this.selectZone(this.settlementZones.find( sz => {return sz.value == report.congestionZones[0].congestionZone} ));
    console.log('settlement zonessss',this.settlementZones);
    // this.selectedSettlementZone = this.selectZone(this.settlementZones.find( sz => {return sz.value == report.congestionZones[0].congestionZone} ));    
    if (report.settlementZone) this.selectedSettlementZone = this.settlementZones.find( sz => {return sz.value == report.settlementZone} );
    console.log('settlement zone this',this.selectedSettlementZone);
    if (report.settlementType) this.selectedSettlementType = this.settlementTypes.find(st => { return st.value == report.settlementType; });
    if (report.globalFlag) this.globalFlag = 1;
    if (report.publicFlag) this.publicFlag = 1;
    if (report.updatedBy) this.updatedBy = report.updatedBy;
    if (report.productId) {
      this.populateProductFromDataservice(report.productId);
    }
    if (this.selectedProduct === undefined && report.reportName) {
      this.selectedProduct = {
        id: report.productId,
        name: report.reportName,
        deliveryStartDate: undefined,
        deliveryEndDate: undefined,
        curveType: undefined,
        dealSource: undefined,
        siteCount: undefined,
        congestionZones: report.congestionZones
      };
    }
    if(report.termLengths != undefined && report.termLengths.length > 0) {
      // console.log('setform',report);
      this.reportTerms = report.termLengths;
      this.customTerms = [];
      this.deselectAllTerms();
      this.selectedStartDate = undefined;
      let maxLength = undefined;
      report.termLengths.forEach(tl => {
        let stl = this.termLengths.find( stl => { return stl.value == tl.termLength});
        // console.log('setform st1',report);
        if(tl.termType == "O") {
          this.selectedStartDate = tl.termStartDate;
          // console.log('setform inside first if',this.selectedStartDate);
          maxLength = this.updateMaxTermLimit(this.selectedStartDate, this.maximumTermEnd);
        }


        // First one wins
        // if(stl && this.selectedStartDate == undefined) {
        //   this.selectedStartDate = tl.termStartDate;
        //   console.log('setform inside first if',this.selectedStartDate);
        //   maxLength = this.updateMaxTermLimit(this.selectedStartDate, this.maximumTermEnd);
        // }
        if (stl && tl.termStartDate === this.selectedStartDate) {
          // console.log('setform inside 2 if',this.selectedStartDate);
          stl.selected = true
        } else {
          // Start date is different so it doesn't actually match the found standard term length
          stl = undefined;
          // console.log('setform inside 3 if',this.selectedStartDate);
        }

        // if(!stl) {
        //   this.customTerms.push({
        //     termStartDate: tl.termStartDate, 
        //     value: tl.termLength,
        //     termStartDateDisplay: moment(tl.termStartDate).format('MM/DD/YYYY'),
        //     maxLength: this.updateMaxTermLimit(tl.termStartDate, this.maximumTermEnd),
        //     termEndDate: this.calculateEndDate(tl.termStartDate, tl.termLength)
        //   });
        // }

        if(tl.termType == "C") {
          this.customTerms.push({
            termStartDate: tl.termStartDate, 
            value: tl.termLength,
            termStartDateDisplay: moment(tl.termStartDate).format('MM/DD/YYYY'),
            maxLength: this.updateMaxTermLimit(tl.termStartDate, this.maximumTermEnd),
            termEndDate: this.calculateEndDate(tl.termStartDate, tl.termLength)
          });
        }
      });
    }

    this.setSiteCountAndCongestionZones(report);
  }

  private setSiteCountAndCongestionZones(report: IReportSettings) {
    // Check to see if the selected product does not have a site count and the selected product
    if (!this.custom && this.selectedProduct.siteCount === undefined) {
      const contracts = this.dataService.getContractsForAccount();
      const reportContract = contracts.find(c => c.contractNum === report.contractId);
      if (reportContract.products.length && reportContract.products[0].siteCount === undefined) {
        this.portalService.populateProductDetails(reportContract);
      }
    }
  }

  static getZoneDisplay(market, congestionZones: IZone[]): string {
    if (!congestionZones || congestionZones.length === 0) {
      return '--';
    }
    if (congestionZones.length === 1) {
      return ForwardPriceForm.getHumanZoneName(market, congestionZones[0].congestionZone);
    }
    return congestionZones.map(zone => ForwardPriceForm.getHumanZoneName(market, zone.congestionZone) + ' ' + zone.percentage + '%').join(', ');
  }

  getPreviousTradeDate() {
    let ptd = moment();
    switch(ptd.day()) {
      case 0:
      case 1:
      case 6:
        return ptd.subtract(6, 'days').day(5);
      default:
        return ptd.day(ptd.day() - 1);
    }
  }

  static getHumanZoneName(market, zone){
    return staticSettlementZone[market].find((z) => { return z.value === zone }).name;
  }

  getProductType(): string {
    if (this.selectedMarket && this.selectedMarket.value) {
      return ['ERCOT', 'PJM', 'NEISO', 'NYISO'].includes(this.selectedMarket.value) ? 'ENERGY' : 'NATURAL GAS';
    }
    return '';
  }

  isErcotMarket(): boolean {
    return this.selectedMarket && this.selectedMarket.value === 'ERCOT';
  }

  isNorthEastMarket(): boolean {
    if (this.selectedMarket && this.selectedMarket.value) {
      return ['PJM', 'NEISO', 'NYISO'].includes(this.selectedMarket.value);
    }
    return false;
  }

  isNaturalGasMarket(): boolean {
    return this.selectedMarket && this.selectedMarket.value === 'NYMEX';
  }

  private populateProductFromDataservice(productId: string) {
    const contracts = this.dataService.getContractsForAccount();
    for (const contract of contracts) {
      const product = contract.products.find(p => p.id === productId);
      if (product) {
        this.selectedProduct = product;
        return;
      }
    }
  }

  renderIndicativeChart(chartContainerName: any, priceData: any[]){
    let yAxisFormat = '{value:.2f}';
    let yAxisLabel = '$/MWh';
    if(this.selectedPriceType.value != 'HEAT_RATE') yAxisFormat = '$'+yAxisFormat;
    if(this.selectedMarket.value == 'NYMEX') yAxisLabel = '$/MMBtu';
    let tooltipLabel = yAxisLabel;
    if(this.selectedPriceType.value == 'HEAT_RATE') {
      yAxisLabel = "Heat Rate";
      tooltipLabel = "HR";
    }

    let cats = [];
    priceData.forEach(function(item) {
      cats.push(item[0]);
    });
      this.barChart = Highcharts.chart(chartContainerName, {
      tooltip: {
        pointFormat: '<span style="color: {series.color}">\u25CF</span> {series.name}: <b>${point.y}/MWh</b><br/>',
        valueDecimals: 2
      },
      title: {
        text: '',
      },
      chart: {
        width: window.innerWidth/3,
      },
      legend: {
        enabled: false
      },
      yAxis: {
        min: Math.max(Math.min(...priceData.map(price => price[1])) - 2, 0),
        title:  {
          text: yAxisLabel
        },
        labels: {
          format: yAxisFormat
        }
      },
      xAxis: {
        categories: cats,
        labels: {
          step: 1
        }
      },
      series: [{
        name: 'Price',
        data: priceData,
        type: 'column',
        turboThreshold: 1000000,
      },
        {
          type: 'line',
          name: 'Trend',
          marker: { enabled: false},
          enableMouseTracking: false,
          pointInterval: priceData.length - 1,
          states: {
            hover: {
              lineWidth: 0
            }
          },
          data: [priceData[0],priceData[priceData.length-1]],
        }],
      credits: { enabled: false }
    });
  }
}
