import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { PortalService } from '../shared/portal.service';
import { Router } from '@angular/router';
import { IntervalUsageReportService } from './interval-usage-report.service';
import { IIntervalUsageReportState, IIntervalUsageExportRequest } from './intervalUsageReport';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { IHelpStep } from '../shared/entities/contextualHelp';

export const MAX_SITE_SELECTION = 25;

@Component({
  selector: 'app-interval-usage-report',
  templateUrl: './interval-usage-report.component.html',
  styleUrls: ['./interval-usage-report.component.scss']
})
export class IntervalUsageReportComponent implements OnInit {
  state: IIntervalUsageReportState;
  buttonDisabled = true;
  maskMode: boolean;
  accountId: string;
  accountName: string;
  startDate = '';
  startDateDisplay = '';
  endDate = '';
  endDateDisplay = '';
  dataInterval = '-';
  kwType: boolean;
  helpStep: IHelpStep;
  kwText = '-';

  realTimeDataReportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private dataService: DataService,
    private router: Router,
    private portalService: PortalService,
    private service: IntervalUsageReportService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Usage Interval Report');
    this.dataService.setSelectedNavItem('usage');
    this.dataService.setCurrentActivePage('usage');
    this.service.onStepReached.subscribe(
      (step) => {
        this.state.currentStep = step;
        this.state.backDisabled = false;
        this.updateButtonLabel();
      }
    );
    if (this.dataService.getAccountSource()) {
      this.accountId = this.dataService.getAccountSource().id;
      this.accountName = this.dataService.getAccountSource().name;
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.state.backDisabled = false;
        this.navigate(1);
        this.service.setSelectedSites([]);
        this.accountId = account.id;
        this.accountName = account.name;
      }
    );
    this.maskMode = this.dataService.getMaskMode();

    this.state = {
      currentStep: 1,
      viewAccountsToggled: false,
      buttonLabel: 'Select Report Settings',
      backDisabled: false,
      selectedSites: []
    };
    this.service.selectedSitesUpdated.subscribe(
      (intervalSites) => {
        this.state.selectedSites = intervalSites;
        if (this.state.selectedSites.length == 0) {
          this.buttonDisabled = true;
          this.state.viewAccountsToggled = false;
        } else {
          this.buttonDisabled = false;
        }
      });
    this.service.startDateUpdated.subscribe(
      (startDate) => {
        if (startDate) {
          this.startDateDisplay = moment(startDate).format('MM/DD/YY');
          this.startDate = moment(startDate).format('YYYY-MM-DD');
        } else {
          this.startDate = '';
        }
        if (this.startDate && this.endDate && moment(this.startDate).isBefore(moment(this.endDate))) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      }
    );
    this.service.endDateUpdated.subscribe(
      (endDate) => {
        if (endDate) {
          this.endDateDisplay = moment(endDate).format('MM/DD/YY');
          this.endDate = moment(endDate).format('YYYY-MM-DD');
        }
        if (this.startDate && this.endDate && moment(this.startDate).isBefore(moment(this.endDate))) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      }
    );
    this.service.dataIntervalUpdated.subscribe(
      (dataInterval) => {
        this.dataInterval = dataInterval;
      }
    );

    this.service.kwTypeUpdated.subscribe(
      (kwType) => {
        this.kwType = kwType;
        if (this.kwType) {
          this.kwText = 'Usage (kWh)';
        }
        else {
          this.kwText = 'Demand (KW)';
        }

      }
    );

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.realTimeDataReportSubscription = this.dataService.realTimeDataReportTriggered.subscribe(() => { this.openRealTimeDataReport(); });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.realTimeDataReportSubscription) this.realTimeDataReportSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    this.service.setSelectedSites([]);
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  nextStep() {
    if (!this.buttonDisabled) {
      if (this.state.currentStep == 3) {
        this.dataService.setLoading(true);
        this.buttonDisabled = true;
        setTimeout(() => {
          this.dataService.setLoading(false);
          this.state.currentStep++;
          this.navigate();
        }, 1500);
      } else {
        this.state.currentStep++;
        if (this.state.currentStep == 2) {
          this.buttonDisabled = true;
        }
        this.navigate();
      }
    }
  }

  openRealTimeDataReport() {
    this.service.getRealTimeDataReportUrl(this.accountId).subscribe(
      (url) => {
        // console.log(url);
        window.open(url, '_blank');
      }
    );
  }



  export() {
    // Get start date, end date, account Id,
    this.dataService.setLoading(true);
    const siteIds = [];
    this.service.selectedSites.forEach((site) => {
      siteIds.push(site.siteId);
    });
    let exportDataInterval = '';
    if (this.dataInterval == 'Quarter-Hourly') {
      exportDataInterval = '15 min';
    } else {
      exportDataInterval = 'Hour';
    }
    const request: IIntervalUsageExportRequest = {
      sites: siteIds,
      accountId: this.accountId,
      accountName: this.accountName,
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      intervalData: exportDataInterval,
      usageNotDemand: this.kwType,
      maskingMode: this.maskMode
    };
    this.service.exportIntervalUsage(request);
  }

  updateButtonLabel() {
    if (this.state.currentStep == 1) {
      this.state.buttonLabel = 'Select Report Settings';
    } else if (this.state.currentStep == 2) {
      this.state.buttonLabel = 'Generate Export';
    } else {
      this.state.backDisabled = true;
    }
  }


  toggleViewAccounts() {
    this.state.viewAccountsToggled = !this.state.viewAccountsToggled;
  }

  navigate(step?: number) {
    if (step && step < this.state.currentStep && !this.state.backDisabled) {
      this.state.currentStep = step;
    }
    if (this.state.currentStep == 1) {
      this.router.navigate(['/interval-usage-report/select-sites']);
    } else if (this.state.currentStep == 2) {
      this.router.navigate(['/interval-usage-report/choose-settings']);
    } else {
      this.export();
    }
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  get maxSiteSelection(): number {
    return MAX_SITE_SELECTION;
  }
}

