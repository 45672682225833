import { Injectable } from '@angular/core';
import {Response, Http, ResponseContentType, Headers} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';import {environment} from "../../environments/environment";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import {DataService} from "../shared/data.service";
import {IComparisonReport, IComparisonChartResponse, ITPComparisonChartResponse} from "../shared/entities/comparison";
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";

@Injectable()
export class BillingComparisonService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http,
              private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getReports(accountId: string): Observable<IComparisonReport[]> {
    let url = environment.production ? environment.getBillingComparisonReportsUrl.replace('{accountId}', accountId.toString()) : environment.getBillingComparisonReportsUrl;
    return this.http
    .get(url).pipe(
      map((data: IComparisonReport[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getYears(accountId: string): Observable<number[]> {
    let url = environment.production ? environment.getBillingComparisonYearsUrl.replace('{accountId}', accountId.toString()) : environment.getBillingComparisonYearsUrl;
    return this.http
    .get(url).pipe(
      map((data: number[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getCharts(request: IComparisonReport) : Observable<IComparisonChartResponse> {
    let url = environment.getBillingComparisonChartsUrl;
    if(environment.production) {
      url = url.replace('{accountId}', request.accountId.toString());
      return this.http
        .post(url,request, {headers : this.headers}).pipe(
          map((data: IComparisonChartResponse)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
    } else {
      return this.http
      .get(url).pipe(
        map((data: IComparisonChartResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  getTpCharts(request: IComparisonReport) : Observable<ITPComparisonChartResponse> {
    let url = environment.getTpBillingComparisonChartsUrl;
    if(environment.production) {
      url = url.replace('{accountId}', request.accountId.toString());
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: ITPComparisonChartResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: ITPComparisonChartResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  saveReport(setting: IComparisonReport): Observable<any> {
    let url = environment.createOrUpdateBillingComparisonReportUrl.replace('{accountId}', setting.accountId.toString());
    if(setting.reportId) {
      url = url.replace('{reportId}', setting.reportId.toString());
    } else {
      url = url.replace('{reportId}', '0');
    }
    return this.http
      .post(url, setting, {headers: this.headers})
      .map((response: HttpResponse<any>) => <any>response)
      .catch(this.handleError);
  }


  deleteReport(setting: IComparisonReport): Observable<any> {
    // console.log('attempting to delete ', setting);
    let url = environment.deleteBillingComparisonReportUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', setting.reportId.toString());
    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  getTpReports(accountId: string): Observable<IComparisonReport[]> {
    let url = environment.getTpBillingComparisonReportsUrl.replace('{accountId}', accountId.toString());
    return this.http
    .get(url).pipe(
      map((data: IComparisonReport[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  saveTpReport(setting: IComparisonReport): Observable<any> {
    let url = environment.createOrUpdateTpBillingComparisonReportUrl.replace('{accountId}', setting.accountId.toString());
    if(setting.reportId) {
      url = url.replace('{reportId}', setting.reportId.toString());
    } else {
      url = url.replace('{reportId}', '0');
    }
    return this.http
      .post(url, setting, {headers: this.headers})
      .map((response: HttpResponse<any>) => <any>response)
      .catch(this.handleError);
  }

  deleteTpReport(setting: IComparisonReport): Observable<any> {
    let url = environment.deleteTpBillingComparisonReportUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', setting.reportId.toString());
    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  getTpYears(accountId: string): Observable<number[]> {
    let url = environment.getTpBillingComparisonYearsUrl.replace('{accountId}', accountId.toString());
    return this.http
      .get(url).pipe(
        map((data: number[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  exportBillingCompReport(settings: IComparisonReport): any {
    let url = environment.production ? environment.getThirdPartyBillingComparisonReportExportUrl.replace('{accountId}', settings.accountId.toString()) : environment.getThirdPartyBillingComparisonReportExportUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
  
    return this.oldHttp
      .post(url, settings, {headers: headers,responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let filename= settings.reportName + '.xlsx';
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      )
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
