import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {DataService} from "../../shared/data.service";
import {AutoPaymentsService} from "../autopayments.service";
import {PaymentsService} from "../../payments/payments.service";
import { PortalService } from "../../shared/portal.service";
import {IPaymentSchedule, IAutoPaymentMethod, IPaymentMethod, IPaymentSettingsKey} from "../autopayments";
import { RoutingPipe } from "../../shared/pipe/routing.pipe"
import { BankAccountPipe } from "../../shared/pipe/bank-account.pipe"
import { AccountEndingPipe } from "../../shared/pipe/account-ending.pipe"
import * as moment from 'moment';
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {Subscription} from "rxjs";
import { ISiteGroup, ISite } from '../../shared/entities/site';
import {Permissions} from "../../shared/const/permissions";
declare var $ :any;

@Component({
  selector: 'app-autopay-info',
  templateUrl: './autopay-info.component.html',
  providers: [BankAccountPipe, RoutingPipe, AccountEndingPipe, CookieService],
  styleUrls: ['./autopay-info.component.scss'],
})
export class AutoPaymentInfoComponent implements OnInit {
  totalSites: IAutoPaymentMethod[];
  paymentMethods: IPaymentMethod[];
  savingRadio: boolean = true;


  makeDefault: boolean = true;
  paymentSchedule: IPaymentSchedule;
  methodNickname: string;
  selectedPayDate: Date;
  selectedPaymentMethod: string;
  minDate: Date;
  maxDate: Date;
  editing: boolean = false;
  editName: IPaymentMethod;
  updatedName: string;
  newRouting: number;
  newToken: string;
  editingNew: boolean;
  helpStep: IHelpStep;
  properties: any;
  showDateError: boolean;
  newPaymentAdded: boolean;


  allowedToEditPaymentMethods : boolean = true;
  showCreditCard: boolean = false;
  showBankAccount: boolean = true;
  nonNullContractNumberForAccount: String = "";


  helpStepSubscription: Subscription;

  constructor(private dataService: DataService, private autopaymentsService: AutoPaymentsService, private paymentsService: PaymentsService, private _cookieService:CookieService, private portalService: PortalService) { }

  ngOnInit() {
    this.autopaymentsService.setStepReached("paymentInfo");
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 15);
    this.newPaymentAdded = false;
    let key: IPaymentSettingsKey = {
      paymentToken: null,
      userId: null,
      accountId: null
    };

    this.showCreditCard = this.dataService.getShowCreditCard();
    //this.showBankAccount = this.dataService.getShowBankAccount();
    this.updateAddPermissions();
    this.updateEditPermissions();
    this.paymentMethods = [];
    // this.updateAddPermissions();
    this.paymentMethods = [];
        let existing = this.autopaymentsService.getAutoPayments();
        // if(existing && this.dataService.getAccountSource().id!=existing.paymentMethod.userPaymentSettingsKey.accountId) {
        //   this.autopaymentsService.setPaymentSchedule(null);
        //   existing = null;
        // }
        if(existing && existing.autoPaymentFlag=='Y') {
          this.autopaymentsService.getPaymentMethods(this.dataService.getAccountSource().id).subscribe(
            (methods) => {
              let temp = [];
              let i = 0;
              let found = false;

              // if(!found && this.paymentSchedule) //new payment type that was not saved in DB by user
              // {
              //   temp[i] = this.paymentSchedule.paymentMethod;
              //   this.selectedPaymentMethod  = this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken.toString();
              // }
              methods.forEach((method)=> {
                if(method.paymentType=='BANKACCOUNT' || (!this.autopaymentsService.getNCCA() && method.paymentType=='CARD')) {
                  method.cvv=null;
                    if((existing.tokenizedBankAccountNumber && existing.tokenizedBankAccountNumber==method.userPaymentSettingsKey.paymentToken)
                       || (existing.tokenizedCcNumber && existing.tokenizedCcNumber==method.userPaymentSettingsKey.paymentToken)) {
                       this.selectedPaymentMethod = method.userPaymentSettingsKey.paymentToken.toString();
                       this.selectMethod(method);
                       this.savingRadio = this.paymentSchedule.savePaymentMethod;
                       this.selectedPaymentMethod  = this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken.toString();
                    }
                    temp[i] = method;
                  i++;
                }
              });
              this.paymentMethods = temp;
            }
          );
          //this.paymentSchedule = this.autopaymentsService.getPaymentSchedule();
        } else {
          this.autopaymentsService.getPaymentMethods(this.dataService.getAccountSource().id).subscribe(
          (methods) => {
              if(methods.length == 0) {
              this.clickedNew();
              } else {
                let temp = [];
                let i = 0;
                let found = false;
                methods.forEach((method)=> {
                    if(method.paymentType=='BANKACCOUNT' || (!this.autopaymentsService.getNCCA() && method.paymentType=='CARD')) {
                      method.cvv=null;
                      temp[i] = method;
                      i++;
                    }
                });

                this.paymentMethods = temp;
                // this.selectDefault();
             }
           }
      );
    }

  //   this.selectedPayDate = moment().toDate();
  //   if(moment().hour() > 17){
  //     this.selectedPayDate = moment().add(1, 'day').toDate();
  //   }
  //   this.paymentSchedule.paymentDate = this.selectedPayDate;

    let autopaysitesel = this.autopaymentsService.getAutoPayments();
    if(autopaysitesel.nccaStatus && autopaysitesel.nccaStatus=='X')
          this.showCreditCard = false;

    this.autopaymentsService.paymentScheduleUpdated.subscribe(
      (paymentSchedule) => {
        this.paymentSchedule = paymentSchedule;
      }
    );
    this.paymentsService.pciChangeCalledBack.subscribe(
      (info) => {
        this.paymentMethods = info.paymentMethods;
        this.paymentMethods.forEach((method)=> {
          // TODO: match on accountID as well
          if(method.userPaymentSettingsKey.paymentToken == info.paymentToken){
            this.selectMethodFromManage(method);
          }
        });
        this.editing = false;
      }
    );
    this.paymentsService.pciEditCancelCalledBack.subscribe(
      (info) => {
        this.paymentMethods = info.paymentMethods;
        this.paymentMethods.forEach((method) => {
          if(method.userPaymentSettingsKey.paymentToken == info.paymentToken) {
            this.selectMethodFromManage(method);
          }
        });
       if(info.paymentType=='BANKACCOUNT'){
         document.getElementById('achClose').click();
         this.achRefresh();
        }
        if(info.paymentType=='CARD'){
         document.getElementById('ccClose').click();
         this.ccRefresh();
        }
      }
    );


    this.paymentsService.pciCreateCalledBack.subscribe(
      (info) => {
       //console.log('>>>>> this.paymentsService.pciCreateCalledBack.subscribe :::: ', info);
       let key: IPaymentSettingsKey = {
          paymentToken: null,
          userId: null,
          accountId: this.dataService.getAccountSource().id
        };
       let method: IPaymentMethod = {
        defaultPayment: 1,
        nickName: "",
        oldPaymentToken: "",
        routingNumber: "-1",
        methodType: "existing",
        maskedAccountNumber: null,
        userPaymentSettingsKey: key,
        paymentType: "",
        cardBrand: "",
        maskedCardNumber: null,
        cardExpiryMonth: null,
        cardExpiryYear: null,
        cvv: null,
        cardZipCode: null,
        rememberPayment: info.rememberPayment
      };
      let schedule: IPaymentSchedule = {
        paymentDate: new Date(),
        savePaymentMethod: true,
        paymentMethod: method
      };
      this.paymentSchedule = schedule;
      this.paymentSchedule.paymentMethod.rememberPayment = info.rememberPayment;
      if(info.paymentType=='BANKACCOUNT'){
            this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken = info.paymentToken;
            this.paymentSchedule.paymentMethod.routingNumber = info.routingNumber;
            this.paymentSchedule.paymentMethod.maskedAccountNumber = info.maskedAccountNumber;
            this.paymentSchedule.paymentMethod.nickName = info.nickname;
            this.paymentSchedule.paymentMethod.paymentType = info.paymentType;

            this.editingNew = false;
            this.autopaymentsService.setPaymentSchedule(this.paymentSchedule);

            document.getElementById('achClose').click();

            this.achRefresh();
         }
        else if(info.paymentType=='CARD')
        {
            this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken = info.paymentToken;
            this.paymentSchedule.paymentMethod.maskedCardNumber = info.paymentToken.slice(-4);
            this.paymentSchedule.paymentMethod.cardExpiryMonth = info.cardExpMonth;
            this.paymentSchedule.paymentMethod.cardExpiryYear = info.cardExpYear;
            this.paymentSchedule.paymentMethod.cvv = info.cvv;
            this.paymentSchedule.paymentMethod.cardZipCode = info.cardZipCode;
            this.paymentSchedule.paymentMethod.nickName = info.nickname;
            this.paymentSchedule.paymentMethod.cardBrand = info.cardBrand;
            this.paymentSchedule.paymentMethod.paymentType = info.paymentType;
            this.paymentSchedule.paymentMethod.routingNumber = '';

            this.editingNew = false;
            this.autopaymentsService.setPaymentSchedule(this.paymentSchedule);

            document.getElementById('ccClose').click();

            this.ccRefresh();
         }
              let temp = [];
              let i = 0;
              this.paymentMethods.forEach((method)=> {
                    //if(method.paymentType=='BANKACCOUNT' || (!this.paymentsService.getNCCA() && method.paymentType=='CARD')) {
                        method.defaultPayment = 0;
                        temp[i] = method;
                        i++;
                    //}
                });
                if(this.paymentSchedule) {
                  this.paymentSchedule.paymentMethod.defaultPayment = 1;
                  temp[i] = this.paymentSchedule.paymentMethod;
                }
                this.paymentMethods = temp;

           this.newPaymentAdded = true;
           this.selectDefault();
      }
    );


    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

     this.properties = this.dataService.getAppProperties();
  }

  ngOnDestroy() {
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }


  ccRefresh()
  {
    var myframe = document.querySelector('iframe[id="pciIFrameCC"]');
    (<HTMLIFrameElement> myframe).src += '';
  }

  achRefresh()
  {
       var myframe = document.querySelector('iframe[id="pciIFrame"]');
       (<HTMLIFrameElement> myframe).src += '';
    }

  focusOutACH(event: any)
  {
    var clicked = event.target;
    var currentID = clicked.id
    if(currentID=='addACHModal')
       this.achRefresh();
  }

  focusOutCC(event: any)
  {
    var clicked = event.target;
    var currentID = clicked.id
    if(currentID=='addCCModal')
       this.ccRefresh();
  }

  getPCICCForm(newOrEdit: string) {
    let element = $("#pciIFrameCC").contents().find("#emptyForm");
    if(element) {
      if( window.location.hostname.indexOf("localhost")!=-1) {
        if('8880' === window.location.port) {
          // Ensure we use HTTPS for non-local environments
          element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
          element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateCC";
          element[0].sourceHostname.value = window.location.protocol + "//" + window.location.hostname + ":8880";
            // console.log('(1) element[0] is', element[0]);
          }
      }  else {
        element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
        element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateCC";
        element[0].sourceHostname.value = window.location.protocol + "//" + window.location.hostname;
        // console.log('(2) element[0] is', element[0]);
      }

      element[0].accountId.value = this.dataService.getAccountSource().id;
      element[0].caNumber.value = (this.nonNullContractNumberForAccount);

      if(newOrEdit == "new") {
        element[0].componentOrigin.value = "newPaymentMethod";
        element[0].paymentType.value = "CARD";
      }


      if('8880' === window.location.port) {
        element[0].loginField.value = "admin";  //only for localhost when we cannot retrieve
        $(element).submit();
      }
      else {
          this.portalService.authenticate().subscribe((auth) => {
            element[0].loginField.value = auth;
            $(element).submit();
          }, err => {
            console.error(err);
            $(element).submit();
            console.error("Could not retrieve username from portal service");
          });
        }

    }
  }

  getPCIForm(newOrEdit: string) {
    let element = $("#pciIFrame").contents().find("#emptyForm");
    if(element) {

      if('8880' === window.location.port) {
        // Ensure we use HTTPS for non-local environments
          element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
          element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateACH";
          element[0].hostnameField.value = window.location.protocol + "//" + window.location.hostname + ":8880";
          // console.log('(1) element[0] is', element[0]);
        } else {
          element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciCallback";
          element[0].validateUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/private/api/accounts/" + this.dataService.getAccountSource().id + "/payments/pciValidateACH";
          element[0].hostnameField.value = window.location.protocol + "//" + window.location.hostname;
          // console.log('(2) element[0] is', element[0]);
        }

      element[0].idField.value = this.dataService.getAccountSource().id;
      element[0].caNumber.value = (this.nonNullContractNumberForAccount);

      //element[0].cancelField.value = false;

      if(newOrEdit == "new") {
        element[0].originField.value = "newPaymentMethod";
        element[0].paymentType.value = "BANKACCOUNT";
    }


      if('8880' === window.location.port) {
        element[0].loginField.value = "admin";  //only for localhost when we cannot retrieve
        $(element).submit();
      }
      else {
          this.portalService.authenticate().subscribe((auth) => {
            element[0].loginField.value = auth;
            $(element).submit();
          }, err => {
            $(element).submit();
            console.error(err);
            console.error("Could not retrieve username from portal service");
          });
        }
    }
  }

  saveOptionChanged() {
    this.paymentSchedule.savePaymentMethod = this.savingRadio;
    // this.paymentsService.setPaymentSchedule(this.paymentSchedule);
  }

  selectMethod( method: IPaymentMethod) {
    this.methodNickname =  method.nickName;
    if(!this.paymentSchedule) {
        let schedule: IPaymentSchedule = {
          paymentDate: new Date(),
          savePaymentMethod: true,
          paymentMethod: method
        };
        this.paymentSchedule = schedule;
      }
      else {
          this.paymentSchedule.paymentMethod = method;
          this.paymentSchedule.paymentMethod.methodType = "new";
          this.editingNew = true;
          this.autopaymentsService.setPaymentSchedule(this.paymentSchedule);
      }

      this.paymentSchedule.paymentMethod = method;
      this.paymentSchedule.paymentMethod.methodType = "manage";
      this.paymentSchedule.paymentMethod.paymentType = method.paymentType;
      this.paymentSchedule.paymentMethod.nickName = method.nickName;
      this.autopaymentsService.setPaymentSchedule(this.paymentSchedule);

      if(method.userPaymentSettingsKey.paymentToken)
            this.selectedPaymentMethod  = method.userPaymentSettingsKey.paymentToken.toString();
      else
            this.selectedPaymentMethod = "";

    this.editingNew = false;
  }

  selectMethodFromManage( method: IPaymentMethod) {
      this.paymentSchedule.paymentMethod = method;
      this.paymentSchedule.paymentMethod.methodType = "manage";
      this.autopaymentsService.setPaymentSchedule(this.paymentSchedule);
      this.editing = false;
  }

  isNCCA() {
    return this.autopaymentsService.getNCCA();
  }

  isNCA() {
    return this.autopaymentsService.getNCA();
  }

  isBankAccount(name) {
    if(name.paymentType=== 'BANKACCOUNT')
        return true;
  }

  isCreditCard(name) {
    if(name.paymentType=== 'CARD')
        return true;
  }

  updateEditPermissions(){
    this.allowedToEditPaymentMethods = this.portalService.hasPermission(Permissions.EditPaymentMethods);
  }

  updateAddPermissions(){
    this.totalSites = this.autopaymentsService.getAllAutopay(); // this.dataService.getSitesForAccountSource();
    let selectedAutopay = this.autopaymentsService.autopay;
    //console.log(this.totalSites.length);

      if(this.totalSites){
        //loop thru, find first non null ca
          this.autopaymentsService.setNCCA(true);
          this.autopaymentsService.setNCA(true);
          for(let i = 0; i < this.totalSites.length; i ++)
          {
            let currSite = this.totalSites[i];
            if((currSite.caNumber != '' || currSite.caNumber) && selectedAutopay.caNumber===currSite.caNumber)
            {
              this.nonNullContractNumberForAccount = currSite.caNumber.split('-')[0];
              if(currSite.nccaStatus !== 'X'){
                this.autopaymentsService.setNCCA(false);
              }
              if(currSite.ncaStatus !== 'X'){
                this.autopaymentsService.setNCA(false);
              }
              break;
            }
          }
          if(this.showCreditCard && this.autopaymentsService.getNCCA()) {
              this.showCreditCard = false;
          }
          if(this.autopaymentsService.getNCA()) {
            this.showBankAccount = false;
           }
      }
  }

  clickedNew() {
      let key: IPaymentSettingsKey = {
        paymentToken: null,
        userId: null,
        accountId: null
      };
      // let method: IPaymentMethod = {
      //   defaultPayment: 1,
      //   nickName: "",
      //   oldPaymentToken: "",
      //   routingNumber: "-1",
      //   maskedAccountNumber: null,
      //   paymentType: null,
      //   methodType: "new",
      //   userPaymentSettingsKey: key,
      //   maskedCardNumber: null,
      //   cardExpiryMonth: null,
      //   cardExpiryYear: null,
      //   cvv: null,
      //   cardZipCode: null,
      //   rememberPayment: 'true',
      // };

      // if(!this.paymentSchedule) {
      //   let schedule: IPaymentSchedule = {
      //     paymentDate: new Date(),
      //     savePaymentMethod: true,
      //     paymentMethod: method
      //   };
      //   this.paymentSchedule = schedule;
      // }
      // else {
      //     this.paymentSchedule.paymentMethod = method;
      //     this.paymentSchedule.paymentMethod.methodType = "new";
      //     this.editingNew = true;
      //     this.paymentsService.setPaymentSchedule(this.paymentSchedule);
      // }
    }

  validPayment() {
    if(this.editingNew)
       return true;
    else
      return false;
  }

  isExpired(method: IPaymentMethod) {
    const dateSel = new Date(method.cardExpiryMonth + '/' + '01' + '/' +  method.cardExpiryYear);
    const dateCurrent = new Date();
    if(dateSel > dateCurrent) {
      return false;
    }
    return true;
  }

  isExpiring(method: IPaymentMethod) {
    const dateSel = new Date(method.cardExpiryMonth + '/' + '01' + '/' +  method.cardExpiryYear);
    const current = new Date();
    const today = new Date();
    current.setMonth(current.getMonth() + 4);
    const dateCurrent = new Date( current.getMonth() + "/" + '01' + '/' + current.getFullYear());
    if(dateSel>today && dateSel < dateCurrent) {
      return true;
    }
    return false;
  }

  enterNew(paymentTypeSel) {
      if(paymentTypeSel==='ACH')
      {
       this.getPCIForm('new');
        setTimeout(
          () => {
            $('#addACHModal').modal({backdrop: 'static', keyboard: false},'show')
          }, 400
        )
      }
      else  if(paymentTypeSel==='CC')
      {
        this.getPCICCForm('new');
        setTimeout(
          () => {
            $('#addCCModal').modal({backdrop: 'static', keyboard: false},'show')
          }, 400
        )

      }
      let element = $("#pciIFrameCC").contents().find("#emptyForm");
  }


  selectDefault() {
    this.paymentMethods.forEach((method)=> {
      if(method.defaultPayment){
        this.selectMethod(method);
      }
    });
  }

  isRememberPayment(name : IPaymentMethod) : boolean {
    // console.log(name.rememberPayment);
    return (name.rememberPayment != 'false');
  }


  save(isEdit) {
    if(isEdit)
      this.editing = true;
    else
      this.editing = false;
    //console.log("Save >>> ", this.paymentSchedule.paymentMethod.nickName)
    // this.paymentsService.savePaymentNickname(this.paymentSchedule.paymentMethod);
  }


  editClicked(name, updatedName) {
    this.editing = true;
    this.editName = name;
    this.updatedName = updatedName;
    this.selectedPaymentMethod  = name.userPaymentSettingsKey.paymentToken.toString();
    $('#editNameModal').modal('show');
  }


  isSelectedPayment(paymentTypeSel)
  {
     if(!this.paymentSchedule && (paymentTypeSel && paymentTypeSel.userPaymentSettingsKey.paymentToken)  && this.selectedPaymentMethod)
     {
       return paymentTypeSel.userPaymentSettingsKey.paymentToken.toString()===this.selectedPaymentMethod;
     }
     else if(this.paymentSchedule && this.paymentSchedule.paymentMethod && this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken)
     {
        if(this.paymentSchedule.paymentMethod && paymentTypeSel && this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken==paymentTypeSel.userPaymentSettingsKey.paymentToken)
            this.selectedPaymentMethod = this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken.toString();
        return this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken==paymentTypeSel.userPaymentSettingsKey.paymentToken;
     }
     else {
        return false;
      }
  }


  editNickName() {
    if(this.isEditNameValid())
     {
       this.editName.nickName = this.updatedName;
       //this.paymentSchedule.paymentMethod.nickName = this.editName.nickName;
       this.autopaymentsService.savePaymentNickname(this.editName).subscribe(
        () => { }
       );
      }
     else
     {
        console.log("Invalid nickname");
     }

  }

  cancelNickName() {
    this.editing=false;
    this.editName = null;
  }

  isEditNameValid()
  {
    var regularExpression= new RegExp('^.*[-!$%^&*()_+|~=`{}[\\]:";\'<>?,.\/]+.*$');
  //  console.log("isEditNameValid: ",regularExpression.test(this.updatedName));
  //  if(this.updatedName &&  (this.updatedName.replace(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"), '') == '')){

    if(this.updatedName=='' || (this.updatedName && (!regularExpression.test(this.updatedName)))) {
      return true;
    }
    else {
       return false;
    }
  }

  deletePaymentMethod(method: IPaymentMethod) {
    let deletingSelectedPayment = false;
    if(this.isSelectedPayment(method)){
      deletingSelectedPayment = true;
    }
    if(method.rememberPayment != 'false'){
      this.autopaymentsService.deletePaymentMethod(method).subscribe(
        () => {
              this.paymentSchedule = null;
              this.getPaymentMethods();
              if(deletingSelectedPayment) {
                this.autopaymentsService.setPaymentSchedule(null);
                this.newPaymentAdded = false;
              }
        }, err => {
          console.error(err);
        }
      )
    }
    // else {
    //   this.newPaymentAdded = false;
    //   let newPaymentMethodList : IPaymentMethod[] = [];
    //   for(let i = 0; i < this.paymentMethods.length; i ++){
    //     let currMethod = this.paymentMethods[i];
    //     if(currMethod != method){
    //       newPaymentMethodList.push(currMethod);
    //     }
    //   }
    //   this.paymentSchedule = null;
    //   this.paymentMethods = newPaymentMethodList;
    // }
  }

  cancel() {
    this.editing = false;
  }

  getPaymentMethods() {
    this.autopaymentsService.getPaymentMethods(this.dataService.getAccountSource().id).subscribe(
      (methods) => {
          this.paymentMethods = methods;
          let found = false;
          let temp = [];
          let i = 0;
          this.paymentMethods.forEach((method)=> {
              if(method.paymentType=='BANKACCOUNT' || (!this.paymentsService.getNCCA() && method.paymentType=='CARD')) {
                 temp[i] = method;
                 if(this.paymentSchedule && method.userPaymentSettingsKey.paymentToken===this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken)
                 {  found = true; }
                 i++;

              }
          });
          // if(!found && this.paymentSchedule) //new payment type that was not saved in DB by user
          //   {
          //     temp[i] = this.paymentSchedule.paymentMethod;
          //   }
          this.paymentMethods = temp;

          //this.selectDefault();
          if(!this.paymentMethods || this.paymentMethods.length===0) {
            this.newPaymentAdded = false;
          }
      }
    );
  }

  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(accountNum) {
      if(paymentType == 'CARD'){
        if(accountNum.length >= 4){
          return accountNum.substring(accountNum.length-4);
        }
      }
      else{
        // if(accountNum.indexOf('-') != -1){
        //   return accountNum.split('-')[1];
        // }
        if(accountNum.length >= 4){
          return accountNum.substring(accountNum.length-3);
        }
      }
      return 'XXXXXXXXXX';
    }
  }

  // hideAccountNumber(paymentType: string, accountNum: string)
  // { // 0000000016
  //   if(paymentType == 'CARD'){
  //     if(accountNum.length >= 4){
  //       return accountNum.substring(accountNum.length-4);
  //     }
  //   }
  //   else{
  //     if(accountNum.indexOf('-') != -1){
  //       return accountNum.split('-')[1];
  //     }

  //   }

  //   return 'XXXXXXXXXX';


  // }
}
