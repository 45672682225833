import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fourcp-about',
  templateUrl: './fourcp-about.component.html',
  styleUrls: ['./fourcp-about.component.css']
})
export class FourcpAboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
