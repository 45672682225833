import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
  name: 'amendmentsPipe'
})
export class AmendmentsPipe implements PipeTransform {
  transform(items: any[], term: string): any[]{
    term = term.toLowerCase().trim();
    let filteredItems = [];
    items.forEach((item) => {
      let foundMatch = false;
      for(let prop in item) {
        if(prop == "amendmentSites" || prop == "detail"){
          let amendmentSites = item[prop];
          amendmentSites.forEach((amendmentSite) => {
            for(let siteProp in amendmentSite){
              if(amendmentSite[siteProp] && amendmentSite[siteProp].toString().toLowerCase().match(term) && !foundMatch){
                filteredItems.push(item);
                foundMatch = true;
              }
            }
          });
        }
        else if (item[prop] && item[prop].toString().toLowerCase().match(term) && !foundMatch){
          filteredItems.push(item);
          foundMatch = true;
        }
      }
    });
    return filteredItems;
  }
}
