import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import {PortalService} from "../../../shared/portal.service";
import {
  I4cpPreferences, SubscriptionType, I4cpAlert, FourcpEnrollmentUrl,
  Hours
} from "../../../shared/entities/fourcp";
import {DataService} from "../../../shared/data.service";
import {Subscription} from "rxjs";
import {FourcpService} from "../../fourcp.service";
import {IHelpStep} from "../../../shared/entities/contextualHelp";

@Component({
  selector: 'app-fourcp-alert',
  templateUrl: './fourcp-alert.component.html',
  styleUrls: ['../../fourcp.component.scss', 'fourcp-alert.component.scss']
})
export class FourcpAlertComponent implements OnInit {

  subscriptionTypes: any;
  preferences: I4cpPreferences;
  alert: I4cpAlert;

  systemDate: string;
  periodEnding: string; // 15 minute period ending for which the highest observed load MTD was recorded

  premium: boolean = true;
  inSeason: boolean;
  enrollmentUrl: string;
  hours: any[];
  helpStep: IHelpStep;

  preferencesSubscription: Subscription;
  inSeasonSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private portalService: PortalService,
              private dataService: DataService,
              private fourcpService: FourcpService) { }

  ngOnInit() {
    this.systemDate = moment().format('MM/DD/YY');
    this.subscriptionTypes = SubscriptionType;
    this.hours = Hours;

    if(moment().month() >= 5 && moment().month() <= 8) {
      this.inSeason = true;
    }

    //FOR DEMO
    this.inSeasonSubscription = this.dataService.inSeasonUpdated.subscribe((inSeason)=>{this.inSeason = inSeason});

    this.preferences = this.dataService.getFourcpPreferences();
    if(this.preferences){
      this.loadPreferences();
    }
    this.preferencesSubscription = this.dataService.fourcpPreferencesUpdated.subscribe((preferences) => {
      this.preferences = preferences;
      this.loadPreferences();
    });

    this.enrollmentUrl = FourcpEnrollmentUrl;
    //TODO: REMOVE THIS WHEN DONE LOCALLY
    this.dataService.setLoading(false);

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  loadPreferences() {
    this.premium = this.preferences.subscriptionType == this.subscriptionTypes.Premium;
    this.getAlert();
  }

  getAlert() {
    this.fourcpService.get4cpAlert().subscribe((alert : any) => {
      this.alert = alert;
      this.alert.alertPeriodDisplay = '';
      if(this.premium){
        if(moment().isBefore(moment(this.preferences.alertTime))){
          this.alert.alertPeriodDisplay = 'Available at ' + moment(this.preferences.alertTime).format('HH:mm') + ' CST';
        } else if (this.alert.alertPeriodStart){
          this.alert.alertPeriodDisplay = moment(this.alert.alertPeriodStart).format('HH:mm') + ' - ' + moment(this.alert.alertPeriodEnd).format('HH:mm') + ' CST';
        }
      } else {
        if(moment().hour() >= 0 && moment().hour() <= 7){
          this.alert.alertPeriodDisplay = 'Available at 7:00 CST';
        } else if(this.alert.alertPeriodStart) {
          this.alert.alertPeriodDisplay = moment(this.alert.alertPeriodStart).format('HH:mm') + ' - ' + moment(this.alert.alertPeriodEnd).format('HH:mm') + ' CST';
        }
      }

      this.fourcpService.setAlert(alert);
      this.loadAlert();
    })
  }

  loadAlert() {
    if(this.alert.mtdMaxLoadTime){
      this.periodEnding = moment(this.alert.mtdMaxLoadTime).format('ddd, MM/DD/YY HH:mm') + ' CST';
    }
  }

  numbersWithCommas(x) { return this.portalService.numberWithCommas(x); }

}
