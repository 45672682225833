import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ForwardPriceForm } from "../../forward-price-form/forward-price.form";
import { MarketsService } from "../../markets.service";
import { PortalService } from "../../../shared/portal.service";
import { DataService } from '../../../shared/data.service';
import { FadeAnimation } from "../../../animations/fadeAnimation";
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
declare var $: any;

@Component({
  selector: 'app-fpc-modify-modal',
  animations: [FadeAnimation],
  templateUrl: './fpc-modify-modal.component.html',
  styleUrls: ['../../../shared/styles/reports.scss',
    './fpc-modify-modal.component.scss']
})
export class FpcModifyModalComponent implements OnInit {
  @Input() originalForm: ForwardPriceForm;
  @Output() notify: EventEmitter<ForwardPriceForm> = new EventEmitter<ForwardPriceForm>();

  public visible:boolean = false;
  public visibleAnimate = false;
  public modifyForm: ForwardPriceForm = new ForwardPriceForm(this.portalService, this.marketsService, this.dataService);

  // Selected data:
  displayTermsAndChart: boolean = false;
  marketSelected: boolean = false;
  displayPriceType: boolean = false;
  displayPeakType: boolean = false;
  displaySettlementType: boolean = false;
  displaySettlementZone:boolean = false;
  displayContinueButton:boolean = false;
  private callback:any;

  constructor(private marketsService: MarketsService,
              private portalService: PortalService,
              private dataService: DataService) { }

  ngOnInit() {
    this.modifyForm.initForm();
  }
  
  public show(callback) {
    this.callback = callback;
    this.modifyForm = Object.assign(Object.create(this.modifyForm), this.originalForm);
    this.modifyForm.termLengths = JSON.parse(JSON.stringify(this.originalForm.termLengths));
    
    this.modifyForm.customTerms = this.originalForm.customTerms;

    // this.selectedStartDate = date;
    this.modifyForm.selectedStartDateDisplay = moment(this.modifyForm.selectedStartDate).format('MM/DD/YYYY');

     console.log('originalform',this.originalForm);
     console.log('modifyform',this.modifyForm);
    $('#modifyReportModal').modal('show');
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  modifyReport() {
    this.notify.emit(this.modifyForm);
    this.hide();
  }

  cancel() {
    this.hide();
  }

  hide() {
    $('#modifyReportModal').modal('hide');
    setTimeout(() => this.visible = false, 300);
  }

  selectMarket(market: any) {
    this.marketSelected = true;
    this.modifyForm.selectMarket(market);
    if(market.value == 'NYMEX'){
      this.displayPriceType = false;
      this.displayPeakType = false;
      this.displaySettlementType = false;
      this.displaySettlementZone = false;
      this.displayTermsAndChart = false;
    }else{
      this.displayContinueButton = true;
      this.displayTermsAndChart = false;
      this.displayPriceType = true;
      this.displayPeakType = true;
      this.displaySettlementType = true;
      this.displaySettlementZone = true;
    }
  }

  updateSettlementZone(zone) {
    this.modifyForm.selectZone(zone);
  }
}
