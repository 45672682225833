import {Injectable, EventEmitter} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {
  IBalances, IBalancesRequest, IPaymentSchedule, IPaymentMethod, IPaymentSettingsRequest,
  IPaymentSubmission, IPaymentResponse, PaymentSettingsRequest, IPCIReturn, IPCIValidateReturn, IAccountBalance,
  IAccountBalanceRequest, IQuickPayVerify, IQuickPayDetails, IPCIQuickPayReturn
} from "./payments";
import {ISite} from "../shared/entities/site";
import {HttpHeaders, HttpClient, HttpResponse} from "@angular/common/http";
import {Response, Http, Headers} from "@angular/http";
import {IInvoice} from "../shared/entities/bill-reports";
import { DataService } from '../shared/data.service';

@Injectable()
export class PaymentsService {

  headers: HttpHeaders;
  oldHeaders: Headers;

  // Bindables
  stepReached: number;
  balances: IBalances;
  selectedBalances: IInvoice[];
  paymentTotal: number;
  submitted: boolean = false;
  paymentSchedule: IPaymentSchedule;
  paymentMethod: IPaymentMethod;
  payByAccount = false;
  hasNCCA = false;
  hasNca = false;
  quickPayResponse : IPCIQuickPayReturn;

  // EventEmitters
  onStepReached: EventEmitter<number> = new EventEmitter<number>();
  balancesUpdated: EventEmitter<IBalances> = new EventEmitter<IBalances>();
  paymentScheduleUpdated: EventEmitter<IPaymentSchedule> = new EventEmitter<IPaymentSchedule>();

  caSelNotice: EventEmitter<string> = new EventEmitter<string>();

  pciChangeCalledBack: EventEmitter<IPCIReturn> = new EventEmitter<IPCIReturn>();
  pciEditCancelCalledBack: EventEmitter<IPCIReturn> = new EventEmitter<IPCIReturn>();
  pciCreateCalledBack: EventEmitter<IPCIValidateReturn> = new EventEmitter<IPCIValidateReturn>();
  pciQuickPayCalledBack: EventEmitter<IPCIQuickPayReturn> = new EventEmitter<IPCIQuickPayReturn>();

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService,) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.oldHeaders = new Headers({'Content-Type': 'application/json'});

  }

  setStepReached(step: number) {
    this.stepReached = step;
    this.onStepReached.emit(step);
  }

  setBalances(balances: any) {
    let contract = '';
    let checked = false;
    let showCCNotice = false;
    let ccSel = false;
    let achSel = false;
    this.balances = balances;
    if(this.balances) {
      this.balances.categories.forEach(category => {
          category.sites.forEach(site => {
            site.invoices.forEach(invoice => {
             if(invoice.checked){
                checked = true;
                if(invoice.nccaStatus){
                  achSel = true;
                }
                else {
                  ccSel = true;
                }

                if(contract==''){
                  contract = invoice.contractAccountNum;
                  showCCNotice = false;
                }
                else {
                  if(contract!=invoice.contractAccountNum) {
                    showCCNotice = true;
                  }
                }
             }  else if(site.otherAmount) {
                  checked = true;
                  if(site.nccaStatus){
                    achSel = true;
                  }
                  else {
                    ccSel = true;
                  }

                  if(contract==''){
                    contract = invoice.contractAccountNum;
                    showCCNotice = false;
                  }
                  else {
                    if(contract!=invoice.contractAccountNum) {
                      showCCNotice = true;
                    }
                  }
             }
            })
         })
      });
    }
    if(this.dataService.getShowCreditCard() && ccSel && achSel) {
      this.caSelNotice.emit("Your selection includes some accounts that are not eligible for credit card payment. If you would like to pay by credit card, eligible accounts must be processed separately.");
    }
    else if(this.dataService.getShowCreditCard() && !achSel && showCCNotice){
      this.caSelNotice.emit('NOTICE: Credit card payments are currently restricted to one \'Account #\'  per transaction. To bundle/group accounts on a single payment transaction, you can pay via bank account (ACH).');
     }
    else if(!checked){
      this.caSelNotice.emit(null);
    }
    else if(!showCCNotice){
      this.caSelNotice.emit(null);
    }
    this.balancesUpdated.emit(balances);
  }

  setPaymentSchedule(paymentSchedule: IPaymentSchedule) {
    this.paymentSchedule = paymentSchedule;
    this.paymentScheduleUpdated.emit(paymentSchedule);
  }

  getPaymentSchedule(): IPaymentSchedule {
    return this.paymentSchedule;
  }

  setSelectedBalances(balances: IInvoice[]) {
    this.selectedBalances = balances;
  }

  setPaymentTotal(total: number){
    this.paymentTotal = total;
  }

  setSubmitted(submitted: boolean) {
    this.submitted = submitted;
  }

  setNca(hasNca : boolean){
    this.hasNca = hasNca;
  }

  getNca(){
    return this.hasNca
  }
  
  setNCCA(hasNCCA: boolean) {
    this.hasNCCA = hasNCCA;
  }

  getNCCA() {
    return this.hasNCCA;
  }

  getSelectedBalances(){
    return this.selectedBalances;
  }

  getQuickPayResponse() {
    return this.quickPayResponse;
  }

  setQuickPayResponse(resp : any) {
    this.quickPayResponse = resp;
  }

  getBalances(request: IBalancesRequest): Observable<IBalances>{
    let url = environment.production ? environment.balancesUrl.replace('{accountId}', request.accountId.toString()) : environment.balancesUrl;
    if(environment.production){
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IBalances)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: IBalances)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  getBalanceDue(request: IAccountBalanceRequest): Observable<IAccountBalance> {
    let url = environment.balanceForAccountUrl.replace('{accountId}', request.accountId);
    if(environment.production){
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IAccountBalance)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: IAccountBalance)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }

  }

  getBalanceDueDates(request: IAccountBalanceRequest): Observable<IAccountBalance> {
    let url = environment.balanceDatesForAccountUrl.replace('{accountId}', request.accountId);
    if(environment.production){
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IAccountBalance)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: IAccountBalance)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  getPaymentMethods(accountId: string): Observable<IPaymentMethod[]> {
    let url = environment.getPaymentMethodsUrl.replace('{accountId}', accountId);
    return this.http
    .get(url).pipe(
      map((data: IPaymentMethod[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  submitPayment(request: IPaymentSubmission) : Observable<IPaymentResponse> {
    let url = '';
    if(request.ccNumber==null)
      url = environment.submitPaymentUrl.replace('{accountId}', request.accountId);
    else
      url = environment.submitPaymentCCUrl.replace('{accountId}', request.accountId);
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IPaymentResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }


  createPaymentMethod(paymentMethod: IPaymentMethod): Observable<IPaymentMethod> {
    let url = environment.paymentMethodsUrl;
    let request: IPaymentSettingsRequest = {
      defaultPayment: paymentMethod.defaultPayment,
      maskedAccountNumber: Number(paymentMethod.userPaymentSettingsKey.paymentToken.slice(-3)),
      nickName: paymentMethod.nickName,
      paymentToken: paymentMethod.userPaymentSettingsKey.paymentToken,
      routingNumber: paymentMethod.routingNumber,
      oldPaymentToken: paymentMethod.oldPaymentToken,
      accountId: paymentMethod.userPaymentSettingsKey.accountId,
      maskedCardNumber: paymentMethod.maskedCardNumber,
      cardExpiryMonth: paymentMethod.cardExpiryMonth,
      cardExpiryYear: paymentMethod.cardExpiryYear
    };
    return this.http
    .post(url,request, {headers : this.headers}).pipe(
      map((data: IPaymentMethod)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  savePaymentNickname(method: IPaymentMethod) {
    console.log(">>>> savePaymentNickname ", method);
    let request: IPaymentSettingsRequest = {
      defaultPayment: method.defaultPayment,
      maskedAccountNumber: method.maskedAccountNumber,
      nickName: method.nickName,
      paymentToken: method.userPaymentSettingsKey.paymentToken,
      routingNumber: method.routingNumber,
      oldPaymentToken: method.oldPaymentToken,
      accountId: method.userPaymentSettingsKey.accountId,
      maskedCardNumber: method.maskedCardNumber,
      cardExpiryMonth: method.cardExpiryMonth,
      cardExpiryYear: method.cardExpiryYear
    };

    let url = environment.savePaymentNicknameUrl.replace('{accountId}', request.accountId);
    return this.http
      .post(url, request, {headers: this.headers})
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

deletePaymentMethod(method: IPaymentMethod) {
    let request = new PaymentSettingsRequest(method);
    let url = environment.deletePaymentMethodsUrl.replace('{accountId}', request.accountId);
    if(this.paymentSchedule && method.userPaymentSettingsKey.paymentToken === this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken)
      this.paymentSchedule = null;
//<<<<<<< HEAD
//    return this.oldHttp
//      .delete(url, {body: request, headers: this.oldHeaders})
//=======
    return this.http
      .post(url, request, {headers: this.headers})
//>>>>>>> Mar2020-release-hotfix2
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  public pciEditCallback(info: IPCIReturn) {
    console.log("Made it back from PCI, returning token and account to finish edit");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        info.paymentMethods = methods;
        this.pciChangeCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI edit callback");
      }
    )
  }

  public pciEditCancel(info: IPCIReturn) {
    console.log("Updating state after cancelling method edit");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        info.paymentMethods = methods;
        this.pciEditCancelCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI callback");
      }
    )
  }

  public pciCreateCallback(validate: IPCIValidateReturn) {
    console.log("Made it back from PCI, need to temporarily store payment method after validate");
    this.pciCreateCalledBack.emit(validate);
  }

  public pciCreatePaymentMethod(request : IPaymentMethod, isCancelled : string,
    rememberPayment : string, login : string){
    let url = environment.pciPaymentMethodsUrl.replace('{accountId}',request.userPaymentSettingsKey.accountId);

    //make conversion to new post format
    let customRequest = {};
    if(request.paymentType == "CARD"){
      customRequest = {
        ccNumber : request.userPaymentSettingsKey.paymentToken,
        accountId : request.userPaymentSettingsKey.accountId,
        login : login,
        nickName : request.nickName,
        paymentType : request.paymentType,
        bankRoutingNumber : request.routingNumber,
        cardBrand : request.cardBrand,
        expMonth : request.cardExpiryMonth,
        expYear : request.cardExpiryYear,
        zipcode : request.cardZipCode,
        isCancelled : isCancelled,
        rememberPayment : rememberPayment
      }
    }
      else{
        customRequest = {
          bankAccountNumber : request.userPaymentSettingsKey.paymentToken,
          accountId : request.userPaymentSettingsKey.accountId,
          login : login,
          nickName : request.nickName,
          paymentType : request.paymentType,
          bankRoutingNumber : request.routingNumber,
          cardBrand : request.cardBrand,
          expMonth : request.cardExpiryMonth,
          expYear : request.cardExpiryYear,
          zipcode : request.cardZipCode,
          isCancelled : isCancelled,
          rememberPayment : rememberPayment,
        }
      }
    //post
    return this.http
      .post(url, customRequest)
      .map((response: HttpResponse<IPaymentMethod>) => response)
      .catch(this.handleError);
  }

  public sendVerifyQuickPayAccount(verify: IQuickPayVerify) {
    let url = environment.getQuickPayDetailsUrl;
    console.log("url: ",url);
    return this.http
      .post(url, verify, {headers: this.headers})
      .map((response: HttpResponse<IQuickPayDetails>) => response)
      .catch(this.handleError);
  }

  public pciQuickPayCallback(info : IPCIQuickPayReturn) {
    this.quickPayResponse = info;
    this.pciQuickPayCalledBack.emit(info);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
