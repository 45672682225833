import {PipeTransform, Pipe} from '@angular/core';
import {IBillingReportResponse} from "../entities/bill-reports";

@Pipe({
  name: 'tpBillingSummaryPipe'
})
export class TpBillingSummaryPipe implements PipeTransform {

  transform(billingReport: IBillingReportResponse, term: string): IBillingReportResponse {
    let filteredReport = {
      totalCurrentCharges: billingReport.totalCurrentCharges,
      balanceDue: billingReport.balanceDue,
      invoiceCount: billingReport.invoiceCount,
      siteCount: billingReport.siteCount,
      categories: []
    };
    term = term.toLowerCase().trim();
    billingReport.categories.forEach((category) => {
      if(category.name.toLowerCase().match(term)){
        filteredReport.categories.push(category);
      } else {
        let accounts = [];
        category.accounts.forEach((account) => {
          for(let prop in account) {
            if(account[prop] && account[prop].toString().toLowerCase().match(term)){
              accounts.push(account);
              break;
            }
          }
        });
        if(accounts.length > 0) {
          let filteredCategory = Object.assign({}, category);
          filteredCategory.accounts = accounts;
          filteredReport.categories.push(filteredCategory);
        }
      }
    });
    return filteredReport;
  }
}
