import { AfterViewInit, Component, Input } from '@angular/core';
declare var Highcharts: any;

@Component({
  selector: 'app-generator-gauge',
  templateUrl: './generator-gauge.component.html',
  styleUrls: ['./generator-gauge.component.scss']
})
export class GeneratorGaugeComponent implements AfterViewInit {
   readonly id = 'gauge-' + Math.round((Math.random() * 100000000));

  minValue = 0;
  @Input() maxValue = 0;
  @Input() intervalDivisor = 3;
  @Input() value: number;
  @Input() unit: string;
  @Input() percentage = false;
  @Input() redMax = 0;
  @Input() yellowMax = 0;
  @Input() blackOverride = false;
  percentageValue: number;
  private gaugeChart: any;

  ngAfterViewInit() {
    if (this.percentage) {
      this.percentageValue = Math.round((this.value / this.maxValue) * 100);
    }
    this.initializeGauge();
  }

  private initializeGauge() {
    this.gaugeChart = Highcharts.chart(this.id, {
      chart: {
        type: 'gauge',
        height: 70,
        width: 200
      },
      title: {
        text: undefined
      },
      pane: [{
        startAngle: -45,
        endAngle: 45,
        background: null,
        center: ['50%', '275%'],
        size: 225
      }],
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      yAxis: [{
        min: 0,
        max: this.maxValue,
        tickInterval: (this.maxValue / this.intervalDivisor).toFixed(),
        tickLength: 7,
        minorTickLength: 7,
        labels: {
          formatter: function() {
            if ([1, 2].includes(this.axis.max - this.value)) {
              return this.axis.max;
            } else {
              return this.value;
            }
          },
          rotation: 'auto',
          distance: 10
        },
        plotBands: [{
          from: 0,
          to: this.value,
          borderColor: 'red',
          borderWidth: 1,
          thickness: 7
        }],
      }],
      plotOptions: {
        gauge: {
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [{}]
    });
  }

  get colorClass(): string {
    if (this.blackOverride) {
      return 'black';
    }
    if (this.value < 21) {
      return 'red';
    }
    if (this.value >= 21 && this.value <= 24 ) {
      return 'yellow';
    }

    if (this.value >= 24 ) {
      return 'green';
    }

    // return 'green';
    // return 'red';
  }
}
