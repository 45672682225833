import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: { [key: string]: { data: any; timestamp: number } } = {};
  private readonly cacheExpiry = 60 * 60 * 8000; // 8 hours in milliseconds

  constructor() {}

  // Store data in the cache with a timestamp
  set(key: string, data: any): void {
    const timestamp = new Date().getTime();
    this.cache[key] = { data, timestamp };
    localStorage.setItem(key, JSON.stringify({ data, timestamp }));
  }

  // Get data from the cache, checking for expiration
  get(key: string): any {
    if (key in this.cache) {
      const cachedItem = this.cache[key];
      const currentTime = new Date().getTime();
      if (currentTime - cachedItem.timestamp <= this.cacheExpiry) {
        return cachedItem.data;
      } else {
        // Cache item has expired, remove it from cache and storage
        this.clear(key);
      }
    } else {
      const storedData = localStorage.getItem(key);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const currentTime = new Date().getTime();
        if (currentTime - parsedData.timestamp <= this.cacheExpiry) {
          this.cache[key] = parsedData;
          return parsedData.data;
        } else {
          // Cache item has expired, remove it from cache and storage
          this.clear(key);
        }
      }
    }
    return null;
  }

  // Cache an API response with expiration
  cacheApiResponse(key: string, observable: Observable<any>): Observable<any> {
    const cachedData = this.get(key);

    if (cachedData) {
      // If data is in cache, check if it has expired
      const currentTime = new Date().getTime();
      if (currentTime - cachedData.timestamp <= this.cacheExpiry) {
        // Data is still within cache expiration time, return it as an observable
        return of(cachedData.data);
      } else {
        // Data has expired, remove it from cache and proceed to fetch new data
        this.clear(key);
      }
    }

    // If data is not in cache or has expired, make the API call and cache the response
    return observable.pipe(
      catchError((error) => {
        // Handle API errors here if needed
        console.error('API error:', error);
        throw error; // Rethrow the error to propagate it to the component
      }),
      // Cache the successful response
      map((response) => {
        this.set(key, response);
        return response;
      })
    );
  }


  // Clear data from the cache
  clear(key: string): void {
    delete this.cache[key];
    localStorage.removeItem(key);
  }

  // Clear all data from the cache
  clearAll(): void {
    this.cache = {};
    localStorage.clear();
  }

  // Function to add artificial waittime
  waitWithoutAsync(milliseconds: number): void {
    const startTime = new Date().getTime();
    let currentTime = startTime;
  
    while (currentTime - startTime < milliseconds) {
      // Keep the loop running until the specified time has passed
      currentTime = new Date().getTime();
    }
  }
}
