import { Component, OnInit, OnDestroy } from '@angular/core';
import {MetricsPages} from "./metrics";
import {Router} from "@angular/router";
import {DataService} from "../shared/data.service";

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  metricsPages: any;
  selectedPage: string;

  constructor(private router: Router, private dataService: DataService) { }

  ngOnInit() {
    this.dataService.setSelectedNavItem('tools');
    this.setCurrentActivePage('tools')
    this.metricsPages = MetricsPages;
    this.selectedPage = 'By Customer';

    this.dataService.selectedMetricsPageUpdated.subscribe((page) =>{
      this.selectedPage = page;
    });

    this.dataService.setContextualHelp(false);
  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(true);
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  selectPage(page: string){
    this.selectedPage = page;
    this.router.navigate(['/metrics/' + page.toLowerCase()])
  }

}
