import { Component, Input, OnInit } from '@angular/core';
import { PortalService } from '../../../shared/portal.service';
import { ISite } from '../../../shared/entities/site';
import { I4cpSummaryPerformance } from '../../../shared/entities/fourcp';

@Component({
  selector: 'app-fourcp-summary-performance-details',
  templateUrl: './fourcp-summary-performance-details.component.html',
  styleUrls: ['./fourcp-summary-performance-details.component.scss', '../fourcp-summary-performance.component.scss']
})
export class FourcpSummaryPerformanceDetailsComponent implements OnInit {


  @Input()
  selectedYear: number;
  @Input()
  summaryPerformance: I4cpSummaryPerformance | I4cpSummaryPerformance;

  constructor(private portalService: PortalService) { }

  ngOnInit() {
  }
  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }
  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }

  getNextYear() { return this.selectedYear + 1; }

}
