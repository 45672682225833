import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import {IUserAccount} from "../../shared/entities/profile";
import {PortalService} from '../../shared/portal.service';
import * as moment from 'moment';
import {GenericPipe} from '../../shared/pipe/generic.pipe'
import {OrderByPipe} from '../../shared/pipe/orderBy.pipe'
import {PagerDenoms} from '../../shared/const/pagerDenoms'
import {IPagerDenom} from '../../shared/const/pagerDenoms'
import {PagerService} from '../../shared/pager.service'
import {Subscription} from "rxjs";
import {FakerService} from "../../shared/faker.service";

import {IAmendmentHistory, IAmendmentSite, IAmendmentProduct, IAmendmentHistoryRequest} from "../../shared/entities/amendments";
import {AmendmentsService} from "../../amendments/amendments.service";
import {AmendmentsPipe} from "../../shared/pipe/amendments.pipe";
import {Router} from "@angular/router";
import { ISite } from '../../shared/entities/site';

@Component({
  selector: 'app-amendments-history',
  templateUrl: './amendments-history.component.html',
  styleUrls: ['./amendments-history.component.scss'],
  providers: [GenericPipe, OrderByPipe, AmendmentsPipe]
})
export class AmendmentsHistoryComponent implements OnInit {

  myAccount: IUserAccount;
  amendmentHistory: IAmendmentHistory[];
  filteredAmendmentHistory: IAmendmentHistory[];

  selectedStartDate: Date;
  selectedEndDate: Date;
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  fromDate: Date;
  toDate: Date;
  ascending: boolean;
  noDataError: boolean = false;
  maskMode: boolean;
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  sortBy: string = 'submittedDateSortable';
  invalidDateRange: boolean = false;

  showSearchRow: boolean = false;
  searchTerm: string = '';
  env: string;

// Subscriptions
  exportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private amendmentsService: AmendmentsService,
    private pipe: GenericPipe,
    private portalService: PortalService,
    private orderByPipe: OrderByPipe,
    private amendmentsPipe: AmendmentsPipe,
    private pagerService: PagerService,
    private fakerService: FakerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataService.setTitleSource('Add/Delete History');
    this.dataService.setSelectedNavItem('accounts');
    this.setCurrentActivePage('accounts');

    this.myAccount = this.dataService.getAccountSource();

    this.selectedStartDate = moment().subtract(6,'months').toDate();
    this.minStartDate = moment(new Date()).subtract(3,'years').toDate();
    this.maxStartDate = moment(new Date()).add(15, 'days').toDate();
    this.selectedEndDate =  moment().toDate();
    this.minEndDate = moment().subtract(2, 'days').toDate();
    if(this.myAccount){
      this.getSiteHistory();
    }
    // this.getPaymentHistory(); // TAKE OUT BEFORE PUSHING!!!!
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getSiteHistory();
      }
    );

    if(this.dataService.getAppProperties()){ this.env = this.dataService.getAppProperties().environment; }

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.default) {
        this.selectDenom(denom);
      }
    });

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.triggerExport();
    });
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  goToAmendment(amendmentHistory: IAmendmentHistory) {
    let selectedRequest = {id: amendmentHistory.requestId, origin: 'AMENDMENT_HISTORY'} as any;
    this.dataService.setSelectedAmendmentRequest(selectedRequest);
    this.router.navigate(['amendments']);
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
  }

  triggerExport() {
    if(this.selectedStartDate && this.selectedEndDate){
      this.dataService.setLoading(true);
      let requestStartDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      let requestEndDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
      this.amendmentsService.exportHistory(requestStartDate, requestEndDate, this.dataService.getMaskMode() + '', window.localStorage.getItem("brand"));
    }
  }

  startDateSelected(){
    if(moment(this.selectedStartDate).isAfter(moment().subtract(2, 'days'))){
      this.minEndDate = moment(this.selectedStartDate).toDate();
    } else {
      this.minEndDate = moment().subtract(2, 'days').toDate();
    }
  }

  getFormattedDate(d) { return moment(d).format('M/D/YYYY'); }

  getSiteHistory() {
    this.dataService.setLoading(true);
    let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
    let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
    if(startDateString && endDateString){
      let request: IAmendmentHistoryRequest = {
        accountId: this.myAccount.id,
        //accountId: 10000,
        startDate: startDateString,
        endDate: endDateString
      };

      this.amendmentsService.getAmendmentHistory(request).subscribe(
        (amendmentHistory : any) => {
          this.amendmentHistory = amendmentHistory;
          this.noDataError = this.amendmentHistory.length == 0;
          this.ascending = false;
          this.amendmentHistory.forEach((parent) => {
            parent.submittedDateFormatted = parent.submittedDate ? moment(parent.submittedDate,'M/D/YYYY').format('M/D/YYYY') : "-------";
            parent.submittedDateSortable = parent.submittedDate ? moment(parent.submittedDate).format('YYYY-MM-DD') : "0000-00-00";
            parent.createdDateFormatted = parent.createdDate ? moment(parent.createdDate,'M/D/YYYY').format('M/D/YYYY') : "-------";
            parent.submittedDateCreated = parent.createdDate ? moment(parent.createdDate).format('YYYY-MM-DD') : "0000-00-00";
            parent.origin = parent.requestId ? 'Online' : 'Offline';
            parent.contractNickname = this.portalService.getContractNickname(parent.contractNumber);
            parent.utility = parent.detail[0].utility;
            parent.detail.forEach((child) => {
              if(parent.utility != child.utility) {
                parent.utility = 'Multiple';
              }
            });

          });
          this.filteredAmendmentHistory = this.orderByPipe.transform(this.amendmentHistory, 'submittedDateSortable', this.ascending);
          this.paginate(1);
          this.dataService.setLoading(false);

        }, err => {
          if(err.status == 404) {
            this.noDataError = true;
          }
          this.filteredAmendmentHistory = [];
          this.paginate(1);
          console.error(err);
          this.dataService.setLoading(false);
        }
      );
    }
  }

  expand(row) {
    row.expanded = !row.expanded;
  }

  sortAmendmentRequest(col: string) {
    this.ascending = !this.ascending;
    this.sortBy = col;
    this.filteredAmendmentHistory = this.orderByPipe.transform(this.filteredAmendmentHistory, col, this.ascending);
    this.paginate(1);
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
    if(this.filteredAmendmentHistory && this.selectedDenom){
      this.pager = this.pagerService.getPage(this.filteredAmendmentHistory, page, this.selectedDenom.count);
      // console.log(this.pager);
    }
  }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterSiteHistory();
  }


  filterSiteHistory(){
    if(!this.searchTerm){
      this.filteredAmendmentHistory = this.amendmentHistory;
    } else {
      this.filteredAmendmentHistory = this.amendmentsPipe.transform(this.amendmentHistory, this.searchTerm);
    }
    this.paginate(1);
  }

  getDateDisplay(date: string) { return this.portalService.getDateDisplay(date); }

  getRequestType(amendmentHistory: IAmendmentHistory){
    let requestType = '';
    amendmentHistory.detail.forEach((detail) => {
      if(detail.amendmentType){
        if(detail.amendmentType.toLowerCase() == 'add'){
          if(requestType == 'Delete'){
            requestType = 'Add & Delete';
          } else {
            requestType = 'Add';
          }
        } else if (detail.amendmentType.toLowerCase() == 'delete'){
          if(requestType == 'Add'){
            requestType = 'Add & Delete';
          } else {
            requestType = 'Delete';
          }
        }
      }
    });
    return requestType;
  }

  getContractNickname(contractNum: string){
    let nickName = this.portalService.getContractNickname(contractNum);
    if(nickName.length > 22){
      nickName = nickName.substring(0, 22) + '...';
    }
    return nickName;
  }

  dateKeyUp(e) {
    if(moment(this.selectedStartDate).isAfter(moment(this.selectedEndDate))) {
      this.invalidDateRange = true;
    } else {
      this.invalidDateRange = false;
    }
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }
}
