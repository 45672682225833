import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'helpTopics'
})
export class HelpTopicsPipe implements PipeTransform {

  transform(topics: any[], term: string): any[] {
    term = term.toLowerCase().trim();
    let searchResults = [];
    if(term.length >= 3){
      for(let topic of topics){
        for(let line of topic.content){
          if(line.toLowerCase().match(term) ||
            topic.title.toLowerCase().match(term)) {
            searchResults.push(topic);
            break;
          }
        }
      }
    }
    return searchResults;
  }

}
