import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AEMHelpService } from './aem-help.service.component';
import { DataService } from '../shared/data.service';
import { PortalService } from '../shared/portal.service';
import { Router } from '@angular/router';
import { FadeAnimation } from '../animations/fadeAnimation';
import { Authority } from '../shared/const/authority';
import { ContactContainer, ActiveHelpPage , HelpTopicCategory, MainPage, HelpTopicPageResponse, JumpLinkParent, JumpLinkChild } from './aem';
import { YoutubePlayerComponent } from '../youtube-player/youtube-player.component';
import { YoutubePlayerService } from '../youtube-player/youtube-player-service';

@Component({
  selector: 'app-aem-help',
  templateUrl: './aem-help.component.html',
  styleUrls: ['./aem-help.component.scss'],
  animations: [FadeAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class AemHelpComponent implements OnInit {
  helpLoading: boolean = true;
  searchDefaultText:string = "Search Our Help Center";
  searchHeaderText:string = "What Can We Help You With?";
  helpTopics : HelpTopicCategory[];
  helpPageNavHeader: string = "Help Topics";
  contactContainer : ContactContainer;
  helpPages : any;
  selectedTopic : string = '';
  helpTopicPageList : MainPage[] = [];
  jumpLinkPageList: JumpLinkParent[] = [];
  mainPageList: MainPage[] = [];
  searchJumpLinkChildren: JumpLinkChild[] = [];
  allPages : MainPage[] = [];
  allJumpLinkParents: JumpLinkParent[] = [];
  mainPageHeader : string = "General Questions";
  activeHelpPage : ActiveHelpPage = null;
  isLandingPage : boolean = true;
  isJumpPage: boolean = false;
  dynamicUrl: string = '';
  isSearchResults : boolean = false;
  searchText : string = '';
  displayedSearchedText: string = '';
  isSuperUser : boolean;
  isThirdParty: boolean;
  videoIdSubscription: Subscription;
  showYoutube: boolean = false;


  constructor(private dataService: DataService,
    private portalService: PortalService,
    private router: Router,
    private aemService: AEMHelpService,
    private youtubePlayerService: YoutubePlayerService) {}

  ngOnInit() {
    this.dataService.setTitleSource('Help Center');
    this.dataService.setHelpStep(null)
    this.portalService.spinBabySpin('helpPageLoader');
    this.getUserPermissions()
    this.getHelpLanding();

    this.videoIdSubscription = this.youtubePlayerService.videoId$.subscribe((videoId) => {
      if (videoId) {
        this.showYoutube = true;
      }
    });
  }

  getHelpLanding(){
    this.activeHelpPage = null;
    this.helpTopics = [];
    this.aemService.getHelpLandingPage().subscribe((hprResponse : any)=>{
      if(hprResponse){
        let pageJson = JSON.parse(hprResponse.pageJSON);
        console.log(pageJson);
        this.dynamicUrl = hprResponse.dynamicUrl;
        let response = pageJson;
        //--- help topic Categories ---
        response.data.helpTopicCategoryList.items.forEach(item => {
          let helpTopicCategory : HelpTopicCategory = {
            allowedRoles : item.allowedRoles,
            categoryName : item.categoryName,
            displayName : item.displayName,
            displayCategory : item.displayThisCategoryToUsers ? item.displayThisCategoryToUsers : false,
            displayOrder : item.displayOrder ? item.displayOrder : 100,
            isJumpPage : item.isJumpPage ? true : false
          };
          this.helpTopics.push(helpTopicCategory);
        });

        // Filtering the categories to show General Questions first, and check permissions
        this.filterCategories(this.helpTopics);

        // Search Container Text
        if(response.data.searchContainerList.items[0])
        {
          this.searchDefaultText = response.data.searchContainerList.items[0].searchplaceholder;
          this.searchHeaderText = response.data.searchContainerList.items[0].searchHeader;
        }

        if(response.data.helpPageNavigationList.items[0])
        {
          this.helpPageNavHeader = response.data.helpPageNavigationList.items[0].headerText;
        }
        //--- main pages - help topic pages --- , general questions first
        response.data.helpTopicPageList.items.forEach(item => {
          let mainPage : MainPage = {
            title : item.title,
            path : item._path,
            category : item.helpTopicCategory.categoryName,
            viewPriority: item.pageDisplayOrder ? item.pageDisplayOrder : 100
          };

          this.allPages.push(mainPage);
          if(mainPage.category == this.selectedTopic){
            this.helpTopicPageList.push(mainPage);
          }
        });

        this.filterPageList(this.helpTopicPageList);

        // jump link pages
        if (response.data.jumpLinkParentList && response.data.jumpLinkParentList.items) {
          response.data.jumpLinkParentList.items.forEach(item => {
            let jumpLinkParent: JumpLinkParent = {
              title: item.jumpLinkTitle,
              order: item.jumpLinkOrder,
              category: item.jumpLinkCategory ? item.jumpLinkCategory.categoryName : null,
              contentList: []
            }

            item.jumpLinkContentList.forEach(content => {
              let jumpLinkChild: JumpLinkChild = {
                label: content.fieldLabel,
                context: content.contextText,
                dataType: content.dataType,
                hyperlink: content.dataType === "pagelink" && content.pageHyperlink ? content.pageHyperlink : null,
                referencePath: content.dataType === "download" && content.contentReference._path ? content.contentReference._path : null
              }
              jumpLinkParent.contentList.push(jumpLinkChild);
            })

            this.allJumpLinkParents.push(jumpLinkParent);
          });

          this.allJumpLinkParents = this.allJumpLinkParents.sort((a, b) => a.order - b.order);
        }

        // Contact Us Text
        if(response.data.contactUsContainerList.items[0])
        {
          let contactContainer : ContactContainer = response.data.contactUsContainerList.items[0];
          this.contactContainer = contactContainer;
        }

        if(response.data.helpTopicPageList.items){
          let helpPages = response.data.helpTopicPageList.items;
          this.helpPages = helpPages;
        }

        this.helpLoading = false;
      }
    })
  }

  getJumpLinkParentId(title: string) {
    return `#id-${title.toLowerCase().replace(/\s+/g, '-')}`
  }

  jumpToElement(elementId: string): void {
    const parentTitle = this.getJumpLinkParentId(elementId);
    const element = document.getElementById(parentTitle);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  getDownloadLink(referencePath: string) {
    return this.dynamicUrl + referencePath;
  }

  getPagesFromSearch(selection : string){
    console.log(selection);
    this.selectedTopic = null;
    this.activeHelpPage = null;
    this.displayedSearchedText = selection;
    this.aemService.getPagesFromSearch(selection).subscribe( (result : any) => {
      console.log("search :" + result);
      this.isLandingPage = false;
      this.isSearchResults = true;
      this.mainPageList = [];
      this.searchJumpLinkChildren = [];
      this.isJumpPage = false;
      result.data.helpTopicPageList.items.forEach(element => {
        let mainPage : MainPage = {
          title : element.title,
          path : element._path,
          category : element.helpTopicCategory.categoryName,
          viewPriority: element.pageDisplayOrder ? element.pageDisplayOrder : 5
        }
        this.mainPageList.push(mainPage);
      });
      result.data.jumpLinkContentList.items.forEach(element => {
        let jumpLinkChild : JumpLinkChild = {
          label: element.fieldLabel,
          context: "",
          dataType: element.dataType,
          hyperlink: element.dataType === "pagelink" ? element.pageHyperlink : null,
          referencePath: element.dataType === "download" ? element.contentReference._path : null
        }
        this.searchJumpLinkChildren.push(jumpLinkChild);
      })
    });
  }

  getTopicPage(sel : string){
    // switch statement to switch between each of the pages based on user selection
    this.selectedTopic = sel;
    this.activeHelpPage = null;
    this.youtubePlayerService.setVideoId(null);
    this.helpTopicPageList = [];
    this.jumpLinkPageList = [];
    this.isLandingPage = true;
    this.isSearchResults = false;
    const selectedHelpTopic = this.helpTopics.find(topic => topic.categoryName === sel);
    this.isJumpPage = selectedHelpTopic ? selectedHelpTopic.isJumpPage : false;
    if (this.isJumpPage) {
      this.allJumpLinkParents.forEach(item => {
        if (item.category == sel) {
          this.jumpLinkPageList.push(item);
        }
      });
      console.log(this.jumpLinkPageList);
    } else {
      this.allPages.forEach(item=>{
        if(item.category == sel){
          this.helpTopicPageList.push(item);
        }
      });
      this.filterPageList(this.helpTopicPageList)
    }
  }

  filterCategories(categories : HelpTopicCategory[]) {
    let filteredCategories = categories.filter((category) => category.displayCategory === "true");

    // Permissions Check on Category
    filteredCategories = filteredCategories.filter((category)=>{
      if(category.allowedRoles.some((role) => role.userType.includes("all_users"))){
        return true;
      }
      if (category.allowedRoles.some((role) => role.userType === "third_party") && this.isThirdParty) {
        return true;
      }
      if (category.allowedRoles.some((role) => role.userType === "super_user") && this.isSuperUser) {
        return true;
      }
      return false;
      });
    // Sort the filtered categories by "displayOrder" in ascending order.
    filteredCategories.sort((a, b) => a.displayOrder - b.displayOrder);
    const generalQuestionsCategory = filteredCategories.find(category => category.categoryName === 'general_questions');
    this.helpTopics = filteredCategories;
    this.selectedTopic = generalQuestionsCategory.categoryName;

    return filteredCategories;
  }

  filterPageList(pageList: MainPage[]){
    this.helpTopicPageList = this.sortByViewPriorityAscending(pageList);
  }

  getUserPermissions(){
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isThirdParty = this.dataService.getAccountSource().accessType === 'ThirdParty';
  }

  getPage(selection : MainPage){
    //get main page, maybe aem call
    this.isSearchResults = false;
    this.selectedTopic = selection.category;
    this.helpLoading = true;
    let helpPageResponse : ActiveHelpPage;
    this.aemService.getHelpPage(selection.path).subscribe( (response : any)=>{
      console.log(response);
      this.isLandingPage = false;
      if (response) {
        let pageJson = JSON.parse(response.pageJSON);
        let helpTopicPageResponse : HelpTopicPageResponse = {
          data: pageJson.data,
          dynamicUrl: response.dynamicUrl
        };
        let helpPageResponse : ActiveHelpPage = {
          title: helpTopicPageResponse.data.helpTopicPageByPath.item.title,
          fullText: helpTopicPageResponse.data.helpTopicPageByPath.item.body.markdown,
          htmlText: this.aemService.startRenderNodeList(helpTopicPageResponse.data.helpTopicPageByPath.item.body.json,helpTopicPageResponse.data.helpTopicPageByPath._references, helpTopicPageResponse.dynamicUrl)
        };
        this.activeHelpPage = helpPageResponse;
        this.helpLoading = false;
      }
    });
  }

  navigateTo(dest: string, slug = '') {
    this.router.navigate(['/' + dest]);
    this.portalService.callAEM();
  }

  sortByViewPriorityAscending(mainPages: MainPage[]): MainPage[] {
    return mainPages.slice().sort((a, b) => {
      // If both items have a viewPriority of 0, keep their relative order.
      if (a.viewPriority === 0 && b.viewPriority === 0) {
        return 0;
      }
      // If only 'a' has a viewPriority of 0, it should come first.
      if (a.viewPriority === 0) {
        return -1;
      }
      // If only 'b' has a viewPriority of 0, it should come first.
      if (b.viewPriority === 0) {
        return 1;
      }
      // For all other cases, sort by viewPriority in ascending order.
      return a.viewPriority - b.viewPriority;
    });
  }


}
