import { Component, OnInit } from '@angular/core';
import {MarketsService} from "../markets.service";
import * as moment from 'moment';
import {IMarketsDashboardProperty} from "../../shared/entities/markets";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {Subscription} from "rxjs";
import { DataService } from '../../shared/data.service';
import { Data } from '@angular/router';


@Component({
  selector: 'app-markets-dashboard',
  templateUrl: './markets-dashboard.component.html',
  styleUrls: ['./markets-dashboard.component.scss']
})
export class MarketsDashboardComponent implements OnInit {

  load: IMarketsDashboardProperty = {} as any;
  hubAverage: IMarketsDashboardProperty = {} as any;
  hubHouston: IMarketsDashboardProperty = {} as any;
  hubNorth: IMarketsDashboardProperty = {} as any;
  hubSouth: IMarketsDashboardProperty = {} as any;
  hubWest: IMarketsDashboardProperty = {} as any;
  hubPan: IMarketsDashboardProperty = {} as any;
  dashboardRefresher: Subscription;

  constructor(private marketsService: MarketsService,
              private dataService: DataService) { }

  ngOnInit() {
    this.loadDashboard(true);

    this.dashboardRefresher = IntervalObservable.create(15000).subscribe(() => {
        this.loadDashboard(false);
    });

    this.dataService.setErcotExport(true);
  }

  ngOnDestroy() {
    if (this.dashboardRefresher) this.dashboardRefresher.unsubscribe();
    this.dataService.setErcotExport(false);
  }

  loadDashboard(forceLoad: boolean){
    //Continue to refresh if dashboard data date is 5 minutes older than current time until new data is received
    let timeToRefresh = moment(this.hubAverage.date).add(5,'minutes').isBefore(moment());
    if(forceLoad || timeToRefresh){
      this.marketsService.getLatestMarketsDashboard().subscribe((response) => {
        this.formatDashboard(response);
      });
    }
  }

  formatDashboard(response){
    response.sources.forEach((location) =>{
        if(location.source == 'ACTUAL_LOAD'){
          this.load.value = location.values[0].toLocaleString();
          this.load.date = location.dates[0];
          this.load.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
        else if (location.source == 'HB_HOUSTON'){
          this.hubHouston.value = location.values[0].toFixed(2);
          this.hubHouston.date = location.dates[0];
          this.hubHouston.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
        else if (location.source == 'HB_HUBAVG'){
          this.hubAverage.value = location.values[0].toFixed(2);
          this.hubAverage.date = location.dates[0];
          this.hubAverage.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
        else if (location.source == 'HB_NORTH'){
          this.hubNorth.value = location.values[0].toFixed(2);
          this.hubNorth.date = location.dates[0];
          this.hubNorth.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
         else if (location.source == 'HB_PAN'){
          this.hubPan.value = location.values[0].toFixed(2);
          this.hubPan.date = location.dates[0];
          this.hubPan.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
        else if (location.source == 'HB_SOUTH'){
          this.hubSouth.value = location.values[0].toFixed(2);
          this.hubSouth.date = location.dates[0];
          this.hubSouth.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
        else if (location.source == 'HB_WEST'){
          this.hubWest.value = location.values[0].toFixed(2);
          this.hubWest.date = location.dates[0];
          this.hubWest.formattedDate = moment(location.dates[0]).format('H:mm A ') + 'CST';
        }
    });
  }

}
