export const DateType = {
  MeterReadEndDate: {
    display: 'Meter Read End Date',
    value: 'METER READ END DATE'
  },
  BillingDate: {
    display: 'Billing Date',
    value: 'BILL POST DATE',
  }
};
