import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';import {IAccount} from "../shared/entities/account";
import {environment} from "../../environments/environment";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {ContractExportRequest, IContractMetadata} from "../shared/entities/contract";
import {IProductMetadata} from "../shared/entities/product";
import {ISite} from "../shared/entities/site";
import 'rxjs/add/observable/throw'
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";
import {ISiteCounts} from "../shared/entities/usageGraph";
import {Http, ResponseContentType} from "@angular/http";
import {DataService} from "../shared/data.service";

@Injectable()
export class AccountsService {

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService) { }

  getRenewablesFlag(accountId: string): Observable<any> {
    const url = environment.production
        ? environment.getRenewablesFlagUrl.replace('{accountId}', accountId)
        : environment.getRenewablesFlagUrl;
    return this.http.get(url);
  }
  
  getAccount(id: string): Observable<IAccount> {
    let url = environment.production ? environment.getAccountUrl.replace('{accountId}', id): environment.getAccountUrl;
    return this.http
      .get(url).pipe(
        map((data: IAccount)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getSite(accountId: string, siteId: string): Observable<ISite> {
    let url = environment.getSiteUrl.replace('{accountId}', accountId).replace('{siteId}', siteId);
    return this.http
    .get(url).pipe(
      map((data: ISite)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getContractsForAccount(id: string, customerAgentId: string): Observable<IAccount> {
    let url = environment.production ? environment.getContractsForAccount.replace('{accountId}', id): environment.getContractsForAccount;
    if(customerAgentId) {
      url += '?customerAgentId=' + customerAgentId;
    }
    return this.http
      .get(url).pipe(
        map((data: IAccount)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getSiteCountsForAccount(accountId: string): Observable<ISiteCounts> {
    let url = environment.production ? environment.getSiteCountsForAccountUrl.replace('{accountId}', accountId): environment.getSiteCountsForAccountUrl;
    return this.http
      .get(url).pipe(
        map((data: ISiteCounts)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getSitesForContract(accountId: string, contractNum: string): Observable<ISite[]> {
    let url = environment.production ? environment.siteUrl + '/' + accountId + '/sites?contract=' + contractNum : environment.siteUrl;
    return this.http
      .get(url).pipe(
        map((data: ISite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getSitesForAccount(accountId: string): Observable<ISite[]> {
    let url = environment.production ? environment.siteUrl + '/' + accountId + '/sites': environment.siteUrl;
    return this.http
      .get(url).pipe(
        map((data: ISite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  setContractMetadata(contractMetadata: IContractMetadata): Observable<IContractMetadata> {
    if(environment.production){
      let url = environment.production ? environment.accountUrl + '/' + contractMetadata.accountId + '/contracts/' + contractMetadata.contractId : '';
      let headers = new HttpHeaders({'Content-Type': 'application/json'});
      return this.http
        .post(url,contractMetadata, {headers : headers}).pipe(
          map((data: IContractMetadata)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
    } else {
      // Running front-end only, just return the metadata.
      return new Observable(observer => {
        observer.next(contractMetadata);
        observer.complete();
      });
    }
  }
  
  exportContractSites(request: ContractExportRequest) {
    if(environment.production){
      let url = environment.accountUrl + '/' + request.accountId + '/contracts/' + request.contractId + '/export';
      return this.oldHttp
        .post(url, request, {responseType: ResponseContentType.Blob})
        .subscribe(
          (res) => {
            let contentDisposition = res.headers.get('content-disposition');
            let filename = contentDisposition.match(/filename=(.+)/)[1];
            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
              window.navigator.msSaveBlob(res.blob(), filename);
            } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
              window.open(window.URL.createObjectURL(blob));
            } else {
              let link=document.createElement('a');
              link.href=window.URL.createObjectURL(res.blob());
              link.download = filename;
              link.click();
            }
            this.dataService.setLoading(false);
          },
          () => {
            this.dataService.setLoading(false);
          }
        );
    }
  }

  setProductMetadata(productMetadata: IProductMetadata): Observable<IProductMetadata> {
    if(environment.production){
      let url = environment.accountUrl + '/' + productMetadata.accountId + '/products/' + productMetadata.productId;
      let headers = new HttpHeaders({'Content-Type': 'application/json'});
      return this.http
      .post(url,productMetadata, {headers : headers}).pipe(
        map((data: IProductMetadata)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      // Running front-end only, just return the metadata.
      return new Observable(observer => {
        observer.next(productMetadata);
        observer.complete();
      });
    }
  }

  private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }
}
