import {transition, trigger, style, animate} from "@angular/animations";

export const FadeInAnimation = trigger('fadeIn',
  [
    transition(
      ':enter', [
        style({opacity: 0}),
        animate('500ms', style({'opacity': 1}))
      ]
    )]
);
