import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { apiDateFormat } from '../../../renewables/renewables-utility';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ShadowMeteringService } from '../../shadow-metering.service';
declare var Highcharts: any;

import { DateRangeSelectorComponent } from '../../../shared/components/date-range-selector/date-range-selector.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';

import { DataService } from '../../../shared/data.service';
import { IUserAccount } from '../../../shared/entities/profile';
import { ISelectedDateRange } from '../../../shared/components/date-range-selector/selected-date-range';
import { IGenerationUsageHistory } from '../../generation-usage-history';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-generation-usage-graph',
  templateUrl: './generation-usage-graph.component.html',
  styleUrls: ['./generation-usage-graph.component.scss']
})
export class GenerationUsageGraphComponent implements OnInit {
  graphChart: any;
  @Input() refreshDataEvent: Observable<void>;
  private readonly unsubscribe$ = new Subject();
  myAccount: IUserAccount;
  data: IGenerationUsageHistory;
  rmarketYAxis: any[];
  initialStartDate: Date;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  lookbackEnabled = false;
  noData: boolean = false;
  dateToolTip ='You may zoom in on a smaller date range within the Distributed Generation Graph by dragging your pointer over the area of the graph you wish to drill into';
  demandLegend = false;
  shadowLegend = false;
  onSiteLegend = false;
  demandData: any;
  shadowData: any;
  onSiteData: any;
  includeEnabled = true;
  showToggle = false;

  @ViewChild(DateRangeSelectorComponent, {static : false}) dateRangeSelector: DateRangeSelectorComponent;
  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(
    private dataService: DataService,
    private shadowMeteringService: ShadowMeteringService
  ) {
    this.minDate = moment(this.shadowMeteringService.selectedSite.minDateRange).toDate();
    this.maxDate = moment(this.shadowMeteringService.selectedSite.maxDateRange).toDate();
    this.endDate = this.maxDate;
    this.startDate = moment(this.maxDate).subtract(7, 'day').endOf('day').toDate();
    this.initialStartDate = moment(this.maxDate).subtract(7, 'day').endOf('day').toDate();
  }

  ngAfterViewInit(){
    this.spinner.show('Loading...','#00AEEF');
  }
  ngOnInit() {
    this.myAccount = this.dataService.getAccountSource();
    this.shadowMeteringService.setLookbackEnabled(this.lookbackEnabled);
    this.shadowMeteringService.generationUsageHistory
      .takeUntil(this.unsubscribe$)
      .subscribe(
        (generationUsageHistory: IGenerationUsageHistory) => {
          this.data = generationUsageHistory;
          if (this.data && (this.data.interval5.length > 0 || this.data.interval15.length > 0)) {
            this.noData = true;
            setTimeout(() => {
                this.updateGraphData();
                this.spinner.hide();
             // }
            }, 300);
          } else {
            this.noData = false;
            this.showToggle = false;
            this.shadowMeteringService.setShowUtilityDemand(false);
            this.spinner.hide();
          }
        }, error => {
          this.spinner.hide();
          console.error('Failed to load site generation', error);
      });

    this.refreshDataEvent
      .takeUntil(this.unsubscribe$)
      .subscribe(() => this.refreshData());
  }

  updateDates(dates: ISelectedDateRange, forceChange = false) {
    const datesChanged = this.startDate !== dates.startDate || this.endDate !== dates.endDate;
    this.startDate = dates.startDate;
    this.endDate = dates.endDate;
    if (datesChanged || forceChange) {
      this.refreshData();
    }
  }

  refreshData() {
    this.spinner.show();
    const startDate = moment(this.startDate).format(apiDateFormat);
    const endDate = moment(this.endDate).format(apiDateFormat);
    setTimeout(() => {
      this.shadowMeteringService.getSiteGenerationUsage(this.myAccount.id, startDate, endDate)
        .takeUntil(this.unsubscribe$)
        .subscribe(
          _res => this.spinner.hide(),
          err => {
            console.error('Failed to load site generation', err);
            this.spinner.hide();
          }
        );
      }, 200);
  }

  onChangeInclude() {
     this.updateGraphData();
  }

  onChangeLookback() {
    this.shadowMeteringService.setLookbackEnabled(this.lookbackEnabled);
    this.updateDates({startDate: this.startDate, endDate: this.endDate}, true);

    if (!this.lookbackEnabled) {
      const updateGenerationStatuses$ = this.shadowMeteringService.getGenerationStatus(this.myAccount.id);
      this.shadowMeteringService.setLookbackRange(null);
      this.shadowMeteringService.setLookbackDemand(null);

      updateGenerationStatuses$
        .subscribe(
          _res => {},
          err => {
            console.error('Error occurred fetching new site data for selected site.', err);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateGraphData() {
    this.demandData = this.data.interval15.filter(point =>
        point.demand !== undefined && point.demand !== null
      ).map(point => (
        { y: point.demand}
      )).concat(this.data.interval5.filter(point =>
        point.demand !== undefined && point.demand !== null
      ).map(point => (
        { y: point.demand}
      )));

    this.demandLegend = this.demandData && this.demandData.length > 0;

    this.shadowData = this.data.interval15.filter(point =>
        (point.shdDemand !== undefined && point.shdDemand !== null) ||
        (point.sgDemand !== undefined && point.sgDemand !== null)
      ).map(point => (
        { y: point.shdDemand}
      )).concat(this.data.interval5.filter(point =>
        (point.shdDemand !== undefined && point.shdDemand !== null) ||
        (point.sgDemand !== undefined && point.sgDemand !== null)
      ).map(point => (
        { y: point.shdDemand}
      )));

    this.shadowLegend = this.shadowData && this.shadowData.length > 0;
    this.showToggle = this.shadowLegend || this.demandLegend;

    if (!this.showToggle) {
      this.includeEnabled = false;
    }

    if (!this.includeEnabled) {
      this.shadowLegend = false;
      this.demandLegend = false;
    }

    this.shadowMeteringService.setShowUtilityDemand(this.includeEnabled);

    this.onSiteData = this.data.interval15.filter(point =>
      point.dGen !== undefined && point.dGen !== null
    ).map(point => (
      { y: point.dGen }
    )).concat(this.data.interval5.filter(point =>
      point.dGen !== undefined && point.dGen !== null
    ).map(point => (
      { y: point.dGen }
    )));

    this.onSiteLegend = this.onSiteData && this.onSiteData.length > 0;
    this.rcreateGraph()
  }

  private formatDateCategory(point: any, interval: number): number {
    const apiDateFormats = 'YYYY-MM-DD';
    return moment(point.date, apiDateFormats).add((point.intervalId - 1) * interval, 'minutes').valueOf();
  }

  private formatDateAsMoment(date: string, interval: number, intervalId: number): moment.Moment {
    return moment(date, apiDateFormat).add((intervalId - 1) * interval, 'minutes');
  }

  rgetYAxis() {
    this.rmarketYAxis = [{ // Primary yAxis
      title: {
        text: 'DEMAND (KW)'
      },
      labels: {
        format: '{value}'
      },
    }];
  }

  tooltipFormatter(context: any) {
    const date = moment(context.x);
    let output = date.format('ddd, MMM Do') + '<br/>';

    if (context.points && context.points.length) {
      output += date.format('HH:mm') + ' - ' + date.add(context.points[0].point.interval, 'minutes').format('HH:mm') + ' CPT';
    } else {
      output += date.format('HH:mm') + ' CPT';
    }

    context.points.filter(point => point.y !== null).forEach(point => {
      output += '<br/>' + point.series.name + ': ' + point.y.toFixed(3) + ' ';
      if (point.series.name.includes('KW')) {
        output += 'KW';
      } else {
        output += 'W/m\xB2';
      }
      if (point.point.count !== undefined && point.point.count !== null) {
        output += '<br/>' + point.point.countLabel + point.point.count;
      }
    });

    return output;
  }

  showDateGenerationInfo(value) {
    if (this.shadowMeteringService.lookbackEnabled) {
      this.shadowMeteringService.setLookbackRange({
        startDate: this.formatDateAsMoment(value.date, value.interval, value.intervalId),
        endDate: this.formatDateAsMoment(value.date, value.interval, value.intervalId).add(value.interval, 'minutes')
      });
      this.shadowMeteringService.setLookbackDemand(value.demand ? value.demand : value.shdDemand);
      this.shadowMeteringService.updateSwitchGearStatuses(value.switchGearStatus ? value.switchGearStatus : []);
      this.shadowMeteringService.updateGeneratorStatuses(value.generatorStatuses ? value.generatorStatuses : []);
      this.shadowMeteringService.setTimestamp(this.formatDateAsMoment(value.date, value.interval, value.intervalId).add(value.interval, 'minutes'));
    }
  }

  rcreateGraph() {
    this.dataService.setLoading(false);
    this.rgetYAxis();
    this.dataService.setLoading(false);
    const component = this;
    const onMouseOver = (value) => this.showDateGenerationInfo(value);
    this.graphChart = Highcharts.chart('sMetering-realtime-graph-container', {
      title: {
        text: ''
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            symbol: 'circle',
          },
          point: {
            events: {
              mouseOver: function() {
                onMouseOver(this);
              }
            }
          }
        },
        area: {
          symbol: 'circle'
        }
      },
      chart: {
        zoomType: 'x'
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        symbol: 'circle'
      },
       yAxis: this.rmarketYAxis,
      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient2: {
          tagName: 'linearGradient',
          id: 'gradient-2',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      xAxis: {
        type: 'datetime',
        tickInterval: 900*1000,
        labels: {
          formatter: function () {
            return moment(this.value).format('M/DD HH:mm');
          }
        }
      },
      tooltip: {
        shared: true,
        formatter: function () { return component.tooltipFormatter(this); }
      },
      series: [
        {
          name: 'Demand (KW)',
          showInLegend: this.demandLegend,
          type: 'area',
          data: this.includeEnabled ? (this.data.interval15.map(point =>
            ({ y: point.demand, x: this.formatDateCategory(point, 15), date: point.date, interval: 15, intervalId: point.intervalId, generatorStatuses: point.generatorStatuses, switchGearStatus: point.switchGearStatus, shdDemand: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, demand: point.demand }))).concat(this.data.interval5.map(point =>
              ({ y: point.demand, x: this.formatDateCategory(point, 5), date: point.date, interval: 5, intervalId: point.intervalId, generatorStatuses: point.generatorStatuses, switchGearStatus: point.switchGearStatus, shdDemand: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, demand: point.demand }))) : [],
          turboThreshold: 7000,
          linearGradient: 300,
        },
        {
          name: 'Shadow Meter Demand (KW)',
          showInLegend: this.shadowLegend,
          type: 'area',
          data: this.includeEnabled ? (this.data.interval15.map(point =>
            ({ y: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, x: this.formatDateCategory(point, 15), date: point.date, interval: 15, intervalId: point.intervalId, generatorStatuses: point.generatorStatuses, switchGearStatus: point.switchGearStatus, shdDemand: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, demand: point.demand }))).concat(this.data.interval5.map(point =>
              ({ y: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, x: this.formatDateCategory(point, 5), date: point.date, interval: 5, intervalId: point.intervalId, generatorStatuses: point.generatorStatuses, switchGearStatus: point.switchGearStatus, shdDemand: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, demand: point.demand }))) : [],
          turboThreshold: 7000,
          linearGradient: 300,
        },
        {
          name: 'Onsite Generation (KW)',
          showInLegend: this.onSiteLegend,
          type: 'area',
          data: (this.data.interval15.map(point =>
            ({ y: point.dGen, x: this.formatDateCategory(point, 15), date: point.date, interval: 15, intervalId: point.intervalId, generatorStatuses: point.generatorStatuses, switchGearStatus: point.switchGearStatus, shdDemand: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, demand: point.demand }))).concat(this.data.interval5.map(point =>
              ({ y: point.dGen, x: this.formatDateCategory(point, 5), date: point.date, interval: 5, intervalId: point.intervalId, generatorStatuses: point.generatorStatuses, switchGearStatus: point.switchGearStatus, shdDemand: point.shdDemand === null || point.shdDemand === undefined ? point.sgDemand : point.shdDemand, demand: point.demand }))),
          turboThreshold: 7000,
          linearGradient: 300,
        }
      ]
    });
  }
}
