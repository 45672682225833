import { Component, OnInit, ElementRef } from '@angular/core';
import {IUserEmail, IUserAccount} from "../../shared/entities/profile"
import {PortalService} from "../../shared/portal.service"
import {ISite} from "../../shared/entities/site";
import { DataService } from "../../shared/data.service";
import {PaymentsService} from "../../payments/payments.service";
import {Subscription} from "rxjs";
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { AutoPaymentsService } from '../../autopayments/autopayments.service';
declare var $ :any;

@Component({
  selector: 'app-payment-confirmation-email',
  templateUrl: './payment-confirmation-email.component.html',
  styleUrls: ['./payment-confirmation-email.component.scss']
})

export class PaymentConfirmationEmailComponent implements OnInit {

  elementRef;
  emailAddresses: IUserEmail[];
  totalAmount: string;
  paymentDate: Date;
  selectedBalances: any[];
  adding: boolean;
  emailError: boolean;
  selectedBalancesSubscription: Subscription;
  paymentTotalSubscription: Subscription;
  paymentDateSubscription: Subscription;
  selectedAccount: IUserAccount;
  emailValidationPattern: String = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$";

  isAutopayConfirm: boolean;
  isPaymentConfirm: boolean;

  emailFormControl = new FormControl('',[
  	Validators.required,
  	Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]
    ); 

    emailFormGroup = new FormGroup({
      emailFormControl: new FormControl('',[
        Validators.required,
        Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")])
      });
    

  constructor(private portalService: PortalService,
              private autoPaymentsService: AutoPaymentsService,
              private dataService: DataService,
              private paymentsService: PaymentsService) {

  }

  ngOnInit() {

    this.isAutopayConfirm  = false;
    this.isPaymentConfirm = false;
    if (window.location.href.match('autopayments')) {
      this.isAutopayConfirm = true;
      console.log('Sending AutoPay Confirmation emails');
    }
    else if (window.location.href.match('payments')) {
      this.isPaymentConfirm = true;
      console.log('Sending Payment Confirmation emails');
  
    }

    this.emailAddresses = Array(1).fill({temp:""});

    if(this.isPaymentConfirm) {
      this.selectedBalances = this.paymentsService.selectedBalances;
      this.selectedBalancesSubscription = this.dataService.selectedBalancesUpdated.subscribe(
        (selectedBalances) => {
            // console.log('Confirmation selected balances updated to ', selectedBalances);
            this.selectedBalances = selectedBalances;
        }
      )
      this.paymentTotalSubscription = this.dataService.paymentTotalUpdated.subscribe(
        (totalAmount) => {
            // console.log('Total amount updated to ', totalAmount);
            this.totalAmount = totalAmount;
        }
      )
      this.paymentDateSubscription = this.dataService.paymentDateUpdated.subscribe(
        (paymentDate) => {
            // console.log('Payment Date updated to ', paymentDate);
            this.paymentDate = paymentDate;
        }
      )
    }


    this.selectedAccount = this.dataService.getAccountSource();
  }

  ngOnDestroy() {
    if (this.selectedBalancesSubscription) this.selectedBalancesSubscription.unsubscribe();
    if (this.paymentTotalSubscription) this.paymentTotalSubscription.unsubscribe();
    if (this.paymentDateSubscription) this.paymentDateSubscription.unsubscribe();
  }

  sendPaymentConfirmationEmails() {
    let account = this.dataService.getAccountSource();
    let customerName = account.name;
    let accountId = account.id;
    
    if(this.isPaymentConfirm) {
        let paymentConfirmation = this.selectedBalances.map(function(invoice:any){
            return {
              "siteId": invoice.collective ? 'Multiple' : invoice.siteId,
              "contractAccountNum": invoice.accountNumber,
              "invoice": invoice.invoiceNo,
              "balanceDue": invoice.paymentAmount,
            };
          }
        );
        let paymentMethod = this.selectedBalances[0].tokenizedBankAccountNumber;
        let confirmationNumber = this.selectedBalances[0].confirmationNumber;
        let paymentDate = moment(this.paymentDate).format('YYYY-MM-DD');
        this.portalService.sendPaymentConfirmationEmails(this.emailAddresses, this.totalAmount, paymentDate, paymentConfirmation, customerName, paymentMethod, confirmationNumber, accountId).subscribe(
          (resp) => {
            this.close();
          },
          (err) => {
            this.emailError = true;
          }
        );
     }
     else if(this.isAutopayConfirm) {
                const action = this.autoPaymentsService.getActionSel();
                const profile = this.dataService.getProfile();
                const brand = this.dataService.getAccountSource().accountBranding;
                const userId = this.dataService.getUserSource().login;
                const siteId = this.autoPaymentsService.autopay.siteId;
                const fullAdd = this.autoPaymentsService.autopay.serviceAddress;
                const address = fullAdd.streetAddress + "," + fullAdd.city + ", " + fullAdd.state + ", " +fullAdd.zipCode;
                const caNumber = this.autoPaymentsService.autopay.caNumber;
                let enrollmentDate = moment(new Date()).format('YYYY-MM-DD');

                let paymentSel;
                let paymentInfo;
                let paymentType;

                if(this.autoPaymentsService.getPaymentSchedule())
                {     
                  paymentSel = this.autoPaymentsService.getPaymentSchedule().paymentMethod;
          
                  if(paymentSel.paymentType==='BANKACCOUNT'){
                    paymentInfo = this.hideAccountNumber("BANK",paymentSel.userPaymentSettingsKey.paymentToken);
                    paymentType = "BANK";
                  }else if(paymentSel.paymentType==='CARD') {
                    paymentInfo = this.hideAccountNumber("CARD",paymentSel.userPaymentSettingsKey.paymentToken);
                    paymentType = "CARD";
                  }
                }                   
                this.portalService.sendAutopayConfirmationEmails(
                    this.emailAddresses, userId, brand, customerName, accountId, siteId, address, enrollmentDate, paymentInfo,paymentType,caNumber,this.autoPaymentsService.actionSel).subscribe(
                    (resp) => {
                      this.close();
                    },
                    (err) => {
                      this.emailError = true;
                    }
                  );
     }
  }

  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(paymentType == 'CARD'){
      if(accountNum.length >= 4){
        return accountNum.substring(accountNum.length-4);
      }
    }
    else{
      if(accountNum.indexOf('-') != -1){
        return 'XXXXXXX' + accountNum.split('-')[1];
      }
      
    }
    return 'XXXXXXXXXX';
  }

  addNewEmail() {
    if(this.emailAddresses.length < 4) {
      let newEmail:IUserEmail = {temp:""};
      this.emailAddresses.push(newEmail);
    }
  }

  saveEmail(index, email) {
    this.emailAddresses[index] = email;
  }

  removeEmail(index) {
    if (index > -1) {
      this.emailAddresses.splice(index, 1);
    }
  }

  close(){
    this.emailError = false;
    $('#paymentConfirmationEmailModal').modal('hide');

  }
}
