import { Injectable } from '@angular/core';
import {Response, ResponseContentType, Http, Headers} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {environment} from "../../environments/environment";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";
import * as moment from "moment";
import {
  IUsageGraphRequest, IUsageGraph
} from "../shared/entities/usageGraph";
import {DataService} from "../shared/data.service";

@Injectable()
export class BillingUsageService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getUsageGraph(request: IUsageGraphRequest): Observable<IUsageGraph> {
     let url = environment.getBilledUsageGraphUrl;
    //let url = environment.getUsageGraphUrl;
    if(environment.production){
      url = environment.getBilledUsageGraphUrl.replace('{accountId}', request.accountId);
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IUsageGraph)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: IUsageGraph)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }
  

  exportUsageGraph(request: IUsageGraphRequest): any{
    let url = environment.production ? environment.exportBilledUsageGraphUrl.replace('{accountId}', request.accountId.toString()) : environment.exportBilledUsageGraphUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let filename= 'Billing Usage Report_' + moment().format('MMDDYYYY') + '.xlsx';
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  getRealTimeDataReportUrl(accountId: string): Observable<string> {
      let url = environment.realTimeDataReportUrl.replace('{accountId}', accountId.toString());
      return this.oldHttp
        .get(url)
        .map((response: Response) => <string>response.text())
        .catch(this.handleError);
  }


  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
