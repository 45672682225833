// Code add by Ramalingam
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IProfile, IUserAccount, IUserDetails, IUserPermission, ILdapPasswordUpdateRequest } from '../shared/entities/profile';
import { DataService } from '../shared/data.service';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Http, Headers, Response, ResponseContentType } from "@angular/http";
import { I4cpContact } from "../shared/entities/fourcp";
import { IStatement, IStatementRequest } from '../shared/entities/statement';

@Injectable()
export class StatementsService {

    private headers: HttpHeaders;

    constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }

    // Get contact information by account ID
    getAllStatements(request: IStatementRequest): Observable<IStatement[]> {
        //let url = './assets/api/profile.json';
        let url = environment.getAllStatementsUrl.replace('{thirdPartyAccountId}',request.thirdPartyId);
        return this.http
      .get(url).pipe(
        map((data: IStatement[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }

    dowloadBrokerStatement(request: IStatementRequest, documentReference: string) {
        // let url = environment.getBrokerStatementUrl;

        // return this.http
        // .get(url)
        // .map((response: HttpResponse<IStatement>) => response)
        // .catch(this.handleError);

        let url = environment.getBrokerStatementUrl;
        let headers = new Headers({'Content-Type': 'application/json'});
        return this.oldHttp
          .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
          .subscribe(
            (res) => {
              console.log(res);
              if(res.ok){
              let maskMode = this.dataService.getMaskMode();
              let filename = documentReference;
              if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
                window.navigator.msSaveBlob(res.blob(), filename);
              } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                let blob = new Blob([res.blob()], {type: "application/pdf"});
                window.open(window.URL.createObjectURL(blob));
              } else {
                let link=document.createElement('a');
                link.href=window.URL.createObjectURL(res.blob());
                link.download = filename;
                link.click();
              }
              this.dataService.setLoading(false);
            }
            else{
              this.handleGeneralServerError();
            }
            },
            () => {
              this.handleGeneralServerError();
            }
          );
    }

    // exportBillingCompReport(request: IStatementRequest, thir): any {
    //     let url = environment.getBrokerStatementUrl
    //         .replace('{accountId}', request.thirdPartyId.toString());
    //     let headers = new Headers({'Content-Type': 'application/json'});
      
    //     return this.oldHttp
    //       .post(url, request, {headers: headers,responseType: ResponseContentType.Blob})
    //       .subscribe(
    //         (res) => {
    //           let filename= settings.reportName + '.xlsx';
    //           if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
    //             window.navigator.msSaveBlob(res.blob(), filename);
    //           } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    //             let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    //             window.open(window.URL.createObjectURL(blob));
    //           } else {
    //             let link=document.createElement('a');
    //             link.href=window.URL.createObjectURL(res.blob());
    //             link.download = filename;
    //             link.click();
    //           }
    //           this.dataService.setLoading(false);
    //         }
    //       )
    //   }

    private handleError(error: HttpResponse<any>) {
        return Observable.throw(error || 'Server error.');
    }

    private handleGeneralServerError() {
      console.log("server error: download statement");
      return Observable.throw('Server error.');
  }


}
