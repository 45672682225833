import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../shared/data.service';
import { ISite } from '../../../shared/entities/site';
import { BalancesPipe } from '../../../shared/pipe/balances.pipe';
import { PortalService } from '../../../shared/portal.service';
import { IBalances } from '../../payments';
import { Subscription } from 'rxjs';
import { PaymentsService } from '../../payments.service';
import * as moment from 'moment';
import { IInvoice } from '../../../shared/entities/bill-reports';
declare var $: any;

@Component({
  selector: 'app-account-balances',
  templateUrl: './account-balances.component.html',
  styleUrls: ['./account-balances.component.scss']
})
export class AccountBalancesComponent implements OnInit, AfterViewInit {
  @Input() balances: IBalances | IBalances;
  @Input() selectCCAccountschecked: boolean;
  filteredBalances: IBalances;
  maskMode: boolean;
  anyChecked = false;
  showSearchBar = false;
  searchTerm = '';
  private readonly defaultAmount = '$0.00';
  showCreditCard : boolean = false;
  filterOnlyCreditcards = () => { };

  maskModeSubscription: Subscription;

  constructor(
    private paymentsService: PaymentsService,
    private portalService: PortalService,
    private balancesPipe: BalancesPipe,
    private currencyPipe: CurrencyPipe,
    private dataService: DataService
  ) { }

  ngOnInit() {
    // Initialize site metadata for this view
    this.balances.categories.forEach(category => {
      category.sites.forEach(site => {
        if (!site.checked && !site.otherAmount) {
          site.invoices.forEach(invoice => {
            invoice.editing = true;
            invoice.otherAmount = null;
            invoice.tempOtherAmount = null;
          } );
          site.editing = true;
          site.otherAmount = null;
          site.tempOtherAmount = null;
        }
      });
    });
    this.filterBalances();
    if (this.selectCCAccountschecked) {
      this.ccAccountsChecked();
    }

    this.showCreditCard = this.dataService.getShowCreditCard() && !this.dataService.getCCNotice();

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
  }

  ngAfterViewInit() {
    this.dataService.setLoading(false);
  }

  clearAll() {
    this.balances.categories.forEach(category => {
      category.sites.forEach(site => {
        site.invoices.forEach(invoice => {
          invoice.checked = false;
          invoice.editing = true;
          invoice.otherAmount = null;
          invoice.tempOtherAmount = null;
        });
        site.checked = false;
        site.editing = true;
        site.otherAmount = null;
        site.tempOtherAmount = null;
      });
    });
    this.updateSelectedAccounts();
  }

  toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
  }

  hasChildNcaa(site: ISite) {
    return (site.invoices.filter(invoice => invoice.nccaStatus == 'X' && !invoice.paymentDate && !invoice.disabled && invoice.autoPaymentFlag).length > 0);
   }
   
   getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeCompanyName(site: any) {
    return this.portalService.getFakeCompanyName(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }

  filterBalances() {
    if (!this.searchTerm) {
      this.filteredBalances = {...this.balances};
    } else {
      this.filteredBalances = this.balancesPipe.transform(this.balances, this.searchTerm);
    }
  }

  filterCC(ccAccountschecked) {
    //this.showCCFilter=!this.showCCFilter;
    this.selectCCAccountschecked=ccAccountschecked;
    this.ccAccountsChecked();
  }

  filterCCEligibleSites() {
    this.filteredBalances.categories = this.filteredBalances.categories.map((i)=>({
      ...i,
      sites: i.sites.filter(x => x.nccaStatus !== "X")
    })
    );
  }

  ccAccountsChecked() {
    if (this.selectCCAccountschecked) {
      //Loop and check if any of the non CC eligible record is selected, then show popup
      if(this.filteredBalances.categories.map(x => x.sites.filter(x => x.nccaStatus == "X").filter(y => y.checked)).filter(x=>x.length>0).length > 0)
        $('#paymentTypeFilterModal').modal('show');
      else
        this.filterCCEligibleSites();

      // On clicking Proceed in the popup, clear checkboxes for non CC eligible records
      this.filterOnlyCreditcards = () => {
        this.filteredBalances.categories.forEach(x => x.sites.forEach(element => {
          if(element.nccaStatus == "X"){
            element.checked = false;
            element.invoices.forEach(y => y.checked = false);
          }
        }));
        this.filterCCEligibleSites();
        $('#paymentTypeFilterModal').modal('hide');
      };
    }
     else {
      this.filteredBalances.categories = [];
      this.filteredBalances.categories = [...this.balances.categories];
    }
  }

  accountBalanceChecked(site: ISite) {
    site.otherAmount = site.tempOtherAmount = null;
    site.editing = true;

    this.updateSelectedAccounts();
  }

  accountBalanceInvChecked(invoice: IInvoice) {
    invoice.otherAmount = invoice.tempOtherAmount = null;
    invoice.editing = true;

    this.updateSelectedAccounts();
  }

  focusAmount(site: ISite) {
    if (site.tempOtherAmount === null || site.tempOtherAmount === '') {
      site.tempOtherAmount = this.defaultAmount;
    }
  }

  keyDownAmount(site: ISite) {
    if (site.tempOtherAmount === this.defaultAmount) {
      site.tempOtherAmount = '';
    }
  }

  blurAmount(site: ISite, event: any) {
    if (!site.tempOtherAmount) {
      return;
    }

    if (site.tempOtherAmount === this.defaultAmount) {
      site.tempOtherAmount = '';
      return;
    }

    // Parse out everything that's not a number or the first decimal
    const parsedAmount = site.tempOtherAmount
      .replace(/[^0-9\.]/g, '')
      .replace(/[\.\%]/g, function(match, offset, all) {
        return match === '.' ? (all.indexOf('.') === offset ? '.' : '') : '';
      }) || 0;

    event.target.value = site.tempOtherAmount = this.currencyPipe.transform(parsedAmount, 'USD', true);
  }

  saveInvoiceAmount(invoice: IInvoice) {
    invoice.editing = false;
    invoice.otherAmount = +invoice.tempOtherAmount.replace(/[^0-9\.]/g, '');
    invoice.tempOtherAmount = null;
    this.updateSelectedAccounts();
  }

  saveAmount(site: ISite) {
    site.editing = false;
    site.otherAmount = +site.tempOtherAmount.replace(/[^0-9\.]/g, '');
    site.tempOtherAmount = null
    this.updateSelectedAccounts();
  }



  editAmount(site: ISite) {
    site.editing = true;
    site.tempOtherAmount = this.currencyPipe.transform(site.otherAmount, 'USD', true);
  }

  cancelAmount(site: ISite) {
    site.otherAmount = site.tempOtherAmount = null;
    site.editing = true;

    this.updateSelectedAccounts();
  }


  focusInvAmount(inv: IInvoice) {
    if (inv.tempOtherAmount === null || inv.tempOtherAmount === '') {
      inv.tempOtherAmount = this.defaultAmount;
    }
  }

  keyDownInvAmount(inv: IInvoice) {
    if (inv.tempOtherAmount === this.defaultAmount) {
      inv.tempOtherAmount = '';
    }
  }

  blurInvAmount(inv: IInvoice, event: any) {
    if (!inv.tempOtherAmount) {
      return;
    }

    if (inv.tempOtherAmount === this.defaultAmount) {
      inv.tempOtherAmount = '';
      return;
    }

    // Parse out everything that's not a number or the first decimal
    const parsedAmount = inv.tempOtherAmount
      .replace(/[^0-9\.]/g, '')
      .replace(/[\.\%]/g, function(match, offset, all) {
        return match === '.' ? (all.indexOf('.') === offset ? '.' : '') : '';
      }) || 0;

    event.target.value = inv.tempOtherAmount = this.currencyPipe.transform(parsedAmount, 'USD', true);
  }

  saveInvAmount(inv: IInvoice) {
    inv.editing = false;
    inv.otherAmount = +inv.tempOtherAmount.replace(/[^0-9\.]/g, '');
    inv.tempOtherAmount = null;

    this.updateSelectedAccounts();
  }

  editInvAmount(inv: IInvoice) {
    inv.editing = true;
    inv.tempOtherAmount = this.currencyPipe.transform(inv.otherAmount, 'USD', true);
  }

  cancelInvAmount(inv: IInvoice) {
    inv.otherAmount = inv.tempOtherAmount = null;
    inv.editing = true;

    this.updateSelectedAccounts();
  }

  getSiteIdDisplay(siteId: string) {
    return this.portalService.getSiteIdDisplay(siteId);
  }

  isAutoPay(site: ISite): boolean {
    if (site && site.autoPaymentFlag) {
      return site.autoPaymentFlag === 'X';
    }
    return false;
  }
  
  isDoubleFlag(site : ISite) : boolean {
    if(site.invoices){
      let doubleFlag = false;
      site.invoices.forEach((invoice)=> {
        if(invoice.ncaStatus == 'X' && invoice.nccaStatus == 'X'){
          doubleFlag = true;
        }
      })
      return doubleFlag;
    }
    return false;
  }

  isOverPay(site: ISite): boolean {
    if (site && site.otherAmount) {
      return site.balanceDue < site.otherAmount;
    }
    return false;
  }

  validAmount(input: string): boolean {
    return input !== null && input !== '' && input !== this.defaultAmount;
  }

  getDateDisplay(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const lowerCaseDate = dateString.toLowerCase();
    if (lowerCaseDate.match('multiple')) {
      return lowerCaseDate;
    }
    const date = moment(moment(dateString, 'YYYY/MM/DD'));
    if (date.isValid()) {
      return date.format('MM/DD/YY');
    }
    return '';
  }

  get accountCount(): number {
    return this.filteredBalances.categories.reduce((sum, category) => {
      return sum + category.sites.length;
    }, 0);
  }

  get totalBalance(): number {
    return this.filteredBalances.categories.reduce((sum, category) => {
      return sum + category.sites.reduce((balanceSum, site) => {
        return balanceSum + site.balanceDue;
      }, 0);
    }, 0);
  }

  private updateSelectedAccounts() {
    this.anyChecked = false;
    let canPayAccountsWithCC = true;
    this.balances.categories.forEach(category => {
      category.sites.forEach(site => {
        if(site.contractAccountNum!='Multiple') {
            if (site.checked || site.otherAmount) {
              this.anyChecked = true;
              if(canPayAccountsWithCC && !site.nccaStatus && !this.hasChildNcaa(site)){
                canPayAccountsWithCC = true;
              }
              else{
                canPayAccountsWithCC = false;
              }
            }
         } else {
              site.invoices.forEach(invoice =>  {
                if (invoice.checked || invoice.otherAmount) {
                  this.anyChecked = true;
                  if(canPayAccountsWithCC && !site.nccaStatus && !this.hasChildNcaa(site)){
                    canPayAccountsWithCC = true;
                  }
                  else{
                    canPayAccountsWithCC = false;
                  }
                }
              });
         }
      });
    });
    this.paymentsService.setNCCA(!canPayAccountsWithCC);
    this.paymentsService.setBalances(this.balances);
  }
}
