export const PagerDenoms = [
    {
        label: '10 per page',
        count: 10
    }, 
    {
        label: '25 per page',
        count: 25,
        default: true
    },
    {
        label: '50 per page',
        count: 50,
    },
    {
        label: '100 per page',
        count: 100
    }
]

export interface IPagerDenom {
    label: string;
    count: number;
    default?: boolean;
}