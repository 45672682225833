import { Injectable } from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
//import {IBillDelivery, IBillDeliveryRequest } from '../shared/entities/billDelivery';
import {DataService} from '../../shared/data.service';
import {PortalService} from "../../shared/portal.service";



import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import { IBillDeliveryHistory, IBillDeliveryHistoryRequest } from '../../shared/entities/billDeliveryEdit';


@Injectable()
export class BillDeliveryHistoryService {

  private headers: Headers;
  constructor(private http: Http, private portalService: PortalService, private dataService: DataService) {
     this.headers = new Headers({'Content-Type': 'application/json'});
   }


  getBillPreferenceChangeHistory(request: IBillDeliveryHistoryRequest): Observable<IBillDeliveryHistory[]> {
    // if(environment.production){
      let url = environment.billingDeliveryHistoryUrl.replace('{accountId}', request.accountId.toString());
      url = url + '?startDate=' + request.startDate + '&endDate=' + request.endDate;
      return this.http
      .get(url,  {headers: this.headers})
      
      .map((response: Response) => <IBillDeliveryHistory[]>response.json())
      .catch(this.handleError);
  }

  export(request: any): any {
    let url = environment.production ? environment.exportBillingDeliveryHistoryUrl.replace('{accountId}', request.accountId.toString()) : environment.exportBillingDeliveryHistoryUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.http
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}


