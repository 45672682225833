import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sr-access',
  templateUrl: './sr-access.component.html',
  styleUrls: ['./sr-access.component.scss']
})
export class SelfRegistrationAccessComponent implements OnInit {
  @Input() showUserConflictError : boolean;
  @Output() enableContinueButton = new EventEmitter<boolean>();
  @Output() makeAdmin = new EventEmitter<boolean>();
  accessAdminBool:boolean;

  constructor() { }

  ngOnInit() {
  }

  enableContinue(){
    this.makeAdmin.emit(this.accessAdminBool)
    this.enableContinueButton.emit(true);
  }

}
