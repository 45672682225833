import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../shared/data.service';
import { EditorialContentService } from '../editorial-content/editorial-content.service';
import { PortalService } from '../shared/portal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-related-content-item',
  templateUrl: './related-content-item.component.html',
  styleUrls: ['related-content-item.component.scss'],
})
export class RelatedContentItemComponent implements OnInit {
  @Input() relatedContentItem: any;
  //   { eyebrow: string, image: any, cta: any, heading: string, subheading: string, type: string, author: any, displayDate: string };

  primaryText: string;
  secondaryText: string;
  tertiaryText: string;
  quaternaryText: string;
  titleText: string;
  card: any;

  constructor(private dataService: DataService, private portalService: PortalService, private editorialContentService: EditorialContentService, private router: Router) {
    this.primaryText = '';
    this.secondaryText = '';
    this.tertiaryText = '';
    this.quaternaryText = '';
    this.titleText = '';
    this.card = null;
  }

  ngOnInit(): void {
    if (this.relatedContentItem.type === 'RelatedCaseStudyItem') {
      this.primaryText = this.truncateString(this.relatedContentItem.eyebrow, 50);
      this.secondaryText = this.truncateString(this.relatedContentItem.heading, 50);

      if (this.relatedContentItem.author != null && this.relatedContentItem.author.displayName != null) {
        this.quaternaryText = `By ${this.relatedContentItem.author.displayName}`;
      }

      this.tertiaryText = 'CASE STUDY';
    }

    if (this.relatedContentItem.type === 'RelatedInsightItem') {
      this.primaryText = this.truncateString(this.relatedContentItem.heading, 50);
      this.secondaryText = `By ${this.relatedContentItem.author.displayName}`;
      this.tertiaryText = 'INSIGHTS';
    }

    if (this.relatedContentItem.type === 'RelatedProductItem') {
      this.primaryText = this.truncateString(this.relatedContentItem.heading, 50);
      this.secondaryText = this.truncateString(this.relatedContentItem.eyebrow, 50);
      this.tertiaryText = 'PRODUCT / SERVICE';

      this.titleText = '';

      this.card = this.relatedContentItem.card;
    }

    if (this.relatedContentItem.type === 'RelatedWhitePaperItem') {
      this.primaryText = this.truncateString(this.relatedContentItem.eyebrow, 50);
      this.secondaryText = this.truncateString(this.relatedContentItem.heading, 50);

      if (this.relatedContentItem.author != null && this.relatedContentItem.author.displayName != null) {
        this.quaternaryText = `By ${this.relatedContentItem.author.displayName}`;
      }

      this.tertiaryText = 'WHITE PAPER';

      this.titleText = '';
    }

    if (this.relatedContentItem.type === 'RelatedEventItem') {
      this.secondaryText = this.truncateString(this.relatedContentItem.eyebrow, 50);
      this.primaryText = this.truncateString(this.relatedContentItem.heading, 50);
      this.quaternaryText = this.truncateString(this.relatedContentItem.subheading, 50);

      this.tertiaryText = 'EVENT';

      this.titleText = '';
    }
  }

  truncateString(str: string, limit: number) {
    if (str.length < limit) {
      return str;
    }
    const trimmedStr = str.slice(0, limit);
    const strParts = trimmedStr.split(' ');
    return strParts.slice(0, strParts.length - 1).join(' ') + '...';
  }

  isExternal(type: string) {
    if (type === 'WHITE PAPER' || type === 'EVENT' || type === 'PRODUCT / SERVICE') {
      return true;
    }

    return false;
  }

  navigateTo(type: string) {
    if (this.isExternal(type)) {
      this.navigateToExternalLink(this.relatedContentItem.cta.route, type);
    } else {
      this.navigateToInternalLink('editorial-content', this.relatedContentItem.cta.route);
    }
  }

  navigateToInternalLink(dest: string, route: string) {
    this.dataService.setEditorialContentRoute(route);
    this.router.navigate(['/' + dest]);
  }

  navigateToExternalLink(route: string, type: string) {
    if (type === 'WHITE PAPER') {
      this.navigateToWhitePaper(route);
    } else if (type === 'EVENT') {
      window.open(route, '_blank');
    } else {
      if (this.dataService.getAppProperties()) {
        const url = this.dataService.getAppProperties().nrgContentUrl + route;
        window.open(url, '_blank');
      } else {
        const url = '//localhost:3000' + route;
        window.open(url, '_blank');
      }
    }
  }

  navigateToWhitePaper(route: string) {
    this.editorialContentService.getContentPage(route).subscribe(
      (contentResponse) => {
        for (let i = 0; i < contentResponse.primaryComponents.length; i++) {
          const contentComponent = contentResponse.primaryComponents[i];

          if (contentComponent.type === 'LeadGeneration') {
            const file = contentComponent.payload.success.downloadFile;

            if (file != null) {
              if (this.dataService.getAppProperties()) {
                const url = this.dataService.getAppProperties().nrgContentUrl + file;
                window.open(url, '_blank');
              } else {
                // TODO: Fix later
                const url = '//localhost' + file;
                window.open(url, '_blank');
              }
            }
          }
        }
      }
    );
  }
}
