export const Permissions = {
  ManageUsers: 1,
  MakePayment: 2,
  EditPaymentMethods: 4,
  SubmitAmendmentRequest: 8,
  EditGroups: 16,
  ViewPortal: 32,
  View4CP: 64,
  PostSale: 62,
  PricingUser: 128,
  PricingAll: 256,
  PostSaleAndBrokerPricingUser: 190,
  PostSaleAndBrokerPricingALL: 318,
  Admin: 63,
  Commissions: 512,
  BrokerConfirms: 1024,
  BrokerDropsEnrollments: 2048
};
