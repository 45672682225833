import * as moment from 'moment';

export const solarTitle = 'SOLAR IRRADIANCE (W/m\xB2)';
export const apiDateFormat = 'YYYY-MM-DD';

export function sharedSeries(): any[] {
  return [{
    name: 'Shadow Meter Generation (KW)',
    type: 'area',
    yAxis: 0,
    step: true,
    turboThreshold: 500000
  }, {
    name: 'Initial Forecast (KW)',
    type: 'area',
    yAxis: 0,
    turboThreshold: 500000,
    step: true,
    stacking: undefined,
    zIndex: 1
  }, {
    name: 'Solar Irradiance (W/m\xB2)',
    type: 'area',
    yAxis: 1,
    turboThreshold: 500000,
    step: true,
    stacking: undefined
  }];
}

export function buildChartConfig(interval: number, overrides: any, afterSetExtremes = () => {}): any {
  return Object.assign({}, {
    title: undefined,
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
        }
      },
      area: {
        stacking: 'normal',
        symbol: 'circle'
      }
    },
    chart: {
      zoomType: 'x'
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      symbol: 'circle'
    },
    tooltip: {
      shared: true,
      formatter: function () { return tooltipFormatter(this, interval); }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return moment(this.value).format('M/D HH:mm');
        }
      },
      events: {
        afterSetExtremes: afterSetExtremes
      }
    },
    yAxis: [{
      title: {
        text: 'GENERATION (KW)'
      },
      labels: {
        format: '{value}'
      },
      min: 0
    }, {
      title: {
        text: solarTitle
      },
      labels: {
        format: '{value}'
      },
      min: 0,
      opposite: true
    }]
  }, overrides);
}

function gradient(number: number) {
  return {
    tagName: 'linearGradient',
    id: 'gradient-' + number,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 1,
    children: [{
      tagName: 'stop',
      offset: 0
    }, {
      tagName: 'stop',
      offset: 1
    }]
  };
}

export const gradient9 = gradient(9); // Dark Green
export const gradient10 = gradient(10); // Light Green

export function tooltipFormatter(context: any, interval: number) {
  const date = moment(context.x);
  let output = date.format('ddd, MMM Do') + '<br/>';
  output += date.format('HH:mm') + ' - ' + date.add(interval, 'minutes').format('HH:mm') + ' CST';

  context.points.filter(point => point.y !== null).forEach(point => {
    output += '<br/>' + point.series.name + ': ' + point.y.toFixed(3) + ' ';
    if (point.series.name.includes('KW')) {
      output += 'KW';
    } else {
      output += 'W/m\xB2';
    }
    if (point.point.count !== undefined && point.point.count !== null) {
      output += '<br/>' + point.point.countLabel + point.point.count;
    }
  });

  return output;
}

export function updateSeriesVisibility(graphChart: any, seriesIndex: number, visible: boolean) {
  const series = graphChart.series[seriesIndex];
  if (visible) {
    series.show();
    series.options.showInLegend = true;
    graphChart.legend.renderItem(series);
  } else {
    series.hide();
    series.options.showInLegend = false;
    series.legendItem = null;
    graphChart.legend.destroyItem(series);
  }
}

export function setSolarExtremes(graphChart: any, maxSolar: number) {
  graphChart.yAxis[1].setExtremes(0, maxSolar * 2);
}
