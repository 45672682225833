// Defines the Date Periods

export const DatePeriodsArr = [
  {
    value: 'ThisMonth',
    display: 'This Month'
  }, {
    value: 'LastMonth',
    display: 'Last Month'
  }, {
    value: 'Last3Months',
    display: 'Last 3 Months'
  }, {
    value: 'Last6Months',
    display: 'Last 6 Months'
  }, {
    value: 'LastYear',
    display: 'Last Year'
  }, {
    value: 'Last2Years',
    display: 'Last 2 Years'
  }, {
    value: 'Last3Years',
    display: 'Last 3 Years'
  }
];

export const DatePeriods = {
  ThisMonth: 'ThisMonth',
  LastMonth: 'LastMonth',
  Last3Months: 'Last3Months',
  Last6Months: 'Last6Months',
  LastYear: 'LastYear',
  Last2Years: 'Last2Years',
  Last3Years: 'Last3Years'
};

export const UsageDatePeriodsArr = [
  {
    value: 'Last7Days',
    display: 'Last 7 Days'
  }, {
    value: 'Last30Days',
    display: 'Last 30 Days'
  }, {
    value: 'Last60Days',
    display: 'Last 60 Days'
  }, {
    value: 'Last90Days',
    display: 'Last 90 Days'
  }, {
    value: 'Last365Days',
    display: 'Last 365 Days'
  }, {
    value: 'LastWeek',
    display: 'Last Week'
  }, {
    value: 'ThisMonth',
    display: 'This Month'
  }, {
    value: 'LastMonth',
    display: 'Last Month'
  }, {
    value: 'Last3Months',
    display: 'Last 3 Months'
  }, {
    value: 'Last6Months',
    display: 'Last 6 Months'
  }, {
    value: 'LastYear',
    display: 'Last Year'
  }, {
    value: 'Last2Years',
    display: 'Last 2 Years'
  }, {
    value: 'Last3Years',
    display: 'Last 3 Years'
  }
];

export const UsageDatePeriods = {
  Last7Days: 'Last7Days',
  Last30Days: 'Last30Days',
  Last60Days: 'Last60Days',
  Last90Days: 'Last90Days',
  Last365Days: 'Last365Days',
  LastWeek: 'LastWeek',
  ThisMonth: 'ThisMonth',
  LastMonth: 'LastMonth',
  Last3Months: 'Last3Months',
  Last6Months: 'Last6Months',
  LastYear: 'LastYear',
  Last2Years: 'Last2Years',
  Last3Years: 'Last3Years'
};

export const UserMetricsDatePeriodsArr = [
  {
    value: 'Last7Days',
    display: 'Last 7 Days'
  }, {
    value: 'Last30Days',
    display: 'Last 30 Days'
  }, {
    value: 'Last60Days',
    display: 'Last 60 Days'
  }, {
    value: 'Last90Days',
    display: 'Last 90 Days'
  }, {
    value: 'Last365Days',
    display: 'Last 365 Days'
  }, {
    value: 'LastWeek',
    display: 'Last Week'
  }, {
    value: 'ThisMonth',
    display: 'This Month'
  }, {
    value: 'LastMonth',
    display: 'Last Month'
  }, {
    value: 'Last3Months',
    display: 'Last 3 Months'
  }, {
    value: 'Last6Months',
    display: 'Last 6 Months'
  }, {
    value: 'LastYear',
    display: 'Last Year'
  }, {
    value: 'Last2Years',
    display: 'Last 2 Years'
  }, {
    value: 'Last3Years',
    display: 'Last 3 Years'
  }
];

export interface DateOption {
  option: string,
  display: string,
  selected: boolean
}

export const UserMetricsDatePeriods: DateOption[] = [
  {option: 'ThisMonth', display: 'This Month', selected: false},
  {option: 'LastMonth', display: 'Last Month', selected: true},
  {option: 'Last3Months', display: 'Last 3 Months', selected: false},
  {option: 'Last6Months', display: 'Last 6 Months', selected: false},
  {option: 'LastYear', display: 'Last Year', selected: false},
  {option: 'InceptionToDate', display: 'Inception To Date', selected: false}
];

export const TransactionUserMetricsDatePeriods: DateOption[] = [
  {option: 'ThisMonth', display: 'This Month', selected: false},
  {option: 'LastMonth', display: 'Last Month', selected: true},
  {option: 'Last3Months', display: 'Last 3 Months', selected: false},
  {option: 'Last6Months', display: 'Last 6 Months', selected: false},
  {option: 'LastYear', display: 'Last Year', selected: false}
];


export const UserMetricsInceptionDate: string = '06/01/2018';

export const EnrollmentMetricsInceptionDate: string = '06/01/2018';

export const EnrollmentMetricsDatePeriods: DateOption[] = [
  {option: 'Last3Months', display: 'Last 3 Months', selected: true},
  {option: 'Last6Months', display: 'Last 6 Months', selected: false},
  {option: 'Last9Months', display: 'Last 9 Months', selected: false},
  {option: 'Last12Months', display: 'Last 12 Months', selected: false},
];


// Subscriptions

export const WeeklyDatePeriods: DateOption[] = [
  {option: 'MONDAY', display: 'Monday', selected: false},
  {option: 'TUESDAY', display: 'Tuesday', selected: false},
  {option: 'WEDNESDAY', display: 'Wednesday', selected: false},
  {option: 'THURSDAY', display: 'Thursday', selected: false},
  {option: 'FRIDAY', display: 'Friday', selected: false},
  {option: 'SATURDAY', display: 'Saturday', selected: false},
  {option: 'SUNDAY', display: 'Sunday', selected: false},
];

