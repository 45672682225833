import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { GenericPipe } from '../../shared/pipe/generic.pipe';
import { OrderByPipe } from '../../shared/pipe/orderBy.pipe';
import { DataService } from '../../shared/data.service';
import { IReportSettings } from '../../shared/entities/markets';
import { MarketsService } from '../markets.service';
import { FadeAnimation } from "../../animations/fadeAnimation";
import { IReportTable } from './report-table-interface';
import * as moment from 'moment';
import {ForwardPriceForm} from "../forward-price-form/forward-price.form";
import {PortalService} from "../../shared/portal.service";
import { IZone } from '../../shared/entities/product';
import { Authority } from '../../shared/const/authority';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forward-pricing-reports',
  templateUrl: './forward-pricing-reports.component.html',
  animations: [ FadeAnimation ],
  styleUrls: [ '../../shared/styles/reports.scss',
  './forward-pricing-reports.component.scss' ]
})
export class ForwardPricingReportsComponent implements OnInit {

  @Output() viewReport: EventEmitter<ForwardPriceForm> = new EventEmitter();

  showCreateReport: boolean = false;
  showSearchRow: boolean = false;
  sortBy: string = 'accessType';
  ascending: boolean = false;
  searchTerm: string;
  allReports: IReportTable[] = [];
  filteredReports: IReportTable[] = [];
  reportToDelete: IReportTable;
  reportSettingsData: IReportSettings[];
  isSuperUser: boolean = false;
  isSuperUserAdmin: boolean = false;
  userSourceSubscription: Subscription;

  constructor(private dataService: DataService,
    private marketsService: MarketsService,
    private orderByPipe: OrderByPipe,
    private genericPipe: GenericPipe,
    private portalService: PortalService) { }

  ngOnInit() {
    this.getReportSettings();
    this.userSourceSubscription = this.dataService.userSourceUpdated.subscribe(
      () => {
        this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
        this.isSuperUserAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
      }
    );
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isSuperUserAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
  }

  ngOnDestroy() {
    if (this.userSourceSubscription) this.userSourceSubscription.unsubscribe();
  }

  viewSelectedReport(report: IReportTable) {
    const currReport = this.reportSettingsData.find(reportSetting => reportSetting.reportId === report.id);
    if (currReport) {
      const form: ForwardPriceForm = new ForwardPriceForm(this.portalService, this.marketsService, this.dataService);
      form.setFormFromSettings(currReport);
      this.viewReport.emit(form);
    }
  }

  switchTabs(selectedTab: string) {
    this.dataService.setSelectedTabForMarketsReports(selectedTab);
    // this.selectedTab = selectedTab;
  }

  toggleCreateReport() {
    this.showCreateReport = !this.showCreateReport;
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterReports();
  }

  getReportSettings() {
    this.dataService.setLoading(true);
    this.marketsService.getReportSettings().subscribe((results: IReportSettings[]) => {
      results.forEach( (result) => {
        result.updatedDateDisplay = moment(result.updatedDate).format('MM/DD/YY');
      });
      this.reportSettingsData = results;
      this.allReports = this.mapReportTableData(results);
      this.filterReports();
      this.sortReports("accessType");
      this.dataService.setLoading(false);
    });
  }

  mapReportTableData(reportSettingResponse: IReportSettings[]) {
    return reportSettingResponse.map(rSetting => {
      return {
        id: rSetting.reportId,
        name: rSetting.reportName,
        accountId: rSetting.accountId,
        type: rSetting.reportType,
        dateModified: rSetting.updatedDateDisplay,
        market: rSetting.market,
        terms: rSetting.termLengths.length,
        settlementZone: this.getZoneDisplay(rSetting.market, rSetting.congestionZones),
        accessType: rSetting.accessType,
        globalFlag: rSetting.globalFlag,
        publicFlag: rSetting.publicFlag,
      } as IReportTable
    });
  }

  filterReports() {
    if (!this.searchTerm) {
      this.filteredReports = this.allReports;
    } else {
      this.filteredReports = this.genericPipe.transform(this.allReports, this.searchTerm, ["id", "accountId", "globalFlag", "publicFlag"]);
    }
  }

  sortReports(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    //TODO: change mockSavedReports to actual
    this.orderByPipe.transform(this.allReports, this.sortBy, this.ascending);
  }

  deleteReport() {
    this.marketsService.deleteReportSettings(this.reportToDelete.accountId, this.reportToDelete.id).subscribe(() => {
      // Update the reports to show it got deleted
      this.getReportSettings();
    }, err => {
      // Deleting throws 200 error when successfully deleted
      if (err.status === 200) {
        this.getReportSettings();
      }
    });
  }

  getZoneDisplay(market, congestionZones: IZone[]) {
    return ForwardPriceForm.getZoneDisplay(market, congestionZones);
  }
}
