export interface ISubscribeReportTemplate {
    title: string;
    defaultReportName: string;
    reportType: string;
    description: string;
    filters: ReportFilter[];
}

export interface ReportFilterOption {
    label: string;
    value: string;
}

export interface ReportFilter {
    name: string;
    filterType: FilterType;
    options: ReportFilterOption[];
    selectedOptionLabel: string;
    selectedOptionValue: string;
    savedOptionValue?: string;
    savedOptionLabel?: string;
    defaultOptionValue:string;
    defaultOptionLabel:string;
    savedFilterControl?: any; // Used to store more complex user input such as DateRangeControl and SitesControl
    showfilter: boolean;
}

export enum FilterType {
    SimpleDropdown = "SimpleDropdown",
    SitePicker = "SitePicker",
    DatePicker = 'DatePicker',
}

export interface SavedReport {
    reportName: string;
    reportDescription?: string;
    isPrivate:boolean;
}