import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";
import {Http, ResponseContentType} from "@angular/http";
import {environment} from "../../environments/environment";
import {IAccountLinkRequest} from "./entities/acccountLinkRequest"
import { IAccountLinkResponse } from './entities/accountLinkResponse';
import { IEmailCheckRequest } from './entities/EmailRequest';
import { IEmailCheckResponse } from './entities/EmailResponse';
import { ISelfRegisterRequest } from './entities/SelfRegisterRequest';
import { ISelfRegisterResponse } from './entities/SelfRegisterResponse';
import {Observable} from "rxjs";
import { IEletricUtilityProviderResponse } from './entities/electric-utility-provider';





@Injectable()
export class SelfRegisterService {
    constructor(private http: HttpClient, private oldHttp: Http) { }

    getElectricUtilityProviders() {
      return this.http.get(environment.getElectricUtilityProviders)
      .map((response: HttpResponse<IEletricUtilityProviderResponse[]>) => response)
      .catch(this.handleError);
    }

    getAttemptsRemaining(){
    return this.http.get(environment.getAttemptsRemaining)
    .map((response: HttpResponse<any>) => response)
    .catch(this.handleError);
  }

    linkAccount(req: IAccountLinkRequest) {
        let url = environment.linkAccountUrl;
        return this.http
          .post(url, req)
          .map((response: IAccountLinkResponse) => response)
      }

    checkEmail(req: IEmailCheckRequest) {
      let url = environment.checkEmail;
      return this.http
        .post(url,req)
        .map((response: IEmailCheckResponse) => response)
    }

    createAccount(req: ISelfRegisterRequest) {
        let url = environment.selfRegister;
        return this.http
        .post(url,req)
        .map((response: ISelfRegisterResponse) => response)
    }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}
