import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { FadeAnimation } from '../animations/fadeAnimation';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import { IUserAccount } from '../shared/entities/profile';
import { ISiteGroup, ISite } from '../shared/entities/site';
import { ISitesControl } from '../shared/entities/billingComparison';
import { IComparisonChartData, IComparisonReport, IComparisonChartResponse } from '../shared/entities/comparison';
import { SiteGroupService } from '../site-groups/site-groups.service';
import { PortalService } from '../shared/portal.service';
import { Subscription } from 'rxjs';
import { UsageComparisonService } from './usage-comparison.service';
declare var $: any;
declare var Highcharts: any;
import * as moment from 'moment';
import { ISiteCounts } from '../shared/entities/usageGraph';
import { FakerService } from '../shared/faker.service';
import { ComparisonUtility } from '../shared/comparison-reports.utility';
import { IHelpStep } from '../shared/entities/contextualHelp';
import { Router } from '@angular/router';
import { IAccount } from '../shared/entities/account';

@Component({
  selector: 'app-usage-comparison',
  templateUrl: './usage-comparison.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['./usage-comparison.component.scss', '../shared/styles/reports.scss']
})
export class UsageComparisonComponent implements OnInit, OnDestroy {
  reports: IComparisonReport[];
  publicReports: IComparisonReport[];
  privateReports: IComparisonReport[];
  selectedReport: IComparisonReport;

  selectedCustomer: IUserAccount;
  accountWithContractData: IAccount;
  userSiteGroups: ISiteGroup[] = [];
  systemSiteGroups: ISiteGroup[] = [];
  sitesForAccount: ISite[] = [];
  filteredSites: ISite[] = [];
  siteCounts: ISiteCounts;

  sitesControl: ISitesControl = {
    siteOptions: [],
    siteSearch: '',
    siteRequest: 'ALL_SITES',
    selectedGrouping: null,
    selectedCategory: null,
    selectedSite: null,
    categoriesForGroup: [],
    siteId: null,
    display: 'All Sites'
  };

  marketsGroup = {
    groupName: 'MARKETS',
    display: 'Markets'
  };

  showDateType = false;
  showYears = false;
  showSites = false;
  enableSave = false;
  makeDefault = true;
  makePublic = false;
  noDataError = false;
  isMobile = false;
  maskMode: boolean;
  makeNewDefault = false;
  makeNewPublic = false;

  showReports: boolean;
  showSettings: boolean;
  showSave: boolean;

  siteIndex: number = -1;
  newReportName: string;
  tempYear1: number;
  tempYear2: number;
  years: number[];
  helpStep: IHelpStep;

  chartData: IComparisonChartData = {
    usageYear1: [],
    usageYear2: [],
    usageDelta: [],
    siteCountYear1: [],
    siteCountYear2: [],
    siteCountDelta: []
  };

  tableColumns = ['Year', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  accountSourceSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  getSiteGroupsSubscription: Subscription;
  siteCountsSubscription: Subscription;
  sitesForAccountSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private dataService: DataService,
    private service: UsageComparisonService,
    private siteGroupsService: SiteGroupService,
    private portalService: PortalService,
    private fakerService: FakerService,
    private comparisonUtility: ComparisonUtility,
    private router: Router

  ) { }

  ngOnInit() {
    this.dataService.setTitleSource('Usage Comparison Report');
    this.dataService.setSelectedNavItem('usage');
    this.dataService.setCurrentActivePage('usage');
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        numericSymbols: null
      }
    });

    this.selectedCustomer = this.dataService.getAccountSource();
    this.maskMode = this.dataService.getMaskMode();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.dataService.setLoading(true);
        this.selectedCustomer = account;
      }
    );

    const siteGroups = this.dataService.getSiteGroupsSource();
    if (siteGroups) {
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.loadReports();
    }
    this.getSiteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.systemSiteGroups = siteGroups.systemSiteGroups;
        this.userSiteGroups = siteGroups.userSiteGroups;
        this.loadReports();
      }
    );

    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.accountWithContractData = account;
    });

    this.siteCounts = this.dataService.getSiteCountsSource();
    this.siteCountsSubscription = this.dataService.siteCountSourceUpdated.subscribe(
      (siteCounts) => {
        this.siteCounts = siteCounts;
      }
    );

    this.sitesForAccount = this.dataService.getSitesForAccountSource();
    if (this.sitesForAccount) {
      this.loadSiteOptions();
    }
    this.sitesForAccountSubscription = this.dataService.sitesForAccountSourceUpdated.subscribe(
      (sites) => {
        this.sitesForAccount = sites;
        this.loadSiteOptions();
      }
    );

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.isMobile = this.dataService.getIsMobile();

    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#sitesSection').length == 0) {
        this.showSites = false;
      }
      if ($($event.target).parents('#yearsSection').length == 0) {
        this.showYears = false;
      }
      if ($($event.target).parents('#optionsSection').length == 0) {
        this.showSettings = false;
      }
      if ($($event.target).parents('#reportsSection').length == 0) {
        this.showReports = false;
      }
      if ($($event.target).parents('#saveSection').length == 0) {
        this.showSave = false;
      }
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
      this.helpStepUpdated();
    });
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.getSiteGroupsSubscription) this.getSiteGroupsSubscription.unsubscribe();
    if (this.siteCountsSubscription) this.siteCountsSubscription.unsubscribe();
    if (this.sitesForAccountSubscription) this.sitesForAccountSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  helpStepUpdated() {
    if (this.helpStep && this.helpStep.stepNum == 1) {
      this.showReports = true;
    } else {
      this.showReports = false;
    }
  }

  toggleReports() {
    this.showReports = !this.showReports;
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  toggleSave() {
    this.showSave = !this.showSave;
  }

  saveNewReport() {
    this.selectedReport.reportId = null;
    $('#renameModal').modal('show');
    this.toggleSave();
  }

  setAsDefault() {
    this.toggleSettings();
    this.makeDefault = true;
    this.enableSave = true;
    this.saveReport();
  }

  getDefaultReport() {
    const defaultReport: IComparisonReport = {
      accountId: this.selectedCustomer.id,
      reportName: 'Comparison Report 01',
      year1: this.years[0],
      year2: this.years[1],
      siteRequest: 'ALL_SITES',
      defaultReport: 0
    };
    return defaultReport;
  }

  goToSiteGroups() {
    this.router.navigate(['/site-groups']);
  }

  loadReports(keepSelected?: boolean) {
    if (!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.years = [moment().year(), moment().year() - 1, moment().year() - 2, moment().year() - 3];
    this.service.getReport(this.selectedCustomer.id).subscribe(
      (reports) => {
        this.reports = reports;
        // Find default report and select it
        let defaultReport = null;
        if (!keepSelected) {
          if (this.reports.length > 0) {
            this.reports.forEach((report) => {
              if (report.defaultReport) {
                defaultReport = report;
              }
            });
            if (!defaultReport) {
              this.selectReport(this.reports[0]);
            } else {
              this.selectReport(defaultReport);
            }
          } else {
            defaultReport = this.getDefaultReport();
            this.selectReport(defaultReport);
            this.enableSave = true;
            this.saveReport();
          }
        }
        this.publicReports = [];
        this.privateReports = [];
        this.reports.forEach((report) => {
          if (report.publicFlag) {
            this.publicReports.push(report);
          } else {
            this.privateReports.push(report);
          }
        });
      }
    );
  }

  loadSiteOptions() {
    this.sitesForAccount.forEach((site) => {
      this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
    });
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  selectReport(report: IComparisonReport) {
    if (!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.selectedReport = report;
    this.makeDefault = this.selectedReport.defaultReport == 1;
    this.makePublic = this.selectedReport.publicFlag == 1;
    this.getSiteControlDisplay();
    this.createCharts();
  }

  createCharts() {
    this.dataService.setLoading(true);
    this.service.getCharts(this.selectedReport).subscribe(
      (chartResponse) => {
        this.dataService.setLoading(false);
        if (chartResponse.year1Values.length == 0 && chartResponse.year2Values.length == 0) {
          this.noDataError = true;
        } else {
          this.noDataError = false;
          this.extractChartData(chartResponse);

          setTimeout(() => {
            // console.log('element2',elem2);
            this.createUsageChart();
            if (this.sitesControl.siteRequest != 'SPECIFIC_SITE') {
              this.createSitesChart();
            }
            // this.createUsageChart();
          }, 1000);

        }

      }, err => {
        if (err.status == 404) {
          this.chartData = null;
          this.noDataError = true;
        }
      }
    );
  }

  extractChartData(chartResponse: IComparisonChartResponse) {
    this.chartData = this.comparisonUtility.extractChartData(chartResponse, this.selectedReport);
  }

  getSiteControlDisplay() {
    if (this.selectedReport.siteRequest == 'ALL_SITES') {
      this.sitesControl.display = 'All Sites';
    } else if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
      this.sitesControl.display = this.selectedReport.siteGroupCategory;
    } else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
      if (!this.maskMode) {
        this.sitesControl.display = '...' + this.selectedReport.siteId.slice(-5);
      } else {
        this.sitesControl.display = '...' + this.fakerService.getSiteId().substr(5);
      }
    }
  }

  populateSitesControl() {
    // Load data from selectedReport into customerControl
    this.sitesControl.siteRequest = this.selectedReport.siteRequest;
    if (this.sitesControl.siteRequest == 'GROUP_CATEGORY') {
      this.systemSiteGroups.forEach((systemGroup) => {
        if (systemGroup.groupName == this.selectedReport.systemGroupName) {
          this.selectGroup(systemGroup);
        }
      });
      this.userSiteGroups.forEach((userGroup) => {
        if (userGroup.id == this.selectedReport.groupId) {
          this.selectGroup(userGroup);
        }
      });
      this.sitesControl.selectedCategory = this.selectedReport.siteGroupCategory;
    } else if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      this.filteredSites.forEach((site, index) => {
        if (site.siteId == this.selectedReport.siteId) {
          this.selectSite(site, index);
        }
      });
    }
  }

  renameReport() {
    this.selectedReport.reportName = this.newReportName;
    this.enableSave = true;
    this.saveReport();
    $('#renameModal').modal('hide');
  }

  createNewReport() {
    const newReport = this.getDefaultReport();
    newReport.reportName = this.newReportName;
    this.makePublic = this.makeNewPublic;
    this.makeDefault = this.makeNewDefault;
    this.enableSave = true;
    this.selectedReport = newReport;
    this.saveReport();
    $('#createReportModal').modal('hide');
  }

  openCreateNewReport() {
    this.makeNewDefault = false;
    this.makeNewPublic = false;
    this.newReportName = '';
  }

  goPublic() {
    this.toggleSettings();
    this.makePublic = true;
    this.enableSave = true;
    this.saveReport();
  }

  duplicateAndSave() {
    this.makePublic = false;
    this.makeDefault = false;
    this.newReportName = '';
    $('#renameModal').modal('show');
  }

  tempReportNameChanged() {
    this.enableSave = this.newReportName.length > 0;
  }

  saveReport() {
    if (this.enableSave) {
      this.dataService.setLoading(true);
      this.selectedReport.defaultReport = this.makeDefault ? 1 : 0;
      this.selectedReport.publicFlag = this.makePublic ? 1 : 0;
      // console.log(this.selectedReport);
      this.service.saveReport(this.selectedReport).subscribe((savedReport) => {
        this.selectedReport = savedReport;
        this.enableSave = false;
        this.loadReports(true);
        this.makeNewDefault = false;
        this.makeNewPublic = false;
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 1000);
      }, err => {
        if (err.status == 409) {
          this.selectedReport.publicFlag = 0;
          this.dataService.setLoading(false);
          $('#duplicateModal').modal('show');
        }
      });
    }
  }

  deleteReport() {
    this.dataService.setLoading(true);
    this.service.deleteReport(this.selectedReport).subscribe(() => {
      this.loadReports();
      setTimeout(() => {
        this.dataService.setLoading(false);
      }, 1000);
    });
  }

  selectGroup(group: ISiteGroup) {
    this.sitesControl.selectedGrouping = group;
    if (this.sitesControl.selectedGrouping.id) {
      if (this.sitesControl.selectedGrouping.groupName.length > 15) {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName.substring(0, 15) + '...';
      } else {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName;
      }
      this.siteGroupsService.getCategoriesForUserGroup(this.sitesControl.selectedGrouping.id).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      );
    } else {
      this.siteGroupsService.getCategoriesForSystemGroup(this.sitesControl.selectedGrouping.groupName).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      );
    }
  }

  selectCategory(category: string) {
    this.sitesControl.selectedCategory = category;
  }

  defaultChanged() {
    if (this.makeDefault && this.selectedReport.defaultReport || !this.makeDefault && !this.selectedReport.defaultReport) {
      this.enableSave = false;
    } else {
      this.enableSave = true;
    }
  }

  selectSite(site: ISite, index?: number) {
    if (this.filteredSites.length > 0) {
      if (this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if (index) {
        this.sitesControl.selectedSite = this.filteredSites[index];
      } else {
        this.sitesControl.selectedSite = this.filteredSites[this.siteIndex];
      }
      if (!this.maskMode) {
        this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
      } else {
        this.sitesControl.siteSearch = this.getFakeSiteId(site) + ' ' + this.fakerService.getCompanyName();
      }
      this.resetTypeahead();
    }
  }

  resetSiteSelection() {
    this.sitesControl.siteSearch = null;
  }

  applySites() {
    // Apply changes in customerControl to selectedReport
    this.toggleSites();
    // console.log('site request is ', this.sitesControl.siteRequest);
    this.selectedReport.siteRequest = this.sitesControl.siteRequest;
    if (this.selectedReport.siteRequest == 'ALL_SITES') {
      this.selectedReport.siteId = this.selectedReport.systemGroupName = this.selectedReport.groupId = this.selectedReport.siteGroupCategory = null;
    } else if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
      this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
      if (this.sitesControl.selectedGrouping.id) {
        this.selectedReport.groupId = this.sitesControl.selectedGrouping.id;
      } else {
        this.selectedReport.systemGroupName = this.sitesControl.selectedGrouping.groupName;
      }
    } else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
      this.selectedReport.siteId = this.sitesControl.selectedSite.siteId;
    }
    // console.log(this.selectedReport);
    this.enableSave = true;
    this.getSiteControlDisplay();
    this.createCharts();
    this.resetSiteSelection();
  }

  formatDelta(delta: number) {
    if (!delta && delta != 0) {
      return '--';
    }
    return this.numberWithCommas(delta);
  }

  numberWithCommas(x) {
    if (x == null) {
      return '--';
    }
    const response = this.portalService.numberWithCommas(x);
    return response;
  }

  toggleYears() {
    this.showYears = !this.showYears;
    if (this.showYears) {
      this.tempYear1 = this.selectedReport.year1;
      this.tempYear2 = this.selectedReport.year2;
    }
    this.showDateType = this.showSites = false;
  }

  toggleSites() {
    this.showSites = !this.showSites;
    if (this.showSites) {
      this.populateSitesControl();
    } else {
      if (this.sitesControl.selectedSite) {
        if (!this.maskMode) {
          this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
        } else {
          this.sitesControl.siteSearch = this.getFakeSiteId(this.sitesControl.selectedSite) + ' ' + this.fakerService.getCompanyName();
        }
      } else {
        this.sitesControl.siteSearch = '';
      }
    }
    this.showDateType = this.showYears = false;
  }


  navigateTypeahead(down: boolean) {
    if (down && this.siteIndex < this.filteredSites.length - 1) {
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }


  selectYear1(year: number) {
    this.tempYear1 = year;
  }

  selectYear2(year: number) {
    this.tempYear2 = year;
  }

  applyYears() {
    this.selectedReport.year1 = this.tempYear1;
    this.selectedReport.year2 = this.tempYear2;
    this.enableSave = true;
    this.toggleYears();
    this.createCharts();
  }

  searchSites() {
    this.filteredSites = [];
    this.sitesForAccount.forEach((site) => {
      if (site.siteId.match(this.sitesControl.siteSearch) ||
        (site.serviceAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
        this.sitesControl.siteSearch == '') {
        this.filteredSites.push(site);
      }
    });
  }


  createUsageChart() {
    Highcharts.chart('usageChart', {
      chart: {
        height: this.isMobile ? 250 : 340
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: { title: { text: 'USAGE (kWh)' } },
      credits: {
        enabled: false
      },

      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      exporting: { enabled: false },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y: ,0.0f} kWh</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.usageYear1
      }, {
        name: this.selectedReport.year2,
        showInLegend: false,
        type: 'area',
        keys: ['y', 'selected'],
        data: this.chartData.usageYear2
      }]
    });
  }

  createSitesChart() {
    Highcharts.chart('sitesChart', {
      chart: {
        type: 'column',
        height: this.isMobile ? 250 : 340
      },
      exporting: { enabled: false },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'NUMBER OF SITES'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y: ,0.0f} site(s)</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      series: [{
        name: this.selectedReport.year1,
        showInLegend: false,
        data: this.chartData.siteCountYear1
      }, {
        name: this.selectedReport.year2,
        showInLegend: false,
        data: this.chartData.siteCountYear2
      }]
    });
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}
