import { Component, OnInit } from '@angular/core';
import {PaymentsService} from '../payments.service';
import { PortalService } from '../../shared/portal.service';
import {IPaymentState, IPaymentMethod} from "../payments";
import {ISite} from "../../shared/entities/site";
import {PagerService} from "../../shared/pager.service";
import {Router} from "@angular/router";
import { BankAccountPipe } from "../../shared/pipe/bank-account.pipe"
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {FakerService} from "../../shared/faker.service";
import {IHelpStep} from "../../shared/entities/contextualHelp";

@Component({
  selector: 'app-review-payment',
  templateUrl: './review-payment.component.html',
  providers: [BankAccountPipe],
  styleUrls: ['./review-payment.component.scss']
})
export class ReviewPaymentComponent implements OnInit {
  selectedBalances: any[];
  pager: any;
  paymentTotal: number;
  paymentDate: Date;
  paymentMethod: IPaymentMethod;
  maskMode: boolean;
  helpStep: IHelpStep;
  payByAccount = false;

  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private paymentsService: PaymentsService,
              private portalService: PortalService,
              private pagerService: PagerService,
              private dataService: DataService,
              private fakerService: FakerService,
              private router: Router) { }

  ngOnInit() {
    // Prevent back button after payment submission
    if(this.paymentsService.submitted) {
      this.router.navigate(['/']);
    }
    this.paymentsService.setStepReached(3);
    this.selectedBalances = this.paymentsService.selectedBalances;
    this.paymentTotal = this.paymentsService.paymentTotal;
    this.paymentDate = this.paymentsService.paymentSchedule.paymentDate;
    this.paymentMethod = this.paymentsService.paymentSchedule.paymentMethod;
    this.paginate(1);

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    this.payByAccount = this.paymentsService.payByAccount;
  }

  ngOnDestroy() {
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  numberWithCommas(x) {
    return this.portalService.numberWithCommas(x);
  }

  getDateDisplay(dateString: string) {
    return this.portalService.getDateDisplay(dateString);
  }

  paginate(page: number) {
    if(this.selectedBalances){
      this.pager = this.pagerService.getPage(this.selectedBalances, page);
    }
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

}
