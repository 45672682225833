import { Injectable } from '@angular/core';
import {Http, Response, Headers, ResponseContentType} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {environment} from "../../environments/environment";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {IComparisonReport, IComparisonChartResponse, IComparisonChartData} from "../shared/entities/comparison";
import {HttpResponse, HttpHeaders, HttpClient} from "@angular/common/http";

@Injectable()
export class UsageComparisonService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getCharts(request: IComparisonReport) : Observable<IComparisonChartResponse> {
    let url = environment.getUsageComparisonChartsUrl;
    if(environment.production) {
      url = url.replace('{accountId}', request.accountId.toString());
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IComparisonChartResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }else {
       return this.http
      .get(url).pipe(
        map((data: IComparisonChartResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  getReport(accountId: string): Observable<IComparisonReport[]> {
    let url = environment.production ? environment.getUsageComparisonReportUrl.replace('{accountId}', accountId.toString()) : environment.getBillingComparisonReportsUrl;
    return this.http
    .get(url).pipe(
      map((data: IComparisonReport[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getYears(accountId: string): Observable<number[]> {
    let url = environment.production ? environment.getUsageComparisonYearsUrl.replace('{accountId}', accountId.toString()) : environment.getBillingComparisonYearsUrl;
    return this.http
      .get(url).pipe(
        map((data: number[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }


  saveReport(setting: IComparisonReport): Observable<any> {
    let url = environment.createOrUpdateUsageComparisonReportUrl.replace('{accountId}', setting.accountId.toString());
    if(setting.reportId) {
      url = url.replace('{reportId}', setting.reportId.toString());
    } else {
      url = url.replace('{reportId}', '0');
    }
    return this.http
      .post(url, setting, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  deleteReport(setting: IComparisonReport): Observable<any> {
    let url = environment.deleteUsageComparisonReportUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', setting.reportId.toString());
    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }




}
