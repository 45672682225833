import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {ISiteGroup} from "../../shared/entities/site";
import {Permissions} from "../../shared/const/permissions";
import {PortalService} from "../../shared/portal.service";
import {SiteGroupService} from "../site-groups.service";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {Router} from "@angular/router";
import {IHelpStep} from "../../shared/entities/contextualHelp";
declare var $ :any;

@Component({
  selector: 'app-manage-site-groups',
  templateUrl: './manage-site-groups.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['manage-site-groups.component.scss', '../site-groups.component.scss']
})
export class ManageSiteGroupsComponent implements OnInit {

  systemSiteGroups: ISiteGroup[];
  userSiteGroups: ISiteGroup[];
  selectedGroup: ISiteGroup;
  newGroupName: string = '';
  showCreateGroupError: boolean = false;
  helpStep: IHelpStep;

  accountSourceSubscription: Subscription;
  siteGroupsSubscription: Subscription;
  helpStepSubscription: Subscription;

  allowedToEditGroups: boolean;

  constructor(private dataService: DataService,
              private portalService: PortalService,
              private siteGroupService: SiteGroupService,
              private router: Router) { }

  ngOnInit() {
    this.dataService.setSelectedNavItem('accounts'); // highlight nav item on page refresh
    this.setCurrentActivePage('accounts');
    this.dataService.setTitleSource('Site Groups');
    let siteGroups = this.dataService.getSiteGroupsSource();
    if(siteGroups){
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.systemSiteGroups = siteGroups.systemSiteGroups;
    }
    this.siteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.dataService.setLoading(false);
        this.userSiteGroups = siteGroups.userSiteGroups;
        this.systemSiteGroups = siteGroups.systemSiteGroups;
      }
    );

    this.allowedToEditGroups = this.portalService.hasPermission(Permissions.EditGroups);
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.dataService.setLoading(true);
        this.allowedToEditGroups = this.portalService.hasPermission(Permissions.EditGroups);
      }
    );

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    })
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.siteGroupsSubscription) this.siteGroupsSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  createGroup(){
    if(!this.newGroupName){
      this.showCreateGroupError = true;
    } else {
      let newGroup: ISiteGroup = {
        id: null,
        accountId: this.dataService.getAccountSource().id,
        groupName: this.newGroupName,
        description: '',
      };
      this.siteGroupService.createOrUpdateSiteGroup(newGroup).subscribe(
        (group) => {
          this.viewSiteGroup(group, false);
          this.siteGroupService.getSiteGroups(this.dataService.getAccountSource().id).subscribe(
            (siteGroups) => {
              this.dataService.setSiteGroupsSource(siteGroups);
            }, err => {
              console.error(err);
            }
          )
        }, err => {
          console.error(err);
        }
      )
    }
  }

  deleteGroup() {
    this.siteGroupService.deleteSiteGroup(this.selectedGroup).subscribe(
      resp => {
        // GET after DELETE to ensure deletion
        this.siteGroupService.getSiteGroups(this.dataService.getAccountSource().id).subscribe(
          (siteGroups) => {
            this.dataService.setSiteGroupsSource(siteGroups);
          }, err => {
            console.error(err);
          })
      }, err => {
        console.error(err);
      }
    )
  }

  viewSiteGroup(group: ISiteGroup, isSystem: boolean){
    this.newGroupName = '';
    this.showCreateGroupError = false;
    $('#createModal').modal('hide');
    this.siteGroupService.setSelectedGroup(group);
    this.router.navigate(['/site-groups/view-edit']);
  }

  selectGroup(siteGroup: ISiteGroup){
    this.selectedGroup = siteGroup;
  }

}
