import {PipeTransform, Pipe} from '@angular/core';
import {ISite} from '../entities/site';
import {IUsageReportReponse} from '../entities/usage-reports';

@Pipe({
  name: 'usageReportPipe'
})
export class UsageReportPipe implements PipeTransform {

  transform(usageReport: IUsageReportReponse, term: string): IUsageReportReponse {
    term = term.toLowerCase().trim();
    let filteredReport = {
      totalCurrentUsage: usageReport.totalCurrentUsage,
      averageDailyUsage: usageReport.averageDailyUsage,
      peakDemand: usageReport.peakDemand,
      peakDemandHour: usageReport.peakDemandHour,
      loadFactor: usageReport.loadFactor,
      siteCount: usageReport.siteCount,
      categories: []
    };
    usageReport.categories.forEach((category) => {
      if(category.name && category.name.toLowerCase().match(term)){
        filteredReport.categories.push(category);
      } else {
        let sites = [];
        category.sites.forEach((site) => {
          for(let prop in site){
            if(site[prop] && site[prop].toString().toLowerCase().match(term)){
              sites.push(site);
              break;
            }
          }
        });
        if(sites.length > 0) {
          let filteredCategory = Object.assign({}, category);
          filteredCategory.sites = sites;
          filteredReport.categories.push(filteredCategory);
        }
      }
    });
    return filteredReport;
  }
}
