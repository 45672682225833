import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {ISuperUser} from "../shared/entities/superUser";
import {IUser} from "../shared/entities/user"
import {ManageSuperUsersService} from "./manage-super-users.service";
import {IPagerDenom, PagerDenoms} from "../shared/const/pagerDenoms";
import {PagerService} from "../shared/pager.service";
import {GenericPipe} from "../shared/pipe/generic.pipe";
import {PortalService} from "../shared/portal.service";
import {OrderByPipe} from "../shared/pipe/orderBy.pipe";
import {Authority} from "../shared/const/authority";

declare var $ :any;
import * as moment from 'moment';
import {Subscription} from "rxjs";
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-manage-super-users',
  templateUrl: './manage-super-users.component.html',
  styleUrls: ['./manage-super-users.component.scss'],
  providers: [GenericPipe, OrderByPipe]
})
export class ManageSuperUsersComponent implements OnInit {

    constructor(private dataService: DataService,
              private manageSuperUserService: ManageSuperUsersService,
              private pagerService: PagerService,
              private pipe: GenericPipe,
              private portalService: PortalService,
              private orderByPipe: OrderByPipe) { }

  superUsers: ISuperUser[];
  filteredSuperUsers: ISuperUser[];
  superUserAdmin: boolean = false;

  searchTerm: string = "";

  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  ascending: boolean = true;
  sortCol: string = 'firstName';
  showSearchRow: boolean = false;
  usersWithCommissionsAvailable: IUser[] = [];
  emailString: string;
  editedUser: ISuperUser;
  addUser: ISuperUser = {};
  userToDelete: ISuperUser;
  brokerPricingToggleEnabled: boolean = false;

  exportSubscription: Subscription;

  ngOnInit() {
    this.dataService.setTitleSource('Super Users');
    this.dataService.setSelectedNavItem('');
    this.setCurrentActivePage('accounts')

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.default) {
        this.selectDenom(denom);
      }
    });

    this.dataService.userSourceUpdated.subscribe(
      () => {
        this.superUserAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
        this.brokerPricingToggleEnabled = this.dataService.getUserSource().brokerPricingToggleEnabled;
        // console.log("superUserAdmin: ", this.superUserAdmin);
      }
    );
    this.brokerPricingToggleEnabled = this.dataService.getUserSource().brokerPricingToggleEnabled;
    this.superUserAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);

    this.manageSuperUserService.getAllSuperUsers().subscribe(
      (superUsers : any) => {
        this.superUsers = superUsers;
        this.formatSuperUsers();
        this.filterSuperUsers();
      }
    );

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (source) => {
        this.manageSuperUserService.export();
      }
    );
  }

  ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  toggleSearchRow(){
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = "";
    this.filterSuperUsers();
  }

  formatSuperUsers(){
    this.superUsers.forEach(
      (superUser) => {
        superUser.organizationDisplay = (superUser.organization && superUser.organization != '' ? superUser.organization : '------');
        superUser.jobTitleDisplay = (superUser.jobTitle && superUser.jobTitle != '' ? superUser.jobTitle : '------');
        superUser.mobilePhoneDisplay = (superUser.mobilePhone && superUser.mobilePhone != '' ? this.formatPhone(superUser.mobilePhone) : '------');
        superUser.phoneDisplay = (superUser.phone && superUser.phone != '' ? this.formatPhone(superUser.phone) : '------');
        superUser.lastLoginDisplay = (superUser.lastLogin ? moment(superUser.lastLogin).format('MM/DD/YY') : '------');
        //superUser.showResend = superUser.activateResendDate ? moment().format('YYYY-MM-DD') != (superUser.activateResendDate) : false; old date format logic

        if(this.generateFormattedDateForToday() == superUser.activateResendDate) {
          superUser.showResend = false;
        }
        else{
          superUser.showResend = true;
        }

        //HACK FOR LOCAL RESEND FIX, remove before pushing
        //superUser.showResend = true;
        // if(superUser.extension && superUser.extension != ''){
        //   superUser.phoneDisplay += ' x' + superUser.extension;
        // }
      }
    );
  }

  formatPhone(phone: string){
    let formattedPhone = phone;
    while(formattedPhone.replace(new RegExp('[^0-9]'), '') != formattedPhone){
      formattedPhone = formattedPhone.replace(new RegExp('[^0-9]'), '');
    }

    formattedPhone = '(' + formattedPhone.substring(0,3) + ') ' + formattedPhone.substring(3,6) + '-' + formattedPhone.substring(6);

    return formattedPhone;
  }

  filterSuperUsers(){
    this.filteredSuperUsers = this.pipe.transform(this.superUsers, this.searchTerm);
    this.sortSuperUsers(this.sortCol);
    this.paginate(1);
  }

  sortSuperUsers(col: string){
    this.filteredSuperUsers = this.orderByPipe.transform(this.filteredSuperUsers, col, this.ascending);
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
    if(this.filteredSuperUsers){
      this.pager = this.pagerService.getPage(this.filteredSuperUsers, page, this.selectedDenom.count)
    }
  }

  editSuperUser(superUser: ISuperUser){
    this.editedUser =  superUser;

  }

  saveSuperUser(superUser: ISuperUser){
    if(this.validateUser(superUser)){

      let validResendDate = superUser.activateResendDate;

      //convert date so backend can process it correctly...
      let date = new Date(superUser.activateResendDate);

      let day = date.getDate();
      let month = date.getMonth() + 1; // method is 0 based
      let monthString = month.toString();
      if(month < 10){
        monthString = '0' + month.toString();
      }
      let year = date.getFullYear();

      let finalDate = year.toString() + '-' + monthString + '-' + day.toString();
      if(superUser.activateResendDate == '' || !superUser.activateResendDate){
        superUser.activateResendDate = finalDate; //update object with date, double check this in october
      }

      this.manageSuperUserService.editSuperUser(superUser).subscribe(
        (response : any) => {
          let tempList = [];
          this.superUsers.forEach(
            (user) => {
              if(user.id != response.id){
                tempList.push(user);
              }
            }
          );
          //dates aren't saving when switching for some reason


          tempList.push(response);
          for(let i = 0; i < tempList.length; i ++){
            if(tempList[i].id == superUser.id){
              tempList[i].activateResendDate = validResendDate;
            }

          }
          this.superUsers = tempList;
          this.editedUser = null;
          this.formatSuperUsers();
          this.filterSuperUsers();
        }
      );
    }
  }

  validateUser(user){
    let valid = true;
    let mobilePhone = user.mobilePhone;
    let work = user.phone;
    let extension = user.extension;
    user.invalidMobilePhone = false;
    user.invalidPhone = false;
    user.invalidExtension = false;
    if(mobilePhone && (mobilePhone.replace(new RegExp('[^0-9]'), '') != mobilePhone || mobilePhone.length != 10)){
      user.invalidMobilePhone = true;
      valid = false;
    }
    if(work && (work.replace(new RegExp('[^0-9]'), '') != work || work.length != 10)){
      user.invalidPhone = true;
      valid = false;
    }
    if(extension && (extension.replace(new RegExp('[^0-9]'), '') != extension || extension.length != 3)){
      user.invalidExtension = true;
      valid = false;
    }
    return valid;
  }

  deleteSuperUser(superUser: ISuperUser){
    $('#deleteSuperUserModal').modal('show');
    this.userToDelete = superUser;
  }

  confirmDeleteSuperUser(){
    this.manageSuperUserService.deleteSuperUser(this.userToDelete.id).subscribe(
      () => {
      },
      (err) => {
        if(err.status == 200) {
          let tempList = [];
          this.superUsers.forEach(
            (user) => {
              if(user.id != this.userToDelete.id){
                tempList.push(user);
              }
            }
          );
          this.superUsers = tempList;
          this.formatSuperUsers();
          this.filterSuperUsers();
        }
      }
    );
  }

  undoEdit(superUser: ISuperUser) {
    this.editedUser = null;
  }

  addNewSuperUser() {
    this.addUser = {};
    $('#addSuperUserModal').modal('show');
  }

  confirmAddNewSuperUser() {
    this.addUser.email = this.addUser.email.toLowerCase();
    this.manageSuperUserService.editSuperUser(this.addUser).subscribe(
      (user : any) => {
        this.addUser = {};
        this.superUsers.push(user);
        this.formatSuperUsers();
        this.filterSuperUsers();
      }
    );
  }

  refreshUserAccountCache() {
    this.addUser = {};
    $('#refreshUserAccountCacheModal').modal('show');
  }

  confirmRefreshUserAccountCache() {
    this.manageSuperUserService.refreshUserAccountCache().subscribe();
  }



  checkForExistingUser(){
    this.addUser.email = this.addUser.email.toLowerCase();
    if(this.addUser.email
      && this.addUser.email.includes('@nrg.com')
      || this.addUser.email.includes('@reliant.com')
      || this.addUser.email.includes('@nrgenergy.com')){
      let foundSuperUser = this.superUserAlreadyExists(this.addUser);
        if(foundSuperUser){
          this.addUser.invalidEmail = true;
          this.addUser.validEmail = false;
        }
        else {
          this.addUser.validEmail = true;
          this.addUser.invalidEmail = false;
            this.manageSuperUserService.checkForExistingUser(this.addUser.email).subscribe(
              (user : any) => {
                console.log('checkForExistingUser: ', user);
                this.addUser.id = user.id;
                this.addUser.email = user.email;
                this.addUser.organization = user.organization;
                this.addUser.firstName = user.firstName;
                this.addUser.lastName = user.lastName;
                this.addUser.jobTitle = user.jobTitle;
                this.addUser.mobilePhone = user.mobilePhone;
                this.addUser.phone = user.phone;
                this.addUser.extension = user.extension;
                this.addUser.pricingAccess = user.pricingAccess;
                this.validateAddUser();
              },
              () => {
                console.log('User Doesnt exist');
                this.addUser.id = null;
                this.addUser.organization = null;
                this.addUser.firstName = null;
                this.addUser.lastName = null;
                this.addUser.jobTitle = null;
                this.addUser.mobilePhone = null;
                this.addUser.phone = null;
                this.addUser.extension = null;
                this.addUser.pricingAccess = false;
              }
            );
        }
    }
    else {
      this.addUser.validEmail = false;
    }
    this.validateAddUser();
  }

  superUserAlreadyExists(user: ISuperUser){
    let found = false;
    this.superUsers.forEach((existingUser) => {
      if(existingUser.email == user.email){
        found = true;
      }
    });
    return found;
  }

  validateAddUser(){
    if(!this.addUser.email || this.addUser.email == "" || !this.addUser.validEmail){
      this.addUser.valid = false;
    }
    else if(!this.addUser.firstName || this.addUser.firstName == ""){
      this.addUser.valid = false;
    }
    else if(!this.addUser.organization || this.addUser.organization == ""){
      this.addUser.valid = false;
    }
    else if(!this.addUser.lastName || this.addUser.lastName == ""){
      this.addUser.valid = false;
    }
    else if(!this.validateUser(this.addUser)){
      this.addUser.valid = false;
    }
    else {
      this.addUser.valid = true;
    }
  }

  resend(superUser: ISuperUser){
    if(!superUser.resendTriggered){
      this.dataService.setLoading(true);
      superUser.resendTriggered = true;
      this.manageSuperUserService.resendActivationEmail(superUser).subscribe(
        (response) => {
          this.dataService.setLoading(false);
          superUser.resendTriggered = false;
        },
        (resp) => {
          if(resp.status == 200){
            this.formatDateForResend(superUser);
            this.formatSuperUsers();
            this.filterSuperUsers();
          }
          this.dataService.setLoading(false);
          superUser.resendTriggered = false;
        }
      )
    }
  }

  formatDateForResend(user : ISuperUser){
    //convert date so backend can process it correctly...
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1; // method is 0 based
    let monthString = month.toString();
    if(month < 10){
      monthString = '0' + month.toString();
    }
    let year = date.getFullYear();

    let finalDate = year.toString() + '-' + monthString + '-' + day.toString();
    user.activateResendDate = finalDate; //update object with date, double check this in october
  }
  generateFormattedDateForToday() : string {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1; // method is 0 based
    let monthString = month.toString();
    if(month < 10){
      monthString = '0' + month.toString();
    }
    let year = date.getFullYear();

    let finalDate = year.toString() + '-' + monthString + '-' + day.toString();
    return finalDate;
  }

}
