import { Component, OnInit } from '@angular/core';
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {DataService} from "../../shared/data.service";
import {ISite,ISiteCountRequest} from "../../shared/entities/site";
import {PortalService} from "../../shared/portal.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import { IAccount } from '../../shared/entities/account';
import {IUserAccount, IProfile} from '../../shared/entities/profile';
import {IShadowUsageRequest} from "../../shared/entities/usageGraph";
import {RealtimeUsageService} from "../../shadow-metering/realtime-usage/realtime-usage.service";

declare var $:any;
@Component({
  selector: 'app-realtime-export',
  templateUrl: './realtime-export.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['./realtime-export.component.scss']
})
export class RealtimeExportComponent implements OnInit {
  request: IShadowUsageRequest = {};
  showInvalidError: boolean = false;
  sitesForAccount: ISite[];

  // Current Step - used to highlight
  activeStep = 1;

  // Sites
  sitesRadio: string;
  selectedSite: ISite;
  siteOptions: string[] = [];
  filteredSites: any[] = [];
  siteSearch: string;
  siteIndex: number = -1;
  siteCountComplete: boolean;
  aggregateESI: any = 'false';
  allSites: ISite[];
  sites: ISite[];
  myAccount: IUserAccount;


  // Dates
  showDateRange = false;
  showDateRangeError = false;
  dateRangeDisplay: string;


  dateRanges: any[];
  startDates: any[];
  selectedStartDate: any;
  endDates: any[];
  selectedEndDate: any;
  accountSourceSubscription: Subscription;
  sitesSubscription: Subscription;
  profile: IProfile;
  profileSubscription: Subscription;
  dateTypeRadio: string;
  validDateRange: boolean;


  constructor(private dataService: DataService,
              private portalService: PortalService,
              private service: RealtimeUsageService,) { }

  ngOnInit() {

    let dateRanges = this.dataService.getReportSubscriptionDateRangesSource();
    if(dateRanges) {
      this.dateRanges = dateRanges;
      this.startDates = this.endDates = this.dateRanges;
    }



    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount) {

     this.getShadowSites();
    }

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {

        this.myAccount = account;
        //  this.getShadowSites();
      }
    );


    let account = this.dataService.getAccountSource();
    this.profileSubscription = this.dataService.profileUpdated.subscribe(
      (profile) => {
        this.profile = profile;
      }
    );



    // this.sitesForAccount = this.dataService.getSitesForAccountSource();
    if(this.sitesForAccount) {
      this.sitesForAccount.forEach((site) => {
        this.siteOptions.push(site.siteId + ' ' + site.name);
      });
    }

    // this.sitesSubscription = this.dataService.sitesForAccountSourceUpdated.subscribe((sites) => {
    //   this.sitesForAccount = sites;
    //   this.sitesForAccount.forEach((site) => {
    //     this.siteOptions.push(site.siteId + ' ' + site.name);
    //   });
    // });

    this.dataService.realTimeExportTriggered.subscribe(() => {

      this.myAccount = this.dataService.getAccountSource();
        if(this.myAccount) {
        this.getShadowSites();
        }

      this.activeStep = 1;
      this.sitesRadio = 'allSites';

      this.selectedSite = null;
      this.siteSearch = '';
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      this.dateTypeRadio = '15MIN';
      this.aggregateESI = 'false';
      this.rloadDefaults();
    })

    this.rloadDefaults();
  }

  ngOnDestroy() {
    if (this.sitesSubscription) this.sitesSubscription.unsubscribe();
    if (this.profileSubscription) this.profileSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
  }


  download() {
    this.dataService.setLoading(true);

    let siteRequest, siteCount = 0,intervalType = 15;
    if(this.sitesRadio == 'allSites'){
      siteRequest = 'ALL_SITES';
      siteCount = this.sitesForAccount.length;
    }
     else if (this.sitesRadio == 'singleSite' && this.selectedSite){
      siteRequest = 'SPECIFIC_SITE';
      siteCount = 1;
    }

    if (this.dateTypeRadio == '15MIN') {
      intervalType =  15;
    }
    else if (this.dateTypeRadio == 'HOUR'){
      intervalType = 60;
    }

    this.request = {
      accountId: this.myAccount.id,
      accountName: this.myAccount.name,
      siteRequest: siteRequest,
      siteId: (this.selectedSite?this.selectedSite.siteId:''),
      intervalType: intervalType,
      startDate:moment(this.selectedStartDate).format('YYYY-MM-DD'),
      endDate: moment(this.selectedEndDate).format('YYYY-MM-DD'),
      maskingMode: this.dataService.getMaskMode(),
      aggregateBySite: this.aggregateESI
    };

    this.service.exportRealtimeGraph(this.request);

    // this.dataService.setLoading(false);

  }

  getShadowSites() {
    this.service.getShadowSites(this.myAccount.id).subscribe(
      (response) => {
        this.sitesForAccount = response;
        // if(this.shadowSites) {
        //   this.shadowSitesValue = this.shadowSites.length;
        //    this.selectedSite = this.shadowSites[0];
        // }

      }
    );
  }



  selectStartDate(startDate: any){
    this.validateDateRange();
  }

  selectEndDate(endDate: any){
    // this.selectedEndDate = endDate;
    this.validateDateRange();
  }

  validateDateRange() {
    this.validDateRange = true;
    if(this.selectedStartDate && this.selectedEndDate) {
      if(moment(this.selectedStartDate.startDate).isAfter(moment(this.selectedEndDate.endDate))) {
        this.validDateRange = false;
      }
    }
  }

  sitesRadioChanged() {
    // if(this.sitesRadio == 'allSites' && this.activeStep != 3){
    //   this.activeStep = 2;
    // }
  }

  dateTypeRadioChanged() {
    this.activeStep = 3;
  }


  searchSites() {
    this.filteredSites = [];
    this.sitesForAccount.forEach((site) => {
      if(site.siteId.includes(this.siteSearch) || site.serviceAddress.streetAddress.toLowerCase().includes(this.siteSearch.toLowerCase())){
        this.filteredSites.push(site);
      }
    });
  }

  navigateTypeahead(down: boolean) {
    if(down && this.siteIndex < this.filteredSites.length-1){
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  selectSite(site: ISite, index?: number){
    if(this.filteredSites.length > 0){
      if(this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if(index) {
        this.selectedSite = this.filteredSites[index];
      } else {
        this.selectedSite = this.filteredSites[this.siteIndex];
      }
      this.siteSearch = this.selectedSite.siteId + ' ' + this.selectedSite.name;
      this.resetTypeahead();
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
    this.showDateRangeError = false;
    this.selectedStartDate = moment(this.request.startDate).toDate();
    this.selectedEndDate = moment(this.request.endDate).toDate();
 }


 rloadDefaults() {
  //this.request.accountId = this.myAccount.id;
  //this.request.accountName = this.myAccount.name;
  this.request = {
    //accountId: this.myAccount.id,
   // accountName: this.myAccount.name,
    siteRequest: "ALL_SITES",
    siteId: (this.selectedSite?this.selectedSite.siteId:''),
    intervalType: 15,
    startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
  };

  //this.sitesControl.siteRequest = "ALL_SITES";
  //this.minDate = moment().subtract(3, 'years').toDate();
  this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + '-' + moment(this.request.endDate).format('MM/DD/YY');

  this.selectedStartDate = moment(this.request.startDate).toDate();
  this.selectedEndDate = moment(this.request.endDate).toDate();
 }

 applyDateRange() {
  if(!(Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days')) > 365)){
    this.request.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
    this.request.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
    this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + ' - ' + moment(this.request.endDate).format('MM/DD/YY');

    this.toggleDateRange();

    this.showDateRangeError = false;
  } else {
    this.showDateRangeError = true;
  }
}

}
