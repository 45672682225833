// Code add by Ramalingam
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {IProfile, IUserAccount, IUserDetails, IUserPermission, ILdapPasswordUpdateRequest} from '../shared/entities/profile';
import {DataService} from '../shared/data.service';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Http, Headers, Response} from "@angular/http";
import {I4cpContact} from "../shared/entities/fourcp";

@Injectable()
export class ProfileService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService) {
     this.headers = new HttpHeaders({'Content-Type': 'application/json'});
   }

  // Get contact information by account ID
  getManageProfile(): Observable<IProfile> {
      //let url = './assets/api/profile.json';
    let url = environment.getManageProfileUrl;
    return this.http
      .get(url).pipe(
        map((data: IProfile)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }
  private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }


  // Get contact information by account ID
  getProfileData(email: string) {
    // console.log('in service call',email);
    let url = environment.production ? environment.retrieveProfileURL.replace('{email}', email.toString()) : environment.retrieveProfileURL;
    // console.log('url',url)
    return this.http
      .get(url)
      .map((response: HttpResponse<IProfile>) => response)
      .catch(this.handleError);
  }

  // Get user Accounts based on search criteria
  getUserAccounts(searchCriteria: string) {
      //let url = environment.production ? environment.getUserAccountsUrl.replace('{searchCriteria}', searchCriteria) : environment.getUserAccountsUrl;
      let encodedSearchCriteria = encodeURIComponent(searchCriteria);
      let url = environment.getUserAccountsUrl.replace('{searchCriteria}', encodedSearchCriteria);
      return this.http
        .get(url)
        .map((response: HttpResponse<IUserAccount>) => response)
        .catch(this.handleError);
    }

  // Get contact information by account ID
  getUserPermissions(accountId: string): Observable<any> {
    let url = environment.production ? environment.getUserPermissionsUrl.replace('{accountId}', accountId) : environment.getUserPermissionsUrl;
    return this.http
      .get(url)
      .map((response: HttpResponse<IUserPermission>) => response)
      .catch(this.handleError);
  }

  createUserPermissions(user: IUserPermission): Observable<any> {
    let url = environment.createUserPermissionsUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, user, {headers: headers})
      .map((response: Response) => <any>response.text())
      .catch(this.handleError);
  }

  updateUserPermissions(user: IUserPermission): Observable<any> {
    let url = environment.production ? environment.updateUserPermissionsUrl.replace('{accountId}', user.userAccount.id.toString()) : environment.updateUserPermissionsUrl;
    return this.http
      .post(url, user, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  deleteUserPermission(accountId: string, userId: string): Observable<any> {
    let url = environment.production ? environment.deleteUserPermissionsUrl.replace('{accountId}', accountId.toString()).replace('{userId}', userId.toString()) : environment.deleteUserPermissionsUrl;
    return this.http
      .post(url,"")
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  resetProfileImage(): Observable<any> {
    let url = environment.resetImageUrl;
    return this.http
      .post(url, {}, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }


  checkForExistingLDAPUser(email: String)  {
    let url = environment.checkForExistingLDAPUserUrl.replace('{email}', email.toString());
    return this.http
    .get(url)
    .map((response: HttpResponse<Boolean>) => response)
    .catch(this.handleError);
  }

  updateProile(profile: IProfile): Observable<IProfile> {
    let url = environment.updateProfileUrl;
    return this.http
        .post(url,profile, {headers : this.headers}).pipe(
          map((data: IProfile)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  getUserImage(): any {
    let url = environment.getUserImageUrl;
    return this.oldHttp.get(url)
    .map(image => image.text())
    .subscribe(
      data => {
        this.dataService.setProfileImage(data);
      }, err => {
        this.dataService.setProfileImage(null);
      }
    );
  }

  getProfileAlerts() {
    let url = environment.getProfileAlertsUrl;
    return this.http
      .get(url)
      .map((response: HttpResponse<I4cpContact[]>) => response)
      .catch(this.handleError);
  }

  updatePassword(request: ILdapPasswordUpdateRequest, userId:string): Observable<any> {
    let url = environment.production ? environment.getUserPermissionsUrl.replace('{accountId}', userId) : environment.getUserPermissionsUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, request, {headers: headers})
      .map((response: any) => response)
      .catch(this.handleError);
  }

  setInitialLogin(userId){
    let url = environment.setFirstLoginUrl;
    let profile = {userId: userId};

    return this.http
      .post(url, profile)
      .map((response: IProfile) => response)
      .catch(this.handleError);
  }
}
