import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const SWTICH_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OnOffSwitchComponent),
  multi: true
};

@Component({
  selector: 'app-on-off-switch',
  providers: [SWTICH_VALUE_ACCESSOR],
  templateUrl: './on-off-switch.component.html',
  styleUrls: ['./on-off-switch.component.scss']
})
export class OnOffSwitchComponent implements ControlValueAccessor {
  @Input() green = false;
  id = 'on-off-switch-' + Math.random();
  switchValue = true;
  private onChange = (_: any) => { };
  private onTouched = () => { };

  toggle() {
    this.onChange(this.switchValue);
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.switchValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
}
