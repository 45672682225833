import {Injectable, EventEmitter} from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {ITealiumRequest} from "../../shared/entities/tealium";

@Injectable()
export class AccountRenewalService {

  headers: HttpHeaders;
  
  constructor(private httpClient: HttpClient, private oldHttp: Http) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  confirmTealium(request: ITealiumRequest) {
    let url = environment.production ? environment.tealiumConfirm.replace('{accountId}', request.user_unique_id) : environment.tealiumConfirm.replace('{accountId}', request.user_unique_id);

    return this.httpClient
      .post(url, request, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }
  
  dismissTealium(request: ITealiumRequest) {
    let url = environment.production ? environment.tealiumDismiss.replace('{accountId}', request.user_unique_id) : environment.tealiumDismiss.replace('{accountId}', request.user_unique_id);

    return this.httpClient
      .post(url, request, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }
  
  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
