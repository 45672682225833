import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { IEquivalency } from '../../equvalency';
import { IRenewableSiteGeneration } from '../../renewable-site-generation';
import { apiDateFormat } from '../../renewables-utility';
import { RenewablesService } from '../../renewables.service';
import * as moment from 'moment';

@Component({
  selector: 'app-real-time-summary',
  templateUrl: './real-time-summary.component.html',
  styleUrls: ['./real-time-summary.component.scss']
})
export class RealTimeSummaryComponent implements OnInit, OnDestroy {
  lastUpdated: Date;
  startDate: Date;
  endDate: Date;
  generationTotal: number;
  co2Equivalency: IEquivalency;
  equivalencies: IEquivalency[];
  private readonly intervalsPerHour = 12;
  private readonly unsubscribe$ = new Subject();

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(private renewablesService: RenewablesService) { }


  ngAfterViewInit(){
    this.spinner.show('Loading Equivalencies...');
  }
  ngOnInit() {
    this.renewablesService.equivalencies
      .combineLatest(this.renewablesService.siteGeneration)
      .takeUntil(this.unsubscribe$)
      .subscribe(result => {
        this.updateData(result[0], result[1]);
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateData(equivalencies: IEquivalency[], siteGeneration: IRenewableSiteGeneration) {
    this.lastUpdated = new Date();

    const dates = new Array<Date>();
    this.generationTotal = 0;
    siteGeneration.points.forEach(point => {
      dates.push(moment(point.date, apiDateFormat).toDate());
      this.generationTotal += point.shadow / this.intervalsPerHour;
    });
    const sortedDates = dates.sort((a, b) => b < a ? 1 : -1);
    this.startDate = sortedDates[0];
    this.endDate = sortedDates[sortedDates.length - 1];

    equivalencies.forEach(equivalency => equivalency.amount = this.generationTotal / equivalency.divisor);
    this.co2Equivalency = equivalencies.find(equivalency => equivalency.type === 'tons');
    this.equivalencies = equivalencies.filter(equivalency => equivalency.type !== 'tons' && equivalency.amount >= 1);
  }
}
