import { Component, OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';
import { IProfile, IUserAccount, IUserPermission } from '../../../shared/entities/profile';
import { SubscriptionsService } from '../../../subscriptions/subscriptions.service';
import { MatrixpricingService } from '../../../matrixpricing/matrixpricing.service';
import {UserAccountPipe} from '../../../shared/pipe/useraccounts.pipe';
import { OrderByPipe } from '../../../shared/pipe/orderBy.pipe';
import { GenericPipe } from '../../../shared/pipe/generic.pipe';
import { UserPermissionsPipe } from '../../../shared/pipe/userpermissions.pipe';
import { ProfileService } from '../../profile.service';
import { PortalService } from '../../../shared/portal.service';
import { Authority } from '../../../shared/const/authority';
import { Permissions } from '../../../shared/const/permissions';


@Component({
  selector: 'app-profile-matrix-broker-pricing',
  templateUrl: './profile-matrix-broker-pricing.component.html',
  styleUrls: ['./profile-matrix-broker-pricing.component.scss'],
  providers: [OrderByPipe, GenericPipe, UserPermissionsPipe],
})
export class ProfileMatrixBrokerPricingComponent implements OnInit {

  myProfile : IProfile;
  brokerPricingSubscription: boolean = false;
  changesMade : boolean = false;
  displayEmailInfo : boolean;
  matrixBrokerPricingSubSelection : boolean;
  madeSubscription: boolean = false;
  madeUnsubscription: boolean = false;
  myUserAccount: IUserAccount;
  filteredUserAccounts: IUserPermission[] = [];
  userAccounts: IUserPermission[] = [];
  searchTerm : string;
  showSearchRow : boolean = false;
  editingUser: boolean = false;
  mbpAccessDictionary: { [id: string]: any } = {};
  currentEditingUser: IUserAccount;
  showTable: boolean = false;
  showRadioSelection : boolean = false;
  tableChangeMade: boolean = false;
  onPricing: boolean = false;
  isSuperUser: boolean = false;
  isAdmin : boolean = false;
  isBroker : boolean = false;



  constructor(
    private dataService: DataService,
    private manageProfileService: ProfileService,
    private matrixPricingService: MatrixpricingService,
    private userAccountsPipe: UserPermissionsPipe,
    private orderByPipe: OrderByPipe,
    private portalService : PortalService
  ) { }


  ngOnInit() {
    this.dataService.setLoading(true);
    this.myProfile = this.dataService.getProfile();
    this.myUserAccount = this.dataService.getAccountSource();
    this.changesMade = false;
    this.displayEmailInfo = false;

    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isAdmin = this.portalService.hasPermission(Permissions.ManageUsers);
    this.isBroker = this.myUserAccount.accessType == "ThirdParty";

    if(window.location.href.indexOf('pricing') != -1){
      this.onPricing = true;
    }
    if(this.portalService.userHasAuthority(Authority.SuperUser)){
      this.showRadioSelection = true;
    }
    this.matrixPricingService.getPortalSubscriptionById(this.myProfile.userId).subscribe(
      (response : any) => {
        if(!response){
          this.brokerPricingSubscription = false;
          this.loadUsers();
        }
        else{
          console.log(response);
          let subCat = response.subscriptionCategory;
          if(subCat && subCat === 'Y'){
            this.brokerPricingSubscription = true;
          }
          else{
            this.brokerPricingSubscription = false;
          }
          this.loadUsers();
        }
      }, (err => {
        if (err.status === 404) {
          this.brokerPricingSubscription = false;
          if (this.isSuperUser || (this.isAdmin && this.isBroker)) {
            this.loadUsers();
          }
        }
      })
    )
    this.dataService.setLoading(false);
  }

  loadUsers(){

   this.manageProfileService.getUserPermissions(this.myUserAccount.id).subscribe(
    (userPermissions : any) => {
      this.userAccounts = userPermissions;
      this.filterUsers();
      this.userAccounts.forEach(user=>{
        if(user.userAccount.accessType == 'ThirdParty'){
          this.showTable = true;
        }
        this.getSubscriptionStatus(user.userAccount);
      })
      this.dataService.setLoading(false);
  })
}


  filterUsers() {
    if (!this.searchTerm) {
      this.filteredUserAccounts = this.userAccounts;
    } else {
     this.filteredUserAccounts = this.userAccountsPipe.transform(this.userAccounts, this.searchTerm);
    }
    this.filteredUserAccounts = this.orderByPipe.transform(this.filteredUserAccounts, 'userProfile.firstName', true);
  }

  saveSelection(){
    console.log("sub sel",this.matrixBrokerPricingSubSelection);
    //call service to update selection here
    let temp = this.matrixBrokerPricingSubSelection;
    let selectionUpdate : string;
    if(temp){
      selectionUpdate = 'Y';
      this.brokerPricingSubscription = true;
      this.madeSubscription = true;
      this.displayEmailInfo = true;
    }
    else{
      selectionUpdate = 'N';
      this.brokerPricingSubscription = false;
      this.madeUnsubscription = true;
      this.displayEmailInfo = false;
    }
    this.matrixPricingService.updatePortalSubscription(this.myProfile.userId,selectionUpdate,this.myUserAccount.id,this.myUserAccount.id).subscribe(
      (response) => {
        console.log("update post response", response);
      }
    );
    console.log("sent to dal");
    this.changesMade = false;
  }

  updateChanges(){
    this.changesMade = true;
  }

  getSubscriptionStatus(user: IUserAccount) : any {
    //get sub status for all accounts
    this.matrixPricingService.getPortalSubscriptionById(parseInt(user.userId)).subscribe(
      (response : any) => {
        console.log(response);
        if(response.subscriptionCategory == 'Y'){
          this.mbpAccessDictionary[user.userId] = "Subscribed";
        }
        else{
          this.mbpAccessDictionary[user.userId] = "Not Subscribed";
        }
      }, (err => {
        if (err.status === 404) {
          this.mbpAccessDictionary[user.userId] = "Not Subscribed";
        }
      })
    )
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterUsers();
  }
  editUser(user : IUserPermission){
    if(this.editingUser){
      this.currentEditingUser = null;
    }
    else{
      this.currentEditingUser = user.userAccount;
    }
    this.editingUser = !this.editingUser;
    //console.log(user);
  }


  setSubscription(user: IUserPermission, selection: boolean){

    this.tableChangeMade = true;
    if(selection){
      this.mbpAccessDictionary[user.userAccount.userId] = "Subscribed";
    }
    else{
      this.mbpAccessDictionary[user.userAccount.userId] = "Not Subscribed";
    }

  }

  saveUser(user: IUserPermission){
    let sel: string;
    if(this.mbpAccessDictionary[user.userAccount.userId] == "Subscribed"){
      sel = 'Y';
    }
    else{
      sel = 'N';
    }
    this.matrixPricingService.updatePortalSubscription((user.userProfile.id),sel,this.myUserAccount.id,this.myUserAccount.id).subscribe(
      (response) => {
        console.log("update post response", response);
      }
    );
    this.editingUser = false;
    this.currentEditingUser = null;
    this.tableChangeMade = false;
  }

  undo(user: IUserPermission){
    if(this.tableChangeMade){
      this.getSubscriptionStatus(user.userAccount);
    }
    this.editingUser = false;
    this.currentEditingUser = null;
    this.tableChangeMade = false;

  }

}
