import { Injectable } from '@angular/core';
import {Http, Response, Headers, ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import {HttpResponse, HttpHeaders, HttpClient} from "@angular/common/http";
import {ITPGroup} from "../shared/entities/thirdParty";
import { IBrokerCustomerListRequest } from '../shared/entities/broker';
import { DataService } from '../shared/data.service';

@Injectable()
export class TpCustomerListService {

  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private oldHttp: Http,
    private dataService: DataService
  ) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getTPCustomers(accountId: string) {
    let url = environment.getThirdPartyCustomersUrl.replace('{accountId}', accountId);

    return this.http
      .get(url)
      .map((response: HttpResponse<any[]>) => response)
      .catch(this.handleError);
  }

  getTPGroups(accountId: string) {
    let url = environment.getThirdPartyGroupsUrl.replace('{accountId}', accountId);
    return this.http
      .get(url)
      .map((response: HttpResponse<any[]>) => response)
      .catch(this.handleError);
  }

  getTPCustomersForGroup(accountId: string, groupId: string) {
    let url = environment.getThirdPartyCustomersForGroupUrl.replace('{accountId}', accountId).replace('{groupId}', groupId ? groupId : '0');

    return this.http
      .get(url)
      .map((response: HttpResponse<any[]>) => response)
      .catch(this.handleError);
  }

  getTPGroupCategories(accountId: string, groupId: string) {
    let url = environment.getThirdPartyAccountGroupCategoriesUrl.replace('{accountId}', accountId).replace('{groupId}', groupId);

    return this.http
      .get(url)
      .map((response: HttpResponse<string[]>) => response)
      .catch(this.handleError);
  }

  createOrUpdateTPGroup(group: ITPGroup) {
    let url = environment.updateThirdPartyAccountGroupUrl.replace('{accountId}', group.accountId).replace('{groupId}', group.id ? group.id.toString() : '0');

    return this.http
      .post(url, group, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  updateTPGroupCategory(accountId: string, groupId: string, body) {
    let url = environment.updateThirdPartyAccountGroupCategoryUrl.replace('{accountId}', accountId).replace('{groupId}', groupId).replace('{tpAccountId}', body.key.accountId);

    return this.http
      .post(url, body, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  deleteTPGroup(accountId: string, groupId: string) {
    let url = environment.deleteThirdPartyAccountGroupUrl.replace('{accountId}', accountId).replace('{groupId}', groupId);

    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  exportBrokerCustomerList(request: IBrokerCustomerListRequest): any {
    let url = environment.production ? environment.exportBrokerCustomerListUrl.replace('{accountId}', request.accountId.toString()) : environment.exportBrokerCustomerListUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}
