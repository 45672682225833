import { Component, Host, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AutoPaymentsService } from '../autopayments.service';
import { ISiteGroup, ISite } from '../../shared/entities/site';
import { IAutoPaySites } from '../autopayments';
import { PortalService } from '../../shared/portal.service';
import { IUserAccount } from '../../shared/entities/profile';
import { DataService } from '../../shared/data.service';
import { Subscription } from 'rxjs/Subscription';
import { IDownloadInvoiceRequest, ICategory, IInvoice } from '../../shared/entities/bill-reports';
import { BillingSummaryService } from '../../billing-summary/billing-summary.service';
import { AutopayPipe } from '../../shared/pipe/autopay.pipe';
import { environment } from '../../../environments/environment';
import { IHelpStep } from '../../shared/entities/contextualHelp';
import * as moment from 'moment';
import { GenericPipe } from '../../shared/pipe/generic.pipe';
import { OrderByPipe } from '../../shared/pipe/orderBy.pipe';
import { IAccount } from '../../shared/entities/account';
import { AutoPaymentsComponent } from '../autopayments.component';
declare var $: any;

@Component({
  selector: 'app-autopay-select-balances',
  templateUrl: './autopay-select-balances.component.html',
  styleUrls: ['autopay-select-balances.component.scss'],
  providers: [AutopayPipe, GenericPipe, OrderByPipe]
})
export class AutopaySelectBalancesComponent implements OnInit, OnDestroy {
  selectedlineitem: string;
  selectedGroup: ISiteGroup;
  autopaySelGroup: String;
  myAccount: IUserAccount;
  accountWithContractData: IAccount;
  showSearchBar = false;
  anyChecked = false;
  searchTerm: string;
  autopayList: any;
  numOfRowsToShow = 10000;
  filteredAutopayLists: IAutoPaySites[];
  userSiteGroups: ISiteGroup[];
  systemSiteGroups: ISiteGroup[];
  autopayGroupings: string[];
  accountChanged = false;
  showNoBalancesMessage = false;
  showCCFilter = false;
  maskMode: boolean;
  helpStep: IHelpStep;
  summaryView = true;
  selectCCAccountschecked = false;
  showCreditCard: boolean = false;
  nccaStatus : boolean = true;
  ncaStatus : boolean = true;
  showFilterCreditCard : boolean = false;

  paymentOptionAction = () => { };
  filterOnlyCreditcards = () => { };

  // Subscriptions
  accountSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  siteGroupsSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription; 
  
  constructor(
    private autopaymentsService: AutoPaymentsService,
    private portalService: PortalService,
    private dataService: DataService,
    private billingService: BillingSummaryService,
    private autopayPipe: AutopayPipe,
    private orderByPipe: OrderByPipe,
    private genericPipe: GenericPipe,
    @Host() private parent: AutoPaymentsComponent) { }

  ngOnInit() {
    this.showCreditCard = this.dataService.getShowCreditCard();
    this.nccaStatus = this.dataService.getNCCAFlag();
    this.ncaStatus = this.dataService.getNCAFlag();
    this.autopaymentsService.setAutoPayments(null);
    this.autopaymentsService.setStepReached("select");
    this.autopaymentsService.setSubmitted(false);
    this.myAccount = this.dataService.getAccountSource();
    this.selectedlineitem = null;
    if (this.myAccount) {
      if (this.myAccount.largeCustomer) {
        this.numOfRowsToShow = 25;
        this.loadScroller();
      } else {
        this.numOfRowsToShow = 10000;
      }
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.dataService.setLoading(true);
        this.myAccount = account;
        this.accountChanged = true;
        if (this.myAccount.largeCustomer) {
          this.numOfRowsToShow = 25;
          this.loadScroller();
        } else {
          this.numOfRowsToShow = 10000;
        }
      }
    );
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.getAutoPayList();
    });
    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.accountWithContractData = account;
    });
    const siteGroups = this.dataService.getSiteGroupsSource();
    if (siteGroups) {
      this.loadGroups(siteGroups);
    }
    this.siteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.loadGroups(siteGroups);
      }
    );
    // if (this.autopaymentsService.selectedBalances && this.autopaymentsService.selectedBalances.length == 0) {
    //   this.clearAll();
    // }
    // if (!environment.production) {
    //   this.getAutoPayList();
    // }
    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
    this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    this.dataService.setLoading(false);
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.siteGroupsSubscription) this.siteGroupsSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  loadScroller() {
    const component = this;
    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        //if (categoryExpanded || !component.summaryView) {
        if (!component.summaryView) {
          component.numOfRowsToShow = component.numOfRowsToShow + 25;
        }
      }
    });
  }

  filterCC() {
    this.selectCCAccountschecked=!this.selectCCAccountschecked;
  }




  getAutoPayList() {    
    this.dataService.setLoading(true);
    this.autopaymentsService.getAutopayList(this.myAccount.id).subscribe(
        (autopaylist : any) => {
          this.autopaymentsService.setAllAutopay(autopaylist);
          let tempSiteArray : IAutoPaySites[] = [];

          this.autopayList = autopaylist;
          if(this.autopayList) {
              this.autopayList.forEach( autopayItem => {
                tempSiteArray.push(autopayItem);
              });

              let bankPaySelCount = tempSiteArray.filter(invoice => (invoice.nccaStatus == 'X' && invoice.ncaStatus ==null)).length;
              let ccPaySelCount = tempSiteArray.filter(invoice => (invoice.nccaStatus == null && invoice.ncaStatus =='X')).length;
              if(bankPaySelCount>0 && ccPaySelCount>0) {
                this.showFilterCreditCard = true;
              }
              this.showFilterCreditCard = true;
              this.filteredAutopayLists = tempSiteArray;
              this.selectAutopayGroup(this.autopayGroupings[0]);    
              this.dataService.setLoading(false);
              this.filterBalances();
            }
        }, () => {
              this.autopayList = null;
              this.filteredAutopayLists = this.autopayList;
              this.dataService.setLoading(false);
             }
     );
  }

  doSomething() {

  }
  
  hasChildNcaa(site: ISite) {
   return (site.invoices.filter(invoice => invoice.nccaStatus == 'X').length > 0);
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeCompanyName(site: any) {
    return this.portalService.getFakeCompanyName(site);
  }

  getFakeStreetAddress(site: ISite): string {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: ISite): string {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: ISite): string {
    return this.portalService.getFakeZipCode(site);
  }


  loadGroups(siteGroups: any) {

    this.autopayGroupings = ['All Accounts', 'AutoPay', 'Unenrolled'] ;

    const hasCollective = siteGroups.systemSiteGroups.filter(group => group.groupName == 'COLLECTIVE_BILLING').length > 0;
    const hasSummary = siteGroups.systemSiteGroups.filter(group => group.groupName == 'SUMMARY_BILLING').length > 0;
    const billingGroup = { groupName: 'BILLING_GROUP', display: 'Billing Groups', description: 'Collective or Summary Billing' };
    const allSites = { groupName: 'ALL_SITES', display: 'All Sites', description: 'All Sites' };
    const marketsGroup = { groupName: 'MARKETS', display: 'Markets', description: 'Markets' };

    this.systemSiteGroups = [allSites];

    this.selectGroup(this.systemSiteGroups[0]);
  }

  findSummary(group) {
    return group.groupName == 'SUMMARY_BILLING';
  }

  toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    this.filterBalances();
  }

  downloadInvoice(invoice: any, site?: any) {
    this.dataService.setLoading(true);
    if (!site) site = invoice;
    const request: IDownloadInvoiceRequest = {
      accountId: this.myAccount.id,
      collectiveAccountId: this.maskMode ? null : site.contractAccountNum.substr(0, 3) == '800' ? site.contractAccountNum : '',
      collectiveInvoiceNo: this.maskMode ? null : invoice.collectiveInvoiceNo,
      siteId: site.siteId,
      invoiceNo: this.maskMode ? '9999999999990' : invoice.invoiceNo,
      billingPeriod: invoice.dueDate.substr(0, 7).replace('-', ''),
      repOwnerId: invoice.repOwnerId
    };
    if (this.dataService.getIsMobile()) {
      this.billingService.openMobileInvoice(request);
    } else {
      this.billingService.downloadInvoice(request);
    }
  }

  numbersWithCommas(x) { return this.portalService.numberWithCommas(x); }

  filterBalances() {
    if(this.autopayList){
      if (this.searchTerm) {
        this.filteredAutopayLists = this.autopayPipe.transform(this.autopayList, this.searchTerm);
      } else {
        this.filteredAutopayLists = this.autopayList;
      }
    }
  }

  isDisabled(site: IAutoPaySites) {
    let disable = false;
    if(site.siteId==='Multiple' || site.caNumber.startsWith('800') || (site.ncaStatus == 'X' && site.nccaStatus == 'X')) {
      disable = true;
    } else {
      disable = false;
    }
    return disable;
  }

  getDateDisplay(dateString: string) {
    if (dateString && !dateString.toLowerCase().match('multiple') && !dateString.toLowerCase().match('past due') && !dateString.toLowerCase().match('yes') && !dateString.toLowerCase().match('no')) {
      return moment(dateString).format('MM/DD/YY');
    } else {
      return dateString;
    }
  }

  expand(row) {
    row.expanded = !row.expanded;
  }

  selectAutopayGroup(strAuto: string) {
    this.autopaySelGroup = strAuto;
    this.filterAutopayList();
    //code to filter based on selected group
  }

  filterAutopayList() {
    this.filteredAutopayLists=[];
    this.autopayList.forEach(site=> {
        if(this.autopaySelGroup==='AutoPay' && site.autoPaymentFlag=='Y') {
          this.filteredAutopayLists.push(site);
        }
        else if(this.autopaySelGroup==='Unenrolled' && site.autoPaymentFlag=='N') {
          this.filteredAutopayLists.push(site);
        }
        else if(this.autopaySelGroup==='All Accounts') {
          this.filteredAutopayLists = this.autopayList;
        }
    });

  }

  selectGroup(group: ISiteGroup, reload?: boolean) {
    this.selectedGroup = group;
    if (group.id) {
      this.selectedGroup.display = group.groupName;
    } else {
      this.selectedGroup.display = group.display;
    }
    this.getAutoPayList();
    this.filterBalances();

  }


  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(accountNum) {
      if(paymentType == 'CARD'){
        if(accountNum.length >= 4){
          return '*'+accountNum.substring(accountNum.length-4);
        }
      }
      else{
        if(accountNum.length >= 4){
          return '*'+accountNum.substring(accountNum.length-3);
        }        
      }
      return 'XXXXXXXXXX';
    }
  }

  siteChecked(autopaysite: IAutoPaySites) {
        // this.isEdit = !this.isEdit;
        this.selectedlineitem = autopaysite.caNumber;
        this.autopaymentsService.setAutoPayments(null);
        this.filteredAutopayLists.forEach((siteSel) => {
          if (siteSel.siteId == autopaysite.siteId) 
          {
            if(autopaysite.caNumber===siteSel.caNumber) {
              this.autopaymentsService.setAutoPayments(siteSel);
            }     
          } 
        });        

        this.autopaymentsService.setCaSelected(true);
  }


enableCategoryCheckbox(category : ICategory) : boolean {

  for(let i = 0; i < category.sites.length; i ++)
  {
    let currSite = category.sites[i];
    if(currSite.autoPaymentFlag != 'X' && (currSite.paymentDateStr == '' || !currSite.paymentScheduled)){
      return true;
    }
  }
  return false;
}

enableTopLevelCheckbox(invoices : IInvoice[]) : boolean {
  for(let i = 0; i < invoices.length; i ++){
    let currInvoice = invoices[i];
    if(currInvoice.autoPaymentFlag != 'X' && (currInvoice.paymentDateStr == '' || !currInvoice.scheduledPayment)){
      return true;
    }
  }
  return false;
}

enableToolTipCheckBox(invoice : IInvoice) : boolean {
  if(!invoice.paymentDate && invoice.disabled && invoice.autoPaymentFlag != 'X'){
    return true;
  }
  return false;
}

isExpired(method: IAutoPaySites) {
  const dateSel = new Date(method.expMonth + '/' + '01' + '/' +  method.expYear);
  const dateCurrent = new Date();
  if(dateSel > dateCurrent) {
    return false;
  }
  return true;
}

isExpiring(method: IAutoPaySites) {
  const dateSel = new Date(method.expMonth + '/' + '01' + '/' +  method.expYear);
  const current = new Date();
  const today = new Date();
  current.setMonth(current.getMonth() + 4);
  const dateCurrent = new Date( current.getMonth() + "/" + '01' + '/' + current.getFullYear());
  if(dateSel>today && dateSel < dateCurrent) {
    return true;
  }
  return false;
}

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }


}
