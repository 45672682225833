import { Component, OnInit } from '@angular/core';
import {PortalService} from "../../../shared/portal.service";
import {I4cpRecentAlerts,IRecentAlertData} from "../../../shared/entities/fourcp";
import * as moment from 'moment';
import {FadeAnimation} from "../../../animations/fadeAnimation";
import {FourcpService} from "../../fourcp.service";
import {IMarketData} from "../../../shared/entities/markets";
import {MarketsService} from "../../../markets/markets.service";
import {Subscription} from "rxjs";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {DataService} from "../../../shared/data.service";
import {IUserAccount} from "../../../shared/entities/profile";


@Component({
  selector: 'app-fourcp-recent',
  templateUrl: './fourcp-recent.component.html',
  styleUrls: ['../../fourcp.component.scss', 'fourcp-recent.component.scss']
})

export class FourcpRecentComponent implements OnInit {

  myAccount: IUserAccount;
  noData: boolean = false;
  noDataError: boolean = false;
  myRecentAlerts: I4cpRecentAlerts;

 // Subscriptions
accountSourceSubscription: Subscription;


  constructor(private portalService: PortalService,
              private fourcpService: FourcpService,
              private marketsService: MarketsService,
              private dataService: DataService) { }


  ngOnInit() {
    this.myAccount = this.dataService.getAccountSource();

    if(this.myAccount){
      this.getRecentAlerts();
    }

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getRecentAlerts();
      }
    );

  }


  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
  }



// Retrieve PaymentHistory
  getRecentAlerts() {
    this.dataService.setLoading(true);
      //    let request: IPaymentHistoryRequest = {
      //    accountId: this.myAccount.id,
      //   //accountId: 10000,
      // };

      this.fourcpService.getRecentAlerts(this.myAccount.id, 4).subscribe(
        (myRecentAlerts) => {
          this.myRecentAlerts = myRecentAlerts;
          if(this.myRecentAlerts.recentAlerts){
            this.noData = this.myRecentAlerts.recentAlerts.length == 0;
          } else {
            this.noData = true;
          }
          this.dataService.setLoading(false);
        }, err => {
          if(err.status == 404) {
            this.noDataError = true;
          }
          this.myRecentAlerts = null;
          console.error(err);
          this.dataService.setLoading(false);
        }
      );
    }
  }
