import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fourcp-weather',
  templateUrl: './fourcp-weather.component.html',
  styleUrls: ['../../fourcp.component.scss', 'fourcp-weather.component.scss']
})
export class FourcpWeatherComponent implements OnInit {

  showRadar: boolean = true;
  imgSrc = '';
  imgTimestamp = '';

  radarImgSrc = './files/RAD_MOS_REG_SC_TX.gif';
  temperatureImgSrc = './files/PLOT_CURRTEMPS_TX.gif';

  radarImgTimestamp = './files/PLOT_CURRTEMPS_TIMESTAMP.gif';
  temperatureImgTimestamp = './files/RAD_MOS_REG_SC_TIMESTAMP.gif';

  constructor() { }

  ngOnInit() {
    this.imgSrc = this.radarImgSrc;
    this.imgTimestamp = this.radarImgTimestamp;
  }

  toggleRadar() {
    this.showRadar = !this.showRadar;
    if(this.showRadar){
      this.imgSrc = this.radarImgSrc;
      this.imgTimestamp = this.radarImgTimestamp;
    } else {
      this.imgSrc = this.temperatureImgSrc;
      this.imgTimestamp = this.temperatureImgTimestamp;
    }
  }

}
