import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IRenewableSite } from '../../renewable-site';
import { PortalService } from '../../../shared/portal.service';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';
import { ISite } from '../../../shared/entities/site';

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss']
})
export class SiteSelectorComponent implements OnChanges {
  
  @Input() sites: IRenewableSite[];
  @Input() green = false;
  expanded = true;
  displayedSites: IRenewableSite[] = [];
  maskMode: boolean;
  maskModeSubscription: Subscription;

  constructor(private portalService: PortalService, private dataService: DataService) { }

  ngOnInit(){
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sites && changes.sites.currentValue) {
      this.refreshDisplayedSites(this.maskMode, this.portalService);
    }
  }

  toggleAllSelection() {
    const allSitesSelected = this.selectedSitesCount === this.displayedSites.length;
    this.displayedSites.forEach(site => site.selected = !allSitesSelected);
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  refreshDisplayedSites(maskMode: boolean, portalService: PortalService) {
    this.maskMode = maskMode;
    this.portalService = portalService;
    this.displayedSites = [];
    this.sites.forEach(site => {
      this.displayedSites.push({... site});
    });
  }

  getSiteName(site: IRenewableSite) {
    if (this.maskMode) {
      return this.portalService.getFakeSiteName(site);
    } else {
      return site.name;
    }
  }
  getSiteAddress(site: IRenewableSite) {
    if (this.maskMode) {
      return this.portalService.getFakeStreetAddress(site);
    } 
    return site.serviceAddress;
  }

  get selectedSiteIds(): string[] {
    return this.displayedSites.filter(site => site.selected).map(site => site.id);
  }

  get selectedSitesCount(): number {
    return this.selectedSiteIds.length;
  }


  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }

}
