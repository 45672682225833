import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {IPaymentMethod, IPaymentSchedule, IPaymentSettingsKey} from "../payments/payments";
import {ManagePaymentMethodsService} from "./manage-payment-methods.service";
import { RoutingPipe } from "../shared/pipe/routing.pipe"
import { BankAccountPipe } from "../shared/pipe/bank-account.pipe"
import {Subscription} from "rxjs";
import {PortalService} from "../shared/portal.service";
import {Permissions} from "../shared/const/permissions";
import {IHelpStep} from "../shared/entities/contextualHelp";
import { PaymentsService } from '../payments/payments.service';
import { ISite } from '../shared/entities/site';
import * as moment from 'moment';
declare var $ :any;


@Component({
  selector: 'app-manage-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  providers: [BankAccountPipe, RoutingPipe],
  styleUrls: ['manage-payment-methods.component.scss']
})
export class ManagePaymentMethodsComponent implements OnInit {

  totalSites: ISite[];
  selectedMethod: IPaymentMethod;
  selectedPaymentMethod: string;
  paymentMethods: IPaymentMethod[];
  paymentSchedule: IPaymentSchedule;
  formRendered: boolean = false;
  editingNew: boolean = false;
  editing: boolean = false;
  allowedToEditPaymentMethods: boolean = true;
  helpStep: IHelpStep;
  properties: any;
  editName : IPaymentMethod;
  updatedName : string;
  minDate: Date;
  maxDate: Date;
  methodNickname: string;
  showDateError: boolean;
  newPaymentAdded: boolean;
  savingRadio: boolean = true;
  makeDefault: boolean = true;
  selectedPayDate: Date;
  sitesForAccount : ISite[];
  isNCCAFlag = true;
  errorText: string = ''; 


  accountSourceSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private dataService: DataService,
              private portalService: PortalService,
              private paymentsService: PaymentsService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Manage Payment Methods');
    this.dataService.setSelectedNavItem('billing');
    this.setCurrentActivePage('payments')
    if(this.paymentSchedule) {
      this.paymentSchedule.paymentMethod = null;     
    }
    let existing = this.paymentsService.getPaymentSchedule();
    if(existing && this.dataService.getAccountSource().id!=existing.paymentMethod.userPaymentSettingsKey.accountId) {
      this.paymentsService.setPaymentSchedule(null)
    }
    this.allowedToEditPaymentMethods = this.portalService.hasPermission(Permissions.EditPaymentMethods);
  }
  

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

}
