import { Component, OnInit, OnDestroy } from '@angular/core';
import {DataService} from "../shared/data.service";
import {
  IMarketProperties, ErcotSnapshotProperties, ErcotInfoProperties, HenryHubProperties, HoustonShipChannelProperties
} from "../shared/entities/markets";

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss']
})
export class MarketsComponent implements OnInit {

  selectedTab: string = 'ERCOT';

  snapshotProperties: IMarketProperties;
  infoProperties: IMarketProperties;
  henryHubProperties: IMarketProperties;
  houstonShipChannelProperties: IMarketProperties;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.dataService.setTitleSource('Markets');
    this.dataService.setContextualHelp(false);
    this.dataService.setSelectedNavItem('markets');
    this.snapshotProperties = ErcotSnapshotProperties;
    this.infoProperties = ErcotInfoProperties;
    this.henryHubProperties = HenryHubProperties;
    this.houstonShipChannelProperties = HoustonShipChannelProperties;
  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(true);
  }

  switchTabs(selectedTab: string){
    this.selectedTab = selectedTab;
  }

}
