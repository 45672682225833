import { UsageReportUtility } from './usage-reports/usage-reports.utility';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { NgModule, NgZone, Provider, Type} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatFormFieldModule, } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { MatNativeDateModule} from '@angular/material/core';
import { FocusModule } from 'angular2-focus';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ImageUploadModule } from 'angular2-image-upload';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Interceptor } from "./auth/interceptor";
import { DndModule } from 'ng2-dnd';
import { TextMaskModule } from 'angular2-text-mask';
import { DatePipe, CurrencyPipe, DecimalPipe, CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip'
import { CarouselModule } from 'ngx-bootstrap/carousel'
import { LayoutModule } from '@angular/cdk/layout';
import { Http, RequestOptions, XHRBackend } from '@angular/http';

/* List of components to support the app */
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ContactComponent } from './contact/contact.component';
import { ProfileComponent } from './profile/profile.component';
import { BillingSummaryComponent } from './billing-summary/billing-summary.component';
import { LoginComponent } from './login/login.component';
import { TermsConditionsComponent } from './modals/terms-conditions/terms-conditions.component';
import { MultipleInvoicesComponent } from './modals/multiple-invoices/multiple-invoices.component';
import { ManageUsersComponent } from './modals/manage-users/manage-users.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { UsageReportsComponent } from './usage-reports/usage-reports.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { CreatePasswordComponent } from './login/create-password/create-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { BrokerConfirmComponent } from './broker-confirm/broker-confirm.component';

import { PaymentsComponent } from './payments/payments.component';
import { PaymentConfirmationComponent } from './payments/payment-confirmation/payment-confirmation.component';
import { PaymentConfirmationEmailComponent } from './modals/payment-confirmation-email/payment-confirmation-email.component';
import { SelectBalancesComponent } from './payments/select-balances/select-balances.component';
import { PaymentInfoComponent } from './payments/payment-info/payment-info.component';
import { ReviewPaymentComponent } from './payments/review-payment/review-payment.component';
import { ManagePaymentMethodsComponent } from './manage-payment-methods/manage-payment-methods.component';

import { AutoPaymentsComponent } from './autopayments/autopayments.component';
import { AutoPaymentsService } from './autopayments/autopayments.service';
import { AutopaySelectBalancesComponent } from './autopayments/autopay-select-balances/autopay-select-balances.component';
import { AutoPaymentInfoComponent } from './autopayments/autopay-info/autopay-info.component';
import { ReviewAutoPaymentComponent } from './autopayments/autopay-review/autopay-review.component';
import { AutoPaymentConfirmationComponent } from './autopayments/autopay-confirmation/autopay-confirmation.component';


import { IntervalUsageReportComponent } from './interval-usage-report/interval-usage-report.component'
import { SelectSitesComponent } from './interval-usage-report/select-sites/select-sites.component'
import { BillingComparisonComponent } from './billing-comparison/billing-comparison.component'
import { ChooseSettingsComponent } from './interval-usage-report/choose-settings/choose-settings.component';
import { ExportConfirmationComponent } from './interval-usage-report/export-confirmation/export-confirmation.component';
import { WidgetComponent } from './widget/widget.component';
import { SelectComponent } from './select/select.component';
import { UsageComparisonComponent } from './usage-comparison/usage-comparison.component';
import { UsageGraphComponent } from './usage-graph/usage-graph.component';
import { HelpTopicsComponent } from './help-topics/help-topics.component';
import { Accordion, AccordionGroup } from './accordion/accordion.component';
import { ManageSiteGroupsComponent } from './site-groups/manage-site-groups/manage-site-groups.component';
import { ViewEditSiteGroupComponent } from './site-groups/view-edit-site-group/view-edit-site-group.component';
import { MultipleInvoiceZipComponent } from './download/multiple-invoice-zip/multiple-invoice-zip.component';
import { EmailPasswordComponent } from './login/email-password/email-password.component';
import { AmendmentsStartComponent } from './amendments/amendments-start/amendments-start.component';
import { AmendmentsHomeComponent } from './amendments/amendments-home/amendments-home.component';
import { AmendmentsAddComponent } from './amendments/amendments-add/amendments-add.component';
import { AmendmentsTermsComponent } from './amendments/amendments-terms/amendments-terms.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AmendmentsCartComponent } from './amendments/amendments-cart/amendments-cart.component';
import { AmendmentsConfirmationComponent } from './amendments/amendments-confirmation/amendments-confirmation.component';
import { AmendmentsDeleteComponent } from './amendments/amendments-delete/amendments-delete.component';
import { AccessDeniedComponent } from './login/access-denied/access-denied.component';
import { MarketsDashboardComponent } from './markets/markets-dashboard/markets-dashboard.component';
import { HealthCheckComponent } from './modals/health-check/health-check.component';
import { MarketsSectionComponent } from "./markets/markets-section/markets-section.component";
import { AmendmentsHistoryComponent } from './amendments/amendments-history/amendments-history.component';
import { MarketsComponent } from './markets/markets.component';
import { AmendmentsAttachmentsComponent } from './modals/amendments-attachments/amendments-attachments.component';
import { AmendmentsComponent } from './amendments/amendments.component';
import { MetricsComponent } from './metrics/metrics.component';
import { UserMetricsComponent } from './metrics/user-metrics/user-metrics.component';
import { TransactionMetricsComponent } from './metrics/transaction-metrics/transaction-metrics.component';
import { EnrollmentMetricsComponent } from './metrics/enrollment-metrics/enrollment-metrics.component';
import { FourcpDemandComponent } from './fourcp/widgets/fourcp-demand/fourcp-demand.component';
import { FourcpAlertComponent } from './fourcp/widgets/fourcp-alert/fourcp-alert.component';
import { FourcpWeatherComponent } from './fourcp/widgets/fourcp-weather/fourcp-weather.component';
import { FourcpRecentComponent } from './fourcp/widgets/fourcp-recent/fourcp-recent.component';
import { FourcpSummaryComponent } from './fourcp/widgets/fourcp-summary/fourcp-summary.component';
import { FourcpComponent } from "./fourcp/fourcp.component";
import { FourcpAlertHistoryComponent } from "./fourcp/fourcp-alert-history/fourcp-alert-history.component";
import { FourcpOverviewComponent } from "./fourcp/fourcp-overview/fourcp-overview.component";
import { FourcpPerformanceComponent } from "./fourcp/fourcp-performance/fourcp-performance.component";
import { FourcpPreferencesComponent } from "./fourcp/fourcp-preferences/fourcp-preferences.component";
import { FourcpAboutComponent } from './fourcp/fourcp-about/fourcp-about.component';
import { FourcpSitePerformanceComponent } from './fourcp/fourcp-site-performance/fourcp-site-performance.component';
import { MagnifyingGlassComponent } from './magnifying-glass/magnifying-glass.component';
import { AmendmentsEmailConfirmationComponent } from './modals/amendments-email-confirmation/amendments-email-confirmation.component';
import { UserMetricsDetailComponent } from './modals/user-metrics-detail/user-metrics-detail.component';
import { TpPayoutReportComponent } from './tp-payout-report/tp-payout-report.component';
import { ProfileAlertsComponent } from './profile/profile-alerts/profile-alerts.component';
import { ProfileAccountsComponent } from './profile/profile-accounts/profile-accounts.component';
import { ProfileSubscriptionsComponent } from './profile/profile-subscriptions/profile-subscriptions.component';
import { TpDashboardComponent } from './tp-dashboard/tp-dashboard.component';
import { TpBillingSummaryComponent } from './tp-billing-summary/tp-billing-summary.component';
import { TpBillingComparisonComponent } from './tp-billing-comparison/tp-billing-comparison.component';
import { TpCustomerListComponent } from './tp-customer-list/tp-customer-list.component';
import { PublicMarketsComponent } from "./markets/public-markets/public-markets.component";
import { ForwardPricingMenuComponent } from './markets/forward-pricing-menu/forward-pricing-menu.component';
import { PositionComponent } from './position/position.component';  // --DRR 08262019
import { EditorialContentComponent } from './editorial-content/editorial-content.component';
import { AccountRenewalComponent } from './modals/account-renewal/account-renewal.component';
import { QuickPayComponent } from './quick-pay/quick-pay.component';

/* List of services to support the app */
import { CacheService } from './cache.service';
import {AccountsService} from './accounts/accounts.service';
import {DataService} from "./shared/data.service";
import {PortalService} from "./shared/portal.service";
import {PagerService} from "./shared/pager.service";
import {ContactService} from "./contact/contact.service";
import {SiteGroupService} from "./site-groups/site-groups.service";
import {BillingSummaryService} from "./billing-summary/billing-summary.service";
import { PositionService } from "./position/position.service";
import {ProfileService} from "./profile/profile.service";
import {LoginService} from "./login/login.service";
import {UsageReportService} from './usage-reports/usage-reports.service';
import {ReportDataUtility} from "./subscribe-to-report/report-data.utility";
import {ResetPasswordService} from "./login/reset-password/reset-password.service";
import {CreatePasswordService} from "./login/create-password/create-password.service";
import {PaymentsService} from "./payments/payments.service";
import {PaymentHistoryService} from "./payment-history/payment-history.service";
import {ManagePaymentMethodsService} from "./manage-payment-methods/manage-payment-methods.service";
import { IntervalUsageReportService } from './interval-usage-report/interval-usage-report.service';
import {BillingComparisonService} from "./billing-comparison/billing-comparison.service";
import { RoutingPipe } from './shared/pipe/routing.pipe';
import { SearchFilterPipe } from './aem-help/aem';
import { BankAccountPipe } from './shared/pipe/bank-account.pipe';
import {DashboardService} from "./dashboard/dashboard.service";
import {UsageComparisonService} from "./usage-comparison/usage-comparison.service";
import {BillingUsageService} from "./billing-usage/billing-usage.service";
import {UsageGraphService} from "./usage-graph/usage-graph.service";
import {RealtimeUsageService} from "./shadow-metering/realtime-usage/realtime-usage.service";
import { AccountEndingPipe } from './shared/pipe/account-ending.pipe';
import { HelpTopicsService } from './help-topics/help-topics.service';
import { HelpTopicsPipe } from './shared/pipe/help-topics.pipe';
import { SafeHtmlPipe } from './shared/pipe/safe-html.pipe';
import { CapitalizePipe } from './shared/pipe/capitalize.pipe';
import {SitePipe} from "./shared/pipe/site.pipe";
import {OrderByPipe} from "./shared/pipe/orderBy.pipe";
import {BalancesPipe} from "./shared/pipe/balances.pipe";
import {UserAccountPipe} from "./shared/pipe/useraccounts.pipe";
import {BillingReportPipe} from "./shared/pipe/billingReport.pipe";
import {UserPermissionsPipe} from "./shared/pipe/userpermissions.pipe";
import {UsageReportPipe} from "./shared/pipe/usageReport.pipe";
import {GenericPipe} from "./shared/pipe/generic.pipe";
import {ContractPipe} from "./shared/pipe/contract.pipe";
import {CookieService} from "ngx-cookie-service";
import {FakerService} from "./shared/faker.service";
import {AmendmentsService} from "./amendments/amendments.service";
import {ComparisonUtility} from "./shared/comparison-reports.utility";
import { MatchHeightDirective } from './shared/match-height.directive';
import {MarketsService} from "./markets/markets.service";
import {AmendmentsAttachmentsService} from "./amendments/amendments-attachments.service";
import {MetricsService} from "./metrics/metrics.service";
import {FourcpService} from "./fourcp/fourcp.service";
import {BrokerService} from "./tp-payout-report/broker.service";
import {SubscriptionsService} from "./subscriptions/subscriptions.service";
import { ManageSuperUsersComponent } from './manage-super-users/manage-super-users.component';
import {ManageSuperUsersService} from "./manage-super-users/manage-super-users.service";
import { TpCustomerListService } from './tp-customer-list/tp-customer-list.service';
import { FourcpSitePerformanceDetailsComponent } from './fourcp/fourcp-site-performance/fourcp-site-performance-details/fourcp-site-performance-details.component';
import { FourcpSummaryPerformanceComponent } from './fourcp/fourcp-summary-performance/fourcp-summary-performance.component';
import { FourcpSummaryPerformancePeakCardsComponent } from './fourcp/fourcp-summary-performance/fourcp-summary-performance-peak-cards/fourcp-summary-performance-peak-cards.component';
import { FourcpSummaryPerformanceDetailsComponent } from './fourcp/fourcp-summary-performance/fourcp-summary-performance-details/fourcp-summary-performance-details.component';
import { FourcpSitePerformanceDemandComponent } from './fourcp/fourcp-site-performance/fourcp-site-performance-demand/fourcp-site-performance-demand.component';
import { FourcpSitePerformanceTableComponent } from './fourcp/fourcp-site-performance/fourcp-site-performance-table/fourcp-site-performance-table.component';
import { FourcpSitePerformanceReviewComponent } from './fourcp/fourcp-site-performance/fourcp-site-performance-review/fourcp-site-performance-review.component';
import { BillingUsageComponent } from './billing-usage/billing-usage.component';
import { ForwardPricingRenewalComponent } from './markets/forward-pricing-renewal/forward-pricing-renewal.component';
import { ForwardPricingCustomComponent } from './markets/forward-pricing-custom/forward-pricing-custom.component';
import { ForwardPricingReportsComponent } from './markets/forward-pricing-reports/forward-pricing-reports.component';
import { ForwardPricingChartsComponent } from './markets/forward-pricing-charts/forward-pricing-charts.component';
import { EpdmModalComponent } from './markets/forward-pricing-charts/epdm-modal/epdm-modal.component';
import { FpcModifyModalComponent } from './markets/forward-pricing-charts/fpc-modify-modal/fpc-modify-modal.component';
import { PricingComponent } from './pricing/pricing.component';
import { PricingTcComponent } from './pricing-tc/pricing-tc.component';
import { PricingIEComponent } from './pricing-ie/pricing-ie.component';
import { RealtimeUsageComponent } from './shadow-metering/realtime-usage/realtime-usage.component';
import { RelatedContentItemComponent } from './related-content-item/related-content-item.component';
import { EditorialContentService} from "./editorial-content/editorial-content.service";
import { RealtimeExportComponent } from './modals/realtime-export/realtime-export.component';
import { RenewablesComponent } from './renewables/renewables.component';
import { RenewablesOverviewComponent } from './renewables/renewables-overview/renewables-overview.component';
import { RenewablesHistoryComponent } from './renewables/renewables-history/renewables-history.component';
import { RenewablesCertificatesComponent } from './renewables/renewables-certificates/renewables-certificates.component';
import { StateSitesMapComponent } from './renewables/renewables-overview/state-sites-map/state-sites-map.component';
import { RealTimeGraphComponent } from './renewables/renewables-overview/real-time-graph/real-time-graph.component';
import { RealTimeSummaryComponent } from './renewables/renewables-overview/real-time-summary/real-time-summary.component';
import { SummaryTableComponent } from './renewables/renewables-overview/summary-table/summary-table.component';
import { RenewablesService } from './renewables/renewables.service';
import { HistoryGraphComponent } from './renewables/renewables-history/history-graph/history-graph.component';
import { HistoryTableComponent } from './renewables/renewables-history/history-table/history-table.component';
import { CertificateTableComponent } from './renewables/renewables-certificates/certificate-table/certificate-table.component';
import { MaintenanceModalComponent } from './renewables/maintenance-modal/maintenance-modal.component';
import { GenerationDetailExportModalComponent } from './renewables/generation-detail-export-modal/generation-detail-export-modal.component';
import { SiteSelectorComponent } from './renewables/renewables-overview/site-selector/site-selector.component';
import { OnOffSwitchComponent } from './shared/components/on-off-switch/on-off-switch.component';
import { DateRangeSelectorComponent } from './shared/components/date-range-selector/date-range-selector.component';
import { AccountBalancesComponent } from './payments/select-balances/account-balances/account-balances.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { ShadowMeteringComponent } from './shadow-metering/shadow-metering.component';
import { DistributedGenerationComponent } from './shadow-metering/distributed-generation/distributed-generation.component';
import { OnsiteGenerationComponent } from './shadow-metering/onsite-generation/onsite-generation.component';
import { GenerationUsageGraphComponent } from './shadow-metering/distributed-generation/generation-usage-graph/generation-usage-graph.component';
import { GenerationStatusComponent } from './shadow-metering/distributed-generation/generation-status/generation-status.component';
import { ColumnSelectorComponent } from './shadow-metering/shared/column-selector/column-selector.component';
import { GeneratorsTableComponent } from './shadow-metering/shared/generators-table/generators-table.component';
import { GeneratorGaugeComponent } from './shadow-metering/shared/generator-gauge/generator-gauge.component';
import { ShadowMeteringService } from './shadow-metering/shadow-metering.service';
import { ColumnSelectorService } from './shadow-metering/shared/column-selector/column-selector.service';
import { StatementsComponent } from './statements/statements.component';
import { StatementsService } from './statements/statements.service';
import { ProfileMatrixBrokerPricingComponent } from './profile/profile-matrix-broker-pricing/profile-matrix-broker-pricing/profile-matrix-broker-pricing.component';
import { MatrixpricingService } from './matrixpricing/matrixpricing.service';
import { PositionManagementComponent } from './position-management/position-management.component';
import { PchbeComponent } from './pchbe/pchbe.component';
import { PchbeChartComponent } from './pchbe/pchbe-chart/pchbe-chart.component';
import { PchbeTableComponent } from './pchbe/pchbe-table/pchbe-table.component';
import { SelectProductComponent } from './select-product/select-product.component';
import { SelectProductService } from './select-product/select-product.service';
import { PositionReviewQueueComponent } from './position-review-queue/position-review-queue.component';
import { SuperManageUsersComponent } from './modals/super-manage-users/super-manage-users.component';
import {AccountRenewalService} from "./modals/account-renewal/account-renewal.service";
import { SelfRegisterComponent } from './self-register/self-register.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SelfRegistrationValidateAccountComponent } from './self-register/validate-account/sr-validate-account.component';
import { SelfRegistrationUserInfoComponent } from './self-register/user-info/sr-user-info.component';
import { SelfRegistrationAccessComponent } from './self-register/access/sr-access.component';
import { SelfRegistrationNrgToolTipComponent } from './self-register/validate-account/nrg-tool-tip/sr-nrg-tool-tip.component';
import { SelfRegisterService } from './self-register/self-register.service';
import { PreAuthHeaderComponent } from './pre-auth-nav/pre-auth-header/pre-auth-header.component';
import { PreAuthDataService} from './pre-auth-nav/pre-auth-data.service';
import { PreAuthFooterComponent } from './pre-auth-nav/pre-auth-footer/pre-auth-footer.component';
import { YoutubePlayerService } from './youtube-player/youtube-player-service';
import { ClickOutsideDirective } from './click-outside.directive';
import { AemHelpComponent } from './aem-help/aem-help.component';
import { AEMHelpService } from './aem-help/aem-help.service.component';
import { CommissionsEmailConfirmationComponent } from './modals/commissions-email-confirmation/commissions-email-confirmation.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { BrokerConfirmService } from './broker-confirm/broker-confirm.service';


import { BillDeliveryComponent } from './bill-delivery/bill-delivery.component';
import { BillDeliveryService } from './bill-delivery/bill-delivery.service';
import { BillDeliveryTermsComponent } from './bill-delivery/bill-delivery-terms/bill-delivery-terms.component';
import { BillDeliveryEditService } from './bill-delivery/bill-delivery-edit/bill-delivery-edit.service';
import { BillDeliveryEditComponent } from './bill-delivery/bill-delivery-edit/bill-delivery-edit.component';

import { BillDeliveryHistoryService } from './bill-delivery/bill-delivery-history/bill-delivery-history.service';
import { BillDeliveryHistoryComponent } from './bill-delivery/bill-delivery-history/bill-delivery-history.component';
import { BrokerDropComponent } from './broker-drop/broker-drop.component';
import { BrokerEnrollmentsComponent } from './broker-enrollments/broker-enrollments.component';
import { MarketDashboardComponent } from './markets/market-dashboard/market-dashboard.component';
import { UtilsService } from './shared/utils.service';
import { ViewDashboardSettingsComponent } from './markets/view-dashboard-settings/view-dashboard-settings.component';
import { MarketsNewSectionComponent } from './markets/markets-new-section/markets-new-section.component';
import { BrokerEnrollmentsService } from './broker-enrollments/broker-enrollments.service';
import { ProfileReportsComponent } from './profile/profile-reports/profile-reports.component';
import { SubscribeToReportComponent } from './subscribe-to-report/subscribe-to-report.component';
import { ReportSettingsService } from './subscribe-to-report/report-settings.service';
import { ProfileSubscriptionsUtility } from './profile/profile-subscriptions/profile-subscriptions.utility';

import { AutopayPipe } from './shared/pipe/autopay.pipe';
import { AmendmentsPipe } from './shared/pipe/amendments.pipe';
import { MarketsPipe } from './shared/pipe/markets.pipe';
import { SingleFieldSearchPipe } from './shared/pipe/single-field-search.pipe';
import { MetricsDetailPipe } from './shared/pipe/metrics-detail.pipe';
import { TpBillingSummaryPipe } from './shared/pipe/tp-billing-summary.pipe';
import { BillDeliveryEditPipe } from './shared/pipe/billdeliveryedit.pipe';
import { BillDeliveryHistoryPipe } from './shared/pipe/billdeliveryhistory.pipe';
import { BrokerConfirmPipe } from './shared/pipe/brokerconfirm';
import { BrokerEnrollmentsPipe } from './shared/pipe/brokerenrollments.pipe';
import { OamHttpInterceptor } from './shared/oam-http.interceptor';
import { OamHttpOldInterceptor } from './shared/oam-http-old.interceptor';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
// issue: New TypeScript version does not include 'window.navigator.msSaveBlob'
// source: https://stackoverflow.com/a/69491367
declare global {
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
}

/* List of routes to support the app */
const appRoutes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'access-denied', component: AccessDeniedComponent},
  {path: 'amendments', component: AmendmentsComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: AmendmentsHomeComponent},
      { path: 'start', component: AmendmentsStartComponent},
      { path: 'terms', component: AmendmentsTermsComponent},
      { path: 'add', component: AmendmentsAddComponent},
      { path: 'delete', component: AmendmentsDeleteComponent},
      { path: 'cart', component: AmendmentsCartComponent},
      { path: 'confirmation', component: AmendmentsConfirmationComponent},
      { path: 'history', component: AmendmentsHistoryComponent}
    ]
  },
  {path: 'bill-delivery', component: BillDeliveryComponent,
    children: [
      { path: '', redirectTo: 'terms', pathMatch: 'full' },
      { path: 'terms', component: BillDeliveryTermsComponent},
      { path: 'edit', component: BillDeliveryEditComponent},
      { path: 'history', component: BillDeliveryHistoryComponent}
     ]
  },
  {path: 'billing-reports', component: BillingSummaryComponent},
  {path: 'billing-comparison', component: BillingComparisonComponent},
  {path: 'billing-usage', component: BillingUsageComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'pricing-tc', component: PricingTcComponent},
  {path: 'pricing-ie', component: PricingIEComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'contracts', component: AccountsComponent},
  {path: 'create-password', component: CreatePasswordComponent},
  {path: 'dashboard', component: DashboardComponent },
  {path: 'download/:accountId/multiple-invoice-zip/:fileName', component: MultipleInvoiceZipComponent},
  {path: 'editorial-content', component: EditorialContentComponent},
  {path: 'email-password', component: EmailPasswordComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'fourcp', component: FourcpComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: FourcpOverviewComponent },
      { path: 'alert-history', component: FourcpAlertHistoryComponent },
      { path: 'performance', component: FourcpPerformanceComponent },
      { path: 'preferences', component: FourcpPreferencesComponent },
      { path: 'site-performance', component: FourcpSitePerformanceComponent},
      { path: 'summary-performance', component: FourcpSummaryPerformanceComponent}
    ]},
  {path: 'fourcp-about', component: FourcpAboutComponent},
  {path: 'help-topics', component: HelpTopicsComponent},
  {path: 'interval-usage-report', component: IntervalUsageReportComponent,
    children: [
      {path: '', redirectTo: 'select-sites', pathMatch: 'full'},
      {path: 'select-sites', component: SelectSitesComponent},
      {path: 'choose-settings', component: ChooseSettingsComponent},
      {path: 'export-confirmation', component: ExportConfirmationComponent},
    ]
  },
  {path: 'manage-payment-methods', component: ManagePaymentMethodsComponent},
  {path: 'autopayments', component: AutoPaymentsComponent,
    children: [
      { path: '', redirectTo: 'autopay-select-balances', pathMatch: 'full' },
      { path: 'autopay-select-balances', component: AutopaySelectBalancesComponent },
      { path: 'autopay-info', component: AutoPaymentInfoComponent },
      { path: 'autopay-review', component: ReviewAutoPaymentComponent },
      { path: 'autopay-confirmation', component: AutoPaymentConfirmationComponent},
    ]
  },
  {path: 'profile', component: ProfileComponent},
  {path: 'manage-super-users', component: ManageSuperUsersComponent},
  {path: 'markets',
    children: [
      { path: '', redirectTo: 'pubic-markets', pathMatch: 'full'},
      { path: 'public-markets', component: PublicMarketsComponent},
      { path: 'forward-pricing', component: ForwardPricingMenuComponent},
    ]
  },
  {path: 'metrics', component: MetricsComponent,
    children: [
      { path: '', redirectTo: 'user', pathMatch: 'full' },
      { path: 'by customer', component: UserMetricsComponent },
      { path: 'transactions', component: TransactionMetricsComponent },
      { path: 'enrollment', component: EnrollmentMetricsComponent },
    ]
  },
  {path: 'broker-confirm', component: BrokerConfirmComponent},
  {path: 'broker-drop', component: BrokerDropComponent},
  {path: 'broker-enrollments', component: BrokerEnrollmentsComponent},
  {path: 'position', component: PositionComponent},    // Path for Postion Report --DRR 08262019
  {path: 'position-management', component: PositionManagementComponent},
  //{path: 'position-review-queue', component: PositionReviewQueueComponent},
  {path: 'pchbe', component: PchbeComponent},
  {path: 'payment-history', component: PaymentHistoryComponent},
  {path: 'payments', component: PaymentsComponent,
    children: [
      { path: '', redirectTo: 'select-balances', pathMatch: 'full' },
      { path: 'select-balances', component: SelectBalancesComponent },
      { path: 'payment-info', component: PaymentInfoComponent },
      { path: 'review-payment', component: ReviewPaymentComponent },
      {path: 'payment-confirmation', component: PaymentConfirmationComponent},
    ]
  },
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'self-register', component: SelfRegisterComponent},
  {path: 'site-groups',
    children: [
      { path: '', redirectTo: 'manage', pathMatch: 'full' },
      { path: 'manage', component: ManageSiteGroupsComponent},
      { path: 'view-edit', component: ViewEditSiteGroupComponent}
    ]
  },
  {path: 'statements', component: StatementsComponent},
  {path: 'third-party',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: TpDashboardComponent},
      { path: 'billing-comparison', component: TpBillingComparisonComponent},
      { path: 'billing-summary', component: TpBillingSummaryComponent},
      { path: 'customer-list', component: TpCustomerListComponent},
      { path: 'payout-report', component: TpPayoutReportComponent},
    ]
  },
  {path: 'usage-comparison', component: UsageComparisonComponent},
  {path: 'usage-graph', component: UsageGraphComponent},
  {path: 'usage-reports', component: UsageReportsComponent},
  {path: 'welcome', component: WelcomeComponent},
  {path: 'shadow-metering', component: ShadowMeteringComponent,
    children: [
      { path: '', redirectTo: 'realtime-usage', pathMatch: 'full' },
      { path: 'realtime-usage', component: RealtimeUsageComponent },
      { path: 'distributed-generation', component: DistributedGenerationComponent },
      { path: 'onsite-generation', component: OnsiteGenerationComponent },
    ]
  },
  {path: 'renewables', component: RenewablesComponent,
    children: [
      { path: '', redirectTo: 'certificates', pathMatch: 'full' },
      { path: 'overview', component: RenewablesOverviewComponent },
      { path: 'history', component: RenewablesHistoryComponent },
      { path: 'certificates', component: RenewablesCertificatesComponent }
    ]
  },
  {path: 'quick-pay', component: QuickPayComponent},
  {path: 'help',component: AemHelpComponent}
];

const appComponents = [
  AppComponent,
  HeaderComponent,
  AccountsComponent,
  ContactComponent,
  ManageSiteGroupsComponent,
  ViewEditSiteGroupComponent,
  BillingSummaryComponent,
  ProfileComponent,
  LoginComponent,
  MultipleInvoicesComponent,
  ManageUsersComponent,
  TermsConditionsComponent,
  ForgotPasswordComponent,
  UsageReportsComponent,
  RealtimeUsageComponent,
  ResetPasswordComponent,
  CreatePasswordComponent,
  DashboardComponent,
  PaymentConfirmationComponent,
  PaymentConfirmationEmailComponent,
  PaymentHistoryComponent,
  IntervalUsageReportComponent,
  PaymentsComponent,
  SelectBalancesComponent,
  PaymentInfoComponent,
  ReviewPaymentComponent,
  ManagePaymentMethodsComponent,
  AutoPaymentsComponent,
  AutopaySelectBalancesComponent,
  AutoPaymentInfoComponent,
  AutoPaymentConfirmationComponent,
  ReviewAutoPaymentComponent,
  BillingComparisonComponent,
  SelectSitesComponent,
  ChooseSettingsComponent,
  ExportConfirmationComponent,
  SelectComponent,
  WidgetComponent,
  UsageComparisonComponent,
  UsageGraphComponent,
  HealthCheckComponent,
  HelpTopicsComponent,
  Accordion,
  AccordionGroup,
  MultipleInvoiceZipComponent,
  EmailPasswordComponent,
  AmendmentsHomeComponent,
  AmendmentsStartComponent,
  AmendmentsAddComponent,
  AmendmentsTermsComponent,
  WelcomeComponent,
  MatchHeightDirective,
  AmendmentsCartComponent,
  AmendmentsConfirmationComponent,
  AmendmentsDeleteComponent,
  AmendmentsHistoryComponent,
  MarketsComponent,
  MarketsSectionComponent,
  MarketsDashboardComponent,
  MarketDashboardComponent,
  AccessDeniedComponent,
  AmendmentsAttachmentsComponent,
  AmendmentsComponent,
  MetricsComponent,
  UserMetricsComponent,
  TransactionMetricsComponent,
  EnrollmentMetricsComponent,
  FourcpComponent,
  FourcpOverviewComponent,
  FourcpAlertHistoryComponent,
  FourcpPerformanceComponent,
  FourcpPreferencesComponent,
  FourcpAboutComponent,
  FourcpDemandComponent,
  FourcpAlertComponent,
  FourcpWeatherComponent,
  FourcpRecentComponent,
  FourcpSummaryComponent,
  FourcpSitePerformanceComponent,
  FourcpSitePerformanceDetailsComponent,
  FourcpSummaryPerformanceComponent,
  FourcpSummaryPerformancePeakCardsComponent,
  FourcpSummaryPerformanceDetailsComponent,
  FourcpSitePerformanceDemandComponent,
  FourcpSitePerformanceTableComponent,
  FourcpSitePerformanceReviewComponent,
  AmendmentsEmailConfirmationComponent,
  MagnifyingGlassComponent,
  UserMetricsDetailComponent,
  TpPayoutReportComponent,
  ProfileAlertsComponent,
  ProfileAccountsComponent,
  ProfileSubscriptionsComponent,
  ManageSuperUsersComponent,
  TpDashboardComponent,
  TpBillingSummaryComponent,
  TpBillingComparisonComponent,
  TpCustomerListComponent,
  PublicMarketsComponent,
  ForwardPricingMenuComponent,
  ForwardPricingRenewalComponent,
  PositionComponent,  // --DRR 08262019
  EditorialContentComponent,
  RelatedContentItemComponent,
  SuperManageUsersComponent,
  SelfRegisterComponent,
  QuickPayComponent,
  PreAuthHeaderComponent,
  SuperManageUsersComponent,
  AccountRenewalComponent,
  YoutubePlayerComponent,
  BillDeliveryComponent,
  BillDeliveryEditComponent,
  BillDeliveryHistoryComponent,
  BillDeliveryTermsComponent
];

const appServices = [
  CacheService,
  AccountsService,
  DataService,
  PortalService,
  PositionService,
  FakerService,
  PagerService,
  ContactService,
  SiteGroupService,
  BillingSummaryService,
  ProfileService,
  LoginService,
  UsageReportService,
  ResetPasswordService,
  CreatePasswordService,
  PaymentsService,
  AutoPaymentsService,
  PaymentHistoryService,

  BillDeliveryService,
  BillDeliveryEditService,
  BillDeliveryHistoryService,
  BrokerConfirmService,
  BrokerEnrollmentsService,
  ManagePaymentMethodsService,
  IntervalUsageReportService,
  BillingComparisonService,
  DashboardService,
  UsageComparisonService,
  RealtimeUsageService,
  UsageGraphService,
  BillingUsageService,
  HelpTopicsService,
  CookieService,
  AmendmentsService,
  MarketsService,
  AmendmentsAttachmentsService,
  MetricsService,
  FourcpService,
  BrokerService,
  SubscriptionsService,
  MatrixpricingService,
  ManageSuperUsersService,
  TpCustomerListService,
  EditorialContentService,
  RenewablesService,
  ShadowMeteringService,
  ColumnSelectorService,
  DatePipe,
  DecimalPipe,
  SelectProductService,
  StatementsService,
  SelfRegisterService,
  PreAuthDataService,
  StatementsService,
  AccountRenewalService,
  AEMHelpService,
  YoutubePlayerService,
  UtilsService,
  ReportSettingsService
];

const appPipes = [
  BankAccountPipe,
  RoutingPipe,
  SearchFilterPipe,
  AccountEndingPipe,
  HelpTopicsPipe,
  SafeHtmlPipe,
  CapitalizePipe,
  SitePipe,
  OrderByPipe,
  BalancesPipe,
  UserAccountPipe,
  BillingReportPipe,
  UserPermissionsPipe,
  UsageReportPipe,
  GenericPipe,
  ContractPipe,
  AutopayPipe,
  AmendmentsPipe,
  MarketsPipe,
  SingleFieldSearchPipe,
  MetricsDetailPipe,
  TpBillingSummaryPipe,
  BillDeliveryEditPipe,
  BillDeliveryHistoryPipe,
  BrokerConfirmPipe,
  BrokerEnrollmentsPipe
];

const appUtilities = [
  ReportDataUtility,
  UsageReportUtility,
  ComparisonUtility,
  ProfileSubscriptionsUtility
];

const appModules = [
  HttpClientModule,
  BrowserModule,
  FormsModule,
  TextMaskModule,
  BrowserAnimationsModule,
  FocusModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatButtonToggleModule,
  LayoutModule
];

@NgModule({
  declarations: [
    appComponents,
    appPipes,
    BillingUsageComponent,
    ForwardPricingCustomComponent,
    ForwardPricingReportsComponent,
    ForwardPricingChartsComponent,
    FpcModifyModalComponent,
    EpdmModalComponent,
    PricingComponent,
    PricingTcComponent,
    PricingIEComponent,
    RealtimeExportComponent,
    RenewablesComponent,
    RenewablesOverviewComponent,
    RenewablesHistoryComponent,
    RenewablesCertificatesComponent,
    StateSitesMapComponent,
    RealTimeGraphComponent,
    RealTimeSummaryComponent,
    SummaryTableComponent,
    HistoryGraphComponent,
    HistoryTableComponent,
    CertificateTableComponent,
    MaintenanceModalComponent,
    GenerationDetailExportModalComponent,
    SiteSelectorComponent,
    OnOffSwitchComponent,
    DateRangeSelectorComponent,
    AccountBalancesComponent,
    SpinnerComponent,
    ShadowMeteringComponent,
    DistributedGenerationComponent,
    OnsiteGenerationComponent,
    GenerationUsageGraphComponent,
    GenerationStatusComponent,
    ColumnSelectorComponent,
    GeneratorsTableComponent,
    GeneratorGaugeComponent,
    ProfileMatrixBrokerPricingComponent,
    PositionManagementComponent,
    PchbeComponent,
    PchbeChartComponent,
    PchbeTableComponent,
    SelectProductComponent,
    PositionReviewQueueComponent,
    StatementsComponent,
    SuperManageUsersComponent,
    SelfRegisterComponent,
    ProgressBarComponent,
    SelfRegistrationValidateAccountComponent,
    SelfRegistrationUserInfoComponent,
    SelfRegistrationAccessComponent,
    SelfRegistrationNrgToolTipComponent,
    PreAuthHeaderComponent,
    PreAuthFooterComponent,
    ClickOutsideDirective,
    YoutubePlayerComponent,
    AemHelpComponent,
    ClickOutsideDirective,
    CommissionsEmailConfirmationComponent,

    BillDeliveryComponent,
    BillDeliveryEditComponent,
    BillDeliveryHistoryComponent,
    BillDeliveryTermsComponent,

    BrokerConfirmComponent,
    BrokerDropComponent,
    ViewDashboardSettingsComponent,
    MarketsNewSectionComponent,
    BrokerEnrollmentsComponent,
    ProfileReportsComponent,
    SubscribeToReportComponent
    // RealtimeUsageComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true }),
    ImageUploadModule.forRoot(),
    appModules,
    DndModule.forRoot(),
    TooltipModule.forRoot(),
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OamHttpInterceptor,
      multi: true
    },
    {
      provide: Http,
      useFactory: (backend: XHRBackend, defaultOptions: RequestOptions, portalService: PortalService) => {
        return new OamHttpOldInterceptor(backend, defaultOptions, portalService);
      },
      deps: [XHRBackend, RequestOptions]
    },
    CurrencyPipe,
    appServices,
    appUtilities
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ngZone: NgZone) {
    (window as any).ngZone = this.ngZone // store reference on window to be used by element during its bootstrap
  }
}
