import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelfRegistrationNrgToolTipComponent } from './nrg-tool-tip/sr-nrg-tool-tip.component';
import {IAccountLinkRequest} from "../entities/acccountLinkRequest"
import { IAccountLinkResponse } from '../entities/accountLinkResponse';
import { IEletricUtilityProviderResponse } from '../entities/electric-utility-provider';
import { SelfRegisterService } from '../self-register.service';

@Component({
  selector: 'app-sr-validate-account',
  templateUrl: './sr-validate-account.component.html',
  styleUrls: ['./sr-validate-account.component.scss']
})
export class SelfRegistrationValidateAccountComponent implements OnInit {
  @Input() billZipBool: boolean;
  @Input() acctLinkRes: IAccountLinkResponse;
  @Input() linkAttemptsRemaining: number;
  @Input() showAttemptFailedError: boolean;
  @Input() isLargeOrThirdPartyAccount: boolean;
  @Output() enableContinueButton = new EventEmitter<boolean>();
  @Output() accountLinkReqOut = new EventEmitter<IAccountLinkRequest>();
  accountLinkRequest: IAccountLinkRequest;
  showToolTip: boolean = false;
  invoiceProvider: string;
  showBillNumberInput: boolean = false;
  invoiceAccountNumber: string;
  invoiceAccountNumber2: string;
  fullInvoiceAccountNumber: string;
  showContent: boolean;
  billingZipCode: number;
  clientServicesNumber: string = "1-844-737-6742";
  isMobile: boolean;
  selectedEDC: IEletricUtilityProviderResponse;
  regexString: string = '';
  edcSearch: string = '';
  filteredEDCList: IEletricUtilityProviderResponse[];
  electricUtilityProviders: IEletricUtilityProviderResponse[];

  constructor(private selfRegisterService: SelfRegisterService) { }

  ngOnInit() {
    if (window.screen.width <= 360) { // 768px portrait
      this.isMobile = true;
    }
    this.getElectricProvidersList();
  }

  getElectricProvidersList() {
    this.selfRegisterService.getElectricUtilityProviders().subscribe((response: any) => {
      if (response) {
        this.electricUtilityProviders = response;
        this.electricUtilityProviders.forEach(edc => {
          edc.accountRegex = new RegExp(edc.accountRegex);
        })
        this.electricUtilityProviders.sort((a, b) => (a.edcName < b.edcName ? -1 : 1));
        this.filteredEDCList = this.electricUtilityProviders;
      } else {
        this.electricUtilityProviders = [];
        this.filteredEDCList = this.electricUtilityProviders;
      }
    }, err => {
      this.electricUtilityProviders = [];
      this.filteredEDCList = this.electricUtilityProviders;
    });
  }

  checkIfValidAccountNumber(){
    if(this.checkAccountNumber()) {
      if (this.billZipBool) {
        this.checkBillingZip();
        return;
      }
      this.enableContinueButton.emit(true)
      return;
    }
    this.enableContinueButton.emit(false);
  }

  checkAccountNumber() {
    return this.invoiceAccountNumber.length >= 7 && this.invoiceAccountNumber2.length > 0;
  }

  validateEDCUAN() {
    if (this.checkUAN()) {
      if (this.billZipBool) {
        this.checkBillingZip();
        return;
      }
      this.enableContinueButton.emit(true);
      return;
    }
    this.enableContinueButton.emit(false);
  }

  checkUAN() {
    return this.selectedEDC &&
      this.selectedEDC.accountLength.includes(this.invoiceAccountNumber.length) &&
      this.selectedEDC.accountRegex.test(this.invoiceAccountNumber);
  }

  enableToolTip(){
    this.showToolTip = !this.showToolTip;
  }

  saveProvider(){
    this.showBillNumberInput = true;
    this.invoiceAccountNumber = '';
    this.invoiceAccountNumber2 = '';
    this.billingZipCode = null;
    this.selectedEDC = null;
    this.enableContinueButton.emit(false);
  }

  selectEDC(edc: IEletricUtilityProviderResponse) {
    this.selectedEDC = edc;
    this.edcSearch = edc.edcName;
    this.filteredEDCList = this.electricUtilityProviders;
    this.regexString = this.convertRegexToReadable(this.selectedEDC.accountRegex);
    if (this.billZipBool) {
      this.checkBillingZip();
      return;
    }
    this.validateEDCUAN();
  }

  checkBillingZip() {
    if (this.billingZipCode.toString().length == 5) {
      if (this.invoiceProvider == 'EDC' && this.checkUAN()) {
        let req: IAccountLinkRequest = {
          invoiceProvider: this.invoiceProvider,
          edcName: this.selectedEDC.edcName,
          edcCode: this.selectedEDC.edcCode,
          invoiceAccountNumber: this.invoiceAccountNumber,
          billingZip: this.billingZipCode.toString()
        };
        this.accountLinkReqOut.emit(req);
        return this.enableContinueButton.emit(true);
      } else if (this.invoiceProvider != 'EDC' && this.checkAccountNumber()) {
        let req: IAccountLinkRequest = {
          invoiceProvider: this.invoiceProvider,
          invoiceAccountNumber: this.invoiceAccountNumber,
          checkDigit: this.invoiceAccountNumber2,
          billingZip: this.billingZipCode.toString()
        }
        this.accountLinkReqOut.emit(req)
        return this.enableContinueButton.emit(true);
      } else {
        this.enableContinueButton.emit(false);
      }
    } else {
      this.enableContinueButton.emit(false);
    }
  }

  searchEDC() {
    this.selectedEDC = null;
    this.enableContinueButton.emit(false);
    if (!this.edcSearch) {
      this.filteredEDCList = this.electricUtilityProviders;
      return;
    }
    this.filteredEDCList = this.electricUtilityProviders.filter(x => x.edcName.toUpperCase().includes(this.edcSearch.toUpperCase()) || x.edcCode.toUpperCase().includes(this.edcSearch.toUpperCase()));
  }

  convertRegexToReadable(regex: RegExp) {
    // Convert the RegExp to a string and remove the beginning/ending slashes
    let regexStr: string = regex.toString().slice(1, -1);

    // Map to translate regex to X-patterns
    let translationMap: { [identifier: string]: string } = {
    "\\d": "X",
    "\\w": "X",
    };

    // Replace regex patterns with X's - track the {min,max} digits for repeat
    let convertedStr: string = regexStr.replace(/(\\w|\\d)\{(\d+),(\d+)\}/g,
    (match, p1, p2, p3) => translationMap[p1].repeat(parseInt(p3)));

    // Remove start and end character (^ or $), escaping backslashes and replace '|' with 'or'
    convertedStr = convertedStr.replace("^", "").replace("$", "").replace("|", " or ").replace(/\\/g, '').replace("[zZ]", 'X');

    convertedStr = convertedStr.replace(/[\^\$]/g, '');

    return convertedStr
  }
}
