import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { IRenewableSite } from '../../renewable-site';
import { IRenewableSiteGeneration, ISiteGenerationRequest } from '../../renewable-site-generation';
import { buildChartConfig, apiDateFormat, gradient9, setSolarExtremes, sharedSeries, solarTitle, updateSeriesVisibility } from '../../renewables-utility';
import { RenewablesService } from '../../renewables.service';
import { Subscription } from 'rxjs';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import {IUserAccount} from "../../../shared/entities/profile";
import { DataService } from '../../../shared/data.service';


declare var Highcharts: any;

@Component({
  selector: 'app-real-time-graph',
  templateUrl: './real-time-graph.component.html',
  styleUrls: ['./real-time-graph.component.scss']
})
export class RealTimeGraphComponent implements OnInit, OnDestroy {
  myUserAccount: IUserAccount;
  sites: IRenewableSite[];
  siteGeneration: IRenewableSiteGeneration;
  showForecast = true;
  showSolar = true;
  dayOffset = 3;
  private graphChart: any;
  private maxSolar: number;
  private readonly interval = 5;
  private readonly fetchInterval = this.interval * 60000;
  private readonly unsubscribe$ = new Subject();
  refreshSubscription: Subscription;

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(private renewablesService: RenewablesService,
              private dataService: DataService
    ) { }

  ngAfterViewInit(){
    this.spinner.show('Loading Site Generation...');
  }
  ngOnInit() {
    
    this.initializeGraph();

    this.renewablesService.sites
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.sites = sites;
        this.fetchSiteGeneration();
      });
      this.myUserAccount = this.dataService.getAccountSource();

      if (this.refreshSubscription) {
        this.refreshSubscription.unsubscribe();
      }
      this.refreshSubscription = IntervalObservable.create(60000).subscribe(() => {
        // if (!this.noDataError) {
          this.fetchSiteGeneration();
        // }
      });
    
    // Observable.timer(this.fetchInterval, this.fetchInterval)
    //   .takeUntil(this.unsubscribe$)
    //   .subscribe(() => this.fetchSiteGeneration());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  toggleSeriesVisibility() {
    setSolarExtremes(this.graphChart, this.maxSolar);
    this.graphChart.yAxis[1].setTitle({ text: this.showSolar && this.siteGeneration.solarAvailable ? solarTitle : null });
    this.graphChart.yAxis[1].update({ visible: this.showSolar && this.siteGeneration.solarAvailable });

    updateSeriesVisibility(this.graphChart, 0, this.siteGeneration.shadowAvailable);
    updateSeriesVisibility(this.graphChart, 1, this.showForecast && this.siteGeneration.forecastAvailable);
    updateSeriesVisibility(this.graphChart, 2, this.showSolar && this.siteGeneration.solarAvailable);
    this.graphChart.legend.render();
  }

  updateDayOffset(offset: number) {
    this.dayOffset = offset;
    this.fetchSiteGeneration();
  }

  private initializeGraph() {
    const chartConfig = buildChartConfig(this.interval, {
      defs: {
        gradient9
      },
      series: sharedSeries()
    }, () => {
      setSolarExtremes(this.graphChart, this.maxSolar);
    });
    this.graphChart = Highcharts.chart('renewables-realtime-graph-container', chartConfig);
  }

  private fetchSiteGeneration() {
    this.spinner.show('Loading Site Generation...');
    this.graphChart.zoomOut();
    const endDate = moment().endOf('day');
    this.myUserAccount = this.dataService.getAccountSource();

    const request = {
      accountId: this.myUserAccount.id,
      siteIds: this.sites.filter(site => site.selected).map(site => site.id),
      startDate: endDate.clone().startOf('day').subtract(this.dayOffset, 'days').format(apiDateFormat),
      endDate: endDate.format(apiDateFormat),
      interval: this.interval
    } as ISiteGenerationRequest;
    this.renewablesService.getSiteGeneration(request, true)
      .takeUntil(this.unsubscribe$)
      .subscribe(siteGeneration => {
        this.siteGeneration = siteGeneration;
        this.updateGraphData();
        this.spinner.hide();
      }, error => {
        console.error('Failed to get site generation', error);
        this.spinner.hide();
      });
  }

  private updateGraphData() {
    const points = this.siteGeneration.points;

    this.graphChart.xAxis[0].setCategories(points.map(point =>
      moment(point.date, apiDateFormat).add((point.intervalId - 1) * 5, 'minutes').toDate()
    ));

    this.graphChart.series[0].setData(points.map(point =>
      ({ y: point.shadow, countLabel: 'Shadow Site Count: ', count: point.shadowCount })));
    this.graphChart.series[1].setData(points.map(point =>
      ({ y: point.forecast, countLabel: 'Forecast Site Count: ', count: point.forecastCount })));
    this.graphChart.series[2].setData(points.map(point => point.solar));

    this.maxSolar = points.map(point => point.solar).sort((a, b) => b - a)[0];

    this.toggleSeriesVisibility();
  }
}
