import {Widget} from "./widget";
declare var Highcharts: any;
import * as moment from 'moment';

export class BillingSummaryWidget extends Widget {

  constructor(widget: Widget) {
    super('BILLINGSUMMARY');
    this.widgetNameDisplay = 'Billing Summary';
    this.widgetId = widget.widgetId ? widget.widgetId : null;
    this.accountId = widget.accountId ? widget.accountId : null;
    this.width = widget.width ? widget.width: 1;
    this.height = widget.height ? widget.height: 1;
    this.chart = 'column';
    this.periodType = widget.periodType ? widget.periodType : { display: 'Last 12 Months', value: "Last12Months" };
    this.dateType = widget.dateType ? widget.dateType : { display: 'Meter Read End Date', value: "METER READ END DATE" };
    this.billingDataType = widget.billingDataType ? widget.billingDataType : { display: 'Current Charges ($)', value: "CURRENTCHARGES" };
    this.siteRequest = widget.siteRequest ? widget.siteRequest : { display: 'All Sites', value: "ALL_SITES" };
    this.systemGroupName = widget.systemGroupName ? widget.systemGroupName : null;
    this.groupCategory = widget.groupCategory ? widget.groupCategory : null;
    this.siteId = widget.siteId ? widget.siteId : null;
    this.groupId = widget.groupId ? widget.groupId : null;
    this.showPlaceholder = widget.showPlaceholder ? widget.showPlaceholder : false;
  }

  buildWidget(data: IChartResponse) {

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    let currentMonth = 1;
    let chartData: IChartData = {
      usage: [],
      totalCharges: [],
      months: [],
      monthLabels: []
    };

    let monthYear: IMonthYear;
    if (this.periodType.value == 'Last6Months') {
      for(let i = 5; i > 0; i--){
        monthYear = {
          month: moment().subtract(i, 'month').month() + 1,
          year: moment().subtract(i, 'month').year()
        };
        chartData.months.push(monthYear);
      }
      monthYear = {
        month: moment().month() + 1,
        year: moment().year()
      };
      chartData.months.push(monthYear);
    } else if (this.periodType.value == 'Last12Months') {
      for(let i = 11; i > 0; i--){
        monthYear = {
          month: moment().subtract(i, 'month').month() + 1,
          year: moment().subtract(i, 'month').year()
        };
        chartData.months.push(monthYear);
      }
      monthYear = {
        month: moment().month() + 1,
        year: moment().year()
      };
      chartData.months.push(monthYear);
    } else if (this.periodType.value == 'ThisYear') {
      for(let i = 0; i < moment().month() + 1; i++){
        monthYear = {
          month: i + 1,
          year: moment().year()
        };
        chartData.months.push(monthYear);
      }
    }

    let dataExists = false;
    if(data.billingValues){
      chartData.months.forEach((monthYear) => {
        let monthFound = false;
        data.billingValues.forEach((item) => {
          if(item.month == monthYear.month && item.year == monthYear.year){
            monthFound = true;
            dataExists = true;
            chartData.usage.push(Math.round(item.usage));
            chartData.totalCharges.push(item.totalCharges);
          }
        });
        if(!monthFound) {
          chartData.usage.push(null);
          chartData.totalCharges.push(null);
        }
      });
    }

    // Format months
    chartData.months.forEach((monthYear) => {
      chartData.monthLabels.push(moment(monthYear.month, 'M').format('MMM'));
    });

    let chartId = 'chart-' + this.widgetId;
    if(this.billingDataType.value == 'CURRENTCHARGES'){
      Highcharts.chart(chartId, {
        chart: {
          type: this.chart,
          height: '200'
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        xAxis: {
          categories: chartData.monthLabels
        },
        yAxis: {
          title: {
            text: 'Current Charges'
          },
          labels: {
            formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<td style="padding:0"><b>${point.y: ,0.2f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        credits: {
          enabled: false
        },
        series: [{
          name: ' ',
          data: chartData.totalCharges
        }]
      })
    } else {
      Highcharts.chart(chartId, {
        chart: {
          type: this.chart,
          height: '200'
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        xAxis: {
          categories: chartData.monthLabels
        },
        yAxis: {
          title: {
            text: 'Usage (kWh)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<td style="padding:0"><b>{point.y: ,0.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        credits: {
          enabled: false
        },
        series: [{
          name: ' ',
          data: chartData.usage
        }]
      })
    }

    return dataExists;
  }

  getDisplayDate(): string {
    let length = 17;
    let str: string = '';
    if(this.width == 1) {
      if(this.periodType) {
        str = this.periodType.display ? this.periodType.display : this.periodType;
      } else if (this.intervalType) {
        str = this.intervalType.display ? this.intervalType.display : this.intervalType;
      }
      str = str.length > length ? '(' + str.substring(0, length - 3) + "..." : '(' + str + ')';
    } else {
      if(this.periodType) {
        str = this.periodType.display ? this.periodType.display : this.periodType;
      } else if (this.intervalType) {
        str = this.intervalType.display ? this.intervalType.display : this.intervalType;
      }
      str = '(' + str + ')';
    }
    return str;
  }
}

interface IChartData {
  usage: number[];
  totalCharges: number[];
  months: IMonthYear[];
  monthLabels: string[];
}

interface IChartResponse {
  billingValues: IBillVal[];
}

interface IBillVal {
  month: number;
  year: number;
  totalCharges: number;
  usage: number;
  siteCount: number;
}

interface IMonthYear {
  month: number;
  year: number;
}
