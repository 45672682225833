import { Component, OnInit } from '@angular/core';
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import { SitesControl } from "../../shared/entities/controls";

import {I4cpPreferences, I4cpAlertHistory, I4cpMonths, I4cpSitePerformance} from "../../shared/entities/fourcp";
import {DataService} from "../../shared/data.service";
import {FourcpService} from "../fourcp.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {PortalService} from "../../shared/portal.service";

import {IUserAccount} from "../../shared/entities/profile";
import {IFourcpSite} from "../../shared/entities/site";
import {current} from "codelyzer/util/syntaxKind";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";



@Component({
  selector: 'app-fourcp-site-performance',
  templateUrl: './fourcp-site-performance.component.html',
  styleUrls: ['./fourcp-site-performance.component.scss'],
  animations: [FadeAnimation, FadeInAnimation],
  providers: [GenericPipe, OrderByPipe]
})
export class FourcpSitePerformanceComponent implements OnInit {

  startDate: string;
  selectedYear: number;
  asOfDate: string;
  showYearSelector: boolean;
  showSiteSelector: boolean;
  selectedCustomer: IUserAccount;
  fourCPsites: IFourcpSite[] = [];
  sitesForAccount: IFourcpSite[] = [];
  filteredSites: IFourcpSite[] = [];
  filteredSiteMatch: boolean;
  yearOptions: number[] = [];
  siteIndex: number = -1;
  siteSearch: string = '';
  selectedSite: IFourcpSite;
  selectedSiteDisplay: string;
  sitePerformance: I4cpSitePerformance;
  dataError: boolean = false;
  dataErrorText: string = '';

  accountSourceSubscription: Subscription;


  constructor(private dataService: DataService,
              private fourcpService: FourcpService,
              private portalService: PortalService,
              private genericPipe: GenericPipe,
              private orderByPipe: OrderByPipe) { }

  ngOnInit() {
    this.loadYearOptions();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
          (account) => {
            this.selectedCustomer = account;
            this.loadSiteOptions();
          }
        );
    this.selectedCustomer = this.dataService.getAccountSource();
    if(this.selectedCustomer){
      this.loadSiteOptions();
    }
  }

  ngOnDestroy() {
    if(this.accountSourceSubscription){
      this.accountSourceSubscription.unsubscribe();
    }
  }

  loadSiteOptions() {
    this.dataService.setLoading(true);
    this.fourcpService.getFourcpSites(this.selectedCustomer.id, this.selectedYear).subscribe(
      (fourCPsites) => {
        this.siteSearch = "";
        this.sitesForAccount = fourCPsites;
        this.searchSites();
        this.selectSite();
      },
      (error) => {
        this.dataError = true;
        this.dataErrorText = "No data available for selection";
        this.dataService.setLoading(false);
      }
    );
  }



  searchSites() {
    this.filteredSites = [];
    this.filteredSiteMatch = false;
    this.sitesForAccount.forEach((site) => {
      if(site.siteId.match(this.siteSearch) ||
        (site.name && site.name.toLowerCase().includes(this.siteSearch.toLowerCase())) ||
        this.siteSearch == ''){
        this.filteredSites.forEach((filteredSite) => {
          if(filteredSite.siteId.match(site.siteId)) {
            this.filteredSiteMatch = true;
          }
        });  
        if(this.filteredSiteMatch == false) {
          this.filteredSites.push(site);
        }
        this.filteredSiteMatch == false;
      }
    });

  }

  navigateTypeahead(down: boolean) {
    if(down && this.siteIndex < this.filteredSites.length-1){
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.siteSearch = "";
    this.searchSites();
  }

   selectSite(index?: number){
      if (this.filteredSites.length > 0) {
        this.dataService.setLoading(true);
        if (this.siteIndex == -1) {
          this.siteIndex = 0;
        }
        if (index) {
          this.selectedSite = this.filteredSites[index];
        } else {
          this.selectedSite = this.filteredSites[this.siteIndex];
        }
        this.getSelectedSiteDisplay();
        this.resetTypeahead();
        this.showSiteSelector = false;
        this.loadSitePerformance();
      }
  }

  loadSitePerformance(){
    this.fourcpService.loadSitePerformance(this.selectedCustomer.id, this.selectedSite, this.selectedYear).subscribe(
      (response : any) => {
        this.sitePerformance = response;
        this.formatSitePerformance();
        this.sortSitePerformance();
        this.dataService.setLoading(false);
        this.updateSelectedSite();
      },
      (error) => {
        this.dataError = true;
        this.dataErrorText = "No data available for selection";
        this.dataService.setLoading(false);
      });
  }

  updateSelectedSite() {
    this.selectedSite.tariff = this.sitePerformance.tariff;
  }
  formatSitePerformance() {
    if(this.sitePerformance && this.sitePerformance.percentFactor && this.sitePerformance.months.length == 0 || (this.sitePerformance.usageResponse.usage.length == 0)){
      this.dataError = true;
      this.dataErrorText = "No data available for selection";
    }
    //Per Dean 02/25/2020
    //else if (!this.sitePerformance || !this.sitePerformance.percentFactor){
    else if (!this.sitePerformance){
        this.dataError = true;
      this.dataErrorText = "No specific sites are enrolled in 4CP split savings services";
    }
    else {
      this.dataError = false;
      this.dataErrorText = "";
    }
  }

  sortSitePerformance() {
    this.sitePerformance.months = this.orderByPipe.transform(this.sitePerformance.months, 'intervalPeriod', true);
    this.sitePerformance.usageResponse.usage = this.orderByPipe.transform(this.sitePerformance.usageResponse.usage, 'date',true);
  }

  getSelectedSiteDisplay(){
    this.selectedSiteDisplay = '...' + this.selectedSite.siteId.substr(5);

  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  loadYearOptions() {
    let todayMoment = moment();

    let currentYear = parseInt(todayMoment.format('YYYY'));

    //Select default month and year
    let thisMonth = parseInt(todayMoment.format('M'));

    if(thisMonth >= 2){
      this.yearOptions.push((currentYear-1));
      currentYear = currentYear-1;
    }
    currentYear = currentYear-1;
    while(this.yearOptions.length <= 3 && currentYear > 2017){
      this.yearOptions.push(currentYear);
      currentYear = currentYear-1;
      console.log("currentYear: " + currentYear + ", yearOptions: " +this.yearOptions)
    }

    this.selectedYear = this.yearOptions[0];
  }

  selectYear(year: number){
    this.selectedYear = year;
    this.asOfDate = '02/01/' + (year + 1);
    this.toggleYearSelector();
    this.loadSiteOptions()
  }

  toggleYearSelector(){
    this.showYearSelector = !this.showYearSelector;
    if(this.showYearSelector){
      this.showSiteSelector = false;
    }
  }

  toggleSiteSelector(){
    this.showSiteSelector = !this.showSiteSelector;
    if(this.showSiteSelector){
      this.showYearSelector = false;
    }
  }

}
