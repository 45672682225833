import {Injectable, EventEmitter} from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from "@angular/common/http";
import { Node, NodeMap, Reference } from './aem';
import { YoutubePlayerService } from '../youtube-player/youtube-player-service';


@Injectable()
export class AEMHelpService {

  headers: HttpHeaders;
  references: Reference[];
  dynamicContentUrl: string;
  constructor(
    private httpClient: HttpClient,
    private youtubePlayerService : YoutubePlayerService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getHelpLandingPage() {
    let url = environment.getHelpLandingPage;
    return this.httpClient
      .get(url)
    //   .map((response: HttpResponse<any>) => {
    //     let contentResponse = this.mapToType(response, "Navigation");
    //     return contentResponse
    //   })
      .catch(this.handleError);
  }

  getPagesFromSearch(query : string){
    let url = environment.getPagesFromSearch.replace("{query}",query);
    return this.httpClient
    .get(url)
    .catch(this.handleError);
  }

  getHelpPage(path : string){
    let url = environment.getHelpPage.replace("{path}",path);
    return this.httpClient
    .post(url,path,{headers : this.headers})
    .catch(this.handleError);
  }

  startRenderNodeList(nodes : Node[], references: Reference[], dynamicUrl: string ) {
    this.dynamicContentUrl = dynamicUrl;
    if(references){
      this.references = references;
    }
    if(!nodes) {
        // null check
        return null;
    }
    return nodes.map(node => this.renderNode(node)).join('');
  }

  // Recursively Renders the nodes in the JSON
  renderNodeList(nodes : Node[] ) {
    if(!nodes) {
        // null check
        return null;
    }
    return nodes.map(node => this.renderNode(node)).join('');
  }

  // renderNode - renders an individual node
  renderNode(node: Node): string {
    // If the current node has children, recursively process them
    const children = node.content ? this.renderNodeList(node.content) : null;
  
    // Use the nodeMap to render the current node based on its nodeType
    return this.nodeMap[node.nodeType] ? this.nodeMap[node.nodeType](node, children) : null;
  }

  nodeMap: NodeMap = {
    'header': (node, children, style) => this.renderHeader(node,children),
    'paragraph': (node, children) => `<p>${children}</p>`,
    'text': node => this.renderTextNode(node),
    'link': node => this.defaultRenderLink(node),
    'unordered-list': (node, children) => `<ul>${children}</ul>`,
    'ordered-list': (node, children) => `<ol>${children}</ol>`,
    'list-item': (node, children) => `<li>${children}</li>`,
    'table': (node, children) => `<table>${children}</table>`,
    'table-body': (node, children) => `<tbody>${children}</tbody>`,
    'table-row': (node, children) => `<tr>${children}</tr>`,
    'table-data': (node, children) => `<td>${children}</td>`,
    'reference': (node) => this.defaultRenderReference(node),
  };

  // const defaultHeaderStyle = {
  //   'h1': (node, children) => `<h1>`{children}`</h1>`,
  //   'h2': (node, children) => `<h2>`{children}`</h2>`,
  //   'h3': (node, children) => `<h3>`{children}`</h3>`
  // }
  renderHeader(node, children) {
    let renderedText = children;
    switch(node.style){
      case "h1":
        return `<h1>${renderedText}</h1>`
      case "h2":
        return `<h2>${renderedText}</h2>`
      case "h3":
        return `<p class="numbered-direction">${renderedText}</p>`
    }
    return renderedText;
  }

  defaultRenderReference(node) {
    const mimeType = node.data.mimetype;

    // If it is a Fragment Reference, we have this fragment to insert images with special dimensions
    if(node.data.type){
      let imageData = this.findImageInfoByPath(this.references, node.data.href);
      let imageSourceUrl = this.dynamicContentUrl + imageData.imagePath;
      return `<img src="${imageSourceUrl}" alt="reference-${imageData.imagePath}" width="${imageData.imageWidth}" height="${imageData.imageHeight}"/>`
    }

    // Image Asset Reference
    if(mimeType && mimeType.startsWith('image')) {
      let imageSourceUrl = this.dynamicContentUrl + node.data.path;
      return `<img src="${imageSourceUrl}" alt="reference"  />`
    }
    return null;
  }
  
  renderTextNode(node){
    if(node.format){
      let renderedText = node.value;
      let variants : string[] = node.format.variants;
      if(variants.includes('bold')){
        renderedText = `<b>` + renderedText + `</b>`;
      }
      if(variants.includes('italic')){
        renderedText = `<i>` + renderedText + `</i>`;
      }
      if(variants.includes('underline')){
        renderedText = `<u>` + renderedText + `</u>`;
      }
      return renderedText;
    }
    return node.value || '';
  }
  defaultRenderLink(node) {
    if (this.isYouTubeLink(node.data.href)) {
      this.youtubePlayerService.setVideoId(this.getYouTubeVideoId(node.data.href));
      return `<a href=${node.data.href} target=${node.data.target}>${node.value}</a>`;
    } else {
      return `<a href=${node.data.href} target=${node.data.target}>${node.value}</a>`;
    }
  }
  
  findImageInfoByPath(references: Reference[], inputPath: string): { imageWidth: string, imageHeight:string, imagePath: string } | null {
    for (const reference of references) {
        if (reference._path === inputPath) {
          let imageWidth;
          let imageHeight;
          switch (reference.imageSize) {
            case "extra-small":
                imageWidth = 500;
                imageHeight = 300;
                break;
            case "small":
                imageWidth = 800;
                imageHeight = 600;
                break;
            case "medium":
                imageWidth = 1200;
                imageHeight = 900;
                break;
            case "large":
                imageWidth = 1600;
                imageHeight = 1200;
                break;
            case "extra-large":
                imageWidth = 2000;
                imageHeight = 1500;
                break;
            default:
                break;
          }
            return {
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                imagePath: reference.image._path,
            };
        }
    }
    return null;
}

  isYouTubeLink(url: string): boolean {
    // Implement your logic to detect YouTube links
    // Example logic: Check if the URL contains 'youtube.com' or 'youtu.be'
    return url.includes('youtube.com') || url.includes('youtu.be');
  }

  getYouTubeVideoId(link: string): string | null {
    const url = new URL(link);
  
    if (url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com') {
      const searchParams = new URLSearchParams(url.search);
      return searchParams.get('v');
    } else if (url.hostname === 'youtu.be') {
      let videoId = url.pathname.substr(1);
      return videoId;
    }
  
    return null; // Not a valid YouTube link
  }
  

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
