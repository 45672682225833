import { Injectable } from '@angular/core';
import {Response, Headers, Http} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {ISiteGroup, ISite} from '../shared/entities/site';

import {environment} from '../../environments/environment';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/combineLatest';
import {DataService} from "../shared/data.service";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";

@Injectable()
export class SiteGroupService {

  private headers: HttpHeaders;
  selectedGroup: ISiteGroup;

  constructor(private http: HttpClient,
              private dataService: DataService,
              private oldHttp: Http) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  setSelectedGroup(group: ISiteGroup) {
    this.selectedGroup = group;
  }

  getSelectedGroup() {
    return this.selectedGroup;
  }

  // Get contact information by account ID
  getSiteGroups(accountId: string): Observable<any> {
    let userSiteGroups = this.getUserSiteGroups(accountId);
    let systemSiteGroups = this.getSystemSiteGroups(accountId);
    return userSiteGroups.combineLatest(systemSiteGroups,
      (userSiteGroups, systemSiteGroups) => {
      let resp = {
        userSiteGroups: userSiteGroups,
        systemSiteGroups: systemSiteGroups
      }
        return resp;
      });
  }

  getUserSiteGroups(accountId: string): Observable<ISiteGroup[]> {
    let url = environment.production ? environment.getUserSiteGroupsUrl.replace('{accountId}', accountId.toString()) : environment.getUserSiteGroupsUrl;
    return this.http
      .get(url).pipe(
        map((data: ISiteGroup[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    // return this.http
    //   .get(url)
    //   .map((response: HttpResponse<ISiteGroup[]>) => response)
    //   .catch(this.handleError);
  }

  getSystemSiteGroups(accountId: string): Observable<ISiteGroup[]> {
    let url = environment.production ? environment.getSystemSiteGroupsUrl.replace('{accountId}', accountId.toString()) : environment.getSystemSiteGroupsUrl;
    return this.http
    .get(url).pipe(
      map((data: ISiteGroup[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getSitesForUserGroup(accountId: string, groupId: number): Observable<ISite[]> {
    let url = environment.getSitesForUserGroupUrl;
    if(environment.production){
      url = url.replace('{accountId}', accountId.toString()).replace('{groupId}', groupId.toString());
    }
    return this.http
      .get(url).pipe(
        map((data: ISite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getSitesForSystemGroup(accountId: string, groupName: string, frequencyType?: string): Observable<ISite[]> {
    let url = environment.getSitesForSystemGroupUrl;
    if(environment.production){
      url = url.replace('{accountId}', accountId.toString()).replace('{groupName}', groupName.toString());
      if(frequencyType){
        url += frequencyType;
      }
    }
    return this.http
      .get(url).pipe(
        map((data: ISite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getSitesForSystemGroupLessReliability(accountId: string, groupName: string, frequencyType?: string): Observable<ISite[]> {
    let url = environment.getSitesForSystemGroupLessReliabilityUrl;
    if(environment.production){
      url = url.replace('{accountId}', accountId.toString()).replace('{groupName}', groupName.toString());
      if(frequencyType){
        url += frequencyType;
      }
    }
    return this.http
    .get(url).pipe(
      map((data: ISite[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  createOrUpdateSiteGroup(group: ISiteGroup ): Observable<ISiteGroup> {
    let url = environment.createOrUpdateSiteGroupUrl.replace('{accountId}', group.accountId.toString());
    if(group.id){
      url = url.replace('{groupId}', group.id.toString());
    } else {
      url = url.replace('{groupId}', '0'); // Don't ask questions
    }
    return this.http
        .post(url,group, {headers : this.headers}).pipe(
          map((data: ISiteGroup)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }


  deleteSiteGroup(group: ISiteGroup): Observable<any> {
    let url = environment.deleteSiteGroupUrl.replace('{accountId}', group.accountId.toString()).replace('{groupId}', group.id.toString());
    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  getCategoriesForUserGroup(groupId: number) {
    if(environment.production){
      let accountId = this.dataService.getAccountSource().id; // do this
      let url = environment.production ? environment.getCategoriesForUserGroupUrl.replace('{accountId}', accountId.toString()).replace('{groupId}', groupId.toString()) : environment.getCategoriesForUserGroupUrl;
      return this.http
        .get(url)
        .map((response: HttpResponse<ISiteGroup[]>) => response)
        .catch(this.handleError);
    }
  }

  getCategoriesForSystemGroup(groupName: string, startDate?: string, endDate?: string){
    if(environment.production){
      let accountId = this.dataService.getAccountSource().id;
      let url = environment.production ? environment.getCategoriesForSystemGroupUrl.replace('{accountId}', accountId.toString()).replace('{groupName}', groupName) : environment.getCategoriesForUserGroupUrl;
      if(startDate && endDate){
        url += '?startDate=' + startDate + '&endDate=' + endDate;
      }
      return this.http
        .get(url)
        .map((response: HttpResponse<ISiteGroup[]>) => response)
        .catch(this.handleError);
    }
  }

  updateSiteCategory(body: any, groupId: number, accountId: string): Observable<ISite> {
    let url = environment.updateSiteCategoryUrl.
              replace('{accountId}', accountId.toString()).
              replace('{groupId}', groupId.toString()).
              replace('{siteId}', body.siteGroupKey.siteId);
              return this.http
        .post(url,body, {headers : this.headers}).pipe(
          map((data: ISite)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
