import {IUsageReportReponse} from '../shared/entities/usage-reports';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {PortalService} from '../shared/portal.service';
import {DataService} from '../shared/data.service';
import {DatePeriods} from '../shared/const/datePeriods';

@Injectable()
export class UsageReportUtility {

  constructor(private portalService: PortalService,
    private dataService: DataService){}

  getColumnNamesString(columns: any) {
    let str = '';
    columns.forEach((section) => {
      section.columns.forEach((col)=> {
        if(col.checked){
          str += col.value + ','
        }
      })
    });
    return str;
  }

  formatCell(data: any, col: string) {
    if(data){
      if(col == 'totalCurrentUsage' || col == 'averageDailyUsage' || col == 'peakDemand' && data[col] != undefined && data[col] != null) {
        if(data[col].toFixed(0) == 0) {
          return '--'
        };
        return this.numbersWithCommas(data[col].toFixed(0));
      } else if (col == 'loadFactor') {
        return data[col] ? this.numbersWithCommas(data[col].toFixed(1)) + '%' : '';
      } else if (col == 'peakDemandHour') {
        return data[col] ? moment(data[col]).format('MM/DD/YY HH:mm') + ' CST' : '';
      } else if (col.toLowerCase().match('date')) {
        if(col == 'maxAvailableDate' && !data[col] && !data['categories']){
          return '';
        }
        return data[col] ? moment(data[col]).format('MM/DD/YY') : '';
      } else if (col == 'serviceAddress') {
        return data['serviceStreetAddress'] ? data['serviceStreetAddress'] + '...' : '';
      } else if (col == 'billingAddress') {
        return data['billingStreetAddress'] ? data['billingStreetAddress'] + '...' : '';
      } else {
        return data[col];
      }
    }
  }

  numbersWithCommas(x) {
    return this.portalService.numberWithCommas(x);
  }

}
