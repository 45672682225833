import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'routing'
})
export class RoutingPipe implements PipeTransform {

  transform(value: number): String {
    return "XXXXX" + value.toString().slice(-4);
  }
}
