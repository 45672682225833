export const UsageReportColumns = [
  {
    name: 'General Information',
    columns: [
      {display: 'Last Updated', value: 'maxAvailableDate'},
      {display: 'Usage Start Date', value: 'meterReadStartDate'},
      {display: 'Usage End Date', value: 'meterReadEndDate'},
      {display: 'TOTAL USAGE (kWh)', value: 'totalCurrentUsage'},
      {display: 'AVERAGE DAILY USAGE (kWh)', value: 'averageDailyUsage'},
      {display: 'Peak Hourly Demand (KW)', value: 'peakDemand'},
      {display: 'Peak Hourly Demand (Period Ending)', value: 'peakDemandHour'},
      {display: 'Load Factor (%)', value: 'loadFactor'},
      {display: 'Site Name', value: 'siteName'},
      {display: 'Account #', value: 'contractAccountNum'},
      {display: 'Service Address', value: 'serviceAddress'},
      {display: 'Billing Address', value: 'billingAddress'}
    ]
  },
  {
    name: 'Site Enrollment Details',
    columns: [
      {display: 'Site Status', value: 'marketStatus'},
      {display: 'Move In Date', value: 'marketMoveInDate'},
      {display: 'Move Out Date', value: 'marketMoveOutDate'},
      {display: 'Meter Type', value: 'frequencyType'},
      {display: 'TDSP/EDC', value: 'utility'},
      {display: 'Market', value: 'market'}
    ]
  }
];
