import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {IUserAccount} from "../../shared/entities/profile";
import {Subscription} from "rxjs";
import {AmendmentsService} from "../amendments.service";
import {IAmendmentHistoryRequest, IAmendmentRequest, IAmendmentSite} from "../../shared/entities/amendments";
import {Router} from "@angular/router";
import {AmendmentsPipe} from "../../shared/pipe/amendments.pipe";
import * as moment from 'moment';
import {FadeAnimation} from "../../animations/fadeAnimation";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {PortalService} from "../../shared/portal.service";
import {Permissions} from "../../shared/const/permissions";
import {Authority} from "../../shared/const/authority";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {IAccount} from "../../shared/entities/account";
import {IContract} from "../../shared/entities/contract";
declare var $:any;


@Component({
  selector: 'app-amendments-home',
  templateUrl: './amendments-home.component.html',
  styleUrls: ['./amendments-home.component.scss', '../amendments.component.scss'],
  animations: [FadeAnimation],
  providers: [AmendmentsPipe, OrderByPipe]
})
export class AmendmentsHomeComponent implements OnInit {

  myAccount: IUserAccount;
  myContractsAccount: IAccount;
  contractsForAccount: IContract[];  
  recentAmendmentRequests: IAmendmentRequest[];
  emptyAmendmentRequests: IAmendmentRequest[];
  filteredAmendmentRequests: IAmendmentRequest[];
  searchTerm: string;
  showSearchRow: boolean;
  amendmentRequestToDelete: IAmendmentRequest;
  isMobile: boolean = false;
  ascending: boolean = false;
  sortColumn: string = 'createdOn';
  isSuperUser: boolean = false;
  helpStep: IHelpStep;
  accountSubscription: Subscription;
  exportSubscription: Subscription;
  selectedAmendmentSubscription: Subscription;
  helpStepSubscription: Subscription;
  hasAmendmentPermissions: boolean = true;

  startBoolean: boolean = false;


  constructor(private dataService: DataService,
              private portalService: PortalService,
              private amendmentsService: AmendmentsService,
              private router: Router,
              private amendmentsPipe: AmendmentsPipe,
              private orderByPipe: OrderByPipe) { }

  ngOnInit() {
    this.dataService.setTitleSource('Submit Request ');
    this.dataService.setSelectedNavItem('accounts');
    this.setCurrentActivePage('accounts');
    if(this.dataService.getAppProperties()){
      // this.dataService.setLoading(true); // add in after creating endpoint
    }
    this.isMobile = this.dataService.getIsMobile();
    this.myAccount = this.dataService.getAccountSource();

    if(this.myAccount){
      this.getRecentAmendmentRequests();
      console.log('first');
      this.accountUpdated();
      this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser) || !this.dataService.getAppProperties();
      this.loadSelectedRequestFromAmendmentHistory();
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
      if(this.myAccount.accessType != 'ThirdParty') {
        this.getRecentAmendmentRequests();
        console.log('second');
        this.accountUpdated();
        this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser) || !this.dataService.getAppProperties();
        this.loadSelectedRequestFromAmendmentHistory()
      }
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.triggerExport();
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
    this.hasAmendmentPermissions = this.portalService.hasPermission(Permissions.SubmitAmendmentRequest) || this.portalService.userHasAuthority(Authority.SuperUser);

    this.selectedAmendmentSubscription = this.dataService.selectedAmendmentRequestUpdated.subscribe(
      ()=> {
        this.getRecentAmendmentRequests();
      }
    );
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.selectedAmendmentSubscription) this.selectedAmendmentSubscription.unsubscribe()
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  triggerExport() {
    this.dataService.setLoading(true);
    this.amendmentsService.exportRecentAmendments();
  }

  loadSelectedRequestFromAmendmentHistory() {
    let selectedRequest = this.dataService.getSelectedAmendmentRequest();
    if(selectedRequest && selectedRequest.origin == 'AMENDMENT_HISTORY') {
      this.amendmentsService.getAllAmendmentRequests(this.myAccount.id).subscribe((requests : any) => {
        requests.forEach((req) => {
          if(req.id == selectedRequest.id){
            this.editAmendmentRequest(req);
          }
        });
      });
    }
  }

  getRecentAmendmentRequests(){
    this.amendmentsService.getRecentAmendmentRequests(this.myAccount.id).subscribe(
      (recentAmendmentRequests : any) => {
        this.recentAmendmentRequests = recentAmendmentRequests;
        this.formatRecentAmendmentRequests();
        this.filterAmendmentRequests();
        this.loadStatuses();
        this.dataService.setLoading(false);
        this.recentAmendmentRequests.forEach(
          (request) => {
            this.amendmentsService.updateRequestTypes(request);
          });
      });
  }

  loadStatuses() {
    let historyReq: IAmendmentHistoryRequest = {
      accountId: this.myAccount.id,
      startDate: moment().subtract(3, 'years').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    };
    let allSubmitted, anyRejected, historyExists;
    this.amendmentsService.getAmendmentHistory(historyReq).subscribe((history : any) => {
      this.recentAmendmentRequests.forEach((amendmentRequest : any) => {
        allSubmitted = true;
        anyRejected = false;
        historyExists = false;
        history.forEach((row) => {
          if(row.requestId == amendmentRequest.id){
            historyExists = true;
            if(!row.amendmentStatus.toLowerCase().match('submitted')) {
              allSubmitted = false;
            }
            if(row.amendmentStatus.toLowerCase().match('rejected')) {
              anyRejected = true;
            }
          }
        });
        if(historyExists && anyRejected) {
          amendmentRequest.statusFormatted = 'Rejected';
        } else if (historyExists && allSubmitted){
          amendmentRequest.statusFormatted = 'Accepted';
        }
      });
      this.filterAmendmentRequests();
    });
  }

  formatRecentAmendmentRequests(){
    this.recentAmendmentRequests.forEach((amendmentRequest) => {
      amendmentRequest.submittedOnFormatted = amendmentRequest.submittedOn? moment(amendmentRequest.submittedOn).format("M/D/YYYY") : "-------";
      amendmentRequest.requestTypeFormatted = this.formatRequestType(amendmentRequest.requestType);
      amendmentRequest.statusFormatted = this.formatStatus(amendmentRequest.status);
      amendmentRequest.createdOnFormatted = moment(amendmentRequest.createdOn).format("M/D/YYYY");
      if (amendmentRequest.amendmentSites) {
        amendmentRequest.amendmentSites = this.orderByPipe.transform(amendmentRequest.amendmentSites, 'siteId', true);
        amendmentRequest.amendmentSites.forEach((amendmentSite) => {
          amendmentSite.amendmentTypeFormatted = this.formatRequestType(amendmentSite.amendmentType);
          amendmentSite.displayAddress = this.formatDisplayAddress(amendmentSite);
          amendmentSite.siteId = this.formatSiteId(amendmentSite.siteId);
        });
      }
    })
  }

  formatSiteId(siteId){
    if(siteId){
      let index = siteId.indexOf('-');
      if(index > 0){
        return siteId.substring(index+1);
      }
    }
    return siteId;
  }

  formatRequestType(requestType){
    if(requestType) {
      if (requestType.toLowerCase() == "add") {
        return "Add";
      }
      if (requestType.toLowerCase() == "delete") {
        return "Delete";
      }
      else {
        return "Add & Delete";
      }
    }
    else {
      return "-";
    }
  }

  formatStatus(status){
    if(status){
      if(status.toLowerCase() == 'draft'){
        return "Draft";
      }
      if(status.toLowerCase() == 'submitted'){
        return "Under Review";
      }
      if(status.toLowerCase() == 'accepted'){
        return "Accepted";
      }
      if(status.toLowerCase() == 'rejected'){
        return "Rejected";
      }
    }
    else {
      return "-";
    }
  }

  formatDisplayAddress(amendmentSite: IAmendmentSite){
    if(amendmentSite.siteAddress && amendmentSite.siteAddress.length > 0 &&
      amendmentSite.siteCity && amendmentSite.siteCity.length > 0 &&
      amendmentSite.siteState && amendmentSite.siteState.length > 0 &&
      amendmentSite.siteZip && amendmentSite.siteZip.length > 0){
      return amendmentSite.siteAddress + ", " + amendmentSite.siteCity +
          " " + amendmentSite.siteState.toUpperCase() + " " + amendmentSite.siteZip;
    }
    return "-------";
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow
    this.searchTerm = '';
    this.filterAmendmentRequests();
  }

  filterAmendmentRequests(){
    if(!this.searchTerm){
      this.filteredAmendmentRequests = this.recentAmendmentRequests;
    } else {
      this.filteredAmendmentRequests = this.amendmentsPipe.transform(this.recentAmendmentRequests, this.searchTerm);
    }
    this.sortAmendments(this.sortColumn);
  }

  startRequest(){
    if(this.myAccount){
        let emptyAmendmentRequest = this.amendmentsService.getEmptyAmendmentRequest(this.myAccount.id);
        emptyAmendmentRequest.accountId = this.myAccount.id;
        this.dataService.setSelectedAmendmentRequest(emptyAmendmentRequest);
    }
    this.router.navigate(['/amendments/terms']);
    //this.router.navigate(['/amendments/start'])
  }

  editAmendmentRequest(amendmentRequest: IAmendmentRequest){
    this.dataService.setSelectedAmendmentRequest(amendmentRequest);
    this.router.navigate(['/amendments/cart']);
  }

  toggleSort(sortColumn: string) {
    this.ascending = !this.ascending;
    this.sortAmendments(sortColumn);
  }

  sortAmendments(sortColumn: string) {
    this.sortColumn = sortColumn;
    this.filteredAmendmentRequests = this.orderByPipe.transform(this.filteredAmendmentRequests, sortColumn, this.ascending);
    //this.paginate(1);
  }

  viewAmendmentRequest(amendmentRequest: IAmendmentRequest){
    this.dataService.setSelectedAmendmentRequest(amendmentRequest);
    this.router.navigate(['/amendments/confirmation']);
  }

  deleteAmendmentRequest(){
    this.amendmentsService.deleteAmendmentRequest(this.amendmentRequestToDelete).subscribe(
      (response) => {
        let tempRequestList = [];
        this.recentAmendmentRequests.forEach((amendmentRequest) => {
          if(this.amendmentRequestToDelete.id != amendmentRequest.id){
            tempRequestList.push(amendmentRequest);
          }
        });
        this.recentAmendmentRequests = tempRequestList;
        this.filterAmendmentRequests();
      });
  }

  downloadAmendmentsForm() {
    this.amendmentsService.downloadAmendmentsForm(this.myAccount.id);
  }


  goToHistory(){
    this.router.navigate(['/amendments/history']);
  }


  goToContracts() {
    this.dataService.setLoading(true);
    this.router.navigate(['/contracts'])
    $('#createModal').modal('hide');
  }


  
accountUpdated() {
  // console.log('inside new finding status');
 this.dataService.setLoading(true); 
 this.contractsForAccount = this.dataService.getContractsForAccount();   
     if(this.contractsForAccount){
       this.contractsForAccount.forEach((contract) => {
        // console.log('inside new details',contract);
         if(contract.status == 'Active' || contract.status == 'Future Start'){
            // console.log('status' + contract.status);
            this.startBoolean = true;
         }
       });
     }    
 
}



// accountUpdated() {
//      console.log('inside finding status');
//     this.dataService.setLoading(true);
//     this.amendmentsService.getContractsForAccount(this.myAccount.id).subscribe(
//       (mycontractsaccount) => {
//         this.myContractsAccount = mycontractsaccount;
//         this.dataService.setAccountWithContractDataSource(mycontractsaccount);
//         if(this.myContractsAccount.contracts){
//           this.myContractsAccount.contracts.forEach((contract) => {
//             if(contract.status == 'Active' || contract.status == 'Future Start'){
//                console.log('status' + contract.status);
//                this.startBoolean = true;
//             }
//           });
//         }
//       }
//     );
//   }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

}
