// For managing application data and sharing data between components

import { Injectable, EventEmitter } from '@angular/core';
import { IAccount, IAccountMessage } from './entities/account';
import {
  IUserAccount,
  IProfile,
  IUserProfileAccount
} from './entities/profile';
import { ISite } from '../shared/entities/site';
import { IError } from './entities/error';
import { IContract } from './entities/contract';
import { ISiteCounts } from './entities/usageGraph';
import { IAccountBalance } from '../payments/payments';
import { IUser } from './entities/user';
import { IAmendmentRequest, IAmendmentSite } from './entities/amendments';
import { IAttachment } from './entities/attachment';
import { ILocalLoader } from './entities/localLoader';
import { I4cpPreferences } from './entities/fourcp';
import { IHelpStep } from './entities/contextualHelp';
import { IGenerationSite } from '../shadow-metering/generation-site';
import { IMarketState } from './entities/marketState';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ERCOTDemandComparisonOptions,
  ERCOTInfoComparisonOptions,
  ERCOTSnapshotComparisonOptions,
  Market,
  MarketDashboardView,
  MarketDemandComparison,
  MarketGraphComparison
} from './entities/markets';
import { IMarketSetting } from './entities/marketSetting';

@Injectable()
export class DataService {
  private appProperties: any;
  private marketStates: IMarketState[];
  private userSource: IUser;
  private accountSource: IUserAccount;
  private thirdPartyAccountsSource: IUserAccount[];
  private accountsForUserSource: IUserAccount[];
  private accountWithContractDataSource: IAccount;
  private permissionsMaskSource: number;
  private selectedNavItem: string;
  private currentActivePageSource = new BehaviorSubject<string>('dashboard');
  private siteGroupsSource: any;
  private contractsForAccountSource: IContract[];
  private siteCountsSource: ISiteCounts;
  private sitesForAccountSource: ISite[];
  private balanceDue: IAccountBalance;
  private titleSource: string;
  private shortMessage: string;
  private selectedUserAccountSource: any;
  private reportSubscriptionDateRangesSource: any[];
  private loading: boolean;
  private loadingText: string;
  private loggedIn: boolean;
  private profileSource: IProfile;
  private profileImage: string;
  private forgotPassword: boolean;
  private passwordResetEmailSent: boolean;
  private forgotEmail: boolean;
  private incorrectLogin: boolean;
  private resetPassword: boolean;
  private resetPasswordFlag: boolean;
  private createPassword: boolean;
  private selectedBalances: ISite[];
  private paymentTotal: string;
  private paymentDate: Date;
  private printing: boolean;
  private timedOut: boolean;
  private isMobile: boolean;
  private isLocal: boolean;
  private emailPassword: boolean;
  private dashboardSize: number;
  private overlay: boolean;
  private maskMode: boolean;
  private fakeAccountName: string;
  private selectedAmendmentRequest: IAmendmentRequest;
  private selectedAmendmentSite: IAmendmentSite;
  private showAmendmentsHeader: boolean;
  private currentAttachments: IAttachment[];
  private accessDenied: boolean;
  private localLoader: ILocalLoader[] = [];
  private fourcpPreferences: I4cpPreferences;
  private helpStep: IHelpStep;
  private selectedMetricsPage: string;
  private selectedProfileTab: string;
  private tpGroupSource: any[];
  private marketsArray: string[];
  private marketValue: string;
  private nccaFlag: boolean = false;
  private ncaFlag: boolean = false;
  private isShadow: boolean;
  private showCreditCard: boolean;
  private showAEMHelp: boolean;
  private showBillingManagement: boolean;
  private isLargCustomer: boolean;
  // private showBankAccount:boolean;
  private autopay: boolean;
  private showMBP: boolean;
  private _showMarketDashboard: boolean;
  private hasCCNotice: boolean = false;
  private selfRegistration: boolean;
  private quickPay: boolean;
  private scsCustomer: boolean;
  private _isMarketDBFullscreen: boolean = false;
  private hideErcotExport: boolean;
  private _openMDBSaveOptions: boolean = false;
  private _openMDBViewOptions: boolean = false;
  private _defaultMarketViewName: string = null;
  private _selectedMarketViewName: string = null;
  private _selectedMarket: string = Market.ERCOT;
  private _showMarketDBlegend: boolean = true;
  private accountSpecificMessage: IAccountMessage;
  private manageSubscriptionflag: string;
  private hSurvey: boolean = true;
  private selectedMarketReportsTab: string;
  private outputPage = false;
  private positionReportDate: Date;
  private billingUsageReportDate: Date;
  private editorialContentRoute: string;
  private userProfileAccounts: IUserProfileAccount[];
  private isProfileDataLoaded: boolean = false;
  private isPricingTcEnabled: boolean = false;
  private dgenSites: IGenerationSite[];
  private tealiumData: any;
  private orginUrl: string;
  private _dbMarketDemandsSettings: IMarketSetting[] = [];
  private _dbInfoMarketGraphSettings: IMarketSetting[] = [];
  private _dbSnapshotMarketGraphSettings: IMarketSetting[] = [];
  private _dbPjmYourListSettings: IMarketSetting[] = [];
  private _dbPjmDaYourListSettings: IMarketSetting[] = [];
  private _newDbMarketDemandsSettings: IMarketSetting[] = [];
  private _newDbInfoMarketGraphSettings: IMarketSetting[] = [];
  private _newDbSnapshotMarketGraphSettings: IMarketSetting[] = [];
  private _newDbPjmYourListSettings: IMarketSetting[] = [];
  private _newDbPjmDaYourListSettings: IMarketSetting[] = [];
  private walkMeAuthToken: any;
  private _marketViews: MarketDashboardView[] = [];

  //FOR DEMO
  private inSeason: boolean = true;
  inSeasonUpdated = new EventEmitter<boolean>();

  saveDashBoardModalTriggered = new EventEmitter<{
    openModal: boolean;
    name: string;
    isRenamed: boolean;
  }>();
  deleteDashBoardModalTriggered = new EventEmitter<void>();
  emitOpenDBSettingsSaveOptions = new EventEmitter<void>();
  userSourceUpdated = new EventEmitter<IUser>();
  accountSpecificMessageUpdated = new EventEmitter<IAccountMessage>();
  accountSourceUpdated = new EventEmitter<IUserAccount>();
  thirdPartyAccountsSourceUpdated = new EventEmitter<IUserAccount[]>();
  accountsForUserSourceUpdated = new EventEmitter<IUserAccount[]>();
  accountWithContractDataSourceUpdated = new EventEmitter<IAccount>();
  permissionsMaskSourceUpdated = new EventEmitter<number>();
  selectedNavItemSourceUpdated = new EventEmitter<string>();
  currentActivePage = this.currentActivePageSource.asObservable();
  siteGroupsSourceUpdated = new EventEmitter<any>();
  contractsForAccountSourceUpdated = new EventEmitter<IContract[]>();
  siteCountSourceUpdated = new EventEmitter<ISiteCounts>();
  sitesForAccountSourceUpdated = new EventEmitter<ISite[]>();
  balanceDueUpdated = new EventEmitter<IAccountBalance>();
  titleSourceUpdated = new EventEmitter<string>();
  shortMessageUpdated = new EventEmitter<string>();
  selectedUserAccountSourceUpdated = new EventEmitter<any>();
  reportSubscriptionDateRangesSourceUpdated = new EventEmitter<any>();
  loadingUpdated = new EventEmitter<boolean>();
  loadingTextUpdated = new EventEmitter<string>();
  loggedInUpdated = new EventEmitter<boolean>();
  profileImageUpdated = new EventEmitter<string>();
  profileUpdated = new EventEmitter<IProfile>();
  forgotPasswordUpdated = new EventEmitter<boolean>();
  passwordResetEmailSentUpdated = new EventEmitter<boolean>();
  forgotEmailUpdated = new EventEmitter<boolean>();
  exportTriggered = new EventEmitter<any>();
  sessionAccountUpdated = new EventEmitter<any>();
  realTimeDataReportTriggered = new EventEmitter<any>();
  incorrectLoginUpdated = new EventEmitter<boolean>();
  resetPasswordUpdated = new EventEmitter<boolean>();
  hideErcotExportUpdated = new EventEmitter<boolean>();
  resetPasswordFlagUpdated = new EventEmitter<boolean>();
  createPasswordUpdated = new EventEmitter<boolean>();
  selectedBalancesUpdated = new EventEmitter<ISite[]>();
  paymentTotalUpdated = new EventEmitter<string>();
  paymentDateUpdated = new EventEmitter<Date>();
  positionReportDateUpdated = new EventEmitter<Date>();
  billingUsageReportDateUpdated = new EventEmitter<Date>();
  ReportDateUpdated = new EventEmitter<Date>();
  printingUpdated = new EventEmitter<boolean>();
  timedOutUpdated = new EventEmitter<boolean>();
  addWidgetTriggered = new EventEmitter<any>();
  errorAdded = new EventEmitter<IError>();
  emailPasswordUpdated = new EventEmitter<boolean>();
  dashboardSizeUpdated = new EventEmitter<number>();
  overlayUpdated = new EventEmitter<boolean>();
  maskModeUpdated = new EventEmitter<boolean>();
  fakeAccountNameUpdated = new EventEmitter<string>();
  selectedAmendmentRequestUpdated = new EventEmitter<IAmendmentRequest>();
  selectedAmendmentSiteUpdated = new EventEmitter<IAmendmentSite>();
  showAmendmentsHeaderUpdated = new EventEmitter<boolean>();
  requestTriggered = new EventEmitter<any>();
  multipleInvoiceTriggered = new EventEmitter<any>();
  realTimeExportTriggered = new EventEmitter<any>();
  currentAttachmentsUpdated = new EventEmitter<IAttachment[]>();
  accessDeniedUpdated = new EventEmitter<boolean>();
  localLoaderUpdated = new EventEmitter<ILocalLoader>();
  fourcpPreferencesUpdated = new EventEmitter<I4cpPreferences>();
  helpStepUpdated = new EventEmitter<IHelpStep>();
  selectedMetricsPageUpdated = new EventEmitter<string>();
  contextualHelpToggle = new EventEmitter<any>();
  contextualMatrixBrokerPricingToggle = new EventEmitter<any>();
  subscriptionValidity = new EventEmitter<any>();
  contextualHelpEnabled = new EventEmitter<boolean>();
  usersUpdatedTriggered = new EventEmitter<any>();
  toggleSubscriptionModalTrigger = new EventEmitter<any>();
  selectedProfileTabUpdated = new EventEmitter<string>();
  tpGroupSourceUpdated = new EventEmitter<any>();
  nccaFlagUpdated = new EventEmitter<boolean>();
  largeCustUpdated = new EventEmitter<boolean>();
  ncaFlagUpdated = new EventEmitter<boolean>();
  showCreditCardUpdated = new EventEmitter<boolean>();
  showAEMHelpUpdated = new EventEmitter<boolean>();
  showBillingManagementUpdated = new EventEmitter<boolean>();
  // showBankAccountUpdated = new EventEmitter<boolean>();
  showAutopayUpdated = new EventEmitter<boolean>();
  selectedMarketReportUpdated = new EventEmitter<string>();
  outputPageTriggered = new EventEmitter<boolean>();
  pricingPageTriggered = new EventEmitter<boolean>();
  pchbePageTriggered = new EventEmitter<boolean>();
  profileDataLoaded = new EventEmitter<boolean>();
  readonly showGenerationDetailExport = new EventEmitter<boolean>();
  readonly generationDetailExportTriggered = new EventEmitter<any>();
  readonly showExportUsageDetail = new EventEmitter<boolean>();
  dgenSitesUpdated = new EventEmitter<IGenerationSite[]>();
  selfRegistrationUpdated = new EventEmitter<boolean>();
  quickPayUpdated = new EventEmitter<boolean>();
  tealiumDataUpdated = new EventEmitter<any>();
  marketChartLegendToggle = new EventEmitter<any>();
  defaultMarketViewChanged: EventEmitter<string> = new EventEmitter();
  selectedMarketViewChanged: EventEmitter<any> = new EventEmitter();

  marketDemandSettings$: Observable<MarketDemandComparison[]>;
  private _marketDemandSettingsSubject$: BehaviorSubject<
    MarketDemandComparison[]
  >;
  marketSnapShotGenMixSettings$: Observable<MarketGraphComparison[]>;
  private _marketSnapShotGenMixSettingsSubject$: BehaviorSubject<
    MarketGraphComparison[]
  >;
  marketInfoSettings$: Observable<MarketGraphComparison[]>;
  private _marketInfoSettingsSubject$: BehaviorSubject<MarketGraphComparison[]>;

  constructor() {
    this._marketSnapShotGenMixSettingsSubject$ = new BehaviorSubject(
      ERCOTSnapshotComparisonOptions
    );
    this.marketSnapShotGenMixSettings$ =
      this._marketSnapShotGenMixSettingsSubject$.asObservable();
    this._marketInfoSettingsSubject$ = new BehaviorSubject(
      ERCOTInfoComparisonOptions
    );
    this.marketInfoSettings$ = this._marketInfoSettingsSubject$.asObservable();
    this._marketDemandSettingsSubject$ = new BehaviorSubject(
      ERCOTDemandComparisonOptions
    );
    this.marketDemandSettings$ =
      this._marketDemandSettingsSubject$.asObservable();
  }

  get marketDemandSettings() {
    return this._marketDemandSettingsSubject$.getValue();
  }

  setMarketDemandSettings(newSettings: MarketDemandComparison[]) {
    this._marketDemandSettingsSubject$.next(newSettings);
  }

  get marketSnapShotGenMixSettings() {
    return this._marketSnapShotGenMixSettingsSubject$.getValue();
  }

  setMarketSnapShotGenMixSettings(newSettings: MarketGraphComparison[]) {
    this._marketSnapShotGenMixSettingsSubject$.next(newSettings);
  }

  get marketInfoSettings() {
    return this._marketInfoSettingsSubject$.getValue();
  }

  setMarketInfoSettings(newSettings: MarketGraphComparison[]) {
    this._marketInfoSettingsSubject$.next(newSettings);
  }

  getDgenSites() {
    return this.dgenSites;
  }

  setDgenSites(dgenSites: IGenerationSite[]) {
    this.dgenSites = dgenSites;
    this.dgenSitesUpdated.emit(dgenSites);
  }

  // gethSurvey(){
  //   return this.hSurvey;
  // }

  // sethSurvey(ahSurvey: boolean){
  //   this.hSurvey = ahSurvey;
  // }

  getAppProperties() {
    return this.appProperties;
  }

  setAppProperties(appProperties: any) {
    this.appProperties = appProperties;
  }

  getIsMobile() {
    return this.isMobile;
  }

  setIsMobile(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  getIsLocal() {
    return this.isLocal;
  }

  setIsLocal(isLocal: boolean) {
    this.isLocal = isLocal;
  }

  getIsShadow() {
    return this.isShadow;
  }

  setIsShadow(isShadow: boolean) {
    this.isShadow = isShadow;
  }

  getShowMBP() {
    return this.showMBP;
  }

  setShowMBP(showMBP: boolean) {
    this.showMBP = showMBP;
  }

  setScsCustomer(scsCustomer: boolean) {
    this.scsCustomer = scsCustomer;
  }

  getScsCustomer() {
    return this.scsCustomer;
  }

  getErcotExport() {
    return this.hideErcotExport;
  }
  setErcotExport(flag) {
    this.hideErcotExport = flag;
    this.hideErcotExportUpdated.emit(flag);
  }

  getMarketStates() {
    return this.marketStates;
  }

  setMarketStates(marketStates: IMarketState[]) {
    this.marketStates = marketStates;
  }

  getUserSource() {
    return this.userSource;
  }

  setUserSource(user: IUser) {
    this.userSource = user;
    this.userSourceUpdated.emit(user);
  }

  getMarketDB() {}

  setMarketDB(openModal: boolean, viewName: string, isRenamed: boolean) {
    this.saveDashBoardModalTriggered.emit({
      openModal: openModal,
      name: viewName,
      isRenamed: isRenamed
    });
  }

  deleteMarketDb() {
    this.deleteDashBoardModalTriggered.emit();
  }

  openDBSettingsSaveOptions() {
    this.emitOpenDBSettingsSaveOptions.emit();
  }

  getDashboardSize() {
    return this.dashboardSize;
  }

  setDashboardSize(size: number) {
    this.dashboardSize = size;
    this.dashboardSizeUpdated.emit(size);
  }

  getAccountSource() {
    return this.accountSource;
  }

  setAccountSource(account: IUserAccount) {
    if (!this.accountSource || this.accountSource.id !== account.id) {
      this.accountSource = account;
      if (account.accessType === 'ThirdParty') {
        this.accountWithContractDataSource = null;
        this.accountSourceUpdated.emit(account);
      }
    }
  }

  setComverge(flag: boolean) {
    if (flag) {
      this.accountSource.comverge = 1;
    } else {
      this.accountSource.comverge = 0;
    }
  }

  getThirdPartyAccountsSource() {
    return this.thirdPartyAccountsSource;
  }

  setThirdPartyAccountsSource(accounts: IUserAccount[]) {
    this.thirdPartyAccountsSource = accounts;
    this.thirdPartyAccountsSourceUpdated.emit(accounts);
  }

  getAccountWithContractDataSource() {
    return this.accountWithContractDataSource;
  }

  setAccountWithContractDataSource(account: IAccount) {
    if (!account.billTypes) {
      account.billTypes = [];
    }
    this.accountWithContractDataSource = account;
    this.accountWithContractDataSourceUpdated.emit(account);
  }

  getAccountsForUserSource() {
    return this.accountsForUserSource;
  }

  setAccountsForUserSource(accounts: IUserAccount[]) {
    this.accountsForUserSource = accounts;
    this.accountsForUserSourceUpdated.emit(accounts);
  }

  getPermissionsMaskSource() {
    return this.permissionsMaskSource;
  }

  setPermissionsMaskSource(mask: number) {
    this.permissionsMaskSource = mask;
    this.permissionsMaskSourceUpdated.emit(mask);
  }

  getSelectedNavItem() {
    return this.selectedNavItem;
  }

  setSelectedNavItem(navItem: string) {
    this.selectedNavItem = navItem;
    this.selectedNavItemSourceUpdated.emit(navItem);
  }

  setCurrentActivePage(tabName: string): void {
    this.currentActivePageSource.next(tabName);
  }

  getSiteGroupsSource() {
    return this.siteGroupsSource;
  }

  setSiteGroupsSource(siteGroups: any) {
    this.siteGroupsSource = siteGroups;
    this.siteGroupsSourceUpdated.emit(siteGroups);
  }

  setTpGroupSource(tpGroupSource: any) {
    this.tpGroupSource = tpGroupSource;
    this.tpGroupSourceUpdated.emit(tpGroupSource);
  }

  getTpGroupSource() {
    return this.tpGroupSource;
  }

  getContractsForAccount() {
    return this.contractsForAccountSource;
  }

  setContractsForAccount(contracts: IContract[]) {
    this.contractsForAccountSource = contracts;
    this.contractsForAccountSourceUpdated.emit(contracts);
  }

  setMarketForAccountSource(market: string) {
    this.marketValue = market;
    this.accountSource.market = market;
    this.accountSourceUpdated.emit(this.accountSource);
  }

  getManageSubscriptionStatus() {
    return this.manageSubscriptionflag;
  }

  setManageSubscriptionStatus(manageSubscriptionflag: string) {
    this.manageSubscriptionflag = manageSubscriptionflag;
  }

  gettMarketForAccountSource() {
    return this.marketValue;
  }

  getMarketArrayForAccountSource() {
    return this.marketsArray;
  }

  setMarketArrayForAccountSource(markets: string[]) {
    this.marketsArray = markets;
  }

  getSiteCountsSource() {
    return this.siteCountsSource;
  }

  setSiteCountsSource(siteCounts: ISiteCounts) {
    this.siteCountsSource = siteCounts;
    this.siteCountSourceUpdated.emit(siteCounts);
  }

  getSitesForAccountSource() {
    return this.sitesForAccountSource;
  }

  setSitesForAccountSource(sites: ISite[]) {
    this.sitesForAccountSource = sites;
    this.sitesForAccountSourceUpdated.emit(sites);
  }

  getBalanceDue() {
    return this.balanceDue;
  }

  setBalanceDue(balanceDue: IAccountBalance) {
    this.balanceDue = balanceDue;
    this.balanceDueUpdated.emit(balanceDue);
  }

  getTitleSource() {
    return this.titleSource;
  }

  setTitleSource(title: string) {
    this.titleSource = title;
    this.titleSourceUpdated.emit(title);
  }

  getEditorialContentRoute() {
    return this.editorialContentRoute;
  }

  setEditorialContentRoute(route: string) {
    this.editorialContentRoute = route;
  }

  getSelectedUserAccountSource() {
    return this.selectedUserAccountSource;
  }

  setSelectedUserAccountSource(selectedUserAccountSource: any) {
    this.selectedUserAccountSource = selectedUserAccountSource;
    this.selectedUserAccountSourceUpdated.emit(selectedUserAccountSource);
  }

  getReportSubscriptionDateRangesSource() {
    return this.reportSubscriptionDateRangesSource;
  }

  setReportSubscriptionDateRangesSource(dateRanges: any[]) {
    this.reportSubscriptionDateRangesSource = dateRanges;
    this.reportSubscriptionDateRangesSourceUpdated.emit(dateRanges);
  }

  getLoading() {
    return this.loading;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    this.loadingUpdated.emit(loading);
  }

  getLoadingText() {
    return this.loadingText;
  }

  setLoadingText(loadingText: string) {
    this.loadingText = loadingText;
    this.loadingTextUpdated.emit(loadingText);
  }

  getLoggedIn() {
    return this.loggedIn;
  }

  setLoggedIn(loggedIn: boolean) {
    this.loggedIn = loggedIn;
    this.loggedInUpdated.emit(loggedIn);
  }

  getTimedOut() {
    return this.timedOut;
  }

  setTimedOut(timedOut: boolean) {
    this.timedOut = timedOut;
    this.timedOutUpdated.emit(timedOut);
  }

  getProfileImage() {
    return this.profileImage;
  }

  setProfileImage(profileImage: string) {
    this.profileImage = profileImage;
    this.profileImageUpdated.emit(profileImage);
  }

  getProfile() {
    return this.profileSource;
  }

  setProfile(profile: IProfile) {
    this.profileSource = profile;
    this.profileUpdated.emit(profile);
  }

  getForgotPassword() {
    return this.forgotPassword;
  }

  setForgotPassword(forgotPassword: boolean) {
    this.forgotPassword = forgotPassword;
    this.forgotPasswordUpdated.emit(forgotPassword);
  }

  setQuickPay(quickPay: boolean) {
    this.quickPay = quickPay;
    this.quickPayUpdated.emit(quickPay);
  }
  getPasswordResetEmailSent() {
    return this.passwordResetEmailSent;
  }

  setPasswordResetEmailSent(passwordResetEmailSent: boolean) {
    this.passwordResetEmailSent = passwordResetEmailSent;
    this.passwordResetEmailSentUpdated.emit(passwordResetEmailSent);
  }

  getSelfRegistration() {
    return this.selfRegistration;
  }

  setSelfRegistration(selfRegistration: boolean) {
    this.selfRegistration = selfRegistration;
    this.selfRegistrationUpdated.emit(selfRegistration);
  }

  getForgotEmail() {
    return this.forgotEmail;
  }

  setForgotEmail(forgotEmail: boolean) {
    this.forgotEmail = forgotEmail;
    this.forgotEmailUpdated.emit(forgotEmail);
  }

  // Triggers

  triggerExport() {
    this.exportTriggered.emit();
  }

  triggerRealTimeDataReport() {
    this.realTimeDataReportTriggered.emit();
  }

  triggerRequest() {
    this.requestTriggered.emit();
  }

  triggerRealTimeExport() {
    this.realTimeExportTriggered.emit();
  }

  triggerMultipleInvoice() {
    this.multipleInvoiceTriggered.emit();
  }

  triggerUsersUpdated() {
    this.usersUpdatedTriggered.emit();
  }

  getIncorrectLogin() {
    return this.incorrectLogin;
  }

  setIncorrectLogin(incorrectLogin: boolean) {
    this.incorrectLogin = incorrectLogin;
    this.incorrectLoginUpdated.emit(incorrectLogin);
  }

  getResetPassword() {
    return this.resetPassword;
  }

  setResetPassword(resetPassword: boolean) {
    this.resetPassword = resetPassword;
    this.resetPasswordUpdated.emit(resetPassword);
  }

  getResetPasswordFlag() {
    return this.resetPasswordFlag;
  }

  setResetPasswordFlag(resetPasswordFlag: boolean) {
    this.resetPasswordFlag = resetPasswordFlag;
    this.resetPasswordFlagUpdated.emit(resetPasswordFlag);
  }

  getCreatePassword() {
    return this.createPassword;
  }

  setCreatePassword(createPassword: boolean) {
    this.createPassword = createPassword;
    this.createPasswordUpdated.emit(createPassword);
  }

  getSelectedBalances() {
    return this.selectedBalances;
  }

  setSelectedBalances(selectedBalances: any) {
    this.selectedBalances = selectedBalances;
    this.selectedBalancesUpdated.emit(selectedBalances);
  }

  getPaymentTotal() {
    return this.paymentTotal;
  }

  setPaymentTotal(paymentTotal: any) {
    this.paymentTotal = paymentTotal;
    this.paymentTotalUpdated.emit(paymentTotal);
  }

  getPaymentDate() {
    return this.paymentDate;
  }

  setPaymentDate(paymentDate: any) {
    this.paymentDate = paymentDate;
    this.paymentDateUpdated.emit(paymentDate);
  }

  getPositionReportDate() {
    return this.positionReportDate;
  }

  setPositionReportDate(positionReportDate: any) {
    this.positionReportDate = positionReportDate;
    this.positionReportDate.setDate(1);
    this.positionReportDate.setMonth(this.positionReportDate.getMonth() - 2);
    this.positionReportDateUpdated.emit(positionReportDate);
  }

  getBillingUsageReportDate() {
    return this.billingUsageReportDate;
  }

  setBillingUsageReportDate(billingUsageReportDate: any) {
    this.billingUsageReportDate = billingUsageReportDate;
    this.billingUsageReportDate.setDate(1);
    this.billingUsageReportDate.setMonth(
      this.billingUsageReportDate.getMonth() - 2
    );
    this.billingUsageReportDateUpdated.emit(billingUsageReportDate);
  }
  getPrinting() {
    return this.printing;
  }

  setPrinting(printing: boolean) {
    this.printing = printing;
    this.printingUpdated.emit(printing);
  }

  triggerAddWidget() {
    this.addWidgetTriggered.emit();
  }

  addError(error: IError) {
    this.errorAdded.emit(error);
  }

  signalSessionAccountUpdated() {
    this.sessionAccountUpdated.emit();
  }

  getEmailPassword() {
    return this.emailPassword;
  }

  setEmailPassword(emailPassword: boolean) {
    this.emailPassword = emailPassword;
    this.emailPasswordUpdated.emit(emailPassword);
  }

  setOverlay(overlay: boolean) {
    this.overlay = overlay;
    this.overlayUpdated.emit(overlay);
  }

  getMaskMode() {
    return this.maskMode;
  }

  setMaskMode(maskMode: boolean) {
    this.maskMode = maskMode;
    this.maskModeUpdated.emit(maskMode);
  }

  getFakeAccountName() {
    return this.fakeAccountName;
  }

  setFakeAccountName(name: string) {
    this.fakeAccountName = name;
    this.fakeAccountNameUpdated.emit(name);
  }

  getSelectedAmendmentRequest() {
    return this.selectedAmendmentRequest;
  }

  setSelectedAmendmentRequest(selectedAmendmentRequest: IAmendmentRequest) {
    this.selectedAmendmentRequest = selectedAmendmentRequest;
    this.selectedAmendmentRequestUpdated.emit(selectedAmendmentRequest);
  }

  getSelectedAmendmentSite() {
    return this.selectedAmendmentSite;
  }

  setSelectedAmendmentSite(selectedAmendmentSite: IAmendmentSite) {
    this.selectedAmendmentSite = selectedAmendmentSite;
    this.selectedAmendmentSiteUpdated.emit(selectedAmendmentSite);
  }

  setShowAmendmentsHeader(show: boolean) {
    this.showAmendmentsHeader = show;
    this.showAmendmentsHeaderUpdated.emit(show);
  }

  getShowAmendmentsHeader() {
    return this.showAmendmentsHeader;
  }

  setCurrentAttachments(currentAttachments: IAttachment[]) {
    this.currentAttachments = currentAttachments;
    this.currentAttachmentsUpdated.emit(currentAttachments);
  }

  getCurrentAttachments() {
    return this.currentAttachments;
  }

  getAccessDenied() {
    return this.accessDenied;
  }

  setAccessDenied(accessDenied: boolean) {
    this.accessDenied = accessDenied;
    this.accessDeniedUpdated.emit(accessDenied);
  }

  getLocalLoader(id: string) {
    this.localLoader.forEach((loader) => {
      if (loader.id == id) {
        return loader.loading;
      }
    });
    return false;
  }

  getAutopay() {
    return this.autopay;
  }

  setAutopay(autopay: boolean) {
    this.autopay = autopay;
    this.showAutopayUpdated.emit(autopay);
  }

  getCCNotice() {
    return this.hasCCNotice;
  }

  setCCNotice(ccNotice: boolean) {
    this.hasCCNotice = ccNotice;
  }

  // getShowBankAccount() {
  //   return this.showBankAccount;
  // }

  // setShowBankAccount(showBankAccount: boolean) {
  //   this.showBankAccount = showBankAccount;
  //   this.showBankAccountUpdated.emit(showBankAccount);
  // }

  getShowCreditCard() {
    return this.showCreditCard;
  }

  setShowCreditCard(showCreditCard: boolean) {
    this.showCreditCard = showCreditCard;
    this.showCreditCardUpdated.emit(showCreditCard);
  }

  getShowAEMHelp() {
    return this.showAEMHelp;
  }

  setShowAEMHelp(showAEMHelp: boolean) {
    this.showAEMHelp = showAEMHelp;
    this.showAEMHelpUpdated.emit(showAEMHelp);
  }

  getShowBillingManagement() {
    return this.showBillingManagement;
  }

  setShowBillingManagement(showBillingManagement: boolean) {
    this.showBillingManagement = showBillingManagement;
    this.showBillingManagementUpdated.emit(showBillingManagement);
  }

  getLargeCustomer() {
    return this.isLargCustomer;
  }

  setLargeCustomer(isLargeCust: boolean) {
    this.isLargCustomer = isLargeCust;
    this.largeCustUpdated.emit(isLargeCust);
  }

  getNCCAFlag() {
    return this.nccaFlag;
  }

  setNCCAFlag(bool: boolean) {
    // console.log('setNCCAFlag: ' + bool);
    this.nccaFlag = bool;
    this.nccaFlagUpdated.emit(bool);
  }

  getNCAFlag() {
    return this.ncaFlag;
  }

  setNCAFlag(bool: boolean) {
    this.ncaFlag = bool;
    this.ncaFlagUpdated.emit(bool);
  }

  setLocalLoader(id: string, loading: boolean) {
    let needsNew = true;
    this.localLoader.forEach((loader) => {
      if (loader.id == id) {
        loader.loading == loading;
        this.localLoaderUpdated.emit(loader);
      }
    });

    if (needsNew) {
      let loader = { id: id, loading: loading };
      this.localLoader.push(loader);
      this.localLoaderUpdated.emit(loader);
    }
  }

  getFourcpPreferences() {
    return this.fourcpPreferences;
  }

  setFourcpPreferences(fourcpPreferences: I4cpPreferences) {
    this.fourcpPreferences = fourcpPreferences;
    this.fourcpPreferencesUpdated.emit(fourcpPreferences);
  }

  getAccountSpecificMessage() {
    return this.accountSpecificMessage;
  }

  setAccountSpecificMessage(accountSpecificMessage: IAccountMessage) {
    if (accountSpecificMessage != null) {
      let shortMsg = accountSpecificMessage.shortMessage;
      if (shortMsg.includes('click here')) {
        shortMsg = shortMsg.replace(
          'click here',
          'click <a click="(#dashboardMessageModal).modal.show();" >here</a>'
        );
        accountSpecificMessage.shortMessage = shortMsg;
      }
      this.accountSpecificMessage = accountSpecificMessage;
      this.accountSpecificMessageUpdated.emit(accountSpecificMessage);
    } else {
      this.accountSpecificMessage = accountSpecificMessage;
      this.accountSpecificMessageUpdated.emit(accountSpecificMessage);
    }
  }

  getInSeason() {
    return this.inSeason;
  }

  toggleInSeason() {
    this.inSeason = !this.inSeason;
    this.inSeasonUpdated.emit(this.inSeason);
  }

  getHelpStep() {
    return this.helpStep;
  }

  setHelpStep(step: IHelpStep) {
    this.helpStep = step;
    this.helpStepUpdated.emit(step);
  }

  getSelectedMetricsPage() {
    return this.selectedMetricsPage;
  }

  setSelectedMetricsPage(page: string) {
    this.selectedMetricsPage = page;
    this.selectedMetricsPageUpdated.emit(page);
  }

  toggleContextualHelp() {
    this.contextualHelpToggle.emit();
  }

  toggleContextualMatrixBrokerPricing() {
    this.contextualMatrixBrokerPricingToggle.emit();
  }

  setSubscriptionValidity(valid) {
    this.subscriptionValidity.emit(valid);
  }

  setContextualHelp(enabled: boolean) {
    this.contextualHelpEnabled.emit(enabled);
  }

  toggleSubscriptionModal() {
    this.toggleSubscriptionModalTrigger.emit();
  }

  setSelectedProfileTab(tab: string) {
    this.selectedProfileTab = tab;
    this.selectedProfileTabUpdated.emit(tab);
  }

  getSelectedProfileTab() {
    return this.selectedProfileTab;
  }

  getSelectedTabForMarketsReports() {
    return this.selectedMarketReportsTab;
  }

  setSelectedTabForMarketsReports(tab: string) {
    this.selectedMarketReportsTab = tab;
    this.selectedMarketReportUpdated.emit(tab);
  }

  getOutputPage() {
    return this.outputPage;
  }

  setOutputPage(onOutputPage: boolean) {
    this.outputPage = onOutputPage;
    this.outputPageTriggered.emit(onOutputPage);
  }

  setProfileDataLoaded() {
    this.isProfileDataLoaded = true;
    this.profileDataLoaded.emit();
  }

  getIsProfileDataLoaded() {
    return this.isProfileDataLoaded;
  }

  getUserProfileAccount(id: string) {
    let userProfileAccounts = this.userProfileAccounts;

    if (userProfileAccounts == null) {
      return null;
    }

    for (var i = 0; i < userProfileAccounts.length; i++) {
      if (userProfileAccounts[i].id == id) {
        return userProfileAccounts[i];
      }
    }

    return null;
  }

  setUserProfileAccounts(userProfileAccounts: IUserProfileAccount[]) {
    this.userProfileAccounts = userProfileAccounts;
  }

  setPricingTcEnabled(isPricingTcEnabled: boolean) {
    this.isPricingTcEnabled = isPricingTcEnabled;
  }

  getPricingTcEnabled() {
    return this.isPricingTcEnabled;
  }

  getTealiumData() {
    return this.tealiumData;
  }

  setTealiumData(tealiumData: any) {
    this.tealiumData = tealiumData;
    this.tealiumDataUpdated.emit(tealiumData);
  }

  getWalkMeAuthToken() {
    return this.walkMeAuthToken;
  }

  setWalkMeAuthToken(token: any) {
    this.walkMeAuthToken = token;
  }

  getOriginUrl() {
    return this.orginUrl;
  }

  setOriginUrl(orginUrl: string) {
    this.orginUrl = orginUrl;
  }

  public get openMDBSaveOptions(): boolean {
    return this._openMDBSaveOptions;
  }

  public set openMDBSaveOptions(value: boolean) {
    this._openMDBSaveOptions = value;
  }

  public get openMDBViewOptions(): boolean {
    return this._openMDBViewOptions;
  }

  public set openMDBViewOptions(value: boolean) {
    this._openMDBViewOptions = value;
  }

  public get defaultMarketViewName(): string {
    return this._defaultMarketViewName;
  }

  public set defaultMarketViewName(value: string) {
    this._defaultMarketViewName = value;
    this.defaultMarketViewChanged.emit(value);
  }

  public get selectedMarketViewName(): string {
    return this._selectedMarketViewName;
  }

  public set selectedMarketViewName(value: string) {
    this._selectedMarketViewName = value;
    this.selectedMarketViewChanged.emit();
  }

  public get isMarketDBFullscreen(): boolean {
    return this._isMarketDBFullscreen;
  }

  public set isMarketDBFullscreen(value: boolean) {
    this._isMarketDBFullscreen = value;
  }

  public get showMarketDBlegend(): boolean {
    return this._showMarketDBlegend;
  }

  public set showMarketDBlegend(value: boolean) {
    this._showMarketDBlegend = value;
    this.marketChartLegendToggle.emit();
  }

  public get dbMarketDemandsSettings(): IMarketSetting[] {
    return this._dbMarketDemandsSettings;
  }

  public set dbMarketDemandsSettings(value: IMarketSetting[]) {
    this._dbMarketDemandsSettings = value;
  }

  public get dbInfoMarketGraphSettings(): IMarketSetting[] {
    return this._dbInfoMarketGraphSettings;
  }

  public set dbInfoMarketGraphSettings(value: IMarketSetting[]) {
    this._dbInfoMarketGraphSettings = value;
  }

  public get dbSnapshotMarketGraphSettings(): IMarketSetting[] {
    return this._dbSnapshotMarketGraphSettings;
  }

  public set dbSnapshotMarketGraphSettings(value: IMarketSetting[]) {
    this._dbSnapshotMarketGraphSettings = value;
  }

  public get dbPjmYourListSettings(): IMarketSetting[] {
    return this._dbPjmYourListSettings;
  }

  public set dbPjmYourListSettings(value: IMarketSetting[]) {
    this._dbPjmYourListSettings = value;
  }

  public get newDbMarketDemandsSettings(): IMarketSetting[] {
    return this._newDbMarketDemandsSettings;
  }

  public set newDbMarketDemandsSettings(value: IMarketSetting[]) {
    this._newDbMarketDemandsSettings = value;
  }

  public get newDbInfoMarketGraphSettings(): IMarketSetting[] {
    return this._newDbInfoMarketGraphSettings;
  }

  public set newDbInfoMarketGraphSettings(value: IMarketSetting[]) {
    this._newDbInfoMarketGraphSettings = value;
  }

  public get newDbSnapshotMarketGraphSettings(): IMarketSetting[] {
    return this._newDbSnapshotMarketGraphSettings;
  }

  public set newDbSnapshotMarketGraphSettings(value: IMarketSetting[]) {
    this._newDbSnapshotMarketGraphSettings = value;
  }

  public get newDbPjmYourListSettings(): IMarketSetting[] {
    return this._newDbPjmYourListSettings;
  }

  public set newDbPjmYourListSettings(value: IMarketSetting[]) {
    this._newDbPjmYourListSettings = value;
  }

  public get marketViews(): MarketDashboardView[] {
    return this._marketViews;
  }

  public set marketViews(value: MarketDashboardView[]) {
    this._marketViews = value;
  }

  public get selectedMarket(): string {
    return this._selectedMarket;
  }

  public set selectedMarket(value: string) {
    this._selectedMarket = value;
  }

  public get dbPjmDaYourListSettings(): IMarketSetting[] {
    return this._dbPjmDaYourListSettings;
  }

  public set dbPjmDaYourListSettings(value: IMarketSetting[]) {
    this._dbPjmDaYourListSettings = value;
  }

  public get newDbPjmDaYourListSettings(): IMarketSetting[] {
    return this._newDbPjmDaYourListSettings;
  }

  public set newDbPjmDaYourListSettings(value: IMarketSetting[]) {
    this._newDbPjmDaYourListSettings = value;
  }

  public get showMarketDashboard(): boolean {
    return this._showMarketDashboard;
  }

  public set showMarketDashboard(value: boolean) {
    this._showMarketDashboard = value;
  }
}
