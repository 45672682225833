import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {CookieService} from 'ngx-cookie-service';
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {PortalService} from "../../shared/portal.service";
@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss'],
  animations: [FadeAnimation, FadeInAnimation],
  providers: [CookieService]
})
export class HealthCheckComponent implements OnInit {

  constructor(private dataService: DataService,
              private cookieService: CookieService,
              private portalService: PortalService) { }

  ngOnInit() {
  }

  logout(){
    this.portalService.logout().subscribe();
  }
}
