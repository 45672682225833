import {PipeTransform, Pipe} from '@angular/core';
import {ISite} from "../entities/site";

@Pipe({
  name: 'sitePipe'
})
export class SitePipe implements PipeTransform {

  transform(sites: ISite[], term: string): ISite[] {
    let filteredSites = [];
    let addressProps = ['billingAddress', 'serviceAddress'];
    let addressSubProps = ['streetAddress', 'city', 'state', 'zipCode'];
    let addressProp, addressSubProp;
    term = term.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"); // remove non-regex-friendly characters (this can be propogated to other pipes if needed)
    term = term.toLowerCase().trim();
    let props = [];
    for(let prop in sites[0]){
      props.push(prop); // consolidate site properties into an array
    }
    for(let site of sites){
      outer:
      for(let prop of props){
        if(prop == 'productInfo' && site.productInfo.name.toString().toLowerCase().match(term)){
          filteredSites.push(site);
          break;
        }
        if(prop.toLowerCase().match('address')) {
          for(addressProp of addressProps){
            for (addressSubProp of addressSubProps){
              if(site[addressProp][addressSubProp] && site[addressProp][addressSubProp].toLowerCase().match(term)){
                filteredSites.push(site);
                break outer;
              }
            }
          }
        }
        if(site[prop]){
          if(site[prop].toString().toLowerCase().match(term)){
            filteredSites.push(site);
            break;
          }
        }
      }
    }
    return filteredSites;
  }
}
