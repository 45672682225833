import { Component, OnInit, Input } from '@angular/core';
import {MarketsService} from "../markets.service";
import {
  IMarketData, ErcotMarketSources, IOffset,
  IMarketComparison, IMarketIndicator, MarketIndicators, ErcotMarketSection
} from "../../shared/entities/markets";
import {DataService} from "../../shared/data.service";
import {IUserAccount} from "../../shared/entities/profile";
import {FadeAnimation} from "../../animations/fadeAnimation";
declare var Highcharts: any;
declare var $:any;
import * as moment from 'moment';
import {MarketsPipe} from "../../shared/pipe/markets.pipe";
import {Subscription} from "rxjs";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {PortalService} from "../../shared/portal.service";
import {IMarketSetting} from "../../shared/entities/marketSetting";

@Component({
  selector: 'app-markets-section',
  templateUrl: 'markets-section.component.html',
  animations: [FadeAnimation],
  providers: [MarketsPipe],
  styleUrls: ['../markets.component.scss', 'markets-section.component.scss']
})

export class MarketsSectionComponent implements OnInit {

  @Input() properties: any; // for some reason, IMarketProperties throws an annoying Warning
  marketData: IMarketData[];
  myAccount: IUserAccount;
  selectedOffset: IOffset;
  selectedFutureOffset: IOffset;
  selectedComparisons: IMarketComparison[] = [];
  defaultComparisons: IMarketComparison[] = [];
  legendSelection : IMarketComparison[] = [];
  indicators: IMarketIndicator[];
  selectedIndicator: IMarketIndicator;
  marketDataTicker = 0;
  marketDates: any[];
  marketYAxis: any[];
  datasets: any[];
  ercotPlotLines: any[];
  hubPlotLines: any[];
  showSearchRow: boolean;
  filteredDatasets: any[];
  searchTerm: string = '';
  loading: boolean = false;
  settings: IMarketSetting[];
  isMobile: boolean;
  numOfRowsToShow = 25;
  foundERCOTActual = false;
  foundERCOTFuture = false;
  foundHubAvg = false;
  foundHenryHubMonthly = false;
  foundHenryHubDaily = false;
  foundHcsMonthly = false;
  foundHcsDaily = false;
  minInterval: number = 0;
  ctr: number = 0;
  maxSelect: boolean = false;
  showChartLegend: boolean = true;
  expandedGroup: string = '';
  // infoexpanded: boolean = false;
  // expandedStatus:boolean = false;

  showComparisons: boolean;
  showIndicators: boolean;
  showGraph: boolean = true; // If false, show table

  accountSubscription: Subscription;
  loadingSubscription: Subscription;
  refreshSubscription: Subscription;
  maskMode: boolean;
  maskModeSubscription: Subscription;


  constructor(private marketsService: MarketsService,
              private dataService: DataService,
              private marketsPipe: MarketsPipe,
              private portalService: PortalService) { }

  ngOnInit() {
    this.indicators = MarketIndicators;
    this.setCurrentActivePage('tools');

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.selectedOffset = this.properties.defaultOffset;
    this.selectedFutureOffset = this.properties.defaultFutureOffset;
    this.properties.comparisons.forEach((comparison) => {
      let defaultComparison = Object.assign({}, comparison);
      this.defaultComparisons.push(defaultComparison);
      if(comparison.selected){
        this.selectedComparisons.push(comparison);        
      }
    });

    // Highcharts.setOptions({
    //   colors: ['#00AEEF','#439539','#424242','#333092','#ffeb3b','#EF3E42','#FBB034', '#8a6d3b', '#424242', '#3336b7', '#aeb733']
    // });

    // this.legendSelection = this.selectedComparisons; 

    

    this.myAccount = this.dataService.getAccountSource();
    // if(this.myAccount){
    //   this.getMarketData();
    // }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.getMarketData();
    });

    this.isMobile = this.dataService.getIsMobile();

    this.marketsService.getSettings(this.properties.name)
      .subscribe(
        (settings : any) => {
          this.settings = settings;
          this.applySettings();
        },
        (error) => {
          // console.log("ERROR LOADING SETTINGS: ", error);
          if(this.myAccount){
            this.getMarketData();
          }
        }
      );

    // Click outside event for date range and groupings sections
    document.addEventListener("click", ($event) => {
      if($($event.target).parents('#comparisonsSection').length == 0){
        this.showComparisons = false;
      }
      if ($($event.target).parents('#indicatorsSection').length == 0) {
        this.showIndicators = false;
      }
    });
    if(this.properties.name == ErcotMarketSection.Snapshot){
      if(this.refreshSubscription){
        this.refreshSubscription.unsubscribe();
      }
      this.refreshSubscription = IntervalObservable.create(60000).subscribe(() => {
        // Update every 1 minute
        this.getMarketData();
      });
    } else if(this.properties.name == ErcotMarketSection.Info){
      if(this.refreshSubscription){
        this.refreshSubscription.unsubscribe();
      }
      this.refreshSubscription = IntervalObservable.create(900000).subscribe(() => {
        // Update every 15 minutes
        this.getMarketData();
      });
    }

    this.loadingSubscription = this.dataService.localLoaderUpdated.subscribe((loader) =>{
      if(('MARKETS-' + this.properties.name) == loader.id){
        this.loading = loader.loading;
      }
    });

    Highcharts.setOptions({
      global : {
        useUTC : false
      }
    });
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    if(this.refreshSubscription){
      this.refreshSubscription.unsubscribe();
    }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  applySettings(){
    this.settings.forEach((setting) => {
      if(setting.fieldName == 'indicator'){
        this.applyIndicatorSetting(setting);
      }
      else if(setting.fieldName == 'offset' && this.properties.name != 'INFO'){
        this.applyOffsetSetting(setting);
      }
      else if(setting.fieldName == 'futureOffset'){
        this.applyFutureOffsetSetting(setting);
      }
      else if(setting.fieldName == 'showGraph'){
        this.applyGraphOrTableSetting(setting);
      }
      else if(setting.fieldName.indexOf('comparison-') > -1 ){
        this.applyComparisonSetting(setting);
      }
    });
    this.saveNotFoundDefaults();
    if(this.myAccount) {
      this.getMarketData();
    }
  }

  expandStatus(comparison: IMarketComparison){
     comparison.expandedValue = !comparison.expandedValue;
  }


  expandGroup(group: string,comparison: IMarketComparison) {
      // // console.log('1group',group);    
    if (this.expandedGroup == group) {      
      this.expandedGroup = ""
      // comparison.expandedValue = !comparison.expandedValue;
      // // console.log('2group name',group);
      //   this.infoexpanded = !this.infoexpanded;
      //   this.expandedStatus = false;
    } else {
      this.expandedGroup = group;
      // // console.log('3expandedGroup',this.expandedGroup);
       this.properties.comparisons.forEach((comp) => {
        if(this.expandedGroup == comp.group){
             comp.expandedValue = true;
        }
        else{
             comp.expandedValue = false;
        }
      }); 
        // this.infoexpanded = !this.infoexpanded;
        // this.expandedStatus = true;
    }
  }

  resetSettings(){
    //reset table vs graph
    this.showGraph = true;
    this.updateSettings('showGraph', 'true', true);

    //reset indicator
    this.selectedIndicator = null;
    this.updateSettings('indicator', ' ', true);

    //reset offset
    if(this.properties.defaultOffset){
      this.selectedOffset = this.properties.defaultOffset;
      this.updateSettings('offset', this.selectedOffset.value, true);
    }

    //reset futureOffset
    if(this.properties.defaultFutureOffset){
      this.selectedFutureOffset = this.properties.defaultFutureOffset;
      this.updateSettings('futureOffset', this.selectedFutureOffset.value, true)
    }

    //reset comparisons
    if(this.defaultComparisons && this.defaultComparisons.length > 0){
      this.selectedComparisons = [];
      this.defaultComparisons.forEach((defaultComparison) => {
        this.properties.comparisons.forEach((comparison) => {
          if(comparison.value == defaultComparison.value){
            comparison.selected = defaultComparison.selected;
          }
        });
        if(defaultComparison.selected){
          this.selectedComparisons.push(defaultComparison);
        }
      });
      this.properties.comparisons.forEach((comparison) => {
        this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true)
      });
    }

    this.getMarketData();
  }

  applyIndicatorSetting(setting: IMarketSetting){
    let matchedIndicator: IMarketIndicator = null;
    this.indicators.forEach((indicator) => {
      if(indicator.value == setting.fieldValue){
        matchedIndicator = indicator;
      }
    });
    this.selectedIndicator = matchedIndicator;
  }

  applyOffsetSetting(setting: IMarketSetting){
    let matchedOffset: IOffset = null;
    this.properties.offsets.forEach((offset) => {
      if(offset.value == setting.fieldValue){
        matchedOffset = offset;
      }
    });
    if(matchedOffset) {
      this.selectedOffset = matchedOffset;
    }
  }

  applyFutureOffsetSetting(setting: IMarketSetting){
    let matchedFutureOffset: IOffset = null;
    this.properties.futureOffsets.forEach((offset) => {
      if(offset.value == setting.fieldValue){
        matchedFutureOffset = offset;
      }
    });
    if(matchedFutureOffset){
      this.selectedFutureOffset = matchedFutureOffset;
    }
  }

  applyGraphOrTableSetting(setting: IMarketSetting){
    this.showGraph = setting.fieldValue == 'true';
    if(this.showGraph){
      this.numOfRowsToShow = 25;
    } else {
      setTimeout(() => {
        let component = this;
        $('#' + this.properties.name + '-table').on('scroll', function() {
          if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
            component.numOfRowsToShow = component.numOfRowsToShow + 25;
          }
        })
      }, 200);
    }
  }

  applyComparisonSetting(setting: IMarketSetting){
    let comparisonString = setting.fieldName.substring(11);

    if(comparisonString == 'ERCOT_ACTUAL_LOAD'){
      this.foundERCOTActual = true;
    }
    if(comparisonString == 'ERCOT_FORECAST_LOAD'){
      this.foundERCOTFuture = true;
    }
    if(comparisonString == 'ERCOT_HB_HUBAVG'){
      this.foundHubAvg = true;
    }
    if(comparisonString == 'HENRY_HUB_MO'){
      this.foundHenryHubMonthly = true;
    }
    if(comparisonString == 'HENRY_HUB_FDT_COM'){
      this.foundHenryHubDaily = true;
    }
    if(comparisonString == 'HOUSTON_SHIP_CHANNEL_MO'){
      this.foundHcsMonthly = true;
    }
    if(comparisonString == 'HOUSTON_SHIP_CHANNEL_FDT_COM'){
      this.foundHcsDaily = true;
    }

    this.properties.comparisons.forEach((comparison) => {
      if(comparison.value == comparisonString){
        comparison.selected = setting.fieldValue == 'true';
      }
    });
    this.selectedComparisons = [];
    this.properties.comparisons.forEach((comp) => {
      if(comp.selected){
        this.selectedComparisons.push(comp);
      }
    });
  }

  saveNotFoundDefaults(){
    if(!this.foundERCOTActual){
      this.updateSettings('comparison-ACTUAL_LOAD', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-ACTUAL_LOAD'));
    }
    if(!this.foundERCOTFuture){
      this.updateSettings('comparison-FORECAST_LOAD', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-FORECAST_LOAD'));
    }
    if(!this.foundHubAvg){
      this.updateSettings('comparison-HB_HUBAVG', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-HB_HUBAVG'));
    }
    if(!this.foundHenryHubMonthly){
      this.updateSettings('comparison-HENRY_HUB_MO', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-HENRY_HUB_MO'));
    }
    if(!this.foundHenryHubDaily){
      this.updateSettings('comparison-HENRY_HUB_FDT_COM', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-HENRY_HUB_FDT_COM'));
    }
    if(!this.foundHcsMonthly){
      this.updateSettings('comparison-HOUSTON_SHIP_CHANNEL_MO', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-HOUSTON_SHIP_CHANNEL_MO'));
    }
    if(!this.foundHcsDaily){
      this.updateSettings('comparison-HOUSTON_SHIP_CHANNEL_FDT_COM', 'true', false);
      this.settings.push(this.newComparisonSetting('comparison-HOUSTON_SHIP_CHANNEL_FDT_COM'));
    }
  }

  newComparisonSetting(comparisonName: string){
    let settingToSave: IMarketSetting = {
      fieldName: comparisonName,
      fieldValue: 'true',
      componentUsed: this.properties.name
    };
    return settingToSave;
  }

  getMarketData() {
    // loop through selected comparisons
    if(this.selectedComparisons.length > 0){
      let offset;
      this.portalService.spinBabySpin('spinner-' + this.properties.name);
      this.dataService.setLocalLoader('MARKETS-' + this.properties.name, true);
      this.marketData = [];
      this.marketDataTicker = 0;
      this.legendSelection = [];
      
      this.legendSelection = this.selectedComparisons; 
      // console.log('legend',this.legendSelection);
      this.selectedComparisons.forEach((comparison) => {
        if(comparison.value == 'ERCOT_FORECAST_LOAD') {
          offset = this.selectedFutureOffset;
        } else {
          offset = this.selectedOffset;
        }
        this.marketsService.getMarketData(comparison.value, offset, this.properties.dataUrl).subscribe((marketData: any) => {
          this.marketData.push(marketData);
          // console.log('old marketssssss data',this.marketData, this.properties.name);
          this.marketDataTick();
        }, err => {
          this.marketDataTick();
        });
      });
    }
  }

  marketDataTick() {
    this.marketDataTicker++;
    if(this.marketDataTicker == this.selectedComparisons.length){
      this.dataService.setLocalLoader('MARKETS-' + this.properties.name, false);
      this.createChart();
    }
  }

  selectOffset(offset: IOffset){
    if(this.properties.name != 'INFO'){
      this.updateSettings('offset', offset.value, true);
    }
    this.selectedOffset = offset;
    this.getMarketData();
  }

  selectFutureOffset(futureOffset: IOffset){
    this.updateSettings('futureOffset', futureOffset.value, true);
    this.selectedFutureOffset = futureOffset;
    this.getMarketData();
  }

  toggleComparisons() {
    this.showComparisons = !this.showComparisons;
  }

  toggleIndicators() {
    this.showIndicators = !this.showIndicators;
  }

  selectComparison(comparison: IMarketComparison) {
    // console.log('testing values',comparison);
     this.showChartLegend = true;      
    // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    //this.selectedComparisons = [];
    this.ctr = 0;
    this.properties.comparisons.forEach((comp) => {
      if(comp.selected){
        this.ctr ++;
        if(this.ctr < 5) {  
           this.maxSelect = false;                               
        }
          else{
            this.maxSelect = true;
          }

       // if(this.ctr < 6){          
          // // console.log('reached 5 or less than equal');                
        //}
        // this.toggleComparisons();                   
        // return;        
      }
    });

    if(this.maxSelect){
      if(comparison.selected) {
        comparison.selected = !comparison.selected;
        this.maxSelect = false;
      }
    }
    else{
     // console.log('before inverting the selection ');  
     comparison.selected = !comparison.selected;
    // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    }    
    
    // this.selectedComparisons.push(comp);
    // this.toggleComparisons();
    // this.getMarketData();
  }

  applyChanges(comparison: IMarketComparison){
    this.showChartLegend = true;
    this.selectedComparisons = [];        
    this.properties.comparisons.forEach((comp) => {
       // console.log('updateing settings');
       this.updateSettings('comparison-' + comp.value, comp.selected.toString(), true);
      if(comp.selected){   
          this.selectedComparisons.push(comp);      
      }
    }); 

    
// export interface IMarketComparison {
//   zIndex: number;
//   display: string; // "ERCOT Actual Load"
//   value: string; // "ACTUAL_LOAD"
//   hexColor: string; // "#439539"
//   selected: boolean;
// }

//      this.updateSettings('comparison-' + this.properties.comparison.value, this.properties.comparison.selected.toString(), true);    
    
    // this.legendSelection = this.selectedComparisons; 
 
    //
    this.toggleComparisons();
    this.getMarketData();
    
  }

  updateSettings(fieldName: string, fieldValue: any, pushSetting: boolean){
    let settingFound = false;
    //Update Setting if exists
    if(this.settings) {
      this.settings.forEach((setting) => {
        if(setting.fieldName == fieldName){
          settingFound = true;
          setting.fieldValue = fieldValue;
          this.marketsService.updateSetting(setting).subscribe(
            (response) => {

            }
          );
        }
      });
    }
    //Save new setting if new
    if(!settingFound){
      let settingToSave: IMarketSetting = {
        fieldName: fieldName,
        fieldValue: fieldValue,
        componentUsed: this.properties.name
      };
      this.marketsService.updateSetting(settingToSave).subscribe(
        (response : any) => {
          if(pushSetting){
            this.settings.push(response);
          }
          else {
            this.settings.forEach((setting) =>{
              if(setting.fieldName = response.fieldName){
                setting.id = response.id;
              }
            });
          }
        }
      )
    }
  }

  selectIndicator(indicator: IMarketIndicator){
    if(this.selectedIndicator && this.selectedIndicator.value == indicator.value) {
      this.selectedIndicator = null;
      this.updateSettings('indicator', " ", true);
    } else {
      this.selectedIndicator = indicator;
      this.updateSettings('indicator', this.selectedIndicator.value, true);
    }
    this.toggleIndicators();
    this.getMarketData();
  }

  toggleGraph(show: boolean){
    this.updateSettings('showGraph', show.toString(), true);
    this.showGraph = show;
    if(this.showGraph){
      this.getMarketData();
      this.numOfRowsToShow = 25;
    } else {
      // this.portalService.spinBabySpin('spinner-' + this.properties.name);
      // this.dataService.setLocalLoader('MARKETS-' + this.properties.name, true);
      // console.log('testing spinner on graph-table');
      setTimeout(() => {
        let component = this;
        $('#' + this.properties.name + '-table').on('scroll', function() {
          if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
            component.numOfRowsToShow = component.numOfRowsToShow + 25;
          }
        })
      }, 200);
    }
  }

  getFormattedDate(date: string){
    return moment(date).format('MM/DD/YY HH:mm');
  }

  getMarketDates() {
    // Get Smallest Interval with Min/Max Dates
    let dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let dates, maxDate, minDate;
    this.marketData.forEach((dataSource) => {
      dates = dataSource.sources[0].dates;
      if(!this.minInterval || moment(dates[1]).diff(dates[0], 'minutes') < this.minInterval){
        this.minInterval = moment(dates[1]).diff(dates[0], 'minutes');
      }
      if(moment(dates[0]).isBefore(moment(minDate)) || !minDate){
        minDate = dates[0];
      }
      if(moment(dates[dates.length - 1]).isAfter(moment(maxDate)) || !maxDate){
        maxDate = dates[dates.length - 1];
      }
    });

    // Generate Dates
    this.marketDates = [];
    let currDate = moment(minDate);
    while(currDate.isBefore(moment(maxDate))) {
      this.marketDates.push(currDate.toDate().getTime());
      currDate = currDate.add(this.minInterval, 'minutes');
    }
  }

  adjustMarketData() {
    this.marketData.forEach((dataSource) => {
      let sourceDates = dataSource.sources[0].dates;
      let oldVals = Object.assign([], dataSource.sources[0].values);
      let interval = moment(sourceDates[1]).diff(moment(sourceDates[0]), 'minutes');
      interval = interval / this.minInterval;
      if(interval == 1) {
        interval = 0;
      } else {
        interval--;
      }
      let forecastNulls = 0;
      if(dataSource.sources[0].source.match('FORECAST')){
        let forecastStartDate = moment(sourceDates[0]);
        this.marketDates.forEach((date) => {
          if(moment(date).isBefore(forecastStartDate)){
            forecastNulls++;
          }
        });
      }
      if(sourceDates.length < this.marketDates.length) {
        let i = 0;
        let j = 0;
        let k = 0; // number of points filled for each loop

        let newVal;
        for (i = 0; i < sourceDates.length-1; i++) {
          if((dataSource.sources[0].source != ErcotMarketSources.ERCOTForecastLoad || i != 0) && i != sourceDates.length-1) {
            newVal = oldVals[i];
          } else {
            newVal = null;
          }
          k = 0;
          if(forecastNulls){
            // Handle forecast nulls at start
            for(let l = 0; l < forecastNulls; l++){
              dataSource.sources[0].values.splice(j, 0, newVal);
              j++
            }
            forecastNulls = 0;
          } else {
            while (k < interval){
              dataSource.sources[0].values.splice(j, 0, newVal);
              k++;
              j++;
            }
            k = 0;
          }
          j++;
        }
      }
    });
  }

  loadChartData() {
    let name, data;
    let first = '';
    this.properties.comparisons.forEach((comparison) => {
      name = '';
      data = [];
      this.marketData.forEach((dataSource) => {
        if(comparison.value == dataSource.sources[0].source) {
          if(dataSource.sources[0]){
            name = dataSource.sources[0].source;
            data = dataSource.sources[0].values;
          }
        }
      });
      let xyData = [], xyObj = {}, x;
      if(comparison.selected){
        for(let i = 0; i < this.marketDates.length; i++){
          xyObj = {
            y: data[i],
            x: this.marketDates[i]
          };
          xyData.push(xyObj);
        };
        if(first == '') first = 'FIRST';
      }
      this.datasets.push({
          id: comparison.value,
          name: name,
          display: comparison.display,
          type: (name.match('LZ') || name.match('HB')) ? 'line' : 'area',
          data: xyData,
          yAxis: (name.match('LZ') || name.match('HB'))? 1 : 0,
          tooltip: {
            valueDecimals: 3
          },
          turboThreshold: 1000000,
          first: first == 'FIRST'
        });
      if(first == 'FIRST') first = null;
    });
    this.filteredDatasets = this.datasets;
    // console.log('this.datasets', this.datasets);
  }


  getPlotLines() {
    // Indicators
    // console.log('indicator',this.selectedIndicator);
    this.ercotPlotLines = [];
    this.hubPlotLines = [];
    let peak;
    if(this.selectedIndicator && this.selectedIndicator.value == 'PEAK'){
      this.datasets.forEach((dataset) => {
        peak = 0;
        dataset.data.forEach((point) => {
          if(point.y > peak){
            peak = point.y;
          }
        });
        this.properties.comparisons.forEach((comparison) => {
          if(dataset.name == comparison.value){
            if((dataset.name.match('HB') || dataset.name.match('LZ')) && peak && (this.properties.name == ErcotMarketSection.Snapshot || this.properties.name == ErcotMarketSection.Info)){
              this.hubPlotLines.push({
                value: peak,
                zIndex: comparison.zIndex
              });
            } else if (peak) {
              this.ercotPlotLines.push({
                value: peak,
                zIndex: comparison.zIndex
              });
            }
          }
        });
      });
    } else if (this.selectedIndicator){
      let plotLine = {} as any;
      this.properties.comparisons.forEach((comparison) => {
        plotLine = {
          yAxis: (comparison.value.match('HB') || comparison.value.match('LZ')) ? 1 : 0,
          type: this.selectedIndicator.value.toLowerCase(),
          linkedTo: comparison.value
        };
        if(this.selectedIndicator.value == 'MACD' || this.selectedIndicator.value == 'EMA'){
          plotLine.params = {
            shortPeriod: 12,
            longPeriod: 26,
            signalPeriod: 9,
            period: 26
          }
        }
        if(((!comparison.value.match('HB') && comparison.value.match('FDT')) || this.selectedIndicator.value != 'MACD') && !comparison.value.match('MO')){         
          if(this.selectedIndicator.value != 'EMA'){
              this.datasets.push(plotLine);
          }
        }
      });
    }
  }

  getYAxis() {
    if(this.properties.name == ErcotMarketSection.Info || this.properties.name == ErcotMarketSection.Snapshot){
      this.marketYAxis = [{ // Primary yAxis
        labels: {
          format: '{value} MW'
        },
        title: {
          text: 'Demand'
        },
        opposite: false,
        plotLines: this.ercotPlotLines,
      }, { // Secondary yAxis
        title: {
          text: 'Price'
        },
        labels: {
          format: '${value}'
        },
        tickInterval: 10,
        plotLines: this.hubPlotLines,
      }];
    } else {
      this.marketYAxis = [{ // Secondary yAxis
        title: {
          text: 'Price'
        },
        labels: {
          format: '${value}'
        },
        // tickInterval: 10,
        plotLines: this.ercotPlotLines,
      }];
    }

  }

  getDatesAndData() {
    this.datasets = [];
    this.marketDates = [];
    this.getMarketDates();
    this.adjustMarketData();
    this.loadChartData();
    this.getPlotLines();
    this.getYAxis();
  }

  getGradients() {
    return {gradient0: {
      tagName: 'linearGradient',
      id: 'gradient-0',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [{
        tagName: 'stop',
        offset: 0
      }, {
        tagName: 'stop',
        offset: 1
      }]
    },
      gradient1: {
        tagName: 'linearGradient',
        id: 'gradient-1',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [{
          tagName: 'stop',
          offset: 0
        }, {
          tagName: 'stop',
          offset: 1
        }]
      }
    }
  }

  createChart() {
    if(this.marketData){
      this.getDatesAndData();
      let component = this;
      let chartName = this.properties.name + '-chart';
      let defs = {};
      // console.log('propertiessss',this.properties);
      if(this.properties.name == 'INFO' || this.properties.name == 'SNAPSHOT'){
        defs = this.getGradients();
      };
      Highcharts.stockChart(chartName, {
        chart: {
          colorCount: 25,         
          alignTicks: true,
          height: this.isMobile ? 300: 500
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: ''
        },
        defs: defs,
        tooltip: {
          formatter: function() {
            var s = '<b>'+ moment(this.x).format('ddd, MMM D HH:mm') +'</b>';
            $.each(this.points, function(i, point) {
              let myColor = '', name = '';
              component.properties.comparisons.forEach((comparison) => {
                if(point.series.name == comparison.value){
                  myColor = comparison.hexColor;
                  name = comparison.display
                  //  // console.log('display',comparison.display);
                  
                  //  // console.log('hexcolor',comparison.hexColor);
                }
              });
              s += '<br/><span style="color:' + myColor + '">\u25CF</span> '+ name +': ';
              if(name.match('Hub') || name.match('Ship') || name.match('Zone')){
                s += '$';
              }
              s += point.y.toFixed(2);
              if(!name.match('Hub') && !name.match('Ship') && !name.match('Zone')){
                s += ' MW';
              }
            });
            return s;
          }
        },
        credits: {
          enabled: false
        },
        yAxis: this.marketYAxis,
        series: this.datasets
      });
    }
  }

  // Filter sites by search term using sitePipe
  filterData() {
    if(!this.searchTerm){
      this.getMarketData();
    } else {
      this.filteredDatasets = this.marketsPipe.transform(this.datasets, this.searchTerm);
      // console.log('this.filteredDatasets', this.filteredDatasets);
    }
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow
    this.searchTerm = '';
    this.filterData();
  }

  getComparisonsString() {
    let comparisons = '';
    this.properties.comparisons.forEach((comparison) => {
      if(comparison.selected){
        comparisons += comparison.value + ','
      }
    });
    comparisons = comparisons.slice(0, -1);
    return comparisons;
  }

  exportToExcel() {
    let sources = this.getComparisonsString();
    this.marketsService.exportToExcel(this.properties.name, this.selectedOffset.value, sources, this.maskMode);
  }
}
