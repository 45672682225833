import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {Router} from "@angular/router";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import { ProfileReportsService } from './profile-reports.service';
import {IProfile, IUserAccount} from "../../shared/entities/profile";
import { IProfileReport} from './profile-report';
import { OrderByPipe } from '../../shared/pipe/orderBy.pipe';
import { Pager, PagerService } from '../../shared/pager.service';
import { IPagerDenom, PagerDenoms } from '../../shared/const/pagerDenoms';
import { FakerService } from '../../shared/faker.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-profile-reports',
  templateUrl: './profile-reports.component.html',
  styleUrls: ['./profile-reports.component.scss'],
  providers: [ProfileReportsService, GenericPipe]
})
export class ProfileReportsComponent implements OnInit {


  constructor(private dataService: DataService,
    private profileReportsService: ProfileReportsService,
    private orderByPipe: OrderByPipe,
    private genericPipe: GenericPipe,
    private pagerService: PagerService,
    private fakerService: FakerService,
    private router: Router) {
    }

  myAccount: IUserAccount;
  myProfile: IProfile;
  allUserAccounts: any[];
  subscriptionList : IProfileReport[] = [];
  filteredSubscriptions : IProfileReport[] = [];

  sortBy: string = 'publishDate';
  ascending: boolean = false;
  showSearchRow: boolean = false;
  searchTerm: string = '';

  pager: Pager = null;
  selectedDenom: IPagerDenom;
  pagerDenoms: IPagerDenom[];

  maskMode: boolean;

  maskModeSubscription: Subscription;

  ngOnInit(): void {
    this.dataService.setLoading(true);
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );
    this.myAccount = this.dataService.getAccountSource();
    this.myProfile = this.dataService.getProfile();
    this.subscriptionList = [];
    this.allUserAccounts = this.myProfile.userAccounts.slice();
    this.myProfile.userAccounts.forEach(acc => {
      this.allUserAccounts.push(...acc.thirdPartyCustomers);
    })
    this.loadReports();

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if (denom.count == 10) {
        this.selectDenom(denom);
      }
    });
  }

  ngOnDestroy() {
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  loadReports() {
    //network call to get all reports for user
    let userId = this.myProfile.userId;
    this.profileReportsService.getSubscriptionsForUser(userId).subscribe((response=>{
      this.subscriptionList = response;
      this.subscriptionList.forEach(rpt => {
        rpt.downloading = false;
        if (!rpt.accountName) {
          rpt.accountName = this.getAccountName(rpt.accountId);
        }
        rpt.fakeAccountName = this.fakerService.getCompanyName();
        rpt.fakeReportName = this.fakerService.generateFakeReportName(rpt.fakeAccountName)
        rpt.reportName = this.getSubscriptionName(rpt);
      });
      this.filteredSubscriptions = this.subscriptionList;
      this.orderReports();
      this.dataService.setLoading(false);
    }));
    console.log(this.subscriptionList);
  }
  formatDate(date : string){

    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1; // method is 0 based
    let monthString = month.toString();
    if(month < 10){
      monthString = '0' + month.toString();
    }
    let year = newDate.getFullYear();

    let finalDate = year.toString() + '-' + monthString + '-' + day.toString();
    return finalDate;
  }

  toTitleCase(str: string){
    return str.split(' ')
   .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
   .join(' ');
  }

  getSubscriptionName(report: IProfileReport) {
    if (report) {
      if (report.exportType.toUpperCase() != 'SUBSCRIPTION') {
        return "";
      }
      return report.reportName;
    }
    return "";
  }

  getAccountName(accountId: string) {
    let account = this.allUserAccounts.find(acc => acc.id == accountId);
    if (account) {
      return account.name;
    }
    return "";
  }

  downloadReport(e, report: IProfileReport) {
    e.preventDefault();
    this.profileReportsService.downloadReport(report).subscribe();
  }

  // Adjust arrow up/down, call out to paginate & sort
  sortReports(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
      switch (sortBy) {
        case "publishDate": {
          this.ascending = false;
          break;
        }
        case "accountName": {
          this.ascending = true;
          break;
        }
        default: {
          break;
        }
      }
    }
    this.orderReports();
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow
    this.searchTerm = '';
    this.filterReports();
  }

  filterReports() {
    if (!this.searchTerm) {
      this.filteredSubscriptions = this.subscriptionList;
    } else {
      this.filteredSubscriptions = this.genericPipe.transform(this.subscriptionList, this.searchTerm);
    }
    this.orderReports();
  }

  orderReports() {
    this.filteredSubscriptions = this.orderByPipe.transform(this.filteredSubscriptions, this.sortBy, this.ascending);
    this.paginate(1);
  }

  getReportName(name: string) {
    if (name === "BROKER_ENROLL") {
      return "Enrollment Report";
    }
    return this.toTitleCase(name.replace('_',' '));
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number) {
    if (this.filteredSubscriptions) {
      this.pager = this.pagerService.getPage(
        this.filteredSubscriptions,
        page,
        this.selectedDenom.count
      );
    }
  }
}
