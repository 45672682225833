import {Injectable, EventEmitter} from '@angular/core';
import {Http, Response,ResponseContentType, Headers} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DataService} from '../shared/data.service';
import {ISiteGroup, IIntervalSiteRequest, IIntervalSite} from "../shared/entities/site";
import {IIntervalUsageExportRequest} from "./intervalUsageReport";

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpHeaders, HttpResponse, HttpClient} from "@angular/common/http";
import {PortalService} from "../shared/portal.service";
import {Router} from "@angular/router";

@Injectable()
export class IntervalUsageReportService {

headers: HttpHeaders;

  // Bindables
  stepReached: number;
  selectedSites: IIntervalSite[] = [];
  startDate: string = '';
  endDate: string = '';
  quarterHourly: boolean;
  dataInterval: string = '';
  kwType: boolean = true;

    // EventEmitters
  onStepReached: EventEmitter<number> = new EventEmitter<number>();
  selectedSitesUpdated: EventEmitter<IIntervalSite[]> = new EventEmitter<IIntervalSite[]>();
  startDateUpdated: EventEmitter<string> = new EventEmitter<string>();
  endDateUpdated: EventEmitter<string> = new EventEmitter<string>();
  dataIntervalUpdated: EventEmitter<string> = new EventEmitter<string>();
  kwTypeUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService, private portalService: PortalService, private router: Router) {
     this.headers = new HttpHeaders({'Content-Type': 'application/json'});
   }

 setStepReached(step: number) {
    this.stepReached = step;
    this.onStepReached.emit(step);
  }

  setSelectedSites(sites: IIntervalSite[]) {
    this.selectedSites = sites;
    this.selectedSitesUpdated.emit(sites);
  }

  setStartDate(startDate: string){
    this.startDate = startDate;
    this.startDateUpdated.emit(startDate);
  }

  setEndDate(endDate: string){
    this.endDate = endDate;
    this.endDateUpdated.emit(endDate);
  }

  setDataInterval(dataInterval: string){
    this.dataInterval = dataInterval;
    this.dataIntervalUpdated.emit(this.dataInterval);
  }

  setKwType(kwType: boolean){
    this.kwType = kwType;
    this.kwTypeUpdated.emit(this.kwType);
  }

  getIntervalSites(request: IIntervalSiteRequest): Observable<IIntervalSite[]>{
    let qstr= '?group=' + request.groupId + '&metertype='+ request.meterType;
    let url = environment.production ? environment.getIntervalSitesUrl.replace('{accountId}', request.accountId.toString()) + qstr : environment.getIntervalSitesUrl;
    return this.http
    .get(url).pipe(
      map((data: IIntervalSite[])=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getRealTimeDataReportUrl(accountId: string): Observable<string> {
      let url = environment.realTimeDataReportUrl.replace('{accountId}', accountId.toString());
      return this.oldHttp
        .get(url)
        .map((response: Response) => <string>response.text())
        .catch(this.handleError);
  }

  exportIntervalUsage(request: IIntervalUsageExportRequest) {
    let url = environment.exportIntervalUsageUrl.replace('{accountId}', request.accountId);
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.router.navigate(['/interval-usage-report/export-confirmation']);
          this.dataService.setLoading(false);
        }
      );  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
