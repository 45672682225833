import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  EventEmitter
} from '@angular/core';
import { PortalService } from './shared/portal.service';
import { DataService } from './shared/data.service';
import { ManagePaymentMethodsService } from './manage-payment-methods/manage-payment-methods.service';
import { PaymentsService } from './payments/payments.service';
import { AutoPaymentsService } from './autopayments/autopayments.service';
import { IAccount, IAccountMessage } from './shared/entities/account';
import { ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Response, Headers, ResponseContentType } from '@angular/http';
import { FadeAnimation } from './animations/fadeAnimation';
import { Router } from '@angular/router';
import { FadeInAnimation } from './animations/fadeInAnimation';
import { IUserAccount, IProfile } from './shared/entities/profile';
import { IError } from './shared/entities/error';
import {
  IAutoPCIReturn,
  IAutoPCIValidateReturn,
  IAutoAccountBalance,
  IAutoPaymentMethod
} from './autopayments/autopayments';
import {
  IPCIReturn,
  IPCIValidateReturn,
  IAccountBalance,
  IPaymentMethod,
  IPCIQuickPayReturn
} from './payments/payments';
import * as moment from 'moment';
import { HelpTopicsService } from './help-topics/help-topics.service';
import { Stage, Dev, Test, Prod } from "./shared/const/properties";
import { CookieService } from 'ngx-cookie-service';
import { IAmendmentSite } from './shared/entities/amendments';
import { I4cpPreferences } from './shared/entities/fourcp';
import { Authority } from './shared/const/authority';
import { IContextualHelp, IHelpStep } from './shared/entities/contextualHelp';
import { Permissions } from './shared/const/permissions';
import { Subject } from 'rxjs';
import { _MatTabHeaderBase } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { MarketsService } from './markets/markets.service';
import { DefaultViewName } from './shared/entities/markets';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  animations: [FadeAnimation, FadeInAnimation],
  providers: [CookieService]
})
export class AppComponent implements OnInit {
  lhSurvey: boolean;
  title: string;
  selectedMetricsPage: string;
  authenticating: boolean = true;
  appProperties: any;
  myAccount: IUserAccount;
  myProfile: IProfile;
  errors: IError[] = [];
  accountWithContractData: IAccount;
  accountBalance: IAccountBalance;
  lastPaymentDisplay: string = '';
  nextPaymentDue: string = '';
  loading: boolean = false;
  loadingText: string;
  spinner: any;
  loggedIn: boolean = false;
  forgotPassword: boolean = false;
  passwordResetEmailSent: boolean = false;
  hideErcotExport: boolean = false;
  forgotEmail: boolean = false;
  resetPassword: boolean = false;
  createPassword: boolean = false;
  emailPassword: boolean = false;
  isDashboard: boolean = false;
  showingBalanceItems: boolean = false;
  showOverlay: boolean = false;
  pageTopics: Array<any>;
  dashboardSize: number = 0;
  showAmendmentsHeader: boolean;
  amendmentSite: IAmendmentSite;
  isFutureStart: boolean = false;
  isThirdParty: boolean = false;
  logoutTimeout: any;
  activityWarningTimeout: any;
  minutesRemaining: number;
  hasPermissions: boolean = true;
  autosavingAmendment: boolean = false;
  accessDenied: boolean = false;
  showWelcomeMessage: boolean = false;
  fourcpPreferences: I4cpPreferences;
  accountSpecificMessage: IAccountMessage;
  isSuperUser: boolean;
  viewPortalAccess: boolean;
  contextualHelpEnabled: boolean = true;
  nccaFlag: boolean = false;
  largeCustFlag: boolean = false;
  showCreditCard: boolean = false;
  showBankAccount: boolean = false;
  showAutopay: boolean = false;

  maskMode: boolean;
  maskModeSubscription: Subscription;
  selectedTab: string = '';
  outputPage = false;
  pricingPage = false;
  quickPay: boolean = false;
  pchbePage: boolean = false;
  isLocal: boolean = false;

  report: any;
  subscriptionValid: boolean = false;

  showContextualHelp: boolean;
  helpStep: IHelpStep;
  contextualHelp: IContextualHelp;
  //fourcpInfoUrl: string = "https://www.nrg.com/insights/energy-education/how-texas-businesses-can-reduce-their-demand-charges-through-4cp.html";
  fourcpInfoUrl: string =
    'https://www.nrg.com/business/all-products-and-services/4cp-alerts.html';
  quickPayUrl: string = 'https://www.reliant.com/public/quickPay.htm';
  positionReportDate: Date;
  billingUsageReportDate: Date;
  helpServiceBus = new Subject<string>();
  matrixBrokerPricingServiceBus = new Subject<string>();
  showGenerationDetailExport = false;
  showExportUsageDetail = false;
  showMatrixBrokerPricing: boolean = false;
  selfRegistration: boolean = false;
  openMDBSaveOptions: boolean = false;

  constructor(
    private portalService: PortalService,
    public dataService: DataService,
    private manageService: ManagePaymentMethodsService,
    private paymentService: PaymentsService,
    private autopaymentService: AutoPaymentsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private cookieService: CookieService,
    private helpTopicsService: HelpTopicsService,
    private managePaymentMethodsService: ManagePaymentMethodsService,
    public marketsService: MarketsService
  ) {
    this.initializeMessageBus();
  }

  getOriginUrl(): string {
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com";
    const stg1 = "https://stg1-manage.nrg.com";
    const test1 = "https://test1-manage.nrg.com";
    const pd = "https://manage.nrg.com";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf('dev1-') > -1) {
      this.dataService.setOriginUrl(dev1);
      return dev1;
    } else if (window.location.origin.indexOf('stg1-') > -1) {
      this.dataService.setOriginUrl(stg1);
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      this.dataService.setOriginUrl(test1);
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      this.dataService.setOriginUrl(pd);
      return pd;
    } else {
      this.dataService.setOriginUrl(local);
      return local;
    }
  }
  ngOnInit() {
    if (this.getOriginUrl().indexOf('localhost') != -1) {
      this.dataService.setIsLocal(true);
      this.isLocal = true;
    } else {
      this.dataService.setIsLocal(false);
      this.isLocal = false;
    }
    let qp = document.cookie.includes('clickedquickpay=1');
    let sr = document.cookie.includes('clickedselfregister=1');
    let qpLeave = document.cookie.includes('leftquickpay=1');
    let srLeave = document.cookie.includes('leftselfregister=1');
    //let accessToken = document.cookie.includes('accessToken');
    let accessToken = window.localStorage.getItem('access_token');

    //console.log(document.cookie);
    if (qp) {
      this.quickPay = true;
      // console.log("after replace");
      // console.log(document.cookie);
      //window.location.replace(this.getOriginUrl() + "/#/quick-pay");
      this.router.navigate(['/quick-pay']);
    } else if (sr) {
      this.selfRegistration = true;
      this.router.navigate(['/self-register']);
    }
    else if (qpLeave) {
      document.cookie = "leftquickpay=0";
      if (this.isLocal) {
        window.location.replace(this.getOriginUrl() + "/login.html");
      } else {
        window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
      }
    }
    else if (srLeave) {
      document.cookie = "leftselfregister=0";
      if (this.isLocal) {
        window.location.replace(this.getOriginUrl() + "/login.html");
      } else {
        window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
      }
    }

    else if (!qp && !sr) {
      if (!accessToken) {
        if (this.isLocal) {
          window.location.replace(this.getOriginUrl() + "/login.html");
        } else {
          window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
        }
      }
      if (window.location.href.match('localhost')) {
        this.dataService.setAppProperties(Dev);
      } else if (window.location.href.match('dev')) {
        this.dataService.setAppProperties(Dev);
        this.insertLaunchTag();
      } else if (window.location.href.match('stg')) {
        this.dataService.setAppProperties(Stage);
        this.insertLaunchTag();
      } else if (window.location.href.match('test1')) {
        this.dataService.setAppProperties(Test);
        this.insertLaunchTag();
      } else if (window.location.href.match('manage')) {
        this.dataService.setAppProperties(Prod);
        this.insertLaunchTag();
      }
    }


    if (screen.width < 800) {
      this.dataService.setIsMobile(true);
    } else {
      this.dataService.setIsMobile(false);
    }

    this.dataService.resetPasswordUpdated.subscribe((resetPassword) => {
      this.resetPassword = resetPassword;
    });

    this.dataService.setCurrentActivePage('dashboard');

    this.dataService.selectedMarketReportUpdated.subscribe((tab) => {
      this.selectedTab = tab;
    });

    this.dataService.outputPageTriggered.subscribe((onOutputPage) => {
      this.outputPage = onOutputPage;
    });

    this.dataService.largeCustUpdated.subscribe(bool => {
      this.largeCustFlag = bool;
    })

    this.dataService.nccaFlagUpdated.subscribe(bool => {
      this.nccaFlag = bool;
    })
    this.dataService.showCreditCardUpdated.subscribe(bool => {
      this.showCreditCard = bool;
    });

    // this.dataService.showBankAccountUpdated.subscribe(bool => {
    //   this.showBankAccount = bool;
    // })

    this.dataService.showAutopayUpdated.subscribe((bool) => {
      this.showAutopay = bool;
    })

    this.dataService.createPasswordUpdated.subscribe(
      (createPassword) => {
        this.createPassword = createPassword;
      }
    );

    this.dataService.dashboardSizeUpdated.subscribe((size) => {
      this.dashboardSize = size;
    });

    this.dataService.overlayUpdated.subscribe((overlay) => {
      this.showOverlay = overlay;
    });

    this.dataService.hideErcotExportUpdated.subscribe((hideErcotExport) => {
      this.hideErcotExport = hideErcotExport;
    });

    this.dataService.emailPasswordUpdated.subscribe((emailPassword) => {
      this.emailPassword = emailPassword;
      if (this.emailPassword) {
        let brandIndex = window.location.href.indexOf('?brand=');
        if (brandIndex > -1) {
          let brand = window.location.href.substring(brandIndex + 7);
          this.router.navigate(['/email-password'], {
            queryParams: { brand: brand }
          });
        } else {
          this.router.navigate(['/email-password'], {
            queryParams: { brand: '' }
          });
        }
      }
    });

    this.dataService.profileUpdated.subscribe((profile) => {
      this.myProfile = profile;
      if (profile == null) {
        this.hasPermissions = false;
      }
      else {
        this.showWelcomeMessage =
          !profile.firstLogin ||
          moment().subtract(30, 'days').isBefore(profile.firstLogin);
      }
    });

    this.dataService.showAmendmentsHeaderUpdated.subscribe((show) => {
      this.showAmendmentsHeader = show;
    });

    this.dataService.selectedAmendmentSiteUpdated.subscribe((site) => {
      this.autosavingAmendment = true;
      setTimeout(() => {
        this.autosavingAmendment = false;
      }, 1000);
      this.amendmentSite = site;
    });

    this.appProperties = this.dataService.getAppProperties();
    let changingPassword =
      window.location.href.match('create-password') ||
      window.location.href.match('reset-password') ||
      window.location.href.match('forgot-password') ||
      window.location.href.match('email-password');
    if (window.location.href.match('email-password')) {
      this.dataService.setEmailPassword(true);
    }

    if (this.appProperties) {
      this.portalService.authenticate().subscribe(
        (auth) => {
          if (auth) {
            this.hasPermissions = true;
            this.authenticating = false;
            this.dataService.setLoggedIn(true);
          } else {
            if (!changingPassword) {
              window.location.href = this.appProperties.loginUrlNrg;
              this.dataService.setLoggedIn(true);
            }
            this.authenticating = false;
          }
        },
        (err) => {
          console.error(err);
          if (!changingPassword) {
            window.location.href = this.appProperties.loginUrlNrg;
          }
          this.authenticating = false;
        }
      );
    } else {
      this.authenticating = false;
    }

    this.dataService.loggedInUpdated.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.dataService.forgotPasswordUpdated.subscribe((forgotPassword) => {
      this.forgotPassword = forgotPassword;
      if (this.forgotPassword) {
        this.router.navigate(['/forgot-password']);
      }
    });

    this.dataService.quickPayUpdated.subscribe((quickPay) => {
      this.quickPay = quickPay;
      if (this.quickPay) {
        this.router.navigate(['/quick-pay']);
      }
    });

    this.dataService.resetPasswordUpdated.subscribe((resetPassword) => {
      this.resetPassword = resetPassword;
      if (this.resetPassword) {
        let keyIndex = window.location.href.indexOf('?key=');
        let brandIndex = window.location.href.indexOf('&brand=');
        let passwordResetKey = window.location.href.substring(
          keyIndex + 5,
          brandIndex
        );
        let brand = window.location.href.substring(brandIndex + 7);
        if (keyIndex > -1) {
          this.router.navigate(['/reset-password'], {
            queryParams: { key: passwordResetKey, brand: brand }
          });
        } else {
          this.router.navigate(['/reset-password'], {
            queryParams: { key: '' }
          });
        }
      }
    });

    this.dataService.forgotEmailUpdated.subscribe((forgotEmail) => {
      this.forgotEmail = forgotEmail;
    });

    this.dataService.accountWithContractDataSourceUpdated.subscribe(
      (account) => {
        this.accountWithContractData = account;
      }
    );

    this.dataService.balanceDueUpdated.subscribe((balanceDue) => {
      this.accountBalance = balanceDue;
      if (balanceDue) {
        if (this.accountBalance.paymentDate) {
          if (this.accountBalance.paymentAmount) {
            this.accountBalance.paymentAmount =
              this.accountBalance.paymentAmount;
          }
          this.lastPaymentDisplay = moment(
            this.accountBalance.paymentDate
          ).format('MMM D, YYYY');
        } else {
          this.lastPaymentDisplay = 'Not Available';
        }
        if (!this.accountBalance.pastDueAmount) {
          if (this.accountBalance.nextPaymentDue) {
            this.nextPaymentDue = moment(
              this.accountBalance.nextPaymentDue
            ).format('MMM D, YYYY');
          } else {
            this.nextPaymentDue = 'Not Available';
          }
        } else {
          this.nextPaymentDue = 'Past Due';
        }
      } else {
        //Handle 404 error accountBalance
        this.showingBalanceItems = false;
        if (this.accountBalance) {
          this.accountBalance.balance = null;
        }
      }
    });

    if (window.location.href.match('reset-password')) {
      this.dataService.setResetPassword(true);
    }

    if (window.location.href.match('create-password')) {
      this.dataService.setCreatePassword(true);
    }

    if (window.location.href.match('forgot-password')) {
      this.dataService.setForgotPassword(true);
    }

    this.dataService.loadingUpdated.subscribe((loading) => {
      this.loading = loading;
      if (loading) {
        this.portalService.spinBabySpin();
      }
    });

    this.dataService.loadingTextUpdated.subscribe((loadingText) => {
      this.loadingText = loadingText;
    });
    this.loadingText = this.dataService.getLoadingText();

    this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;

      // if(this.myAccount.accessType !== 'ThirdParty') {
      //       this.portalService.getComvergeAccess(this.myAccount.id).subscribe((response) => {
      //         // console.log('response comverge value',response);
      // if (response) {
      //   this.myAccount.comverge = 1;
      // } else if (!response) {
      //   this.myAccount.comverge = 0;
      // }
      //       });
      //   }
      // Survey start

      if (typeof Storage !== 'undefined') {
        window.localStorage.setItem('brand', this.myAccount.accountBranding); //NRG/Reliant
        window.localStorage.setItem('userType', this.myAccount.accessType); //Direct/ThridParty
        window.localStorage.setItem('email', this.myProfile.email);
        window.localStorage.setItem('org', this.myProfile.organization);
        window.localStorage.setItem('customerName', this.myAccount.name);
        window.localStorage.setItem('customerId', this.myAccount.id);
        this.cookieService.set('brand', this.myAccount.accountBranding);
        this.cookieService.set('userType', this.myAccount.accessType);
        this.cookieService.set('email', this.myProfile.email);
        this.cookieService.set('org', this.myProfile.organization);
        this.cookieService.set('customerName', this.myAccount.name);
        this.cookieService.set('customerId', this.myAccount.id);

        this.portalService.updatePersonalizationProfileWithAccountAttributes(
          this.myAccount.id
        );

        // Store
        // localStorage.setItem("brand", "NRG"); //NRG/Reliant
        // localStorage.setItem("userType", "Direct"); //Direct/ThridParty
        // localStorage.setItem("email", "greg.glover@nrg.com");
        // localStorage.setItem("org", "NRG");
        // localStorage.setItem("customerName", "City of Houston");
        // localStorage.setItem("customerId", "I-QR2ZT");
      }

      //          this.lhSurvey = this.dataService.gethSurvey();
      //               if (this.lhSurvey) {

      //                 $(document).ready(function() {
      //                   // alert("Hello! I am an  alert box inside app function!!");

      //                     console.log("Firing script for loading Maritz Survey xyz");
      //                   var node1 = document.createElement('script');
      //                   node1.type = 'text/JavaScript';
      //                   node1.src = 'https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js';
      //                   // document.body.removeChild(node1);
      //                   document.body.appendChild(node1);

      //                    var node2 = document.createElement('script');
      //                   node2.type = 'text/JavaScript';
      //                   node2.src = 'https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js';
      //                   // document.body.removeChild(node2);
      //                   document.body.appendChild(node2);

      //                 });

      //             this.dataService.sethSurvey(false);

      //  }

      //  call 2

      //  this.lhSurvey = this.dataService.gethSurvey();
      //   if (this.lhSurvey) {

      //     $.getScript("https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js")
      //             .done(() => {  });

      //     setTimeout(function(){
      //       $.getScript("https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js")
      //             .done(() => { });
      //         }, 6000);
      //   this.dataService.sethSurvey(false);

      //   }

      // $.getScript("https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js")
      //   .done(() => { alert("done") });

      //  $.getScript("https://siteintercept.allegiancetech.com/InterceptScripts/mcxSiteIntercept-1.6.4.js")
      //   .done(() => { alert("done") });

      // this.myAccount.comverge = 0;
      // console.log('comverge value',this.myAccount.comverge);
      // console.log('id',this.myAccount.id);
      // this.dataService.setBalanceDue(null);
      this.viewPortalAccess = this.portalService.hasPermission(
        Permissions.ViewPortal
      );
      // console.log("testing access",this.viewPortalAccess);

      if (this.myAccount.accessType == 'ThirdParty') {
        this.isThirdParty = true;
        // this.goTotpDashboard();
      } else if (this.myAccount.accessType != 'ThirdParty') {
        this.isThirdParty = false;
        // this.goToDashboard();
      }
    });

    this.dataService.titleSourceUpdated.subscribe((title) => {
      this.title = title;
      this.isDashboard = this.title.indexOf('Welcome') >= 0;
      this.isFutureStart = this.title.indexOf('Future Start') >= 0;
      this.cdr.detectChanges();
    });

    this.dataService.selectedMetricsPageUpdated.subscribe(
      (selectedMetricsPage) => {
        this.selectedMetricsPage = selectedMetricsPage;
      }
    );

    this.dataService.errorAdded.subscribe((error) => {
      this.showOverlay = true;
      this.errors.push(error);
    });

    this.dataService.fourcpPreferencesUpdated.subscribe((fourcpPreferences) => {
      this.fourcpPreferences = fourcpPreferences;
    });
    this.dataService.accountSpecificMessageUpdated.subscribe(
      (accountSpecificMessage) => {
        this.accountSpecificMessage = accountSpecificMessage;
      }
    );

    $('#myModal').modal({ show: false });
    if (this.dataService.getAppProperties()) {
      this.getDalHealthCheck();
    }
    this.dataService.accessDeniedUpdated.subscribe((accessDenied) => {
      this.accessDenied = accessDenied;
    });


    let user = this.dataService.getUserSource();
    if (user) {
      this.isSuperUser = this.portalService.userHasAuthority(
        Authority.SuperUser
      );
    }
    this.dataService.userSourceUpdated.subscribe((user) => {
      this.isSuperUser = this.portalService.userHasAuthority(
        Authority.SuperUser
      );
    });

    this.dataService.contextualHelpToggle.subscribe(() => {
      this.toggleContextualHelp();
    });

    // console.log("subscribing to validity");
    this.dataService.subscriptionValidity.subscribe((valid) => {
      // console.log("Subscription validity: ", valid);
      this.subscriptionValid = valid;
    });

    this.dataService.contextualHelpEnabled.subscribe((enabled) => {
      this.contextualHelpEnabled = enabled;
    });

    //     this.dataService.positionReportDateUpdated.subscribe((positionReportDate) => {
    //       console.log("Position Report Date: ", positionReportDate);
    //       this.positionReportDate = positionReportDate;
    //     });

    this.dataService.billingUsageReportDateUpdated.subscribe(
      (billingUsageReportDate) => {
        console.log('billingUsage Report Date: ', billingUsageReportDate);
        this.billingUsageReportDate = billingUsageReportDate;
      }
    );

    this.dataService.pricingPageTriggered.subscribe(
      (pricingPageTriggered: boolean) => {
        this.pricingPage = pricingPageTriggered;
      }
    );

    this.dataService.pchbePageTriggered.subscribe(
      (pchbePageTriggered: boolean) => {
        this.pchbePage = pchbePageTriggered;
      }
    );

    this.dataService.showGenerationDetailExport.subscribe(
      (showGenerationDetailExport) => {
        this.showGenerationDetailExport = showGenerationDetailExport;
      }
    );
    this.dataService.showExportUsageDetail.subscribe(
      (showExportUsageDetail) => {
        this.showExportUsageDetail = showExportUsageDetail;
      }
    );

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );

    this.dataService.selfRegistrationUpdated.subscribe((selfRegistration) => {
      this.selfRegistration = selfRegistration;
      if (this.selfRegistration) {
        this.router.navigate(['/self-register']);
      } else {
        // window.location.href = this.appProperties.loginUrlNrg;
        window.location.href = 'localhost:8880/login.html';
      }
    });
    // Click outside event for date range and groupings sections
    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#market-dashboard-settings-menu-section').length == 0) {
        this.dataService.openMDBViewOptions = false;
      }
    });
    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#market-dashboard-save-section').length == 0) {
        this.dataService.openMDBSaveOptions = false;
      }
    });
  }

  ngOnDestroy() {
    this.cookieService.delete('logged-in');
    this.cookieService.delete('SESSION');
    this.cookieService.delete('JSESSIONID');
    window.localStorage.removeItem('profileInit');
    this.dataService.nccaFlagUpdated.unsubscribe();
    this.dataService.largeCustUpdated.unsubscribe();
    this.dataService.showCreditCardUpdated.unsubscribe();
    // this.dataService.showBankAccountUpdated.unsubscribe();
    this.dataService.showAutopayUpdated.unsubscribe();
    this.dataService.selectedMarketReportUpdated.unsubscribe();
    this.dataService.outputPageTriggered.unsubscribe();
    // this.cookieService.delete("default-account");
    this.dataService.contextualHelpEnabled.unsubscribe();
    this.dataService.showGenerationDetailExport.unsubscribe();
    this.dataService.showExportUsageDetail.unsubscribe();
    this.portalService.logout().subscribe();
  }

  fullscreenmarketdb() {
    this.dataService.isMarketDBFullscreen = true;
  }

  savemarketdb(
    openModal: boolean = false,
    defaultMarketViewName: string = null,
    isRenamed: boolean = false
  ) {
    if (!isRenamed && this.marketsService.settingsNotChanged) {
      return;
    }
    if (
      this.dataService.defaultMarketViewName.toUpperCase() ===
      DefaultViewName.toUpperCase()
    ) {
      // works as "save as". open modal.
      openModal = true;
    }
    this.dataService.setMarketDB(openModal, defaultMarketViewName, isRenamed);
  }

  deleteMarketDb() {
    this.dataService.deleteMarketDb();
  }

  addWidget() {
    let target = $('#add-widget');
    $('html, body').animate(
      {
        scrollTop: target.offset().top - 30
      },
      800
    );

    setTimeout(() => {
      this.dataService.triggerAddWidget();
    }, 400);
  }

  navigate(dest: string, navItem?: string, subNavPos?: number) {
    this.router.navigate(['/' + dest]);

    if (navItem) {
      // Navigating from within the subnav
      this.dataService.setSelectedNavItem(navItem);
    } else {
      // Immediate Navigation (e.g. Contact Us)
      this.dataService.setSelectedNavItem(dest);
    }
  }

  toggleContextualHelp() {
    if (!this.showContextualHelp) {
      this.helpTopicsService
        .getContextualHelp(this.title)
        .subscribe((response: any) => {
          this.showContextualHelp = true;
          response.contentPayload.children[0].payload.summary =
            response.contentPayload.children[0].payload.summary.split(
              '<br/><br/>'
            );
          this.contextualHelp = response.contentPayload.children[0].payload;
          this.contextualHelp.steps.forEach((step) => {
            step.description = step.description.replace('<br/><br/>', ' ');
          });
          let stepNum = 1;
          this.contextualHelp.steps.forEach((step) => {
            step.stepNum = stepNum;
            stepNum++;
          });
          this.helpStep = this.contextualHelp.steps[0];
          this.dataService.setHelpStep(this.helpStep);
        });
    } else {
      this.helpStep = null;
      this.dataService.setHelpStep(null);
      this.showContextualHelp = false;
    }
  }

  nextHelpStep() {
    let currentStep = this.helpStep.stepNum;
    this.helpStep = this.contextualHelp.steps[currentStep];
    this.dataService.setHelpStep(this.helpStep);
  }

  selectHelpStep(step: IHelpStep) {
    this.helpStep = step;
    this.dataService.setHelpStep(step);
  }

  triggerMultipleInvoice() {
    this.dataService.triggerMultipleInvoice();
  }

  triggerRealTimeExport() {
    this.dataService.triggerRealTimeExport();
  }

  triggerGenerationDetailExport() {
    this.dataService.generationDetailExportTriggered.emit();
  }

  closeError(index: number) {
    this.errors.splice(index, 1);
    if (this.errors.length == 0) {
      this.showOverlay = false;
      this.dataService.setLoading(false);
    }
  }

  dismissAll() {
    this.errors = [];
    this.showOverlay = false;
    this.dataService.setLoading(false);
  }

  exportToExcel() {
    this.dataService.triggerExport();
  }

  openRealTimeDataReport() {
    this.dataService.triggerRealTimeDataReport();
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

  initializeMessageBus() {
    this.helpServiceBus.subscribe((msg) => {
      console.log('Toggling contextual help for ' + msg);
      this.dataService.setTitleSource(msg);
      this.dataService.toggleContextualHelp();
    });

    this.matrixBrokerPricingServiceBus.subscribe((msg) => {
      console.log('Toggling mbp for ' + msg);
      this.dataService.setTitleSource(msg);
      this.dataService.toggleContextualMatrixBrokerPricing();
      this.showMatrixBrokerPricingModal();
    });

    window['_helpServiceBus'] = this.helpServiceBus;
    window['_matrixBrokerPricingServiceBus'] =
      this.matrixBrokerPricingServiceBus;
  }

  showMatrixBrokerPricingModal() {
    this.showMatrixBrokerPricing = true;
    $('#matrixBrokerPricingModal').modal('show');
    console.log('showing mbp modal maybe');
  }

  disableMatrixBrokerPricing() {
    this.showMatrixBrokerPricing = false;
    $('#matrixBrokerPricingModal').modal({
      show: false
    });
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.startTimeout();
  }

  @HostListener('window:message', ['$event'])
  PCICallback($event: MessageEvent) {
    // if ($event.origin !== "https://dev1-pay.manage.nrg.com")
    //   return;
    let qpmessage = $event.data.message;
    let caller: string = $event.data.caller;
    let cancelled: string = $event.data.isCancelled;
    let token: string = $event.data.token;
    let id: string = $event.data.id;
    let paymentType: string = $event.data.paymentType;
    let rememberPaymentPCI: string = $event.data.rememberPayment;
    let login: string = $event.data.login;

    let info: IPCIReturn = {
      accountId: id,
      paymentToken: token,
      paymentType: paymentType,
      isCancelled: cancelled,
      paymentMethods: [],
      rememberPayment: rememberPaymentPCI
    };
    let infoAuto: IAutoPCIReturn = {
      accountId: id,
      paymentToken: token,
      paymentType: paymentType,
      isCancelled: cancelled,
      paymentMethods: [],
      rememberPayment: rememberPaymentPCI
    };

    if (cancelled == 'false' && info.rememberPayment) {
      let methodInfo: IPaymentMethod = {
        userPaymentSettingsKey: {
          paymentToken: token,
          accountId: this.dataService.getAccountSource().id,
          userId: this.dataService.getUserSource().id.toString()
        },
        nickName: $event.data.nickName,
        defaultPayment: 0,
        routingNumber: $event.data.routing,
        paymentType: $event.data.paymentType,
        cardExpiryMonth: $event.data.cardExpiryMonth,
        cardExpiryYear: $event.data.cardExpiryYear,
        cardZipCode: $event.data.cardZipCode,
        cardBrand: $event.data.cardBrand,
        cvv: $event.data.cvv,
        rememberPayment: $event.data.rememberPayment,
        maskedAccountNumber: 0,
        maskedCardNumber: 0
      };


      console.log("method created", methodInfo.nickName);

      //custom post call to url : /private/api/users/accounts/{accountId}/paymentSettings, login = userId, do not send cvv
      //bank acc // cc , accountId, nickName, paymentType = "BANKACCOUNT" or "CARD", routingNumber, brand, expMonth & year, zipcode

      //conversions to custom post are handled in the service call
      this.paymentService
        .pciCreatePaymentMethod(
          methodInfo,
          cancelled,
          info.rememberPayment,
          login
        )
        .subscribe((resp) => {
          console.log(resp);
        });
    }

    // TODO: Refactor after Ravi makes his change
    if (cancelled == 'true') {
      if (caller == 'manageEdit') {
        this.manageService.pciEditCancel(info);
      } else if (caller == 'manageNew') {
        this.manageService.pciNewCancel(info);
      } else if (caller == 'paymentManageEdit' || caller == 'makePayment') {
        // || caller == "newPayment") {
        //TODO: call from payment method
        // Analysis :  control comes here from "Make a Payment" UI
        // if(!window.location.href.match('autopay-info'))
        this.paymentService.pciEditCancel(info);
        // else
        //   this.autopaymentService.pciEditCancel(info);
      } else if (caller == 'newPaymentMethod') {
        this.paymentService.pciEditCancel(info);
      }
      return;
    }
    //makePayment
    //if (caller == "newPayment") {
    if (caller == 'makePayment' || caller == 'newPaymentMethod') {
      let paymenttype: string = $event.data.paymentType;
      if (paymenttype == 'BANKACCOUNT') {
        let routing: string = $event.data.routing;
        let masked: string = token.substr(token.length - 3);
        let validate: IPCIValidateReturn = {
          paymentType: paymenttype,
          paymentToken: token,
          routingNumber: routing,
          maskedAccountNumber: masked,
          maskedCardNumber: '',
          cardBrand: '',
          cardExpYear: '',
          cardExpMonth: '',
          cvv: '',
          nickname: $event.data.nickName,
          isCancelled: cancelled,
          cardZipCode: '',
          rememberPayment: rememberPaymentPCI
        };
        // console.log('New Payment detected', validate);
        this.paymentService.pciCreateCallback(validate);
      } else if ($event.data.paymentType == 'CARD') {
        let routing: string = $event.data.routing;
        let masked: string = token.substr(token.length - 3);
        let validate: IPCIValidateReturn = {
          paymentType: paymenttype,
          paymentToken: token,
          routingNumber: '',
          maskedAccountNumber: '',
          maskedCardNumber: masked,
          cardBrand: $event.data.cardBrand,
          cardExpYear: $event.data.cardExpiryYear,
          cardExpMonth: $event.data.cardExpiryMonth,
          nickname: $event.data.nickName,
          cvv: $event.data.cvv,
          cardZipCode: $event.data.cardZipCode,
          isCancelled: cancelled,
          rememberPayment: rememberPaymentPCI
        };
        // console.log('New Payment detected', validate);
        this.paymentService.pciCreateCallback(validate);
      }
    } else if (caller == 'manageEdit') {
      this.manageService.pciEditCallback(info);
    } else if (caller == 'paymentManageEdit') {
      // console.log("caller was paymentManageEdit");
      // if(!window.location.href.match('autopay-info'))
      this.paymentService.pciEditCallback(info);
    } else if (caller == 'manageNew') {
      this.manageService.pciRefresh(info);
    } else if (caller == 'quickPay' || qpmessage) {
      let caName: string = $event.data.caName;
      let caNumber: string = $event.data.caNumber;
      let paymentAmount: string = $event.data.paymentAmount;
      let paymentDate: string = $event.data.paymentDate;
      let payType: string = $event.data.paytype;
      let confirmationNumber: string = $event.data.confirmationNumber;
      let message: string = $event.data.message;

      let info: IPCIQuickPayReturn = {
        caName: caName,
        caNumber: caNumber,
        paymentAmount: paymentAmount,
        paymentDate: paymentDate,
        paytype: payType,
        confirmationNumber: confirmationNumber,
        message: message
      };

      this.paymentService.pciQuickPayCallback(info);
    }
  }

  getDalHealthCheck() {
    this.portalService.getDalHealthCheck().subscribe(
      () => { },
      (err) => {
        this.showDalUnresponsive();
      }
    );
  }

  getPortalHealthCheck() {
    this.portalService.getPortalHealthCheck().subscribe(
      () => { },
      (err) => {
        this.showDalUnresponsive();
      }
    );
  }

  startTimeout() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
    if (this.activityWarningTimeout) {
      clearTimeout(this.activityWarningTimeout);
    }
    this.activityWarningTimeout = setTimeout(() => {
      //console.log('--------in Warning Time out 55min');
      $('#timeoutWarningModal').modal('show');
      this.minutesRemaining = 5;
      this.minuteCountdown();
    }, 3300000); //55min
    this.logoutTimeout = setTimeout(() => {
      //console.log('--------in LOGOUT Time out 60min');
      this.portalService.logout().subscribe();
    }, 3600000); //60min
  }

  minuteCountdown() {
    setTimeout(() => {
      this.minutesRemaining--;
      if (this.minutesRemaining) {
        this.minuteCountdown();
      }
    }, 60000);
  }

  showDalUnresponsive() {
    $('#healthCheckModal').modal({
      show: true,
      backdrop: 'static'
    });
  }

  // moved this logic to header.component.ts
  // goToDashboard() {
  //   if (this.viewPortalAccess || this.isSuperUser) {
  //     this.router.navigate(['/dashboard']);
  //   } else {
  //     // if dgen only
  //     // if 4CP only or dgen + 4CP
  //     this.router.navigate(['/fourcp/overview']);
  //   }
  // }
  // moved this logic to header.component.ts
  // goTotpDashboard() {
  //   // console.log('going to Tp dashboard');
  //   setTimeout(() => {
  //     // I have no idea why this is needed. otherwise it goes to 4CP dashboard.
  //     this.router.navigate(['/third-party']);
  //   }, 50);
  // }

  goToBrokerConfirm() {
    this.router.navigate(['/broker-confirm']);
  }

  goToBillingDelivery() {
    this.router.navigate(['/billing-delivery']);
  }

  goToPaymentHistory() {
    this.router.navigate(['/payment-history']);
  }

  goToAmendmentsCart() {
    // console.log("Go to amendments cart: ", this.dataService.getSelectedAmendmentRequest());
    this.router.navigate(['/amendments/cart']);
  }

  showBalanceItems() {
    if (this.viewPortalAccess) {
      this.showingBalanceItems = true;
    }
  }

  hideBalanceItems() {
    this.showingBalanceItems = false;
  }

  makePayment() {
    this.router.navigate(['/payments']);
  }

  goToWelcomePage() {
    this.router.navigate(['/welcome']);
  }

  upgradeToPremium() {
    this.fourcpPreferences.subscriptionType = 'PREMIUM';
    this.dataService.setFourcpPreferences(this.fourcpPreferences);
  }

  toggleInSeason() {
    this.dataService.toggleInSeason();
  }

  setSubscriptionClasses() {
    let classes = {
      pointer: this.subscriptionValid,
      disabled: !this.subscriptionValid
    };
    return classes;
  }

  toggleSubscriptionModal() {
    this.dataService.toggleSubscriptionModal();
  }

  numberWithCommas(x) {
    return this.portalService.numberWithCommas(x);
  }

  insertLaunchTag() {
    let properties = this.dataService.getAppProperties();

    if (properties != null && properties.adobeLaunchURL != null) {
      var sNew = document.createElement('script');
      sNew.async = true;
      sNew.src = properties.adobeLaunchURL;
      var s0 = document.getElementsByTagName('script')[0];
      s0.parentNode.insertBefore(sNew, s0);
    }
  }

  hasRequiredCompanyCode(accountWithContractData: IAccount) {
    return (
      accountWithContractData.contracts.filter(
        (contract) => contract.companyCode == '0017'
      ).length == 0
    );
  }

  openDBSettingsSaveOptions() {
    this.dataService.openMDBSaveOptions = !this.dataService.openMDBSaveOptions;
  }

  toggleMDBViewOptions() {
    this.dataService.openMDBViewOptions = !this.dataService.openMDBViewOptions;
  }
}
