import {Injectable, EventEmitter} from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import { delay } from 'rxjs/operators';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {Widget} from "../shared/entities/widgets/widget";
import {IUserAccount} from "../shared/entities/profile";
import {IDashboard, IWidgetUpdateRequest} from "../shared/entities/dashboard";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {PortalService} from "../shared/portal.service";
import {ITealiumRequest} from "../shared/entities/tealium";
import {IContactInfo, ICompanyContactInfo, IUsefulContactInfo} from '../shared/entities/contacts';
import { CacheService } from '../cache.service';

@Injectable()
export class DashboardService {

  headers: HttpHeaders;
  addedWidgetTriggered = new EventEmitter<any>();
  placeholderWidgetTriggered = new EventEmitter<any>();

  constructor(private httpClient: HttpClient, private oldHttp: Http, private portalService: PortalService, private cacheService: CacheService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  triggerAddedWidget(widget) {
    this.addedWidgetTriggered.emit(widget);
  }

  triggerPlaceholderWidget(widget) {
    this.placeholderWidgetTriggered.emit(widget);
  }

  getDashboard(account: IUserAccount): Observable<any>{
    // Call Micah's API
    let url = environment.production ? environment.dashboardUrl.replace('{accountId}', account.id) : environment.dashboardUrl;
    return this.httpClient
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  deleteWidget(request: IWidgetUpdateRequest) {
    let widgetId = request.widgetId.toString();
    let url = environment.production ? environment.deleteWidgetUrl.replace('{accountId}', request.accountId).replace('{widgetId}', widgetId) : environment.deleteWidgetUrl;
    return this.httpClient
      .post(url, request, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  addOrUpdateWidget(request: IWidgetUpdateRequest) {
    let url = environment.production ? environment.widgetsUrl.replace('{accountId}', request.accountId).replace('{widgetId}', request.widgetId ? request.widgetId.toString() : '-1'): environment.widgetsUrl;
    return this.httpClient
      .post(url, request, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  updateWidget(account: IUserAccount, widget: Widget) {
    let url = environment.production ? environment.widgetsUrl.replace('{accountId}', account.id).replace('{widgetId}', widget.widgetId.toString()) : environment.widgetsUrl;
    return this.httpClient
      .post(url, widget, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  reorderWidgets(dashboard: IDashboard) {
    let url = environment.production ? environment.dashboardUrl.replace('{accountId}', dashboard.accountId) : environment.dashboardUrl;
    return this.httpClient
      .post(url, dashboard, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }


  getRealTimeDataReportUrl(accountId: string): Observable<string> {
      let url = environment.realTimeDataReportUrl.replace('{accountId}', accountId.toString());
      return this.oldHttp
        .get(url)
        .map((response: Response) => <string>response.text())
        .catch(this.handleError);
  }


  getWidgetData(request: IWidgetUpdateRequest, changeMade? : boolean) {
    let url, key;
    let data;
    if(request.widgetName == 'USAGESUMMARY' || request.widgetName == 'INTERVALUSAGE'){
      url = environment.usageWidgetChartDataUrl;
      key = 'USAGESUMMARY' + request.widgetId.toString();
    } else {
      url = environment.billingWidgetChartDataUrl;
      key = 'BILLINGDATA' + request.widgetId.toString();
    }
    data = this.cacheService.get(key);
    if(data && !changeMade){
      // Need to artificially delay this cache call to give hicharts time to render in the dom (.01 sec)
      return of(data).pipe(delay(10));
    } else {
      url = url.replace('{accountId}', request.accountId).replace('{widgetName}', request.widgetName);
      return this.httpClient
        .post(url, request, {headers: this.headers})
        .map((response: HttpResponse<any>) => {
          this.cacheService.set(key, response);
          return response;
        })
        .catch(this.handleError);
    }
  }

  getPromoData() {
    let url = environment.production ? environment.promoContent : environment.promoContent;
    let profile = this.portalService.getPersonalizationProfile(true);

    return this.httpClient
      .post(url, profile, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }
  
  createTealium(request: ITealiumRequest) {
    let url = environment.production ? environment.tealiumCreate.replace('{accountId}', request.user_unique_id) : environment.tealiumCreate.replace('{accountId}', request.user_unique_id);

    return this.httpClient
      .post(url, request, {headers: this.headers})
      .map((response: HttpResponse<any>) => response);
  }
  
  getTealium(accountId: string, tealId: string): Observable<any>{
    let url = environment.production ? environment.tealiumGet.replace('{accountId}', accountId) : environment.tealiumGet.replace('{accountId}', accountId);
	url += '/' + tealId;
	
    return this.httpClient
      .get(url)
      .map((response: HttpResponse<any>) => response);
  }
  
  // Get contact information by account ID
  getContactsForAccount(accountId: string, thirdParty: boolean): Observable<IContactInfo> {
    let url = environment.production ? environment.contactUrl.replace('{accountId}', accountId.toString()) : environment.contactUrl;
    url = url.replace('{thirdParty}', thirdParty.toString());
    return this.httpClient
      .get(url)
      .map((response: Response) => <IContactInfo>response.json())
      .catch(this.noContact);
  }
  
  private noContact(error: Response) {
    return new Observable<IContactInfo>();
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
  
}
