export const nrgNav = {
    "contentPayload": {
        "isAuthorEnv": false,
        "route": "/navigation.html",
        "last modified": "2022-04-06T06:06:33.404-05:00",
        "pageTitle": "",
        "type": "/conf/nrg/settings/wcm/templates/pg-navigation",
        "title": "Navigation",
        "uuid": "73837521-b181-327b-af01-6b758ee6a4b9",
        "damPath": "/content/dam/nrg/content/navigation",
        "tags": {
            "lastModified": "",
            "lastModifiedBy": "",
            "topic": "",
            "year": "",
            "category": "",
            "personalizationTags": [
                "nrg:templates/pages/navigation"
            ]
        },
        "personalizationTags": {},
        "metadata": {
            "pagePath": "https://dev-www.nrg.com/navigation.html",
            "canonical": "https://dev-www.nrg.com/navigation.html"
        },
        "children": [
            {
                "type": "LayoutOneColumn",
                "payload": {
                    "liteFooter": false,
                    "breadcrumb": {
                        "label": "",
                        "route": "",
                        "icon": "",
                        "behavior": "",
                        "type": "",
                        "layout": "",
                        "v2Page": false,
                        "overrideAction": false,
                        "navBehavior": "floating"
                    },
                    "primary": [
                        {
                            "type": "Navigation",
                            "cta": {
                                "route": "/contact-us.html",
                                "label": "Contact us",
                                "behavior": "_self"
                            },
                            "children": [
                                {
                                    "type": "DropDown",
                                    "label": "About",
                                    "key": "about",
                                    "payload": {
                                        "primary": {
                                            "payload": {
                                                "items": [
                                                    {
                                                        "label": "Our story",
                                                        "route": "/about/our-story.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Our leadership",
                                                        "route": "/about/our-management.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Social impact",
                                                        "route": "/about/social-responsibility.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Generation",
                                                        "route": "/generation.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Newsroom",
                                                        "route": "/about/newsroom.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Careers",
                                                        "route": "/about/careers.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Diversity, equity, and inclusion",
                                                        "route": "/about/diversity-equity-and-inclusion.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Investors",
                                                        "route": "https://investors.nrg.com",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    }
                                                ],
                                                "heading": "About"
                                            }
                                        },
                                        "secondary": {
                                            "payload": {
                                                "heading": "OUR COMMITMENT",
                                                "items": [
                                                    {
                                                        "label": "Learn about our approach to sustainability",
                                                        "route": "/sustainability/approach.html",
                                                        "behavior": "_self"
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "DropDown",
                                    "label": "Residential",
                                    "key": "residential",
                                    "payload": {
                                        "primary": {
                                            "payload": {
                                                "items": [
                                                    {
                                                        "label": "Energy plans",
                                                        "route": "/residential/electricity-plans.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Sustainable living",
                                                        "route": "/residential/sustainable-living.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "EV driving",
                                                        "route": "/residential/electric-vehicles.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Backup power",
                                                        "route": "/residential/backup-power.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Smart technology",
                                                        "route": "/residential/smart-technology.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Home services",
                                                        "route": "/residential/home-services.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Browse all products and services",
                                                        "route": "/residential/all-products-and-services.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    }
                                                ],
                                                "heading": "Residential"
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "DropDown",
                                    "label": "Business",
                                    "key": "business",
                                    "payload": {
                                        "primary": {
                                            "payload": {
                                                "items": [
                                                    {
                                                        "label": "Power",
                                                        "route": "/business/electricity.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Natural gas",
                                                        "route": "/business/natural-gas.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Load management",
                                                        "route": "/business/load-management.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Sustainability & renewables",
                                                        "route": "/business/sustainability-services.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Energy brokers",
                                                        "route": "/business/brokers.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Browse all products and services",
                                                        "route": "/business/all-products-and-services.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    }
                                                ],
                                                "heading": "Business"
                                            }
                                        },
                                        "secondary": {
                                            "payload": {
                                                "heading": "CASE STUDIES",
                                                "items": [
                                                    {
                                                        "label": "View case studies by industries and goal",
                                                        "route": "/case-studies/all-case-studies.html",
                                                        "behavior": "_self"
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "DropDown",
                                    "label": "Insights",
                                    "key": "insights",
                                    "payload": {
                                        "primary": {
                                            "payload": {
                                                "items": [
                                                    {
                                                        "label": "All insights",
                                                        "route": "/insights/all-insights.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "White papers and webinars",
                                                        "route": "/insights/white-papers-and-webinars.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Tracking the market",
                                                        "route": "/insights/tracking-the-market.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    }
                                                ],
                                                "heading": "Insights"
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "DropDown",
                                    "label": "Sustainability",
                                    "key": "sustainability",
                                    "payload": {
                                        "primary": {
                                            "payload": {
                                                "items": [
                                                    {
                                                        "label": "Overview",
                                                        "route": "/sustainability/context.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Progress",
                                                        "route": "/sustainability/progress.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Approach",
                                                        "route": "/sustainability/approach.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    },
                                                    {
                                                        "label": "Reporting",
                                                        "route": "/sustainability/reporting.html",
                                                        "behavior": "_self",
                                                        "primaryCTALink": false
                                                    }
                                                ],
                                                "heading": "Sustainability"
                                            }
                                        },
                                        "secondary": {
                                            "payload": {
                                                "heading": "SUSTAINABILITY RUNS DEEP AT NRG",
                                                "items": [
                                                    {
                                                        "label": "2020 Sustainability Report",
                                                        "route": "/sustainability/reporting.html",
                                                        "behavior": "_self"
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            "type": "Footer",
                            "payload": {
                                "primaryContent": {
                                    "heading": "Ready to talk to an energy expert?",
                                    "description": "Whatever you need, we'll help you get it done.",
                                    "cta": {
                                        "label": "Contact us",
                                        "route": "/contact-us.html",
                                        "behavior": "_self",
                                        "attributes": "",
                                        "color": "transparent-white"
                                    }
                                },
                                "secondaryContent": {
                                    "heading": "Sign up for insights",
                                    "description": "Receive news and insights directly to your inbox.",
                                    "secondarySubmit": ""
                                },
                                "items": [],
                                "primaryLinks": [
                                    {
                                        "heading": "About us",
                                        "links": [
                                            {
                                                "label": "Our story",
                                                "route": "/about/our-story.html"
                                            },
                                            {
                                                "label": "Our leadership",
                                                "route": "/about/our-management.html"
                                            },
                                            {
                                                "label": "Newsroom",
                                                "route": "/about/newsroom.html"
                                            },
                                            {
                                                "label": "Investors",
                                                "route": "https://investors.nrg.com"
                                            },
                                            {
                                                "label": "Careers",
                                                "route": "/about/careers.html"
                                            }
                                        ]
                                    },
                                    {
                                        "heading": "Our offerings",
                                        "links": [
                                            {
                                                "label": "For your home",
                                                "route": "/residential/all-products-and-services.html"
                                            },
                                            {
                                                "label": "For your business",
                                                "route": "/business/all-products-and-services.html"
                                            },
                                            {
                                                "label": "Generation",
                                                "route": "/generation.html"
                                            },
                                            {
                                                "label": "Sustainability",
                                                "route": "/sustainability/context.html"
                                            },
                                            {
                                                "label": "Energy brokers",
                                                "route": "/business/brokers.html"
                                            }
                                        ]
                                    }
                                ],
                                "secondaryLinks": [
                                    {
                                        "label": "Legal",
                                        "route": "/legal.html"
                                    },
                                    {
                                        "label": "Privacy rights & requests",
                                        "route": "/legal/privacy-rights-and-requests.html"
                                    },
                                    {
                                        "label": "Suppliers",
                                        "route": "/suppliers.html"
                                    },
                                    {
                                        "label": "Energy policy",
                                        "route": "/energy-policy.html"
                                    }
                                ],
                                "socialLinks": {
                                    "heading": "Ready to talk to an energy expert?",
                                    "links": [
                                        {
                                            "label": "facebook",
                                            "route": "https://www.facebook.com/pages/NRG-Energy/68784278588",
                                            "behavior": "_blank"
                                        },
                                        {
                                            "label": "instagram",
                                            "route": "http://instagram.com/nrgenergy",
                                            "behavior": "_blank"
                                        },
                                        {
                                            "label": "linkedin",
                                            "route": "http://www.linkedin.com/company/7136?trk=tyah",
                                            "behavior": "_blank"
                                        },
                                        {
                                            "label": "twitter",
                                            "route": "http://twitter.com/#!/nrgenergy",
                                            "behavior": "_blank"
                                        },
                                        {
                                            "label": "youtube",
                                            "route": "https://www.youtube.com/channel/UCiGskGDPDG47BVwAFwtroXQ",
                                            "behavior": "_blank"
                                        }
                                    ]
                                },
                                "copyrightText": "NRG Energy, Inc. All Rights Reserved."
                            },
                            "positionOnPage": 1
                        },
                        {
                            "type": "ReadyToTalk",
                            "payload": {
                                "heading": "Ready to talk?",
                                "subheading": "Our energy experts are ready to help with your energy goals.",
                                "backgroundStyle": "gradient",
                                "label": "Contact us",
                                "behavior": "_self",
                                "ctaRoute": "/contact-us.html"
                            },
                            "positionOnPage": 2
                        }
                    ]
                }
            }
        ]
    }
}