import { IAddress } from './address';

export interface I4cpPreferences {
  accountId?: string;
  userId?: string;
  subscriptionType?: string; // “Basic” or “Premium”, using a string so we can add other options as needed in the future
  alertType?: string;
  // if subscriptionType == “Basic”, alertType will be either “Basic” (subscribed to alerts) or NULL (not subscribed)
  // if subscriptionType == “Premium”, alertType will be either “Selective” or “Highly Selective”
  alertInterval?: number; // interval for alerts based on alertType in minutes
  alertTime?: Date; // start time for alerts - to be displayed in UI
  enrollmentDate?: string;
  lastModified?: string;
}

// subscriptionType: BASIC
// alertType: NULL
// alertInterval: NULL
// alertTime: NULL

export interface I4cpAlert {
  alertType: string; // BASIC || SELECTIVE || HIGHLY_SELECTIVE
  maxLoadForecast: number;
  mtdMaxLoad: number;
  mtdMaxLoadTime: string; // "MM/dd/yyyy HH:mm"
  alertLikelyhood: string; // LOW || MEDIUM || HIGH
  alertPeriodEnd: string; // 2018-04-12 13:00
  alertPeriodStart: string; // 2018-04-12 19:00
  alertPeriodDisplay?: string;
  // alertPeriod: string // "HH:mm – HH:mm"
}

export interface I4cpROC {
  minuteRoc: number;
  hourRoc: number;
}

export const SubscriptionType = {
  Premium: 'PREMIUM',
  Basic: 'BASIC'
};

export const AlertType = {
  Basic: 'BASIC',
  Selective: 'SELECTIVE',
  HighlySelective: 'HIGHLY_SELECTIVE'
};

export const FourcpEnrollmentUrl = 'https://www.reliant.com/en/business/large-business/energy-management/4cp-alerts.jsp';

export const PeriodTypes = {
  AllDay: 'ALL_DAY',
  Afternoon: 'AFTERNOON',
  Custom: 'CUSTOM'
};

export const Hours = [
  { display: '00:00', value: 0 },
  { display: '01:00', value: 1 },
  { display: '02:00', value: 2 },
  { display: '03:00', value: 3 },
  { display: '04:00', value: 4 },
  { display: '05:00', value: 5 },
  { display: '06:00', value: 6 },
  { display: '07:00', value: 7 },
  { display: '08:00', value: 8 },
  { display: '09:00', value: 9 },
  { display: '10:00', value: 10 },
  { display: '11:00', value: 11 },
  { display: '12:00', value: 12 },
  { display: '13:00', value: 13 },
  { display: '14:00', value: 14 },
  { display: '15:00', value: 15 },
  { display: '16:00', value: 16 },
  { display: '17:00', value: 17 },
  { display: '18:00', value: 18 },
  { display: '19:00', value: 19 },
  { display: '20:00', value: 20 },
  { display: '21:00', value: 21 },
  { display: '22:00', value: 22 },
  { display: '23:00', value: 23 },
  { display: '24:00', value: 24 },
];

export interface I4cpMTDData {
  mtdMaxLoad: number;
  high5YearMtd: number;
  low5YearMtd: number;
}

export interface I4cpAlertHistory {
  peaks: I4cpPeak[];
}

export interface I4cpPeak {
  date: string; // 2018-04-10
  dateFormatted?: string; // Tue 08/01/18
  forecastMW: number; // Forecasted load
  forecastMWFormatted?: string;
  forecastAlertType: string; // LOW | MEDIUM | HIGH
  actualMW: number; // Observed Load
  actualMWFormatted?: string;
  actualPeakTime: string; // 15 min period ending
  mtdMaxLoad: boolean; // Is this the MTD peak?
  yourAlert: string; // 13:00 - 17:00 (alert issued)
}


export interface I4cpRecentAlerts {
  accountId: string;
  recentAlerts: IRecentAlertData[];

}

export interface IRecentAlertData {
  day: string;
  date: string; // 2018-04-12
  timePeriod: string;
}

export const I4cpMonths = [
  { display: 'June', value: '06' },
  { display: 'July', value: '07' },
  { display: 'August', value: '08' },
  { display: 'September', value: '09' }
];

export interface I4cpContactResponse {
  accountId: string;
  contacts: I4cpContact[];
}

export interface I4cpContact {
  // also used for 4CP alerts
  user?: string;
  alertEmail: string;
  textAlertPhone: string;
  voiceAlertPhone: string;
  voiceAlertExt?: string;
  activatedOn?: string;
  editingEmail?: boolean;
  accountId?: string;
  temp?: I4cpContact;
  phoneSelection?: string;
  textPhoneSelection?: string;
  voicePhoneSelection?: string;
  alertPhone?: string;
  modifiedOn?: string;
  onlineAccess?: string;
  textAlertSync?: string;
  voiceAlertSync?: string;
  activeFlag?: string;
  emailError?: boolean;
  validationError?: boolean;
  userProfile?: any;
  name?: string;
  alertType?: string;
}

export interface I4cpSitePerformance {
  months?: I4cpSitePerformanceMonth[];
  usageResponse?: IFourCPUsageResponse;
  tdsp?: string;
  serviceType?: string;
  voltage?: string;
  serviceAddress?: IAddress;
  tariff?: number;
  percentFactor?: number;
  year?: number;
  averagePeak?: number; // 22.500
  estimatedTariffCost?: number; // $1,233.01
  observedReduction?: number; // 386.550
  maxObservedReduction?: number;
  estimatedSavings?: number; // $21,183.06
  averageProgram?: number; // 287.550
  estimatedProgramSavings?: number; // $15,757.83
  reliantProgramMulitplier?: number; // 57.51
  reliantAnnualServiceFee?: number; // $3,151.57
  unitType?: string;
  estimatedReduction?: number;
}

export interface I4cpSitePerformanceMonth {
  alertsInMonth?: number;
  intervalPeriod?: string; // 6/27/2018 17:00
  peakDemand?: number; // 23.400
  observedReduction?: number; // 94%
  estimatedReduction?: number; // 370.800
  yourAlert?: string; // 16:15 - 17:00
  alertCalled?: boolean; //true
  reduction20Plus?: string; //"N/A" or "true"
  siteBaseline?: number; // 394.00
  reliantProgramReduction?: number; //370.800
}

// export interface I4cpSummaryPerformance {
//   sitesTotal?: I4cpSummaryPerformanceMonth;
//   months?: I4cpSummaryPerformanceMonth[];
//   sites?: I4cpSummaryPerformanceSite[];
// }

export interface I4cpSummaryPerformance {
  sitePerformance?: I4cpSitePerformance[];
  estimatedTariffCost?: number;
  estimatedReduction?: number;
  estimatedSavings?: number;
  averagePeak?: number;
  observedReduction?: number;
  estimatedProgramSavings?: number;
  reliantAnnualServiceFee?: number;
  maxObservedReduction?: number;
}

// export interface I4cpSummaryPerformanceSite {
//   siteId?: string;
//   tdsp?: string;
//   serviceType?: string;
//   projected4cp?: number;
//   projectedCost?: number;
//   averageReduction?: number;
//   maxReduction?: number;
//   overallProjectedSavings?: number;
//   projectedProgramSavings?: number;
//   reliantFee?: number;
// }
//
// export interface I4cpSummaryPerformanceMonth {
//   month?: string;
//   alertTime?: string;
//   notificationCount?: number;
//   alertCalled?: boolean;
// }

export interface IFourCPUsageResponse {
  unit?: string;
  timezone?: string;
  usage?: IDemandData[];
}

export interface IDemandData {
  date: string;
  peakInterval: string;
  data: IDemandChart[];
}

export interface IDemandChart {
  periodEnding: string;
  interval: number;
  value: number;
}




// export interface I4cpErcotPeak {
//   month?: string;
//   year?: string;
//   day?: string;
//   peakTime?: string;
// }

// export interface I4cpAlert {
//   day?: string;
//   date?: string;
//   timeRange?: string;
// }
//
// export interface ISitePerformance {
//   esiId?: string;
//   day?: string;
//   month?: string;
//   curtailment?: string;
//   peakInterval?: string;
//   peakDemand?: string;
//   baselineDemand?: string;
//   baselineInterval?: string;
//   estimatedReduction?: number;
//   percentageDifference?: number
// }
//
// export interface I4cpUsageHistory {
//   intervalEnding?: string;
//   kWH?: string;
//   var?: string;
// }
//
// export interface programSavings {
//   esiId?: string;
//   year?: string;
//   notifications?: number;
//   tariff?:
// }
