import * as moment from "moment";
export const MetricsPages = ['Enrollment', 'Transactions'];

export const transactionUserTypes = [
  {display: 'All Users', value: 'all'},
  {display: 'External Indirect', value: 'External Indirect'},
  {display: 'External Direct', value: 'External direct'},
  {display: 'Internal Direct', value: 'internal'}
];

export interface IUserMetrics {
  date?: string,
  dateSortable?: string,
  dateFormatted?: string,
  userEvents?: {
    distinctUserLogins?: ITransactionMetricsDetail[],
    activeUsers?: IUserMetricsEnrollmentDetail[],
    inactiveUsers?: IUserMetricsEnrollmentDetail[],
    totalUsers?: IUserMetricsEnrollmentDetail[]
  }
  txEvents?: {
    AMENDMENT_ACTIVITY_EXPORT?: ITransactionMetricsDetail[],
    AMENDMENT_CART_EXPORT?: ITransactionMetricsDetail[],
    AMENDMENT_HISTORY_EXPORT?: ITransactionMetricsDetail[],
    AMENDMENT_SUBMITTED?: ITransactionMetricsDetail[],
    AUTHENTICATION_SUCCESS?: ITransactionMetricsDetail[],
    BILLING_REPORT_EXPORT?: ITransactionMetricsDetail[],
    CONTRACT_NICKNAME_UPDATED?: ITransactionMetricsDetail[],
    CONTRACT_EXPORT?: ITransactionMetricsDetail[],
    INVOICE_DOWNLOAD?: ITransactionMetricsDetail[],
    MARKETDATA_EXPORT?: ITransactionMetricsDetail[],
    MARKETDATA_GAS_EXPORT?: ITransactionMetricsDetail[],
    MULTIPLE_INVOICE_DOWNLOAD?: ITransactionMetricsDetail[],
    PAYMENT_SUBMITTED?: ITransactionMetricsDetail[],
    SITE_GROUP_DELETED?: ITransactionMetricsDetail[],
    SITE_GROUP_CREATED?: ITransactionMetricsDetail[],
    SITE_GROUP_UPDATED?: ITransactionMetricsDetail[],
    TERMS_AND_CONDITIONS_ACKNOWLEDGE?: ITransactionMetricsDetail[],
    USAGE_REPORT_EXPORT?: ITransactionMetricsDetail[],
    USAGE_GRAPH_EXPORT?: ITransactionMetricsDetail[],
    USER_MODIFIED?: ITransactionMetricsDetail[],
    USER_CREATED?: ITransactionMetricsDetail[],
    USER_DELETED?: ITransactionMetricsDetail[]
  }
}

export interface IUserMetricsEnrollmentDetail {
  login?: string;
  organization?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  extension?: string;
  mobile_phone?: string;
  enrollment_status?: string;
  activation_date?: string;
  last_login?: string;
  created_date?: string;
}

export interface IUserMetricsDetail {
  principal?: string;
  firstName?: string;
  lastName?: string;
  phone?: string
  userOrganization?: string;
  userType?: string;
  email?: string;
  txOrganization?: string;


  organization?: string;
  login?: string;
  auditEventDate?: Date;
  timeFormatted?: string,

  user?: string,
  customer?: string,
  customerId?: number,
  time?: Date,
}

export const transactionColumnsControl = [
  {display:'Payments Submitted', name: 'PAYMENT_SUBMITTED', checked: true, disabled: true},
  {display:'Single Invoice Downloads', name: 'INVOICE_DOWNLOAD', checked: true, disabled: false},
  {display:'Multiple Invoice Downloads', name: 'MULTIPLE_INVOICE_DOWNLOAD', checked: true, disabled: false},
  {display:'Amendments Submitted', name: 'AMENDMENT_SUBMITTED', checked: true, disabled: false},
  {display:'Billing Reports Exported', name: 'BILLING_REPORT_EXPORT', checked: false, disabled: false},
  {display:'Usage Reports Exported', name: 'USAGE_REPORTS_EXPORTED', checked: false, disabled: false},
  {display:'Amendment Reports Exported', name: 'AMENDMENT_REPORTS_EXPORTED', checked: false, disabled: false},
  {display:'Market Data Reports Exported', name: 'MARKET_DATA_REPORTS_EXPORTED', checked: false, disabled: false},
  {display:'Custom Site Groups Created', name: 'SITE_GROUP_CREATED', checked: true, disabled: false},
  {display:'Custom Contract Nicknames Created', name: 'CONTRACT_NICKNAME_UPDATED', checked: true, disabled: false},
];

export const userColumnsControl = {
  userCols: [
    {display:'Daily Unique User Logins', name: 'distinctUserLogins', checked: true, disabled: false, totals: false},
    {display:'Activated Enrolled Users', name: 'activeUsers', checked: true, disabled: false, totals: false},    
    {display:'Non-Activated Enrolled Users', name: 'inactiveUsers', checked: true, disabled: false, totals: false},
    {display:'Total Enrolled Users', name: 'totalUsers', checked: true, disabled: false, totals: false},
  ],
  txCols: [
    {display:'Payments Submitted', name: 'PAYMENT_SUBMITTED', checked: true, disabled: true, totals: true},
    {display:'Single Invoice Downloads', name: 'INVOICE_DOWNLOAD', checked: true, disabled: false, totals: true},
    {display:'Multiple Invoice Downloads', name: 'MULTIPLE_INVOICE_DOWNLOAD', checked: true, disabled: false, totals: true},
    {display:'Amendments Submitted', name: 'AMENDMENT_SUBMITTED', checked: true, disabled: false, totals: true},
    {display:'Billing Reports Exported', name: 'BILLING_REPORT_EXPORT', checked: false, disabled: false, totals: true},
    {display:'Usage Reports Exported', name: 'USAGE_REPORTS_EXPORTED', checked: false, disabled: false, totals: true},
    {display:'Amendment Reports Exported', name: 'AMENDMENT_REPORTS_EXPORTED', checked: false, disabled: false, totals: true},
     {display:'Market Data Reports Exported', name: 'MARKET_DATA_REPORTS_EXPORTED', checked: false, disabled: false, totals: true},
    // {display:'Market Data Reports Exported', name: 'MARKETDATA_GAS_EXPORT', checked: false, disabled: false, totals: true},
    {display:'Custom Site Groups Created', name: 'SITE_GROUP_CREATED', checked: true, disabled: false, totals: true},
    {display:'Custom Contract Nicknames Created', name: 'CONTRACT_NICKNAME_UPDATED', checked: true, disabled: false, totals: true}
  ]
};

export interface ITransactionMetrics {
  date: Date,
  dateFormatted?: string,
  dateSortable?: string,
  events?: {
    AMENDMENT_ACTIVITY_EXPORT?: ITransactionMetricsDetail[],
    AMENDMENT_CART_EXPORT?: ITransactionMetricsDetail[],
    AMENDMENT_HISTORY_EXPORT?: ITransactionMetricsDetail[],
    AMENDMENT_SUBMITTED?: ITransactionMetricsDetail[],
    AUTHENTICATION_SUCCESS?: ITransactionMetricsDetail[],
    BILLING_REPORT_EXPORT?: ITransactionMetricsDetail[],
    CONTRACT_NICKNAME_UPDATED?: ITransactionMetricsDetail[],
    CONTRACT_EXPORT?: ITransactionMetricsDetail[],
    INVOICE_DOWNLOAD?: ITransactionMetricsDetail[],
    MARKETDATA_EXPORT?: ITransactionMetricsDetail[],
    MARKETDATA_GAS_EXPORT?: ITransactionMetricsDetail[],
    MULTIPLE_INVOICE_DOWNLOAD?: ITransactionMetricsDetail[],
    PAYMENT_SUBMITTED?: ITransactionMetricsDetail[],
    SITE_GROUP_DELETED?: ITransactionMetricsDetail[],
    SITE_GROUP_CREATED?: ITransactionMetricsDetail[],
    SITE_GROUP_UPDATED?: ITransactionMetricsDetail[],
    TERMS_AND_CONDITIONS_ACKNOWLEDGE?: ITransactionMetricsDetail[],
    USAGE_REPORT_EXPORT?: ITransactionMetricsDetail[],
    USAGE_GRAPH_EXPORT?: ITransactionMetricsDetail[],
    USER_MODIFIED?: ITransactionMetricsDetail[],
    USER_CREATED?: ITransactionMetricsDetail[],
    USER_DELETED?: ITransactionMetricsDetail[]
  }
  columns?:{
    PAYMENT_SUBMITTED: number,
    INVOICE_DOWNLOAD: number,
    MULTIPLE_INVOICE_DOWNLOAD: number,
    AMENDMENT_SUBMITTED: number,
    BILLING_REPORT_EXPORT: number,
    USAGE_REPORTS_EXPORTED: number,
    AMENDMENT_REPORTS_EXPORTED: number,
    MARKET_DATA_REPORTS_EXPORTED: number,
    SITE_GROUP_CREATED: number,
    CONTRACT_NICKNAME_UPDATED: number
  }
}

export interface ITransactionMetricsDetail {
  id?: number,
  principal?: string,
  auditEventDate?: Date,
  auditEventDateFormatted?: string;
  auditEventType?: string,
  count?: number,
  data?: {
    accountId?: string,
    txOrganization?: string,
    firstName?: string,
    lastName?: string,
    accountExecs?: string,
    userOrganization?: string,
    phone?: string,
    organization?: string,
    contractId?: string,
    userType?: string,
    customerReps?: string,
    email?: string
  }
}

export const UserTypeList = [
  {display: 'All', name: 'all'},
  {display: 'Internal', name: 'internal'},
  {display: 'External', name: 'external'}
]

export const SalesGroupList = [
  {display: 'C&I', name: 'candi'},
  {display: 'Mid Market', name: 'midMarket'},
  {display: 'C&I; Mid Market', name: 'candiMidMarket'}
]
export interface IEnrollmentDetail {
  customerName: string,
  customerId: string,
  enrolled?: number,
  enrolledFormatted?: string
}

export interface IEnrollmentMonthly {
  month?: string,
  assigned?: number,
  enrolled?: number,
  detail?: IEnrollmentDetail[],
  snapShotDate?: string
}

export interface IEnrollmentHistory {
  teamMemberName?: string,
  data?: IEnrollmentMonthly[];
}

export interface IEnrollmentHistoryWithMonths {
  dateList?: string[],
  formattedDateList?: string[];
  enrollmentData?: IEnrollmentHistory[];
}
