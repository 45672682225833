import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
  name: 'metricsDetailPipe'
})
export class MetricsDetailPipe implements PipeTransform {
  transform(items: any[], term: string): any[]{
    term = term.toLowerCase().trim();
    let filteredItems = [];
    items.forEach((item) => {
      let found = false;
      if(item.data) {
        for(let prop in item.data) {
          if(item.data[prop] && item.data[prop].toString().toLowerCase().match(term) && !found){
            filteredItems.push(item);
            found = true;
          }
        }
      } else {
        for(let prop in item) {
          if(item[prop] && item[prop].toString().toLowerCase().match(term) && !found){
            filteredItems.push(item);
            found = true;
          }
        }
      }

    });
    return filteredItems;
  }
}
