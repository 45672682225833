import { Component, OnInit } from '@angular/core';
import {IUserAccount} from "../shared/entities/profile";
import {Subscription} from "rxjs";
import {DataService} from "../shared/data.service";
import * as moment from 'moment';
import {BrokerService} from "./broker.service";
import {IBrokerPayout} from "../shared/entities/broker";
import {PortalService} from "../shared/portal.service";
import {GenericPipe} from "../shared/pipe/generic.pipe";
import {OrderByPipe} from "../shared/pipe/orderBy.pipe";
import {FadeAnimation} from "../animations/fadeAnimation";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-payout-report',
  templateUrl: './tp-payout-report.component.html',
  styleUrls: ['./tp-payout-report.component.scss'],
  providers: [GenericPipe, OrderByPipe],
  animations: [FadeAnimation]
})
export class TpPayoutReportComponent implements OnInit {

  myAccount: IUserAccount;
  payoutReport: IBrokerPayout[] = [];
  filteredPayoutReport: IBrokerPayout[] = [];
  selectedStartDate: Date;
  selectedEndDate: Date;
  minDate: Date;
  maxDate: Date;
  invalidDateError: string;
  showSearchRow: boolean;
  searchTerm: string;
  sortBy: string = 'paymentDate';
  ascending: boolean;
  editingBroker: boolean;
  totalPaymentAmount: any;
  topRowNotes: string;
  maskMode: boolean;
  maskModeSubscription: Subscription;

  // Broker Selection
  // thirdPartyAccounts: IUserAccount[] = [];
  // filteredThirdPartyAccounts: IUserAccount[] = [];
  // selectedThirdPartyAccount: IUserAccount = {} as any;
  typeaheadIndex: number = -1;
  accountSearch: string = '';

  // thirdPartyAccountSubscription: Subscription;
  accountSubscription: Subscription;
  exportSubscription: Subscription;

  constructor(private dataService: DataService,
              private brokerService: BrokerService,
              private portalService: PortalService,
              private genericPipe: GenericPipe,
              private orderByPipe: OrderByPipe,
              private cookieService: CookieService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Third Party Payout Report');
    this.setCurrentActivePage('payments')
    this.minDate = moment('2018-01-01').toDate();
    this.maxDate = moment(new Date()).toDate();

    this.selectedStartDate = moment(new Date()).subtract(3,'months').toDate();
    this.selectedEndDate = moment(new Date()).toDate();

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.getPayoutReport();
    });


    // this.thirdPartyAccounts = this.dataService.getThirdPartyAccountsSource();
    // if(this.thirdPartyAccounts) {
    //   // this.getDefaultThirdParty();
    //   this.getPayoutReport();
    // }
    // this.thirdPartyAccountSubscription = this.dataService.thirdPartyAccountsSourceUpdated.subscribe((accounts) => {
    //   this.thirdPartyAccounts = accounts;
    //   // this.getDefaultThirdParty();
    //   this.getPayoutReport();
    // });

    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount) {
      this.getPayoutReport();
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.myAccount = account;
      this.getPayoutReport()
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (time) => {
        this.dataService.setLoading(true);
        this.export();
      }
    );

    if(!this.dataService.getAppProperties()){
      // LOCAL
      this.getPayoutReport();
    }

    this.dataService.setContextualHelp(false);
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  // getDefaultThirdParty() {
  //   let cookie = this.cookieService.get('selected-third-party');
  //   if(cookie){
  //     this.thirdPartyAccounts.forEach((account) => {
  //       if(account.id == cookie) {
  //         this.selectedThirdPartyAccount = account;
  //       }
  //     });
  //     if(!this.selectedThirdPartyAccount){
  //       this.selectedThirdPartyAccount = this.thirdPartyAccounts[0];
  //     }
  //   } else {
  //     this.selectedThirdPartyAccount = this.thirdPartyAccounts[0];
  //   }
  // }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getPayoutReport() {
    // validate dates
    if(this.validateDates()){
      this.dataService.setLoading(true);
      let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
      let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
      this.brokerService.getPayoutReport(this.myAccount.id, startDateString, endDateString).subscribe((payoutReport) => {
        this.payoutReport = payoutReport;
        this.filterPayout();
        this.dataService.setLoading(false);
      }, err => {
        this.payoutReport = this.filteredPayoutReport = [];
        this.dataService.setLoading(false);
      })
    }
  }

  validateDates() {
    this.invalidDateError = '';
    if(moment(this.selectedStartDate).isAfter(moment(this.selectedEndDate))){
      this.invalidDateError = 'Please select a valid start and end date.';
      return false;
    } else if(moment(this.selectedStartDate).isBefore(moment(this.minDate))) {
      this.invalidDateError = 'Please select a start date on or after ' + moment(this.minDate).format('M/D/YYYY') + '.';
      return false;
    } else if (moment(this.selectedEndDate).isAfter(moment(this.maxDate).add(1, 'day'))) {
      this.invalidDateError = 'Please select an end date on or before ' + moment(this.maxDate).format('M/D/YYYY') + '.';
      return false;
    } else {
      return true;
    }
  }

  filterPayout() {
    if(this.searchTerm){
      this.filteredPayoutReport = this.genericPipe.transform(this.payoutReport, this.searchTerm);
    } else {
      this.filteredPayoutReport = this.payoutReport;
    }
    this.filteredPayoutReport = this.orderByPipe.transform(this.filteredPayoutReport, this.sortBy, this.ascending);
    this.getTotals();
  }

  maskAccountName(account: IUserAccount) {
    return this.portalService.getFakeCompanyName(account);
  }

  sortPayout(sortBy: string){
    if(sortBy == this.sortBy){
      this.ascending = !this.ascending;
    }
    this.sortBy = sortBy;
    this.filteredPayoutReport = this.orderByPipe.transform(this.filteredPayoutReport, this.sortBy, this.ascending);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterPayout();
  }

  getDateDisplay(date: string) { return moment(date).format('MM/DD/YYYY') }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x) }

  // toggleEditBroker() {
  //   if(this.thirdPartyAccounts.length > 1){
  //     this.editingBroker = !this.editingBroker;
  //     this.filteredThirdPartyAccounts = this.thirdPartyAccounts;
  //     this.accountSearch = '';
  //   }
  // }
  //
  //
  // // Typeahead
  // selectThirdPartyAccount(thirdPartyAccount?: IUserAccount){
  //   if(this.filteredThirdPartyAccounts.length > 0){
  //     if(this.typeaheadIndex == -1) {
  //       this.typeaheadIndex = 0;
  //     }
  //     if(thirdPartyAccount) {
  //       this.selectedThirdPartyAccount = thirdPartyAccount;
  //     } else {
  //       this.selectedThirdPartyAccount = this.filteredThirdPartyAccounts[this.typeaheadIndex];
  //     }
  //     this.cookieService.set('selected-third-party', this.selectedThirdPartyAccount.id);
  //     this.accountSearch = '';
  //     this.resetTypeahead();
  //   }
  //   this.editingBroker = false;
  //   this.getPayoutReport();
  // }


  resetTypeahead() {
    this.typeaheadIndex = -1;
    this.editingBroker = false;
  }

  //
  // searchAccounts() {
  //   this.filteredThirdPartyAccounts = [];
  //   this.thirdPartyAccounts.forEach((account) => {
  //     if(account.id.match(this.accountSearch) ||
  //       (account.name && account.name.toLowerCase().match(this.accountSearch.toLowerCase())) ||
  //       this.accountSearch == ''){
  //       this.filteredThirdPartyAccounts.push(account);
  //     }
  //   });
  // }
  //
  // navigateTypeahead(down: boolean) {
  //   if(down && this.typeaheadIndex < this.filteredThirdPartyAccounts.length-1){
  //     this.typeaheadIndex++;
  //   } else if (!down && this.typeaheadIndex > -1) {
  //     this.typeaheadIndex--;
  //   }
  // }

  getTotals() {
    this.totalPaymentAmount = 0;
    this.topRowNotes = null;
    this.filteredPayoutReport.forEach((row) => {
      this.totalPaymentAmount += row.paymentAmount;
      if(!this.topRowNotes) {
        this.topRowNotes = row.notes;
      } else if (this.topRowNotes != row.notes) {
        this.topRowNotes = 'Multiple';
      }
    });
    this.totalPaymentAmount = this.totalPaymentAmount.toFixed(2);
  }

  export() {
    let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
    let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
    this.maskMode = this.dataService.getMaskMode();
    this.brokerService.exportPayoutReport(this.myAccount,
       startDateString,
       endDateString,
       this.maskMode,
       window.localStorage.getItem("brand"));
  }

}
