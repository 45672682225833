import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {BillDeliveryService} from "../bill-delivery.service";
// import {IBillDeliveryRequest} from "../../shared/entities/billdelivery";
import * as moment from 'moment';
import {FadeAnimation} from "../../animations/fadeAnimation";


@Component({
  selector: 'app-bill-delivery-terms',
  templateUrl: './bill-delivery-terms.component.html',
  animations: [FadeAnimation],
  styleUrls: ['./bill-delivery-terms.component.scss', '../bill-delivery.component.scss']
})
export class BillDeliveryTermsComponent implements OnInit {

  accepted: boolean = true;
  // selectedBillDeliveryRequest: IBillDeliveryRequest;

  accountSubscription: Subscription;

  constructor( private router: Router,
              private dataService: DataService ,
              private billDeliveryService: BillDeliveryService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Bill Delivery Management');
    this.dataService.setSelectedNavItem('payments');
    this.continue();
    // this.selectedBillDeliveryRequest = this.dataService.getSelectedBillDeliveryRequest();
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goHome();
    });
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
  }

  continue(){
    // record (hit API) that user accepted Ts & Cs
     if(this.accepted){
      //  if(this.selectedBillDeliveryRequest){
        //  this.selectedBillDeliveryRequest.conditionsAcknowledgedDate = moment().toDate();
       } else {
        //  this.selectedBillDeliveryRequest = {} as any; // Local:4200
       }

      // this.billDeliveryService.submitBillDeliverytc(this.selectedBillDeliveryRequest).subscribe();
      // this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
       this.router.navigate(['/bill-delivery/edit']);
    }
  

  goHome() {
    this.router.navigate(['/bill-delivery/terms']);
  }
}
