import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Subscription } from "rxjs"
import { Authority } from "../shared/const/authority";

import { DataService } from "../shared/data.service";
import { IAccount } from "../shared/entities/account";
import { IContract } from "../shared/entities/contract";
import { IProduct } from "../shared/entities/product";
import { IUserAccount } from "../shared/entities/profile";
import { IGenerationSite } from './generation-site';
import { PortalService } from "../shared/portal.service";
import { ShadowMeteringService } from "./shadow-metering.service";

@Component({
  selector: "app-shadow-metering",
  templateUrl: "./shadow-metering.component.html",
  styleUrls: ["./shadow-metering.component.scss"],
})
export class ShadowMeteringComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject();
  isSuperUser: boolean;
  isSuperAdmin: boolean;
  dgenFlag: boolean;
  showRealTimeUsage: boolean;
  showDistributedGeneration: boolean;
  showOnsiteGeneration: boolean;
  accountWithContracts: IAccount;
  selectedAccount: IUserAccount;
  sites: IGenerationSite[];
  hasReliabilityProducts: boolean;
  accountSourceSubscription: Subscription;
  dgenSitesSubscription; Subscription;

  constructor(
    private dataService: DataService,
    private shadowMeteringService: ShadowMeteringService,
    private portalService: PortalService
  ) {}

  ngOnInit() {
    this.dataService.setTitleSource('Shadow Metering');
    this.setCurrentActivePage('tools'); 
    this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.selectedAccount = this.dataService.getAccountSource();

    // PBI 84567: Restrict dgen subpage visibility to super admin, super user, or those with dgen account flag
    if (this.selectedAccount) {

      /***this.shadowMeteringService.getSites(this.selectedAccount.id)
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.dataService.setDgenSites(sites);
        this.sites = sites;
        this.hasReliabilityProducts = this.sites.filter(site => site.hasReliabilityService).length > 0;
        });**/
      this.onAccountChange(this.selectedAccount);
    } else {
      this.showRealTimeUsage = false;
      this.showDistributedGeneration = false;
      this.showOnsiteGeneration = false;
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.onAccountChange(account);
    });
    this.sites = this.dataService.getDgenSites();
    if (this.sites) {
      this.hasReliabilityProducts = this.sites.filter(site => site.hasReliabilityService).length > 0;
    }

    this.dgenSitesSubscription = this.dataService.dgenSitesUpdated.subscribe(
      sites => {
        this.sites = sites;
        this.hasReliabilityProducts = sites.filter(site => site.hasReliabilityService).length > 0;
      }
    );


  }

  ngOnDestroy() {
    // When we unload the shadow metering pages, be sure to clear out the site list
    this.shadowMeteringService.resetData();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.accountSourceSubscription.unsubscribe();
    this.dgenSitesSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }
  
  onAccountChange(account: IUserAccount) {
    this.selectedAccount = account;
    this.showRealTimeUsage = true;
    this.showDistributedGeneration = true;
    this.showOnsiteGeneration = true;

    this.portalService.getAccountFlags(this.selectedAccount).subscribe(
      (response) => {
        if (response.shadowMeters) {
          this.showRealTimeUsage = true;
        } else {
          this.showRealTimeUsage = false;
        }

        if (response.dgen) {
          this.showDistributedGeneration = true;
          this.showOnsiteGeneration = true;
          this.accountWithContracts = this.dataService.getAccountWithContractDataSource();
          if (this.accountWithContracts) {
            // PBI 84567: Restrict shadow meter subpage visibility to account
            // contracts that contain 'Reliability Service'
            // or 'Reliability Fuel Service' products
            const reliabilityServiceContracts = this.accountWithContracts.contracts
                .filter((contract: IContract) => contract.products
                .filter((product: IProduct) => product.detailName && product.detailName.startsWith('Reliability Service')).length > 0);
            const reliabilityFuelServiceContracts = this.accountWithContracts.contracts
                .filter((contract: IContract) => contract.products
                .filter((product: IProduct) => product.detailName && product.detailName.startsWith('Reliability Fuel Service')).length > 0);
            if (this.showDistributedGeneration && this.showOnsiteGeneration) {
              // Disable either dgen and/or onsite gen options based on products present
              if (reliabilityServiceContracts.length === 0 && reliabilityFuelServiceContracts.length > 0) {
                this.showDistributedGeneration = false;
              } else if (reliabilityServiceContracts.length === 0 && reliabilityFuelServiceContracts.length === 0) {
                this.showDistributedGeneration = false;
                this.showOnsiteGeneration = false;
              }
            }
          }
        } else {
          this.showDistributedGeneration = false;
          this.showOnsiteGeneration = false;
        }
      }
    );
  }
}
