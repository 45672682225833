import { Injectable } from "@angular/core";

export class ReportTypeOption {
  display: string;
  value: string;
  accountType: string;
}

@Injectable()
export class ProfileSubscriptionsUtility {
  reportTypeOptions: ReportTypeOption[] = [
    {display: "Billing Summary", value: "BILLING_SUMMARY", accountType: "CUSTOMER"},
    {display: "Drop Report", value: "DROP_REPORT", accountType: "BROKER"},
    {display: "Enrollment Report", value: "BROKER_ENROLL", accountType: "BROKER"},
    {display: "Payment History Report", value: "PAYMENT_HISTORY", accountType: "CUSTOMER"},
    {display: "Usage Summary", value: "USAGE_SUMMARY", accountType: "CUSTOMER"}
  ];
}
