import { Component, HostListener, OnInit } from '@angular/core';
import { IAccount } from '../shared/entities/account';
import {IContact, ICompanyContactInfo, IUsefulContact, IContactInfo, IUsefulContactInfo} from '../shared/entities/contacts';
import {  } from '../shared/entities/usefulContact';
import { AccountsService } from '../accounts/accounts.service';
import { DataService } from '../shared/data.service';
import { ContractPipe } from '../shared/pipe/contract.pipe';
import { ContactService } from '../contact/contact.service';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import {FadeAnimation} from "../animations/fadeAnimation";
import {IUserAccount} from "../shared/entities/profile";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  animations: [
    FadeInAnimation,
    FadeAnimation
  ],
  styleUrls: ['./contact.component.scss'],
  providers: [ContractPipe, ContactService]
})
export class ContactComponent implements OnInit {
   myAccount: IUserAccount;
   contactsForAccount: IContactInfo;
   // Task 275970: Remove 'Company Contact' Information Cards from 'Contact Us' page
   // companyContacts: ICompanyContactInfo;
   usefulContactsForMarkets: IUsefulContactInfo[];
   usefulContactInfo: IUsefulContactInfo;
   contactsExist: boolean = true;
   markets: string[] = [];
   selectedMarket: string;
   accountSourceSubscription: Subscription;
   public currentWindowWidth: number;


  constructor(private accountsService: AccountsService,
              private dataService: DataService,
              private contactService: ContactService
            ) { }
  thirdParty: boolean = false;
  inDirectCust: boolean = false;

  ngOnInit() {
    this.dataService.setLoading(true);
    this.dataService.setSelectedNavItem('contact'); // highlight nav item on page refresh
    this.setCurrentActivePage('contact')
    this.dataService.setTitleSource('Contact Us'); // update title
    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
      this.thirdParty = this.myAccount.accessType == 'ThirdParty';
      this.getContacts();
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getContacts();
      }
    );

    if(this.dataService.getContractsForAccount())
      this.inDirectCust = this.dataService.getContractsForAccount().filter(c=>c.customerAgent!=null && c.status=='Active').length > 0;

    this.dataService.setContextualHelp(false);
  }

  ngOndestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  selectMarket(market){
     this.selectedMarket = market.iso;
     for (let usefulContactInfo of this.usefulContactsForMarkets){
       if(usefulContactInfo.iso.toLowerCase() == this.selectedMarket.toLowerCase()){
         this.usefulContactInfo = usefulContactInfo;
       }
     }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getContacts() {
    // Get Contacts for Account (First Section of Page)
    //let thirdParty = this.myAccount.accessType == 'ThirdParty';
      this.contactService.getContactsForAccount(this.myAccount.id, this.thirdParty).subscribe(
       (contactInfo) => {
          this.contactsForAccount = contactInfo;
          this.contactsExist = (contactInfo.accountExec != null && contactInfo.accountExec.length > 0)
              || (contactInfo.specialist != null && contactInfo.specialist.length > 0);
        }, err => {
          console.error(err);
        }
      );
    //Third Party is using mocks until further notice
    if (this.thirdParty) {
      // Task 275970: Remove 'Company Contact' Information Cards from 'Contact Us' page
      /* this.contactService.getMockCompanyContacts(this.myAccount.id).subscribe(
        (companyContacts) => {
          // console.log(companyContacts);
          this.companyContacts = companyContacts;
        }, err => {
          console.error(err);
        }
      ); */
      this.contactService.getMockUsefulContacts(this.myAccount.id).subscribe(
        (usefulContacts) => {
          this.usefulContactsForMarkets = usefulContacts;
          this.selectMarket(this.usefulContactsForMarkets[0]);
          this.dataService.setLoading(false);
        }, err => {
          this.dataService.setLoading(false);
          console.error(err);
        }
      );
    }
     else {
      // Task 275970: Remove 'Company Contact' Information Cards from 'Contact Us' page
      // Get Company Contacts For Each Market (Second Section of Page)
      /* this.contactService.getCompanyContacts(this.myAccount.id).subscribe(
        (companyContacts) => {
          this.companyContacts = companyContacts;
        }, err => {
          console.error(err);
        }
      ); */
      // Get Useful Contacts For Each Market (Third Section of Page)
      this.contactService.getUsefulContacts(this.myAccount.id).subscribe(
        (usefulContacts) => {
          this.usefulContactsForMarkets = usefulContacts;
          this.selectMarket(this.usefulContactsForMarkets[0]);
          this.dataService.setLoading(false);
        }, err => {
          this.dataService.setLoading(false);
          console.error(err);
        }
      );
    }
  }

  toggleScreenSharing() {
    if (typeof (window as any).cobrowseStart === "function") {
      try {
        (window as any).cobrowseStart();
        this.contactService.screenSharingSessionStartAudit(this.myAccount.id)
          .subscribe((res: any) => {});
      } catch (e) {
        // ignore
      }
    }
  }
}

