import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { MarketsService } from '../markets.service';
import { MarketDashboardView } from '../../shared/entities/markets';

@Component({
  selector: 'app-view-dashboard-settings',
  templateUrl: './view-dashboard-settings.component.html',
  styleUrls: ['./view-dashboard-settings.component.scss']
})
export class ViewDashboardSettingsComponent implements OnInit {
  showDBViewRadios: boolean = false;
  selectedDBSettingsName: string;

  constructor(
    public dataService: DataService,
    private marketsService: MarketsService
  ) {}

  ngOnInit() {
    this.selectedDBSettingsName = this.dataService.defaultMarketViewName;
  }

  toggleDBViewRadios() {
    this.showDBViewRadios = !this.showDBViewRadios;
  }

  toggleDBViewMenu() {
    this.dataService.openMDBViewOptions = !this.dataService.openMDBViewOptions;
  }

  setSelectedMarketView(option: MarketDashboardView) {
    this.dataService.selectedMarketViewName = option.viewName;
    this.toggleDBViewMenu();
  }

  setDBViewAsDefault() {
    this.dataService.marketViews = this.dataService.marketViews.map((view) => {
      view.viewValue = 'false';
      return view;
    });
    const defaultView = this.dataService.marketViews.find(
      (view) =>
        view.viewName.toUpperCase().trim() ===
        this.selectedDBSettingsName.toUpperCase().trim()
    );
    defaultView.viewValue = 'true';
    this.marketsService
      .saveAllMarketViews(this.dataService.marketViews)
      .subscribe((res) => {
        this.dataService.marketViews = res;
      });
    this.dataService.defaultMarketViewName = this.selectedDBSettingsName;
    this.dataService.selectedMarketViewName =
      this.dataService.defaultMarketViewName;
    this.toggleDBViewMenu();
  }
}
