import { Component, OnInit } from '@angular/core';
import { IAccount } from "../../shared/entities/account";
import { IProfile, IUserAccount, IUserDetails } from '../../shared/entities/profile';
import { DataService } from "../../shared/data.service";
import { ProfileService } from "../../profile/profile.service";
import { UserAccountPipe } from "../../shared/pipe/useraccounts.pipe";
import {Subscription} from "rxjs";
import { PortalService } from '../../shared/portal.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  users: IUserDetails[];
  showSearchRow: boolean = false;
  showThirdPartyLegend: boolean = false;
  searchTerm: string = '';
  filteredUserDetails: IUserDetails[] = [];
  selectedAccount: IUserAccount;
  selectedUserAccountSourceSubscription: Subscription;
  isAdminOrSuperUser : boolean = false;
  isThirdParty : boolean = false;


  constructor(private dataService: DataService,
              private manageProfileService: ProfileService,
              private portalService: PortalService,
              private userAccountPipe: UserAccountPipe) { }

  ngOnInit() {
    let accountName = this.dataService.getSelectedUserAccountSource();
    if(accountName) {
      this.selectedAccount = accountName;
      this.showThirdPartyLegend = this.selectedAccount.accessType === 'ThirdParty';
    }
    this.selectedUserAccountSourceSubscription = this.dataService.selectedUserAccountSourceUpdated.subscribe(
      (selectedAccount) => {
          // console.log('T&C selected account updated to ', selectedAccount);
          this.selectedAccount = selectedAccount;
          this.showThirdPartyLegend = this.selectedAccount.accessType === 'ThirdParty';

          if(this.selectedAccount){
            if(this.selectedAccount.accessLevel.toUpperCase() != 'ADMIN' && this.selectedAccount.accessLevel.toUpperCase() != 'SUPERUSER'){
              this.isAdminOrSuperUser = false;
            }
            else{
              this.isAdminOrSuperUser = true;
            }
            if(this.selectedAccount.accessType == "ThirdParty"){
              this.isThirdParty = true;
            }
            else{
              this.isThirdParty = false;
            }
        }
        
      }
    );
    // console.log('on init');

   
  }

  ngOnDestroy() {
    if (this.selectedUserAccountSourceSubscription) this.selectedUserAccountSourceSubscription.unsubscribe();
  }

  createManageUser(account: IUserAccount){
    this.dataService.setSelectedUserAccountSource(account);
  }

  acknowledge(){
    this.portalService.acknowledge().subscribe();
  }
}
