import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { IAutoPaymentState, IPaymentSchedule, IAutoPaymentSubmission, IPayment, IAutoPaymentResponse, IPaymentSettingsKey } from '../autopayments/autopayments';
import { AutoPaymentsService } from './autopayments.service';
import { PortalService } from '../shared/portal.service';
import { Subscription } from 'rxjs/Subscription';
import { IUserAccount } from '../shared/entities/profile';
import * as moment from 'moment';
import { Permissions } from '../shared/const/permissions';
import { ISite } from '../shared/entities/site';
import { IHelpStep } from '../shared/entities/contextualHelp';
import { Authority } from '../shared/const/authority';
import { ICategory, IInvoice } from '../shared/entities/bill-reports';
declare var $: any;

@Component({
  selector: 'app-autopayments',
  templateUrl: './autopayments.component.html',
  styleUrls: ['autopayments.component.scss']
})
export class AutoPaymentsComponent implements OnInit {

  tcchecked: boolean;
  tcSpecificMessage: string;
  tcSpecificTitle: string;
  state: IAutoPaymentState;
  myAccount: IUserAccount;
  buttonDisabled = true;
  printing: boolean;
  printingSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  paymentSchedule: IPaymentSchedule;
  paymentErrorModalText = '';
  paymentErrorModalTable: IPayment[];
  paymentErrorModalCanNavigate: boolean;
  allowedToMakePayment = true;
  maskMode: boolean;
  helpStep: IHelpStep;
  isSuperUser: boolean;
  hasCVV: boolean;
  invalid: boolean;
  cvvLen: number;
  autopaySel: string;
  caSelected: boolean = false;

  helpStepSubscription: Subscription;
  autopaySubscription: Subscription;

  hasNCCA = false;

  constructor(private dataService: DataService,
    private router: Router,
    private portalService: PortalService,
    private autopaymentsService: AutoPaymentsService) { }

  ngOnInit() {
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.setCurrentActivePage('payments');
    this.autopaySel = 'Change';
    this.tcchecked = false;
    this.myAccount = this.dataService.getAccountSource();
    this.settermconditionmsg();
    this.allowedToMakePayment = this.portalService.hasPermission(Permissions.MakePayment) || this.isSuperUser;
    this.maskMode = this.dataService.getMaskMode();
    if(this.paymentSchedule)
      this.paymentSchedule.savePaymentMethod = null;

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.state.backDisabled = false;
        // this.paymentsService.setBalances(null);
        this.allowedToMakePayment = this.portalService.hasPermission(Permissions.MakePayment);
        // this.navigate(1);
        this.router.navigate(['/']);
        this.myAccount = account;
        this.settermconditionmsg();
      }
    );

    this.autopaymentsService.caSelected.subscribe(
      (flag =>{
        this.caSelected = flag;
      })
    )

    this.autopaymentsService.onStepReached.subscribe(
      (step) => {
        if(step=='select')
          this.state.currentStep = 1;
        else if(step=='paymentInfo')
          this.state.currentStep = 2;
        else if(step=='review')
        {
          if(this.autopaySel == 'Cancel')
            this.state.currentStep = 2;
          else
            this.state.currentStep = 3;
        }
        else if (step=='confirm')
        {
          if(this.autopaySel == 'Cancel')
            this.state.currentStep = 3;
          else
            this.state.currentStep = 4;
        }
        this.state.backDisabled = false;

        this.updateButtonLabel();
      }
    );

    this.autopaySubscription = this.autopaymentsService.autopayUpdated.subscribe(
      (autopay) => {
        this.updateAutoPaymentSummary(autopay);
      }
    );
    this.autopaymentsService.paymentScheduleUpdated.subscribe(
      (paymentSchedule) => {
        // TODO: Check button disabled;
        this.paymentSchedule = paymentSchedule;
        if (!this.paymentSchedule || (this.paymentSchedule
              && this.paymentSchedule.paymentMethod
              && (!this.paymentSchedule.paymentMethod.userPaymentSettingsKey ||
                     (this.paymentSchedule.paymentMethod.userPaymentSettingsKey && !this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken))))
        {
          this.buttonDisabled = true;
        } else {
          this.buttonDisabled = false;
        }
        // if (this.paymentSchedule && moment(moment(this.paymentSchedule.paymentDate).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))) {
        //   this.buttonDisabled = true;
        // }
      }
    );
    this.autopaymentsService.setSubmitted(false);
    this.printingSubscription = this.dataService.printingUpdated.subscribe(
      (printing) => {
        this.printing = printing;
      }
    );
    this.state = {
      currentStep: 1,
      selectedAutopay: null,
      paymentTotal: 0,
      buttonLabel: 'Choose Payment Method',
      backDisabled: false,
    };
    this.autopaymentsService.setSubmitted(false);
    this.router.navigate(['/autopayments/autopay-select-balances']); // For page refresh, ensure they land at page 1;
    this.dataService.setSelectedNavItem('payments'); // highlight nav item on page refresh
    this.dataService.setTitleSource('AutoPay');

    this.dataService.accountSourceUpdated.subscribe(
      () => {
        const key: IPaymentSettingsKey = {
          paymentToken: null,
          userId: null,
          accountId: null
        };
      }
    );
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  autopayOptionSel(selectedOption) {
    this.autopaySel = selectedOption;
    if(selectedOption==='Cancel') {
      this.state.buttonLabel = 'Continue';
    } else if(selectedOption==='Change') {
      this.state.buttonLabel = 'Continue';//'Change Payment Method';
    }
  }

  settcChecked() {
      if(this.tcchecked)
        this.buttonDisabled = false;
      else
        this.buttonDisabled = true;

      this.settermconditionmsg();
  }

  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(paymentType == 'CARD'){
      if(accountNum.length >= 4){
        return accountNum.substring(accountNum.length-4);
      }
    }
    else{
      if(accountNum.indexOf('-') != -1){
        return 'XXXXXXX' + accountNum.split('-')[1];
      }

    }

    return 'XXXXXXXXXX';

  }

  updateAutoPaymentSummary(autopay: any) {
    this.hasNCCA = false;
    this.state.selectedAutopay = autopay;
    this.state.paymentTotal = 0;
    if(autopay) {
        if (autopay.autoPaymentFlag=='Y'
             && (autopay.tokenizedBankAccountNumber || autopay.tokenizedCcNumber))
          {
             this.autopaySel = 'Change';
             this.buttonDisabled = false;
          }
          else if(autopay.autoPaymentFlag=='N' || autopay.autoPaymentFlag=='') {
            this.autopaySel = 'Change';
            this.buttonDisabled = false;
          }
          else{
             this.autopaySel = 'Change';
             this.buttonDisabled = true;
            }
          this.updateButtonLabel();
    }
    else {
        this.buttonDisabled = true;
    }
  }

  ngOnDestroy() {
    if (this.printingSubscription) this.printingSubscription.unsubscribe();
    // this.paymentsService.setBalances(null);
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.autopaySubscription) this.autopaySubscription.unsubscribe();
    this.caSelected = false;

  }

  getFakeContractAccountNum(balance: ISite) {
    return this.portalService.getFakeContractAccountNum(balance);
  }

  // toggleAccountView(visible: boolean) {
  //   this.state.viewAccountToggled = visible;
  //   this.paymentsService.payByAccount = visible;
  // }

  setCVVLength() {
    if(this.paymentSchedule.paymentMethod.cardBrand=='Amex') {
        this.cvvLen = 4;
    } else {
        this.cvvLen = 3;
    }

  }

  nextStep() {
    if (!this.buttonDisabled) {
      if ((this.autopaySel == 'Cancel' && this.state.currentStep == 2) || this.state.currentStep == 3) {
        // this.dataService.setLoading(true);
        // this.dataService.setLoadingText('Please do not refresh page or navigate backwards while payment is processing...');
        this.buttonDisabled = true;
        // this.createPaymentMethod();
        setTimeout(() => {
          // this.dataService.setLoading(false);
           this.state.currentStep++;
          if(this.autopaySel == 'Cancel')
          {
             this.navigateCancel(3, true);
          }
          else
             this.navigate(4, true);
        }, 1500);
      } else {
          this.state.currentStep++;
          if(this.autopaySel == 'Cancel')
            this.navigateCancel();
          else
            this.navigate();
      }
    }
  }

  hasCVVFlag() {
   return this.hasCVV;
  }


  updateButtonLabel() {
    if (this.state.currentStep == 1) {
      this.state.buttonLabel = 'Continue';
    }
    else if (this.autopaySel=='Change' && this.state.currentStep == 2) {
      this.state.buttonLabel = 'Continue';
    } else if (this.autopaySel=='Cancel' && this.state.currentStep == 2){
      this.buttonDisabled = true;
      this.autopaymentsService.setActionSel('Remove');
      this.state.buttonLabel = 'Remove';
    } else if (this.autopaySel=='Change' && this.state.currentStep == 3) {
      if(this.paymentSchedule &&  this.paymentSchedule.paymentMethod.paymentType=='CARD')
          this.setCVVLength();
      this.autopaymentsService.setActionSel('Change');
      this.state.buttonLabel = 'Authorize';
      this.buttonDisabled = true;
    } else {
      this.state.backDisabled = true;
    }
  }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }

  // Only Integer Numbers
  eventHandler(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  navigateCancel(step?: number, submitting?: boolean) {
    if (step && step < this.state.currentStep && !this.state.backDisabled  && !submitting) {
      this.state.currentStep = step;
    }
    if (this.state.currentStep == 1) {
      this.settermconditionmsg();
      this.router.navigate(['/autopayments/autopay-select-balances']);
    } else if (this.state.currentStep == 2) {
         this.settermconditionmsg();
         this.router.navigate(['/autopayments/autopay-review']);
    } else if (this.state.currentStep == 3 ) {
            this.deEnrollAutopay();
    } else {
        this.enrollAutopay();
    }

    this.updateButtonLabel();
  }


  navigate(step?: number, submitting?: boolean) {
    if (step && step < this.state.currentStep && !this.state.backDisabled && !submitting) {
      this.state.currentStep = step;
    }
    if (this.state.currentStep == 1) {
      this.tcchecked = false;
      this.router.navigate(['/autopayments/autopay-select-balances']);
    } else if (this.state.currentStep == 2) {
      if(this.autopaySel != 'Cancel'){
        this.buttonDisabled = true;
        this.router.navigate(['/autopayments/autopay-info']);
      }
      else {
          this.invalid = false;
          this.settermconditionmsg()
          if(this.paymentSchedule)
          {
              this.paymentSchedule.paymentMethod.cvv = null;
          }
          this.router.navigate(['/autopayments/autopay-review']);
      }
    } else if (this.state.currentStep == 3 && !submitting) {
          this.settermconditionmsg()
          if(this.autopaySel != 'Cancel')
          {
            this.invalid = false;
            this.router.navigate(['/autopayments/autopay-review']);
          }
          else {
            this.deEnrollAutopay();
          }
    } else {
        this.enrollAutopay();
    }
    this.updateButtonLabel();
  }

  deEnrollAutopay() {
    console.log("<<<< deEnrollAutopay >>>>");

    this.state.currentStep = 3;
    const selectedSite = this.autopaymentsService.autopay;
    this.tcchecked = false;

    this.dataService.setLoadingText('Please do not refresh page or navigate backwards while payment is processing...');
    this.dataService.setLoading(true);
    this.autopaymentsService.cancelAutoPayment(selectedSite.caNumber, this.myAccount.id).subscribe(
        (resp: any) => {

          if(resp.statusCode == '00') {
            this.dataService.setLoading(false);
            this.dataService.setLoadingText('');
            this.state.currentStep = 3;
            //this.autopaymentsService.setAutoPayments(null);
            //  this.paymentSchedule.paymentMethod.cvv = null;
            //  this.hasCVV = false;
            //  this.invalid = false;
            this.router.navigate(['/autopayments/autopay-confirmation']);
          }
          else if(resp.statusCode == '99') {
            this.paymentErrorModalText = 'We were unable to process this payment at this time. If you need to make a payment today, please contact your Customer Care Representative for assistance.';
            this.paymentErrorModalCanNavigate = false;
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
            this.dataService.setLoading(false);
            this.dataService.setLoadingText('');
            this.state.currentStep = 3;
            this.autopaymentsService.setAutoPayments(null);
          }
          else {
            this.paymentErrorModalText = 'We were unable to process this payment at this time. If you need to make a payment today, please contact your Customer Care Representative for assistance.';
            this.paymentErrorModalCanNavigate = false;
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
            this.dataService.setLoading(false);
            this.dataService.setLoadingText('');
            this.state.currentStep = 3;
            this.autopaymentsService.setAutoPayments(null);
          }
      });
  }

  enrollAutopay() {
     console.log("<<<< EnrollAutopay >>>>");
     const paymentSchedule = this.autopaymentsService.paymentSchedule;
     const selectedSite = this.autopaymentsService.autopay;
     this.tcchecked = false;

     var paymentSubmission: IAutoPaymentSubmission;
     if(paymentSchedule.paymentMethod.paymentType=='BANKACCOUNT'){
           paymentSubmission= {
                 'accountId': this.myAccount.id,
                 'caNumber': selectedSite.caNumber,
                 'bankRoutingNumber': paymentSchedule.paymentMethod.routingNumber.toString(),
                 'tokenizedBankAccountNumber': paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken,
                 'name': paymentSchedule.paymentMethod.nickName,
                 'tokenizedCcNumber': '',
                 'cardBrand': '',
                 'expYear': '',
                 'expMonth': ''
               };
       }
       else if(paymentSchedule.paymentMethod.paymentType=='CARD'){
            paymentSubmission= {
                  'accountId': this.myAccount.id,
                  'caNumber': selectedSite.caNumber,
                  'bankRoutingNumber': '',
                  'tokenizedBankAccountNumber': '',
                  'name': paymentSchedule.paymentMethod.nickName,
                  'tokenizedCcNumber':  paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken,
                  'cardBrand': paymentSchedule.paymentMethod.cardBrand,
                  'expYear': paymentSchedule.paymentMethod.cardExpiryYear.toString(),
                  'expMonth': paymentSchedule.paymentMethod.cardExpiryMonth.toString()
                };
            }


    if((paymentSchedule.paymentMethod.paymentType!='CARD')  || (paymentSchedule.paymentMethod.paymentType=='CARD')) {

      this.dataService.setLoadingText('Please do not refresh page or navigate backwards while payment is processing...');
      this.dataService.setLoading(true);
      this.autopaymentsService.submitAutoPayment(paymentSubmission).subscribe(
        (resp: any) => {
          if(resp.statusCode == '00') {
                      this.dataService.setLoading(false);
                      this.dataService.setLoadingText('');
                      this.state.currentStep = 4;
                      this.router.navigate(['/autopayments/autopay-confirmation']);
          }
          else if(resp.statusCode == '99') {
                        this.dataService.setLoading(false);
                        this.dataService.setLoadingText('');
                        this.paymentErrorModalText = 'We were unable to process this payment at this time. If you need to make a payment today, please contact your Customer Care Representative for assistance.';
                        this.paymentErrorModalCanNavigate = false;
                        $('#paymentErrorModal').modal({
                          show: true,
                          backdrop: 'static'
                        });
            }
            else {
              this.dataService.setLoading(false);
              this.dataService.setLoadingText('');
              this.paymentErrorModalText = 'We were unable to process this payment at this time. If you need to make a payment today, please contact your Customer Care Representative for assistance.';
              this.paymentErrorModalCanNavigate = false;
              $('#paymentErrorModal').modal({
                show: true,
                backdrop: 'static'
              });
            }
         });
    }
  }

  accept() {
    this.dataService.setLoading(false);
    $('#paymentErrorModal').modal('hide');

    if (this.paymentErrorModalCanNavigate) {
      this.state.currentStep = 4;
      this.router.navigate(['/autopayments/autopay-confirmation']);
    }
    // else {
    //   this.navigate(3, false);
    // }
    this.buttonDisabled = false;
  }

  numbersWithCommas(x) {
    return this.portalService.numberWithCommas(x);
  }

  settermconditionmsg() {
    if(this.myAccount){

      if(this.autopaySel=='Change'){
        this.tcSpecificTitle="AutoPay terms and conditions";

        let branding = this.myAccount.accountBranding === "Reliant" ? "Reliant" : "NRG";
        let brandNumber = this.myAccount.accountBranding === "Reliant" ? "1-866-RELIANT" : "1 (844) 737-6742";
        let privacyLink = this.myAccount.accountBranding === "Reliant"
          ? "https://www.reliant.com/en/about/legal"
          : "https://www.nrg.com/legal/privacy-rights-and-requests/privacy-policy.html";

        this.tcSpecificMessage = `${branding} Automatic Payment Terms<br><br>
                      <i>Automatic Payment Options – Terms and Conditions</i><br>
                      Automatic payment(s) may be set up via the online account management platform and may be made with a credit card or debit
                      card that we accept or via an ACH draft from a bank account. To be eligible for an automatic payment, your account must be
                      in good standing, and you may not have two or more returned payments during the past twelve-month period. If your account
                      is eligible, you will be enrolled in the automatic payment plan of your choice. This service is provided as a courtesy at
                      no additional cost. In the interim, please continue making regular payments until you see “Do Not Pay” on your invoice or
                      in your ${branding} online account. Note, the amounts due presented on the OAM platform may differ from your true balance for a
                      variety of reasons. The ${branding} invoice constitutes the official record of amounts owed and your payments and usage.<br><br>
                      ${branding} may terminate your participation in automatic payment options in the event you provide incorrect, false, or fraudulent
                      account information. After your first returned payment item, your automatic payment plan will be temporarily deactivated and
                      may only be reactivated upon verification of your payment account information. Once you have more than one returned payment,
                      your account may be ineligible for reactivation on automatic payment options for up to six months.<br><br>`;

        if (branding === "NRG") {
          this.tcSpecificMessage += `Your bank account and credit card account information will be used only for the purposes of setting up your account for automatic
                      monthly payments, and NRG will keep your account information confidential. For more information on the NRG Privacy Policy,
                      please refer to <a href="${privacyLink}" target="_blank">${privacyLink}</a>. Direct inquiries to
                      NRG, P.O. Box 1532, Houston, TX 77251-1532 or call 1 (844) 737-6742.<br><br>`;
        }
        else if (branding === "Reliant") {
          this.tcSpecificMessage += `Your bank account and credit card account information will be used only for the purposes of setting up your account for automatic
                      monthly payments, and Reliant will keep your account information confidential. For more information on the Reliant Privacy Policy, please refer to
                      <a href="${privacyLink}" target="_blank">${privacyLink}</a>. Direct inquiries to Reliant, P.O. Box 228, Houston, TX 77251-00228 or call 1-866-RELIANT.<br><br>`;
        }

        this.tcSpecificMessage += `YOU ACKNOWLEDGE AND AGREE THAT ${branding.toUpperCase()} SHALL BEAR NO LIABILITY OR RESPONSIBILITY FOR ANY LOSSES OF ANY KIND THAT YOU MAY INCUR AS
                      A RESULT OF AN ERRONEOUS STATEMENT, ANY DELAY IN THE ACTUAL DATE ON WHICH YOUR ACCOUNT IS DEBITED OR YOUR FAILURE TO PROVIDE ACCURATE AND/OR
                      VALID PAYMENT INFORMATION.<br><br>
                      <i>* Visa is a trademark of Visa, Inc. Discover Network and the Discover Network Acceptance Mark are trademarks of Discover Financial Services. MasterCard
                      is a registered trademark of MasterCard International Incorporated. American Express is a registered trademark of American Express. ${branding} is not affiliated
                      with Visa, Discover Network, MasterCard, American Express or any of the programs or promotions of these entities.</i><br><br>
                      <i>Automatic Payment Authorization</i><br>
                      By providing my bank account or credit card information to ${branding}, I hereby authorize ${branding} to charge my account in the amount of my monthly invoice.
                      I understand that my ${branding} monthly invoice together with this authorization  will be notice of the amount and the date of each withdrawal from my bank account
                      or charge to my credit card. I understand that any past due balance on my account will be charged as soon as my enrollment is processed. I also understand that
                      I may cancel my automatic payment option at any time online at nrg.com/myaccount or by calling ${brandNumber}. I also agree to notify ${branding} if there are any
                      changes to my bank or credit card account information.<br><br>
                      <b>I have read and agree to the terms of automatic payments. I understand that my bank account or credit card information will be saved so that it can be
                      used to pay my bill each month. I understand that my information will remain confidential per ${branding}'s <a href="${privacyLink}" target="_blank">privacy statement</a>. I understand that I am authorizing
                      ${branding} to charge my bank account or credit card, as applicable, for the amount of my monthly invoice on an ongoing basis.`;
    }
      }
      else if(this.autopaySel=='Cancel') {
        this.tcSpecificTitle="AutoPay cancellation terms and conditions";
        this.tcSpecificMessage = "My monthly payment will no longer be automatically charged to my credit card or drafted from my bank account. I understand that I must opt out of AutoPay at least 2 business days before my next payment due date to avoid additional charges or debits, and I will now have to pay my bill online, by mail or by phone before the due date each month. It may take up to 2 business days to process the cancellation request. After Automatic Payments are discontinued, my bill will no longer indicate \"Do Not Pay\".\<br\>\<br\>";
        this.tcSpecificMessage = this.tcSpecificMessage + " I understand that if I am cancelling my AutoPay to sign up for a payment arrangement option (Deferred Payment Plan or Payment Extension) and the cancellation is less than the required 2 business day period described above, the total amount of the invoice may still be drafted as scheduled.";
      }
  }
}
