import { Component, OnInit } from '@angular/core';
import { IUserAccount } from "../../shared/entities/profile";
import { DataService } from "../../shared/data.service";
import { Subscription } from "rxjs";
import { AccountsService } from '../../accounts/accounts.service';
import { IAmendmentSite, IAmendmentRequest, IAmendmentProduct, IUtility } from "../../shared/entities/amendments";
import { AmendmentsService } from "../amendments.service";
import { FadeAnimation } from "../../animations/fadeAnimation";
import { Router } from "@angular/router";
declare var $: any;
import * as moment from 'moment';
import { IContract } from "../../shared/entities/contract";
import { PortalService } from "../../shared/portal.service";
import { IAddress } from "../../shared/entities/address";
import { ISite } from "../../shared/entities/site";
import { UsageComparisonService } from "../../usage-comparison/usage-comparison.service";
import { IComparisonReport, IComparisonChartData } from "../../shared/entities/comparison";
import { ComparisonUtility } from "../../shared/comparison-reports.utility";
import { IProduct } from "../../shared/entities/product";
import { AmendmentsAttachmentsService } from "../amendments-attachments.service";
import { Authority } from "../../shared/const/authority";
import { States } from "../../shared/const/utility";
import { IProductDateRange } from "../../shared/entities/daterange";
import { IMarketState } from "../../shared/entities/marketState";

@Component({
  selector: 'app-amendments-add',
  templateUrl: './amendments-add.component.html',
  animations: [FadeAnimation],
  styleUrls: ['../amendments.component.scss', 'amendments-add.component.scss']
})
export class AmendmentsAddComponent implements OnInit {

  myAccount: IUserAccount;
  isSuperUser: boolean = false;
  contractsForAccount: IContract[] = [];
  sitesForAccount: ISite[];
  markets: string[];
  form: IAmendmentSite = {} as any;
  numberOfAttachments: number = 0;
  completionError: string;
  hasBlockIndexProduct: boolean;

  step = 1;

  // Site Essentials
  siteIdError: string;
  validSiteId: boolean;
  showSiteIdValidation: boolean;
  tempSiteId: string;
  States: string[];
  EDCs: IUtility[] = [];
  errorMessage1: string;

  // About the Premise

  // Request Date
  minDate: Date;
  maxDate: Date;
  contractsAvailableForDate: boolean;

  // Product Selection
  contractDateMessage: string;
  productOverlapMessage: string;
  productDatesOverlap: boolean;
  anyChecks: boolean;
  contractsLoading: boolean;

  productCountFlag: boolean;

  // Billing Information
  showBillingAddress: boolean;
  showNewBillingAddress: boolean;
  billingAddresses: IAddress[];
  showBillingStates: boolean;

  // Expected Site Load
  showComparisonSites: boolean;
  numOfSitesToShow: number = 25;
  siteSearch: string;
  filteredComparisonSites: ISite[];
  comparisonSites: ISite[];
  selectedSite: ISite;
  selectedRequest: IAmendmentRequest;
  manuallyAdding: boolean;
  show6MonthsNote: boolean;
  startingMonth: any;
  monthOptions: any[];
  monthLabels1: string[] = ['January', 'February', 'March', 'April', 'May', 'June'];
  monthLabels2: string[] = ['July', 'August', 'September', 'October', 'November', 'December'];
  manualTotalConsumption: number = 0;
  usageUnknown: boolean = false;
  residential: boolean = false;
  completionDateDate: Date;
  completionDateDate1: Date;
  completionDateDate2: Date;
  invalidCompletionDate: boolean;
  currentProductStart: string;
  currentProductEnd: string;
  overlappingProductError: boolean;

  // Subscriptions
  accountSubscription: Subscription;

  constructor(private dataService: DataService,
    private accountsService: AccountsService,
    private amendmentsService: AmendmentsService,
    private portalService: PortalService,
    private usageComparisonService: UsageComparisonService,
    private comparisonUtility: ComparisonUtility,
    private router: Router,
    private attachmentService: AmendmentsAttachmentsService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Submit Request ');
    let existingForm = this.dataService.getSelectedAmendmentSite();
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    if (existingForm) {
      this.form = existingForm;
      if (this.form.completionDate) {
        this.completionDateDate = moment(this.form.completionDate).toDate();
        this.completionDateDate1 = moment(this.form.completionDate).toDate();
        this.completionDateDate2 = moment(this.form.completionDate).toDate();
      }
    }
    this.myAccount = this.dataService.getAccountSource();
    if (this.myAccount) {
      this.form.accountId = this.myAccount.id;
      this.filterBillingAddresses();
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goHome();
    });

    /*if(moment().hour() >= 17){
      this.minDate = moment().add(2, 'days').toDate();
    } else {
      this.minDate = moment().add(1, 'days').toDate();
    }*/

    this.maxDate = moment().add(10, 'years').toDate();

    document.addEventListener("click", ($event) => {
      if ($($event.target).parents('#billingAddressSection').length == 0) {
        this.showBillingAddress = false;
      }
      if ($($event.target).parents('#comparisonSiteSection').length == 0) {
        this.showComparisonSites = false;
      }
    });

    this.selectedRequest = this.dataService.getSelectedAmendmentRequest();
    if (this.selectedRequest) {
      this.form.requestId = this.selectedRequest.id;
      this.form.amendmentType = 'ADD';
      this.attachmentService.getAttachmentsList(this.selectedRequest.id).subscribe((attachmentList: any) => {
        // console.log("ATTACHMENT LIST: ", attachmentList);
        this.dataService.setCurrentAttachments(attachmentList);
        this.numberOfAttachments = attachmentList.length;
      }
      );
    } else {
      this.goHome();
    }

    if (!this.form.siteId) {
      this.dataService.setShowAmendmentsHeader(false);
    }
    this.tempSiteId = this.form.siteId;

    if (this.form.id) {
      if (this.form.siteId && this.form.siteId.includes('_')) {
        this.form.uan = this.form.siteId.split('_')[0];
        this.form.meterNum = this.form.siteId.split('_')[1];
      }
      this.getStep();
    }

    this.States = this.dataService.getMarketStates().filter(st => st.market == 'ALL').map(st => st.state);

    if (this.dataService.getAccountWithContractDataSource()) {

      this.markets = this.dataService.getAccountWithContractDataSource().markets;
      if (this.markets.length == 1) {
        this.form.market = this.markets[0];
      }
    }

    if (this.form.market) {
      this.getEDCs();
    }

  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    this.dataService.setShowAmendmentsHeader(false);
  }

  getMarketStates() {
    let retStates: string[];
    retStates = this.dataService.getMarketStates().filter(st => st.market == this.form.market).map(st => st.state);
    if (retStates.length == 1) {
      this.form.siteState = retStates[0];
    }
    return retStates;
  }

  getSiteIdDisplay(siteId: string) {
    return this.portalService.getSiteIdDisplay(siteId);
  }

  isValidZip(zip: string): boolean {
    var regex = new RegExp("^[a-zA-Z0-9\-]+$");
    if (!regex.test(zip)) {
      return false;
    }
    return true;

  }

  isValidComment(text: string): boolean {
    var regex = new RegExp("^[a-zA-Z0-9.,'-_ ]*$");
    if (!regex.test(text)) {
         this.completionError = 'Please ensure you use alphanumeric characters only in the comments.';
         return false;
    }
    this.completionError = '';
    return true;
  }

  continue() {
    this.errorMessage1 = '';
    if(this.form.market == 'PJM'){
      this.form.energizedWithOtherProvider == true;
    }
    if (this.step == 1 && this.form.market != 'ERCOT') {
      this.form.meterExists = true;
      this.dataService.setShowAmendmentsHeader(true);
      if (this.form.tdsp == 'CLP' || this.form.tdsp == 'MWST') {
        if (!this.form.meterNum || this.form.meterNum.length < 9) {
          this.errorMessage1 = 'Please enter a valid 9-digit meter number.';
        } else if (!this.form.uan || this.form.uan.length < 11) {
          this.errorMessage1 = 'Please enter a valid 11-digit UAN.';
        }

      } else {
        if (!this.form.siteId || this.form.siteId.length < 9) {
          this.errorMessage1 = 'Please enter a valid UAN with a length of 9 digits or greater.';
        }
        if (!this.isValidZip(this.form.siteZip)) {
          this.errorMessage1 = "Please enter a valid zip code with alphanumeric characters only.";
        }
      }
    }
    if (this.step == 2 && this.form.market != 'ERCOT') {
      this.minDate = moment().toDate();
      if(this.form.market == 'PJM'){
        this.form.energizedWithOtherProvider == true;
      }
    }
    if (this.step < 7 && !this.errorMessage1) {
      this.step++;
    }
    if (this.step == 4) {
      this.getProductCount();
    }
    if (this.step >= 7) {
      this.validateForm();
    }
    this.scrollToStep();
  }

  getStep() {
    this.step = 1;

    // Prepare
    this.completionDateChanged();

    if (this.form.billingAddress && this.form.billingCity && this.form.billingState && this.form.billingZip) {
      if (!this.form.comparisonSiteId && !this.form.consumptionMonth1) {
        this.usageUnknown = true;
      } else if (!this.form.comparisonSiteId && this.form.consumptionMonth1) {
        this.manuallyAdding = true;
      }
      this.step = 6;
    } else if (this.anyChecks) {
      this.step = 5;
    } else if (this.form.completeByMeterReadDate || (this.form.acceptedTDSPFees || (!this.onOrBeforeNextBusinessDay() && !this.form.energizedWithOtherProvider)) && this.form.completionDate && this.contractsAvailableForDate) {
      this.step = 4;
    } else if (this.form.taxExempt != undefined) {
      this.step = 3;
    } else if (this.form.siteId && this.form.siteAddress && this.form.siteCity && this.form.siteState && this.form.siteZip) {
      this.step = 2
    }

    if (this.step > 1) {
      this.dataService.setSelectedAmendmentSite(this.form);
      this.dataService.setShowAmendmentsHeader(true);
      this.scrollToStep(2);
    }
  }

  scrollToStep(secs?: number) {
    setTimeout(() => {
      if (secs) {
        $("html, body").animate({ scrollTop: $('#step' + this.step).offset().top }, secs * 1000);
      } else {
        $("html, body").animate({ scrollTop: $('#step' + this.step).offset().top }, 1000);
      }
    }, 50)
  }

  goHome() {
    this.dataService.setSelectedAmendmentSite(undefined);
    this.router.navigate(['/amendments/home']);
  }

  changeandsave() {
    let existingForm = this.dataService.getSelectedAmendmentSite();
    console.log("changeandsave : ", existingForm);
    this.form.completionDate = null;
    this.completionDateDate = null;
    this.completionDateDate1 = null;
    this.completionDateDate2 = null;
    this.form.completeByMeterReadDate = false;
    this.form.completeByMeterReadOrSpecificDate = false;
    if(this.form.market == 'PJM'){
      this.form.energizedWithOtherProvider = true;
    }
    this.invalidCompletionDate = false;
    this.form.acceptedTDSPFees = null;
    this.save();
  }

  save() {
    if (this.form.uan && this.form.meterNum) {
      this.form.siteId = this.form.uan + '_' + this.form.meterNum;
    }
    // if(this.form.energizedWithOtherProvider == false){
    //   this.form.completeByMeterReadOrSpecificDate = true;
    // }
    this.amendmentsService.saveAmendmentSite(this.form).subscribe((resp: any) => {
      if (!this.form.id) {
        this.form.id = resp.id;
      }
      //replace old site with updated response
      let newSiteList = [];
      if (!this.selectedRequest.amendmentSites) {
        this.selectedRequest.amendmentSites = [];
      } else {
        this.selectedRequest.amendmentSites.forEach((amendmentSite) => {
          if (amendmentSite.id != this.form.id) {
            newSiteList.push(amendmentSite);
          }
        });
      }

      this.form.complete = resp.complete;
      this.form.products = resp.products;

      if(this.form.market == 'PJM'){
        this.form.energizedWithOtherProvider = true;
      }
      newSiteList.push(this.form);
      this.selectedRequest.amendmentSites = newSiteList;
      
      this.dataService.setSelectedAmendmentRequest(this.selectedRequest);
    });
  }

  goToContracts() {
    this.dataService.setSelectedAmendmentSite(undefined);
    this.router.navigate(['/contracts']);
    // let url = window.location.href;
    // let urlArr = url.split('#');
    // let newUrl = urlArr[0] + '#/contracts';
    // let win = window.open(newUrl, '_blank');
    // win.focus();

  }

  // Site Essentials ////////////////////////////////////////////////////////////////////////////////

  selectMarket(market: string) {
    this.form.market = market;
    if (market != 'ERCOT') {
      this.getEDCs();
    }
    this.save();
  }

  getEDCs() {
    this.amendmentsService.getUtilities(this.form.market).subscribe((utilities: any) => {
      this.EDCs = utilities;
      if (this.form.tdsp) {
        this.EDCs.forEach((edc) => {
          if (edc.utilityCode == this.form.tdsp) {
            this.form.tdspName = edc.utility;
            this.form.edcSel = edc;
          }
        });
      }
    })
  }

  selectEDC(edc: IUtility) {
    this.form.tdspName = edc.utility;
    this.form.tdsp = edc.utilityCode;
  }

  selectState(state: string) {
    this.form.siteState = state;
    this.save();
    if (this.step >= 4) {
      this.getFilteredContracts();
    }
  }

  hasNonNumeric(str: string) {
    return str.match(/[^$,.\d]/);
  }

  tempSiteIdChanged() {
    this.showSiteIdValidation = false;
    this.validSiteId = false;
    this.form.siteAddress = null;
    this.form.siteCity = null;
    this.form.siteState = null;
    this.form.siteZip = null;
    this.dataService.setSelectedAmendmentSite(this.form);
  }

  validateSiteId() {
    this.validSiteId = false;
    if (!this.tempSiteId) {
      this.siteIdError = 'Please enter an ESI ID.';
      return;
    } else if (this.tempSiteId && this.tempSiteId.length != 17 && this.tempSiteId.length != 22 && this.tempSiteId.length != 15) {
      this.siteIdError = 'ESI IDs are either 15, 17 or 22 digit numbers';
      return;
    } else {
      this.siteIdError = null;
      this.showSiteIdValidation = true;
      this.amendmentsService.validateSiteId(this.tempSiteId).subscribe(
        (resp: any) => {
          if (resp.status.toLowerCase() != 'inactive') {
            this.validSiteId = true;
            this.form.siteId = this.tempSiteId;
            this.form.siteAddress = resp.address;
            this.form.siteCity = resp.city;
            this.form.siteState = resp.state;
            this.form.siteZip = resp.zipCode;
            this.form.zone = resp.zone;
            this.form.tdsp = resp.tdsp;
            this.form.tdspName = resp.tdspName;
            if (resp.premiseType) {
              this.residential = resp.premiseType.toLowerCase().match('residential') && !resp.premiseType.toLowerCase().match('non-residential');
            }
            this.form.meterReadCycle = resp.meterReadCycle;
          } else {
            this.validSiteId = false;
            this.form.siteId = this.tempSiteId;
          }
          this.dataService.setShowAmendmentsHeader(true);
          this.dataService.setSelectedAmendmentSite(this.form);
          this.save();
        },
        err => {
          this.validSiteId = false;
          this.form.siteId = this.tempSiteId;
          this.dataService.setShowAmendmentsHeader(true);
          this.dataService.setSelectedAmendmentSite(this.form);
          this.save();
        });
    }

  }

  // About the Premise////////////////////////////////////////////////////////////////////////////////////

  // Request Date ////////////////////////////////////////////////////////////////////////////////////////

  onOrBeforeNextBusinessDay() {
    if (!this.form.completionDate) {
      return false;
    }
    let nextBusinessDay;
    if (moment().day() == 4) {
      // Today is Friday
      nextBusinessDay = moment().add(3, 'days');
    } else if (moment().day() == 5) {
      // Today is Saturday
      nextBusinessDay = moment().add(2, 'days');
    } else {
      // Today is Sun - Thurs
      nextBusinessDay = moment().add(1, 'days');
    }
    return moment(this.form.completionDate).isSameOrBefore(nextBusinessDay);
  }

  completionDateChanged1() {
    this.completionDateDate = this.completionDateDate1;
    this.completionDateChanged();
  }

  completionDateChanged2() {
    this.completionDateDate = this.completionDateDate2;
    this.completionDateChanged();
  }

  completionDateChanged() {
    this.invalidCompletionDate = false;
    this.contractsAvailableForDate = false;
    if (moment(this.completionDateDate).isBefore(moment(this.minDate).startOf('day')) || moment(this.completionDateDate).isAfter(moment(this.maxDate))) {
      this.form.completionDate = null;
      this.invalidCompletionDate = true;
    } else {
      this.form.completionDate = moment(this.completionDateDate).format('YYYY-MM-DD');
    }
    if (this.form.completionDate) {
      this.getFilteredContracts();
    }

    this.contractsAvailableForDate = true;
    this.save();
  }

  completeByMeterReadSelected() {
    this.form.completeByMeterReadOrSpecificDate = null;
    this.form.completeByMeterReadDate = true;
    if (this.form.completeByMeterReadDate) {
      this.completionDateDate = moment().add(1, 'day').toDate();
      this.form.completionDate = moment(this.completionDateDate).format('YYYY-MM-DD');
      this.getFilteredContracts();
      if (this.form.completionDate) {
        this.completionDateDate1 = null;
        this.completionDateDate2 = null;
      }
    }
  }

  completeByMeterReadOrSpecificDate() {
    this.form.completeByMeterReadDate = false;
    this.form.completionDate = null;
    // if(this.form.completionDate) {
    this.completionDateDate = moment(this.form.completionDate).toDate();
    if (this.form.completeByMeterReadOrSpecificDate) {
      this.completionDateDate1 = moment(this.form.completionDate).toDate();
      this.completionDateDate2 = null;
    } else {
      this.completionDateDate2 = moment(this.form.completionDate).toDate();
      this.completionDateDate1 = null;
    }
    // }
    this.invalidCompletionDate = false;
  }

  getFilteredContracts() {
    this.contractsLoading = true;
    this.overlappingProductError = false;
    this.portalService.spinBabySpin('contractsLoader');
    this.amendmentsService.getFilteredContracts(this.form).subscribe((resp: any) => {
      this.hasBlockIndexProduct = resp.hasBlockIndexProduct;
      this.contractsForAccount = [];
      this.accountsService.getSite(this.myAccount.id, this.form.siteId).subscribe((site: any) => {
        if (resp.contracts) {
          resp.contracts.forEach((contract) => {
            if (moment(contract.deliveryStartDate).isAfter(moment(site.productInfo.deliveryEndDate))) {
              this.contractsForAccount.push(contract);
            }
          });
        }
        if (resp.contracts && resp.contracts.length > 0 && this.contractsForAccount.length == 0) {
          this.overlappingProductError = true;
          this.currentProductStart = moment(site.productInfo.deliveryStartDate).startOf('day').format('MM/DD/YY');
          this.currentProductEnd = moment(site.productInfo.deliveryEndDate).startOf('day').format('MM/DD/YY');
        }
        if (this.contractsForAccount.length > 0) {
          this.prepareContracts();
        }
        this.contractsLoading = false;
      }, err => {
        // Site not found
        this.contractsForAccount = resp.contracts;
        if (this.contractsForAccount.length > 0) {
          this.prepareContracts();
        }
        this.contractsLoading = false;
      });

    });
  }

  // Product Selection ///////////////////////////////////////////////////////////////////////

  contractChecked(contract: IContract) {
    setTimeout(() => {
      //Wait 0.01s for model to update before triggering other checkboxes #HACK
      let amendmentProduct;
      this.contractsForAccount.forEach((contract) => {
        if (contract.contractNum == contract.contractNum) {
          contract.partialCheck = false;
          contract.products.forEach((product) => {
            amendmentProduct = this.getAmendmentProductFromProduct(product, contract);
            // console.log('amendmentProduct to save 2 is ', amendmentProduct);
            if (!product.checked && contract.checked) {
              this.saveProduct(amendmentProduct);
            } else if (product.checked && !contract.checked) {
              this.deleteProduct(amendmentProduct);
            }
            product.checked = contract.checked;
          });
        }
      });
      this.getContractDateMessage();
      this.getProductOverlapMessage();
      this.checkForAnyChecks();
    }, 10);
  }

  checkForCompletedForm() {
    this.checkForAnyChecksOnContractOrProduct();
    this.validateForm();
    if (!this.completionError) {
      this.form.complete = true;
    } else {
      this.form.complete = false;
    }
  }

  checkForAnyChecksOnContractOrProduct() {
    this.anyChecks = false;
    this.contractsForAccount.forEach((contract) => {
      if (contract.checked) {
        this.anyChecks = true;
      }
      contract.products.forEach((product) => {
        if (product.checked) {
          this.anyChecks = true;
        }
      })
    });
  }

  checkForAnyChecks() {
    this.checkForAnyChecksOnContractOrProduct();
    this.getComparisonSites();
  }

  getComparisonSites() {
    this.comparisonSites = [];
    let containsSelectedComparisonSite = false;
    if (this.anyChecks) {
      this.contractsForAccount.forEach((contract) => {
        this.sitesForAccount.forEach((site) => {
          if (contract.contractNum == site.contractNum && contract.checked) {
            this.comparisonSites.push(site);
            if (site.siteId == this.form.comparisonSiteId) {
              containsSelectedComparisonSite = true;
            }
          }
        });
      });
      if (!containsSelectedComparisonSite) {
        // this.form.comparisonSiteId = null;
        this.selectedSite = null;
      }
      this.searchSites();
    } else {
      this.filteredComparisonSites = [];
      // this.form.comparisonSiteId = null;
      this.selectedSite = null;
    }
    this.sitesForAccount.forEach((site) => {
      if (site.siteId == this.form.comparisonSiteId) {
        this.selectedSite = site;
      }
    });
  }

  productChecked(product, contract) {
    let amendmentProduct = this.getAmendmentProductFromProduct(product, contract);
    // console.log('amendmentProduct to save is ', amendmentProduct);
    setTimeout(() => {
      if (product.checked) {
        this.saveProduct(amendmentProduct);
      } else {
        this.deleteProduct(amendmentProduct);
      }
      this.getPartialChecks();
    }, 10);
  }

  loadChecks() {
    // Get checkboxes
    this.contractsForAccount.forEach((contract) => {
      contract.products.forEach((product) => {
        if (this.form.products) {
          this.form.products.forEach((amendmentProduct) => {
            if (product.id == amendmentProduct.productId) {
              product.checked = true;
              this.productChecked(product, contract);
            }
          });
        }
      })
    });
  }

  getPartialChecks() {
    this.contractsForAccount.forEach((contract) => {
      contract.partialCheck = false;
      let checkedCount = 0;
      contract.products.forEach((product) => {
        if (product.checked) {
          contract.partialCheck = true;
          contract.checked = true;
          checkedCount++;
        } else {
        }
        if (checkedCount === contract.products.length) {
          contract.partialCheck = false;
        } else if (checkedCount == 0) {
          contract.checked = false;
        }
      });
    });

    this.getContractDateMessage();
    this.getProductOverlapMessage();
    this.checkForAnyChecks();

  }

  getProductCount() {
    this.contractsForAccount.forEach((contract) => {
      if (contract.products.length > 1) {
        this.productCountFlag = true;
      }
    });
  }

  saveProduct(product: IAmendmentProduct) {
    // console.log('saving amendmentProduct ', product);
    this.amendmentsService.saveProduct(this.form, product).subscribe((resp) => {
      // console.log(resp);
      if (!this.form.products) {
        this.form.products = [];
      }
      this.form.products.push(product);

      this.checkForCompletedForm();

      this.save();
    });
  }

  deleteProduct(product: IAmendmentProduct) {
    this.amendmentsService.deleteProduct(this.form, product).subscribe((resp) => {
      // console.log(resp);
      let newProducts = [];
      this.form.products.forEach((formProduct) => {
        if (product.productId != formProduct.productId) {
          newProducts.push(formProduct);
        }
      });
      this.form.products = newProducts;

      this.checkForCompletedForm();

      this.save();
    });
  }

  getAmendmentProductFromProduct(product: IProduct, contract: IContract) {
    let amendmentProduct = product as any;
    amendmentProduct.amendmentSiteId = this.form.id;
    amendmentProduct.contractId = contract.contractNum;
    amendmentProduct.productId = product.id;
    amendmentProduct.productName = product.name;
    amendmentProduct.deliveryStartDate = product.deliveryStartDate;
    amendmentProduct.deliveryEndDate = product.deliveryEndDate;
    return amendmentProduct;
  }

  getContractDateMessage() {
    let allDatesAfterSystemDate = true;
    let datesCurrentForSystemDate = true;
    this.contractsForAccount.forEach((contract) => {
      if (contract.checked || contract.partialCheck) {
        if (moment(contract.deliveryStartDate).isSameOrBefore(moment()) || moment(contract.deliveryEndDate).isSameOrBefore(moment())) {
          allDatesAfterSystemDate = false;
        }
        if (moment(contract.deliveryStartDate).isSameOrAfter(moment()) || moment(contract.deliveryEndDate).isSameOrBefore(moment())) {
          datesCurrentForSystemDate = false;
        }
      }
    });
    if (allDatesAfterSystemDate) {
      this.contractDateMessage = 'You chose to add this site to future contract(s). Please select an active contract if you would like to add this site to a current contract.';
    } else if (datesCurrentForSystemDate) {
      this.contractDateMessage = 'You chose to add this site to current contract(s). Please select future start contracts if you would like to add this site to your future contract(s).';
    } else {
      this.contractDateMessage = '';
    }
  }

  getProductOverlapMessage() {
    var datesArray: IProductDateRange[] = [];
    this.productDatesOverlap = false;
    this.contractsForAccount.forEach((contract) => {
      if (contract.checked || contract.partialCheck) {
        contract.products.forEach((product) => {
          if (product.checked) {
            datesArray.push({ productName: product.detailName, startDate: product.deliveryStartDate, endDate: product.deliveryEndDate });
            console.log('Added ' + product.detailName);
          }
        });
      }
      datesArray.forEach((dateRange) => {
        datesArray.forEach((dateRangeToCheck) => {
          if (dateRange.productName != dateRangeToCheck.productName &&
            moment(dateRange.startDate).isSameOrAfter(moment(dateRangeToCheck.startDate)) &&
            moment(dateRange.startDate).isSameOrBefore(moment(dateRangeToCheck.endDate))) {
            this.productDatesOverlap = true;
            this.step = 4;
            console.log(dateRange.startDate + ' : ' + dateRange.endDate + '----' + dateRangeToCheck.startDate + ' : ' + dateRangeToCheck.endDate)
          }
          if (dateRange.productName != dateRangeToCheck.productName &&
            moment(dateRange.endDate).isSameOrAfter(moment(dateRangeToCheck.startDate)) &&
            moment(dateRange.startDate).isSameOrBefore(moment(dateRangeToCheck.endDate))) {
            this.productDatesOverlap = true;
            this.step = 4;
            console.log(dateRange.startDate + ' : ' + dateRange.endDate + '----' + dateRangeToCheck.startDate + ' : ' + dateRangeToCheck.endDate)
          }
        });

      });

    });
    if (this.productDatesOverlap) {
      this.productOverlapMessage = 'Two or more selected product terms overlap. Please modify your product selection to continue.';
      console.log(this.productOverlapMessage);
    }
    else {
      this.productOverlapMessage = '';
    }
    console.log(this.productDatesOverlap);
  }

  getContractNickname(contractNum: string) {
    return this.portalService.getContractNickname(contractNum);
  }

  prepareContracts() {
    let completion = moment(this.form.completionDate);
    this.contractsForAccount.forEach((contract) => {
      if (moment(contract.deliveryStartDate).isBefore(completion) && moment(contract.deliveryEndDate).isAfter(completion)) {
        this.contractsAvailableForDate = true;
      }
      contract.toggled = true;
    });
    this.loadChecks();
    this.checkForAnyChecks();
    this.getContractDisplayDates();
  }

  getContractDisplayDates() {
    this.contractsForAccount.forEach((contract) => {
      contract.deliveryStartDateDisplay = moment(contract.deliveryStartDate).format('MM/DD/YYYY');
      contract.deliveryEndDateDisplay = moment(contract.deliveryEndDate).format('MM/DD/YYYY');
      contract.products.forEach((product) => {
        product.deliveryStartDateDisplay = moment(product.deliveryStartDate).format('MM/DD/YYYY');
        product.deliveryEndDateDisplay = moment(product.deliveryEndDate).format('MM/DD/YYYY');
      })
    })
  }

  // Billing Information /////////////////////////////////////////////////////////////////////

  filterBillingAddresses() {
    this.billingAddresses = [];
    let unique;
    this.sitesForAccount = this.dataService.getSitesForAccountSource();
    this.filteredComparisonSites = [];
    this.comparisonSites = [];
    if (this.sitesForAccount) {
      this.sitesForAccount.forEach((site) => {
        unique = true;
        this.billingAddresses.forEach((address) => {
          if (address.streetAddress == site.billingAddress.streetAddress) {
            unique = false;
          }
        });
        if (unique) this.billingAddresses.push(site.billingAddress);
      });
    }
  }

  addNewAddress() {
    this.showNewBillingAddress = true;
    this.toggleBillingAddress();
  }

  toggleBillingStates() {
    this.showBillingStates = !this.showBillingStates;
  }

  selectBillingState(state: string) {
    this.form.billingState = state;
    this.toggleBillingStates();
    this.save();
  }

  selectBillingAddress(address: IAddress) {
    this.form.billingAddress = address.streetAddress;
    this.form.billingCity = address.city;
    this.form.billingState = address.state;
    this.form.billingZip = address.zipCode;
    this.form.attn = address.attentionName;
    this.toggleBillingAddress();
    this.showNewBillingAddress = false;
    this.save();
  }

  useSiteAddressAsBilling() {
    if (!this.residential) {
      this.form.billingAddress = this.form.siteAddress;
      this.form.billingState = this.form.siteState;
      this.form.billingCity = this.form.siteCity;
      this.form.billingZip = this.form.siteZip;
      this.toggleBillingAddress();
      this.showNewBillingAddress = false;
      this.save();
    }
  }

  getBillingAddressDisplay() {
    let siteId = '';
    if (this.form.billingAddress && this.form.billingCity && this.form.billingState && this.form.billingZip && this.sitesForAccount) {
      this.sitesForAccount.forEach((site) => {
        if (site.billingAddress.streetAddress == this.form.billingAddress || site.serviceAddress.streetAddress == this.form.billingAddress) {
          siteId = site.siteId;
        }
      });
      return this.form.billingAddress + ', ' + this.form.billingCity + ', ' + this.form.billingState + ' ' + this.form.billingZip;
    } else {
      return 'Select an address';
    }
  }

  toggleBillingAddress() {
    this.showBillingAddress = !this.showBillingAddress;
  }

  // Expected Site Load ///////////////////////////////////////////////////////////////////////////
  loadScroller() {
    const component = this;
    const div = document.getElementById('comparisonSitesComponent')
    $(div).scroll(function () {
      // console.log($(div).scrollTop() + ':' + this.scrollHeight);
      if ($(div).scrollTop() > this.scrollHeight - 500) {
        component.numOfSitesToShow = component.numOfSitesToShow + 25;
      }
    });
  }

  toggleComparisonSites() {
    this.showComparisonSites = !this.showComparisonSites;
    if (this.showComparisonSites) {
      setTimeout(() => {
        this.loadScroller();
      }, 1500);
    }
  }

  searchSites() {
    if (this.siteSearch) {
      this.filteredComparisonSites = [];
      this.comparisonSites.forEach((site) => {
        if (site.siteId.toString().match(this.siteSearch) ||
          (site.serviceAddress && site.serviceAddress.streetAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.siteSearch.toLowerCase())) ||
          this.siteSearch == '') {
          this.filteredComparisonSites.push(site);
        }
      });
    } else {
      this.filteredComparisonSites = this.comparisonSites;
    }
  }

  selectSite(site: ISite) {
    this.selectedSite = site;
    if (this.selectedSite.siteId.indexOf('-') > -1) {
      this.form.comparisonSiteId = this.selectedSite.siteId.split('-')[1];
    } else {
      this.form.comparisonSiteId = this.selectedSite.siteId;
    }
    this.form.comparisonPct = 0;
    this.form.consumptionMonth1 = null;
    this.form.consumptionMonth2 = null;
    this.form.consumptionMonth3 = null;
    this.form.consumptionMonth4 = null;
    this.form.consumptionMonth5 = null;
    this.form.consumptionMonth6 = null;
    this.form.consumptionMonth7 = null;
    this.form.consumptionMonth8 = null;
    this.form.consumptionMonth9 = null;
    this.form.consumptionMonth10 = null;
    this.form.consumptionMonth11 = null;
    this.form.consumptionMonth12 = null;
    this.form.expectedTotalConsumption = 0;
    this.manuallyAdding = false;
    this.usageUnknown = false;
    this.toggleComparisonSites();
    this.getConsumption();
    this.save();
  }

  getConsumption() {
    this.show6MonthsNote = false;
    let usageRequest: IComparisonReport = {
      accountId: this.myAccount.id,
      reportName: 'Let my people go',
      year1: moment().year(),
      year2: moment().year() - 1,
      siteRequest: "SPECIFIC_SITE",
      siteId: this.form.comparisonSiteId,
      defaultReport: 0
    };
    let consumptionMonths = 0, combinedMonthlyConsumption = 0;
    this.usageComparisonService.getCharts(usageRequest).subscribe((usageResponse) => {
      let chartData: IComparisonChartData = this.comparisonUtility.extractChartData(usageResponse, usageRequest);
      let totalMonths = 0;
      combinedMonthlyConsumption = 0;
      for (let i = moment().month(); i > 0; i--) {
        if (chartData.usageYear1[i - 1]) {
          combinedMonthlyConsumption += chartData.usageYear1[i - 1];
          consumptionMonths++;
        }
        totalMonths++;
      }
      // console.log('chartData is ', chartData);
      for (let i = 12; i > 0; i--) {
        if (totalMonths == 12) break;
        if (chartData.usageYear2[i - 1]) {
          combinedMonthlyConsumption += chartData.usageYear2[i - 1];
          consumptionMonths++;
        }
        totalMonths++;
      }
      if (consumptionMonths <= 6) {
        this.show6MonthsNote = true;
      }
      this.form.combinedMonthlyConsumption = combinedMonthlyConsumption;
      this.form.monthsDataAvailable = consumptionMonths;
      this.form.comparisonTotalUom = 'kWh';
      this.save();
      if (this.form.comparisonPct) {
        this.getExpectedTotalUsage();
      }
    });
  }

  getExpectedTotalUsage() {
    this.form.expectedTotalConsumption = this.form.combinedMonthlyConsumption * (this.form.comparisonPct * 0.01);
    this.save();
  }

  manuallyAdd() {
    this.toggleComparisonSites();
    this.form.comparisonSiteId = null;
    this.selectedSite = null;
    this.form.expectedTotalConsumption = 0;
    this.manuallyAdding = true;
    this.usageUnknown = false;
    this.save();
  }

  selectUsageUnknown() {
    this.toggleComparisonSites();
    this.form.comparisonSiteId = null;
    this.selectedSite = null;
    this.form.expectedTotalConsumption = 0;
    this.usageUnknown = true;
    this.manuallyAdding = false;
    this.save();
  }

  consumptionMonthUpdated() {
    this.form.expectedTotalConsumption = 0;
    for (let i = 1; i < 13; i++) {
      if (this.form['consumptionMonth' + i]) {
        this.form.expectedTotalConsumption += this.form['consumptionMonth' + i];
      }
    }
    this.save();
  }

  numbersWithCommas(x) { return this.portalService.numberWithCommas(x); }

  continueToCart() {
    this.validateForm();
    if (!this.completionError) {
      this.form.complete = true;
      this.save();
      this.router.navigate(['/amendments/cart']);
    }
  }

  validateForm() {
    this.completionError = null;
    console.log("completeByMeterReadDate : " + this.form.completeByMeterReadDate);
    console.log("acceptedTDSPFees : " + this.form.acceptedTDSPFees);
    console.log("energizedWithOtherProvider : " + this.form.energizedWithOtherProvider);
    console.log("completionDate : " + this.form.completionDate);
    console.log("completeByMeterReadOrSpecificDate : " + this.form.completeByMeterReadOrSpecificDate);
    console.log("contractsAvailableForDate : " + this.contractsAvailableForDate);
    console.log("billingAddress : " + this.form.billingAddress);
    console.log("billingCity : " + this.form.billingCity);
    console.log("billingState : " + this.form.billingState);
    console.log("billingZip : " + this.form.billingZip);
    if (this.form.siteId && this.form.siteAddress && this.form.siteCity && this.form.siteState && this.form.siteZip && (this.form.meterReadCycle || this.form.market == 'ERCOT')) {
      // Step 1 validated
      if (this.form.taxExempt != undefined) {
        // Step 2 validated
        //if((this.form.market == 'ERCOT' && (this.form.completeByMeterReadDate || (this.form.acceptedTDSPFees || (!this.onOrBeforeNextBusinessDay() && !this.form.energizedWithOtherProvider)) && this.form.completionDate && this.contractsAvailableForDate))
        //  || this.form.market != 'ERCOT' && this.form.completionDate) {
        if ((this.form.market == 'ERCOT' && (this.form.completeByMeterReadDate ||
          ((this.form.acceptedTDSPFees || (!this.onOrBeforeNextBusinessDay() &&
            !this.form.energizedWithOtherProvider))) || this.form.completeByMeterReadOrSpecificDate) && this.form.completionDate && this.contractsAvailableForDate)
          || this.form.market != 'ERCOT' && this.form.completionDate) {
          // Step 3 validated
          if (this.anyChecks) {
            // Step 4 validated
            if (this.form.billingAddress && this.form.billingCity && this.form.billingState && this.form.billingZip) {
              // Step 5 validated
              if (this.isValidZip(this.form.billingZip)) {

                if (((this.form.comparisonSiteId && this.form.comparisonPct) || this.manuallyAdding || this.usageUnknown)) {
                  // Step 6 validated
                } else {
                  this.completionError = 'Please ensure you\'ve completed all required fields in the "Expected Site Usage" section.';
                }
              } else {
                this.completionError = 'Please enter a valid zip code.'
              }
            } else {
              this.completionError = 'Please ensure you\'ve filled out all fields in the "Billing Information" section.';
            }
          } else {
            this.completionError = 'Please ensure you\'ve selected a product to add in the "Product Selection" section.';
          }
        } else {
          this.completionError = 'Please ensure you\'ve completed all required information in the "Request Date" section.';
        }
      } else {
        this.completionError = 'Please ensure you\'ve answered all questions in the "About the Premise" section.';
      }
    } else {
      this.completionError = 'Please ensure the site information is filled out completely in the "Site Information" section.';
    }
    if (!this.isValidComment(this.form.additionalComments)) {
      this.completionError = 'Please ensure you use alphanumeric characters only in the comments.';
    }
  }
}

