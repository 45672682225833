import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from "rxjs";
import { DataService } from '../shared/data.service';
import { PortalService } from "../shared/portal.service";
import { Widget } from "../shared/entities/widgets/widget";
import { WidgetType } from "../shared/const/widgetType";
import { DashboardService } from "./dashboard.service";
import { IUserAccount } from "../shared/entities/profile";
import { BillingSummaryWidget } from "../shared/entities/widgets/billingSummaryWidget";
import { IntervalUsageWidget } from "../shared/entities/widgets/intervalUsageWidget";
import { HelpTopicsWidget } from "../shared/entities/widgets/helpTopicsWidget";
import { UsageSummaryWidget } from "../shared/entities/widgets/usageSummaryWidget";
import { RecentInvoicesWidget } from "../shared/entities/widgets/recentInvoicesWidget";
import { IDashboard } from "../shared/entities/dashboard";
import { ISiteGroup, ISite } from "../shared/entities/site";
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare let $: any;
import * as moment from 'moment';
import { FadeAnimation } from "../animations/fadeAnimation";
import { ProfileComponent } from "../profile/profile.component";
import { ProfileService } from "../profile/profile.service";
import { IHelpStep } from "../shared/entities/contextualHelp";
import { IAccount } from "../shared/entities/account";
import { removeWhitespaces } from "@angular/compiler/src/ml_parser/html_whitespaces";
import { UserAccountPipe } from "../shared/pipe/useraccounts.pipe";
import { ITealiumRequest } from "../shared/entities/tealium";
import { CookieService } from 'ngx-cookie-service';
import { Authority } from "../shared/const/authority";

declare global {
  interface Window { nrg_datalayer: any; }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  animations: [FadeAnimation],
  styleUrls: ['dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UserAccountPipe, CookieService]
})
export class DashboardComponent implements OnInit {

  widgetOrder: number[];
  addingWidget: boolean = false;
  myAccount: IUserAccount;
  accountWithContractData: IAccount;
  widgetTypes: Array<{ display: string }> = [];
  newWidget: Widget;
  profileSubscription: Subscription;
  username: string;
  dashboard: IDashboard;
  systemSiteGroups: ISiteGroup[];
  userSiteGroups: ISiteGroup[];
  promoContent: any;
  isFirst30Days: boolean = false;
  loadedProfile: boolean = false;
  helpStep: IHelpStep;

  tealiumContracts: string[];
  tealiumId: string;
  tealiumEmail: string;
  tealiumBypass: string = "false";

  accountSourceSubscription: Subscription;
  realTimeDataReportSubscription: Subscription;
  helpStepSubscription: Subscription;
  accountWithDataSourceSubscription: Subscription;

  isSuperUser: boolean = false;

  //this.realTimeDataReportSubscription = this.dataService.realTimeDataReportTriggered.subscribe(() => {this.openRealTimeDataReport()});
  constructor(private router: Router,
    private service: DashboardService,
    private portalService: PortalService,
    private dataService: DataService,
    private manageProfileService: ProfileService,
    private cookieService: CookieService) { }

  ngOnInit() {
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.dataService.setSelectedNavItem('dashboard');
    this.setCurrentActivePage('dashboard');
    this.myAccount = this.dataService.getAccountSource();
    let profile = this.dataService.getProfile();
    if (profile) {
      this.loadedProfile = true;
      this.dataService.setTitleSource('Welcome, ' + profile.firstName + '!');
      if (!profile.firstLogin) {
      //  $('#welcomeModal').modal('show');
        this.manageProfileService.setInitialLogin(profile.userId).subscribe((profile) => {
        });
      }
    } else {
      this.dataService.setTitleSource('Welcome back!');
    }

    this.profileSubscription = this.dataService.profileUpdated.subscribe(
      (profile) => {
        if (profile.email) {
          this.tealiumEmail = profile.email;
        }
        else {
          this.tealiumEmail = 'noemail.email@test.com';
        }

        if (profile.firstName) {
          this.dataService.setTitleSource('Welcome, ' + profile.firstName + '!');
        } else {
          this.dataService.setTitleSource('Welcome back!');
        }
        if (!this.loadedProfile) {
          this.loadedProfile = true;
          if (!profile.firstLogin) {
          //  $('#welcomeModal').modal('show');
            this.manageProfileService.setInitialLogin(profile.userId).subscribe((profile) => {
            });
          }
        }

        let user = this.dataService.getUserSource();
        if (user) {
          this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
        }
        this.dataService.userSourceUpdated.subscribe((user) => {
          this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
        });
      }
    );

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
      }
    );

    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    if (this.accountWithContractData) {
      this.getWidgetTypes();
      this.getDashboard();
      this.getPromoContentInit();
    }
    this.accountWithDataSourceSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.accountWithContractData = account;
      this.getWidgetTypes();
      this.getDashboard();
      this.getPromoContentInit();

      if (!this.isSuperUser) {
        this.checkRenewal();
      }
    });

    this.dataService.addWidgetTriggered.subscribe(
      () => {
        this.addingWidget = true;
      });

    this.realTimeDataReportSubscription = this.dataService.realTimeDataReportTriggered.subscribe(() => { this.openRealTimeDataReport() });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.profileSubscription) this.profileSubscription.unsubscribe();
    if (this.realTimeDataReportSubscription) this.realTimeDataReportSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.accountWithDataSourceSubscription) this.accountWithDataSourceSubscription.unsubscribe();
  }


  openRealTimeDataReport() {
    this.service.getRealTimeDataReportUrl(this.myAccount.id).subscribe(
      (url) => {
        // console.log(url);
        window.open(url, '_blank');
      }
    );
  }

  getPromoContentInit() {
    this.dataService.setLoading(true);

    if (this.dataService.getIsProfileDataLoaded()) {
      this.getPromoContent();
    } else {
      this.dataService.profileDataLoaded.subscribe(() => {
        this.getPromoContent();
      })
    }
  }

  getPromoContent() {
    this.promoContent = { relatedContent: {}, relatedNews: {} };

    this.service.getPromoData().subscribe(
      (promoContent: any) => {
        // let content = JSON.parse(promoContent);
        this.promoContent.relatedContent = promoContent.contentPayload.children[0].payload.relatedContent;
        this.promoContent.relatedNews = promoContent.contentPayload.children[0].payload.relatedNews;
      }
    );
    if (this.promoContent.relatedContent && this.promoContent.relatedContent.items) {
      this.promoContent.relatedContent.items = this.promoContent.relatedContent.items.map((item: any) => {
        switch (item.type) {
          case 'RelatedCaseStudyItem':
            item.eyebrow = 'CASE STUDY';
            break;
          case 'RelatedInsightItem':
            item.eyebrow = 'INSIGHTS';
            break;
          case 'RelatedProductItem':
            item.eyebrow = 'PRODUCT / SERVICE';
            break;
          case 'RelatedWhitePaperItem':
            item.eyebrow = 'WHITE PAPER';
            break;
          case 'RelatedEventItem':
            item.eyebrow = 'EVENT';
            break;
          default:
            item.eyebrow = '';
            break;
        }
        return item;
      });
    }

    this.dataService.setLoading(false);
  }

  navigateTo(dest: string) {
    this.router.navigate(['/' + dest]);
    //this.showSubNav = false;
  }

  // stripHtml(html) {
  //   let innerHtml = html.substring(html.indexOf('>') + 1, html.lastIndexOf('<'));
  //   return innerHtml;
  // }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }


  getWidgetTypes() {
    this.widgetTypes = [];
    for (var key in WidgetType) {
      if (WidgetType.hasOwnProperty(key)) {
        let display: string;
        if (WidgetType[key] === WidgetType.BillingSummary && this.accountWithContractData && (this.accountWithContractData.billTypes[0] != 'Consolidated' || this.accountWithContractData.billTypes.length != 1)) {
          display = 'Billing Summary';
        } else if (WidgetType[key] === WidgetType.IntervalUsage && this.myAccount.market != 'NORTHEAST' && !(this.myAccount.contractAuthorization == 'Brokers' && !this.myAccount.isOverridden)) {
          display = 'Interval Usage';
        } else if (WidgetType[key] === WidgetType.RecentInvoices && this.accountWithContractData && (this.accountWithContractData.billTypes[0] != 'Consolidated' || this.accountWithContractData.billTypes.length != 1)) {
          display = 'Recent Invoices';
        } else if (WidgetType[key] === WidgetType.UsageSummary && !(this.myAccount.contractAuthorization == 'Brokers' && !this.myAccount.isOverridden)) {
          // console.log('made it to usage summary');
          display = 'Usage Summary';
        }
        if (display) {
          // console.log('pushing ' + display);
          this.widgetTypes.push({ display: display });
        }
      }
    }
  }

  removeWidget(widget: Widget) {
    for (var i = 0; i < this.dashboard.widgets.length; i++) {
      if (this.dashboard.widgets[i]['widgetId'] === widget.widgetId) {
        // this.dashboard.widgets.splice(i,1);
        let serverWidget = this.prepareDeleteWidget(this.dashboard.widgets[i]);
        let request = serverWidget;
        this.dashboard.widgetIds = this.dashboard.widgetIds.replace(widget.widgetId.toString(), '');
        request.widgetIds = this.dashboard.widgetIds;
        this.service.deleteWidget(request).subscribe(
          (account) => {
            this.dashboard.widgets.splice(i, 1);
            this.dashboard.widgets = this.orderWidgets(this.dashboard.widgets, this.dashboard.widgetIds.split(','));
            this.dataService.setDashboardSize(this.dashboard.widgets.length);
          }
        );
        break;
      }
    }
  }

  selectNewWidget(widgetNameDisplay: any) {
    let widgetName = widgetNameDisplay.display.replace(/\s/g, '').toUpperCase();
    if (widgetName === WidgetType.BillingSummary) {
      this.newWidget = new BillingSummaryWidget(widgetName);
    } else if (widgetName === WidgetType.IntervalUsage) {
      this.newWidget = new IntervalUsageWidget(widgetName);
    }
    // else if(widgetName === WidgetType.HelpTopics) {
    //   this.newWidget = new HelpTopicsWidget(widgetName);
    // }
    else if (widgetName === WidgetType.RecentInvoices) {
      this.newWidget = new RecentInvoicesWidget(widgetName);
    } else if (widgetName === WidgetType.UsageSummary) {
      this.newWidget = new UsageSummaryWidget(widgetName);
    }
  }

  addWidget(widget: any) {
    this.newWidget.accountId = this.myAccount.id;
    let serverWidget = this.prepareServerWidget(widget);
    if (!this.dashboard.widgets) {
      this.dashboard.widgets = [];
    }
    let request = serverWidget;
    request.widgetIds = this.dashboard.widgetIds;
    if (this.dashboard.widgets.length < 12) {
      this.service.addOrUpdateWidget(request).subscribe(
        (widget: any) => {
          serverWidget.widgetId = widget.widgetId;
          this.dashboard.widgets.push(serverWidget);
          this.dashboard.widgetIds = this.getWidgetOrder();
          this.dataService.setDashboardSize(this.dashboard.widgetIds.split(',').length);
          this.addingWidget = false;
          this.service.triggerAddedWidget(widget);
        }
      )
    }
  }

  getDashboard() {
    this.service.getDashboard(this.myAccount).subscribe(
      (dashboard) => {
        this.dashboard = dashboard;
        this.allocateWidgetIds();
        console.log('dashboard', this.dashboard);
        if (this.dashboard.widgetIds && this.dashboard.widgets) {
          this.dataService.setDashboardSize(this.dashboard.widgetIds.split(',').length);
          this.dashboard.widgets = this.orderWidgets(this.dashboard.widgets, this.dashboard.widgetIds.split(','));
        }
      }
    )
  }

  // Billing Summary Widget is of the interface (logan sending)
  // Billing Summary Widget example can be found billing comparison top two currency and consumption (months instead of years)

  orderWidgets(widgets: Widget[], widgetIds: any) {
    let orderedWidgets = [];
    widgetIds.forEach((id, i) => {
      widgets.forEach((widget) => {
        if (widget.widgetId == id) {
          orderedWidgets.push(widget);
        }
      })
    });
    this.setPlaceholder(orderedWidgets);
    return orderedWidgets;
  }

  widgetResized() {
    this.orderWidgets(this.dashboard.widgets, this.dashboard.widgetIds.split(','))
    // this.getDashboard();
  }

  setPlaceholder(widgets) {
    widgets.forEach((widget, i) => {
      let currWidgetWidth = widget.width;
      let nextWidgetWidth = widgets[i + 1] ? widgets[i + 1].width : null;
      if (currWidgetWidth === 2 && nextWidgetWidth === 2) {
        widget.showPlaceholder = true;
      } else {
        widget.showPlaceholder = false;
      }
    });
  }

  setDatesValues(widget) {
    if (widget.periodType === 'Last6Months') {

    }
  }

  allocateWidgetIds() {
    this.dashboard.widgetIds = '';
    this.dashboard.widgets.forEach((widget => {
      this.dashboard.widgetIds += widget.widgetId + ',';
    }))
  }

  getWidgetOrder(): string {
    let widgetIds: string = '';
    if (this.dashboard.widgets) {
      this.dashboard.widgets.forEach((widget, i, widgets) => {
        if (i === widgets.length - 1) {
          widgetIds += widget.widgetId;
        } else {
          widgetIds += widget.widgetId + ',';
        }
      });
    }
    return widgetIds;
  }

  reorderWidgets() {
    this.dashboard.widgetIds = this.getWidgetOrder();
    this.service.reorderWidgets(this.dashboard).subscribe((dashboard) => { console.log(dashboard) })
  }

  widgetDropped() {
    this.reorderWidgets();
  }

  // widgetDropped(widgetIds) {
  //   let newOrder = [];
  //   let targetWidgetIndex = null;
  //   let currWidgetIndex = null;
  //   let placeholder = widgetIds.targetWidgetId.indexOf('ph');

  //   if (this.dashboard.widgetIds.charAt(0) === ',') {
  //     newOrder = this.dashboard.widgetIds.substring(1).split(',');
  //   } else {
  //     newOrder = this.dashboard.widgetIds.split(',');
  //   }

  //   newOrder.forEach((widgetId, index)=>{
  //     if(placeholder) {
  //       widgetIds.targetWidgetId = widgetIds.targetWidgetId.replace('ph', '');
  //     }
  //     if (widgetId == widgetIds.targetWidgetId) {
  //     targetWidgetIndex = index;
  //     }
  //     if (widgetId == widgetIds.currWidgetId) {
  //      currWidgetIndex = index;
  //     }
  //   });

  //   while (currWidgetIndex < 0) {
  //       currWidgetIndex += newOrder.length;
  //   }
  //   while (targetWidgetIndex < 0) {
  //       targetWidgetIndex += newOrder.length;
  //   }
  //   if (targetWidgetIndex >= newOrder.length) {
  //       var k = targetWidgetIndex - newOrder.length;
  //       while ((k--) + 1) {
  //           newOrder.push(undefined);
  //       }
  //   }

  //   if (placeholder > -1) {
  //     console.log('placeholder');
  //     newOrder.splice(targetWidgetIndex + 1, 0, newOrder.splice(currWidgetIndex, 1)[0]);

  //   } else {
  //     console.log('not placeholder');
  //     newOrder.splice(targetWidgetIndex, 0, newOrder.splice(currWidgetIndex, 1)[0]);
  //   }

  //   // newOrder.splice(targetWidgetIndex, 0, newOrder.splice(currWidgetIndex, 1)[0]);
  //   this.dashboard.widgets = this.orderWidgets(this.dashboard.widgets, newOrder);
  //   this.dashboard.widgetIds = this.getWidgetOrder();
  //   this.reorderWidgets();
  // }

  prepareServerWidget(widget: any) {
    let serverWidget: any = {};
    serverWidget.widgetId = widget.widgetId ? widget.widgetId : null;
    serverWidget.widgetName = widget.widgetName;
    serverWidget.width = widget.width;
    serverWidget.height = widget.height;
    serverWidget.accountId = widget.accountId;
    serverWidget.periodType = widget.periodType ? widget.periodType.value : null;
    serverWidget.dateType = widget.dateType ? widget.dateType.value : null;
    serverWidget.billingDataType = widget.billingDataType ? widget.billingDataType.value : null;
    serverWidget.siteRequest = widget.siteRequest ? widget.siteRequest.value : null;
    serverWidget.systemGroupName = widget.systemGroupName ? widget.systemGroupName.value : null;
    serverWidget.groupCategory = widget.groupCategory ? widget.groupCategory.value : null;
    serverWidget.siteId = widget.siteId ? widget.siteId.value : null;
    serverWidget.groupId = widget.groupId ? widget.groupId.value : null;

    return serverWidget;
  }

  prepareDeleteWidget(widget: any) {
    let serverWidget: any = {};
    serverWidget.widgetId = widget.widgetId ? widget.widgetId : null;
    serverWidget.widgetName = widget.widgetName;
    serverWidget.width = widget.width;
    serverWidget.height = widget.height;
    serverWidget.accountId = widget.accountId;
    serverWidget.periodType = widget.periodType ? widget.periodType : null;
    serverWidget.dateType = widget.dateType ? widget.dateType : null;
    serverWidget.billingDataType = widget.billingDataType ? widget.billingDataType : null;
    serverWidget.siteRequest = widget.siteRequest ? widget.siteRequest : null;
    serverWidget.systemGroupName = widget.systemGroupName ? widget.systemGroupName : null;
    serverWidget.groupCategory = widget.groupCategory ? widget.groupCategory : null;
    serverWidget.siteId = widget.siteId ? widget.siteId : null;
    serverWidget.groupId = widget.groupId ? widget.groupId : null;

    return serverWidget;
  }

  getStarted() {
    this.dataService.toggleContextualHelp();
    //$('#welcomeModal').modal('hide');
  }

  async getTealium() {

    let delay = window.location.hostname.indexOf("dev1") >= 0 || window.location.hostname.indexOf("localhost") >= 0 ? environment.tealiumNonProdDelay : environment.tealiumProdDelay;

    //wait 10 seconds
    await new Promise(f => setTimeout(f, delay));

    this.service.getTealium(this.accountWithContractData.id, this.tealiumId).subscribe(
      (tealiumData) => {

        if (tealiumData && tealiumData.customerName != undefined && tealiumData != null) {
          tealiumData.customerName = decodeURI(tealiumData.customerName);
          tealiumData.customerName = tealiumData.customerName.replace(/%26/g, "&");
        }

        tealiumData.accountId = this.accountWithContractData.id;
        tealiumData.email = this.tealiumEmail;
        tealiumData.tealId = this.tealiumId;

        //Mock Data for testing
        //tealiumData.customerName = "El%20Cortez%20Villas%20Condominium%20Association%20Inc";
        //tealiumData.contractNumbers = [ "1-1O4G2M0", "2-1O4G2M0", "3-1O4G2M0", "4-1O4G2M0" ];
        //tealiumData.largestRenewalMonth = "10";
        //tealiumData.smallestRenewalMonth = "10";
        //tealiumData.eligibleForRenewal = true;
        //tealiumData.primaryContact = true;
        //tealiumData.secondaryContact = false;

        if (tealiumData.eligibleForRenewal && (tealiumData.primaryContact || tealiumData.secondaryContact)) //only show modal for those eligibleForRenewal
        {
          this.dataService.setTealiumData(tealiumData);
          $('#accountRenewalModal').modal('show');
        }
      }
    )

  }

  checkRenewal() {

    //get new TEAL ID
    //this.cookieService.remove("TEAL");
    window.dispatchEvent(new CustomEvent("resetTealiumVisitor"));


    //this.tealiumEmail = 'John.Doe@test.com';
    this.tealiumContracts = [];

    let contracts = this.accountWithContractData.contracts;
    if (Array.isArray(contracts) && contracts.length > 0) {
      contracts.forEach((contract) => {
        this.tealiumContracts.push(contract.contractNum);
      })
    }

    this.tealiumId = this.cookieService.get('TEAL');
    this.tealiumBypass = this.cookieService.get('TealiumByPass' + this.accountWithContractData.id);

    if (this.tealiumId == undefined || this.tealiumId == null) {
      //this.tealiumId = 'testvisitor' + parseInt(Math.random().toFixed(10).replace("0.","")).toString();
      this.tealiumId = 'testvisitor123456';
    }

    if (this.tealiumBypass == undefined || this.tealiumBypass == null) {
      //this.tealiumId = 'testvisitor' + parseInt(Math.random().toFixed(10).replace("0.","")).toString();
      this.tealiumBypass = 'false';
    }

    if (this.tealiumBypass == "false") //if customer has already renewed or dismissed we will bypass tealium call
    {

      let tealiumRequest: ITealiumRequest = {
        sbl_cta_contracts: this.tealiumContracts,
        oam_user_email: this.tealiumEmail,
        user_unique_id: this.accountWithContractData.id,
        tealium_visitor_id: this.tealiumId
      };

      this.service.createTealium(tealiumRequest).subscribe(
        (tealiumResponse) => {

          this.getTealium();


        }
      );

    }

  }
}
