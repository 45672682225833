import { DatePipe } from '@angular/common';
import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { notStrictEqual } from 'assert';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { PortalService } from '../../shared/portal.service';
import { DataService } from '../../shared/data.service';
import { IMaintenanceNote } from '../maintenance-note';
import { IRenewableSite } from '../renewable-site';
import { IGenerationHistoryMonth } from '../renewables-history/generation-history-month';
import { RenewablesService } from '../renewables.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-maintenance-modal',
  templateUrl: './maintenance-modal.component.html',
  styleUrls: ['./maintenance-modal.component.scss']
})
export class MaintenanceModalComponent implements OnDestroy {
  newMaintenanceNote: IMaintenanceNote;
  isNewNote: boolean;
  isHistoryView: boolean;

  siteSel: IRenewableSite;
  allMaintenanceNotes: IMaintenanceNote[];
  filteredMaintenanceNotes: IMaintenanceNote[] = [];
  statusValues: string[] = [];
  editStatus: string;
  cptTimeValues: string[] = [];
  editCptTime: string;
  internalView: boolean;
  siteView: boolean;
  editView: boolean;
  addNewNote: boolean;
  title: string;
  subtitle: string;
  showSearchRow = false;
  searchTerm = '';
  siteId = '';
  private readonly unsubscribe$ = new Subject();
  maskModeSubscription: Subscription;
  maskMode = false;

  constructor(
    private datePipe: DatePipe,
    private portalService: PortalService,
    private dataService: DataService,
    private renewablesService: RenewablesService
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openSite(site: IRenewableSite, internalView: boolean, maskMode: boolean) {
    this.siteView = true;
    this.maskMode = maskMode;
    if (!this.maskMode) {
      this.title = `${site.name} (${site.capacity} MW)`;
      this.subtitle = site.serviceAddress;
    } else {
      const siteName = this.portalService.getFakeSiteName(site);
      const siteAddress = this.portalService.getFakeStreetAddress(site);
      this.title = `${siteName} (${site.capacity} MW)`;
      this.subtitle = siteAddress;
    }

    this.setDropdowns();
    this.open(site, null, internalView);
  }

  setDropdowns() {
    this.statusValues = ['Online', 'Online; Limited Production', 'Issue Detected', 'Unavailable', 'Offline'];
    for (let i = 0; i <= 23; i++) {
      if (i < 10) {
          this.cptTimeValues.push('0' + i + ':00');
      } else  {
        this.cptTimeValues.push(i + ':00');
      }
   }
  }

  selectCPTTime(cptTimeSel: string, maintenanceNote: IMaintenanceNote) {
      const dt = maintenanceNote.dateCal;
      const x = cptTimeSel.substring(0, cptTimeSel.indexOf(':'));
      maintenanceNote.editNote.time = x + ':00';
  }

  selectStatus(statusSel: string, maintenanceNote: IMaintenanceNote) {
    maintenanceNote.editNote.generationStatus = statusSel;
  }

  selectNewRowStatus(statusSel: string) {
    this.newMaintenanceNote.generationStatus = statusSel;
  }

  selectNewRowCPTTime(cptTimeSel: string) {
    const dt = this.newMaintenanceNote.dateCal;
    const x = cptTimeSel.substring(0, cptTimeSel.indexOf(':'));
    this.newMaintenanceNote.time = x + ':00';
}

  openHistoryMonth(historyMonth: IGenerationHistoryMonth, internalView: boolean, maskMode: boolean) {
    this.siteView = false;
    this.maskMode = maskMode;
    this.title = this.datePipe.transform(historyMonth.date, 'MMMM yyyy');
    this.subtitle = null;
    this.open(null, historyMonth.maintenanceNotes, internalView);
  }

  close() {
    $('#maintenanceModal').modal('hide');
  }

  getName(maintenanceNote: IMaintenanceNote) {
    if (this.maskMode) {
      return this.portalService.getFakeSiteName(maintenanceNote);
    }
    return maintenanceNote.name;
  }

  getServiceAddress(maintenanceNote: IMaintenanceNote) {
    if (this.maskMode) {
      return this.portalService.getFakeStreetAddress(maintenanceNote);
    }
    return maintenanceNote.serviceAddress;
  }

  addNote() {
    this.isNewNote = true;
    const temp = new Date();
    temp.setHours(0);
    temp.setMinutes(0);
    this.newMaintenanceNote = Object.assign({
                                        id: null,
                                        siteId: this.siteId,
                                        date : temp,
                                        time : '00:00',
                                        generationStatus : '',
                                        note  : '',
                                        showNote: false,
                                        isValidEdit: true
                                      });
  }

  exitEdit(maintenanceNote: IMaintenanceNote) {
    maintenanceNote.editNote = null;
    maintenanceNote.isValidEdit = true;
  }

  exitAddNewNote() {
    this.isNewNote = false;
    const temp = new Date();
    temp.setHours(0);
    temp.setMinutes(0);
    this.newMaintenanceNote = Object.assign({
      id: '',
      siteId: '',
      date : temp,
      time : '',
      generationStatus : '',
      note  : '',
      showNote: false
    });
  }

  editNote(maintenanceNote: IMaintenanceNote) {
    // TODO: Put row into edit mode
    maintenanceNote.editNote = Object.assign({}, maintenanceNote);
  }

  cancelEdit(maintenanceNote: IMaintenanceNote) {
    // TODO: Restore row state
    maintenanceNote.editNote = null;
    maintenanceNote.isValidEdit = false;
  }

  toggleShowNote(maintenanceNote: IMaintenanceNote){
    maintenanceNote.editNote.showNote = !maintenanceNote.editNote.showNote;
  }

  saveNewNote(maintenanceNote: IMaintenanceNote){
    this.saveNote(maintenanceNote, true);
  }

  saveNote(maintenanceNote: IMaintenanceNote, isNew: boolean) {
    // TODO: Show spinner
      maintenanceNote.isValidEdit = true;
      if (isNew) {
        const d = new Date(maintenanceNote.dateCal);
        maintenanceNote.date = [
                          d.getFullYear(),
                          ('0' + (d.getMonth() + 1)).slice(-2),
                          ('0' + d.getDate()).slice(-2)
                        ].join('-');
        maintenanceNote.date = maintenanceNote.date + ' ' +  maintenanceNote.time.substring(0, maintenanceNote.time.indexOf(':'));;
        this.renewablesService.upsertMaintenanceNote(maintenanceNote)
        .takeUntil(this.unsubscribe$)
        .subscribe(savedMaintenanceNote => {
          maintenanceNote.id = savedMaintenanceNote.id;
          maintenanceNote.date = savedMaintenanceNote.date;
          maintenanceNote.dateCal = moment(maintenanceNote.date.substr(0, maintenanceNote.date.indexOf(' '))).toDate();
          maintenanceNote.time = maintenanceNote.date.substr(maintenanceNote.date.indexOf(' ') + 1, maintenanceNote.date.length);
          maintenanceNote.dateCal.setHours(parseInt(maintenanceNote.time));
          maintenanceNote.time = maintenanceNote.time + ':00';
          maintenanceNote.siteId = savedMaintenanceNote.siteId;
          maintenanceNote.generationStatus = savedMaintenanceNote.generationStatus;
          maintenanceNote.note = savedMaintenanceNote.note;
          maintenanceNote.showNote = savedMaintenanceNote.showNote;
          maintenanceNote.editNote = null;
          if (this.siteSel!=null) {
            maintenanceNote.name = this.siteSel.name;
            maintenanceNote.serviceAddress = this.siteSel.serviceAddress;
          }
          if (this.allMaintenanceNotes == null) {
            this.allMaintenanceNotes = [];
            this.filteredMaintenanceNotes = [];
          }
          this.allMaintenanceNotes.push(maintenanceNote);
          this.isNewNote = false;
          if(this.allMaintenanceNotes!==null) {
             this.allMaintenanceNotes = this.allMaintenanceNotes.sort((a, b) => a.dateCal < b.dateCal ? 1 : -1);
          }
          this.filteredMaintenanceNotes = this.allMaintenanceNotes;
          if (this.siteSel!=null) {
            this.siteSel.generationStatus = this.filteredMaintenanceNotes[0].generationStatus;
            this.siteSel.maintenanceNotes = this.filteredMaintenanceNotes;
            this.setAlertNote();
          }
        },
        err => {
         if (err.status == 409) {
           maintenanceNote.isValidEdit = false;
          // $('#duplicateModal').modal('show');
         }
        });
      } else {
          const d = new Date(maintenanceNote.editNote.dateCal);
          maintenanceNote.editNote.date = [
                              d.getFullYear(),
                              ('0' + (d.getMonth() + 1)).slice(-2),
                              ('0' + d.getDate()).slice(-2)
                            ].join('-');
          maintenanceNote.editNote.date = maintenanceNote.editNote.date + ' '
                                         + maintenanceNote.editNote.time.substring(0, maintenanceNote.editNote.time.indexOf(':'));
          this.renewablesService.upsertMaintenanceNote(maintenanceNote.editNote)
          .takeUntil(this.unsubscribe$)
          .subscribe(savedMaintenanceNote => {
            maintenanceNote.id = savedMaintenanceNote.id;
            maintenanceNote.date = savedMaintenanceNote.date;
            maintenanceNote.dateCal = moment(maintenanceNote.date.substr(0, maintenanceNote.date.indexOf(' '))).toDate();
            maintenanceNote.time = maintenanceNote.date.substr(maintenanceNote.date.indexOf(' ') + 1, maintenanceNote.date.length);
            maintenanceNote.dateCal.setHours(parseInt(maintenanceNote.time));
            maintenanceNote.time = maintenanceNote.time + ':00';
            maintenanceNote.siteId = savedMaintenanceNote.siteId;
            maintenanceNote.generationStatus = savedMaintenanceNote.generationStatus;
            maintenanceNote.note = savedMaintenanceNote.note;
            maintenanceNote.showNote = savedMaintenanceNote.showNote;
            maintenanceNote.editNote = null;
            if (this.siteSel!=null) {
              maintenanceNote.name = this.siteSel.name;
              maintenanceNote.serviceAddress = this.siteSel.serviceAddress;
            }
            if(this.allMaintenanceNotes!==null) {
             this.allMaintenanceNotes = this.allMaintenanceNotes.sort((a, b) => a.dateCal < b.dateCal ? 1 : -1);
            }
            this.filteredMaintenanceNotes = this.allMaintenanceNotes;
            if(this.siteSel!=null) {
              this.siteSel.generationStatus = this.filteredMaintenanceNotes[0].generationStatus;
              this.siteSel.maintenanceNotes = this.filteredMaintenanceNotes;
              this.setAlertNote();
            }
          },
           err => {
            if (err.status == 409) {
              maintenanceNote.isValidEdit = false;
              // $('#duplicateModal').modal('show');
            }
          });
      }
  }

  deleteNote(maintenanceNote: IMaintenanceNote) {
    // TODO: Show spinner
    this.renewablesService.deleteMaintenanceNote(maintenanceNote)
      .takeUntil(this.unsubscribe$)
      .subscribe(
        () => {
        },
        (err) => {
                if (err.status === 200) {
                  const index = this.allMaintenanceNotes.indexOf(maintenanceNote);
                  if (index > -1) {
                    this.allMaintenanceNotes.splice(index, 1);
                    this.filteredMaintenanceNotes = this.allMaintenanceNotes;
                    if (this.filteredMaintenanceNotes[0] != null) {
                      this.siteSel.generationStatus = this.filteredMaintenanceNotes[0].generationStatus;
                    } else {
                      this.siteSel.generationStatus = '';
                    }
                  }
                }
                this.setAlertNote();
      });
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterTable();
  }

  filterTable() {
    // TODO: Filter by search term
    const tempfilteredMaintenanceNotes: IMaintenanceNote[] = [];
    if (!this.internalView && this.allMaintenanceNotes != null) {
      let i = 0;
      this.allMaintenanceNotes.forEach(function(maintenanceNoteObj) {
        if (maintenanceNoteObj.showNote) {
          tempfilteredMaintenanceNotes[i] = maintenanceNoteObj;
          i++;
        }
      });
      this.filteredMaintenanceNotes = tempfilteredMaintenanceNotes;
    } else {
      this.filteredMaintenanceNotes = this.allMaintenanceNotes;
    }
  }

  setAlertNote() {
    if (this.siteSel !== null) {
      if (this.allMaintenanceNotes !== null && this.allMaintenanceNotes.length > 0) {
          if ((this.internalView || (!this.internalView && this.allMaintenanceNotes[0].showNote))
                && (this.allMaintenanceNotes[0].note !== null && this.allMaintenanceNotes[0].note.trim() !== '')
                  ) {
            this.siteSel.noteAlert = this.datePipe.transform(this.allMaintenanceNotes[0].dateCal, 'MM/dd/yyyy')
                                      + ' : ' + this.allMaintenanceNotes[0].note;
          } else {
            this.siteSel.noteAlert = null;
          }
        } else {
            this.siteSel.noteAlert = null;
          }
    }
  }

  private open(site: IRenewableSite, maintenanceNotes: IMaintenanceNote[], internalView: boolean) {
    if (site === null) {
      this.isHistoryView = true;
    } else {
      this.isHistoryView = false;
    }
    this.isNewNote = false;
    if (maintenanceNotes != null) {
        maintenanceNotes.forEach(note=>{
          note.editNote = null;
          note.isValidEdit = true;
          note.dateCal = moment(note.date.substr(0, note.date.indexOf(' '))).toDate();
          note.time = note.date.substr(note.date.indexOf(' ') + 1, note.date.length);
          note.dateCal.setHours(parseInt(note.time));
          note.time = note.time + ':00';
      });
    }
    if (site != null && site.maintenanceNotes != null) {
      site.maintenanceNotes.forEach(note=>{
        note.editNote = null;
        note.isValidEdit = true;
        note.dateCal = moment(note.date.substr(0, note.date.indexOf(' '))).toDate();
        note.time = note.date.substr(note.date.indexOf(' ') + 1, note.date.length);
        note.dateCal.setHours(parseInt(note.time));
        note.time = note.time + ':00';
      });
    }
    this.internalView = internalView;
    if (site != null) {
      this.siteId = this.maskMode ? this.portalService.getFakeSiteId(site) : site.id;
      this.siteSel = site;
      this.allMaintenanceNotes = site.maintenanceNotes;
    } else {
      this.allMaintenanceNotes = maintenanceNotes;
    }
    if(this.allMaintenanceNotes !== null) {
      this.allMaintenanceNotes = this.allMaintenanceNotes.sort((a, b) => a.dateCal < b.dateCal ? 1 : -1);
    }
    this.filterTable();
    if (site != null && site.maintenanceNotes != null) {
      this.setAlertNote();
    }
    $('#maintenanceModal').modal('show');
  }
}
