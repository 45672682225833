import { Component, OnInit } from '@angular/core';
import { DataService } from "../shared/data.service";
import { ProfileService } from "./profile.service";
import { IProfile, IUserAccount, IUserPermission, ILdapPasswordUpdateRequest } from '../shared/entities/profile';
import { FadeInAnimation } from "../animations/fadeInAnimation";
import { IAccount } from '../shared/entities/account';
import { UserAccountPipe } from "../shared/pipe/useraccounts.pipe";
import { PortalService } from "../shared/portal.service";
import { environment } from '../../environments/environment';
import { Permissions } from "../shared/const/permissions";
import { OrderByPipe } from "../shared/pipe/orderBy.pipe";
import { Subscription } from "rxjs";
declare var $: any;
import * as moment from 'moment';
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { FadeAnimation } from "../animations/fadeAnimation";
import { IPagerDenom, PagerDenoms } from "../shared/const/pagerDenoms";
import { Authority } from "../shared/const/authority";
import { PagerService } from "../shared/pager.service";
import { IHelpStep } from "../shared/entities/contextualHelp";
import { ProfileMatrixBrokerPricingComponent } from './profile-matrix-broker-pricing/profile-matrix-broker-pricing/profile-matrix-broker-pricing.component';

@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['profile.component.scss'],
  providers: [UserAccountPipe, OrderByPipe],
  animations: [FadeInAnimation, FadeAnimation]
})
export class ProfileComponent implements OnInit {

  editingFirstName: boolean = false;
  editingProfile: boolean = false;
  myProfile: IProfile;
  origMyProfile: IProfile
  showThirdPartyLegend: boolean = false;
  myImage: string;
  editProfileErrors: boolean[] = [];
  profilesForAccount: IProfile[] = [];
  filteredAccounts: IUserAccount[];
  uploadImageURL: string;
  phone: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})(?!.*[!@#\$%\^&\*])");
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  invalidPassword: boolean = false;
  passwordErrorMessage: string;
  passwordNotChanged: boolean = false;
  newEmail: string;
  confirmNewEmail: string;
  invalidEmail: boolean = false;
  emailErrorMessage: string;
  emailNotChanged: boolean = false;
  fireFoxBrowser: boolean = false;
  maskMode: boolean;
  helpStep: IHelpStep;
  selectedTab: string = 'ACCOUNTS';
  tabFlag: string;
  myUserAccount: IUserAccount;


  //Permissions
  allowedToManageUsers: boolean = false;
  canViewBrokerPricing: boolean = false;
  showMBP: boolean = false;
  showInitialMBP: boolean = false;
  isSuperUser: boolean;
  isAdmin: boolean;
  isBroker: boolean;

  // Subscriptions
  profileSubscription: Subscription;
  profileImageSubscription: Subscription;
  updatePasswordSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;
  selectedTabSubscription: Subscription;

  constructor(private dataService: DataService,
    private profileService: ProfileService,
    private userAccountPipe: UserAccountPipe,
    private orderByPipe: OrderByPipe,
    private portalService: PortalService,
    private cookieService: CookieService,
    private pagerService: PagerService,
    private router: Router) { }

  ngOnInit() {
    this.uploadImageURL = environment.setUserImageUrl;
    this.dataService.setSelectedNavItem('accounts'); // highlight nav item on page refresh
    this.setCurrentActivePage('accounts');
    this.dataService.setTitleSource('Profile');
    this.showMBP = this.dataService.getShowMBP();

    this.myUserAccount = this.dataService.getAccountSource();
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    this.isBroker = this.myUserAccount.accessType == "ThirdParty"

    this.tabFlag = this.dataService.getManageSubscriptionStatus();
    // console.log('I am in profile screen',this.tabFlag);

    if (this.tabFlag == 'Y') {

      // console.log('tabflag',this.tabFlag);

      this.selectedTab = 'SUBSCRIPTIONS';
      this.dataService.setManageSubscriptionStatus('N');
    }

    this.myImage = this.dataService.getProfileImage();
    this.profileImageSubscription = this.dataService.profileImageUpdated.subscribe(
      (profileImage) => {
        this.myImage = profileImage;
      }, err => {
        console.error(err);
      }
    );

    this.dataService.setLoading(true);
    this.maskMode = this.dataService.getMaskMode();
    setTimeout(() => { this.dataService.setLoading(false); }, 1200);

    this.setUploadStyling();

    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.fireFoxBrowser = true;
    };
    this.myProfile = this.dataService.getProfile();
    this.origMyProfile = Object.assign(Object.create(this.myProfile), this.myProfile);

    if (this.myProfile) {
      this.loadAccounts();
    }
    this.profileSubscription = this.dataService.profileUpdated.subscribe(
      (profile) => {
        this.myProfile = profile;
        this.loadAccounts();
      }
    );

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

    if (this.dataService.getSelectedProfileTab()) {
      this.selectedTab = this.dataService.getSelectedProfileTab();
    }

    this.selectedTabSubscription = this.dataService.selectedProfileTabUpdated.subscribe((tab) => {
      this.selectedTab = tab;
    })


    console.log("getting mbp status in init...");
    this.showInitialMBP = this.myUserAccount.accessType === 'ThirdParty' && this.hasMatrixPricingEnabled(this.myProfile.userAccounts) && this.showMBP && (this.isSuperUser || this.isBroker);
    console.log("init mbp status: " + this.showInitialMBP);

  }

  ngOnDestroy() {
    if (this.profileSubscription) this.profileSubscription.unsubscribe();
    if (this.profileImageSubscription) this.profileImageSubscription.unsubscribe();
    if (this.updatePasswordSubscription) {
      this.updatePasswordSubscription.unsubscribe();
    }
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.selectedTabSubscription) this.selectedTabSubscription.unsubscribe();
  }

  getFakeAccountName(account: IUserAccount) {
    return this.portalService.getFakeCompanyName(account);
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  loadAccounts() {
    this.filteredAccounts = this.myProfile.userAccounts;
    this.filteredAccounts.forEach((account) => {
      account.createdOn = moment(account.createdOn).format('MM/DD/YYYY');
      account.lastModified = moment(account.lastModified).format('MM/DD/YYYY');
      if (account.accessType == 'ThirdParty') {
        this.showThirdPartyLegend = true;
      }
    });
    this.filteredAccounts = this.orderByPipe.transform(this.filteredAccounts, 'name', true);
  }

  editfirstName() {
    this.editingFirstName = true;
  }

  exitFirstName(update?: boolean) {
    this.editingFirstName = false;
    if (update) {
      //this.updateNickName();
    }
  }

  editProfile() {
    this.editingProfile = true;
    this.origMyProfile = Object.assign(Object.create(this.myProfile), this.myProfile);
  }

  saveProfile() {

    let props = ['firstName', 'lastName', 'organization', 'email']
    this.editProfileErrors = [];
    let valid = true;
    props.forEach((prop) => {
      if (!this.myProfile[prop]) {
        valid = false;
        this.editProfileErrors.push(true);
      } else if (this.myProfile[prop] && prop === 'email' && !this.myProfile[prop].includes('@')) {
        this.myProfile[prop] = this.myProfile[prop].toLowerCase();
        valid = false;
        this.editProfileErrors.push(true);
      } else {
        this.editProfileErrors.push(false);
      }

    })
    if (valid) {
      console.log('saveProfile(1): ', this.myProfile.email, '-', this.origMyProfile.email);
      this.editingProfile = false;
      if (this.myProfile.email != this.origMyProfile.email) {
        this.profileService.checkForExistingLDAPUser(this.myProfile.email).subscribe((response) => {
          console.log('response comverge value', response);
          if (response) {
            //email exist in ldap. don't change
            $('#loginExistsModal').modal('show');
          } else {
            $('#loginChangedModal').modal('show');
          }


        });
      } else {
        console.log('update profile no email change');
        this.profileService.updateProile(this.myProfile).subscribe(
          (updatedProfile) => {
            updatedProfile.userAccounts = this.myProfile.userAccounts;
            this.myProfile = updatedProfile;
            this.dataService.setProfile(updatedProfile);
            console.log('Inside Call');
          },
          error => {
            console.log('Call errored');
            this.myProfile = this.origMyProfile;
            console.log(this.myProfile);
          }
        )
      }
    } else {
      this.editingProfile = true;
    }
    console.log('saveProfile(2): ', this.myProfile.email, '-', this.origMyProfile.email);
  }

  revert() {
    console.log('revert(1) profile: ', this.myProfile.email, '-', this.origMyProfile.email); this.myProfile = this.origMyProfile;
    this.dataService.setProfile(this.myProfile);
    console.log('revert(2) profile: ', this.myProfile.email, '-', this.origMyProfile.email); this.myProfile = this.origMyProfile;
    console.log(this.myProfile);
  }

  changeLogin() {
    console.log('changeLogin(1) profile: ', this.myProfile.email, '-', this.origMyProfile.email);
    //this.myProfile = this.origMyProfile;
    this.profileService.updateProile(this.myProfile).subscribe(
      (updatedProfile) => {
        updatedProfile.userAccounts = this.myProfile.userAccounts;
        this.myProfile = updatedProfile;
        this.dataService.setProfile(updatedProfile);
        console.log('Inside Call in ChangeLogin');
        this.logOut();
      },
      error => {
        console.log('Call errored in ChangeLogin');
        this.myProfile = this.origMyProfile;
        console.log(this.myProfile);
      }
    )
    console.log('changeLogin(2) profile: ', this.myProfile.email, '-', this.origMyProfile.email);
    //this.myProfile = this.origMyProfile;

  }

  getPhoneDisplay(phoneNum: string) {
    if (phoneNum) {
      return this.portalService.getPhoneDisplay(phoneNum);
    }
  }

  // "2017-01-01T00:00:00" -> "01/01/2017"
  getDateDisplay(dateString: string) {
    return this.portalService.getDateDisplay(dateString);
  }

  imageUploaded($event) {
    $("image-upload div div label.clear").click();
    this.myImage = this.profileService.getUserImage();
  }

  resetProfileImage() {
    this.profileService.resetProfileImage().subscribe((resp) => { console.log(resp); })
    this.myImage = null;
    this.dataService.setProfileImage(null);
  }

  setUploadStyling() {
    // Lord have mercy on me, a sinner.
    $("image-upload div").first().css({
      "border": "none"
    });
    $("image-upload div div").first().css({
      "background-color": "white",
      "border": "none",
      "padding": "0"
    });
    $("image-upload div .file-too-large").first().css({
      "margin-top": "40px",
    });
    $("image-upload div div label").css({
      "background-color": "#00AEEF",
      "position": "absolute",
      "height": "47px",
      "box-shadow": "none",
      "padding": "13px 20px",
      "font-family": "effra, Helvetica, Arial, sans-serif",
      "font-size": "12px",
      "font-weight": "bold",
      "letter-spacing": "1.3px"
    }).prepend("<i class='material-icons'>file_upload</i>");
    $("image-upload div div label i").css({
      "vertical-align": "middle",
      "font-size": "20px"
    });
  }

  callEmailapi() {
    //Confirm emails match
    if (this.newEmail != this.confirmNewEmail) {
      console.log('callEmailapi() -"Emails do not match: ', this.newEmail, '-', this.confirmNewEmail);
      this.emailErrorMessage = 'Email addresses do not match.';
      this.invalidEmail = true;
      //this.emailNotChanged = true;
    } //Is the email formatted as email
    else if (!this.newEmail.includes('@')) {
      console.log("Email not formatted as email: ", this.newEmail);
      this.emailErrorMessage = 'Unable to validate email addresses. Please re-enter.';
      this.invalidEmail = true;
      //this.emailNotChanged = true;
    }
    //Is the email in use.
    else {
      this.profileService.checkForExistingLDAPUser(this.newEmail).subscribe((response) => {
        console.log('response comverge value', response);
        if (response) {
          //email exist in ldap. don't change
          console.log('Would have changed and logged out.');
          this.invalidEmail = true;
          this.emailErrorMessage = 'Unable to make change. Email address associated with another user account. If you believe that you received this message in error, please email myaccount@nrg.com';
          //this.emailNotChanged = true;
        } else {
          console.log('Email: ', this.newEmail, ' exists in LDAP do not change.');
          this.myProfile.email = this.newEmail;
          this.invalidEmail = false;
          this.emailErrorMessage = "Don't want this to show up";
          this.changeLogin();
          //  this.emailNotChanged = true;
          //$('#loginChangedModal').modal('show');
        }


      });
    }


  }

  getOriginUrl() : string{
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com";
    const stg1 = "https://stg1-manage.nrg.com";
    const test1 = "https://test1-manage.nrg.com";
    const pd = "https://manage.nrg.com";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
      return dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return pd;
    }
    else {
      return local;
    }
  }
  callPwdapi() {
    let validation = this.portalService.validatePassword(this.newPassword, this.confirmNewPassword, this.currentPassword);
    if (validation.isValid) {
      // console.log("Updating password");
      let ldapPasswordUpdateRequest: ILdapPasswordUpdateRequest = {
        email: this.myProfile.email,
        newPassword: this.newPassword,
        currentPassword: this.currentPassword,
        env: this.getOriginUrl()
      };
      this.updatePasswordSubscription = this.profileService.updatePassword(ldapPasswordUpdateRequest, this.myProfile.userId.toString()).subscribe(
        (response) => {
          this.invalidPassword = false;
          $('#changePwdModal').modal('hide');
        }, (err) => {
          this.passwordNotChanged = true;
        }
      );
    } else {
      // console.log("Password validation failed");
      this.passwordErrorMessage = validation.error;
      this.invalidPassword = true;
    }
  }

  clearPasswordData() {
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
  }

  switchTabs(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  hasPricingEnabled(account: IUserAccount) {
    if (account.pricingFlagEnabled) {
      if (this.hasPermission(Permissions.PricingAll, account) || this.hasPermission(Permissions.PricingUser, account)) {
        return true;
      }
      if (!this.portalService.userHasAuthority(Authority.SuperUser)) {
        if (account.accessLevel.toUpperCase() === 'ADMIN') {
          return true;
        }
      }
    }
    return false;
  }
  hasPermission(mask: number, account: IUserAccount) {
    const permission = account.permissions;
    // tslint:disable-next-line:no-bitwise
    return (permission & mask) === mask;
  }

  hasPostSale(accounts: IUserAccount[]) {
    if (this.portalService.userHasAuthority(Authority.SuperUser)) {
      return true;
    }
    if (accounts) {
      return accounts.some(account => {
        if (account.accessLevel.toUpperCase() === 'ADMIN' || this.hasPermission(Permissions.PostSale, account)) {
          return true;
        }
        return false;
      });
    }
    return true;
  }

  hasMatrixPricingEnabled(accounts: IUserAccount[]) {
    return (
      this.myUserAccount.pricingFlagEnabled &&
      (((this.portalService.userHasAuthority(Authority.SuperUserAdmin) ||
        this.portalService.userHasAuthority(Authority.SuperUser)) &&
        this.portalService.userHasAuthority(Authority.PricingAll)) ||
        (!this.portalService.userHasAuthority(Authority.SuperUserAdmin) &&
          !this.portalService.userHasAuthority(Authority.SuperUser) &&
          this.myUserAccount.accessLevel.toUpperCase() === 'ADMIN') ||
        (!this.portalService.userHasAuthority(Authority.SuperUserAdmin) &&
          !this.portalService.userHasAuthority(Authority.SuperUser) &&
          (this.portalService.hasPermission(Permissions.PricingAll) ||
            this.portalService.hasPermission(Permissions.PricingUser))))
    );
  }



  logOut() {
    this.portalService.logout().subscribe();
  }
}
