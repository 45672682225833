import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bankAccount'
})
export class BankAccountPipe implements PipeTransform {

  transform(value: string): string {
    return "XXXXXX" + value;
  }
}
