// Code add by Ramalingam
import { Injectable } from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Observable} from 'rxjs';
import {IContactInfo, ICompanyContactInfo, IUsefulContactInfo} from '../shared/entities/contacts';
import {environment} from '../../environments/environment';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ContactService {

  headers: HttpHeaders;

  constructor(private http: Http, private httpClient: HttpClient) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  // Get contact information by account ID
  getContactsForAccount(accountId: string, thirdParty: boolean): Observable<IContactInfo> {
    let url = environment.production ? environment.contactUrl.replace('{accountId}', accountId.toString()) : environment.contactUrl;
    url = url.replace('{thirdParty}', thirdParty.toString());
    return this.http
      .get(url)
      .map((response: Response) => <IContactInfo>response.json())
      .catch(this.handleError);
  }


  //Get the contact information
  getMockCompanyContacts(accountId: string): Observable<ICompanyContactInfo> {
    // console.log('mockCompany');
    let url = './assets/api/companyContacts.json';
    return this.http
      .get(url)
      .map((response: Response) => <ICompanyContactInfo>response.json())
      .catch(this.handleError);
  }

   // Get useful contacts for each market the Account has sites in
  getMockUsefulContacts(accountId: string): Observable<IUsefulContactInfo[]> {
    let url = './assets/api/usefulContact.json';
      // console.log('mockUsefulContacts');
    //need to parse this and return as an [] object
    return this.http
      .get(url)
      .map((response: Response) => <IUsefulContactInfo[]>response.json())
      .catch(this.handleError);
  }


  //Get the contact information
  getCompanyContacts(accountId: string): Observable<ICompanyContactInfo> {
    let url = environment.production ? environment.companyContactUrl.replace('{accountId}', accountId.toString()) : environment.companyContactUrl;
    return this.http
      .get(url)
      .map((response: Response) => <ICompanyContactInfo>response.json())
      .catch(this.handleError);
  }

  // Get useful contacts for each market the Account has sites in
  getUsefulContacts(accountId: string): Observable<IUsefulContactInfo[]> {
    let url = environment.production ? environment.usefulContactUrl.replace('{accountId}', accountId.toString()) : environment.usefulContactUrl;
    return this.http
      .get(url)
      .map((response: Response) => <IUsefulContactInfo[]>response.json())
      .catch(this.handleError);
  }

  screenSharingSessionStartAudit(accountId: string): Observable<any> {
    let url = environment.production ? environment.screenSharingAuditUrl.replace('{accountId}', accountId.toString()) : environment.screenSharingAuditUrl;
    return this.httpClient
      .post(url, {}, {headers: this.headers})
      .map((response: Response) => null) // API returns void
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
