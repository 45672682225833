import {Component, Input, OnInit} from '@angular/core';
import {IUserAccount} from '../../shared/entities/profile';
import {IPagerDenom, PagerDenoms} from '../../shared/const/pagerDenoms';
import {UserAccountPipe} from '../../shared/pipe/useraccounts.pipe';
import {PagerService} from '../../shared/pager.service';
import {Permissions} from '../../shared/const/permissions';
import { ProfileService } from '../profile.service';
import {DataService} from '../../shared/data.service';
import {Authority} from '../../shared/const/authority';
import {PortalService} from '../../shared/portal.service';
import { Subscription } from 'rxjs';
import { FakerService } from '../../shared/faker.service';

@Component({
  selector: 'app-profile-accounts',
  templateUrl: './profile-accounts.component.html',
  styleUrls: ['./profile-accounts.component.scss'],
  providers: [UserAccountPipe]
})
export class ProfileAccountsComponent implements OnInit {

  @Input() accounts: IUserAccount[];
  top5Accounts : IUserAccount[];
  filteredAccounts: IUserAccount[];

  showSearchRow = false;
  searchTerm = '';
  lastSearchedTerm = '';
  superUser = false;

  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;

  showThirdPartyLegend: boolean = false;
  selectedAccount: IUserAccount;
  selectedUserAccountSourceSubscription: Subscription;
  showMBP: boolean = false;

  maskMode: boolean;
  maskModeSubscription: Subscription;

  ngOnChanges(changes) {
    if (changes['accounts']) {
      this.filteredAccounts = this.accounts;
      if (!this.pagerDenoms) {
        this.pagerDenoms = PagerDenoms;
      }
      this.pagerDenoms.forEach((d) => {
          if (d.default) {
            this.selectDenom(d);
          }
        });
      this.filterAccounts();
    }
  }

  constructor(private userAccountPipe: UserAccountPipe,
              private pagerService: PagerService,
              private dataService: DataService,
              private portalService: PortalService,
              private fakerService: FakerService,
              private profileService: ProfileService) { }

  ngOnInit() {
    this.superUser = this.portalService.userHasAuthority(Authority.SuperUser);
    // console.log('This.superUserAdmin: ', this.superUser);

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );

    let accountName = this.dataService.getSelectedUserAccountSource();
    this.showMBP = this.dataService.getShowMBP();
    // if(accountName) {
    //   this.selectedAccount = accountName;
    //   this.showThirdPartyLegend = this.selectedAccount.accessType === 'ThirdParty';
    // }
    // this.selectedUserAccountSourceSubscription = this.dataService.selectedUserAccountSourceUpdated.subscribe(
    //   (selectedAccount) => {
    //       // console.log('T&C selected account updated to ', selectedAccount);
    //       this.selectedAccount = selectedAccount;
    //       this.showThirdPartyLegend = this.selectedAccount.accessType === 'ThirdParty';
    //   }
    // );

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((d) => {
      if (d.default) {
        this.selectDenom(d);
      }
    });
    this.top5Accounts = this.accounts;
  }

  ngOnDestroy() {
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    if (this.accounts) {
      this.paginate(1);
    }
  }

  paginate(page: number) {
    this.showThirdPartyLegend = false;
    if (this.filteredAccounts) {
      this.pager = this.pagerService.getPage(this.filteredAccounts, page, this.selectedDenom.count)
    }
  }

  async filterAccounts() {
    if (!this.searchTerm) {
      this.filteredAccounts = this.top5Accounts;
    } else {
      //network call
      this.searchAccountsForSuperUser(this.searchTerm);
    }
    this.paginate(1);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterAccounts();
  }

  getActivityLevel(account: IUserAccount) {
    if (account.accessType === 'ThirdParty') {
      this.showThirdPartyLegend = true;
      return Authority.accessMap.get(account.permissions);
    } else {
      return account.accessLevel;
    }

  }

  hasPermissionToManageUsers(account: any) {
    // tslint:disable-next-line:no-bitwise
    return (Permissions.ManageUsers & account.permissions) === Permissions.ManageUsers;
  }

  expand(account: IUserAccount) {
    account.expanded = !account.expanded;
  }

  hasPricingEnabled(account: IUserAccount) {
    if (account.pricingFlagEnabled) {
      // tslint:disable-next-line:no-bitwise
      return (Permissions.PricingAll & account.permissions) === Permissions.PricingAll ||
      // tslint:disable-next-line:no-bitwise
      (Permissions.PricingUser & account.permissions) === Permissions.PricingUser;
    }
    return false;
  }

  hasPostSale(account: IUserAccount) {
    // tslint:disable-next-line:no-bitwise
    return (Permissions.PostSale & account.permissions) === Permissions.PostSale;
  }

  createManageUser(account: IUserAccount) {
    this.dataService.setSelectedUserAccountSource(account);
  }

  searchAccountsForSuperUser(searchTerm: string) {
    this.accounts = [];
    let duplicate;
    if (searchTerm.length >= 3) {
      if (searchTerm != this.lastSearchedTerm) {
        this.lastSearchedTerm = searchTerm;
        const escapedAccountSearch = this.portalService.escapeRegExp(searchTerm);
        // Call the new service that looks up data in Elastic search
        this.profileService.getUserAccounts(searchTerm).subscribe(
          (accountsForUser : any) => {
            accountsForUser.forEach((account) => {
              if (account.id.toLowerCase().match(escapedAccountSearch.toLowerCase()) ||
                (account.name && account.name.toLowerCase().match(escapedAccountSearch.toLowerCase())) ||
                searchTerm === '') {
                duplicate = false;
                this.accounts.forEach((acc, i) => {
                  if (acc.id === account.id) {
                    duplicate = true;
                    if (acc.customerAgentId) {
                      this.accounts[i] = account;
                    }
                  }
                });
                if (!duplicate) {
                  this.accounts.push(account);
                }
              }
            });
            this.filteredAccounts = this.userAccountPipe.transform(this.accounts, this.searchTerm);
          }, err => {
            console.error(err);
          }
        );
      }
    }
  }

  getFakeAccountName(account) {
    if (!account.fakeName) {
      account.fakeName = this.fakerService.getCompanyName();
    }
    return account.fakeName;
  }
}


