import {ICategory, IInvoice} from "../shared/entities/bill-reports";
import {ISite} from "../shared/entities/site";
import {IAddress} from "../shared/entities/address";
export interface IPaymentState {
  currentStep: number;
  viewInvoicesToggled: boolean;
  viewAccountToggled: boolean;
  // Payment Summary
  selectedBalances: IInvoice[];
  paymentTotal: number
  buttonLabel: string;
  backDisabled: boolean;
}

// Step 1
export interface IBalances {
  balanceDue: number,
  newCharges?: number,
  siteCount: number,
  invoiceCount: number,
  checked?: boolean,
  partialCheck?: boolean,
  dueDate?: string;
  categories: ICategory[]
  scheduledPayment?: string; // "2018-05-31" || "No" || "Yes"
  autoPaymentFlag?: string;
  serviceAddress?: IAddress;
  invoices?: IInvoice[]; // For invoice view
  paymentDate?: string;
  paymentDateStr?: string;
  nccaStatus?: string;
}

export interface IAccountBalance {
  balance: number;
  paymentAmount: any;
  paymentDate: string;
  pastDueAmount?: number;
  nextPaymentDue?: string;
}

export interface IAccountBalanceRequest {
  accountId: string;
  startDate: string;
  endDate: string;
}

export interface IBalancesRequest {
  accountId: string,
  groupId?: number,
  system: boolean,
  groupName?: string
}

export interface IPaymentMethod {
  userPaymentSettingsKey: IPaymentSettingsKey;
  nickName: string;
  defaultPayment: number;
  routingNumber: string;
  maskedAccountNumber: number;
  oldPaymentToken?: string;
  methodType?: string;
  //selectedType?: string  
  paymentType?: string; //BANKACCOUNT , CARD
  cardBrand?: string;
  maskedCardNumber : number;
  cardExpiryMonth : number;
  cardExpiryYear : number;
  cvv : number;
  cardZipCode : number;
  rememberPayment : string;
}

export interface IPaymentSchedule {
  paymentMethod: IPaymentMethod;
  paymentDate: Date;
  savePaymentMethod: boolean;
}

export interface IPaymentSubmission {
  accountId: string;

  ccNumber: string;
  cvvNumber: string;
  expiryDate: string;
  zipCode: string;
  paymentDate: string;
  type: string;

  payments: IPayment[];
}

export interface IPaymentResponse {
  accountId: string;
  statusCode: string;
  confirmationNumber: string;
  payments: IPayment[];
}

export interface IPayment {
  businessPartnerId: string;
  accountNumber: string; // SAP Contract Account Number
  bankRoutingNumber: string;
  tokenizedBankAccountNumber: string;
  paymentAmount: string;
  paymentDate: string;
  siteId: string;
  invoiceNo: string;
  statusCode?: string;
  collective?: boolean;
  type: string;
}

export interface IPCIReturn {
  accountId: string;
  paymentToken: string;
  paymentType?: string; //BANKACCOUNT , CARD
  paymentMethods: IPaymentMethod[];
  isCancelled: string;
  rememberPayment: string;
}

export interface IPCIValidateReturn {
  paymentType: string;
  paymentToken: string;
  routingNumber: string;
  maskedAccountNumber: string;
  maskedCardNumber: string;
  nickname: string;
  cardBrand: string;
  cardExpYear: string;
  cardExpMonth: string;
  cvv: string;
  cardZipCode: string;
  isCancelled: string;
  rememberPayment: string;
}

export interface IPaymentSettingsRequest {
  paymentToken: string;
  nickName: string;
  defaultPayment: number;
  routingNumber: string;
  maskedAccountNumber: number;
  oldPaymentToken: string;
  accountId: string;
  paymentType?: string; //BANKACCOUNT , CARD
  cardBrand?: string;
  maskedCardNumber : number;
  cardExpiryMonth : number;
  cardExpiryYear : number
}

export interface IPaymentSettingsKey {
  paymentToken: string;
  userId: string;
  accountId: string;
}

export interface IQuickPayVerify {
  ca: string;
  checkDigit: string;
  zipCode: string;
}

export interface IQuickPayDetails {
  caNumber: string;
  caName: string;
  bpNumber: string;
  arDate: string;
  arCurrentDueDate: string;
  arCurrentArBalance: string;
  arLastPayAmt: string;
  arPastPayDate: string;
  pastDueAmt: string;
  creditAmt: string;
  pastDueAmtNec: string;
  ncastatus: string;
  nccastatus: string; 
  errorCode: string;
  email: string;
}

export interface IPCIQuickPayReturn {
  caName: string;
  caNumber: string;
  paymentAmount: string;
  paymentDate: string;
  paytype: string; //BANKACCOUNT , CARD
  confirmationNumber: string;
  message : string;
}



export class PaymentSettingsRequest implements IPaymentSettingsRequest {
  paymentToken: string;
  nickName: string;
  defaultPayment: number;
  routingNumber: string;
  maskedAccountNumber: number;
  oldPaymentToken: string;
  accountId: string;
  paymentType?: string; //BANKACCOUNT , CARD
  cardBrand?: string;
  maskedCardNumber : number;
  cardExpiryMonth : number;
  cardExpiryYear : number

  constructor(method: IPaymentMethod) {
    this.paymentToken = method.userPaymentSettingsKey.paymentToken;
    this.nickName = method.nickName;
    this.defaultPayment = method.defaultPayment;
    this.routingNumber = method.routingNumber;
    this.maskedAccountNumber = method.maskedAccountNumber;
    this.oldPaymentToken = method.oldPaymentToken;
    this.accountId = method.userPaymentSettingsKey.accountId;

    this.paymentType = method.paymentType;
    this.cardBrand = method.cardBrand;
    this.maskedCardNumber = method.maskedCardNumber;
    this.cardExpiryMonth = method.cardExpiryMonth;
    this.cardExpiryYear = method.cardExpiryYear;
  }

  public toString = (): string => {
    return `PaymentSettingsRequest (paymentToken: ${this.paymentToken}, nickName: ${this.nickName}, defaultPayment: ${this.defaultPayment}, routingNumber: ${this.routingNumber}, maskedAccountNumber: ${this.maskedAccountNumber}, oldPaymentToken: ${this.oldPaymentToken}, accountId: ${this.accountId})`;
  }
}
