export const Header = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    }, {
      subNavPos: 50,
      label: 'Billing, Payments & Usage',
      slug: 'billing'
    }, {
      subNavPos: 150,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Markets',
      slug: 'markets'
    },
    {
      subNavPos: 300,
      label: 'Tools',
      slug: 'tools'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          },
        ]
      }
    ],
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'billing-reports'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Make a Payment',
            slug: 'payments'
          }, {
            label: 'Payment History',
            slug: 'payment-history'
          }, {
            label: 'Manage Payment Methods',
            slug: 'manage-payment-methods'
          }, {
            label: 'AutoPay',
            slug: 'autopayments'
          }
        ]
      }, {
        title: 'Usage',
        items: [
          {
            label: 'Usage Comparison Report',
            slug: 'usage-comparison'
          },
          {
            label: 'Usage Interval Report',
            slug: 'interval-usage-report'
          },
          {
            label: 'Usage Interval Graph',
            slug: 'usage-graph'
          },
        ]
      }
    ],
    tools: [
      {
        title: 'Demand Charge Management',
        items: [
          {
            label: '4CP Dashboard',
            slug: 'fourcp'
          },
          {
            label: 'Realtime Usage Graph',
            slug: 'realtime-usage'
          }
        ]

      }
    ]
  }
};

export const HeaderSuperUser = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    }, {
      subNavPos: 50,
      label: 'Billing, Payments & Usage',
      slug: 'billing'
    }, {
      subNavPos: 150,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Markets',
      slug: 'markets'
    },
    {
      subNavPos: 300,
      label: 'Tools',
      slug: 'tools'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
    markets: [
      {
        title: 'Markets',
        items: [
          {
            label: 'Public Markets',
            slug: 'public-markets'
          },
          {
            label: 'Energy Term Analysis',
            slug: 'forward-market-pricing'
          }
        ]
      }
    ],
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'billing-reports'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Make a Payment',
            slug: 'payments'
          }, {
            label: 'Payment History',
            slug: 'payment-history'
          }, {
            label: 'Manage Payment Methods',
            slug: 'manage-payment-methods'
          }, {
            label: 'AutoPay',
            slug: 'autopayments'
          }
        ]
      }, {
        title: 'Usage',
        items: [
          {
            label: 'Usage Comparison Report',
            slug: 'usage-comparison'
          },
          {
            label: 'Usage Interval Report',
            slug: 'interval-usage-report'
          },
          {
            label: 'Usage Interval Graph',
            slug: 'usage-graph'
          },
        ]
      }
    ],
    tools: [
      {
        title: 'Adoption Metrics',
        items: [
          {
            label: 'Enrollment',
            slug: 'metrics/enrollment'
          },
          {
            label: 'Transactions',
            slug: 'metrics/transactions'
          }
        ]
      },
      {
        title: 'Demand Charge Management',
        items: [
          {
            label: '4CP Dashboard',
            slug: 'fourcp'
          },
          {
            label: 'Realtime Usage Graph',
            slug: 'realtime-usage'
          }
        ]
      }
    ]
  }
};

export const LimitedPortalHeader = { // ViewPortal == 0, View4CP == 1
  enabled: false, // Used to determine if the header UI should be driven by this config
  navItems: [
    {
      subNavPos: 10,
      label: 'Tools',
      slug: 'tools',
      navOverride: 'fourcp'
    },
    {
      subNavPos: 90,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 230,
      label: 'Markets',
      slug: 'markets'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Account',
        group: 1,
        items: [
          {
            label: 'Contracts',
            slug: 'contracts',
            url: 'contracts'
          }
        ]
      }
    ],
    markets: [
      {
        title: 'Market Snapshot',
        group: 1,
        items: [
          {
            label: 'ERCOT',
            slug: 'ERCOT',
            url: 'markets/public-markets'
          },
          {
            label: 'Natural Gas',
            slug: 'COMMODITIES',
            url: 'markets/public-markets'
          }
        ]
      }
    ]
  }
};

export const LimitedPortalHeaderSuperUser = { // ViewPortal == 0, View4CP == 1
  navItems: [
    {
      subNavPos: 0,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Markets',
      slug: 'markets'
    },
    {
      subNavPos: 250,
      label: 'Tools',
      slug: 'tools'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
    tools: [
      {
        title: 'Adoption Metrics',
        items: [
          {
            label: 'Enrollment',
            slug: 'metrics/enrollment'
          },
          {
            label: 'Transactions',
            slug: 'metrics/transactions'
          }
        ]
      },
      {
        title: 'Demand Charge Management',
        items: [
          {
            label: '4CP Dashboard',
            slug: 'fourcp'
          }
        ]
      }
    ]
  }
};

export const HeaderThirdParty = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'third-party'
    }, {
      subNavPos: 50,
      label: 'Billing & Payments',
      slug: 'billing'
    }, {
      subNavPos: 150,
      label: 'Customers',
      slug: 'customers'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'third-party/billing-summary'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'third-party/billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Payout Report',
            slug: 'third-party/payout-report'
          }
        ]
      }
    ], customers: [
      {
        title: 'Customers',
        items: [
          {
            label: 'Customer List',
            slug: 'third-party/customer-list'
          }
        ]
      }
    ]
  }
};

export const LimitedHeaderThirdParty = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'third-party'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
  }
};

export const HeaderBroker = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    }, {
      subNavPos: 50,
      label: 'Billing & Payments',
      slug: 'billing'
    }, {
      subNavPos: 150,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Markets',
      slug: 'markets'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'billing-reports'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Make a Payment',
            slug: 'payments'
          }, {
            label: 'Payment History',
            slug: 'payment-history'
          }, {
            label: 'Manage Payment Methods',
            slug: 'manage-payment-methods'
          }, {
            label: 'AutoPay',
            slug: 'autopayments'
          }
        ]
      }
    ]
  }
};

export const HeaderNonBroker = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    }, {
      subNavPos: 50,
      label: 'Billing, Payments & Usage',
      slug: 'billing'
    }, {
      subNavPos: 150,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Markets',
      slug: 'markets'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'billing-reports'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Make a Payment',
            slug: 'payments'
          }, {
            label: 'Payment History',
            slug: 'payment-history'
          }, {
            label: 'Manage Payment Methods',
            slug: 'manage-payment-methods'
          }, {
            label: 'AutoPay',
            slug: 'autopayments'
          }
        ]
      }, {
        title: 'Usage',
        items: [
          {
            label: 'Usage Comparison Report',
            slug: 'usage-comparison'
          },
          {
            label: 'Usage Interval Report',
            slug: 'interval-usage-report'
          },
          {
            label: 'Usage Interval Graph',
            slug: 'usage-graph'
          },
        ]
      }
    ]
  }
};

export const Northeast = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    },
    {
      subNavPos: 2,
      label: 'Billing, Payments & Usage',
      slug: 'billing'
    },
    {
      subNavPos: 3,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Contact Us',
      slug: 'contact'
    },
  ],
  subnavs: {
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'billing-reports'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Make a Payment',
            slug: 'payments'
          }, {
            label: 'Payment History',
            slug: 'payment-history'
          }, {
            label: 'Manage Payment Methods',
            slug: 'manage-payment-methods'
          }, {
            label: 'AutoPay',
            slug: 'autopayments'
          }
        ]
      }, {
        title: 'Usage',
        items: [
          {
            label: 'Usage Summary Report',
            slug: 'usage-reports'
          },
          {
            label: 'Usage Comparison Report',
            slug: 'usage-comparison'
          },
          {
            label: 'Usage Interval Report',
            slug: 'interval-usage-report'
          },
          {
            label: 'Usage Interval Graph',
            slug: 'usage-graph'
          },
        ]
      }
    ],
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
  }
};

export const Consolidated = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    },
    {
      subNavPos: 2,
      label: 'Usage',
      slug: 'usage'
    },
    {
      subNavPos: 3,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Contact Us',
      slug: 'contact'
    },
  ],
  subnavs: {
    usage: [
      {
        title: 'Usage',
        items: [
          {
            label: 'Usage Summary Report',
            slug: 'usage-reports'
          },
          {
            label: 'Usage Comparison Report',
            slug: 'usage-comparison'
          },
          {
            label: 'Usage Interval Report',
            slug: 'interval-usage-report'
          },
          {
            label: 'Usage Interval Graph',
            slug: 'usage-graph'
          },
        ]
      }
    ],
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
  }
};

export const National = {
  navItems: [
    {
      subNavPos: 1,
      label: 'Dashboard',
      slug: 'dashboard'
    }, {
      subNavPos: 50,
      label: 'Billing, Payments & Usage',
      slug: 'billing'
    }, {
      subNavPos: 150,
      label: 'Accounts',
      slug: 'accounts'
    },
    {
      subNavPos: 4,
      label: 'Markets',
      slug: 'markets'
    },
    {
      subNavPos: 300,
      label: 'Tools',
      slug: 'tools'
    },
    {
      subNavPos: 6,
      label: 'Contact Us',
      slug: 'contact'
    }
  ],
  subnavs: {
    accounts: [
      {
        title: 'My Account',
        items: [
          {
            label: 'Contracts',
            slug: 'contracts'
          }
        ]
      }, {
        title: 'Sites',
        items: [
          {
            label: 'Site Groups',
            slug: 'site-groups'
          }, {
            label: 'Add/Delete Sites',
            slug: 'amendments'
          },
          {
            label: 'Add/Delete History',
            slug: 'amendments/history'
          }
        ]
      }, {
        title: 'My Settings',
        items: [
          {
            label: 'Profile',
            slug: 'profile'
          }
        ]
      }
    ],
    billing: [
      {
        title: 'Billing',
        items: [
          {
            label: 'Billing Summary Report',
            slug: 'billing-reports'
          },
          {
            label: 'Billing Comparison Report',
            slug: 'billing-comparison'
          },
          {
            label: 'Billing Usage Report',
            slug: 'billing-usage'
          }
        ]
      }, {
        title: 'Payments',
        items: [
          {
            label: 'Make a Payment',
            slug: 'payments'
          }, {
            label: 'Payment History',
            slug: 'payment-history'
          }, {
            label: 'Manage Payment Methods',
            slug: 'manage-payment-methods'
          }, {
            label: 'AutoPay',
            slug: 'autopayments'
          }
        ]
      }, {
        title: 'Usage',
        items: [
          {
            label: 'Usage Summary Report',
            slug: 'usage-reports'
          },
          {
            label: 'Usage Comparison Report',
            slug: 'usage-comparison'
          },
          {
            label: 'Usage Interval Report',
            slug: 'interval-usage-report'
          },
          {
            label: 'Usage Interval Graph',
            slug: 'usage-graph'
          },
        ]
      }
    ],
    tools: [
      {
        title: 'Demand Charge Management',
        items: [
          {
            label: '4CP Dashboard',
            slug: 'fourcp'
          }
        ]
      }
    ]
  }
};

