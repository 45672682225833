
import { Component, OnInit } from '@angular/core';
import {
  DateOption, UserMetricsInceptionDate, UsageDatePeriods,
  TransactionUserMetricsDatePeriods
} from "../../shared/const/datePeriods";
import * as moment from 'moment';
import {FadeAnimation} from "../../animations/fadeAnimation";
import {DataService} from "../../shared/data.service";
import {PagerDenoms, IPagerDenom} from "../../shared/const/pagerDenoms";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {PagerService} from "../../shared/pager.service";
import {MetricsService} from "../metrics.service";
import {ITransactionMetrics, transactionColumnsControl, ITransactionMetricsDetail, UserTypeList, SalesGroupList} from "../metrics";
import {IUserAccount} from "../../shared/entities/profile";
import {Subscription} from "rxjs";
import {SingleFieldSearchPipe} from "../../shared/pipe/single-field-search.pipe";
import {MetricsDetailPipe} from "../../shared/pipe/metrics-detail.pipe";

declare var $ :any;

@Component({
  selector: 'app-transaction-metrics',
  templateUrl: './transaction-metrics.component.html',
  styleUrls: ['transaction-metrics.component.scss', '../metrics.component.scss'],
  animations: [FadeAnimation],
  providers: [GenericPipe, OrderByPipe, SingleFieldSearchPipe, MetricsDetailPipe]
})
export class TransactionMetricsComponent implements OnInit {

  //DATE RANGE VARS
  showDateRangeSelector: boolean = false;
  datePeriods: DateOption[];
  selectedDatePeriod: DateOption;
  periodSelected: boolean = true;
  startMinDate: Date;
  startMaxDate: Date;
  endMinDate: Date;
  endMaxDate: Date;
  periodStartDate: string;
  periodEndDate: string;
  startDate: Date;
  startDateDisplay: string;
  endDate: Date;
  endDateDisplay: string;
  dateRangeLabel: string;
  invalidDateRange: boolean;
  dataAvailableDate: string;

  //USER TYPE VARS
  userTypeList: any;
  userTypeControl: any = {
    show: false,
    selectedType: {
      name: 'all',
      display: 'All'
    }
  };

  //SALES GROUP VARS
  salesGroupList: any;
  salesGroupControl: any = {
    show: false,
    selectedGroup: {
      name: 'candi',
      display: 'C & I'
    }
  };  //CUSTOMER SETTING VARS
  accountsForUserSourceSubscription: Subscription;
  customerControl: any = {
    show: false,
    selectedCustomerType: 'all',
    selectedCustomerDisplay: 'All Accounts',
    selectedSingleCustomer: {},
    searchTerm: ''
  }
  customerList: IUserAccount[] = [];
  filteredCustomerList: IUserAccount[] = [];

  //COLUMN SETTING VARS
  columnControl: any;

  //PAGING/SORTING/SEARCHING
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  sortBy: string = "dateSortable";
  ascending: boolean = false;
  showSearchRow: boolean = false;
  searchTerm: string;

  transactionMetrics: ITransactionMetrics[];
  transactionMetricsDet: ITransactionMetrics[];
  
  filteredTransactionMetrics: ITransactionMetrics[];
  totals: any;

  //DETAILS
  detailModalTitle: string;
  detailModalDate: string;
  detailModalDateDisplay: string;
  forDate: string;
  showDetailSearchRow: boolean = false;
  detailSearchTerm: string;
  transactionMetricsDetails: ITransactionMetricsDetail[];
  filteredTransactionMetricsDetails: ITransactionMetricsDetail[];

  exportSubscription: Subscription;

  constructor(private dataService: DataService,
              private metricsService: MetricsService,
              private genericPipe: GenericPipe,
              private orderByPipe: OrderByPipe,
              private pagerService: PagerService,
              private singleFieldSearchPipe: SingleFieldSearchPipe,
              private metricsDetailPipe: MetricsDetailPipe) { }

  ngOnInit() {

    this.dataService.setTitleSource("Adoption Metrics");
    this.dataService.setSelectedMetricsPage('Transactions');
    this.dataService.setContextualHelp(false);

    this.columnControl = transactionColumnsControl;
    this.userTypeList = UserTypeList;
    this.salesGroupList = SalesGroupList;

    this.datePeriods = TransactionUserMetricsDatePeriods;
    this.datePeriods.forEach((datePeriod) => {
      if (datePeriod.selected){
        this.selectedDatePeriod = datePeriod;
        this.calculateStartAndEndDate();
      }
    });

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.default) {
        this.selectDenom(denom);
      }
    });

    this.startMinDate = moment(UserMetricsInceptionDate).toDate();
    this.endMinDate = moment(UserMetricsInceptionDate).toDate();
    this.startMaxDate = moment().toDate();
    this.endMaxDate = moment().toDate();
    this.dataAvailableDate = moment(UserMetricsInceptionDate).format("MM/DD/YYYY");

    let yeardiff = moment(UserMetricsInceptionDate).toDate().getFullYear() - moment().toDate().getFullYear();
    if(Math.abs(yeardiff) >= 1){
      this.startMinDate.setFullYear(moment().toDate().getFullYear() - 1 );
      this.startMinDate.setMonth(0); //Jan , this year - 1 is minimum
      //1 year gap max to save memory upon download
      console.log(moment().toDate().getFullYear() - 1);
    }

    this.customerList = this.dataService.getAccountsForUserSource();

    this.accountsForUserSourceSubscription = this.dataService.accountsForUserSourceUpdated.subscribe((accounts) => {
      this.customerList = accounts;
      this.filteredCustomerList = accounts;
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (trigger) => {
        this.exportTransactionsToExcel();
      }
    );

    this.getTransactionMetrics();
  }

  ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  //DATE RANGE FUNCTIONS
  toggleDateRange() {
    this.showDateRangeSelector = !this.showDateRangeSelector;
    if(this.showDateRangeSelector){
      this.columnControl.show = false;
      this.customerControl.show = false;
      this.userTypeControl.show = false;
      this.salesGroupControl.show = false;
    }
  }

  closeDateRange() {
    this.showDateRangeSelector = false;
  }

  selectDatePeriod(selectedPeriod) {
    this.datePeriods.forEach((datePeriod) => {
      if(datePeriod.option == selectedPeriod.option){
        datePeriod.selected = true;
        this.selectedDatePeriod = datePeriod;
        this.dateRangeLabel = this.selectedDatePeriod.display;
        this.calculateStartAndEndDate();
      }
      else {
        datePeriod.selected = false;
      }
    });
  }

  calculateStartAndEndDate(){
    if(this.selectedDatePeriod.option == 'InceptionToDate'){
      this.periodStartDate = UserMetricsInceptionDate;
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.ThisMonth){
      this.periodStartDate = moment().startOf('month').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.LastMonth){
      this.periodStartDate = moment().subtract(30, 'day').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.Last3Months){
      this.periodStartDate = moment().subtract(90, 'day').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.Last6Months){
      this.periodStartDate = moment().subtract(180, 'day').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.LastYear){
      this.periodStartDate = moment().subtract(1, 'year').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
  }

  startDateSelected(event) {
    this.startDate = event.value;
    this.endMinDate = event.value;
    this.startDateDisplay = moment(event.value).format('MMM DD, YYYY');
  }

  endDateSelected(event) {
    this.endDate = event.value;
    this.startMaxDate = event.value;
    this.endDateDisplay = moment(event.value).format('MMM DD, YYYY');

  }

  applyDateRange() {
    this.invalidDateRange = false;
    if(!this.periodSelected && (moment(this.startDate).isBefore(moment(this.startMinDate)) ||
      moment(this.endDate).isAfter(moment(this.endMaxDate)) ||
      moment(this.startDate).isAfter(moment(this.endDate)))) {
      this.invalidDateRange = true;
    } else {
      this.showDateRangeSelector = false;
      this.getTransactionMetrics();
    }
  }


  getTransactionMetrics(){
    this.dataService.setLoading(true);
    let fromDate,toDate, customer, userType, salesGroup;

    if(this.periodSelected){
      fromDate = this.periodStartDate;
      toDate = this.periodEndDate;
    }
    else {
      fromDate = this.startDateDisplay;
      toDate = this.endDateDisplay;
    }

    customer = this.customerControl.selectedCustomerType != 'single' ? this.customerControl.selectedCustomerType : this.customerControl.selectedSingleCustomer.id;
    userType = this.userTypeControl.selectedType.name;
    salesGroup = this.salesGroupControl.selectedGroup.name;

    this.metricsService.getTransactionMetrics(fromDate, toDate, customer, userType, salesGroup).subscribe((transactionMetrics : any) => {
      this.transactionMetrics = transactionMetrics;
      this.formatTransactionMetrics();
      this.filterTransactionMetrics();
      this.paginate(1);
      this.dataService.setLoading(false);
    });
  }

  //USER TYPE FUNCTIONS
  toggleUserType(){
    this.userTypeControl.show = !this.userTypeControl.show;
    if(this.userTypeControl.show){
      this.columnControl.show = false;
      this.customerControl.show = false;
      this.showDateRangeSelector = false;
      this.salesGroupControl.show = false;
    }
  }

  selectUserType(userType: any) {
    this.userTypeControl.selectedType = userType;
    this.toggleUserType();
    this.getTransactionMetrics();
  }

  //SALES GROUP FUNCTIONS
  toggleSalesGroup(){
    this.salesGroupControl.show = !this.salesGroupControl.show;
    if(this.salesGroupControl.show){
      this.columnControl.show = false;
      this.customerControl.show = false;
      this.showDateRangeSelector = false;
      this.userTypeControl.show = false;
    }
  }

  selectSalesGroup(salesGroup: any) {
    this.salesGroupControl.selectedGroup = salesGroup;
    this.toggleSalesGroup();
    this.getTransactionMetrics();
  }

  //CUSTOMER FUNCTIONS
  toggleCustomer(){
    this.customerControl.show = !this.customerControl.show;
    if(this.customerControl.show){
      this.showDateRangeSelector = false;
      this.columnControl.show = false;
      this.userTypeControl.show = false;
      this.salesGroupControl.show = false;
    }
    else {
      if(this.customerControl.selectedCustomerType == 'single' && !this.customerControl.selectedSingleCustomer.id){
        this.customerControl.selectedCustomerType = 'all';
        this.customerControl.selectedCustomerDisplay = 'All Accounts';
      }
    }
  }

  selectCustomerType(customerType: string, customerTypeDisplay: string){
    this.customerControl.selectedCustomerType = customerType;
    this.customerControl.selectedCustomerDisplay = customerTypeDisplay;
    if(customerType != 'single') {
      this.customerControl.show = false;
      this.getTransactionMetrics();
    }
    else if (this.customerControl.selectedSingleCustomer.id){
      this.customerControl.selectedCustomerDisplay = this.customerControl.selectedSingleCustomer.id + ' - ' + this.customerControl.selectedSingleCustomer.name;
      this.getTransactionMetrics();
    }
  }

  filterCustomerControl(){
    this.filteredCustomerList = [];
    this.customerList.forEach((account) => {
      if(account.id.toLowerCase().match(this.customerControl.searchTerm.toLowerCase()) ||
        (account.name && account.name.toLowerCase().match(this.customerControl.searchTerm.toLowerCase())) ||
        this.customerControl.searchTerm == ''){
        this.filteredCustomerList.push(account);
      }
    });
  }

  selectCustomer(account: IUserAccount){
    this.customerControl.selectedSingleCustomer = account;
    this.customerControl.selectedCustomerDisplay = account.id + ' - ' + account.name;
    this.customerControl.show = false;
    this.getTransactionMetrics();
  }



  //COLUMN SETTINGS FUNCTIONS
  showSidenav() {
    this.dataService.setOverlay(true);
    document.getElementById('editColumnsSidenav').style.width = "24%";
  }

  hideSidenav() {
    this.dataService.setOverlay(false);
    document.getElementById('editColumnsSidenav').style.width = "0";
  }

  //PAGING/SORTING/SEARCHING FUNCTIONS
  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterTransactionMetrics();
  }

  formatTransactionMetrics(){

    this.transactionMetrics.forEach((transactionMetric) => {
      transactionMetric.dateFormatted = moment(transactionMetric.date).format('MM/DD/YYYY');
      transactionMetric.dateSortable = moment(transactionMetric.date).format('YYYYMMDD');
      this.resetColumnCounts(transactionMetric);
      if(transactionMetric.events.AMENDMENT_ACTIVITY_EXPORT){
        transactionMetric.columns.AMENDMENT_REPORTS_EXPORTED = transactionMetric.events.AMENDMENT_ACTIVITY_EXPORT['count'];
      }
      if(transactionMetric.events.AMENDMENT_CART_EXPORT){
        transactionMetric.columns.AMENDMENT_REPORTS_EXPORTED = transactionMetric.events.AMENDMENT_CART_EXPORT['count'];
      }
      if(transactionMetric.events.AMENDMENT_HISTORY_EXPORT){
        transactionMetric.columns.AMENDMENT_REPORTS_EXPORTED = transactionMetric.events.AMENDMENT_HISTORY_EXPORT['count'];
      }
      if(transactionMetric.events.AMENDMENT_SUBMITTED){
        transactionMetric.columns.AMENDMENT_SUBMITTED = transactionMetric.events.AMENDMENT_SUBMITTED['count'];
      }
      if(transactionMetric.events.BILLING_REPORT_EXPORT){
        transactionMetric.columns.BILLING_REPORT_EXPORT = transactionMetric.events.BILLING_REPORT_EXPORT['count'];
      }
      if(transactionMetric.events.CONTRACT_NICKNAME_UPDATED){
        transactionMetric.columns.CONTRACT_NICKNAME_UPDATED = transactionMetric.events.CONTRACT_NICKNAME_UPDATED['count'];
      }
      if(transactionMetric.events.INVOICE_DOWNLOAD){
        transactionMetric.columns.INVOICE_DOWNLOAD = transactionMetric.events.INVOICE_DOWNLOAD['count'];
      }
      if(transactionMetric.events.MARKETDATA_EXPORT){        
        transactionMetric.columns.MARKET_DATA_REPORTS_EXPORTED = transactionMetric.events.MARKETDATA_EXPORT['count'];
      }
      if(transactionMetric.events.MARKETDATA_GAS_EXPORT){
        transactionMetric.columns.MARKET_DATA_REPORTS_EXPORTED = transactionMetric.events.MARKETDATA_GAS_EXPORT['count'];
      }
      if(transactionMetric.events.MULTIPLE_INVOICE_DOWNLOAD){
        transactionMetric.columns.MULTIPLE_INVOICE_DOWNLOAD = transactionMetric.events.MULTIPLE_INVOICE_DOWNLOAD['count'];
      }
      if(transactionMetric.events.PAYMENT_SUBMITTED){
        transactionMetric.columns.PAYMENT_SUBMITTED = transactionMetric.events.PAYMENT_SUBMITTED['count'];
      }
      
      if(transactionMetric.events.SITE_GROUP_CREATED){
        transactionMetric.columns.SITE_GROUP_CREATED = transactionMetric.events.SITE_GROUP_CREATED['count'];
      }
      if(transactionMetric.events.USAGE_REPORT_EXPORT){
        transactionMetric.columns.USAGE_REPORTS_EXPORTED = transactionMetric.events.USAGE_REPORT_EXPORT['count'];
      }
      if(transactionMetric.events.USAGE_GRAPH_EXPORT){
        transactionMetric.columns.USAGE_REPORTS_EXPORTED = transactionMetric.events.USAGE_GRAPH_EXPORT['count'];
      }
    });
  }


  resetColumnCounts(transactionMetric: any){
    transactionMetric.columns = {};
    transactionMetric.columns.PAYMENT_SUBMITTED = 0;
    transactionMetric.columns.INVOICE_DOWNLOAD = 0;
    transactionMetric.columns.MULTIPLE_INVOICE_DOWNLOAD = 0;
    transactionMetric.columns.AMENDMENT_SUBMITTED = 0;
    transactionMetric.columns.BILLING_REPORT_EXPORT = 0;
    transactionMetric.columns.USAGE_REPORTS_EXPORTED = 0;
    transactionMetric.columns.AMENDMENT_REPORTS_EXPORTED = 0;
    transactionMetric.columns.MARKET_DATA_REPORTS_EXPORTED = 0;
    transactionMetric.columns.SITE_GROUP_CREATED = 0;
    transactionMetric.columns.CONTRACT_NICKNAME_UPDATED = 0;
  }

  filterTransactionMetrics() {
    if(!this.searchTerm){
      this.filteredTransactionMetrics = this.transactionMetrics;
    } else {
      this.filteredTransactionMetrics = this.singleFieldSearchPipe.transform(this.transactionMetrics, this.searchTerm, 'dateFormatted');
    }
    this.sortMetrics(this.sortBy);
    this.calculateTotals();
  }

  calculateTotals(){
    this.totals = {
      PAYMENT_SUBMITTED: 0,
      INVOICE_DOWNLOAD: 0,
      MULTIPLE_INVOICE_DOWNLOAD: 0,
      AMENDMENT_SUBMITTED: 0,
      BILLING_REPORT_EXPORT: 0,
      USAGE_REPORTS_EXPORTED: 0,
      AMENDMENT_REPORTS_EXPORTED: 0,
      MARKET_DATA_REPORTS_EXPORTED: 0,
      SITE_GROUP_CREATED: 0,
      CONTRACT_NICKNAME_UPDATED: 0
    };
    this.filteredTransactionMetrics.forEach(
      (transactionMetric) => {
        this.totals.PAYMENT_SUBMITTED += transactionMetric.columns.PAYMENT_SUBMITTED;
        this.totals.INVOICE_DOWNLOAD += transactionMetric.columns.INVOICE_DOWNLOAD;
        this.totals.MULTIPLE_INVOICE_DOWNLOAD += transactionMetric.columns.MULTIPLE_INVOICE_DOWNLOAD;
        this.totals.AMENDMENT_SUBMITTED += transactionMetric.columns.AMENDMENT_SUBMITTED;
        this.totals.BILLING_REPORT_EXPORT += transactionMetric.columns.BILLING_REPORT_EXPORT;
        this.totals.USAGE_REPORTS_EXPORTED += transactionMetric.columns.USAGE_REPORTS_EXPORTED;
        this.totals.AMENDMENT_REPORTS_EXPORTED += transactionMetric.columns.AMENDMENT_REPORTS_EXPORTED;
        this.totals.MARKET_DATA_REPORTS_EXPORTED += transactionMetric.columns.MARKET_DATA_REPORTS_EXPORTED;
        this.totals.SITE_GROUP_CREATED += transactionMetric.columns.SITE_GROUP_CREATED;
        this.totals.CONTRACT_NICKNAME_UPDATED +=  transactionMetric.columns.CONTRACT_NICKNAME_UPDATED;
      }
    );
  }

  sortMetrics(sortColumn: string) {
    this.sortBy = sortColumn;
    this.filteredTransactionMetrics = this.orderByPipe.transform(this.filteredTransactionMetrics, sortColumn, this.ascending);
    this.paginate(1);
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
    if(this.filteredTransactionMetrics){
      this.pager = this.pagerService.getPage(this.filteredTransactionMetrics, page, this.selectedDenom.count)
    }
  }

  openDetailModal(title: string, transactionMetric: ITransactionMetrics, colName: string) {
    let customer, userType, salesGroup;
    this.detailModalTitle = title;
    this.detailModalDateDisplay = moment(transactionMetric.date).format("MMMM DD, YYYY");
    this.forDate = moment(transactionMetric.date).format('YYYY-MM-DD');
    this.dataService.setLoading(true);

    customer = this.customerControl.selectedCustomerType != 'single' ? this.customerControl.selectedCustomerType : this.customerControl.selectedSingleCustomer.id;
    userType = this.userTypeControl.selectedType.name;
    salesGroup = this.salesGroupControl.selectedGroup.name;

    this.metricsService.getTransactionMetricsDetails(this.forDate, colName, customer, userType, salesGroup).subscribe((transactionMetrics : any) => {
      this.transactionMetricsDet = transactionMetrics;
      this.dataService.setLoading(false);

      this.transactionMetricsDetails = [];

      if (colName == 'AMENDMENT_REPORTS_EXPORTED') {
        this.addAll(transactionMetrics, 'AMENDMENT_ACTIVITY_EXPORT');
        this.addAll(transactionMetrics, 'AMENDMENT_CART_EXPORT');
        this.addAll(transactionMetrics, 'AMENDMENT_HISTORY_EXPORT');
      }
      else if (colName == 'MARKET_DATA_REPORTS_EXPORTED') {
        this.addAll(transactionMetrics, 'MARKETDATA_EXPORT');
        this.addAll(transactionMetrics, 'MARKETDATA_GAS_EXPORT');
      }
      else if (colName == 'USAGE_REPORTS_EXPORTED') {
        this.addAll(transactionMetrics, 'USAGE_REPORT_EXPORT');
        this.addAll(transactionMetrics, 'USAGE_GRAPH_EXPORT');
      }
      else {
        this.addAll(transactionMetrics, colName);
      }

      this.detailSearchTerm = "";
      this.showDetailSearchRow = false;
      this.filterTransactionMetricsDetails();

      $('#transactionMetricsDetailModal').modal('show');
    });
  }

  addAll(transactionMetric: ITransactionMetrics, colName: string){
    // console.log('inside add all',transactionMetric);
    if(transactionMetric[0].events[colName]){
      // console.log('i am here inside add all loop');
      transactionMetric[0].events[colName].forEach((event) => {
        event.auditEventDateFormatted = moment(event.auditEventDate).format('HH:mm') + " CST";
         this.transactionMetricsDetails.push(Object.assign({}, event));
      });
    }
  }

  toggleDetailSearchRow() {
    this.showDetailSearchRow = !this.showDetailSearchRow;
    this.detailSearchTerm = "";
    this.filterTransactionMetricsDetails();
  }

  filterTransactionMetricsDetails() {
    if(!this.detailSearchTerm){
      this.filteredTransactionMetricsDetails = this.transactionMetricsDetails;
    } else {
      this.filteredTransactionMetricsDetails = this.metricsDetailPipe.transform(this.transactionMetricsDetails, this.detailSearchTerm);
    }
  }

  closeDetail(){
    $('#transactionMetricsDetailModal').modal('hide');
  }

  exportTransactionsToExcel() {
    let fromDate, toDate;

    if(this.periodSelected){
      fromDate = this.periodStartDate;
      toDate = this.periodEndDate;
    }
    else {
      fromDate = this.startDateDisplay;
      toDate = this.endDateDisplay;
    }

    let difference = moment(toDate).diff(moment(fromDate), 'months', true);
    if(difference > 1){
      $('#oneMonthExportLimitForCustomerModal').modal('show');
    }
    else {
      let fromDate,toDate, org, orgName, userType, salesGroup;

      if(this.periodSelected){
        fromDate = this.periodStartDate;
        toDate = this.periodEndDate;
      }
      else {
        fromDate = this.startDateDisplay;
        toDate = this.endDateDisplay;
      }

      org = this.customerControl.selectedCustomerType == 'single' ? this.customerControl.selectedSingleCustomer.id : this.customerControl.selectedCustomerType;
      orgName = this.customerControl.selectedCustomerType == 'single' ? this.customerControl.selectedSingleCustomer.name : this.customerControl.selectedCustomerType;
      userType = this.userTypeControl.selectedType.name;
      salesGroup = this.salesGroupControl.selectedGroup.name;

      this.dataService.setLoading(true);
      this.metricsService.exportTransactions(fromDate, toDate, org, orgName, userType, salesGroup);
    }
  }

  closeExportModal(){
    $('#oneMonthExportLimitForCustomerModal').modal('hide');
  }
}
