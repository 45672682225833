import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import {IContract} from "../shared/entities/contract";
import * as moment from 'moment';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {IProfile} from "../shared/entities/profile";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  private contracts: IContract[];
  profile: IProfile;
  private contractsSubscription: Subscription;
  private profileSubscription: Subscription;

  constructor(private dataService: DataService,
              private router: Router) {
  }

  ngOnInit() {
    this.dataService.setTitleSource('Future Start');

    this.contractsSubscription = this.dataService.contractsForAccountSourceUpdated.subscribe(
      (contracts) => {
        this.contracts = contracts;
        this.checkValidContracts();
    });

    this.profile = this.dataService.getProfile();
    this.profileSubscription = this.dataService.profileUpdated.subscribe((profile) => {
      this.profile = profile;
    });

  }

  ngOnDestroy(){
    if (this.contractsSubscription) this.contractsSubscription.unsubscribe();
  }

  checkValidContracts() {
    this.contracts.forEach((contract) => {
      if (moment().isAfter(moment(contract.deliveryStartDate))) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  goToAmendments() {
    this.router.navigate(['/amendments']);
  }

  goToContracts() {
    this.router.navigate(['/contracts']);
  }

  goToManageProfile() {
    this.router.navigate(['/profile']);
  }

  goToContactUs() {
    this.router.navigate(['/contact']);
  }
}
