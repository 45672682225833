import { Component, OnInit } from '@angular/core';
import {PortalService} from "../../shared/portal.service";


@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit{

  constructor(private portalService: PortalService) { }

  ngOnInit() {

  }

  logOut() {
    this.portalService.logout().subscribe();
  }


}
