import { Injectable } from '@angular/core';
import {Response, ResponseContentType, Http, Headers} from '@angular/http';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import * as moment from "moment";
import {DataService} from "../shared/data.service";

@Injectable()
export class PositionService {
  headers: HttpHeaders;

  constructor(private httpClient: HttpClient,private oldHttp: Http,private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }
 
  getActualAndForecast(request: any): Observable<any> {
    let url = environment.getActualAndForecastUrl.replace('{accountId}', request.accountId);
    return this.httpClient
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getBlockTransactions(request: any): Observable<any> {
    let url = environment.getBlockTransactionsUrl;
    return this.httpClient
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getBMQContract(request): Observable<any> {
    let url = environment.getBMQContractUrl.replace('{accountId}', request.accountId);

//    let url = "./api/accounts/1-3TQ9GD/bmqcontractdata?productId=980209127&contractId=1-WLJ95&startDate=2016-06-01&endDate=2016-12-31";

    return this.httpClient
      .post(url, request, { headers: this.headers })
      // .get(url, { headers: this.headers })
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getMockData(): Observable<any> {
    let url = "./api/renewalanalysis/mockForwardStripData";
    return this.httpClient
      .get(url, {headers: this.headers})
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }


  
  exportPositionGraph(request: any): any{
    let url = environment.production ? environment.exportgetActualAndForecastUrl.replace('{accountId}', request.accountId.toString()) : environment.exportgetActualAndForecastUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let filename= 'Position Report_' + moment().format('MMDDYYYY') + '.xlsx';
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
