import { Injectable } from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Observable} from 'rxjs';
import {IPermission} from '../shared/entities/permission';
import {environment} from '../../environments/environment';
import {IUserAccount, IUserProfileData} from "../shared/entities/profile";

@Injectable()
export class LoginService {

  constructor(private http: Http) { }

  getUserPermissionsForAccount(accountId: string): Observable<any> {
    let url = environment.production ? environment.getUserPermissionsForAccountUrl.replace('{accountId}', accountId.toString()) : environment.getUserPermissionsForAccountUrl;
    return this.http
      .get(url)
      .map((response: Response) => <IPermission>response.json())
      .catch(this.handleError);
  }

  getAccountsForLoggedInUser(): Observable<IUserAccount[]> {
    let url = environment.accountsForUserUrl;
    return this.http
      .get(url)
      .map((response: Response) => <IUserAccount[]>response.json())
      .catch(this.handleError);
  }

  getProfileData(): Observable<IUserProfileData> {
    let url = environment.userProfileDataUrl;

    return this.http
      .get(url)
      .map((response: Response) => <IUserProfileData>response.json())
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
