import {Component, Input, OnInit} from '@angular/core';
import {IFourcpSite, ISite} from "../../../shared/entities/site";
import {FadeAnimation} from "../../../animations/fadeAnimation";
import {FadeInAnimation} from "../../../animations/fadeInAnimation";

@Component({
  selector: 'app-fourcp-site-performance-details',
  templateUrl: './fourcp-site-performance-details.component.html',
  styleUrls: ['./fourcp-site-performance-details.component.scss', '../fourcp-site-performance.component.scss'],
  animations: [FadeAnimation, FadeInAnimation]
})
export class FourcpSitePerformanceDetailsComponent implements OnInit {

  @Input()
    year: number;

  @Input()
    selectedSite: IFourcpSite;

  constructor() { }

  ngOnInit() {

  }
}
