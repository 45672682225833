import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import { IUserAccount } from '../shared/entities/profile';

@Component({
  selector: 'app-pricing-ie',
  templateUrl: './pricing-ie.component.html',
  styleUrls: ['./pricing-ie.component.scss'],
})
export class PricingIEComponent implements OnInit {
  brokerPortalFailed = true;
  brokerPortalLoaded = true;
  selectedAccount: IUserAccount;
  
  constructor(private dataService: DataService) {}

  ngOnInit() { 
      this.dataService.setTitleSource('Pricing');
      this.selectedAccount = this.dataService.getAccountSource();
      this.dataService.setContextualHelp(false);
      
  }

  ngOnDestroy() {
      this.dataService.setContextualHelp(true);
  }

}
