import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import {
  IMarketProperties,
  HenryHubProperties,
  HoustonShipChannelProperties,
  Market
} from '../../shared/entities/markets';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-markets',
  templateUrl: './public-markets.component.html',
  styleUrls: ['./public-markets.component.scss']
})
export class PublicMarketsComponent implements OnInit {
  snapshotProperties: IMarketProperties;
  infoProperties: IMarketProperties;
  henryHubProperties: IMarketProperties;
  houstonShipChannelProperties: IMarketProperties;
  Market = Market;
  HoustonShipChannelProperties = HoustonShipChannelProperties;
  HenryHubProperties = HenryHubProperties;

  constructor(public dataService: DataService) {}

  //required to set initial tab for report, declares subscription --DRR 08222019
  selectedTab: string = this.dataService.getSelectedTabForMarketsReports();
  tabSubscribe: Subscription;
  // --DRR08222019

  ngOnInit() {
    this.dataService.setContextualHelp(false);
    this.dataService.setSelectedNavItem('markets');
    this.setSelectedTabProperties();
    //start subscription OnInit --DRR 08222019
    this.tabSubscribe = this.dataService.selectedMarketReportUpdated.subscribe(
      (tab) => {
        this.selectedTab = tab;
        this.setSelectedTabProperties();
      }
    );
    // --DRR 08222019
  }

  ngOnDestroy() {
    if (this.tabSubscribe) this.tabSubscribe.unsubscribe(); // end subscription when leaving page --DRR 08222019
    this.dataService.setContextualHelp(true);
  }

  switchTabs(selectedTab: string) {
    this.selectedTab = selectedTab;
    this.setSelectedTabProperties();
  }

  setSelectedTabProperties() {
    this.dataService.selectedMarket = this.selectedTab;
    if (this.selectedTab == 'ERCOT') {
      this.dataService.setTitleSource('ERCOT Market Dashboard');
    } else if (this.selectedTab == 'ISO-NE') {
      this.dataService.setTitleSource('ISO-NE Market Dashboard');
    } else if (this.selectedTab == 'PJM') {
      this.dataService.setTitleSource('PJM Market Dashboard');
    } else if (this.selectedTab == 'COMMODITIES') {
      this.dataService.setTitleSource('Natural Gas Market Dashboard');
    } else {
      this.dataService.setTitleSource('Market Dashboards');
    }
  }
}
