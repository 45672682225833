import { Component, OnInit } from '@angular/core';
import {PortalService} from "../../shared/portal.service";
import {DataService} from "../../shared/data.service";
import { ActivatedRoute } from '@angular/router';
import {BillingSummaryService} from "../../billing-summary/billing-summary.service";
import {IDownloadMultipleInvoicesZipRequest} from '../../shared/entities/bill-reports';

@Component({
  selector: 'app-multiple-invoice-zip',
  templateUrl: './multiple-invoice-zip.component.html',
  styleUrls: ['./multiple-invoice-zip.component.scss']
})
export class MultipleInvoiceZipComponent implements OnInit {

  showError: boolean = false;
  accountId: string;
  fileName: string;

  constructor(private portalService: PortalService,
              private dataService: DataService,
              private route: ActivatedRoute,
              private billingReportService: BillingSummaryService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.accountId = params['accountId'];
        this.fileName = params['fileName'];
      });

    this.dataService.setLoading(true);
    this.download();
  }

  download(){
    //accountId:string, fileName:string,
    this.dataService.setLoading(true);
    let request:IDownloadMultipleInvoicesZipRequest = {accountId: this.accountId, fileName: this.fileName};
    this.billingReportService.downloadMultipleInvoiceZip(request).subscribe(
      (res) => {
        this.dataService.setLoading(false);
        let contentDisposition = res.headers.get('content-disposition');
        let filename = '';
        filename = contentDisposition.match(/filename=(.+)/)[1];
        // console.log(filename);
        if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
          window.navigator.msSaveBlob(res.body, filename);
        } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          let blob = new Blob([res.body], {type: "application/zip"});
          window.open(window.URL.createObjectURL(blob));
        }   else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(res.body);
          link.download = filename;
          link.click();
        }
        this.showError = false;
      }, err => {
        this.showError = true;
        this.dataService.setLoading(false);
        console.error(err);
      }
    );
  }

  str2bytes (str) {
    let bytes = new Uint8Array(str.length);
    for (let i=0; i<str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }
}
