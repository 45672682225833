import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {FakerService} from "../../shared/faker.service";

import * as moment from 'moment';
declare var $ :any;

import {IEnrollmentHistory, IEnrollmentMonthly, IEnrollmentDetail, IEnrollmentHistoryWithMonths} from "../metrics";
import {MetricsService} from "../metrics.service";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {subtract} from "ngx-bootstrap/chronos";
import {DateOption, EnrollmentMetricsDatePeriods, EnrollmentMetricsInceptionDate} from "../../shared/const/datePeriods";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-enrollment-metrics',
  templateUrl: './enrollment-metrics.component.html',
  styleUrls: ['enrollment-metrics.component.scss', '../metrics.component.scss'],
  animations: [FadeAnimation],
  providers: [GenericPipe]
})
export class EnrollmentMetricsComponent implements OnInit {

  //GROUPING SETTINGS VARS
  groupingControl: any = {
    show: false,
    selectedGrouping: 'accountRepresentatives',
    selectedGroupingDisplay: 'Account Representatives'
  };

  //DATE RANGE VARS
  showDateRangeSelector: boolean = false;
  datePeriods: DateOption[];
  selectedDatePeriod: DateOption;
  periodStartDate: string;
  periodEndDate: string;
  dateRangeLabel: string;

  //ENROLLMENT VARS
  historicalEnrollmentMetrics: IEnrollmentHistoryWithMonths;
  currentEnrollmentMetrics: IEnrollmentMonthly;
  unassignedCustomers: IEnrollmentDetail[];
  todayDisplay: string;

  //DETAIL MODAL
  detailModalTitle: string;
  enrollmentMetricsDetails: any;
  filteredEnrollmentMetricsDetails: any;
  detailSearchTerm: string;
  showDetailSearchRow: boolean;

  exportSubscription: Subscription;

  maskMode: boolean;
  maskModeSubscription: Subscription;

  constructor(private dataService: DataService,
              private metricsService: MetricsService,
              private fakerService: FakerService,
              private genericPipe: GenericPipe) { }

  ngOnInit() {

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.dataService.setTitleSource("Adoption Metrics");
    this.dataService.setSelectedMetricsPage('Enrollment');
    this.dataService.setContextualHelp(false);
    this.todayDisplay = moment().format('MM/DD/YYYY');
    this.datePeriods = EnrollmentMetricsDatePeriods;
    this.addDatePeriodYears();
    this.datePeriods.forEach((datePeriod) => {
      if (datePeriod.selected){
        this.selectedDatePeriod = datePeriod;
        this.calculateStartAndEndDate();
      }
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (trigger) => {
        this.exportEnrollmentMetricsToExcel();
      }
    );

    this.getCurrentEnrollment();
    this.getHistoricalEnrollment();
    this.getUnassignedCustomers();
  }

  ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  getHistoricalEnrollment(){
    this.dataService.setLoading(true);
    let grouping, startDate, endDate = "";
    grouping = this.groupingControl.selectedGrouping;
    startDate = this.periodStartDate;
    endDate = this.periodEndDate;

    this.metricsService.getHistoricalEnrollmentMetrics(grouping, startDate, endDate).subscribe((historicalEnrollment : any) => {
      // console.log("this.historicalEnrollmentMetrics: ", historicalEnrollment);
      this.historicalEnrollmentMetrics = historicalEnrollment;
      this.formatHistoricalEnrollmentMetrics();
      if(this.historicalEnrollmentMetrics && this.historicalEnrollmentMetrics[0] && this.historicalEnrollmentMetrics[0].data && this.historicalEnrollmentMetrics[0].data[0]){
      this.todayDisplay = moment(this.historicalEnrollmentMetrics[0].data[0].month).format('MM/DD/YYYY');}
      this.dataService.setLoading(false);
    });
  }

  maskTeamMemberName(name: string) {
    return this.fakerService.getName();
  }

  formatHistoricalEnrollmentMetrics(){
    // console.log("DATE LIST BEFORE SORT: ", this.historicalEnrollmentMetrics.formattedDateList);
    this.historicalEnrollmentMetrics.formattedDateList = this.historicalEnrollmentMetrics.dateList.sort().reverse();
    // console.log("DATE LIST AFTER SORT: ", this.historicalEnrollmentMetrics.formattedDateList);

    this.historicalEnrollmentMetrics.enrollmentData.forEach(
      (enrollment) => {
        let monthlyTemp = Object.assign([], this.historicalEnrollmentMetrics.dateList);
        monthlyTemp.forEach(
          (date) => {
            let found = false;
            enrollment.data.forEach(
              (monthly) => {
                if(monthly.month == date) {
                  found = true;
                }
              });
            if(!found){
              let emptyMonth =
                {
                  month: date,
                  assigned: 0,
                  enrolled: 0,
                  detail: []
                };
              enrollment.data.push(emptyMonth);
            }
          });
      });
  }

  getCurrentEnrollment(){
    let grouping = this.groupingControl.selectedGrouping;

    this.metricsService.getCurrentEnrollmentMetrics(grouping).subscribe((currentEnrollment : any) => {
      this.currentEnrollmentMetrics = currentEnrollment;
      // this.todayDisplay = moment(this.currentEnrollmentMetrics.snapShotDate).format('MM/DD/YYYY');
    });
  }

  getUnassignedCustomers(){
    let grouping = this.groupingControl.selectedGrouping;

    this.metricsService.getUnassignedCustomers(grouping).subscribe((unassignedCustomers : any) => {
      this.unassignedCustomers = unassignedCustomers;
    });
  }

  calculateStartAndEndDate(){
    let enrollmentInceptionDate = moment(EnrollmentMetricsInceptionDate);
    // For Testing
    // let today = '08/05/2019';

    if(this.selectedDatePeriod.option == 'Last3Months'){
      this.periodStartDate = moment().subtract(2, 'month').startOf('month').format('MM/YY');
      this.periodEndDate = moment().endOf('month').format('MM/YY');
    }
    else if(this.selectedDatePeriod.option == 'Last6Months'){
      this.periodStartDate = moment().subtract(5, 'month').startOf('month').format('MM/YY');
      this.periodEndDate = moment().endOf('month').format('MM/YY');
    }
    else if(this.selectedDatePeriod.option == 'Last9Months'){
      this.periodStartDate = moment().subtract(8, 'month').startOf('month').format('MM/YY');
      this.periodEndDate = moment().endOf('month').format('MM/YY');
    }
    else if(this.selectedDatePeriod.option == 'Last12Months'){
      this.periodStartDate = moment().subtract(11, 'month').startOf('month').format('MM/YY');
      this.periodEndDate = moment().endOf('month').format('MM/YY');
    }
    else {
      let year = this.selectedDatePeriod.option;
      this.periodStartDate = '01/'+ year;
      this.periodEndDate = '12/'+ year;
    }
    if(moment(this.periodStartDate, 'MM/YY').isSameOrBefore(enrollmentInceptionDate)){
      this.periodStartDate = enrollmentInceptionDate.format('MM/YY');
    }
    if(moment(this.periodEndDate, 'MM/YY').isSameOrAfter(moment())){
      this.periodEndDate = moment().format('MM/YY');
    }
  }

  addDatePeriodYears(){
    let startYear = moment('01/01/2018');
    let thisYear = moment();
    while(startYear.isSameOrBefore(thisYear)){
      let year: DateOption = {
        option: startYear.format('YY'),
        display: startYear.format('YYYY'),
        selected: false
      };
      this.datePeriods.push(year);
      startYear = startYear.add(1,'year');
    }
  }
  selectDatePeriod(selectedPeriod) {
    this.datePeriods.forEach((datePeriod) => {
      if(datePeriod.option == selectedPeriod.option){
        datePeriod.selected = true;
        this.selectedDatePeriod = datePeriod;
        this.dateRangeLabel = this.selectedDatePeriod.display;
        this.calculateStartAndEndDate();
      }
      else {
        datePeriod.selected = false;
      }
    });
    this.showDateRangeSelector = false;
    this.getHistoricalEnrollment();
  }

  toggleGrouping(){
    this.groupingControl.show = !this.groupingControl.show;
    if(this.groupingControl.show){
      this.showDateRangeSelector = false;
    }
  }

  selectGrouping(value: string, display: string){
    this.groupingControl.selectedGrouping = value;
    this.groupingControl.selectedGroupingDisplay = display;
    this.groupingControl.show = false;

    this.getCurrentEnrollment();
    this.getUnassignedCustomers();
    this.getHistoricalEnrollment();
  }

  toggleDateRange() {
    this.showDateRangeSelector = !this.showDateRangeSelector;
    if(this.showDateRangeSelector){
      this.groupingControl.show = false;
    }
  }

  openHistoricalDetailModal(title: string, teamMember: string, month: IEnrollmentMonthly) {
    let monthDisplay;
    if(moment().startOf('month').isSameOrBefore(moment(month.month).startOf('month'))){
      monthDisplay = moment(month.month).format('MM/DD/YYYY');
    }
    else {
      monthDisplay = moment(month.month).endOf('month').format('MM/DD/YYYY');
    }
    this.detailModalTitle = title + ' for ' + teamMember + ' (as of ' + monthDisplay + ')';
    // this.detailModalDateDisplay = moment(transactionMetric.date).format("MMMM DD, YYYY");
    this.enrollmentMetricsDetails = [];
    month.detail.forEach((detail) => {
      if(title != 'Enrolled' || detail.enrolled > 0){
        let detailCopy = Object.assign({}, detail);
        detailCopy.enrolledFormatted = detailCopy.enrolled > 0 ? 'Yes' : 'No';
        this.enrollmentMetricsDetails.push(detailCopy);
      }
    });

    this.detailSearchTerm = "";
    this.showDetailSearchRow = false;
    this.filterEnrollmentMetricsDetails();

    $('#EnrollmentMetricsDetailModal').modal('show');
  }

  openCurrentDetailModal(title: string, grouping: string){
    this.detailModalTitle = title + ' for ' + grouping + ' (as of ' + this.todayDisplay + ')';
    this.enrollmentMetricsDetails = [];
    this.currentEnrollmentMetrics.detail.forEach((detail) => {
      if(title != 'Enrolled' || detail.enrolled > 0){
        let detailCopy = Object.assign({}, detail);
        detailCopy.enrolledFormatted = detailCopy.enrolled > 0 ? 'Yes' : 'No';
        this.enrollmentMetricsDetails.push(detailCopy);
      }
    });

    this.detailSearchTerm = "";
    this.showDetailSearchRow = false;
    this.filterEnrollmentMetricsDetails();

    $('#EnrollmentMetricsDetailModal').modal('show');
  }

  filterEnrollmentMetricsDetails(){
    if(!this.detailSearchTerm){
      this.filteredEnrollmentMetricsDetails = this.enrollmentMetricsDetails;
    } else {
      this.filteredEnrollmentMetricsDetails = this.genericPipe.transform(this.enrollmentMetricsDetails, this.detailSearchTerm);
    }
    // console.log("filteredEnrollmentMetricsDetails: ", this.filteredEnrollmentMetricsDetails);
  }

  closeDetail(modalId: string){
    $('#' + modalId).modal('hide');
  }

  formatMonth(month){
    // console.log("FORMAT MONTH: ", month);
    if(moment().startOf('month').isSameOrBefore(moment(month).startOf('month'))){
      return moment(month).format('MM/DD/YYYY');
    }
    return moment(month).endOf('month').format('MM/DD/YYYY');
  }

  toggleDetailSearchRow(){
    this.showDetailSearchRow = !this.showDetailSearchRow;
    this.detailSearchTerm = "";
    this.filterEnrollmentMetricsDetails();
  }

  openUnassignedModal(title: string){
    this.detailModalTitle = title + ' ' + this.groupingControl.selectedGroupingDisplay.substring(0,this.groupingControl.selectedGroupingDisplay.length -1);
    $('#UnassignedDetailModal').modal('show');
  }

  exportEnrollmentMetricsToExcel() {
    let grouping, startDate, endDate, period = "";
    grouping = this.groupingControl.selectedGrouping;
    startDate = this.periodStartDate;
    endDate = this.periodEndDate;
    period = this.selectedDatePeriod.display;

    this.dataService.setLoading(true);
    this.metricsService.exportEnrollmentMetrics(grouping, startDate, endDate, period);
  }
}
