import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class MatrixpricingService {

  constructor(private oldHttp: Http,
    private http: HttpClient) { }

  getPortalSubscriptionById(id: number)
  {
    let url = environment.matrixPricingUrl
      .replace('{id}', id + '');

      return this.http
      .get(url)
      .catch(this.handleError); 
      
  }

  updatePortalSubscription(userId: number, subscriptionCategory: string, thirdPartyId : string, accountId : string)
  {
    let url = environment.matrixPricingUrl
      .replace('{id}', userId + '');

    let headers = new Headers({'Content-Type': 'application/json'});
    //build json to hold object
    var jsonObj = {
      userId : userId,
      subscriptionCategory : subscriptionCategory,
      thirdPartyId : thirdPartyId,
      accountId : accountId
    };
      return this.oldHttp.post(url,jsonObj,{headers : headers})
      .catch(this.handleError);
  }
  
  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
