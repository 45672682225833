import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {IUserAccount} from "../shared/entities/profile";
import {PaymentHistoryService} from "./payment-history.service";
import {IPaymentHistory,IPaymentHistoryRequest} from '../shared/entities/paymentHistory';
import {PortalService} from '../shared/portal.service';
import * as moment from 'moment';
import {GenericPipe} from '../shared/pipe/generic.pipe'
import {OrderByPipe} from '../shared/pipe/orderBy.pipe'
import {PagerDenoms} from '../shared/const/pagerDenoms'
import {IPagerDenom} from '../shared/const/pagerDenoms'
import {PagerService} from '../shared/pager.service'
import {Subscription} from "rxjs";
import {FakerService} from "../shared/faker.service";
import {ISite} from "../shared/entities/site";
import {IHelpStep} from "../shared/entities/contextualHelp";
import {IDownloadInvoiceRequest} from "../shared/entities/bill-reports";
import {BillingSummaryService} from "../billing-summary/billing-summary.service";
import {Authority} from "../shared/const/authority";
import {Permissions} from "../shared/const/permissions";
import { FilterType, ISubscribeReportTemplate } from '../shared/entities/subscribeReportTemplate';
import { ReportSettingsService } from '../subscribe-to-report/report-settings.service';
import { ReportDataUtility } from '../subscribe-to-report/report-data.utility';
import { IGenericReportSetting } from '../shared/entities/reportSettings';
import { DatePeriodsArr } from '../shared/const/datePeriods';
import { SitesControl } from '../shared/entities/controls';

declare var $: any;

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  providers: [GenericPipe, OrderByPipe]
})
export class PaymentHistoryComponent implements OnInit {

myAccount: IUserAccount;
myPaymentHistory: IPaymentHistory[];

startDate: Date;
endDate: Date;
minDate: Date;
maxDate: Date;
fromDate: Date;
toDate: Date;
ascending: boolean;
noDataError: boolean = false;
displayLimitError: boolean = false;
maskMode: boolean;
pagerDenoms: IPagerDenom[];
selectedDenom: IPagerDenom;
pager: any;
helpStep: IHelpStep;
allowedToMakePayment: boolean = true;

largeCustFlag: boolean = false;

showSearchRow: boolean = false;
searchTerm: string = '';
filteredPayments: IPaymentHistory[];

//Total Row
siteCount: number;
invoiceCount: number;
contractAccountCount: number;
serviceAddress: any; // Multiple or single entry
paymentMethod: string;  // Multiple or single entry
confirmationNumber: string;  // Multiple or single entry
status: string;  // Multiple or single entry
paymentAmountTotal: number;
isSuperUser: boolean;

reportRequest: IPaymentHistoryRequest;

// SitesControl
sitesControl: SitesControl;

// Subscriptions
exportSubscription: Subscription;
accountSourceSubscription: Subscription;
maskModeSubscription: Subscription;
helpStepSubscription: Subscription;
largeCustSubscription: Subscription;

 /**************************************************************
  Variables For Subscribe to Report Template - START
  **************************************************************/
 reportSettings: IGenericReportSetting[]; // List of All Report Settings for Subscribe To Report Template
 privateReports: IGenericReportSetting[]; // List of Private Report Settings for Subscribe To Report Template
 publicReports: IGenericReportSetting[];  // List of Private Report Settings for Subscribe To Report Template
 reportsLoaded: boolean = false;          // Boolean that tells us if Reports have loaded, used to Render Subscribe to Report Template
 newReportName = '';                      // Report Name used for saving a report
 makeDefault: boolean = true;             // Boolean Flag used for saving default report
 makePublic: boolean = false;             // Boolean Flag used for saving public or private reports
 selectedReport: IGenericReportSetting;   // Report that is used to generate data for the table and for saving/editing/deleting reports
 reportSubscriptionRangesSourceSubscription: Subscription; // Used to get the valid date ranges for Reports
 datePeriods: any[];                      // Helper for displaying different date periods for the filter (3 Months, 6 Months, etc.)
 reportDateRanges: any[];                 // Stores valid date ranges for reports
 endDates: any[];                         // Stores end date ranges for reports
 dateRangeControl: any = {                // Used to track date range selections from user inputs
   periodSelected: true,
   toggled: false,
   show: false,
   startDate: {},
   endDate: {},
   datePeriod: 'Last3Months',
   datePeriodDisplay: 'Last 3 Months',
   dateRangeDisplay: '',
 };
 reportTemplate: ISubscribeReportTemplate = { // Holds the information about the specific report and builds child SubscribeToReportTemplate with correct filters
   title: "Payment History Report",
   defaultReportName: "Payment History Report 01",
   reportType: 'PAYMENT_HISTORY',
   description: "This is a sample report description.",
   filters: [
    { // This is the first filter
      name: "Select Sites",
      filterType: FilterType.SitePicker,
      options: [
        { label: "Option1", value: "option1" }, // These are unused SitePicker is a special filter type
        { label: "Option2", value: "option2" },
        { label: "Option3", value: "option3" },
        { label: "Option4", value: "option4" },
        { label: "Option5", value: "option5" },
      ],
      selectedOptionLabel:"ALL SITES",
      selectedOptionValue: "ALL_SITES",
      defaultOptionLabel: "ALL SITES",
      defaultOptionValue: "ALL_SITES",
      showfilter: false
      },{ // This is the second filter
       name: "Date Range",
       filterType: FilterType.DatePicker,
       options: [
         { label: "Choice1", value: "choice1" }, // These are unused DatePicker is a special filter type
         { label: "Choice2", value: "choice2" }, // Never the less this is the pattern you would use to load a filter
         { label: "Choice3", value: "choice3" },
         { label: "Choice4", value: "choice4" },
         { label: "Choice5", value: "choice5" },
       ],
       selectedOptionLabel:"Last 3 Months", // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
       selectedOptionValue: "Last3Months",  // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
       defaultOptionLabel: "Last 3 Months", // Default Option
       defaultOptionValue: "Last3Months",   // Default Option
       showfilter: false
     },
   ],
 };
  /**************************************************************
  Variables For Subscribe to Report Template - END
  **************************************************************/

  constructor(private dataService: DataService,
  private paymentHistoryService: PaymentHistoryService,
  private reportSettingsService: ReportSettingsService,
  private utility: ReportDataUtility,
  private pipe: GenericPipe,
  private portalService: PortalService,
  private orderByPipe: OrderByPipe,
  private pagerService: PagerService,
  private billingService: BillingSummaryService,

  private fakerService: FakerService) { }

  ngOnInit() {
    this.dataService.setTitleSource('Payment History');
    this.setCurrentActivePage('payments');
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    this.sitesControl = new SitesControl();
    this.myAccount = this.dataService.getAccountSource();
    this.minDate = moment(new Date()).subtract(3,'years').toDate();
    this.maxDate = moment(new Date()).add(15, 'days').toDate();
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.datePeriods = DatePeriodsArr;

    // console.log("IS SUPER USER: ", this.isSuperUser);
    this.largeCustFlag =  this.dataService.getLargeCustomer();
    this.largeCustSubscription = this.dataService.largeCustUpdated.subscribe(bool => {
      this.largeCustFlag = bool;
    });

    this.reportTemplate.filters.forEach(filter =>{
      if(filter.filterType === FilterType.DatePicker) {
        filter.selectedOptionLabel = this.dateRangeControl.datePeriodDisplay;
        this.reportSubscriptionRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
          (dateRanges) => {
            this.reportDateRanges = dateRanges;
            this.endDates = dateRanges;
          });
      }
    });
    this.utility.calculateDateRanges();
    if (this.myAccount) {
      this.getReportSettings(true);
    }
    this.allowedToMakePayment = this.portalService.hasPermission(Permissions.MakePayment) || this.isSuperUser || this.portalService.hasPermission(Permissions.Admin);
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        if(!this.largeCustFlag)
          this.getPaymentHistory();
      }
    );
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.default) {
        this.selectDenom(denom);
      }
    });
    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {this.export();});



    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }

  getFakeStreetAddress(site: ISite): string {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: ISite): string {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: ISite): string {
    return this.portalService.getFakeZipCode(site);
  }

  getFakeConfirmationNumber(site: ISite): string {
    return this.portalService.getFakeConfirmationNumber(site);
  }

  ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.largeCustSubscription) this.largeCustSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.reportSubscriptionRangesSourceSubscription) this.reportSubscriptionRangesSourceSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  generateRequest() {
    this.reportRequest =
    {
      accountId: this.myAccount.id,
      groupId: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null : null,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null,
      siteRequest: this.sitesControl.siteRequest,
      siteGroupCategory: this.sitesControl.selectedCategory ? this.sitesControl.selectedCategory : '',
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      brand: window.localStorage.getItem("brand")
    };
    this.reportRequest.accountId = this.myAccount.id;
    this.reportRequest.groupId = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null : null;
    this.reportRequest.systemGroupName = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null;
     if (this.sitesControl.selectedGrouping.groupName == 'All Sites') {
      this.reportRequest.systemGroupName = 'ALL_SITES';
      this.sitesControl.siteRequest = 'GROUP';
      this.reportRequest.siteGroupCategory = '';
    }
  }

  // Retrieve PaymentHistory
  getPaymentHistory() {
    this.generateRequest();
    if(this.allowedToMakePayment) {
      this.dataService.setLoading(true);
      let startDateString = moment(this.startDate).format('YYYY-MM-DD');
      let endDateString = moment(this.endDate).format('YYYY-MM-DD');
      if(startDateString && endDateString){
        this.paymentHistoryService.getPaymentHistory(this.reportRequest).subscribe(
          (myPaymentHistory) => {
            this.myPaymentHistory = myPaymentHistory;
            this.noDataError = this.myPaymentHistory.length == 0;
            this.displayLimitError = false;
            this.ascending = false;
            this.myPaymentHistory.forEach((payment) => {
              if(payment.contractAccount.toString().substr(0,3) == '800' && payment.serviceAddress) {
                payment.serviceAddress.streetAddress = 'Multiple';
              }
            });
            this.filteredPayments = this.orderByPipe.transform(this.myPaymentHistory, 'paymentDate', this.ascending);
            this.paginate(1);
            this.getTotals();
            this.dataService.setLoading(false);
          }, err => {
            if(err.status == 404) {
              this.noDataError = true;
              this.displayLimitError = true;
            }
            if(err.status == 500) {
              this.displayLimitError = true;
              this.noDataError = false;
            }
            this.filteredPayments = [];
            this.paginate(1);
            console.error(err);
            this.dataService.setLoading(false);
          }
        );
      }
    }
  }

  sortPaymentHistory(col: string) {
    this.ascending = !this.ascending;
    this.filteredPayments = this.orderByPipe.transform(this.filteredPayments, col, this.ascending);
    this.paginate(1);
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
    if(this.filteredPayments){
      this.pager = this.pagerService.getPage(this.filteredPayments, page, this.selectedDenom.count)
    }
  }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }

  toggleSearchRow() {
      this.showSearchRow = !this.showSearchRow;
      this.searchTerm = '';
      this.filterPayments();
  }

  getDateDisplay(date: string) { return this.portalService.getDateDisplay(date); }

  export() {
    this.generateRequest();
    // Get start date, end date, account Id,
    let startDateString = moment(this.startDate).format('YYYY-MM-DD');
    let endDateString = moment(this.endDate).format('YYYY-MM-DD');
    this.dataService.setLoading(true);

    if(startDateString && endDateString){
      this.paymentHistoryService.exportPaymentHistory(this.reportRequest);
    }

    // Ask Micah what data he needs for export
    // Call service with data to export
    // this.paymentHistoryService.export
  }

  filterPayments(){
    if(!this.searchTerm){
      this.filteredPayments = this.myPaymentHistory;
    } else {
      this.filteredPayments = this.pipe.transform(this.myPaymentHistory, this.searchTerm);
    }
    this.getTotals();
    this.paginate(1);
  }

  padLeft(conf:string,text:string, padChar:string, size:number): string {
    if(conf == null){
      return('-------');
    } else {
      return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
    }
}



 validateConf(conf:string): string {
    if(conf == null){
      return('-------');
    } else {
      return (conf) ;
    }
}

getTotals() {
  this.siteCount = 0;
  let siteIdArr = [], siteIncluded;
  this.invoiceCount = 0;
  let invoiceNoArr = [], invoiceIncluded;
  this.contractAccountCount = 0;
  let contractAccountArr = [], contractAccountIncluded;
  this.serviceAddress = null;
  this.paymentMethod = '';
  this.confirmationNumber = '';
  this.status = '';
  this.paymentAmountTotal = 0;
  this.filteredPayments.forEach((payment) => {
    siteIncluded = false;
    siteIdArr.forEach((siteId) => {
      if(siteId == payment.siteId)
        siteIncluded = true;
    });
    if(!siteIncluded) {
      this.siteCount++;
      siteIdArr.push(payment.siteId);
    }

    invoiceIncluded = false;
    invoiceNoArr.forEach((invoiceNo) => {
        if(invoiceNo == payment.invoiceNo)
          invoiceIncluded = true;
    });
    if (!invoiceIncluded && payment.invoiceNo) {
      this.invoiceCount++;
      invoiceNoArr.push(payment.invoiceNo);
    }

    contractAccountIncluded = false;
    contractAccountArr.forEach((contractAccount) => {
      if(contractAccount == payment.contractAccount)
        contractAccountIncluded = true;
    });
    if (!contractAccountIncluded) {
      this.contractAccountCount++;
      contractAccountArr.push(payment.contractAccount);
    }

    if(!this.serviceAddress && payment.serviceAddress) this.serviceAddress = payment.serviceAddress.streetAddress;

    if(payment.serviceAddress && this.serviceAddress) {
       if(payment.serviceAddress && payment.serviceAddress.streetAddress != this.serviceAddress.streetAddress)
      	  this.serviceAddress = 'Multiple';
    }
    else
       this.serviceAddress = '...';

    if(!this.paymentMethod) this.paymentMethod = payment.paymentMethodToken;
    if(payment.paymentMethodToken != this.paymentMethod)
      this.paymentMethod = 'Multiple';

    if(!this.confirmationNumber) this.confirmationNumber = payment.paymentConfirmation
    if(payment.paymentConfirmation != this.confirmationNumber)
      this.confirmationNumber = 'Multiple';

    if(!this.status) this.status = payment.paymentStatus
    if(payment.paymentStatus != this.status)
      this.status = 'Multiple';

    this.paymentAmountTotal += payment.paymentAmount;
  });
}

  exportToExcel() {
    this.dataService.triggerExport();
  }

  downloadInvoice(invoice: any) {
    this.dataService.setLoading(true);
    let request: IDownloadInvoiceRequest = {
      accountId: this.myAccount.id,
      contractAccountNumber: invoice.contractAccount,
      collectiveAccountId: this.maskMode ? null : invoice.contractAccount.substr(0,3)=="800" ? invoice.contractAccountNum : "",
      collectiveInvoiceNo: this.maskMode ? null : invoice.collectiveInvoiceNo,
      siteId: invoice.siteId,
      invoiceNo: this.maskMode ? '9999999999990' : invoice.invoiceNo,
      billingPeriod: invoice.dueDate ? invoice.dueDate.substr(0,7).replace("-","") : null,
      repOwnerId: invoice.repOwnerId
    };
    if(this.dataService.getIsMobile()){
      this.billingService.openMobileInvoice(request);
    } else {
      this.billingService.downloadInvoice(request);
    }
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  /**************************************************************
  Methods For Subscribe to Report Template - Start
  **************************************************************/
  getReportSettings(selectDefault?: boolean){
    this.utility.getReportSettings(this.myAccount.id, this.reportTemplate, this.dateRangeControl, this.datePeriods, this.reportDateRanges, this.maskMode, selectDefault, this.selectedReport, null, null)
    .subscribe({
      next: ({ reportSettings, reportTemplate, selectedReport, publicReports, privateReports, startDate, endDate }) => {
        this.reportSettings = reportSettings;
        this.selectedReport = selectedReport;
        this.publicReports = publicReports;
        this.privateReports = privateReports;
        this.startDate = startDate;
        this.endDate = endDate;
        this.reportsLoaded = true;
        reportTemplate.filters.forEach((filter) => {
          switch (filter.filterType) {
            case 'DatePicker':
              this.dateRangeControl = filter.savedFilterControl;
              break;
            case 'SitePicker':
              // Uncomment if using SitePicker
              this.sitesControl = filter.savedFilterControl;
              break;
            default:
              // Action for other filter types
              break;
          }
        });
        if(this.myAccount && !this.largeCustFlag){
          this.getPaymentHistory();
        }
      },
      error: (error) => {
        // Handle errors
      }
    });
  }

  reportSavedHandler($event) {
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    this.makePublic = this.selectedReport.publicFlag ? this.selectedReport.publicFlag == 1 : false;
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    let isNewReport = this.selectedReport.reportId == null;

    const saveReportRequest: any = {
      reportType: this.reportTemplate.reportType,
      reportId: this.selectedReport.reportId,
      accountId: this.myAccount.id,
      reportName: this.newReportName ? this.newReportName : this.selectedReport.reportName ? this.selectedReport.reportName : 'Generic Report 01',
      defaultReport: this.selectedReport.defaultReport ? 1 : 0,
      publicFlag: this.selectedReport.publicFlag ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.endDate,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null,
      groupId: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id.toString() : null : null,
      siteGroupCategory: this.sitesControl.selectedCategory ? this.sitesControl.selectedCategory.toString() : null,
      siteRequest: this.sitesControl.siteRequest ? this.sitesControl.siteRequest : null,
      reportSpecificBoolean: this.selectedReport.reportSpecificBoolean
    };
    saveReportRequest.systemGroupName = saveReportRequest.systemGroupName === 'All Sites' ? 'ALL_SITES' : saveReportRequest.systemGroupName;
    this.utility.saveReport(saveReportRequest, isNewReport)
    .then((reportId) => {
        this.selectedReport.reportId = reportId;
        this.getReportSettings();
    })
    .catch((error) => {
        // it will load default report
        this.getReportSettings(true);
    });
  }

  reportSelectedHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    this.startDate = loadSelectedReport.startDate;
    this.endDate = loadSelectedReport.endDate;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    this.getPaymentHistory();
  }

  exportHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the BillingReport Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          this.sitesControl = filter.savedFilterControl;
          break;
        default:
          // Action for other filter types
          break;
      }
    });
    this.dataService.setLoading(true);
    this.exportToExcel();
  }

  reportDeletedHandler($event){
    this.reportSettingsService.deleteReportSetting(this.myAccount.id, this.selectedReport.reportId, this.dataService.getUserSource().login,this.reportTemplate.reportType, this.selectedReport.publicFlag).subscribe(
      () => {
        this.getReportSettings(true);
      }
    );
  }
  /**************************************************************
  Methods For Subscribe to Report Template - End
  **************************************************************/
}


