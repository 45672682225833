import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../shared/data.service";
import {IAmendmentRequest} from "../../shared/entities/amendments";
import {AmendmentsAttachmentsService} from "../../amendments/amendments-attachments.service";
import {IAttachment} from "../../shared/entities/attachment";
import {Subscription} from "rxjs";
import {IUserAccount} from "../../shared/entities/profile";

declare var $ :any;


@Component({
  selector: 'app-amendments-attachments',
  templateUrl: './amendments-attachments.component.html',
  styleUrls: ['./amendments-attachments.component.scss']
})
export class AmendmentsAttachmentsComponent implements OnInit {

  selectedAmendmentRequest: IAmendmentRequest;
  filesToUpload: FileList;
  currentDocuments: IAttachment[];
  hasDocuments: boolean = false;
  currentAttachmentsSubscription: Subscription;
  showFilesTooLargeMessage: boolean = false;
  totalUploadSize: number = 0;
  fileToRemove: IAttachment;

  @Input()
  market: string;

  constructor(private dataService: DataService,
              private attachmentsService: AmendmentsAttachmentsService) { }

  ngOnInit() {
    this.selectedAmendmentRequest = this.dataService.getSelectedAmendmentRequest();
    this.currentDocuments = this.dataService.getCurrentAttachments();
    this.updateHasDocuments();

    this.currentAttachmentsSubscription = this.dataService.currentAttachmentsUpdated
      .subscribe(
        (attachments) => {
          this.currentDocuments = attachments;
          this.updateHasDocuments();
        }
      );
  }

  triggerFileBrowser(){
    $('#fileInput').trigger("click");
  }


  updateHasDocuments(){
    this.hasDocuments = this.currentDocuments && this.currentDocuments.length > 0;
  }

  uploadDocuments(){
    this.showFilesTooLargeMessage = false;
    this.filesToUpload = (<HTMLInputElement>document.getElementById('fileInput')).files;

    if(this.filesToUpload && this.filesToUpload.length > 0){
      let validFiles = this.validateFileSelection(this.filesToUpload);
      if(validFiles){
        for(let i = 0; i < this.filesToUpload.length; i++) {
          let file = this.filesToUpload[i];
          this.attachmentsService.uploadAttachment(this.selectedAmendmentRequest.id, file).subscribe((result : any) =>{
            this.currentDocuments.push(result);
            this.dataService.setCurrentAttachments(this.currentDocuments);
            this.updateHasDocuments();
          });
        }
      }
      else {
        this.showFilesTooLargeMessage = true;
      }
    }
  }

  validateFileSelection(filesToUpload: FileList) {
    let listSize = 0;
    //sizeLimit 6mb currently;
    let sizeLimit = 6000000;
    if (this.filesToUpload && this.filesToUpload.length > 0) {
      for(let i = 0; i < this.filesToUpload.length; i++) {
        let file = filesToUpload[i];
        listSize += file.size;
        this.totalUploadSize = listSize;
      }
    }
    if(listSize > sizeLimit){
      return false;
    }
    else {
      return true;
    }
  }

  saveComment(file: IAttachment){
    let originalComment = file.comment;
    file.comment = file.tempComment;
    this.attachmentsService.saveComment(this.selectedAmendmentRequest.id, file).subscribe(
      () => {
        this.currentDocuments.forEach((doc) => {
          if(doc.id == file.id){
            doc.comment = file.comment;
            doc.editingComment = false;
          }
        });
        this.dataService.setCurrentAttachments(this.currentDocuments);
      });
  }

  removeAttachment(file: IAttachment){
    this.attachmentsService.deleteAttachment(this.selectedAmendmentRequest.id, file.id)
      .subscribe(() => {
        let tempDocuments: IAttachment[] = [];
        this.currentDocuments.forEach((doc) => {
          if(doc.id != file.id){
            tempDocuments.push(doc);
          }
        });
        this.currentDocuments = tempDocuments;
        this.updateHasDocuments();
        this.dataService.setCurrentAttachments(this.currentDocuments);
      });
  }

  downloadAttachment(file:IAttachment){
    this.attachmentsService.downloadAttachment(this.selectedAmendmentRequest.id, file);
  }


  toggleCommenting(file: IAttachment){
    file.tempComment = file.comment ? file.comment : "";
    file.editingComment = true;
  }

  cancelComment(file: IAttachment){
    file.tempComment = file.comment;
    file.editingComment = false;
  }

  close(){
    $('#amendmentsAttachmentsModal').modal('hide');
  }
}
