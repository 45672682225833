import {IComparisonChartResponse, IComparisonChartData, IComparisonReport} from "./entities/comparison";
import {IBillingReportResponse} from "../shared/entities/bill-reports";
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {PortalService} from "../shared/portal.service";
import {DataService} from "../shared/data.service";
import {DatePeriods} from "../shared/const/datePeriods";
@Injectable()
export class ComparisonUtility {

  constructor(private portalService: PortalService,
              private dataService: DataService) {
  }

  extractChartData(chartResponse: IComparisonChartResponse, selectedReport: IComparisonReport) {
    let chartData: IComparisonChartData = {} as any;
    let currentMonth = 1;
    let emptyVal = 0;
    chartData.siteCountYear1 = [];
    chartData.usageYear1 = [];
    chartData.usageYear1Partial = false;

    chartResponse.year1Values.sort((val1, val2) => {
      return val1.month < val2.month ? -1 : 1;
    });
    chartResponse.year2Values.sort((val1, val2) => {
      return val1.month < val2.month ? -1 : 1;
    });
    if (chartResponse.year1Values) {
      chartResponse.year1Values.forEach((item) => {
        if (currentMonth < item.month) {
          while (currentMonth < item.month) {
            chartData.siteCountYear1.push(null);
            chartData.usageYear1.push(null);
            currentMonth++;
          }
        }
        currentMonth++;
        chartData.siteCountYear1.push(item.siteCount);
        chartData.usageYear1.push(Math.round(item.usage));
      });
      while (chartData.siteCountYear1.length < 12) {
        chartData.siteCountYear1.push(null);
      }
      while (chartData.usageYear1.length < 12) {
        chartData.usageYear1Partial = true;
        chartData.usageYear1.push(null);
      }
    }
    emptyVal = 0;
    if (selectedReport.year1 == moment().year()) {
      emptyVal = null;
    }
    while (chartData.siteCountYear1.length < 12) {
      chartData.siteCountYear1.push(emptyVal);
    }

    chartData.siteCountYear2 = [];
    chartData.usageYear2 = [];
    chartData.usageYear2Partial = false;

    if (chartResponse.year2Values) {
      currentMonth = 1;
      chartResponse.year2Values.forEach((item) => {
        if (currentMonth < item.month) {
          while (currentMonth < item.month) {
            chartData.siteCountYear2.push(null);
            chartData.usageYear2.push(null);
            currentMonth++;
          }
        }
        currentMonth++;
        chartData.siteCountYear2.push(item.siteCount);
        chartData.usageYear2.push(Math.round(item.usage));
      });
      while (chartData.siteCountYear2.length < 12) {
        chartData.siteCountYear2.push(null);
      }
      while (chartData.usageYear2.length < 12) {
        chartData.usageYear2Partial = true;
        chartData.usageYear2.push(null);
      }
    }

    chartData.usageDelta = [];
    chartData.siteCountDelta = [];
    let delta = 0;

    for (let i in chartData.usageYear1) {
      if (chartData.usageYear1[i] == null || chartData.usageYear2 == null) {
        chartData.usageDelta.push(null);
      } else {
        delta = chartData.usageYear1[i] - chartData.usageYear2[i];
        if (delta) {
          chartData.usageDelta.push(delta);
        } else {
          chartData.usageDelta.push(null);
        }
      }
    }

    for (let i = 0; i < chartData.siteCountYear1.length; i++) {
      if (chartData.siteCountYear1[i] == null || chartData.siteCountYear2 == null) {
        chartData.siteCountDelta.push(null);
      } else {
        delta = chartData.siteCountYear1[i] - chartData.siteCountYear2[i];
        if (delta) {
          chartData.siteCountDelta.push(delta);
        } else {
          chartData.siteCountDelta.push(null);
        }
      }
    }

    // Get Totals - Thanks Moses...
    chartData.usageYear1Total = 0;
    chartData.usageYear1.forEach((usage) => {
      chartData.usageYear1Total += usage
    });
    chartData.usageYear2Total = 0;
    chartData.usageYear2.forEach((usage) => {
      chartData.usageYear2Total += usage
    });
    chartData.usageDeltaTotal = 0;
    chartData.usageDelta.forEach((usage) => {
      chartData.usageDeltaTotal += usage
    });
    return chartData;
  };
}
