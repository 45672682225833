import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import { IPaymentState, IPaymentSchedule, IPaymentSubmission, IPayment,
         IPaymentResponse, IPaymentSettingsKey, IPaymentMethod, IQuickPayVerify, IQuickPayDetails, IPCIQuickPayReturn } from '../payments/payments';
import { PaymentsService } from '../payments/payments.service';
import {Subscription} from "rxjs";
import * as moment from 'moment';
import { PortalService } from '../shared/portal.service';
import { IUserEmail } from '../shared/entities/profile';
import { IAccountLinkRequest, IAccountLinkResponse, IPaymentDetails } from './quickpay';
import { HttpClient } from '@angular/common/http';
declare var $ :any;

@Component({
  selector: 'app-quick-pay',
  templateUrl: './quick-pay.component.html',
  styleUrls: ['./quick-pay.component.scss']
})

export class QuickPayComponent implements OnInit {
  state: IPaymentState;
  verify: IQuickPayVerify
  resp: IQuickPayDetails
  invalidAccount : boolean = false;
  quickPayError: boolean = false;
  hasColAcct: boolean = false;
  quickPayDetails : IQuickPayDetails;
  ncaFlag : boolean = false;
  quickPayResponse : IPCIQuickPayReturn;
  confirmationEmail : string;
  accountId : string;
  showSpinner : boolean = false;
  pciVistited : boolean = false;
  pciURL : string = ''
  mailFormat;
  verifiedAccount : boolean = false;
  constructor(private paymentsService: PaymentsService,
              private portalService: PortalService,
              private http: HttpClient) {
  }
  //constructor() { }

  ngOnInit() {
    this.state = {
      currentStep: 1,
      viewInvoicesToggled: false,
      viewAccountToggled: false,
      selectedBalances: [],
      paymentTotal: 0,
      buttonLabel: 'Verify',
      backDisabled: false,
    };
    this.verify = {
      ca: '',
      checkDigit: '',
      zipCode: ''
    };
    this.resp;
    this.verifiedAccount = false;
    this.pciURL = this.getPCIUrl();
    document.cookie = "clickedquickpay=0";
    this.subscribeToQuickPayEvent();
    this.mailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    document.cookie = "leftquickpay=1";
    // window.history.replaceState( {}, '', '/login.html');
}

  ngOnDestroy() {
    this.state = {
      currentStep: 1,
      viewInvoicesToggled: false,
      viewAccountToggled: false,
      selectedBalances: [],
      paymentTotal: 0,
      buttonLabel: 'Verify',
      backDisabled: false,
    };
    this.verify = {
      ca: '',
      checkDigit: '',
      zipCode: '',
    };
    let emptyResp : IPCIQuickPayReturn;
    document.cookie = "leftquickpay=1";
    this.paymentsService.setQuickPayResponse(emptyResp);
    window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
  }
  getOriginUrl() : string{
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com";
    const stg1 = "https://stg1-manage.nrg.com";
    const test1 = "https://test1-manage.nrg.com";
    const pd = "https://manage.nrg.com";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
      return dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return pd;
    }
    else {
      return local;
    }
  }
  getPCIUrl() : string{
    if (window.location.origin.indexOf("dev1-") > -1) {
      return 'QP-iframe-dev.html'
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return 'QP-iframe-stg.html';
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return 'QP-iframe-test.html';
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return 'QP-iframe-prod.html';
    }
    else {
      return 'QP-iframe-stg.html';
    }
  }

  updateButtonLabel() {
    if (this.state.currentStep == 1) {
      this.state.buttonLabel = 'Verify';
    } else if (this.state.currentStep == 2) {
      this.state.buttonLabel = 'Pay';
    } else if (this.state.currentStep == 3) {
      this.state.buttonLabel = 'Confirm';
    } else {
      this.state.backDisabled = true;
    }
  }


  sendVerifyQuickPayAccount() {
  if(this.verify.ca && this.verify.checkDigit && this.verify.zipCode){
      this.invalidAccount = false;

    console.log("sendVerifyAccount: ", this.verify);
    this.showSpinner = true;
    this.paymentsService.sendVerifyQuickPayAccount(this.verify).subscribe(
    (resp : any) => {
      console.log("Quick Pay Details: ", resp);
      if(resp.caName == null || resp.caNumber == null || this.verify.zipCode.length > 5){
        this.invalidAccount = true;
        this.showSpinner = false;
      }
      else if((resp.ncastatus != "") && resp.nccastatus == 'X'){
        this.invalidAccount = true;
        this.showSpinner = false;
        this.ncaFlag = true;
      }
      else if(resp.colContrAcctId && resp.caNumber.substring(0,3) != '800'){
        // console.log("has col acct id!!");
        this.invalidAccount = true;
        this.showSpinner = false;
        this.hasColAcct = true;
      }
      else{

        let req : IAccountLinkRequest = {
          invoiceAccountNumber : this.verify.ca,
          checkDigit : this.verify.checkDigit,
          billingZip : this.verify.zipCode,
          invoiceProvider : "QuickPay"
        }
        this.linkAccount(req).subscribe((response: IAccountLinkResponse) => {
          if (response.accountName) {
            resp.caName = response.accountName;
          }

          if(resp.accountId){
            this.accountId = resp.accountId;
          }
            this.navigate(2);
            this.verifiedAccount = true;
            this.invalidAccount = false;
            this.showSpinner = false;
            this.hasColAcct = false;
            this.quickPayDetails = resp;
            //this.getPCIForm(resp);
          }

        );
      }
      }, err => {
        this.invalidAccount = true;
        this.showSpinner = false;
        console.error(err);
      }
    )
    }else{
      this.showSpinner = false;
      this.invalidAccount = true;
    }
  }


  getPCIForm(qpDetails: IQuickPayDetails) {
    if(!this.pciVistited){
      this.pciVistited = true;
      console.log("qpDetails: ", qpDetails);
      let element = $("#pciIFrame").contents().find("#emptyForm");
      if(element) {
        element[0].caNumber.value = qpDetails.caNumber;
        element[0].caName.value = qpDetails.caName;
        element[0].bpNumber.value = qpDetails.bpNumber;
        element[0].arDate.value = qpDetails.arDate;
        element[0].arCurrentDueDate.value = qpDetails.arCurrentDueDate;
        element[0].arCurrentArBalance.value = qpDetails.arCurrentArBalance;
        element[0].arLastPayAmt.value = qpDetails.arLastPayAmt;
        element[0].arPastPayDate.value = qpDetails.arPastPayDate;
        element[0].pastDueAmt.value = qpDetails.pastDueAmt;
        element[0].creditAmt.value = qpDetails.creditAmt;
        element[0].pastDueAmtNec.value = qpDetails.pastDueAmtNec;
        element[0].ncastatus.value = qpDetails.ncastatus;
        element[0].nccastatus.value = qpDetails.nccastatus;
        element[0].email.value = this.confirmationEmail;
        element[0].accountId.value = this.accountId;

        if('8880' === window.location.port) {
          // Ensure we use HTTPS for non-local environments
            element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/api/payments/pciQuickPayCallback";
            element[0].submitQuickPayUrl.value = window.location.protocol + "//" + window.location.hostname + ":8880/api/payments/pciSubmitQuickPay";

          }
        else {
            element[0].redirectUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/api/payments/pciQuickPayCallback";
            element[0].submitQuickPayUrl.value = window.location.protocol + "//" + window.location.hostname + "/myaccount/api/payments/pciSubmitQuickPay";

      }

        this.quickPayDetails = qpDetails;
        $(element).submit();
      }
    }

  }

  navigate(step){
   // this.TESTsendPaymentConfirmationEmails();
    if(this.state.currentStep == 2 && step == 1){
      this.pciRefresh();
    }
    this.state.currentStep = step;
    this.updateButtonLabel();
    if(step >= 3) {
      this.getQuickPayResponseInfo();
      if(this.confirmationEmail != ''){
        this.sendConfirmationEmail();
      }
    }

  }

  pciRefresh()
  {
       var myframe = document.querySelector('iframe[id="pciIFrame"]');
       (<HTMLIFrameElement> myframe).src += '';
  }


  getQuickPayResponseInfo(){
    this.quickPayResponse = this.paymentsService.getQuickPayResponse();
    if(this.quickPayResponse.paytype == null) {
      console.log('error getting payment type, setting to unknown');
      this.quickPayResponse.paytype = 'unkn';
    }
    if(this.quickPayResponse.paymentDate == null){
      this.quickPayResponse.paymentDate = new Date().toDateString();
    }
  }

  linkAccount(req: IAccountLinkRequest) {
    let url = environment.linkAccountUrl;
    return this.http
      .post(url, req)
      .map((response: IAccountLinkResponse) => response)
  }

  sendConfirmationEmail(){
    console.log('sending conf email to ' + this.confirmationEmail);

    //backed conf email call
    this.sendPaymentConfirmationEmails();

  }

  // checkForConfirmationNumber() {
  //   console.log("checking con number");
  //   if(this.state.currentStep > 1){
  //     let resp = this.paymentsService.getQuickPayResponse();
  //     if(resp != null && resp.confirmationNumber != null){
  //       this.state.currentStep = 3;
  //       return true;
  //     }
  // }
  //   return false;
  // }

  subscribeToQuickPayEvent(){
    this.paymentsService.pciQuickPayCalledBack.subscribe((resp)=>{
      if(resp && resp.confirmationNumber != null){
        console.log('detected change on payments service via event');
        if(resp.errMsg){
          console.log("error on qp");
        }
        else{
          this.navigate(3);
        }

      }
    })
  }

  validateEmailFormat() : boolean{
     if(this.confirmationEmail != null)
     {
       if(this.confirmationEmail.length > 0)
       {

          if(this.confirmationEmail.match(this.mailFormat) && this.confirmationEmail.match(this.mailFormat).length > 0 && this.confirmationEmail.match(this.mailFormat)[0] == this.confirmationEmail){
            return true;
          }
       }
     }
     else{
      return false;
     }
  }

  sendPaymentConfirmationEmails() {
    let email : IUserEmail;
    email = {temp:this.confirmationEmail};
    let emailList = []; emailList.push(email);
    let customerName = this.quickPayDetails.caName;

    let msg = this.quickPayResponse.message;
    while(msg.indexOf('_') != -1){
     msg = msg.replace('_',' ');
    }
    let paymentMethod = msg;
    let confirmationNumber = this.quickPayResponse.confirmationNumber;
    let paymentDate = moment(this.quickPayResponse.paymentDate).format('YYYY-MM-DD');
    let amt = this.quickPayResponse.paymentAmount;
    let paymentDetails:IPaymentDetails[] = [{
            contractAccountNum : this.quickPayDetails.caNumber
    }];
    this.portalService.sendQuickPayConfirmationEmails(emailList, amt, paymentDate, paymentDetails, customerName, paymentMethod, confirmationNumber, null /*what can I put here for accountId?*/ ).subscribe(
      (resp) => {
       console.log('email sent');
       console.log(resp);
      },

    );
  }

  // TESTsendPaymentConfirmationEmails() {
  //   let email : IUserEmail;
  //   email = {temp:this.confirmationEmail};
  //   let emailList = []; emailList.push(email);
  //   let customerName = "caName";
  //   let paymentMethod = "012399012381298";
  //   let confirmationNumber = "CI00981238";
  //   let paymentDate = moment('2016-01-01').format('YYYY-MM-DD');
  //   let amt = "1.0";
  //   let paymentDetails:IPaymentDetails[] = [{
  //     "contractAccountNum": "1231203-0"
  //   }];
  //   this.portalService.sendQuickPayConfirmationEmails(emailList, amt, paymentDate, paymentDetails, customerName, paymentMethod, confirmationNumber, null /*what can I put here for accountId?*/ ).subscribe(
  //     (resp) => {
  //      console.log('email sent');
  //      console.log(resp);
  //     },

  //   );
  // }

}


