import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {
  IAmendmentRequest, IAmendmentSite, IAmendmentProduct, IAmendmentHistory,
  IAmendmentHistoryRequest, IUtility
} from "../shared/entities/amendments";
import {DataService} from "../shared/data.service";
import * as moment from 'moment';
import {PortalService} from "../shared/portal.service";
import {IAccount} from "../shared/entities/account";


@Injectable()
export class AmendmentsService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService, private portalService: PortalService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  updateRequestTypes(selectedAmendmentRequest: IAmendmentRequest){
    let actualRequestType = selectedAmendmentRequest.requestType;
    let expectedRequestType = this.getExpectedRequestType(selectedAmendmentRequest);

    if(expectedRequestType != "" && expectedRequestType != actualRequestType){
      selectedAmendmentRequest.requestType = expectedRequestType;
      this.saveNewAmendmentRequest(selectedAmendmentRequest).subscribe((response) => {
        this.dataService.setSelectedAmendmentRequest(selectedAmendmentRequest);
      });
    }
  }

  getExpectedRequestType(selectedAmendmentRequest: IAmendmentRequest){
    let hasAdd = false;
    let hasDelete = false;
    if(selectedAmendmentRequest.amendmentSites){
    selectedAmendmentRequest.amendmentSites.forEach(
      (site)=>{
        if(site.amendmentType.toLowerCase() == 'add'){
            hasAdd = true;
          }
        if(site.amendmentType.toLowerCase() == 'delete'){
          hasDelete = true;
        }
      });

      if(hasAdd && hasDelete){
        return "add_delete";
      }
      else if (hasAdd){
        return "add";
      }
      else if (hasDelete){
        return "delete";
      }
    }

    return "";
  }

  getRecentAmendmentRequests(accountId: string){
    let url = environment.recentAmendmentRequestsUrl.replace('{accountId}', accountId);
    return this.http
      .get(url)
      .map((response: HttpResponse<IAmendmentRequest[]>) => response)
      .catch(this.handleError);
  }

  getAmendmentRequest(accountId: string, requestId: number){
    let url = environment.recentAmendmentRequestsUrl.replace('{accountId}', accountId);
    url = url + "/" + requestId
    return this.http
      .get(url)
      .map(response  => {
          console.log('got response from amendment request', response);
          return response;
      })
      .catch(this.handleError);
  }

  getAllAmendmentRequests(accountId: string){
    let url = environment.allAmendmentRequestsUrl.replace('{accountId}', accountId);
    return this.http
      .get(url)
      .map((response: HttpResponse<IAmendmentRequest[]>) => response)
      .catch(this.handleError);
  }

  saveNewAmendmentRequest(amendmentRequestToSave: IAmendmentRequest){
    let url = environment.saveAmendmentRequestUrl.replace('{accountId}', amendmentRequestToSave.accountId);

    return this.http
      .post(url, amendmentRequestToSave)
      .map((response: HttpResponse<IAmendmentRequest>) => response)
      .catch(this.handleError);
  }

  deleteAmendmentRequest(amendmentRequestToDelete: IAmendmentRequest){
    let url = environment.deleteAmendmentRequestUrl
      .replace('{accountId}', amendmentRequestToDelete.accountId.toString())
      .replace('{requestId}', amendmentRequestToDelete.id.toString());

    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  validateSiteId(siteId: string){
    let url = environment.validateSiteIdUrl.replace('{siteId}', siteId).replace('{accountId}', this.dataService.getAccountSource().id);
    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getFilteredContracts(form: IAmendmentSite) {
    let url = environment.getFilteredContractsUrl.replace('{accountId}', this.dataService.getAccountSource().id);
    // if(!this.dataService.getAppProperties()) {
    //   // Local
    //   return this.http
    //     .get(url)
    //     .map((response: HttpResponse<any>) => response)
    //     .catch(this.handleError);
    // } else {
    let body = {
      market: form.market,
      zone: form.zone,
      nextMeterReadDate: moment(form.completionDate).format('YYYY-MM-DD'),
      accountId: this.dataService.getAccountSource().id
    };
    if(form.market != 'ERCOT') {
      body["serviceState"] = form.siteState;
      body["utility"] = form.tdsp;
    }
    return this.http
      .post(url, body)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
    // }
  }

  getContractsForSite(siteId: string, completionDate: string){
    let url = environment.getContractsForSiteLessReliabilityUrl.replace('{accountId}', this.dataService.getAccountSource().id).replace('{siteId}', siteId);
    url = url + '?completionDate=' + completionDate;
    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getActiveSites(completionDate: any){
    let url = environment.getActiveSites.replace('{accountId}', this.dataService.getAccountSource().id);
    url = url.replace('{completionDate}', moment(completionDate).format('YYYY-MM-DD'));
    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getAmendmentSite(accountId: string, form: IAmendmentSite){
    let url = environment.saveAmendmentSiteUrl
      .replace('{accountId}', accountId)
      .replace('{requestId}', form.requestId.toString())
      .replace('{amendmentSiteId}', form.id.toString());

    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  saveAmendmentSite(form: IAmendmentSite) {
    let url = environment.saveAmendmentSiteUrl
      .replace('{accountId}', form.accountId)
      .replace('{requestId}', form.requestId.toString())
      .replace('{amendmentSiteId}', '');
    if(form.id){
      url += form.id;
    } else {
      url += '-1';
    }

    return this.http
      .post(url, form)
      .map((response: HttpResponse<IAmendmentSite>) => response)
      .catch(this.handleError);
  }

  deleteAmendmentSite(form: IAmendmentSite){
    let url = environment.deleteAmendmentSiteUrl
      .replace('{accountId}', form.accountId)
      .replace('{requestId}', form.requestId.toString())
      .replace('{amendmentSiteId}', form.id.toString());

    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  saveProduct(form: IAmendmentSite, product: IAmendmentProduct){
    let url = environment.saveAmendmentProductsUrl.replace('{accountId}', form.accountId).replace('{requestId}', form.requestId.toString()).replace('{amendmentSiteId}', form.id.toString());

    return this.http
      .post(url, product)
      .map((response: HttpResponse<IAmendmentProduct>) => response)
      .catch(this.handleError);
  }

  deleteProduct(form: IAmendmentSite, product: IAmendmentProduct) {
    let url = environment.deleteAmendmentProductsUrl
      .replace('{accountId}', form.accountId)
      .replace('{requestId}', form.requestId.toString())
      .replace('{amendmentSiteId}', form.id.toString());

    // Add query params
    url = url + '?contractId=' + product.contractId;
    url = url + '&productId=' + product.productId;
    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }


  submitAmendmentstc(amendmentToSubmit: IAmendmentRequest){
    //let url = environment.amendmentsAcknowledgeUrl;
    let url = environment.acknowledgeUrl;
     // Add query params
    url = url + '?acktype=AMENDMENT_TERMS_AND_CONDITIONS_ACKNOWLEDGE';

   // console.log(url);
    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  submitRequest(amendmentToSubmit: IAmendmentRequest){
    let url = environment.submitAmendmentRequestUrl
      .replace('{accountId}', amendmentToSubmit.accountId)
      .replace('{requestId}', amendmentToSubmit.id.toString());

    return this.http
      .post(url, {})
      .map((response: HttpResponse<IAmendmentRequest>) => response)
      .catch(this.handleError);
  }

  exportCart(requestId: number, maskingMode:boolean): any {
    let url = environment.exportAmendmentsCartUrl.replace('{accountId}', this.dataService.getAccountSource().id).replace('{requestId}', requestId.toString()).replace('{maskingMode}', (maskingMode ? "true":"false")).replace('{brand}', (window.localStorage.getItem("brand")));
    this.portalService.exportToExcel(url, 'AmendmentsCart.xlsx');
  }

  exportHistory(startDate: string, endDate: string, maskingMode : string, brand : string): any {
    let url = environment.exportAmendmentsHistoryUrl.replace('{accountId}', this.dataService.getAccountSource().id);
    url += '?startDate=' + startDate + '&endDate=' + endDate
    + '&maskingMode=' + maskingMode
    + '&brand=' + brand;

    this.portalService.exportToExcel(url, 'AmendmentsHistory.xlsx');
  }

  exportRecentAmendments(): any {
    let url = environment.exportAmendmentsRecentUrl.replace('{accountId}', this.dataService.getAccountSource().id);
    this.portalService.exportToExcel(url, 'RecentAmendments.xlsx');
  }

  downloadAmendmentsForm(accountId: string): any {
    this.dataService.setLoading(true);
    let url = environment.amendmentDownloadUrl.replace('{accountId}', accountId);
    this.portalService.exportToExcel(url, 'RFI_Form.xlsx');
  }

  getEmptyAmendmentRequest(accountId: string){
    let emptyAmendmentRequest: IAmendmentRequest = {} as any;
    emptyAmendmentRequest.active = true;
    emptyAmendmentRequest.amendmentSites = [];
    return emptyAmendmentRequest;
  }

  getAmendmentHistory(request: IAmendmentHistoryRequest) {
    let url = environment.getAmendmentHistoryUrl.replace('{accountId}', request.accountId.toString());
    url = url + '?startDate=' + request.startDate + '&endDate=' + request.endDate;
    return this.http
      .get(url, {headers: this.headers})
      .map((response: HttpResponse<IAmendmentHistory[]>) => response)
      .catch(this.handleError);
  }



  getContractsForAccount(id: string): Observable<IAccount> {
    let url = environment.production ? environment.getContractsForAccount.replace('{accountId}', id): environment.getContractsForAccount;
    return this.http
      .get(url).pipe(
        map((data: IAccount)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  sendAmendmentConfirmationEmail(usersToEmail: string, firstName: string, lastName: string, customerName: string, request: IAmendmentRequest){

    if(environment.production){
      let url = environment.sendAmendmentConfirmationEmailUrl;
      let addressArray = usersToEmail;
      let data = {
        "firstName": firstName,
        "lastName": lastName,
        "customerName": customerName,
        "emailAddresses": addressArray,
        "amendmentRequest": request,
        "amendmentSites": request.amendmentSites
      };
      let headers = new HttpHeaders({'Content-Type': 'application/json'});
      return this.http
        .post(url, data, { headers })
        .map((response: any) => response)
        .catch(this.handleError);
    } else {
      return new Observable(observer => {
        //this.dataService.setPasswordResetEmailSent(true);
        observer.complete();
      });
    }
  }

  getUtilities(market: string) {
    let url = environment.getUtilitiesURL.replace('{market}', market);
    return this.http
      .get(url)
      .map((response: HttpResponse<IUtility[]>) => response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}
