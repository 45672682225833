import { Component, OnInit } from '@angular/core';
import {
  UserMetricsDatePeriods, DateOption, UsageDatePeriods,
  UserMetricsInceptionDate
} from "../../shared/const/datePeriods";
import {DataService} from "../../shared/data.service";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {IPagerDenom, PagerDenoms} from "../../shared/const/pagerDenoms";
import {IUserMetrics, userColumnsControl, ITransactionMetricsDetail} from "../metrics";


import * as moment from 'moment';
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {MetricsService} from "../metrics.service";
import {PagerService} from "../../shared/pager.service";
import {Subscription} from "rxjs";
import {IUserAccount} from "../../shared/entities/profile";
import {SingleFieldSearchPipe} from "../../shared/pipe/single-field-search.pipe";
import {MetricsDetailPipe} from "../../shared/pipe/metrics-detail.pipe";

declare var $ :any;


@Component({
  selector: 'app-user-metrics',
  templateUrl: './user-metrics.component.html',
  animations: [FadeAnimation],
  styleUrls: ['user-metrics.component.scss', '../metrics.component.scss'],
  providers: [GenericPipe, OrderByPipe, SingleFieldSearchPipe, MetricsDetailPipe]
})
export class UserMetricsComponent implements OnInit {

  //DATE RANGE VARS
  showDateRangeSelector: boolean = false;
  datePeriods: DateOption[];
  selectedDatePeriod: DateOption;
  periodSelected: boolean = true;
  startMinDate: Date;
  startMaxDate: Date;
  endMinDate: Date;
  endMaxDate: Date;
  periodStartDate: string;
  periodEndDate: string;
  startDate: Date;
  startDateDisplay: string;
  endDate: Date;
  endDateDisplay: string;
  dateRangeLabel: string;
  invalidDateRange: boolean;
  dataAvailableDate: string;
  forDate: string;
  doNotDisplay: boolean = true;

  //CUSTOMER SETTING VARS
  customerControl: any = {
    show: false,
    selectedCustomerType: 'customer',
    selectedCustomerDisplay: 'Customer Accounts',
    selectedSingleCustomer: {},
    searchTerm: ''
  }
  customerList: IUserAccount[] = [];
  filteredCustomerList: IUserAccount[] = [];

  //ORGANIZATION VARS
  selectedOrganization: string = 'all';

  //PAGING/SORTING/SEARCHING
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  sortBy: string = "dateSortable";
  ascending: boolean = false;
  showSearchRow: boolean = false;
  searchTerm: string;

  //COLUMN SETTING VARS
  columnControl: any = {
    userCols: [],
    txCols: []
  }

  //METRICS
  userMetrics: IUserMetrics[];
  userMetricUx: IUserMetrics;

  filteredUserMetrics: IUserMetrics[];
  totals: any = {};

  //DETAILS
  detailModalTitle: string;
  detailModalDate: string;
  detailModalDateDisplay: string;
  userEvents: boolean;
  showDetailSearchRow: boolean = false;
  detailSearchTerm: string;
  userMetricsDetails: ITransactionMetricsDetail[];
  filteredUserMetricsDetails: ITransactionMetricsDetail[];

  thirdPartyAccountsAdded = false;
  accountsForUserSourceSubscription: Subscription;
  thirdPartyAccountsSubscription: Subscription;
  exportSubscription: Subscription;

  constructor(private dataService: DataService,
              private metricsService: MetricsService,
              private genericPipe: GenericPipe,
              private orderByPipe: OrderByPipe,
              private pagerService: PagerService,
              private singleFieldSearchPipe: SingleFieldSearchPipe,
              private metricsDetailPipe: MetricsDetailPipe) { }

  ngOnInit() {
    this.dataService.setTitleSource("Adoption Metrics");
    this.dataService.setSelectedMetricsPage('By Customer');
    this.dataService.setContextualHelp(false);

    this.columnControl = userColumnsControl;
    this.datePeriods = UserMetricsDatePeriods;
    this.datePeriods.forEach((datePeriod) => {
      if (datePeriod.selected){
        this.selectedDatePeriod = datePeriod;
        this.calculateStartAndEndDate();
      }
    });

    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.default) {
        this.selectDenom(denom);
      }
    });

    this.startMinDate = moment(UserMetricsInceptionDate).toDate();
    this.endMinDate = moment(UserMetricsInceptionDate).toDate();
    this.startMaxDate = moment().toDate();
    this.endMaxDate = moment().toDate();

    this.dataAvailableDate = moment(UserMetricsInceptionDate).format("MM/DD/YYYY");

    this.customerList = this.dataService.getAccountsForUserSource();

    this.accountsForUserSourceSubscription = this.dataService.accountsForUserSourceUpdated.subscribe((accounts) => {
      this.customerList = accounts;
      this.filteredCustomerList = accounts;
    });

    let thirdPartyAccounts = this.dataService.getThirdPartyAccountsSource();
    if(thirdPartyAccounts){
      this.addThirdPartyAccounts(thirdPartyAccounts);
    }
    this.thirdPartyAccountsSubscription = this.dataService.thirdPartyAccountsSourceUpdated.subscribe((thirdPartyAccounts) => {
      this.addThirdPartyAccounts(thirdPartyAccounts);
    });

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (trigger) => {
        this.exportByUserToExcel();
      }
    );

    this.getUserMetricData();
  }

  ngOnDestroy() {
    if (this.accountsForUserSourceSubscription) this.accountsForUserSourceSubscription.unsubscribe();
    if (this.thirdPartyAccountsSubscription) this.thirdPartyAccountsSubscription.unsubscribe();
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  addThirdPartyAccounts(tpas: IUserAccount[]){
    if(!this.thirdPartyAccountsAdded) {
      this.customerList = this.customerList.concat(tpas);
      this.thirdPartyAccountsAdded = true;
    }
    this.filteredCustomerList = this.customerList;
  }


  //DATE RANGE FUNCTIONS
  toggleDateRange() {
    this.showDateRangeSelector = !this.showDateRangeSelector;
    if(this.showDateRangeSelector){
      this.columnControl.show = false;
      this.customerControl.show = false;
    }
  }

  closeDateRange() {
    this.showDateRangeSelector = false;
  }

  selectDatePeriod(selectedPeriod) {
    this.datePeriods.forEach((datePeriod) => {
      if(datePeriod.option == selectedPeriod.option){
        datePeriod.selected = true;
        this.selectedDatePeriod = datePeriod;
        this.dateRangeLabel = this.selectedDatePeriod.display;
        this.calculateStartAndEndDate();
      }
      else {
        datePeriod.selected = false;
      }
    });
  }

  calculateStartAndEndDate(){
    if(this.selectedDatePeriod.option == 'InceptionToDate'){
      this.periodStartDate = UserMetricsInceptionDate;
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.ThisMonth){
      this.periodStartDate = moment().startOf('month').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.LastMonth){
      this.periodStartDate = moment().subtract(30, 'day').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.Last3Months){
      this.periodStartDate = moment().subtract(90, 'day').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.Last6Months){
      this.periodStartDate = moment().subtract(180, 'day').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
    if(this.selectedDatePeriod.option == UsageDatePeriods.LastYear){
      this.periodStartDate = moment().subtract(1, 'year').format('MM/DD/YYYY');
      this.periodEndDate = moment().format('MM/DD/YYYY');
    }
  }

  startDateSelected(event) {
    this.startDate = event.value;
    this.endMinDate = event.value;
    this.startDateDisplay = moment(event.value).format('MMM DD, YYYY');
  }

  endDateSelected(event) {
    this.endDate = event.value;
    this.startMaxDate = event.value;
    this.endDateDisplay = moment(event.value).format('MMM DD, YYYY');

  }

  applyDateRange() {
    this.invalidDateRange = false;
    if(!this.periodSelected && (moment(this.startDate).isBefore(moment(this.startMinDate)) ||
      moment(this.endDate).isAfter(moment(this.endMaxDate)) ||
      moment(this.startDate).isAfter(moment(this.endDate)))) {
      this.invalidDateRange = true;
    } else {
      this.showDateRangeSelector = false;
      this.getUserMetricData();
    }
  }


  //PAGING/SORTING/SEARCHING FUNCTIONS
  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = "";
    this.filterUserMetrics();
  }

  filterUserMetrics() {
    if(!this.searchTerm){
      this.filteredUserMetrics = this.userMetrics;
    } else {
      this.filteredUserMetrics = this.genericPipe.transform(this.userMetrics, this.searchTerm);
    }
    this.sortMetrics(this.sortBy);
    this.calculateTotals();
  }

  sortMetrics(sortColumn: string) {
    this.sortBy = sortColumn;
    this.filteredUserMetrics = this.orderByPipe.transform(this.filteredUserMetrics, sortColumn, this.ascending);
    this.paginate(1);
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
    if(this.filteredUserMetrics){
      this.pager = this.pagerService.getPage(this.filteredUserMetrics, page, this.selectedDenom.count)
    }
  }

  //CUSTOMER FUNCTIONS
  toggleCustomer(){
    this.customerControl.show = !this.customerControl.show;
    if(this.customerControl.show){
      this.showDateRangeSelector = false;
      this.columnControl.show = false;
    }
    else {
      if(this.customerControl.selectedCustomerType == 'single' && !this.customerControl.selectedSingleCustomer.id){
        this.customerControl.selectedCustomerType = 'customer';
        this.customerControl.selectedCustomerDisplay = 'Customer Accounts';
      }
    }
  }

  selectCustomerType(customerType: string, customerTypeDisplay: string){
    this.customerControl.selectedCustomerType = customerType;
    this.customerControl.selectedCustomerDisplay = customerTypeDisplay;
    if(customerType != 'single') {
      this.customerControl.show = false;
      this.getUserMetricData();
    }
    else if (this.customerControl.selectedSingleCustomer.id){
      this.customerControl.selectedCustomerDisplay = this.customerControl.selectedSingleCustomer.id + ' - ' + this.customerControl.selectedSingleCustomer.name;
      this.getUserMetricData();
    }
  }

  filterCustomerControl(){
    this.filteredCustomerList = [];
    this.customerList.forEach((account) => {
      if(account.id.toLowerCase().match(this.customerControl.searchTerm.toLowerCase()) ||
        (account.name && account.name.toLowerCase().match(this.customerControl.searchTerm.toLowerCase())) ||
        this.customerControl.searchTerm == ''){
        this.filteredCustomerList.push(account);
      }
    });
  }

  selectCustomer(account: IUserAccount){
    this.customerControl.selectedSingleCustomer = account;
    this.customerControl.selectedCustomerDisplay = account.id + ' - ' + account.name;
    this.customerControl.show = false;
    this.getUserMetricData();
  }

  //COLUMN SETTINGS FUNCTIONS
  showSidenav() {
    this.dataService.setOverlay(true);
    document.getElementById('editColumnsSidenav').style.width = "24%";
  }

  hideSidenav() {
    this.dataService.setOverlay(false);
    document.getElementById('editColumnsSidenav').style.width = "0";
  }



  //USER METRIC FUNCTIONS
  getUserMetricData(){
    this.dataService.setLoading(true);
    let fromDate,toDate, organization;

    if(this.periodSelected){
      fromDate = this.periodStartDate;
      toDate = this.periodEndDate;
    }
    else {
      fromDate = this.startDateDisplay;
      toDate = this.endDateDisplay;
    }

    if(this.customerControl.selectedCustomerType == 'single') {
      organization = this.customerControl.selectedSingleCustomer.id;
    }
    else {
      organization = this.customerControl.selectedCustomerType;
    }

    this.metricsService.getUserMetricData(fromDate, toDate, organization).subscribe(
      (userMetrics : any) => {
        this.userMetrics = userMetrics;
        // console.log('userMetric',this.userMetrics);
        this.formatUserMetrics();
        this.filterUserMetrics();
        this.dataService.setLoading(false);
      }
    )
  }

  calculateTotals(){
      // console.log('colum control',this.columnControl);
    this.columnControl.userCols.forEach((col) => {
      this.totals[col.name] = 0;
    });
    this.columnControl.txCols.forEach((col) => {
      this.totals[col.name] = 0;
    });
    // console.log('filteredMetrics',this.filteredUserMetrics);
    this.filteredUserMetrics.forEach(
      (metric) => {
        this.columnControl.txCols.forEach((col) => {
          if(metric.txEvents[col.name]){
            // console.log('in tx events',col.name);
            this.totals[col.name] += metric.txEvents[col.name]['count'];
          }
        });

         this.columnControl.userCols.forEach((col) => {  
          if(metric.userEvents[col.name]){
            // console.log('in user events',col.name);
            this.totals[col.name] += metric.userEvents[col.name]['count'];
          }

        });
      }
    );

    // console.log('totals',this.totals);
  }

  formatUserMetrics(){
    //Add sortable date column
    this.userMetrics.forEach((metric) => {
      metric.dateFormatted = moment(metric.date).format('MM/DD/YYYY');
      metric.dateSortable = moment(metric.date).format('YYYYMMDD');
    });
  }

  //DETAILS

  openDetailModal(title: string, userMetric: IUserMetrics, userEvent: boolean, colName: string) {

    this.userMetricsDetails = [];
    this.detailModalTitle = title;
    this.detailModalDate = userMetric.date;
    this.detailModalDateDisplay = moment(this.detailModalDate).format("MMMM DD, YYYY");

    this.forDate = moment(this.detailModalDate).format('YYYY-MM-DD');
    // console.log('date displays',this.forDate);

    var modalName = '#userMetricsDetailModal';

     // console.log('title',title);
     // console.log('userMetric',userMetric);
     // console.log('userevent',userEvent);
     // console.log('ColName',colName);
    let fromDate,toDate, organization;

    
    if(this.customerControl.selectedCustomerType == 'single') {
      organization = this.customerControl.selectedSingleCustomer.id;
    }
    else {
      organization = this.customerControl.selectedCustomerType;
    }

    if(this.periodSelected){
      fromDate = this.periodStartDate;
      toDate = this.periodEndDate;
    }
    else {
      fromDate = this.startDateDisplay;
      toDate = this.endDateDisplay;
    }


    if(userEvent){


      this.metricsService.getUserMetricDataUX(this.forDate, organization,colName ).subscribe(
      (userMetrics : any) => {
        this.userMetricUx = userMetrics;
        // userMetric = userMetrics;
         // console.log('uxevent userMetrics',this.userMetricUx);
        // this.formatUserMetrics();
        // this.filterUserMetrics();
        this.dataService.setLoading(false);
    //   }
    // )



      if(colName === 'distinctUserLoginsss') {
        this.userMetricsDetails = userMetric.userEvents[colName];
        this.userEvents = true;
      } else {
        // console.log('i am here already');
        let metricsDetails = this.userMetricUx[0].events[colName];
        
         // console.log('uxevent userMetrics data',this.userMetricUx);
         // console.log('uxevent metricDetails data',metricsDetails);
        if(metricsDetails){
          metricsDetails.forEach((detail) => {
            detail.activationDateFormatted = detail.data.activationDate != null ? moment(detail.activationDate).format("MM/DD/YYYY") : "";
            detail.lastLoginFormatted = detail.data.lastLogin != null ? moment(detail.lastLogin).format("MM/DD/YYYY") : "";
            this.userMetricsDetails.push(Object.assign({}, detail));
          });
        }
        // // console.log('ux userMetricDetails',this.userMetricsDetails);
          modalName = '#userMetricsEnrollmentDetailModal'
            // if(colName === 'distinctUserLogins') {
            //   modalName = '#userMetricsEnrollmentDetailModal'
            // }
            // else{
            //   modalName = '#userMetricsEnrollmentDetailModal'
            // }        
          // this.formatUserMetricsDetails();
          // this.filterUserMetricsDetails();
          // $(modalName).modal('show');
      }
         this.formatUserMetricsDetails();
          this.filterUserMetricsDetails();
          $(modalName).modal('show');
        }
     )      
    }
    
    else {        
      this.metricsService.getUserMetricDataTX(this.forDate, organization,colName ).subscribe(
      (userMetrics : any) => {
        this.userMetricUx = userMetrics;
        // userMetric = userMetrics;
        this.dataService.setLoading(false);
        //  this.userMetricsDetails = this.userMetricUx[0].txEvents[colName];
         this.userEvents = false;         
          // console.log('1 txevent metricsDetails tx events', this.userMetricUx);
         let metricsDetails = this.userMetricUx[0].events[colName];
          // console.log('2 txevent metricsDetails tx events',metricsDetails);
        if(metricsDetails){
            metricsDetails.forEach((detail) => {
              detail.activationDateFormatted = detail.activationDate != null ? moment(detail.activationDate).format("MM/DD/YYYY") : "";
              detail.lastLoginFormatted = detail.lastLogin != null ? moment(detail.lastLogin).format("MM/DD/YYYY") : "";
              this.userMetricsDetails.push(Object.assign({}, detail));
            });
          }

          modalName = '#userMetricsDetailModal'
          // // console.log('2 usermetricDetails tx events',this.userMetricsDetails);
          this.formatUserMetricsDetails();
          this.filterUserMetricsDetails();
          $(modalName).modal('show');
          }
        )
      
      // this.userMetricsDetails = userMetric.txEvents[colName];
      // this.userEvents = false;
    }   
  }

  openTotalsDetailModal(col: any, userEvent: boolean) {
    this.userMetricsDetails = [];
    this.detailModalTitle = "Total " + col.display;
    this.detailModalDateDisplay = "";

    if(userEvent){
      this.userMetrics.forEach((metric) => {
        let metricsDetails = metric.userEvents[col.name];
        if(metricsDetails){
          metricsDetails.forEach((detail) => {
            this.userMetricsDetails.push(Object.assign({}, detail));
          });
        }
      });
      this.userEvents = true;
    }
    else {
      this.userMetrics.forEach((metric) => {
        let metricsDetails = metric.txEvents[col.name];
        if(metricsDetails){
          metricsDetails.forEach((detail) => {
            this.userMetricsDetails.push(Object.assign({}, detail));
          });
        }
      });
      this.userEvents = false;
    }

    this.detailSearchTerm = "";
    this.showDetailSearchRow = false;

    this.formatUserMetricsDetails();

    this.filterUserMetricsDetails();

    $('#userMetricsDetailModal').modal('show');
  }

  formatUserMetricsDetails(){
    this.userMetricsDetails.forEach((metric) => {
      metric.auditEventDateFormatted = moment(metric.auditEventDate).format('HH:mm') + ' CST';
    });
  }

  toggleDetailSearchRow() {
    this.showDetailSearchRow = !this.showDetailSearchRow;
    this.detailSearchTerm = "";
    this.filterUserMetricsDetails();
  }

  filterUserMetricsDetails() {
    if(!this.detailSearchTerm){
      this.filteredUserMetricsDetails = this.userMetricsDetails;
    } else {
      this.filteredUserMetricsDetails = this.metricsDetailPipe.transform(this.userMetricsDetails, this.detailSearchTerm);
    }
  }

  closeDetail(modalName: string){
    $(modalName).modal('hide');
  }

  getSearchBarLength(){
    let colspan = 2;
    this.columnControl.userCols.forEach((col) => {
      if(col.checked) { colspan++; }
    });
    this.columnControl.txCols.forEach((col) => {
      if(col.checked) { colspan++; }
    });
    return colspan;
  }

  exportByUserToExcel(){
    let fromDate, toDate;

    if(this.periodSelected){
      fromDate = this.periodStartDate;
      toDate = this.periodEndDate;
    }
    else {
      fromDate = this.startDateDisplay;
      toDate = this.endDateDisplay;
    }

    let difference = moment(toDate).diff(moment(fromDate), 'months', true);
    if(difference > 3){
      $('#threeMonthExportLimitByCustomerModal').modal('show');
    }
    else {
      //toggle export
      // console.log("RUNNING EXPORT LOGIC");
      let fromDate,toDate, organization, orgName;

      if(this.periodSelected){
        fromDate = this.periodStartDate;
        toDate = this.periodEndDate;
      }
      else {
        fromDate = this.startDateDisplay;
        toDate = this.endDateDisplay;
      }

      if(this.customerControl.selectedCustomerType == 'single') {
        organization = this.customerControl.selectedSingleCustomer.id;
        orgName = this.customerControl.selectedSingleCustomer.name;
      }
      else {
        organization = orgName = this.customerControl.selectedCustomerType;
      }

      this.dataService.setLoading(true);
      this.metricsService.exportUserMetrics(fromDate, toDate, organization, orgName);
    }
  }

  closeExportModal(){
    $('#threeMonthExportLimitByCustomerModal').modal('hide');
  }

}
