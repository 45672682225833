import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {PortalService} from "../../shared/portal.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit{

  email: string;
  forgotPassword: boolean = true;
  passwordResetEmailSent: boolean = false;
  invalidEmail: boolean = false;
  emailError: boolean = false;
  passwordResetEmailSubscription: Subscription;
  passwordResetEmailSentSubscription: Subscription;

  constructor(private dataService: DataService,
              private portalService: PortalService) { }

  ngOnInit() {
    this.dataService.setForgotPassword(true);
    this.passwordResetEmailSentSubscription = this.dataService.passwordResetEmailSentUpdated.subscribe(
      (passwordResetEmailSent) => {
        this.passwordResetEmailSent = passwordResetEmailSent;
      }
    );
  }

  ngOnDestroy() {
    if (this.passwordResetEmailSentSubscription) this.passwordResetEmailSentSubscription.unsubscribe();
    // this.passwordResetEmailSubscription.unsubscribe();
  }

  sendResetEmail() {
    this.passwordResetEmailSubscription = this.portalService.sendResetEmail(this.email).subscribe(
      (resp) => {
        this.dataService.setPasswordResetEmailSent(true);
        this.invalidEmail = false;
      },
      (err) => {
        if(err.status == 400){
          this.invalidEmail = true;
          this.emailError = false;
        } else if(err.status == 500){
          this.invalidEmail = false;
          this.emailError = true;
        }
      }
    );
  }

  loginScreen() {
    this.dataService.setForgotPassword(false);
  }

}
