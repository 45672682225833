import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IUserAccount } from "../shared/entities/profile"
import { DataService } from "../shared/data.service";
import { IProduct, IZone } from '../shared/entities/product';
import { IContract } from '../shared/entities/contract';
import { IAccount } from '../shared/entities/account';
import * as moment from 'moment';
import { IProductSelectDefaultSettings } from '../shared/entities/productSelectDefaultSettings';
import { SelectProductService } from './select-product.service';
import { PortalService } from '../shared/portal.service';
import { Authority } from "../shared/const/authority";
import { FadeAnimation } from '../animations/fadeAnimation';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import { AccordionAnimation } from '../animations/accordionAnimation';
import {ForwardPriceForm} from "../markets/forward-price-form/forward-price.form";

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss'],
  animations: [FadeAnimation, FadeInAnimation, AccordionAnimation]
  // providers: [GenericPipe],
})
export class SelectProductComponent implements OnInit {
  @Input() selectProductContext: string;
  @Output() onProductSelected = new EventEmitter<any>();

  myAccount: IUserAccount;
  account: IAccount;
  availableContracts: IContract[];
  selectedContract: IContract = null;
  selectedProduct: IProduct = null;
  savedDefaultProduct: boolean = false;
  specificProductPCHBE: boolean = true; 
  isSuperUser: boolean = false;
  setAsDefault = false;
  firstTime = true;
  isValidGroupSelect = true;
  isValidContractSelect = true;
  value = 1; selectedOption: string;
  tempSelectedOption: string;
  tempSelCat: string;
  tempSelectedSite: string;
  tempSelectedContract: string;
  tempSelectedContrac: string;
  modify = false; apply = true; cancel = false;
  contracts: IContract[];
  currentSelectedContract: IContract = null;
  contractSelected = false;
  editingFilter = true;
  colors : string[] = ["#0A38D9","#471E97","#168677"];
  request: IProductSelectDefaultSettings = {};
  defaultResponse: IProductSelectDefaultSettings = {};
  noDataError = true;

  constructor(
    private service: SelectProductService,
    private portalService: PortalService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.firstTime = true;
    this.selectedOption = 'CONTRACT';
    this.myAccount = this.dataService.getAccountSource();
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    if (this.isSuperUser) {
      if (this.dataService.getContractsForAccount()) {
        this.contracts = this.dataService
          .getContractsForAccount()
          .sort(this.contractSorter)
          .map(this.mapRecentProducts)
          .filter(this.contractFilter);
        if (this.contracts.length > 0) {
          this.selectContract(this.contracts[0]);
          //this.noContractError = false;
        }
      }
      if (this.myAccount) {    
        this.portalService.spinBabySpin('defaultLoader');
  
        this.getDefault(); 
        //this.setSelected();

      }           
    }
    // this.myAccount = this.dataService.getAccountSource();
    // this.accountService.getContractsForAccount(this.myAccount.id, this.myAccount.customerAgentId).subscribe(
    //   (account) => {
    //     this.account = account;
    //     this.dataService.setAccountWithContractDataSource(account);
    //     this.availableContracts = this.account.contracts;
    //   } 
    // );
  }

  public getDate(dateString: string) {
    let date = new Date(dateString);
 
 
    return date;
  }

  getZoneDisplay(market, congestionZones: IZone[]) {
    if (!congestionZones || congestionZones.length === 0 || !market) {
      return '--';
    }
    if (congestionZones.length === 1) {
      return ForwardPriceForm.getHumanZoneName(market, congestionZones[0].congestionZone);
    }
    return congestionZones.map(zone => ForwardPriceForm.getHumanZoneName(market, zone.congestionZone) + ' ' + zone.percentage + '%').join(', ');
  }


  contractSorter = function mySorter(val1, val2) {
    if (val1.deliveryEndDate == val2.deliveryEndDate) {
      return val1.deliveryStartDate < val2.deliveryStartDate ? 1 : -1;
    } else {
      return val1.deliveryEndDate < val2.deliveryEndDate ? 1 : -1;
    }
  };

  contractFilter = (contract: IContract) => {
    return contract.commodity == true 
      && (contract.contractType !== 'Transition' || contract.status !== 'Terminated')
      && moment(contract.deliveryEndDate).isSameOrAfter(moment().subtract(6, 'months'))
      && contract.products.length > 0
      && contract.market == 'ERCOT';
  }

  mapRecentProducts = (contract: IContract) => {
    const newContract = {...contract} as IContract;
    newContract.products = contract.products.filter(
      product => moment(product.deliveryEndDate).isAfter(moment().subtract(6, 'months'))
      );
    if(this.selectProductContext != null){
      newContract.products = contract.products.filter(
        product => product.name === this.selectProductContext
        );
    }
    return newContract;
  }

  getDefault() {
    this.service.getDefault(this.myAccount.id).subscribe(
     (response) => {            
          this.defaultResponse = response;
          if (this.defaultResponse) {
            console.log('Got default contract: ' + this.defaultResponse.contractId);
            this.setSelected();
            this.applyChanges();
            //this.rloadDefaultGraph();
            this.firstTime = false;
          } 


      }, 
      err => {
        if (err.status == 404) {
          this.noDataError = true;
        }
      }        
    );
    //console.log('Leaving getDefault() default contract : ' + this.defaultResponse.contractId);
    //console.log('Leaving getDefault() selected contract: ' + this.selectedContract.contractNum);
    //console.log('Leaving getDefault() selected product : ' + this.selectedProduct.name);

  }

  applyDefault() {
    if(this.setAsDefault) { this.saveDefault();
     this.service.setDefault(this.request).subscribe(
      (response) => { }, 
      err => {
        if (err.status == 404) {
          this.noDataError = true;
        }
      }
    );
    }
  }

  saveDefault() {
    this.request = {};    
    this.request.userId = this.myAccount.userId;
    this.request.accountId = this.myAccount.id;
    this.request.settingType = "PCHBE";
    if (this.selectedOption == 'CONTRACT') {
       this.request.category = 'CONTRACT';
       this.request.productId = (this.selectedProduct ? this.selectedProduct.id : '');
       this.request.contractId = (this.selectedProduct ? this.selectedContract.contractNum : '');
    }
  }

  applyCancel() {
    //this.apply = false;
    this.modify = true;
    this.editingFilter = false;
  }

  applyChanges() {
    this.apply = false;
    this.modify = true;
    this.editingFilter = false;
    this.onProductSelected.emit(this.selectedProduct);
  }

  modifySelected() {
    this.apply = true;
    this.modify = false;
    this.setAsDefault = false;
    // this.tempHrequest = this.hrequest;
    // this.tempSelectedOption = this.selectedOption;
    // this.tempSelectedGroup = this.selectedGroup;
    // this.tempSelectedSite = this.selectedSite;
    // this.tempSelectedContract = this.selectedContract;
    // this.tempSelectedContrac = this.selectedContrac;
    this.editingFilter = true;
  }

  selectContract(contract: IContract) {
    this.selectedContract = contract;
    this.currentSelectedContract = contract;
    console.log('Contract contractNum: ' + contract.contractNum)
    console.log('id= ' + this.selectedContract.id);
    if (this.selectedContract.contractNum.length > 0) {
      this.contractSelected = true;
      this.isValidContractSelect = true;
      this.selectedContract = contract;
      if (contract.products.length && contract.products[0].siteCount === undefined) {
        this.portalService.populateProductDetails(contract);
      }
      this.selectProduct(contract.products[0]);

    }
    else {
      console.log("selectContract::This not a valid Contract")
      this.isValidContractSelect = false;
    }
    console.log('contractSelected = ' + this.contractSelected 
              + ', isValidContractSelect = ' + this.isValidContractSelect
              + ', selectedOption = ' + this.selectedOption);
  }

  //Sets the selectedContract and the selectedProduct to either 
  //the saved default or the first contract/product
  setSelected() {
    if(this.defaultResponse) {
      this.contracts.forEach((contract,index) => {
        if (this.defaultResponse.contractId == contract.contractNum) {
          this.selectContract(contract);
          this.portalService.populateProductDetails(contract);
          contract.products.forEach((product, index) => {
            if(this.defaultResponse.productId == product.id) {
              this.selectedProduct = product;
            }
          });
        }
      });
    }
    else {
      this.selectContract(this.contracts[0]);
      //this.selectedProduct = this.selectedContract.products[0];
    }
  }

  selectProduct(product: IProduct) {
     this.selectedProduct = product;
  }


}
