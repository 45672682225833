export interface IColumnData {
  title: string;
  key: string;
  disabled: boolean;
  selected: boolean;
}

export interface IColumn {
  title: string;
  key: string;
  disabled: boolean;
  selected: boolean;
  expanded: boolean;
  data: IColumnData[];
  partialSelected: boolean;
}

export interface IColumnRequest {
  id: string; // Account ID when all entities, entity ID when specific entity
  selectedSettings: string[];
}

export function dgenInitialColumns(): string[] {
  return [
    'GEN_INFO',
    'GEN_NAME',
    'SITE_ID',
    'SITE_NAME',
    'SERVICE_ADDRESS',
    'MARKET',
    'EMERGENCY_AVAILABLE',
    'GEN_MAIN_BREAKER',
    'ENGINE_MODE',
    'CURRENT_GEN',
    'FUEL_LEVEL',
    'FUEL_GAUGE',
    'FUEL_90',
    'RPM',
    'WATER_TEMP',
    'BATTERY_VOLTAGE',
    'OIL_PRESSURE'
  ];
}

export function dgenColumnDefaults() {
  return [{
    title: 'GENERATION INFORMATION',
    key: 'GEN_INFO',
    selected: true,
    expanded: true,
    partialSelected: true,
    data: [{
      title: 'Generator Name',
      key: 'GEN_NAME',
      disabled: true,
      selected: true
    }, {
      title: 'Site #',
      key: 'SITE_ID',
      disabled: false,
      selected: false
    }, {
      title: 'Site Name',
      key: 'SITE_NAME',
      disabled: false,
      selected: true
    }, {
      title: 'Service Address',
      key: 'SERVICE_ADDRESS',
      disabled: false,
      selected: true
    }, {
      title: 'Market',
      key: 'MARKET',
      disabled: false,
      selected: false
    }]
  }, {
    title: 'ENGINE RUNNING',
    key: 'EMERGENCY_AVAILABLE',
    selected: true
  }, {
    title: 'GENERATION MAIN BREAKER',
    key: 'GEN_MAIN_BREAKER',
    selected: true
  }, {
    title: 'ENGINE MODE',
    key: 'ENGINE_MODE',
    selected: true
  }, {
    title: 'CURRENT GENERATION (KW)',
    key: 'CURRENT_GEN',
    selected: true
  }, {
    title: 'FUEL LEVEL',
    key: 'FUEL_LEVEL',
    selected: true,
    expanded: true,
    partialSelected: false,
    data: [{
      title: 'Fuel Gauge',
      key: 'FUEL_GAUGE',
      disabled: true,
      selected: true
    }, {
      title: 'Quantity to reach 90%',
      key: 'FUEL_90',
      disabled: false,
      selected: true
    }]
  }, {
    title: 'RPM',
    key: 'RPM',
    selected: true
  }, {
    title: 'WATER TEMPERATURE',
    key: 'WATER_TEMP',
    selected: true
  }, {
    title: 'BATTERY VOLTAGE',
    key: 'BATTERY_VOLTAGE',
    selected: true
  }, {
    title: 'OIL PRESSURE',
    key: 'OIL_PRESSURE',
    selected: true
  }] as IColumn[];
}
