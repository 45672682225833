import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { IFourcpSite } from '../../../shared/entities/site';
import { I4cpSitePerformance, IDemandData } from '../../../shared/entities/fourcp';

declare var Highcharts: any;


@Component({
  selector: 'app-fourcp-site-performance-demand',
  templateUrl: './fourcp-site-performance-demand.component.html',
  styleUrls: ['./fourcp-site-performance-demand.component.scss', '../fourcp-site-performance.component.scss']
})
export class FourcpSitePerformanceDemandComponent implements OnInit {

  @Input()
  year: number;

  @Input()
  selectedSite: IFourcpSite | IFourcpSite;

  @Input()
  sitePerformance: I4cpSitePerformance | I4cpSitePerformance;

  chartData: IDemandData[];
  points: any;
  yAxisMin: number;
  yAxisMax: number;
  xAxisStart: number;
  xAxisEnd: number;
  test = 'test';
  peaks: any = [{}, {}, {}, {}];
  colors: any = ['blue-bar', 'purple-bar', 'yellow-bar', 'pink-bar'];
  kwWarning = false;

  constructor() { }

  ngOnInit() {
    if (this.sitePerformance) {
      this.loadPeaks();
      this.loadGraphData();
    }
  }

  ngOnChanges(changes) {
    if (changes['sitePerformance'] && this.sitePerformance) {
      this.loadPeaks();
      this.loadGraphData();
    }
  }

  loadPeaks() {
    this.peaks = [];
    for (const month of this.sitePerformance.months) {
      const peak = {
        monthDisplay: moment(month.intervalPeriod).format('MMMM') + ' Peak',
        dayDisplay: moment(month.intervalPeriod).format('MM/DD/YYYY')
      };
      this.peaks.push(peak);
    }
  }

  loadGraphData() {
    this.chartData = this.sitePerformance.usageResponse.usage;
    this.extractData();
    this.createUsageChart();
  }

  extractData() {
    if (this.chartData) {
      this.yAxisMin = Number.MAX_SAFE_INTEGER;
      this.yAxisMax = 0;
      this.xAxisStart = moment('11:00', 'HH:mm').toDate().getTime();
      this.xAxisEnd = moment('19:00', 'HH:mm').toDate().getTime();
      this.points = {
        months: []
      };
      let point = {};
      for (let month = 0; month < this.chartData.length; month++) {
        this.points.months[month] = [];
        const monthlyPeak = this.findPeak(this.chartData[month].date);
        for (let i = 0; i < this.chartData[month].data.length; i++) {
          let isPeak = false;
          if (monthlyPeak == this.chartData[month].data[i].periodEnding) {
            isPeak = true;
          }
          point = {
            x: moment(this.chartData[month].data[i].periodEnding, 'HH:mm').toDate().getTime(),
            y: this.chartData[month].data[i].value,
            marker: {
              symbol: 'circle',
              enabled: isPeak
            }
          };
          if (moment(this.chartData[month].data[i].periodEnding, 'HH:mm').toDate().getTime() >= this.xAxisStart && moment(this.chartData[month].data[i].periodEnding, 'HH:mm').toDate().getTime() <= this.xAxisEnd) {
            this.points.months[month].push(point);
          }
          if (this.chartData[month].data[i].value > this.yAxisMax) {
            this.yAxisMax = this.chartData[month].data[i].value;
          }
          if (this.chartData[month].data[i].value < this.yAxisMin) {
            this.yAxisMin = this.chartData[month].data[i].value;
          }
        }
      }

      this.kwWarning = this.sitePerformance.unitType.toLowerCase() != 'kw';
      // console.log("Points0: ", this.pointsToString(this.points.months[0]));
      // console.log("Points1: ", this.pointsToString(this.points.months[1]));
      // console.log("Points2: ", this.pointsToString(this.points.months[2]));
      // console.log("Points3: ", this.pointsToString(this.points.months[3]));
      //
      // console.log("ChartData: ", this.chartData);
    }
  }

  // pointsToString(points){
  //   let output = '';
  //   for(let point of points){
  //     output += "{" +
  //       "x: " + point.x +
  //       ", y: " + point.y +
  //       ", marker: { symbol: 'circle', enabled: " + point.marker.enabled + "}" +
  //       "},";
  //   }
  //   return output;
  // }

  findPeak(date) {
    const dateText = moment(date).format('MM/YY');
    for (const month of this.sitePerformance.months) {
      const monthText = moment(month.intervalPeriod).format('MM/YY');
      if (dateText == monthText) {
        return moment(month.intervalPeriod).format('HH:mm');
      }
    }
  }


  createUsageChart() {
    if (this.points) {
      // Highcharts.setOptions({
      //   colors: ['#00AEEF', '#333092', '#FBB034', '#EC008C']
      // });

      Highcharts.chart('site-performance-usage-chart', {
        title: {
          text: ''
        },
        chart: {
          height: 300
          // styledMode: true
        },
        // colors: ['#00AEEF', '#333092', '#FBB034', '#EC008C'],

        // plotOptions: {
        //   series: {
        //     colorByPoint: true
        //   }
        // },
        exporting: { enabled: false },
        yAxis: {
          max: this.yAxisMax,
          min: this.yAxisMin,
          title: {
            text: 'ENERGY DEMAND (KW)',
            style: { 'font-size:': 'bold' }
          }
        },
        xAxis: {
          type: 'datetime',
          plotBands: [{
            from: this.xAxisStart,
            to: this.xAxisEnd
          }],
          title: {
            text: 'TIME PERIOD ENDING (CST)',
            style: { 'font-size:': 'bold' }
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            if (this.point.marker.enabled) {
              return '<b>DEMAND: </b>' + this.y + ' KW<br>' +
                '<b>PEAK TIME:</b> ' + moment(this.x).subtract(15, 'minutes').format('HH:mm') + '-' + moment(this.x).format('HH:mm') + ' CST';
            }
            return '<b>DEMAND: </b>' + this.y + ' KW<br>' +
              '<b>TIME:</b> ' + moment(this.x).subtract(15, 'minutes').format('HH:mm') + '-' + moment(this.x).format('HH:mm') + ' CST';
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'horizontal'
        },
        series: [
          {
            // name: moment(this.chartData[1].date, 'MM-DD-YYYY').format('MM/DD/YYYY'),
            data: this.points.months[0],
            // color: '#00AEEF',
            showInLegend: false
          },
          {
            // name: moment(this.chartData[1].date, 'MM-DD-YYYY').format('MM/DD/YYYY'),
            data: this.points.months[1],
            // color: '#333092',
            showInLegend: false
          },
          {
            // name: moment(this.chartData[2].date, 'MM-DD-YYYY').format('MM/DD/YYYY'),
            data: this.points.months[2],
            // color: '#FBB034',
            showInLegend: false
          },
          {
            // name: moment(this.chartData[3].date, 'MM-DD-YYYY').format('MM/DD/YYYY'),
            data: this.points.months[3],
            // color: '#EC008C',
            showInLegend: false
          }
        ]
      });
    }
  }

}
