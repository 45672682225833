import 'rxjs/add/operator/do';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import {Observable} from "rxjs";
import {DataService} from "../shared/data.service";
import {Injectable} from "@angular/core";
import {IError} from "../shared/entities/error";
import {PortalService} from "../shared/portal.service";
import * as moment from 'moment';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private dataService: DataService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//    let newHeaders = request.headers;
//    newHeaders = newHeaders.append('Access-Control-Allow-Origin', 'https://stg1-manage.nrg.com');
//    const newRequest = request.clone({headers: newHeaders});
//    return next.handle(newRequest).do((event: HttpEvent<any>) => {
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // If needed
      }
      this.dataService.triggerRequest();
    }, err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401 || err.status == 403) {
          // Timed out
          this.dataService.setTimedOut(true);
        } else if (err.status == 409 || err.status == 404 || err.status == 400){
          // Suppress error
        } else if (err.status == 200) {
          // Overzealous error handling
        } else if (err.status == 422) {
          // Used for trying to change email to existing ldap
          console.log("ERROR: ", err);
          let error: IError = {
            title: 'Change to email error',
            message: 'Unable to modify email address, the new email address is already in use on another account.' +
            '(' + err.status + ':' + moment().format('MM/DD/YYTHH:mm:ss') + ')'
          };
          this.dataService.addError(error);

        } else {
          console.log("ERROR: ", err);
          let error: IError = {
            title: 'An error has occurred',
            message: 'The site is currently experiencing technical difficulties. For immediate assistance, please contact your Account Representative. ' +
            '(' + err.status + ':' + moment().format('MM/DD/YYTHH:mm:ss') + ')'
          };
          this.dataService.addError(error);
        }
      }
    });
  }
}
