import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {ForwardPriceForm} from "../forward-price-form/forward-price.form";
import {DataService} from "../../shared/data.service";
import {MarketsService} from "../markets.service";
import {PortalService} from "../../shared/portal.service";
import { IReportSettings } from '../../shared/entities/markets';

@Component({
  selector: 'app-forward-pricing-custom',
  templateUrl: './forward-pricing-custom.component.html',
  styleUrls: [
    '../../shared/styles/reports.scss',
    './forward-pricing-custom.component.scss',
    '../forward-pricing-renewal/forward-pricing-renewal.component.scss',
  ]
})
export class ForwardPricingCustomComponent implements OnInit {
  @Output() viewReport: EventEmitter<ForwardPriceForm> = new EventEmitter();

  markets: any[];
  dateModified: Date;
  displayTermsAndChart: boolean = false;
  chartContainerId: string = 'chart-custom';
  marketSelected: boolean = false;
  displayPriceType: boolean = false;
  displayPeakType: boolean = false;
  displaySettlementType: boolean = false;
  displaySettlementZone:boolean = false;
  displayContinueButton:boolean = false;

  // Form Data
  forwardPriceForm: ForwardPriceForm = new ForwardPriceForm(this.portalService, this.marketsService, this.dataService);
  


  constructor(private dataService: DataService,
              private marketsService: MarketsService,
              private portalService: PortalService) { }

  ngOnInit() {
    this.forwardPriceForm.ngOnInit();
    this.forwardPriceForm.accountId = this.dataService.getAccountSource().id;
    this.forwardPriceForm.userId = this.dataService.getUserSource().email;
    this.forwardPriceForm.custom = true;
    this.dataService.setOutputPage(false);

    // COMMENT OUT FOR MOCK DATA, UNCOMMENT FOR SERVICE CALL
    // this.markets = this.dataService.getMarketArrayForAccountSource();
    this.markets = this.forwardPriceForm.markets;
    this.forwardPriceForm.deselectAllTerms();
  }

  updateSettlementZone(zone, refreshButton) {
    this.forwardPriceForm.selectZone(zone);
    if(this.displayTermsAndChart) this.forwardPriceForm.refreshButtonEnable(refreshButton);
//    this.showTermsAndChart = true;
  }

  showTermsAndChart() {
    this.displayTermsAndChart = true;
    this.displayContinueButton = false;
    this.forwardPriceForm.showChart(this.chartContainerId);
  }

  selectMarket(market: any) {
    this.marketSelected = true;
    this.forwardPriceForm.selectMarket(market);
    if (market.value === 'NYMEX') {
      this.displayPriceType = false;
      this.displayPeakType = false;
      this.displaySettlementType = false;
      this.displaySettlementZone = false;
      this.displayTermsAndChart = false;
      this.showTermsAndChart();
      this.forwardPriceForm.refreshChart(this.chartContainerId);
    } else {
      this.displayContinueButton = true;
      this.displayTermsAndChart = false;
      this.displayPriceType = true;
      this.displayPeakType = true;
      this.displaySettlementType = true;
      this.displaySettlementZone = true;
    }
  }

  createReport() {
    this.viewReport.emit(this.forwardPriceForm);
  }
}
