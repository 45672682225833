import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { SelfRegistrationValidateAccountComponent } from './validate-account/sr-validate-account.component';
import { SelfRegistrationUserInfoComponent } from './user-info/sr-user-info.component';
import { SelfRegistrationAccessComponent } from './access/sr-access.component';
import { DataService } from "../shared/data.service";
import { current } from 'codelyzer/util/syntaxKind';
import { SelfRegisterService } from './self-register.service';
import { IAccountLinkRequest } from './entities/acccountLinkRequest';
import { IAccountLinkResponse } from './entities/accountLinkResponse';
import { IEmailCheckResponse } from './entities/EmailResponse';
import { IEmailCheckRequest } from './entities/EmailRequest';
import { ISelfRegisterRequest } from './entities/SelfRegisterRequest';
import { ISelfRegisterResponse } from './entities/SelfRegisterResponse';
import { PreAuthHeaderComponent } from '../pre-auth-nav/pre-auth-header/pre-auth-header.component';
import { PreAuthFooterComponent } from '../pre-auth-nav/pre-auth-footer/pre-auth-footer.component';
import { ProgressStep } from '../shared/entities/progressBarInput';

@Component({
  selector: 'app-self-register',
  templateUrl: './self-register.component.html',
  styleUrls: ['./self-register.component.scss']
})
export class SelfRegisterComponent implements OnInit {

  // Step for Progress Bar, also used to dynamically render child components (validate-account, user-info, access)
  currentStep: number = 1;

  // Variables for Progress Bar
  progressSteps: ProgressStep[] = [
    { label: 'Validate', index: 1 },
    { label: 'User Info', index: 2 },
    { label: 'Access', index: 3 }
  ];

  showSpinner : boolean = false;
  responseSuccess : boolean;

  // Variables for Validate
  showBillingZip: Boolean;
  // Output from child components to disable continue button until ready for next step
  isContinueButtonDisabled: boolean = true;

  // Error Boolean for Self-Registration Flow
  isFormError: boolean = false;

  // Account Linking from sr-validate-account
  attemptsRemaining: number;
  attemptFailedBool: boolean;
  isLargeAccountOrThirdParty: boolean;
  accountLinkRequest: IAccountLinkRequest;
  accountLinkResponse: IAccountLinkResponse;
  linkedAccountName: string;
  linkedAccountId: string;

  // Email to check in user-info
  emailToCheck: string;

  // Variables for user-info
  userInfoShowEmailInput: boolean;
  userInfoShowInitialMessage: boolean;
  userInfoShowEmailInUseForOtherBrandError: boolean = false;
  userInfoShowEmailInUseForThisAccount: boolean = false;
  userInfoShowEmailInUseForAnotherAccount: boolean;
  userInfoShowUserInfoForm: boolean;

  // Variables for Access Level & Registration
  accessLevel:string;
  selfRegistrationReq:ISelfRegisterRequest;
  selfRegistrationCase:number;

  // Conflict Error Message
  showConflictError: boolean = false;

  constructor(private dataService: DataService, private selfRegisterService: SelfRegisterService) { }

  ngOnInit() {
    document.cookie = "clickedselfregister=0";
    document.cookie = "leftselfregister=1";
    this.selfRegisterService.getAttemptsRemaining().subscribe((attempts : any) => {
      this.attemptsRemaining = attempts;
      if(this.attemptsRemaining < 1) {
        this.attemptFailedBool = true;
      }
    });
  }

  ngOnDestroy(){
    document.cookie = "leftselfregister=1";
    window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
  }

  getOriginUrl() : string{
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com";
    const stg1 = "https://stg1-manage.nrg.com";
    const test1 = "https://test1-manage.nrg.com";
    const pd = "https://manage.nrg.com";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
      return dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return pd;
    }
    else {
      return local;
    }
  }

  loginScreen() {
    const local = "http://localhost:8880/login.html"
    const dev1 = "https://dev1-manage.nrg.com/myaccount/login.html";
    const stg1 = "https://stg1-manage.nrg.com/myaccount/login.html";
    const test1 = "https://test1-manage.nrg.com/myaccount/login.html";
    const pd = "https://manage.nrg.com/myaccount/login.html";
    let loginUrl;
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
       loginUrl = dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      loginUrl = stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      loginUrl = test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      loginUrl = pd;
    }
    else {
      loginUrl = local;
    }
    window.location.href = loginUrl;
  }


  // Enables to the user to hit continue to progress through the self-registration process. Simple logic for now but will expand.
  continueEnable(enableBoolean: boolean) {
    this.isContinueButtonDisabled = !enableBoolean;
  }

  continue() {
    if (this.currentStep == 1) {
      if (this.showBillingZip) {
        this.showSpinner = true;
        this.selfRegisterService.linkAccount(this.accountLinkRequest).subscribe((response: IAccountLinkResponse) => {
          if (response) {
            if(response.error){
              this.responseSuccess = false;
              this.showSpinner = false;
              if(response.error == "NO_ACCOUNT_FOUND"){
                this.attemptsRemaining = response.attemptsRemaining;
                this.attemptFailedBool = true;
                return;
              }
              else if(response.error == "LARGE_ACCOUNT_OR_THIRD_PARTY"){
                this.isLargeAccountOrThirdParty = true;
                return;
              }
            }
            if (response.accountName) {
              this.responseSuccess = true;
              this.linkedAccountName = response.accountName;
              this.linkedAccountId = response.accountId;
              this.isContinueButtonDisabled = true;
              this.currentStep = 2;
              this.showSpinner = false;
            }
          }
          else {
            return;
          }
        },
          err => {
            if (err.status == 404) {
            }
          }
        );
      } else {
        this.isContinueButtonDisabled = true;
        this.showBillingZip = true;
      }
    }
    else if (this.currentStep == 2) {
      this.userInfoShowInitialMessage = false;
      this.userInfoShowEmailInput = false;
      this.userInfoShowEmailInUseForOtherBrandError = false;
      if (!this.userInfoShowUserInfoForm) {
        let req: IEmailCheckRequest = {
          email: this.emailToCheck,
          linkedAccountId: this.linkedAccountId
        }
        if(this.selfRegistrationCase == 2) {
          this.isContinueButtonDisabled = true;
          this.userInfoShowEmailInUseForAnotherAccount = false;
          this.currentStep += 1;
          return;
        }
        this.selfRegisterService.checkEmail(req).subscribe((response: IEmailCheckResponse) => {
          if (response) {
            if (response.caseId == 1) { // Happy path and continue with user info form
              this.userInfoShowEmailInput = true;
              this.userInfoShowUserInfoForm = true;
              this.isContinueButtonDisabled = true;
              this.selfRegistrationCase = 1;
            }
            if (response.caseId == 2) { // Email in use for another account
              this.userInfoShowEmailInput = false;
              this.userInfoShowEmailInUseForAnotherAccount = true;
              this.selfRegistrationCase = 2;
            }
            if (response.caseId == 3) { // Email in use for this account
              this.userInfoShowEmailInput = false;
              this.userInfoShowEmailInUseForThisAccount = true;
              this.selfRegistrationCase = 3;
            }
            if (response.caseId == 4) { // Email in use for another brand (Reliant Home Accounts)
              this.userInfoShowEmailInUseForOtherBrandError = true;
              this.userInfoShowEmailInput = true;
              this.selfRegistrationCase = 4;
            }
          }
          else {
            return;
          }
        },
          err => {
            if (err.status == 404) {
            }
          }
        );
      } else {
        this.isContinueButtonDisabled = true;
        this.currentStep += 1;
      }
    }
    else if (this.currentStep == 3) {
      this.selfRegistrationReq.accountId = this.linkedAccountId;
      this.selfRegistrationReq.accountName = this.linkedAccountName;
      this.selfRegistrationReq.selfRegistrationCase = this.selfRegistrationCase;
      this.selfRegisterService.createAccount(this.selfRegistrationReq).subscribe((response: ISelfRegisterResponse) => {
        if (response){
          if(response.createdUserSuccess==true){
            this.currentStep += 1;
          }
          else if(response.createdUserSuccess == false && response.status== 'USER_CONFLICT_ERROR'){
            this.showConflictError = true;
          }
        }
      })
    }
  }

  accountLinkReqHandler($event) {
    this.accountLinkRequest = $event;
  }

  emailToCheckHandler($event) {
    this.emailToCheck = $event;
  }

  selfRegistrationRequestHandler($event) {
    this.selfRegistrationReq = $event;
  }

  adminAccessHandler($event) {
    this.selfRegistrationReq.giveAdminAccess = $event;
  }

  changeEmail(){
    this.currentStep = 2
    this.selfRegistrationCase = null;
    this.userInfoShowEmailInUseForAnotherAccount = false;
    this.userInfoShowInitialMessage = true;
    this.userInfoShowEmailInput = true;
  }


}
