export const environment = {
  production: true,

  portalHealthCheckUrl: './api/healthCheck',
  dalHealthCheckUrl: './api/healthCheck/dal',
  getAllThirdPartyAccountsUrl: './private/api/getAllThirdPartyAgents',
  accountUrl: './private/api/accounts',
  selectAccountUrl: './private/api/accounts/{accountId}/select',
  selectAccountMessageUrl: './private/api/accounts/{accountId}/message?accountName={accountName}',
  getScsFlagUrl: './private/api/accounts/{accountId}/scsflag',
  getAccountUrl: './private/api/accounts/{accountId}',
  loginUrl: './private/api/authentication',
  setLocalSessionUserId: './private/api/setLocalSessionUserId',
  logoutUrl: './api/bye',
  removeSessionLogoutUrl: './login.html?signout=true',
  acknowledgeUrl: './private/api/acknowledge',
  amendmentsAcknowledgeUrl: './private/api/acknowledge',
  authenticateUrl: './api/authenticate',
  accountsForUserUrl: './private/api/users/accounts',
  getUserPermissionsForAccountUrl: './private/api/users/accounts/{accountId}',
  resetPasswordInitUrl: './api/account/reset_password/init',
  resetPasswordFinishUrl: './api/account/reset_password/finish',
  createPasswordUrl: './api/account/create_password/finish',
  paymentConfirmationUrl: './private/api/account/payment/confirmation',
  autopaymentCancelConfirmationUrl: './private/api/payments/autopay-cancellation-confirmation',
  autopaymentConfirmationUrl: './private/api/payments/autopay-enrollment-confirmation',
  quickPayConfirmationUrl: '/public/api/payments/qp-confirmation',
  permissionUrl: './private/api/permission',
  userUrl: './private/api/account',
  siteUrl: './private/api/accounts',
  contactUrl: './private/api/accounts/{accountId}/contacts?thirdParty={thirdParty}',
  companyContactUrl: './private/api/accounts/{accountId}/corpcontactsCC',
  usefulContactUrl: './private/api/accounts/{accountId}/usefulcontacts',
  getContractsForAccount: './private/api/accounts/{accountId}/contracts',
  getSiteCountsForAccountUrl: './private/api/accounts/{accountId}/sitecounts',
  accountExcelUrl: './private/api/accounts/{accountId}/contracts/{contractId}/export',
  productDetailsUrl: './private/api/accounts/{accountId}/contracts/{contractId}/productdetails',
  amendmentDownloadUrl: './private/api/accounts/{accountId}/addremovesites',
  customerSizeUrl: '',
  comvergeAccessUrl: './private/api/accounts/{accountId}/comvergeaccess',
  getUserPricingTcStatus: './private/api/getUserPricingTcStatus',
  getUserAccountsUrl: './private/api/users/accounts?q={searchCriteria}',

  comvergeAccessUrlT: 'https://nrg.allegiancetech.com/surveys/HM6W3R/Scripts/clientExtensibility.js',

  //Position Report
  getActualAndForecastUrl: './private/api/cpr/{accountId}/getActualAndForecast',

  exportgetActualAndForecastUrl: './private/api/cpr/{accountId}/getActualAndForecastexport',

  getBlockTransactionsUrl: './private/api/cpr/getBlockTransactions',
  getBMQContractUrl: './private/api/accounts/{accountId}/bmqcontractdata',

  //Renewables
  getCertificatesUrl: './private/api/renewables/{accountId}/certificates',
  getSitesUrl: './private/api/renewables/{accountId}/sites',
  getSiteGenerationUrl: './private/api/renewables/{accountId}/site-generation-history',
  getEquivalenciesUrl: './private/api/renewables/{accountId}/equivalencies',
  getSiteYearsUrl: './private/api/renewables/{accountId}/years',
  exportRenewablesUrl: './private/api/renewables/{accountId}/export',
  getMaintenanceUrl: './private/api/renewables/{accountId}/notes',
  deleteMaintenanceUrl: './private/api/renewables/{accountId}/note/delete?noteId={maintNoteId}',
  getRenewablesFlagUrl: './private/api/accounts/{accountId}/hasRenewables',
  downloadCertificatesUrl: './private/api/renewables/{accountId}/certificates/{criNumber}/{year}?resolution={resolution}',
  downloadFactSheetUrl: './private/api/renewables/{accountId}/sites/{siteId}/fact-sheet',
  getAccountFlagUrl: './private/api/accounts/{accountId}/accountFlags',

  // Site Groups
  getUserSiteGroupsUrl: './private/api/accounts/{accountId}/sitegroups',
  getSystemSiteGroupsUrl: './private/api/accounts/{accountId}/systemgroups',
  getSitesForUserGroupUrl: './private/api/accounts/{accountId}/sitegroups/{groupId}',
  getSitesForSystemGroupUrl: './private/api/accounts/{accountId}/systemgroups/{groupName}',
  getSitesForSystemGroupLessReliabilityUrl: './private/api/accounts/{accountId}/systemgroups/{groupName}/amendments',
  createOrUpdateSiteGroupUrl: './private/api/accounts/{accountId}/sitegroups/{groupId}',
  updateSiteCategoryUrl: './private/api/accounts/{accountId}/sitegroups/{groupId}/site/{siteId}',
  deleteSiteGroupUrl: './private/api/accounts/{accountId}/sitegroups/{groupId}/delete',
  getCategoriesForUserGroupUrl: './private/api/accounts/{accountId}/sitegroups/{groupId}/categories',
  getCategoriesForSystemGroupUrl: './private/api/accounts/{accountId}/systemgroups/{groupName}/categories',
  getSitesByCategoryUrl: './private/api/accounts/{accountId}/sitesByCategory?category={category}',
  //getFourcpSiteUrl: './private/api/accounts/{accountId}/sitegroups/',

  //manage profile
  getManageProfileUrl: './private/api/users/profile',
  getProfileAlertsUrl: './private/api/users/profile/alerts',
  updateProfileUrl: './private/api/users/profile',
  getUserImageUrl: './private/api/users/profile/image',
  setUserImageUrl: './private/api/users/profile/image',
  resetImageUrl: './private/api/users/profile/resetimage',
  setFirstLoginUrl: './private/api/users/profile/firstLogin',
  retrieveProfileURL: './private/api/user?emailId={email}',
 // retrieveProfileURL: './assets/api/profileData.json',
 checkForExistingLDAPUserUrl: './private/api/users/checkFor?email={email}',

  //Payment History
  getPaymentHistoryUrl: './private/api/accounts/{accountId}/paymentHistory',
  exportPaymentHistoryUrl: './private/api/accounts/{accountId}/paymentHistory/export',

  //Broker Confirms
  getBrokerConfirmUrl: './private/api/accounts/{accountId}/brokerConfirm',
  exportBrokerConfirmUrl: './private/api/accounts/{accountId}/brokerConfirm/export',

  //Enrollment Report
  getEnrollmentsReportUrl: './private/api/accounts/{accountId}/brokerEnrollment',
  exportEnrollmentsReportUrl: './private/api/accounts/{accountId}/brokerEnrollment/export',

  // Broker Drops
  getBrokerDropUrl: './private/api/accounts/{accountId}/brokerDrop',
  exportBrokerDropUrl: './private/api/accounts/{accountId}/brokerDrop/export',

  // Broker Customer List
  exportBrokerCustomerListUrl: './private/api/accounts/{accountId}/brokerCustomerList/export',

  // Payments
  balanceForAccountUrl: './private/api/accounts/{accountId}/displayBalanceDue',
  balanceDatesForAccountUrl: './private/api/accounts/{accountId}/displayBalanceDueDates',
  balancesUrl: './private/api/accounts/{accountId}/payments/balances',
  savePaymentMethodUrl: './private/api/users/accounts/{accountId}/paymentSettings',
  savePaymentNicknameUrl: './private/api/users/accounts/{accountId}/paymentSettings/nickname',
  pciFormUrl: 'https://dev1-pay.manage.nrg.com/payUsingBankAccount',
  pciCCFormUrl: 'https://dev1-pay.manage.nrg.com/payUsingCC',
  getPaymentMethodsUrl: './private/api/users/accounts/{accountId}/paymentSettings',
  pciPaymentMethodsUrl: './private/api/users/accounts/{accountId}/paymentSettings',
  paymentMethodsUrl: './private/api/users/accounts/{accountId}/paymentSettings',
  deletePaymentMethodsUrl: './private/api/users/accounts/{accountId}/paymentSettings/delete',
  submitPaymentUrl: './private/api/users/accounts/{accountId}/submitPayments',
  submitPaymentCCUrl: './private/api/users/accounts/{accountId}/submitCardPayments',
  getQuickPayDetailsUrl: './api/payments/getQuickPayDetails',

  // AutoPay
  autopayForAccountUrl: './private/api/accounts/{accountId}/payments/autopay',
  autopayEnroll: './private/api/accounts/{accountId}/payments/autopayEnroll',
  autopayCancel: './private/api/accounts/{accountId}/payments/autopayCancel',


  //BillingDelivery
  billingDeliveryUrl: './private/api/accounts/{accountId}/payments/eBill',
  exportBillingDeliveryUrl: './private/api/accounts/{accountId}/payments/eBill/export',
  billingDeliveryUpdateUrl: './private/api/accounts/{accountId}/payments/eBillUpdate',
  billingDeliveryHistoryUrl: './private/api/accounts/{accountId}/payments/eBillHistory',
  exportBillingDeliveryHistoryUrl: './private/api/accounts/{accountId}/payments/eBillHistory/export',

  //manage Users component
  getUserPermissionsUrl: './private/api/users/userprofileandpermissions/{accountId}',
  createUserPermissionsUrl: './private/api/users/userprofileandpermissions',
  updateUserPermissionsUrl: './private/api/users/userprofileandpermissions/{accountId}/permissions',
  deleteUserPermissionsUrl: './private/api/users/{userId}/userprofileandpermissions/{accountId}',

  // Billing Report
  generateBillingReportUrl: './private/api/billing/{accountId}/report',
  generateTpBillingReportUrl: './private/api/billing/{accountId}/thirdpartyreport',
  getBillingReportSummaryViewUrl: './private/api/billing/{accountId}/report/categories',
  getBillingReportSearchViewUrl: './private/api/billing/{accountId}/report/search',
  getBillingReportDetailViewUrl: './private/api/billing/{accountId}/report/category/details',
  getTpBillingSummaryReportsUrl: './private/api/billing/{accountId}/thirdparty/reportsettings',
  createOrUpdateTpBillingReportUrl: './private/api/billing/{accountId}/thirdparty/reportsettings/{reportId}',
  deleteTpBillingReportUrl: './private/api/billing/{accountId}/thirdparty/reportsettings/{reportId}/delete',
  downloadInvoiceUrl: './private/api/billing/{accountId}/downloadInvoice/{invoice}',
  openMobileInvoiceUrl: './private/api/billing/{accountId}/downloadInvoice/{invoice}/mobile',
  downloadMultipleInvoicesUrl: './private/api/billing/{accountId}/downloadInvoices',
  downloadMultipleInvoicesAsyncUrl: './private/api/billing/{accountId}/downloadInvoicesAsync',
  excelBillingReportUrl: './private/api/billing/{accountId}/report/export',
  excelTpBillingReportUrl: './private/api/billing/{accountId}/thirdpartyreport/export',

  // Report Settings
  getReportSettingsUrl: './private/api/reportsettings/{reportType}/{accountId}',
  createOrUpdateReportSettingsUrl: './private/api/{accountId}/reportsettings/{reportId}',
  deleteReportSettingsUrl: './private/api/reportsettings/{accountId}/{reportType}/{reportId}/{publicFlag}/delete',

  // Billing Comparison Report (Charts)
  getBillingComparisonChartsUrl: './private/api/billingcomp/{accountId}/report',
  getTpBillingComparisonChartsUrl: './private/api/billingcomp/{accountId}/thirdparty/report',
  getBillingComparisonReportsUrl: './private/api/billingcomp/{accountId}/reportsettings',
  getTpBillingComparisonReportsUrl: './private/api/billingcomp/{accountId}/thirdparty/reportsettings',
  getBillingComparisonYearsUrl: './private/api/billingcomp/{accountId}/billingYears',
  getTpBillingComparisonYearsUrl: './private/api/billingcomp/{accountId}/thirdparty/billingYears',
  createOrUpdateBillingComparisonReportUrl: './private/api/billingcomp/{accountId}/reportsettings/{reportId}',
  createOrUpdateTpBillingComparisonReportUrl: './private/api/billingcomp/{accountId}/thirdparty/reportsettings/{reportId}',
  deleteBillingComparisonReportUrl: './private/api/billingcomp/{accountId}/reportsettings/{reportId}/delete',
  deleteTpBillingComparisonReportUrl: './private/api/billingcomp/{accountId}/thirdparty/reportsettings/{reportId}/delete',

  //Billing Comparison Export
  getThirdPartyBillingComparisonReportExportUrl: "./private/api/billingcomp/{accountId}/thirdparty/report/export",

  // Usage Report
  generateUsageReportUrl: './private/api/usage/{accountId}/report',
  getUsageReportNamesUrl: './private/api/usage/{accountId}/reportsettings/names',
  getUsageReportSettingsUrl: './private/api/usage/{accountId}/reportsettings',
  createUsageNameAndSettingUrl: './private/api/usage/{accountId}/reportsettings/-1',
  updateUsageNameAndSettingUrl: './private/api/usage/{accountId}/reportsettings/{usageReportId}',
  deleteUsageReportUrl: './private/api/usage/{accountId}/reportsettings/{usageReportId}/delete',
  excelUsageReportUrl: './private/api/usage/{accountId}/report/export',
  frequencyTypeCountUrl: './private/api/accounts/{accountId}/sitecounts',
  realTimeDataReportUrl: './private/api/accounts/{accountId}/comverge',

  // Interval Usage Report
  getIntervalSitesUrl: './private/api/usage/{accountId}/sites',
  exportIntervalUsageUrl: './private/api/usage/{accountId}/intervalusagereport',



 getShadowMetersUrl: './private/api/accounts/{accountId}/hasShadowMeters',
//  getMarketUsageUrl: './private/api/marketData/SPP/{source}/{startDate}/{endDate}',
// getMarketUsageUrl: './api/marketData/SPP/{source}/{startDate}/{endDate}',
 getMarketUsageUrl: './api/marketData/intervalSPP/{source}/{startDate}/{endDate}',


 getShadowSites: './private/api/accounts/{accountId}/shadowsites',
 getShadowGroups:'./private/api/accounts/{accountId}/shadowsitegroups',
 getShadowCategory: './private/api/accounts/{accountId}/sitegroups/{groupId}/shadowcategories',
 getShadowContracts:'./private/api/accounts/{accountId}/shadowcontracts',
 getShadowDemandUrl:'./private/api/usage/{accountId}/shadowusagegraph',
 getActualAndShadowDemandUrl:'./private/api/usage/{accountId}/actualandshadowusagegraph',
 setRUDefaultUrl:'./private/api/usage/{accountId}/shadowgraphsettings',
 exportShadowMeterUrl: './private/api/usage/{accountId}/shadowmeterexport',

 getGenerationSites: './private/api/dgen/{accountId}/sites',
 getGenerationSitesStatus: './private/api/dgen/{accountId}/generation-statuses',
 getGenerationUsage: './private/api/dgen/{accountId}/generation-history',

 // COLUMN SELECTOR
 allEntityColumnSettings: './private/api/{entity}/{accountId}/settings',
 singleEntityColumnSettings: './private/api/{entity}/{accountId}/settings/{entityId}',

  // Usage Comparison Report (Charts)
  getUsageComparisonChartsUrl: './private/api/usagecomp/{accountId}/report',
  getUsageComparisonReportUrl: './private/api/usagecomp/{accountId}/reportsettings',
  getUsageComparisonYearsUrl: './private/api/usagecomp/{accountId}/usageYears',
  createOrUpdateUsageComparisonReportUrl: './private/api/usagecomp/{accountId}/reportsettings/{reportId}',
  deleteUsageComparisonReportUrl: './private/api/usagecomp/{accountId}/reportsettings/{reportId}/delete',

  // Usage Graph
  // getUsageGraphUrl: './api/usage/{accountId}/usagegraph',
   getUsageGraphUrl: './private/api/usage/{accountId}/usagegraph',
  getBilledUsageGraphUrl: './private/api/usage/{accountId}/billedusagegraph',
  exportUsageGraphUrl: './private/api/usage/{accountId}/usagegraphexport',

   exportBilledUsageGraphUrl: './private/api/usage/{accountId}/billedusagegraphexport',

  // Dashboard
  dashboardUrl: './private/api/dashboard/{accountId}',
  widgetsUrl: './private/api/dashboard/{accountId}/widgetsettings/{widgetId}',
  usageWidgetChartDataUrl: './private/api/usage/{accountId}/widget/{widgetName}',
  billingWidgetChartDataUrl: './private/api/billing/{accountId}/widget/{widgetName}',
  deleteWidgetUrl: './private/api/dashboard/{accountId}/widgetsettings/{widgetId}/delete',
  promoContent: './private/api/dashboard/promo',

  //Emails
  fileDownloadEmailUrl: './private/api/account/file',
  userConfirmationEmailUrl: './private/api/account/user/confirmation',
  paymentConfirmationEmailUrl: './private/api/account/payment/confirmation',
  passwordResetRequestEmaillUrl: './private/api/account/reset_password/init',
  userActivationEmailUrl: './private/api/account/user/activation',

  //Downloads
  multipleInvoiceDownloadUrl: './api/billing/{accountId}/downloadInvoiceZip/{fileName}',

  // Help Topics
  helpTopicsUrl: './private/api/help/topics',

  // AMENDMENTS

  // Amendment Request
  recentAmendmentRequestsUrl: './private/api/accounts/{accountId}/amendments',
  allAmendmentRequestsUrl: './private/api/accounts/{accountId}/amendments/all',
  // recentAmendmentRequestsUrl: '../assets/api/amendments.json',
  saveAmendmentRequestUrl: './private/api/accounts/{accountId}/amendments',
  sendAmendmentConfirmationEmailUrl: './private/api/account/amendment/confirmation',

  deleteAmendmentRequestUrl: './private/api/accounts/{accountId}/amendments/{requestId}',
  submitAmendmentRequestUrl: './private/api/accounts/{accountId}/amendments/{requestId}/submitAmendmentEvent',

  // Amendment Sites
  getAmendmentSitesUrl: './private/api/accounts/{accountId}/amendments/{requestId}/sites',
  saveAmendmentSiteUrl: './private/api/accounts/{accountId}/amendments/{requestId}/sites/{amendmentSiteId}',
  deleteAmendmentSiteUrl: './private/api/accounts/{accountId}/amendments/{requestId}/sites/{amendmentSiteId}/delete',

  // Amendment Products
  getAmendmentProductsUrl: './private/api/accounts/{accountId}/amendments/{requestId}/sites/{amendmentSiteId}/products',
  deleteAmendmentProductsUrl: './private/api/accounts/{accountId}/amendments/{requestId}/sites/{amendmentSiteId}/products/delete',
  //@RequestParam(value = "contractId") String contractId, @RequestParam(value = "productId") String productId)
  saveAmendmentProductsUrl: './private/api/accounts/{accountId}/amendments/{requestId}/sites/{amendmentSiteId}/products',
  getAmendmentHistoryUrl: './private/api/accounts/{accountId}/amendments/history',
  // getAmendmentHistoryUrl: './assets/api/amendmentHistory.json',

  getFilteredContractsUrl: './private/api/accounts/{accountId}/amendments/contracts',
  getContractsForSiteUrl: './private/api/accounts/{accountId}/sites/{siteId}/contracts',
  getContractsForSiteLessReliabilityUrl: './private/api/accounts/{accountId}/sites/{siteId}/contracts/amendments',
  getSiteUrl: './private/api/accounts/{accountId}/sites/{siteId}',
  validateSiteIdUrl: './private/api/accounts/{accountId}/amendments/validate/{siteId}',
  getActiveSites: './private/api/accounts/{accountId}/amendments/sites/active?completionDate={completionDate}',
  getUtilitiesURL: './api/marketData/utility?market={market}',

  // Amendment Export
  exportAmendmentsCartUrl: './private/api/accounts/{accountId}/amendments/{requestId}/cart/export?maskingMode={maskingMode}&brand={brand}',
  exportAmendmentsHistoryUrl: './private/api/accounts/{accountId}/amendments/history/export', //startDate, endDate
  exportAmendmentsRecentUrl: './private/api/accounts/{accountId}/amendments/activity/export',

  // Amendment Attachments
  getAmendmentAttachmentslistUrl: './private/api/accounts/{accountId}/amendments/{requestId}/attachments',
  downloadAmendmentAttachmentUrl: './private/api/accounts/{accountId}/amendments/{requestId}/attachment/{id}',
  deleteAmendmentAttachmentUrl: './private/api/accounts/{accountId}/amendments/{requestId}/attachment/{id}/delete',
  uploadAmendmentAttachmentUrl: './private/api/accounts/{accountId}/amendments/{requestId}/attachment',
  saveAttachmentCommentUrl: './private/api/accounts/{accountId}/amendments/{requestId}/attachment/{id}',



  // MARKETS
  savePJMYourList: './api/marketData/savePJMYourList',
  getMarketStates: './api/marketstates',
  getGridConditions: './api/marketData/currentGridConditions/{market}',
  getFuelMix: './api/marketData/currentFuelMix{market}',
  getMarketFuelMix: './api/marketData/currentFuelMix/{market}',
  getErcotFuelMix: './api/marketData/currentFuelMixErcot',
  getMarketDemands: './api/marketData/currentRTMarketDemands/{market}',
  getMarketPrices: './api/marketData/currentRTMarketPrices/{market}',
  getMarketTypePrices: './api/marketData/currentRTMarketPrices/{market}/{type}',
  getMarketTypeViewPrices: './api/marketData/currentRTMarketPrices/{market}/{type}/{viewName}',
  getDAMarketTypePrices: './api/marketData/currentDAMarketPrices/{market}/{type}',
  getDAMarketTypeViewPrices: './api/marketData/currentDAMarketPrices/{market}/{type}/{viewName}',
  getErcotMarketPrices: './api/marketData/currentRTMarketPricesErcot',
  getPjmFuelMix: './api/marketData/currentFuelMixPjm',
  getLatestMarketsDashboard: './api/marketData/latest', // Returns actual load and all hub prices
  getMarketDataUrl: './api/marketData/{source}/{offset}',
  getMarketDashboardDataUrl: './api/marketData/intervalData/{source}/{offset}',
  getGenerationMixDataUrl: './api/marketData/getFuelMixErcotTypeBetweenDates/{source}/{offset}',
  getLMPMarketDataUrl: './api/marketData/LMP/{source}/{offset}',
  getSPPMarketDashboardDataUrl: './api/marketData/intervalData/{source}/{offset}',
  getForecastSPPMarketDashboardDataUrl: './api/marketData/intervalData/{source}/{pastOffset}/{offset}',
  getSPPMarketDataUrl: './api/marketData/intervalSPP/{source}/{offset}',
//  getSPPMarketDataUrl: './api/marketData/SPP/{source}/{offset}',
  getHenryHubDataUrl: './api/marketData/ERCOT_HENRY_HUB/{offset}',
  getHoustonShipChannelDataUrl: './api/marketData/ERCOT_HOUSTON_SHIP_CHANNEL/{offset}',
  getMarketsSettingsUrl: './api/marketData/settings/{componentUsed}',
  getMarketsViewSettingsUrl: './api/marketData/settings/{viewName}/{componentUsed}',
  getERCOTRTMarketDemandSettings: './api/marketData/currentRTMarketDemandsErcot/',
  updateMarketSettingUrl: './api/marketData/settings',
  exportMarketInformationUrl: './api/marketData/{accountId}/exportMarketInformation/{pastOffset}/{offset}?sources={sources}&maskingMode={maskingMode}',
  exportGasUrl: './api/marketData/{accountId}/exportGas/{offset}?sources={source}&maskingMode={maskingMode}',
  getForwardTradePriceByTradeDate: './private/api/renewalanalysis/forwardTradePriceByTradeDate',
  getForwardTradePriceByMonth: './private/api/renewalanalysis/forwardTradePriceByMonth',
  getForwardTradePriceByYear: './private/api/renewalanalysis/forwardTradePriceByYear',
  getIndicativePricing: './private/api/renewalanalysis/indicativePricing',
  getReportSettings: './private/api/renewalanalysis/{accountId}/reportsettings',
  getForwardPriceReportExport: './private/api/renewalanalysis/forwardTradePriceByTradeDate/export',
  updateRenewalReportSettings: './private/api/renewalanalysis/{accountId}/reportsettings/{reportId}',
  deleteRenewalReportSettings: './private/api/renewalanalysis/{accountId}/reportsettings/{reportId}/delete',
  getDashboardViews: './api/marketData/settings/views',
  getMarketDashboardViews: './api/marketData/settings/views/{market}',
  saveAllMarketDashboardSettings: './api/marketData/settings/saveAll/{market}',
  saveAllMarketViews: './api/marketData/settings/saveAllViews',
  deleteMarketView: './api/marketData/settings/deleteView/{viewName}/{market}',
  deleteMarketSettings: './api/marketData/settings/delete/{viewName}/{market}',

  // 4CP
  // get4cpPreferencesUrl: './assets/api/fourcp/fourcpPreferences.json',
  get4cpPreferencesUrl: './private/api/4CP/{accountId}/preferences',
  get4cpAlertUrl: './private/api/4CP/alert/{alertType}', //'./assets/api/fourcp/fourcpAlert.json',
  get4cpAlertByDateUrl: './private/api/4CP/alert/{alertType}/{date}', // date is in form 09oct2017 (don't ask why)
  get4cpMarketDataUrl: './api/4CP/marketData/{source}?startDay={start}&endDay={end}', // startDay and endDay == YYYY-mm-dd HH:mm
  get4cpRocUrl: './api/4CP/roc',
  get4cpAlertHistoryUrl: './private/api/4CP/{accountId}/history/{year}/{month}',
  // get4cpAlertHistoryUrl: '/assets/api/fourcp/alertHistory.json',
  get4cpMTDUrl: './api/4CP/5YearMtd',
  getRecentAlertsUrl: './private/api/4CP/{accountId}/recent',
  get4cpContactsUrl: './private/api/4CP/{accountId}/contacts',
  get4cpSitePerformanceUrl: './private/api/4CP/{accountId}/get4CPSitePerformanceSummary/{siteId}/{year}',
  get4cpSummaryPerformanceUrl: './private/api/4CP/{accountId}/get4CPAccountPerformanceSummary/{year}',
  getFourcpSiteUrl: './private/api/4CP/{accountId}/get4CPSites/{year}',
  get4cpSummaryDemandUrl: './assets/api/fourcp/fourcpDemand.json',


  // METRICS
  getUserMetricDataUrl: './management/audits/userStats?fromDate={fromDateParam}&toDate={toDateParam}&org={orgParam}',
  getUserMetricDataUxUrl: './management/audits/userStats/userDetails?forDate={fromDateParam}&org={orgParam}&eventType={eventTypeParm}',
  getUserMetricDataTxUrl: './management/audits/userStats/txDetails?forDate={fromDateParam}&org={orgParam}&eventType={eventTypeParm}',
  getUserMetricDetailDataUrl: '../assets/api/metrics/userMetricsDetails.json',
  getUserTransactionMetricUrl: './management/audits/txStats?fromDate={fromDateParam}&toDate={toDateParam}&org={orgParam}&userType={userTypeParam}&salesGroup={salesGroupParam}',
  getUserTransactionMetricDetailUrl: './management/audits/txStats/details?forDate={forDateParam}&eventType={eventParam}&org={orgParam}&userType={userTypeParam}&salesGroup={salesGroupParam}',

  getHistoricalEnrollmentMetricUrl: './private/api/management/audits/historicalEnrollmentStats?grouping={groupingParam}&start={startParam}&end={endParam}',
  getCurrentEnrollmentMetricUrl:'./private/api/management/audits/currentEnrollmentStats?grouping={groupingParam}',
  getUnassignedCustomersUrl: './private/api/management/audits/unassignedEnrollmentStats?grouping={groupingParam}',
  // getUnassignedCustomersUrl: './assets/api/metrics/unassignedEnrollmentCustomers.json',
  // getUserTransactionMetricUrl: './assets/api/transactionMetrics.json',

  // METRICS EXPORTS
  exportByCustomerUrl: './management/audits/userStats/export?fromDate={fromDateParam}&toDate={toDateParam}&org={orgParam}&orgName={orgNameParam}',
  exportFromCustomerUrl: './management/audits/txStats/export?fromDate={fromDateParam}&toDate={toDateParam}&org={orgParam}&orgName={orgNameParam}&userType={userTypeParam}&salesGroup={salesGroupParam}',
  exportEnrollmentUrl: './management/audits/enrollment/export?grouping={groupingParam}&start={startParam}&end={endParam}&period={periodParam}',

  // // CONTEXTUAL HELP
  // helpManageSiteGroupsUrl: 'https://stg-www.nrg.com/portal/help-topics/site-groups.nrgcontent.json',
  // helpViewEditSiteGroupsUrl: 'https://stg-www.nrg.com/portal/help-topics/add-edit-site-groups.nrgcontent.json',
  // helpDashboardUrl: 'https://stg-www.nrg.com/portal/help-topics/dashboard.nrgcontent.json',
  // helpBillingSummaryUrl: 'https://stg-www.nrg.com/portal/help-topics/billing-summary-report.nrgcontent.json',
  // helpBillingComparisonUrl: 'https://stg-www.nrg.com/portal/help-topics/billing-comparison-report.nrgcontent.json',
  // helpSelectBalancesUrl: 'https://stg-www.nrg.com/portal/help-topics/select-balances.nrgcontent.json',
  // helpPaymentInfoUrl: 'https://stg-www.nrg.com/portal/help-topics/select-payment-information.nrgcontent.json',
  // helpReviewPaymentUrl: 'https://stg-www.nrg.com/portal/help-topics/review-payment.nrgcontent.json',
  // helpPaymentConfirmationUrl: 'https://stg-www.nrg.com/portal/help-topics/payment-confirmation.nrgcontent.json',
  // helpPaymentHistoryUrl: 'https://stg-www.nrg.com/portal/help-topics/payment-history.nrgcontent.json',
  // helpManagePaymentMethodsUrl: 'https://stg-www.nrg.com/portal/help-topics/manage-payment-methods.nrgcontent.json',
  // helpUsageSummaryUrl: 'https://stg-www.nrg.com/portal/help-topics/usage-summary-report.nrgcontent.json',
  // helpUsageComparisonUrl: 'https://stg-www.nrg.com/portal/help-topics/usage-comparison-report.nrgcontent.json',
  // helpSelectSitesUrl: 'https://stg-www.nrg.com/portal/help-topics/select-sites.nrgcontent.json',
  // helpSelectReportSettingsUrl: 'https://stg-www.nrg.com/portal/help-topics/select-report-settings.nrgcontent.json',
  // helpUsageIntervalGraphUrl: 'https://stg-www.nrg.com/portal/help-topics/usage-interval-graph.nrgcontent.json',
  // helpContractsUrl: 'https://stg-www.nrg.com/portal/help-topics/contracts.nrgcontent.json',
  // helpProfileUrl: 'https://stg-www.nrg.com/portal/help-topics/profile.nrgcontent.json',
  // helpAddRemoveSitesUrl: 'https://stg-www.nrg.com/portal/help-topics/add-remove-sites.nrgcontent.json',

  payoutReportUrl: './private/api/broker/payoutReport/{thirdPartyAccountId}?startDate={startDate}&endDate={endDate}',
  exportPayoutReportUrl: './private/api/broker/payoutReport/export/{thirdPartyAccountId}?name={name}&startDate={startDate}&endDate={endDate}&maskingMode={maskingMode}&brand={brand}',

  // SUBSCRIPTIONS
  getSubscriptionsForReportUrl: './private/api/subscriptions/reportHasSubscriptions?userId={userId}&&reportType={reportType}',
  saveNewSubscriptionUrl: './private/api/subscriptions',
  getSubscriptionsForUserUrl: './private/api/subscriptions/{userId}',
  getAllSubscriptionsUrl: './private/api/subscriptions/allSubscriptions',

  deleteSubscriptionUrl: './private/api/subscriptions/{subscriptionId}/delete',
  editSubscriptionUrl: './private/api/subscriptions/{subscriptionId}',
  getAllReportSubscriptions: './private/api/subscriptions/reports/{userId}',
  saveUserReportUrl: './private/api/subscriptions/reports',
  downloadUserReportUrl: './private/api/subscriptions/{brand}/report/download',
  downloadAwsReport: './public/report/{reportHash}',

  // MANAGE SUPER USERS
  getAllSuperUsersUrl: './private/api/superUsers',
  editSuperUserUrl: './private/api/superUsers',
  deleteSuperUserUrl: './private/api/superUsers/user/{userId}',
  addSuperUserUrl: './private/api/superUsers/add',
  checkForExistingUserUrl: './private/api/users/{login}',
  exportSuperUsersUrl: './private/api/superUsers/export',
  resendSuperUserActivationUrl: './private/api/superUsers/resendActivation',
  refreshUserAccountCache: './private/api/superUsers/refreshElasticCache',
  getGeneratedStatementsEmailListUrl: './api/brokercommissions/getStatementsGenerated',
  manuallySendStatements: './api/brokercommissions/manuallySendStatements',



  //Third Party Experience
  getThirdPartyCustomersUrl: './private/api/accounts/{accountId}/thirdPartyCustomers',
  getThirdPartyGroupsUrl: './private/api/accounts/{accountId}/thirdpartygroups',
  getThirdPartyCustomersForGroupUrl: './private/api/accounts/{accountId}/thirdpartygroups/{groupId}',
  getThirdPartyAccountGroupCategoriesUrl: './private/api/accounts/{accountId}/thirdpartygroups/{groupId}/categories',
  updateThirdPartyAccountGroupUrl: './private/api/accounts/{accountId}/thirdpartygroups/{groupId}',
  updateThirdPartyAccountGroupCategoryUrl: './private/api/accounts/{accountId}/thirdpartygroups/{groupId}/tpAccount/{tpAccountId}',
  deleteThirdPartyAccountGroupUrl: './private/api/accounts/{accountId}/thirdpartygroups/{groupId}/delete',

  // Editorial content
  contentPage: './private/api/content?path=',
  userProfileDataUrl:  './private/api/users/profileData',

  // Hedge
  hedgeDefaultUrl:'./private/api/accounts/{accountId}/hedge/hedgemonitorsettings',

  // Matrix Pricing Subscriptions
  matrixPricingUrl : './private/api/portalsubscriptions/{id}',

  // Product Select (For Setting User Default Settings in product-select component)
  productSelectDefaultUrl:'./private/api/accounts/{accountId}/product-select/product-select-settings',

  // Broker Commission Statements
  getAllStatementsUrl: './api/brokercommissions/getStatements/{thirdPartyAccountId}',
  getBrokerStatementUrl:  './api/brokercommissions/downloadCommissionStatement',

  // Self Register
  getAttemptsRemaining: './api/self-register/attemptsRemaining',
  linkAccountUrl: './api/self-register/linkAccount',
  checkEmail: './api/self-register/checkEmail',
  selfRegister: './api/self-register/selfRegister',
  getElectricUtilityProviders: './api/self-register/electricUtilityProvider',

  // Pre Auth Header
  getNavDataUrl: './public/api/getNavData',

  // Tealium
  tealiumCreate: './private/api/users/accounts/{accountId}/createTealium',
  tealiumGet: './private/api/users/accounts/{accountId}/getProfileTealium',
  tealiumConfirm: './private/api/users/accounts/{accountId}/confirmRenewal',
  tealiumDismiss: './private/api/users/accounts/{accountId}/dismissRenewal',
  tealiumProdDelay: 2500,
  tealiumNonProdDelay: 20000,

  // AEM Content
  getHelpLandingPage: './private/api/aem/getHelpLanding',
  getPagesFromSearch: './private/api/aem/getPagesFromSearch/{query}',
  getHelpPage: './private/api/aem/getHelpPage',

  // walkme content
  walkMeAuthUrl: './private/api/accounts/getWalkMeAuthToken',

  // screen sharing audit
  screenSharingAuditUrl: './private/api/account/{accountId}/screenSharingAudit',

  // ReportSettings
  getAllReportSettingsByAccountUrl: './private/api/subscriptions/reportsettings',

};
