import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { IPaymentState, IPaymentSchedule, IPaymentSubmission, IPayment, IPaymentResponse, IPaymentSettingsKey, IPaymentMethod } from './payments';
import { PaymentsService } from './payments.service';
import { PortalService } from '../shared/portal.service';
import { Subscription } from 'rxjs';
import { IUserAccount } from '../shared/entities/profile';
import * as moment from 'moment';
import { Permissions } from '../shared/const/permissions';
import { ISite } from '../shared/entities/site';
import { IHelpStep } from '../shared/entities/contextualHelp';
import { Authority } from '../shared/const/authority';
import { ICategory, IInvoice } from '../shared/entities/bill-reports';
declare var $: any;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  state: IPaymentState;
  myAccount: IUserAccount;
  buttonDisabled = true;
  tcchecked: boolean = false;
  printing: boolean;
  printingSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  paymentSchedule: IPaymentSchedule;
  paymentErrorModalText = '';
  paymentErrorModalTable: IPayment[];
  paymentErrorModalCanNavigate: boolean;
  allowedToMakePayment = true;
  maskMode: boolean;
  helpStep: IHelpStep;
  isSuperUser: boolean;
  hasCVV: boolean;
  invalid: boolean;
  showNotice: string;
  cvvLen: number;
  tcSpecificMessage: string;

  helpStepSubscription: Subscription;

  hasNCCA = false;

  constructor(private dataService: DataService,
    private router: Router,
    private portalService: PortalService,
    private paymentsService: PaymentsService) { }

  ngOnInit() {
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);

    this.tcchecked = false;

    this.setCurrentActivePage('payments')
    this.myAccount = this.dataService.getAccountSource();
    this.allowedToMakePayment = this.portalService.hasPermission(Permissions.MakePayment) || this.isSuperUser;
    this.maskMode = this.dataService.getMaskMode();
    if(this.paymentSchedule)
      this.paymentSchedule.savePaymentMethod = null;

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.state.backDisabled = false;
        this.paymentsService.setBalances(null);
        this.allowedToMakePayment = this.portalService.hasPermission(Permissions.MakePayment);
        // this.navigate(1);
        this.router.navigate(['/']);
        this.myAccount = account;
      }
    );
    this.paymentsService.caSelNotice.subscribe(
      (caSel) => {
        this.showNotice = caSel;
        if(this.showNotice) {
            this.dataService.setCCNotice(true);
        }
        else{
           this.dataService.setCCNotice(false);
        }
      }
    );
    this.paymentsService.onStepReached.subscribe(
      (step) => {
        this.state.currentStep = step;
        this.state.backDisabled = false;
        this.updateButtonLabel();
      }
    );
    this.paymentsService.balancesUpdated.subscribe(
      (balances) => {
        this.updatePaymentSummary(balances);
      }
    );
    this.paymentsService.paymentScheduleUpdated.subscribe(
      (paymentSchedule) => {
        // TODO: Check button disabled;
        this.paymentSchedule = paymentSchedule;
        if (!this.paymentSchedule || (this.paymentSchedule
              && this.paymentSchedule.paymentMethod
              && (!this.paymentSchedule.paymentMethod.userPaymentSettingsKey ||
                     (this.paymentSchedule.paymentMethod.userPaymentSettingsKey && !this.paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken))))
        {
          this.buttonDisabled = true;
        } else {
          this.buttonDisabled = false;
        }
        if (this.paymentSchedule && moment(moment(this.paymentSchedule.paymentDate).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))) {
          this.buttonDisabled = true;
        }
      }
    );
    this.paymentsService.setSubmitted(false);
    this.printingSubscription = this.dataService.printingUpdated.subscribe(
      (printing) => {
        this.printing = printing;
      }
    );
    this.state = {
      currentStep: 1,
      viewInvoicesToggled: false,
      viewAccountToggled: false,
      selectedBalances: [],
      paymentTotal: 0,
      buttonLabel: 'Choose Payment Method',
      backDisabled: false,
    };
    this.paymentsService.setSubmitted(false);
    this.router.navigate(['/payments/select-balances']); // For page refresh, ensure they land at page 1;
    this.dataService.setSelectedNavItem('billing'); // highlight nav item on page refresh
    this.dataService.setTitleSource('Make a Payment');

    this.dataService.accountSourceUpdated.subscribe(
      () => {
        const key: IPaymentSettingsKey = {
          paymentToken: null,
          userId: null,
          accountId: null
        };
        const method: IPaymentMethod = {
          defaultPayment: 1,
          nickName: '',
          oldPaymentToken: '',
          routingNumber: '-1',
          methodType: 'existing',
          maskedAccountNumber: null,
          userPaymentSettingsKey: key,
          maskedCardNumber: null,
          cardExpiryMonth: null,
          cardExpiryYear: null,
          cvv: null,
          cardZipCode: null,
          rememberPayment: null,
        };
        const schedule: IPaymentSchedule = {
          paymentDate: new Date(),
          savePaymentMethod: true,
          paymentMethod: method
        };
        this.paymentSchedule = schedule;
        this.paymentsService.setPaymentSchedule(this.paymentSchedule);
      }
    );
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

  ngOnDestroy() {
    if (this.printingSubscription) this.printingSubscription.unsubscribe();
    this.paymentsService.setBalances(null);
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getFakeContractAccountNum(balance: ISite) {
    return this.portalService.getFakeContractAccountNum(balance);
  }

  toggleAccountView(visible: boolean) {
    this.state.viewAccountToggled = visible;
    this.paymentsService.payByAccount = visible;
  }

  setCVVLength() {
    if(this.paymentSchedule.paymentMethod.cardBrand=='Amex') {
        this.cvvLen = 4;
    } else {
        this.cvvLen = 3;
    }

  }

  nextStep() {
    if (!this.buttonDisabled) {
      if (this.state.currentStep == 3) {
        // this.dataService.setLoading(true);
        // this.dataService.setLoadingText('Please do not refresh page or navigate backwards while payment is processing...');
        this.buttonDisabled = true;
        // this.createPaymentMethod();
        setTimeout(() => {
          // this.dataService.setLoading(false);
          // this.state.currentStep++;
          this.navigate(4, true);
        }, 1500);
      } else {
        this.state.currentStep++;
        this.navigate();
      }
    }
  }

  hasCVVFlag() {
   return this.hasCVV;
  }

  updatePaymentSummary(balances: any) {
    this.hasNCCA = false;
    this.state.selectedBalances = [];
    this.state.paymentTotal = 0;
    this.state.viewInvoicesToggled = false;
    if (balances) {
      if (balances.categories) {
        balances.categories.forEach((category: ICategory) => {
          category.sites.forEach((site: ISite) => {
            if (this.paymentsService.payByAccount) {
              // Pay by account view
              if(site.contractAccountNum!='Multiple' && (site.checked || site.otherAmount)) {
                const invoice = {
                  siteId: site.siteId,
                  contractAccountNum: site.contractAccountNum,
                  contractAccountNumber: site.contractAccountNumber,
                  balanceDue: site.otherAmount || site.balanceDue,
                  businessPartnerId: site.businessPartnerId,
                  collectiveAccountId: site.collectiveAccountId,
                  collective: site.collectiveAccountId !== ''
                } as IInvoice;
                if(invoice.nccaStatus && invoice.checked)
                   this.hasNCCA = true;
                this.state.selectedBalances.push(invoice);
                this.state.paymentTotal += invoice.balanceDue;
              }
              else if(site.contractAccountNum=='Multiple'){
                site.invoices.forEach((invoiceSel: IInvoice) => {
                    if(invoiceSel.checked || invoiceSel.otherAmount) {
                      const invoice = {
                        siteId: site.siteId,
                        contractAccountNum: invoiceSel.contractAccountNum,
                        contractAccountNumber: invoiceSel.contractAccountNumber,
                        balanceDue: invoiceSel.otherAmount || invoiceSel.balanceDue,
                        businessPartnerId: site.businessPartnerId,
                        collectiveAccountId: site.collectiveAccountId,
                        collective: site.collectiveAccountId !== ''
                      } as IInvoice;
                      if(invoice.nccaStatus && invoice.checked)
                         this.hasNCCA = true;
                      this.state.selectedBalances.push(invoice);
                      this.state.paymentTotal += invoice.balanceDue;
                    }
                });
              }
            } else {
              // Summary view
              site.invoices.forEach((invoice) => {
                if (invoice.checked) {
                  if(invoice.nccaStatus && invoice.checked)
                   this.hasNCCA = true;
                  this.state.selectedBalances.push(invoice);
                  this.state.paymentTotal += invoice.balanceDue;
                }
              });
            }
          });
        });
      } else {
        // Invoice View
        balances.forEach((invoice) => {
          if (invoice.checked) {
            this.state.selectedBalances.push(invoice);
            this.state.paymentTotal += invoice.balanceDue;
            if(invoice.nccaStatus && invoice.checked)
                 this.hasNCCA = true;
          }
        });
      }
      if (this.state.selectedBalances.length == 0 || this.state.paymentTotal <= 0) {
        this.buttonDisabled = true;
      } else {
        this.buttonDisabled = false;
      }
    }
    this.paymentsService.setSelectedBalances(this.state.selectedBalances);
    this.paymentsService.setPaymentTotal(this.state.paymentTotal);
    if(!this.paymentsService.payByAccount){
      this.paymentsService.setNCCA(this.hasNCCA);
    }
  }

  updateButtonLabel() {
    if (this.state.currentStep == 1) {
      this.state.buttonLabel = 'Select Payment Method';
    } else if (this.state.currentStep == 2) {
      this.state.buttonLabel = 'Review Payment';
    } else if (this.state.currentStep == 3) {
      if(this.paymentSchedule &&  this.paymentSchedule.paymentMethod.paymentType=='CARD')
          this.setCVVLength();
      this.state.buttonLabel = 'Submit Payment';
    } else {
      this.state.backDisabled = true;
    }
  }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }

  // Only Integer Numbers
  eventHandler(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  navigate(step?: number, submitting?: boolean) {
    if (step && step < this.state.currentStep && !this.state.backDisabled && !submitting) {
      this.state.currentStep = step;
    }
    if (this.state.currentStep == 1) {
      this.tcchecked = false;
      this.router.navigate(['/payments/select-balances']);
    } else if (this.state.currentStep == 2) {
      this.tcchecked = false;
      this.buttonDisabled = false;
      this.invalid = false;
      if(this.paymentSchedule)
      {
          this.paymentSchedule.paymentMethod.cvv = null;
      }
      this.router.navigate(['/payments/payment-info']);
    } else if (this.state.currentStep == 3 && !submitting) {
      this.tcchecked = false;
      this.buttonDisabled = true;
      this.settermconditionmsg();
      this.invalid = false;
      if(this.paymentSchedule && this.paymentSchedule.paymentMethod.cvv!=null)
        this.hasCVV = true;
      else
        this.hasCVV = false;
      this.router.navigate(['/payments/review-payment']);
    } else {
      if(this.paymentSchedule && this.paymentSchedule.paymentMethod.paymentType=='CARD'
              &&(!this.paymentSchedule.paymentMethod.cvv || this.paymentSchedule.paymentMethod.cvv.toString().length==0))
      {
        this.invalid = true;
        this.buttonDisabled = false;
      }
      else
        this.submitPayment();
    }

    this.updateButtonLabel();
  }

  submitPayment() {
    const selectedBalances = this.paymentsService.selectedBalances;
    const paymentSchedule = this.paymentsService.paymentSchedule;
    const payments: IPayment[] = [];

    selectedBalances.forEach((balance) => {
        const invoiceNo = balance.invoiceNo ? balance.invoiceNo.toString() : null;
        var payment;
        if(paymentSchedule.paymentMethod.paymentType=='BANKACCOUNT'){
              payment= {
              // 'businessPartnerId': balance.businessPartnerId, // fetched in the backend
              'accountNumber': balance.contractAccountNum,
              'bankRoutingNumber': paymentSchedule.paymentMethod.routingNumber.toString(),
              'tokenizedBankAccountNumber': paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken,
              'paymentAmount': balance.balanceDue.toString(),
              'paymentDate': moment(paymentSchedule.paymentDate).format('YYYY-MM-DD'),
              'siteId': balance.siteId,
              'invoiceNo': invoiceNo,
              'type': this.payByAccount ? 'ACCOUNT' : 'INVOICE'
            };
        }
        else if(paymentSchedule.paymentMethod.paymentType=='CARD'){

          payment= {
            // 'businessPartnerId': balance.businessPartnerId, // fetched in the backend
            'accountNumber': balance.contractAccountNum,
            'paymentAmount': balance.balanceDue.toString(),
            'siteId': balance.siteId,
            'invoiceNo': invoiceNo
          };
         }
         payments.push(payment);
     });

   var paymentSubmission: IPaymentSubmission = {
      accountId: this.myAccount.id,
      'ccNumber': null,
      'expiryDate':  null,
      'cvvNumber': null,
      'zipCode': null,
      'paymentDate': null,
      'type': null,
       payments: payments,
    };



    if((paymentSchedule.paymentMethod.paymentType!='CARD')  || (paymentSchedule.paymentMethod.paymentType=='CARD' && paymentSchedule.paymentMethod.cvv)) {
      if(paymentSchedule.paymentMethod.paymentType=='CARD')
      {
        let expiryDateTmp = '';

        if(paymentSchedule.paymentMethod.cardExpiryMonth.toString().length == 1) {
          expiryDateTmp = '0' + paymentSchedule.paymentMethod.cardExpiryMonth + (paymentSchedule.paymentMethod.cardExpiryYear.toString()).substring(2);
        }
        else {
          expiryDateTmp = paymentSchedule.paymentMethod.cardExpiryMonth + (paymentSchedule.paymentMethod.cardExpiryYear.toString()).substring(2);
        }

        paymentSubmission = {
          'accountId' : paymentSchedule.paymentMethod.userPaymentSettingsKey.accountId,
          'ccNumber': paymentSchedule.paymentMethod.userPaymentSettingsKey.paymentToken,
          'expiryDate':  expiryDateTmp,
          'cvvNumber': paymentSchedule.paymentMethod.cvv.toString(),
          'zipCode': paymentSchedule.paymentMethod.cardZipCode.toString(),
          'paymentDate': moment(paymentSchedule.paymentDate).format('YYYY-MM-DD'),
          'type': this.payByAccount ? 'ACCOUNT' : 'INVOICE',
          'payments' : payments
        }
      }
      this.dataService.setLoadingText('Please do not refresh page or navigate backwards while payment is processing...');
      this.dataService.setLoading(true);
      this.paymentsService.submitPayment(paymentSubmission).subscribe(
        (resp: IPaymentResponse) => {
          this.dataService.setLoading(false);
          this.dataService.setLoadingText('');
          const originalSelectedBalances = selectedBalances;
          const paymentsReturned = resp.payments;
          paymentsReturned.forEach(payment => {
            const selectedBalance = originalSelectedBalances.find(balance => balance.contractAccountNum === payment.accountNumber);
            if (selectedBalance && selectedBalance.collectiveAccountId) {
              payment.collective = true;
            }
          });
          this.paymentsService.setSelectedBalances(paymentsReturned as any);


          const validPayments = paymentsReturned.filter(payment => (payment.statusCode == '00'));
          const invalidBankPayments = paymentsReturned.filter(payment => (payment.statusCode == '01' || payment.statusCode == '03' || payment.statusCode == '04'));
          const duplicatePayments = paymentsReturned.filter(payment => (payment.statusCode == '52'));
          const errorPayments = paymentsReturned.filter(payment => (payment.statusCode == '07'  || payment.statusCode == '99'
                              || (paymentSchedule.paymentMethod.paymentType!='CARD' && (payment.statusCode == '53' ||  payment.statusCode == '54' || payment.statusCode == '99'))));

          //-----credit card payment error status codes-----
        const ccInvalidCCVError = paymentsReturned.filter(payment => (payment.statusCode == '57'  || (paymentSchedule.paymentMethod.paymentType=='CARD' && payment.statusCode == '53')));
        const ccUnauthorizedError = paymentsReturned.filter(payment => (
          payment.statusCode ==  '50' ||  //'MSG_CCSERR_50_PAY_CC' ||
                                       //payment.statusCode == 'MSG_CCSERR_50_INVALID_CC' ||
          payment.statusCode == '51'));  //'MSG_CCSERR_51_INVALID_CC' ));
        const ccInvalidExpDateError = paymentsReturned.filter(payment => (
          (paymentSchedule.paymentMethod.paymentType=='CARD' && payment.statusCode == '54') ||  //'MSG_CCSERR_54_PAY_CC' ||
          payment.statusCode == '55'     //'MSG_CCSERR_55_PAY_CC'
        ));
        const ccZipCodeError = paymentsReturned.filter(payment => (
          payment.statusCode == '56' //'MSG_CCSERR_56_INVALID_CC'
        ));
        const ccTokenError = paymentsReturned.filter(payment => (
          payment.statusCode == '58' //'MSG_CCSERR_58_INVALID_CC'
        ));
        const ccExpiredError = paymentsReturned.filter(payment => (
          payment.statusCode == '59' //'MSG_CCSERR_59_PAY_CC'
        ));
        const ccOtherError = paymentsReturned.filter(payment => (
          payment.statusCode == '60' ||  //'MSG_CCSERR_60_INVALID_CC' ||
          payment.statusCode == '91' ||  //'MSG_CCSERR_91_PAY_CC' ||
          payment.statusCode == '92'     //'MSG_CCSERR_92_PAY_CC'
        ))
        const errorGIPayments = paymentsReturned.filter(payment => (payment.statusCode == 'GI'));
        const bpcaError = paymentsReturned.filter(payment => (
          payment.statusCode == '100'
        ));

          if (validPayments.length == paymentsReturned.length) {
            this.state.currentStep = 4;
            this.paymentSchedule.paymentMethod.cvv = null;
            this.hasCVV = false;
            this.invalid = false;
            this.router.navigate(['/payments/payment-confirmation']);
          }
          else if (errorGIPayments.length > 0) {
               this.paymentErrorModalText = 'The payment information provided does not appear to be valid. Please verify your information and try again.';
               this.paymentErrorModalTable = errorGIPayments;
               this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
               this.paymentErrorModalCanNavigate = false;
               if (validPayments.length == 0) {
                   this.paymentErrorModalCanNavigate = false;
               } else {
                  this.paymentsService.setSelectedBalances(validPayments as any);
                  this.paymentErrorModalCanNavigate = true;
               }
               $('#giPayErrorModal').modal({
                  show: true,
                  backdrop: 'static'
               });
           }
          else if (errorPayments.length > 0) {
            this.paymentErrorModalText = 'We were unable to process this payment at this time. If you need to make a payment today, please contact your Customer Care Representative for assistance.';
            this.paymentErrorModalTable = errorPayments;
            if (errorPayments.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          } else if (duplicatePayments.length > 0) {
            this.paymentErrorModalText = 'We did not process this payment to protect you from submitting duplicate payments. Please review your payment history records to confirm payments were submitted. ';
            this.paymentErrorModalTable = duplicatePayments;
            if (duplicatePayments.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          } else if (invalidBankPayments.length > 0) {
            this.paymentErrorModalText = 'We were unable to process this payment because the routing number or bank account number was invalid. Please verify your bank account information and resubmit payment.';
            this.paymentErrorModalTable = invalidBankPayments;
            if (invalidBankPayments.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          } //cc errors below
          else if (ccExpiredError.length > 0 ){
            this.paymentErrorModalText =  'Credit card expired';
            this.paymentErrorModalTable = ccExpiredError;
            if (ccExpiredError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          else if (ccInvalidCCVError.length > 0){
            this.paymentSchedule.paymentMethod.cvv = null;
            this.hasCVV = false;

            this.paymentErrorModalText = 'Invalid CCV';
            this.paymentErrorModalTable = ccInvalidCCVError;
            if (ccInvalidCCVError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          else if (ccUnauthorizedError.length > 0){
            this.paymentErrorModalText =  'The credit card entered cannot be processed. Please try again with a different card, or pay via ACH.'
            this.paymentErrorModalTable = ccUnauthorizedError;
            if (ccUnauthorizedError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          else if (ccInvalidExpDateError.length > 0){
            this.paymentErrorModalText =  'Invalid expiration date';
            this.paymentErrorModalTable = ccInvalidExpDateError;
            if (ccInvalidExpDateError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          else if (ccZipCodeError.length > 0){
            this.paymentErrorModalText =  'Invalid zip code';
            this.paymentErrorModalTable = ccZipCodeError;
            if (ccZipCodeError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          else if (ccTokenError.length > 0){
            this.paymentErrorModalText =  'We\'re unable to process your payment at this time. Please try again later.'
            this.paymentErrorModalTable = ccTokenError;
            if (ccTokenError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          else if (ccOtherError.length > 0){
            this.paymentErrorModalText =  'We\'re unable to process your payment at this time. Please try again later.'
            this.paymentErrorModalTable = ccOtherError;
            if (ccOtherError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          } 
          else if (bpcaError.length > 0){
            this.paymentErrorModalText =  'We\'re unable to process this payment at this time. If you need to make a payment today, please contact your Customer Care Representative for assistance.'
            this.paymentErrorModalTable = bpcaError;
            if (bpcaError.length == paymentsReturned.length) {
              this.paymentsService.setSelectedBalances(originalSelectedBalances as any);
              this.paymentErrorModalCanNavigate = false;
            } else if (validPayments.length == 0) {
              this.paymentErrorModalCanNavigate = false;
            } else {
              this.paymentsService.setSelectedBalances(validPayments as any);
              this.paymentErrorModalCanNavigate = true;
            }
            $('#paymentErrorModal').modal({
              show: true,
              backdrop: 'static'
            });
          }
          this.paymentSchedule.paymentMethod.cvv = null;
          this.hasCVV = false;
          this.invalid = false;

          // console.log(resp);
        }, () => {
          this.dataService.setLoading(false);
        }
      );
    }
    else{
          this.dataService.setLoading(false);
          this.dataService.setLoadingText('');
          this.paymentErrorModalCanNavigate = false;
          this.paymentErrorModalText = 'We did not process this payment. Please enter CVV for credit card. ';

          $('#paymentErrorModal').modal({
            show: true,
            backdrop: 'static'
          });
    }

  }




  toggleViewInvoices() {
    this.state.viewInvoicesToggled = !this.state.viewInvoicesToggled;
  }

  // createPaymentMethod() {
  //   if (this.paymentSchedule.savePaymentMethod) {
  //     this.paymentsService.createPaymentMethod(this.paymentSchedule.paymentMethod).subscribe(
  //       () => { }
  //     );
  //   }
  // }

  accept() {
    this.dataService.setLoading(false);
    $('#paymentErrorModal').modal('hide');
    $('#giPayErrorModal').modal('hide');
    if (this.paymentErrorModalCanNavigate) {
      this.state.currentStep = 4;
      this.router.navigate(['/payments/payment-confirmation']);
    } else {
      this.navigate(3, false);
    }
    this.buttonDisabled = false;
  }

  hideAccountNumber(paymentType: string, accountNum: string)
  { // 0000000016
    if(paymentType == 'CARD'){
      if(accountNum.length >= 4){
        return accountNum.substring(accountNum.length-4);
      }
    }
    else{
      if(accountNum.indexOf('-') != -1){
        return 'XXXXXXX' + accountNum.split('-')[1];
      }

    }

    return 'XXXXXXXXXX';

  }

  numbersWithCommas(x) {
    return this.portalService.numberWithCommas(x);
  }

  get payByAccount(): boolean {
    return this.paymentsService.payByAccount;
  }

  settcChecked() {
    if(this.tcchecked)
      this.buttonDisabled = false;
    else
      this.buttonDisabled = true;

    this.settermconditionmsg();
  }

  settermconditionmsg() {
    if(this.myAccount){
      let branding = this.myAccount.accountBranding === "Reliant" ? "Reliant" : "NRG";
      this.tcSpecificMessage = `${branding} Payment Terms<br><br>
                    <i>Online Payment Options – Terms and Conditions</i><br>
                    One-time payment(s) made via the online account management platform may be made with a credit card
                    or debit card that we accept or via an ACH draft from a bank account. This service is provided as a
                    courtesy at no additional cost. Note, the amounts due presented on the OAM platform may differ from
                    your true balance for a variety of reasons. The ${branding} invoice constitutes the official record of amounts
                    owed and your payments and usage. Your account will be credited for the day the payment was scheduled
                    and OAM will reflect your updated balance.<br><br>
                    YOU ACKNOWLEDGE AND AGREE THAT ${branding.toUpperCase()} SHALL BEAR NO LIABILITY OR RESPONSIBILITY FOR ANY LOSSES OF ANY
                    KIND THAT YOU MAY INCUR AS A RESULT OF AN ERRONEOUS STATEMENT, ANY DELAY IN THE ACTUAL DATE ON WHICH
                    YOUR ACCOUNT IS DEBITED OR YOUR FAILURE TO PROVIDE ACCURATE AND/OR VALID PAYMENT INFORMATION.<br>
                    <i>* Visa is a trademark of Visa, Inc. Discover Network and the Discover Network Acceptance Mark are
                    trademarks of Discover Financial Services. MasterCard is a registered trademark of MasterCard
                    International Incorporated. American Express is a registered trademark of American Express. ${branding} is
                    not affiliated with Visa, Discover Network, MasterCard, American Express or any of the programs or
                    promotions of these entities.</i><br><br>
                    <i>Payment Authorization</i><br>
                    <b>I have read and agree to the terms of payments. I understand that I am authorizing ${branding} to charge
                    my bank account or credit card, as applicable.</b>`;
    }
  }

}
