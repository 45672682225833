

import { Component, OnInit, Input } from '@angular/core';
import {DataService} from "../shared/data.service";
import {SitesControl} from "../shared/entities/controls";
import {FadeAnimation} from "../animations/fadeAnimation";
import {FadeInAnimation} from "../animations/fadeInAnimation";
import {Subscription} from "rxjs";
import {ISiteGroup, ISite} from "../shared/entities/site";
import {IUserAccount} from "../shared/entities/profile";
import {SiteGroupService} from "../site-groups/site-groups.service";
import {IUsageGraphRequest, IUsageGraph, IUsageGraphPoint, ISiteCounts} from "../shared/entities/usageGraph";
import * as moment from "moment";
import {UsageGraphService} from "./usage-graph.service";
import {PortalService} from "../shared/portal.service";
import {FakerService} from "../shared/faker.service";
import {IHelpStep} from "../shared/entities/contextualHelp";
declare var Highcharts: any;
declare var $:any;
import {Router} from "@angular/router";
import {IAccount} from "../shared/entities/account";
import { reduce } from 'rxjs/operator/reduce';
import {
  IMarketData, IOffset,
  IMarketComparison, IUMarketComparison,IMarketIndicator, MarketIndicators, IUMarkets
} from "../shared/entities/markets";

import {
  IMarketProperties, IUMarketProperties, ErcotIUnfoProperties,HenryHubProperties, HoustonShipChannelProperties
} from "../shared/entities/markets";

 
import * as cloneDeep from 'lodash/cloneDeep';




@Component({
  selector: 'app-usage-graph',
  templateUrl: './usage-graph.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['usage-graph.component.scss']
})
export class UsageGraphComponent implements OnInit {

  myAccount: IUserAccount;
  accountWithContractData: IAccount;
  component = this;
  request: IUsageGraphRequest = {};
  graphData: IUsageGraph;
  target: IUsageGraph;
  allPoints: IUsageGraphPoint[];
  allTPoints: IUsageGraphPoint[];
  allShadowPoints: IUsageGraphPoint[];
  siteCounts: ISiteCounts;
  totalUsage: number;
  peakHourlyDemand: number;
  averageHourlyUsage: number;
  peakDemandHr: string;
  allSites: ISite[];
  filteredSites: ISite[];
  userSiteGroups: ISiteGroup[] = [];
  systemSiteGroups: ISiteGroup[] = [];
  showSites = false;
  showMeterType = false;
  showDateRange = false;
  showDateRangeError = false;
  noDataError: boolean = true;
  daylightSavings: boolean = false;
  maskMode: boolean;
  amsCount: number = 0;
  idrCount: number = 0;
  nidrCount: number = 0;
  siteIndex: number = -1;
  enableSave: boolean = false;
  sitesControl: SitesControl;
  accountSourceSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  getSiteGroupsSubscription: Subscription;
  siteCountsSubscription: Subscription;
  realTimeReportSubscription: Subscription;
  exportTriggeredSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;
  minDate: Date;
  maxDate: Date;
  selectedStartDate: Date;
  selectedEndDate: Date;
  dateRangeDisplay: string;
  helpStep: IHelpStep;
  marketYAxis: any[];
  isMarketData: boolean = false;
  isColor: boolean = false; 
  properties: IUMarketProperties;
  selectedComparisons: IUMarketComparison[] = [];
  defaultComparisons: IUMarketComparison[] = [];
  legendSelection : IUMarketComparison[] = [];
  ctrColor: number = 0;
  ctr: number = 0;
  maxSelect: boolean = false;
  hasData: boolean = false;
  showChartLegend: boolean = true;
  expandedGroup: string = ''; 
  expandedMarket: string = ''; 
  // infoexpanded: boolean = false;
  // expandedStatus:boolean = false;
  showIntervalDetails: boolean = true;
  showComparisons: boolean = false;
  showIndicators: boolean;
  marketData: IMarketData[];
  datasets: any[];
  newdatasets: any[];
  mCtr: number = 0;
  marketDataTicker: number = 0;
  marketsLoading: boolean = false;
  shadowData: boolean = true;
  demLegend: boolean = false;
  isZoom: boolean = false;
  

  marketsGroup = {
    groupName: 'MARKETS',
    display: 'Markets'
  };

  constructor(private usageGraphService: UsageGraphService,
              private dataService: DataService,
              private portalService: PortalService,
              private siteGroupsService: SiteGroupService,
              private fakerService: FakerService,
              private router: Router) { }

  ngOnInit() {
    // this.datasets = [];
    this.dataService.setTitleSource('Usage Interval Graph');
    this.dataService.setSelectedNavItem('usage');
    this.dataService.setCurrentActivePage('usage');
    this.sitesControl = new SitesControl();
    this.myAccount = this.dataService.getAccountSource();
    this.properties = ErcotIUnfoProperties;
    
    this.properties.market.forEach((market,index) => {
      market.comparisons.forEach((comparison,index1) => {
        let defaultComparison = Object.assign({}, comparison);
        this.defaultComparisons.push(defaultComparison);
        this.properties.market[index].comparisons[index1].selected = false;
        this.properties.market[index].comparisons[index1].expandedValue = false;
        this.properties.market[index].comparisons[index1].expandColor = false;
      // if(comparison.selected)x{
      //   // this.selectedComparisons.push(comparison);        
      // }
      });
    });
    if(this.myAccount) {
      
      this.opengetShadowMetersUrl();
      this.loadDefaultGraph();
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      // this.dataService.setLoading(true);
      this.myAccount = account;
      
      // this.opengetShadowMetersUrl();
      // this.loadDefaultGraph();

    });
    this.accountWithContractData = this.dataService.getAccountWithContractDataSource();
    this.accountWithContractDataSubscription = this.dataService.accountWithContractDataSourceUpdated.subscribe((account) => {
      this.myAccount = account;
    });


    

    this.maskMode = this.dataService.getMaskMode();
    let siteGroups = this.dataService.getSiteGroupsSource();
    if(siteGroups){
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.loadSiteOptions();
    }
    this.getSiteGroupsSubscription = this.dataService.siteGroupsSourceUpdated.subscribe((siteGroups) => {
      this.systemSiteGroups = siteGroups.systemSiteGroups;
      this.userSiteGroups = siteGroups.userSiteGroups;
      this.loadSiteOptions();
    });

    this.siteCounts = this.dataService.getSiteCountsSource();
    this.siteCountsSubscription = this.dataService.siteCountSourceUpdated.subscribe((siteCounts) =>{
      this.siteCounts = siteCounts;
    });

    // this.realTimeReportSubscription = this.dataService.realTimeDataReportTriggered.subscribe(() => {this.openRealTimeDataReport()})
    this.exportTriggeredSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.dataService.setLoading(true);
      this.exportGraph()
    });

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    })
  }

  ngOnDestroy() {
    // this.properties.comparisons.forEach((comp) => {      
    //   comp.selected = false;
    //   comp.expandColor = false;
    // });
    if (this.siteCountsSubscription) this.siteCountsSubscription.unsubscribe();
    if (this.realTimeReportSubscription) this.realTimeReportSubscription.unsubscribe();
    if (this.getSiteGroupsSubscription) this.getSiteGroupsSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.exportTriggeredSubscription) this.exportTriggeredSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  loadDefaultGraph() {
   
    this.request.accountId = this.myAccount.id;
    this.request.accountName = this.myAccount.name;
    this.request = {
      accountId: this.myAccount.id,
      accountName: this.myAccount.name,
      siteRequest: 'ALL_SITES',
      startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      frequencyType: 'ALL',
      
    };
    this.selectedStartDate = moment(this.request.startDate).toDate();
    this.selectedEndDate = moment(this.request.endDate).toDate();
     
    this.sitesControl.siteRequest = "ALL_SITES";
    this.minDate = moment().subtract(3, 'years').toDate();
    this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + '-' + moment(this.request.endDate).format('MM/DD/YY');
    this.applyChanges();

  }

  expandMarket(marketSel: IUMarkets) {
    marketSel.expanded = !marketSel.expanded;
    // marketSel.expandedColor = !marketSel.expandedColor;    
}

  exportGraph() {
    this.request.shadow = this.hasData;
    // this.request.shadow = this.showIntervalDetails;
    this.request.marketComparisonIndex = [];
    let xyData = [], xyObj = {}, x;    
    for(let i = 0; i < this.properties.market.length; i++){      
        for(let j = 0; j < this.properties.market[i].comparisons.length; j++){      
         if(this.properties.market[i].comparisons[j].selected == true){
          xyObj = {
            description:this.properties.market[i].comparisons[j].display,
            type: 'SPP',
            source:this.properties.market[i].comparisons[j].value
          };
          xyData.push(xyObj);
        }          
        }
     }
     this.request.marketComparisonIndex = xyData;
     this.request.maskingMode = this.dataService.getMaskMode();
     this.request.brand = window.localStorage.getItem("brand");
     this.usageGraphService.exportUsageGraph(this.request);
  }

  toggleSites() {
    
    this.showSites = !this.showSites;
    this.showMeterType = this.showDateRange = this.showComparisons = false;
    if(this.showSites){

      this.sitesControl.siteRequest = this.request.siteRequest;
      if (this.request.siteRequest == 'GROUP_CATEGORY'){
        this.systemSiteGroups.forEach((systemGroup) => {
          if(systemGroup.groupName == this.request.systemGroupName){
            this.selectGroup(systemGroup);
          }
        });
        this.userSiteGroups.forEach((userGroup) => {
          if(userGroup.id == this.request.groupId){
            this.selectGroup(userGroup);
          }
        });
        this.sitesControl.selectedCategory = this.request.groupCategory;
      } else if (this.request.siteRequest == 'SPECIFIC_SITE'){
        this.filteredSites.forEach((site, index) => {
          if(site.siteId == this.request.siteId){
            this.selectSite(site, index);
          }
        });
      }
    } else {
      if(this.sitesControl.selectedSite){
        if(!this.maskMode) {
          this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
        } else {
          this.sitesControl.siteSearch = this.getFakeSiteId(this.sitesControl.selectedSite) + ' ' + this.fakerService.getCompanyName();
        }
      } else {
        this.sitesControl.siteSearch = '';
      }
    }
  }
  toggleMeterType() {
    this.showMeterType = !this.showMeterType;
    this.showDateRange = this.showSites = this.showComparisons = false;
  }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
    this.showDateRangeError = this.showMeterType = this.showSites = this.showComparisons = false;
    this.selectedStartDate = moment(this.request.startDate).toDate();
    this.selectedEndDate = moment(this.request.endDate).toDate();
  }

  loadSiteOptions() {
   
    /*this.siteGroupsService.getSitesForSystemGroup(this.myAccount.id, 'ALL_SITES').subscribe(
      (sites) => {
        this.allSites = sites;
        sites.forEach((site) => {
          this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
        });
      }
    );*/
    this.allSites = this.dataService.getSitesForAccountSource();
    this.allSites.forEach((site) => {
      this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
    });
  }

  selectGroup(group: ISiteGroup){
    this.sitesControl.selectedGrouping = group;
    if(this.sitesControl.selectedGrouping.id) {
      if(this.sitesControl.selectedGrouping.groupName.length > 15){
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName.substring(0,15) + '...'
      } else {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName;
      }
      this.siteGroupsService.getCategoriesForUserGroup(this.sitesControl.selectedGrouping.id).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      )
    } else {
      this.siteGroupsService.getCategoriesForSystemGroup(this.sitesControl.selectedGrouping.groupName).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
        }
      )
    }
    this.sitesControl.selectedCategory = null;
  }

  selectCategory(category: string){
    this.sitesControl.selectedCategory = category;
  }

  selectMeterType(meterType: string){
    this.request.frequencyType = meterType;
    this.toggleMeterType();
    this.applyChanges();
  }

  searchSites() {
    this.filteredSites = [];
    this.allSites.forEach((site) => {
      if(site.siteId.match(this.sitesControl.siteSearch) ||
        (site.serviceAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
        this.sitesControl.siteSearch == ''){
        this.filteredSites.push(site);
      }
    });
  }

  navigateTypeahead(down: boolean) {
    if(down && this.siteIndex < this.filteredSites.length-1){
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  selectStartDate() {
    this.selectedEndDate = moment(this.selectedStartDate).add(7, 'days').toDate();
  }

  selectSite(site: ISite, index?: number){
    if(this.filteredSites.length > 0){
      if(this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if(index) {
        this.sitesControl.selectedSite = this.filteredSites[index];
      } else {
        this.sitesControl.selectedSite = this.filteredSites[this.siteIndex];
      }
      if(!this.maskMode){
        this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
      } else {
        this.sitesControl.siteSearch = this.getFakeSiteId(site) + ' ' + this.fakerService.getCompanyName();
      }
      this.resetTypeahead();
    }
  }

  resetSiteSelection() {
    this.sitesControl.siteSearch = null;
  }

  applySites() {
    // Apply changes in customerControl to selectedReport
    this.toggleSites();
    this.enableSave = true;
    this.request.siteRequest = this.sitesControl.siteRequest;
    if(this.request.siteRequest == 'ALL_SITES'){
      this.request.groupCategory = null;
      this.request.systemGroupName = null;
      this.request.groupId = null;
      this.request.siteId = null;
    } else if (this.request.siteRequest == 'GROUP_CATEGORY'){
      this.request.groupCategory = this.sitesControl.selectedCategory;
      if(this.sitesControl.selectedGrouping.id) {
        this.request.groupId = this.sitesControl.selectedGrouping.id;
        this.request.systemGroupName = null;
      } else {
        this.request.systemGroupName = this.sitesControl.selectedGrouping.groupName;
        this.request.groupId = null;
      }
      this.request.siteId = null;
    } else if (this.request.siteRequest == 'SPECIFIC_SITE'){
      this.request.siteId = this.sitesControl.selectedSite.siteId;
      this.request.groupCategory = null;
      this.request.systemGroupName = null;
      this.request.groupId = null;
    }
    this.applyChanges();
    this.resetSiteSelection();
  }

  applyChanges() {
    // Get graph data
    this.isMarketData = false;
    this.demLegend = false;
    if(!this.dataService.getLoading()) {
      this.dataService.setLoading(true);
    }
    this.usageGraphService.getUsageGraph(this.request).subscribe(
      (graphResponse) => {
        if(graphResponse.points.length > 0){
          // || graphResponse.shadowPoints.length > 0
           this.noDataError = false;
        } else {
           this.noDataError = true;
        }
        this.graphData = graphResponse;
        this.allPoints = this.graphData.points;
        this.allShadowPoints = this.graphData.shadowPoints;
        this.getSiteControlDisplay();

        this.target = cloneDeep(this.graphData);
        this.allTPoints = this.target.points;
        
        this.selectedComparisons = []; 
        this.properties.market.forEach((market) => {
          market.comparisons.forEach((comp) => {        
              if(comp.selected){ 
                this.selectedComparisons.push(comp);  
                this.isMarketData = true; 
              }
          // console.log('Selected Comparison on apply',this.selectedComparisons);
          }); 
        });

       
       

        this.extractGraphData();


        if (this.isMarketData) {
          setTimeout(() => {
            // Ensure div is rendered for HighCharts
            
              this.getMarketData();
            }, 50);

          // this.datasets = []
        }

         if (!this.isMarketData) {
          if(!this.noDataError){
            setTimeout(() => {
            // Ensure div is rendered for HighCharts
            
              this.createGraph();
            }, 50);
          }
       }
        this.dataService.setLoading(false);
      }, err => {
        if(err.status == 404){
          this.graphData = null;
          this.noDataError = true;
        }
      }
    );
  }

  applyDateRange() {
    if(!(Math.abs(moment(this.selectedStartDate).diff(moment(this.selectedEndDate), 'days')) > 30)){
      this.request.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      this.request.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
      this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + ' - ' + moment(this.request.endDate).format('MM/DD/YY');
      this.toggleDateRange();
      this.applyChanges();
      this.showDateRangeError = false;
    } else {
      this.showDateRangeError = true;
    }
  }


  opengetShadowMetersUrl() {

    this.usageGraphService.getShadowMetersUrl(this.myAccount.id).subscribe(
      (response) => {
         
         
      if (response == 'true') {
        this.hasData = true;
      }else {
         this.hasData = false;
         this.showIntervalDetails = false;
        // this.hasData = true;
      }
      
      }
    );
  }


  openRealTimeDataReport() {
    this.usageGraphService.getRealTimeDataReportUrl(this.myAccount.id).subscribe(
      (url) => {
        // console.log(url);
        window.open(url, '_blank');
      }
    );
  }


  getSiteControlDisplay() {
    if(this.sitesControl.siteRequest == 'ALL_SITES') {
      this.sitesControl.display = 'All Sites';
      // if(this.graphData.siteCount > 0) {
      //   this.sitesControl.display += ' (' + (this.graphData.idrCount + this.graphData.amsCount) + ')';
      // }
    } else if(this.sitesControl.siteRequest == 'GROUP_CATEGORY'){
      this.sitesControl.display = this.sitesControl.selectedCategory;
      // if(this.graphData.siteCount > 0) {
      //   this.sitesControl.display += ' (' + (this.graphData.idrCount + this.graphData.amsCount) + ')';
      // }
    } else if(this.sitesControl.siteRequest == 'SPECIFIC_SITE'){
      this.sitesControl.display = '...' + this.sitesControl.selectedSite.siteId.substr(5);
    }
  }


  
  getMarketData() {
    this.datasets = [];
    this.mCtr = 0;
    // loop through selected comparisons
    if(this.selectedComparisons.length > 0){
      let offset;
      // this.portalService.spinBabySpin('spinner-' + this.properties.name);
      
      
      // this.dataService.setLocalLoader('MARKETS-' + this.properties.name, true);
      this.marketData = [];
      this.marketDataTicker = 0;
      this.legendSelection = [];
      // console.log('selected comparison',this.selectedComparisons);
      // console.log('dates',this.request.startDate);
      this.request.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      this.request.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
      
      this.legendSelection = this.selectedComparisons; 
      this.selectedComparisons.forEach((comparison) => {        
        this.marketData = [];
        // if(comparison.value == 'FORECAST_LOAD') {
        //   offset = this.selectedFutureOffset;
        // } else {
        //   offset = this.selectedOffset;
        // }.subscribe((marketData: IMarketData) => {
        this.usageGraphService.getMarketData(comparison.value, this.request).subscribe((marketData: any) => {
          this.marketData.push(marketData);

          
          this.loadChartData(comparison.display);
          this.marketDataTick();
          //  this.prepareMarketdata();          
          // this.createGraph();
        }, err => {
          // this.marketDataTick();
        });
      });
      
      
    }
    
  }

  marketDataTick() {
    this.marketDataTicker++;
    if(this.marketDataTicker == this.selectedComparisons.length){
      this.marketsLoading = false;
      // this.dataService.setLocalLoader('MARKETS-' + this.properties.name, false);      
       setTimeout(() => {       
         this.createGraph();
       }, 400);
     
    }
  }

  loadChartData(displayName:string) {
    let name,dates, data;
    let first = '';
    let usageTime
    // this.properties.comparisons.forEach((comparison) => {
      name = '';
      data = [];
      
      // this.datasets = [];
      this.marketData.forEach((dataSource) => {
        // if(comparison.value == dataSource.sources[0].source) {
          if(dataSource.sources[0]){
            // displayName = display;
            name = dataSource.sources[0].source;
            data = dataSource.sources[0].values;
            dates = dataSource.sources[0].dates;
          }
        // }
      });
//j
      
      let gData,smarketTimes;
      var val = 0.00;
      gData = moment(this.graphData.time[0]).format('M/DD HH:mm');
      smarketTimes = moment(dates[0]).format('M/DD HH:mm');
      
      for(let i = 0; i < this.graphData.time.length; i++){
        gData = moment(this.graphData.time[i]).format('M/DD HH:mm');
        // if(moment(gData).isBefore(moment(smarketTimes).format('M/DD HH:mm'))){
          if (moment(gData).isBefore(moment(smarketTimes))){
            // dates.unshift(gData);
            // data.unshift(null);

        }

      }   

    //   if(moment(this.selectedStartDate).isAfter(moment(this.selectedEndDate))) {
    //     this.invalidDateRange = true;
    //   } else {
    //     this.invalidDateRange = false;
    //   }
    // }
      let count = 0;
      let marketTimes
      marketTimes = moment(this.graphData.time[this.graphData.time.length - 1]).format('M/DD HH:mm')
      let xyData = [], xyObj = {}, x;    
      
      // if(comparison.selected){
        for(let i = 0; i < dates.length; i++){        
                   
           if (moment(marketTimes).add(15,'minutes').isSameOrAfter(moment(dates[i]).format('M/DD HH:mm'))){
          xyObj = {
            y: (data[i] == null?null:data[i]/1000),
            d: dates[i]
          };
          xyData.push(xyObj);
         }
        };
        if(first == '') first = 'FIRST';
        
        if (xyData === undefined || xyData.length == 0) {
            
        }
    else {
      if (xyData.length > this.mCtr){
        this.mCtr = xyData.length;
      }
      
      
      this.datasets.push({        
          // id: comparison.value,
          name: name,
          display: displayName,
          type: (name.match('LZ') || name.match('HB')) ? 'line' : 'area',
          data: xyData,
        //   yAxis: (name.match('LZ') || name.match('HB'))? 1 : 0,
          // tooltip: {
          //   valueDecimals: 3
          // },
        //   turboThreshold: 1000000,
        //   first: first == 'FIRST'
        });
      }
      // }
      // if(first == 'FIRST') first = null;
    // });
    // this.filteredDatasets = this.datasets;
    //  console.log('this.datasets', this.datasets);
  }

 applyReset() {
   this.properties.market.forEach((market,index) => {  
    this.properties.market[index].comparisons.forEach((comparison,index1) => {     
      this.properties.market[index].comparisons[index1].selected = false;
      this.properties.market[index].comparisons[index1].expandColor = false;
      });
    });
 }

  applyMChanges(comparison: IUMarketComparison){
    // if(!this.dataService.getLoading()) {
    //   this.dataService.setLoading(true);
    // }
    // this.isMarketData = true;
    this.showChartLegend = true;
    this.selectedComparisons = [];    
    this.properties.market.forEach((market) => {
        market.comparisons.forEach((comp) => {
        // console.log('updateing settings');
        //  this.updateSettings('comparison-' + comp.value, comp.selected.toString(), true);
        if(comp.selected){ 
            this.selectedComparisons.push(comp);      
        }
        // console.log('Selected Comparison on apply',this.selectedComparisons);
      }); 
    });


    
    this.toggleComparisons();

    
    if (this.isZoom) {
      this.graphData.points = [];
      this.target.points = [];       
      this.request.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
      this.request.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
      this.dateRangeDisplay = moment(this.request.startDate).format('MM/DD/YY') + ' - ' + moment(this.request.endDate).format('MM/DD/YY');
           
      this.graphData.points = this.allPoints;
      this.target.points = this.allTPoints;
      this.extractGraphData(); 
      
    }

    this.marketsLoading = true;
    this.portalService.spinBabySpin('marketsLoader');

    
    if (this.isZoom) {
      setTimeout(() => {  
        this.getMarketData();
     }, 100);
    }
    else{
    this.getMarketData();
    }


    if (this.selectedComparisons.length == 0) 
    {
      this.marketsLoading = false;
      this.createGraph()
      
    } 
    // this.dataService.setLoading(true);
    // setTimeout(() => {
    //   // Ensure div is rendered for HighCharts     
    //     this.createGraph();
    //   }, 5000);
    // this.marketsLoading = false;
      // this.dataService.setLoading(false); 
  }
 
  // applyMChanges(){

  //   this.getMarketData();

  // }

  extractGraphData(){
    this.totalUsage = 0;
    this.peakHourlyDemand = 0;
    let peakDemand = 0;
    let hourlyUsage = 0;
    let hourlyUsages = [];
    let peakDemandPoint;
    


   
    //this.target = this.bestCopyEver(this.graphData);
    //  this.target = this.iterationCopy(this.graphData);

    //  this.target = cloneDeep(this.graphData);
    // console.log('target', this.target);


   
 
    this.graphData.points.forEach((point, i) => {      
      this.totalUsage += point.usage;
      hourlyUsage += point.usage;
      if (this.showIntervalDetails) {
        this.totalUsage += point.shdUsage;
        hourlyUsage += point.shdUsage;
      }
      if ((i+1) % 4 == 0){
        hourlyUsages.push(hourlyUsage);
        hourlyUsage = 0;
      }

      if (!this.showIntervalDetails) {
      if(point.demand > peakDemand){
        peakDemand = point.demand;
        peakDemandPoint = point;
        this.peakDemandHr = this.getMomentFromPoint(point).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
      }
    }

    if (this.showIntervalDetails) {
      if((point.demand + point.shdDemand) > peakDemand){
        peakDemand = point.demand + point.shdDemand;
        peakDemandPoint = point;
        this.peakDemandHr = this.getMomentFromPoint(point).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
      }
    }


      if (point.demand > 0 ) {
        // this.noDataError = false;
        this.demLegend = true
          point.y = point.demand;
        }
       
        if (point.demand == 0)  {
          if(point.amsCount == 0 && point.idrCount == 0){
            point.y = null;
          }
          else {
            point.y = point.demand;
          }
        }
        if (point.demand == null) {
            point.y = null;
        }
        
    });


    // this.graphData.shadowPoints.unshift(this.target.points)

    if (!this.showIntervalDetails) {
     
      this.target.points.forEach((point, i) => {
        
  
        // this.totalUsage += point.usage;      
        // hourlyUsage += point.usage;
        // if ((i+1) % 4 == 0){
        //   hourlyUsages.push(hourlyUsage);
        //   hourlyUsage = 0;
        // }
        // if(point.demand > peakDemand){
        //   peakDemand = point.demand;
        //   peakDemandPoint = point;
        //   this.peakDemandHr = this.getMomentFromPoint(point).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
        // }
        if (point.shdDemand > 0 || point.shdDemand == 0 ) {
          point.y = null;
        }
      });
    }
    

    if (this.showIntervalDetails) {
     
    this.target.points.forEach((point, i) => {
      

      // this.totalUsage += point.usage;      
      // hourlyUsage += point.usage;
      // if ((i+1) % 4 == 0){
      //   hourlyUsages.push(hourlyUsage);
      //   hourlyUsage = 0;
      // }
      // if(point.demand > peakDemand){
      //   peakDemand = point.demand;
      //   peakDemandPoint = point;
      //   this.peakDemandHr = this.getMomentFromPoint(point).endOf('hour').add(1, 'minute').format('MM/DD/YY HH:mm') + ' CST';
      // }
    //   if (point.shdDemand = 0 ){
    //       point.y = null;
    //   }
      // if (point.shdDemand > 0 ) {
      //   point.y = point.shdDemand;
      // }

      if (point.shdDemand > 0 || point.shdDemand == 0) {
        point.y = point.shdDemand;
      }
      if (point.shdDemand == null){
        point.y = null;
      }

    });
  }

// if (this.target.points.length > 0) {
//   this.target.points.forEach((point, i) => {
//     point.y = null;
//     this.graphData.shadowPoints.unshift(this.target.points[i])
//   });
// }

  
    this.totalUsage = this.portalService.numberWithCommas(this.totalUsage.toFixed(3));
    let count = 0;
    let hourlyDemand = 0;
    this.graphData.points.forEach((point) => {
      if(count == 4) {
        if(hourlyDemand > this.peakHourlyDemand) {
          this.peakHourlyDemand = hourlyDemand;
        }
        count = 0;
        hourlyDemand = 0;
      }
      hourlyDemand += point.usage;
      if (this.showIntervalDetails) {
        hourlyDemand += point.shdUsage
      }
      count++;
    });
    this.peakHourlyDemand = this.portalService.numberWithCommas(this.peakHourlyDemand.toFixed(3));

    let totalHourlyUsages = 0;
    hourlyUsages.forEach((usage) => {
      totalHourlyUsages += usage;     
    });

    if(hourlyUsages.length > 0){
      this.averageHourlyUsage = totalHourlyUsages / hourlyUsages.length;
      this.averageHourlyUsage = this.portalService.numberWithCommas(this.averageHourlyUsage.toFixed(3));
    } else {
      this.averageHourlyUsage = 0;
    }

    this.graphData.time = [];
    let moment, isLongHour = false, longHourPassed = false,j = 0, longHourIndexes = []; 
   

    this.graphData.points.forEach((point, i) => {
     
      moment = this.getMomentFromPoint(point);
 
      // console.log('moment',moment);

      if(moment.format('YYYY-MM-DD HH:mm') == moment.add(1, 'hour').format('YYYY-MM-DD HH:mm')){
        longHourIndexes.push(i + 4);
       
      }
      isLongHour = false;
      longHourIndexes.forEach((index) => {
        if(index == i){
         isLongHour = true;
        }
      });
      moment = this.getMomentFromPoint(point); 
      
     
          if(isLongHour){
            this.graphData.time.push('*' + moment.format('M/DD HH:mm'));
            longHourPassed = true;
            this.daylightSavings = true;
          } else if (longHourPassed){
            this.graphData.time.push(moment.subtract(1, 'hour').format('M/DD HH:mm'))
            j = j + 1;
            if (j == 88){
              longHourPassed = false;
            }
          } else {
            this.graphData.time.push(moment.format('M/DD HH:mm'))
          }
});

     
     

  }

  
  prepareMarketdata(){
     let marketTime
    //  console.log('prepare mctr',this.mCtr);

     if (this.mCtr > this.graphData.time.length) {
        marketTime = moment(this.graphData.time[this.graphData.time.length - 1]) 
          // marketTime = moment().add(15, 'minutes').format('M/DD HH:mm');
          // this.graphData.time.push(marketTime.format('M/DD HH:mm'))
           



          for(let i = this.graphData.time.length; i < this.mCtr; i++){
            this.graphData.time.push(marketTime.add(15, 'minutes').format('M/DD HH:mm'))
                // marketTime = moment.add(15, 'minutes').format('M/DD HH:mm');
                // this.graphData.time.push(marketTime.format('M/DD HH:mm'))        
            };

      }

  };


  //  isObject(obj) {
  //   var type = typeof obj;
  //   return type === 'function' || type === 'object' && !!obj;
  // };
  // iterationCopy(src) {
  //   let target = {};
  //   for (let prop in src) {
  //     if (src.hasOwnProperty(prop)) {
  //       // if the value is a nested object, recursively copy all it's properties
  //       if (this.isObject(src[prop])) {
  //         target[prop] = this.iterationCopy(src[prop]);
  //       } else {
  //         target[prop] = src[prop];
  //       }
  //     }
  //   }
  //   return target;
  // }

  //  bestCopyEver(src) {
  //   return Object.assign({}, src);
  // }
  goToSiteGroups() {
    this.router.navigate(['/site-groups']);
  }

  getMomentFromPoint(point: IUsageGraphPoint){
    return moment(moment(point.date).minute((point.intervalId * 15) - 15).format('MM/DD/YY HH:mm:SS'));
    
  }

  
  getYAxis() {
    // if(this.properties.name == MarketSection.Info || this.properties.name == MarketSection.Snapshot){
    // console.log('datasets n y axis', this.datasets);
    if(this.datasets && this.datasets.length) { 
    this.marketYAxis = [{ // Primary yAxis
        labels: {
          format: '{value}'
        },
        title: {
          text: 'DEMAND (KW)'
        },
        opposite: false,
        // plotLines: this.ercotPlotLines,
      }, { // Secondary yAxis
        title: {
          text: 'PRICE ($/kWh)'
        },
        labels: {
          formatter: function () {
            return '$' + Highcharts.numberFormat(this.axis.defaultLabelFormatter.call(this), 5);
          }
        },
        // labels: {
        //   format: '${value}'
          
        // },
        opposite: true,
        // tickInterval: 10,
        // plotLines: this.hubPlotLines,
      }];
    } else {
      
      this.marketYAxis = [{ // Primary yAxis
        title: {
          text: 'DEMAND (KW)'
        },
        labels: {
          format: '{value}'
        },
        // tickInterval: 10,
        // plotLines: this.ercotPlotLines,
      }];
    }

  }


  createGraph() {      
    this.getYAxis();     
    let component = this;         
    Highcharts.chart('usageGraph', {    
      title: {
        text: ''
      },
      // plotOptions: { area: {stacking: 'normal', marker: { enabled: false}}},
      // plotOptions: { area: {marker: { enabled: false}}},
      plotOptions: {
        series: {
          // general options for all series
          marker: {
            enabled: false,
            symbol: 'circle',
          },
        },       
        area: {
           stacking: 'normal',
          // shared options for all area series        
          symbol: 'circle',
          // tooltip: {
            
          //   shared: true
          // }
        }
      },     
       xAxis: {
         categories: this.graphData.time,
        events: {
                    afterSetExtremes: (event) => {
                     
                      if(event.max){
                        component.isZoom = true;
                        this.graphData.points = [];
                        this.target.points = [];
                        let pointMoment;
                        let newStart = moment(moment(this.request.startDate).minute(Math.round(event.min * 15)).format('MM/DD/YY HH:mm:SS'));
                        let newEnd = moment(moment(this.request.startDate).minute(Math.round(event.max * 15)).format('MM/DD/YY HH:mm:SS'));
                        this.dateRangeDisplay = newStart.format('MM/DD/YY') + ' - ' + newEnd.format('MM/DD/YY');
                        this.allPoints.forEach((point) => {
                          pointMoment = this.getMomentFromPoint(point);
                          if(pointMoment.isSameOrAfter(newStart) && pointMoment.isSameOrBefore(newEnd)) {
                            this.graphData.points.push(point);
                          }
                        });
                       
                        let tpointMoment;                         
                        this.allTPoints.forEach((tpoint) => {
                          tpointMoment = this.getMomentFromPoint(tpoint);
                          if(tpointMoment.isSameOrAfter(newStart) && tpointMoment.isSameOrBefore(newEnd)) {
                            this.target.points.push(tpoint);
                          }
                        });

                        this.extractGraphData();                        
                      }
                    }
                  }
                },
        
        
      chart : {
        zoomType: 'x',
        // resetZoomButton: {
        //   theme: {
        //     display: 'none'
        //   }
        // },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        symbol: 'circle'
      },
      yAxis: this.marketYAxis,

      credits: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
        // gradient2: {
        //   tagName: 'linearGradient',
        //   id: 'gradient-2',
        //   x1: 0,
        //   y1: 0,
        //   x2: 0,
        //   y2: 1,
        //   children: [{
        //     tagName: 'stop',
        //     offset: 0
        //   }, {
        //     tagName: 'stop',
        //     offset: 1
        //   }]
        // }

      },
      
      exporting: { enabled: false },
      
      tooltip: { 
        shared:true,    
        formatter: function() {       
                           
        var s = '';
        var flag = 'A';
            
        $.each(this.points, function(i, point) {
            // s += '<br/>'+ point.series.name +': '+
            // '$'+ (this.point.y).toFixed(5) + '/kWh)';
         if (this.point.series.name ==  'Demand (KW)' || this.point.series.name == 'Shadow Meter Demand (KW)'){ 
           if (flag == 'A'){
          var dateString = this.point.date + " " + this.x.substring(10-5);
             s = moment(dateString, "YYYY-MM-DD HH:mm").format('ddd, MMM Do') + '<br />' 
             s += moment(dateString, "YYYY-MM-DD HH:mm").format('HH:mm') + ' - ' + moment(dateString, "YYYY-MM-DD HH:mm").add(15, 'minutes').format('HH:mm') + ' CST' + '<br />'
             flag = 'N';
            }}

            if (flag == 'A'){
              var dateString = this.point.d + " " + this.x.substring(10-5);
                 s = moment(dateString, "YYYY-MM-DD HH:mm").format('ddd, MMM Do') + '<br />' 
                 s += moment(dateString, "YYYY-MM-DD HH:mm").format('HH:mm') + ' - ' + moment(dateString, "YYYY-MM-DD HH:mm").add(5, 'minutes').format('HH:mm') + ' CST' + '<br />'
                 flag = 'N';
                }
            
                if (this.point.series.name == 'Shadow Meter Demand (KW)'){ 
                  s += '<br />' + 'Shadow Meter Demand: ' + this.point.shdDemand.toFixed(3) + ' KW'; 
                  s += '<br />' +'Shadow AMS Count: ' + this.point.shdAmsCount; 
                  s += '<br />' + 'Shadow IDR Count: ' + this.point.shdIdrCount; 
                }    
  
             if (this.point.series.name == 'Demand (KW)'){
            s += '<br />' + 'Demand: ' + this.point.demand.toFixed(3) + ' KW <br/>';
            s =  s +  'AMS Count: ' + this.point.amsCount + '<br/>';
            s = s + 'IDR Count: ' + this.point.idrCount;
           }
           
           if (this.point.series.name !==  'Demand (KW)' && this.point.series.name !== 'Shadow Meter Demand (KW)'){ 
           s +=  '<br/>' + point.series.name +': '+
            '$'+ (this.point.y).toFixed(5) + '/kWh';
           }
           
        });

        return s;
        },
       },      
       
      series: [               
        {
          name: 'Shadow Meter Demand (KW)',
          showInLegend: (this.showIntervalDetails)?true:false,
          type: 'area',
          data: this.target.points, 
          turboThreshold: 3000,
          linearGradient: 300,
          legendIndex:1                  
         },
         {
          name: 'Demand (KW)',
          showInLegend: this.demLegend,
          type: 'area',
          data: this.graphData.points,          
          turboThreshold: 3000,
          linearGradient: 300, 
          legendIndex:0
          },                   
          {
            name: (this.datasets && this.datasets.length && this.datasets[0].data && this.datasets[0].data.length)?this.datasets[0].display:'',
            showInLegend: (this.datasets && this.datasets.length && this.datasets[0].data && this.datasets[0].data.length)?true:false,
            // showInLegend: true,
            type: 'line',
            data: (this.datasets && this.datasets.length && this.datasets[0].data && this.datasets[0].data.length)?this.datasets[0].data:[],
            yAxis: (this.datasets && this.datasets.length) ?1:0,
            opposite:true,
            step: true, 
            turboThreshold: 3000,
            linearGradient: 300,
            legendIndex:2                             
          },
          {
            name: (this.datasets && this.datasets.length > 1 && this.datasets[1].data && this.datasets[1].data.length)?this.datasets[1].display:'',
            // name:'comparison2';
            // showInLegend: true,
            showInLegend: (this.datasets && this.datasets.length > 1 && this.datasets[1].data && this.datasets[1].data.length)?true:false,
            type: 'line',
            data: (this.datasets && this.datasets.length > 1 && this.datasets[1].data && this.datasets[1].data.length)?this.datasets[1].data:[],
            yAxis: (this.datasets && this.datasets.length) ?1:0,
            opposite:true,
            step: true, 
            turboThreshold: 3000,
            linearGradient: 300, 
            legendIndex:3     
          },
          {            
            name: (this.datasets && this.datasets.length > 2 && this.datasets[2].data && this.datasets[2].data.length)?this.datasets[2].display:'',
            showInLegend: (this.datasets && this.datasets.length > 2 && this.datasets[2].data && this.datasets[2].data.length)?true:false,
            // showInLegend: true,
            type: 'line',            
            // color:'#9D!14D',
            data: (this.datasets && this.datasets.length > 2 && this.datasets[2].data && this.datasets[2].data.length)?this.datasets[2].data:[],
            yAxis: (this.datasets && this.datasets.length) ?1:0,
            opposite:true,
            step: true, 
            turboThreshold: 3000,
            linearGradient: 300,
            legendIndex:4                          
          }
      ]
    });
  }


  toggleComparisons() {
    this.showComparisons = !this.showComparisons;
  
    this.showMeterType = this.showDateRange = this.showSites = false;

  }

  toggleIndicators() {
    this.showIndicators = !this.showIndicators;
  }

  selectComparison(marketSel: IUMarkets, comparison: IUMarketComparison, ) {
    
     this.showChartLegend = true;      
    // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    //this.selectedComparisons = [];
    this.ctr = 0;
    this.ctrColor = 0;

   
    this.properties.market.forEach((market) => {
      market.comparisons.forEach((comp) => {
        if(comp.selected){
          this.ctr ++;
          if(this.ctr < 3) {  
            this.maxSelect = false;                               
          }
            else{
              this.maxSelect = true;
            }

        // if(this.ctr < 6){          
            // // console.log('reached 5 or less than equal');                
          //}
          // this.toggleComparisons();                   
          // return;        
        }
      });
    });

    if (this.ctr == 0 ){
      this.maxSelect = false;
    }

    if(this.maxSelect){
      if(comparison.selected) {
        comparison.selected = !comparison.selected;
        this.maxSelect = false;
      }
    }
    else{
     
     comparison.selected = !comparison.selected;
    // this.updateSettings('comparison-' + comparison.value, comparison.selected.toString(), true);
    }    
    
    // this.selectedComparisons.push(comp);
    // this.toggleComparisons();
    // this.getMarketData();
    this.properties.market.forEach((market) => {
      if(market.marketName===marketSel.marketName) {
          market.comparisons.forEach((comp) => {
          if (comp.groupName == comparison.groupName && comp.selected == true) {
            this.ctrColor ++;
          }
        });
      }
      });
   
      this.properties.market.forEach((market) => {
        if(market.marketName===marketSel.marketName) {
          market.comparisons.forEach((comp) => {
            if (comp.groupName == comparison.groupName && comp.groupStart == true) {
              if (this.ctrColor > 0) {
                comp.expandColor = true;
                comp.selectedCount = this.ctrColor;
              }
  
              if (this.ctrColor == 0) {
                comp.expandColor = false;
                comp.selectedCount = this.ctrColor;
              }
            }
          });
       }
      });
  
    this.properties.market.forEach((market) => {
      this.ctr = 0;
      if(market.marketName===marketSel.marketName) {
        market.comparisons.forEach((comp) => {
          if (comp.selected) {
            this.ctr++;
          }
        });
        market.counter = this.ctr;
        if(market.counter>0)
        {
          market.expandedColor = true;
        }
        else {
          market.expandedColor = false;
        }
      }
    });
    

  }


  
  expandStatus(comparison: IUMarketComparison){
    // console.log('6comparison',comparison); 
    comparison.expandedValue = !comparison.expandedValue;
 }


 expandGroup(marketSel:IUMarkets, group: string, comparison: IUMarketComparison) {
  if (this.expandedMarket===marketSel.marketName  && this.expandedGroup == group) {
    this.expandedGroup = '';
    comparison.expandedValue = !comparison.expandedValue;
  } else {
    this.expandedGroup = group;
    this.expandedMarket = marketSel.marketName;
    this.properties.market.forEach((market) => {
      if(market.marketName === marketSel.marketName) {
          market.comparisons.forEach((comp) => {
          if (this.expandedMarket===marketSel.marketName && this.expandedGroup == comp.groupName) {
            comp.expandedValue = true;
          }
          else {
            comp.expandedValue = false;
          }
        });
      }
      else {
          market.comparisons.forEach((comp) => {
            comp.expandedValue = false;
          });
      }
    });
  }
}
//  expandGroup(group: string,comparison: IUMarketComparison) {
      
//    if (this.expandedGroup == group) {      
//      this.expandedGroup = ""
//      comparison.expandedValue = !comparison.expandedValue;
    
//      // comparison.expandedValue = !comparison.expandedValue;
//      // // console.log('2group name',group);
//      //   this.infoexpanded = !this.infoexpanded;
//      //   this.expandedStatus = false;
//    } else {
//      this.expandedGroup = group;
     
//      this.properties.market.forEach((market) => {
//       market.comparisons.forEach((comp) => {
//        if(this.expandedGroup == comp.groupName){          
//             comp.expandedValue = true;
           
//             // comp.expandColor = true

//       //   if (this.expandedGroup == comp.groupName && comp.selected == true)
//       //  {
//       //    comp.expandColor = true
//       //  }

//        }       
//        else{
         
//             comp.expandedValue = false;
           
//        }
//      }); 
//     }); 
//        // this.infoexpanded = !this.infoexpanded;
//        // this.expandedStatus = true;
//    }
//  }

// // Click outside event for date range and groupings sections
// document.addEventListener("click", ($event) => {
//   if($($event.target).parents('#comparisonsSection').length == 0){
//     this.showComparisons = false;
//   } 
// });



displayIntervalDetails(event) {
   this.showIntervalDetails = event.target.checked;

  //  console.log('intervalDetails',this.showIntervalDetails);
    //  this.applyChanges();

     this.extractGraphData();
     this.createGraph();
  // The chart redraws faster than the DOM is updated with showIntervalDetails
  // setTimeout(() => this.CHART.reflow(), 100);
}


  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}

