import { Component, OnInit } from '@angular/core';
import { DataService } from "../shared/data.service";
import { FadeAnimation } from "../animations/fadeAnimation";
import { OrderByPipe } from "../shared/pipe/orderBy.pipe";
import { GenericPipe } from '../shared/pipe/generic.pipe';
import { PortalService } from "../shared/portal.service";
import { TpCustomerListService } from './tp-customer-list.service';
import { ITPGroup } from "../shared/entities/thirdParty";
import { Subscription } from 'rxjs';
import { PagerService } from "../shared/pager.service";
import {FakerService} from "../shared/faker.service";
import * as moment from 'moment';
import { IPagerDenom, PagerDenoms } from '../shared/const/pagerDenoms';
import { IUserAccount } from '../shared/entities/profile';
import { IBrokerCustomerListRequest } from '../shared/entities/broker';
declare var $: any;

@Component({
  selector: 'app-tp-customer-list',
  templateUrl: './tp-customer-list.component.html',
  styleUrls: ['./tp-customer-list.component.scss'],
  animations: [FadeAnimation],
  providers: [OrderByPipe, GenericPipe]
})
export class TpCustomerListComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private pagerService: PagerService,
    private orderByPipe: OrderByPipe,
    private portalService: PortalService,
    private pipe: GenericPipe,
    private TpCustomerListService: TpCustomerListService,
    private fakerService: FakerService
  ) { }

  myAccount: IUserAccount;

  showGroups: boolean = false;
  customerGroups: any[];
  filteredTable: any[];
  selectedGroup: any;

  pager: any;
  selectedDenom: IPagerDenom;
  pagerDenoms: IPagerDenom[];
  showSearchRow: boolean = false;
  searchTerm: string = '';
  sortBy: string = 'name';
  newGroupName: string = '';
  ascending: boolean = true;
  saving: boolean = false;
  typeaheadOptions: any[] = [];
  typeaheadId: number;
  typeaheadIndex: number = -1;
  categoryOptions: string[] = [];
  groupsSubscription: Subscription;
  editingDescription: boolean = false;
  allCustomers: any[];
  savedBefore: string = "";
  noCustomerError: boolean = false;
  maskMode: boolean;
  maskModeSubscription: Subscription;

  ngOnInit() {

    this.myAccount = this.dataService.getAccountSource();
    this.dataService.setTitleSource('Customer List');
    this.dataService.setSelectedNavItem('accounts');
    this.setCurrentActivePage('accounts')
    this.customerGroups = this.dataService.getTpGroupSource();
    this.dataService.setContextualHelp(false);
    this.groupsSubscription = this.dataService.tpGroupSourceUpdated.subscribe((groups) => {
      this.customerGroups = groups;
    });
    this.getDefaultReport();

    document.addEventListener("click", ($event) => {
      if($($event.target).parents('#showGroups').length == 0){
        this.showGroups = false;
      }
    });
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.getDefaultReport();
    });
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if (denom.count == 10) {
        this.selectDenom(denom);
      }
    });
  }

  ngOnDestroy() {
    if (this.groupsSubscription) this.groupsSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  maskCustomerName(name: string) {
    return this.fakerService.getCompanyName();
  }

  maskAddress(address: string) {
    return this.fakerService.getStreetAddress() + ', '  + this.fakerService.getCity() + ', TX ' + this.fakerService.getZipCode();
  }

  toggleGroups(){
    this.showGroups = !this.showGroups;
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';

    this.filterCustomers();
  }

  exitDescription(save?: boolean) {
    this.editingDescription = false;
    if(save) {
      this.updateGroup();
    }
  }

  resetSearch() {
    this.searchTerm = '';
    this.showSearchRow = false;
  }

  resetTypeahead() {
    this.typeaheadIndex = -1;
    this.typeaheadOptions = [];
  }

  selectTypeahead(customer, index?: number){
    if(this.typeaheadOptions.length > 0){
      if(this.typeaheadIndex === -1) {
        this.typeaheadIndex = 0;
      }
      if(index) {
        customer.category = this.typeaheadOptions[index].full;
      } else {
        customer.category = this.typeaheadOptions[this.typeaheadIndex].full;
      }
      this.resetTypeahead();
    }
  }

  navigateTypeahead(down: boolean) {
    if(down && this.typeaheadIndex < this.typeaheadOptions.length-1){
      this.typeaheadIndex++;
    } else if (!down && this.typeaheadIndex > -1) {
      this.typeaheadIndex--;
    }
  }

  typeahead(customer) {
    if(customer.category){
      this.typeaheadOptions = [];
      this.typeaheadId = customer.accountId;
      this.categoryOptions.forEach((categoryOption) => {
        for(var i = 0; i < categoryOption.length; i++){
          if(customer.category.toLowerCase() === categoryOption.substring(0, i+1).toLowerCase()) {
            let option = {
              'match': categoryOption.substring(0,i+1),
              'remainder': categoryOption.substring(i+1),
              'full': categoryOption
            };
            this.typeaheadOptions.push(option);
            break;
          }
        }
      });
    }
  }

  sortCustomers(sortBy: string, search?: boolean) {
    if(this.sortBy === sortBy && search) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }

  filterCustomers(){
    if(!this.searchTerm){
      this.filteredTable = this.allCustomers;
    } else {
      this.filteredTable = this.pipe.transform(this.allCustomers, this.searchTerm);
    }
    this.sortCustomers(this.sortBy);
  }

  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  // Apply ordering and pagination to customer table
  paginate(page: number) {
    if(((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager) && this.filteredTable){
      this.filteredTable = this.orderByPipe.transform(this.filteredTable, this.sortBy, this.ascending);
      this.pager = this.pagerService.getPage(this.filteredTable, page, this.selectedDenom.count);
    }
  }

  loadCategoryOptions() {
    // Load values from customers for type-ahead
    this.categoryOptions = [];
    this.filteredTable.forEach((customer) => {
      if(customer.category){
        let unique = true;
        this.categoryOptions.forEach((category) => {
          if(category === customer.category){
            unique = false;
          }
        });
        if(unique) {
          this.categoryOptions.push(customer.category);
        }
      }
    });
  }

  getDateDisplay(dateString: string) {
    return this.portalService.getDateDisplay(dateString);
  }

  getDescription(description: string) {
    if (description.length > 100) {
      $("#description").attr("title", description).addClass("pointer");
      return description.slice(0,100) + "...";
    }
    if ($("#description").attr("title")) {
      $("#description").removeAttr("title").removeClass("pointer");
    }
    return description;
  }

  updateStatus(customers) {
    customers.forEach( customer => {
      if (customer.status === "Y") {
        customer.status = "Active";
        return;
      }
      if (customer.futureContractMinStart || customer.maxContractEndDate) {
        if (customer.status === "N" && (moment(customer.futureContractMinStart).isSameOrAfter(moment()) || moment(customer.maxContractEndDate).isSameOrAfter(moment()))) {
          customer.status = "Future Start";
          return;
        }
      }
      customer.status = "Inactive";
    });
    return customers;
  }

  setSavedTime() {
    this.savedBefore = "Last Saved: " + moment().format("M/D/YYYY") + " at " + moment().format("H:mm:ss") + " CST";
  }

  updateCustomerCategory(customer) {
    this.saving = true;
    this.setSavedTime();
    let body = {
      key: {
        accountId: customer.accountId,
        groupId: this.selectedGroup.id
      },
      category: customer.category
    };
    this.resetTypeahead();
    if(customer.category != null){
      this.TpCustomerListService.updateTPGroupCategory(this.dataService.getAccountSource().id, this.selectedGroup.id, body).subscribe(
        (updatedCustomer) => {
          this.loadCategoryOptions();
          setTimeout(() => {
            this.saving = false;
          }, 1000);
        }, err => {
          setTimeout(() => {
            this.saving = false;
          }, 1000);
          console.error(err);
        }
      )
    } else {
      this.saving = false;
    }
  }

  updateGroup() {
    this.saving = true;
    this.setSavedTime();

    this.dataService.setLoading(true);

    this.TpCustomerListService.createOrUpdateTPGroup(this.selectedGroup).subscribe(
      (group) => {
        this.selectedGroup = group;
        this.TpCustomerListService.getTPGroups(this.dataService.getAccountSource().id).subscribe(
          (tpGroups) => {
            this.dataService.setTpGroupSource(tpGroups);
            this.dataService.setLoading(false);
          }, err => {
            console.error(err);
            this.dataService.setLoading(false);
          }
        )
        setTimeout(() => {
          this.saving = false;
        }, 1000);
      }, err => {
        setTimeout(() => {
          this.saving = false;
        }, 1000);
        console.error(err);
      }
    );
  }

  resetCreateGroupField() {
    this.newGroupName = '';
  }

  createNewGroup() {
    let newGroup: ITPGroup = {
      id: null,
      accountId: this.dataService.getAccountSource().id,
      groupName: this.newGroupName,
      description: ''
    };

    this.dataService.setLoading(true);

    this.TpCustomerListService.createOrUpdateTPGroup(newGroup).subscribe(
      (group : any) => {
        this.getGroup(group);
        this.TpCustomerListService.getTPGroups(this.dataService.getAccountSource().id).subscribe(
          (tpGroups) => {
            this.dataService.setTpGroupSource(tpGroups);
            this.dataService.setLoading(false);
          }, err => {
            console.error(err);
            this.dataService.setLoading(false);
          }
        )
      }, err => {
        this.dataService.setLoading(false);
        console.error(err);
      }
    )

    this.newGroupName = '';
    this.toggleGroups();
    $('#createGroupModal').modal('hide');
  }

  /**
   * Handles when the user wants to delete the current group
   */
  deleteGroup() {
    this.dataService.setLoading(true);
    this.TpCustomerListService.deleteTPGroup(this.dataService.getAccountSource().id, this.selectedGroup.id).subscribe(
      () => {
        this.TpCustomerListService.getTPGroups(this.dataService.getAccountSource().id).subscribe(
          (tpGroups) => {
            this.dataService.setLoading(false);
            this.dataService.setTpGroupSource(tpGroups);
          }, err => {
            this.dataService.setLoading(false);
            console.error(err);
          }
        )
      }, err => {
        this.dataService.setLoading(false);
        console.error(err);
      }
    )
    this.getDefaultReport();
  }
  /**
   * All Customer option is not part of the backend service, Wrote this function to trigger
   * whenever the user selects 'All Customer' on dropdown.
   *
   * It will trigger the UI to show a table with all the customers with no ability to edit the category
   * This will be available for all customers by default
   */
  getDefaultReport() {
    let defaultReport: ITPGroup = {
      id: 0,
      groupName: 'All Customers',
      description: ''
    }
    this.getGroup(defaultReport);
  }

  setCustomerFilter(customers) {
    customers.forEach((customer) => {
      if (customer.address.streetAddress) {
        customer.addressToSearch = customer.address.streetAddress + ', ' + customer.address.city + ', ' + customer.address.state + ' ' + customer.address.zipCode;
      }
      if (customer.maxContractEndDate) {
        customer.maxContractEndDateDisplay = moment(customer.maxContractEndDate).format('M/D/YYYY');
      }
      if (customer.futureContractMinStart) {
        customer.futureContractMinStartDateDisplay = moment(customer.futureContractMinStart).format('M/D/YYYY');
      }
    })
  }

  getFakeCompanyName(site: any) {
    return this.portalService.getFakeCompanyName(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }

  /**
   * This is triggered whenever the user selects/creates/deletes a group
   * @param tpGroup
   */
  getGroup(tpGroup: ITPGroup){
    this.dataService.setLoading(true);
    this.selectedGroup = tpGroup;

    this.TpCustomerListService.getTPCustomersForGroup(this.dataService.getAccountSource().id, this.selectedGroup.id).subscribe(
      (customers) => {
        this.dataService.setLoading(false);
        this.allCustomers = this.updateStatus(customers);
        this.setCustomerFilter(this.allCustomers);
        this.filterCustomers();
        this.loadCategoryOptions();
        if(this.allCustomers.length == 0){
          this.noCustomerError = true;
        } else {
          this.noCustomerError = false;
        }
      }, err => {
        this.dataService.setLoading(false);
        this.filteredTable = this.allCustomers = [];
        this.noCustomerError = true;
        this.paginate(0);
        console.error(err);
      }
    )
    this.resetSearch();
  }

  exportCustomerList() {
    if (this.myAccount) {
      this.dataService.setLoading(true);
      let request: IBrokerCustomerListRequest = {
        accountId: this.myAccount.id,
        accountName: this.myAccount.name,
        groupId: this.selectedGroup.id,
        groupName: this.selectedGroup.groupName,
        maskingMode: this.maskMode
      }
      this.TpCustomerListService.exportBrokerCustomerList(request);
    }
  }
}
