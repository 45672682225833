import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
import { FilterType, ISubscribeReportTemplate, ReportFilter, ReportFilterOption } from '../shared/entities/subscribeReportTemplate';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { SitesControl } from '../shared/entities/controls';
import { ISiteGroup } from '../shared/entities/site';
import { ISite } from '../shared/entities/site';
import { Subscription } from 'rxjs';
import { PortalService } from '../shared/portal.service';
import { SiteGroupService } from '../site-groups/site-groups.service';
import { IUserAccount, IProfile } from '../shared/entities/profile';
import { ReportDataUtility } from './report-data.utility';
import { DatePeriodsArr, WeeklyDatePeriods } from '../shared/const/datePeriods';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';

declare var $: any;



import * as moment from 'moment';
import { ISubscription } from '../shared/entities/widgets/subscriptions';
import { FakerService } from '../shared/faker.service';



@Component({
  selector: 'app-subscribe-to-report',
  templateUrl: './subscribe-to-report.component.html',
  styleUrls: ['./subscribe-to-report.component.scss']
})
export class SubscribeToReportComponent implements OnInit {

  @Input() reportTemplate: ISubscribeReportTemplate;
  @Input() allReports: any[];
  @Input() columns: any[];
  @Input() selectedReport: any;

  @Output() reportSaved: EventEmitter<any> = new EventEmitter();
  @Output() reportSelected: EventEmitter<any> = new EventEmitter();
  @Output() columnsChanged: EventEmitter<any> = new EventEmitter();
  @Output() reportDeleted: EventEmitter<any> = new EventEmitter();
  @Output() exportTriggered: EventEmitter<any> = new EventEmitter();



  init:boolean = true;

  myAccount: IUserAccount;
  myProfile: IProfile;

  tempReportTemplate: ISubscribeReportTemplate;

  combinedReports:any[];
  privateReports: any[];
  publicReports: any[];

  hasSitePicker:boolean = false;
  hasDatePicker:boolean = false;

  showFilters:boolean = false;
  showReports:boolean = false;
  showOptions:boolean = false;
  showSites:boolean = false;

  allSites: ISite[];
  filteredSites: ISite[];
  siteIndex: number = -1;

  descriptionText : string = "";


  isSaveDisabled = true;
  isSubscribeDisabled = false;
  fakeHasChanged = false;

  // Subscription Variables
  subscription: ISubscription;
  subscriptionOptionsHeaderText:string;
  subscribedReports:any[];
  showSubscribeOptions: boolean;
  activeReportSubscription:boolean;
  subscriptionOptions: any = {
    frequencies: [
      { 'display': 'Daily', 'option': 'DAILY', 'selected':false },
      { 'display': 'Weekly', 'option': 'WEEKLY','selected':false },
       { 'display': 'Monthly', 'option': 'MONTHLY','selected':false }],
    deliveries: WeeklyDatePeriods,
    description: '',
    valid: false
  };
  activeSubscriptionId: number;

  // Site Variables
  sitesControl: SitesControl;
  userSiteGroups: ISiteGroup[] = [];
  systemSiteGroups: ISiteGroup[] = [];
  siteGroupsSourceSubscription: Subscription;
  groupCategoryDisabled: boolean = true;


  // Date Variables
  dateRangeControl: any = {
    periodSelected: true,
    toggled: false,
    show: false,
    startDate: {},
    endDate: {},
    datePeriod: 'Last3Months',
    datePeriodDisplay: 'Last 3 Months',
    dateRangeDisplay: '',
    dateType: 'BILL POST DATE'
  };
  daysOfWeek = WeeklyDatePeriods;
  dailySubscriptionType: boolean;
  weeklySubscriptionType: boolean;
  monthlySubscriptionType: boolean;
  tempDateRangeControl: any = {};
  dateTypeSelected = true;
  datePeriods: any[];
  dateDisabled = false;
  selectedStartDate: string;
  selectedEndDate: string;
  billingDateRanges: any[];
  endDates: any[];

  // New Report Variables
  newReportName = '';
  makeNewDefault = false;
  makeNewPublic = false;

  reportSubscriptionDateRangesSourceSubscription: Subscription;


  // ReportRequests
  reportRequest: any = {};
  tempPrevRequest: any = {};
  editingReportName:boolean = false;
  makeDefault = true;
  makePublic = false;

  // Columns for Select Columns Side NAve
  tableColumns: any[]; // Columns iterated over the table, taken from columns model in applyColumnChanges()

  maskMode: boolean;
  maskModeSubscription: Subscription;

  contractsSitesGrouping = {
    groupName: 'CONTRACTS',
    description: 'Contracts',
  };

  constructor(private dataService: DataService,private router: Router, private portalService:PortalService, private siteGroupsService:SiteGroupService, private utility: ReportDataUtility, private fakerService: FakerService,
    private subscriptionsService: SubscriptionsService

    ) { }

  ngOnInit(): void {
    this.init = true;
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
      (maskMode) => {
        this.maskMode = maskMode;
      }
    );
    this.subscription = {
      description: '',
      frequencyType: 'DAILY',
      weeklyFrequencySelector: {'option':'MONDAY'},
      frequencyTypeSelector: {'option': 'DAILY'}
    };
    this.allReports.forEach(rpt => {
      rpt.fakeReportName = this.fakerService.generateFakeReportName();
    })
    this.combinedReports = this.allReports;
    this.showSubscribeOptions = false;
    this.sitesControl = new SitesControl();
    this.sitesControl.selectedTypeDisplay = 'GROUP';
    if(this.reportTemplate.reportType == 'BILLING_SUMMARY'){
      const contractsSitesGrouping = {
        groupName: 'CONTRACTS',
        description: 'Contracts',
      };
      this.sitesControl.selectedGrouping = contractsSitesGrouping;
      this.sitesControl.selectedGrouping.display = contractsSitesGrouping.description;
    } else {
      const allSitesGrouping = {
        groupName: 'All Sites',
        description: 'All Sites',
      };
      this.sitesControl.selectedGrouping = allSitesGrouping;
      this.sitesControl.selectedGrouping.display = allSitesGrouping.description;
    }

    this.myAccount = this.dataService.getAccountSource();
    this.myProfile = this.dataService.getProfile();
    this.hideSidenav();

    this.subscriptionOptionsHeaderText = 'Subscribe';

    // Subscribe to report Template Settings. Necessary for the recursive generation of filters
    this.tempReportTemplate = this.reportTemplate;

    this.tempReportTemplate.filters.forEach(filter =>{
      if(filter.filterType === FilterType.SitePicker){
        this.hasSitePicker = true;
      } else if(filter.filterType === FilterType.DatePicker) {
        this.hasDatePicker = true;
        this.datePeriods = DatePeriodsArr;
      }
    });


    // Report has SitePicker Filter
    if(this.hasSitePicker){
      const siteGroups = this.dataService.getSiteGroupsSource();

      // Loading Options for the Sites Filter
      if (siteGroups) {
        this.userSiteGroups = siteGroups.userSiteGroups;
        this.systemSiteGroups = siteGroups.systemSiteGroups;
        this.loadSiteOptions();
      } else {
        this.siteGroupsSourceSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
          (siteGroups) => {
            // Manula
            this.userSiteGroups = siteGroups.userSiteGroups;
            this.systemSiteGroups = siteGroups.systemSiteGroups;
            this.loadSiteOptions();
          }
        );
      }

      this.tempReportTemplate.filters.forEach(filter =>{
        if(filter.filterType === FilterType.SitePicker){
          filter.selectedOptionLabel = this.sitesControl.display;
        }
      });
    }

    // Report has DatePicker Filter
    if(this.hasDatePicker){
      // Loading Options for the Date Filter
      this.tempReportTemplate.filters.forEach(filter =>{
        if(filter.filterType === FilterType.DatePicker) {
          filter.selectedOptionLabel = this.dateRangeControl.datePeriodDisplay;

          this.reportSubscriptionDateRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
            (billingDateRanges) => {
              this.billingDateRanges = billingDateRanges;
              this.endDates = billingDateRanges;
            });
          this.calculateDateRanges();
        }
      });
    }
    this.subscribedReportsCheck();

    const hasSavedReports = this.allReports.find(rpt => rpt.reportId != null);

    if (hasSavedReports === null || hasSavedReports === undefined) {
      this.isSaveDisabled = false;
    }
    this.isSubscribeDisabled = hasSavedReports === undefined || hasSavedReports === null;

        // Click outside event for date range and groupings sections
    document.addEventListener('click', ($event) => {
      if ($($event.target).parents('#subscriptionOptionSection').length == 0) {
        this.showSubscribeOptions = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.reportSubscriptionDateRangesSourceSubscription) this.reportSubscriptionDateRangesSourceSubscription.unsubscribe();
    if (this.siteGroupsSourceSubscription) this.siteGroupsSourceSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  loadReports() {
    let userHasDefaultReport = false;
    const defaultReport = this.getDefaultReport();
    this.setDefaultDateRange();
    // this.allReports.push(defaultReport);
    this.allReports.forEach((report)=>{
      if(!userHasDefaultReport) {
        if(report.defaultReport) {
          this.selectedReport = report;
          userHasDefaultReport = true;
        }
      }
    });
    if(!userHasDefaultReport) {
      this.selectedReport = this.allReports[0];
    }
    this.privateReports = [defaultReport];
    this.publicReports = [];
    this.getSiteControlDisplay();
    this.loadSelectedReport();
  }

  getDefaultReport() {
    if(this.reportTemplate.reportType == 'BILLING_SUMMARY'){
      const contractsSitesGrouping = {
        groupName: 'CONTRACTS',
        description: 'Contracts',
      };
      this.sitesControl.selectedGrouping = contractsSitesGrouping;
      this.sitesControl.selectedGrouping.display = contractsSitesGrouping.description;
    } else {
      const allSitesGrouping = {
        groupName: 'All Sites',
        description: 'All Sites',
      };
      this.sitesControl.selectedGrouping = allSitesGrouping;
      this.sitesControl.selectedGrouping.display = allSitesGrouping.description;
    }
    this.sitesControl.siteRequest = 'GROUP';
    let defaultColumns = this.selectedReport.columnNames != ' ' && this.selectedReport.columnNames != null ? this.selectedReport.columnNames : ' ';
    const defaultReport = {
      reportId: null,
      columnNames: defaultColumns,
      accountId: this.myAccount.id,
      reportName: 'OAM Default Report',
      periodType: 'Last3Months',
      defaultReport: 0,
      startDate: null,
      endDate: null,
      dateType: 'BILL POST DATE',
      systemGroupName: 'ALL_SITES',
      publicFlag: 0,
      createdUserId: this.dataService.getUserSource().login
    };
    return defaultReport;
  }



  loadPreSelectedFilters(){
    this.reportTemplate.filters.forEach((filter)=> {
      if(filter.filterType === FilterType.SitePicker){
        filter.savedOptionLabel = this.sitesControl.display;
        filter.savedOptionValue = this.sitesControl.display;
        filter.selectedOptionLabel = this.sitesControl.display;
        filter.selectedOptionValue = this.sitesControl.display;
      } else if (filter.filterType === FilterType.DatePicker) {
        filter.savedOptionLabel = this.dateRangeControl.dateRangeDisplay;
        filter.savedOptionValue = this.dateRangeControl.dateRangeDisplay;
        filter.selectedOptionLabel = this.dateRangeControl.dateRangeDisplay;
        filter.selectedOptionValue = this.dateRangeControl.dateRangeDisplay;
      }
    });
    this.tempReportTemplate.filters.forEach((filter)=> {
      if(filter.filterType === FilterType.SitePicker){
        filter.savedOptionLabel = this.sitesControl.display;
        filter.savedOptionValue = this.sitesControl.display;
        filter.selectedOptionLabel = this.sitesControl.display;
        filter.selectedOptionValue = this.sitesControl.display;
      } else if (filter.filterType === FilterType.DatePicker) {
        filter.savedOptionLabel = this.dateRangeControl.dateRangeDisplay;
        filter.savedOptionValue = this.dateRangeControl.dateRangeDisplay;
        filter.selectedOptionLabel = this.dateRangeControl.dateRangeDisplay;
        filter.selectedOptionValue = this.dateRangeControl.dateRangeDisplay;
      }
    });
  }

  loadSelectedReport() {
    // Site Control Handler
    if (this.selectedReport.systemGroupName == 'SPECIFIC_SITE') {
      this.sitesControl.siteRequest = 'SPECIFIC_SITE';
      this.sitesControl.display = '...' + this.selectedReport.siteGroupCategory.substr(5);
      this.sitesControl.siteSearch = this.selectedReport.siteGroupCategory;
      //this.sitesControl.selectedCategory = this.selectedReport.siteGroupCategory;
      //this.searchSites();
    }
    else if (this.selectedReport.systemGroupName == 'ALL_SITES') {
      const allSitesGrouping = {
        groupName: 'All Sites',
        description: 'All Sites',
      };
      this.sitesControl.selectedGrouping = allSitesGrouping;
      this.sitesControl.selectedGrouping.display = allSitesGrouping.description;
      this.sitesControl.display = allSitesGrouping.description;
      this.sitesControl.siteRequest = 'GROUP';
    }
    else if (this.selectedReport.groupId) {
      this.userSiteGroups.forEach((siteGroup) => {
        if (siteGroup.id.toString() == this.selectedReport.groupId) {
          this.sitesControl.selectedGrouping = siteGroup;
          if (siteGroup.groupName.length > 18) {
            this.sitesControl.selectedGrouping.display = siteGroup.groupName.substring(0, 18) + '...';
            this.sitesControl.display = siteGroup.groupName.substring(0, 18) + '...';
          } else {
            this.sitesControl.selectedGrouping.display = siteGroup.groupName;
            this.sitesControl.display = siteGroup.groupName;
          }
        }
      });
      this.sitesControl.siteRequest = this.selectedReport.siteRequest;
      if (this.selectedReport.siteRequest != null && this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
        this.sitesControl.selectedCategory = this.selectedReport.siteGroupCategory;
      }
    }
    else if (this.selectedReport.systemGroupName) {
      this.systemSiteGroups.forEach((systemGroup) => {
        if (systemGroup.groupName == this.selectedReport.systemGroupName) {
          this.sitesControl.selectedGrouping = systemGroup;
          this.sitesControl.selectedGrouping.display = systemGroup.display;
          this.sitesControl.display = systemGroup.display;
        }
      });
      this.sitesControl.siteRequest = 'GROUP';
      // this.sitesControl.siteRequest = this.selectedReport.siteRequest;
      if (this.selectedReport.siteRequest != null && this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
        this.sitesControl.selectedCategory = this.selectedReport.siteGroupCategory;
      }
    }

    // Boolean Flags
    this.makeDefault = this.selectedReport.defaultReport == 1;
    this.makePublic = this.selectedReport.publicFlag == 1;
    this.dateRangeControl.dateType = this.selectedReport.dateType;

    // Date Range Control Handler
    if (this.reportTemplate.reportType == 'BROKER_ENROLL') {
      this.dateRangeControl.includeSitesWithoutMoveIn = this.selectedReport.reportSpecificBoolean;
    }
    if (this.dateRangeControl.dateType == 'BILL POST DATE') {
      this.dateTypeSelected = true;
    } else {
      this.dateTypeSelected = false;
    }
    if (this.selectedReport.periodType) {
      this.dateRangeControl.periodSelected = true;
      this.dateRangeControl.datePeriod = this.selectedReport.periodType;
      this.getDatePeriodDisplay(this.dateRangeControl);
      this.generateDatesFromPeriod(this.dateRangeControl);
    } else {
      this.dateRangeControl.periodSelected = false;
      this.dateRangeControl.startDate = this.selectedStartDate = this.selectedReport.startDate;
      this.dateRangeControl.endDate = this.selectedEndDate = this.selectedReport.endDate;
    }
    this.getDateRangeDisplay();
    this.loadPreSelectedFilters();
    if(this.isSubscribedToReport(this.selectedReport)){
      this.activeReportSubscription = this.isSubscribedToReport(this.selectedReport);
      this.subscriptionOptionsHeaderText = this.activeReportSubscription ? "Subscribed" : "Subscribe";
    }
  }

  createReport() {
    this.selectedReport.reportName = this.newReportName;
    let report = this.getDefaultReport();
    report.reportName = this.newReportName;
    report.publicFlag = this.makeNewPublic ? 1 : 0;
    report.defaultReport = this.makeNewDefault ? 1 : 0;
    this.allReports = [...this.allReports, report];
    this.selectedReport = report;
    this.selectedReport.reportName = this.newReportName;
    this.selectedReport.reportSpecificBoolean = this.dateRangeControl.includeSitesWithoutMoveIn;
    this.saveReport();
    this.loadSelectedReport();
    if(this.columns != null) {
      this.setDefaultColumns();
    }
    this.selectedReport.reportName = this.newReportName;
    this.activeReportSubscription = false;
    this.subscriptionOptionsHeaderText = 'Subscribe';
    $('#createAnyReportModal').modal('hide');
  }

  toggleNewReportCheckbox() {
    this.makeNewPublic = !this.makeNewPublic;
  }

  getReportNameDisplay(cutOff: number) {
    let selectedReportName = this.maskMode ? this.selectedReport.fakeReportName : this.selectedReport.reportName;
    if (selectedReportName.defaultReport == 1) {
      cutOff = cutOff - 8;
    }
    if (selectedReportName.publicFlag == 0) {
      cutOff = cutOff - 8;
    }
    if (window.innerWidth < 1350) {
      cutOff = 20;
    }
    if (selectedReportName) {
      return selectedReportName.length <= cutOff ? selectedReportName : selectedReportName.substr(0, cutOff) + '...';
    }
  }

  selectReport(report: any) {
    this.selectedReport = report;
    if(this.isSubscribedToReport(this.selectedReport)){
      this.activeReportSubscription = true;
      this.subscriptionOptionsHeaderText = 'Subscribed'
    } else {
      this.activeReportSubscription = false;
      this.subscriptionOptionsHeaderText = 'Subscribe'
    }
    if (this.selectedReport.defaultReport) {
      this.makeDefault = true;
    } else {
      this.makeDefault = false;
    }

    if (this.selectedReport.publicFlag) {
      this.makePublic = true;
    } else {
      this.makePublic = false;
    }
    this.loadSelectedReport();
    this.getSiteControlDisplay();
    this.reportSelected.emit(this.selectedReport);

  }

  toggleReports() {
    this.showReports = !this.showReports;
  }

  closeReports() {
    this.showReports = false;

  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    this.isSaveDisabled = true;
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  selectFilterOption(selectedOptionLabel:string,selectedOptionValue:string, index:number) {
    this.tempReportTemplate.filters[index].selectedOptionValue = selectedOptionValue;
    this.tempReportTemplate.filters[index].selectedOptionLabel = selectedOptionLabel;
    if (this.reportTemplate.reportType === "USAGE_SUMMARY") {
      this.selectedReport.frequencyType = selectedOptionValue;
    }
    this.isSaveDisabled = false;
    this.reportSelected.emit(this.selectedReport);
  }

  toggleFilterMenuForButton(filter:ReportFilter) {
    if(filter.filterType === FilterType.SitePicker){
      filter.showfilter = !filter.showfilter;
      this.toggleSites(filter);
    } else if (filter.filterType === FilterType.DatePicker) {
      this.toggleDateRange(filter);
    } else {
      filter.showfilter = !filter.showfilter;
    }
  }

  toggleGroupings() {
    this.showSites = !this.showSites;
  }

  renameReport() {
    this.editingReportName = true;
    this.selectedReport.reportName = this.newReportName;
    this.saveReport();
    this.toggleOptions();
    $('#renameModal').modal('hide');
  }

  setDefaultReport() {
    this.selectedReport.defaultReport = 1;
    this.saveReport();
  }

  saveAsPublicReport() {
    this.makePublic = true;
  }

  saveAsPrivateReport() {
    this.makePublic = false;
  }

  saveReportModal(){
    this.editingReportName = true;
    this.selectedReport.publicFlag = this.makePublic ? 1 : 0;
    this.selectedReport.reportName = this.newReportName;
    this.selectedReport.reportId = null;
    this.selectedReport.defaultReport = this.makeNewDefault ? 1 : 0;
    this.selectedReport.createdUserId = null;
    this.saveReport();
    this.toggleOptions();
    this.makePublic = false;
    this.makeNewDefault = false;
    this.selectedReport.reportName = this.newReportName;
    $('#saveAsModal').modal('hide');
  }

  deleteReportModal() {
    if (this.isSubscribedToReport(this.selectedReport)) {
      $('#deleteSubscribeModal').modal('show');
    } else {
      $('#deleteModal').modal('show');
    }
  }

  deleteReport() {
    this.reportDeleted.emit(this.selectedReport);
    this.allReports = this.allReports.filter(report => report.reportId !== this.selectedReport.reportId);
    if (this.allReports.length == 0) {
      this.isSaveDisabled = false;
      this.isSubscribeDisabled = true
    }
    this.selectedReport = this.allReports[0];
  }

  subscriptionsButtonHandler() {
    if (this.activeReportSubscription) {
      this.showSubscribeOptions = !this.showSubscribeOptions;
    } else {
      $('#subscribeModal').modal('show');
    }
  }

  subscribedReportsCheck() {
    if (this.myProfile) {
      this.subscriptionsService.getSubscriptionForReport(this.myProfile.userId, this.reportTemplate.reportType).subscribe(
        (subscribedReports : any) => {
          this.subscribedReports = subscribedReports;
          // console.log("this.subscribedReports: ", this.subscribedReports);
          // this.validateSubscription()
          this.loadReports();
        }
      );
    }
    else {
      this.subscribedReports = [];
    }
  }

  setSubscriptionSetting(value, type) {
    if (type == 'frequencyType') {
      this.subscription.frequencyTypeSelector = {'option': value.option};
      this.subscription.frequencyType = value.option;
      if (this.subscription.frequencyType == 'WEEKLY') {
        this.subscriptionOptions.deliveries = WeeklyDatePeriods;
        this.subscription.monthlyFrequency = null;
        this.subscription.monthlyFrequencySelector = null;
        this.subscription.weeklyFrequencySelector = this.subscription.weeklyFrequencySelector ? this.subscription.weeklyFrequencySelector : 'MONDAY';
      }
      if (this.subscription.frequencyType == 'MONTHLY') {
        this.subscriptionOptions.deliveries = this.setMonthDays();
        this.subscription.weeklyFrequency = null;
        this.subscription.monthlyFrequencySelector = null;
        this.subscription.weeklyFrequencySelector = null;
      }
    }
    if (type == 'dailyFrequency'){
      this.subscription[type] = value
    }
    else if (type == 'monthlyFrequency') {
      this.subscription[type] = value.option;
      this.subscription.monthlyFrequencySelector = {'option': value};
    }
    else if (type == 'weeklyFrequency') {
      this.subscription[type] = value;
      this.subscription.weeklyFrequencySelector = {'option': value};
    }
  }

  setMonthDays() {
    const days = 28;
    const deliveries = [];

    for (let i = 0; i < days; i++) {
      if (i != (days - 1)) {
        const dayOfMonth = moment((i + 1) + '-01-18', 'D-MM-YY').format('Do');
        deliveries.push({ option: (i + 1), display: dayOfMonth, selected: false });
      } else {
        deliveries.push({ option: '99 ', display: 'Last Day of the Month ', selected: false });
      }
    }

    return deliveries;
  }

  saveSubscription(){
    $('#subscribeModal').modal('show');
    this.subscription.reportType = this.reportTemplate.reportType;
    this.subscription.reportId = this.selectedReport.reportId;
    this.subscription.userId = this.myProfile.userId;
    // add new columns
    this.subscription.accountId = this.myAccount.id;
    this.subscription.customerName = this.myAccount.name;
    this.subscription.thirdPartyFlag = this.myAccount.accessType == 'ThirdParty';
    this.subscription.email = this.myProfile.email;
    this.subscription.firstName = this.myProfile.firstName;
    this.subscription.lastName = this.myProfile.lastName;
    this.subscription.deleted = false;
    this.subscription.description = this.descriptionText;
    this.subscription.frequencyType = this.subscription.frequencyTypeSelector.option;
    this.subscription.reportId = this.selectedReport.reportId;
    this.subscription.userId = this.myProfile.userId;
    this.subscription.frequencyType = this.subscription.frequencyTypeSelector.option;
    if (this.subscription.monthlyFrequencySelector) {
      const monthlySubscribtionOption = this.subscription.monthlyFrequencySelector.option;
      this.subscription.monthlyFrequency = monthlySubscribtionOption.option;
    }
    if (this.subscription.weeklyFrequencySelector) {
      this.subscription.weeklyFrequencySelector.option === '' ? this.subscription.weeklyFrequency ="MONDAY" : this.subscription.weeklyFrequency = this.subscription.weeklyFrequencySelector.option;
    }
    this.subscriptionsService.saveNewSubscription(this.subscription).subscribe((response : any) => {
      // console.log("response: ", response);
      if (response && response.id) {
        this.subscribedReports.push(response);
        // this.subscribedReports = true;
        this.descriptionText = "";
        this.subscription = {};
        if(this.isSubscribedToReport(this.selectedReport)) {
          this.subscriptionOptionsHeaderText = 'Subscribed';
          this.activeReportSubscription = true;
        }
      }
    });
  }

  isSubscribedToReport(report) {
    let isSubscribed = false;
    this.subscribedReports.forEach(
      (subscribed) => {
        if (report.reportId + '' == subscribed.reportId + '') {
          isSubscribed = true;
          this.activeSubscriptionId = subscribed.id;
        }
      }
    );
    return isSubscribed;
  }

  unsubscribeToReport() {
    this.dataService.setLoading(true);
    this.subscription.id = this.activeSubscriptionId;
    this.subscriptionsService.deleteSubscription(this.subscription).subscribe(
      (response) => {
        console.log("DELETE RESPONSE: ", response);
        this.activeReportSubscription = false;
        const index = this.subscribedReports.findIndex(rtp => rtp.id === this.activeSubscriptionId);
        if (index !== -1) {
          this.subscribedReports.splice(index, 1);
        }
        this.activeSubscriptionId = -1;
        this.subscriptionOptionsHeaderText = this.activeReportSubscription ? "Subscribed" : "Subscribe";
        this.dataService.setLoading(false);
      }, err => {
        this.dataService.setLoading(false);
      }
    );
  }

  newReportModalOpen() {
    this.makeNewDefault = false;
    this.makeNewPublic = false;
    this.newReportName = '';
    $('#createAnyReportModal').modal('show');
  }

  saveReport(){
    if(!this.dateRangeControl.periodSelected && this.isSubscribedToReport(this.selectedReport)){
      this.unsubscribeToReport();
    }
    this.reportTemplate = this.tempReportTemplate;
    this.showFilters= false;
    this.reportSaved.emit(this.selectedReport);
    this.isSaveDisabled = true;
    this.isSubscribeDisabled = false;
  }

  manageSubscriptions() {
    this.dataService.setManageSubscriptionStatus('Y');
    // console.log('I am calling from billing summary');
    this.router.navigate(['/profile']);
  }

  exportReport() {
    let newReport = {
      userId : this.myProfile.userId,
      subscriptionId : this.selectedReport.reportId,
      reportType : this.reportTemplate.reportType,
      exportType : 'EXPORT',
      reportLink : 'TODO',
      accountId : this.myAccount.id,
      publishDate : this.formatDate(moment().toDate()),
      expiryDate : this.formatDate(moment().add(30, 'days').toDate())
    }
    // TODO: Uncomment when we are actually storing reports.
    // this.subscriptionsService.saveUserReport(newReport).subscribe((response=>{
    //   console.log(newReport + " saved!");
    // }));
    this.exportTriggered.emit(this.selectedReport);
  }

  formatDate(date : Date){

    let day = date.getDate();
    let month = date.getMonth() + 1; // method is 0 based
    let monthString = month.toString();
    if(month < 10){
      monthString = '0' + month.toString();
    }
    let year = date.getFullYear();

    let finalDate = year.toString() + '-' + monthString + '-' + day.toString();
    return finalDate;
  }

  getSiteControlDisplay() {

    if (this.sitesControl.siteRequest == 'GROUP') {
      this.sitesControl.selectedTypeDisplay = 'Group';
      this.sitesControl.catDisplay = this.sitesControl.selectedGrouping.display;
      this.sitesControl.display = this.sitesControl.selectedGrouping.display;
      return this.sitesControl.selectedGrouping.display;

    } else if (this.sitesControl.siteRequest == 'GROUP_CATEGORY') {
      this.sitesControl.selectedTypeDisplay = 'Group Category';
      this.sitesControl.catDisplay = this.sitesControl.selectedGrouping.groupName;
      this.sitesControl.display = this.sitesControl.selectedCategory;
      return this.sitesControl.selectedCategory;
    }
    else if (this.sitesControl.siteRequest == 'SPECIFIC_SITE') {
      this.sitesControl.catDisplay = 'Specific Site';
      this.sitesControl.selectedTypeDisplay = 'Specific Site';
      if (this.sitesControl.selectedSite != null) {
        this.sitesControl.display = '...' + this.sitesControl.selectedSite.siteId.substr(5);
        return this.sitesControl.selectedSite.siteId
      }
      else {
        this.sitesControl.display = '...' + this.selectedReport.siteGroupCategory.substr(5);
        return this.selectedReport.siteGroupCategory
      }
    }
  }

  selectGroup(group: ISiteGroup) {
    this.groupCategoryDisabled = true;
    this.dataService.setLoading(true);
    if (this.sitesControl.selectedGrouping != group) {
      this.sitesControl.selectedCategory = null;
      this.sitesControl.selectedGrouping = group;
    }
    if (this.sitesControl.selectedGrouping.id) {
      if (this.sitesControl.selectedGrouping.groupName.length > 15) {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName.substring(0, 15) + '...';
      } else {
        this.sitesControl.selectedGrouping.display = this.sitesControl.selectedGrouping.groupName;
      }
      this.siteGroupsService.getCategoriesForUserGroup(this.sitesControl.selectedGrouping.id).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
          this.groupCategoryDisabled = false;
          this.dataService.setLoading(false);
        }
      );
    }
    else {
      this.siteGroupsService.getCategoriesForSystemGroup(this.sitesControl.selectedGrouping.groupName).subscribe(
        (categories : any) => {
          this.sitesControl.categoriesForGroup = categories;
          this.groupCategoryDisabled = false;
          this.dataService.setLoading(false);
        }
      );
    }
    //this.sitesControl.selectedCategory = null;
  }

  selectGrouping(grouping: any, isSystem: boolean) {
    this.fakeHasChanged = true;

    if (grouping == 'ALL_SITES') {
      grouping = {
        groupName: 'All Sites',
        display: 'All Sites'
      };
    }
    this.sitesControl.selectedGrouping = grouping;

    // Get grouping display
    if (!isSystem) {
      this.userSiteGroups.forEach((userGroup) => {
        if (grouping.id == userGroup.id) {
          if (userGroup.groupName.length > 18) {
            this.sitesControl.selectedGrouping.display = userGroup.groupName.substring(0, 18) + '...';
          } else {
            this.sitesControl.selectedGrouping.display = userGroup.groupName;
          }
        }
      });
    } else {
      if (grouping) {
        if (grouping.groupName == 'All Sites') {
          this.sitesControl.selectedGrouping.display = 'All Sites';
        } else {
          this.systemSiteGroups.forEach((systemGroup) => {
            if (grouping.groupName == systemGroup.groupName) {
              this.sitesControl.selectedGrouping.display = systemGroup.display;
            }
          });
        }
      }
    }
    // this.validateSubscription();
  }

  setFilterDisplay(label: string) {
    if (!label) {
      return "";
    }
    label = label.replace("_", " ");
    if (label.length > 25) {
      return label.substring(0, 25) + "...";
    }
    return label;
  }

  selectSpecificGroup() {
    if (this.sitesControl.siteRequest != 'GROUP_CATEGORY') {
      this.selectGrouping(null, true);
      this.groupCategoryDisabled = true;
    }
  }

  loadSiteOptions() {
    this.allSites = this.dataService.getSitesForAccountSource();
    this.allSites.forEach((site) => {
       this.sitesControl.siteOptions.push(site.siteId + ' ' + site.name);
    });
  }

  searchSites() {
    if (this.allSites != null) {
      this.filteredSites = [];
      this.allSites.forEach((site) => {
        if (site.siteId.match(this.sitesControl.siteSearch) ||
          (site.serviceAddress && site.serviceAddress.streetAddress.toLowerCase().includes(this.sitesControl.siteSearch.toLowerCase())) ||
          this.sitesControl.siteSearch == '') {
          this.filteredSites.push(site);
        }
      });
    }
  }

  selectSite(site: ISite, index?: number) {
    if (this.filteredSites.length > 0) {
      if (this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if (index) {
        this.sitesControl.selectedSite = this.filteredSites[index];
      } else {
        this.sitesControl.selectedSite = this.filteredSites[this.siteIndex];
      }
      if (!this.maskMode) {
        this.sitesControl.siteSearch = this.sitesControl.selectedSite.siteId + ' ' + this.sitesControl.selectedSite.name;
      } else {
        // this.sitesControl.siteSearch = this.getFakeSiteId(site) + ' ' + this.fakerService.getCompanyName();
      }
      this.resetTypeahead();
    }
  }

  navigateTypeahead(down: boolean) {

    if (down && this.siteIndex < this.filteredSites.length - 1) {
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  getCategoryDisplay() {
    if (this.sitesControl.selectedGrouping == null && this.sitesControl.selectedCategory != null)
      this.sitesControl.selectedCategory = 'Select a category';
    if (this.sitesControl.selectedGrouping != null && this.sitesControl.selectedCategory) {
      if (this.sitesControl.selectedGrouping.groupName == 'COLLECTIVE_BILLING' && this.maskMode) {
        return '80012345';
      }
      if (this.sitesControl.selectedGrouping.display == 'Contracts') {
        return this.getContractNickname(this.sitesControl.selectedCategory);
      }
      else {
        return this.sitesControl.selectedCategory;
      }
    }
    else {
      return 'Select a category';
    }
  }

  getContractNickname(contractNum: string) {
    return this.portalService.getContractNickname(contractNum);
  }

  goToSiteGroups() {
    this.router.navigate(['/site-groups']);
  }

  selectCategory(category: string) {
    this.sitesControl.selectedCategory = category;
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }
  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }

  applySites(filter) {
    // Apply changes in customerControl to selectedReport
    this.selectedReport.siteRequest = this.sitesControl.siteRequest;
    if (this.selectedReport.siteRequest == 'GROUP') {
      this.sitesControl.siteRequest = 'GROUP';
      if (this.sitesControl.selectedGrouping.id) {
        this.selectedReport.groupId = this.sitesControl.selectedGrouping.id;
        this.selectedReport.systemGroupName = null;
        filter.selectedOptionLabel = this.sitesControl.selectedGrouping.groupName;
      }
      else {
        this.selectedReport.systemGroupName = this.sitesControl.selectedGrouping.groupName;
        filter.selectedOptionLabel = this.sitesControl.selectedGrouping.groupName;
        filter.selectedOptionValue = this.sitesControl.selectedGrouping.groupName;
        this.selectedReport.groupId = null;
      }
      this.selectedReport.siteId = null;
      this.selectedReport.siteGroupCategory = null;
      this.sitesControl.selectedCategory = null;
      this.sitesControl.display = filter.selectedOptionLabel;
    }
    else if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
      this.sitesControl.siteRequest = 'GROUP_CATEGORY';
      this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
      if (this.sitesControl.selectedGrouping.id) {
        this.selectedReport.groupId = this.sitesControl.selectedGrouping.id;
        filter.selectedOptionLabel = this.sitesControl.selectedGrouping.id;

        this.selectedReport.systemGroupName = null;
      }
      else {
        this.selectedReport.systemGroupName = this.sitesControl.selectedGrouping.groupName;
        filter.selectedOptionLabel = this.sitesControl.selectedGrouping.groupName;

        this.selectedReport.groupId = null;
      }
      this.selectedReport.siteId = null;
      this.sitesControl.display = `${filter.selectedOptionLabel} - ${this.sitesControl.selectedCategory}`;
    }
    else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
      this.sitesControl.siteRequest = 'SPECIFIC_SITE';
      this.sitesControl.selectedGrouping = null;
      this.selectedReport.selectedSite = this.sitesControl.selectedSite;
      this.selectedReport.siteGroupCategory = this.sitesControl.selectedSite.siteId;
      filter.selectedOptionLabel = this.sitesControl.selectedSite.siteId;

      this.selectedReport.systemGroupName = this.selectedReport.siteRequest;
      this.selectedReport.siteRequest = 'SPECIFIC_SITE';
      this.selectedReport.groupId = null;
      this.sitesControl.display = `${this.sitesControl.selectedSite.siteId} - ${this.sitesControl.selectedSite.name}`;
    }
    filter.showfilter = !filter.showfilter;
    this.isSaveDisabled = false;
    this.reportSelected.emit(this.selectedReport);
    this.loadPreSelectedFilters();
  }

  cancel(filter:ReportFilter) {
    this.toggleGroupings();

    this.selectedReport.siteRequest = this.tempPrevRequest;
    this.sitesControl.siteRequest = this.tempPrevRequest;


    if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
      this.systemSiteGroups.forEach((systemGroup) => {
        if (systemGroup.groupName == this.selectedReport.systemGroupName) {
          this.selectGrouping(systemGroup, true);
        }
      });
      this.userSiteGroups.forEach((userGroup) => {
        if (userGroup.id == this.selectedReport.groupId) {
          this.selectGroup(userGroup);
        }
      });
      this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
    }
    else if (this.selectedReport.siteRequest == 'GROUP') {
      this.systemSiteGroups.forEach((systemGroup) => {
        if (systemGroup.groupName == this.selectedReport.systemGroupName) {
          this.selectGrouping(systemGroup, true);
        }
      });
      this.userSiteGroups.forEach((userGroup) => {
        if (userGroup.id == this.selectedReport.groupId) {
          this.selectGroup(userGroup);
        }
      });
    }
    else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
      this.filteredSites.forEach((site, index) => {
        if (site.siteId == this.selectedReport.siteId) {
          this.selectSite(site, index);
          this.sitesControl.selectedCategory = this.selectedReport.siteId;
          this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
        }
      });
    }
  }
  toggleSites(filter:ReportFilter) {
    this.toggleGroupings();
    if (this.showSites) {
      if (this.sitesControl.siteRequest == 'GROUP_CATEGORY' && this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
        this.sitesControl.selectedCategory = null;
      }

      this.tempPrevRequest = this.sitesControl.siteRequest;
      this.selectedReport.siteRequest = this.sitesControl.siteRequest;


      if (this.selectedReport.siteRequest == 'GROUP_CATEGORY') {
        this.systemSiteGroups.forEach((systemGroup) => {
          if (systemGroup.groupName == this.selectedReport.systemGroupName) {
            this.selectGrouping(systemGroup, true);
          }
        });
        this.userSiteGroups.forEach((userGroup) => {
          if (userGroup.id == this.selectedReport.groupId) {
            this.selectGroup(userGroup);
          }
        });
        this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
      }
      else if (this.selectedReport.siteRequest == 'GROUP') {
        this.systemSiteGroups.forEach((systemGroup) => {
          if (systemGroup.groupName == this.selectedReport.systemGroupName) {
            this.selectGrouping(systemGroup, true);
          }
        });
        this.userSiteGroups.forEach((userGroup) => {
          if (userGroup.id == this.selectedReport.groupId) {
            this.selectGroup(userGroup);
          }
        });
      }
      else if (this.selectedReport.siteRequest == 'SPECIFIC_SITE') {
        this.filteredSites.forEach((site, index) => {
          if (site.siteId == this.selectedReport.siteId) {
            this.selectSite(site, index);
            this.sitesControl.selectedCategory = this.selectedReport.siteId;
            this.selectedReport.siteGroupCategory = this.sitesControl.selectedCategory;
          }
        });
      }
    }
    else {

      this.sitesControl.siteSearch = '';
    }
  }

  // DATE RELATED FUNCTIONS
  setDefaultDateRange() {
    this.dateTypeSelected = true;
    this.dateRangeControl.periodSelected = true;
    this.dateRangeControl.show = false;
    this.dateRangeControl.toggled = false;
    this.selectDatePeriod(this.dateRangeControl, 'Last3Months');
    this.applyDateRange(false, true);
  }

  toggleDateRange(filter?:ReportFilter) {
    if(filter){
      filter.showfilter = !filter.showfilter;
    }
    this.dateRangeControl.show = !this.dateRangeControl.show;
    this.tempDateRangeControl = Object.assign({}, this.dateRangeControl);
    this.getDatePeriodRangeDisplay(this.tempDateRangeControl);
  }

  selectDatePeriod(dateRangeControl: any, datePeriod: string) {
    dateRangeControl.datePeriod = datePeriod;
    this.getDatePeriodDisplay(dateRangeControl);
    this.generateDatesFromPeriod(dateRangeControl);
    this.getDatePeriodRangeDisplay(dateRangeControl);
  }

  getDatePeriodRangeDisplay(dateRangeControl: any) {
    const startDate = moment(dateRangeControl.startDate).format('MMM \'YY');
    const endDate = moment(dateRangeControl.endDate).format('MMM \'YY');
    dateRangeControl.datePeriodRangeDisplay = startDate + ' - ' + endDate;
  }

  selectPeriodRadio() {
    this.dateDisabled = false;
    this.tempDateRangeControl.periodSelected = true;
    this.generateDatesFromPeriod(this.tempDateRangeControl);
    this.getDatePeriodRangeDisplay(this.tempDateRangeControl);
  }

  selectDateRangeRadio() {
    this.tempDateRangeControl.periodSelected = false;
    this.tempDateRangeControl.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
    this.tempDateRangeControl.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
    if (moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      this.dateDisabled = true;
    }
  }

  selectStartDate(startDate: string) {
    this.dateDisabled = false;
    this.selectedStartDate = startDate;
    this.tempDateRangeControl.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
    this.endDates = this.billingDateRanges;
    const newEndDates = [];
    this.endDates.forEach((endDate) => {
      if (moment(this.tempDateRangeControl.startDate).isBefore(endDate.endDate)) {
        newEndDates.push(endDate);
      }
    });
    this.endDates = newEndDates;
    if (moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      this.dateDisabled = true;
    }
  }

  selectEndDate(endDate: string) {
    this.dateDisabled = false;
    this.selectedEndDate = endDate;
    this.tempDateRangeControl.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
    if (moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      this.dateDisabled = true;
    }
  }

  applyDateRange(toggle: boolean, isInitLoad: boolean, filter?:ReportFilter) {
    if (!this.dateDisabled) {
      if (toggle) {
        this.dateRangeControl = Object.assign({}, this.tempDateRangeControl);
        this.toggleDateRange(filter);
        this.selectedReport.periodType = null;
        if(this.dateRangeControl.periodSelected){
          this.selectedReport.periodType = this.dateRangeControl.datePeriod;
        } else {
          this.selectedReport.startDate = this.dateRangeControl.startDate;
          this.selectedReport.endDate = this.dateRangeControl.endDate;
        }

      }
      this.getDateRangeDisplay();
      if(filter) {
        filter.selectedOptionLabel = this.dateRangeControl.dateRangeDisplay;
      }
      if(this.selectedReport != null && !isInitLoad) { // Initial Load of Page we don't want to enable saving
        this.isSaveDisabled = false;
      }
    }
    if(this.reportTemplate.reportType == 'BROKER_ENROLL') {
      this.selectedReport.reportSpecificBoolean = this.dateRangeControl.includeSitesWithoutMoveIn;
    }
    if(this.selectedReport != null){ // Initial Load of Page we dont want to emit a report settings change
      this.reportSelected.emit(this.selectedReport)
    };
    this.loadPreSelectedFilters();
  }


  calculateDateRanges() {
    this.utility.calculateDateRanges();
  }

  getDateRangeDisplay() {
    const startDate = moment(this.dateRangeControl.startDate).format('MMM \'YY');
    const endDate = moment(this.dateRangeControl.endDate).format('MMM \'YY');
    this.dateRangeControl.dateRangeDisplay = startDate + ' - ' + endDate;
  }

  getDateDisplay(date: any) {
    return moment(date).format('MMMM YYYY');
  }

  getDatePeriodDisplay(dateRangeControl: any) {
    if (this.datePeriods) {
      this.datePeriods.forEach((period) => {
        if (period.value == dateRangeControl.datePeriod) {
          dateRangeControl.datePeriodDisplay = period.display;
          return;
        }
      });
    }
  }

  generateDatesFromPeriod(control: any) {
    this.utility.generateDatesFromPeriod(control, this.billingDateRanges);
  }


  // METHODS FOR SIDE NAV

  saveColumnChanges() {
    this.columnsChanged.emit(this.columns);
  }

  setDefaultColumns() {
    this.columns.forEach((section) => {
      section.checked = false;
      section.toggled = section.name == 'General Information';
      section.columns.forEach((col) => {
        if(col.display == 'Meter Read Start Date' || col.display == 'Meter Read End Date' || col.display == 'Total Current Charges'
          || col.display == 'Billing Date'|| col.display == 'Due Date' || col.display == 'Account #'
          || col.display == 'Usage (kWh)' || col.display == 'Service Address'){
          col.checked = true;
          section.checked = true;
        } else {
          col.checked = false;
        }
      });
    });
    this.applyColumnChanges();
  }

  showSidenav() {
    this.dataService.setOverlay(true);
    document.getElementById('editReportColumnsSidenav').style.width = '24%';
  }

  hideSidenav() {
    this.dataService.setOverlay(false);
    document.getElementById('editReportColumnsSidenav').style.width = '0';
  }

  // Editing Columns
  applyColumnChanges(init?: boolean) {
    this.tableColumns = [];
    this.columns && this.columns.forEach((section) => {
      section.columns.forEach((col) => {
        const tableCol = Object.assign({}, col);
        this.tableColumns.push(tableCol);
      });
    });
    this.selectedReport.columnNames = "";
    let selectedColumns = this.tableColumns
    .filter(column => column.checked)
    .map(column => column.value)
    .join(',');
    this.selectedReport.columnNames = selectedColumns;

    this.updateScrollbarLength();
    this.hideSidenav();
    if (!init) {
      this.saveColumnChanges();
      this.isSaveDisabled = false;
    }
  }

  sectionChecked(sectionName: string) {
    setTimeout(() => {
      //Wait 0.01s for model to update before triggering other checkboxes #HACK
      this.columns.forEach((section) => {
        if (section.name == sectionName) {
          section.partialCheck = false;
          section.columns.forEach((col) => {
            col.checked = section.checked;
          });
        }
      });
    }, 10);
  }

  partialCheck() {
    setTimeout(() => {
      this.columns.forEach((section) => {
        section.partialCheck = false;
        let checkedCount = 0;
        section.columns.forEach((column) => {
          if (column.checked) {
            section.partialCheck = true;
            section.checked = true;
            checkedCount++;
          }
          if (checkedCount === section.columns.length) {
            section.partialCheck = false;
          }
        });
      });
    }, 10);
  }

  updateScrollbarLength() {
    // Welcome to the most hated block of code in this app.
    let id, scrollWidth = 0;
    setTimeout(() => {
      this.tableColumns.forEach((tableCol) => {
        if (tableCol.checked) {
          id = '#' + tableCol.value + 'Col';
          scrollWidth += $(id).outerWidth();
        }
      });
      $('#scrollbar').css({
        'width': (scrollWidth + 'px')
      });
    }, 50);
  }

}
