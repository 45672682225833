import {ISiteGroup} from "../site";
export class Widget {
  // Every widget will have at LEAST these 3 properties
  widgetId?: number; // New widgets won't have these - let's make it optional
  widgetName: string;
  widgetNameDisplay: string;
  chart?: string;
  width: number;
  height: number;
  userId: string;
  accountId: string;

  // chart?: string;
  // sites?: any[];
  periodType?: any;
  intervalType?: any;
  dateType?: any;
  billingDataType?: any;
  siteRequest?: any;
  systemGroupName?: any;
  groupCategory?: any;
  siteId?: any;
  groupId?: any;
  showPlaceholder?: boolean;


  constructor(widgetName: string){
    this.widgetName = widgetName;
  }

  buildWidget(widgetData: any): boolean { return true;}

  getDisplayDate(systemSiteGroups?: ISiteGroup[], userSiteGroups?: ISiteGroup[], maskMode?: boolean): string {return ''}
}

export const SummaryPeriods = [
	{
		display: 'Last 6 Months',
		value: "Last6Months"
	},
  {
    display: 'Last 12 Months',
    value: "Last12Months"
  },
  {
    display: 'This Year',
    value: "ThisYear"
  }
];

export const DataOptions = [
  {
    display: 'Current Charges ($)',
    value: "CURRENTCHARGES"
  },
  {
    display: 'Usage (kWh)',
    value: "USAGE"
  }
];

export const DateTypes = [
  {
    display: 'Billing Date',
    value: "BILL POST DATE"
  },
  {
    display: 'Meter Read End Date',
    value: "METER READ END DATE"
  }
];

export const IntervalUsagePeriods = [
  {
    display: '3 Days Ago',
    value: "3DaysAgo"
  },
  {
    display: '4 Days Ago',
    value: "4DaysAgo"
  },
  {
    display: '5 Days Ago',
    value: "5DaysAgo"
  },
  {
    display: '6 Days Ago',
    value: "6DaysAgo"
  },
  {
    display: '7 Days Ago',
    value: "7DaysAgo"
  },
  {
    display: '8 Days Ago',
    value: "8DaysAgo"
  },
  {
    display: '9 Days Ago',
    value: "9DaysAgo"
  }
];
