// For common API calls and helper methods shared between components
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {Http, Headers, Response, ResponseContentType} from "@angular/http";
import {environment} from "../../environments/environment";
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {DataService} from "./data.service";
import {AccountsService} from "../accounts/accounts.service";
import {SiteGroupService} from "../site-groups/site-groups.service";
import { ShadowMeteringService } from "../shadow-metering/shadow-metering.service";
import {TpCustomerListService} from "../tp-customer-list/tp-customer-list.service";
import {IUserAccount, IUserEmail, IValidation, IUserProfileData, IUserAccountFlag, WalkMeProfileInfo} from "./entities/profile";
import {PaymentsService} from "../payments/payments.service";
import {IAccountBalanceRequest} from "../payments/payments";
import * as moment from 'moment';
import {HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse} from "@angular/common/http";
import {IUser} from "./entities/user";
import {IUserDetails, IUserPermission} from '../shared/entities/profile';
import * as Spinner from 'spin';
import {ISite} from "./entities/site";
import {FakerService} from "./faker.service";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {ICategory} from "./entities/bill-reports";
import {IAccountMessage, ICustomerSize, IScsFlag} from "./entities/account";
import { IProductDetails } from './entities/product';
import { IContract } from './entities/contract';
import { ResolvedStaticSymbol, ThrowStmt } from '@angular/compiler';
import { AccountBrandingConstant } from './const/accountBranding';
import { Buffer } from 'buffer';

@Injectable()
export class PortalService {

  headers: Headers;
  siteGroupsAccountId = '';
  logoutClicked = false;
  accountFlags: IUserAccountFlag = null;

  constructor(private http: Http,
              private httpClient: HttpClient,
              private dataService: DataService,
              private accountService: AccountsService,
              private siteGroupService: SiteGroupService,
              private shadowMeteringService: ShadowMeteringService,
              private tpCustomerListService: TpCustomerListService,
              private fakerService: FakerService,
              private paymentsService: PaymentsService,
              private cookieService: CookieService,
              private router: Router) {
    this.headers = new Headers ({
      'Content-Type': 'application/json'
    });
  }

  login(j_username: string, j_password: string): Observable<any> {
      if(true){
        let url = environment.loginUrl;
        let data = 'j_username='+j_username+'&j_password='+j_password+'&remember-me=true&submit=Login';
        let headers = new Headers ({
          'Content-Type': 'application/x-www-form-urlencoded'
        });
        return this.http.post(url, data, {headers: headers});
      // } else {
      //   return new Observable(observer => {
      //     this.dataService.setLoggedIn(true);
      //     observer.complete();
      //   });
       }
  }

  setLocalUserId() {
    let url = environment.setLocalSessionUserId;
    return this.http.get(url);
  }

  logout(): Observable<any> {
    console.log('Calling Logout');
    this.logoutClicked = true;

    this.dataService.setLoggedIn(false);
    window.localStorage.removeItem("profileInit");

    return  this.httpClient
      .get(environment.logoutUrl)
      .pipe(
        map((response: HttpResponse<any>) => {
        this.clearData(response.url);
      }), catchError((errorResponse: HttpErrorResponse) => {
        this.clearData(errorResponse.url);
        return throwError(errorResponse)
      })
    );
  }

  clearData(logoutUrl: string) {
    this.cookieService.delete('default-account');
    this.cookieService.delete('logged-in');
    this.cookieService.delete('SESSION');
    this.cookieService.delete('JSESSIONID');
    this.cookieService.delete('SMSESSION'); // Keep for when going to prod the cookie is deleted
    window.localStorage.removeItem('profileInit');
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
    if (logoutUrl) {
      window.location.replace(logoutUrl);
    } else {
      if (this.dataService.getOriginUrl() == 'http://localhost:8880') {
        window.location.replace('http://localhost:8880/login.html?signout=true');
      } else {
        window.location.replace(this.dataService.getOriginUrl() + '/myaccount/login.html?signout=true');
      }
    }
  }

    //Adobe event manager call
    callAEM() {
      console.log("ADOBE Event Manager called");
      let loadEvent = new Event('adobeDynamicFullPageLoadTriggered');
      window.dispatchEvent(loadEvent);
    }

  acknowledge(): Observable<any> {
    return this.http
      .get(environment.acknowledgeUrl)
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  selectAccount(request: any): Observable<any> {
    let url = environment.selectAccountUrl.replace('{accountId}', request.accountId);
    return this.http
      .post(url, request, {headers: this.headers})
      .map((response) => <any>response)
      .catch(this.handleError);
  }

  getAccountSpecificMessage(request: any): Observable<IAccountMessage[]> {
    let url = environment.selectAccountMessageUrl.replace('{accountId}',  request.accountId).replace('{accountName}',  request.accountName);
    return this.http
      .get(url)
      .map((response: Response) => <IAccountMessage[]>response.json());
  }

  getScsFlagForAccount(accountId : string): Observable<IScsFlag[]>{
    let url = environment.getScsFlagUrl.replace('{accountId}', accountId);
    return this.http
    .get(url)
    .map((response: Response) => <IScsFlag[]>response.json());
  }

  getDalHealthCheck(): Observable<any> {
    if(environment.production){
      let url = environment.dalHealthCheckUrl;
      return this.http.get(url);
    } else {
      return new Observable(observer => {
        observer.complete();
      });
    }
  }

  getUser(): Observable<IUser> {
    return this.http
      .get(environment.userUrl)
      .map((response: Response) => <IUser>response.json());
  }

  authenticate(): Observable<any> {
  const headers = new Headers ({
      'Access-Control-Allow-Origin': '*'
    });
    if(!this.logoutClicked) {
      return this.http
        .get(environment.authenticateUrl, { headers })
        .map((response: Response) => <any>response.text());
    } else {
      return new Observable(observer => {
        observer.complete();
      });
    }
  }

//  authenticate(): Observable<any> {
//    const headers = new Headers ({
//      headers: {'Access-Control-Allow-Origin': '*'}
//    });
//    return this.http
//    .get(environment.authenticateUrl, { headers })
//    .map((response: Response) => <any>response.text());

//    return this.http
//      .get(environment.authenticateUrl)
//      .map((response: Response) => <any>response.text());
//  }

  userHasAuthority(authority: string){
    let user = this.dataService.getUserSource();
    let hasAuthority = false;
    if(user && user.authorities){
      user.authorities.forEach((auth) => {
        if(auth == authority){
          hasAuthority = true;
        }
      })
    }
    return hasAuthority;
  }

  getPortalHealthCheck(): Observable<any> {
    if(environment.production){
      let url = environment.portalHealthCheckUrl;
      return this.http.get(url);
    } else {
      return new Observable(observer => {
        observer.complete();
      });
    }
  }

  sendResetEmail(email:string): Observable<any> {
    if(environment.production){
      let url = environment.resetPasswordInitUrl;
      let data = email;
      let headers = new Headers ({
        'Content-Type': 'application/json'
      });
      return this.http.post(url, data, { headers });
    } else {
      return new Observable(observer => {
        this.dataService.setPasswordResetEmailSent(true);
        observer.complete();
      });
    }
  }

  sendAutopayConfirmationEmails(emailAddresses: IUserEmail[], userId: string, brand: string, customerName: string, accountId: string, siteId: string, address: string, enrollmentDate: string, paymentInfo: string, paymentType: string, caNumber: string, action: string): Observable<any> {
    if(environment.production){
      let url='';
      if(action==='Remove')
        url = environment.autopaymentCancelConfirmationUrl;
      else
        url = environment.autopaymentConfirmationUrl;
      let addressArray = emailAddresses.map(function(email) {
        return email.temp;
      });
      let data = {
         "emailAddressses": addressArray,
         "siteNumber": siteId,
         "caNumber": caNumber,
         "accountId": accountId,
         "serviceAddress": address,
         "customerName": customerName,
         "paymentMethod": paymentInfo,
         "paymentType": paymentType
      };
      let headers = new Headers ({
        'Content-Type': 'application/json'
      });
      return this.http.post(url, data, { headers });
    } else {
      return new Observable(observer => {
        //this.dataService.setPasswordResetEmailSent(true);
        observer.complete();
      });
    }
  }

  sendPaymentConfirmationEmails(emailAddresses: IUserEmail[], totalAmount: string, paymentDate: string, paymentDetails: any, customerName: string, paymentMethod: string, confirmationNumber: string, accountId: string): Observable<any> {
    if(environment.production){
      let url = environment.paymentConfirmationUrl;
      let addressArray = emailAddresses.map(function(email) {
        return email.temp;
      });
      let data = {
         "emailAddressses": addressArray,
         "totalAmount": totalAmount,
         "paymentDate": paymentDate,
         "paymentDetails": paymentDetails,
         "customerName": customerName,
         "accountId": accountId,
         "paymentMethod": paymentMethod,
         "confirmationNumber": confirmationNumber
      };
      let headers = new Headers ({
        'Content-Type': 'application/json'
      });
      return this.http.post(url, data, { headers });
    } else {
      return new Observable(observer => {
        //this.dataService.setPasswordResetEmailSent(true);
        observer.complete();
      });
    }
  }

  sendQuickPayConfirmationEmails(emailAddresses: IUserEmail[], totalAmount: string, paymentDate: string, paymentDetails: any, customerName: string, paymentMethod: string, confirmationNumber: string, accountId: string): Observable<any> {
    if(environment.production){
      let url = '';
      if(window.location.href.indexOf('localhost') == -1){
        url = '/myaccount/' + environment.quickPayConfirmationUrl;
      }
      else{
        url = environment.quickPayConfirmationUrl;
      }

      let addressArray = emailAddresses.map(function(email) {
        return email.temp;
      });
      let data = {
         "emailAddressses": addressArray,
         "totalAmount": totalAmount,
         "paymentDate": paymentDate,
         "paymentDetails": paymentDetails,
         "customerName": customerName,
         "accountId": accountId,
         "paymentMethod": paymentMethod,
         "confirmationNumber": confirmationNumber
      };
      let headers = new Headers ({
        'Content-Type': 'application/json'
      });
      return this.http.post(url, data, { headers });
    } else {
      return new Observable(observer => {
        //this.dataService.setPasswordResetEmailSent(true);
        observer.complete();
      });
    }
  }

  sendFileDownloadEmail(email:string, fileLink: string): Observable<any> {
    if(environment.production){
      let url = environment.fileDownloadEmailUrl;
      let data = {
        "email": email,
        "fileLink": fileLink
      };
      let headers = new Headers ({
        'Content-Type': 'application/json'
      });
      return this.http.post(url, data, { headers });
    } else {
      return new Observable(observer => {
        observer.complete();
      });
    }
  }

  sendUserConfirmationEmail(user: IUserPermission): Observable<any> {
    if(environment.production){
      let url = environment.userConfirmationEmailUrl;
      let data = user;
      let headers = new Headers ({
        'Content-Type': 'application/json'
      });
      return this.http.post(url, data, { headers });
    } else {
      return new Observable(observer => {
        observer.complete();
      });
    }
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

  // "2017-01-01T00:00:00" -> "01/01/2017"
  getDateDisplay(dateString: string) {
    if(dateString){
      dateString = dateString.includes("T", 0) ? dateString : dateString + "T00:00:00";
      let date = new Date(dateString);
      return date.toLocaleDateString();
    }
  }

  getPhoneDisplay(phoneNum: string) {
    phoneNum = phoneNum.replace(/\D/g,''); // remove all non-numeric characters
    return '(' + phoneNum.substr(0, 3) + ') ' + phoneNum.substr(3,3) + '-' + phoneNum.substr(6,4)
  }

  getSiteIdDisplay(siteId: string) {
    if(siteId){
      let arr = siteId.split('-');
      if(arr.length > 1) {
        arr.splice(0, 1); // remove string before leading '-'
        let str = arr.join('-'); // add all other '-'s back
        return str;
      } else {
        return arr[0]; // no '-' in string, return full string
      }
    }
  }

  // 1234567.89 -> 1,234,567.89
  numberWithCommas(x) {
    if(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  hasPermission(mask: number) {
    if (this.dataService.getAccountSource()) {
      const permission = this.dataService.getAccountSource().permissions;
      // tslint:disable-next-line:no-bitwise
      return (permission & mask) === mask;
    }
    return false;
  }


  // getComvergeAccess(id: string){
  //    let url = environment.production ? environment.comvergeAccessUrl.replace('{accountId}', id): environment.comvergeAccessUrl;

  //   return this.http
  //     .get(url)
  //     .map((response: HttpResponse<any>) => response)
  //     .catch(this.handleError);
  // }



  // getComvergeAccesss(id: string): Observable<Boolean> {
  //   let url = environment.getLatestMarketsDashboard;

  //   return this.httpClient
  //     .get(url)
  //     .map((response: HttpResponse<Boolean>) => response)
  //     .catch(this.handleError);
  // }


  // getComvergeAccess(id: string): Observable<any> {
  //  let url = environment.production ? environment.comvergeAccessUrl.replace('{accountId}', id): environment.comvergeAccessUrl;

  //   return this.http
  //     .get(url)
  //    .map((response: any) => response)
  //     .catch(this.handleError);
  // }


  getShadowMetersUrl(accountId: string): Observable<string> {
    let url = environment.getShadowMetersUrl.replace('{accountId}', accountId.toString());
    return this.httpClient
    .get(url).pipe(
      map((data: string)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
}


//working
  getComvergeAccess(id: string): Observable<Boolean> {
    // alert('in comverge');

    let url = environment.production ? environment.comvergeAccessUrl.replace('{accountId}', id): environment.comvergeAccessUrl;
    return this.httpClient
      .get(url).pipe(
        map((data: Boolean)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getCustomerSize() {
    let url = environment.customerSizeUrl;
    return this.httpClient
      .get(url)
      .map((response: HttpResponse<ICustomerSize>) => response)
      .catch(this.handleError);
  }

  getAccountFlags(account: IUserAccount): Observable<IUserAccountFlag> {
    const url = environment.production
    ? environment.getAccountFlagUrl.replace('{accountId}', account.id)
    : environment.getAccountFlagUrl;
    return this.httpClient
    .get(url).pipe(
      map((data: IUserAccountFlag)=>{
        this.accountFlags = data;
        return data; //USE HTTPCLIENT
      }),
      catchError( error =>{
        return throwError(this.handleError);
      })
    );


  }

  getRenewablesFlag(account: IUserAccount): boolean {

    if (this.accountFlags) {
      return this.accountFlags.renewables;
    }
    else {
      this.getAccountFlags(account).subscribe((response) => {
        return response.renewables;
      });
    }

  }


  loadAccountData(account: IUserAccount) {

    let requestMsg = {
      accountId: account.id,
      accountName: account.name
    };

    let isThirdParty = false;
    if(account.accessType=='ThirdParty'){
      isThirdParty = true;
    } else if(account.accessType=='Direct'){
      isThirdParty = false;
    }

    this.getAccountSpecificMessage(requestMsg).subscribe(
      (accountSpecificMessage) => {
        if(accountSpecificMessage!=null && accountSpecificMessage.length>0) {
          this.dataService.setAccountSpecificMessage(accountSpecificMessage[0]);
        }
        else {
          this.dataService.setAccountSpecificMessage(null);
        }
      }, err => {
        console.error(err);
      }
    );

    account.accountBranding = "";
    let nrgString : string = AccountBrandingConstant.NRG;
    let reliantString : string = AccountBrandingConstant.Reliant;
    if (account.accessType === 'ThirdParty') {
      account.accountBranding = nrgString;
    }
    else if(account.accessType !== 'ThirdParty') {
        this.accountService.getContractsForAccount(account.id,account.customerAgentId).subscribe((accountWithContractData)=> {

          if(accountWithContractData && accountWithContractData.contracts){
            let contracts = accountWithContractData.contracts;
            let retVal : string = nrgString;
              for (let index = 0; index < contracts.length; index++) {
                const contract = contracts[index];
                if (contract.companyGroup !== 'Energy Solutions' && contract.companyGroup !== 'Retail Services') {
                  retVal = nrgString;
                  break;
                }
                else {
                  if (contract.companyCode !== '393' && contract.companyCode !== '0393' && contract.companyCode !== '320' && contract.companyCode !== '0320') {
                    retVal = reliantString; //satisfied both conditions for reliant, can go to next contract
                  }
                  else {
                    retVal = nrgString;
                    break;
                  }
                }
              }
              account.accountBranding = retVal;
          }

          this.dataService.setContractsForAccount(accountWithContractData.contracts);
          this.dataService.setMarketArrayForAccountSource(accountWithContractData.markets);
          let market = this.calculateMarket(accountWithContractData);
          this.dataService.setMarketForAccountSource(market);
          this.dataService.setAccountWithContractDataSource(accountWithContractData);
        });
        this.siteGroupService.getSiteGroups(account.id).subscribe(  //TODO : HANDLE 404
        (siteGroups) => {
          this.dataService.setSiteGroupsSource(siteGroups);
        }, (err) => {
          console.error(err);
          if(err.status == '404'){
            this.dataService.setSiteGroupsSource(null);
           } else {
            this.dataService.setSiteGroupsSource(null);
          }
        }
      );
    }
    this.dataService.setAccountSource(account);
    this.dataService.setBalanceDue(null);
    let request = {
      accountId: account.id,
      customerAgentId: account.customerAgentId,
      contractAuthorization: account.contractAuthorization,
      accountName: account.name
    };





    let isProfileInitialized = window.localStorage.getItem("profileInit");

    if(!isProfileInitialized) {
      this.initializePersonalizationProfile();
      window.localStorage.setItem("profileInit", "true");
    }

    let isProfileDataLoaded = this.dataService.getIsProfileDataLoaded();

    if(!isProfileDataLoaded) {
      this.getAndStoreProfileData();
    }

    this.getRenewablesFlag(account);
    this.dataService.setNCCAFlag(true);
    if (account.accessType === 'ThirdParty') {
      this.tpCustomerListService.getTPGroups(account.id).subscribe(
        (tpGroup) => {
          this.dataService.setTpGroupSource(tpGroup);
        }, err => {
          console.error(err);
        }
      );
    } else {
      //this.loadContracts(account);
      this.siteGroupService.getSitesForSystemGroup(account.id, 'ALL_SITES').subscribe(   //TODO : HANDLE 404
        (sites) => {
          this.dataService.setSitesForAccountSource(sites);
          this.dataService.setNCCAFlag(true);
          // Check if NCCA
          if (sites.filter(ele => ele.nccaStatus === null).length > 0) {
            this.dataService.setNCCAFlag(false);
          }
        }, (err) => {
          console.error(err);
          if(err.status == '404'){
            this.dataService.setSitesForAccountSource([]);
            this.dataService.setNCCAFlag(true);
           } else {
            this.dataService.setNCCAFlag(true);
          }
        }
      );
      this.accountService.getSiteCountsForAccount(account.id).subscribe(  //TODO : HANDLE 404
        (siteCounts) => {
          this.dataService.setSiteCountsSource(siteCounts);
        }, (err) => {
          console.error(err);
          if(err.status == '404'){
            this.dataService.setSiteCountsSource(null);
           } else {
            this.dataService.setSiteCountsSource(null);
          }
        }
      );
      let balanceRequest: IAccountBalanceRequest = {
        accountId: account.id,
        startDate: moment().subtract(60, 'days').format('YYYY-MM-DD'), // shh
        endDate: moment().format('YYYY-MM-DD')
      };

      // this.paymentsService.getBalanceDue(balanceRequest).subscribe(   //TODO : HANDLE 404
      //   (resp) => {
      //     // console.log("Getting balance due: ", resp);
      //     let balanceDue = this.dataService.getBalanceDue();
      //     if(balanceDue) {
      //       balanceDue.pastDueAmount = resp.pastDueAmount;
      //       balanceDue.balance = resp.balance;
      //       this.dataService.setBalanceDue(balanceDue);
      //     } else {
      //       this.dataService.setBalanceDue(resp);
      //     }
      //   }, (err) => {
      //     if(err.status == '404'){
      //       let currBalance = this.dataService.getBalanceDue();
      //       if(currBalance){
      //         currBalance.balance = 0.00;
      //         currBalance.pastDueAmount = 0.00;
      //         this.dataService.setBalanceDue(currBalance);
      //       } else {
      //         this.dataService.setBalanceDue({
      //           balance: 0.00,
      //           pastDueAmount: 0.00
      //         } as any);
      //       }
      //     } else {
      //       this.dataService.setBalanceDue(null);
      //     }
      //   }
      // );
      this.paymentsService.getBalanceDueDates(balanceRequest).subscribe(
        (resp) => {
          // console.log("Getting balance due dates: ", resp);
          let balanceDue = this.dataService.getBalanceDue();

          if(balanceDue) {
            balanceDue.balance = resp.balance;
            balanceDue.pastDueAmount = resp.pastDueAmount;
            balanceDue.paymentDate = resp.paymentDate;
            balanceDue.paymentAmount = resp.paymentAmount;
            balanceDue.nextPaymentDue = resp.nextPaymentDue;
            this.dataService.setBalanceDue(balanceDue);
          } else {
            this.dataService.setBalanceDue(resp);
          }
        }, (err) => {
          if(err.status == '404'){
            let currBalance = this.dataService.getBalanceDue();
            if(currBalance){
              currBalance.balance = 0.00;
              currBalance.pastDueAmount = 0.00;
              currBalance.paymentDate = null;
              currBalance.paymentAmount = 0.00;
              currBalance.nextPaymentDue = null;
              this.dataService.setBalanceDue(currBalance);
            } else {
              this.dataService.setBalanceDue({
                balance: 0.00,
                pastDueAmount: 0.00,
                paymentDate: null,
                paymentAmount: 0.00,
                nextPaymentDue: null
              } as any);
            }
          } else {
                this.dataService.setBalanceDue({
                  balance: 0.00,
                  pastDueAmount: 0.00,
                  paymentDate: null,
                  paymentAmount: 0.00,
                  nextPaymentDue: null
                } as any);
           }
        }
      );
    }
  }

  loadDgenSites(account: IUserAccount) {
    this.shadowMeteringService.getSites(account.id).subscribe(
      (dgenSites) => {
        this.dataService.setDgenSites(dgenSites);
      }, err => {
        console.error(err);
      }
    );
  }

  loadContracts(account: IUserAccount) {
    this.accountService.getContractsForAccount(account.id, account.customerAgentId).subscribe(
      (account) => {
        // console.log("LOADED ACCOUNT: ", account);
        if(account.markets === null) account.markets = ['ERCOT'];

        this.dataService.setMarketArrayForAccountSource(account.markets);
        let market = this.calculateMarket(account);
        this.dataService.setMarketForAccountSource(market);
        this.dataService.setAccountWithContractDataSource(account);
        this.dataService.setContractsForAccount(account.contracts);
      }
    );
  }



  calculateMarket(account){
    // console.log("MARKETS: ", account.markets);
    let hasNortheast, hasErcot = false, hasNoMarket = false;
    account.markets.forEach(
      (market) => {
        if(market == 'PJM' || market == 'NEISO' || market == 'NYISO') {
          hasNortheast = true;
        }
        if(market == 'ERCOT'){
          hasErcot = true;
        }
        if(market == 'N/A'){
          hasNoMarket = true;
        }
      }
    );

    if(hasNortheast && !hasErcot) {
      return 'NORTHEAST';
    }
    else if (hasNortheast && hasErcot){
      return 'NATIONAL';
    } else if (hasNoMarket){
      return 'N/A';
    }
    return 'ERCOT';
  }

  checkValidContracts() {
    let contracts = this.dataService.getContractsForAccount();
    let hasCurrentContracts = false;
    if (contracts) {
      contracts.forEach((contract) => {
        if (moment().isAfter(moment(contract.deliveryStartDate))) {
          hasCurrentContracts = true;
        }
      });
    }
    return hasCurrentContracts;
  }

  getContractNickname(contractNum: string) {
    let contracts = this.dataService.getContractsForAccount();
    let nickName = null;
    if(contracts){
      contracts.forEach((contract) => {
        if(contract.contractNum == contractNum) {
          nickName = contract.nickName;
        }
      });
    }
    return nickName ? nickName : contractNum;
  }

  populateProductDetails(contract: IContract) {
    this.getProductDetailsForContract(contract.contractNum).subscribe(productDetailsList => {
      contract.products.forEach(product => {
        const productDetails = productDetailsList.find(pd => product.id === pd.productId);
        if (productDetails) {
          product.congestionZones = productDetails.congestionZones;
          product.siteCount = productDetails.siteCount;
        }
      });
    });
  }

  getProductDetailsForContract(contractId: string): Observable<IProductDetails[]> {
    const accountId = this.dataService.getAccountSource().id;
    const url = environment.production
      ? environment.productDetailsUrl.replace('{accountId}', accountId).replace('{contractId}', contractId)
      : environment.productDetailsUrl;
      return this.httpClient
      .get(url).pipe(
        map((data: IProductDetails[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  getAllThirdPartyAccounts() {
    let url = environment.getAllThirdPartyAccountsUrl;
    return this.httpClient
      .get(url)
      .map((response: HttpResponse<any[]>) => response)
      .catch(this.handleError);
  }

  spinBabySpin(id?: string, colorsel?: string) {
    let spincolor = colorsel ? colorsel : '#00AEEF';
    if (colorsel != null) {
      spincolor = colorsel;
    }
    let opts = {
      lines: 12 // The number of lines to draw
      , length: 22 // The length of each line
      , width: 14 // The line thickness
      , radius: 32 // The radius of the inner circle
      , scale: 1 // Scales overall size of the spinner
      , corners: 0.1 // Corner roundness (0..1)
      , color: spincolor // #rgb or #rrggbb or array of colors
      , opacity: 0.25 // Opacity of the lines
      , rotate: 0 // The rotation offset
      , direction: 1 // 1: clockwise, -1: counterclockwise
      , speed: 1 // Rounds per second
      , trail: 60 // Afterglow percentage
      , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
      , zIndex: 1101 // The z-index (defaults to 2000000000)
      , className: 'spinner' // The CSS class to assign to the spinner
      , top: '100%' // Top position relative to parent
      , left: '100%' // Left position relative to parent
      , shadow: false // Whether to render a shadow
      , hwaccel: false // Whether to use hardware acceleration
      , position: 'absolute' // Element positioning
    };
    let spinnerId = id ? id : 'spinner';
    setTimeout(() => {
      let target = document.getElementById(spinnerId);
      let spinner = new Spinner(opts).spin(target);
    }, 250)
  }

  validatePassword(pwd: string, confirmPwd: string, currentPwd?: string): IValidation {
    let validation: IValidation = { isValid: false };

    let reqsMet = 0;
    let specialCharReq = new RegExp('(?=.*[!@#\$%\^&\*])');
    let hasNumberReq = new RegExp('(?=.*[0-9])');
    let hasLowerCaseReq = new RegExp('(?=.*[a-z])');
    let hasUpperCaseReq = new RegExp('(?=.*[A-Z])');
    let requirements = [specialCharReq, hasNumberReq, hasLowerCaseReq, hasUpperCaseReq];

    requirements.forEach((req) => {
      if(req.test(pwd)){
        reqsMet++;
      }
    });

    if(pwd.length >= 7) {
      if(pwd == confirmPwd){
        if(pwd != currentPwd){
          if(reqsMet >= 3) {
            validation.isValid = true;
          } else {
            validation.error = 'Please make sure your password meets the requirements above.'
          }
        } else {
          validation.error = 'Old and new passwords cannot match.';
        }
      } else {
        validation.error = 'Passwords do not match.';
      }
    } else {
      validation.error = 'Please ensure your password has at least 7 characters.';
    }


    return validation;
  }

  exportToExcel(url: string, filename?: string){
    let isMarketsPage = this.dataService.getTitleSource() == 'Markets';

    if(isMarketsPage){
      let name = filename.substring(0, filename.length-5);
      this.spinBabySpin('spinner-' + name);
      this.dataService.setLocalLoader('MARKETS-' + name, true);
    }
    return this.http
      .get(url, {responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          if(isMarketsPage) {
            this.dataService.setLocalLoader('MARKETS-' + name, false);
          }
          let contentDisposition = res.headers.get('content-disposition');
          let resFilename = contentDisposition.match(/filename=(.+)/)[1];
          if(resFilename){
            filename = resFilename;
          }
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        },
        () => {
          this.dataService.setLoading(false);
          if(isMarketsPage) {
            this.dataService.setLocalLoader('MARKETS-' + name, false);
          }
        }
      );
  }

  // Faker
  getFakeSiteId(site: any){
    if(!site.fake) site.fake = {};
    if(!site.fake.siteId) site.fake.siteId = this.fakerService.getSiteId();
    return site.fake.siteId;
  }

  getFakeSiteName(site: any){
    if(!site.fake) site.fake = {};
    if(!site.fake.siteName) site.fake.siteName = this.fakerService.getCompanyName();
    return site.fake.siteName;
  }

  getFakeCustName(){
    return this.fakerService.getCompanyName();
  }

  getFakeContractAccountNum(site: any) {
    if(!site.fake) site.fake = {};
    if(!site.fake.contractAccountNum) site.fake.contractAccountNum = this.fakerService.getContractAccountNum();
    return site.fake.contractAccountNum;
  }

  getFakeStreetAddress(site: any) {
    if(!site.fake) site.fake = {};
    if(!site.fake.streetAddress) site.fake.streetAddress = this.fakerService.getStreetAddress();
    return site.fake.streetAddress;
  }

  getFakeCity(site: any) {
    if(!site.fake) site.fake = {};
    if(!site.fake.city) site.fake.city = this.fakerService.getCity();
    return site.fake.city;
  }

  getFakeZipCode(site: any) {
    if(!site.fake) site.fake = {};
    if(!site.fake.zipCode) site.fake.zipCode = this.fakerService.getZipCode();
    return site.fake.zipCode;
  }

  getFakeConfirmationNumber(site: any) {
    if(!site.fake) site.fake = {};
    if(!site.fake.confirmationNumber) site.fake.confirmationNumber = this.fakerService.getConfirmationNumber();
    return site.fake.confirmationNumber;
  }

  getFakeCompanyName(account: IUserAccount){
    if(!account.fake) account.fake = {};
    if(!account.fake.name) account.fake.name = this.fakerService.getCompanyName();
    return account.fake.name;
  }

  getFakeName() {
    return this.fakerService.getName();
  }

  escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  getDataAttributes() {
    // pulls attributes from body; these are used for personalization
    let datasetKeys = Object.keys(document.body.dataset);
    let profile = {};

    if(datasetKeys !== undefined) {
      for(var i = 0; i < datasetKeys.length; i++) {
        let key = datasetKeys[i];
        let value = document.body.dataset[key]

        profile[key] = value;
      }
    }

    return profile;
  }

    getPersonalizationProfile(accountOverride: boolean){
      let storedProfile = window.localStorage.getItem("personalizationProfile");
      let personalizationProfile: any = {};

      if (storedProfile == null) {
        console.log("stored profile not found; initializing new one");

    		personalizationProfile = {
    			personalization: true,
          expiration: moment().valueOf()
    		};

        this.setPersonalizationProfile(personalizationProfile);
      } else {
        personalizationProfile = JSON.parse(storedProfile)

        if(!personalizationProfile.hasOwnProperty('personalization') || !personalizationProfile.hasOwnProperty('expiration')) {
          // Profile is in invalid state, initializePersonalizationProfile
          console.log("profile is invalid; initializing new one");

          personalizationProfile = {
      			personalization: true,
            expiration: moment().valueOf()
      		};

          this.setPersonalizationProfile(personalizationProfile);
        }

        let currentProfileExpiration = personalizationProfile.expiration;
        let compareDate = moment.unix(currentProfileExpiration/1000).add(3, 'M').valueOf();
        let now = moment().valueOf();

        if(compareDate <= now) {
          console.log("profile has expired; initializing new one");
          // Expired; reset
          personalizationProfile = {
      			personalization: true,
            expiration: moment().valueOf()
      		};

          this.setPersonalizationProfile(personalizationProfile);
        }
      }

      if(accountOverride) {
        let accountProduct = personalizationProfile["account.product"];
        let accountMarket = personalizationProfile["account.utilityMarket"];
        let accountUpForRenewal = personalizationProfile["account.upForRenewal"];

        if(accountProduct != null) {
          personalizationProfile.product = accountProduct;
        }

        if(accountMarket != null) {
          personalizationProfile.utilityMarket = accountMarket;
        }

        if(accountUpForRenewal != null) {
          personalizationProfile.upForRenewal = accountUpForRenewal;
        }
      }

      return personalizationProfile;
    }

    setPersonalizationProfile(profile: any) {
      window.localStorage.setItem("personalizationProfile", JSON.stringify(profile));
    }

    setPersonalizationProfileField(key: string, value: string) {
      let personalizationProfile = this.getPersonalizationProfile(false);

      if(personalizationProfile != null) {
        personalizationProfile[key] = value;
        personalizationProfile["expiration"] = moment().valueOf();
        this.setPersonalizationProfile(personalizationProfile);
      }
    }

    getLTFromPersonalizationTags(personalizationTags: any, key: string) {
        let values = personalizationTags[key];

        let ltValue = null;
        if(values != null && values.length > 0) {
          ltValue = values[0];
        }

        return ltValue;
    }

    updatePersonalizationProfileAndFireAnalyticsEvent(personalizationTags: any, route: string) {
      let product = this.getLTFromPersonalizationTags(personalizationTags, "product");
      let theme = this.getLTFromPersonalizationTags(personalizationTags, "theme");
      let benefit = this.getLTFromPersonalizationTags(personalizationTags, "benefit");
      let vertical = this.getLTFromPersonalizationTags(personalizationTags, "vertical");
      let utilityMarket = this.getLTFromPersonalizationTags(personalizationTags, "utilityMarket");

      if(product != null) {
        this.setPersonalizationProfileField("product", product);
      }

      if(theme != null) {
        this.setPersonalizationProfileField("theme", theme);
      }

      if(benefit != null) {
        this.setPersonalizationProfileField("benefit", benefit);
      }

      if(vertical != null) {
        this.setPersonalizationProfileField("vertical", vertical);
      }

      if(utilityMarket != null) {
        this.setPersonalizationProfileField("utilityMarket", utilityMarket);
      }

      let analyticsObject:any = {};

      analyticsObject.clickLocation = route;
      analyticsObject.clickText = "";
      analyticsObject.actionType = "click";

      if (!window.nrg_datalayer) {
        window.nrg_datalayer = {};
        window.nrg_datalayer.analytics = {};
      }

      window.nrg_datalayer.analytics.page = window.location.href;

      window.nrg_datalayer.analytics.sProp50 = window.nrg_datalayer.analytics.eVar50 = analyticsObject.clickLocation || "";
      window.nrg_datalayer.analytics.sProp52 = window.nrg_datalayer.analytics.eVar52 = analyticsObject.clickText ? analyticsObject.clickText.toLowerCase() : "";
      window.nrg_datalayer.analytics.sProp53 = window.nrg_datalayer.analytics.eVar53 = analyticsObject.actionType || "";

      if(benefit != null) {
        window.nrg_datalayer.analytics.sProp55 = window.nrg_datalayer.analytics.eVar77 = benefit;
        analyticsObject.benefit = benefit;
      }

      if(theme != null) {
        window.nrg_datalayer.analytics.sProp56 = window.nrg_datalayer.analytics.eVar73 = theme;
        analyticsObject.theme = theme;
      }

      if(product != null) {
        window.nrg_datalayer.analytics.sProp57 = window.nrg_datalayer.analytics.eVar74 = product;
        analyticsObject.product = product;
      }

      if(vertical != null) {
				window.nrg_datalayer.analytics.sProp58 = window.nrg_datalayer.analytics.eVar75 = vertical;
				analyticsObject.vertical = vertical;
			}

			if(utilityMarket != null) {
				window.nrg_datalayer.analytics.sProp64 = window.nrg_datalayer.analytics.eVar76 = utilityMarket;
				analyticsObject.utilityMarket = utilityMarket;
			}

      let personalizationProfile = this.getPersonalizationProfile(false);

      if(personalizationProfile != null) {
        let brand = personalizationProfile.brand;

        if(brand != null) {
  				window.nrg_datalayer.analytics.eVar7 = brand;
  				analyticsObject.brand = brand;
  			}

        let customerID = personalizationProfile.customerID;

        if(customerID != null) {
  				window.nrg_datalayer.analytics.eVar3 = customerID;
  				analyticsObject.customerID = customerID;
  			}

        let upForRenewal = personalizationProfile.upForRenewal;

        if(upForRenewal != null) {
  				window.nrg_datalayer.analytics.eVar6 = upForRenewal;
  				analyticsObject.upForRenewal = upForRenewal;
  			}
      }

      let event = new CustomEvent("nrg_" + analyticsObject.actionType, {
        detail: analyticsObject
      });

      window.dispatchEvent(event);
    }

    updatePersonalizationProfileWithAccountAttributes(accountID: string) {
      let account = this.dataService.getUserProfileAccount(accountID);

      if(account != null) {
        let accountProduct = account.product;
        this.setPersonalizationProfileField("account.product", accountProduct);

        let accountUtilityMarket = account.utilityMarket;
        this.setPersonalizationProfileField("account.utilityMarket", accountUtilityMarket);

        let accountUpForRenewal = account.upForRenewal || false;
        this.setPersonalizationProfileField("account.upForRenewal", accountUpForRenewal.toString());
      }
    }

    initializePersonalizationProfile() {
      let initialPersonalizationProfile = this.getPersonalizationProfile(false);
      let dataElements = this.getDataAttributes();

      var datasetKeys = Object.keys(dataElements);

      for(var i = 0; i < datasetKeys.length; i++) {
        let key = datasetKeys[i];
        let value = dataElements[key];

        this.setPersonalizationProfileField(key, value);
      }
    }

    getAndStoreProfileData() {
      this.getProfileData().subscribe(
        (profileData) => {
          let accounts = profileData.accounts;
          this.dataService.setUserProfileAccounts(accounts);

          let accountID = window.localStorage.getItem("customerId");
          this.updatePersonalizationProfileWithAccountAttributes(accountID);

          let brand = profileData.brand;
          this.setPersonalizationProfileField("brand", brand);

          let upForRenewal = profileData.upForRenewal;
          this.setPersonalizationProfileField("upForRenewal", upForRenewal.toString());

          let customerID = profileData.id;
          this.setPersonalizationProfileField("customerID", customerID.toString());

          let product = profileData.product;
          this.setPersonalizationProfileField("mostTouchedProduct", product);

          let um = profileData.utilityMarket;
          this.setPersonalizationProfileField("mostTouchedUtilityMarket", um);

          this.dataService.setProfileDataLoaded();
        },
        err => {
          console.log("Could not retrieve existing profile data from DAL. ");
          this.dataService.setProfileDataLoaded();
        }
      )
    }

    getProfileData(): Observable<IUserProfileData> {
      let url = environment.userProfileDataUrl;

      return this.http
        .get(url)
        .map((response: Response) => <IUserProfileData>response.json())
        .catch(this.handleError);
    }

    getWalkMeAuthToken() : any{
      let url = environment.walkMeAuthUrl;

      return this.http
      .get(url)
      .map((response: Response) => <any>response.json())
      .catch(this.handleError);


    }

    sendAccountDataToWalkMe(walkMeInfo: WalkMeProfileInfo){

    }
}
