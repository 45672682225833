import { Component, OnInit, ElementRef } from '@angular/core';
import { DataService } from '../shared/data.service';
import { UsageReportService } from './usage-reports.service';
import {
  IUsageReport, IUsageReportRequest, IUsageReportReponse, IUsageReportFrequencyCount, IUsageReportExportRequest
} from '../shared/entities/usage-reports';
import { FadeAnimation } from '../animations/fadeAnimation';
import { Router } from '@angular/router';
import { UsageDatePeriods } from '../shared/const/datePeriods';
import { UsageReportColumns } from '../shared/const/usageReportColumns';
declare var $: any;
import * as moment from 'moment';
import { PortalService } from '../shared/portal.service';
import { UsageReportPipe } from '../shared/pipe/usageReport.pipe';
import { Subscription } from 'rxjs';
import { IUserAccount } from '../shared/entities/profile';
import { FadeInAnimation } from '../animations/fadeInAnimation';
import { FakerService } from '../shared/faker.service';
import { ISite } from '../shared/entities/site';
import { IHelpStep } from '../shared/entities/contextualHelp';
import { IAccount } from '../shared/entities/account';
import { FilterType, ISubscribeReportTemplate } from '../shared/entities/subscribeReportTemplate';
import { IGenericReportSetting } from '../shared/entities/reportSettings';
import { ReportDataUtility } from '../subscribe-to-report/report-data.utility';
import { ReportSettingsService } from '../subscribe-to-report/report-settings.service';
import { SitesControl } from '../shared/entities/controls';

@Component({
  selector: 'app-usage-reports',
  templateUrl: 'usage-reports.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['usage-reports.component.scss', '../shared/styles/reports.scss'],
  providers: [UsageReportPipe]
})
export class UsageReportsComponent implements OnInit {
  elementRef;
  myAccount: IUserAccount;
  accountWithContractData: IAccount;
  usageReports: IUsageReport[];
  reportData: IUsageReportReponse;
  filteredReportData: IUsageReportReponse;
  userSiteGroups: any[];
  showSearchBar = false;
  noDataError = false;
  searchTerm = '';
  showScrollbar = false;
  maskMode: boolean;
  meterTypeCount: IUsageReportFrequencyCount;
  meterList: any[];
  meterNIDRCount: number;
  showReports: boolean;
  showSettings: boolean;
  showSave: boolean;
  makeNewDefault = false;
  makeNewPublic = false;
  activateSave = false;
  creatingReport = false;
  editingReportName = false;
  AMSCount: number;
  IDRCount: number;
  helpStep: IHelpStep;
  savedPeriodSelected = true;
  showInvalidDateRangeError: boolean;
  reportRequest: IUsageReportRequest;

  // Groupings
  sitesControl: SitesControl;

  // Meter Type
  meterTypeControl: any = {
    selectedMeter: {
      meterName: 'All',
      display: 'All'
    }
  };

  // To handle Angular Material date picker typing
  selectedStartDate: Date;
  selectedEndDate: Date;

  dataTypeSelected = true;
  usageDateRanges: any[];

  // Subscriptions
  exportSubscription: Subscription;
  accountSubscription: Subscription;
  accountSourceSubscription: Subscription;
  accountWithContractDataSubscription: Subscription;
  siteGroupsSubscription: Subscription;
  realTimeDataReportSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  // Columns
  tableColumns: any[]; // Columns iterated over the table, taken from columns model in applyColumnChanges()
  columns: any[]; // Model for sidenav, see UsageReportColumns.ts

  /**************************************************************
  Variables For Subscribe to Report Template - START
  **************************************************************/

  reportSettings: IGenericReportSetting[]; // List of All Report Settings for Subscribe To Report Template
  privateReports: IGenericReportSetting[]; // List of Private Report Settings for Subscribe To Report Template
  publicReports: IGenericReportSetting[];  // List of Private Report Settings for Subscribe To Report Template
  reportsLoaded: boolean = false;          // Boolean that tells us if Reports have loaded, used to Render Subscribe to Report Template
  newReportName = '';                      // Report Name used for saving a report
  makeDefault: boolean = true;             // Boolean Flag used for saving default report
  makePublic: boolean = false;             // Boolean Flag used for saving public or private reports
  selectedReport: IGenericReportSetting;   // Report that is used to generate data for the table and for saving/editing/deleting reports
  reportSubscriptionRangesSourceSubscription: Subscription; // Used to get the valid date ranges for Reports
  datePeriods: any[];                      // Helper for displaying different date periods for the filter (3 Months, 6 Months, etc.)
  reportDateRanges: any[];                 // Stores valid date ranges for reports
  endDates: any[];                         // Stores end date ranges for reports
  startDate: any;
  endDate: any;
  dateRangeControl: any = {                // Used to track date range selections from user inputs
    periodSelected: true,
    toggled: false,
    show: false,
    startDate: {},
    endDate: {},
    datePeriod: 'Last3Months',
    datePeriodDisplay: 'Last 3 Months',
    dateRangeDisplay: '',
  };
  reportTemplate: ISubscribeReportTemplate = { // Holds the information about the specific report and builds child SubscribeToReportTemplate with correct filters
    title: "Usage Summary",
    defaultReportName: "Usage Summary 01",
    reportType: 'USAGE_SUMMARY',
    description: "This is a sample report description.",
    filters: [
      { // This is the first filter
        name: "Grouping",
        filterType: FilterType.SitePicker,
        options: [
          { label: "Option1", value: "option1" }, // These are unused SitePicker is a special filter type
          { label: "Option2", value: "option2" },
          { label: "Option3", value: "option3" },
          { label: "Option4", value: "option4" },
          { label: "Option5", value: "option5" },
        ],
        selectedOptionLabel:"ALL SITES",
        selectedOptionValue: "ALL_SITES",
        defaultOptionLabel: "ALL SITES",
        defaultOptionValue: "ALL_SITES",
        showfilter: false
      },
      {
        name: "Usage Date Range",
        filterType: FilterType.DatePicker,
        options: [
          { label: "Choice1", value: "choice1" }, // These are unused DatePicker is a special filter type
          { label: "Choice2", value: "choice2" }, // Never the less this is the pattern you would use to load a filter
          { label: "Choice3", value: "choice3" },
          { label: "Choice4", value: "choice4" },
          { label: "Choice5", value: "choice5" },
        ],
        selectedOptionLabel:"Last 3 Months", // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        selectedOptionValue: "Last3Months",  // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        defaultOptionLabel: "Last 3 Months", // Default Option
        defaultOptionValue: "Last3Months",   // Default Option
        showfilter: false
      },
      {
        name: "Meter Type",
        filterType: FilterType.SimpleDropdown,
        options: [
          { label: "All", value: "All" }
        ],
        selectedOptionLabel:"All", // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        selectedOptionValue: "All",  // Updated in Subscribe to ReportTemplate but nevertheless we must instantiate them
        defaultOptionLabel: "All", // Default Option
        defaultOptionValue: "All",   // Default Option
        showfilter: false
      },
    ],
  };

  constructor(private dataService: DataService,
    private usageReportService: UsageReportService,
    private usageReportPipe: UsageReportPipe,
    private portalService: PortalService,
    myElement: ElementRef,
    private utility: ReportDataUtility,
    private reportSettingsService: ReportSettingsService,
    private fakerService: FakerService
  ) {
    this.elementRef = myElement;
  }

  ngOnInit() {
    this.dataTypeSelected = true;
    this.dataService.setSelectedNavItem('usage');
    this.dataService.setCurrentActivePage('usage');
    this.dataService.setTitleSource('Usage Summary Report');
    this.maskMode = this.dataService.getMaskMode();
    this.dataService.setLoading(true);
    this.sitesControl = new SitesControl();
    this.sitesControl.selectedTypeDisplay = 'GROUP';
    this.myAccount = this.dataService.getAccountSource();
    this.columns = UsageReportColumns;
    if (this.myAccount) {
       this.setDefaultColumns();
       this.usageReportService.frequencyTypeCountUsageReport(this.myAccount.id).subscribe(
        (frequencyCount) => {
          this.meterTypeCount = frequencyCount;
          this.generateMeterType();
        });
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
          this.usageReportService.frequencyTypeCountUsageReport(this.myAccount.id).subscribe(
            (frequencyCount) => {
              this.meterTypeCount = frequencyCount;
              this.generateMeterType();
            });
        }
    );

    this.reportTemplate.filters.forEach(filter =>{
      if(filter.filterType === FilterType.DatePicker) {
        filter.selectedOptionLabel = this.dateRangeControl.datePeriodDisplay;
        this.reportSubscriptionRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
          (dateRanges) => {
            this.reportDateRanges = dateRanges;
            this.endDates = dateRanges;
          });
      }
    });
    this.utility.calculateDateRanges();
    if (this.myAccount) {
      this.getReportSettings(true);
    }
    this.accountSourceSubscription =
      this.dataService.accountSourceUpdated.subscribe((account) => {
        this.myAccount = account;
      });

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.generateUsageReportData();
    });
  }


  // Long ago, at many times and in many ways, God spoke to our fathers by the prophets,
  // but in these last days he has spoken to us by his Son, whom he appointed the heir of all things, through whom also he created the world.
  // He is the radiance of the glory of God and the exact imprint of his nature, and he upholds the universe by the word of his power.
  // Hebrews 1:1-3

  ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.accountWithContractDataSubscription) this.accountWithContractDataSubscription.unsubscribe();
    if (this.siteGroupsSubscription) this.siteGroupsSubscription.unsubscribe();
    if (this.realTimeDataReportSubscription) this.realTimeDataReportSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
    if (this.reportSubscriptionRangesSourceSubscription) {this.reportSubscriptionRangesSourceSubscription.unsubscribe()}
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  generateMeterType() {
    this.meterList = [];
    let meterFilter = this.reportTemplate.filters.find(x => x.name === "Meter Type");
    if (this.meterTypeCount.amsCount > 0) {
      const meterContent = {
        name: 'AMS',
        count: this.meterTypeCount.amsCount
      };
      this.meterList.push(meterContent);
      meterFilter.options.push({
        label: "AMS",
        value: "AMS"
      });
    }
    if (this.meterTypeCount.idrCount > 0) {
      const meterContent = {
        name: 'IDR',
        count: this.meterTypeCount.idrCount
      };
      this.meterList.push(meterContent);
      meterFilter.options.push({
        label: "IDR",
        value: "IDR"
      });
    }
    if (this.meterTypeCount.nidrCount > 0) {
      this.meterNIDRCount = this.meterTypeCount.nidrCount;
    } else {
      this.meterNIDRCount = 0;
    }
    // console.log("TOTAL METER TYPE: " + this.meterList.length);
    if (this.meterList.length < 1) {
      this.meterList = [];
    }
  }

  setDefaultColumns() {
    this.columns.forEach((section) => {
      section.checked = false;
      section.toggled = section.name == 'General Information';
      section.columns.forEach((col) => {
        if (col.display == 'Last Updated' || col.display == 'Usage Start Date' || col.display == 'Usage End Date'
          || col.display == 'TOTAL USAGE (kWh)'
          || col.display == 'Peak Demand (Hour Ending)' || col.display == 'Peak Demand (KW)'
          || col.display == 'Service Address' || col.display == 'Account #') {
          col.checked = true;
        } else {
          col.checked = false;
        }
      });
    });
  }

  generateDatesFromPeriod() {
    if (this.dateRangeControl.periodSelected) {
      const period = this.dateRangeControl.datePeriod;
      let startDate = moment().subtract(3, 'days');
      const endDate = moment().subtract(3, 'days');

      if (period == UsageDatePeriods.Last7Days) {
        startDate = startDate.subtract(7, 'days');
      } else if (period == UsageDatePeriods.Last30Days) {
        startDate.subtract(30, 'days');
      } else if (period == UsageDatePeriods.Last60Days) {
        startDate.subtract(60, 'days');
      } else if (period == UsageDatePeriods.Last90Days) {
        startDate.subtract(90, 'days');
      } else if (period == UsageDatePeriods.Last365Days) {
        startDate.subtract(365, 'days');
      } else if (period == UsageDatePeriods.LastWeek) {
        startDate.subtract(1, 'week').startOf('week');
        endDate.subtract(1, 'week').endOf('week');
      } else if (period == UsageDatePeriods.ThisMonth) {
        startDate.startOf('month');
      } else if (period == UsageDatePeriods.LastMonth) {
        startDate.subtract(1, 'month').startOf('month');
        endDate.subtract(1, 'month').endOf('month');
      } else if (period == UsageDatePeriods.Last3Months) {
        startDate.subtract(3, 'month').startOf('month');
        endDate.subtract(1, 'month').endOf('month');
      } else if (period == UsageDatePeriods.Last6Months) {
        startDate.subtract(6, 'month').startOf('month');
        endDate.subtract(1, 'month').endOf('month');
      } else if (period == UsageDatePeriods.LastYear) {
        startDate.subtract(1, 'year').startOf('year');
        endDate.subtract(1, 'year').endOf('year');
      } else if (period == UsageDatePeriods.Last2Years) {
        startDate.subtract(2, 'year').startOf('year');
        endDate.subtract(1, 'year').endOf('year');
      } else if (period == UsageDatePeriods.Last3Years) {
        startDate.subtract(3, 'year').startOf('year');
        endDate.subtract(1, 'year').endOf('year');
      }

      this.dateRangeControl.maxDate = new Date(moment().subtract(2, 'days').format('YYYY-MM-DD'));
      this.dateRangeControl.minDate = new Date(moment().subtract(2, 'days').subtract(3, 'years').format('YYYY-MM-DD'));
      this.dateRangeControl.startDate = startDate.format('YYYY-MM-DD');
      this.dateRangeControl.endDate = endDate.format('YYYY-MM-DD');
    }

  }

  generateUsageReportData() {
    this.dataService.setLoading(true);
    this.generateDatesFromPeriod();
    this.generateRequest();
    this.usageReportService.generateUsageReportData(this.reportRequest).subscribe(
      (reportData) => {
        this.reportData = reportData;
        this.noDataError = false;
        if (this.sitesControl.selectedGrouping.groupName == 'ALL_SITES' && this.reportData.categories.length > 0) {
          this.reportData.categories[0].name = 'All Sites';
          this.reportData.categories[0].expanded = true;
        }
        this.filteredReportData = this.reportData;
        this.filteredReportData.categories.forEach((category) => {
          category.siteCount = category.sites.length;
          if (this.maskMode) {
            category.sites.forEach((site) => {
              site.siteName = this.fakerService.getCompanyName();
              site.contractAccountNum = this.fakerService.getContractAccountNum();
              site['serviceStreetAddress'] = this.fakerService.getStreetAddress();
              site['serviceCity'] = this.fakerService.getCity();
              site['billingStreetAddress'] = this.fakerService.getStreetAddress();
              site['billingCity'] = this.fakerService.getCity();
            });
          }
        });
        setTimeout(() => {
          const element = document.getElementById('tableWrapper');
          $(element).scroll(function () {
            $('.scroll-header').css('top', $(this).scrollTop() - 3 + 'px');
          });

          $('.scroll').scroll(function () {
            $('.scrollbar').scrollLeft($('.scroll').scrollLeft());
          });

          $('.scrollbar').scroll(function () {
            $('.scroll').scrollLeft($('.scrollbar').scrollLeft());
          });
          this.dataService.setLoading(false);
        }, 1000);
      }, (err) => {
        if (err.status == 404) {
          this.reportData = null;
          this.noDataError = true;
        }
        console.error(err);
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 1000);
      });
  }

  generateRequest() {
    this.reportRequest =
    {
      accountId: this.myAccount.id,
      groupId: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null : null,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null,
      startDate: this.startDate,
      endDate: this.endDate,
      frequencyType: this.meterTypeControl.selectedMeter.value,
    };
    this.reportRequest.accountId = this.myAccount.id;
    this.reportRequest.groupId = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null : null;
    this.reportRequest.systemGroupName = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null;
    if (this.sitesControl.selectedGrouping.groupName == 'All Sites') {
      this.reportRequest.systemGroupName = 'ALL_SITES';
      this.sitesControl.siteRequest = 'GROUP';
    }
  }

  expand(row) {
    row.expanded = !row.expanded;
    this.detectScrollbar();
    this.updateScrollbarLength();
  }

  detectScrollbar() {
    const element = document.getElementById('tableWrapper');
    this.showScrollbar = element.offsetHeight != element.scrollHeight;
  }

  updateScrollbarLength() {
    // Welcome to the most hated block of code in this app.
    let id, scrollWidth = 0;
    setTimeout(() => {
      this.tableColumns.forEach((tableCol) => {
        if (tableCol.checked) {
          id = '#' + tableCol.value + 'Col';
          scrollWidth += $(id).outerWidth();
        }
      });
      $('#scrollbar').css({
        'width': (scrollWidth + 'px')
      });
    }, 50);
  }

  // Editing Columns
  applyColumnChanges(init?: boolean) {
    this.tableColumns = [];
    this.columns && this.columns.forEach((section) => {
      section.columns.forEach((col) => {
        const tableCol = Object.assign({}, col);
        this.tableColumns.push(tableCol);
      });
    });
    this.updateScrollbarLength();
  }

  sectionChecked(sectionName: string) {
    setTimeout(() => {
      //Wait 0.01s for model to update before triggering other checkboxes #HACK
      this.columns.forEach((section) => {
        if (section.name == sectionName) {
          section.partialCheck = false;
          section.columns.forEach((col) => {
            col.checked = section.checked;
          });
        }
      });
    }, 10);
  }

  partialCheck() {
    setTimeout(() => {
      this.columns.forEach((section) => {
        section.partialCheck = false;
        let checkedCount = 0;
        section.columns.forEach((column) => {
          if (column.checked) {
            section.partialCheck = true;
            section.checked = true;
            checkedCount++;
          }
          if (checkedCount === section.columns.length) {
            section.partialCheck = false;
          }
        });
      });
    }, 10);
  }

  formatFixedCell(data: any, category: string) {
    if (data) {
      if (category === 'maxAvailable') {
        return data[category] ? moment(data[category]).format('MM/DD/YY') : '';
      }
    }
  }

  formatCell(data: any, col: string) {
    return this.utility.formatCellUsage(data, col);
  }

  getTooltip(site: any, col: string) {
    if (col == 'serviceAddress') {
      return site['serviceStreetAddress'] + ' \n' + site['serviceCity'] + ', ' + site['serviceState'] + ' ' + site['serviceZipCode'];
    } else if (col == 'billingAddress') {
      return site['billingStreetAddress'] + ' \n' + site['billingCity'] + ', ' + site['billingState'] + ' ' + site['billingZipCode'];
    }
  }

  numbersWithCommas(x) {
    return this.utility.numbersWithCommas(x);
  }

  getColumnNamesString() {
    return this.utility.getColumnNamesString(this.columns);
  }

  getColumnHeadersString() { return this.utility.getColumnHeadersString(this.columns); }

  openRealTimeDataReport() {
    this.usageReportService.getRealTimeDataReportUrl(this.myAccount.id).subscribe(
      (url) => {
        // console.log(url);
        window.open(url, '_blank');
      }
    );
  }

  exportToExcel() {
    this.dataService.setLoading(true);
    this.generateRequest();
    let req: IUsageReportExportRequest = {
      accountId: this.myAccount.id,
      reportId: this.selectedReport.reportId,
      reportName: this.newReportName,
      columnNames: this.getColumnNamesString(),
      defaultReport: this.makeDefault ? 1 : 0,
      systemGroupName: this.sitesControl.selectedGrouping.id ? null : this.reportRequest.systemGroupName,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      groupId: this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.endDate,
      createdUserId: this.selectedReport.createdUserId,
      frequencyType: this.meterTypeControl.selectedMeter.value,
      publicFlag: 0,
      brand: this.myAccount.accountBranding,
    };

    req.reportId = this.selectedReport.reportId;
    req.reportName = this.selectedReport.reportName;
    req.maskingMode = this.dataService.getMaskMode();
    req.brand = window.localStorage.getItem("brand");
    this.usageReportService.exportUsageReport(req);
  }

  // Search
  toggleSearchRow() {
    const tableWrapper = document.getElementById('tableWrapper');
    tableWrapper.scrollTop = 0;
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    this.filterReport();
  }

  filterReport() {
    if (!this.searchTerm) {
      this.filteredReportData = this.reportData;
    } else {
      this.filteredReportData = this.usageReportPipe.transform(this.reportData, this.searchTerm);
    }
  }

  getReportNameDisplay() {
    if (this.selectedReport && this.selectedReport.reportName) {
      return this.selectedReport.reportName.length < 20 ? this.selectedReport.reportName : this.selectedReport.reportName.substr(0, 20) + '...';
    }
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }

  /**************************************************************
  Methods For Subscribe to Report Template - Start
  **************************************************************/
  getReportSettings(selectDefault?: boolean){
    this.utility.getReportSettings(this.myAccount.id, this.reportTemplate, this.dateRangeControl, this.datePeriods, this.reportDateRanges, this.maskMode, selectDefault, this.selectedReport, this.columns)
    .subscribe({
      next: ({ reportSettings, reportTemplate, selectedReport, publicReports, privateReports, startDate, endDate, columns }) => {
        this.reportSettings = reportSettings;
        this.selectedReport = selectedReport;
        this.publicReports = publicReports;
        this.privateReports = privateReports;
        this.startDate = startDate;
        this.endDate = endDate;
        this.reportsLoaded = true;
        this.columns = columns;
        reportTemplate.filters.forEach((filter) => {
          switch (filter.filterType) {
            case 'DatePicker':
              this.dateRangeControl = filter.savedFilterControl;
              break;
            case 'SitePicker':
              // Uncomment if using SitePicker
              this.sitesControl = filter.savedFilterControl;
              break;
            case 'SimpleDropdown':
              this.meterTypeControl.selectedMeter.value = filter.selectedOptionValue;
              break;
            default:
              // Action for other filter types
              break;
          }
        });

        // If we have the column editor on this report
        if(this.selectedReport && this.selectedReport.columnNames){
          const settingsColumns = this.selectedReport.columnNames.split(',');
          this.columns.forEach((section) => {
            section.columns.forEach((col) => {
              col.checked = false;
              settingsColumns.forEach((settingsCol) => {
                if (settingsCol == col.value) {
                  col.checked = true;
                }
              });
            });
          });
          this.applyColumnChanges(true);
        }

        this.generateUsageReportData();
      },
      error: (error) => {
        // Handle errors
      }
    });
  }

  reportSavedHandler($event) {
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    this.makePublic = this.selectedReport.publicFlag ? this.selectedReport.publicFlag == 1 : false;
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          this.sitesControl = filter.savedFilterControl;
          break;
        case 'SimpleDropdown':
          this.meterTypeControl.selectedMeter.value = filter.selectedOptionValue;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    let isNewReport = this.selectedReport.reportId == null;

    let temp = this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.id ? null : this.sitesControl.selectedGrouping.groupName ? this.sitesControl.selectedGrouping.groupName : null : null;
    if(temp==='All Sites') {
      temp = 'ALL_SITES';
    }

    const saveReportRequest: any = {
      reportType: this.reportTemplate.reportType,
      reportId: this.selectedReport.reportId,
      accountId: this.myAccount.id,
      columnNames: this.getColumnNamesString(),
      reportName: this.newReportName ? this.newReportName : this.selectedReport.reportName ? this.selectedReport.reportName : 'Generic Report 01',
      frequencyType: this.meterTypeControl.selectedMeter.value,
      defaultReport: this.selectedReport.defaultReport ? 1 : 0,
      publicFlag: this.selectedReport.publicFlag ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      system: this.sitesControl.selectedGrouping ? this.sitesControl.selectedGrouping.id ? false : true : false,
      systemGroupName: temp,
      groupId: this.sitesControl.selectedGrouping.id ? this.sitesControl.selectedGrouping.id : null,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.endDate,
    };
    this.utility.saveReport(saveReportRequest, isNewReport)
    .then((reportId) => {
        this.selectedReport.reportId = reportId;
        this.getReportSettings();
    })
    .catch((error) => {
        // it will load default report
        this.getReportSettings(true);

    });
  }

  reportSelectedHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the ReportSettings Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    this.startDate = loadSelectedReport.startDate;
    this.endDate = loadSelectedReport.endDate;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          this.sitesControl = filter.savedFilterControl;
          break;
        case 'SimpleDropdown':
          this.meterTypeControl.selectedMeter.value = filter.selectedOptionValue;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    // If we have the column editor on this report
    if(this.selectedReport && this.selectedReport.columnNames){
      const settingsColumns = this.selectedReport.columnNames.split(',');
      this.columns.forEach((section) => {
        section.columns.forEach((col) => {
          col.checked = false;
          settingsColumns.forEach((settingsCol) => {
            if (settingsCol == col.value) {
              col.checked = true;
            }
          });
        });
      });
      this.applyColumnChanges(true);
    }

    this.generateUsageReportData();
  }

  // Getting columns from Subscribe to Report Template
  columnChangeHandler($event){
    this.tableColumns = [];
    this.columns = $event;
    this.columns && this.columns.forEach((section) => {
      section.columns.forEach((col) => {
        const tableCol = Object.assign({}, col);
        this.tableColumns.push(tableCol);
      });
    });
    this.updateScrollbarLength();
  }

  exportHandler($event){
    this.selectedReport = $event;
    let reportSettings = $event; //extra variable as some fields do not exist on the BillingReport Object
    const loadSelectedReport = this.utility.loadSelectedReport(this.reportTemplate,reportSettings, this.dateRangeControl, this.datePeriods, this.reportDateRanges);
    this.makeDefault = loadSelectedReport.makeDefault;
    this.makePublic = loadSelectedReport.makePublic;
    loadSelectedReport.reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          this.dateRangeControl = filter.savedFilterControl;
          break;
        case 'SitePicker':
          // Uncomment if using SitePicker
          this.sitesControl = filter.savedFilterControl;
          break;
        case 'SimpleDropdown':
          this.meterTypeControl.selectedMeter.value = filter.selectedOptionValue;
          break;
        default:
          // Action for other filter types
          break;
      }
    });

    // If we have the column editor on this report
    if(this.selectedReport.columnNames){
      const settingsColumns = this.selectedReport.columnNames.split(',');
      this.columns.forEach((section) => {
        section.columns.forEach((col) => {
          col.checked = false;
          settingsColumns.forEach((settingsCol) => {
            if (settingsCol == col.value) {
              col.checked = true;
            }
          });
        });
      });
      this.applyColumnChanges(true);
    }

    this.dataService.setLoading(true);
    this.exportToExcel();
  }

  reportDeletedHandler($event){
    this.reportSettingsService.deleteReportSetting(this.myAccount.id, this.selectedReport.reportId, this.dataService.getUserSource().login,this.reportTemplate.reportType, this.selectedReport.publicFlag).subscribe(
      () => {
        this.getReportSettings(true);
      }
    );
  }
  /**************************************************************
  Methods For Subscribe to Report Template - End
  **************************************************************/
}
