import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Headers, Http, ResponseContentType} from "@angular/http";
import {DataService} from "../shared/data.service";
import {environment} from "../../environments/environment";
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {BrokerDrop, IBrokerDropRequest, IBrokerPayout} from "../shared/entities/broker";
import {IUserAccount} from "../shared/entities/profile";

@Injectable()
export class BrokerService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http,
              private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getPayoutReport(thirdPartyAccountId: string, startDate: string, endDate: string): Observable<IBrokerPayout[]>{
    let url = environment.payoutReportUrl.replace('{thirdPartyAccountId}', thirdPartyAccountId);
    url = url.replace('{startDate}', startDate);
    url = url.replace('{endDate}', endDate);
    return this.http
      .get(url).pipe(
        map((data: IBrokerPayout[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  exportPayoutReport(thirdPartyAccount: IUserAccount, startDate: string, endDate: string, maskingMode :boolean, brand: string): any {
    let url = environment.exportPayoutReportUrl.replace('{thirdPartyAccountId}', thirdPartyAccount.id);
    url = url.replace('{name}', thirdPartyAccount.name);
    url = url.replace('{startDate}', startDate);
    url = url.replace('{endDate}', endDate);
    url = url.replace('{maskingMode}', (maskingMode ? "true":"false"));
    url = url.replace('{brand}', brand);
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .get(url, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  getBrokerDrops(request: IBrokerDropRequest): Observable<BrokerDrop[]> {
      let url = environment.getBrokerDropUrl.replace('{accountId}', request.accountId.toString());
      return this.http
      .post<BrokerDrop[]>(url, request, {headers: this.headers});
  }

  exportBrokerDrop(request: IBrokerDropRequest): any {
    let url = environment.production ? environment.exportBrokerDropUrl.replace('{accountId}', request.accountId.toString()) : environment.exportBrokerConfirmUrl;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http
      .post(url, request, {headers: headers, responseType: 'blob', observe: 'response'})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.body, filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.body], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.body);
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }, (error) => {
          console.error(error);
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }
}
