import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { PortalService } from "./portal.service";

@Injectable()
export class OamHttpInterceptor implements HttpInterceptor {
  constructor(private portalService: PortalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const nrgEndpoints = ["./private", "./api"];
    if (!nrgEndpoints.some(endpoint => req.url.startsWith(endpoint))) return next.handle(req);

    var headers = req.headers;

    const accessToken = window.localStorage.getItem("access_token");
    const refreshToken = window.localStorage.getItem("refresh_token");
    if (accessToken) {
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
      headers = headers.set('Refresh-Token', refreshToken);
    }

    const interceptedReq = req.clone({ headers })
    return next.handle(interceptedReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          let newAuthToken = event.headers.get('Authorization-Updated');
          let newRefreshToken = event.headers.get('Refresh-Updated');
          if (newAuthToken && newRefreshToken) {
            localStorage.setItem('access_token', newAuthToken);
            localStorage.setItem('refresh_token', newRefreshToken);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          console.error('Unauthorized access - possibly invalid token.');
          this.portalService.logout().subscribe();
        }
        // Let other errors pass through
        return throwError(error);
      })
    );
  }
}
