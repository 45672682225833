import { Component, OnInit, Inject } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import { DOCUMENT } from '@angular/common';
import { AccordionAnimation } from '../animations/accordionAnimation';
import { FadeAnimation } from '../animations/fadeAnimation';
import { Subject, Subscription } from "rxjs";
import * as moment from "moment";
import { DataService } from '../shared/data.service';
import { PortalService } from "../shared/portal.service";
import { IProduct, IZone } from '../shared/entities/product';
import { IContract } from "../shared/entities/contract";

import { IUserAccount } from '../shared/entities/profile';
import { GenericPipe } from '../shared/pipe/generic.pipe';
import { zip } from 'rxjs';
import {  Router } from "@angular/router";
import {ForwardPriceForm} from "../markets/forward-price-form/forward-price.form";
import { Authority } from '../shared/const/authority';

@Component({
  selector: 'app-pchbe',
  animations: [ FadeAnimation, AccordionAnimation ],
  templateUrl: './pchbe.component.html',
  styleUrls: ['./pchbe.component.scss'],
  providers: [GenericPipe]
})
export class PchbeComponent implements OnInit {

  isPchbeEnabled: boolean = false;
  pchbePortalFailed = false;
  pchbePortalLoaded = false;
  pchbePortalSubscription: Subscription;
  selectedAccount: IUserAccount;
  isSuperUser: boolean = false;
  contractsHidden: boolean = false;
  contracts: IContract[];
  selectedContract: IContract = null;
  selectedProduct: IProduct = null;
  noContractError: boolean = false;
  noDataError: boolean = false;
  maskMode: boolean;
  maskModeSubscription: Subscription;
  contractSubscription: Subscription;
  pchbeProductServiceBus = new Subject<string>();
  productServiceBusName = '_pchbeProductServiceBus';

  constructor(
    private router: Router,
    private dataService: DataService,
    private portalService: PortalService,
    private httpClient: Http,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    window[this.productServiceBusName] = this.pchbeProductServiceBus;
    this.dataService.setTitleSource('Post-Close Block Entry');
    this.dataService.setSelectedNavItem('accounts');
    this.dataService.setCurrentActivePage('accounts');
    this.dataService.pchbePageTriggered.emit(true);

    this.selectedAccount = this.dataService.getAccountSource();
    this.isPchbeEnabled = this.hasPchbeEnabled(this.selectedAccount);

    this.dataService.setPositionReportDate(new Date());
    const profile = this.dataService.getProfile();
    if (!profile) {
      return;
    }

    const profileName = profile.firstName + ' ' + profile.lastName;
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    if (this.isSuperUser) {
      if (this.dataService.getContractsForAccount()) {
        this.contracts = this.dataService
          .getContractsForAccount()
          .sort(this.contractSorter)
          .map(this.mapRecentProducts)
          .filter(this.contractFilter);
        if (this.contracts.length > 0) {
          this.onContractSelected(this.contracts[0]);
          this.noContractError = false;
        } else {
          this.noContractError = true;
        }
      }
      this.contractSubscription = this.dataService.contractsForAccountSourceUpdated.subscribe(
        contracts => {
          this.contracts = contracts
            .sort(this.contractSorter)
            .map(this.mapRecentProducts)
            .filter(this.contractFilter);
          if (this.contracts.length > 0) {
            this.onContractSelected(this.contracts[0]);
            this.noDataError = false;
          } else {
            this.noDataError = true;
          }
        }
      );
      this.maskMode = this.dataService.getMaskMode();
      this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe(
        maskMode => {
          this.maskMode = maskMode;
        }
      );

    }
  }

  public onContractSelected(contract: IContract) {
    this.selectedContract = contract;
    if (contract.products.length && contract.products[0].siteCount === undefined) {
      this.portalService.populateProductDetails(contract);
    }
    this.onProductSelected(contract.products[0]);
  }

  onProductSelected(product: IProduct) {
    this.selectedProduct = product;
    const appProperties = this.dataService.getAppProperties();
    const profile = this.dataService.getProfile();
    let authName = this.getUserRole();
    
    window.vhosConfig = {
      apiPrefix: (appProperties && appProperties.pchbePrefix ? appProperties.pchbePrefix : '/myaccount/private'),
      pchbeUserId: profile.userId,
      pchbeCompanyId: this.selectedAccount.id,
      pchbeCompanyName: this.selectedAccount.name,
      userFirstName: profile.firstName,
      userLastName: profile.lastName,
      userName: authName,
      userEmail: profile.email,
      userAccess: 'ADMIN',
      productId: product.id,
      subdealId: product.dealOptionId,
      contractId: this.selectedContract.contractNum,
      accountId: this.selectedAccount.id,
      brokerUserEmail: profile.email,
      brokerUserAccess: 'ADMIN'
    };
    if(this.pchbePortalLoaded) {
      console.log('updating service bus with product ' + product.id);
      this.pchbeProductServiceBus.next(product.id);
    } else {
      this.loadPchbeApp();
    }

  }

  private getUserRole() {
    if(this.portalService.userHasAuthority(Authority.VhospAdmin)) {
      return 'oamadmin';
    } else { //if(this.portalService.userHasAuthority(Authority.VhospTrader)) {
      return 'oamtrader';
    }    
    // return '';
  }

  contractSorter = function mySorter(val1, val2) {
    if (val1.deliveryEndDate == val2.deliveryEndDate) {
      return val1.deliveryStartDate < val2.deliveryStartDate ? 1 : -1;
    } else {
      return val1.deliveryEndDate < val2.deliveryEndDate ? 1 : -1;
    }
  };

  contractFilter = (contract: IContract) => {
    return contract.commodity == true 
      && (contract.contractType !== 'Transition' || contract.status !== 'Terminated')
      && moment(contract.deliveryEndDate).isSameOrAfter(moment().subtract(6, 'months'))
      && contract.products.length > 0
      && contract.market == 'ERCOT';
  }

  mapRecentProducts = (contract: IContract) => {
    const newContract = {...contract} as IContract;
    newContract.products = contract.products.filter(product => moment(product.deliveryEndDate).isAfter(moment().subtract(6, 'months')));
    return newContract;
  }

  private loadPchbeApp() {
    if(!this.pchbePortalLoaded) {
      this.pchbePortalSubscription = zip(
        this.httpClient.get(this.pchbePortalJsUrl, { responseType: ResponseContentType.Text }),
        this.httpClient.get(this.pchbePortalCssUrl, { responseType: ResponseContentType.Text }),
        this.httpClient.get(this.pchbePortalShadowDOMScript, { responseType: ResponseContentType.Text })
      ).subscribe(
        pchbePortalFiles => {

          const component = this.document.getElementsByTagName('app-pchbe')[0];

          // add style tag
          const style = this.document.createElement('style');
          style.innerHTML = pchbePortalFiles[1].text();
          component.appendChild(style);

          // add script tag
          const script = this.document.createElement('script');
          script.innerHTML = pchbePortalFiles[0].text();
          component.appendChild(script);

          // add shadowDOM script tag
          const scriptShadowDOM = this.document.createElement('script');
          scriptShadowDOM.innerHTML = pchbePortalFiles[2].text();
          component.appendChild(scriptShadowDOM);

          // add app
          const frontend = this.document.createElement('app-vhosapp');
          const content = this.document.getElementById('embedded-app-container');
          content.appendChild(frontend);

          this.pchbePortalLoaded = true;
        },
        err => {
          console.error('Failed to load PCHBE', err);
          this.pchbePortalFailed = true;
        }
      );
    }
  }

  ngOnDestroy() {
    this.dataService.pchbePageTriggered.emit(false);
    if (this.pchbePortalSubscription) {
      this.pchbePortalSubscription.unsubscribe();
    }
  }

  get pchbePortalJsUrl(): string {

    if(this.document.URL.includes('localhost:8085')){
      return 'http://localhost:8085/assets/app/assets/js/vhosapp.js';
    }

    if(this.document.URL.includes('localhost:8880')){
      return 'http://localhost:8085/vhos-pchbe.js';
    }

    return this.document.URL.includes('localhost:4200') ?
      'http://localhost:4200/assets/app/assets/vhosapp.js' :
      '/myaccount/private/vhosp/vhos-pchbe.js';
  }

  get pchbePortalCssUrl(): string {

    if(this.document.URL.includes('localhost:8085')){
      return 'http://localhost:8085/assets/app/assets/css/app.css';
    }

    if(this.document.URL.includes('localhost:8880')){
      return 'http://localhost:8085/vhos-pchbe.css';
    }

    return this.document.URL.includes('localhost:4200') ?
      'http://localhost:4200/assets/app/assets/css/app.css' :
      '/myaccount/private/vhosp/vhos-pchbe.css';
  }

  get pchbePortalShadowDOMScript(): string {
    return 'https://cdnjs.cloudflare.com/ajax/libs/shadydom/1.8.0/shadydom.min.js';
  }

  hasPchbeEnabled(account: IUserAccount) {
    return this.portalService.userHasAuthority(Authority.SuperUser);
  }
  hasPermission(mask: number, account: IUserAccount) {
    const permission = account.permissions;
  // tslint:disable-next-line:no-bitwise
    return (permission & mask) === mask;
  }
  public getDateDisplay(dateString: string) {
    let dateStr = this.portalService.getDateDisplay(dateString);
    if (dateStr && dateStr.includes("9999")) {
      dateStr = "";
    }
    return dateStr;
  }

  getZoneDisplay(market, congestionZones: IZone[]) {
    if (!congestionZones || congestionZones.length === 0 || !market) {
      return '--';
    }
    if (congestionZones.length === 1) {
      return ForwardPriceForm.getHumanZoneName(market, congestionZones[0].congestionZone);
    }
    return congestionZones.map(zone => ForwardPriceForm.getHumanZoneName(market, zone.congestionZone) + ' ' + zone.percentage + '%').join(', ');
  }

}
