import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Http, Response, Headers, ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import { IPasswordResetRequest } from '../../shared/entities/profile';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {DataService} from "../../shared/data.service";

@Injectable()
export class CreatePasswordService {

  private headers: Headers;

  constructor(private http: Http,
              private dataService: DataService) {
    this.headers = new Headers({'Content-Type': 'application/json'});
  }

  createPassword(request: IPasswordResetRequest): Observable<any> {
    if(environment.production){
        let url = environment.createPasswordUrl;
        // console.log("POSTing to ",url);
        // console.log("With request ",request);
        return this.http
            .post(url, request, {headers: this.headers})
            .catch(this.handleError);
    } else {
        let url = environment.createPasswordUrl;
        return this.http
            .post(url, request, {headers: this.headers})
            .catch(this.handleError);
    }

  }
  
  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}