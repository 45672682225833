import { Component, Input, OnInit } from '@angular/core';
import { IFourcpSite } from '../../../shared/entities/site';
import { I4cpSitePerformance } from '../../../shared/entities/fourcp';
import { PortalService } from '../../../shared/portal.service';

@Component({
  selector: 'app-fourcp-site-performance-table',
  templateUrl: './fourcp-site-performance-table.component.html',
  styleUrls: ['./fourcp-site-performance-table.component.scss', '../fourcp-site-performance.component.scss']
})
export class FourcpSitePerformanceTableComponent implements OnInit {

  @Input()
  year: number;

  @Input()
  selectedSite: IFourcpSite | IFourcpSite;

  @Input()
  sitePerformance: I4cpSitePerformance | I4cpSitePerformance;

  constructor(private portalService: PortalService) { }

  ngOnInit() {
  }

  numbersWithCommas(x) {
    return this.portalService.numberWithCommas(x);
  }


}
