import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Response, ResponseContentType, Http, Headers } from '@angular/http';
import { DataService } from '../shared/data.service';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {
  IForwardTradeRates,
  IMarketData,
  IOffset,
  IReportSettings,
  IYearCurveRate,
  ErcotMarketSection,
  FuelMixData,
  IMarketSource,
  MarketDashboardView,
  Market
} from '../shared/entities/markets';
import * as moment from 'moment';
import * as _ from 'lodash';
import { IMarketSetting } from '../shared/entities/marketSetting';
import { PortalService } from '../shared/portal.service';
import { ITradeDateRate } from '../shared/entities/markets';
import { UtilsService } from '../shared/utils.service';

@Injectable()
export class MarketsService {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private oldHttp: Http,
    private dataService: DataService,
    private portalService: PortalService,
    private utilsService: UtilsService
  ) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  getMarketStates() {
    let url = environment.getMarketStates;

    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getMarketDemandSources(market: string): Observable<IMarketSource[]> {
    let url = environment.getMarketDemands;
    url = url.replace('{market}', market.toUpperCase());
    // TODO: temp mock data. remove later
    // if (market === Market.PJM) {
    //   return this.http.get<IMarketSource[]>(
    //     './assets/api/markets/pjm-rt-market-demands.json'
    //   );
    // }
    return this.http
      .get<IMarketData>(url)
      .map((response) => {
        return response.sources;
      })
      .catch(this.handleError);
  }

  getLatestMarketsDashboard() {
    let url = environment.getLatestMarketsDashboard;

    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getMarketData(
    source: string,
    offset?: IOffset,
    url?: string,
    propertyName?: string,
    pastOffset?: IOffset
  ): Observable<IMarketData> {
    if (
      propertyName === ErcotMarketSection.Snapshot &&
      !source.match('_DA_') &&
      (source.match('_LZ_') || source.match('_HB_'))
    ) {
      url = environment.getSPPMarketDashboardDataUrl;
    }

    if (pastOffset) {
      url = environment.getForecastSPPMarketDashboardDataUrl;
    }

    if (environment.production) {
      url = url.replace('{accountId}', this.dataService.getAccountSource().id);
    }

    url = url.replace('{source}', source);

    if (offset) {
      url = url.replace('{offset}', offset.value.toString());
    } else {
      url = url.replace('{offset}', '-7');
    }
    if (pastOffset) {
      url = url.replace('{pastOffset}', pastOffset.value.toString());
    }
    return this.http
      .get<IMarketData>(url, { headers: this.headers })
      .map((response) => response)
      .catch(this.handleError);
  }

  getSettings(componentName: string) {
    let url = environment.getMarketsSettingsUrl.replace(
      '{componentUsed}',
      componentName
    );

    return this.http
      .get(url)
      .map((response: HttpResponse<IMarketSetting[]>) => response)
      .catch(this.settingsError);
  }

  updateSetting(setting: IMarketSetting) {
    let url = environment.updateMarketSettingUrl;
    return this.http
      .post(url, setting)
      .map((response: HttpResponse<IMarketSetting>) => response)
      .catch(this.settingsError);
  }

  settingsError(error: Response) {
    //Returns empty array to force default settings
    return new Observable();
  }

  exportToExcel(
    marketSection: string,
    offset: string,
    sources: string,
    maskingMode: boolean,
    pastOffset?: string
  ) {
    let url;
    if (marketSection == ErcotMarketSection.Info) {
      url = environment.exportMarketInformationUrl.replace(
        '{accountId}',
        this.dataService.getAccountSource().id
      );
      url = url.replace('{sources}', sources); // sources should be a comma separated string
      url = url.replace('{maskingMode}', maskingMode ? 'true' : 'false');
      url = url.replace('{pastOffset}', pastOffset);
    } else if (
      marketSection == ErcotMarketSection.HenryHub ||
      marketSection == ErcotMarketSection.HoustonShipChannel
    ) {
      url = environment.exportGasUrl.replace(
        '{accountId}',
        this.dataService.getAccountSource().id
      );
      url = url.replace('{source}', marketSection);
      url = url.replace('{maskingMode}', maskingMode ? 'true' : 'false');
    }
    url = url.replace('{offset}', offset);
    this.portalService.exportToExcel(url, marketSection + '.xlsx');
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

  getReportSettings(): Observable<IReportSettings[]> {
    let url = environment.getReportSettings.replace(
      '{accountId}',
      this.dataService.getAccountSource().id
    );
    return this.http.get(url).pipe(
      map((data: IReportSettings[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError(this.handleError);
      })
    );
  }

  updateReportSettings(report: IReportSettings): Observable<IReportSettings> {
    let url = environment.updateRenewalReportSettings
      .replace('{accountId}', report.accountId)
      .replace(
        '{reportId}',
        report.reportId ? report.reportId.toString() : '0'
      );
    return this.http.post(url, report, { headers: this.headers }).pipe(
      map((data: IReportSettings) => {
        return data;
      }),
      catchError((error) => {
        return throwError(this.handleError);
      })
    );
  }

  deleteReportSettings(accountId: string, reportId: number) {
    let url = environment.deleteRenewalReportSettings
      .replace('{accountId}', accountId)
      .replace('{reportId}', reportId.toString());
    return this.http
      .post(url, '')
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  getIndicativePriceData(request) {
    // console.log("getIndicativePriceData");
    let url = environment.getForwardTradePriceByYear;
    return this.http
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<ITradeDateRate[]>) => response)
      .catch(this.handleError);
  }

  getForwardPriceByTradeDateChartData(
    request: IReportSettings
  ): Observable<IForwardTradeRates[]> {
    // console.log("getForwardPriceByTradeDateChartData");
    let url = environment.getForwardTradePriceByTradeDate;
    return this.http.post(url, request, { headers: this.headers }).pipe(
      map((data: IForwardTradeRates[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError(this.handleError);
      })
    );
  }

  getForwardPriceByMonthChartData(request: IReportSettings) {
    // console.log("getForwardPriceByMonthChartData");
    let url = environment.getForwardTradePriceByMonth;
    return this.http
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<IYearCurveRate>) => response)
      .catch(this.handleError);
  }

  getForwardPriceByYearChartData(request: IReportSettings) {
    // console.log("getForwardPriceByYearChartData");
    let url = environment.getForwardTradePriceByYear;
    return this.http
      .post(url, request, { headers: this.headers })
      .map((response: HttpResponse<IYearCurveRate>) => response)
      .catch(this.handleError);
  }

  exportForwardPriceChart(request: IReportSettings): any {
    const url = environment.getForwardPriceReportExport;
    const headers = new Headers({ 'Content-Type': 'application/json' });
    return this.oldHttp
      .post(url, request, {
        headers: headers,
        responseType: ResponseContentType.Blob
      })
      .subscribe(
        (res) => {
          this.dataService.setLoading(false);
          const filename = this.determineExcelExportFileName(
            request.reportType,
            request.reportName
          );
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1
          ) {
            const blob = new Blob([res.blob()], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const a = document.createElement('a');
            const objectUrl = window.URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(objectUrl);
            }, 100);
          } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
        },
        (err) => {
          alert('Failed to create export. Please try again later.');
          console.log('Failed to create excel export', err);
          this.dataService.setLoading(false);
        }
      );
  }

  private determineExcelExportFileName(
    reportType: string,
    reportName: string
  ): string {
    const fileNameEnd = '_' + moment().format('MMDDYYYY') + '.xlsm';
    if (reportType === 'Renewal Analysis') {
      return 'Renewal Analysis' + fileNameEnd;
    } else if (reportType === 'Custom') {
      return 'Custom Analysis' + fileNameEnd;
    } else {
      // Saved Report
      return reportName + fileNameEnd;
    }
  }

  getGenerationMixData(market: string): Observable<FuelMixData[]> {
    let url = environment.getMarketFuelMix;
    url = url.replace('{market}', market.toUpperCase());
    return this.http.get<FuelMixData[]>(url).pipe(
      map((res: any) => {
        return res.currentFuelTypes.map((item: FuelMixData) => {
          item.lastUpdated = res.lastUpdated;
          return item;
        });
      })
    );
  }

  getRTMarketDemandsSettings() {
    // TODO replace with real URL {viewName}/{componentUsed}
    return this.http
      .get<IMarketSetting[]>(
        './assets/api/markets/ercot-rt-market-demand-settings.json'
      )
      .pipe(map((res) => res));
  }

  getRTMarketPrices(
    market: string,
    type?: string,
    viewName?: string
  ): Observable<IMarketSource[]> {
    let url = viewName
      ? environment.getMarketTypeViewPrices
      : type
      ? environment.getMarketTypePrices
      : environment.getMarketPrices;
    url = url.replace('{market}', market.toUpperCase());
    if (type) {
      url = url.replace('{type}', type);
    }
    if (viewName) {
      url = url.replace('{viewName}', viewName);
    }
    return this.http
      .get<IMarketSource[]>(url)
      .pipe(map((res: IMarketData) => res.sources));
  }

  getGridConditions(market: string): Observable<IMarketSource[]> {
    let url = environment.getGridConditions;
    url = url.replace('{market}', market.toUpperCase());
    // TODO: temp mock data. remove later
    // if (market === Market.PJM) {
    //   return this.http.get<IMarketSource[]>(
    //     './assets/api/markets/pjm-grid-conditions.json'
    //   );
    // }
    return this.http
      .get<IMarketSource[]>(url)
      .pipe(map((res: IMarketData) => res.sources));
  }

  getSnapshotSettings() {
    return this.http
      .get<IMarketSetting[]>(
        './assets/api/markets/ercot-market-snapshot-settings.json'
      )
      .pipe(map((res) => res));
  }

  getInfoSettings() {
    return this.http
      .get<IMarketSetting[]>(
        './assets/api/markets/ercot-market-info-settings.json'
      )
      .pipe(map((res) => res));
  }

  getGenerationMixSettings() {
    return this.http
      .get<IMarketSetting[]>(
        './assets/api/markets/ercot-market-generation-mix-settings.json'
      )
      .pipe(map((res) => res));
  }

  /**
   * returns a boolean
   * true if no page settings have changed
   * false when any of the page settings have changed
   */
  get settingsNotChanged(): boolean {
    return (
      _.isEqual(
        this.dataService.dbInfoMarketGraphSettings,
        this.dataService.newDbInfoMarketGraphSettings
      ) &&
      _.isEqual(
        this.dataService.dbSnapshotMarketGraphSettings,
        this.dataService.newDbSnapshotMarketGraphSettings
      ) &&
      _.isEqual(
        this.dataService.dbMarketDemandsSettings,
        this.dataService.newDbMarketDemandsSettings
      ) &&
      (this.dataService.selectedMarket === Market.PJM
        ? _.isEqual(
            this.dataService.dbPjmYourListSettings,
            this.dataService.newDbPjmYourListSettings
          ) &&
          _.isEqual(
            this.dataService.dbPjmDaYourListSettings,
            this.dataService.newDbPjmDaYourListSettings
          )
        : true)
    );
  }

  /**
   *
   * @param settings
   * @param newSettings
   * updates the id and mdUser fields into the new settings from the corresponding row in settings
   */
  setSettingsIdData(settings: IMarketSetting[], newSettings: IMarketSetting[]) {
    newSettings = newSettings.map((newSetting) => {
      const matchingSetting = settings.find(
        (setting) => setting.fieldName === newSetting.fieldName
      );
      if (matchingSetting) {
        newSetting.id = matchingSetting.id;
        newSetting.mdUser = matchingSetting.mdUser;
      }
      return newSetting;
    });
  }

  getDashboardViews(market: string): Observable<MarketDashboardView[]> {
    let url = environment.getMarketDashboardViews;
    url = url.replace('{market}', market.toUpperCase());
    return this.http.get<MarketDashboardView[]>(url).pipe(map((res) => res));
  }

  getMarketViewSettings(
    viewName: string,
    componentName: string
  ): Observable<IMarketSetting[]> {
    let url = environment.getMarketsViewSettingsUrl.replace(
      '{componentUsed}',
      componentName
    );
    url = url.replace('{viewName}', viewName);
    return this.http.get<IMarketSetting[]>(url).map((response) => response);
  }

  saveAllMarketDashboardSettings(
    settings: IMarketSetting[],
    market: string
  ): Observable<IMarketSetting[]> {
    let url = environment.saveAllMarketDashboardSettings;
    url = url.replace('{market}', market);
    return this.http
      .post<IMarketSetting[]>(url, settings)
      .map((response) => response);
  }

  saveAllMarketViews(
    views: MarketDashboardView[]
  ): Observable<MarketDashboardView[]> {
    let url = environment.saveAllMarketViews;
    return this.http
      .post<MarketDashboardView[]>(url, views)
      .map((response) => response);
  }

  deleteMarketView(viewName: string, market: string): Observable<any> {
    let url = environment.deleteMarketView;
    url = url.replace('{viewName}', viewName).replace('{market}', market);
    return this.http.delete<any>(url);
  }

  deleteMarketSettings(viewName: string, market: string) {
    let url = environment.deleteMarketSettings;
    url = url.replace('{viewName}', viewName).replace('{market}', market);
    return this.http.delete<any>(url);
  }

  // TODO: verify URL and return type
  savePjmYourList(payload: IMarketSetting[]): Observable<IMarketSetting[]> {
    let url = environment.savePJMYourList;
    return this.http.post<IMarketSetting[]>(url, payload);
  }
}
