import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from "rxjs";
import {IAmendmentRequest, IAmendmentSite, IAmendmentProduct} from "../shared/entities/amendments";
import {DataService} from "../shared/data.service";
import * as moment from 'moment';
import {IAttachment} from "../shared/entities/attachment";

@Injectable()
export class AmendmentsAttachmentsService {

  headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http, private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }


  getAttachmentsList(requestId: number){
    let url = environment.getAmendmentAttachmentslistUrl
      .replace('{accountId}', this.dataService.getAccountSource().id)
      .replace('{requestId}', requestId.toString());

    return this.http
      .get(url)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  downloadAttachment(requestId: number, attachment: IAttachment){
    let url = environment.downloadAmendmentAttachmentUrl
      .replace('{accountId}', this.dataService.getAccountSource().id)
      .replace('{requestId}', requestId.toString())
      .replace('{id}', attachment.id.toString());

    return this.oldHttp
      .get(url, {responseType: ResponseContentType.Blob})
      .subscribe(
        (response) => {
          let fileName = attachment.attachmentName;
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(response.blob(), fileName);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([response.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(response.blob());
            link.download = fileName;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  deleteAttachment(requestId: number, attachmentId: number){
    let url = environment.deleteAmendmentAttachmentUrl
      .replace('{accountId}', this.dataService.getAccountSource().id)
      .replace('{requestId}', requestId.toString())
      .replace('{id}', attachmentId.toString());

    return this.oldHttp
      .post(url,"")
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  uploadAttachment(requestId: number, attachmentToUpload: File){
    const formData: FormData = new FormData();
    formData.append('file', attachmentToUpload);
    // formData.append('name', attachmentToUpload.name);

    let url = environment.uploadAmendmentAttachmentUrl
      .replace('{accountId}', this.dataService.getAccountSource().id)
      .replace('{requestId}', requestId.toString());

    return this.http
      .post(url, formData)
      .map((response: HttpResponse<any>) => response)
      .catch(this.handleError);
  }

  saveComment(requestId: number, attachmentToSave: IAttachment){
    let url = environment.saveAttachmentCommentUrl
      .replace('{accountId}', this.dataService.getAccountSource().id)
      .replace('{requestId}', requestId.toString())
      .replace('{id}', attachmentToSave.id.toString());

    let header = new HttpHeaders({'Content-Type': 'application/json'});

    return this.oldHttp
      .post(url, attachmentToSave)
      .map((response: any) => <any>response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
