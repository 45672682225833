import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'select-component',
  templateUrl: './select.component.html',
  styleUrls: ['select.component.scss']
})
export class SelectComponent implements OnInit{

  constructor() { }

  @Input()
  options: any[];

  @Input()
  value: any;

  @Input()
  disabled: boolean;

  @Output()
  selected: EventEmitter<any> = new EventEmitter<any>();

  selectedOption: any;

  ngOnInit() {
    this.selectedOption = this.value;
  }

  selectOption(option) : void {
    this.selectedOption =  option;
    this.selected.emit(this.selectedOption);
  }

}
