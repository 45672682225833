import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IColumnRequest } from './column-data';

/* GET Requests return null selectedSettings if none are saved */
@Injectable()
export class ColumnSelectorService {
  headers: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  getSingleEntityUrl(entity: string, accountId: string, entityId: string): string {
    return environment.singleEntityColumnSettings
      .replace('{accountId}', accountId)
      .replace('{entityId}', entityId)
      .replace('{entity}', entity);
  }

  getAllEntityUrl(entity: string, accountId: string): string {
    return environment.allEntityColumnSettings
      .replace('{accountId}', accountId)
      .replace('{entity}', entity);
  }

  saveColumnSettingsForSingleEntity(entity: string, accountId: string, entityId: string, selectedSettings: string[]): Observable<IColumnRequest> {
    const requestBody: IColumnRequest = {
      id: entityId,
      selectedSettings
    };

    return this.httpClient
      .post<IColumnRequest>(this.getSingleEntityUrl(entity, accountId, entityId), requestBody, { headers: this.headers })
      .catch(this.handleError);
  }

  getColumnSettingsForSingleEntity(entity: string, accountId: string, entityId: string): Observable<IColumnRequest> {
    return this.httpClient
      .get<IColumnRequest>(this.getSingleEntityUrl(entity, accountId, entityId))
      .catch(this.handleError);
  }

  saveColumnSettingsForAllEntity(entity: string, accountId: string, selectedSettings: string[]): Observable<IColumnRequest> {
    const requestBody: IColumnRequest = {
      id: accountId,
      selectedSettings
    };

    return this.httpClient
      .post<IColumnRequest>(this.getAllEntityUrl(entity, accountId), requestBody, { headers: this.headers })
      .catch(this.handleError);
  }

  getColumnSettingsForAllEntity(entity: string, accountId: string): Observable<IColumnRequest> {
    return this.httpClient
      .get<IColumnRequest>(this.getAllEntityUrl(entity, accountId))
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
