import { Component, Input, OnInit } from '@angular/core';
import { IProduct } from '../../shared/entities/product';

@Component({
  selector: 'app-pchbe-chart',
  templateUrl: './pchbe-chart.component.html',
  styleUrls: ['./pchbe-chart.component.scss']
})
export class PchbeChartComponent implements OnInit {

  @Input() product: IProduct;

  constructor() { }

  ngOnInit() {
  }

}
