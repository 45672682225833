import { Component, OnInit } from '@angular/core';
import {IUserAccount, IUserPermission} from "../../shared/entities/profile";
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {FourcpService} from "../fourcp.service";
import {I4cpAlert, I4cpContact, I4cpPreferences} from "../../shared/entities/fourcp";

import * as moment from 'moment';
import {PortalService} from "../../shared/portal.service";
import {Permissions} from "../../shared/const/permissions";
import {IUser} from "../../shared/entities/user";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {PagerService} from "../../shared/pager.service";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {UserAccountPipe} from "../../shared/pipe/useraccounts.pipe";
import {ProfileService} from "../../profile/profile.service";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {FadeAnimation} from "../../animations/fadeAnimation";
declare var $:any;

@Component({
  selector: 'app-fourcp-preferences',
  templateUrl: './fourcp-preferences.component.html',
  providers: [GenericPipe, OrderByPipe, UserAccountPipe],
  animations: [FadeInAnimation, FadeAnimation],
  styleUrls: ['./fourcp-preferences.component.scss']
})
export class FourcpPreferencesComponent implements OnInit {

  myAccount: IUserAccount;
  preferences: I4cpPreferences;
  contacts: I4cpContact[] = [];
  isAdmin: boolean;
  user: IUser;
  phoneMask: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  showSearchRow: boolean;
  searchTerm: string;
  filteredContacts: I4cpContact[] = [];
  pager: any;
  sortBy: string = 'user';
  ascending: boolean = true;
  addingContacts: boolean;
  newContactsToAdd: I4cpContact[] = [];
  existingUsersToAdd: IUserPermission[] = [];
  existingUsers: IUserPermission[] = [];
  allUsers: IUserPermission[] = [];
  tempUser: IUserPermission;
  tempContact: I4cpContact;
  editingTextPhone: boolean;
  showValidationError: boolean;
  errorMessage: string;
  contactToDelete: I4cpContact;

  lastModifiedDisplay: string;
  enrollmentDateDisplay: string;
  alertTypeDisplay: string;
  alertTimeDisplay: string;

  contactPreferenceOptions = ['Text', 'Phone Call', 'Text & Phone Call'];

  accountSubscription: Subscription;
  preferencesSubscription: Subscription;
  userSubscription: Subscription;
  usersUpdatedSubscription: Subscription;

  constructor(private dataService: DataService,
              private fourcpService: FourcpService,
              private portalService: PortalService,
              private genericPipe: GenericPipe,
              private pagerService: PagerService,
              private orderByPipe: OrderByPipe,
              private manageProfileService: ProfileService) { }

  ngOnInit() {
    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
      this.isAdmin = this.portalService.hasPermission(Permissions.ManageUsers);
      this.getContacts();
    }
    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.isAdmin = this.portalService.hasPermission(Permissions.ManageUsers);
      this.myAccount = account;
      this.getContacts();
    });

    this.preferences = this.dataService.getFourcpPreferences();
    if(this.preferences){
      this.preferencesUpdated();
    }
    this.preferencesSubscription = this.dataService.fourcpPreferencesUpdated.subscribe((preferences) => {
      this.preferences = preferences;
      this.preferencesUpdated();
    });

    this.user = this.dataService.getUserSource();
    this.userSubscription = this.dataService.userSourceUpdated.subscribe((user) => {
      this.user = user;
    });

    this.usersUpdatedSubscription = this.dataService.usersUpdatedTriggered.subscribe(() => {
      this.getContacts();
    });

    this.dataService.setContextualHelp(false);
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.preferencesSubscription) this.preferencesSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.usersUpdatedSubscription) this.usersUpdatedSubscription.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  preferencesUpdated() {
    if(this.preferences.alertType) {
      this.alertTypeDisplay = this.preferences.alertType.replace('_', ' ').toLowerCase();
    }
    if(this.preferences.alertTime) {
      this.alertTimeDisplay = this.preferences.alertInterval + ' minute window called at ' + moment(this.preferences.alertTime).format('HH:mm') + ' CST';
    }
    if(this.preferences.lastModified){
      this.lastModifiedDisplay = moment(this.preferences.lastModified).format('MMMM D, YYYY');
    }
    if(this.preferences.enrollmentDate){
      this.enrollmentDateDisplay = moment(this.preferences.enrollmentDate).format('MMMM D, YYYY');
    }
  }

  getContacts() {
    this.dataService.setLoading(true);
    this.fourcpService.get4cpContacts(this.myAccount.id).subscribe((resp : any) => {
      this.contacts = resp.contacts;
      this.filteredContacts = this.contacts;
      this.getUsers();
      this.paginate(1);
    });
  }

  filterContacts() {
    if(!this.searchTerm){
      this.filteredContacts = this.contacts;
    }
    this.filteredContacts = this.genericPipe.transform(this.contacts, this.searchTerm);
    this.paginate(1);
  }

  paginate(page: number){
    if((this.pager && page > 0 && page <= this.pager.totalPages) || !this.pager){
      this.filteredContacts = this.orderByPipe.transform(this.filteredContacts, this.sortBy, this.ascending);
      this.pager = this.pagerService.getPage(this.filteredContacts, page);
    }
  }

  sortContacts(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }

  getDateDisplay(date: string) {
    return moment(date).format('MM/DD/YY');
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterContacts();
  }

  saveContact(contact: I4cpContact) {
    let req = {
      accountId: this.myAccount.id,
      contacts: [contact]
    };
    this.fourcpService.saveContact(req).subscribe((resp) => {
      this.getContacts();
    });
  }

  toggleAddContacts() {
    this.addingContacts = !this.addingContacts;
    this.showValidationError = false;
    if(this.addingContacts){
      this.newContactsToAdd = [];
      this.getUsers();
    } else {
      this.getContacts();
    }
  }

  addNewContact() {
    let newContact = {
      user: '',
    };
    this.newContactsToAdd.push({} as any);
  }

  deleteNewContact(index: number){
    this.newContactsToAdd.splice(index,1);
  }

  toggleDelete(alert: I4cpContact){
    this.contactToDelete = alert;
  }

  deleteContact(alert: I4cpContact){
    alert.activeFlag = "F";
    this.saveContact(alert);
  }

  openManageUsers() {
    this.dataService.setSelectedUserAccountSource(this.myAccount);
  }

  getUsers() {
    this.manageProfileService.getUserPermissions(this.myAccount.id).subscribe((users : any) => {
      this.existingUsers = [];
      this.allUsers = users;
      let userIsContact = false;
      users.forEach((user) => {
        userIsContact = false;
        this.contacts.forEach((contact) => {
          if(user.userProfile.email == contact.alertEmail) {
            userIsContact = true;
          }
        });
        if(!userIsContact) {
          this.existingUsers.push(user);
        }
      });
      this.dataService.setLoading(false);
    });
  }

  openPhoneModal(user: any, text: boolean){
    if(this.addingContacts) {
      this.tempUser = Object.assign({}, user);
      this.editingTextPhone = text;
      if(text) {
        this.tempUser.phoneSelection = user.textPhoneSelection;
        this.tempUser.alertPhone = user.textAlertPhone;
      } else {
        this.tempUser.phoneSelection = user.voicePhoneSelection;
        this.tempUser.alertPhone = user.voiceAlertPhone;
      }
      if(!this.tempUser.phoneSelection) {
        this.tempUser.phoneSelection = 'NONE';
        this.tempUser.alertPhone = '';
        this.tempUser.voiceAlertExt = '';
      }
    } else {
      this.tempContact = Object.assign({}, user);
      this.editingTextPhone = text;
      this.allUsers.forEach((user) => {
        if(user.userProfile.email == this.tempContact.alertEmail){
          this.tempContact.userProfile = Object.assign({}, user.userProfile);
        }
      });
      if(text) {
        this.tempContact.phoneSelection = user.textPhoneSelection;
        this.tempContact.alertPhone = user.textAlertPhone;
      } else {
        this.tempContact.phoneSelection = user.voicePhoneSelection;
        this.tempContact.alertPhone = user.voiceAlertPhone;
      }
      if(!this.tempContact.phoneSelection){
        this.tempContact.phoneSelection = 'NONE';
        this.tempContact.alertPhone = '';
        this.tempContact.voiceAlertExt = '';
      }
    }
  }

  applyPhone() {
    this.existingUsers.forEach((user) => {
      if (user.userProfile.email == this.tempUser.userProfile.email) {
        if (this.tempUser.alertPhone && this.tempUser.alertPhone.length > 10) {
          this.tempUser.alertPhone = this.tempUser.alertPhone.substr(1, 3) + this.tempUser.alertPhone.substr(6, 3) + this.tempUser.alertPhone.substr(10, 4);
        }
        if(this.tempUser.phoneSelection == 'NONE') {
          this.tempUser.alertPhone = '';
          this.tempUser.voiceAlertExt = '';
        }
        if (this.editingTextPhone) {
          user.textPhoneSelection = this.tempUser.phoneSelection;
          user.textAlertPhone = this.tempUser.alertPhone;
        } else {
          user.voicePhoneSelection = this.tempUser.phoneSelection;
          user.voiceAlertPhone = this.tempUser.alertPhone;
          user.voiceAlertExt = this.tempUser.voiceAlertExt;
        }
      }
    })
  }

  applyPhoneContact() {
    this.contacts.forEach((contact) => {
      if (contact.alertEmail == this.tempContact.alertEmail) {
        if (this.tempContact.alertPhone.length > 10) {
          this.tempContact.alertPhone = this.tempContact.alertPhone.substr(1, 3) + this.tempContact.alertPhone.substr(6, 3) + this.tempContact.alertPhone.substr(10, 4);
        }
        if(this.tempContact.phoneSelection == 'NONE') {
          this.tempContact.alertPhone = '';
          this.tempContact.voiceAlertExt = '';
        }
        if (this.editingTextPhone) {
          contact.textPhoneSelection = this.tempContact.phoneSelection;
          contact.textAlertPhone = this.tempContact.alertPhone;
        } else {
          contact.voicePhoneSelection = this.tempContact.phoneSelection;
          contact.voiceAlertPhone = this.tempContact.alertPhone;
          contact.voiceAlertExt = this.tempContact.voiceAlertExt;
        }
        this.saveContact(contact);
      }
    });
  }

  getPhoneNumberFormatted(phoneNumStr: string){
    if(phoneNumStr && phoneNumStr.length == 10) {
      phoneNumStr = '(' + phoneNumStr.substr(0, 3) + ') ' + phoneNumStr.substr(3, 3) + '-' + phoneNumStr.substr(6, 4);
    }
    return phoneNumStr;
  }

  updatePhoneSelection() {
    if(this.addingContacts){
      this.existingUsers.forEach((user) => {
        if (user.userProfile.email == this.tempUser.userProfile.email) {
          if (this.tempUser.phoneSelection == 'MOBILE') {
            this.tempUser.alertPhone = user.userProfile.mobilePhone;
          } else if (this.tempUser.phoneSelection == 'WORK') {
            this.tempUser.alertPhone = user.userProfile.phone;
          } else {
            this.tempUser.alertPhone = null;
          }
        }
      });
    } else {
      this.allUsers.forEach((user) => {
        if (user.userProfile.email == this.tempContact.alertEmail) {
          if (this.tempContact.phoneSelection == 'MOBILE') {
            this.tempContact.alertPhone = user.userProfile.mobilePhone;
          } else if (this.tempContact.phoneSelection == 'WORK') {
            this.tempContact.alertPhone = user.userProfile.phone;
          } else {
            this.tempContact.alertPhone = null;
          }
        }
      });
    }
  }

  addContacts() {
    this.showValidationError = false;
    if(this.validate()) {
      let contactsToSave = [], newContact: I4cpContact = {} as any;
      this.newContactsToAdd.forEach((contact) => {
        contact.activeFlag = 'T';
        if(contact.textAlertPhone){
          contact.textAlertPhone = contact.textAlertPhone.substr(1, 3) + contact.textAlertPhone.substr(6, 3) + contact.textAlertPhone.substr(10, 4);
        }
        if(contact.voiceAlertPhone){
          contact.voiceAlertPhone = contact.voiceAlertPhone.substr(1, 3) + contact.voiceAlertPhone.substr(6, 3) + contact.voiceAlertPhone.substr(10, 4);
        }
        contactsToSave.push(contact);
      });
      this.existingUsersToAdd.forEach((existingUser) => {
        if(existingUser.checked){
          newContact = {
            user: existingUser.userProfile.firstName + ' ' + existingUser.userProfile.lastName,
            alertEmail: existingUser.userProfile.email,
            textAlertPhone: existingUser.textAlertPhone,
            voiceAlertPhone: existingUser.voiceAlertPhone,
            activeFlag: 'T'
          };
          if(existingUser.textPhoneSelection == 'MOBILE' || existingUser.textPhoneSelection == 'WORK'){
            newContact.textAlertSync = existingUser.textPhoneSelection;
          }
          if(existingUser.voicePhoneSelection == 'MOBILE' || existingUser.voicePhoneSelection == 'WORK'){
            newContact.voiceAlertSync = existingUser.voicePhoneSelection;
          }
          contactsToSave.push(newContact);
        }
      });

      let req = {
        accountId: this.myAccount.id,
        contacts: contactsToSave
      };
      this.dataService.setLoading(true);
      this.fourcpService.saveContact(req).subscribe((resp) => {
        this.newContactsToAdd = [];
        this.existingUsersToAdd = [];
        this.toggleAddContacts();
        this.dataService.setLoading(false);
      }, err => {
        this.dataService.setLoading(false);
      });
    }
  }

  editContactEmail(contact) {
    contact.editingEmail = true;

  }

  validate() {
    this.errorMessage = null;
    let newContactsValid = true;
    this.newContactsToAdd.forEach((newContact) => {
      newContact.validationError = false;
      newContact.emailError = false;
      if(!newContact.user || !(newContact.alertEmail || newContact.textAlertPhone || newContact.voiceAlertPhone)) {
        newContactsValid = false;
        newContact.validationError = true;
        this.showValidationError = true;
        this.errorMessage = 'Please ensure all required fields are complete.';
      }
      this.existingUsers.forEach((user) => {
        if(newContact.alertEmail.toLowerCase() == user.userProfile.email.toLowerCase()) {
          newContact.emailError = true;
          this.errorMessage = 'An online user already uses this email. Please select the user from the table below.';
        }
      });
      this.contacts.forEach((contact) => {
        if(newContact.alertEmail.toLowerCase() == contact.alertEmail.toLowerCase()){
          newContact.emailError = true;
          this.errorMessage = 'An alert with this email already exists, please go the preferences view to edit the options for this alert.';
        }
      })
    });
    return this.errorMessage == null;
  }

  contactChecked() {
    this.existingUsersToAdd = [];
    this.existingUsers.forEach((user) => {
      if(user.checked) {
        this.existingUsersToAdd.push(user);
      }
    })
  }

  cancel() {
    if(this.existingUsersToAdd.length > 0 || this.newContactsToAdd.length > 0){
      $('#cancelModal').modal('show');
    } else {
      this.toggleAddContacts();
    }
  }

  getTitleCase(str){
    if(str){
      str = str.toLowerCase().split(' ').map((w) => {
        return (w.charAt(0).toUpperCase() + w.slice(1));
      });
      return str.join(' ');
    }
  }

}
