import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { IRenewableSite } from '../../renewable-site';
import { RenewablesComponent } from '../../renewables.component';
import { RenewablesService } from '../../renewables.service';
import { PortalService } from '../../../shared/portal.service';
import { Authority } from '../../../shared/const/authority';
import * as moment from 'moment';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss']
})
export class SummaryTableComponent implements OnInit, OnDestroy {
  sites: IRenewableSite[];
  internalView = true;
  showInternal: Boolean;
  maskModeSubscription: Subscription;
  maskMode = false;
  private readonly unsubscribe$ = new Subject();

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(
    private datePipe: DatePipe,
    private renewablesService: RenewablesService,
    private dataService: DataService,
    private portalService: PortalService,
    @Inject(RenewablesComponent) private parent: RenewablesComponent
  ) { }

  ngAfterViewInit(){
    this.spinner.show('Loading Equivalencies...');
  }

  ngOnInit() {
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.renewablesService.sites
      .takeUntil(this.unsubscribe$)
      .subscribe(sites => {
        this.sites = sites;
        this.showInternal = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
        this.setAlertNote(this.showInternal.valueOf());
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error('Failed to load sites', error);
      });

  }

  ngOnDestroy() {
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openMaintenanceModal(site: IRenewableSite) {
    this.parent.maintenanceModal.openSite(site, this.showInternal.valueOf(), this.maskMode);
  }

  downloadFactSheet(siteId, spinner) {
    this.spinner.show('Downloading Fact Sheet...');
    this.renewablesService.downloadFactSheetPdf(siteId, this.spinner);
  }

  setAlertNote(internalView: boolean) {
    this.sites.forEach(site => {
        if (site.maintenanceNotes != null && site.maintenanceNotes.length > 0) {
          const latestMaintNotes = site.maintenanceNotes[0];
          if ((internalView || (!internalView && latestMaintNotes.showNote))
               &&  (latestMaintNotes.note != null &&  latestMaintNotes.note !== '')) {
            latestMaintNotes.dateCal = moment(latestMaintNotes.date.substr(0, latestMaintNotes.date.indexOf(' '))).toDate();
            site.noteAlert = this.datePipe.transform(latestMaintNotes.dateCal, 'MM/dd/yyyy') + ' : ' + latestMaintNotes.note;
          } else {
            site.noteAlert = null;
          }
        } else {
          site.noteAlert = null;
        }
    });
    //this.showInternal = internalView;
  }

  getServiceAddress(site: IRenewableSite) {
    if (this.maskMode) {
      return this.portalService.getFakeStreetAddress(site);
    } else {
      return site.serviceAddress;
    }
  }

  getSiteName(site: IRenewableSite) {
    if (this.maskMode) {
      return this.portalService.getFakeSiteName(site);
    } else {
      return site.name;
    }
  }

  get selectedSites(): IRenewableSite[] {
    if (this.sites) {
      return this.sites.filter(site => site.selected);
    }
    return [];
  }
}
