import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string, words: boolean, units: boolean): string{
  	let unitString: string = '';

    if(value) {
     
      if (units) {
      	if(value.indexOf('(') !== -1) {
      		unitString = value.substring(value.indexOf('('), (value.indexOf(')') + 1));
	      	value = value.substring(0, value.indexOf('('));
      	}
      }

      value = value.toLowerCase();

      if (words) {
        value = value.replace(/\b\w/g, first => first.toLocaleUpperCase());
        value = unitString.length > 1 ? value + unitString : value;
      } else {
        value = value.charAt(0).toUpperCase() + value.slice(1);
        value = unitString.length > 1 ? value + unitString : value;
      }

      return value;
      
    }
  }
}
