import { Component, OnInit } from '@angular/core';
import { DataService } from "../../shared/data.service";
import { Subscription } from "rxjs";
import { FadeAnimation } from "../../animations/fadeAnimation";
import { HttpHeaders } from "@angular/common/http";
import { OrderByPipe } from "../../shared/pipe/orderBy.pipe";
import { GenericPipe } from "../../shared/pipe/generic.pipe";
import { ForwardPriceForm } from '../forward-price-form/forward-price.form';
import { PortalService } from '../../shared/portal.service';
import { Authority } from '../../shared/const/authority';

@Component({
  selector: 'app-forward-pricing-menu',
  templateUrl: './forward-pricing-menu.component.html',
  animations: [FadeAnimation],
  styleUrls: [
    '../../shared/styles/reports.scss',
    './forward-pricing-menu.component.scss',
  ],
  providers: [OrderByPipe, GenericPipe]
})

export class ForwardPricingMenuComponent implements OnInit {
  headers: HttpHeaders;
  selectedReport: any = false;
  pager: any;

  constructor(
    private dataService: DataService,
    private portalService: PortalService) { }
  selectedTab: string = this.dataService.getSelectedTabForMarketsReports() || '';
  isSuperUser: boolean = false;
  scsCustomer : boolean = false;
  tabSubscribe: Subscription;

  ngOnInit() {
    this.setCurrentActivePage('accounts');
    this.dataService.setTitleSource('Term Analysis');
    this.dataService.setContextualHelp(false);
    this.dataService.setSelectedNavItem('markets');
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.tabSubscribe = this.dataService.selectedMarketReportUpdated.subscribe((tab) => {
      this.selectedTab = tab;
      if (!tab.includes("OUTPUT")) {
        this.selectedReport = false;
      }
    });
   // this.portalService.getScsFlagForAccount(this.dataService.getAccountSource().id).subscribe((resp=>{
      // console.log(resp);
       if(!this.dataService.getScsCustomer()){
          //set header access values to f, unsure if there are any y's at all in dal dev
          this.scsCustomer = false;
        }
        else{
          this.scsCustomer = true;
        }
      

    //}));
  }

  ngOnDestroy() {
    if (this.tabSubscribe) this.tabSubscribe.unsubscribe();
    this.dataService.setContextualHelp(true);
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  switchTabs(selectedTab: string) {
    this.dataService.setSelectedTabForMarketsReports(selectedTab);
    this.selectedTab = selectedTab;
    // Double bang !! is to convert object to boolean
    if (!!this.selectedReport) {
      this.selectedReport = false;
    }
  }

  /**
   * Check data.service.ts for global application event handling.
   * 
   * @param product 
   */
  viewReport(product: ForwardPriceForm) {
    this.selectedReport = product;
    if (this.selectedTab == "RENEW") {
      this.dataService.setSelectedTabForMarketsReports("RENEW_OUTPUT");
    }
    if (this.selectedTab == "CUSTOM") {
      this.dataService.setSelectedTabForMarketsReports("CUSTOM_OUTPUT");
    }
    if (this.selectedTab == "REPORTS") {
      this.dataService.setSelectedTabForMarketsReports(product.custom ? "CUSTOM_OUTPUT" : "RENEW_OUTPUT");
    }
  }

  isRenewal() {
    return this.selectedTab.includes("RENEW") && (this.isSuperUser || this.scsCustomer);
  }

  isCustom() {
    return this.selectedTab.includes("CUSTOM") && (this.isSuperUser || this.scsCustomer);
  }

  returnToInput(bool: boolean) {
    this.selectedReport = bool;
    this.dataService.setSelectedTabForMarketsReports(this.selectedTab.slice(0, this.selectedTab.indexOf('_')));
  }
}
