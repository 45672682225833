import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { ICategory, IDownloadInvoiceRequest, IBillingRptName, IBillingReportResponse, IDownloadMultipleInvoicesRequest,IDownloadMultipleInvoicesZipRequest
} from '../shared/entities/bill-reports';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {DataService} from "../shared/data.service";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {ResponseContentType, Http, Headers, Response} from "@angular/http";
import {IError} from "../shared/entities/error";
import {ISite,ISiteCountRequest} from "../shared/entities/site";
import { IBillingReport, IBillingReportRequest } from '../shared/entities/reportSettings';

@Injectable()
export class BillingSummaryService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient, private oldHttp: Http,
              private dataService: DataService) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }


  generateBillingReportData(request: IBillingReportRequest): Observable<any> {
    let url;
    if(this.dataService.getAccountSource().largeCustomer){
      if(request.searchTerm) {
        url = environment.getBillingReportSearchViewUrl;
      } else {
        url = environment.getBillingReportSummaryViewUrl;
      }
    } else {
      url = environment.generateBillingReportUrl;
    }
    url = url.replace('{accountId}', request.accountId.toString());
    if(environment.production){
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IBillingReportResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: ICategory[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  generateTpBillingReportData(request: IBillingReportRequest): Observable<any> {
    let url;
    if(this.dataService.getAccountSource().largeCustomer){
      if(request.searchTerm) {
        url = environment.getBillingReportSearchViewUrl;
      } else {
        url = environment.getBillingReportSummaryViewUrl;
      }
    } else {
      url = environment.generateTpBillingReportUrl;
    }
    url = url.replace('{accountId}', request.accountId.toString());
    if(environment.production){
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IBillingReportResponse)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: ICategory)=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  getBillingReportDetailView(request: IBillingReportRequest): Observable<any[]> {
    let url = environment.getBillingReportDetailViewUrl;
    url = url.replace('{accountId}', request.accountId.toString());
    if(environment.production){
      return this.http
      .post(url,request, {headers : this.headers}).pipe(
        map((data: IBillingReportResponse[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    } else {
      return this.http
      .get(url).pipe(
        map((data: IBillingReportResponse[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }
  }

  getBillingReports(accountId: string,reportType: string): Observable<IBillingReport[]>{
    let url = environment.getReportSettingsUrl.replace('{accountId}', accountId.toString()).replace('{reportType}',reportType.toString());
    return this.http
    .get(url).pipe(
      map((data: any[]) => {
        return this.mapItems<IBillingReport>(data);
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  createBillingReport(setting: IBillingReport): Observable<IBillingReport> {
    let url = environment.createOrUpdateReportSettingsUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', '');
    return this.http
        .post(url,setting, {headers : this.headers}).pipe(
          map((data: IBillingReport)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  updateBillingReport(setting: IBillingReport): Observable<IBillingReport> {
    let url = environment.createOrUpdateReportSettingsUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', setting.reportId.toString());
    return this.http
        .post(url,setting, {headers : this.headers}).pipe(
          map((data: IBillingReport)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  deleteBillingReport(accountId: string, reportId: number, createdUserId: string, reportType:string): Observable<any>{
    let url = environment.deleteReportSettingsUrl.replace('{accountId}', accountId.toString()).replace('{reportId}', reportId.toString()).replace('{reportType}',reportType.toString());
      return this.oldHttp
        .post(url,"")
        .map((response: Response) => <any>response)
        .catch(this.handleError);
 }

  getTpBillingReports(accountId: string): Observable<IBillingReport[]>{
    let url = environment.getTpBillingSummaryReportsUrl.replace('{accountId}', accountId.toString());
    return this.http
      .get(url).pipe(
        map((data: IBillingReport[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
  }

  createTpBillingReport(setting: IBillingReport): Observable<IBillingReport> {
    let url = environment.createOrUpdateTpBillingReportUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', '');
    return this.http
        .post(url,setting, {headers : this.headers}).pipe(
          map((data: IBillingReport)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
  }

  updateTpBillingReport(setting: IBillingReport): Observable<IBillingReport> {
    let url = environment.createOrUpdateTpBillingReportUrl.replace('{accountId}', setting.accountId.toString()).replace('{reportId}', setting.reportId.toString());
    return this.http
    .post(url,setting, {headers : this.headers}).pipe(
      map((data: IBillingReport)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  deleteTpBillingReport(accountId: string, reportId: number): Observable<any>{
      let url = environment.deleteTpBillingReportUrl.replace('{accountId}', accountId.toString()).replace('{reportId}', reportId.toString());
      return this.oldHttp
        .post(url,"")
        .map((response: Response) => <any>response)
        .catch(this.handleError);
 }


  downloadInvoice(request: IDownloadInvoiceRequest): any {
    let url = environment.downloadInvoiceUrl.replace('{accountId}', request.accountId.toString()).replace('{invoice}', request.invoiceNo.toString());
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
          .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
          .subscribe(
            (res) => {
              let maskMode = this.dataService.getMaskMode();
              let contentDisposition = res.headers.get('content-disposition');
              let filename;
              if(!maskMode){
                filename = contentDisposition.match(/filename=(.+)/)[1];
              } else {
                filename = '9999999999990.pdf';
              }
              if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
                window.navigator.msSaveBlob(res.blob(), filename);
              } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                let blob = new Blob([res.blob()], {type: "application/pdf"});
                window.open(window.URL.createObjectURL(blob));
              } else {
                let link=document.createElement('a');
                link.href=window.URL.createObjectURL(res.blob());
                link.download = filename;
                link.click();
              }
              this.dataService.setLoading(false);
            }
          );
  }

  openMobileInvoice(request: IDownloadInvoiceRequest): any {
    let url = environment.downloadInvoiceUrl.replace('{accountId}', request.accountId.toString()).replace('{invoice}', request.invoiceNo.toString());
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let blob = new Blob([res.blob()], {type: 'application/pdf'});
          let newWin = window.open(window.URL.createObjectURL(blob));
          if(!newWin || newWin.closed || typeof newWin.closed=='undefined') {
            // Popup was blocked
            let error: IError = {
              title: 'Please enable pop-ups',
              message: 'To view invoices on your phone, please enable pop-ups in your phone settings.'
            };
            this.dataService.addError(error)
          }
          this.dataService.setLoading(false);
        }
      );
  }



siteCount(req: ISiteCountRequest): Observable<ISite[]> {
  let url = environment.production ? environment.getSitesByCategoryUrl.replace('{accountId}', req.accountId.toString()) : environment.getSitesByCategoryUrl;

    url = url.replace('{category}', req.category);
    if(req.groupId) {
      url = url + '&groupId=' + req.groupId.toString();
    } else if(req.systemGroupName) {
      url = url + '&systemGroupName=' + req.systemGroupName;
    }
    // console.log('req is ', req);
    // console.log('making call');
    return this.http
      .get(url).pipe(
        map((data: ISite[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
}



  downloadMultipleInvoices(request: IDownloadMultipleInvoicesRequest): Observable<HttpResponse<Blob>> {
    let url = environment.downloadMultipleInvoicesUrl.replace('{accountId}', request.accountId.toString());
    return this.http
      .post(url, request, {headers: this.headers, observe: 'response', responseType: "blob"})
      .catch(this.handleError);
  }

  downloadMultipleInvoicesAsync(request: IDownloadMultipleInvoicesRequest): Observable<HttpResponse<Blob>> {
    let url = environment.downloadMultipleInvoicesAsyncUrl.replace('{accountId}', request.accountId.toString());
    return this.http
      .post(url, request, {headers: this.headers, observe: 'response', responseType: "blob"})
      .catch(this.handleError);
  }

  downloadMultipleInvoiceZip(request: IDownloadMultipleInvoicesZipRequest) {
    let url = environment.multipleInvoiceDownloadUrl.replace('{accountId}',request.accountId).replace('{fileName}',request.fileName);
    return this.http
      .post(url, request, {headers: this.headers, observe: 'response', responseType: "blob"})
      .catch(this.handleError);
  }

  exportBillingReport(setting: IBillingReport): any {
    let url = environment.production ? environment.excelBillingReportUrl.replace('{accountId}', setting.accountId.toString()) : environment.excelBillingReportUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, setting, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let filename= setting.reportName + '.xlsx';
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }, err => {
          this.dataService.setLoading(false);
        }
      );
  }

  exportTpBillingReport(setting: IBillingReport): any {
    let url = environment.production ? environment.excelTpBillingReportUrl.replace('{accountId}', setting.accountId.toString()) : environment.excelTpBillingReportUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.oldHttp
      .post(url, setting, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let filename= setting.reportName + '.xlsx';
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }

  private mapItems<T>(data: any[]): T[] {
    return data.map(item => {
      const mappedItem: T = {} as T;
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          mappedItem[key] = item[key];
        }
      }
      return mappedItem;
    });
  }
};
