import { IPagerDenom } from "./const/pagerDenoms";

export enum RulerFactoryOption {
  Start = "START",
  End = "END",
  Default = "DEFAULT",
}

export interface Pager {
  items: any[];
  totalPages: number; // total number of pages
  firstItem: number; // index of the first item + 1
  lastItem: number; // index of the last item + 1
  currentPage: number; // default 1
  pageArray: number[]; // array of all pages numbers
  pagerDenoms?: IPagerDenom[];
  selectedDenom?: IPagerDenom;
  pager?: any;
  searchTerm?: string;
}

export class PagerService {
  getPage(totalItems: any[], currentPage: number = 1, pageSize: number = 10) {
    let totalPages = Math.ceil(totalItems.length / pageSize);
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems.length - 1);
    let pageArray = [];
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push(i);
    }
    if (totalPages == 0) {
      totalPages = 1;
    }
    let pager = {
      items: totalItems.slice(startIndex, endIndex + 1), //
      totalPages: totalPages, // total number of pages
      firstItem: startIndex + 1, // index of the first item + 1
      lastItem: endIndex + 1, // index of the last item + 1
      currentPage: currentPage, // 1
      pageArray: pageArray, // [1, 2, 3, 4]
    };
    // source credits: https://itnext.io/simple-intelligible-numbered-pagination-in-angular-66dbed73e2d7
    this.getPageArray(pager);
    return pager;
  }

  getSingleItemPage(
    totalItems: any,
    currentPage: number = 1,
    pageSize: number = 10
  ) {
    //let totalPages = Math.ceil(totalItems.length / pageSize);
    //let startIndex = (currentPage - 1) * pageSize;
    //let endIndex = Math.min(startIndex + pageSize - 1, totalItems.length - 1);
    let pageArray = [];
    pageArray.push(1);

    return {
      items: totalItems, //
      totalPages: 1, // total number of pages
      firstItem: 1, // index of the first item + 1
      lastItem: 1, // index of the last item + 1
      currentPage: currentPage, // 1
      pageArray: pageArray, // page numbers displayed at a time
    };
  }

  getPageArray(pager) {
    let rulerLength = 5; // number of page numbers to be displayed in the ruler (page array)
    if (pager.totalPages < 5) {
      rulerLength = pager.totalPages;
    }
    pager.pageArray = this.ruler(
      pager.currentPage,
      pager.totalPages,
      rulerLength
    );
  }

  ruler(currentIndex: number, maxPages: number, rulerLength: number): number[] {
    const array = new Array(rulerLength).fill(null);
    const min = Math.floor(rulerLength / 2);
    return array.map((_, index) =>
      this.rulerFactory(currentIndex, index, min, maxPages, rulerLength)
    );
  }

  rulerFactory(
    currentIndex: number,
    index: number,
    min: number,
    maxPages: number,
    rulerLength: number
  ): number {
    const factory = {
      [RulerFactoryOption.Start]: () => index + 1,
      [RulerFactoryOption.End]: () => maxPages - rulerLength + index + 1,
      [RulerFactoryOption.Default]: () => currentIndex + index - min,
    };

    return factory[this.rulerOption(currentIndex, min, maxPages)]();
  }

  rulerOption(
    currentIndex: number,
    min: number,
    maxPages: number
  ): RulerFactoryOption {
    return currentIndex <= min
      ? RulerFactoryOption.Start
      : currentIndex >= maxPages - min
      ? RulerFactoryOption.End
      : RulerFactoryOption.Default;
  }
}
