import {PipeTransform, Pipe} from '@angular/core';
import {IBalances} from "../../payments/payments";
import * as moment from 'moment';
import { UtilsService } from '../utils.service';

@Pipe({
  name: 'balancesPipe'
})
export class BalancesPipe implements PipeTransform {

  constructor(public utilService: UtilsService) {}

  transform(balances: IBalances, term: string): IBalances {
    const filteredBalances: IBalances = this.utilService.clone(balances);
    filteredBalances.categories = [];
    term = term.toLowerCase().trim();
    let termMoment = moment(term);
    balances.categories.forEach((category) => {
      if(category.name && category.name.toLowerCase().match(term)){
        filteredBalances.categories.push(category);
      } else {
        let filteredSites = [], sitePushed = false;
        category.sites.forEach((site) => {
          sitePushed = false;
          for(let prop in site){
            if(site[prop]){
              if(prop === "serviceAddress" || prop ===  "billingAddress"){
                let addr = (site[prop].address1 + " " + site[prop].address2 + " " + site[prop].attentionName + " " + site[prop].attn + " " + site[prop].streetAddress + " " + site[prop].streetAddress2 + " " + site[prop].city + " " + site[prop].state + " " + site[prop].zipCode).toLowerCase();
                if(addr.match(term)) {
                  filteredSites.push(site);
                  sitePushed = true;
                  break;
                }
              }else if(site[prop].toString().toLowerCase().match(term)){
                filteredSites.push(site);
                sitePushed = true;
                break;
              } else if (!site[prop].toString().match(/[a-z]/i) && prop.toLowerCase().match('date') && moment(site[prop]).format('MM/DD') == termMoment.format('MM/DD')){
                // console.log(moment(site[prop]).format('MM/DD'), site[prop]);
                filteredSites.push(site);
                sitePushed = true;
                break;
              }
            }
          }
          if(!sitePushed){
            let filteredInvoices = [];
            site.invoices.forEach((invoice) => {
              for (let iProp in invoice) {
                if(invoice[iProp]){
                  if(invoice[iProp].toString().toLowerCase().match(term)){
                    filteredInvoices.push(invoice);
                    break;
                  } else if (!invoice[iProp].toString().match(/[a-z]/i) && iProp.toLowerCase().match('date') && moment(invoice[iProp]).format('MM/DD') == termMoment.format('MM/DD')){
                    // console.log(moment(invoice[iProp]).format('MM/DD'), invoice[iProp]);
                    filteredInvoices.push(invoice);
                    break;
                  }
                }
              }
            });
            if(filteredInvoices.length > 0){
              let filteredSite = Object.assign({}, site);
              filteredSite.invoices = filteredInvoices;
              filteredSites.push(filteredSite);
            }
          }
        });
        if(filteredSites.length > 0) {
          let filteredCategory = Object.assign({}, category);
          filteredCategory.sites = filteredSites;
          filteredBalances.categories.push(filteredCategory);
        }
      }
    });
    return filteredBalances;
  }

  getDateDisplay(dateString: string) {
    if(dateString){
      dateString = dateString.includes("T", 0) ? dateString : dateString + "T00:00:00";
      let date = new Date(dateString);
      return date.toLocaleDateString();
    }
  }
}
