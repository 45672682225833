import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
  name: 'singleFieldSearchPipe'
})
export class SingleFieldSearchPipe implements PipeTransform {
  transform(items: any[], term: string, prop: string): any[]{
    term = term.toLowerCase().trim();
    let filteredItems = [];
    items.forEach((item) => {
      let found = false;
      if(item[prop] && item[prop].toString().toLowerCase().match(term) && !found){
        filteredItems.push(item);
        found = true;
      }
    });
    return filteredItems;
  }
}
