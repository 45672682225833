import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import {ResponseContentType, Headers, Http} from '@angular/http';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { IGenerationDetailExport } from './generation-detail-export-modal/generation-detail-export';
import { IMaintenanceNote } from './maintenance-note';
import { IRenewableSite } from './renewable-site';
import { IRenewableSiteGeneration, ISiteGenerationRequest } from './renewable-site-generation';
import { IRenewableCertificate } from './renewables-certificates/renewable-certificate';
import { IEquivalency } from './equvalency';
import { environment } from '../../environments/environment';
import { DataService } from '../shared/data.service';


@Injectable()
export class RenewablesService {
  private sites$: ReplaySubject<IRenewableSite[]>;
  private siteGeneration$: ReplaySubject<IRenewableSiteGeneration>;
  private readonly equivalencies$ = new ReplaySubject<IEquivalency[]>(1);
  private readonly headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  
  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private oldHttp: Http
  ) {
    this.resetData();
    
  }

  get sites(): Observable<IRenewableSite[]> {
    return this.sites$.asObservable();
  }

  get siteGeneration(): Observable<IRenewableSiteGeneration> {
    return this.siteGeneration$.asObservable();
  }

  get equivalencies(): Observable<IEquivalency[]> {
    return this.equivalencies$.asObservable();
  }

  updateSites(sites: IRenewableSite[]) {
    this.sites$.next(sites);
  }

  updateSiteGeneration(siteGeneration: IRenewableSiteGeneration) {
    this.siteGeneration$.next(siteGeneration);
  }

  updateEquivalencies(equivalencies: IEquivalency[]) {
    this.equivalencies$.next(equivalencies);
  }

  resetData() {
    this.sites$ = new ReplaySubject<IRenewableSite[]>(1);
    this.siteGeneration$ = new ReplaySubject<IRenewableSiteGeneration>(1);
  }

 compareDate(date1: Date, date2: Date): number
 {
  let d1 = new Date(date1); let d2 = new Date(date2);
  let same = d1.getTime() === d2.getTime();
  if (same) return 0;
  if (d1 > d2) return 1;
  if (d1 < d2) return -1;
}

  getSites(): Observable<IRenewableSite[]> {
    if (!this.accountId) {
      return of();
    }
    this.dataService.showGenerationDetailExport.emit(false);
    //console.log("getSites() >>> "  );
    const url = environment.getSitesUrl.replace('{accountId}', this.accountId);
    return this.http
      .get<IRenewableSite[]>(url)
      .map(sites => {
        sites.forEach(site => site.selected = true);
        if(sites){
         let today = new Date();
          sites.forEach(site => {
            if(site.generationStartDate) {
              //console.log("sites >>> " + site.name + " : " + site.generationStartDate + " : " + today);
              //console.log(site.generationStartDate < today);
              let x = this.compareDate(site.generationStartDate,today);
              if(x===0 || x===-1) {
              //console.log("Export =  true")
                this.dataService.showGenerationDetailExport.emit(true);
              }
            }             
          });
        }
        this.updateSites(sites);
        return sites;
      })
      .catch(this.handleError);
  }

  getSiteGeneration(request: ISiteGenerationRequest, updateLocal = false): Observable<IRenewableSiteGeneration> {
    if (!this.accountId) {
      return of();
    }
    const url = environment.getSiteGenerationUrl.replace('{accountId}', this.accountId);
    return this.http
      .post<IRenewableSiteGeneration>(url, request, { headers: this.headers })
      .map(siteGeneration => {
        if (updateLocal) {
          this.updateSiteGeneration(siteGeneration);
        }
        return siteGeneration;
      })
      .catch(this.handleError);
  }

 
  getCertificates(): Observable<IRenewableCertificate[]> {
    if (!this.accountId) {
      return of();
    }
    const url = environment.getCertificatesUrl.replace('{accountId}', this.accountId);
    return this.http
      .get<IRenewableCertificate[]>(url)
      .catch(this.handleError);
  }


  
  createGenerationDetailExport(request: IGenerationDetailExport): any{
  let url = environment.production ? environment.exportRenewablesUrl.replace('{accountId}', request.accountId.toString()) : environment.exportRenewablesUrl;
  let headers = new Headers({'Content-Type': 'application/json'});
  return this.oldHttp
    .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
    .subscribe(
      (res) => {
        let filename= 'Generation Detail ' + moment().format('MMDDYYYY') + '.xlsx';
        if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
          window.navigator.msSaveBlob(res.blob(), filename);
        } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          window.open(window.URL.createObjectURL(blob));
        } else {
          let link=document.createElement('a');
          link.href=window.URL.createObjectURL(res.blob());
          link.download = filename;
          link.click();
        }
        this.dataService.setLoading(false);
      }
      , error => {
        console.error('Failed to Export', error);
        setTimeout(() => {
          this.dataService.setLoading(false);
         });
        });
  }

downloadFactSheetPdf(siteId, spinner): any {
  let url = environment.downloadFactSheetUrl.replace('{accountId}', this.accountId.toString()).replace('{siteId}', siteId.toString());
  let headers = new Headers({'Content-Type': 'application/json'});
  return this.oldHttp
        .get(url, {headers: headers, responseType: ResponseContentType.Blob})
        .subscribe(
          (res) => {
            let maskMode = this.dataService.getMaskMode();
            let contentDisposition = res.headers.get('content-disposition');
            let filename;
            // if(!maskMode){
              //  filename = contentDisposition.match(/filename=(.+)/)[1];
            // } else {
            //   filename = '9999999999990.pdf';
            // }
            filename = contentDisposition.match(/filename=(.+)/)[1] + '.pdf';
            // filename = contentDisposition + '.pdf';
            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
              window.navigator.msSaveBlob(res.blob(), filename);
            } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              let blob = new Blob([res.blob()], {type: "application/pdf"});
              window.open(window.URL.createObjectURL(blob));
            } else {
              let link=document.createElement('a');
              link.href=window.URL.createObjectURL(res.blob());
              link.download = filename;
              link.click();
            }
            this.dataService.setLoading(false);
            setTimeout(() => {    
              spinner.hide();    
             }, 300);
          }
          , error => {
            console.error('Failed to download Fact Sheet', error);
            spinner.show('Failed to download Fact Sheet');
            setTimeout(() => {    
              spinner.hide();    
             }, 500);
            });
}


downloadCertificates(cri,calyear,res,spinner):any {
  let url = environment.downloadCertificatesUrl.replace('{accountId}', this.accountId.toString()).replace('{criNumber}', cri.toString()).replace('{year}', calyear.toString()).replace('{resolution}',res);
  let headers = new Headers({'Content-Type': 'application/json'});
  return this.oldHttp
        .get(url, {headers: headers, responseType: ResponseContentType.Blob})
        .subscribe(
          (res) => {
            let maskMode = this.dataService.getMaskMode();
            let contentDisposition = res.headers.get('content-disposition');            
            console.log('contentDisp',contentDisposition);
            let filename;
            // if(!maskMode){
               filename = contentDisposition.match(/filename=(.+)/)[1] + '.pdf';
            // } else {
            // filename = contentDisposition + '.pdf';
              // filename = '9999999999990.pdf';
            // }
            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
              window.navigator.msSaveBlob(res.blob(), filename);
            } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              let blob = new Blob([res.blob()], {type: "application/pdf"});
              window.open(window.URL.createObjectURL(blob));
            } else {
              let link=document.createElement('a');
              link.href=window.URL.createObjectURL(res.blob());
              link.download = filename;
              link.click();
            }
            this.dataService.setLoading(false);
            setTimeout(() => {    
              spinner.hide();    
             }, 300);
          }
        , error => {
          console.error('Failed to download the Certificate', error);
          spinner.show('Failed to download the Certificate');
          setTimeout(() => {    
            spinner.hide();    
           }, 500);
          });
}


  getSiteYears(): Observable<any[]> {
    if (!this.accountId) {
      return of();
    }
    const url = environment.getSiteYearsUrl.replace('{accountId}', this.accountId);
    return this.http
      .get<any[]>(url)
      .catch(this.handleError);
  }

  getEquivalencies(): Observable<IEquivalency[]> {
    if (!this.accountId) {
      return of();
    }
    const url = environment.getEquivalenciesUrl.replace('{accountId}', this.accountId);
    return this.http
      .get<IEquivalency[]>(url)
      .map(equivalencies => {
        this.updateEquivalencies(equivalencies);
        return equivalencies;
      })
      .catch(this.handleError);
  }

  upsertMaintenanceNote(maintenanceNote: IMaintenanceNote): Observable<IMaintenanceNote> {
    if (!this.accountId) {
      return of();
    }
    const url = environment.getMaintenanceUrl.replace('{accountId}', this.accountId);
    return this.http
      .post<IMaintenanceNote>(url, maintenanceNote, { headers: this.headers })
      .catch(this.handleError);
  }

  deleteMaintenanceNote(maintenanceNote: IMaintenanceNote): Observable<any> {
    if (!this.accountId) {
      return of();
    }
    const url = environment.deleteMaintenanceUrl.replace('{accountId}', this.accountId).replace('{maintNoteId}', maintenanceNote.id);
    return this.http
      .post<IMaintenanceNote>(url, maintenanceNote, { headers: this.headers })
      .catch(this.handleError);
  }

  
  private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }

  private generateValue(rangeStart: number, rangeEnd: number, dateTime?: moment.Moment): number {
    if (!dateTime || dateTime.isBefore(moment())) {
      return parseFloat((rangeStart + Math.random() * (rangeEnd - rangeStart)).toFixed(2));
    }
    return null;
  }

  private get accountId(): string {
    if (this.dataService.getAccountSource()) {
      return this.dataService.getAccountSource().id;
    }
    console.warn('No account loaded, cannot retrieve account id for renewables service');
    return null;
  }
}
