export interface IBrokerPayout {
  paymentDate: string;
  paymentAmount: number;
  notes: string;
}

export interface BrokerDrop {
  accountName: string;
  siteId: string;
  contractId: string;
  tdsp: string; // TODO replace with enum
  serviceAddress: string;
  contractStartDate: string;
  contractEndDate: string;
  remainingContractMonths: string;
  dropDate: string;
  dropReason: string; // TODO replace with enum
  product: string; // TODO replace with enum
  fake?: any;
}

export interface IBrokerDropRequest {
  accountId: string;
  accountName: string;
  startDate: string;
  endDate: string;
  brand: string;
  tdsp: string;
  maskingMode?: boolean;
}

export const TSDP = {
  CNP: "CNP - CenterPoint Energy",
  CPL: "CPL - AEP Texas Central Company (CPL)",
  LPL: "LPL - Lubbock Power & Light",
  ONC: "ONC - Oncor Electric Delivery",
  SHL: "SHL - Sharyland McAllen",
  SHY: "SHY - Sharyland Utilities",
  TNP: "TNP - Texas-New Mexico Power Company",
  WTU: "WTU - AEP Texas Central Company (WTU)",
};

export const DropReason = {
  ForcedMoveOut: "Forced Move Out",
  Cancelled: "Cancelled",
  PendingEnrollment: "Pending Enrollment",
  ForceMoveOut: "Force Move Out",
  PendingSwitchAway: "Pending Switch Away",
  PendingForceMoveOut: "Pending Force Move Out",
  SwitchedAway: "Switched Away",
  Active: "Active",
  MoveOut: "Move Out",
  PendingMoveOut: "Pending Move Out",
  SwitchAway: "Switch Away",
  PendingEnrollmentReceived8140506: "Pending Enrollment Received 8140506",
  PendingForceMoveOutSwitch: "Pending Force Move Out/Switch",
};

export interface IBrokerCustomerListRequest {
  accountId: string;
  accountName: string;
  groupId: string;
  groupName: string;
  maskingMode?: boolean;
}
