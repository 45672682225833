import {Component, OnInit, Input} from '@angular/core';

import {IUserEmail} from "../../shared/entities/profile"
import {PortalService} from "../../shared/portal.service"
import {ISite} from "../../shared/entities/site";
import {DataService} from "../../shared/data.service";
import {Subscription} from "rxjs";
import {AmendmentsService} from "../../amendments/amendments.service";
import {IAmendmentRequest} from "../../shared/entities/amendments";
declare var $ :any;

@Component({
  selector: 'app-amendments-email-confirmation',
  templateUrl: './amendments-email-confirmation.component.html',
  styleUrls: ['./amendments-email-confirmation.component.scss']
})
export class AmendmentsEmailConfirmationComponent implements OnInit {

  @Input() selectedAmendmentRequest: IAmendmentRequest;
  emailAddresses: IUserEmail[];
  emailError: boolean;

  constructor(private portalService: PortalService,
              private dataService: DataService,
              private amendmentsService: AmendmentsService) { }

  ngOnInit() {
    this.emailAddresses = Array(1).fill({temp:""});
  }

  ngOnDestroy() {

  }

  sendAmendmentConfirmationEmails() {
    // console.log("Sending emails ",this.emailAddresses);
    let profile = this.dataService.getProfile();
    let firstName = profile.firstName;
    let lastName = profile.lastName;
	let account = this.dataService.getAccountSource();
	let customerName = account.name;
    let emailAddressesArray = "";
    this.emailAddresses.forEach((address) => {
      emailAddressesArray+= address.temp+",";
    });

    this.amendmentsService.sendAmendmentConfirmationEmail(emailAddressesArray, firstName, lastName, customerName, this.selectedAmendmentRequest).subscribe(
      () => {
        // console.log("Emails sent Successfully!");
        this.close();
      }, err => {
        // console.log("Error sending emails!");
        this.emailError = true;
        this.close();
      }
    );
  }

  addNewEmail() {
    if(this.emailAddresses.length < 4) {
      let newEmail:IUserEmail = {temp:""};
      this.emailAddresses.push(newEmail);
    }
  }

  saveEmail(index, email) {
    this.emailAddresses[index] = email;
  }

  removeEmail(index) {
    if (index > -1) {
      this.emailAddresses.splice(index, 1);
    }
  }

  close(){
    this.emailError = false;
    $('#amendmentsConfirmationEmailModal').modal('hide');

  }
}
