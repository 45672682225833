import { Component, OnInit } from '@angular/core';
import {I4cpPreferences, I4cpAlertHistory, I4cpMonths} from "../../shared/entities/fourcp";
import {DataService} from "../../shared/data.service";
import {FourcpService} from "../fourcp.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {PortalService} from "../../shared/portal.service";


@Component({
  selector: 'app-fourcp-alert-history',
  templateUrl: './fourcp-alert-history.component.html',
  styleUrls: ['./fourcp-alert-history.component.scss','../fourcp.component.scss'],
  providers: [GenericPipe],
  animations: [FadeAnimation]
})
export class FourcpAlertHistoryComponent implements OnInit {

  preferences: I4cpPreferences;
  showSearchRow: boolean = false;
  searchTerm: string;
  alertHistory: I4cpAlertHistory;
  alertHistoryFullMonth: I4cpAlertHistory;
  filteredAlertHistory: I4cpAlertHistory;
  fourcpMonths: any;

  startDate: string;
  selectedYear: string;
  selectedMonth: any;
  currentYear: string;
  currentMonth: string;
  showYearSelector: boolean;
  showMonthSelector: boolean;
  yearOptions: string[] = [];

  preferencesSubscription: Subscription;

  constructor(private dataService: DataService,
              private fourcpService: FourcpService,
              private portalService: PortalService,
              private genericPipe: GenericPipe) { }

  ngOnInit() {
    this.getStartDate();

    this.preferences = this.dataService.getFourcpPreferences();

    if(this.preferences){
      this.getAlertHistory();
    }

    this.preferencesSubscription = this.dataService.fourcpPreferencesUpdated.subscribe((preferences) => {
      this.preferences = preferences;
      this.getAlertHistory();
    });
  }

  ngOnDestroy() {
    if (this.preferencesSubscription) this.preferencesSubscription.unsubscribe();
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm;
    this.filterAlertHistory();
  }

  selectYear(year: string){
    this.selectedYear = year;
    if(year == this.currentYear){
      this.updateMonthOptions();
      if(this.selectedMonth.value > this.currentMonth) {
        this.selectedMonth = this.fourcpMonths[this.fourcpMonths.length-1];
      }
    }
    else {
      this.fourcpMonths = I4cpMonths;
    }

    this.startDate = this.selectedYear + '-' + this.selectedMonth.value + '-01';
    this.toggleYearSelector();
    this.getAlertHistory();
  }

  selectMonth(month: any){
    this.selectedMonth = month;
    this.startDate = this.selectedYear + '-' + this.selectedMonth.value + '-01';
    this.getAlertHistory();
    this.toggleMonthSelector();
  }

  updateMonthOptions(){
    let monthNumber = parseInt(this.currentMonth);

    if(monthNumber < 6 || monthNumber > 9){
      this.fourcpMonths = I4cpMonths;
    }
    else {
      this.fourcpMonths = [];
      I4cpMonths.forEach((month) => {
        if(parseInt(month.value) <= monthNumber){
          this.fourcpMonths.push(month);
        }
      })
    }
  }

  getStartDate() {
    let todayMoment = moment();

    this.currentYear = todayMoment.format('YYYY');
    this.currentMonth = todayMoment.format('MM');
    let monthNumber = parseInt(this.currentMonth);

    //Generate month options
    this.updateMonthOptions();

    //Select default month and year
    let thisMonth = todayMoment.format('MM');

    if(parseInt(thisMonth) < 6 || (todayMoment.format('D') == '1' || (todayMoment.format('D') == '2' && parseInt(todayMoment.format('H')) < 7))){
      this.selectedYear = moment().subtract(1, 'year').format('YYYY');
      this.selectedMonth = {display:'September', value:'09'};
    }
    else if(parseInt(thisMonth) > 9){
      this.selectedYear = moment().format('YYYY');
      this.selectedMonth = {display:'September', value:'09'};
    } else {
      this.selectedYear = moment().format('YYYY');
      this.fourcpMonths.forEach((month)=> {
        if(month.value == thisMonth){
          this.selectedMonth = month;
        }
      });
    }

    //Generate Year Options
    let historicalStart = moment('2017-01-01');
    while (historicalStart.isBefore(moment().startOf('year'))){
      this.yearOptions.push(historicalStart.format('YYYY'));
      historicalStart.add(1, 'year');
    }
    if(monthNumber > 5){
      this.yearOptions.push(this.currentYear);
    }
    this.startDate = this.selectedYear + '-' + this.selectedMonth.value + '-01';
  }

  toggleYearSelector(){
    this.showYearSelector = !this.showYearSelector;
    if(this.showYearSelector){
      this.showMonthSelector = false;
    }
  }

  toggleMonthSelector(){
    this.showMonthSelector = !this.showMonthSelector;
    if(this.showMonthSelector){
      this.showYearSelector = false;
    }
  }

  getAlertHistory() {
    this.fourcpService.get4cpAlertHistory(this.startDate, this.preferences.subscriptionType).subscribe((response) => {
      this.alertHistory = Object.assign({}, response);
      this.alertHistory.peaks = [];
      response.peaks.forEach((peak) => {
        if(moment(peak.date).month() <= moment(this.startDate).month()) {
          this.alertHistory.peaks.push(peak);
        }
      });
      this.formatAlertHistory();
      this.filterAlertHistory();
      this.getAlertHistoryFullMonth();
    });
  }

  formatAlertHistory(){
    this.alertHistory.peaks.forEach((peak)=>{
      peak.forecastMWFormatted = this.portalService.numberWithCommas(peak.forecastMW);
      peak.actualMWFormatted = this.portalService.numberWithCommas(peak.actualMW);
      peak.dateFormatted = moment(peak.date).format('ddd MM/DD/YY')
    });
  }

  getAlertHistoryFullMonth() {
    let temp = {peaks: []};

    let lastPeak;
    this.alertHistory.peaks.forEach((peak) =>{
      temp.peaks.push(peak);
      lastPeak = peak;
    });

    let peakToAdd = moment(lastPeak.date);
    let endOfPeaks = moment(lastPeak.date).format('D')  ;
    let endOfMonth = moment(lastPeak.date).endOf('month').format('D');
    let daysMissing = parseInt(endOfMonth) - parseInt(endOfPeaks);

    for(let i = 0; i < daysMissing; i++){
      peakToAdd = peakToAdd.add(1, 'day');
      let emptyPeak = {
        date: peakToAdd.format('YYYY-MM-DD'),
        forecastMW: 0,
        forecastAlertType: 'LOW',
        actualMW: 0,
        actualPeakTime: '13:00',
        mtdMaxLoad: false,
        yourAlert: '13:00 - 17:00'
      };
      temp.peaks.push(emptyPeak);
    }
    this.alertHistoryFullMonth = temp;
  }

  filterAlertHistory(){
    if(!this.searchTerm){
      this.filteredAlertHistory = {peaks: []};
      this.filteredAlertHistory.peaks = this.alertHistory.peaks;
    } else {
      let copiedPeaks = [];
      this.alertHistory.peaks.forEach((peak)=> {copiedPeaks.push(Object.assign({}, peak));});
      this.filteredAlertHistory.peaks = this.genericPipe.transform(copiedPeaks, this.searchTerm);
    }
  }

}
