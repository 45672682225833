import { Injectable, EventEmitter } from '@angular/core';
import {Http, Response, Headers, ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {environment} from "../../environments/environment";
import {IPaymentMethod, IPaymentSettingsRequest, PaymentSettingsRequest, IPCIReturn} from "../payments/payments";

@Injectable()
export class ManagePaymentMethodsService {

  private headers: Headers;

  private paymentMethods: IPaymentMethod[];

  pciCancelCalledBack: EventEmitter<IPaymentMethod[]> = new EventEmitter<IPaymentMethod[]>();
  pciEditCancelCalledBack: EventEmitter<IPCIReturn> = new EventEmitter<IPCIReturn>();
  pciChangeCalledBack: EventEmitter<IPCIReturn> = new EventEmitter<IPCIReturn>();

  constructor(private http: Http) {
    this.headers = new Headers({'Content-Type': 'application/json'});
  }

  getPaymentMethods(accountId: string): Observable<IPaymentMethod[]> {
    let url = environment.getPaymentMethodsUrl.replace('{accountId}', accountId);
    return this.http
      .get(url)
      .map((response: Response) => <IPaymentMethod[]>response.json())
      .catch(this.handleError);
  }

  deletePaymentMethod(method: IPaymentMethod): Observable<any> {
    
    let request = new PaymentSettingsRequest(method);
    let url = environment.deletePaymentMethodsUrl;
    return this.http
      .post(url, request, {headers: this.headers})
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  public pciEditCallback(info: IPCIReturn) {
     console.log("Made it back from PCI, returning token and account to finish creation");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        this.paymentMethods = methods;
        info.paymentMethods = methods;
        this.pciChangeCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI edit callback");
      }
    )
  }

  public pciRefresh(info: IPCIReturn) {
     console.log("Made it back from PCI, refreshing with new method");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        this.paymentMethods = methods;
        info.paymentMethods = methods;
        this.pciChangeCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI callback");
      }
    )
  }

  public pciNewCancel(info: IPCIReturn) {
    console.log("Updating state after cancelling new method creation");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        this.paymentMethods = methods;
        this.pciCancelCalledBack.emit(this.paymentMethods);
      }, err => {
        console.error("Couldn't refresh the methods after PCI callback");
      }
    )
  }

  public pciEditCancel(info: IPCIReturn) {
    console.log("Updating state after cancelling method edit");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        this.paymentMethods = methods;
        info.paymentMethods = methods;
        this.pciEditCancelCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI callback");
      }
    )
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
