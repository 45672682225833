import { Injectable } from '@angular/core';
import {Response, ResponseContentType, Http, Headers} from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { IUsageReportRequest, IUsageReport, ICategory, IUsageReportReponse, IUsageReportFrequencyCount, IUsageReportExportRequest } from './../shared/entities/usage-reports';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {DataService} from "../shared/data.service";

@Injectable()
export class UsageReportService {

    private headers: HttpHeaders;

    constructor(private http: HttpClient,
                private oldHttp: Http,
                private dataService: DataService) {
      this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    }

    generateUsageReportData(request: IUsageReportRequest): Observable<any> {
      if(environment.production){
        let url = environment.generateUsageReportUrl.replace('{accountId}', request.accountId.toString());
        return this.http
        .post(url,request, {headers : this.headers}).pipe(
          map((data: IUsageReportReponse)=>{
            return data;
          }),catchError( error =>{
            return this.handleError(error);
          })
        );
      } else {
        let url = environment.generateUsageReportUrl;
        return this.http
        .post(url,request, {headers : this.headers}).pipe(
          map((data: IUsageReportReponse)=>{
            return data;
          }),catchError( error =>{
            return this.handleError(error);
          })
        );
      }

    }

    getUsageReports(accountId: string): Observable<IUsageReport[]> {
      let url = environment.production ? environment.getUsageReportSettingsUrl.replace('{accountId}', accountId.toString()): environment.getUsageReportSettingsUrl;
      return this.http
      .get(url).pipe(
        map((data: IUsageReport[])=>{
          return data;
        }),catchError( error =>{
          return throwError(this.handleError);
        })
      );
    }

    createUsageReport(report: IUsageReport): Observable<IUsageReport> {
      let url = environment.production ? environment.createUsageNameAndSettingUrl.replace('{accountId}', report.accountId.toString()) : environment.createUsageNameAndSettingUrl;
      return this.http
        .post(url,report, {headers : this.headers}).pipe(
          map((data: IUsageReport)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
    }

    updateUsageReport(report: IUsageReport): Observable<IUsageReport> {
      let url = environment.production ? environment.updateUsageNameAndSettingUrl.replace('{accountId}', report.accountId.toString()).replace('{usageReportId}', report.reportId.toString()) : environment.updateUsageNameAndSettingUrl;
      return this.http
        .post(url,report, {headers : this.headers}).pipe(
          map((data: IUsageReport)=>{
            return data;
          }),catchError( error =>{
            return throwError(this.handleError);
          })
        );
    }

    deleteUsageReport(accountId: string, usageReportId: number): Observable<void>{
        let url = environment.deleteUsageReportUrl.replace('{accountId}', accountId.toString()).replace('{usageReportId}', usageReportId.toString());
        return this.oldHttp
          .post(url,"")
          .map((response: Response) => <any>response)
          .catch(this.handleError);
   }

   exportUsageReport(report: IUsageReportExportRequest): any {
    let url = environment.production ? environment.excelUsageReportUrl.replace('{accountId}', report.accountId.toString()) : environment.excelUsageReportUrl;
     let headers = new Headers({'Content-Type': 'application/json'});
     return this.oldHttp
       .post(url, report, {headers: headers, responseType: ResponseContentType.Blob})
       .subscribe(
         (res) => {
           let filename= report.reportName + '.xlsx';
           if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
             window.navigator.msSaveBlob(res.blob(), filename);
           } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
             let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
             window.open(window.URL.createObjectURL(blob));
           } else {
             let link=document.createElement('a');
             link.href=window.URL.createObjectURL(res.blob());
             link.download = filename;
             link.click();
           }
           this.dataService.setLoading(false);
         }
       );
  }

  frequencyTypeCountUsageReport(accountId: string): Observable<IUsageReportFrequencyCount> {
    let url = environment.production ? environment.frequencyTypeCountUrl.replace('{accountId}', accountId.toString()): environment.frequencyTypeCountUrl;
    return this.http
    .get(url).pipe(
      map((data: IUsageReportFrequencyCount)=>{
        return data;
      }),catchError( error =>{
        return throwError(this.handleError);
      })
    );
  }

  getRealTimeDataReportUrl(accountId: string): Observable<string> {
      let url = environment.realTimeDataReportUrl.replace('{accountId}', accountId.toString());
      return this.oldHttp
        .get(url)
        .map((response: Response) => <string>response.text())
        .catch(this.handleError);
  }

   private handleError(error: HttpResponse<any>) {
    return Observable.throw(error || 'Server error.');
  }

}
