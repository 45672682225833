import {Widget} from "./widget";
import {IInvoice} from "../bill-reports";
import {ISiteGroup} from "../site";
export class RecentInvoicesWidget extends Widget {

  constructor(widget: Widget) {
    super('RECENTINVOICES');
    this.widgetNameDisplay = 'Recent Invoices';
    this.widgetId = widget.widgetId ? widget.widgetId : null;
    this.accountId = widget.accountId ? widget.accountId : null;
    this.width = widget.width ? widget.width: 1;
    this.height = widget.height ? widget.height: 1;
    this.groupId = widget.groupId ? widget.groupId : null;
    this.showPlaceholder = widget.showPlaceholder ? widget.showPlaceholder : false;
    this.siteRequest = widget.siteRequest ? widget.siteRequest : 'ALL_SITES';
    this.systemGroupName = widget.systemGroupName ? widget.systemGroupName : null;
    this.groupCategory = widget.groupCategory ? widget.groupCategory : null;
    this.siteId = widget.siteId ? widget.siteId : null;
    this.groupId = widget.groupId ? widget.groupId : null;
  }

  buildWidget(widgetData: IInvoice[]){
      // Do anything you need to do with the invoices
    return widgetData.length != 0;
  }

  getDisplayDate(systemSiteGroups: ISiteGroup[], userSiteGroups: ISiteGroup[], maskMode: boolean): string {
    let str: string = '';
    if(this.siteRequest == 'ALL_SITES'){
      str = '(ALL SITES)';
    } else if (this.siteRequest == 'GROUP_CATEGORY'){
      if(this.systemGroupName){
        systemSiteGroups.forEach((systemGroup) => {
          if(systemGroup.groupName == this.systemGroupName) {
            str = '(' + systemGroup.display + ': ' + this.groupCategory + ')';
          }
          if(systemGroup.groupName == 'COLLECTIVE_BILLING' && maskMode){
            str = '(' + systemGroup.display + ': 80012345)';
          }
        });
      } else {
        userSiteGroups.forEach((userGroup) => {
          if(userGroup.id == this.groupId) {
            str = '(' + userGroup.groupName + ': ' + this.groupCategory + ')';
          }
        });
      }
    } else {
      if(!maskMode){
        str = '(...' + this.siteId.substr(5) + ')';
      } else {
        str = '(...1247362837481)'; // Amen.
      }
    }
    return str;
  }
}
