import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { RenewablesService } from '../../renewables.service';
import { IRenewableCertificate } from '../renewable-certificate';
import { GenericPipe } from '../../../shared/pipe/generic.pipe';
import { IUserAccount } from "../../../shared/entities/profile";
import { DataService } from "../../../shared/data.service";
import { PortalService } from '../../../shared/portal.service';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { _MatTabHeaderBase } from '@angular/material/tabs';

@Component({
  selector: 'app-certificate-table',
  templateUrl: './certificate-table.component.html',
  styleUrls: ['./certificate-table.component.scss'],
  providers: [GenericPipe]
})
export class CertificateTableComponent implements OnInit, OnDestroy {
  allCertificates: IRenewableCertificate[];
  filteredCertificates: IRenewableCertificate[] = [];
  showSearchRow = false;
  searchTerm = '';
  myAccount: IUserAccount;
  standardResLow = 'low';
  standardResHIgh = 'high';
  maskModeSubscription: Subscription;
  maskMode = false;  
  private readonly unsubscribe$ = new Subject();

  @ViewChild(SpinnerComponent, {static : false}) spinner: SpinnerComponent;

  constructor(private renewablesService: RenewablesService,
    private genericPipe: GenericPipe,
    private portalService: PortalService,
    private dataService: DataService) { }

  ngAfterViewInit(){
    this.spinner.show('Loading Certificates...');
  }
  ngOnInit() {
    this.myAccount = this.dataService.getAccountSource();
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    this.fetchCertificates();
  }

  ngOnDestroy() {
    if (this.maskModeSubscription) {
      this.maskModeSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterTable();
  }

  getCustomerName(certificate: IRenewableCertificate) {
   // .certAccountName)
   if (this.maskMode) {
    return this.portalService.getFakeCompanyName(this.myAccount);
   } else {
     return certificate.certAccountName;
   }
  }
  filterTable() {
    if (!this.searchTerm) {
      this.filteredCertificates = this.allCertificates;
    } else {
      this.filteredCertificates = this.genericPipe.transform(this.allCertificates, this.searchTerm);
    }
  }

  downloadCert(cri,calyear,res){
    this.spinner.show('Downloading the Certificate...');
    this.renewablesService.downloadCertificates(cri,calyear,res,this.spinner);
  }


  private fetchCertificates() {
    //this.spinner.show('Loading Certificates...');
    this.showSearchRow = false;
    this.searchTerm = '';
    this.renewablesService.getCertificates()
      .takeUntil(this.unsubscribe$)
      .subscribe(certificates => {
        this.allCertificates = certificates;
        this.filterTable();
        this.spinner.hide();
      }, error => {
        console.error('Failed to get certificates', error);
        this.spinner.hide();
      });
  }
}
