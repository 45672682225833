import {Widget, IntervalUsagePeriods} from "./widget";
import { IUsageGraphPoint } from "../../../shared/entities/usageGraph";
declare var Highcharts: any;
import * as moment from 'moment';
export class IntervalUsageWidget extends Widget {

  constructor(widget: Widget) {
    super('INTERVALUSAGE');
    this.widgetNameDisplay = 'Interval Usage';
    this.widgetId = widget.widgetId ? widget.widgetId : null;
    this.accountId = widget.accountId ? widget.accountId : null;
    this.width = widget.width ? widget.width: 1;
    this.height = widget.height ? widget.height: 1;
    this.chart = 'area';
    this.periodType = widget.periodType ? widget.periodType : { display: '3 Days Ago', value: "3DaysAgo" };
    this.dateType = widget.dateType ? widget.dateType : { display: 'Meter Read End Date', value: "METER READ END DATE" };
    this.siteRequest = widget.siteRequest ? widget.siteRequest : { display: 'All Sites', value: "ALL_SITES" };
    this.systemGroupName = widget.systemGroupName ? widget.systemGroupName : null;
    this.groupCategory = widget.groupCategory ? widget.groupCategory : null;
    this.siteId = widget.siteId ? widget.siteId : null;
    this.groupId = widget.groupId ? widget.groupId : null;
    this.showPlaceholder = widget.showPlaceholder ? widget.showPlaceholder : false;
  }

  getMomentFromPoint(point: IUsageGraphPoint){
    return moment(moment(point.date).minute((point.intervalId * 15) - 15).format('MM/DD/YY HH:mm:SS'));
  }

  buildWidget(data: IChartResponse) {

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  let chartData: IChartData = {
    demand: [],
    time: []
  };

  data.points.forEach((point) => {
    let militaryTimeLabel = this.getMomentFromPoint(point).format('HH:mm');
    chartData.time.push(militaryTimeLabel);
    point.hourDisplay = militaryTimeLabel;
    point.y = point.demand;
  });

  let chartId = 'chart-' + this.widgetId;
    Highcharts.chart(chartId, {
      chart: {
        type: this.chart,
        height: '200'
      },
      title: {
        text: ''
      },
      plotOptions: { series: { marker: { enabled: false}}},
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0',
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      xAxis: {
        categories: chartData.time,
        tickInterval: 8
      },
      yAxis: {
        title: {
          text: 'Demand (KW)'
        }
      },
      tooltip: {
        formatter: function() {
          return moment(this.point.date).format('ddd, MMM Do') + ' - ' + this.point.hourDisplay + '<br /> Demand: ' + this.point.y + ' KW';
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: ' ',
        data: data.points
      }]
    });

    return data.points.length != 0;
  }

  getDisplayDate(): string {
    let str = '';
    if(this.periodType.display){
      str += '(' + moment().subtract(this.periodType.value.substr(0,1), 'day').format('M/D') + ' - ';
      str += this.periodType.display + ')';
    } else {
      str += '(' + moment().subtract(this.periodType.substr(0,1), 'day').format('M/D') + ' - ';
      IntervalUsagePeriods.forEach((period) => {
        if(period.value == this.periodType){
          str += period.display + ')';
        }
      })
    }
    return str;
  }
}

interface IChartResponse {
  points: IUsageGraphPoint[];
  siteCount: number;
}

interface IChartData {
  demand: number[];
  time: string[];
}
