import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import { ISelectedDateRange, ISelectedMoveInDateRange } from './selected-date-range';
declare var $: any;

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss']
})
export class DateRangeSelectorComponent implements OnInit, OnChanges {
  
  type: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() rangeTooltip = '';
  @Input() maxDays = 30;
  @Input() green = false;
  @Input() startDateOverride: Date;
  @Input() initialStartDate: Date;
  @Input() endDateOverride: Date;
  @Input() minRangeDate: string;
  @Input() maxRangeDate: string;
  @Input() exportRange = false;

  @Input() minStartDate: Date;
  @Input() maxStartDate: Date;
  @Input() minEndDate: Date;
  @Input() maxEndDate: Date;
  @Input() isMarketDate = false;
  @Input() isMoveInDate = false;
  @Output() datesSelected = new EventEmitter<ISelectedDateRange>();
  @Output() moveInDatesSelected = new EventEmitter<ISelectedMoveInDateRange>();
  selectAllDates = false;

  showDateRange = false;

  // marking as i/p props to trigger onchanges in date range component
  @Input() editStartDate: Date;
  @Input() editEndDate: Date;
  selectedStartDate: Date;
  selectedEndDate: Date;
  dateRangeError: string = null;
  private readonly dateFormat = 'MM/DD/YY';
  // private readonly dateFormat = 'YYYY-MM-DD';

  ngOnInit() {
    this.setDefaultSelection();
    if(!this.type)
    {
        this.type="";
        if(window.location.href.match('drop')) {
          this.type = "Drop";
        } else if(window.location.href.match('enrollments')) {
          this.type = "Move-In";
        }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // for edit dates: triggered only when the dates are null
    if (
      (changes.minDate && changes.minDate.currentValue) ||
      (changes.maxDate && changes.maxDate.currentValue) ||
      (changes.editStartDate && !changes.editStartDate.currentValue) ||
      (changes.editEndDate && !changes.editEndDate.currentValue)
    ) {
      this.setDefaultSelection();
    }
  }

  toggleDateRange() {
    this.showDateRange = !this.showDateRange;
    if (this.dateRangeError) {
      this.setDefaultSelection();
    }
    if (this.showDateRange) {
      if (this.selectedStartDate) {
        this.editStartDate = this.selectedStartDate;
      }
      if (this.selectedEndDate) {
        this.editEndDate = this.selectedEndDate;
      }
    }
    this.dateRangeError = null;
  }

  selectStartDate() {
    const maxSelectionEnd = moment(this.selectedStartDate)
      .add(this.maxDays, 'days')
      .toDate();
    this.selectedEndDate =
      maxSelectionEnd > this.actualMaxDate
        ? this.actualMaxDate
        : maxSelectionEnd;
  }

  applyDateRange() {
    const start = moment(this.editStartDate);
    const end = moment(this.editEndDate);
    const min = moment(this.actualMinDate).startOf('day');
    const max = moment(this.actualMaxDate).endOf('day');

    if (end.isBefore(start)) {
      this.dateRangeError = 'Start Date must be before End Date';
      return;
    }

    if (start.isBefore(min)) {
      this.dateRangeError =
        'Start Date must be on or after ' + min.format(this.dateFormat);
      return;
    }

    if (end.isAfter(max)) {
      this.dateRangeError =
        'End Date must be on or before ' + max.format(this.dateFormat);
      return;
    }

    // Check if date range is exceeded
    if (
      !this.exportRange &&
      (this.minStartDate
        ? false
        : Math.abs(start.diff(end, 'days')) > this.maxDays)
    ) {
      this.dateRangeError = `Dates must be at most ${this.maxDays} days apart.`;
      return;
    }

    this.dateRangeError = null;
    this.startDateOverride = null;
    this.endDateOverride = null;

    this.selectedStartDate = this.editStartDate;
    this.selectedEndDate = this.editEndDate;
    this.editStartDate = null;
    this.editEndDate = null;
    if(window.location.href.match('enrollments')) {
       this.moveInDatesSelected.emit({
          startDate: this.selectedStartDate,
          endDate: this.selectedEndDate,
          selectAll: this.selectAllDates
        });
   }
    else {
      this.datesSelected.emit({
         startDate: this.selectedStartDate,
          endDate: this.selectedEndDate
        });
    }
    this.toggleDateRange();
  }

  private setDefaultSelection() {
    const minSelectionStart = moment(this.actualMaxDate)
      .subtract(this.maxDays, 'days')
      .startOf('day')
      .toDate();
    this.editStartDate = this.initialStartDate
      ? this.initialStartDate
      : minSelectionStart < this.actualMinDate
      ? this.actualMinDate
      : minSelectionStart;
    this.editEndDate = this.endDateOverride || this.actualMaxDate;
    this.selectedStartDate = moment(this.editStartDate)
      .subtract(0, 'day')
      .toDate();
    if (!this.selectedEndDate) {
      this.selectedEndDate = moment(this.editEndDate)
        .subtract(0, 'day')
        .toDate();
    }
  }

  get actualMinDate(): Date {
    return (
      this.minDate ||
      moment(this.actualMaxDate)
        .subtract(this.maxDays, 'days')
        .startOf('day')
        .toDate()
    );
  }

  get actualMaxDate(): Date {
    if(window.location.href.match('enrollments')) {
      return this.maxDate || moment().subtract(0, 'day').endOf('day').toDate();
    }
    else{
      return this.maxDate || moment().subtract(1, 'day').endOf('day').toDate();
    }
  }

  get dateRangeDisplay(): string {
    const startDate = this.startDateOverride || this.selectedStartDate;
    const endDate = this.endDateOverride || this.selectedEndDate;
    if (startDate && endDate) {
      return (
        moment(startDate).format(this.dateFormat) +
        ' - ' +
        moment(endDate).format(this.dateFormat)
      );
    }
    return 'No Dates Selected';
  }
}
