import { Pipe, PipeTransform } from '@angular/core';
import {IUserPermission} from "../entities/profile";

@Pipe({
  name: 'userPermissionsPipe'
})
export class UserPermissionsPipe implements PipeTransform {

  transform(userPermissions: IUserPermission[], term: string): IUserPermission[] {
    let filteredUsers = [];
    term = term.toLowerCase();
    let props = [];
    for(let prop in userPermissions[0].userProfile){
      props.push(prop); // consolidate site properties into an array
    }
    for(let prop in userPermissions[0].userAccount){
      props.push(prop); // consolidate site properties into an array
    }
    // console.log(props);
    for(let userPermission of userPermissions){
      //outer:
      for(let prop of props){
        if(userPermission.userProfile[prop] && userPermission.userProfile[prop].toString().toLowerCase().match(term.toLowerCase()) ||
          userPermission.userAccount[prop] && userPermission.userAccount[prop].toString().toLowerCase().match(term.toLowerCase())){
          filteredUsers.push(userPermission);
          break;
        }
      }
    }
    return filteredUsers;
  }

}
