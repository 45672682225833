import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISelfRegisterRequest } from '../entities/SelfRegisterRequest';


@Component({
  selector: 'app-sr-user-info',
  templateUrl: './sr-user-info.component.html',
  styleUrls: ['./sr-user-info.component.scss']
})
export class SelfRegistrationUserInfoComponent implements OnInit {
  @Input() accountId: string;
  @Input() accountName: string;
  @Input() showEmailInput:boolean;
  @Input() showInitialMessage:boolean;
  @Input() showEmailInUseForOtherBrandError:boolean;
  @Input() showEmailInUseForThisAccount:boolean;
  @Input() showEmailInUseForAnotherAccount:boolean;
  @Input() showUserInfoForm:boolean;
  @Output() enableContinueButton = new EventEmitter<boolean>();
  @Output() emailToCheck = new EventEmitter<string>();
  @Output() showLoginButton = new EventEmitter<boolean>();
  @Output() SelfRegisterRequest = new EventEmitter<ISelfRegisterRequest>();

  srRequest: ISelfRegisterRequest;

  emailFormControl = new FormControl('',[
  	Validators.required,
    Validators.minLength(4),
  	Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z]{2,4}$")
  ]);

  nameFormControl = new FormControl('',[
    Validators.required,
    Validators.minLength(4)
  ]);


  constructor() { }

  ngOnInit() {
    this.showInitialMessage = true;
    this.showEmailInput = true;
    this.srRequest = {
      accountId: null,
      accountName:null,
      selfRegistrationCase: null,
      email: null,
      firstName: null,
      lastName: null,
      organization: null,
      phoneNumber: null,
      giveAdminAccess: false
    }
    this.SelfRegisterRequest.emit(this.srRequest);
  }x

  enableContinue(){
    this.enableContinueButton.emit(true);
  }

  validateInputs(){}

  updateEmail(){
    this.emailToCheck.emit(this.srRequest.email);
    if(this.emailFormControl.valid){
      this.enableContinueButton.emit(true);
    } else {
      this.enableContinueButton.emit(false);
    }
  }

  checkFormCompletion(){
    if(this.nameFormControl.valid){
      this.SelfRegisterRequest.emit(this.srRequest);
      this.enableContinue();
    }
  }
}
