import {Component, Input, OnInit, ViewChild} from '@angular/core';
import * as moment from "moment";

declare var Highcharts: any;

@Component({
  selector: 'app-epdm-modal',
  templateUrl: './epdm-modal.component.html',
  styleUrls: ['./epdm-modal.component.scss']
})
export class EpdmModalComponent implements OnInit {

  epdmChart:any;
  public visible = false;

  constructor() { }

  ngOnInit() {
  }

  show(monthYear) {
    // Code here to make service call and get data
    // Pass data into render method below
    this.epdmChart = this.renderEpdmChart(this.someData);
    // this.epdmModal.show();
    this.visible = true;
  }

  hide() {
    // this.epdmModal.hide();
    this.visible = false;
  }

  private renderEpdmChart(data){
    return Highcharts.chart('epdm-chart', {
      chart: {
        zoomType: 'x',
        events: {
          click: this.hide()
        }
      },
      // colors: this.colors,
      legend: {
        enabled: false,
      },
      title: {
        text: ''
      },
      plotOptions: {
        series: {
          // general options for all series
          marker: {
            symbol: 'square',
          },
        },
        area: {
          // shared options for all area series
        }
      },
      yAxis: {
        title: {
          // text: yAxisLabel,
          // format: yAxisFormat,
        },
        labels: {
          // format: yAxisFormat
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          rotation: -45,
        },
        dateTimeLabelFormats: {
          second: '%m/%d/%Y<br/>%H:%M:%S',
          minute: '%m/%d/%Y<br/>%H:%M',
          hour: '%m/%d/%Y<br/>%H:%M',
          day: '%m/%d/%Y',
          week: '%m/%d/%Y',
          month: '%m/%Y',
          year: '%Y'
        }
      },
      series: [{
        // credits: { enabled: false },
        type: 'line',
        turboThreshold: 1000000,
        data: data,
        tooltip: {
          crosshairs: {
            width: 2,
            color: 'black',
            // dashStyle: 'shortdot'
          },
        }
      }]
    });
  }

  public someData = [
    [
      1533099600000,
      28.75
    ],
    [
      1533186000000,
      28.85
    ],
    [
      1533272400000,
      28.97
    ],
    [
      1533531600000,
      28.76
    ],
    [
      1533618000000,
      28.99
    ],
    [
      1533704400000,
      29.19
    ],
    [
      1533790800000,
      29.29
    ],
    [
      1533877200000,
      29.3
    ],
    [
      1534136400000,
      29.35
    ],
    [
      1534222800000,
      29.46
    ],
    [
      1534309200000,
      29.42
    ],
    [
      1534395600000,
      29.42
    ],
    [
      1534482000000,
      29.49
    ],
    [
      1534741200000,
      29.55
    ],
    [
      1534827600000,
      29.6
    ],
    [
      1534914000000,
      29.47
    ],
    [
      1535000400000,
      29.53
    ],
    [
      1535086800000,
      29.43
    ],
    [
      1535346000000,
      29.57
    ],
    [
      1535432400000,
      29.37
    ],
    [
      1535518800000,
      29.32
    ],
    [
      1535605200000,
      29.25
    ],
    [
      1535691600000,
      29.54
    ],
    [
      1536037200000,
      29.29
    ],
    [
      1536123600000,
      29.3
    ],
    [
      1536210000000,
      29.6
    ],
    [
      1536296400000,
      29.83
    ],
    [
      1536555600000,
      29.93
    ],
    [
      1536642000000,
      30.04
    ],
    [
      1536728400000,
      30.41
    ],
    [
      1536814800000,
      30.45
    ],
    [
      1536901200000,
      30.77
    ],
    [
      1537160400000,
      30.84
    ],
    [
      1537246800000,
      31.04
    ],
    [
      1537333200000,
      31.05
    ],
    [
      1537419600000,
      31.33
    ],
    [
      1537506000000,
      32.16
    ],
    [
      1537765200000,
      32.45
    ],
    [
      1537851600000,
      32.06
    ],
    [
      1537938000000,
      32.16
    ],
    [
      1538024400000,
      32.32
    ],
    [
      1538110800000,
      32.44
    ],
    [
      1538370000000,
      32.49
    ],
    [
      1538456400000,
      32.58
    ],
    [
      1538542800000,
      32.58
    ],
    [
      1538629200000,
      32.58
    ],
    [
      1538715600000,
      32.75
    ],
    [
      1538974800000,
      32.92
    ],
    [
      1539061200000,
      32.79
    ],
    [
      1539147600000,
      32.8
    ],
    [
      1539234000000,
      32.66
    ],
    [
      1539320400000,
      32.67
    ],
    [
      1539579600000,
      32.82
    ],
    [
      1539666000000,
      32.78
    ],
    [
      1539752400000,
      32.75
    ],
    [
      1539838800000,
      32.7
    ],
    [
      1539925200000,
      32.75
    ],
    [
      1540184400000,
      32.65
    ],
    [
      1540270800000,
      32.71
    ],
    [
      1540357200000,
      32.75
    ],
    [
      1540443600000,
      32.64
    ],
    [
      1540530000000,
      32.73
    ],
    [
      1540789200000,
      32.84
    ],
    [
      1540875600000,
      32.68
    ],
    [
      1540962000000,
      32.88
    ],
    [
      1541048400000,
      32.82
    ],
    [
      1541134800000,
      32.85
    ],
    [
      1541397600000,
      32.95
    ],
    [
      1541484000000,
      33.19
    ],
    [
      1541570400000,
      33.31
    ],
    [
      1541656800000,
      33.47
    ],
    [
      1541743200000,
      33.47
    ],
    [
      1542002400000,
      33.56
    ],
    [
      1542088800000,
      33.42
    ],
    [
      1542175200000,
      33.19
    ],
    [
      1542261600000,
      33.03
    ],
    [
      1542348000000,
      32.77
    ],
    [
      1542607200000,
      32.81
    ],
    [
      1542693600000,
      32.41
    ],
    [
      1542780000000,
      32.38
    ],
    [
      1542952800000,
      32.8
    ],
    [
      1543212000000,
      32.74
    ],
    [
      1543298400000,
      32.89
    ],
    [
      1543384800000,
      33.19
    ],
    [
      1543471200000,
      32.88
    ],
    [
      1543557600000,
      32.99
    ],
    [
      1543816800000,
      33.2
    ],
    [
      1543903200000,
      33.14
    ],
    [
      1543989600000,
      33.2
    ],
    [
      1544076000000,
      33.51
    ],
    [
      1544162400000,
      33.49
    ],
    [
      1544421600000,
      33.44
    ],
    [
      1544508000000,
      33.33
    ],
    [
      1544594400000,
      33.35
    ],
    [
      1544680800000,
      33.33
    ],
    [
      1544767200000,
      33.2
    ],
    [
      1545026400000,
      32.77
    ],
    [
      1545112800000,
      32.88
    ],
    [
      1545199200000,
      32.76
    ],
    [
      1545285600000,
      32.68
    ],
    [
      1545372000000,
      32.84
    ],
    [
      1545631200000,
      33.26
    ],
    [
      1545804000000,
      33.21
    ],
    [
      1545890400000,
      33.25
    ],
    [
      1545976800000,
      32.96
    ],
    [
      1546236000000,
      32.84
    ],
    [
      1546408800000,
      32.46
    ],
    [
      1546495200000,
      32.33
    ],
    [
      1546581600000,
      32.47
    ],
    [
      1546840800000,
      32.6
    ],
    [
      1546927200000,
      32.95
    ],
    [
      1547013600000,
      33.3
    ],
    [
      1547100000000,
      33.44
    ],
    [
      1547186400000,
      33.52
    ],
    [
      1547445600000,
      33.73
    ],
    [
      1547532000000,
      33.51
    ],
    [
      1547618400000,
      33.16
    ],
    [
      1547704800000,
      33.48
    ],
    [
      1547791200000,
      33.88
    ],
    [
      1548136800000,
      34.06
    ],
    [
      1548223200000,
      34.42
    ],
    [
      1548309600000,
      34.75
    ],
    [
      1548396000000,
      34.71
    ],
    [
      1548655200000,
      34.55
    ],
    [
      1548741600000,
      34.38
    ],
    [
      1548828000000,
      34.21
    ],
    [
      1548914400000,
      33.87
    ],
    [
      1549000800000,
      33.89
    ],
    [
      1549260000000,
      33.41
    ],
    [
      1549346400000,
      33.74
    ],
    [
      1549432800000,
      33.78
    ],
    [
      1549519200000,
      33.56
    ],
    [
      1549605600000,
      33.75
    ],
    [
      1549864800000,
      34.03
    ],
    [
      1549951200000,
      34.14
    ],
    [
      1550037600000,
      33.9
    ],
    [
      1550124000000,
      33.59
    ],
    [
      1550210400000,
      33.38
    ],
    [
      1550556000000,
      33.72
    ],
    [
      1550642400000,
      33.47
    ],
    [
      1550728800000,
      33.57
    ],
    [
      1550815200000,
      33.5
    ],
    [
      1551074400000,
      33.62
    ],
    [
      1551160800000,
      33.47
    ],
    [
      1551247200000,
      33.6
    ],
    [
      1551333600000,
      33.82
    ],
    [
      1551420000000,
      33.79
    ],
    [
      1551679200000,
      33.97
    ],
    [
      1551765600000,
      33.9
    ],
    [
      1551852000000,
      33.46
    ],
    [
      1551938400000,
      33.3
    ],
    [
      1552024800000,
      33.11
    ],
    [
      1552280400000,
      32.85
    ],
    [
      1552366800000,
      32.92
    ],
    [
      1552453200000,
      33.05
    ],
    [
      1552539600000,
      33.06
    ],
    [
      1552626000000,
      32.93
    ],
    [
      1552885200000,
      33.14
    ],
    [
      1552971600000,
      33.15
    ],
    [
      1553058000000,
      32.8
    ],
    [
      1553144400000,
      32.65
    ],
    [
      1553230800000,
      32.61
    ],
    [
      1553490000000,
      32.92
    ],
    [
      1553576400000,
      32.84
    ],
    [
      1553662800000,
      32.77
    ],
    [
      1553749200000,
      32.92
    ],
    [
      1553835600000,
      32.87
    ],
    [
      1554094800000,
      32.93
    ],
    [
      1554181200000,
      33.31
    ],
    [
      1554267600000,
      33.46
    ],
    [
      1554354000000,
      33.21
    ],
    [
      1554440400000,
      33.37
    ],
    [
      1554699600000,
      33.49
    ],
    [
      1554786000000,
      33.41
    ],
    [
      1554872400000,
      33.11
    ],
    [
      1554958800000,
      32.96
    ],
    [
      1555045200000,
      33.09
    ],
    [
      1555304400000,
      32.67
    ],
    [
      1555390800000,
      32.44
    ],
    [
      1555477200000,
      32.36
    ],
    [
      1555563600000,
      32.32
    ],
    [
      1555909200000,
      32.29
    ],
    [
      1555995600000,
      32.25
    ],
    [
      1556082000000,
      32.24
    ],
    [
      1556168400000,
      32.46
    ],
    [
      1556254800000,
      32.5
    ],
    [
      1556514000000,
      32.53
    ],
    [
      1556600400000,
      32.69
    ],
    [
      1556686800000,
      32.6
    ],
    [
      1556773200000,
      32.57
    ],
    [
      1556859600000,
      32.24
    ],
    [
      1557118800000,
      32.09
    ],
    [
      1557205200000,
      32.23
    ],
    [
      1557291600000,
      32.35
    ],
    [
      1557378000000,
      32.35
    ],
    [
      1557464400000,
      32.41
    ],
    [
      1557723600000,
      32.6
    ],
    [
      1557810000000,
      32.79
    ],
    [
      1557896400000,
      32.7
    ],
    [
      1557982800000,
      32.87
    ],
    [
      1558069200000,
      32.94
    ],
    [
      1558328400000,
      32.99
    ],
    [
      1558414800000,
      32.6
    ],
    [
      1558501200000,
      32.33
    ],
    [
      1558587600000,
      32.56
    ],
    [
      1558674000000,
      32.74
    ],
    [
      1559019600000,
      32.55
    ],
    [
      1559106000000,
      32.29
    ],
    [
      1559192400000,
      32.02
    ],
    [
      1559278800000,
      31.74
    ],
    [
      1559538000000,
      31.83
    ],
    [
      1559624400000,
      31.92
    ],
    [
      1559710800000,
      31.95
    ],
    [
      1559797200000,
      31.71
    ],
    [
      1559883600000,
      31.9
    ],
    [
      1560142800000,
      31.82
    ],
    [
      1560229200000,
      31.94
    ],
    [
      1560315600000,
      31.89
    ],
    [
      1560402000000,
      31.82
    ],
    [
      1560488400000,
      32.13
    ],
    [
      1560747600000,
      32.01
    ],
    [
      1560834000000,
      31.47
    ],
    [
      1560920400000,
      31.02
    ],
    [
      1561006800000,
      30.44
    ],
    [
      1561093200000,
      30.59
    ],
    [
      1561352400000,
      30.93
    ],
    [
      1561438800000,
      30.54
    ],
    [
      1561525200000,
      30.27
    ],
    [
      1561611600000,
      30.27
    ],
    [
      1561698000000,
      30.19
    ],
    [
      1561957200000,
      29.94
    ],
    [
      1562043600000,
      29.88
    ],
    [
      1562130000000,
      30.09
    ],
    [
      1562302800000,
      30.75
    ],
    [
      1562562000000,
      30.33
    ],
    [
      1562648400000,
      30.57
    ],
    [
      1562734800000,
      30.6
    ],
    [
      1562821200000,
      30.63
    ],
    [
      1562907600000,
      31.15
    ],
    [
      1563166800000,
      30.7
    ],
    [
      1563253200000,
      30.12
    ],
    [
      1563339600000,
      30.15
    ],
    [
      1563426000000,
      30.04
    ],
    [
      1563512400000,
      29.98
    ],
    [
      1563771600000,
      30.08
    ],
    [
      1563858000000,
      30.09
    ],
    [
      1563944400000,
      29.75
    ],
    [
      1564030800000,
      29.99
    ],
    [
      1564117200000,
      29.72
    ],
    [
      1564376400000,
      29.47
    ],
    [
      1564462800000,
      29.86
    ],
    [
      1564549200000,
      30.29
    ],
    [
      1564722000000,
      29.87
    ]
  ];
}



