import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {DataService} from "../../shared/data.service";
import { ResetPasswordService } from "./reset-password.service"
import { IPasswordResetRequest } from '../../shared/entities/profile';
import {Subscription} from "rxjs";
import {PortalService} from "../../shared/portal.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  newPassword: string;
  confirmNewPassword: string;
  passwordResetKey: string;
  invalidPassword: boolean = false;
  passwordErrorMessage: string;
  brand: string;
  routeSubscription: Subscription;
  resetPasswordSubscription: Subscription;

  constructor(private passwordResetService: ResetPasswordService,
              private dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private portalService: PortalService,
              private router: Router) { }

  ngOnInit() {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.passwordResetKey = params['key'];
      this.brand = params['brand'];
    });
  }

  ngOnDestroy() {
    // this.routeSubscription.unsubscribe();
    // this.resetPasswordSubscription.unsubscribe();
  }

  getOriginUrl() : string{
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com";
    const stg1 = "https://stg1-manage.nrg.com";
    const test1 = "https://test1-manage.nrg.com";
    const pd = "https://manage.nrg.com";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
      return dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return pd;
    }
    else {
      return local;
    }
  }

  resetPassword() {
    let validation = this.portalService.validatePassword(this.newPassword, this.confirmNewPassword);
    if(validation.isValid){
        console.log("Resetting password");
        let passwordResetRequest:IPasswordResetRequest = {newPassword: this.newPassword, key: this.passwordResetKey, env: this.getOriginUrl()};
        this.resetPasswordSubscription = this.passwordResetService.resetPassword(passwordResetRequest).subscribe(
          (response) => {
            this.invalidPassword = false;
            this.dataService.setCreatePassword(false);
            this.dataService.setResetPassword(false);
            this.dataService.setLoggedIn(false);
            this.dataService.setLoading(true);
            setTimeout(()=>{
              let appProps = this.dataService.getAppProperties();
              window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
              this.dataService.setLoading(false);
            }, 3000)
          }
        );
    } else {
        console.log("Password validation failed");
        this.passwordErrorMessage = validation.error;
        this.invalidPassword = true;
    }
  }
}
