import {IBillingReportResponse} from "../shared/entities/bill-reports";
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {PortalService} from "../shared/portal.service";
import {DataService} from "../shared/data.service";
import {DatePeriods} from "../shared/const/datePeriods";
import { FakerService } from "../shared/faker.service";
import { ReportSettingsService } from "./report-settings.service";
import { Observable } from "rxjs";
import { ISubscribeReportTemplate } from "../shared/entities/subscribeReportTemplate";
import { IGenericReportSetting } from "../shared/entities/reportSettings";
import { SitesControl } from "../shared/entities/controls";

declare var $: any;

@Injectable()
export class ReportDataUtility {

  constructor(private portalService: PortalService,
              private dataService: DataService,
              private reportSettingsService: ReportSettingsService,
              private fakerService: FakerService){}

  calculateTotals(reportData: IBillingReportResponse) {


    let allSites = 0, allInvoices = 0, invoicesForCategory = 0;
    let invoiceProps = [];
    if(reportData.categories[0] && reportData.categories[0].sites[0]) {
      for(let prop in reportData.categories[0].sites[0].invoices[0]){
        invoiceProps.push(prop);
      }
    }

    reportData.categories.forEach((category) => {
      allSites += category.sites.length;
      invoicesForCategory = 0;
      category.sites.forEach((site) => {
        for(let prop of invoiceProps) {
          if(prop.toLowerCase().match('charge') || prop == 'balanceDue' || prop.toLowerCase().match('energy') ||
             prop.toLowerCase().match('passthrough') || prop == 'aggregationFee' || prop == 'taxesAndAssessments'){
            site[prop] = 0;
            site.invoices.forEach((invoice) => {
              site[prop] += invoice[prop];
            });
          } else if((prop!='moveInDate' && prop!='moveOutDate') && prop.toLowerCase().match('date') || prop.toLowerCase().match('contract'))
          {
            let temp = "";
            site.invoices.forEach((invoice) => {
              if(temp!="Multiple") {
                if(temp=="" ) {
                  site[prop] = invoice[prop];
                  temp = invoice[prop];
                } else {
                  if(temp!=invoice[prop]) {
                    site[prop] = "Multiple";
                    temp = "Multiple";
                  }
                }
              }
            })
          }
          else if(prop == 'frequencyType')
          {
            let temp = "";
            site.invoices.forEach((invoice) => {
              if(temp!="Other") {
                if(temp=="" ) {
                  site[prop] = invoice[prop];
                  temp = invoice[prop];
                } else {
                  if(invoice[prop]!=null && temp!=invoice[prop]) {
                    site[prop] = "Other";
                    temp = "Other";
                  }
                }
              }
            })
          }
        }
        allInvoices += site.invoices.length;
        invoicesForCategory += site.invoices.length;
        site.invoiceCount = site.invoices.length;
      });
      for(let prop of invoiceProps) {
        if(prop.toLowerCase().match('charge') || prop == 'balanceDue' || prop.toLowerCase().match('energy') ||
        prop.toLowerCase().match('passthrough') || prop == 'aggregationFee' || prop == 'taxesAndAssessments'){
          category[prop] = 0;
          category.sites.forEach((site)=> {
            category[prop] += site[prop];
          })
        } else if((prop!='moveInDate' && prop!='moveOutDate') && prop.toLowerCase().match('date') || prop.toLowerCase().match('contract'))
        {
          let temp = "";
          category.sites.forEach((site)=>{
            if(temp!="Multiple") {
              if(temp=="" ) {
                category[prop] = site[prop];
                temp = site[prop];
              } else {
                if(temp!=site[prop]) {
                  category[prop] = "Multiple";
                  temp = "Multiple";
                }
              }
            }
          })
        }
        else if((prop!='moveInDate'&& prop!='moveOutDate') && prop.toLowerCase().match('date') || prop.toLowerCase().match('contract'))
        {
          let temp = "";
          category.sites.forEach((site)=>{
            if(temp!="Multiple") {
              if(temp=="" ) {
                category[prop] = site[prop];
                temp = site[prop];
              } else {
                if(temp!=site[prop]) {
                  category[prop] = "Multiple";
                  temp = "Multiple";
                }
              }
            }
          })
        }else if(prop == 'frequencyType')
        {
          let temp = "";
          category.sites.forEach((site)=>{
            if(temp!="Other") {
              if(temp=="" ) {
                category[prop] = site[prop];
                temp = site[prop];
              } else {
                if(site[prop]!=null && temp!=site[prop]) {
                  category[prop] = "Other";
                  temp = "Other";
                }
              }
            }
          })
        }
      }
      category.invoiceCount = invoicesForCategory;
    });
    for(let prop of invoiceProps){
      if(prop.toLowerCase().match('charge') || prop == 'balanceDue' || prop.toLowerCase().match('energy') ||
      prop.toLowerCase().match('passthrough')  || prop == 'aggregationFee' || prop == 'taxesAndAssessments'){
        reportData[prop] = 0;
        reportData.categories.forEach((category) => {
          reportData[prop] += category[prop];
        })
      }
      else if((prop!='moveInDate' && prop!='moveOutDate') && prop.toLowerCase().match('date') || prop.toLowerCase().match('contract'))
      {
        let temp = "";
        reportData.categories.forEach((category) => {
          console.log(category[prop]);
          if(temp!="Multiple") {
            if(temp=="" ) {
              reportData[prop] = category[prop];
              temp = category[prop];
            } else {
              if(temp!=category[prop]) {
                reportData[prop] = "Multiple";
                temp = "Multiple";
              }
            }
          }
        })
      }
      else if(prop == 'frequencyType')
      {
        let temp = "";
        reportData.categories.forEach((category) => {
          if(temp!="Other") {
            if(temp=="" ) {
              reportData[prop] = category[prop];
              temp = category[prop];
            } else {
              if(category[prop]!=null && temp!=category[prop]) {
                reportData[prop] = "Other";
                temp = "Other";
              }
            }
          }
        })
      }
    }
    reportData.siteCount = allSites;
    reportData.invoiceCount = allInvoices;
    return reportData;
  }

  getColumnNamesString(columns: any) {
    let str = '';
    columns.forEach((section) => {
      section.columns.forEach((col)=> {
        if(col.checked){
          str += col.value + ','
        }
      })
    });
    if(str == '') str = ' ';
    return str;
  }

  getColumnHeadersString(columns: any) {
    let str = 'Grouping,Site #,Invoice,';
    columns.forEach((section) => {
      section.columns.forEach((col)=> {
        if(col.checked){
          str += col.display + ','
        }
      })
    });
    return str;
  }

  formatCellBilling(data: any, col: string, maskingMode: boolean, maskedAddress?: string) {
    if(data){
      if((col == 'taxAmount' || col == 'totalCurrentCharges' || col == 'balanceDue' || col.toLowerCase().match('charge') || col == 'aggregationFee' ||  col == 'tec' ||  col == 'taxesAndAssessments'
          || col == 'energyCharge' ||  col == 'transmissionCharge' ||  col == 'nodalAncillaryCharge' ||  col == 'nitCharge' ||  col == 'capoCharge' ||  col == 'otherCharge' ||
          col == 'currentPeriodPassThrough' || col == 'priorPeriodPassThrough') && data[col] != undefined) {
        return '$' + this.numbersWithCommas(data[col].toFixed(2));
      } else if (col.toLowerCase().match('date')){
        if (col == 'moveOutDate') {
          return data['moveOutDate'] == '9999-12-31' ? '' : data['moveOutDate'] ? moment(data['moveOutDate']).format('M/D/YY') : '';
        }
        if(data[col]){
          if(data[col] == 'Multiple'){
            return "Multiple";
          } else {
            return moment(data[col]).format('M/D/YY');
          }
        } else {
          return '';
        }
      } else if (col == 'serviceAddress' && !maskingMode) {
        return data['serviceAddress1'] ? data['serviceAddress1'] + '...' : '';
      } else if (col == 'billingAddress' && !maskingMode) {
        return data['billingAddress1'] ? data['billingAddress1'] + '...' : '';
      }  else if (col == 'serviceAddress' && maskingMode) {
        return maskedAddress;
      } else if (col == 'billingAddress' && maskingMode) {
        return maskedAddress;
      }else if (col == 'energy' || col == 'totalEnergyQuantity'){
        return this.numbersWithCommas(data[col]);
      } else if (data["contractAccountNumber"] != null && col == "contractAccountNumber" && maskingMode) {
        return '1-ZK3JE7'; //constant value for masking
      } else if (col == 'siteName' && maskingMode) {
        return 'SITE NAME';
      }
        else {
        return data[col];
      }
    }
  }

  numbersWithCommas(x){
    return this.portalService.numberWithCommas(x);
  }

  calculateDateRanges() {
    let billingDateRanges = [];
    let current = moment();
    let date: any = {};
    for (let i = 0; i < 36; i++) {
      date = {};
      date.display = current.format('MMMM YYYY');
      date.startDate = current.startOf('month').format('YYYY-MM-DD');
      date.endDate = current.endOf('month').format('YYYY-MM-DD');
      billingDateRanges.push(date);
      current = current.subtract(1, 'months');
    }
    this.dataService.setReportSubscriptionDateRangesSource(billingDateRanges);
  }

  generateDatesFromPeriod(dateRangeControl: any, reportDateRanges: any) {
    if(dateRangeControl.periodSelected && reportDateRanges){
      let period = dateRangeControl.datePeriod;
      let startDate;
      if(period == DatePeriods.ThisMonth){
        startDate= reportDateRanges[0].startDate;
      } else if (period == DatePeriods.LastMonth) {
        startDate= reportDateRanges[1].startDate;
      } else if (period == DatePeriods.Last3Months) {
        startDate= reportDateRanges[2].startDate;
      } else if (period == DatePeriods.Last6Months) {
        startDate= reportDateRanges[5].startDate;
      } else if (period == DatePeriods.LastYear) {
        startDate= reportDateRanges[11].startDate;
      } else if (period == DatePeriods.Last2Years) {
        startDate= reportDateRanges[23].startDate;
      } else if (period == DatePeriods.Last3Years) {
        startDate= reportDateRanges[35].startDate;
      }
      dateRangeControl.startDate = startDate;
      dateRangeControl.endDate = reportDateRanges[0].endDate;
      return dateRangeControl;
    }
  }

  getReportSettings(accountId: string, reportTemplate: ISubscribeReportTemplate, dateRangeControl: any, datePeriods: any[], reportDateRanges: any[], maskMode: boolean, selectDefault?: boolean, selectedReport?: IGenericReportSetting,  columns?: any[], sitesControl?: SitesControl) {
    let reportSettings: any = null;
    let finalSelection: any = null;
    let finalPublic: any = null;
    let finalPrivate:any = null;
    let makeReportDefault: any = null;
    let makeReportPublic:any = null;
    let startDate;
    let endDate;

    return new Observable(observer => {
      this.reportSettingsService.getReportSettings(accountId, reportTemplate.reportType).subscribe(
        (reports) => {
          reportSettings = reports;
          if(maskMode) {
            reportSettings.forEach(report =>{
              report.reportName = this.fakerService.generateFakeReportName();
            })
          }
          if (reports.length > 0) {
            finalSelection = this.handleReportSelection(reportSettings,selectedReport,selectDefault);
            const {publicReports, privateReports} = this.segregateReports(reports);
            finalPublic = publicReports;
            finalPrivate = privateReports;
            const loadSelectedReportResult = this.loadSelectedReport(reportTemplate,finalSelection, dateRangeControl, datePeriods, reportDateRanges,sitesControl);
            makeReportDefault = loadSelectedReportResult.makeDefault;
            makeReportPublic = loadSelectedReportResult.makePublic;
            reportTemplate = loadSelectedReportResult.reportTemplate;
            startDate = loadSelectedReportResult.startDate;
            endDate = loadSelectedReportResult.endDate;
            columns = columns;
          } else {
            const defaultReport = this.loadDefaultReport(reportTemplate,accountId,dateRangeControl,datePeriods, reportDateRanges, columns);
            reportSettings = defaultReport.reports;
            finalSelection = defaultReport.selectedReport;
            reportTemplate = defaultReport.reportTemplate;
            finalPublic = defaultReport.publicReports;
            finalPrivate = defaultReport.privateReports;
            startDate = defaultReport.startDate;
            endDate = defaultReport.endDate;
            columns = defaultReport.columns;
          }
          observer.next({
            reportSettings: reportSettings,
            reportTemplate: reportTemplate,
            publicReports: finalPublic,
            privateReports: finalPrivate,
            selectedReport: finalSelection,
            dateRangeControl: dateRangeControl,
            startDate: startDate,
            endDate: endDate,
            columns: columns
          });
          observer.complete();
        },
        (error) => {
          const defaultReport = this.loadDefaultReport(reportTemplate,accountId,dateRangeControl,datePeriods, reportDateRanges, columns);
          reportSettings = defaultReport.reports;
          finalSelection = defaultReport.selectedReport;
          reportTemplate = defaultReport.reportTemplate;
          finalPublic = defaultReport.publicReports;
          finalPrivate = defaultReport.privateReports;
          startDate = defaultReport.startDate;
          endDate = defaultReport.endDate;
          columns = defaultReport.columns;
          observer.next({
            enrollmentReports: reportSettings,
            reportTemplate: reportTemplate,
            publicReports: finalPublic,
            privateReports: finalPrivate,
            selectedReport: finalSelection,
            dateRangeControl: dateRangeControl,
            startDate: startDate,
            endDate: endDate,
            columns: columns
          });
          observer.complete();
        }
      );
    });
  }

  handleReportSelection(reportSettings:any, selectedReport?:any,selectDefault?: boolean) {
    return selectDefault
    ? reportSettings.find(report => report.defaultReport === 1) || reportSettings[0]
    : reportSettings.find(report => report.reportId === selectedReport.reportId) || reportSettings[0];
  }

  segregateReports(reports: any) {
    let publicReports = reports.filter(report => report.publicFlag === 1);
    let privateReports = reports.filter(report => report.publicFlag !== 1);
    return { publicReports, privateReports};
  }

  loadSelectedReport(reportTemplate: ISubscribeReportTemplate, selectedReport: any, dateRangeControl: any, datePeriods: any[], reportDateRanges: any[], sitesControl?: SitesControl) {
    const makeDefault = selectedReport.defaultReport == 1;
    const makePublic = selectedReport.publicFlag == 1;
    let startDate;
    let endDate;
    reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          if (selectedReport.periodType) {
            dateRangeControl.periodSelected = true;
            dateRangeControl.datePeriod = selectedReport.periodType;
            dateRangeControl = this.getDatePeriodDisplay(datePeriods,dateRangeControl);
            const startAndEndDates = this.generateStartAndEndDatesFromPeriod(dateRangeControl, reportDateRanges);
            startDate = startAndEndDates.startDate;
            endDate = startAndEndDates.endDate;
          } else {
            dateRangeControl.periodSelected = false;
            dateRangeControl.startDate = selectedReport.startDate;
            dateRangeControl.endDate = selectedReport.endDate;
            const dateRange = this.setDateRange(dateRangeControl);
            startDate = dateRange.startDate;
            endDate = dateRange.endDate;
          }
          dateRangeControl = this.getDateRangeDisplay(dateRangeControl);
          if(reportTemplate.reportType == 'BROKER_ENROLL') {
            dateRangeControl.includeSitesWithoutMoveIn = selectedReport.reportSpecificBoolean;
          }
          filter.savedFilterControl = dateRangeControl;
          break;
        case 'SitePicker':
          let userSiteGroups;
          let systemSiteGroups;
          const siteGroups = this.dataService.getSiteGroupsSource();
          if(sitesControl == null){
            sitesControl = new SitesControl();
          }
          if (siteGroups) {
            userSiteGroups = siteGroups.userSiteGroups;
            systemSiteGroups = siteGroups.systemSiteGroups;
          }
          if (selectedReport.systemGroupName == 'SPECIFIC_SITE') {
            sitesControl.siteRequest = 'SPECIFIC_SITE';
            sitesControl.display = '...' + selectedReport.siteGroupCategory.substr(5);
            sitesControl.siteSearch = selectedReport.siteGroupCategory;
            if (selectedReport.selectedSite != null) {
              sitesControl.selectedSite = selectedReport.selectedSite;
            } else {
              sitesControl.siteId = selectedReport.siteGroupCategory;
            }
          }
          else if (selectedReport.systemGroupName == 'ALL_SITES' || selectedReport.systemGroupName == 'All Sites') {
            const allSitesGrouping = {
              groupName: 'All Sites',
              description: 'All Sites',
            };
            sitesControl.selectedGrouping = allSitesGrouping;
            sitesControl.selectedGrouping.display = allSitesGrouping.description;
            sitesControl.display = allSitesGrouping.description;
            sitesControl.siteRequest = 'GROUP';
          }
          else if (selectedReport.groupId) {
            userSiteGroups.forEach((siteGroup) => {
              if (siteGroup.id.toString() == selectedReport.groupId) {
                sitesControl.selectedGrouping = siteGroup;
                if (siteGroup.groupName.length > 18) {
                  sitesControl.selectedGrouping.display = siteGroup.groupName.substring(0, 18) + '...';
                  sitesControl.display = siteGroup.groupName.substring(0, 18) + '...';
                } else {
                  sitesControl.selectedGrouping.display = siteGroup.groupName;
                  sitesControl.display = siteGroup.groupName;
                }
              }
            });
            sitesControl.siteRequest = selectedReport.siteRequest;
            if (selectedReport.siteRequest != null && selectedReport.siteRequest == 'GROUP_CATEGORY') {
              sitesControl.selectedCategory = selectedReport.siteGroupCategory;
            }
          }
          else if (selectedReport.systemGroupName) {
            systemSiteGroups.forEach((systemGroup) => {
              if (systemGroup.groupName == selectedReport.systemGroupName) {
                sitesControl.selectedGrouping = systemGroup;
                sitesControl.selectedGrouping.display = systemGroup.display;
                sitesControl.display = systemGroup.display;
              }
            });
            sitesControl.siteRequest = selectedReport.siteRequest;
            if (selectedReport.siteRequest != null && selectedReport.siteRequest == 'GROUP_CATEGORY') {
              sitesControl.selectedCategory = selectedReport.siteGroupCategory;
            }
          }
          filter.savedFilterControl = sitesControl;
          break;
        case 'SimpleDropdown':
          if(reportTemplate.reportType == 'USAGE_SUMMARY'){
            filter.selectedOptionValue = selectedReport.frequencyType;
            filter.selectedOptionLabel = selectedReport.frequencyType;
          }
          break;
        default:
          // TODO Filter Types that arent handled
          break;
      }
    });

    return { makeDefault, makePublic, startDate, endDate, reportTemplate };
  }


  loadDefaultReport(reportTemplate:ISubscribeReportTemplate, accountId:string, dateRangeControl:any, datePeriods:any[], reportDateRanges: any[], columns?: any[]) {
    let reports = [];
    let defaultReport = this.getDefaultReport(reportTemplate,accountId);
    if(columns) {
      columns = this.setDefaultColumns(columns,reportTemplate);
      defaultReport.columnNames = this.getColumnNamesString(columns);
    }
    let startDate;
    let endDate;
    reportTemplate.filters.forEach((filter) => {
      switch (filter.filterType) {
        case 'DatePicker':
          const defaultDateResult = this.setDefaultDateRange(dateRangeControl,datePeriods, reportDateRanges);
          let tempDateRangeControl = defaultDateResult.dateRangeControl
          if(reportTemplate.reportType == 'BROKER_ENROLL') {
            tempDateRangeControl.includeSitesWithoutMoveIn = true;
          }
          filter.savedFilterControl = tempDateRangeControl;
          startDate = defaultDateResult.startDate;
          endDate = defaultDateResult.endDate;
          break;
        case 'SitePicker':
          const defaultSitesResult = this.setDefaultSites(reportTemplate);
          filter.savedFilterControl = defaultSitesResult;
          break;
        case 'SimpleDropdown':
          if(reportTemplate.reportType == 'USAGE_SUMMARY'){
            filter.selectedOptionValue = 'All';
            filter.selectedOptionLabel = 'All';
          }
        default:
          // Action for other filter types
          break;
      }
    });
    reports.push(defaultReport);
    let privateReports = [defaultReport];
    let publicReports = [];
    let selectedReport = reports[0];
    return { reports, reportTemplate, privateReports, publicReports, selectedReport, dateRangeControl, startDate, endDate, columns };
  }

  getDefaultReport(reportTemplate: ISubscribeReportTemplate,accountId:string,) {
    let defaultReport = {
      reportId: null,
      columnNames: '',
      accountId: accountId,
      reportName: reportTemplate.defaultReportName,
      periodType: 'Last3Months',
      defaultReport: 0,
      startDate: null,
      endDate: null,
      publicFlag: 0,
      systemGroupName: reportTemplate.reportType == 'BILLING_SUMMARY' ? 'CONTRACTS' : 'ALL_SITES',
      frequencyType: 'All',
      createdUserId: this.dataService.getUserSource().login
    };
    return defaultReport;
  }

  setDefaultDateRange(dateRangeControl: any, datePeriods: any[], reportDateRanges: any[]) {
    let startDate;
    let endDate;
    dateRangeControl.periodSelected = true;
    dateRangeControl.show = false;
    dateRangeControl.toggled = false;
    const datePeriod = this.selectDatePeriod(dateRangeControl, 'Last3Months', datePeriods, reportDateRanges);
    startDate = datePeriod.startDate;
    endDate = datePeriod.endDate;
    dateRangeControl = datePeriod.dateRangeControl;
    this.getDateRangeDisplay(dateRangeControl);

    return {startDate, endDate, dateRangeControl};
  }

  setDefaultSites(reportTemplate: ISubscribeReportTemplate){
    let sitesControl: SitesControl = new SitesControl();
    if(reportTemplate.reportType == 'BILLING_SUMMARY') {
      const contractsSitesGrouping = {
        groupName: 'CONTRACTS',
        description: 'Contracts',
      };
      sitesControl.siteRequest = 'GROUP';
      sitesControl.selectedGrouping = contractsSitesGrouping;
      sitesControl.selectedGrouping.display = contractsSitesGrouping.description;
    } else {
      const allSitesGrouping = {
        groupName: 'All Sites',
        description: 'All Sites',
      };
      sitesControl.siteRequest = 'GROUP';
      sitesControl.selectedGrouping = allSitesGrouping;
      sitesControl.selectedGrouping.display = allSitesGrouping.description;
    }

    return sitesControl;
  }

  setDefaultColumns(columns: any[], reportTemplate: ISubscribeReportTemplate) {
    if(reportTemplate.reportType == 'BILLING_SUMMARY'){
      columns.forEach((section) => {
        section.checked = false;
        section.toggled = section.name == 'General Information';
        section.columns.forEach((col) => {
          if(col.display == 'Meter Read Start Date' || col.display == 'Meter Read End Date' || col.display == 'Total Current Charges'
            || col.display == 'Billing Date'|| col.display == 'Due Date' || col.display == 'Account #'
            || col.display == 'Usage (kWh)' || col.display == 'Service Address') {
            col.checked = true;
            section.checked = true;
          } else {
            col.checked = false;
          }
        });
      });
    } else if (reportTemplate.reportType == 'USAGE_SUMMARY') {
      columns.forEach((section) => {
        section.checked = false;
        section.toggled = section.name == 'General Information';
        section.columns.forEach((col) => {
          if (col.display == 'Last Updated' || col.display == 'Usage Start Date' || col.display == 'Usage End Date'
            || col.display == 'TOTAL USAGE (kWh)'
            || col.display == 'Peak Demand (Hour Ending)' || col.display == 'Peak Demand (KW)'
            || col.display == 'Service Address' || col.display == 'Account #') {
            col.checked = true;
          } else {
            col.checked = false;
          }
        });
      });
    }
    return columns;
  }

  selectDatePeriod(dateRangeControl: any, datePeriod: string, datePeriods: any[], reportDateRanges: any[]) {
    dateRangeControl.datePeriod = datePeriod;
    dateRangeControl = this.getDatePeriodDisplay(datePeriods,dateRangeControl);
    const startAndEndDates = this.generateStartAndEndDatesFromPeriod(dateRangeControl, reportDateRanges);
    let startDate = startAndEndDates.startDate;
    let endDate = startAndEndDates.endDate;
    dateRangeControl = this.getDatePeriodRangeDisplay(dateRangeControl);
    return {startDate, endDate, dateRangeControl};
  }

  generateStartAndEndDatesFromPeriod(control: any, reportDateRanges: any[]) {
    this.generateDatesFromPeriod(control, reportDateRanges);
    let startDate = control.startDate;
    let endDate = control.endDate;
    return {startDate, endDate};
  }

  getDatePeriodDisplay(datePeriods:any[],dateRangeControl: any) {
    if (datePeriods) {
      datePeriods.forEach((period) => {
        if (period.value == dateRangeControl.datePeriod) {
          dateRangeControl.datePeriodDisplay = period.display;
          return dateRangeControl;
        }
      });
    }
    return dateRangeControl;
  }

  getDateRangeDisplay(dateRangeControl:any) {
    const startDate = moment(dateRangeControl.startDate).format('MMM \'YY');
    const endDate = moment(dateRangeControl.endDate).format('MMM \'YY');
    dateRangeControl.dateRangeDisplay = startDate + ' - ' + endDate;
    return dateRangeControl;
  }

  getDatePeriodRangeDisplay(dateRangeControl: any) {
    const startDate = moment(dateRangeControl.startDate).format('MMM \'YY');
    const endDate = moment(dateRangeControl.endDate).format('MMM \'YY');
    dateRangeControl.datePeriodRangeDisplay = startDate + ' - ' + endDate;
    return dateRangeControl;
  }

  setDateRange(dateRangeControl: any) {
    let startDate = dateRangeControl.startDate;
    let endDate = dateRangeControl.endDate;
    return {startDate, endDate};
  }

  saveReport(reportRequest: any, isNewReport: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
        if (!isNewReport) {
            this.reportSettingsService.updateReportSetting(reportRequest).subscribe(
                resp => {
                    let selectedReportId = resp.reportId;
                    resolve(selectedReportId);
                }, err => {
                    if (err.status == 409) {
                        this.dataService.setLoading(false);
                        $('#duplicateModal').modal('show');
                        reject(null);
                    } else {
                        console.error(err);
                        reject(null);
                    }
                }
            );
        } else {
            this.reportSettingsService.createReportSetting(reportRequest).subscribe(
                resp => {
                    let selectedReportId = resp.reportId;
                    resolve(selectedReportId);
                }, err => {
                    if (err.status == 409) {
                        this.dataService.setLoading(false);
                        $('#duplicateModal').modal('show');
                        reject(null);
                    } else {
                        console.error(err);
                        reject(null);
                    }
                }
            );
        }
    });
  }

  formatCellUsage(data: any, col: string) {
    if(data){
      if(col == 'totalCurrentUsage' || col == 'averageDailyUsage' || col == 'peakDemand' && data[col] != undefined && data[col] != null) {
        if(data[col].toFixed(0) == 0) {
          return '--'
        };
        return this.numbersWithCommas(data[col].toFixed(0));
      } else if (col == 'loadFactor') {
        return data[col] ? this.numbersWithCommas(data[col].toFixed(1)) + '%' : '';
      } else if (col == 'peakDemandHour') {
        return data[col] ? moment(data[col]).format('MM/DD/YY HH:mm') + ' CST' : '';
      } else if (col.toLowerCase().match('date')) {
        if(col == 'maxAvailableDate' && !data[col] && !data['categories']){
          return '';
        }
        return data[col] ? moment(data[col]).format('MM/DD/YY') : '';
      } else if (col == 'serviceAddress') {
        return data['serviceStreetAddress'] ? data['serviceStreetAddress'] + '...' : '';
      } else if (col == 'billingAddress') {
        return data['billingStreetAddress'] ? data['billingStreetAddress'] + '...' : '';
      } else {
        return data[col];
      }
    }
  }
}

