// youtube-video.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YoutubePlayerService {
  private videoIdSubject = new BehaviorSubject<string>('');
  videoId$ = this.videoIdSubject.asObservable();

  setVideoId(videoId: string): void {
    this.videoIdSubject.next(videoId);
  }
}
