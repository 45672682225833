import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sr-nrg-tool-tip',
  templateUrl: './sr-nrg-tool-tip.component.html',
  styleUrls: ['./sr-nrg-tool-tip.component.scss']
})
export class SelfRegistrationNrgToolTipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
