import { Component, OnInit, Injectable } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {IUserAccount} from "../../shared/entities/profile";
import {PortalService} from '../../shared/portal.service';
import * as moment from 'moment';
import {GenericPipe} from '../../shared/pipe/generic.pipe'
import {OrderByPipe} from '../../shared/pipe/orderBy.pipe'
import {PagerDenoms} from '../../shared/const/pagerDenoms'
import {IPagerDenom} from '../../shared/const/pagerDenoms'
import {PagerService} from '../../shared/pager.service'
import {Subscription} from "rxjs";
import {FakerService} from "../../shared/faker.service";
import {IHelpStep} from "../../shared/entities/contextualHelp";
import {Authority} from "../../shared/const/authority";
import {Permissions} from "../../shared/const/permissions";
import {MatDatepickerModule} from '@angular/material/datepicker';
// import { IBillDelivery, IBillDeliveryRequest } from '../shared/entities/billDelivery';
import { BillDeliveryHistoryService } from './bill-delivery-history.service';
// import { BillDeliveryHistoryPipe } from '../../shared/pipe/billdeliveryhistory.pipe';
import { Router } from '@angular/router';
import { IBillDeliveryHistory, IBillDeliveryHistoryRequest } from '../../shared/entities/billDeliveryEdit';
import { BillDeliveryHistoryPipe } from '../../shared/pipe/billdeliveryhistory.pipe';
import { ISite } from '../../shared/entities/site';

declare var $: any;

@Component({
  selector: 'app-bill-delivery-history',
  templateUrl: './bill-delivery-history.component.html',
  styleUrls: ['./bill-delivery-history.component.scss'],
  providers: [BillDeliveryHistoryPipe, GenericPipe, OrderByPipe]
})

@Injectable()
export class BillDeliveryHistoryComponent implements OnInit {

  myAccount: IUserAccount;
  selectedStartDate: Date;
  selectedEndDate: Date;
  minDate: Date;
  maxDate: Date;
  fromDate: Date;
  toDate: Date;
  showSearchBar = false;
  
  searchTerm: string;

  maskMode: boolean;
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  ascending: boolean;
  noDataError: boolean = false;
  displayLimitError: boolean = false;

  

  isSuperUser: boolean;
  
  helpStep: IHelpStep;

  sortBy: string = 'confirmEffectiveDt';

  // Subscriptions
  exportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  allBillHistory: IBillDeliveryHistory[];
  filteredBillHistory: IBillDeliveryHistory[];

  
  constructor(private dataService: DataService,
    private billDeliveryHistoryService: BillDeliveryHistoryService,
    private pipe: GenericPipe,
    private router: Router,
    private portalService: PortalService,
    private billDeliveryHistoryPipe : BillDeliveryHistoryPipe,
    private orderByPipe: OrderByPipe,
    private pagerService: PagerService,
    private fakerService: FakerService) { }

  ngOnInit(): void { 
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    // console.log("IS SUPER USER: ", this.isSuperUser);

    this.dataService.setTitleSource('Bill Delivery Management');
    this.setCurrentActivePage('payments');
    this.dataService.setSelectedNavItem('payments');
    this.myAccount = this.dataService.getAccountSource();
   // this.minDate = moment(new Date()).subtract(3,'years').toDate();
   // this.maxDate = moment(new Date()).add(15, 'days').toDate();
    this.selectedStartDate = moment(new Date()).subtract(30,'days').toDate();
    this.selectedEndDate = moment(new Date()).toDate();
    if(this.myAccount){
     this.getBillDeliveryHistory();
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getBillDeliveryHistory();
      }
    );
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.count==10) {
        this.selectDenom(denom);
      }
    });
    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {
      if(window.location.href.indexOf("history") != -1)
      this.export();
    });

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

  }

   toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    this.filterBillHistory();
  }
  
  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }

  paginate(page: number){
    if(this.filteredBillHistory){
      this.filteredBillHistory = this.orderByPipe.transform(this.filteredBillHistory, this.sortBy, this.ascending);
      this.pager = this.pagerService.getPage(this.filteredBillHistory, page, this.selectedDenom.count)
    }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  numberWithCommas(x, dollar?: boolean, twoDecimals?: boolean) {
    if(x == null){
      return '--'
    }
    let val = +x;
    let response = this.portalService.numberWithCommas(val);
    if(dollar || twoDecimals){
      response = this.portalService.numberWithCommas(val.toFixed(2));
    }
    if(dollar){
      response = '$' + response;
    }
    return response;
  }

  export() {
    // Get start date, end date, account Id,
    let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
    let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
    this.dataService.setLoading(true);
    console.log("exporting history...")

    let currentDate = moment().format("YYYY-MM-DD");
    let billDeliveryList = this.filteredBillHistory;
    let maskingMode = this.dataService.getMaskMode();
    let accountName = this.dataService.getAccountSource().name;
    let accountId = this.dataService.getAccountSource().id;
    let brand = this.dataService.getAccountSource().accountBranding;
    
    let request = {
      startDate : startDateString,
      endDate : endDateString,
      accountName : accountName,
      accountId : accountId,
     // eBillList : billDeliveryList,
      maskingMode: maskingMode,
      brand: brand
    };

    this.billDeliveryHistoryService.export(request);
   }

  filterBillHistory() {
    if(this.filteredBillHistory){
      if (this.searchTerm) {
        this.filteredBillHistory = this.billDeliveryHistoryPipe.transform(this.allBillHistory, this.searchTerm);
      } else {
        this.filteredBillHistory = this.allBillHistory;
      }
    }
    this.paginate(1);
  }

  sortHistory(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }


  getBillDeliveryHistory(){
      this.dataService.setLoading(true);
      let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
      let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
      if(startDateString && endDateString){
        let request: IBillDeliveryHistoryRequest  = {
          accountId: this.myAccount.id,
          accountName: this.dataService.getAccountSource().name, 
          startDate: startDateString,
          endDate: endDateString
        };

        this.billDeliveryHistoryService.getBillPreferenceChangeHistory(request).subscribe(
          (billHistoryResult) => {
            this.allBillHistory = billHistoryResult;
            this.noDataError = this.allBillHistory.length == 0;
            this.displayLimitError = false;
            this.ascending = false;
            this.filteredBillHistory = this.orderByPipe.transform(this.allBillHistory, 'changedDate', this.ascending);
            this.paginate(1);
            this.dataService.setLoading(false);
          }, err => {
            if(err.status == 404) {
              this.noDataError = true;
              this.displayLimitError = true;
            }
            if(err.status == 500) {
              this.displayLimitError = true;
              this.noDataError = false;
            }
            this.filteredBillHistory = [];
            this.paginate(1);
            console.error(err);
            this.dataService.setLoading(false);
          }
        );
      }
    
   }

   formatSiteId(siteId: string){
    if(siteId.indexOf("-") != -1){
      return siteId.substring(siteId.indexOf("-") + 1);
    } else{
      return siteId;
    }
  }

   getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeSiteName(site: ISite) {
    return this.portalService.getFakeSiteName(site);
  }

  getFakeContractAccountNum(site: ISite) {
    return this.portalService.getFakeContractAccountNum(site);
  }


   ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

 }

