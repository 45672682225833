import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ElementRef, OnDestroy} from '@angular/core';
import { IProductTerms } from './product-terms';
import {MarketsService} from "../markets.service";
import { DataService } from '../../shared/data.service';
import { PortalService } from '../../shared/portal.service';
import {IDateCurveRates, IFlowRate, IReportSettings, IForwardTradeRates} from "../../shared/entities/markets";
import * as moment from 'moment';
import {FadeAnimation} from "../../animations/fadeAnimation";
import { ForwardPriceForm } from '../forward-price-form/forward-price.form';
import {EpdmModalComponent} from "./epdm-modal/epdm-modal.component";
import {FpcModifyModalComponent} from "./fpc-modify-modal/fpc-modify-modal.component";
import { Subscription } from 'rxjs';
import { Authority } from '../../shared/const/authority';
import * as momentTz from 'moment-timezone';

declare var Highcharts: any;
declare var $:any;

@Component({
  selector: 'app-forward-pricing-charts',
  animations: [FadeAnimation],
  templateUrl: './forward-pricing-charts.component.html',
  styleUrls: ['../../shared/styles/reports.scss',
              './forward-pricing-charts.component.scss']
})

export class ForwardPricingChartsComponent implements OnInit, OnDestroy {
  // Forward Price Terms Chart
  forwardPriceTermsChart: any;
  reportSetting: IReportSettings;

  @Input() forwardPriceForm: ForwardPriceForm;
  @Output() returnToInput: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('epdmModal' , {static : false}) epdmModal: EpdmModalComponent;
  @ViewChild('fpcModifyModal', {static : false}) fpcModifyModal: FpcModifyModalComponent;

  // Forward Price by Trade Date Chart
  forwardPriceByTradeDateChartDate: any[];
  forwardPriceChartLoading: boolean;

  // Forward Price by Month/Year Chart

  readonly monthChart = 'MONTH';
  readonly yearChart = 'YEAR';
  forwardPriceSelectedChart = this.monthChart;
  forwardPriceMonthYearLoading: boolean;
  forwardPriceFirstDate: string;
  forwardPriceLastDate: string;
  forwardPriceMaxRange = 35;
  forwardPriceRangeInput = 0;
  forwardPriceRangeInputDisplay: string;
  forwardPriceLineTradeDateChart: any;
  forwardPriceBarMonthChart: any;
  forwardPriceBarYearChart: any;
  forwardPriceTradeDates: any;
  forwardPriceDeliveryMonthDates: string[] = [];
  forwardPriceDeliveryYearDates: string[] = [];
  forwardPriceBarChartMonthData: any = [[]];
  forwardPriceBarChartYearData: any = [[]];
  newReport: string = '';
  privacy: string = '';
  termStartDateDisplay: string = '';
  termTradeDateDisplay: string = '';
  flipSettings: boolean = false;
  newSettings: any;
  settingTypes: Array<{display: string, value: string}> = [
    // { display: 'Last 3 months', value: 'Last3Months' },
     //{ display: 'Last 6 months', value: 'Last6Months' },
     //{ display: 'Last 9 months', value: 'Last9Months' },
    { display: 'DEFAULT', value: 'LastYear' },
    //  { display: 'Last 2 years', value: 'Last2Years' },
    { display: 'LAST 3 YEARS (AS AVAILABLE)', value: 'Last3Years' }
  ];
  asOfDate: string;
  disabledSave: boolean = true;
  exportTriggeredSubscription: any;
  showSave: boolean = false;
  saveExistingAsNew: boolean = false;
  forwardTradePriceByTradeSubscription: Subscription;
  forwardTradePriceByMonthSubscription: Subscription;
  forwardTradePriceByYearSubscription: Subscription;
  readonly colors: string[] = [
    '#1DEEFB',
    '#18AAFD',
    '#0A38D9',
    '#0D1D5E',
    '#B6B6B6',
    '#707376',
    '#EE1587',
    '#9D114D',
    '#AB53BD',
    '#471E97',
    '#3BDB9E',
    '#168677'
  ];
  yAxisFormat: string;
  yAxisLabel: string;
  tooltipLabel: string;
  prefixTooltip: string;
  readonly today: moment.Moment = moment();
  readonly fiveDaysAgo: moment.Moment = moment().subtract(5, 'days');
  readonly oneYearAgo: moment.Moment = moment().subtract(1, "years");
  readonly oneYearFiveDays: moment.Moment = moment().subtract(1, "years").add(5, "days");
  isSuperUser: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(private marketsService: MarketsService, private portalService: PortalService, private dataService: DataService) { }

  ngOnInit() {
    this.reportSetting = this.forwardPriceForm.getReportSettings();
    this.dataService.setOutputPage(true);
    this.forwardPriceSelectedChart = this.monthChart;
    if (!this.reportSetting.periodType) {
      this.newSettings = this.settingTypes[0];
      this.forwardPriceForm.periodType = this.settingTypes[0].value;
      this.reportSetting = this.forwardPriceForm.getReportSettings();
    } else {
      this.newSettings = this.settingTypes.find(ele => {
        return ele.value === this.reportSetting.periodType;
      }) || this.settingTypes[0];
    }
    this.getForwardTradePriceByTradeDate();
    if(this.reportSetting.tradeEndDate.indexOf('T') != -1 && this.reportSetting.tradeStartDate.indexOf('T') != -1)
    {
      this.reportSetting.tradeEndDate = this.reportSetting.tradeEndDate.split('T')[0];
      this.reportSetting.tradeStartDate = this.reportSetting.tradeStartDate.split('T')[0];
    }
    this.getForwardTradePriceByMonth(this.reportSetting);
    this.getForwardTradePriceByYear(this.reportSetting);
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    this.isSuperAdmin = this.portalService.userHasAuthority(Authority.SuperUserAdmin);
    this.termStartDateDisplay = moment(this.forwardPriceForm.selectedTradeStartDate).format('MM/DD/YYYY');
    this.termTradeDateDisplay = moment(this.forwardPriceForm.selectedTradeEndDate).format('MM/DD/YYYY');
    this.exportTriggeredSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.dataService.setLoading(true);
      this.exportGraph()
    });

    // Listening when the user clicks outside of the dropdown
    document.addEventListener("click", ($event) => {
      if($($event.target).parents('#buttonPanel').length == 0){
        this.showSave = false;
      }
    });
  }

  destroySubscriptions() {
    if (this.forwardTradePriceByTradeSubscription) this.forwardTradePriceByTradeSubscription.unsubscribe();
    if (this.forwardTradePriceByMonthSubscription) this.forwardTradePriceByMonthSubscription.unsubscribe();
    if (this.forwardTradePriceByYearSubscription) this.forwardTradePriceByYearSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.destroySubscriptions();
    if (this.exportTriggeredSubscription) {
      this.exportTriggeredSubscription.unsubscribe();
    }
  }

  productTerms: IProductTerms[] = [];

  setColor(index: number) {
    let styles = {
      'background-color': this.colors[index],
    };
    return styles;
  }

  flip(state: boolean) {
    this.flipSettings = state;
  }

  permissionCheck() {
    return this.reportSetting.reportId && (
      this.reportSetting.globalFlag && this.isSuperAdmin
      || (this.reportSetting.publicFlag && !this.reportSetting.globalFlag && (this.isSuperAdmin || this.isSuperUser))
      || !this.reportSetting.publicFlag
    )
  }

  /***********************************
   * Forward Price Terms Chart Logic *
   ***********************************/

  private getForwardTradePriceByTradeDate() {
    this.forwardPriceChartLoading = true;
    this.portalService.spinBabySpin('forwardPriceChartLoader');
    //change dates to no time stamp
    this.reportSetting.tradeEndDate = this.reportSetting.tradeEndDate.toISOString().split('T')[0];
    this.reportSetting.tradeStartDate = this.reportSetting.tradeStartDate.toISOString().split('T')[0];
    this.forwardTradePriceByTradeSubscription = this.marketsService.getForwardPriceByTradeDateChartData(this.reportSetting).subscribe((results: IForwardTradeRates[]) => {
      this.forwardPriceTradeDates = this.processForwardTradeDates(results);
      this.forwardPriceLineTradeDateChart = this.renderForwardPriceByTradeDate();
      this.forwardPriceChartLoading = false;
    });
  }

  private processForwardTradeDates(results: IForwardTradeRates[]) {
    const data = [];
    this.productTerms = [];
    let latestDate: Date;
    results.filter(rate => rate.rates.length).forEach(lastDate => {
      if (latestDate === undefined) {
        latestDate = lastDate.rates[lastDate.rates.length - 1].flowDate;
      } else if (lastDate.rates[lastDate.rates.length - 1].flowDate > latestDate) {
        latestDate = lastDate.rates[lastDate.rates.length - 1].flowDate;
      }
    })
    results.forEach((fptd) => {
      const productItem = {} as IProductTerms;
      const tradeStartDate = moment(fptd.termStartDate);
      productItem.market = this.forwardPriceForm.selectedMarket.value;
      productItem.term = tradeStartDate.format('MM/YYYY') + ' - ' +
        tradeStartDate.add(fptd.termLength, 'month').subtract(1, 'day').format('MM/YYYY');
      productItem.months = fptd.termLength;
      productItem.priceType = this.forwardPriceForm.selectedPriceType.value;
      const series = [];
      let rateLow = 100000000;
      let rateHigh = 0;
      let ratePrev = 0;
      let change = 0;
      const lastFiftyTwoWeeks = fptd.rates.filter(rangeFiftyTwo => moment(rangeFiftyTwo.flowDate).isSameOrAfter(this.oneYearAgo));
      lastFiftyTwoWeeks.forEach(oneYearTerm => {
        if (oneYearTerm.flowPrice < rateLow) {
          rateLow = oneYearTerm.flowPrice
        };
        if (oneYearTerm.flowPrice > rateHigh) {
          rateHigh = oneYearTerm.flowPrice
        };
      });
      for (let j = 0; j < fptd.rates.length; j++) {
        const rate = fptd.rates[j];
        change = (Math.round(rate.flowPrice * 100) / 100) - (Math.round(ratePrev * 100) / 100);
        series.push([moment(rate.flowDate).toDate().getTime(), rate.flowPrice]);
        ratePrev = rate.flowPrice;
      }
      productItem.maxRange = rateHigh;
      productItem.minRange = rateLow;
      productItem.change = change;
      productItem.asOf = fptd.rates.length ? fptd.rates[fptd.rates.length - 1].flowPrice : undefined;
      if (productItem.market === 'NYMEX') {
        productItem.asOfUnit = '/MMBtu';
      } else if (productItem.priceType === 'HEAT_RATE') {
        productItem.asOfUnit = ' HR';
      } else {
        productItem.asOfUnit = '/MWh';
      }
      const todayFilter = fptd.rates.filter(tradeData => {
        return moment(tradeData.flowDate).isSameOrAfter(this.fiveDaysAgo) && moment(tradeData.flowDate).isSameOrBefore(this.today)
      });
      const oneYearAgoFilter = fptd.rates.filter(tradeData => {
        return moment(tradeData.flowDate).isSameOrAfter(this.oneYearAgo) && moment(tradeData.flowDate).isSameOrBefore(this.oneYearFiveDays)
      });
      if (todayFilter.length === 0 || oneYearAgoFilter.length === 0) {
        productItem.minRange = undefined;
      }
      if (fptd.rates.length && fptd.rates[fptd.rates.length - 1].flowDate !== latestDate) {
        productItem.asOf = undefined;
        productItem.change = undefined;
      }
      this.productTerms.push(productItem);
      data.push({ name: '', data: series });
    });
    this.asOfDate = moment(latestDate).format('MM/DD/YYYY');
    return data;
  }

  private renderForwardPriceByTradeDate() {
    this.setYAxisLabels();
    let that = this;
    return Highcharts.chart('forwardPriceByTradeDate', {
      chart: {
        zoomType: 'x',
        colorCount: 12,
        height: 475,
      },
      colors: this.colors,
      legend: {
        enabled: false,
      },
      title: {
        text: ''
      },
      plotOptions: {
        series: {
          // general options for all series
          marker: {
            symbol: 'square',
          },
        },
        area: {
          // shared options for all area series
        }
      },
      yAxis: {
        title: {
          text: this.yAxisLabel,
          format: this.yAxisFormat,
        },
        labels: {
          format: this.yAxisFormat
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          rotation: -45,
        },
        dateTimeLabelFormats: {
          second: '%m/%d/%Y<br/>%H:%M:%S',
          minute: '%m/%d/%Y<br/>%H:%M',
          hour: '%m/%d/%Y<br/>%H:%M',
          day: '%m/%d/%Y',
          week: '%m/%d/%Y',
          month: '%m/%Y',
          year: '%Y'
        }
      },
      series: this.forwardPriceTradeDates,
      credits: { enabled: false },
      tooltip: {
        crosshairs: {
          width: 2,
          color: 'black',
        },
        shared: true,
        formatter: function () {
          let str = '<b>' + moment(this.x).format('ddd, MM/DD/YY') + '</b><br/>';
          $.each(this.points, function (i, point) {
            str += '<span style="color:' + point.series.chart.options.colors[point.series.colorIndex] + '">\u25A0\u00A0</span> '+ that.prefixTooltip + point.y.toFixed(2) + that.tooltipLabel+'<br/>';
          });
          return str;
        },
      }
    });
  }

  openChartSettings() {
  }
  /********************************
   * Forward Price By Month Logic *
   ********************************/

  selectMonthChart() {
    this.forwardPriceSelectedChart = this.monthChart;
    this.forwardPriceMaxRange = this.forwardPriceRangeInput = this.forwardPriceDeliveryMonthDates.length - 1;
    this.forwardPriceFirstDate = this.forwardPriceDeliveryMonthDates[0];
    this.forwardPriceLastDate = this.forwardPriceRangeInputDisplay = this.forwardPriceDeliveryMonthDates[this.forwardPriceRangeInput];
    this.update();
  }

  selectYearChart() {
    this.forwardPriceSelectedChart = this.yearChart;
    this.forwardPriceMaxRange = this.forwardPriceRangeInput = this.forwardPriceDeliveryYearDates.length - 1;
    this.forwardPriceFirstDate = this.forwardPriceDeliveryYearDates[0];
    this.forwardPriceLastDate = this.forwardPriceRangeInputDisplay = this.forwardPriceDeliveryYearDates[this.forwardPriceRangeInput];
    this.update();
  }

  getForwardTradePriceByMonth(reportSetting: IReportSettings) {
    this.forwardPriceMonthYearLoading = true;
    this.portalService.spinBabySpin('forwardPriceMonthYearLoader');
    this.forwardTradePriceByMonthSubscription = this.marketsService.getForwardPriceByMonthChartData(reportSetting).subscribe((results: any) => {
      const [ data, maxY ] = this.processForwardPriceData(results, false);
      this.forwardPriceBarChartMonthData = data;
      this.forwardPriceBarMonthChart = this.renderForwardPriceChart("forwardPriceByMonthChart", this.forwardPriceBarChartMonthData[this.forwardPriceBarChartMonthData.length-1], false, maxY);
      this.forwardPriceMonthYearLoading = false;
    });
  }

  getForwardTradePriceByYear(reportSetting: IReportSettings) {
    this.forwardPriceMonthYearLoading = true;
    if(reportSetting.tradeEndDate.indexOf('T') > -1 && reportSetting.tradeStartDate.indexOf('T') )
    {
      reportSetting.tradeEndDate = reportSetting.tradeEndDate.split('T')[0];
      reportSetting.tradeStartDate = reportSetting.tradeStartDate.split('T')[0];
    }
    this.portalService.spinBabySpin('forwardPriceMonthYearLoader');
    this.forwardTradePriceByYearSubscription = this.marketsService.getForwardPriceByYearChartData(reportSetting).subscribe((results: any) => {
      const [ data, maxY ] = this.processForwardPriceData(results, true);
      this.forwardPriceBarChartYearData = data;
      this.forwardPriceBarYearChart = this.renderForwardPriceChart("forwardPriceByYearChart",this.forwardPriceBarChartYearData[this.forwardPriceBarChartYearData.length-1], true, maxY);
      this.forwardPriceMonthYearLoading = false;
    });
  }

  private processForwardPriceData(results: IDateCurveRates[], showYears: boolean = false) {
    let data = [];
    let maxY = 0;
    if(showYears === true) {
      this.forwardPriceDeliveryYearDates = [];
    } else {
      this.forwardPriceDeliveryMonthDates = [];
    }
    results.forEach((trd) => {
      let flowRow = [];
      if (showYears === true) {
        this.forwardPriceDeliveryYearDates.push(moment(trd.tradeDate).format('MM/DD/YYYY'));
      } else {
        this.forwardPriceDeliveryMonthDates.push(moment(trd.tradeDate).format('MM/DD/YYYY'));
      }
      trd.rates.forEach((fr: IFlowRate) => {
        /*if (fr.flowPrice > maxY) {
          maxY = fr.flowPrice;
        }*/
        flowRow.push(fr);
      });
      data.push(flowRow);
    });
    this.forwardPriceMaxRange = this.forwardPriceRangeInput = showYears === true ? this.forwardPriceDeliveryYearDates.length - 1 : this.forwardPriceDeliveryMonthDates.length - 1;
    this.forwardPriceFirstDate = showYears === true ? this.forwardPriceDeliveryYearDates[0] : this.forwardPriceDeliveryMonthDates[0];
    this.forwardPriceLastDate = this.forwardPriceRangeInputDisplay = showYears === true ? this.forwardPriceDeliveryYearDates[this.forwardPriceRangeInput] : this.forwardPriceDeliveryMonthDates[this.forwardPriceRangeInput];
    //maxY = Math.ceil(maxY * 1.10); // Multiply by 10% of the max value
    return [ data, maxY ];
  }

  public modifyReport() {
    this.fpcModifyModal.show(this.updateFormFromModify);
  }

  public updateFormFromModify(newForwardPriceForm: ForwardPriceForm) {
    this.forwardPriceForm = newForwardPriceForm;
    this.reportSetting = this.forwardPriceForm.getReportSettings();
    this.destroySubscriptions();
    this.getForwardTradePriceByTradeDate();
    this.getForwardTradePriceByMonth(this.reportSetting);
    this.getForwardTradePriceByYear(this.reportSetting);
    this.setYAxisLabels();
  }

  checkSaveModal(privacy: string) {
    if (privacy) {
      this.privacy = privacy;
    }
    this.disabledSave = !(this.newReport && this.privacy);
  }

  async createNewReport() {
    const userLogin = this.dataService.getUserSource().login;
    if (this.saveExistingAsNew) {
      this.reportSetting.reportId = 0;
      this.reportSetting.createdUserId = userLogin;
    }

    this.reportSetting.reportName = this.newReport;
    this.reportSetting.updatedBy = userLogin;
    this.reportSetting.publicFlag = this.privacy == 'private' ? 0 : 1;
    this.reportSetting.globalFlag = this.privacy == 'global' ? 1 : 0;
    this.marketsService.updateReportSettings(this.reportSetting).subscribe((response: IReportSettings) => {
      // We are going to do something later... I think - AH
    }, err => {
      // 404 gets returned when you select Save as New Report
      if (err.status == 409 || err.status == 404) {
        $('#submitConflictModal').modal('show');
      }
    })
  }

  returnRenewalPage() {
    this.returnToInput.emit(false);
  }

  private renderForwardPriceChart(container, data, year: boolean = false, maxY) {
    this.setYAxisLabels();
    let that = this;

    return Highcharts.chart(container, {
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      chart: {
          type: 'columnrange',
          inverted: false,
          reflow: true
         },
      tooltip: {
        shared:true,
        valueDecimals: 2,
        formatter: function() {
          var str = '';
          var str2 = '';
          var date = year ? moment(this.x).format('YYYY') : moment(this.x).format('MMM YYYY');

          $.each(this.points, function(i, point) {
            //console.log(point.series.name, i, point);

            if(this.point.series.name ==  'Price') {
              str = "<b>" + date + "</b>" + "<br/>Price: " + that.prefixTooltip + this.point.high.toFixed(2) + that.tooltipLabel;
            } else {
              str2 = "<br/>3 Year High:" + that.prefixTooltip + this.point.high.toFixed(2) + that.tooltipLabel +
              " on " + moment(this.point.maxFlowPriceTradeDate).format('MM/DD/YYYY') +
              "<br/>3 Year Low:" + that.prefixTooltip + this.point.low.toFixed(2) + that.tooltipLabel +
              " on " + moment(this.point.minFlowPriceTradeDate).format('MM/DD/YYYY');
            }

          });

          return str + str2;
        }
      },
      xAxis: {
        type: "datetime",
        labels: {
          rotation: -45,
          formatter: function() {
            let date = moment(this.value);
            date = date.add(-date.utcOffset(), 'minutes');
            return year ? date.format('YYYY') : date.format('MM/YYYY');
          }
        }
      },
      yAxis: {
        title:  {
          text: this.yAxisLabel
        },
        labels: {
          format: this.yAxisFormat
        },
        min: 0
      },

      plotOptions: {
        columnrange: {
              grouping: false,
              groupPadding: 0
        }
      },

      series: [{
        name: 'Price Range',
        data: this.getFlowRangeData(data),
        turboThreshold: 1000000,
        keys: ['x', 'low', 'high', 'minFlowPriceTradeDate', 'maxFlowPriceTradeDate'],
        seriesMapping: {
          x:0,
          low:1,
          high:2,
          minFlowPriceTradeDate: 3,
          maxFlowPriceTradeDate: 4
        }
      },{
        name: 'Price',
        data: this.getFlowData(data),
        turboThreshold: 1000000,
        keys: ['x', 'low', 'high'],
        seriesMapping: {
          x:0,
          low:1,
          high:2
        }
      }],
      credits: { enabled: false }
    });
  }

  getFlowData(flowRates) {
    console.log("getFlowData", this.forwardPriceRangeInputDisplay, flowRates);
    if(flowRates) {
      return flowRates.map(fr => [moment(fr.flowDate).valueOf(), fr.flowPrice-.2, fr.flowPrice]);
    }
    return [];
  }

  getFlowRangeData(flowRates) {
    console.log("getFlowRangeData", this.forwardPriceRangeInputDisplay, flowRates);
    if(flowRates) {
      return flowRates.map(fr => [moment(fr.flowDate).valueOf(), fr.minFlowPrice, fr.maxFlowPrice, fr.minFlowPriceTradeDate, fr.maxFlowPriceTradeDate]);
    }
    return [];
  }

  changeRange(event) {
    const value = event.target.value;
    // Only change either month/year
    if (this.forwardPriceSelectedChart === this.monthChart) {
      this.forwardPriceRangeInputDisplay = this.forwardPriceDeliveryMonthDates[value];
    } else {
      this.forwardPriceRangeInputDisplay = this.forwardPriceDeliveryYearDates[value];
    }
  }

  update() {
    let input = this.forwardPriceRangeInput;

    // Only change either month/year
    if (this.forwardPriceSelectedChart === this.monthChart) {
      this.forwardPriceBarMonthChart.series[0].setData(this.getFlowRangeData(this.forwardPriceBarChartMonthData[input])); // Increment dataset (updates chart)
      this.forwardPriceBarMonthChart.series[1].setData(this.getFlowData(this.forwardPriceBarChartMonthData[input])); // Increment dataset (updates chart)
    } else {
      this.forwardPriceBarYearChart.series[0].setData(this.getFlowRangeData(this.forwardPriceBarChartYearData[input])); // Increment dataset (updates chart)
      this.forwardPriceBarYearChart.series[1].setData(this.getFlowData(this.forwardPriceBarChartYearData[input])); // Increment dataset (updates chart)
    }

  }

  toggleSave() {
    this.showSave = !this.showSave;
  }

  saveExisting() {
    this.showSave = this.saveExistingAsNew = false;
    this.newReport = this.forwardPriceForm.selectedProduct.name;
  }

  saveNewReport() {
    this.showSave = false;
    this.newReport = '';
    if (this.reportSetting.reportId) {
      this.saveExistingAsNew = true;
    }
  }

  selectNewSettings(selectedValue: any){
    this.newSettings = selectedValue;
  }

  changeSettings(newSettings: any) {
    const momentToday = moment();
    this.forwardPriceForm.periodType = newSettings.value;
    this.reportSetting = this.forwardPriceForm.getReportSettings();
    if (this.forwardPriceForm.periodType == 'Last3Years')
      {
        this.reportSetting.tradeStartDate = momentToday.subtract(3, 'y').toDate();
      }
    this.getForwardTradePriceByTradeDate();
  }

  showMonthChartData() {
    return this.forwardPriceSelectedChart === this.monthChart && this.forwardPriceBarChartMonthData.length;
  }

  showYearChartData() {
    return this.forwardPriceSelectedChart === this.yearChart && this.forwardPriceBarChartYearData.length;
  }

  isMonthYearDataNotAvailable() {
    return (this.forwardPriceSelectedChart === this.monthChart && !this.forwardPriceBarChartMonthData.length)
      || (this.forwardPriceSelectedChart === this.yearChart && !this.forwardPriceBarChartYearData.length);
  }

  private exportGraph() {
    let rs = this.forwardPriceForm.getReportSettings();
    this.marketsService.exportForwardPriceChart(rs);
  }

  private setYAxisLabels() {
    this.yAxisFormat = '{value:.2f}';
    this.yAxisLabel = '$/MWh';
    this.tooltipLabel = '/MWh';
    this.prefixTooltip = '$';
    if (this.forwardPriceForm.selectedPriceType.value != 'HEAT_RATE') this.yAxisFormat = '$' + this.yAxisFormat;
    if (this.forwardPriceForm.selectedMarket.value == 'NYMEX') {
      this.yAxisLabel = '$/MMBtu';
      this.tooltipLabel = '/MMBTu';
    }
    if (this.forwardPriceForm.selectedPriceType.value == 'HEAT_RATE') {
      this.yAxisLabel = "Heat Rate";
      this.tooltipLabel = " HR";
      this.prefixTooltip = '';
    }
  }
}
