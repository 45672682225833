import { Component, Input, OnInit } from '@angular/core';
import { ManageSuperUsersService } from '../../manage-super-users/manage-super-users.service';
import { IUser } from '../../shared/entities/user';
import * as moment from 'moment';
import { PortalService } from '../../shared/portal.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FadeAnimation } from '../../animations/fadeAnimation';;

declare var $ :any;


@Component({
  selector: 'app-commissions-email-confirmation',
  templateUrl: './commissions-email-confirmation.component.html',
  styleUrls: ['./commissions-email-confirmation.component.scss'],
  animations: [FadeAnimation]
})
export class CommissionsEmailConfirmationComponent implements OnInit {

  @Input() selectedEmails: any;
  selectedStartDate: Date;
  selectedEndDate: Date;
  minDate: Date;
  maxDate: Date;
  datesPicked: boolean = false;
  usersWithCommissionsAvailable: IUser[] = [];
  emailList: String[] = [];
  userHasConfirmed: boolean = false;
  sendDisabled: boolean = true;
  showConfirmCheckBox: boolean = false;
  emailsString: string;
  emailsLoading: boolean = false;

  constructor(private manageSuperUserService: ManageSuperUsersService, private portalService: PortalService) {}

  ngOnInit() {
    this.minDate = moment('2016-01-01').toDate();
    this.maxDate = moment(new Date()).toDate();
  }
  getEmailsForDate(){
    this.emailsLoading = true;
    this.portalService.spinBabySpin('emailsLoader');
    this.manageSuperUserService.getGeneratedStatementsEmailList(this.selectedStartDate,this.selectedEndDate).subscribe((response : any)=>
    {
      this.datesPicked = true;
      this.usersWithCommissionsAvailable = response;
      this.emailsString = "";
      for (let i in response) {
        this.emailList.push(response[i].email)
        this.emailsString += response[i].email + ", ";
      }
      if(this.emailsString != ""){
        this.sendDisabled = false;
      }
      this.emailsLoading = false;
    })
  }
  sendCommissionEmails(){
    if(!this.userHasConfirmed){
      this.showConfirmCheckBox = true;
      this.sendDisabled = true;
      return
    }
    this.manageSuperUserService.sendGeneratedStatementsEmail(this.selectedStartDate,this.selectedEndDate).subscribe((response : any)=>{});
    this.sendDisabled = true;
    this.userHasConfirmed = false;
    this.showConfirmCheckBox = false;
  }

  toggleSendButton() {
    this.sendDisabled = !this.sendDisabled;
  }
  close(){
    this.userHasConfirmed = false;
    this.showConfirmCheckBox = false;
    $('#commissionsEmailConfirmationModal').modal('hide');
  }
}
