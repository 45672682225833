import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountEnding'
})
export class AccountEndingPipe implements PipeTransform {

  transform(value: string): string {
    if(value){
      return "Account ending in " + value;
    }
    return "New Payment Method";
  }
}
