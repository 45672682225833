import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { IGenerationSite, ISiteGenerationRequest } from './generation-site';
import { IGenerationStatus } from './generation-status';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import * as moment from 'moment';
import { IGeneratorStatus } from './generator-status';
import { ISwitchGearStatus } from './switch-gear-status';
import { IGenerationUsageHistory } from './generation-usage-history';
import { apiDateFormat } from '../renewables/renewables-utility';
import { ILookbackDateRange } from './lookbackDate';

@Injectable()
export class ShadowMeteringService {
  headers: HttpHeaders;
  private sites$: ReplaySubject<IGenerationSite[]>;
  private generatorStatuses$: ReplaySubject<IGeneratorStatus[]>;
  private switchGearStatuses$: ReplaySubject<ISwitchGearStatus[]>;
  private generationUsageHistory$: ReplaySubject<IGenerationUsageHistory>;
  private statusDate: moment.Moment;
  private siteSelected: IGenerationSite;
  private lookback = false;
  private lookbackRange: ILookbackDateRange;
  private lookbackDemand: number;
  private showUtilityDemand: boolean;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.resetData();
  }

  get sites(): Observable<IGenerationSite[]> {
    return this.sites$.asObservable();
  }

  get generatorStatuses(): Observable<IGeneratorStatus[]> {
    return this.generatorStatuses$.asObservable();
  }

  get generationUsageHistory(): Observable<IGenerationUsageHistory> {
    return this.generationUsageHistory$.asObservable();
  }

  get switchGearStatuses(): Observable<ISwitchGearStatus[]> {
    return this.switchGearStatuses$.asObservable();
  }

  get statusTimestamp(): moment.Moment {
    return this.statusDate;
  }

  get utilityDemandAvailable(): boolean {
    return this.showUtilityDemand;
  }

  get selectedSite(): IGenerationSite {
    return this.siteSelected;
  }

  get lookbackEnabled(): boolean {
    return this.lookback;
  }

  get lookbackUtilityDemand(): number {
    return this.lookbackDemand;
  }

  get lookbackDateRange(): ILookbackDateRange {
    return this.lookbackRange;
  }

  setSelectedSite(site: IGenerationSite) {
    this.siteSelected = site;
  }

  setShowUtilityDemand(showUtilityDemand: boolean) {
    this.showUtilityDemand = showUtilityDemand;
  }

  setLookbackEnabled(lookback: boolean) {
    this.lookback = lookback;
  }

  setLookbackDemand(lookbackDemand: number) {
    this.lookbackDemand = lookbackDemand;
  }

  setLookbackRange(lookbackRange: ILookbackDateRange) {
    this.lookbackRange = lookbackRange;
  }

  setTimestamp(timestamp: moment.Moment) {
    this.statusDate = timestamp;
  }

  updateSites(sites: IGenerationSite[]) {
    this.sites$.next(sites);
  }

  updateGeneratorStatuses(generatorStatuses: IGeneratorStatus[]) {
    this.generatorStatuses$.next(generatorStatuses);
  }

  updateSwitchGearStatuses(switchGearStatuses: ISwitchGearStatus[]) {
    this.switchGearStatuses$.next(switchGearStatuses);
  }

  updateGenerationUsageHistory(generationUsageHistory: IGenerationUsageHistory) {
    this.generationUsageHistory$.next(generationUsageHistory);
  }

  resetData() {
    this.sites$ = new ReplaySubject<IGenerationSite[]>(1);
    this.generatorStatuses$ = new ReplaySubject<IGeneratorStatus[]>(1);
    this.switchGearStatuses$ = new ReplaySubject<ISwitchGearStatus[]>(1);
    this.generationUsageHistory$ = new ReplaySubject<IGenerationUsageHistory>(1);
    this.statusDate = null;
    this.siteSelected = null;
    this.lookback = false;
    this.lookbackRange = null;
    this.lookbackDemand = null;
    this.showUtilityDemand = true;
  }

  getSites(accountId: string): Observable<IGenerationSite[]> {
    let url = environment.getGenerationSites.replace('{accountId}', accountId.toString());
    return this.httpClient
      .get<IGenerationSite[]>(url)
      .map((response: IGenerationSite[]) => {
        this.updateSites(response);
        return response;
      }).catch(this.handleError);
  }

  getSiteGenerationUsage(accountId: string, startDate?: string, endDate?: string): Observable<IGenerationUsageHistory> {
    const request = {
      siteId: this.selectedSite.id,
      startDate: startDate ? startDate : moment(this.selectedSite.maxDateRange).subtract(7, 'day').endOf('day').format(apiDateFormat),
      endDate: endDate ? endDate : moment(this.selectedSite.maxDateRange).endOf('day').format(apiDateFormat),
      latest: !this.lookbackEnabled
    } as ISiteGenerationRequest;

    let url = environment.getGenerationUsage;
    url = environment.getGenerationUsage.replace('{accountId}', accountId);
    return this.httpClient
      .post<IGenerationUsageHistory>(url, request, { headers: this.headers })
      .map((response: IGenerationUsageHistory) => {
        this.updateGenerationUsageHistory(response);
        return response;
      }).catch(this.handleError);
  }

  private calculateLatestTimestamp(date: string, intervalId: number): moment.Moment {
    return moment(date, apiDateFormat).add((intervalId - 1) * 5, 'minutes');
  }

  getGenerationStatus(accountId: string, siteIds?: string[], isLatest = true): Observable<IGenerationStatus[]> {
        let url = environment.getGenerationSitesStatus.replace('{accountId}', accountId.toString());
        let request = {
          siteIds: siteIds ? siteIds : [this.selectedSite.id],
          latest: isLatest
        };

        return this.httpClient
          .post<IGenerationStatus[]>(url, request)
          .map((response: IGenerationStatus[]) => {
            if (response && response.length) {
              const generatorStatuses: IGeneratorStatus[] = [];

              // Flat map generator responses, add site ID to each record for reference
              response.forEach(generationStatus =>
                generationStatus.generatorStatuses.forEach(generatorStatus => {
                  generatorStatus.siteId = generationStatus.siteId;
                  generatorStatuses.push(generatorStatus);
                })
              );

              this.updateGeneratorStatuses(generatorStatuses);

              // Only update switchGearStatuses if we are fetching data for a single site
              // TODO: Add generator status values here?
              if (!siteIds) {
                this.updateSwitchGearStatuses(response[0].switchGearStatus);
              }

              // Set the correct timestamp
              if (siteIds) {
                let latestTimestamp = null;
                response.forEach(generationStatus => {
                  if (generationStatus.generatorStatuses.length) {
                    const thisTimestamp = this.calculateLatestTimestamp(generationStatus.date, generationStatus.intervalId);
                    if (!latestTimestamp || thisTimestamp.isAfter(latestTimestamp)) {
                      latestTimestamp = thisTimestamp;
                    }
                  }
                });
                this.setTimestamp(latestTimestamp);
              } else {
                this.setTimestamp(this.calculateLatestTimestamp(response[0].date, response[0].intervalId));
              }
            }
            return response;
          }).catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
