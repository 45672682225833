import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'genericPipe'
})
export class GenericPipe implements PipeTransform {
  transform(items: any[], term: string, excludedProps: string[] = []): any[] {
    term = term.toLowerCase().trim();
    let termMoment = moment(term);
    let filteredItems = [];
    let addressProps = ['billingAddress', 'serviceAddress'];
    let addressSubProps = ['streetAddress', 'city', 'state', 'zipCode', 'zip'];
    items.forEach((item) => {
      let found = false;
      outer:
      for (let prop in item) {
        if (item[prop] && !excludedProps.includes(prop)) {
          if (item[prop].toString().toLowerCase().match(term)) {
            found = true;
          } else if (prop.toLowerCase().match('date')) {
            if (termMoment.year() == 2001) {
              // No year specified
              if (moment(item[prop]).format('MM/DD') == termMoment.format('MM/DD')) {
                found = true;
              }
            } else {
              if (moment(item[prop]).format('MM/DD/YYYY') == termMoment.format('MM/DD/YYYY')) {
                found = true;
                if (term.includes('31') && termMoment.format('DD') == '01') {
                  found = false;
                }
              } else if (moment(item[prop]).format('MMMM').toLowerCase().match(term)) {
                found = true;
              }
            }
          } else if (prop.toLowerCase().match('address')) {
            for (let addressProp of addressProps) {
              for (let addressSubProp of addressSubProps) {
                if (item[addressProp] && item[addressProp][addressSubProp] && item[addressProp][addressSubProp].toLowerCase().match(term)) {
                  found = true;
                }
              }
            }
          }
        }
        if (found) {
          filteredItems.push(item);
          break outer;
        }
      }
    });
    return filteredItems;
  }
}
