import { Component, OnInit } from '@angular/core';
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {DataService} from "../../shared/data.service";
import {ISiteGroup, ISite,ISiteCountRequest} from "../../shared/entities/site";
import {SiteGroupService} from "../../site-groups/site-groups.service";
import {BillingSummaryService} from "../../billing-summary/billing-summary.service";
import {PortalService} from "../../shared/portal.service";
import * as moment from 'moment';
import {IDownloadMultipleInvoicesRequest} from "../../shared/entities/bill-reports";
import {Subscription} from "rxjs";
import { IAccount } from '../../shared/entities/account';
import {IUserAccount, IProfile} from '../../shared/entities/profile';
declare var $:any;
@Component({
  selector: 'app-multiple-invoices',
  templateUrl: 'multiple-invoices.component.html',
  animations: [FadeAnimation, FadeInAnimation],
  styleUrls: ['multiple-invoices.component.scss']
})
export class MultipleInvoicesComponent implements OnInit {

  showInvalidError: boolean = false;
  sitesForAccount: ISite[];


  // Current Step - used to highlight
  activeStep = 1;

  // Sites
  sitesRadio: string;
  siteGroups: ISiteGroup;
  selectedGroup: ISiteGroup;
  isSystem: boolean;
  groupCategories: string[];
  selectedCategory: string;
  selectedSite: ISite;
  siteOptions: string[] = [];
  filteredSites: any[] = [];
  siteSearch: string;
  siteIndex: number = -1;
  emailError: boolean = false;
  // Invoices
  invoicesRadio: string;
  excessiveSites: boolean;
  noInvoices: boolean;
  siteCountComplete: boolean;
  allSites: ISite[];
  groupCategory: ISiteCountRequest;
  sites: ISite[];
  myAccount: IUserAccount;


  // Dates
  dateRanges: any[];
  startDates: any[];
  selectedStartDate: any;
  endDates: any[];
  selectedEndDate: any;
  siteGroupsSourceSubscription: Subscription;
  billingDateRangesSourceSubscription: Subscription;
  accountSourceSubscription: Subscription;
  sitesSubscription: Subscription;
  profile: IProfile;
  profileSubscription: Subscription;
  dateTypeRadio: string;
  validDateRange: boolean;


  constructor(private dataService: DataService,
              private siteGroupsService: SiteGroupService,
              private billingReportService: BillingSummaryService,
              private portalService: PortalService) { }

  ngOnInit() {
    let siteGroups = this.dataService.getSiteGroupsSource();
    if(siteGroups) {
      this.siteGroups = siteGroups;
    }
    this.siteGroupsSourceSubscription = this.dataService.siteGroupsSourceUpdated.subscribe(
      (siteGroups) => {
        this.siteGroups = siteGroups;
      }
    );
    let dateRanges = this.dataService.getReportSubscriptionDateRangesSource();
    if(dateRanges) {
      this.dateRanges = dateRanges;
      this.startDates = this.endDates = this.dateRanges;
    }



    this.myAccount = this.dataService.getAccountSource();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
      }
    );

    this.billingDateRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
      (dateRanges) => {
        this.dateRanges = dateRanges;
        this.startDates = this.endDates = this.dateRanges;
      }
    );
    let account = this.dataService.getAccountSource();
    this.profileSubscription = this.dataService.profileUpdated.subscribe(
      (profile) => {
        this.profile = profile;
      }
    );
    this.sitesForAccount = this.dataService.getSitesForAccountSource();
    if(this.sitesForAccount) {
      this.sitesForAccount.forEach((site) => {
        this.siteOptions.push(site.siteId + ' ' + site.name);
      });
    }

   
      this.sitesSubscription = this.dataService.sitesForAccountSourceUpdated.subscribe((sites) => {
        this.sitesForAccount = sites;
        if(this.sitesForAccount) {
          this.sitesForAccount.forEach((site) => {
            this.siteOptions.push(site.siteId + ' ' + site.name);
          });
        }
      });
   
    this.dataService.multipleInvoiceTriggered.subscribe(() => {

      this.activeStep = 1;
      this.sitesRadio = 'allSites';
      this.selectedCategory = null;
      this.selectedSite = null;
      this.siteSearch = '';
      this.invoicesRadio = null;
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      this.dateTypeRadio = null;
    })
  }

  ngOnDestroy() {
    if (this.siteGroupsSourceSubscription) this.siteGroupsSourceSubscription.unsubscribe();
    if (this.billingDateRangesSourceSubscription) this.billingDateRangesSourceSubscription.unsubscribe();
    if (this.sitesSubscription) this.sitesSubscription.unsubscribe();
    if (this.profileSubscription) this.profileSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
  }

  selectGroup(group: ISiteGroup, isSystem: boolean){
    this.selectedGroup = group;
    this.isSystem = isSystem;
    if(isSystem){
      this.siteGroupsService.getCategoriesForSystemGroup(this.selectedGroup.groupName).subscribe(
        (categories : any) => {
          this.groupCategories = categories;
        }
      )
    } else {
      this.siteGroupsService.getCategoriesForUserGroup(this.selectedGroup.id).subscribe(
        (categories : any) => {
          this.groupCategories = categories;
        }
      )
    }
  }

  getContractNickname(contractNum: string){
    return this.portalService.getContractNickname(contractNum);
  }

  selectCategory(category: string){
    this.selectedCategory = category;
    if(this.activeStep != 3) {
      this.activeStep = 2;
    }
  }

  selectStartDate(startDate: any){
    // console.log('selectstartdate is called');
    this.selectedStartDate = startDate;
    this.endDates = this.dateRanges;
    let newEndDates = [];
    this.endDates.forEach((endDate) => {
      if(moment(this.selectedStartDate.startDate).isBefore(endDate.endDate)){
        newEndDates.push(endDate);
      }
    });
    this.endDates = newEndDates;
    this.validateDateRange();
   this.countSites();
  }

  selectEndDate(endDate: any){
    this.selectedEndDate = endDate;
    this.validateDateRange();
     this.countSites();
  }

  validateDateRange() {
    this.validDateRange = true;
    if(this.selectedStartDate && this.selectedEndDate) {
      if(moment(this.selectedStartDate.startDate).isAfter(moment(this.selectedEndDate.endDate))) {
        this.validDateRange = false;
      }
    }
  }

  sitesRadioChanged() {
    if(this.sitesRadio == 'allSites' && this.activeStep != 3){
      this.activeStep = 2;
    }
    // if (this.myAccount.largeCustomer) {
    //     this.countSites();
    // }
    this.countSites();
  }

  dateTypeRadioChanged() {
    this.activeStep = 3;
  }

  countSites() {
    this.excessiveSites = false;
    this.noInvoices = false;
    this.siteCountComplete = false;
    if(this.sitesRadio && this.selectedStartDate && this.selectedEndDate){
      let siteCount = 0;
      if(this.sitesRadio == 'allSites') {
          siteCount = this.sitesForAccount.length;
          this.evaluateSiteCount(siteCount);
      } else if (this.sitesRadio == 'groupCategory') {
            let request: ISiteCountRequest = {
              accountId: this.dataService.getAccountSource().id,
              category: this.selectedCategory
            };
            if(this.isSystem) {
              // System group (e.g. contracts, etc.)
              request.systemGroupName = this.selectedGroup.groupName;
            } else {
              //Custom group
              request.groupId = this.selectedGroup.id;
            }
          this.billingReportService.siteCount(request).subscribe(
               (sites) => {
               this.sites = sites;
               siteCount = this.sites.length;
               this.evaluateSiteCount(siteCount);
            }, err => {
              console.error(err);
            }
          );
      } else {
        siteCount = 1;
        this.siteCountComplete = true;
      }
    }
  }

  evaluateSiteCount(siteCount: number) {
    let diff = moment(this.selectedEndDate.endDate).diff(moment(this.selectedStartDate.startDate), 'months');
    diff++;

    // console.log('months diff is ' + diff);
    // console.log('site count is ', siteCount);
    let invoiceCount = siteCount * diff;
    // console.log('invoice count is ', invoiceCount);

    if(invoiceCount > 7500) {
      this.excessiveSites = true;
    } else if (invoiceCount == 0) {
      this.noInvoices = true;
    }

    this.siteCountComplete = true;

    return invoiceCount;
  }

  getGroupLabel() {
    if(this.selectedGroup){
      if(this.selectedGroup.id){
        return this.selectedGroup.groupName.length > 22 ? this.selectedGroup.groupName.slice(0,22) + '...' : this.selectedGroup.groupName;
      } else {
        return this.selectedGroup.display.length > 22 ? this.selectedGroup.display.slice(0,22) + '...': this.selectedGroup.display;
      }
    } else {
      return 'Select a Group'
    }
  }

  download() {
    let siteRequest, siteCount = 0;
    if(this.sitesRadio == 'allSites'){
      siteRequest = 'ALL_SITES';
      siteCount = this.sitesForAccount.length;
    } else if (this.sitesRadio == 'groupCategory' && this.selectedGroup && this.selectedCategory){
      siteRequest = 'GROUP_CATEGORY';
      siteCount = this.sites.length;
    } else if (this.sitesRadio == 'singleSite' && this.selectedSite){
      siteRequest = 'SPECIFIC_SITE';
      siteCount = 1;
    }
    if(siteRequest){
      this.dataService.setLoading(true);
      let request: IDownloadMultipleInvoicesRequest = {
        accountId: this.dataService.getAccountSource().id,
        customerName: this.dataService.getAccountSource().name,
        startDate: this.selectedStartDate.startDate,
        endDate: this.selectedEndDate.endDate,
        siteRequest: siteRequest,
        email: this.profile.email,
        dateType: this.dateTypeRadio,
        allInvoices: true
      };
      if(siteRequest == 'GROUP_CATEGORY'){
        request.groupId = this.isSystem ? null : this.selectedGroup.id;
        request.siteGroupName = this.isSystem ? this.selectedGroup.groupName : null,
        request.siteGroupCategory = this.selectedCategory
      } else if (siteRequest == 'SPECIFIC_SITE'){
        request.siteId = this.selectedSite.siteId;
      }
      
      if ( (siteRequest == 'ALL_SITES' || siteRequest == 'GROUP_CATEGORY') && this.evaluateSiteCount(siteCount) > 10 ) {
        this.downloadAsync(request);
      } else {
        this.billingReportService.downloadMultipleInvoices(request).subscribe(
          (res) => {
            if(res.status == 202){
              this.downloadAsync(request);
            } else {
              this.dataService.setLoading(false);
              let contentDisposition = res.headers.get('content-disposition');
              let filename = '';
              filename = contentDisposition.match(/filename=(.+)/)[1];
              if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
                window.navigator.msSaveBlob(res.body, filename);
              } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                let blob = new Blob([res.body], {type: "application/zip"});
                window.open(window.URL.createObjectURL(blob));
              }  else {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(res.body);
                link.download = filename;
                link.click();
              }
            }
          }, err => {
            if(err.status == 404){
              $('#noInvoicesModal').modal('show');
            }
            this.dataService.setLoading(false);
            console.error(err);
          }
        );
      }
      this.showInvalidError = false;
    } else {
      this.showInvalidError = true;
    }
  }

  downloadAsync(request: IDownloadMultipleInvoicesRequest) {
    if(request.siteRequest){
      request.login = this.dataService.getUserSource().login;
      this.billingReportService.downloadMultipleInvoicesAsync(request).subscribe(
        (asyncRes) => {
          this.dataService.setLoading(false);
          $('#asyncModal').modal('show');
        }, err => {
          if(err.status == 404){
            $('#noInvoicesModal').modal('show')
          }
          this.dataService.setLoading(false);
          console.error(err);
          this.emailError = true;
        }
      );
    } else {
      this.showInvalidError = true;
    }
  }

  searchSites() {
    this.filteredSites = [];
    this.sitesForAccount.forEach((site) => {
      if(site.siteId.includes(this.siteSearch) || site.serviceAddress.streetAddress.toLowerCase().includes(this.siteSearch.toLowerCase())){
        this.filteredSites.push(site);
      }
    });
  }

  navigateTypeahead(down: boolean) {
    if(down && this.siteIndex < this.filteredSites.length-1){
      this.siteIndex++;
    } else if (!down && this.siteIndex > -1) {
      this.siteIndex--;
    }
  }

  selectSite(site: ISite, index?: number){
    if(this.filteredSites.length > 0){
      if(this.siteIndex == -1) {
        this.siteIndex = 0;
      }
      if(index) {
        this.selectedSite = this.filteredSites[index];
      } else {
        this.selectedSite = this.filteredSites[this.siteIndex];
      }
      this.siteSearch = this.selectedSite.siteId + ' ' + this.selectedSite.name;
      this.resetTypeahead();
    }
  }

  resetTypeahead() {
    this.siteIndex = -1;
    this.filteredSites = [];
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}
