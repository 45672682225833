import {PipeTransform, Pipe} from '@angular/core';
import {IBillDeliveryEdit, IBillDeliveryHistory} from '../entities/billDeliveryEdit';
import * as moment from 'moment';

@Pipe({
  name: 'billdeliveryhistoryPipe'
})
export class BillDeliveryHistoryPipe implements PipeTransform {

  transform(billDelivery: IBillDeliveryHistory[], term: string): IBillDeliveryHistory[] {
 
        term = term.toLowerCase().trim();
        let termMoment = moment(term);
        let filteredBillDelivery= [], billDeliveryPushed = false;
        billDelivery.forEach((billDelRow) => {
          billDeliveryPushed = false;
            for(let prop in billDelRow){
              if(billDelRow[prop]){
                  if(prop=="changedBy" && billDelRow[prop].toString().toLowerCase().match(term)){
                    filteredBillDelivery.push(billDelRow);
                    billDeliveryPushed = true;
                    break;
                  }
                  else if(prop==="caNumber" && billDelRow[prop].toString().toLowerCase().match(term)){
                    filteredBillDelivery.push(billDelRow);
                    billDeliveryPushed = true;
                    break;
                  } 
                  else if(prop==="siteId" && billDelRow[prop].toString().toLowerCase().match(term)){
                    filteredBillDelivery.push(billDelRow);
                    billDeliveryPushed = true;
                    break;
                  } 
              }
            }
        });
       return filteredBillDelivery;
  }

  getDateDisplay(dateString: string) {
    if(dateString){
      dateString = dateString.includes("T", 0) ? dateString : dateString + "T00:00:00";
      let date = new Date(dateString);
      return date.toLocaleDateString();
    }
  }
}
