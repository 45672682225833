import {PipeTransform, Pipe} from '@angular/core';
import {IBillDeliveryEdit} from '../entities/billDeliveryEdit';
import * as moment from 'moment';

@Pipe({
  name: 'billdeliveryeditPipe'
})
export class BillDeliveryEditPipe implements PipeTransform {

  transform(billDelivery: IBillDeliveryEdit[], term: string, electronicOption: string, paperOption: string): IBillDeliveryEdit[] {
 
        term = term.toLowerCase().trim();
        let termMoment = moment(term);
        let filteredBillDelivery= [], billDeliveryPushed = false;
        billDelivery.forEach((billDelRow) => {
          if(electronicOption==='ALL' || (electronicOption==='ENROLL' && billDelRow.eBillFlag==='X') || (electronicOption==='NOT ENROLLED' && billDelRow.eBillFlag===null)
          && (paperOption==='ALL' || (paperOption==='ENROLL' && billDelRow.paperBillFlag==='X') || (paperOption==='NOT ENROLLED' && billDelRow.paperBillFlag===null))
          )
          {
          billDeliveryPushed = false;
            for(let prop in billDelRow){
              if(billDelRow[prop]){
                  if(prop=="sites"){
                    billDelRow[prop].forEach((site) => {
                      for(let prop1 in site){
                        if(prop1==="siteId"){
                            console.log(site[prop1]);
                        }
                        if(prop1 === "serviceAddress" || prop1 ===  "billingAddress"){
                          let addr = (site[prop1].streetAddress + " " + site[prop1].attentionName + " " + site[prop1].streetAddress  + " " + site[prop1].city + " " + site[prop1].state + " " + site[prop1].zipCode).toLowerCase();
                          if(addr.match(term)) {
                            filteredBillDelivery.push(billDelRow);
                            billDeliveryPushed = true;
                            break;
                          }
                        }
                        else if((prop1==="siteId" || prop1==="contractAccountNum") && site[prop1].toString().toLowerCase().match(term)){
                          filteredBillDelivery.push(billDelRow);
                          billDeliveryPushed = true;
                          break;
                        }
                      }
                    });
                  }
                else if(prop==="caNumber" && billDelRow[prop].toString().toLowerCase().match(term)){
                    filteredBillDelivery.push(billDelRow);
                    billDeliveryPushed = true;
                    break;
                  } 
              }
            }
          }
        });
       
       return filteredBillDelivery;
  }

  getDateDisplay(dateString: string) {
    if(dateString){
      dateString = dateString.includes("T", 0) ? dateString : dateString + "T00:00:00";
      let date = new Date(dateString);
      return date.toLocaleDateString();
    }
  }
}
