import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {DataService} from "../../shared/data.service";
import {PortalService} from "../../shared/portal.service";
import { CreatePasswordService } from "./create-password.service"
import { IPasswordResetRequest } from '../../shared/entities/profile';
import {Subscription} from "rxjs";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {FadeAnimation} from "../../animations/fadeAnimation";
import * as Spinner from 'spin';

@Component({
  selector: 'app-reset-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
  animations: [FadeAnimation, FadeInAnimation]
})
export class CreatePasswordComponent implements OnInit {

  newPassword: string;
  confirmNewPassword: string;
  passwordResestKey: string;
  passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})(?!.*[!@#\$%\^&\*])");
  invalidPassword: boolean = false;
  passwordErrorMessage: string;
  routeSubscription: Subscription;
  createPasswordSubscription: Subscription;
  termsAccepted: boolean;
  loading: boolean = false;
  spinner: any;

  constructor(private createPasswordService: CreatePasswordService,
              private dataService: DataService,
              private portalService: PortalService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.dataService.setCreatePassword(true);
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.passwordResestKey = params['key'];
      // console.log("key: ",this.passwordResestKey);
    });

    this.dataService.loadingUpdated.subscribe(
      (loading) => {
        this.loading = loading;
        let opts = {
          lines: 12 // The number of lines to draw
          , length: 22 // The length of each line
          , width: 14 // The line thickness
          , radius: 32 // The radius of the inner circle
          , scale: 1 // Scales overall size of the spinner
          , corners: 0.1 // Corner roundness (0..1)
          , color: '#00AEEF' // #rgb or #rrggbb or array of colors
          , opacity: 0.25 // Opacity of the lines
          , rotate: 0 // The rotation offset
          , direction: 1 // 1: clockwise, -1: counterclockwise
          , speed: 1 // Rounds per second
          , trail: 60 // Afterglow percentage
          , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
          , zIndex: 1101 // The z-index (defaults to 2000000000)
          , className: 'spinner' // The CSS class to assign to the spinner
          , top: '100%' // Top position relative to parent
          , left: '100%' // Left position relative to parent
          , shadow: false // Whether to render a shadow
          , hwaccel: false // Whether to use hardware acceleration
          , position: 'absolute' // Element positioning
        };
        setTimeout(() => {
          let target = document.getElementById('spinner');
          this.spinner = new Spinner(opts).spin(target);
        }, 50);
      }
    );
  }

  ngOnDestroy() {
    // this.routeSubscription.unsubscribe();
    // this.createPasswordSubscription.unsubscribe();
  }

  getOriginUrl() : string{
    const local = "http://localhost:8880"
    const dev1 = "https://dev1-manage.nrg.com";
    const stg1 = "https://stg1-manage.nrg.com";
    const test1 = "https://test1-manage.nrg.com";
    const pd = "https://manage.nrg.com";
    // const isIE = window.navigator.userAgent.indexOf('Triden') > -1;
    // IE is special :)
    if (window.location.origin.indexOf("dev1-") > -1) {
      return dev1;
    } else if (window.location.origin.indexOf("stg1-") > -1) {
      return stg1;
    } else if (window.location.origin.indexOf("test1-") > -1) {
      return test1;
    } else if (window.location.origin.indexOf("manage.nrg.com") > -1) {
      return pd;
    }
    else {
      return local;
    }
  }

  changeTerms() {
    // console.log('changing terms. Terms accepted are ' + this.termsAccepted);
    this.portalService.acknowledge().subscribe();
    // this.termsAccepted = !this.termsAccepted;
  }

  createPassword(key?: string) {
    if(!this.termsAccepted) {
      return false;
    }
    let validation = this.portalService.validatePassword(this.newPassword, this.confirmNewPassword);
    if(validation.isValid){
        // console.log("Creating password");
        let passwordResetRequest:IPasswordResetRequest = {newPassword: this.newPassword, key: this.passwordResestKey, env: this.getOriginUrl()};
        this.createPasswordSubscription = this.createPasswordService.createPassword(passwordResetRequest).subscribe(
          (response) => {
            // console.log("response ",response);
            this.invalidPassword = false;
            this.dataService.setCreatePassword(false);
            this.dataService.setResetPassword(false);
            this.dataService.setLoggedIn(false);
            this.dataService.setForgotPassword(false);
            // this.router.navigate(['/']);
            this.dataService.setLoading(true);
            setTimeout(()=>{
              let appProps = this.dataService.getAppProperties();
              window.location.replace(this.getOriginUrl() + "/myaccount/login.html");
              this.dataService.setLoading(false);
            }, 3000)
          }
        );
    } else {
      // console.log("Password validation failed");
      this.passwordErrorMessage = validation.error;
      this.invalidPassword = true;
    }
  }
}
