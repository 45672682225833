import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {FourcpService} from "./fourcp.service";
import {I4cpPreferences} from "../shared/entities/fourcp";
import {IUserAccount} from "../shared/entities/profile";
import {Subscription} from "rxjs";
import {Permissions} from "../shared/const/permissions";
import {PortalService} from "../shared/portal.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-fourcp',
  templateUrl: './fourcp.component.html',
  styleUrls: ['./fourcp.component.scss']
})
export class FourcpComponent implements OnInit {

  selectedTab: string = 'overview';
  myAccount: IUserAccount;
  preferences: I4cpPreferences;
  location: any;

  performanceFeatureToggle: boolean = false;
  preferencesFeatureToggle: boolean = true;

  accountSourceSubscription: Subscription;

  constructor(private dataService: DataService,
              private fourcpService: FourcpService,
              private router: Router,
              private portalService: PortalService) { }

  ngOnInit() {
    this.dataService.setTitleSource('4CP Dashboard');
    this.dataService.setSelectedNavItem('tools');
    this.setCurrentActivePage('tools')
    
    // If reloaded on tab
    this.location = window.location.href;
    if (!this.location.match('fourcp/overview')) {
      if (this.location.match('fourcp/alert-history')) {
        this.selectedTab = 'alert-history';
      } else if (this.location.match('fourcp/performance')) {
        this.selectedTab = 'performance';
      } else if (this.location.match('fourcp/preferences')) {
        this.selectedTab = 'preferences';
      } else if (this.location.match('fourcp/site-performance')){
        this.selectedTab = 'site-performance';
      } else if (this.location.match('fourcp/summary-performance')){
        this.selectedTab = 'summary-performance';
      }
    } 

    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getFourcpPreferences();
      }
    );

    this.myAccount = this.dataService.getAccountSource();
    if(this.myAccount){
      this.getFourcpPreferences();
    }
  }

  ngOnDestroy() {
    if(this.accountSourceSubscription) {
      this.accountSourceSubscription.unsubscribe();
    }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  getFourcpPreferences(){
    this.fourcpService.get4cpPreferences(this.myAccount.id).subscribe((preferences : any) => {
      this.preferences = preferences;
      this.dataService.setFourcpPreferences(preferences);
    }, err => {
        this.preferences = {
          subscriptionType: 'BASIC'
        };
        this.dataService.setFourcpPreferences(this.preferences);
    });
  }

  switchTabs(selectedTab: string){
    this.selectedTab = selectedTab;
    this.router.navigate(['/fourcp/' + selectedTab]);
  }

}
