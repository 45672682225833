import { Component, OnInit } from '@angular/core';
import {IntervalUsageReportService} from '../interval-usage-report.service';
import {IIntervalSite} from '../../shared/entities/site';
import {GenericPipe} from '../../shared/pipe/generic.pipe';
import {PortalService} from '../../shared/portal.service';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {IHelpStep} from '../../shared/entities/contextualHelp';
import {Subscription} from 'rxjs';
import {DataService} from '../../shared/data.service';

@Component({
  selector: 'app-choose-settings',
  templateUrl: './choose-settings.component.html',
  styleUrls: ['choose-settings.component.scss'],
  providers: [GenericPipe]
})
export class ChooseSettingsComponent implements OnInit {

  selectedSites: IIntervalSite[];
  filteredSites: IIntervalSite[];
  searchTerm: string;
  showSearchRow = false;
  selectedDataInterval = '15 min';
  selectedKwType = true;
  availableFromDate: string;
  availableToDate: string;
  minStartDate: Date;
  maxEndDate: Date;
  selectedStartDate: Date;
  selectedEndDate: Date;
  showAvailableDates = true;
  hideModal = false;
  validDateRange = true;
  helpStep: IHelpStep;
  maskMode: boolean;

  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private service: IntervalUsageReportService,
              private pipe: GenericPipe,
              private portalService: PortalService,
              private router: Router,
              private dataService: DataService) { }

  ngOnInit() {
    this.service.setStepReached(2);
    this.selectedSites = this.service.selectedSites;
    this.minStartDate = moment(new Date()).subtract(3, 'years').toDate();
    this.maxEndDate = moment(new Date()).toDate();

    if (this.selectedSites.length > 0){
      this.loadSelectedSites();
    } else {
      this.router.navigate(['/interval-usage-report/select-sites']);
    }

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });
    this.selectKwType(true);
  }

  ngOnDestroy() {
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  loadSelectedSites() {
    this.filterSites();
    this.getDefaultRadio();
    this.getAvailableDates();
  }

  filterSites(){
    if (!this.searchTerm){
      this.filteredSites = this.selectedSites;
    } else {
      this.filteredSites = this.pipe.transform(this.selectedSites, this.searchTerm);
    }
  }

  getDefaultRadio() {
    let quarterHourly = true;
    this.selectedSites.forEach((site) => {
      if (site.intervalData.toLowerCase().match('hour')){
        quarterHourly = false;
        this.selectDataInterval('Hourly');
      }
    });
    if (quarterHourly){
      this.hideModal = true;
      this.selectDataInterval('Quarter-Hourly');
    }
  }

  getAvailableDates() {
    let availableFromDate = this.selectedSites[0].usageStartDate;
    this.minStartDate = moment(availableFromDate).toDate();
    let availableToDate = this.selectedSites[0].usageEndDate;
    this.maxEndDate = moment(availableToDate).toDate();
    this.selectedSites.forEach((site) => {
      if (moment(site.usageStartDate).isAfter(availableFromDate)){
        availableFromDate = site.usageStartDate;
      } else if (moment(site.usageStartDate).isBefore(this.minStartDate)){
        this.minStartDate = moment(site.usageStartDate).toDate();
      }
      if (moment(site.usageEndDate).isBefore(availableToDate)){
        availableToDate = site.usageEndDate;
      } else if (moment(site.usageEndDate).isAfter(this.maxEndDate)) {
        this.maxEndDate = moment(site.usageEndDate).toDate();
      }
    });
    // Every good and perfect gift is from above, coming down from the Father of lights, who does not change like shifting shadows. -James 1:17
  }

  datesUpdated() {
    if (this.selectedStartDate){
      this.service.setStartDate(moment(this.selectedStartDate).format('YYYY-MM-DD'));
    } else {
      this.service.setStartDate(undefined);
    }
    if (this.selectedEndDate) {
      this.service.setEndDate(moment(this.selectedEndDate).format('YYYY-MM-DD'));
    } else {
      this.service.setStartDate(undefined);
    }
    if (this.selectedStartDate && this.selectedEndDate && moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))){
      this.validDateRange = false;
    } else {
      this.validDateRange = true;
    }
  }

  getFakeSiteId(site: any) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeStreetAddress(site: any) {
    return this.portalService.getFakeStreetAddress(site);
  }

  getFakeCity(site: any) {
    return this.portalService.getFakeCity(site);
  }

  getFakeZipCode(site: any) {
    return this.portalService.getFakeZipCode(site);
  }

  getDateDisplay(dateString: string){
    return this.portalService.getDateDisplay(dateString);
  }

  selectDataInterval(interval: string){
    this.selectedDataInterval = interval;
    this.service.setDataInterval(interval);
  }

  selectKwType(kwType: boolean){
    this.selectedKwType = kwType;
    this.service.setKwType(kwType);
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterSites();
  }

  getSiteIdDisplay(siteId: string) { return this.portalService.getSiteIdDisplay(siteId); }
}
