
import { Component, OnInit } from '@angular/core';
import {PortalService} from "../../../shared/portal.service";
import {PeriodTypes, Hours, I4cpROC, I4cpAlert, I4cpMTDData} from "../../../shared/entities/fourcp";
import * as moment from 'moment';
import {FadeAnimation} from "../../../animations/fadeAnimation";
import {FourcpService} from "../../fourcp.service";
import {ErcotMarketSources, IMarketData} from "../../../shared/entities/markets";
import {MarketsService} from "../../../markets/markets.service";
import {Subscription} from "rxjs";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {DataService} from "../../../shared/data.service";
import {IHelpStep} from "../../../shared/entities/contextualHelp";
declare var Highcharts: any;
declare var $:any;

@Component({
  selector: 'app-fourcp-demand',
  templateUrl: './fourcp-demand.component.html',
  animations: [FadeAnimation],
  styleUrls: ['../../fourcp.component.scss', 'fourcp-demand.component.scss']
})
export class FourcpDemandComponent implements OnInit {

  showTimeSelection: boolean;
  selectedPeriodType: string;
  periodTypes: any;
  selectedStartTime: any;
  selectedEndTime: any;
  hours: any[];
  actualLoad: IMarketData;
  forecastLoad: IMarketData;
  overallMarketLoad: string;
  lastUpdatedDate: string;
  actualLoadPoints: any;
  forecastLoadPoints: any;
  tempTimeSelection: any;
  mtdData: I4cpMTDData;
  invalidTimeSelection: boolean;
  ROC: I4cpROC;
  yAxisMin: number;
  yAxisMax: number;
  helpStep: IHelpStep;

  alert: I4cpAlert;
  alertStart: number;
  alertEnd: number;
  showYesterday: boolean;
  yesterdayAlert: I4cpAlert;
  yesterdayDisplayDate: string;
  todayDisplayDate: string;

  alertSubscription: Subscription;
  refreshSubscription: Subscription;
  preferencesSubscription: Subscription;
  helpStepSubscription: Subscription;

  constructor(private portalService: PortalService,
              private fourcpService: FourcpService,
              private marketsService: MarketsService,
              private dataService: DataService) { }

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        numericSymbols: null
      },
      global : {
        useUTC : false
      }
    });

    this.hours = Hours;
    this.periodTypes = PeriodTypes;
    this.getDefaultPeriodType();
    this.alert = this.fourcpService.alert;
    if(this.alert){
      this.getAlertFrame();
    }
    this.alertSubscription = this.fourcpService.alertUpdated.subscribe((alert) => {
      this.alert = alert;
      this.getAlertFrame();
      this.periodTypeSelected();
      this.get4cpMarketData();
    });

    this.periodTypeSelected();
    this.get4cpMarketData();

    this.refreshSubscription = IntervalObservable.create(60000).subscribe(() => {
      // Refresh every 5 min
      this.get4cpMarketData();
    });

    if(this.dataService.getFourcpPreferences()){
      this.getYesterdayAlert();
    }

    this.preferencesSubscription = this.dataService.fourcpPreferencesUpdated.subscribe(() =>{
      this.getYesterdayAlert();
    });

    this.yesterdayDisplayDate = moment().subtract(1, 'days').format('ddd MM/DD/YY');
    this.todayDisplayDate = moment().format('ddd, MM/DD/YY');

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });

  }

  ngOnDestroy() {
    if (this.alertSubscription) this.alertSubscription.unsubscribe();
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    if (this.preferencesSubscription) this.preferencesSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  getYesterdayAlert() {
    let yesterdayGregDate = moment().subtract(1, 'days').format('DDMMMYYYY');
    this.fourcpService.get4cpAlert(yesterdayGregDate).subscribe((alert : any) => {
      this.yesterdayAlert = alert;
    });
  }

  getDefaultPeriodType() {
    if(moment().hour() > 13 || (moment().hour() == 13 && moment().minute() > 15)){
      this.selectedPeriodType = PeriodTypes.Afternoon;
    } else {
      this.selectedPeriodType = PeriodTypes.AllDay;
    }
  }

  getAlertFrame() {
    this.alertStart = null;
    this.alertEnd = null;
    let now = moment().second(0).millisecond(0);
    let alert = Object.assign({}, this.alert);
    if(this.showYesterday){
      now = now.subtract(1, 'days');
      alert = Object.assign({}, this.yesterdayAlert);
    }
    if(alert.alertLikelyhood != 'LOW' && alert.alertPeriodStart){
      let startHour = parseInt(moment(alert.alertPeriodStart).format('H'));
      let startMinute = parseInt(moment(alert.alertPeriodStart).format('mm'));
      let endHour = parseInt(moment(alert.alertPeriodEnd).format('H'));
      let endMinute = parseInt(moment(alert.alertPeriodEnd).format('mm'));
      this.alertStart = now.hour(startHour).minute(startMinute).toDate().getTime();
      this.alertEnd = now.hour(endHour).minute(endMinute).toDate().getTime();

    }
  }

  getMarketLoad() {
    this.marketsService.getLatestMarketsDashboard().subscribe((response : any) => {
      response.sources.forEach((location) => {
        if (location.source == 'ACTUAL_LOAD') {
          this.overallMarketLoad = this.numberWithCommas(location.values[0].toLocaleString());
          this.lastUpdatedDate = moment(location.dates[0]).format('MM/DD/YY HH:mm') + ' CST';
        }
      });
    });
  }

  /*
    For by the grace given to me I say to everyone among you not to think of himself more highly than he ought to think,
    but to think with sober judgment, each according to the measure of faith that God has assigned. -Romans 12:3
  */

  toggleTimeSelection(){
    this.showTimeSelection = !this.showTimeSelection;
    if(this.showTimeSelection){
      this.setTempTimeSelection();
    }
  }

  setTempTimeSelection() {
    this.tempTimeSelection = {
      selectedPeriodType: this.selectedPeriodType,
      selectedStartTime: this.selectedStartTime,
      selectedEndTime: this.selectedEndTime
    }
  }

  cancelTimeSelection() {
    this.selectedPeriodType = this.tempTimeSelection.selectedPeriodType;
    this.selectedStartTime = this.tempTimeSelection.selectedStartTime;
    this.selectedEndTime = this.tempTimeSelection.selectedEndTime;
    this.invalidTimeSelection = false;
    this.toggleTimeSelection();
  }

  applyTimeSelection() {
    if((this.selectedEndTime && this.selectedStartTime && this.selectedEndTime.value < this.selectedStartTime.value) || !this.selectedEndTime || !this.selectedStartTime){
      this.invalidTimeSelection = true;
    }
    if(!this.invalidTimeSelection){
      this.get4cpMarketData();
      this.toggleTimeSelection();
    }
  }

  get4cpMarketData() {
    let now = moment().minute(0);
    if(this.showYesterday){
      now = now.subtract(1, 'days');
    }
    let startString = now.hour(this.selectedStartTime.value).format('YYYY-MM-DD HH:mm');
    let endString = now.hour(this.selectedEndTime.value).format('YYYY-MM-DD HH:mm');
    this.fourcpService.get4cpMarketData(ErcotMarketSources.ERCOTActualLoad, startString, endString).subscribe((marketData : any) => {
      this.actualLoad = marketData;
      this.fourcpService.get4cpMarketData(ErcotMarketSources.ERCOTForecastLoad, startString, endString).subscribe((marketData : any) => {
        this.forecastLoad = marketData;
        this.extractChartData();
        this.createChart();
      });
    });
    this.fourcpService.get4cpROC().subscribe((ROC : any) => {
      this.ROC = ROC;
    });
    this.getMarketLoad();
    this.getMTDData();
  }

  getMTDData() {
    this.fourcpService.get4cpMTDData().subscribe((mtdData : any) => {
      this.mtdData = mtdData;
    });
  }

  selectStartTime(time: any) {
    this.selectedStartTime = time;
    if(this.selectedEndTime && this.selectedEndTime.value > this.selectedStartTime.value){
      this.invalidTimeSelection = false;
    }
  }

  selectEndTime(time: any){
    this.invalidTimeSelection = false;
    this.selectedEndTime = time;
    if(this.selectedStartTime && this.selectedEndTime.value > this.selectedStartTime.value){
      this.invalidTimeSelection = false;
    }
  }

  periodTypeSelected(){
    if(this.selectedPeriodType == this.periodTypes.AllDay){
      this.selectedStartTime = this.hours[0];
      this.selectedEndTime = this.hours[24];
    } else if (this.selectedPeriodType == this.periodTypes.Afternoon){
      this.selectedStartTime = this.hours[13];
      this.selectedEndTime = this.hours[19];
    }
  }

  numberWithCommas(x) { return this.portalService.numberWithCommas(x); }

  extractChartData() {
    this.actualLoadPoints = [];
    this.forecastLoadPoints = [];
    let point = {};
    for (let i = 0; i < this.actualLoad.sources[0].values.length; i++) {
      point = {
        x: moment(this.actualLoad.sources[0].dates[i]).toDate().getTime(),
        y: this.actualLoad.sources[0].values[i]
      };
      this.actualLoadPoints.push(point);
    }
    for (let i = 0; i < this.forecastLoad.sources[0].values.length; i++) {
      point = {
        x: moment(this.forecastLoad.sources[0].dates[i]).toDate().getTime(),
        y: this.forecastLoad.sources[0].values[i]
      };
      this.forecastLoadPoints.push(point);
    }

    if(this.actualLoadPoints && this.actualLoadPoints.length > 0){
      this.yAxisMin = this.actualLoadPoints[0].y;
    }
    this.actualLoadPoints.forEach((point) => {
      if(point.y < this.yAxisMin){
        this.yAxisMin = point.y;
      }
    });
    this.forecastLoadPoints.forEach((point) => {
      if(point.y < this.yAxisMin){
        this.yAxisMin = point.y;
      }
    });
    this.yAxisMax = this.mtdData.high5YearMtd;
    this.actualLoadPoints.forEach((point) => {
      if(point.y > this.yAxisMax){
        this.yAxisMax = point.y;
      }
    });
    this.forecastLoadPoints.forEach((point) => {
      if(point.y > this.yAxisMax){
        this.yAxisMax = point.y;
      }
    });
    if(this.mtdData.mtdMaxLoad > this.yAxisMax){
      this.yAxisMax = this.mtdData.mtdMaxLoad;
    }
  }

  createChart() {
    Highcharts.chart('demandGraph', {
      title: {
        text: ''
      },
      exporting: { enabled: false },
      yAxis : {
        max: this.yAxisMax,
        min: this.yAxisMin,
        title: {text: 'DEMAND (MW)'},
        plotBands: [{
          from: this.mtdData.low5YearMtd,
          to: this.mtdData.high5YearMtd
        }],
        plotLines: [{
          width: 2,
          value: this.mtdData.mtdMaxLoad,
          dashStyle: 'Dash'
        }]
      },
      xAxis : {
        type: 'datetime',
        plotBands: [{
          from: this.alertStart,
          to: this.alertEnd
        }]
      },
      formatter: function() {
        var s = '<b>'+ moment(this.x).format('ddd, MMM D HH:mm') +'</b>';
        $.each(this.points, function(i, point) {
          let myColor = '';
          if(point.series.name == 'Actual Load'){
            myColor = '#00AEEF'
          } else {
            myColor = '#439539'
          }
          s += '<br/><span style="color:' + myColor + '">\u25CF</span> '+ point.series.name +': ';
          s += point.y.toFixed(2);
        });
        return s;
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Actual Load',
        showInLegend: false,
        data: this.actualLoadPoints
      }, {
        name: 'Forecast Load',
        showInLegend: false,
        data: this.forecastLoadPoints
      }],
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      }
    });
  }

  toggleYesterday() {
    this.showYesterday = !this.showYesterday;
    this.getAlertFrame();
    if(this.showYesterday){
      this.selectedPeriodType = this.periodTypes.AllDay;
    } else {
      this.getDefaultPeriodType();
    }
    this.periodTypeSelected();
    this.get4cpMarketData();
  }
}
