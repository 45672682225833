import {Injectable, EventEmitter} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {IPaymentMethod, IPaymentSettingsRequest,
  IPaymentSubmission, IPaymentResponse, PaymentSettingsRequest, IPCIReturn, IPCIValidateReturn, IAccountBalance,
  IAccountBalanceRequest} from '../payments/payments';
import {
  IAutoPaymentMethod, IAutoPaySites,  IPaymentSchedule, IAutoPaymentSubmission, IAutoPaymentResponse
} from "./autopayments";
import {ISite} from "../shared/entities/site";
import {HttpHeaders, HttpClient, HttpResponse} from "@angular/common/http";
import {Response, Http, Headers} from "@angular/http";
import {IInvoice} from "../shared/entities/bill-reports";

@Injectable()
export class AutoPaymentsService {

  headers: HttpHeaders;
  oldHeaders: Headers;
  autopay: IAutoPaySites;
  allautopay: IAutoPaySites[];
  // Bindables
  stepReached: string;
  actionSel: string;
  paymentTotal: number;
  submitted: boolean = false;
  payByAccount = false;
  hasNCCA = false;
  hasNCA = false;
  paymentSchedule: IPaymentSchedule;

  // EventEmitters
  onStepReached: EventEmitter<string> = new EventEmitter<string>();
  autopayUpdated: EventEmitter<IAutoPaySites> = new EventEmitter<IAutoPaySites>();
  paymentScheduleUpdated: EventEmitter<IPaymentSchedule> = new EventEmitter<IPaymentSchedule>();

  caSelected : EventEmitter<boolean> = new EventEmitter<boolean>();


  
  pciChangeCalledBack: EventEmitter<IPCIReturn> = new EventEmitter<IPCIReturn>();
  pciEditCancelCalledBack: EventEmitter<IPCIReturn> = new EventEmitter<IPCIReturn>();
  pciCreateCalledBack: EventEmitter<IPCIValidateReturn> = new EventEmitter<IPCIValidateReturn>();

  constructor(private http: HttpClient, private oldHttp: Http) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.oldHeaders = new Headers({'Content-Type': 'application/json'});
  }

  setStepReached(step: string) {
    this.stepReached = step;
    this.onStepReached.emit(step);
  }

  setCaSelected(sel : boolean){
    this.caSelected.emit(true);
  }
  
  setActionSel(action: string) {
    this.actionSel = action;
  }

  getActionSel() {
    return this.actionSel;
  }

  getAutoPayments(){
    return this.autopay;
  }

  setAutoPayments(autopay: any) {
    this.autopay = autopay;
    this.autopayUpdated.emit(autopay);
  }

  getAllAutopay(){
    return this.allautopay;
  }

  setAllAutopay(allautopay: IAutoPaySites[]){
    this.allautopay = allautopay
  }

  setPaymentTotal(total: number){
    this.paymentTotal = total;
  }

  setSubmitted(submitted: boolean) {
    this.submitted = submitted;
  }

  setNCCA(hasNCCA: boolean) {
    this.hasNCCA = hasNCCA;
  }

  getNCCA() {
    return this.hasNCCA;
  }
  setNCA(hasNCA: boolean) {
      this.hasNCA = hasNCA;
    }

    getNCA() {
      return this.hasNCA;
    }

  getAutopayList(accountId): Observable<HttpResponse<IAutoPaySites[]>> {
    let url = environment.production ? environment.autopayForAccountUrl.replace('{accountId}', accountId) : environment.autopayForAccountUrl;
    if(environment.production){
      return this.http
        .get(url)
        .map((response: HttpResponse<IAutoPaySites[]>) => response)
        .catch(this.handleError);
    } else {
      return this.http
        .get(url)
        .map((response: HttpResponse<IAutoPaySites[]>) => response)
        .catch(this.handleError);
    }
  }

  setPaymentSchedule(paymentSchedule: IPaymentSchedule) {
    this.paymentSchedule = paymentSchedule;
    this.paymentScheduleUpdated.emit(paymentSchedule);
  }

  getPaymentSchedule(): IPaymentSchedule {
    return this.paymentSchedule;
  }

  getBalanceDue(request: IAccountBalanceRequest): Observable<HttpResponse<IAccountBalance>> {
    let url = environment.balanceForAccountUrl.replace('{accountId}', request.accountId);
    //delete: let url = environment.autopayForAccountUrl.replace('{accountId}', request.accountId);
    if(environment.production){
      return this.http
        .post(url, request, {headers: this.headers})
        .map((response: HttpResponse<IAccountBalance>) => response)
        .catch(this.handleError);
    } else {
      return this.http
        .get(url, {headers: this.headers})
        .map((response: HttpResponse<IAccountBalance>) => response)
        .catch(this.handleError);
    }

  }

  getBalanceDueDates(request: IAccountBalanceRequest): Observable<HttpResponse<IAccountBalance>> {
    let url = environment.balanceDatesForAccountUrl.replace('{accountId}', request.accountId);
    if(environment.production){
      return this.http
        .post(url, request, {headers: this.headers})
        .map((response: HttpResponse<IAccountBalance>) => response)
        .catch(this.handleError);
    } else {
      return this.http
        .get(url, {headers: this.headers})
        .map((response: HttpResponse<IAccountBalance>) => response)
        .catch(this.handleError);
    }
  }

  getPaymentMethods(accountId: string): any {
    let url = environment.getPaymentMethodsUrl.replace('{accountId}', accountId);
    return this.http
      .get(url)
      .map((response: HttpResponse<IPaymentMethod[]>) => response)
      .catch(this.handleError);
  }

  submitAutoPayment(request: IAutoPaymentSubmission) : Observable<HttpResponse<IAutoPaymentResponse>> {
      let url = environment.autopayEnroll.replace('{accountId}', request.accountId);
      return this.http
        .post(url, request, {headers: this.headers})
        .map((response: HttpResponse<IAutoPaymentResponse>) => response)
        .catch(this.handleError);
  }

  cancelAutoPayment(caNumber: string, accountId: string) : Observable<HttpResponse<IAutoPaymentResponse>> {
    let url = environment.autopayCancel.replace('{accountId}', accountId);
    let customRequest = {};
    customRequest = {
        caNumber : caNumber
      }
    
    return this.http
      .post(url, customRequest)
      .map((response: HttpResponse<IAutoPaymentResponse>) => response)
      .catch(this.handleError);
  }


  createPaymentMethod(paymentMethod: IPaymentMethod): Observable<HttpResponse<IPaymentMethod>> {
    let url = environment.paymentMethodsUrl;
    let request: IPaymentSettingsRequest = {
      defaultPayment: paymentMethod.defaultPayment,
      maskedAccountNumber: Number(paymentMethod.userPaymentSettingsKey.paymentToken.slice(-3)),
      nickName: paymentMethod.nickName,
      paymentToken: paymentMethod.userPaymentSettingsKey.paymentToken,
      routingNumber: paymentMethod.routingNumber,
      oldPaymentToken: paymentMethod.oldPaymentToken,
      accountId: paymentMethod.userPaymentSettingsKey.accountId,
      maskedCardNumber: paymentMethod.maskedCardNumber,
      cardExpiryMonth: paymentMethod.cardExpiryMonth,
      cardExpiryYear: paymentMethod.cardExpiryYear
    };
    return this.http
      .post(url, request)
      .map((response: HttpResponse<IPaymentMethod>) => response)
      .catch(this.handleError);
  }

  savePaymentNickname(method: IPaymentMethod) {
    console.log(">>>> savePaymentNickname ", method);
    let request: IPaymentSettingsRequest = {
      defaultPayment: method.defaultPayment,
      maskedAccountNumber: method.maskedAccountNumber,
      nickName: method.nickName,
      paymentToken: method.userPaymentSettingsKey.paymentToken,
      routingNumber: method.routingNumber,
      oldPaymentToken: method.oldPaymentToken,
      accountId: method.userPaymentSettingsKey.accountId,
      maskedCardNumber: method.maskedCardNumber,
      cardExpiryMonth: method.cardExpiryMonth,
      cardExpiryYear: method.cardExpiryYear
    };
    
    let url = environment.savePaymentNicknameUrl.replace('{accountId}', request.accountId);
    return this.http
      .post(url, request, {headers: this.headers})
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

deletePaymentMethod(method: IPaymentMethod) {
    let request = new PaymentSettingsRequest(method);
    let url = environment.deletePaymentMethodsUrl.replace('{accountId}', request.accountId);

    return this.http
      .post(url, request, {headers: this.headers})
      .map((response: Response) => <any>response)
      .catch(this.handleError);
  }

  public pciEditCallback(info: any) {
    console.log("Made it back from PCI, returning token and account to finish edit");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        info.paymentMethods = methods;
        this.pciChangeCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI edit callback");
      }
    )
  }

  public pciEditCancel(info: any) {
    console.log("Updating state after cancelling method edit");
    this.getPaymentMethods(info.accountId).subscribe(
      (methods) => {
        info.paymentMethods = methods;
        this.pciEditCancelCalledBack.emit(info);
      }, err => {
        console.error("Couldn't refresh the methods after PCI callback");
      }
    )
  }

  public pciCreateCallback(validate: IPCIValidateReturn) {
    console.log("Made it back from PCI, need to temporarily store payment method after validate");
    this.pciCreateCalledBack.emit(validate);
  }

  public pciCreatePaymentMethod(request : IPaymentMethod, isCancelled : string,
    rememberPayment : string, login : string){
    let url = environment.pciPaymentMethodsUrl.replace('{accountId}',request.userPaymentSettingsKey.accountId);

    //make conversion to new post format
    let customRequest = {};
    if(request.paymentType == "CARD"){
      customRequest = {
        ccNumber : request.userPaymentSettingsKey.paymentToken,
        accountId : request.userPaymentSettingsKey.accountId,
        login : login,
        nickName : request.nickName,
        paymentType : request.paymentType,
        bankRoutingNumber : request.routingNumber,
        cardBrand : request.cardBrand,
        expMonth : request.cardExpiryMonth,
        expYear : request.cardExpiryYear,
        zipcode : request.cardZipCode,
        isCancelled : isCancelled,
        rememberPayment : rememberPayment
      }
    }
      else{
        customRequest = {
          bankAccountNumber : request.userPaymentSettingsKey.paymentToken,
          accountId : request.userPaymentSettingsKey.accountId,
          login : login,
          nickName : request.nickName,
          paymentType : request.paymentType,
          bankRoutingNumber : request.routingNumber,
          cardBrand : request.cardBrand,
          expMonth : request.cardExpiryMonth,
          expYear : request.cardExpiryYear,
          zipcode : request.cardZipCode,
          isCancelled : isCancelled,
          rememberPayment : rememberPayment,
        }
      }
    //post
    return this.http
      .post(url, customRequest)
      .map((response: HttpResponse<IPaymentMethod>) => response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
}
