import { Component, OnInit } from '@angular/core';
import {I4cpSummaryPerformance} from "../../shared/entities/fourcp";
import {FadeAnimation} from "../../animations/fadeAnimation";
import {FadeInAnimation} from "../../animations/fadeInAnimation";
import {DataService} from "../../shared/data.service";
import {FourcpService} from "../fourcp.service";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {GenericPipe} from "../../shared/pipe/generic.pipe";
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {PortalService} from "../../shared/portal.service";
import {IUserAccount} from "../../shared/entities/profile";
import {IFourcpSite} from "../../shared/entities/site";

@Component({
  selector: 'app-fourcp-summary-performance',
  templateUrl: './fourcp-summary-performance.component.html',
  styleUrls: ['./fourcp-summary-performance.component.scss'],
  animations: [FadeAnimation, FadeInAnimation],
  providers: [GenericPipe, OrderByPipe]
})
export class FourcpSummaryPerformanceComponent implements OnInit {

  summaryPerformance: I4cpSummaryPerformance;
  selectedYear: number;
  showYearSelector: boolean;
  yearOptions: number[] = [];
  selectedCustomer: IUserAccount;
  dataError: boolean = false;
  dataErrorText: string = '';
  
  accountSourceSubscription: Subscription;
  constructor(private dataService: DataService,
              private fourcpService: FourcpService,
              private portalService: PortalService,
              private genericPipe: GenericPipe,
              private orderByPipe: OrderByPipe) { }
  ngOnInit() {

   this.loadYearOptions();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
          (account) => {
            this.dataService.setLoading(true);
            this.selectedCustomer = account;
            this.loadSummaryPerformance();
          }
        );
    this.selectedCustomer = this.dataService.getAccountSource();
    if(this.selectedCustomer){
      this.loadSummaryPerformance();
    }
  }

  ngOnDestroy() {
    if(this.accountSourceSubscription){
      this.accountSourceSubscription.unsubscribe();
    }
  }

  loadSummaryPerformance(){
    this.dataService.setLoading(true);
    this.fourcpService.loadSummaryPerformance(this.selectedCustomer.id, this.selectedYear).subscribe(
      (response : any) => {
        this.summaryPerformance = response;
        this.formatSummaryPerformance();
        this.sortSitePerformance();
        this.dataService.setLoading(false);
      },
      (error) => {
        this.dataError = true;
        this.dataErrorText = "No data available for selection";
        this.dataService.setLoading(false);
      }
    );
  }

  formatSummaryPerformance() {
    if(this.summaryPerformance.sitePerformance[0] && this.summaryPerformance.sitePerformance[0] && this.summaryPerformance.sitePerformance[0].months.length == 0){
      this.dataError = true;
      this.dataErrorText = "No data available for selection";
    }
    else if (!this.checkSitesEnrolled()){
      this.dataError = true;
      this.dataErrorText = "No specific sites are enrolled in 4CP split savings services";
    }
    else {
      this.dataError = false;
      this.dataErrorText = "";
    }
  }

  checkSitesEnrolled(){
    let enrolled  = false;
    if(this.summaryPerformance && this.summaryPerformance.sitePerformance) {
      for (let site of this.summaryPerformance.sitePerformance) {
        //Per Dean 02/25/2020
        //else if (!this.sitePerformance || !this.sitePerformance.percentFactor){
        //if(site && site.percentFactor && site.percentFactor > 0) {
        if(site) {
          enrolled = true;
        }
      }
    }
    return enrolled;
  }

  sortSitePerformance() {
    this.summaryPerformance.sitePerformance[0].months = this.orderByPipe.transform(this.summaryPerformance.sitePerformance[0].months, 'intervalPeriod', true)
  }
  
  loadYearOptions() {
    let todayMoment = moment();

    let currentYear = parseInt(todayMoment.format('YYYY'));

    //Select default month and year
    let thisMonth = parseInt(todayMoment.format('M'));

    if(thisMonth >= 2){
      this.yearOptions.push((currentYear-1));
      currentYear = currentYear-1;
    }
    currentYear = currentYear-1;
    while(this.yearOptions.length <= 3 && currentYear > 2017){
      this.yearOptions.push(currentYear);
      currentYear = currentYear-1;
    }

    this.selectedYear = this.yearOptions[0];
  }

  selectYear(year: number){
    this.selectedYear = year;
    this.toggleYearSelector();
  }

  toggleYearSelector(){
    this.showYearSelector = !this.showYearSelector;
 }
 
 getNextYear() { return this.selectedYear + 1; }
}
