import {PipeTransform, Pipe} from '@angular/core';
import {ISite} from "../entities/site";
import {IBillingReportResponse} from "../entities/bill-reports";

@Pipe({
  name: 'billingReportPipe'
})
export class BillingReportPipe implements PipeTransform {

  transform(billingReport: IBillingReportResponse, term: string): IBillingReportResponse {
    let filteredReport = {
      totalCurrentCharges: billingReport.totalCurrentCharges,
      balanceDue: billingReport.balanceDue,
      invoiceCount: billingReport.invoiceCount,
      siteCount: billingReport.siteCount,
      categories: []
    };
    term = term.toLowerCase().trim();
    billingReport.categories.forEach((category) => {
      if(category.name.toLowerCase().match(term)){
        filteredReport.categories.push(category);
      } else {
        let sites = [];
        category.sites.forEach((site) => {
          if(site.siteId.toLowerCase().match(term)) {
            sites.push(site);
          } else {
            let invoices = [];
            site.invoices.forEach((invoice) => {
              for(let prop in invoice) {
                if(invoice[prop] && invoice[prop].toString().toLowerCase().match(term)){
                  invoices.push(invoice);
                  break;
                }
              }
            });
            if(invoices.length > 0){
              let filteredSite = Object.assign({}, site);
              filteredSite.invoices = invoices;
              sites.push(filteredSite);
            }
          }
        });
        if(sites.length > 0) {
          let filteredCategory = Object.assign({}, category);
          filteredCategory.sites = sites;
          filteredReport.categories.push(filteredCategory);
        }
      }
    });
    return filteredReport;
  }
}
