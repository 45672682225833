import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-amendments',
  templateUrl: './amendments.component.html',
})
export class AmendmentsComponent implements OnInit {

  accountSourceSubscription: Subscription;

  constructor(private dataService: DataService,
              private router: Router) {}

  ngOnInit() {
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.router.navigate(['/']);
    });
  }

  ngOnDestroy() {
    this.dataService.setSelectedAmendmentSite(undefined);
    this.dataService.setSelectedAmendmentRequest(undefined);
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
  }

}
