import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../shared/data.service';
import { PortalService } from '../shared/portal.service';
import { EditorialContentService } from './editorial-content.service';
import {Router} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-editorial-content',
  templateUrl: './editorial-content.component.html',
  styleUrls: ['./editorial-content.component.scss'],
})
export class EditorialContentComponent implements OnInit {
  layoutType: string;
  primaryComponents: any | null = null;
  secondaryComponents: any | null = null;
  headerComponents: any[] = [];

  constructor(
    private dataService: DataService,
    private portalService: PortalService,
    private editorialContentService: EditorialContentService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.dataService.setLoading(true);
    this.dataService.setTitleSource('Insights');
    this.dataService.setContextualHelp(false);
    this.dataService.setSelectedNavItem('dashboard');
    this.getContentPage();
  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(true);
  }

  sanitizeVideoLink(link: string) {
    let sanitizedURL = null;

    if(link.includes("/embed/")) {
      sanitizedURL = link;
    } else if(link.includes("watch?v=")) {
      let split = link.split("watch?v=");
      sanitizedURL = "https://www.youtube.com/embed/" + split.pop();
    } else {
      let split = link.split("/");
      sanitizedURL = "https://www.youtube.com/embed/" + split.pop();
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedURL);
  }

  getContentPage() {
    window.scrollTo(0, 0);
    const route = this.dataService.getEditorialContentRoute();

    if(route == null) {
      this.router.navigate(['/dashboard']);
    }

    let response = this.editorialContentService.getContentPage(route);

    if(response != null) {
      response.subscribe((contentResponse) => {
        this.layoutType = contentResponse.layoutType;
        if (contentResponse.primaryComponents !== undefined) {
          this.primaryComponents = contentResponse.primaryComponents;
        }
        if (contentResponse.secondaryComponents !== undefined) {
          this.secondaryComponents = contentResponse.secondaryComponents;
        }
        if (contentResponse.headerComponents && contentResponse.headerComponents.length) {
          this.headerComponents = contentResponse.headerComponents;
        }
        this.dataService.setLoading(false);
      });
    } else {
      this.dataService.setLoading(false);
      this.router.navigate(['/dashboard']);
    }
  }

  getVideoURL(path: string) {
    return path.slice(17); // gets id after url
  }
}
