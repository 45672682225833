import { Component, OnInit } from '@angular/core';
import {Authority} from '../../shared/const/authority';
import {DataService} from '../../shared/data.service';
import {ProfileService} from '../../profile/profile.service';
import {IUserDetails, IUserPermission,IProfile} from '../../shared/entities/profile';
import {IUserAccount} from '../../shared/entities/profile';
import {PortalService} from '../../shared/portal.service';
import {UserPermissionsPipe} from '../../shared/pipe/userpermissions.pipe';
import {Permissions} from '../../shared/const/permissions';
import {Subscription} from 'rxjs';
import {OrderByPipe} from '../../shared/pipe/orderBy.pipe';
import {FadeAnimation} from '../../animations/fadeAnimation';
import {FadeInAnimation} from '../../animations/fadeInAnimation';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
  animations: [FadeAnimation, FadeInAnimation],
  providers: [UserPermissionsPipe]
})
export class ManageUsersComponent implements OnInit {

  emailValidationPattern: String = "([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*]).([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])";
  emailFormControl = new FormControl('',[
  	Validators.required,
  	Validators.pattern("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*]).([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])")]
    ); 

  userPermissions: IUserPermission[];
  showThirdPartyLegend: boolean = false;
  showSearchRow: boolean = false;
  requiredError: boolean = false;
  searchTerm: string = '';
  filteredUserPermissions: IUserPermission[] = [];
  selectedAccount: IUserAccount;
  adding: boolean = false;
  userToDelete: IUserPermission;
  selectedUserAccount: IUserAccount;

  accessLevels = ['Admin', 'Full', 'View Only'];

  required = ['email', 'firstName', 'lastName', 'email', 'accessLevel', 'organization'];
  selectedUserAccountSourceSubscription: Subscription;
  phone: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  emailError: boolean = false;
  conflictError: boolean = false;
  emailValidError: boolean = false;
  editingAny: boolean;
  loading: boolean = true;
  // existUser: boolean = false;
  retrieveProfile: IProfile;
  profileExists: boolean = false;
  iconCheck: boolean = false;
  editingEmail: boolean = false;
  addFlag: boolean = false;
  editFlag: boolean = false;
  showPricingLegend: boolean = false;

  constructor(private dataService: DataService,
              private manageProfileService: ProfileService,
              private userPermissionsPipe: UserPermissionsPipe,
              private portalService: PortalService,
              private orderByPipe: OrderByPipe) { }

  ngOnInit() {
    // this.retrieveUser('super.user@nrg.com');
    this.portalService.spinBabySpin('spinner-manage-users-modal');
    const userAccount = this.dataService.getSelectedUserAccountSource();
    if (userAccount) {
      this.loadUsers(userAccount);
    }
    this.selectedUserAccountSourceSubscription = this.dataService.selectedUserAccountSourceUpdated.subscribe(
      (account) => {

        this.selectedUserAccount = account;
        if (this.selectedUserAccount.accessType === 'ThirdParty' && this.selectedUserAccount.pricingFlagEnabled) {
          this.showPricingLegend = true;
        } else {
          this.showPricingLegend = false;
        }
        // // console.log("INIT this.selectedUserAccount: ", this.selectedUserAccount);
        this.loadUsers(account);
      }
    );
  }

  ngOnDestroy() {
    if (this.selectedUserAccountSourceSubscription) {
      this.selectedUserAccountSourceSubscription.unsubscribe();
    }
  }

  loadUsers(selectedAccount: IUserAccount){
    this.selectedAccount = selectedAccount;
    // // console.log("LOADING USERS this.selectedAccount: ", this.selectedAccount);
    // // console.log("LOADING USERS this.selectedUserAccount: ", this.selectedUserAccount);
    this.initializeActivityLevelMenu(this.selectedUserAccount);

    this.manageProfileService.getUserPermissions(this.selectedAccount.id).subscribe(
      (userPermissions : any) => {
        this.loading = false;
        this.userPermissions = userPermissions;
        this.filterUsers();
        this.userPermissions.forEach((permission) => {
          permission.userProfile.showResend = moment()
              .format('YYYY-MM-DD') != moment(permission.userProfile.activateResendDate).format('YYYY-MM-DD');
        });
        this.dataService.setLoading(false);
      }, err => {
        console.error(err);
        this.manageProfileService.getUserPermissions(this.selectedAccount.id).subscribe(
          (userPermissions : any) => {
            this.loading = false;
            this.userPermissions = userPermissions;
            this.filterUsers();
          }, err => {
            console.error(err);
          }
        );
      }
    );
  }

  getUserAccess(user: IUserPermission) {
    if (user.userAccount.accessType === 'ThirdParty') {
      if (Authority.accessMap.has(user.userAccount.permissions)) {
        return Authority.accessMap.get(user.userAccount.permissions);
      }
    }
    return user.userAccount.accessLevel;
  }

  noUnderScores(activityLevel: string) {
    return activityLevel.replace('_', ' ');
  }

  initializeActivityLevelMenu(userAccount: IUserAccount) {
    if (userAccount && userAccount.accessType === 'ThirdParty') {
      if (userAccount.pricingFlagEnabled) {
        this.accessLevels = Array.from(Authority.accessMap.values());
      } else {
       this.accessLevels = ['Admin', 'Post Sale'];
      }
    } else {
      this.accessLevels = ['Admin', 'Full Access', 'View Only'];
    }
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterUsers();
  }

  filterUsers() {
    if (!this.searchTerm) {
      this.filteredUserPermissions = this.userPermissions;
    } else {
     this.filteredUserPermissions = this.userPermissionsPipe.transform(this.userPermissions, this.searchTerm);
    }
    this.filteredUserPermissions = this.orderByPipe.transform(this.filteredUserPermissions, 'userProfile.firstName', true);
  }

  editUser(user: IUserPermission) {
    this.editingAny = true;
    this.editFlag = true;
    user.userProfile.editing = true;
    user.temp = {
      userProfile: Object.assign({}, user.userProfile),
      userAccount: Object.assign({}, user.userAccount)
    };
    user.errors = [];

    // console.log('in Edituser ediflag',this.editFlag)
  }

  hasError(user: IUserPermission, error: string){
    let hasError = false;
    if (user.errors) {
      user.errors.forEach((err) => {
        if (err === error) {
          hasError = true;
        }
      });
    }
    return hasError;
  }


  retrieveUser(user: IUserPermission) {

    user.errors = [];
    this.emailValidError = false;
    if (!user.temp.userProfile.email  || this.emailFormControl.errors) {
      user.errors.push('email');
      this.emailValidError = true;
      return;
    } else {
          // // console.log('i am in the function',user.temp.userProfile.email);
          this.iconCheck = true;
          this.editingEmail = true;
          this.addFlag = true;
          this.manageProfileService.getProfileData(user.temp.userProfile.email).subscribe(
              (retrieveProfileData : any) => {
                  this.retrieveProfile = retrieveProfileData;
                  // console.log('retrivieprofile',this.retrieveProfile)
                  if (this.retrieveProfile == null) {
                    this.profileExists = false;
                    // // console.log('firstname',this.retrieveProfile.firstName);
                  } else {
                  // // console.log('i in else assignemnt');
                  this.profileExists = (this.retrieveProfile.firstName != null && this.retrieveProfile.firstName.length > 0);
                  if (this.profileExists) {
                    //  // console.log('inside profileexists');
                    user.temp.userProfile.firstName = this.retrieveProfile.firstName;
                    user.temp.userProfile.lastName = this.retrieveProfile.lastName;
                    user.temp.userProfile.organization = this.retrieveProfile.organization;
                    user.temp.userProfile.jobTitle = this.retrieveProfile.jobTitle;
                    user.temp.userProfile.phone = this.retrieveProfile.phone;
                      user.temp.userProfile.extension = this.retrieveProfile.extension;
                      user.temp.userProfile.mobilePhone = this.retrieveProfile.mobilePhone;
                      if (this.retrieveProfile.userAccounts && this.retrieveProfile.userAccounts.length > 0) {
                        user.temp.userAccount[0].accessLevel = this.retrieveProfile.userAccounts[0].accessLevel;
                      }
                  } else {
                        // console.log('i am in else before save');
                      // this.saveUser(user);
                  }
                }

                  //     || (contactInfo.customerRep != null && contactInfo.customerRep.length > 0);
                }, err => {
                  console.error(err);
                }
              );
        }
  }

  setPermissions(user: IUserPermission, accessLevel: string) {
    if (this.selectedAccount.accessType === 'ThirdParty') {
        user.temp.userAccount.accessLevel = Authority.accessToActivityMap.get(accessLevel);
        user.temp.userAccount.permissions = Authority.activityMap.get(Authority.accessToActivityMap.get(accessLevel));
    } else {
      user.temp.userAccount.accessLevel = accessLevel;
      user.temp.userAccount.permissions = Authority.activityMap.get(Authority.accessToActivityMap.get(accessLevel));
    }
  }

  saveUser(user: IUserPermission) {
    user.errors = [];
    this.required.forEach((required) => {
      // Validate
      console.log('required', required);
      if (!user.temp.userProfile[required] && !user.temp.userAccount[required]) {
        user.errors.push(required);
        console.log('inside required', required);
      }
    });

    // If no errors, proceed with save
    if (user.errors.length === 0) {
      this.adding = false;

      user.userAccount = user.temp.userAccount;
      user.userProfile = user.temp.userProfile;
      user.userProfile.editing = false;
      user.userProfile.email = user.userProfile.email.toLowerCase();
      this.requiredError = false;
    } else {
      this.requiredError = true;
      return;
    }
    this.getUserAccess(user);
    if (user.userAccount.id) {
      // ID exists, update existing object
      this.manageProfileService.updateUserPermissions(user).subscribe(
        (resp) => {
          // console.log(resp)
        }, err => {
          console.error(err);
        }
      )
    } else {
      // New object, assign ID and create
      this.dataService.setLoading(true);
      this.conflictError = false;
      user.userAccount.id = this.selectedUserAccount.id;
      // console.log("this.selectedUserAccount: ", this.selectedUserAccount);
      user.userAccount.accessType = this.selectedUserAccount.accessType;
      this.manageProfileService.createUserPermissions(user).subscribe(
        (resp) => {
          // console.log(resp);
          this.loadUsers(this.dataService.getSelectedUserAccountSource())
        }, err => {
          this.dataService.setLoading(false);
          if (err.status === 500) {
            console.error(err);
            this.emailError = true;
          } else if (err.status === 409) {
            this.conflictError = true;
          }
        }
      )
    }

    this.getEditingAny();
    this.dataService.triggerUsersUpdated();
  }

  getEditingAny() {
    this.editingAny = false;
    this.userPermissions.forEach((user) => {
      if (user.userProfile.editing) {
        this.editingAny = true;
      }
    });
  }

  deleteUser() {
    this.emailError = false;
    this.requiredError = false;
    this.manageProfileService.deleteUserPermission(this.userToDelete.userAccount.id, this.userToDelete.userAccount.userId).subscribe(
      (resp) => {
        let i;
        this.userPermissions.forEach((tableUser, index) => {
          if (this.userToDelete.userAccount.userId === tableUser.userAccount.userId) {
            i = index;
          }
        });
        this.userPermissions.splice(i, 1);
        this.filterUsers();
        // console.log(resp);
      }, err => {
        let i;
        this.userPermissions.forEach((tableUser, index) => {
          if (this.userToDelete.userAccount.userId === tableUser.userAccount.userId) {
            i = index;
          }
        });
        this.userPermissions.splice(i, 1);
        this.filterUsers();
        // console.log(err);
      }
    );
    this.dataService.triggerUsersUpdated();
  }

  // He was pierced for our transgressions; he was crushed for our iniquities;
  // upon him was the chastisement that brought us peace, and with his wounds we are healed. -Isaiah 53:5

  addNewUser() {
  this.iconCheck = false;
  this.editingEmail = false;
    const newUser: IUserPermission = {
      userAccount: {
        name: this.selectedAccount.name,
        accessLevel: '',
      }, userProfile: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        organization: '',
        jobTitle: '',
        editing: true
      }, errors: []
    };
    this.adding = true;
    this.editUser(newUser);
    const userTable = document.getElementById('usersTable');
    userTable.scrollTop = 0;
    this.userPermissions.unshift(newUser);
    this.filterUsers();
    this.addFlag = false;
    this.editFlag = false;
    // console.log('EdiFlag',this.editFlag);
  }

  undo(user: IUserPermission) {
    user.userProfile.editing = false;
    user.temp = {};
    user.errors = [];
    this.getEditingAny();
  }

  cancel() {
    this.filteredUserPermissions.splice(0, 1);
    this.adding = false;
    this.requiredError = false;
  }

  resend(user: IUserPermission) {
    if (!user.userProfile.activated) {
      user.userProfile.showResend = false;
      const req = Object.assign({}, user);
      delete req.userProfile.showResend;
      delete req.userProfile.editing;
      this.portalService.sendUserConfirmationEmail(req).subscribe(
        (resp) => {
          // console.log("Email resent");
      }, err => {
          if (err.status === 500) {
            this.emailError = true;
          }
      });
    }
  }

  closeModal() {
    this.adding = false;
    this.emailError = false;
    this.conflictError = false;
    this.requiredError = false;
  }

  getPhoneDisplay(phoneNum: string) {
    if (phoneNum) {
      return this.portalService.getPhoneDisplay(phoneNum);
    }
  }
}
