import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/data.service";
import {IAmendmentRequest, IAmendmentSite} from "../../shared/entities/amendments";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {AmendmentsService} from "../amendments.service";
import {FadeAnimation} from "../../animations/fadeAnimation";
import * as moment from 'moment';
import {OrderByPipe} from "../../shared/pipe/orderBy.pipe";
import {IUserAccount} from "../../shared/entities/profile";
import {AmendmentsAttachmentsService} from "../amendments-attachments.service";
import {IAttachment} from "../../shared/entities/attachment";
import {PortalService} from "../../shared/portal.service";
import { ISite } from '../../shared/entities/site';
declare var $: any;

@Component({
  selector: 'app-amendments-cart',
  templateUrl: './amendments-cart.component.html',
  animations: [FadeAnimation],
  styleUrls: ['./amendments-cart.component.scss', '../amendments.component.scss'],
  providers: [OrderByPipe]
})
export class AmendmentsCartComponent implements OnInit {

  myAccount: IUserAccount;
  selectedAmendmentRequest: IAmendmentRequest;
  hasSitesToAdd: boolean;
  hasSitesToDelete: boolean;
  ascending: boolean;
  sortBy: string = 'siteId';
  currentAttachments: IAttachment[];
  numberOfAttachments: number = 0;
  amendmentSiteToRemove: IAmendmentSite;
  isSubmitted: boolean = false;
  maskMode: boolean;
  maskModeSubscription: Subscription;
  selectedAmendmentSubscription: Subscription;
  accountSubscription: Subscription;
  exportSubscription: Subscription;
  currentAttachmentsSubscription: Subscription;

  constructor(private dataService: DataService,
              private router: Router,
              private amendmentsService: AmendmentsService,
              private orderByPipe: OrderByPipe,
              private attachmentService: AmendmentsAttachmentsService,
              private portalService: PortalService) { }

  ngOnInit() {
    this.loadData();
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      // this.getSites();
    });


  }

  loadData() {
    this.dataService.setTitleSource('Add/Delete Sites');
    this.dataService.setSelectedNavItem('accounts');

    this.myAccount = this.dataService.getAccountSource(); 

    this.accountSubscription = this.dataService.accountSourceUpdated.subscribe((account) => {
      this.goBack();
    });

    this.selectedAmendmentRequest = this.dataService.getSelectedAmendmentRequest();
    this.amendmentsService.getAmendmentRequest(this.myAccount.id, this.selectedAmendmentRequest.id).subscribe((savedRequest : any) => {
      this.selectedAmendmentRequest = savedRequest;
      this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    
    console.log('done with getAmendmentRequest', this.selectedAmendmentRequest);

    if(!this.selectedAmendmentRequest || !this.selectedAmendmentRequest.id){
      this.goBack();
    }
    else {
      this.attachmentService.getAttachmentsList(this.selectedAmendmentRequest.id).subscribe((attachmentList : any) => {
          this.currentAttachments = attachmentList;
          this.numberOfAttachments = attachmentList.length;
          this.dataService.setCurrentAttachments(attachmentList);

          this.currentAttachmentsSubscription = this.dataService.currentAttachmentsUpdated
            .subscribe(
              (attachmentList) => {
                this.currentAttachments = attachmentList;
                this.numberOfAttachments = attachmentList.length;
              }
            )
        }
      );
      this.isSubmitted = this.selectedAmendmentRequest.status.toLowerCase() != "draft";
    }

    this.selectedAmendmentSubscription = this.dataService.selectedAmendmentRequestUpdated.subscribe((selectedAmendmentRequest) => {
      this.selectedAmendmentRequest = selectedAmendmentRequest;
      this.formatAmendmentRequest();
    });

    this.formatAmendmentRequest();

    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {
      this.triggerExport();
    })

  });
  }

  ngOnDestroy() {
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.selectedAmendmentSubscription) this.selectedAmendmentSubscription.unsubscribe();
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
  }

  goBack(){
    this.router.navigate(['/amendments/home']);
  }

  goToCart(){
    this.loadData();
  }

  getSiteIdDisplay(siteId: string) {
    return this.portalService.getSiteIdDisplay(siteId);
  }

  triggerExport() {
    this.dataService.setLoading(true);
    this.amendmentsService.exportCart(this.selectedAmendmentRequest.id,this.maskMode);
  }

  formatAmendmentRequest(){
    this.hasSitesToAdd = false;
    this.hasSitesToDelete = false;
    if(this.selectedAmendmentRequest && this.selectedAmendmentRequest.amendmentSites) {
      this.selectedAmendmentRequest.amendmentSites.forEach((amendmentSite) => {
        amendmentSite.displayId = amendmentSite.siteId ? amendmentSite.siteId :'-------';
        amendmentSite.displayAddress = this.formatDisplayAddress(amendmentSite);
        amendmentSite.requestDateFormatted = this.formatRequestDate(amendmentSite);
        if (amendmentSite.amendmentType.toLowerCase() == 'add') {
          this.hasSitesToAdd = true;
        }
        else if (amendmentSite.amendmentType.toLowerCase() == 'delete') {
          this.hasSitesToDelete = true;
        }
        if(!amendmentSite.products) {
          amendmentSite.complete = false;
        }
      });
      this.amendmentsService.updateRequestTypes(this.selectedAmendmentRequest);
    }
  }

  formatDisplayAddress(amendmentSite: IAmendmentSite){
    if(amendmentSite.siteAddress && amendmentSite.siteAddress.length > 0 &&
      amendmentSite.siteCity && amendmentSite.siteCity.length > 0 &&
      amendmentSite.siteState && amendmentSite.siteState.length > 0 &&
      amendmentSite.siteZip && amendmentSite.siteZip.length > 0){
      return amendmentSite.siteAddress + ", " + amendmentSite.siteCity +
        " " + amendmentSite.siteState.toUpperCase() + " " + amendmentSite.siteZip;
    }
    return "-------";
  }

  formatRequestDate(amendmentSite: IAmendmentSite){
    if(!amendmentSite.completeByMeterReadDate){
      if(amendmentSite.completionDate){
        return moment(amendmentSite.completionDate).format('M/D/YYYY');
      }
      else{
        return "-------";
      }
    }
    else{
      return "Next Meter Read";
    }
  }

  addSite(){
    this.dataService.setSelectedAmendmentSite(undefined);
    this.router.navigate(['/amendments/add']);
  }

  deleteSite(){
    this.dataService.setSelectedAmendmentSite(undefined);
    this.router.navigate(['/amendments/delete']);
  }

  editAmendmentSite(amendmentSite: IAmendmentSite, addSite: boolean){
    this.amendmentsService.getAmendmentSite(this.myAccount.id, amendmentSite).subscribe((amendmentSiteFromDb : any) => {
      this.dataService.setSelectedAmendmentSite(amendmentSiteFromDb);
    
      if(addSite){
        this.router.navigate(['/amendments/add']);
      }
      else{
        this.router.navigate(['/amendments/delete']);
      }
    });
  }

  removeAmendmentSite(){
    this.amendmentSiteToRemove.accountId = this.myAccount.id;
    this.amendmentsService.deleteAmendmentSite(this.amendmentSiteToRemove).subscribe(() => {
      let newSites = [];
      this.selectedAmendmentRequest.amendmentSites.forEach((site) => {
        if(this.amendmentSiteToRemove.id != site.id){
          newSites.push(site);
        }
      });
      this.selectedAmendmentRequest.amendmentSites = newSites;
      this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
    });
  }

  submitRequest(){
    if(!this.selectedAmendmentRequest.amendmentSites || this.selectedAmendmentRequest.amendmentSites.length == 0){
      $('#noSitesModal').modal('show');
    }
    else if(this.hasIncompleteSites()){
      $('#incompleteSitesModal').modal('show');
    } else {
      this.amendmentsService.submitRequest(this.selectedAmendmentRequest).subscribe((amendmentRequest : any) => {
        $('#submitModal').modal('show');
        // console.log("Submit Request response: ", amendmentRequest);
        this.isSubmitted = true;
        this.selectedAmendmentRequest.submittedBy = amendmentRequest.submittedBy;
        this.selectedAmendmentRequest.submittedOn = amendmentRequest.submittedOn;
        this.selectedAmendmentRequest.status = 'SUBMITTED';
        this.dataService.setSelectedAmendmentSite(undefined);
        this.dataService.setSelectedAmendmentRequest(this.selectedAmendmentRequest);
      }, err => {
        if(err.status == 409) {
          $('#submitConflictModal').modal('show');
        }
        if(err.status == 400) {
          $('#submitErrorModal').modal('show');
        }
      });
    }
  }

  hasIncompleteSites(){
    let hasIncompleteSites = false;
    if(this.selectedAmendmentRequest.amendmentSites){
      this.selectedAmendmentRequest.amendmentSites.forEach((amendmentSite) => {
        if(!amendmentSite.complete){
          hasIncompleteSites = true;
        }
      });
    }
    return hasIncompleteSites;
  }

  sortAmendmentSites(sortBy: string, forceAsc?: boolean){
    if(sortBy == this.sortBy && !forceAsc) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.selectedAmendmentRequest.amendmentSites = this.orderByPipe.transform(this.selectedAmendmentRequest.amendmentSites, this.sortBy, this.ascending);
  }

  goToConfirmation(){
    //Sending on submit, now just want to route to confirmation page

    /*let emailList = "";

    let profile = this.dataService.getProfile();
    let firstName = profile.firstName;
    let lastName = profile.lastName;
	  let account = this.dataService.getAccountSource();
	  let customerName = account.name;
    emailList = profile.email;
    // console.log("EmailList: ", emailList);

    this.amendmentsService.sendAmendmentConfirmationEmail(emailList, firstName, lastName, customerName, this.selectedAmendmentRequest).subscribe(
      (resp) => {
        console.log("Emails sent Successfully!");
      },
      (err) => {
        console.log("Error sending emails!");
      }
    );
    */
    this.router.navigate(['/amendments/confirmation']);
  }

  // Masking Util Methods
  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }
  getFakeStreetAddress(site: ISite) {
    return this.portalService.getFakeStreetAddress(site);
  }
}
