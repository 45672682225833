import {PipeTransform, Pipe} from '@angular/core';
import {IBrokerEnrollments} from '../entities/brokerEnrollments';
import * as moment from 'moment';

@Pipe({
  name: 'brokerEnrollmentsPipe'
})
export class BrokerEnrollmentsPipe implements PipeTransform {

  transform(enrollmentsReport: IBrokerEnrollments[], term: string): IBrokerEnrollments[] {
 
        term = term.toLowerCase().trim();
        let termMoment = moment(term);
        let filteredEnrollmentsReport = [], confirmPushed = false;
        enrollmentsReport.forEach((confirm) => {
          confirmPushed = false;
          for(let prop in confirm){
            if(confirm[prop]){
              // if(prop === "serviceAddress" || prop ===  "billingAddress"){
              //   let addr = (confirm[prop].address1 + " " + confirm[prop].address2 + " " + confirm[prop].attentionName + " " + confirm[prop].attn + " " + site[prop].streetAddress + " " + confirm[prop].streetAddress2 + " " + confirm[prop].city + " " + confirm[prop].state + " " + confirm[prop].zipCode).toLowerCase();
              //   if(addr.match(term)) {
              //     filteredConfirms.push(confirm);
              //     confirmPushed = true;
              //     break;
              //   }
              // }else 
              if(confirm[prop].toString().toLowerCase().match(term)){
                filteredEnrollmentsReport.push(confirm);
                confirmPushed = true;
                break;
              } else if (!confirm[prop].toString().match(/[a-z]/i) && prop.toLowerCase().match('date') && moment(confirm[prop]).format('MM/DD') == termMoment.format('MM/DD')){
                // console.log(moment(site[prop]).format('MM/DD'), site[prop]);
                filteredEnrollmentsReport.push(confirm);
                confirmPushed = true;
                break;
              }
            }
          }
         
        });
        
    return filteredEnrollmentsReport;
  }

  getDateDisplay(dateString: string) {
    if(dateString){
      dateString = dateString.includes("T", 0) ? dateString : dateString + "T00:00:00";
      let date = new Date(dateString);
      return date.toLocaleDateString();
    }
  }
}
