import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {PortalService} from "../shared/portal.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Http} from "@angular/http";
import {Observable} from "rxjs";
import {ISubscription} from "../shared/entities/widgets/subscriptions";

@Injectable()
export class SubscriptionsService {

  constructor(private oldHttp: Http,
              private http: HttpClient,
              private portalService: PortalService) { }

  getSubscriptionForReport(userId: number, reportType: string){
    let url = environment.getSubscriptionsForReportUrl
      .replace('{userId}', userId + '')
      .replace('{reportType}', reportType);

    return this.http
      .get(url)
      .map((response: HttpResponse<ISubscription[]>) => response)
      .catch(this.handleError);
  }

  saveNewSubscription(subscription: ISubscription){
    let url = environment.saveNewSubscriptionUrl;

    return this.http
      .post(url, subscription)
      .map((response: HttpResponse<boolean>) => response)
      .catch(this.handleError);
  }

  getSubscriptionsForUser(userId: number){
    let url = environment.getSubscriptionsForUserUrl
      .replace('{userId}', userId + '');

    return this.http
      .get(url)
      .map((response: HttpResponse<ISubscription[]>) => response)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

  deleteSubscription(subscription: ISubscription){
    let url = environment.deleteSubscriptionUrl
      .replace('{subscriptionId}', subscription.id + '');

    // console.log("DELETING SUBSCRIPTION: ", url);

    return this.http
      .post(url,"")
      .catch(this.handleError);
  }

  editSubscription(subscription: ISubscription){
    let url = environment.editSubscriptionUrl
      .replace('{subscriptionId}', subscription.id + '');

    return this.http
      .post(url, subscription)
      .map((response: HttpResponse<ISubscription[]>) => response)
      .catch(this.handleError);
  }

  saveUserReport(request: any) {
    let url = environment.saveUserReportUrl;
    return this.http
    .post(url,request)
    .map((response : any) => response)
    .catch(this.handleError)
  }

  getReportsForType(request: any){
    let url = environment.getAllReportSettingsByAccountUrl;
    return this.http
    .post(url,request)
    .map((response : any) => response)
    .catch(this.handleError)
  }
}
