import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';


@Component({
  selector: 'app-position-management',
  templateUrl: './position-management.component.html',
  styleUrls: ['./position-management.component.scss']
})
export class PositionManagementComponent implements OnInit {

  showSave : boolean;
  saveExistingAsNew : boolean;
  constructor(
    private dataService : DataService,
  ) { }

  ngOnInit() {
    this.dataService.setTitleSource('Position Management');
    this.setCurrentActivePage('accounts');
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  modify()
  {
    console.log("modify")
  }

  toggleSave() {
    this.showSave = !this.showSave;
  }

  permissionCheck() {
    return true; //edit for perms
  }

  saveExisting() {
    this.showSave = this.saveExistingAsNew = false;
    // this.newReport = this.forwardPriceForm.selectedProduct.name;
  }
  saveNewReport() {
    this.showSave = false;
  //   this.newReport = '';
  //   if (this.reportSetting.reportId) {
  //     this.saveExistingAsNew = true;
  //   }
   }
}
