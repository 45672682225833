// Defines the Contract Status enumeration

export const ContractStatus = {
  FutureStart: 'Future Start',
  Active: 'Active',
  Assigned: 'Assigned',
  OnTransition: 'OnTransition',
  Restructured: 'Restructured',
  Terminated: 'Terminated',
  Expired: 'Expired'
};
