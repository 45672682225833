import {PipeTransform, Pipe} from '@angular/core';
import {IUserAccount, IUserPermission} from "../entities/profile";

@Pipe({
  name: 'userAccountPipe'
})
export class UserAccountPipe implements PipeTransform {

  transform(userAccounts: IUserAccount[], term: string): IUserAccount[] {
    let filteredUsers = [];
    term = term.toLowerCase().trim();
    for(let userAccount of userAccounts){
      //outer:
      for(let prop in userAccount){
        if(userAccount[prop] && userAccount[prop].toString().toLowerCase().match(term.toLowerCase())){
          filteredUsers.push(userAccount);
          break;
        }
      }
    }
    return filteredUsers;
  }
}
