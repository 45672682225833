import { Component, Input, OnInit } from '@angular/core';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() positionRelative = true;
  id = Math.random() * 10000;
  loading = false;
  text: string;

  constructor(private portalService: PortalService) { }

  ngOnInit() {
    this.portalService.spinBabySpin('spinner-' + this.id);
  }

  show(text = 'Loading...', color = '#3bdb9e') {
    this.portalService.spinBabySpin('spinner-' + this.id, color);
    this.loading = true;
    this.text = text;
  }

  hide() {
    this.loading = false;
    this.text = null;
  }

}
