import { Component, OnInit, Injectable } from '@angular/core';
import {DataService} from "../shared/data.service";
import {IUserAccount} from "../shared/entities/profile";
import {PortalService} from '../shared/portal.service';
import * as moment from 'moment';
import {GenericPipe} from '../shared/pipe/generic.pipe'
import {OrderByPipe} from '../shared/pipe/orderBy.pipe'
import {PagerDenoms} from '../shared/const/pagerDenoms'
import {IPagerDenom} from '../shared/const/pagerDenoms'
import {PagerService} from '../shared/pager.service'
import {Subscription} from "rxjs";
import {FakerService} from "../shared/faker.service";
import {IHelpStep} from "../shared/entities/contextualHelp";
import {IDownloadInvoiceRequest} from "../shared/entities/bill-reports";
import {Authority} from "../shared/const/authority";
import {Permissions} from "../shared/const/permissions";
import {MatDatepickerModule} from '@angular/material/datepicker';
import { IBrokerContact, IBrokerConfirm, IBrokerConfirmRequest } from '../shared/entities/brokerConfirm';
import { BrokerConfirmService } from './broker-confirm.service';
import { BrokerConfirmPipe } from '../shared/pipe/brokerconfirm';

declare var $: any;

@Component({
  selector: 'app-broker-confirm',
  templateUrl: './broker-confirm.component.html',
  styleUrls: ['./broker-confirm.component.scss'],
  providers: [BrokerConfirmPipe, GenericPipe, OrderByPipe]
})

@Injectable()
export class BrokerConfirmComponent implements OnInit {

  myAccount: IUserAccount;
  selectedStartDate: Date;
  selectedEndDate: Date;
  minDate: Date;
  maxDate: Date;
  fromDate: Date;
  toDate: Date;
  showSearchBar = false;
  
  searchTerm: string;

  maskMode: boolean;
  pagerDenoms: IPagerDenom[];
  selectedDenom: IPagerDenom;
  pager: any;
  ascending: boolean;
  noDataError: boolean = false;
  displayLimitError: boolean = false;

  allBrokerConfirms: IBrokerConfirm[];
  filteredBrokerConfirms: IBrokerConfirm[];

  isSuperUser: boolean;
  
  helpStep: IHelpStep;

  sortBy: string = 'confirmEffectiveDt';

  // Subscriptions
  exportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;


  brokerContacts : IBrokerContact[];
  
  constructor(private dataService: DataService,
    private brokerConfirmService: BrokerConfirmService,
    private pipe: GenericPipe,
    private portalService: PortalService,
    private brokerconfirmPipe: BrokerConfirmPipe,
    private orderByPipe: OrderByPipe,
    private pagerService: PagerService,
    private fakerService: FakerService) { }

  ngOnInit(): void { 
    this.isSuperUser = this.portalService.userHasAuthority(Authority.SuperUser);
    // console.log("IS SUPER USER: ", this.isSuperUser);

    this.dataService.setTitleSource('Broker Confirm');
    this.setCurrentActivePage('tools');
    this.dataService.setSelectedNavItem('tools');
    this.myAccount = this.dataService.getAccountSource();
    this.minDate = moment(new Date()).subtract(3,'years').toDate();
    this.maxDate = moment(new Date()).toDate();
    this.selectedStartDate = moment(new Date()).subtract(30,'days').toDate();
    this.selectedEndDate =  moment(new Date()).toDate();
    //this.allowedToMakePayment = this.portalService.hasPermission(Permissions.MakePayment) || this.isSuperUser || this.portalService.hasPermission(Permissions.Admin);
    if(this.myAccount){
     this.getBrokerConfirms();
    }
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
        this.getBrokerConfirms();
      }
    );
    this.pagerDenoms = PagerDenoms;
    this.pagerDenoms.forEach((denom) => {
      if(denom.count==10) {
        this.selectDenom(denom);
      }
    });
    this.exportSubscription = this.dataService.exportTriggered.subscribe(() => {this.export();});

    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
    });
  }

   toggleSearchRow() {
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    this.filterConfirms();
  }
  
  selectDenom(denom: IPagerDenom) {
    this.selectedDenom = denom;
    this.paginate(1);
  }


  getFakeCustName(){
    return this.portalService.getFakeCustName();
  }

  paginate(page: number){
    if(this.filteredBrokerConfirms){
      if(this.sortBy !== 'confirmEffectiveDt' && this.sortBy !== 'confirmStartDt' && this.sortBy !== 'confirmEndDt'){
        this.filteredBrokerConfirms = this.orderByPipe.transform(this.filteredBrokerConfirms, this.sortBy, this.ascending);
      } else {
        this.filteredBrokerConfirms = this.filteredBrokerConfirms.sort((a, b) => {
          let dateA = new Date(a.confirmEffectiveDt);
          let dateB = new Date(b.confirmEffectiveDt);
          if(this.sortBy === 'confirmStartDt') {
            dateA = new Date(a.confirmStartDt);
            dateB = new Date(b.confirmStartDt);
          } else if (this.sortBy === 'confirmEndDt') {
            dateA = new Date(a.confirmEndDt);
            dateB = new Date(b.confirmEndDt);
          }
          if (this.ascending) {
            return dateA.getTime() - dateB.getTime();
          } else {
            return dateB.getTime() - dateA.getTime();
          }
        });
      }
      this.pager = this.pagerService.getPage(this.filteredBrokerConfirms, page, this.selectedDenom.count)
    }
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  numberWithCommas(x, dollar?: boolean, twoDecimals?: boolean) {
    if(x == null){
      return '--'
    }
    let val = +x;
    let response = this.portalService.numberWithCommas(val);
    if(dollar || twoDecimals){
      response = this.portalService.numberWithCommas(val.toFixed(2));
    }
    if(dollar){
      response = '$' + response;
    }
    return response;
  }

  export() {
    // Get start date, end date, account Id,
    let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
    let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
    this.dataService.setLoading(true);

    if(startDateString && endDateString){
        let request: IBrokerConfirmRequest = {
         accountId: this.myAccount.id,
         accountName: this.dataService.getAccountSource().name,
         startDate: startDateString,
         endDate: endDateString,
         maskingMode : this.dataService.getMaskMode(),
         brand: window.localStorage.getItem("brand")
      };
      this.brokerConfirmService.exportBrokerConfirm(request);
     }
   }

  filterConfirms() {
    if(this.filteredBrokerConfirms){
      if (this.searchTerm) {
        this.filteredBrokerConfirms = this.brokerconfirmPipe.transform(this.allBrokerConfirms, this.searchTerm);
      } else {
        this.filteredBrokerConfirms = this.allBrokerConfirms;
      }
    }
    this.paginate(1);
  }

  sortBrokerConfirm(sortBy: string) {
    if(sortBy == this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = sortBy;
    }
    this.paginate(1);
  }


   getBrokerConfirms(){
      this.dataService.setLoading(true);
      let startDateString = moment(this.selectedStartDate).format('YYYY-MM-DD');
      let endDateString = moment(this.selectedEndDate).format('YYYY-MM-DD');
      if(startDateString && endDateString){
        let request: IBrokerConfirmRequest = {
          accountId: this.myAccount.id,
          accountName: this.dataService.getAccountSource().name, 
          startDate: startDateString,
          endDate: endDateString,
          brand: window.localStorage.getItem("brand")
        };

        this.brokerConfirmService.getBrokerConfirm(request).subscribe(
          (brokerConfirmResult) => {
            this.allBrokerConfirms = brokerConfirmResult;
            this.noDataError = this.allBrokerConfirms.length == 0;
            this.displayLimitError = false;
            this.ascending = true;
            this.filteredBrokerConfirms = this.orderByPipe.transform(this.allBrokerConfirms, 'confirmEffectiveDt', this.ascending);
            this.sortBrokerConfirm('confirmEffectiveDt');
            this.paginate(1);
            this.dataService.setLoading(false);
          }, err => {
            if(err.status == 404) {
              this.noDataError = true;
              this.displayLimitError = true;
            }
            if(err.status == 500) {
              this.displayLimitError = true;
              this.noDataError = false;
            }
            this.filteredBrokerConfirms = [];
            this.paginate(1);
            console.error(err);
            this.dataService.setLoading(false);
          }
        );
      }
    
   }


   ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.helpStepSubscription) this.helpStepSubscription.unsubscribe();
  }

  displayConfirmContact(contacts)
  {
    this.brokerContacts = contacts;
    $('#brokersModal').modal({
      show: true,
      backdrop: 'static'
    });
  }

  accept() {
    this.dataService.setLoading(false);
    $('#brokersModal').modal('hide');
  }
 }

