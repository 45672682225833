import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login/login.service';
import { PortalService } from "../shared/portal.service";
import { AccountsService } from "../accounts/accounts.service";
import { IPermission } from '../shared/entities/permission';
import { Router } from "@angular/router";
import { DataService } from "../shared/data.service";
import { ProfileService } from "../profile/profile.service";
import { Subscription } from "rxjs";
import { OrderByPipe } from "../shared/pipe/orderBy.pipe";
import { Authority } from "../shared/const/authority";
import { MarketsService } from '../markets/markets.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  providers: [CookieService, OrderByPipe]
})
export class LoginComponent implements OnInit {

  permission: IPermission;
  appProperties: any;
  email: string;
  password: string;
  rememberMe: boolean = true;
  incorrectLogin: boolean = false;
  incorrectLoginSubscription: Subscription;
  loggedInSubscription: Subscription;
  timedOut: boolean = false;

  constructor(private loginService: LoginService,
    private portalService: PortalService,
    private accountsService: AccountsService,
    private marketService: MarketsService,
    private manageProfileService: ProfileService,
    private router: Router,
    private dataService: DataService,
    private _cookieService: CookieService,
    private orderByPipe: OrderByPipe) { }

  ngOnInit() {
    this.appProperties = this.dataService.getAppProperties();
    this.dataService.setLoggedIn(false);
    this.incorrectLoginSubscription = this.dataService.incorrectLoginUpdated.subscribe(
      (incorrectLogin) => {
        this.incorrectLogin = incorrectLogin;
      }
    );

    if (this.dataService.getLoggedIn()) {
      this.loadAccountData();
    }

    this.loggedInSubscription = this.dataService.loggedInUpdated.subscribe(
      (loggedIn) => {
        if (loggedIn) {
          this.loadAccountData();
        }
      }
    );
  }

  ngOnDestroy() {
    this.incorrectLoginSubscription.unsubscribe();
    this.loggedInSubscription.unsubscribe();
  }

  getCookie(key: string) {
    return this._cookieService.get(key);
  }

  checkCookie() {
    let rememberedUsername: string = this.getCookie("username");
    if (rememberedUsername != null) {
      this.email = rememberedUsername;
    }

    let rememberedSession: string = this.getCookie("logged-in");
    if (rememberedSession != null) {
      if (!this.dataService.getTimedOut()) {
        this.dataService.setLoggedIn(true);
      }
    } else {
      this.dataService.setLoggedIn(false);
    }
  }

  createUsernameCookie(value: string) {
    this._cookieService.set("username", value);
  }

  removeUsernameCookie() {
    this._cookieService.delete("username");
  }

  createLoggedInCookie(value: string) {
    this._cookieService.set("logged-in", value);
  }

  removeLoggedInCookie() {
    this._cookieService.delete("logged-in");
  }

  createDefaultAccountCookie(value: string) {
    this._cookieService.set("default-account", value);
  }

  removeDefaultAccountCookie() {
    this._cookieService.delete("default-account");
  }

  updateCookies() {
    if (this.rememberMe) {
      this.createUsernameCookie(this.email);
    } else {
      this.removeUsernameCookie();
    }
    this.createLoggedInCookie(this.email);
  }

  login() {
    if (!this.dataService.getAppProperties()) {
      this.dataService.setLoggedIn(true);
    }
    this.portalService.login(this.email, this.password).subscribe(
      (resp) => {
        this.portalService.setLocalUserId().subscribe(
          (resp2) => {
            this.hideIncorrectLogin();
            this.dataService.setLoggedIn(true);
            this.dataService.setCreatePassword(false);
            this.dataService.setResetPassword(false);
            this.router.navigate(['/']);
            this.updateCookies();
            this.getDalHealthCheck();
          }
        );
      },
      err => {
        this.showIncorrectLogin();
      }
    );
  }

  getDalHealthCheck() {
    this.portalService.getDalHealthCheck().subscribe(
      () => {
        console.log("DAL is up and running!");
      },
      err => {
        console.log("DAL UNRESPONSIVE");
        this.showDalUnresponsive();
      }
    );
  }

  getPortalHealthCheck() {
    this.portalService.getPortalHealthCheck().subscribe(
      () => {
        console.log("Portal is up and running!");
      },
      err => {
        console.log("PORTAL UNRESPONSIVE");
        this.showDalUnresponsive();
      }
    );
  }

  showDalUnresponsive() {
    $('#healthCheckModal').modal({
      show: true,
      keyboard: false,
      backdrop: 'static'
    });
  }

  loadAccountData() {
    this.dataService.setLoading(true);

    this.marketService.getMarketStates().subscribe(
      (marketStates : any) => {
        this.dataService.setMarketStates(marketStates);
      }
    );

    this.manageProfileService.getManageProfile().subscribe(
      (profile) => {
        this.dataService.setProfile(profile);
        let accounts = profile.userAccounts;
        if (accounts.length <= 0) {
          this.dataService.setAccessDenied(true);
        }
        this.portalService.getUser().subscribe((user) => {
          window.localStorage.setItem("customerID", user.id.toString());
          window.dispatchEvent(new CustomEvent("oam_get_customer_id"));

          this.dataService.setUserSource(user);
          let filteredAccounts = [], thirdPartyAccounts = [];
          accounts.forEach((acc) => {
            if (acc.accessType != 'ThirdParty') {
              filteredAccounts.push(acc);
            } else {
              if (!this.portalService.userHasAuthority(Authority.SuperUser)) {
                acc.thirdPartyCustomers.forEach((customer) => {
                  if (customer.isOverridden) {
                    filteredAccounts.push(customer);
                  } else {
                    customer.parentName = acc.name;
                    customer.customerAgentId = acc.id;
                    filteredAccounts.push(customer)
                  }
                });
              }
              thirdPartyAccounts.push(acc);
            }
          });

          if (thirdPartyAccounts.length > 0) {
            this.dataService.setThirdPartyAccountsSource(thirdPartyAccounts);
          }
          let orderedAccounts = this.orderByPipe.transform(filteredAccounts, 'name', true);
          if (filteredAccounts.length > 0 || thirdPartyAccounts.length > 0) {
            let storedDefaultAccountNumber = this.getCookie("default-account");
            let accountToLoad;
            if (storedDefaultAccountNumber) {
              filteredAccounts.forEach((filteredAccount) => {
                if (filteredAccount.id == storedDefaultAccountNumber) {
                  accountToLoad = filteredAccount;
                }
              });
              thirdPartyAccounts.forEach((tpa) => {
                if (tpa.id == storedDefaultAccountNumber) {
                  accountToLoad = tpa;
                }
              });
              // console.log('default account exists, loading ', accountToLoad);
            } else {
              if (filteredAccounts.length > 0) {
                accountToLoad = filteredAccounts[0];
              } else if (thirdPartyAccounts.length > 0) {
                accountToLoad = thirdPartyAccounts[0];
              }

            }
            this.manageProfileService.getUserImage();
            // if (this.portalService.userHasAuthority(Authority.SuperUser)) {
            //   let thirdPartyAccounts = [], acc = {};
            //   this.portalService.getAllThirdPartyAccounts().subscribe((tpas) => {
            //     tpas.forEach((tpa) => {
            //       acc = {
            //         id: tpa.thirdPartyId,
            //         name: tpa.thirdPartyName,
            //         accessType: 'ThirdParty'
            //       };
            //       thirdPartyAccounts.push(acc);
            //     });
            //     thirdPartyAccounts.forEach((tpa) => {
            //       if (tpa.id == storedDefaultAccountNumber) {
            //         accountToLoad = tpa;
            //       }
            //     });
            //     console.log('found account to load after getting third party accounts: ', accountToLoad);
            //     this.dataService.setThirdPartyAccountsSource(thirdPartyAccounts);
            //     this.removeDefaultAccountCookie();
            //     if (accountToLoad) {
            //       this.createDefaultAccountCookie(String(accountToLoad.id));
            //     }
            //     this.dataService.setAccountsForUserSource(filteredAccounts);
            //     this.dataService.setLoading(false);
            //   });
            // } else {
            if (!accountToLoad) {
              this.removeDefaultAccountCookie();
              if (filteredAccounts.length > 0) {
                accountToLoad = filteredAccounts[0];
              } else if (thirdPartyAccounts.length > 0) {
                accountToLoad = thirdPartyAccounts[0];
              }
            }
            if (accountToLoad) {
              this.createDefaultAccountCookie(String(accountToLoad.id));
            }
            this.dataService.setAccountsForUserSource(filteredAccounts);
            this.dataService.setLoading(false);
            // }
          }
        });
      },
      err => {
        if (err.status == 401) {
          this.dataService.setTimedOut(true);
          this.dataService.setLoggedIn(false);
        }
        if(window.location.href.indexOf("localhost") == -1){
          this.dataService.setProfile(null);
        }
      }
    );
  }
  showForgotPassword() {
    this.dataService.setForgotPassword(true);
  }

  showSelfRegistration() {
    this.dataService.setSelfRegistration(true);
  }

  showForgotEmail() {
    this.dataService.setForgotEmail(true);
  }

  showIncorrectLogin() {
    this.dataService.setIncorrectLogin(true);
  }

  hideIncorrectLogin() {
    this.dataService.setIncorrectLogin(false);
  }

  isLoginCorrect() {
    this.dataService.getIncorrectLogin();
  }

  showQuickPay() {
    this.dataService.setQuickPay(true);
  }

}
