import { Component, OnInit } from '@angular/core';
import { Authority } from '../../shared/const/authority';
import { DataService } from '../../shared/data.service';
import { ProfileService } from '../../profile/profile.service';
import { IUserDetails, IUserPermission, IProfile } from '../../shared/entities/profile';
import { IUserAccount } from '../../shared/entities/profile';
import { PortalService } from '../../shared/portal.service';
import { UserPermissionsPipe } from '../../shared/pipe/userpermissions.pipe';
import { Permissions } from '../../shared/const/permissions';
import { Subscription } from 'rxjs';
import { OrderByPipe } from '../../shared/pipe/orderBy.pipe';
import { FadeAnimation } from '../../animations/fadeAnimation';
import { FadeInAnimation } from '../../animations/fadeInAnimation';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'app-super-manage-users',
  templateUrl: './super-manage-users.component.html',
  styleUrls: ['./super-manage-users.component.scss'],
  animations: [FadeAnimation, FadeInAnimation],
  providers: [UserPermissionsPipe]
})
export class SuperManageUsersComponent implements OnInit {

  emailValidationPattern: String = "([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*]).([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])";
  emailFormControl = new FormControl('',[
  	Validators.required,
  	Validators.pattern("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*]).([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])")]
    );
  userPermissions: IUserPermission[];
  showThirdPartyLegend: boolean = false;
  showSearchRow: boolean = false;
  requiredError: boolean = false;
  searchTerm: string = '';
  filteredUserPermissions: IUserPermission[] = [];
  currentUserDetails: IProfile;
  selectedAccount: IUserAccount;
  adding: boolean = false;
  userToDelete: IUserPermission;
  selectedUserAccount: IUserAccount;
  pricingAccessQuotes: string;
  matrixAccess: string;
  brokerConfirmAccess: boolean = false;
  brokerConfirmAccessVal: boolean = false;
  brokerDropEnrollmentAccess: boolean = false;
  brokerDropEnrollmentAccessVal: boolean = false;
  commissionsAccess: boolean = false;
  commissionsAccessVal: boolean = false;
  pricingAccessVal: number = -1;
  accessLevels = ['Admin', 'Full', 'View Only'];
  validEmail: boolean = false;
  madeChangeToPerms : boolean = false;


  required = ['email', 'firstName', 'lastName', 'email', 'accessLevel', 'organization'];
  selectedUserAccountSourceSubscription: Subscription;
  phone: any[] = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  emailError: boolean = false;
  emailValidError: boolean = false;
  conflictError: boolean = false;
  editingAny: boolean;
  loading: boolean = true;
  // existUser: boolean = false;
  retrieveProfile: IProfile;
  profileExists: boolean = false;
  iconCheck: boolean = false;
  editingEmail: boolean = false;
  addFlag: boolean = false;
  editFlag: boolean = false;
  showPricingLegend: boolean = false;
  selectedAccess: string;
  selectedPermission: string;
  saveError: boolean = false;
  textBoxesEnabled: boolean = false;
  focusedUser: IUserPermission;

  constructor(private dataService: DataService,
    private manageProfileService: ProfileService,
    private userPermissionsPipe: UserPermissionsPipe,
    private portalService: PortalService,
    private orderByPipe: OrderByPipe) { }

  ngOnInit() {

    // this.retrieveUser('super.user@nrg.com');
    this.portalService.spinBabySpin('spinner-manage-users-modal');
    const userAccount = this.dataService.getSelectedUserAccountSource();
    this.textBoxesEnabled = false;
    if (userAccount) {
      this.loadUsers(userAccount);
    }
    this.selectedUserAccountSourceSubscription = this.dataService.selectedUserAccountSourceUpdated.subscribe(
      (account) => {
        this.selectedUserAccount = account;

        if (this.selectedUserAccount.accessType === 'ThirdParty' && this.selectedUserAccount.pricingFlagEnabled) {
          this.showPricingLegend = true;
        } else {
          this.showPricingLegend = false;
        }
        // // console.log("INIT this.selectedUserAccount: ", this.selectedUserAccount);
        this.loadUsers(account);
      }
    );
    // if (userAccount) {
    //   this.currentUserDetails = userAccount;
    // }

    //seperate perm check for first login?
    if((this.userPermissions && this.userPermissions[0].userAccount.permissions & Permissions.BrokerConfirms) === Permissions.BrokerConfirms){
      this.brokerConfirmAccess = true;
    }

    if((this.userPermissions && this.userPermissions[0].userAccount.permissions & Permissions.BrokerDropsEnrollments) === Permissions.BrokerDropsEnrollments){
      this.brokerDropEnrollmentAccess = true;
    }

    if((this.userPermissions && this.userPermissions[0].userAccount.permissions & Permissions.Commissions) === Permissions.Commissions){
      this.commissionsAccess = true;
    }

    this.commissionsAccessVal = this.commissionsAccess;
    this.brokerConfirmAccessVal = this.brokerConfirmAccess;
    this.brokerDropEnrollmentAccessVal = this.brokerDropEnrollmentAccess;
    console.log("at the end of oninit");
   
  }

  ngOnDestroy() {
    if (this.selectedUserAccountSourceSubscription) {
      this.selectedUserAccountSourceSubscription.unsubscribe();
    }
    this.editFlag = false;
    this.textBoxesEnabled = false;
  }

  loadUsers(selectedAccount: IUserAccount) {
    this.selectedAccount = selectedAccount;
    // // console.log("LOADING USERS this.selectedAccount: ", this.selectedAccount);
    // // console.log("LOADING USERS this.selectedUserAccount: ", this.selectedUserAccount);
    this.initializeActivityLevelMenu(this.selectedUserAccount);

    this.manageProfileService.getUserPermissions(this.selectedAccount.id).subscribe(
      (userPermissions : any) => {
        this.loading = false;
        this.userPermissions = userPermissions;
        this.filterUsers();
        this.userPermissions.forEach((permission) => {
          permission.userProfile.showResend = moment()
            .format('YYYY-MM-DD') != moment(permission.userProfile.activateResendDate).format('YYYY-MM-DD');
        });
        this.dataService.setLoading(false);
      }, err => {
        console.error(err);
        this.manageProfileService.getUserPermissions(this.selectedAccount.id).subscribe(
          (userPermissions : any) => {
            this.loading = false;
            this.userPermissions = userPermissions;
            this.filterUsers();
          }, err => {
            console.error(err);
          }
        );
      }
    );
  }
 
  isNotPricingOnly(user: IUserPermission) {
    return (user.temp.userAccount.accessLevel.toUpperCase().indexOf('PRICING') === -1 
      || (user.temp.userAccount.accessLevel.toUpperCase().indexOf('PRICING')!=-1 && user.temp.userAccount.accessLevel.toUpperCase().indexOf('POST')!=-1))
  }

  isOamUserAccessSel(user: IUserPermission, level: string) {
      if(this.getOamUserAccess(user)===level)
        return true;
      else
        return false;
  }

  getOamUserAccess(user: IUserPermission) {
    if(user.userAccount.accessLevel.toUpperCase().indexOf("POST")!=-1)
      return "Post Sale";
    else if(user.userAccount.accessLevel.toUpperCase().indexOf("PRICING")!=-1)
      return "Pricing";  
    else if(user.userAccount.accessLevel.toUpperCase().indexOf("ADMIN")!=-1)
      return "Admin";  
    else
      return Authority.oamPermToAccessMap.get(user.userAccount.permissions);
  }

  getUserAccess(user: IUserPermission) {
    // if (user.userAccount.accessType === 'ThirdParty') {
    //   if (Authority.accessMap.has(user.userAccount.permissions)) {
    //     return Authority.accessMap.get(user.userAccount.permissions);
    //   }
    // }
    return user.userAccount.accessLevel;
  }

  disableCommissionsRadio(hasCommissions: boolean) {
    return hasCommissions;
  }


  noUnderScores(activityLevel: string) {
    return activityLevel.replace('_', ' ');
  }

  initializeActivityLevelMenu(userAccount: IUserAccount) {
    if (userAccount && userAccount.accessType === 'ThirdParty') {
      if (userAccount.pricingFlagEnabled) {
        this.accessLevels = Array.from(Authority.oamAccessList);
        // this.accessLevels = this.accessLevels.filter((level)=>{level !== 'Commissions'});

        let tempAccessList = [''];
        this.accessLevels.forEach((level) => {
          if (level == 'Commissions') {
          }
          else if(!tempAccessList.includes(level)){
            tempAccessList.push(level);
          }
        })
        this.accessLevels = tempAccessList;

      } else {
        this.accessLevels = ['Admin', 'Post Sale'];
      }
    } else {
      this.accessLevels = ['Admin', 'Full Access', 'View Only'];
    }
  }

  toggleSearchRow() {
    this.showSearchRow = !this.showSearchRow;
    this.searchTerm = '';
    this.filterUsers();
  }

  filterUsers() {
    if (!this.searchTerm) {
      this.filteredUserPermissions = this.userPermissions;
    } else {
      this.filteredUserPermissions = this.userPermissionsPipe.transform(this.userPermissions, this.searchTerm);
    }
    this.filteredUserPermissions = this.orderByPipe.transform(this.filteredUserPermissions, 'userProfile.firstName', true);
  }



  editUser(user: IUserPermission) {

    this.madeChangeToPerms = false;
    this.textBoxesEnabled = false;
    this.selectedPermission = this.getOamUserAccess(user); //user.userAccount.accessLevel;
    if (user.userProfile.email != '') {
      this.validEmail = true;
    }
    else {
      this.validEmail = false;
    }
    this.editingAny = true;
    if (user == this.focusedUser) {
      this.editFlag = !this.editFlag;
    } else if (this.editFlag && user != this.focusedUser) {
      this.cleanUserList(null);
      this.editFlag = true;
    } else {
      this.editFlag = true;
    }
    user.userProfile.editing = true;
    user.errors = [];
    user.temp = {
      userProfile: Object.assign({}, user.userProfile),
      userAccount: Object.assign({}, user.userAccount)
    };
    this.currentUserDetails = user.userProfile;
    this.focusedUser = user;

    if((user && user.userAccount != null && user.userAccount.permissions & Permissions.BrokerConfirms) === Permissions.BrokerConfirms){
      this.brokerConfirmAccess = true;
    }
    this.brokerConfirmAccessVal = this.brokerConfirmAccess;

    if((user && user.userAccount != null && user.userAccount.permissions & Permissions.BrokerDropsEnrollments) === Permissions.BrokerDropsEnrollments){
      this.brokerDropEnrollmentAccess = true;
    }
    this.brokerDropEnrollmentAccessVal = this.brokerDropEnrollmentAccess;

    if((user && user.userAccount != null && user.userAccount.permissions & Permissions.Commissions) === Permissions.Commissions){
      this.commissionsAccess = true;
    }
    this.commissionsAccessVal = this.commissionsAccess;
    this.setPermissions(user,user.userAccount.accessLevel);
    console.log('in superEdituser edit', this.editFlag)
  }

  hasError(user: IUserPermission, error: string) {
    let hasError = false;
    if (user.errors) {
      user.errors.forEach((err) => {
        if (err === error) {
          hasError = true;
        }
      });
    }
    return hasError;
  }


  retrieveUser(user: IUserPermission) {
    user.errors = [];
    this.emailValidError = false;
    if (!user.temp.userProfile.email || this.emailFormControl.errors) {
      this.emailValidError = true;
      user.errors.push('email');
      return;
    } else {
      if (user.temp.userProfile.email) {
        this.validEmail = true;
      }
      this.iconCheck = true;
      this.editingEmail = true;
      this.addFlag = true;
      this.manageProfileService.getProfileData(user.temp.userProfile.email).subscribe(
        (retrieveProfileData : any) => {
          this.retrieveProfile = retrieveProfileData;
          // console.log('retrivieprofile',this.retrieveProfile)
          this.textBoxesEnabled = true;

          if (this.retrieveProfile == null) {
            this.profileExists = false;
            // // console.log('firstname',this.retrieveProfile.firstName);
          } else {
            // // console.log('i in else assignemnt');
            this.profileExists = (this.retrieveProfile.firstName != null && this.retrieveProfile.firstName.length > 0);
            if (this.profileExists) {
              //  // console.log('inside profileexists');
              user.temp.userProfile.firstName = this.retrieveProfile.firstName;
              user.temp.userProfile.lastName = this.retrieveProfile.lastName;
              user.temp.userProfile.organization = this.retrieveProfile.organization;
              user.temp.userProfile.jobTitle = this.retrieveProfile.jobTitle;
              user.temp.userProfile.phone = this.retrieveProfile.phone;
              user.temp.userProfile.extension = this.retrieveProfile.extension;
              user.temp.userProfile.mobilePhone = this.retrieveProfile.mobilePhone;
              this.currentUserDetails = user.temp.userProfile;
              //this.editingEmail = false;
              if (this.retrieveProfile.userAccounts && this.retrieveProfile.userAccounts.length > 0) {
                user.temp.userAccount[0].accessLevel = this.retrieveProfile.userAccounts[0].accessLevel;
              }
            } else {
              // console.log('i am in else before save');
              // this.saveUser(user);
            }
          }

          //     || (contactInfo.customerRep != null && contactInfo.customerRep.length > 0);
        }, err => {
          console.error(err);
        }
      );
    }

  }

  setPermissions(user: IUserPermission, accessLevel: string) {

    this.madeChangeToPerms = false;
    this.selectedAccess = accessLevel;
    if (this.selectedAccount.accessType === 'ThirdParty') {
      user.temp.userAccount.accessLevel = accessLevel;
      user.temp.userAccount.permissions = Authority.activityMap.get(Authority.accessToActivityMap.get(accessLevel));
    } else {
      user.temp.userAccount.accessLevel = accessLevel;
      user.temp.userAccount.permissions = Authority.activityMap.get(Authority.accessToActivityMap.get(accessLevel));
    }
    // "accessLevel" : "Post Sale",
    // "permissions" : 62,
    //"accessType" : "ThirdParty",

    //new access levels :  Admin','Pricing','Post Sale','No Access'
    this.pricingAccessVal=2; //default
    if(!this.isNotPricingOnly(user) || accessLevel==='No Access') {
      this.commissionsAccess = false; 
      this.commissionsAccessVal = this.commissionsAccess;
      this.brokerConfirmAccess = false;
      this.brokerConfirmAccessVal = this.brokerConfirmAccess;
      this.brokerDropEnrollmentAccess = false;
      this.brokerDropEnrollmentAccessVal = this.brokerDropEnrollmentAccess;
    }

   
    if(accessLevel.toUpperCase().indexOf('POST')!=-1) {
          user.temp.userAccount.accessLevel = 'Post Sale';
          this.selectedAccess = 'Post Sale';
          if((user.userAccount.permissions & Permissions.PricingAll) === Permissions.PricingAll){
            this.pricingAccessVal=0;
          }
          else if((user.userAccount.permissions & Permissions.PricingUser) === Permissions.PricingUser){
            this.pricingAccessVal=2;
          }
          else{
            this.pricingAccessVal=1;
          }
    } 
    else if(accessLevel.toUpperCase().indexOf('PRICING')!=-1){
      user.temp.userAccount.accessLevel = 'Pricing';
      this.selectedAccess = 'Pricing';
      if((user.userAccount.permissions & Permissions.PricingAll) === Permissions.PricingAll){
        this.pricingAccessVal=0;
      }
      else if((user.userAccount.permissions & Permissions.PricingUser) === Permissions.PricingUser){
        this.pricingAccessVal=2;
      }
    }
    else if(accessLevel.toUpperCase().indexOf('ADMIN')!=-1) {
          this.pricingAccessVal=0;
          this.selectedAccess = 'Admin';
    }
    else if(accessLevel.toUpperCase().indexOf('NO ACCESS')!=-1) {
          this.pricingAccessVal=1;
          this.selectedAccess = 'No Access';
    }

    if (accessLevel == "BrokerConfirms" || this.brokerConfirmAccess == true || accessLevel.toUpperCase() == "ADMIN" ) {
      this.brokerConfirmAccess = true;
    }
    else{
      this.brokerConfirmAccess = false;
    }

    if (accessLevel == "BrokerDrops" || this.brokerDropEnrollmentAccess == true || accessLevel.toUpperCase() == "ADMIN" ) {
      this.brokerDropEnrollmentAccess = true;
    }
    else{
      this.brokerDropEnrollmentAccess = false;
    }

    this.brokerConfirmAccessVal = this.brokerConfirmAccess;
    this.brokerDropEnrollmentAccessVal = this.brokerDropEnrollmentAccess;
    console.log("at the end of set-> com access", this.brokerConfirmAccess, this.brokerDropEnrollmentAccess);
    console.log("comaccessval", this.brokerConfirmAccessVal, this.brokerDropEnrollmentAccessVal);

    if (accessLevel == "Commissions" || this.commissionsAccess == true || accessLevel.toUpperCase() == "ADMIN" ) {
      this.commissionsAccess = true;
    }
    else{
      this.commissionsAccess = false;
    }
    this.commissionsAccessVal = this.commissionsAccess;
    console.log("at the end of set-> com access" + this.commissionsAccess);
    console.log("comaccessval" + this.commissionsAccessVal);
  }

  setSelectedPermission(perm: string) {
    this.selectedPermission = perm;
  }

  saveUser(user: IUserPermission) {
    user.errors = [];
    this.required.forEach((required) => {
      // Validate
      console.log('required', required);
      if (!user.temp.userProfile[required] && !user.temp.userAccount[required]) {
        user.errors.push(required);
        console.log('inside required', required);
      }
    });

    // If no errors, proceed with save
    if (user.errors.length === 0) {
      this.adding = false;

      user.userAccount = user.temp.userAccount;
      user.userProfile = user.temp.userProfile;
      user.userProfile.editing = false;
      this.editFlag = false;
      user.userProfile.email = user.userProfile.email.toLowerCase();
      this.requiredError = false;
    } else {
      this.requiredError = true;
      return;
    }
    this.getUserAccess(user);
    if (user.userAccount.id) {
      // ID exists, update existing object
      this.validEmail = false; //reset valid email bool
      this.manageProfileService.updateUserPermissions(user).subscribe(
        (resp) => {
          console.log(resp)
        }, err => {
          console.error(err);
        }
      )
    } else {
      // New object, assign ID and create
      this.validEmail = false;
      this.dataService.setLoading(true);
      this.conflictError = false;
      user.userAccount.id = this.selectedUserAccount.id;
      // console.log("this.selectedUserAccount: ", this.selectedUserAccount);
      user.userAccount.accessType = this.selectedUserAccount.accessType;
      this.manageProfileService.createUserPermissions(user).subscribe(
        (resp) => {
          // console.log(resp);
          this.loadUsers(this.dataService.getSelectedUserAccountSource())
          this.dataService.setLoading(false);
          this.editFlag = false;
        }, (  err) => {
          this.dataService.setLoading(false);
          if (err.status === 500) {
            console.error(err);
            this.emailError = true;
          } else if (err.status === 409) {
            this.conflictError = true;
          }
        }
      )
      this.dataService.setLoading(false);
    }

    this.getEditingAny();
    this.dataService.triggerUsersUpdated();
    this.focusedUser = null;
  }

  getEditingAny() {
    this.editingAny = false;
    this.userPermissions.forEach((user) => {
      if (user.userProfile.editing) {
        this.editingAny = true;
      }
    });
  }

  deleteUser() {
    let accountDeletedBool = false;
    this.emailError = false;
    this.requiredError = false;
    this.manageProfileService.deleteUserPermission(this.userToDelete.userAccount.id, this.userToDelete.userAccount.userId).subscribe(
      (resp) => {
        let i;
        this.userPermissions.forEach((tableUser, index) => {
          if (this.userToDelete.userAccount.userId === tableUser.userAccount.userId) {
            i = index;
          }
        });
        this.userPermissions.splice(i, 1);
        this.filterUsers();
        accountDeletedBool = true;
        // console.log(resp);
      }, (error) => {
        let i;
        this.userPermissions.forEach((tableUser, index) => {
          if (this.userToDelete.userAccount.userId === tableUser.userAccount.userId) {
            i = index;
          }
        });
        this.userPermissions.splice(i, 1);
        this.filterUsers();
        accountDeletedBool = true;
        // console.log(err);
      }
    );

    // Code to delete users created on Front End that never register in backend
    this.cleanUserList(this.userToDelete);

    this.dataService.triggerUsersUpdated();
  }

  // He was pierced for our transgressions; he was crushed for our iniquities;
  // upon him was the chastisement that brought us peace, and with his wounds we are healed. -Isaiah 53:5

  addNewUser() {
    this.iconCheck = false;
    this.editingEmail = false;
    this.validEmail = false;
    const newUser: IUserPermission = {
      userAccount: {
        name: this.selectedAccount.name,
        accessLevel: '',
      }, userProfile: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        organization: '',
        jobTitle: '',
        editing: true
      }, errors: []
    };
    this.adding = true;
    this.editUser(newUser);
    const userTable = document.getElementById('usersTable');
    userTable.scrollTop = 0;
    this.userPermissions.unshift(newUser);
    this.filterUsers();
    this.addFlag = false;
    // console.log('EdiFlag',this.editFlag);
  }

  undo(user: IUserPermission) {
    user.userProfile.editing = false;
    this.editFlag = false;
    user.temp = {};
    user.errors = [];
    this.saveError = false;
    this.getEditingAny();
    // this.cleanUserList(null);
    this.filterUsers();
    this.focusedUser = null;
  }

  cleanUserList(cancelledUser?: IUserPermission) {
    if (this.adding) {
      let tempUserList = [];
      this.filteredUserPermissions.forEach((user) => {
        if (user.userProfile.email == null || user.userProfile.email == '') {
        }
        else if (cancelledUser != null && cancelledUser.userProfile.email == user.userProfile.email) {
        }
        else {
          tempUserList.push(user);
        }
      })
      this.userPermissions = tempUserList;
      this.filterUsers();
    }
  }

    cancelEdit(cancelledUser?: IUserPermission) {
    this.cleanUserList(cancelledUser);
    console.log('cancel edit hit');
    this.editFlag = false;
    this.adding = false;
    this.focusedUser = null;
    this.requiredError = false;
    this.saveError = false;
    }

    checkRadioButton(user) : boolean {
      if(this.selectedPermission.toUpperCase() == 'ADMIN'){
        return true;
      }
      else{
        return false;
      }

    }

  saveButton(userPermission: IUserPermission, pricingAccessQuotes: string, matrixAccess: string, commissionsAccess: boolean,  accessLevel: string, brokerConfirmAccess: boolean, brokerDropEnrollmentAccess: boolean) {
    //userPermission.userAccount.accessLevel = accessLevel;

    userPermission.errors = [];
    this.required.forEach((required) => {
      // Validate
      console.log('required', required);
      if (!userPermission.temp.userProfile[required] && !userPermission.temp.userAccount[required]) {
        userPermission.errors.push(required);
        console.log('inside required', required);
      }
    });

    if (userPermission.temp.userProfile.email && userPermission.temp.userProfile.firstName && userPermission.temp.userProfile.lastName && userPermission.temp.userProfile.organization && userPermission.temp.userAccount.accessLevel && userPermission.temp.userAccount.accessLevel != '') {
      this.validEmail = true;
      let permissionsNumber = 0;


      if (this.pricingAccessVal == 0) {
        permissionsNumber += 256;
        if (accessLevel === 'Pricing')
          accessLevel = 'Pricing (All Quotes)';
      }
      // else if(this.pricingAccessVal==1){
      //   permissionsNumber += 158;
      // }
      else if (this.pricingAccessVal == 2) {
        permissionsNumber += 128;
        if (accessLevel === 'Pricing')
          accessLevel = 'Pricing (User Quotes)'
      }
      

      if (accessLevel == 'Admin') {
        permissionsNumber += 63;
      } 
      else if(accessLevel.toUpperCase().indexOf("POST")!=-1){
        permissionsNumber += 62;
        accessLevel = 'Post Sale';
        if(permissionsNumber == 318){
          accessLevel = 'Post Sale and Pricing (All Quotes)';
        }
        else if(permissionsNumber == 190){
          accessLevel = 'Post Sale and Pricing (User Quotes)';
        }
      }
      // else if (accessLevel == 'Pricing (User Quotes)') {
      //   permissionsNumber += 128;
      // }
      // else if(accessLevel == 'Pricing (All Quotes)'){
      //   permissionsNumber += 256;
      // }
      // else if(accessLevel == 'Post Sale and Pricing (All Quotes)'){
      //   permissionsNumber += 318;
      // }
      // else if(accessLevel == 'Post Sale and Pricing (User Quotes)'){
      //   permissionsNumber += 190;
      // }
      else {
      }
      if (commissionsAccess == true || this.commissionsAccess) {
        permissionsNumber += 512;
      }
      if (this.brokerConfirmAccess == true || this.brokerConfirmAccess) {
        permissionsNumber += 1024;        
      }
      if (this.brokerDropEnrollmentAccess == true || this.brokerDropEnrollmentAccess) {
        permissionsNumber += 2048;        
      }
      userPermission.userAccount.accessLevel = accessLevel;
      userPermission.userAccount.permissions = permissionsNumber;
      userPermission.temp.userAccount.permissions = permissionsNumber;
      userPermission.temp.userAccount.accessLevel = accessLevel;

      this.saveError = false;
    }
    else {
      this.saveError = true;
      return;
    }
    this.saveUser(userPermission);
    this.saveError = false;
  }

  resend(user: IUserPermission) {
    if (!user.userProfile.activated) {
      user.userProfile.showResend = false;
      const req = Object.assign({}, user);
      delete req.userProfile.showResend;
      delete req.userProfile.editing;
      this.portalService.sendUserConfirmationEmail(req).subscribe(
        (resp) => {
          // console.log("Email resent");
        }, err => {
          if (err.status === 500) {
            this.emailError = true;
          }
        });
    }
  }

  closeModal() {
    this.adding = false;
    this.emailError = false;
    this.conflictError = false;
    this.requiredError = false;
  }

  getPhoneDisplay(phoneNum: string) {
    if (phoneNum) {
      return this.portalService.getPhoneDisplay(phoneNum);
    }
  }

  listUserPermissions(user: IUserPermission) {
    if (user.userAccount) {
      let listOfPermissions: string[] = [];
      if ((user.userAccount.permissions & Permissions.PricingAll) === Permissions.PricingAll) {
        listOfPermissions.push("Pricing Access All Quotes")
      }
      if ((user.userAccount.permissions & Permissions.PricingUser) === Permissions.PricingUser) {
        listOfPermissions.push("Pricing Access User Quotes")
      }
      if (user.userAccount.matrixPricingFlagEnabled) {
        listOfPermissions.push("Daily Matrix Pricing")
      }
      if (/*user.userAccount.accessType == "ThirdParty" && */(user.userAccount.permissions & Permissions.Commissions) === Permissions.Commissions) {
        listOfPermissions.push("Broker Commissions Report")
      }
      return listOfPermissions;
    }
    else {
      return [];
    }
  }

  flipCommissionsAccessVal(){
    this.commissionsAccessVal = !this.commissionsAccessVal;
  }
  flipBrokerConfirmsAccessVal(){
    this.brokerConfirmAccessVal = !this.brokerConfirmAccessVal;
  }

  flipBrokerDropsEnrollmentsAccessVal(){
    this.brokerDropEnrollmentAccessVal = !this.brokerDropEnrollmentAccessVal;
  }

  flipPricingAccessVal(sel:number){
    this.pricingAccessVal = sel;
  }
}
