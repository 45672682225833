
import { Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IGenerationSite } from "../generation-site";
import { ShadowMeteringService } from "../shadow-metering.service";
import { PortalService } from "../../shared/portal.service";

import { IUserAccount } from '../../shared/entities/profile';
import { DataService } from '../../shared/data.service';
import { Observable, Subscription } from "rxjs";
import { combineLatest, of } from "rxjs";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";

@Component({
  selector: "app-distributed-generation",
  templateUrl: "./distributed-generation.component.html",
  styleUrls: ["./distributed-generation.component.scss"],
})
export class DistributedGenerationComponent implements OnInit, OnDestroy {
  tempSelectedSite: IGenerationSite;
  sites$: Observable<IGenerationSite[]>;
  private readonly unsubscribe$ = new Subject();
  modify = false;
  loading = true;
  lookback = false;
  myAccount: IUserAccount;
  refreshDataEvent = new EventEmitter();
  dgenSitesSubscription: Subscription;
  showFuelLevel: boolean;
  maskModeSubscription: Subscription;
  maskMode = false;

  constructor(
    public shadowMeteringService: ShadowMeteringService,
    private portalService: PortalService,
    private dataService: DataService
  ) {
    this.sites$ = shadowMeteringService.sites;
  }

  ngOnInit() {
    this.dataService.setContextualHelp(true);
    this.dataService.showExportUsageDetail.emit(false);
    if (this.dataService.getDgenSites()) {
      this.sites$  = of(this.dataService.getDgenSites().filter(site => site.hasReliabilityService));
      let sites = this.dataService.getDgenSites().filter(site => site.hasReliabilityService);
        this.showFuelLevel = false;
        if(sites) {
          sites.forEach(siteSel => {
            if(siteSel.generators){
              siteSel.generators.forEach(gens => {
                if(gens.fuelType==="Diesel") {
                  this.showFuelLevel = true;
                }
              })
            }
          });
        }

      
      this.myAccount = this.dataService.getAccountSource();

      if (this.myAccount) {
            if (sites && sites.length) {
              if (this.shadowMeteringService.selectedSite) {
                let i = 0;
                let selItem = 0;
                sites.forEach(siteSel => {
                  if (siteSel.id === this.shadowMeteringService.selectedSite.id) {
                    selItem = i;
                  }
                  i++;
                });
                this.shadowMeteringService.setSelectedSite(null);
                this.selectSite(sites[selItem]);
              } else {
                this.selectSite(sites[0]);
              }
            }
        }
        IntervalObservable.create(300000)
        .takeUntil(this.unsubscribe$)
        .subscribe(() => {
          if (!this.shadowMeteringService.lookbackEnabled) {
            this.shadowMeteringService.getGenerationStatus(this.myAccount.id)
              .takeUntil(this.unsubscribe$)
              .subscribe(_res => {});
            this.refreshDataEvent.emit();
          }
        });
    }
    this.maskMode = this.dataService.getMaskMode();
    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
    });

    this.dgenSitesSubscription  = this.dataService.dgenSitesUpdated.subscribe(
      sites => {
      this.sites$ = of(sites);

      this.myAccount = this.dataService.getAccountSource();
      if (this.myAccount) {
        if (sites && sites.length) {
          if (this.shadowMeteringService.selectedSite) {
            let i = 0;
            let selItem = 0;
            sites.forEach(siteSel => {
              if (siteSel.id === this.shadowMeteringService.selectedSite.id) {
                selItem = i;
              }
              i++;
            });
            this.shadowMeteringService.setSelectedSite(null);
            this.selectSite(sites[selItem]);
          } else {
            this.selectSite(sites[0]);
          }
        }
      }

      IntervalObservable.create(300000)
        .takeUntil(this.unsubscribe$)
        .subscribe(() => {
          if (!this.shadowMeteringService.lookbackEnabled) {
            this.shadowMeteringService.getGenerationStatus(this.myAccount.id)
              .takeUntil(this.unsubscribe$)
              .subscribe(_res => {});
            this.refreshDataEvent.emit();
          }
        });

      });
  }

  ngOnDestroy() {
    this.dataService.setContextualHelp(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.shadowMeteringService.resetData();
    if (this.dgenSitesSubscription) this.dgenSitesSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  modifySelected() {
    this.tempSelectedSite = this.selectedSite;
    this.modify = true;
  }

  onModifyApply() {
    this.selectSite(this.tempSelectedSite);
    this.modify = false;
  }

  onModifyCancel() {
    this.selectSite(this.selectedSite);
    this.modify = false;
  }

  getDateDisplay(dateString: string): string {
    let dateStr = this.portalService.getDateDisplay(dateString);
    if (dateStr && dateStr.includes('9999')) {
      dateStr = '';
    }
    return dateStr;
  }
  getFakeSiteId(site: IGenerationSite) {
    return this.portalService.getFakeSiteId(site);
  }
  getFakeSiteName(site: IGenerationSite) {
    return this.portalService.getFakeSiteName(site);
  }
  getFakeSiteAddress(site: IGenerationSite) {
    return this.portalService.getFakeStreetAddress(site);
  }
  selectSite(site: IGenerationSite) {
    if (!this.selectedSite || site.id !== this.selectedSite.id) {
      this.loading = true;
      this.shadowMeteringService.setSelectedSite(site);
      this.shadowMeteringService.setLookbackEnabled(false);
      this.shadowMeteringService.setLookbackRange(null);
      const updateGenerationStatuses$ = this.shadowMeteringService.getGenerationStatus(this.myAccount.id);
      const updateGenerationUsageHistory$ = this.shadowMeteringService.getSiteGenerationUsage(this.myAccount.id);
      combineLatest(
        updateGenerationStatuses$,
        updateGenerationUsageHistory$,
        (_status, _usage) => {}
      ).subscribe(
        _pair => {
          this.loading = false;
        },
        err => {
          console.error('Error occurred fetching new site data for selected site.', err);
          this.loading = false;
        });
    }
  }

  get selectedSite(): IGenerationSite {
    return this.shadowMeteringService.selectedSite;
  }
}
