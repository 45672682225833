import {Component, ElementRef, OnInit} from '@angular/core';
import {  IBillingReportResponse, ICategory, IDownloadInvoiceRequest, IInvoice } from "../shared/entities/bill-reports";
import {ReportDataUtility} from "../subscribe-to-report/report-data.utility";
declare var $ :any;
import {ISite} from "../shared/entities/site";
import {Subscription} from "rxjs";
import {IHelpStep} from "../shared/entities/contextualHelp";
import {IProfile, IUserAccount} from "../shared/entities/profile";
import {PortalService} from "../shared/portal.service";
import {BillingReportPipe} from "../shared/pipe/billingReport.pipe";
import {FakerService} from "../shared/faker.service";
import {DatePeriodsArr, WeeklyDatePeriods} from "../shared/const/datePeriods";
import {DataService} from "../shared/data.service";
import {BillingSummaryService} from "../billing-summary/billing-summary.service";
import {Router} from "@angular/router";
import * as moment from 'moment';
import {FadeAnimation} from "../animations/fadeAnimation";
import {TpBillingSummaryColumns} from "../shared/const/tpBillingSummaryColumns";
import {TpBillingSummaryPipe} from "../shared/pipe/tp-billing-summary.pipe";
import { IBillingReport, IBillingReportRequest } from '../shared/entities/reportSettings';

// For all the promises of God find their Yes in him. That is why it is through him that we utter our Amen to God for his glory. - 2 Corinthians 1:20

@Component({
  selector: 'app-tp-billing-summary',
  templateUrl: './tp-billing-summary.component.html',
  styleUrls: ['./tp-billing-summary.component.scss', '../shared/styles/reports.scss'],
  animations: [FadeAnimation],
  providers: [TpBillingSummaryPipe],
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class TpBillingSummaryComponent implements OnInit {
  elementRef;
  myAccount: IUserAccount;
  billingReports: IBillingReport[];
  privateReports: IBillingReport[];
  publicReports: IBillingReport[];
  selectedReport: IBillingReport;
  reportData: IBillingReportResponse;
  filteredReportData: IBillingReportResponse;
  reportRequest: IBillingReportRequest;
  summaryCategories: ICategory[];
  customerGroups: any[];
  noDataError: boolean = false;
  makeDefault: boolean = true;
  makePublic: boolean = false;
  showSearchBar: boolean = false;
  searchTerm: string = '';
  prevSearch: string = '';
  showScrollbar: boolean = false;
  showReports: boolean = false;
  showSettings: boolean = false;
  showSave: boolean = false;
  maskMode: boolean;
  helpStep: IHelpStep;
  selectedStartDate: string;
  selectedEndDate: string;
  showDetailView: boolean;
  pager = {
    totalPages: 0,
    firstItem: 1,
    lastItem: 50,
    totalItems: 0,
    pageArray: [],
  };
  dateDisabled: boolean = false;


  exportSubscription: Subscription;
  accountSourceSubscription: Subscription;
  billingDateRangesSourceSubscription: Subscription;
  tpGroupSourceSubscription: Subscription;
  maskModeSubscription: Subscription;
  helpStepSubscription: Subscription;

  activateSave: boolean = false;
  creatingReport: boolean = false;
  editingReportName: boolean = false;
  newReportName: string = "";
  makeNewDefault: boolean = false;
  makeNewPublic: boolean = false;

  // Groupings
  groupingsControl: any = {
    show: false,
    selectedGrouping: {
      id: null,
      display: 'All Customers'
    }
  };

  // Date Range
  dateRangeControl: any = {
    periodSelected: true,
    toggled: false,
    show: false,
    startDate: {},
    endDate: {},
    datePeriod: 'Last3Months',
    datePeriodDisplay: 'Last 3 Months',
    dateRangeDisplay: '',
    dateType: 'BILL POST DATE'
  };

  tempDateRangeControl: any = {};

  dateTypeSelected: boolean = true;
  datePeriods: any[];
  billingDateRanges: any[];
  endDates: any[];

  subscriptionOptions: any = {
    frequencies: [{'display':'Weekly', 'option':'WEEKLY'}, {'display':'Monthly', 'option':'MONTHLY'}],
    deliveries: WeeklyDatePeriods,
    description: '',
    valid: false
  };


  // Columns
  tableColumns: any[]; // Columns iterated over the table, taken from columns model in applyColumnChanges()
  columns: any[];

  constructor(private dataService: DataService,
              private billingService: BillingSummaryService,
              private tpBillingSummaryPipe: TpBillingSummaryPipe,
              private router: Router,
              private portalService: PortalService,
              private myElement: ElementRef,
              private utility: ReportDataUtility,
              private fakerService: FakerService) {
    this.elementRef = myElement;
  }

  ngOnInit() {
    this.dataService.setSelectedNavItem('billing');
    this.setCurrentActivePage('billing')
    this.dataService.setTitleSource('Billing Summary Report');
    this.dataService.setLoading(true);

    this.myAccount = this.dataService.getAccountSource();
    this.maskMode = this.dataService.getMaskMode();
    this.accountSourceSubscription = this.dataService.accountSourceUpdated.subscribe(
      (account) => {
        this.myAccount = account;
      }
    );
    this.billingDateRangesSourceSubscription = this.dataService.reportSubscriptionDateRangesSourceUpdated.subscribe(
      (billingDateRanges) => {
        this.billingDateRanges = billingDateRanges;
        this.endDates = billingDateRanges;
      });
    this.calculateDateRanges();
    let customerGroups = this.dataService.getTpGroupSource();
    if (customerGroups) {
      this.customerGroups = customerGroups;
      this.getBillingReports(true);
    }
    this.tpGroupSourceSubscription = this.dataService.tpGroupSourceUpdated.subscribe(
      (customerGroups) => {
        this.customerGroups = customerGroups;
        this.getBillingReports(true);
      }
    );

    this.exportSubscription = this.dataService.exportTriggered.subscribe(
      (time) => {
        if(this.isValidExportDateRange()){
          this.dataService.setLoading(true);
          this.exportToExcel();
        }
        else {
          $('#invalidDateRangeExportModal').modal('show');
        }
      }
    );

    this.maskModeSubscription = this.dataService.maskModeUpdated.subscribe((maskMode) => {
      this.maskMode = maskMode;
      this.generateBillingReportData();
    });

    // Click outside event for date range and groupings sections
    document.addEventListener("click", ($event) => {
      if($($event.target).parents('#dateRangeSection').length == 0){
        this.dateRangeControl.show = false;
      }
      if($($event.target).parents('#groupingsSection').length == 0){
        this.groupingsControl.show = false;
      }
      if($($event.target).parents('#optionsSection').length == 0){
        this.showSettings = false;
      }
      if($($event.target).parents('#reportsSection').length == 0){
        this.showReports = false;
      }
      if($($event.target).parents('#saveSection').length == 0){
        this.showSave = false;
      }
    });

    this.datePeriods = DatePeriodsArr;
    this.columns = TpBillingSummaryColumns;
    this.setDefaultColumns();
    this.applyColumnChanges(true);
    this.hideSidenav();
    this.activateSave = false;

    this.detectScrollbar();

    this.helpStep = this.dataService.getHelpStep();
    this.helpStepSubscription = this.dataService.helpStepUpdated.subscribe((helpStep) => {
      this.helpStep = helpStep;
      if(this.helpStep){
        this.helpStepUpdated();
      }
    });
  }

  ngOnDestroy() {
    if (this.exportSubscription) this.exportSubscription.unsubscribe();
    if (this.accountSourceSubscription) this.accountSourceSubscription.unsubscribe();
    if (this.billingDateRangesSourceSubscription) this.billingDateRangesSourceSubscription.unsubscribe();
    if (this.tpGroupSourceSubscription) this.tpGroupSourceSubscription.unsubscribe();
    if (this.maskModeSubscription) this.maskModeSubscription.unsubscribe();
  }

  setCurrentActivePage(tabName: string): void {
    this.dataService.setCurrentActivePage(tabName);
  }

  isValidExportDateRange(){
    let startDate = moment(this.dateRangeControl.startDate);
    let endDate = moment(this.dateRangeControl.endDate);

    // console.log("this.myAccount.id: ", this.myAccount.id);

    if(this.myAccount.id == '1+2G+563' && startDate.add(6, 'M').isSameOrBefore(endDate)){
      return false;
    }
    return true;
  }

  helpStepUpdated() {

  }

  handleClick(event){
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if(!inside && this.editingReportName){
      this.editReportName();
    }
  }

  generateDatesFromPeriod(control: any) {
    this.utility.generateDatesFromPeriod(control, this.billingDateRanges);
  }

  // And this is love: not that we loved God but that he loved us and sent his Son to be the propitiation for our sins. - 1 John 4:10

  generateRequest() {
    this.reportRequest = {
      accountId: this.myAccount.id,
      groupId: this.groupingsControl.selectedGrouping.id ? this.groupingsControl.selectedGrouping.id : null,
      startDate: this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.endDate,
      dateType: this.dateTypeSelected ? 'BILL POST DATE' : 'METER READ END DATE'
    };
  }

  generateBillingReportData() {
    this.dataService.setLoading(true);
    this.generateDatesFromPeriod(this.dateRangeControl);
    this.generateRequest();
    this.showDetailView = false;
    this.reportRequest.searchTerm = this.searchTerm.trim();
    this.billingService.generateTpBillingReportData(this.reportRequest).subscribe(
      (reportData) => {
        this.reportData = reportData;
        this.noDataError = false;
        if(this.reportData.categories){
          this.summaryCategories = this.reportData.categories;
          if (!this.groupingsControl.selectedGrouping.id){
            this.reportData.categories[0].name = 'All Customers';
          }

          if(this.maskMode){
            this.reportData.categories.forEach((category) => {
              if (this.groupingsControl.selectedGrouping.groupName == 'COLLECTIVE_BILLING' && category.name != 'Unassigned') {
                category.name = '80012345';
              }
              if (category.sites) {
                category.sites.forEach((site) => {
                  site.siteName = this.fakerService.getCompanyName();
                  site.contractAccountNumber = this.fakerService.getContractAccountNum();
                  site['serviceAddress1'] = this.fakerService.getStreetAddress();
                  site['serviceCity'] = this.fakerService.getCity();
                  site['billingAddress1'] = this.fakerService.getStreetAddress();
                  site['billingCity'] = this.fakerService.getCity();
                  site.invoices.forEach((invoice) => {
                    invoice.siteName = site.siteName;
                    invoice.contractAccountNumber = site.contractAccountNumber;
                    invoice['serviceAddress1'] = site['serviceAddress1'];
                    invoice['serviceCity'] = site['serviceCity'];
                    invoice['billingAddress1'] = site['billingAddress1'];
                    invoice['billingCity'] = site['billingCity'];
                  });
                });
              } else if (this.groupingsControl.selectedGrouping.groupName === 'ALL_CUSTOMERS') {
                category.accounts.forEach((acct) => acct.name = this.fakerService.getCompanyName());
              }
            });
          }
        } else {
          this.reportData.categories = [reportData as any];
        }

        this.filteredReportData = this.reportData;
        setTimeout(() => {
          let element = document.getElementById('tableWrapper');
          $(element).scroll(function() {
            if($(this).scrollTop() != 0){
              $('.scroll-header').css('top', $(this).scrollTop()-5 + "px");
            } else {
              $('.scroll-header').css('top', $(this).scrollTop() + "px");
            }
          });
          $('.scroll').scroll(function() {
            $('.scrollbar').scrollLeft($('.scroll').scrollLeft());
          });

          $('.scrollbar').scroll(function() {
            $('.scroll').scrollLeft($('.scrollbar').scrollLeft());
          });
          this.dataService.setLoading(false);
        }, 1500);
      }, err => {
        if(err.status == 404){
          this.reportData = null;
          this.noDataError = true;
        }
        console.error(err);
        setTimeout(() => {
          this.dataService.setLoading(false);
        }, 1500)
      })
  }

  getFakeSiteId(site: ISite) {
    return this.portalService.getFakeSiteId(site);
  }

  getFakeCompanyName(account: IUserAccount): string {
    return this.portalService.getFakeCompanyName(account);
  }

  getDefaultReport() {
    let defaultReport = {
      reportId: null,
      columnNames: ' ',
      accountId: this.myAccount.id,
      reportName: 'Billing Report 01',
      periodType: 'Last3Months',
      defaultReport: 0,
      startDate: null,
      endDate: null,
      dateType: 'BILL POST DATE',
      publicFlag: 0,
      groupId: null,
      createdUserId: this.dataService.getUserSource().login
    };
    return defaultReport;
  }

  loadDefaultReport() {
    this.billingReports = [];
    let defaultReport = this.getDefaultReport();
    this.setDefaultColumns();
    this.applyColumnChanges();
    this.setDefaultDateRange();
    this.setDefaultGrouping();

    this.billingReports.push(defaultReport);
    this.privateReports = [defaultReport];
    this.publicReports = [];
    this.selectedReport = this.billingReports[0];
    this.generateBillingReportData();
  }

  getBillingReports(selectDefault?: boolean) {
    this.editingReportName = false;
    this.billingService.getTpBillingReports(this.myAccount.id).subscribe(
      (billingReports) => {
        this.billingReports = billingReports;
        if(billingReports.length > 0){
          if(selectDefault){
            let defaultFound = false;
            this.billingReports.forEach((name) => {
              if(name.defaultReport) {
                this.selectedReport = name;
                defaultFound = true;
              }
            });
            this.activateSave = false;
            if(!defaultFound && billingReports.length > 0) {
              this.selectedReport = billingReports[0];
            }
          } else {
            this.billingReports.forEach(
              (name) => {
                if(name.reportId == this.selectedReport.reportId) {
                  this.selectedReport = name;
                }
              }
            )
          }
          if(!this.selectedReport){
            this.selectedReport = billingReports[0];
          }
          this.activateSave = false;
          this.publicReports = [];
          this.privateReports = [];
          this.billingReports.forEach((report) => {
            if(report.publicFlag){
              this.publicReports.push(report);
            } else {
              this.privateReports.push(report);
            }
          });
          this.loadSelectedReport();
          this.generateBillingReportData();
        } else {
          this.loadDefaultReport();
          // this.saveReport();
        }
      }, err => {
        this.loadDefaultReport();
        this.activateSave = false;
      }
    );
  }

  loadSelectedReport() {
    if(this.selectedReport.groupId) {
      this.customerGroups.forEach((customerGroup) => {
        if(customerGroup.id == this.selectedReport.groupId) {
          this.groupingsControl.selectedGrouping = customerGroup;
          if(customerGroup.groupName.length > 18){
            this.groupingsControl.selectedGrouping.display = customerGroup.groupName.substring(0, 18) + '...'
          } else {
            this.groupingsControl.selectedGrouping.display = customerGroup.groupName;
          }
        }
      });
    } else {
      let allSitesGrouping = {
        groupName: 'ALL_CUSTOMERS',
        description: 'All Customers',
      };
      this.groupingsControl.selectedGrouping = allSitesGrouping;
      this.groupingsControl.selectedGrouping.display = allSitesGrouping.description
    }

    this.makeDefault = this.selectedReport.defaultReport == 1;
    this.makePublic = this.selectedReport.publicFlag == 1;
    this.dateRangeControl.dateType = this.selectedReport.dateType;
    if (this.dateRangeControl.dateType == 'BILL POST DATE'){
      this.dateTypeSelected = true
    } else{
      this.dateTypeSelected = false
    }
    if(this.selectedReport.periodType) {
      this.dateRangeControl.periodSelected = true;
      this.dateRangeControl.datePeriod = this.selectedReport.periodType;
      this.getDatePeriodDisplay(this.dateRangeControl);
      this.generateDatesFromPeriod(this.dateRangeControl);
    } else {
      this.dateRangeControl.periodSelected = false;
      this.dateRangeControl.startDate = this.selectedStartDate = this.selectedReport.startDate;
      this.dateRangeControl.endDate = this.selectedEndDate = this.selectedReport.endDate;
    }
    this.getDateRangeDisplay();
    let settingsColumns = this.selectedReport.columnNames.split(',');
    this.columns.forEach((section)=> {
      section.columns.forEach((col) => {
        col.checked = false;
        settingsColumns.forEach((settingsCol) => {
          if(settingsCol == col.value){
            col.checked = true;
          }
        })
      });
    });
    this.applyColumnChanges(true);
  }

  deleteBillingReportName() {
    this.billingService.deleteTpBillingReport(this.myAccount.id, this.selectedReport.reportId).subscribe(
      () => {
        this.getBillingReports(true);
      }
    );
  }

  expand(row) {
    row.expanded = !row.expanded;
    this.detectScrollbar();
    this.updateScrollbarLength();
  }

  toggleDetailView(categoryRow: ICategory){

    if(this.myAccount.largeCustomer){
      if(categoryRow.expanded){
        this.showDetailView = true;
        if(categoryRow.name != 'Search Results') {
          this.generateRequest();
          this.reportRequest.pageNo = 1;
          this.reportRequest.pageSize = 50;
          this.reportRequest.categoryName = categoryRow.name;
          if(this.reportRequest.categoryName == 'All Customers') {
            this.reportRequest.categoryName = 'ALL_CUSTOMERS';
          }
          this.dataService.setLoading(true);
          this.billingService.getBillingReportDetailView(this.reportRequest).subscribe((sites) => {
            categoryRow.sites = sites;
            this.reportData.categories = [categoryRow];
            this.buildPager(categoryRow);
          });
        } else {
          this.reportRequest.pageNo = 1;
          this.reportRequest.pageSize = 50;
          this.buildPager(categoryRow);
        }
      } else {
        this.showDetailView = false;
        this.reportData.categories = this.summaryCategories;
      }
    }
  }

  buildPager(categoryRow: ICategory) {
    this.pager.totalPages = Math.ceil(categoryRow.siteCount/50);
    this.pager.totalItems = categoryRow.siteCount;
    this.pager.pageArray = [];
    for (let i = 1; i <= this.pager.totalPages; i++) {
      this.pager.pageArray.push(i);
    }
    this.updatePager();
    this.dataService.setLoading(false);
  }

  updatePager() {
    this.pager.firstItem = this.reportRequest.pageNo * 50 - 49;
    this.pager.lastItem = this.reportRequest.pageNo * 50;
    if(this.pager.lastItem > this.pager.totalItems){
      this.pager.lastItem = this.pager.totalItems;
    }
  }

  getDetailPage(pageNo: number) {
    if(pageNo > 0 && pageNo <= this.pager.totalPages){
      this.reportRequest.pageNo = pageNo;
      this.updatePager();
      this.dataService.setLoading(true);
      this.billingService.getBillingReportDetailView(this.reportRequest).subscribe((sites) => {
        this.reportData.categories[0].sites = sites;
        this.dataService.setLoading(false);
      });
    }
  }

  getGroupingName(name: string, count: number) {
    return count ? name + ' (' + count.toString() + ')' : name;
  }

  detectScrollbar() {
    let element = document.getElementById('tableWrapper');
    this.showScrollbar = element.offsetHeight != element.scrollHeight;
  }

  updateScrollbarLength() {
    // Welcome to the most hated block of code in this app.
    let id, scrollWidth = 0;
    setTimeout(() => {
      this.tableColumns.forEach((tableCol)=> {
        if (tableCol.checked) {
          id = '#' + tableCol.value + 'Col';
          scrollWidth += $(id).outerWidth();
        }
      });
      $("#scrollbar").css({
        'width': (scrollWidth + 'px')
      });
    }, 50);
  }

  // Reports dropdown

  toggleReports() {
    this.showReports = !this.showReports;
  }

  // Report Settings
  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  duplicateAndSave(){
    this.makePublic = false;
    this.makeDefault = false;
    this.newReportName = '';
    $('#namePrivateModal').modal('show');
  }

  goPublic(){
    this.toggleSettings();
    this.makePublic = true;
    this.saveReport();
    this.getBillingReports();
  }

  setAsDefault(){
    this.toggleSettings();
    this.makeDefault = true;
    this.saveReport();
    this.getBillingReports();
  }

  // Save

  toggleSave(){
    if(this.activateSave){
      this.showSave = !this.showSave;
    }
  }

  saveNewReport(){
    this.selectedReport.reportId = null;
    $('#renameModal').modal('show');
    this.toggleSave();
  }

  createNewReport(){
    this.selectedReport = this.getDefaultReport();
    this.selectedReport.reportName = this.newReportName;
    this.selectedReport.publicFlag = this.makeNewPublic ? 1 : 0;
    this.selectedReport.defaultReport = this.makeNewDefault ? 1 : 0;
    this.loadSelectedReport();
    this.setDefaultColumns();
    this.applyColumnChanges();
    this.saveReport();
    $('#createReportModal').modal('hide');
  }

  openCreateNewReport(){
    this.makeNewDefault = false;
    this.makeNewPublic = false;
    this.newReportName = '';
  }

  // Groupings Dialog
  toggleGroupings() {
    this.groupingsControl.show = !this.groupingsControl.show;
  }

  selectGrouping(grouping: any) {
    if(grouping == 'ALL_CUSTOMERS'){
      grouping = {
        groupName: 'ALL_CUSTOMERS'
      };
    }
    this.toggleGroupings();
    this.groupingsControl.selectedGrouping = grouping;
    this.generateBillingReportData();
    this.customerGroups.forEach((userGroup) => {
      if (grouping.id == userGroup.id) {
        if(userGroup.groupName.length > 18){
          this.groupingsControl.selectedGrouping.display = userGroup.groupName.substring(0, 18) + '...'
        } else {
          this.groupingsControl.selectedGrouping.display = userGroup.groupName;
        }
      }
    });
    this.activateSave = true;
  }

  // Date Range Dialog
  toggleDateRange() {
    this.dateRangeControl.show = !this.dateRangeControl.show;
    this.tempDateRangeControl = Object.assign({}, this.dateRangeControl);
    this.getDatePeriodRangeDisplay(this.tempDateRangeControl);
  }

  selectDatePeriod(dateRangeControl: any, datePeriod: string){
    dateRangeControl.datePeriod = datePeriod;
    this.getDatePeriodDisplay(dateRangeControl);
    this.generateDatesFromPeriod(dateRangeControl);
    this.getDatePeriodRangeDisplay(dateRangeControl);
  }

  getDatePeriodRangeDisplay(dateRangeControl: any){
    let startDate = moment(dateRangeControl.startDate).format('MMM \'YY');
    let endDate = moment(dateRangeControl.endDate).format('MMM \'YY');
    dateRangeControl.datePeriodRangeDisplay = startDate + ' - ' + endDate;
  }

  selectPeriodRadio() {
    this.dateDisabled = false;
    this.tempDateRangeControl.periodSelected = true;
    this.generateDatesFromPeriod(this.tempDateRangeControl);
    this.getDatePeriodRangeDisplay(this.tempDateRangeControl);
  }

  selectDateRangeRadio() {
    this.tempDateRangeControl.periodSelected = false;
    this.tempDateRangeControl.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
    this.tempDateRangeControl.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
    if(moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      this.dateDisabled = true;
    }
  }

  selectStartDate(startDate: string){
    this.dateDisabled = false;
    this.selectedStartDate = startDate;
    this.tempDateRangeControl.startDate = moment(this.selectedStartDate).format('YYYY-MM-DD');
    this.endDates = this.billingDateRanges;
    let newEndDates = [];
    this.endDates.forEach((endDate) => {
      if(moment(this.tempDateRangeControl.startDate).isBefore(endDate.endDate)){
        newEndDates.push(endDate);
      }
    });
    this.endDates = newEndDates;
    if(moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      this.dateDisabled = true;
    }
  }

  selectEndDate(endDate: string){
    this.dateDisabled = false;
    this.selectedEndDate = endDate;
    this.tempDateRangeControl.endDate = moment(this.selectedEndDate).format('YYYY-MM-DD');
    if(moment(this.selectedEndDate).isBefore(moment(this.selectedStartDate))) {
      this.dateDisabled = true;
    }
  }

  applyDateRange(toggle: boolean) {
    if(toggle){
      this.dateRangeControl = Object.assign({}, this.tempDateRangeControl);
      this.toggleDateRange();
      this.generateBillingReportData();
    }
    //this.generateBillingReportData();
    this.getDateRangeDisplay();
    this.activateSave = true;
  }

  calculateDateRanges() {
    this.utility.calculateDateRanges()
  }

  getDateRangeDisplay() {
    let startDate = moment(this.dateRangeControl.startDate).format('MMM \'YY');
    let endDate = moment(this.dateRangeControl.endDate).format('MMM \'YY');
    this.dateRangeControl.dateRangeDisplay = startDate + ' - ' + endDate;
  }

  getDateDisplay(date: any) {
    return moment(date).format('MMMM YYYY');
  }

  getDatePeriodDisplay(dateRangeControl: any) {
    if(this.datePeriods){
      this.datePeriods.forEach((period) => {
        if (period.value == dateRangeControl.datePeriod) {
          dateRangeControl.datePeriodDisplay = period.display;
          return;
        }
      });
    }
  }

  navToCustomerList() {
    this.router.navigate(['/third-party/customer-list']);
  }

  showSidenav() {
    this.dataService.setOverlay(true);
    document.getElementById('editReportColumnsSidenav').style.width = "24%";
  }

  hideSidenav() {
    this.dataService.setOverlay(false);
    document.getElementById('editReportColumnsSidenav').style.width = "0";
  }

  // Editing Columns
  applyColumnChanges(init?: boolean) {
    this.tableColumns = [];
    this.columns.forEach((section) => {
      section.columns.forEach((col) => {
        let tableCol = Object.assign({}, col);
        this.tableColumns.push(tableCol);
      });
    });
    this.updateScrollbarLength();
    this.hideSidenav();
    if (!init) {
      this.activateSave = true;
    }
  }

  sectionChecked(sectionName: string) {
    setTimeout(() => {
      //Wait 0.01s for model to update before triggering other checkboxes #HACK
      this.columns.forEach((section) => {
        if (section.name == sectionName) {
          section.partialCheck = false;
          section.columns.forEach((col) => {
            col.checked = section.checked;
          });
        }
      });
    }, 10);
  }

  partialCheck() {
    setTimeout(() => {
      this.columns.forEach((section)=>{
        section.partialCheck = false;
        let checkedCount = 0;
        section.columns.forEach((column)=>{
          if(column.checked) {
            section.partialCheck = true;
            section.checked = true;
            checkedCount++;
          }
          if(checkedCount === section.columns.length) {
            section.partialCheck = false;
          }
        });
      });
    }, 10);
  }

  renameReport() {
    this.editingReportName = true;
    this.selectedReport.reportName = this.newReportName;
    this.saveReport();
    this.toggleSettings();
    $('#renameModal').modal('hide');
  }

  editReportName() {
    this.selectedReport.reportName = this.newReportName;
    this.editingReportName = false;
  }

  setDefaultColumns(){
    this.columns.forEach((section) => {
      section.checked = false;
      section.toggled = true;
      section.columns.forEach((col) => {
        if(col.display == 'Total Current Charges' || col.display == 'Usage (kWh)' ||
          col.display == 'Energy Charge' || col.display == 'Transmission Charges' || col.display == 'Aggregation Fee' ||
          col.display == 'Nodal Charge' || col.display == 'Other Charges' || col.display == 'Taxes & Assessments'){
          col.checked = true;
        } else {
          col.checked = false;
        }
      });
    });
  }

  setDefaultDateRange() {
    this.dateTypeSelected = true;
    this.dateRangeControl.periodSelected = true;
    this.dateRangeControl.show = false;
    this.dateRangeControl.toggled = false;
    this.selectDatePeriod(this.dateRangeControl, 'Last3Months');
    this.applyDateRange(false);
  }

  setDefaultGrouping() {
    this.groupingsControl = {
      show: false,
      selectedGrouping: {
        id: null,
        display: 'All Customers'
      }
    };
  }

  selectReport(report: IBillingReport){
    this.selectedReport = report;

    if(this.selectedReport.defaultReport){
      this.makeDefault = true;
    } else {
      this.makeDefault = false;
    }

    if(this.selectedReport.publicFlag){
      this.makePublic = true;
    } else {
      this.makePublic = false;
    }
    this.loadSelectedReport();
    this.generateBillingReportData();
  }

  downloadInvoice(invoice: IInvoice) {
    this.dataService.setLoading(true);
    let request: IDownloadInvoiceRequest = {
      accountId: this.myAccount.id,
      contractAccountNumber: invoice.contractAccountNumber,
      collectiveAccountId: this.maskMode ? null : invoice.collectiveAccountId,
      collectiveInvoiceNo: this.maskMode ? null : invoice.collectiveInvoiceNo,
      siteId: invoice.siteId,
      invoiceNo: this.maskMode ? '9999999999990' : invoice.invoiceNo,
      billingPeriod: invoice.billingDate.substr(0,7).replace("-",""),
      repOwnerId: invoice.repOwnerId
    };
    if(this.dataService.getIsMobile()){
      this.billingService.openMobileInvoice(request);
    } else {
      this.billingService.downloadInvoice(request);
    }
  }

  formatCell(data: any, col: string) {
    return this.utility.formatCellBilling(data, col, this.maskMode);
  }

  getTooltip(site: any, col: string){
    if(col == 'serviceAddress'){
      return site['serviceAddress1'] + ' \n' + site['serviceCity'] + ', ' + site['serviceState'] + ' ' + site['serviceZip'];
    } else if (col == 'billingAddress'){
      return site['billingAddress1'] + ' \n' + site['billingCity'] + ', ' + site['billingState'] + ' ' + site['billingZip'];
    } else if (col == 'siteStatus') {
      if(site['siteStatus']){}
        switch(site['siteStatus']) {
            case 'Added': {
              return 'Site was added via an amendment.';
            }
            case 'Assigned Away': {
              return 'Site was reassigned to another customer without a change in price.';
            }
            case 'Assigned To': {
              return 'Site was reassigned from another customer without a change in price.';
          }
          case 'Deleted': {
            return 'Site was deleted via an amendment.';
          }
          case 'Rescinded': {
            return 'Contract was never valid.';
          }
          case 'Restructured': {
            return 'Contract was restructured; new contract will contain \'Under Contract\' site.';
          }
          case 'Terminated': {
            return 'Contract is no longer valid.';
          }
          case 'Under Contract': {
            return 'Active site that was included in contract at the time of closing the contract.';
          }
        }
    }

  }

  defaultChanged() {
    if(this.selectedReport.defaultReport){
      this.activateSave = !this.makeDefault;
    } else {
      this.activateSave = this.makeDefault;
    }
  }

  publicChanged() {
    if(this.selectedReport.publicFlag){
      this.activateSave = !this.makePublic;
    } else {
      this.activateSave = this.makePublic
    }
  }

  saveReport() {

    let req: IBillingReport = {
      reportId: this.selectedReport.reportId,
      accountId: this.myAccount.id,
      reportName: this.newReportName ? this.newReportName : this.selectedReport.reportName ? this.selectedReport.reportName : 'Billing Report 01',
      columnNames: this.getColumnNamesString(),
      defaultReport: this.makeDefault ? 1 : 0,
      publicFlag: this.makePublic ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      groupId: this.groupingsControl.selectedGrouping.id ? this.groupingsControl.selectedGrouping.id : null,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.periodSelected ? null : this.dateRangeControl.endDate,
      dateType: this.dateTypeSelected ? 'BILL POST DATE' : 'METER READ END DATE'
    };
    this.showReports = false;
    this.newReportName = '';
    if(this.selectedReport.reportId){
      this.billingService.updateTpBillingReport(req).subscribe(
        resp => {
          // console.log(resp);
          this.selectedReport.reportId = resp.reportId;
          this.makeNewPublic = this.makeNewDefault = false;
          this.getBillingReports(false);
        }, err => {
          if(err.status == 409){
            this.dataService.setLoading(false);
            $('#duplicateModal').modal('show');
          }
          console.error(err);
        }
      )
    } else {
      this.selectedReport.reportName = this.newReportName;
      this.billingService.createTpBillingReport(req).subscribe(
        resp => {
          this.makeNewPublic = this.makeNewDefault = false;
          this.selectedReport.reportId = resp.reportId;
          this.getBillingReports(false);
        }, err => {
          if(err.status == 409){
            this.dataService.setLoading(false);
            $('#duplicateModal').modal('show');
          }
          console.error(err);
        }
      )
    }
  }

  getColumnNamesString() {
    return this.utility.getColumnNamesString(this.columns);
  }

  getColumnHeadersString() {
    return this.utility.getColumnHeadersString(this.columns);
  }

  exportToExcel() {
    let req: IBillingReport = {
      accountId: this.myAccount.id,
      accountName: this.myAccount.name,
      reportName: this.selectedReport.reportName,
      columnNames: this.getColumnNamesString(),
      defaultReport: this.makeDefault ? 1 : 0,
      publicFlag: this.makePublic ? 1 : 0,
      createdUserId: this.selectedReport.createdUserId,
      reportId: this.selectedReport.reportId,
      groupId: this.groupingsControl.selectedGrouping.id ? this.groupingsControl.selectedGrouping.id : null,
      periodType: this.dateRangeControl.periodSelected ? this.dateRangeControl.datePeriod : null,
      startDate: this.dateRangeControl.startDate,
      endDate: this.dateRangeControl.endDate,
      dateType: this.dateTypeSelected ? 'BILL POST DATE' : 'METER READ END DATE',
      maskingMode: this.dataService.getMaskMode(),
      brand: window.localStorage.getItem("brand")
    };
    this.billingService.exportTpBillingReport(req);
  }

  toggleSearchRow() {
    let tableWrapper = document.getElementById('tableWrapper');
    tableWrapper.scrollTop = 0;
    this.showSearchBar = !this.showSearchBar;
    this.searchTerm = '';
    if(!this.showSearchBar) {
      this.filterReport(true);
    }
  }

  filterReport(refreshLarge: boolean) {
    if(!this.myAccount.largeCustomer){
      if(!this.searchTerm){
        this.filteredReportData = this.reportData;
      } else {
        this.filteredReportData = this.tpBillingSummaryPipe.transform(this.reportData, this.searchTerm);
      }
    } else if(refreshLarge && this.myAccount.largeCustomer && this.prevSearch != this.searchTerm){
      this.prevSearch = this.searchTerm;
      this.generateBillingReportData();
    }
  }

  calculateTotals() {
    this.filteredReportData = this.utility.calculateTotals(this.filteredReportData);
  }

  getReportNameDisplay(cutOff: number) {
    if(window.innerWidth < 1350 && cutOff > 20) {
      cutOff = 20;
    }
    if(this.selectedReport && this.selectedReport.reportName){
      return this.selectedReport.reportName.length < cutOff ? this.selectedReport.reportName : this.selectedReport.reportName.substr(0,cutOff) + '...';
    }
  }

  numbersWithCommas(x) {
    return this.utility.numbersWithCommas(x);
  }

  deleteReportModal() {
      $('#deleteModal').modal('show');
  }

}
