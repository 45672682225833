import { Injectable } from '@angular/core';
import {Http, Response, Headers,ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IPaymentHistory,IPaymentHistoryRequest} from '../shared/entities/paymentHistory';
import {DataService} from '../shared/data.service';



import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw'

@Injectable()
export class PaymentHistoryService {

  private headers: Headers;

  constructor(private http: Http, private dataService: DataService) {
     this.headers = new Headers({'Content-Type': 'application/json'});
   }


  getPaymentHistory(request: IPaymentHistoryRequest): Observable<IPaymentHistory[]> {
    if(environment.production){
      let url = environment.getPaymentHistoryUrl.replace('{accountId}', request.accountId.toString());
      return this.http
        .post(url, request, {headers: this.headers})
        .map((response: Response) => <IPaymentHistory[]>response.json())
        .catch(this.handleError);
    } else {
      let url = environment.getPaymentHistoryUrl;
      return this.http
        .get(url)
        .map((response: Response) => <IPaymentHistory[]>response.json())
        .catch(this.handleError);
    }
  }

  exportPaymentHistory(request: IPaymentHistoryRequest): any {
    let url = environment.production ? environment.exportPaymentHistoryUrl.replace('{accountId}', request.accountId.toString()) : environment.exportPaymentHistoryUrl;
    let headers = new Headers({'Content-Type': 'application/json'});
    return this.http
      .post(url, request, {headers: headers, responseType: ResponseContentType.Blob})
      .subscribe(
        (res) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.match(/filename=(.+)/)[1];
          if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE...
            window.navigator.msSaveBlob(res.blob(), filename);
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            let blob = new Blob([res.blob()], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.open(window.URL.createObjectURL(blob));
          } else {
            let link=document.createElement('a');
            link.href=window.URL.createObjectURL(res.blob());
            link.download = filename;
            link.click();
          }
          this.dataService.setLoading(false);
        }
      );
  }

  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }

}


/*
  // Get contact information by account ID
  getPaymentHistory(custAccountNo: number,startDate :string,endDate: string): Observable<IPaymentHistory> {
      let url = './assets/api/paymentHistory.json';
    //let url = environment.getPaymentHistoryUrl;
     return this.http
      .get(url)
      .map((response: Response) => <IPaymentHistory>response.json())
      .catch(this.handleError);
  }
  private handleError(error: Response) {
    return Observable.throw(error || 'Server error.');
  }
} */

